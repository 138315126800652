import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { EAppIconsStandard, EAppIcons } from 'src/app/classes/def/app/icons';
import { Messages } from 'src/app/classes/def/app/messages';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AudioService, IAudioUploadResponse } from 'src/app/services/media/audio';
import { ImageUtilsService } from 'src/app/services/media/image-utils';
import { IMediaUploadContext } from 'src/app/services/media/media-template';
import { MediaUtilsService } from 'src/app/services/media/media-utils';
import { SleepUtils } from 'src/app/services/utils/sleep-utils';

@Component({
  selector: 'media-recording-audio',
  templateUrl: './media-recording-audio.component.html',
  styleUrls: ['./media-recording-audio.component.scss'],
})
export class MediaRecordingAudioComponent implements OnInit, OnDestroy {
  @ViewChild('avStream', { read: ElementRef, static: false }) avStream: ElementRef<HTMLAudioElement>;
  @ViewChild('avCanvas', { read: ElementRef, static: false }) avCanvas: ElementRef<HTMLCanvasElement>;

  @Input()
  description: string;

  @Input()
  title: string;

  @Input()
  audioUrl: string;

  @Input()
  toggleRecording: boolean;

  @Input()
  innerClass: string;

  @Input()
  context: IMediaUploadContext;

  @Output()
  mediaUpload: EventEmitter<boolean> = new EventEmitter();

  @Output()
  mediaRecord: EventEmitter<boolean> = new EventEmitter();

  withButtons: boolean = false;

  appIconsStandard = EAppIconsStandard;
  appIcons = EAppIcons;

  np: INavParams = null;

  started: boolean = false;
  recording: boolean = false;
  locked: boolean = false;

  show: boolean = true;

  loading: boolean = false;

  innerClassActive: string = "full-w";

  constructor(
    public mediaUtils: MediaUtilsService,
    public imgUtils: ImageUtilsService,
    public audio: AudioService,
    public uiext: UiExtensionService
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'toggleRecording':
            this.toggleRecord();
            break;
        }
      }
    }
  }

  ngOnInit() {
    // if (this.audioUrl != null) {
    //   this.audio.setAudioElementPlayable(this.avStream);
    // }
    if (this.innerClass) {
      this.innerClassActive += " " + this.innerClass;
    }
  }

  isAudioContent() {
    return this.audioUrl != null;
  }

  ngOnDestroy() {
    this.stop();
  }

  toggleRecord() {
    if (this.locked) {
      console.warn("recording state locked");
      return;
    }
    if (!this.recording) {
      this.start(true);
    }
    if (this.recording) {
      this.stop();
    }
  }

  start(record: boolean) {
    if (this.audioUrl != null) {
      this.audio.setMediaElementPlayable(this.avStream);
      this.audio.playMediaElement(this.avStream, (paused: boolean) => {
        this.started = !paused;
      });
      return;
    }
    this.audio.setMediaElement(this.avStream);
    this.locked = true;
    this.loading = true;
    this.mediaRecord.emit(null);
    this.audio.startMediaCapture(this.avCanvas, false).then(() => {
      console.log("started");
      if (record) {
        this.startRecording();
      } else {
        this.mediaRecord.emit(false);
      }
      this.started = true;
      this.locked = false;
      this.loading = false;
    }).catch((err: Error) => {
      console.error(err);
      this.mediaRecord.emit(false);
      this.locked = false;
      this.loading = false;
    });
  }

  upload(data: any) {
    if (!this.context) {
      return;
    }
    this.loading = true;
    this.audio.uploadMedia(this.context.storyId, this.context.storyLocationId, ".webm", data).then((res: IAudioUploadResponse) => {
      if (res && res.status) {
        console.log("uploaded url: ", res.uploadedUrl);
        this.loading = false;
        this.audioUrl = res.uploadedUrl;
        this.mediaUpload.emit(true);
      } else {
        this.loading = false;
        this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
      }
    }).catch((err) => {
      this.loading = false;
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
    });
  }

  pause() {
    this.started = false;
    if (this.audioUrl != null) {
      this.audio.pauseMediaElement(this.avStream);
      return;
    }
    this.audio.pauseMediaCapture();
  }

  stop() {
    this.started = false;
    this.audio.stopMediaCapture();
    this.recording = false;
    this.mediaRecord.emit(false);
  }

  async record() {
    try {
      if (this.recording) {
        this.stop();
        return;
      }
      if (this.audioUrl != null) {
        let res: number = await this.uiext.showAlert(Messages.msg.recordingOverwrite.after.msg, Messages.msg.recordingOverwrite.after.sub, 2, null);
        if (res === EAlertButtonCodes.cancel) {
          return;
        }
        this.audioUrl = null;
      }
      if (!this.started) {
        this.start(true);
      } else {
        this.stop();
        await SleepUtils.sleep(100);
        this.start(true);
        // this.startRecording();
      }
    } catch (err) {
      console.error(err);
    }
  }

  startRecording() {
    this.mediaRecord.emit(true);
    this.recording = true;
    console.log("start recording");
    this.audio.startRecording((data) => {
      console.log("recorded blob: ", data);
      if (data != null) {
        this.imgUtils.blobToB64(data).then((b64: any) => {
          this.upload(b64);
        });
      } else {
        this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
      }
    });
  }

}

