import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { Platform } from '@ionic/angular';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { CheckAppsService, ECheckApp } from 'src/app/services/general/apis/check-apps';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { IGenericResponse } from 'src/app/classes/def/requests/general';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { AppSettings } from 'src/app/services/utils/app-settings';
import { AppConstants } from 'src/app/classes/app/constants';
import { Router } from '@angular/router';
import { ERouteDef } from 'src/app/app-utils';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { Util } from 'src/app/classes/general/util';
import { IAppVersionDB } from 'src/app/classes/def/app/app-version';
import { Messages } from 'src/app/classes/def/app/messages';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { EOS } from 'src/app/classes/def/app/app';
import { IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-about',
  templateUrl: './about.page.html',
  styleUrls: ['./about.page.scss'], animations: [
    trigger('showState', [
      state('inactive', style({
        opacity: 0
      })),
      state('active', style({
        opacity: 1
      })),
      transition('inactive => active', animate('0.25s ease-in')),
      transition('active => inactive', animate('0.25s ease-out'))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class AboutPage implements OnInit, OnDestroy {
  settings: any;
  description: string = null;
  loaded: boolean = false;
  showState: string = 'inactive';
  vs: IViewSpecs;

  theme: string = "theme-aubergine theme-aubergine-bg";
  version: string;

  timeout = null;
  timeout1 = null;

  appDetails: IAppVersionDB = null;
  errorRetrieveData: boolean = false;
  enableBackButton: boolean = true;

  headerClass: string = "";

  // this.testAnimate.state == 'inactive' ? this.testAnimate.state = 'active' : this.testAnimate.state = 'inactive'

  constructor(
    public settingsProvider: SettingsManagerService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public router: Router,
    public resourcesProvider: ResourcesCoreDataService,
    public backButton: BackButtonService,
    public analytics: AnalyticsService,
    public checkApps: CheckAppsService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public popupFeatures: PopupFeaturesService,
    public tutorials: TutorialsService
  ) {


  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  ngOnInit() {
    this.analytics.trackView("about");

    if (GeneralCache.isWeb) {
      this.version = GeneralCache.versionString;
    } else {
      this.version = GeneralCache.versionString;
      if (GeneralCache.codePushLabel) {
        this.version += "-" + GeneralCache.codePushLabel;
      }
    }

    // this.loadInfo();
    this.loadData();
    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        // ThemeClass[Settings.settings.app.settings.theme.value]
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        this.settings = this.settingsProvider.getSettings();
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    this.webView.ready().then(() => {
      this.backButton.replace(() => {
        this.back();
      });
    }).catch((err: Error) => { console.error(err); });
  }

  back() {
    this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngOnDestroy() {
    this.clearWatch();
  }

  loadData() {
    this.description = `
    <p>Leplace brings a new concept of urban experience right on your mobile phone</p>
    <p>Become a modern urban explorer anywhere in the world</p>
    <p>We place stories</p>
    <p>You make them unique</p>`;
    this.resourcesProvider.getAboutContent().then((response: IGenericResponse) => {
      this.revealText(response.data);
      this.loaded = true;
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "about");
      this.loaded = true;
      this.revealText(this.description);
      console.error(err);
    });

    this.resourcesProvider.getAppDetailsFromServer().then((data: IAppVersionDB) => {
      this.appDetails = data;
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "about");
      console.error(err);
      this.errorRetrieveData = true;
    });
  }

  revealText(text: string) {
    this.showState = 'active';
    this.description = text;
    // this.timeout1 = setTimeout(() => {
    //   this.showState = 'inactive';
    //   this.timeout = setTimeout(() => {
    //     this.description = text;
    //     this.showState = 'active';
    //   }, 500);
    // }, 500);
  }

  swipeEvent(_e) {
    // this.backButton.handleSwipeEvent(e);
  }


  clearWatch() {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (this.timeout1 !== null) {
      clearTimeout(this.timeout1);
      this.timeout1 = null;
    }
  }

  openURL(url: string) {
    Util.openURL(url);
  }

  openURLExt(url: string) {
    Util.openURLExt(url);
  }

  /**
   * check app details loaded
   */
  checkAppDetailsLoaded(): boolean {
    if (!this.appDetails) {
      if (this.errorRetrieveData) {
        this.uiext.showAlertNoAction(Messages.msg.pleaseReload.after.msg, Messages.msg.pleaseReload.after.sub);
      }
      return false;
    }
    return true;
  }

  /**
   * open app FB page
   */
  openFBPage() {
    // let ID: string = "leplaceapp";
    if (!this.checkAppDetailsLoaded()) {
      return;
    }

    let nativeUrl: string = "";
    if (GeneralCache.checkPlatformOS() === EOS.ios) {
      // ios open with native app
      nativeUrl = "fb://page?id=" + this.appDetails.fbId;
      // nativeUrl = "fb://profile/" + this.appDetails.fbId;
      // nativeUrl = "https://www.facebook.com/" + this.appDetails.fbId;
    } else {
      // android open with native app
      nativeUrl = "fb://page/" + this.appDetails.fbId;
    }

    this.checkApps.openNativeTimeout([ECheckApp.fb], nativeUrl, this.appDetails.fbLink);
  }

  /**
   * open app webpage
   */
  openWebpage() {
    if (!this.checkAppDetailsLoaded()) {
      return;
    }
    this.openURLExt(this.appDetails.webpage);
  }

  /**
   * write email to developers
   */
  contact() {
    this.resourcesProvider.getContactEmail().then((response: IGenericResponse) => {
      // let link = "mailto:leplace.app@gmail.com?subject=Contact";
      let link = response.data;
      if (link) {
        this.openURLExt(link);
      }
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "about");
    });
  }


  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      mainTutorial: {
        name: "Review tutorial",
        code: 0,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      },
      tutorial: {
        name: "Review terms",
        code: 1,
        icon: EAppIconsStandard.terms,
        enabled: true
      },
      account: {
        name: "Review briefing",
        code: 2,
        icon: EAppIconsStandard.briefing,
        enabled: true
      },
      web: {
        name: "Website",
        code: 3,
        icon: "globe",
        enabled: true
      },
      fb: {
        name: "Facebook",
        code: 4,
        icon: "logo-facebook",
        enabled: true
      },
      email: {
        name: "Contact",
        code: 5,
        icon: "mail",
        enabled: true
      },
    };

    if (AppSettings.testerMode) {
      let actions2: IPopoverActions = {

      };
      Object.assign(actions, actions2);
    }

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: true,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      console.log("standard modal result: ", result);
      switch (result) {
        case 0:
          this.router.navigate([ERouteDef.tutorial], { replaceUrl: true }).then(() => {

          }).catch((err: Error) => {
            console.error(err);
          });
          break;
        case 1:
          this.tutorials.showSignupTermsNoAction(false);
          break;
        case 2:
          this.tutorials.showSignupTutorialNoAction();
          break;
        case 3:
          this.openWebpage();
          break;
        case 4:
          this.openFBPage();
          break;
        case 5:
          this.contact();
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


}

