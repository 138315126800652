import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { Messages } from 'src/app/classes/def/app/messages';
import { IPopoverAction } from 'src/app/classes/def/app/modal-interaction';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { ParamHandler } from 'src/app/classes/general/params';
import { ICheckLocationPermission, LocationPermissionManagerService } from 'src/app/services/general/permissions/location-permission-manager';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { LocationManagerService } from 'src/app/services/map/location-manager';


export interface ICheckLocationFrameParams {
  title: string,
  description: string,
  footnote: string
}

export interface ICheckLocationFrameReturnData {
  resolved: boolean;
}

@Component({
  selector: 'modal-check-location',
  templateUrl: './check-location.component.html',
  styleUrls: ['./check-location.component.scss'],
})
export class CheckLocationViewComponent implements OnInit, OnDestroy {

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  vs: IViewSpecs = ViewSpecs.getDefault();
  np: INavParams = null;

  title: string = "";
  description: string = "";
  footnote: string = "";

  enabled: boolean = true;

  res: ICheckLocationFrameReturnData = {
    resolved: false
  };

  actionsList: IPopoverAction[] = [{
    name: "Check location",
    code: 1,
    enabled: true,
    icon: EAppIcons.gpsFixed,
    customIcon: true,
    handler: () => {
      this.initLocation();
      return true;
    }
  }, {
    name: "Request permissions",
    code: 2,
    enabled: !GeneralCache.isWeb,
    icon: EAppIconsStandard.unlocked,
    customIcon: false,
    handler: () => {
      this.setupLocationService();
      return true;
    }
  }, {
    name: "Open settings",
    code: 3,
    enabled: !GeneralCache.isWeb,
    icon: EAppIconsStandard.settings,
    customIcon: false,
    handler: () => {
      this.checkLocationSettings();
      return true;
    }
  }];

  constructor(
    public modalCtrl: ModalController,
    public locationPermissionManager: LocationPermissionManagerService,
    public locationManager: LocationManagerService,
    public uiext: UiExtensionService
  ) { }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);
      let params: ICheckLocationFrameParams = np.params;
      this.title = params.title;
      this.description = params.description;
      this.footnote = params.footnote;
    }
  }

  ngOnDestroy() {

  }

  dismiss() {
    setTimeout(() => {
      let res = null;
      if (this.res.resolved) {
        res = this.res;
      }
      this.modalCtrl.dismiss(res).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  async setupLocationService() {
    try {
      this.enabled = false;
      await this.uiext.showLoadingV2Queue("Checking..");
      await this.locationPermissionManager.setupLocationService(false);
      await this.uiext.dismissLoadingV2();
      this.enabled = true;
    } catch (err) {
      console.error(err);
      await this.uiext.dismissLoadingV2();
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
      this.enabled = true;
    }
  }

  initLocation() {
    this.enabled = false;
    this.locationManager.getCurrentLocationWrapper(true, false, true).then((location) => {
      console.log("init location: ", location);
      this.uiext.showAlertNoAction("Current location", "<p>Latitude:</p><p>" + location.lat + "</p><p>Longitude:</p><p>" + location.lng + "</p>");
      this.res.resolved = true;
      this.enabled = true;
    }).catch((err: Error) => {
      console.error(err);
      this.uiext.showAlertNoAction(Messages.msg.locationError.after.msg, ErrorMessage.parse(err, Messages.msg.locationError.after.sub));
      this.enabled = true;
    });
  }

  checkLocationSettings() {
    this.enabled = false;
    this.locationPermissionManager.checkNativeLocationSettings().then((status: ICheckLocationPermission) => {
      let granted: boolean = false;
      if (status != null) {
        granted = status.status;
      }
      console.log("granted: ", granted);
      this.uiext.showAlertNoAction("Status", granted ? "PERMISSIONS GRANTED" : "PERMISSIONS NOT GRANTED");
      this.enabled = true;
    }).catch((err: Error) => {
      console.error(err);
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
      this.enabled = true;
    });
  }
}
