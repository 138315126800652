import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { ModalController } from '@ionic/angular';
import { IChartDataContainer, IChartSeries, IChartSeriesContainer } from 'src/app/classes/def/app/charts';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ParamHandler } from 'src/app/classes/general/params';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { IBeaconLocateResult, IBLEDevice, IBLEDeviceDict } from 'src/app/services/map/ble-utils';
import { IndoorLocationManagerService } from 'src/app/services/map/indoor-location-manager';
import { IBeaconScannerParams } from './def';
import { ILatLng } from 'src/app/classes/def/map/coords';

@Component({
  selector: 'beacon-scanner-vc',
  templateUrl: './beacon-scanner.component.html',
  styleUrls: ['./beacon-scanner.component.scss'],
})
export class BeaconScannerViewComponent implements OnInit, OnDestroy {
  @ViewChild('paginator', { read: ElementRef, static: false }) paginator: MatPaginator;

  dataSource: MatTableDataSource<IBLEDevice>;
  displayedColumns = ["ID", "RSSI", "RSSIf", "Dist"];

  description: string = "";
  title: string = "";
  loading: boolean = false;
  vs: IViewSpecs = ViewSpecs.getDefault();
  params: IBeaconScannerParams = null;
  loaded: boolean = false;
  np: INavParams = null;

  data: IBLEDeviceDict;
  dataList: IBLEDevice[] = [];
  format: boolean = true;
  appIconsStandard = EAppIconsStandard;

  subscription = {
    refresh: null
  };

  connectButtonLabel: string = "Connect";
  connected: boolean = false;
  reload: boolean = false;
  updateChart: boolean = false;
  estimate: IBeaconLocateResult;
  estimateCoords: ILatLng;
  chartData: IChartDataContainer = {
    data: []
  };

  hasData: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService,
    public indoorLocation: IndoorLocationManagerService
  ) { }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IBeaconScannerParams = np.params;
      this.params = params;
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);
      this.title = params.title;
      if (params.description) {
        this.description = params.description;
      }
      this.loaded = true;
    }
  }

  ngOnDestroy() {
    this.stopBeaconScanner();
    ResourceManager.clearSubObj(this.subscription);
  }

  toggleBeaconScanner() {
    if (!this.indoorLocation.isScannerRunning()) {
      this.startBeaconScanner();
    } else {
      this.stopBeaconScanner();
    }
  }

  startBeaconScanner() {
    if (!this.indoorLocation.isScannerRunning()) {
      this.indoorLocation.startWizard();
      this.setConnected(true);
      if (!this.subscription.refresh) {
        this.subscription.refresh = this.indoorLocation.watchUpdate().subscribe(() => {
          console.log("sync device stats");
          this.syncData(this.indoorLocation.devicesList);
          let estimate = this.indoorLocation.estimate;
          this.estimateCoords = this.indoorLocation.estimateCoords;

          // get bounds
          let bounds: IChartSeriesContainer = {
            name: "Beacons",
            series: []
          };

          bounds.series = this.indoorLocation.devicesList.map(dev => {
            if (dev.registered && dev.beaconSpecs) {
              let pos: IChartSeries = {
                x: dev.beaconSpecs.x,
                y: dev.beaconSpecs.y,
                z: dev.beaconSpecs.z,
                name: dev.id,
                value: dev.rssi,
                r: 5
              };
              return pos;
            } else {
              return null;
            }
          }).filter(dev => dev != null);

          let locate: IChartSeriesContainer = {
            name: "Location",
            series: []
          };

          if (estimate && estimate.coords) {
            this.estimate = estimate;
            locate.series = [
              {
                name: "Mobile",
                x: this.estimate.coords.x,
                y: this.estimate.coords.y,
                z: this.estimate.coords.z,
                value: 0,
                r: 10
              }
            ];
          }

          this.chartData.data = [bounds, locate];
          this.updateChart = !this.updateChart;

          console.log("chart data: ", this.chartData.data);
          this.hasData = true;

        }, (err) => {
          console.error(err);
        });
      }
    }
  }

  stopBeaconScanner() {
    if (this.indoorLocation.isScannerRunning()) {
      this.indoorLocation.stopWizard();
      this.setConnected(false);
      this.subscription.refresh = ResourceManager.clearSub(this.subscription.refresh);
    }
  }

  setConnected(connected: boolean) {
    if (connected) {
      this.connected = true;
      this.connectButtonLabel = "Disconnect";
    } else {
      this.connected = false;
      this.connectButtonLabel = "Connect";
    }
  }

  syncData(data: IBLEDevice[]) {
    this.dataList = data;
    if (this.format) {
      // dataList.forEach((e) => {
      //   if (!e.valueString) {
      //     e.valueString = MathUtils.formatNumericString(e.value, 1);
      //   }
      // });
    }
    console.log("sync data: ", this.dataList);
    // if (!this.dataSource) {
    //   this.dataSource = new MatTableDataSource<IBLEDevice>( this.dataList);
    //   // this.dataSource.paginator = this.paginator;
    // } else {
    //   this.dataSource.data =  this.dataList;
    //   this.dataSource.connect().next(this.dataList);
    // }
    this.reload = !this.reload;
  }

  trackByFn(_index: number, item: IBLEDevice) {
    // return index; // or item.id, if your image has an ID
    return item.id;
  }


  ok() {
    this.dismiss(null);
  }

  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  onSelect(event: any) {
    console.log("on select chart: ", event);
  }
}
