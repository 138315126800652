import { Injectable } from "@angular/core";
import { GeometryUtils } from "./geometry-utils";

@Injectable({
    providedIn: 'root'
})
export class ArrayFilters {

    arrayContent: { [key: string]: number[] } = {};
    arraySpec: { [key: string]: number } = {};

    constructor(

    ) {
        console.log("directions service created");
    }


    resetFilterAngle(key: string) {
        this.arrayContent[key] = [];
        this.arraySpec[key + "_sin"] = 0;
        this.arraySpec[key + "_cos"] = 0;
    }

    /**
    * @param key 
    * @param data 
    * @param N 
    * @returns 
    */
    lowPassFilterN(key: string, data: number, N: number) {

        if (!this.arrayContent[key]) {
            this.arrayContent[key] = [];
        }

        let ac: number[] = this.arrayContent[key];
        let sum: number = this.arraySpec[key];

        // queue add (push to end, take from start)
        let add = (val: number) => {
            sum += val;
            if (ac.length >= N) {
                let old: number = ac.shift();
                sum -= old;
            }
            ac.push(val);
        }

        let average = () => {
            let size = ac.length;
            let res = sum / size;
            return res;
        };

        add(data);
        return average();
    }

    /**
     * works with 0-359 angle
     * handles edge cases e.g. [350, 10, 340, 5, ..]
     * @param key 
     * @param data 
     * @param N 
     * @returns 
     */
    lowPassFilterAngle(key: string, data: number, N: number) {

        if (!this.arrayContent[key]) {
            this.arrayContent[key] = [];
        }
        if (this.arraySpec[key + "_sin"] == null) {
            this.arraySpec[key + "_sin"] = 0;
        }
        if (this.arraySpec[key + "_cos"] == null) {
            this.arraySpec[key + "_cos"] = 0;
        }

        let sumSin: number = this.arraySpec[key + "_sin"];
        let sumCos: number = this.arraySpec[key + "_cos"];
        let ac: number[] = this.arrayContent[key];

        let radians: number = GeometryUtils.degreesToRadians(data);

        // queue add (push to end, take from start)
        let add = (val: number) => {
            sumSin += Math.sin(val);
            sumCos += Math.cos(val);
            if (ac.length >= N) {
                let old: number = ac.shift();
                sumSin -= Math.sin(old);
                sumCos -= Math.cos(old);
            }
            ac.push(val);
            this.arraySpec[key + "_sin"] = sumSin;
            this.arraySpec[key + "_cos"] = sumCos;
        }

        let average = () => {
            let size = ac.length;
            let res = Math.atan2(sumSin / size, sumCos / size);
            return GeometryUtils.translateTo360(GeometryUtils.radiansToDegrees(res));
        };

        add(radians);
        return average();
    }

}