import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EImgIcons, EAppIcons } from 'src/app/classes/def/app/icons';
import { IBackendLocation } from 'src/app/classes/def/places/backend-location';
import { Util } from 'src/app/classes/general/util';
import { ILocationGameItem } from 'src/app/classes/def/items/game-item';
import { LocationUtilsWizard } from 'src/app/services/location/location-utils-wizard';

@Component({
  selector: 'place-items-card',
  templateUrl: './place-items-card.component.html',
  styleUrls: ['./place-items-card.component.scss'],
})
export class PlaceItemsCardComponent implements OnInit, OnDestroy {
  imgIcons = EImgIcons;
  appIcons = EAppIcons;

  place: IBackendLocation = null;
  photoUrl: string = null;
  photoLoaded: boolean = false;
  mapsUrl: string = null;
  placeType: string = "PLACE";
  placeName: string = "";
  descriptionHtml: string = null;
  placeUrl: string = null;

  @Input()
  locationItem: ILocationGameItem;

  @Input()
  location: IBackendLocation;

  @Input()
  crop: boolean;

  loaded: boolean = false;
  hasData: boolean = false;

  constructor(
    public locationUtlsWizard: LocationUtilsWizard
  ) {

  }

  ngOnInit() {
    this.formatPlace();
  }

  ngOnDestroy() {

  }

  openURL(url: string) {
    Util.openURLAdaptive(url);
  }

  goToReference() {
    if (this.placeUrl) {
      Util.openURL(this.placeUrl);
    }
  }

  /**
   * format place display
   */
  formatPlace() {
    let place: IBackendLocation = null;
    if (this.locationItem && this.locationItem.location) {
      // get via locationItem
      console.log("format location item data: ", this.locationItem);
      place = this.locationItem.location.merged;
    }
    if (this.location) {
      // get via location
      console.log("format location data: ", this.location);
      place = this.location;
    }

    if (place) {
      console.log("format location item data: ", this.locationItem);
      this.placeUrl = place.url;
      this.locationUtlsWizard.runThroughCache(place.photoUrl).then((extracted: string) => {
        // this.photoUrl = this.sanitizer.bypassSecurityTrustUrl(extracted) as any;
        this.photoUrl = extracted;
      });

      this.mapsUrl = Util.generateMapsLink(place.googleId, place.name);
      this.placeType = place.type;
      this.placeType = Util.formatPlaceType(this.placeType);
      if (place.registered) {
        this.placeType += " (verified)";
      }

      this.placeName = place.name;
      if (place.description) {
        this.descriptionHtml = place.description;
      }
      this.hasData = true;
    }

    this.loaded = true;
  }
}
