
import { Injectable } from "@angular/core";
import { EOS } from 'src/app/classes/def/app/app';
import { SleepUtils } from '../../utils/sleep-utils';
import { Platform } from '@ionic/angular';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { StatusBar, Style } from '@capacitor/status-bar';

@Injectable({
    providedIn: 'root'
})
export class WebviewUtilsService {

    enableViewReset: boolean = false;
    enableShiftView: boolean = false;
    usePlatformReady: boolean = false;

    constructor(
        private plt: Platform
    ) {
        console.log("webview utils service created");
    }

    ready() {
        console.log("check platform ready");
        if (this.usePlatformReady) {
            return this.plt.ready();
        } else {
            return Promise.resolve(true);
        }
    }

    setOverlayWebViewDefault() {
        if (GeneralCache.os === EOS.ios) {
            //GeneralCache.shiftView = false;
            this.setOverlayWebView(true);
        } else {
            // because there is a problem on Android 13 (bottom button drawer overlaps web view)
            // this.statusBar.overlaysWebView(false);
            // solved by adding <preference name="resizeOnFullScreen" value="false" /> in config.xml           
            this.setOverlayWebView(true);
        }
    }

    setOverlayWebView(enable: boolean) {
        StatusBar.setOverlaysWebView({ overlay: enable });
    }

    /**
     * fixes header overlap with status bar on native view return to webview on iOS
     */
    resetViewframe(shiftView: boolean, noDelay: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            try {
                if (!this.enableViewReset) {
                    resolve(true);
                    return;
                }

                console.log("reset view frame");
                if (GeneralCache.os === EOS.ios) {
                    console.log("shift view");
                    // this.statusBar.overlaysWebView(true);
                    // this.statusBar.overlaysWebView(false);
                    if (shiftView && this.enableShiftView) {
                        GeneralCache.shiftView = false;
                    }

                    this.setOverlayWebViewDefault();
                    if (!noDelay) {
                        await SleepUtils.sleep(200);
                    }
                    await StatusBar.hide();

                    this.setOverlayWebViewDefault();
                    if (!noDelay) {
                        await SleepUtils.sleep(200);
                    }
                    await StatusBar.show();
                    if (!noDelay) {
                        await SleepUtils.sleep(200);
                    }
                    this.setOverlayWebViewDefault();

                    if (shiftView && this.enableShiftView) {
                        GeneralCache.shiftView = true;
                    }
                    resolve(true);
                } else {
                    resolve(true);
                }
            } catch (err) {
                console.error(err);
                resolve(false);
            }
        });
        return promise;
    }
}




