import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeaderboardExtPage } from './leaderboard-ext.page';
import { PagesModule } from '../pages.module';

const routes: Routes = [
  {
    path: '',
    component: LeaderboardExtPage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [LeaderboardExtPage]
})
export class LeaderboardExtPageModule {}
