<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" [title]="title" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()"
    rightButton="settings" [rightIcon]="true" (rightClick)="options()" [loading]="!loaded"></leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <div class="view-frame theme-background">
    <!-- bg-filter-1 -->
    <div class="bg-container bg-container-fill"
      [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: photoUrlSource}">

      <div class="content">
        <div class="content-height no-scroll">

          <div fxLayout="column" class="scrollbar-y content-height">

            <!-- top section -->

            <ion-list class="padding-top-s" *ngIf="place">
              <ion-item class="list-item transparent-bg list-item-padding-top">
                <place-card class="full-w" [place]="place"></place-card>
              </ion-item>
            </ion-list>

            <!-- middle section -->

            <div class="text-div-margin text-color-overlay full-height" [ngClass]="{'margin-top-s': !place}">

              <div *ngIf="infoText" [innerHTML]="infoText"
                class="padding-s info-text-m text-color-primary description-frame text-center">
              </div>

              <div class="padding-s">
                <div *ngFor="let e of infoTextGraphics" class="list-item list-item-padding transparent-bg text-left">
                  <plain-entry-expand [e]="e" [fixed]="true" (action)="onTapSelectGraphic($event)"></plain-entry-expand>
                </div>
              </div>

              <div *ngIf="infoTextAux" [innerHTML]="infoTextAux"
                class="padding-s info-text-m text-color-primary description-frame text-left">
              </div>

              <div *ngIf="connecting" class="text-center">
                <span class="info-text-s bold text-color-primary">connecting...</span>
              </div>

              <div *ngIf="connecting" class="padding-top-s padding-bottom-s progress-bar-alternate">
                <mat-progress-bar mode="indeterminate" class="progress-rounded" color="primary">
                </mat-progress-bar>
              </div>

              <div class="center-text margin-top-s" *ngIf="connectedMeetingPlace">
                <span class="info-text-mlg bold text-color-primary">Lobby</span>
              </div>

              <ion-list class="list-padding" *ngIf="connectedMeetingPlace">
                <ion-item *ngFor="let p of nearbyPlayers; trackBy: trackByFn"
                  class="list-item list-item-padding transparent-bg list-item-padding-top">
                  <mp-status-card [player]="p" [update]="statusUpdate" [self]="isSelf(p)" tappable
                    (click)="selectNearbyPlayer(p)" class="full-w">
                  </mp-status-card>
                </ion-item>
              </ion-list>
            </div>

            <!-- bottom section -->

            <div fxFlex></div>
            <div>
              <ion-grid class="full-w">

                <!-- entry from menu/meeting place selection -->
                <!-- meeting place mode active -->
                <ion-row *ngIf="!selectedGroup">

                  <ion-col size="4">
                    <blink-button *ngIf="!connectedMeetingPlace" [disabled]="isMeetingPlaceDisabled() || connecting"
                      [icon]="appIcons.gpsFixed" [customIcon]="true" theme="accent"
                      (select)="connectMeetingPlaceModeWrapper(true)">
                    </blink-button>
                    <blink-button *ngIf="connectedMeetingPlace" [icon]="appIcons.gpsFixed" [customIcon]="true"
                      theme="warn" [disabled]="!checkCanExit() || connecting"
                      (select)="disconnectMeetingPlaceMode(true)">
                    </blink-button>
                  </ion-col>

                  <ion-col size="4">
                    <blink-button [disabled]="!canChat" icon="chatbox" theme="primary" [setTimeout]="chatBtnBlink"
                      (select)="showChatModal()"></blink-button>
                  </ion-col>

                  <ion-col size="4">
                    <ion-button class="action-button-fill button-color-alternate" [disabled]="mpLocked || lockedForUser"
                      (click)="viewGroups()">
                      <icon-wrapper slot="icon-only" icon="people"></icon-wrapper>
                    </ion-button>
                  </ion-col>

                </ion-row>

              </ion-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>