import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { ISignalData, IFrequencyDomainData } from 'src/app/classes/def/processing/signal';
import { BehaviorSubject } from 'rxjs';
import { NgxChartParams } from 'src/app/classes/general/params';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { INgxChartOptions, IChartDataContainer } from 'src/app/classes/def/app/charts';

@Component({
  selector: 'sound-button',
  templateUrl: './sound-button.component.html',
  styleUrls: ['./sound-button.component.scss'],
})
export class SoundButtonComponent implements OnInit, OnDestroy {
  @Input()
  data: ISignalData;

  @Input()
  update: BehaviorSubject<boolean>;

  @Output()
  action: EventEmitter<any> = new EventEmitter();


  init: boolean = false;

  polarData: IChartDataContainer = {
    data: []
  };

  updateChartSub: any;

  nc: number = 3;

  chartOptions: INgxChartOptions = NgxChartParams.getDefaultOptions();

  minTs: number = 30;
  currentTime: number = 0;
  showEQ: boolean = true;

  constructor(
    public uiext: UiExtensionService

  ) {
    this.chartOptions.animations = false;
    this.chartOptions.showLegend = false;
    this.chartOptions.showXAxisLabel = false;
    this.chartOptions.showYAxisLabel = false;
    this.chartOptions.showXAxis = false;
    this.chartOptions.showYAxis = false;
    this.chartOptions.yAxisMinScale = 100;
  }

  ngOnInit() {
    if (this.data) {
      this.createGraph(this.data);
      this.init = true;
      if (this.update) {
        this.updateChartSub = this.update.subscribe((up: boolean) => {
          if (up !== null) {
            this.updateGraph(this.data);
          }
        }, (err: Error) => {
          console.error(err);
        });
      }
    }
  }

  ngOnDestroy() {

  }

  onSelect(event: any) {
    this.action.emit(event);
  }


  createGraph(bpdata: ISignalData) {
    console.log(bpdata);

    this.polarData.data = [
      {
        name: "Raw",
        series: [

        ]
      },
      {
        name: "Filtered",
        series: [

        ]
      },
      {
        name: "Reference",
        series: [

        ]
      }
    ];

    this.polarData.data[0].series = [];
    // this.polarData.data[1].series = [];
    // this.polarData.data[2].series = [];


    for (let i = 0; i < this.nc; i++) {
      this.polarData.data[0].series.push({
        name: "1/" + i,
        value: 0
      });
    }

    this.init = true;
  }

  updateGraph(bpdata: ISignalData) {

    let update: boolean = false;
    if (this.minTs > 0) {
      let t1: number = new Date().getTime();
      if (t1 - this.currentTime > this.minTs) {
        this.currentTime = t1;
        update = true;
      }
    } else {
      update = true;
    }

    if (update) {
      if (bpdata) {
        let spdata: IFrequencyDomainData = bpdata.frequencyDomain;
        // if (spdata) {
        //     console.log(spdata.bass + ", " + spdata.mid + ", " + spdata.treble);
        // }

        if (this.showEQ && spdata) {
          if (spdata.soundBins.length > this.nc) {

            for (let i = 0; i < spdata.soundBins.length - this.nc; i++) {
              this.polarData.data[0].series.push({
                name: "1/" + this.nc + i,
                value: 0
              });
            }
            this.nc = spdata.soundBins.length;
            console.log("updated series: ", this.polarData.data[0].series);
          }
          // let dbg: string = "";

          for (let i = 0; i < spdata.soundBins.length; i++) {
            // dbg += spdata.soundBins[i].value + "\t";
            this.polarData.data[0].series[i].value = spdata.soundBins[i].value;
          }

          // console.log(dbg);

          // dynamic range from average fft power
          this.chartOptions.yAxisMinScale = spdata.scaleFilter;

        } else {
          for (let i = 0; i < this.nc; i++) {
            this.polarData.data[0].series[i].value = bpdata.timeDomain.shortFilter;
          }
        }

        // charts need an immutable object for change detection
        this.polarData.data = [...this.polarData.data];
      }
    }
  }
}
