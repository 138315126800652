import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { IGroupMember, EGroupRole } from 'src/app/classes/def/mp/groups';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { IMPUserCardOptionsNavParams } from 'src/app/classes/def/nav-params/arena';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { IItemAction } from 'src/app/classes/def/items/action';
import { EItemActions } from 'src/app/classes/def/items/game-item';
import { EPhotos, EAppIcons } from 'src/app/classes/def/app/icons';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { MpUserCardOptionsViewComponent } from 'src/app/modals/app/modals/mp-user-card-options/mp-user-card-options.component';
import { StringUtils } from 'src/app/services/app/utils/string-utils';

@Component({
  selector: 'mp-user-card',
  templateUrl: './mp-user-card.component.html',
  styleUrls: ['./mp-user-card.component.scss'],
})
export class MpUserCardComponent implements OnInit, OnDestroy {
  photoLoaded: boolean = false;
  scoreDisp: string = "";
  bgPhoto: string = null;
  hasPhoto: boolean = false;

  connecting: boolean = false;
  connected: boolean = false;

  timeout = null;

  init: boolean = false;

  enabledInSession: boolean = true;

  levelDisp: string = "";

  userName: string = "";
  roleName: string = "";

  isAdminCard: boolean = false;

  appIcons = EAppIcons;

  userClass: string = "info-text-xs bold text-color-overlay-s uppercase";

  self: boolean = false;
  selected: boolean = false;

  @Input()
  member: IGroupMember;
  // set member(member: IGroupMember) {
  //     this.member = member
  // }
  // get member(): IGroupMember { return this.member; }

  @Input()
  admin: boolean;

  @Output() removeMember = new EventEmitter<IGroupMember>();


  constructor(
    public uiext: UiExtensionService,
    public multiplayer: MPDataService,
    public analytics: AnalyticsService
  ) {

  }


  /**
  * check connected users periodically to display the connect icon
  */
  checkConnected() {
    this.timeout = setTimeout(() => {
      // console.log("check connected");
      this.formatMember();
      this.checkConnected();
    }, 1000);
  }

  ngOnInit() {
    // console.log(this.member);
    if (this.member) {
      this.formatMember();
      this.checkConnected();
      this.init = true;
    }
  }

  onCheck(ev: Event) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    ev.cancelBubble = true;
    ev.stopPropagation();
    // Doing other stuff here to control if checkbox should be checked or not!, or just let it be empty!
    // this.select.emit(this.group);
    this.showOptions();
    return (false);   // you not have to return false , depends of what you doing above like using alertController etc
  }


  ngOnDestroy() {
    this.timeout = ResourceManager.clearTimeout(this.timeout);
  }


  showOptions() {
    if (this.member) {
      let params: IMPUserCardOptionsNavParams = {
        member: this.member,
        isAdmin: this.admin
      };
      let navParams: INavParams = {
        view: {
          fullScreen: false,
          transparent: false,
          large: false,
          addToStack: true,
          frame: false
        },
        params: params
      };
      this.uiext.showCustomModal(null, MpUserCardOptionsViewComponent, navParams).then((data: IItemAction) => {
        if (data) {
          switch (data.code) {
            case EItemActions.buy:
              // set active for the current session (reset inactive)
              if (this.member.staticFlags) {
                this.member.staticFlags.enabled = true;
              }
              break;
            case EItemActions.drop:
              // set inactive for the current session
              if (this.member.staticFlags) {
                this.member.staticFlags.enabled = false;
              }
              break;
            case EItemActions.cancel:
              // remove member from the group
              this.multiplayer.removeMember(this.member.groupId, this.member.userId).then(() => {
                // emit member removed event
                console.log("removed member");
                this.removeMember.emit(this.member);
                this.member = null;
              }).catch((err: Error) => {
                console.error(err);
                this.analytics.dispatchError(err, "mp-user-card");
              });
              break;
          }
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  } 


  formatMember() {
    if (this.member) {
      if (!this.member.user.photoUrl) {
        this.bgPhoto = EPhotos.userPlaceholder;
      } else {
        this.bgPhoto = this.member.user.photoUrl;
        this.hasPhoto = true;
      }

      let score: number = this.member.user.cachedScore ? this.member.user.cachedScore : 0;
      this.scoreDisp = score + " XP";

      let level: number = this.member.user.cachedLevel ? this.member.user.cachedLevel : 0;
      this.levelDisp = "Level " + level;

      this.enabledInSession = false;

      this.userName = this.member.user.name;

      if (this.member.staticFlags) {
        switch (this.member.type) {
          case EGroupRole.leader:
            this.userClass = "info-text-xs bold text-color-overlay-s text-color-overlay-warn uppercase";
            // if (!this.member.staticFlags.self) {
            //     this.name += " (admin)";
            // }
            this.roleName = "host";
            break;
          case EGroupRole.member:
            this.userClass = "info-text-xs bold text-color-overlay-s uppercase";
            // if (!this.member.staticFlags.self) {
            //     this.name += " (member)";
            // }
            this.roleName = "member";
            break;
        }

        if (this.member.staticFlags.self) {
          // this.userName += " (you)";
          this.self = true;
        }

        if (this.member.staticFlags) {
          this.enabledInSession = this.member.staticFlags.enabled;
        }

        if (this.member.type === EGroupRole.leader) {
          this.isAdminCard = true;
        }
      }

      this.userName = StringUtils.trimNameMultilineHTML(this.userName, EMessageTrim.mpUserStatusCard);

      if (this.member.dynamic) {
        this.connected = this.member.dynamic.connected;
        if (!this.connected && this.enabledInSession) {
          this.connecting = true;
        } else {
          this.connecting = false;
        }
      }

    }
  }
}
