
import { ITimeoutMonitorData } from '../../general/timeout';
import { ILatLng } from '../map/coords';

export interface IQuestActivityStatus {
    status: number;
    tmData: ITimeoutMonitorData;
    /** percent */
    penalty: number;
    usedClue: boolean;
    usedMapClue: boolean;

    internal: {
        questValidated: boolean;
        questValidatedConfirmed: boolean;
    }
}

export enum EQuestAction {
    requireClue = 1,
    requireMapClue = 2
}

export enum EQuestDynamicType {
    streetName = 1
}

export enum EQuestType {
    text = 1,
    numeric = 2,
    select = 3,
    selectMulti = 4
}

export interface IQuestAction {
    action: number,
    data: any
}

export interface IQuestActionClue extends IQuestAction {
    data: string
}

export interface IQuestActionMapClue extends IQuestAction {
    data: ILatLng
}

export interface IQuestOptions {
    cluePenalty: number,
    mapCluePenalty: number
}