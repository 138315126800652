
import { ILeplaceTreasure, ITreasureSpec } from "../def/places/leplace";
import { MathUtils, IFormatDisp } from "../general/math";
import { IGameItem, EItemIapCodes, EItemCodes } from "../def/items/game-item";
import { ICustomParamForActivity } from "../def/core/custom-param";
import { AppConstants } from "../app/constants";
import { IExploreItemParams } from '../def/activity/explore';
import { GeneralCache } from '../app/general-cache';
import { EOS } from '../def/app/app';
import { DeepCopy } from "../general/deep-copy";


export class GameUtils {

    static checkExploreGameItems(params: IExploreItemParams, activeItems: IGameItem[]): IExploreItemParams {
        for (let i = 0; i < activeItems.length; i++) {
            let item: IGameItem = activeItems[i];
            let itemCode: number = GameUtils.getItemCode(activeItems[i].code);
            switch (itemCode) {
                case EItemCodes.coinMultiplier:
                    if (!params.fixedCoinCap) {
                        params.coinCap = GameUtils.getCoinCap(params.coinCap, item.value);
                    }
                    break;
                case EItemCodes.coinMagnet:
                    params.collectDistance = GameUtils.getCoinCollectDistance(item.value);
                    break;
                default:
                    break;
            }
        }
        return params;
    }

    static getCoinCollectDistance(coinMagnetValue: number): number {
        let collectDistance: number = AppConstants.gameConfig.collectDistance;
        if (coinMagnetValue) {
            collectDistance = Math.floor(collectDistance + collectDistance * coinMagnetValue / 100);
        }
        return collectDistance;
    }

    static getCoinCap(initialCap: number, doubleCoinsValue: number): number {
        let coinCap: number = initialCap;
        if (doubleCoinsValue) {
            coinCap = Math.floor(coinCap + coinCap * doubleCoinsValue / 100);
        }
        return coinCap;
    }

    /**
     * get coin specs defined in the activity custom params
     * only works for (returns) a single type
     * @param customParams 
     */
    static getCoinSpecs(customParams: ICustomParamForActivity[]): ITreasureSpec[] {
        console.log("get coin specs: ", customParams);
        if (customParams && (customParams.length > 0)) {
            return customParams.filter(c => (c != null) && (c.treasureSpec != null)).map(c => {
                c.treasureSpec.customParamCode = c.code;
                c.treasureSpec.customParamValue = c.value;
                c.treasureSpec.customParamReference = DeepCopy.deepcopy(c);
                return c.treasureSpec;
            });
        }
        return null;
    }

    /**
    * get coin value defined in the activity custom params
    * returns average coin value
    * @param customParams 
    */
    static getAverageCoinValue(customParams: ICustomParamForActivity[]): number {
        if (customParams && (customParams.length > 0)) {
            let value: number = 0;
            let count: number = 0;
            for (let cparam of customParams) {
                if (cparam.value != null) {
                    value += cparam.value;
                    count += 1;
                }
            }
            if (count > 0) {
                value = value / count;
            } else {
                value = 0;
            }
            return value;
        }
        return null;
    }

    /**
     * get reward based on the amount of LP (or item equivalent in LP) collected
     * and the standard reward cap associated to the activity/story/etc (add)
     * @param collectedLP 
     * @param standardRewardCap 
     */
    static getRewardLP(collectedLP: number, standardRewardCap: number): number {
        let rewardCoins: number = Math.floor(collectedLP * Math.random());
        rewardCoins += Math.floor(standardRewardCap * Math.random());
        if (rewardCoins === 0) {
            rewardCoins = 1;
        }
        return rewardCoins;
    }

    static getItemCode(dbCode: number): number {
        let itemCode: number = dbCode;
        if (dbCode >= 10000) {
            // the item is encoded as
            // category(n)/itemCode(2)/level(2)
            itemCode = Math.floor(dbCode / 100);
        } else {
            // the item is encoded as
            // category(n)/itemCode(2)
            // for compatibility
            itemCode = dbCode;
        }
        console.log("item code: ", itemCode);
        return itemCode;
    }

    static getDefaultObjectUid(id: number, code: number): string {
        return "LOCAL/" + code + "/" + id;
    }

    static getTreasureTooFarMessage(tm: ILeplaceTreasure): string {
        let actualDistance: string = tm.dynamic.distanceFromUser ? MathUtils.formatDistanceDisp(tm.dynamic.distanceFromUser).value : null;
        let range: IFormatDisp = MathUtils.formatDistanceDisp(AppConstants.gameConfig.itemEnableDistance);
        let rangeDistance: string = range.value;
        let notInRangeSub: string = "Move closer to reveal the content (" + (actualDistance ? actualDistance + "/" : "") + rangeDistance + " " + range.unit + ")";
        return notInRangeSub;
    }

    /**
     * check if the item has true IAP enabled and well defined
     * @param item 
     */
    static checkIAP(item: IGameItem): boolean {
        if (!item) {
            return false;
        }
        if (item.itemIap) {
            if (item.itemIap.price && item.itemIap.playStoreCode) {
                return true;
            }
            if (item.itemIap.priceIos && item.itemIap.appStoreCode) {
                return true;
            }
        }
        return false;
    }

    /**
     * get price 
     * check OS
     * @param item 
     */
    static getPriceIAP(item: IGameItem): string {
        let price: string = null;
        if (item.itemIap) {
            switch (GeneralCache.checkPlatformOS()) {
                case EOS.ios:
                    price = item.itemIap.priceIos;
                    break;
                case EOS.android:
                default:
                    price = item.itemIap.price;
                    break;
            }
        }
        return price;
    }

    /**
     * check if the item has special IAP enabled e.g. admin request
     * @param item 
     */
    static checkSpecialIAP(item: IGameItem): boolean {
        if (item.itemIap) {
            if (item.itemIap.code === EItemIapCodes.adminRequest) {
                return true;
            }
        }
        return false;
    }
}
