<ng-template #template>
    <ng-content></ng-content>
</ng-template>

<div class="text-left padding-horizontal-m padding-vertical-s">
    <span class="text-color-primary label-text-m ion-label-input bold">{{label}}</span>
</div>

<ion-item
    class="list-item transparent-bg item-noborder padding-bottom-s center-input-container input-wrapper-item-none">

    <!-- <ion-label class="text-color-primary label-text-m ion-label-input">{{label}}</ion-label> -->
    <!-- <code-input [ngClass]="ios ? 'input-code-ios' : 'input-code'" [isCodeHidden]="false" [isCharsCode]="!numeric"
        [codeLength]="dim" [autocapitalize]="autocapsMode" [inputType]="inputType" (codeChanged)="onCodeChanged($event)"
        [(code)]="_prop" (codeCompleted)="onCodeCompleted($event)" (codeFocus)="onCodeFocused(true)"
        (codeBlur)="onCodeFocused(false)" [disabled]="_disabled" ngDefaultControl>
    </code-input> -->

    <!-- [maxlength]="dim" -->
    <ion-input type="text" spellcheck="false" class="text-color-primary info-text-xxl input-code-wrapper uppercase"
        [ngStyle]="{'background': backgroundStyle}" (ionBlur)="onCodeFocused(false)" (keyup.enter)="sendMsg()"
        (ionFocus)="onCodeFocused(true)" name="code" id="code" custom-maxlength [charMaxLength]="dim" [maxlength]="dim"
        [(ngModel)]="_prop" [disabled]="_disabled" placeholder="" (ionChange)="onCodeChangedNoLag($event)">
    </ion-input>

</ion-item>