export enum EMapShapes {
    marker = 0,
    circle = 1,
    polyline = 2,
    polygon = 3
}

export enum EMarkerTypes {
    plain = 2,
    canvasFrame = 1,
    canvasPlain = 0,
    canvasPlainCenter = 100
}

export enum EMarkerScope {
    place = 0,
    auxPlace = 1,
    item = 2,
    auxItem = 3
}

/**
 * lower value is higher priority
 * UPDATE: Higher zIndex value overlays will be drawn on top of lower zIndex value tile layers and overlays.
 */
export enum EMarkerPriority {
    circle = 1,
    directions = 2,

    coin = 3,
    waypoint = 4,  

    // world map
    treasure = 5,
    story = 6,
    arena = 6,
    challenge = 6,
    otherTreasure = 6,

    auxPlace = 7,
    place = 8,

    otherUser = 9,
    user = 10,
    drone = 11
}

export enum EMarkerRenderPriority {   
    user = 1,
    drone = 1,
    otherUser = 7,
    otherUserDrone = 8,
    place = 9,
    auxPlace = 10,
    coin = 11,
    waypoint = 12,
    story = 13,
    challenge = 14,
    arena = 15,
    treasure = 16
}

export interface ISetThisMarkerOptions {
    zindex: number;
    size: number;
    color?: string;
    unlockLimiter?: boolean;
}
