<div *ngIf="loaded" class="flex-card item-card bg-container bg-container-fill item-border-radius"
  tappable [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data.photoUrl}" (click)="showDescription()">
  <div class="content" fxLayout="column">
    <ion-grid class="full-w grid-center-absolute">
      <ion-row>
        <ion-col size="12" class="text-div-container">
          <div class="info-text-xs bold text-color-overlay-s" *ngIf="showLabel" [innerHtml]="itemName"></div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>