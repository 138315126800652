import { Injectable } from "@angular/core";

import { SettingsManagerService } from '../settings-manager';
import { Observable, Subscription, timer } from 'rxjs';
import { BackgroundModeWatchService } from './background-mode-watch';
import { LocalNotificationsService, ELocalNotificationId } from './local-notifications';
import { NotificationsService } from './notifications';
import { LocationManagerService } from '../../map/location-manager';
import { TextToSpeechService } from './tts';
import { Messages } from 'src/app/classes/def/app/messages';
import { BackgroundModeService } from './background-mode';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { ETutorialEntries } from "src/app/classes/def/app/tutorials";
import { MQTTService } from "../../telemetry/mqtt.service";
import { EMQTTStatusKeys } from "../../telemetry/def";

/**
 * watch with external service calls
 */
@Injectable({
    providedIn: 'root'
})
export class BackgroundModeWatchControllerService {
    timerSub: Subscription;
    timeoutLevelsMinutes: number[] = [20, 25, 29, 30];
    timeoutLevelsRem: number[] = [10, 5, 1, 0];
    timeoutLevelIndex: number = 0;

    timeoutIntervalCounter: number = 0;
    timeoutIntervalReminder: number = 10;

    timeoutLevelScaler: number = 1;
    disableBackgroundModeTimeout: boolean = false;

    constructor(
        public backgroundModeWatch: BackgroundModeWatchService,
        public bgm: BackgroundModeService,
        public localNotifications: LocalNotificationsService,
        public notifications: NotificationsService,
        public locationManager: LocationManagerService,
        public mqttService: MQTTService,
        public tts: TextToSpeechService
    ) {
        console.log("background mode watch controller service created");
        this.initSubscriber();
    }


    initSubscriber() {
        // watch app going to background (pause/resume only)
        this.backgroundModeWatch.getBackgroundWatch().subscribe((paused: boolean) => {
            if (paused != null) {
                console.log("background watch: ", paused);
                if (paused) {
                    this.onPausedAction();
                } else {
                    this.onResumedAction();
                }
                this.mqttService.updateStatus(EMQTTStatusKeys.backgroundMode, { value: paused });
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    setExtendedBackgroundTimer() {
        this.timeoutLevelIndex = 0;
        this.timeoutLevelScaler = 2;
    }

    resumeDefaultBackgroundTimer() {
        this.timeoutLevelIndex = 0;
        this.timeoutLevelScaler = 1;
    }


    onPausedAction() {
        if (this.bgm.isBgModeEnabled()) {
            console.log("onPausedAction");
            // start timeout inactive watch
            let timer1: Observable<number> = timer(0, 60 * 1000);
            // let timer1 = timer(0, 1000);
            let counter: number = 0;
            this.timerSub = timer1.subscribe(() => {
                counter += 1;
                if (!this.disableBackgroundModeTimeout) {
                    if (counter >= (this.timeoutLevelsMinutes[this.timeoutLevelIndex] * this.timeoutLevelScaler)) {
                        this.timeoutLevelIndex += 1;
                        if (this.timeoutLevelIndex >= this.timeoutLevelsMinutes.length) {
                            this.timerSub = ResourceManager.clearSub(this.timerSub);
                            this.locationManager.pauseWatchPosition().then(() => {
                                console.log("disabled location: bgm paused");
                                // pause bg mode (deactivate)
                                this.bgm.pause();
                            });
                        } else {
                            // show notification
                            let msg: string = "Did you forget the app in background?";
                            let timeoutRem: number = this.timeoutLevelsRem[this.timeoutLevelIndex - 1] * this.timeoutLevelScaler;
                            if (timeoutRem > 0) {
                                msg += " Closing in " + (this.timeoutLevelsRem[this.timeoutLevelIndex - 1] * this.timeoutLevelScaler) + " min";
                            } else {
                                msg += " Closing soon!";
                            }
                            this.localNotifications.notify(Messages.notification.backgroundModeTimeout.after.msg, msg, false, ELocalNotificationId.warn);
                        }
                    }
                    this.timeoutIntervalCounter += 1;
                    // emit "app is running in background mode" every 10 min at full volume
                    if (this.timeoutIntervalCounter >= this.timeoutIntervalReminder) {
                        this.timeoutIntervalCounter = 0;
                        this.tts.textToSpeechQueue(Messages.tts.bgModeDefault, false, null, null, null, false);
                    }
                }
            }, (err: Error) => {
                console.error(err);
            });

            // handle notifications with tts
            // check bg mode enabled, allow watch position
            if (!SettingsManagerService.settings.app.settings.backgroundMode.value) {
                this.locationManager.pauseWatchPosition().then(() => {
                    console.log("disabled location: not authorized for background location");
                });
            } else {

                let first: boolean = !this.tts.checkAlreadyPlayed(ETutorialEntries.localTtsBackgroundMode);

                if (!first) {
                    this.tts.textToSpeechQueue(Messages.tts.bgModeDefault, false, null, null, null, false);
                } else {
                    this.tts.speakUsingLoaderCode(ETutorialEntries.localTtsBackgroundMode, ((GeneralCache.os === EOS.android) && !GeneralCache.enforceBackgroundLocationPermissionRequestOnAndroid) ? Messages.tts.bgModeFirstNoLocation : Messages.tts.bgModeFirst, true, true, null, null, null);
                }

                let useExternalNotification: boolean = SettingsManagerService.settings.app.settings.useExternalBgModeNotification.value;
                let hiddenNotification: boolean = SettingsManagerService.settings.app.settings.useHiddenBgModeNotification.value;

                if (GeneralCache.os === EOS.ios) {
                    if (first) {
                        this.localNotifications.notify(Messages.notification.backgroundModeFirst.after.msg, Messages.notification.backgroundModeFirst.after.sub, true, ELocalNotificationId.warn);
                    } else {
                        this.localNotifications.notify(Messages.notification.backgroundMode.after.msg, Messages.notification.backgroundMode.after.sub, true, ELocalNotificationId.warn);
                    }
                } else {
                    if (useExternalNotification && hiddenNotification) {
                        this.localNotifications.setPersistentNotification(Messages.notification.backgroundMode.after.msg, Messages.notification.backgroundMode.after.sub, true, ELocalNotificationId.persistentWarn, null);
                    }
                }
            }
        }
    }

    onResumedAction() {
        console.log("onResumedAction");
        // clear timeout watch
        this.timerSub = ResourceManager.clearSub(this.timerSub);
        this.timeoutLevelIndex = 0;
        // clear notifications
        // resume watch position if bg mode enabled
        this.localNotifications.clearLocal();
        this.notifications.clearOSNotifications();

        if (!GeneralCache.appFlags.locationPermissionsAlwaysGranted) {
            // reset location watch (work around internal issue that makes the app not receive further location updates once resumed from bg mode)
            this.locationManager.resetWatchLocationCheck(true);
        } else {
            // resume if paused (deactivated due to timeout)
            this.locationManager.resumeWatchPosition();
        }

        // resume bg mode if enabled
        if (this.bgm.isBgModeEnabled()) {
            this.bgm.resume();
        }
    }

}
