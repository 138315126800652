
import { Injectable } from '@angular/core';
import { Messages } from 'src/app/classes/def/app/messages';
import { UiExtensionService } from '../ui/ui-extension';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { AppConstants } from 'src/app/classes/app/constants';
import { AdsService } from './ads';
import { SettingsManagerService } from '../settings-manager';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { GameUtils } from 'src/app/classes/utils/game-utils';
import { IShareMsgParams, IShareMsg, MessageUtils, EShareMsgFeatures } from 'src/app/classes/utils/message-utils';
import { PromiseUtils } from '../../utils/promise-utils';

export interface IAdHandlerContext {
    watchCounter: number;
    rewardCounter: number;
    watchEnabled: boolean;
    scanQREnabled: boolean;
    loading: boolean;
    triggerCooldown: boolean;
    collectedLP: number;
    rewardCap: number;
    rewardLP: number;
    rewardIssued: boolean;
    shareContext: number;
    shareParams: IShareMsgParams;
    shareMessage: string;
    infoHTML: string;
}

/**
 * single scope (no multiple views at the same time using ads handler service)
 */
@Injectable({
    providedIn: 'root'
})
export class AdHandlerService {

    adContext: IAdHandlerContext = null;
    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        private uiext: UiExtensionService,
        private adService: AdsService,
        private settingsProvider: SettingsManagerService
    ) {
        console.log("ads handler service created");

        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    initContext() {
        this.adContext = {
            watchCounter: 0,
            rewardCounter: 0,
            watchEnabled: true,
            scanQREnabled: false,
            loading: false,
            triggerCooldown: false,
            collectedLP: 0,
            rewardLP: 0,
            rewardCap: 0,
            rewardIssued: false,
            shareContext: EShareMsgFeatures.itemFound,
            shareParams: null,
            shareMessage: null,
            infoHTML: null
        };
    }

    updateContext(shareParams: IShareMsgParams, shareMessage: string, infoHTML: string, collectedLP: number, rewardCap: number) {
        this.adContext.shareParams = shareParams;
        this.adContext.shareMessage = shareMessage;
        this.adContext.infoHTML = infoHTML;
        this.adContext.collectedLP = collectedLP;
        this.adContext.rewardCap = rewardCap;
    }

    clearContext() {
        this.adContext = null;
    }

    getContext() {
        return this.adContext;
    }

    setContext(adContext: IAdHandlerContext) {
        this.adContext = adContext;
    }


    playAndContinue(callback: () => any) {
        // this.stopCloseTimeout();
        if (!this.adContext) {
            console.warn("null context");
            return;
        }
        let showAd: Promise<boolean>;
        if (this.adContext.rewardLP) {
            showAd = new Promise((resolve) => {
                this.uiext.showAlert(Messages.msg.rewardAlreadyIssued.before.msg, Messages.msg.rewardAlreadyIssued.before.sub, 2, ["dismiss", "ok"], true).then((res: number) => {
                    if (res === EAlertButtonCodes.ok) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                })
            });
        } else {
            showAd = Promise.resolve(true);
        }

        let onWatchRetry = () => {
            this.adContext.watchCounter += 1;
            if (this.adContext.watchCounter >= AppConstants.gameConfig.maxReviewAdCounter) {
                this.adContext.watchCounter = AppConstants.gameConfig.maxReviewAdCounter;
                this.adContext.watchEnabled = false;
            }
        };

        showAd.then((show: boolean) => {
            if (show) {
                if (this.platform.WEB) {
                    this.adContext.loading = true;
                    this.adContext.loading = false;
                    this.adContext.triggerCooldown = !this.adContext.triggerCooldown;
                    onWatchRetry();
                    this.onRewardAction(callback);
                    return;
                }
                this.adContext.loading = true;
                this.adService.showRewardVideo(false, true).then((reward: boolean) => {
                    this.adContext.loading = false;
                    this.adContext.triggerCooldown = !this.adContext.triggerCooldown;
                    onWatchRetry();
                    console.log("watch reward resolved to ", reward);
                    if (reward) {
                        this.onRewardAction(callback);
                    } else {

                    }
                }).catch((err: Error) => {
                    console.error(err);
                    this.adContext.triggerCooldown = !this.adContext.triggerCooldown;
                    this.adContext.loading = false;
                    onWatchRetry();
                    this.onRewardAction(callback);
                });
            }
        });
    }


    onRewardAction(callback: () => any) {
        if (!this.adContext) {
            console.warn("null context");
            return;
        }
        this.adContext.rewardIssued = true;
        // earn random reward relative to the initial coins

        let multiplierBase: number = this.adContext.rewardCap;
        if (!multiplierBase) {
            multiplierBase = 1;
        }

        let rewardLP: number = GameUtils.getRewardLP(this.adContext.collectedLP, multiplierBase * this.adContext.rewardCounter);
        this.adContext.rewardCounter += 1;

        if (this.adContext.rewardCounter >= AppConstants.gameConfig.maxReviewAdCounter) {
            this.adContext.rewardCounter = AppConstants.gameConfig.maxReviewAdCounter;
        }

        if (this.adContext.shareParams.items) {
            this.adContext.shareParams.items.reward = rewardLP;
        }

        if (rewardLP) {
            PromiseUtils.wrapNoAction(this.uiext.showRewardPopupQueue("+" + rewardLP + " LP", null, null, false), true);
        }

        let shareMessage: IShareMsg = MessageUtils.buildMessage(this.adContext.shareContext, this.adContext.shareParams);
        this.adContext.shareMessage = shareMessage.share;
        this.adContext.infoHTML = shareMessage.auxHTML;
        this.adContext.rewardLP = rewardLP;
        if (callback != null) {
            callback();
        }
    }
}