

import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
import { Component, Input, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AppConstants } from 'src/app/classes/app/constants';
import { IEventDetails, EEventType, IEventLeaderboard, IEventDetailsResponse, IEventGroupDetailsResponse, IEventCheckStartAvailableResponse, EEventCheckStartAvailableCode, IEventItem } from 'src/app/classes/def/events/events';
import { ILeplaceReg } from 'src/app/classes/def/places/google';
import { IEventListNavParams } from 'src/app/classes/def/events/nav-params';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { EStandardCode, EStandardStoryCode } from 'src/app/classes/def/core/activity';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { IGmapEntryNavParams, EGmapMode } from 'src/app/classes/def/nav-params/gmap';
import { IModalEvent, EModalEmitter } from 'src/app/classes/def/app/modal-events';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { ILeaderboardNavParams, ELeaderboardScope } from 'src/app/classes/def/nav-params/leaderboard';
import { LeaderboardPage } from '../../leaderboard/leaderboard.page';
import { StoryDataService } from 'src/app/services/data/story';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { ModularViewsService } from 'src/app/services/utils/modular-views';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { EventsDataService } from 'src/app/services/data/events';
import { BusinessDataService } from 'src/app/services/data/business';
import { ModularInteractionService } from 'src/app/services/utils/modular-interaction';
import { LocalDataService } from 'src/app/services/data/local';
import { MiscDataService } from 'src/app/services/data/misc';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { ERouteDef } from 'src/app/app-utils';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { IButtonCollection, IButtonOptions, EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { IInventoryDetailNavParams, EInventoryDetailMode } from 'src/app/classes/def/nav-params/inventory';
import { InventoryDetailPage } from '../../inventory/inventory-detail/inventory-detail.page';
import { QRService } from 'src/app/services/general/apis/qr';
import { EventsJoinPage } from '../events-join/events-join.page';
import { IEventsJoinNavParams, IEventsJoinReturnParams } from 'src/app/classes/def/nav-params/events';
import { IGroupRole } from 'src/app/classes/def/mp/groups';
import { IListSelectorItem, IListSelectorParams } from 'src/app/classes/def/dynamic/list';
import { ListSelectorViewComponent } from 'src/app/modals/generic/modals/list-selector/list-selector.component';
import { TeamStatsPage } from '../../groups/team-stats/team-stats.page';
import { ITeamStatsNavParams } from 'src/app/classes/def/nav-params/teams';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { Router } from '@angular/router';
import { LinksDataService } from 'src/app/services/data/links';
import { IEventStoryGroupLinkData } from 'src/app/classes/def/core/links';
import { EModalEvents, Events } from 'src/app/services/utils/events';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { ILatLng } from 'src/app/classes/def/map/coords';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';


interface IButtonCollectionViewMain extends IButtonCollection {
  stats: IButtonOptions;
  inventory: IButtonOptions;
  start: IButtonOptions;
}

interface IButtonsViewMain {
  buttons: IButtonCollectionViewMain;
}


interface IEventsHomeButtonOptions {
  main: IButtonsViewMain;
}

enum EEventsApiLevel {
  standard = 1,
  // custom map and treasures
  custom = 2
}


// @DisableSideMenu()
@Component({
  selector: 'app-events-home',
  templateUrl: './events-home.page.html',
  styleUrls: ['./events-home.page.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0
      })),
      state('active', style({
        // transform: 'translateY(0)',
        opacity: 1
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class EventsHomePage implements OnInit, OnDestroy {

  event: IEventDetails;
  eventId: number = 0;
  title: string = "Event";

  eventProgress: number = 0;

  inProgress: boolean = false;

  finished: boolean = false;
  loaded: boolean = false;
  saved: boolean = false;

  reload: boolean = true;

  entryPlace: ILeplaceReg = null;
  locationURL: string = null;

  params: IEventListNavParams;
  coords: ILatLng;

  apiLevel: number = EEventsApiLevel.standard;

  appIcons = EAppIcons;

  showState: string = "inactive";
  timeout = null;

  theme: string = "theme-light theme-light-bg";

  photoUrl: string = "";
  photoLoaded: boolean = false;
  animate: boolean = true;
  registered: boolean = false;
  registeredGroupIds: number[] = [];
  groupId: number = null;
  eventGroupRole: number = null;

  lockedState: boolean = false;

  np: INavParams = null;
  vs: IViewSpecs;
  headerClass: string = "";
  isModal: boolean = false;
  appIconsStandard = EAppIconsStandard;

  viewContainer: IEventsHomeButtonOptions = {
    main: {
      buttons: {
        stats: {
          name: null,
          icon: "stats",
          class: "action-button-fill button-color-alternate",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.viewLeaderboard(this.event.eventLeaderboards[0]);
          },
          code: null
        },
        inventory: {
          name: null,
          icon: EAppIcons.backpack,
          class: "action-button-fill button-color-alternate",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.viewEventItems();
          },
          code: null
        },
        join: {
          name: null,
          icon: "person-add",
          class: "action-button-fill button-color-alternate",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.joinEvent();
          },
          code: null
        },
        start: {
          name: null,
          icon: "map",
          class: "action-button-fill button-color-accent",
          size: 1,
          disabled: true,
          enabled: false,
          highlight: false,
          callback: () => {
            this.joinEvent();
          },
          code: null
        }
      }
    }
  };

  @Input()
  public showChild: boolean = true;

  constructor(
    public eventDataProvider: EventsDataService,
    public router: Router,
    public storyDataProvider: StoryDataService,
    public modalCtrl: ModalController,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public analytics: AnalyticsService,
    public locationManager: LocationManagerService,
    public popoverCtrl: PopoverController,
    public backButton: BackButtonService,
    public modularViews: ModularViewsService,
    public resourcesProvider: ResourcesCoreDataService,
    public businessDataProvider: BusinessDataService,
    public settingsProvider: SettingsManagerService,
    public modularInteraction: ModularInteractionService,
    public localDataProvider: LocalDataService,
    public miscProvider: MiscDataService,
    public events: Events,
    public popupFeatures: PopupFeaturesService,
    public tutorials: TutorialsService,
    public nps: NavParamsService,
    public qrSecurity: QRService,
    public links: LinksDataService
  ) {


  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  swipeEvent(e) {
    this.backButton.handleSwipeEvent(e);
  }

  ngOnInit() {
    this.uiext.disableSidemenu();
    this.analytics.trackView("events-home");
    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.events, null, this.np);
      console.log("nav params: ", npInfo.params);
      let hasParams: boolean = npInfo.hasParams;
      if (hasParams) {
        let np: INavParams = npInfo.params;
        console.log(np);

        if (np && np.params) {
          this.params = np.params;
        }
        this.vs = np.view;
        this.isModal = this.vs ? this.vs.isModal : false;

        this.eventId = this.params.eventId;
        this.reload = this.params.reload;
        this.entryPlace = this.params.entryPlace;

        if (this.params.event) {
          this.apiLevel = this.params.event.apiLevel;
        }

        if (!this.reload || this.isModal) {
          this.animate = false;
          this.showState = 'active';
        }
      } else {
        this.eventId = 0;
      }

      // can be opened either as a modal or a root page
      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back(true);
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });

      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
          // load story data
          this.locationManager.getCurrentLocationWrapper(true, true, true).then((coords: ILatLng) => {
            this.coords = coords;
            this.loadEventWrapper();
          }).catch((err: Error) => {
            console.error(err);
            this.loadEventWrapper();
          });
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  ngOnDestroy() {
    this.clearTimers();
    this.uiext.enableSidemenu();
    this.backButton.checkPop(this.vs);
  }

  loadEventWrapper() {
    this.loadEventFromSource(this.eventId).then(() => {
      if (this.animate) {
        this.showState = "active";
      }
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "events-home");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }

  /**
   * load story from the backend or local storage
   * @param eventId 
   */
  loadEventFromSource(eventId: number) {
    let promise = new Promise((resolve, reject) => {
      this.loaded = false;

      let eventDataPromise: Promise<IEventDetailsResponse>;
      if (this.apiLevel === EEventsApiLevel.standard) {
        eventDataPromise = this.eventDataProvider.getDetails(eventId);
      } else {
        eventDataPromise = this.eventDataProvider.getDetailsV2(eventId);
      }
      eventDataPromise.then((ed: IEventDetailsResponse) => {
        console.log(ed);

        this.registered = ed.registered;

        if (this.apiLevel !== EEventsApiLevel.standard) {
          this.registeredGroupIds = ed.registeredGroupIds;
          this.groupId = ed.registeredGroupIds[0];
        }

        this.links.setEventGroupLinkData(this.getGroupLinkData());

        this.viewContainer.main.buttons.start.disabled = !this.registered;

        this.loadEventCore(ed.event);
        this.loaded = true;
        resolve(true);
      }).catch((err: Error) => {
        this.loaded = true;
        reject(err);
      });
    });
    return promise;
  }

  getStandardPhoto() {
    this.resourcesProvider.getStandardPhotoByCode(EStandardCode.story + EStandardStoryCode.locked).then((photoUrl: string) => {
      this.photoUrl = photoUrl;
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  loadEventCore(event: IEventDetails) {
    if (event !== null) {
      this.photoUrl = event.photoUrl;
      // get progress (time span)
      let t1: number = new Date(event.startDate).getTime();
      let t2: number = new Date(event.endDate).getTime();
      let duration: number = t2 - t1;

      let timeNow: number = new Date().getTime();
      // let timeNow: number = new Date(event.startDate).getTime() + duration / 2;

      this.eventProgress = 0;
      if (timeNow > t1) {
        this.eventProgress = (timeNow - t1) / duration * 100;
      }

      let btn: IButtonOptions;

      if (!event.eventItems) {
        this.viewContainer.main.buttons.inventory.disabled = true;
      } else {
        for (let i = 0; i < event.eventItems.length; i++) {
          let item = event.eventItems[i];
          if (item && item.item) {
            item.item.peek = 1;
          }
        }
      }

      if (!event.publicLeaderboard) {
        this.viewContainer.main.buttons.stats.disabled = false;
        // show team stats (private) only
        this.viewContainer.main.buttons.stats.callback = () => {
          this.viewTeamStats();
        };
      } else {
        this.viewContainer.main.buttons.stats.callback = () => {
          this.viewLeaderboard(this.event.eventLeaderboards[0]);
        };
      }

      if (this.registered) {
        switch (event.type) {
          case EEventType.freestyle:
            btn = this.viewContainer.main.buttons.start;
            btn.name = "Start";
            btn.class = "action-button-fill button-color-accent";
            btn.icon = "map";
            btn.callback = () => {
              this.startEventFreestyle();
            };
            break;
          case EEventType.story:
            btn = this.viewContainer.main.buttons.start;
            btn.name = "Start";
            btn.class = "action-button-fill button-color-accent";
            btn.icon = "bookmarks";
            btn.callback = () => {
              this.startEventStory();
            };
            break;
          case EEventType.challenge:
            btn = this.viewContainer.main.buttons.start;
            btn.name = "Start";
            btn.class = "action-button-fill button-color-accent";
            btn.icon = "trophy";
            btn.callback = () => {
              this.startEventChallenge();
            };
            break;
          case EEventType.custom:
            btn = this.viewContainer.main.buttons.start;
            btn.name = "Start";
            btn.class = "action-button-fill button-color-accent";
            btn.icon = "map";
            btn.callback = () => {
              this.startEventCustomMap();
            };
            break;
        }
      } else {
        btn = this.viewContainer.main.buttons.start;
        btn.name = "Register";
        btn.class = "action-button-fill button-color-accent";
        btn.icon = "play";
        btn.callback = () => {
          this.joinEvent();
        };
      }
    }
    this.event = event;

    console.log(event);
  }

  clearTimers() {
    ResourceManager.clearTimeout(this.timeout);
    this.timeout = null;
  }

  private getGroupLinkData() {
    let groupLinkData: IEventStoryGroupLinkData = {
      storyId: null,
      eventId: this.eventId,
      groupId: this.groupId,
      eventGroupRole: this.eventGroupRole,
      registeredGroupIds: this.registeredGroupIds
    };
    return groupLinkData;
  }

  getOpenMapParams() {
    let params: IEventListNavParams = {
      eventId: this.eventId,
      reload: this.reload,
      loadEvent: true,
      links: this.getGroupLinkData(),
      event: null,
      useMp: false
    };
    return params;
  }


  /**
  * view group roles
  * select or preview
  * @param groupId 
  */
  viewGroupRoles(groupId: number) {
    let promise = new Promise((resolve, reject) => {
      this.eventDataProvider.getRoles(this.eventId, groupId).then((roles: IGroupRole[]) => {
        // this.useCache = true;
        // wrap items to pass to the group role card component
        let rolesContainer = roles.map((role) => {
          let listItem: IListSelectorItem = {
            selected: false,
            available: role.available,
            item: role,
            inputName: "groupRole"
          };
          // set current group role for registered group

          let isRegistered: boolean =
            this.registeredGroupIds &&
            (this.registeredGroupIds.indexOf(groupId) !== -1) || (this.groupId === groupId);

          if (role.code === this.eventGroupRole && isRegistered) {
            listItem.selected = true;
          }
          return listItem;
        });

        let params: IListSelectorParams = {
          title: "Select a role",
          componentType: "groupRole",
          listItems: rolesContainer,
          direct: false,
          customSelect: false
        };

        console.log(params);

        this.uiext.showCustomModal(null, ListSelectorViewComponent, {
          view: {
            fullScreen: false,
            transparent: false,
            large: true,
            addToStack: true,
            frame: false
          },
          params: params
        }).then((data: IListSelectorItem) => {
          console.log(data);
          if (data && data.item) {
            let role: IGroupRole = data.item;
            this.eventDataProvider.setGroupRole(this.groupId, role.code).then(() => {
              resolve(data);
            }).catch((err: Error) => {
              reject(err);
            });
          } else {
            reject(new Error("role not set"));
          }
        }).catch((err: Error) => {
          reject(err);
        });
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }


  /**
   * open map with open challenge option/story/freestyle (world map)
   */
  openMap(mode: number) {
    // check event start available 
    this.eventDataProvider.checkStartAvailable(this.eventId, this.groupId).then((resp: IEventCheckStartAvailableResponse) => {
      let promiseMsg: Promise<boolean>;
      if (!resp.available) {
        promiseMsg = new Promise((resolve, reject) => {
          switch (resp.code) {
            case EEventCheckStartAvailableCode.roleRequired:
              // open role selector
              // select role
              this.viewGroupRoles(this.groupId).then((data: any) => {
                if (data) {
                  let groupRole: IGroupRole = data.groupRole;
                  if (groupRole != null) {
                    this.eventGroupRole = groupRole.code;
                    resolve(true);
                  } else {
                    resolve(false);
                  }
                } else {
                  resolve(false);
                }
              }).catch((err: Error) => {
                reject(err);
              });
              break;
            default:
              this.uiext.showAlert(Messages.msg.eventStartNotAvailable.after.msg, resp.message, 2, null).then((res: number) => {
                switch (res) {
                  case EAlertButtonCodes.ok:
                    resolve(true);
                    break;
                  default:
                    resolve(false);
                    break;
                }
              }).catch((err: Error) => {
                console.error(err);
                resolve(false);
              });
              break;
          }
        });
      } else {
        promiseMsg = Promise.resolve(true);
      }

      promiseMsg.then((start: boolean) => {

        if (start) {

          if (this.animate) {
            this.showState = "inactive";
          }

          this.timeout = setTimeout(() => {
            if (this.isModal) {
              this.dismissModal(false, true);
            } else {

              let params: IGmapEntryNavParams = {
                mode: mode
              };

              let eparams: IEventListNavParams;

              switch (mode) {
                case EGmapMode.eventChallenge:
                  eparams = this.getOpenMapParams();
                  params.eventNavParams = eparams;
                  break;
                case EGmapMode.customMap:
                  eparams = this.getOpenMapParams();
                  params.eventNavParams = eparams;
                  break;
                case EGmapMode.worldMap:
                default:
                  break;
              }

              let promiseMp: Promise<boolean>;

              promiseMp = new Promise((resolve) => {
                this.uiext.showAlert(Messages.msg.shareLocationTeam.before.msg, Messages.msg.shareLocationTeam.before.sub, 2, ["No", "Yes"], true).then((res: number) => {
                  switch (res) {
                    case EAlertButtonCodes.ok:
                      resolve(true);
                      break;
                    default:
                      resolve(false);
                      break;
                  }
                }).catch((err: Error) => {
                  console.error(err);
                  this.analytics.dispatchError(err, "gmap");
                  resolve(false);
                });
              });

              promiseMp.then((useMp: boolean) => {

                if (params.eventNavParams) {
                  params.eventNavParams.useMp = useMp;
                }

                let navParams: INavParams = {
                  view: null,
                  params: params
                };


                this.nps.set(ENavParamsResources.gmap, navParams);
                this.router.navigate([ERouteDef.gmap], { replaceUrl: true }).then(() => {
                }).catch((err: Error) => {
                  console.error(err);
                });
              });

            }
          }, this.animate ? AppConstants.animationTime : 0);
        }
      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "events-home");
      });
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "events-home");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
    });
  }


  /**
   * back to map, no action
   * @param animate 
   */
  back(animate: boolean) {
    let params: IEventListNavParams = {
      eventId: this.eventId,
      reload: false,
      loadEvent: false,
      event: null,
      links: this.getGroupLinkData(),
      useMp: false
    };

    if (this.isModal) {
      let modalEvent: IModalEvent = {
        emitter: EModalEmitter.storyDetailBack
      };
      this.events.publish(EModalEvents.dismiss, modalEvent);
      this.dismissModal(animate, false);
    } else {
      let navParams: INavParams = {
        view: null,
        params: params
      };

      this.nps.set(ENavParamsResources.events, navParams);

      this.router.navigate([ERouteDef.eventsList], { replaceUrl: true }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  /**
   * return from modal
   * load story flag shows if the map should load the current story
   * @param animate 
   * @param loadStory 
   */
  dismissModal(_animate: boolean, loadEvent: boolean) {
    let params: IEventListNavParams = {
      eventId: this.eventId,
      reload: false,
      loadEvent: loadEvent,
      event: null,
      links: this.getGroupLinkData(),
      useMp: false
    };
    this.modalCtrl.dismiss(params).then(() => {

    }).catch(() => {
    });
  }



  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      showDetails: {
        name: "Tutorial",
        code: 3,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      },
      reload: {
        name: "Reload",
        code: 2,
        icon: EAppIconsStandard.refresh,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: true,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((code: number) => {
      switch (code) {
        case 2:
          this.reload = true;
          this.loadEventWrapper();
          break;
        case 3:
          this.onHelpClick();
          break;
        case 4:
          this.joinEventQR();
          break;
        case 5:
          this.joinEventQRAuto();
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  /**
   * join event
   * select group and role
   */
  joinEventQR() {

  }


  /**
   * join event
   * scan the QR code provided for the group
   * select role
   */
  joinEventQRAuto() {

  }



  onHelpClick() {
    this.tutorials.showTutorialNoAction("Event Tutorial", ETutorialEntries.eventHome, null, null, true);
  }


  joinEventV1() {
    this.eventDataProvider.joinEvent(this.event.id).then((load: boolean) => {
      if (load) {
        this.loadEventWrapper();
      }
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "event-details");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
    });
  }

  joinEventV2() {
    if (this.event.qrRegistration === 1) {
      // require QR registration and role selection
      // group assignment is done automatically via QR codes
      let params: IEventsJoinNavParams = {
        mode: ELeaderboardScope.event,
        reload: true,
        event: this.event,
        registered: this.registered
      };

      this.uiext.showCustomModal(null, EventsJoinPage, {
        view: {
          fullScreen: true,
          transparent: false,
          large: true,
          addToStack: false,
          frame: false
        },
        params: params
      }).then((res: IEventsJoinReturnParams) => {
        console.log(res);
        if (!res) {
          // cancel join event - nop
        } else {
          if (res.join) {
            this.joinEventV2Core(res).then(() => {
              // now the user has joined the event
              // the start button is enabled
            }).catch((err: Error) => {
              console.error(err);
              this.analytics.dispatchError(err, "events-home");
              this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
            });
          }
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  /**
   * handle QR registration 
   * scan and send QR for joining event
   */
  joinEventV2Core(res: IEventsJoinReturnParams) {
    let promise = new Promise((resolve, reject) => {
      // scan QR code to join the event
      this.qrSecurity.scanQRResultString(false, "Join event").then((qrCode: string) => {
        // join the event with current selection
        this.eventDataProvider.joinEventQR(this.event.id, res.selectedGroupId, res.selectedRoleCode, qrCode).then(() => {
          // joined event, now the game can start
          this.viewContainer.main.buttons.start.disabled = false;

          this.eventGroupRole = res.selectedRoleCode;

          this.links.setEventGroupLinkData(this.getGroupLinkData());

          this.uiext.showAlert(Messages.msg.joinedEvent.after.msg, Messages.msg.joinedEvent.after.sub, 1, null).then(() => {
            this.reload = true;
            this.loadEventWrapper();
            resolve(true);
          }).catch((err: Error) => {
            console.error(err);
            resolve(true);
          });
        }).catch((err: Error) => {
          reject(err);
        });
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  viewSelectedGroup() {
    let promise = new Promise((resolve, reject) => {
      this.eventDataProvider.viewUserGroupDetails(this.eventId, this.groupId).then((res: IEventGroupDetailsResponse) => {
        this.eventGroupRole = res.groupRoleCode;
        this.links.setEventGroupLinkData(this.getGroupLinkData());
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  joinEvent() {
    if (this.apiLevel === EEventsApiLevel.standard) {
      this.joinEventV1();
    } else {
      this.joinEventV2();
    }
  }


  startEventFreestyle() {
    console.log("start event freestyle");
    this.openMap(EGmapMode.worldMap);
  }

  startEventChallenge() {
    console.log("start event challenge");
    this.openMap(EGmapMode.eventChallenge);
  }

  startEventCustomMap() {
    console.log("start event custom map");
    this.viewSelectedGroup().then(() => {
      this.openMap(EGmapMode.customMap);
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "events-home");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
    });
  }

  startEventStory() {
    console.log("start event story");
    // this.openMap(EGmapMode.story);
  }

  viewTeamStats() {
    // increment story count only if not already in progress 

    if (!this.registered) {
      this.uiext.showAlertNoAction(Messages.msg.groupNotSelected.after.msg, Messages.msg.groupNotSelected.after.sub);
      return;
    }

    let params: ITeamStatsNavParams = {
      links: this.getGroupLinkData(),
      categoryCode: null,
      categoryTabs: null,
      label: "Team stats"
    };

    let navParams: INavParams = {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: false
      },
      params: params
    };

    this.uiext.showCustomModal(null, TeamStatsPage, navParams).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  viewLeaderboard(elb: IEventLeaderboard) {

    // increment story count only if not already in progress 
    if (this.animate) {
      this.showState = "inactive";
    }

    if (!elb) {
      elb = this.event.eventLeaderboards[0];
    }

    let params: ILeaderboardNavParams = {
      scope: ELeaderboardScope.event,
      reload: true,
      lb: elb,
      mpCard: false,
      eventListNavParams: this.params
    };

    this.timeout = setTimeout(() => {
      if (this.isModal) {
        this.uiext.showCustomModal(null, LeaderboardPage, {
          view: {
            fullScreen: true,
            transparent: false,
            large: true,
            addToStack: false,
            frame: false
          },
          params: params
        }).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
      } else {
        let navParams: INavParams = {
          view: null,
          params: params
        };

        this.nps.set(ENavParamsResources.leaderboard, navParams);

        this.router.navigate([ERouteDef.leaderboard], { replaceUrl: true }).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
      }
    }, this.animate ? AppConstants.animationTime : 0);
  }


  viewEventItems() {
    this.eventDataProvider.getInventoryV2(this.eventId).then((eventItems: IEventItem[]) => {
      let params: IInventoryDetailNavParams = {
        // items: this.event.eventItems.map(e => e.item),
        items: eventItems.map(e => e.item),
        mode: EInventoryDetailMode.placeSales,
        enableLockedDescriptions: true,
        heading: this.event.name
      };
      this.uiext.showCustomModal(null, InventoryDetailPage, {
        view: {
          fullScreen: true,
          transparent: false,
          large: true,
          addToStack: true,
          frame: true
        },
        params: params
      }).then(() => {

      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "events-home");
        this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
      });
    }).catch((err: Error) => {
      console.error(err);
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
    });
  }
}

