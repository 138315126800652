import { Component, OnInit, OnDestroy } from '@angular/core';
import { IPopoverActions, ICheckboxFrameStatus, IPopoverAction } from 'src/app/classes/def/app/modal-interaction';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { ParamHandler } from 'src/app/classes/general/params';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { DeepCopy } from 'src/app/classes/general/deep-copy';

@Component({
  selector: 'modal-checkbox-frame',
  templateUrl: './checkbox-frame.component.html',
  styleUrls: ['./checkbox-frame.component.scss'],
})
export class CheckboxFrameViewComponent implements OnInit, OnDestroy {
  actions: IPopoverActions = {};
  actionsInit: IPopoverActions = {};
  title: string = "";
  rightBtnIcon: string = EAppIconsStandard.close;
  vs: IViewSpecs = ViewSpecs.getDefault();
  selectAll: boolean = false;
  selectAllEnabled: boolean = true;
  appIcons = EAppIcons;

  actionsList: IPopoverAction[] = [];

  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params = np.params;
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);
      this.init(params.actions);
      this.actions = params.actions;
      this.title = params.title;


    }
  }

  ngOnDestroy() {

  }

  init(actions: IPopoverActions) {
    let keys: string[] = Object.keys(actions);
    this.actionsInit = DeepCopy.deepcopy(actions);
    for (let i = 0; i < keys.length; i++) {
      this.actionsList.push(actions[keys[i]]);
    }
    this.selectAll = this.checkAllSelected(actions);
  }

  /**
   * check if all items are selected
   */
  checkAllSelected(actions: IPopoverActions) {
    let allSelected: boolean = true;
    let keys: string[] = Object.keys(actions);
    for (let i = 0; i < keys.length; i++) {
      if (!actions[keys[i]].enabled) {
        allSelected = false;
      }
    }
    return allSelected;
  }

  /**
   * check for updated items
   * compare with the initial values
   */
  checkUpdates() {
    let updates: boolean = false;
    let keys: string[] = Object.keys(this.actions);
    console.log("check updates: ", this.actions);
    console.log("against: ", this.actionsInit);
    for (let i = 0; i < keys.length; i++) {
      if (this.actions[keys[i]] && this.actionsInit[keys[i]]) {
        if (this.actions[keys[i]].enabled !== this.actionsInit[keys[i]].enabled) {
          updates = true;
          break;
        }
      }
    }
    console.log("updated: ", updates);
    return updates;
  }

  onChange(_event) {
    // console.log("change select: ", _event);
    if (this.checkUpdatesBtnText()) {
    }
  }

  onSelectAll(_event) {
    // console.log("change select all: ", _event);
    let keys: string[] = Object.keys(this.actions);
    for (let i = 0; i < keys.length; i++) {
      this.actions[keys[i]].enabled = this.selectAll;
    }
    this.checkUpdatesBtnText();
  }

  checkUpdatesBtnText() {
    let update: boolean = this.checkUpdates();
    if (update) {
      // this.selectAll = this.checkAllSelected();
      this.rightBtnIcon = EAppIconsStandard.check;
    } else {
      this.rightBtnIcon = EAppIconsStandard.close;
    }
    return update;
  }

  dismiss() {
    let returnParams: ICheckboxFrameStatus = {
      update: this.checkUpdates(),
      status: this.actions
    };

    setTimeout(() => {
      this.modalCtrl.dismiss(returnParams).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }
}
