export interface IAppMessage {
    before?: {
        msg: string,
        sub: string
    },
    after?: {
        msg: string,
        sub: string
    }
}

export interface IAppMessageGroup {
    [key: string]: IAppMessage
}

export interface IAppSimpleMessageGroup {
    [key: string]: string
}

export class Messages {
    public static notification = {
        activityFinished: {
            after: {
                msg: "Game",
                sub: "Challenge completed"
            }
        },
        activityFailed: {
            after: {
                msg: "Game",
                sub: "Challenge failed"
            }
        },
        checkNavDirections: {
            after: {
                msg: "Nav",
                sub: "Check navigation directions"
            }
        },
        destinationReached: {
            after: {
                msg: "Nav",
                sub: "Destination reached"
            }
        },
        destinationRevealed: {
            after: {
                msg: "Nav",
                sub: "Destination revealed"
            }
        },
        searchZoneReached: {
            after: {
                msg: "Nav",
                sub: "Search zone reached"
            }
        },
        checkpointReached: {
            after: {
                msg: "Nav",
                sub: "Checkpoint reached"
            }
        },
        stashFound: {
            after: {
                msg: "Game",
                sub: "Hidden stash found"
            }
        },
        backgroundMode: {
            after: {
                msg: "App",
                sub: "Running in background mode"
            }
        },
        backgroundModeFirst: {
            after: {
                msg: "App",
                sub: "Running in background mode. Please allow background location permissions always, to ensure optimal experience."
            }
        },
        backgroundModeTimeout: {
            after: {
                msg: "App",
                sub: "Running in background mode"
            }
        },
        previewMode: {
            after: {
                msg: "App",
                sub: "You are in story preview mode. Open the map to start the game."
            }
        }
    };

    public static mp = {
        init: "<p>Welcome to MP lobby</p><p>Here you can connect with other players and start multiplayer team-based games</p>",
        initNoMp: "<p>Welcome to MP lobby</p>",

        leaderInit: "<p>INIT</p><p>Connect after the others have joined the team</p>",
        leaderReady: "<p>READY</p><p>Please wait for the others to start</p><p>You will be able to join the game once all team members are connected</p>",

        leaderSetAndTappedStart: "<p>WAIT</p><p>Please wait for the other apps to be in sync</p>",
        leaderSet: "<p>SET</p><p>Tap start to let the game begin</p>",

        memberInit: "<p>INIT</p><p>Connect to start the game</p><p>The host will join the game once all team members are connected</p>",
        memberReady: "<p>READY</p><p>The game will start as soon as the host is ready</p>",

        memberSet: "<p>SET</p><p>The app is now ready to join the game</p>",

        memberSetGo: "<p>START</p><p>The app will now connect to the game</p>",

        gameOn: "<p>GO</p><p>The game is online</p>",
        leaderHandleMemberDisconnected: `<p>EXCEPTION</p><p>A member has disconnected</p><p>(1) Wait for the connection to resume</p>
        <p>(2) Remove the member from the current game</p><p>(3) Exit MP and start a new session</p>`,
        memberHandleLeaderDisconnected: "<p>EXCEPTION</p><p>The host has disconnected</p><p>(1) Wait for the connection to resume</p>(2) Exit MP game</p>",

        selectChallenge: "Pick a challenge on the map to start the game",
        waitForChallenge: "Wait for the host to start the game",

        teamMemberFinishedChallenge: "Progress synced with team",
        mapSyncInProgress: "Map sync in progress",

        // create (private / public) team

        createGroupPrivate: `
        <p>Create a new private team</p>
        <p>Others can join your private team using the team ID or QR code</p>
        <p>Once the team is ready, you may start the game</p>`,

        createGroupPrivateHost: `
        <p>You are the team host</p>
        <p>Others can join your private team using the team ID or QR code</p>
        <p>Once the team is ready, you may start the game</p>`,

        createGroupPublic: `
        <p>Create a new public team</p>
        <p>Others can join your public team from the lobby (or by using the team ID or QR code)</p>
        <p>Once the team is ready, you may start the game</p>
        <p>Note: Public teams expire automatically. Once you connect to the game, the team will instead become private after the expiration date</p>`,

        createGroupPublicHost: `
        <p>You are the team host</p>       
        <p>Others can join your public team from the lobby (or by using the team ID or QR code)</p>
        <p>Once the team is ready, you may start the game</p>
        <p>Note: Public teams expire automatically. Once you connect to the game, the team will instead become private after the expiration date</p>`,

        // join (private / public) team

        joinGroupPrivate: `
        <p>Join a private team</p>
        <p>Request the team ID from the host or scan the QR code to join the team</p>`,

        joinGroupPrivateMember: `
        <p>You are already a member</p>
        <p>Tap on the button to join the session</p>
        <p>The game will start as soon as the host is ready</p>`,

        joinGroupPublic: `
        <p>Join a public team</p>
        <p>Tap on the button to join</p>
        <p>Note: Public teams expire automatically. Once you connect to the game, the team will instead become private after the expiration date</p>`,

        joinGroupPublicMember: `
        <p>You are already a member</p>
        <p>Tap on the button to join the session</p>
        <p>The game will start as soon as the host is ready</p>
        <p>Note: Public teams expire automatically. Once you connect to the game, the team will instead become private after the expiration date</p>`,

        // chat only team

        createGroupChat: `
        <p>You are the team host</p>
        <p>Here you can chat with your team</p>
        <p>Others can join your team using the QR code or team ID</p>
        <p>To start a game, you have to visit a Meeting Place on the map</p>`,

        joinGroupChat: `
        <p>You are already a member</p>
        <p>Here you can chat with your team</p>
        <p>To join game, you have to visit a Meeting Place on the map</p>`,
    };

    public static tts = {
        // typos may be there for correct pronounciation
        destinationReached: "You have reached your destination.",
        proceedToTheNextLocation: "Please proceed to the next location and be aware of your surroundings.",
        welcomeToWorldMap: "Welcome to world map! Please wait for the content to load.",
        welcomeToWorldMapStoryMode: "Welcome to world map! The story will start now. Please wait for the content to load.",
        errorLoadingResources: "There was an error loading some resources. Please check your data connection.",
        networkDisconnected: "Network disconnected. Please check your data connection.",
        GPSNotAvailable: "G.P.S. signal not available. Please check your location settings and be aware that local conditions might have an effect on location accuracy.",
        searchZoneReached: "Search zone, reached!",
        checkpointRevealed: "Checkpoint revealed!",
        checkNavDirections: "Check navigation directions!",
        checkpointReached: "Checkpoint reached!",
        achievementsUnlocked: "You have unlocked new rewards.",
        challengeComplete: "Challenge complete!",
        challengeFailed: "Challenge failed!",
        verifiedPlaces: "There are verified places nearby that might offer special discounts.",
        bgModeDefault: "The app is running in background mode.",
        // bgModeFirst: "The app is running in background mode. Please allow background location permissions always, to ensure optimal experience, and be aware that the app is still using battery.",
        bgModeFirstNoLocation: "The app is running in background mode. You may continue the navigation in the background, and be aware that the app is still using battery.",
        bgModeFirst: "The app is running in background mode. You may continue the navigation in the background, and be aware that the app is still using battery.",
        previewMode: "You are in story preview mode. Open the map to start the game.",
    };

    public static toast = {
        proceedToTheNextLocation: "Go to the place shown on the map",
        beAware: "Remember to be aware of your surroundings",
        isPreview: "<p>Checkpoint preview mode</p><p>Reach the location to start the challenge</p>",
        isPreviewFromStoryline: "<p>Checkpoint preview mode</p><p>Start the interactive story to enable the challenge</p>",
        isPreviewFromMapOverview: "<p>You are in preview mode</p><p>Return to map to continue the interactive story</p>",
        isDoneFromGmapLinear: "<p>Checkpoint already finished</p><p>Return to map to continue the interactive story</p>",
        isDoneFromGmapNonlinear: "<p>Checkpoint already finished</p><p>You may try again for higher score</p>",
        isSkippedFromGmapLinear: "<p>Checkpoint skipped</p><p>You have to restart the story to try again</p>",
        isSkippedFromGmapNonlinear: "<p>Checkpoint skipped</p><p>You may try again for higher score</p>",
        tooFarAway: "<p>You are too far away</p>"
    };

    public static msg = {
        success: {
            after: {
                msg: "Success",
                sub: ""
            }
        },
        joinedEvent: {
            after: {
                msg: "Joined",
                sub: "Let the treasure hunting begin"
            }
        },
        removeGroupMember: {
            before: {
                msg: "Remove member",
                sub: "This will remove the member from the team"
            }
        },
        walkToThePlace: {
            before: {
                msg: "Proceed",
                sub: "Go to the place shown on the map"
            }
        },
        loadIapDetails: {
            before: {
                msg: "Store products",
                sub: "Load and display store product details?"
            }
        },
        moveTargetMoveComplete: {
            after: {
                msg: "Status update",
                sub: "Capture the target to complete the challenge"
            }
        },
        runTargetExploreComplete: {
            after: {
                msg: "Status update",
                sub: "Run the target distance to complete the challenge"
            }
        },
        walkTargetExploreComplete: {
            after: {
                msg: "Status update",
                sub: "Walk the target distance to complete the challenge"
            }
        },
        removePhoto: {
            after: {
                msg: "Info",
                sub: "Photo removed"
            }
        },
        photoNotAvailableGen: {
            after: {
                msg: "Info",
                sub: "Photo not available"
            }
        },
        photoNotAvailable: {
            after: {
                msg: "Info",
                sub: "You don't have a profile photo at the moment"
            }
        },
        platformNotSupported: {
            after: {
                msg: "Unknown platform",
                sub: "<p>Your device is not supported.</p><p>The app might not work as expected.</p>"
            }
        },
        iPadDetected: {
            after: {
                msg: "Platform",
                sub: "<p>It seems you are using an iPad.</p><p>Please be aware that the app is optimized for iPhones.</p>"
            }
        },
        waitForGroupConnect: {
            after: {
                msg: "Connecting",
                sub: "Please wait until everybody is connected"
            }
        },
        exit: {
            before: {
                msg: "Exit app",
                sub: ""
            },
        },
        joinOrSelectGroup: {
            before: {
                msg: "Lobby",
                sub: "Join new team or select from your list"
            },
        },
        joinSelectedGroup: {
            before: {
                msg: "Team lobby",
                sub: "Join this team first to connect"
            },
        },
        worldMapUpdated: {
            after: {
                msg: "Success",
                sub: "World Edit registered"
            }
        },
        info: {
            after: {
                msg: "Info",
                sub: ""
            }
        },
        questPreviewMode: {
            after: {
                msg: "Preview",
                sub: "You are in preview mode. You have to start the challenge to submit the answer."
            }
        },
        storyPreviewMode: {
            after: {
                msg: "Preview Locked",
                sub: "<p>You are in preview mode</p><p>Return to map to continue the interactive story</p>"
            }
        },
        questAlreadyValidated: {
            after: {
                msg: "Checked",
                sub: "Response already checked. Please proceed to complete the challenge"
            }
        },
        gpsNotAvailableRestartDevice: {
            after: {
                msg: "Location issues",
                sub: "<p>Uh oh! GPS location could not be acquired.</p><p>You may need to restart the app and/or device and try again.</p><p>Note: GPS signal is usually not available indoors.</p>"
            }
        },
        gpsNotAvailableCanSkip: {
            after: {
                msg: "Location issues",
                sub: "<p>Uh oh! GPS location could not be determined.</p><p>Are you already in the location, but it's not showing up on the map?</p><p>Don't worry! You can skip to the challenge.</p><p>Later you might want to check your mobile location and data connection.</p>"
            }
        },
        storyInProgress: {
            before: {
                msg: "Story in progress",
                sub: "Continue or change story"
            }
        },
        passwordChanged: {
            after: {
                msg: "Info",
                sub: "Password changed"
            }
        },
        accountValidated: {
            after: {
                msg: "Info",
                sub: "Account validated"
            }
        },
        referralCodeValidated: {
            after: {
                msg: "R-Code",
                sub: ""
            }
        },
        featureUnlocked: {
            after: {
                msg: "Unlocked",
                sub: "Feature available in the game"
            }
        },
        featureLocked: {
            after: {
                msg: "Locked",
                sub: "Proceed to unlock"
            }
        },
        featureLockedTemp: {
            after: {
                msg: "Locked",
                sub: "<p>Not available in this context</p>Check location, app version, release date</p>"
            }
        },
        featureLockedAtTheMoment: {
            after: {
                msg: "Locked",
                sub: "This feature is not available at the moment"
            }
        },
        referralCodeUsed: {
            after: {
                msg: "R-Code",
                sub: "<p>Thanks for using the R-Code to register</p><p>Your friend will receive a bonus</p>"
            }
        },
        requiresRestartBgMode: {
            after: {
                msg: "Restart required",
                sub: "<p>To ensure that the background mode works properly this change requires restarting the app.</p><p>Please restart the app or reset to the previous setting.</p>"
            }
        },
        returnToMapAfterRequiredItemBought: {
            after: {
                msg: "Got it",
                sub: "Return to world map"
            }
        },
        returnToMapAfterRequiredItemActivated: {
            after: {
                msg: "Activate",
                sub: "Return to world map"
            }
        },
        pauseOrStopStory: {
            before: {
                msg: "Game paused",
                sub: "Quit story"
            }
        },
        exitGroupChat: {
            before: {
                msg: "Exit session",
                sub: "You can connect later from a Meeting Place"
            }
        },
        pauseOrStopStoryFromStoryline: {
            before: {
                msg: "Game paused",
                sub: "Return to storyline"
            }
        },
        usernameChanged: {
            after: {
                msg: "Info",
                sub: "Username changed"
            }
        },
        scanEnergyLow: {
            after: {
                msg: "Scanner",
                sub: "Energy low. Please recharge soon"
            }
        },
        scanEnergyDepleted: {
            after: {
                msg: "Scanner",
                sub: "Please recharge your energy"
            }
        },
        isDroneOnly: {
            before: {
                msg: "Virtual Drone",
                sub: "<p>This is a virtual drone-only story. Tap on the drone button to begin.</p>"
            }
        },
        exitDroneMode: {
            before: {
                msg: "Land drone",
                sub: "Return to home"
            }
        },
        exitDroneModeOnly: {
            before: {
                msg: "Land drone",
                sub: "Continue from here"
            }
        },
        jumpToMeetingPlace: {
            before: {
                msg: "Virtual drone mode",
                sub: "Continue from starting point instead of your current location"
            }
        },
        rechargeDrone: {
            before: {
                msg: "Recharge",
                sub: "<p>Drone battery empty</p><p>Please recharge</p>"
            }
        },
        droneRecharged: {
            after: {
                msg: "Recharge complete",
                sub: "<p>Drone battery recharged</p><p>Continue flying</p>"
            }
        },
        saveTour: {
            before: {
                msg: "Record",
                sub: "Save current tour"
            }
        },
        hiddenPlace: {
            after: {
                msg: "Hidden",
                sub: "You have to find the place first"
            }
        },
        goToFeature: {
            before: {
                msg: "Feature shortcut",
                sub: "Go"
            }
        },
        submitWithoutReview: {
            after: {
                msg: "Review",
                sub: "Submit rating without review"
            }
        },
        accountDeleted: {
            after: {
                msg: "Info",
                sub: "Acount deleted"
            }
        },
        newInbox: {
            after: {
                msg: "Newsfeed",
                sub: "<p>You have new messages in the Newsfeed</p>"
            }
        },
        mpSelectChallengeLeader: {
            after: {
                msg: "MP",
                sub: "<p>Pick a challenge on the map to start a game</p>"
            }
        },
        mpChallengeRejected: {
            after: {
                msg: "MP",
                sub: "<p>The game was rejected by a team member</p><p>You may start another game</p>"
            }
        },
        mpSelectChallengeMember: {
            after: {
                msg: "MP",
                sub: "<p>Wait for the host to start a game</p>"
            }
        },
        treasureNotInRange: {
            after: {
                msg: "You are too far away",
                sub: "Get closer to reveal the item"
            }
        },
        treasureNotAvailable: {
            after: {
                msg: "Locked",
                sub: "This item is not available at the moment"
            }
        },
        treasureLocked: {
            after: {
                msg: "Locked",
                sub: "This item is locked at the moment"
            }
        },
        itemLocked: {
            after: {
                msg: "Locked",
                sub: "This item has to be unlocked first"
            }
        },
        itemLockedIap: {
            after: {
                msg: "Locked",
                sub: "<p>This item is currently unavailable for purchase.</p><p>Please contact support if you have pending orders.</p>"
            }
        },
        coinsPurchased: {
            after: {
                msg: "Account updated",
                sub: "LP has been added to your account"
            }
        },
        feedbackReceived: {
            after: {
                msg: "Thank you",
                sub: "Your feedback has been registered"
            }
        },
        treasureLockedForMember: {
            after: {
                msg: "Locked",
                sub: "Wait for the host to start a game"
            }
        },
        treasureLockedForMp: {
            after: {
                msg: "Locked",
                sub: "This item is not available in multiplayer mode"
            }
        },
        photoUploaded: {
            after: {
                msg: "Info",
                sub: "Photo uploaded"
            }
        },
        discardReview: {
            before: {
                msg: "Review",
                sub: "Discard review"
            }
        },
        updatePhotoNotAllowed: {
            before: {
                msg: "Update photo",
                sub: "You have to reach the checkpoint to upload a new photo"
            }
        },
        updatePhoto: {
            before: {
                msg: "Update photo",
                sub: "This will reset your current photo upload"
            }
        },
        addProfilePhoto: {
            after: {
                msg: "Profile photo",
                sub: "Do you want to add a profile photo now?"
            }
        },
        addProfilePhotoExt: {
            after: {
                msg: "Profile photo",
                sub: "Do you want to use your existing signup photo?"
            }
        },
        addPhotoTreasure: {
            before: {
                msg: "Content creator",
                sub: "Upload photo"
            }
        },
        photoDeleted: {
            after: {
                msg: "Info",
                sub: "Photo deleted"
            }
        },
        passwordsDoNotMatch: {
            after: {
                msg: "Request failed",
                sub: "Passwords do not match"
            }
        },
        cannotSelectNewDestination: {
            after: {
                msg: "Locked",
                sub: "Finish current challenge first"
            }
        },
        storeTimeout: {
            after: {
                msg: "Store Timeout",
                sub: "Some items failed to load from the store and might not display properly"
            }
        },
        logOut: {
            before: {
                msg: "Log out",
                sub: "This will clear your local data"
            }
        },
        deleteGroup: {
            before: {
                msg: "Delete team",
                sub: "Please confirm"
            }
        },
        leaveGroup: {
            before: {
                msg: "Leave team",
                sub: "Please confirm"
            }
        },
        leaveSession: {
            before: {
                msg: "Leave session",
                sub: "Please confirm"
            }
        },
        accountNotValidated: {
            after: {
                msg: "Account",
                sub: "Please validate your email account"
            },
        },

        termsNotAgreed: {
            after: {
                msg: "Account",
                sub: "You may delete your account if for some reason you don't find the Terms and Conditions acceptable"
            },
        },

        serviceNotAvailable: {
            after: {
                msg: "Not available",
                sub: "This service is not available at the moment"
            },
        },
        exitMap: {
            before: {
                msg: "Exit map",
                sub: ""
            }
        },
        exitMapStory: {
            before: {
                msg: "Exit map",
                sub: "Note: you have a story in progress"
            }
        },
        exitMapChallenge: {
            before: {
                msg: "Exit map",
                sub: "Note: you have a challenge in progress"
            }
        },
        exitMapMP: {
            before: {
                msg: "Exit map",
                sub: "Note: you have team session in progress"
            }
        },
        exitAR: {
            before: {
                msg: "Exit AR View",
                sub: ""
            }
        },
        updateAppRequiredLock: {
            after: {
                msg: "Locked",
                sub: "Update your app to unlock this feature"
            }
        },
        settingsUpdated: {
            after: {
                msg: "Info",
                sub: "Settings updated"
            }
        },
        removeAdsInfo: {
            after: {
                msg: "Remove ads",
                sub: "<p>You can unlock the ad-free version from the inventory (items).</p><p>*Reward ads will still be available on request.</p>"
            }
        },
        registerClient: {
            after: {
                msg: "Request validated",
                sub: "Continue"
            }
        },
        subscribeToPrivateStory: {
            after: {
                msg: "Request validated",
                sub: "Subscribed"
            }
        },
        registerClientError: {
            after: {
                msg: "Request failed",
                sub: "Invalid code"
            }
        },
        signup: {
            after: {
                msg: "Account registered",
                sub: "Please log in"
            }
        },
        requestNewValidationEmail: {
            after: {
                msg: "Email sent",
                sub: "Please validate your email account"
            }
        },
        requestNewPasswordEmail: {
            after: {
                msg: "Email sent",
                sub: "Check your email for temporary password"
            }
        },
        finishBeforeComplete: {
            before: {
                msg: "Finish note",
                sub: "You have partially completed the challenge. Please confirm or return to complete the tasks."
            },
        },
        skipLinear: {
            before: {
                msg: "Skip place",
                sub: "You can only come back later by restarting the story (or app)"
            },
        },
        skipNonlinear: {
            before: {
                msg: "Skip place",
                sub: "You can come back later"
            },
        },
        thanksForRating: {
            after: {
                msg: "Thank you",
                sub: "You make stories unique"
            }
        },
        thanksForRatingRequestAppRating: {
            after: {
                msg: "Thank you",
                sub: "<p>You make stories unique</p><p>We'd be glad if you could leave a 5* rating for the app too :)</p>"
            }
        },
        selectModeLobby: {
            before: {
                msg: "Teams",
                sub: "Select mode"
            }
        },
        unsubscribeFromStoryProvider: {
            before: {
                msg: "Unsubscribe",
                sub: "Remove private stories from provider"
            }
        },
        removePrivateStory: {
            before: {
                msg: "Unsubscribe",
                sub: "Remove private story"
            }
        },
        startOptions: {
            before: {
                msg: "Start",
                sub: "Begin the story"
            }
        },
        restartOptions: {
            before: {
                msg: "Replay",
                sub: "Replay or reset the story"
            }
        },
        serverError: {
            after: {
                msg: "Request failed",
                sub: "Resource not available"
            }
        },
        preCheckoutError: {
            after: {
                msg: "Checkout error",
                sub: "There was an error processing your request"
            }
        },
        codePushUpdated: {
            after: {
                msg: "App updated",
                sub: "Push update installed"
            }
        },
        extLoginFailed: {
            after: {
                msg: "Login exception",
                sub: "<p>Service not available at the moment.</p><p>Please try again later or use email login/sign up instead.</p>"
            }
        },
        shareLocationTeam: {
            before: {
                msg: "Location updates",
                sub: "<p>Do you want to share your location with your team members?</p><p>This feature is in beta and might not work as expected</p><p>Game experience should not be affected either way</p>"
            }
        },
        locationError: {
            after: {
                msg: "Location not found",
                sub: "Please check location services"
            }
        },
        backgroundLocationAccess: {
            before: {
                msg: "Permission required",
                sub: "<p>This app requires location access for geolocation, also when the screen is off when world map is open. This way you won't have to look at your phone while running.</p><p>Please select \"Allow all the time\" to enable this feature for the best experience.</p>"
            }
        },
        loginFailed: {
            after: {
                msg: "Authentication error",
                sub: "Could not log in to your account"
            }
        },
        loginAgain: {
            after: {
                msg: "Login",
                sub: "Log in with your (existing) account"
            }
        },
        mpError: {
            after: {
                msg: "MP Exception",
                sub: "Check your connection"
            }
        },
        mpChallengeLoadError: {
            after: {
                msg: "MP Exception",
                sub: "Requested challenge could not be loaded"
            }
        },
        mpNotification: {
            after: {
                msg: "MP Notification",
                sub: "You have received a message from your team"
            }
        },
        notificationPermissionsRequireAction: {
            after: {
                msg: "Permissions",
                sub: "Notification permissions could not be acquired. These are required for certain app functions to improve your overall experience. You may enable notification permissions from the Settings app."
            }
        },
        treasureScannerLoadError: {
            after: {
                msg: "Request failed",
                sub: "Treasure Scanner is not available at the moment"
            }
        },
        itemNotAvailable: {
            after: {
                msg: "Out of stock",
                sub: "Collect or buy more items"
            }
        },
        clearSavedPlaces: {
            before: {
                msg: "Clear story",
                sub: "Visit other places"
            }
        },
        applyForGroup: {
            before: {
                msg: "Team action",
                sub: "Warning: this would apply for the entire team. Please confirm."
            }
        },
        storyNotAvailable: {
            after: {
                msg: "Request failed",
                sub: "This story is not available at the moment"
            }
        },
        storyCategoryNotAvailable: {
            after: {
                msg: "Request failed",
                sub: "This category is not available at the moment"
            }
        },
        eventNotAvailable: {
            after: {
                msg: "Request failed",
                sub: "This event is not available at the moment"
            }
        },
        eventStartNotAvailable: {
            after: {
                msg: "Warning",
                sub: "This event is not available at the moment"
            }
        },
        clearProgress: {
            before: {
                msg: "Replay story",
                sub: "<p>Reset progress and keep saved places</p><p>Warning: This will reset your score on the leaderboard</p>"
            }
        },
        clearProgressGroup: {
            before: {
                msg: "Replay story",
                sub: "<p>Reset progress and keep saved places</p><p>Warning: This will reset your team progress and score on the leaderboard</p>"
            }
        },
        dropItem: {
            before: {
                msg: "Drop item",
                sub: "Please confirm"
            }
        },
        cancelItem: {
            before: {
                msg: "Cancel item order",
                sub: "Please confirm"
            }
        },
        orderCancelled: {
            after: {
                msg: "Store",
                sub: "Order canceled"
            }
        },
        error: {
            after: {
                msg: "Error",
                sub: ""
            }
        },
        returned: {
            after: {
                msg: "Returned",
                sub: "Exception / user action"
            }
        },
        unrecoverableError: {
            after: {
                msg: "Unrecoverable error",
                sub: "Please restart the session to continue"
            }
        },
        networkErrorRetry: {
            after: {
                msg: "Network error",
                sub: "<p>Check your network connection and try again to continue.</p>"
            }
        },
        applicationError: {
            after: {
                msg: "Unexpected error",
                sub: "<p>A rare/unexpected/unrecoverable error occured in the application</p><p>Please report the issue with the integrated support service</p><p>Then, please restart the app to continue</p><p>We are sorry for the inconvenience</p>"
            }
        },
        adsRemoved: {
            after: {
                msg: "Ads removed",
                sub: "Removed the Ads that were shown automatically in the app"
            }
        },
        rewardAlreadyIssued: {
            before: {
                msg: "Info",
                sub: "<p>You have already received a reward.</p><p>You may watch another ad to get a bigger reward (not adding to the previous one).</p>"
            }
        },
        mpConnectChatError: {
            after: {
                msg: "Connection error",
                sub: "Please close and open the page again"
            }
        },
        challengeInProgress: {
            after: {
                msg: "Locked",
                sub: "Finish your current challenge first"
            }
        },
        stayOnMapOrReturnToStoryline: {
            before: {
                msg: "Game paused",
                sub: "Return to storyline"
            }
        },
        destinationTooFarAwayForDirections: {
            after: {
                msg: "Directions",
                sub: "<p>Destination too far away to show directions.</p>"
            }
        },
        quitChallenge: {
            before: {
                msg: "Game paused",
                sub: "Quit challenge"
            }
        },
        disconnectGroup: {
            before: {
                msg: "Game paused",
                sub: "Disconnect from team"
            }
        },
        quitChallengeExt: {
            before: {
                msg: "Game paused",
                sub: "The host has stopped the game"
            }
        },
        quitEvent: {
            before: {
                msg: "Quit event",
                sub: "This will remove you from all event groups"
            }
        },
        alreadyRegistered: {
            before: {
                msg: "Action unavailable",
                sub: "You are already registered"
            }
        },
        qrScanRequired: {
            before: {
                msg: "Scan code",
                sub: "Scan the QR code to continue"
            }
        },
        requestFailed: {
            after: {
                msg: "Request failed",
                sub: "Not available"
            }
        },
        requestFailedTryAgain: {
            after: {
                msg: "Request failed",
                sub: "Please try again later"
            }
        },
        resourceUnavailable: {
            after: {
                msg: "Resource unavailable",
                sub: "Check data connection."
            }
        },
        usernameUndefined: {
            after: {
                msg: "Undefined username",
                sub: "Please set a username"
            }
        },
        layersNotInitialized: {
            after: {
                msg: "GMAP",
                sub: "Network error: layers not initialized"
            }
        },
        batteryCheckFailed: {
            after: {
                msg: "Request failed",
                sub: "<p>Could not sync drone battery level</p><p>Check your internet connection</p>"
            }
        },
        lowBatteryWarning: {
            after: {
                msg: "Battery check",
                sub: "<p>Warning: battery is running low.</p><p>You might not have enough juice left to complete the story.</p>"
            }
        },
        groupNotSelected: {
            after: {
                msg: "Request failed",
                sub: "Select a team first"
            }
        },
        groupNotSelectedStory: {
            after: {
                msg: "Teams mode",
                sub: "Open the lobby to select a team first"
            }
        },
        groupNotStartedStory: {
            after: {
                msg: "Teams mode",
                sub: "Open the team lobby to connect with your team and start the game."
            }
        },
        groupCreatedSelect: {
            after: {
                msg: "Created team",
                sub: "Select your team from the lobby to connect"
            }
        },
        groupJoinedSelect: {
            after: {
                msg: "Joined team",
                sub: "You are now a member of the selected team"
            }
        },
        refreshGroupChangeDetectedOngoingSession: {
            after: {
                msg: "Ongoing session",
                sub: "Reload required due to team change detected. You will be temporarily disconnected from the session"
            }
        },
        roleNotSelected: {
            after: {
                msg: "Request failed",
                sub: "Select a role first"
            }
        },
        resourceInitFailed: {
            after: {
                msg: "Resource unavailable",
                sub: "<p>Service not available at the moment</p><p>Check your internet connection</p>"
            }
        },
        mediaServiceNotAvailable: {
            after: {
                msg: "Request failed",
                sub: "Media service not available"
            }
        },
        mediaServiceError: {
            after: {
                msg: "Request failed",
                sub: "Media service error"
            }
        },
        ARObjectNotFound: {
            after: {
                msg: "Info",
                sub: "<p>The required objects were not found in the photo</p><p>You may try again</p>"
            }
        },
        ARNotSupportedMissingSensors: {
            after: {
                msg: "AR not available",
                sub: "<p>Gyroscope is not available on this device</p>"
            }
        },
        ARNotAvailablePermissions: {
            after: {
                msg: "AR not available",
                sub: "<p>Required permissions not granted</p>"
            }
        },
        QRCodeOpenLink: {
            before: {
                msg: "QR Link",
                sub: "<p>This QR code has a link</p><p>Open to view the content</p>"
            }
        },
        storyPreviewNotification: {
            after: {
                msg: "Story preview mode",
                sub: "<p>Still checking out the story preview?</p><p>Start the interactive story to complete the checkpoints.</p><p>You will find the start button by scrolling down to the bottom.</p>"
            }
        },
        skipToFirstPending: {
            after: {
                msg: "Continue",
                sub: "<p>Continue from FIRST or LAST pending checkpoint</p>"
            }
        },
        noFeaturedStories: {
            after: {
                msg: "Storyline",
                sub: "<p>There are no featured storylines in your location</p>"
            }
        },
        stripeCheckoutUnlockStoryline: {
            after: {
                msg: "Checkout",
                sub: "<p>Please tap on refresh after successful checkout.</p><p>You will also receive a confirmation email with an additional unlock code that you can use to unlock this story if needed.</p>"
            }
        },
        stripeCheckoutUnlockItem: {
            after: {
                msg: "Checkout",
                sub: "<p>Please tap on refresh after successful checkout.</p>"
            }
        },
        connectCheckout: {
            after: {
                msg: "Connect",
                sub: "<p>Please tap on refresh to continue.</p>"
            }
        },
        photoValidateOk: {
            after: {
                msg: "Photo uploaded",
                sub: "Find the hidden place to continue"
            }
        },
        recordingOverwrite: {
            after: {
                msg: "Record",
                sub: "Overwrite current recording?"
            }
        },
        itemOnlyAvailableAsPackage: {
            after: {
                msg: "Not available",
                sub: "This item can only be supplied as a package"
            }
        },
        pleaseReload: {
            after: {
                msg: "Please reload page",
                sub: "Some data could not be retrieved from the server"
            }
        },
        storyLocked: {
            after: {
                msg: "Locked",
                sub: "This story is locked at the moment"
            }
        },
        storyCompletedReturn: {
            after: {
                msg: "Story finished",
                sub: "You may return to storyline"
            }
        },
        storyCompletedContinueWorldMap: {
            after: {
                msg: "Story finished",
                sub: "You may return to world map"
            }
        },
        storyLocationLockedCompleted: {
            after: {
                msg: "Checkpoint locked",
                sub: "This checkpoint was already completed. Replay?"
            }
        },
        storyLocationLockedCompletedNoReplay: {
            after: {
                msg: "Checkpoint locked",
                sub: "This checkpoint was already completed."
            }
        },
        storyLocationLockedCooldown: {
            after: {
                msg: "Checkpoint locked",
                sub: "This checkpoint is unavailable at the moment. You may come back later once the cooldown is complete: "
            }
        },
        storyLocationLockedTooFar: {
            after: {
                msg: "Checkpoint locked",
                sub: "You are too far away"
            }
        },
        storyLocationLocked: {
            after: {
                msg: "Checkpoint locked",
                sub: "Not available at the moment"
            }
        },
        storyLocationAvailable: {
            before: {
                msg: "Story location",
                sub: "Nearby story location available"
            }
        },
        storyLocationStart: {
            before: {
                msg: "Story location",
                sub: "Start challenge"
            }
        },
        storyLockedCoins: {
            after: {
                msg: "Locked",
                sub: "Proceed to unlock this story with LP"
            }
        },
        storyUnlockDenied: {
            after: {
                msg: "Locked",
                sub: "<p>Story could not be unlocked.</p><p>You may try another unlock code.</p>"
            }
        },
        itemUnlockDeniedTester: {
            after: {
                msg: "Locked",
                sub: "<p>This item is not available for TestFlight users.</p>"
            }
        },
        storyPreloadError: {
            after: {
                msg: "Warning",
                sub: "<p>Some story locations failed to load due to unexpected errors.</p><p>Please exit the map and try again.</p>"
            }
        },
        reviewTerms: {
            after: {
                msg: "Info",
                sub: "Please review the terms and conditions"
            }
        },
        compassError: {
            after: {
                msg: "Nav",
                sub: "<p>Device compass not available</p><p>Navigation is limited to GPS updates</p>"
            }
        },
        compassNeedsCalibration: {
            after: {
                msg: "Warning",
                sub: "<p>Your compass needs calibrating</p><p>Wave your device in a figure-eight motion</p>"
            }
        },
        warning: {
            after: {
                msg: "Warning",
                sub: ""
            }
        },
        googleError: {
            after: {
                msg: "Not found",
                sub: "Google services are temporary unavailable. Please try again later"
            }
        },
        googleQuotaExceeded: {
            after: {
                msg: "Overloaded",
                sub: "Google services are temporary unavailable. Please try again later"
            }
        },
        noNearbyPlaces: {
            after: {
                msg: "Uncharted",
                sub: "Cannot place object here"
            }
        }
    };
}
