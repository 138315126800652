<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" [title]="title" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()"
    rightButton="settings" [rightIcon]="true" (rightClick)="options()" [loading]="!loaded"></leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <!-- (swipe)="swipeEvent($event)" -->
  <div class="view-frame theme-background" fxLayout="column">

    <div class="margin-top-s">
      <nav-bar [slidesPerView]="categoryTabsGroupCount" [(selectedTabId)]="selectedIndex" [loadData]="loaded"
        [navBarItems]="navBarItems" (select)="selectIndexFromNavItem($event)">
      </nav-bar>
    </div>

    <!-- class="theme-border-bottom" -->
    <div>
      <leplace-paginator [pagination]="pagination" (onSwitch)="doRefresh($event)"></leplace-paginator>
    </div>

    <progress-timeout *ngIf="showProgressReload" pid="groups-list" [small]="true"
      customClass="progress-bar-alternate-light" [timeoutValue]="loadingTimeout"
      [resetTimeoutToggle]="resetLoadingTimeout" [enabled]="autoReload">
    </progress-timeout>

    <div *ngIf="!hasGroups && loaded">
      <div [innerHTML]="description" class="padding-s info-text-m description-frame text-color-primary">
      </div>
    </div>

    <div class="scrollbar-y scroll-type-2xt" #scrollContent>
      <div *ngIf="connecting" class="list-padding">
        <div class="text-center padding-bottom-s">
          <span class="info-text-m bold text-color-primary">connecting...</span>
        </div>
        <div class="progress-spinner-center">
          <mat-progress-spinner [diameter]="40" color="primary" mode="indeterminate">
          </mat-progress-spinner>
        </div>
      </div>
      <ion-list *ngIf="groups && !connecting" class="list-padding">
        <ion-item *ngFor="let group of groups" class="list-item list-item-padding transparent-bg">
          <div class="full-w">
            <group-card class="full-w" tappable (select)="goToGroup(group)" [group]="group"
              [update]="updateGroupsReloaded"></group-card>
          </div>
        </ion-item>
        <ion-item class="list-item list-item-padding transparent-bg">
          <ion-row *ngIf="isLobbyContext" class="full-w padding-top-s">
            <ion-col size="12">
              <ion-button *ngIf="isLobbyContext" class="action-button-icon-only button-color-transparent"
                (click)="createJoinOptions(true)" icon-end>
                <span class="button-font-size-s">Create team</span>
                <icon-wrapper slot="start" [icon]="appIconsStandard.groups" [custom]="false" class="slot-icon-padding">
                </icon-wrapper>
              </ion-button>
            </ion-col>
          </ion-row>
          <ion-row *ngIf="!isLobbyContext" class="full-w padding-top-s">
            <ion-col size="6">
              <ion-button class="action-button-icon-only button-color-transparent" (click)="createJoinOptions(true)"
                icon-end>
                <span class="button-font-size-s">Create</span>
                <icon-wrapper slot="start" [icon]="appIconsStandard.groups" [custom]="false" class="slot-icon-padding">
                </icon-wrapper>
              </ion-button>
            </ion-col>
            <ion-col size="6">
              <ion-button class="action-button-icon-only button-color-transparent" (click)="createJoinOptions(false)"
                icon-end>
                <span class="button-font-size-s">Join</span>
                <icon-wrapper slot="start" [icon]="appIconsStandard.addGroup" [custom]="false"
                  class="slot-icon-padding">
                </icon-wrapper>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-item>
      </ion-list>
    </div>

    <div fxFlex></div>

    <div class="padding-horizontal-s theme-border-top padding-bottom-xs">
      <ion-grid class="full-w">
        <ion-row>

          <ion-col size="4">
            <!-- <ion-button class="action-button-icon-only" (click)="searchByName()">
              <icon-wrapper slot="icon-only" [icon]="appIconsStandard.search"></icon-wrapper>
            </ion-button> -->
            <ion-button class="action-button-fill button-color-transparent" [disabled]="false" (click)="searchByName()">
              <icon-wrapper slot="start" class="slot-icon-padding" [icon]="appIconsStandard.search"></icon-wrapper>
              <span class="button-font-size-xs">Search</span>
            </ion-button>
          </ion-col>

          <ion-col size="4">
            <!-- <ion-button class="action-button-icon-only" (click)="reloadOnTap()">
              <icon-wrapper slot="icon-only" [icon]="appIconsStandard.refresh"></icon-wrapper>
            </ion-button> -->
            <!-- <blink-button *ngIf="!connectedMeetingPlace" [disabled]="isMeetingPlaceDisabled() || connecting"
              [icon]="appIcons.gpsFixed" [customIcon]="true" theme="transp" customBaseClass="action-button-icon-only"
              (select)="connectMeetingPlaceModeWrapper(true)">
            </blink-button> -->

            <blink-button *ngIf="!connectedMeetingPlace" [disabled]="isMeetingPlaceDisabled() || connecting"
              [icon]="appIcons.gpsFixed" name="connect" customFontClass="button-font-size-xs" [customIcon]="true"
              theme="transp" (select)="connectMeetingPlaceModeWrapper(true)">
            </blink-button>
            <blink-button *ngIf="connectedMeetingPlace" name="connect" customFontClass="button-font-size-xs"
              [icon]="appIcons.gpsFixed" [customIcon]="true" theme="accent" [disabled]="!checkCanExit() || connecting"
              (select)="disconnectMeetingPlaceMode(true)">
            </blink-button>
          </ion-col>

          <ion-col size="4" *ngIf="fromMpHome">

            <ion-button class="action-button-fill button-color-transparent" [disabled]="false"
              (click)="createJoinOptions(null)">
              <icon-wrapper slot="start" class="slot-icon-padding" [icon]="appIconsStandard.groups"></icon-wrapper>
              <span class="button-font-size-xs">new team</span>
            </ion-button>

            <!-- <ion-button class="action-button-icon-only" (click)="createJoinOptions(null)">
              <icon-wrapper slot="icon-only" [icon]="appIconsStandard.groups"></icon-wrapper>
            </ion-button> -->
          </ion-col>

          <ion-col size="4" *ngIf="!fromMpHome">
            <blink-button [disabled]="!canChat" name="chat" customFontClass="button-font-size-xs" icon="chatbox"
              theme="transp" [setTimeout]="chatBtnBlink" (select)="showChatModal()"></blink-button>
          </ion-col>

        </ion-row>
      </ion-grid>
    </div>


  </div>
</ion-content>