import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { IMPStatusDB } from 'src/app/classes/def/mp/status';
import { EPhotos } from 'src/app/classes/def/app/icons';
import { TimeUtils } from 'src/app/classes/general/time';
import { IKVStatDict, KVUtils, IKVStat } from 'src/app/classes/def/app/kval';

@Component({
  selector: 'mp-status-card',
  templateUrl: './mp-status-card.component.html',
  styleUrls: ['./mp-status-card.component.scss'],
})
export class MpStatusCardComponent implements OnInit, OnDestroy, OnChanges {
  photoLoaded: boolean = false;

  bgPhoto: string = "";
  hasPhoto: boolean = false;

  @Input()
  player: IMPStatusDB;

  @Input()
  update: boolean;

  @Input()
  mini: boolean;

  @Input()
  self: boolean = false;

  loaded: boolean = false;
  playerName: string = "Player";
  connectedAt: string = "";
  updatedAt: string = "";

  kvStats: IKVStatDict = {};

  constructor(
    public uiext: UiExtensionService
  ) {

  }

  ngOnInit() {
    this.formatUser();
  }

  ngOnDestroy() {

  }


  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'update':
            this.formatUser();
            break;
        }
      }
    }
  }

  formatUser() {
    if (!this.player) {
      return;
    }

    if (!this.player.photoUrl) {
      this.bgPhoto = EPhotos.userPlaceholder;
    } else {
      this.bgPhoto = this.player.photoUrl;
      this.hasPhoto = true;
    }

    this.playerName = this.player.playerName;
    // this.connectedAt = "connected: " + TimeUtils.formatDateTimeDisp(this.player.timestamp2, true);
    // this.updatedAt = "active: " + TimeUtils.formatDateTimeDisp(this.player.timestampCrt, true);

    this.connectedAt = TimeUtils.formatDateTimeDisp(this.player.timestamp2, true, true);
    this.updatedAt = TimeUtils.formatDateTimeDisp(this.player.timestampCrt, true, true);

    let kv: IKVStat = {
      name: "connected",
      value: null,
      valueString: this.connectedAt
    }
    KVUtils.updateKV(this.kvStats, "connectedAt", kv);

    kv = {
      name: "updated",
      value: null,
      valueString: this.updatedAt
    };

    KVUtils.updateKV(this.kvStats, "updatedAt", kv);

    this.loaded = true;
  }

}
