<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div *ngIf="submitView === viewModes.description" class="padding-s" fxLayout="column">
    <div class="scrollbar-y" [ngClass]="{'scroll-content-modal-1': !noButtons}" fxLayout="column"
      [@showState]="showState">

      <safe-html-renderer [unsafeHtmlContent]="description"
        innerClass="info-text-m description-frame text-color-primary theme-border-bottom-negative"
        innerNgClass="!noButtons?'padding-top-s':''"></safe-html-renderer>

      <ion-button *ngIf="withReference" class="as-text element-selector info-text-s text-color-primary text-center"
        (click)="goToReference()">
        <icon-wrapper slot="icon-only" [icon]="appIcons.www" [custom]="true"></icon-wrapper>
        <!-- Wiki -->
      </ion-button>

      <div *ngIf="photoUrl" class="text-center padding-vertical-s">
        <responsive-img [photoUrl]="photoUrl" class="border-radius img-small" customClass="img-xs"
          (onTapZoom)="onTapZoom($event)"></responsive-img>
      </div>

      <div *ngIf="hasSponsors">
        <ion-col size="12" class="text-center">
          <span class="info-text-m bold text-color-primary">Sponsored by</span>
        </ion-col>
        <div *ngFor="let sponsor of sponsors">
          <sponsor-card class="full-w" tappable [sponsor]="sponsor"></sponsor-card>
        </div>
      </div>

      <div [innerHTML]="statusDescription"
        class="info-text-m description-frame text-color-primary theme-border-bottom-negative text-center">
      </div>

      <ion-list *ngIf="hasProviders" class="list-padding">
        <span class="info-text-mlg bold text-color-primary">Provided by</span>
        <ion-item *ngFor="let place of locationItems; let i = index;" class="list-item transparent-bg" no-lines>
          <place-items-card class="full-w" *ngIf="place" [locationItem]="place" tappable [crop]="false"
            (click)="getPlaceDetails(i)">
          </place-items-card>
        </ion-item>
      </ion-list>

      <div [innerHTML]="actionDescription"
        class="info-text-m bold description-frame text-color-primary theme-border-bottom-negative margin-top-s">
      </div>

      <stripe-card *ngIf="useStripeIAPAlways()" [price]="alternativePrice" [promo]="alternativePricePromo"
        (checkout)="checkoutStripe()"></stripe-card>

      <div *ngIf="useStripeCheckout && !useStripeIAPAlways()" [innerHTML]="externalCheckoutIndications"
        class="info-text-m bold description-frame text-color-primary padding-s">
      </div>

    </div>

    <div fxFlex></div>
    <div class="padding-null"
      [ngClass]="[!noButtons?'theme-border-top':'', !vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen']">
      <ion-grid class="full-w">
        <ion-row>
          <!-- dynamic button grid -->
          <ion-col *ngFor="let b of buttons" [dynamicCol]="b.size">
            <ion-button *ngIf="b.enabled" [disabled]='b.disabled' [ngClass]="b.class" (click)="b.callback()">
              <icon-wrapper [custom]="b.customIcon" slot="icon-only" [icon]="b.icon"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>

  <div *ngIf="submitView === viewModes.buy" class="padding-s" fxLayout="column">

    <div class="scrollbar-y" fxLayout="column" [@showState]="showState"
      [ngClass]="{'scroll-content-modal-1': !noButtons}">
      <ion-grid class="full-w">

        <ion-row>
          <ion-col size="12" class="text-center">
            <span class="info-text-m bold text-color-primary">{{selectAmountText}}</span>
          </ion-col>
        </ion-row>

        <ion-row class="margin-top-s theme-border-top theme-border-bottom">
          <ion-col size="12" class="text-center padding-xs">
            <segment-display [value]="submit.amount"></segment-display>
          </ion-col>
        </ion-row>
      </ion-grid>
      <div fxFlex></div>
      <div class="padding-xs">
        <div *ngFor="let e of dispTable | values">
          <ion-row *ngIf="e.show">
            <ion-col size="6">
              <span class="info-text-xs bold text-color-primary">{{e.name}}</span>
            </ion-col>
            <ion-col size="6">
              <span class="info-text-xs bold text-color-primary">{{e.value}}</span>
            </ion-col>
          </ion-row>
        </div>
      </div>
    </div>

    <div fxFlex></div>

    <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col size="3">
            <ion-button class="action-button-fill button-color-warn"
              (click)="viewContainer.buy.buttons.switchPreview.callback()">
              <icon-wrapper [custom]="viewContainer.buy.buttons.switchPreview.customIcon" slot="icon-only"
                [icon]="viewContainer.buy.buttons.switchPreview.icon"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="3">
            <ion-button class="action-button-fill button-color-alternate"
              (click)="viewContainer.buy.buttons.decreaseAmount.callback()"
              [disabled]="viewContainer.buy.buttons.decreaseAmount.disabled">
              <icon-wrapper [custom]="viewContainer.buy.buttons.decreaseAmount.customIcon" slot="icon-only"
                [icon]="viewContainer.buy.buttons.decreaseAmount.icon"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="3">
            <ion-button class="action-button-fill button-color-alternate"
              (click)="viewContainer.buy.buttons.increaseAmount.callback()"
              [disabled]="viewContainer.buy.buttons.increaseAmount.disabled">
              <icon-wrapper [custom]="viewContainer.buy.buttons.increaseAmount.customIcon" slot="icon-only"
                [icon]="viewContainer.buy.buttons.increaseAmount.icon"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="3">
            <ion-button class="action-button-fill"
              [ngClass]="{'button-color-accent': submit.amount>0, 'button-color-warn':submit.amount<0}" ion-button
              [disabled]="submit.amount===0 || !checkAvailableIAP()"
              (click)="viewContainer.buy.buttons.submitPurchase.callback()">
              <icon-wrapper [custom]="viewContainer.buy.buttons.submitPurchase.customIcon" slot="icon-only"
                [icon]="viewContainer.buy.buttons.submitPurchase.icon"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>


  <div *ngIf="submitView === viewModes.providers" class="padding-s" fxLayout="column">
    <div class="scrollbar-y" fxLayout="column" [@showState]="showState"
      [ngClass]="{'scroll-content-modal-1': !noButtons}">
      <!-- <div class="scrollbar-y places-list"> -->
      <ion-list *ngIf="hasProviders" class="list-padding">
        <ion-item *ngFor="let place of locationItems; let i = index;" class="list-item transparent-bg" no-lines>
          <place-items-card class="full-w" *ngIf="place" [locationItem]="place" tappable [crop]="false"
            (click)="getPlaceDetails(i)">
          </place-items-card>
        </ion-item>
      </ion-list>
      <div *ngIf="!hasProviders" class="padding-s">
        <span class="center-text info-text-m bold text-color-primary">There are no providers nearby</span>
      </div>
    </div>
    <div fxFlex></div>
    <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col size="3">
            <ion-button class="action-button-fill button-color-warn"
              (click)="viewContainer.providers.buttons.switchPreview.callback()">
              <icon-wrapper slot="icon-only" [icon]="viewContainer.providers.buttons.switchPreview.icon"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="3">

          </ion-col>
          <ion-col size="3">

          </ion-col>
          <ion-col size="3">
            <ion-button class="action-button-fill button-color-accent"
              [disabled]="viewContainer.providers.buttons.use.disabled"
              (click)="viewContainer.providers.buttons.use.callback()">
              <icon-wrapper slot="icon-only" [icon]="viewContainer.providers.buttons.use.icon"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>


</modal-container>