import { ILatLng } from 'src/app/classes/def/map/coords';
import { RegressionUtils } from "src/app/classes/general/regression";
import { GeometryUtils, IDistanceOnAxis } from "../utils/geometry-utils";

export interface IBLEDevice {
    id: string,
    name?: string,
    rssi: number,
    rssiFiltered: number,
    lastTs: number,
    pingCount: number,
    registered: boolean,
    rssiTracker?: any,
    // raw data
    advertising: any,
    // parsed data
    advertisingDict: any,
    dist?: number,
    beaconSpecs?: IBeaconSpecs
}

export interface IBLEDeviceDict {
    [key: string]: IBLEDevice;
}

export interface IBeaconStats extends ILocationEuclidCoords {
    r: number,
    sig: number
}

export interface ILocationEuclidCoords {
    x: number,
    y: number,
    z: number
}

export interface IBeaconSpecs {
    id: string,
    x: number,
    y: number,
    z: number,
    lat: number,
    lng: number,
    sig: IBeaconSig[],
    isRef?: boolean
}

export interface IBeaconSig {
    d: number,
    s: number
}

export interface IBeaconLocateResult {
    coords: ILocationEuclidCoords,
    mode: number
}

export enum EBeaconLocatorMode {
    highAccuracy = 1,
    lowAccuracy = 2,
    proximity = 3,
    unavailable = -1
}

export class BLEUtils {

    /**
     * locate device based on beacon signals
     * @param stats 
     * @returns 
     */
    static locateWizard(stats: IBeaconStats[]): IBeaconLocateResult {
        let coords: ILocationEuclidCoords = null;
        let res: IBeaconLocateResult = {
            coords: coords,
            mode: EBeaconLocatorMode.unavailable
        };
        let mode: number = 0;
        if (stats.length >= 3) {
            mode = EBeaconLocatorMode.highAccuracy;
        } else if (stats.length === 2) {
            mode = EBeaconLocatorMode.lowAccuracy;
        } else if (stats.length === 1) {
            mode = EBeaconLocatorMode.proximity;
        } else {
            mode = EBeaconLocatorMode.unavailable;
        }
        if (mode === EBeaconLocatorMode.highAccuracy) {
            coords = BLEUtils.trilaterate(stats[0], stats[1], stats[2]);
            if (coords == null) {
                // fallback to bilaterate
                mode = EBeaconLocatorMode.lowAccuracy;
            }
        }
        if (mode === EBeaconLocatorMode.lowAccuracy) {
            // set position between the two beacons
            coords = BLEUtils.bilaterate(stats[0], stats[1]);
            if (coords == null) {
                // fallback to single beacon
                mode = EBeaconLocatorMode.proximity;
            }
        }
        if (mode === EBeaconLocatorMode.proximity) {
            // set position as single beacon coords
            coords = stats[0];
        }
        if (mode === EBeaconLocatorMode.unavailable) {
            coords = null;
        }
        res.mode = mode;
        res.coords = coords;
        return res;
    }

    static bilaterate(p1: IBeaconStats, p2: IBeaconStats): ILocationEuclidCoords {
        let coords: ILocationEuclidCoords = {
            x: (p1.x + p2.x) / 2,
            y: (p1.y + p2.y) / 2,
            z: (p1.z + p2.z) / 2
        };
        return coords;
    }


    // https://gist.github.com/kdzwinel/8235348
    static trilaterate(p1: IBeaconStats, p2: IBeaconStats, p3: IBeaconStats): ILocationEuclidCoords {
        // based on: https://en.wikipedia.org/wiki/Trilateration

        // some additional local functions declared here for
        // scalar and vector operations
        //   console.log("trilaterate: ", p1,p2,p3);

        function sqr(a) {
            return a * a;
        }

        function norm(a) {
            return Math.sqrt(sqr(a.x) + sqr(a.y) + sqr(a.z));
        }

        function dot(a, b) {
            return a.x * b.x + a.y * b.y + a.z * b.z;
        }

        function vector_subtract(a, b) {
            return {
                x: a.x - b.x,
                y: a.y - b.y,
                z: a.z - b.z
            };
        }

        function vector_add(a, b) {
            return {
                x: a.x + b.x,
                y: a.y + b.y,
                z: a.z + b.z
            };
        }

        function vector_divide(a, b) {
            return {
                x: a.x / b,
                y: a.y / b,
                z: a.z / b
            };
        }

        function vector_multiply(a, b) {
            return {
                x: a.x * b,
                y: a.y * b,
                z: a.z * b
            };
        }

        function vector_cross(a, b) {
            return {
                x: a.y * b.z - a.z * b.y,
                y: a.z * b.x - a.x * b.z,
                z: a.x * b.y - a.y * b.x
            };
        }

        var ex, ey, ez, i, j, d, a, x, y, z, b, p4;

        ex = vector_divide(vector_subtract(p2, p1), norm(vector_subtract(p2, p1)));

        i = dot(ex, vector_subtract(p3, p1));
        a = vector_subtract(vector_subtract(p3, p1), vector_multiply(ex, i));
        ey = vector_divide(a, norm(a));
        ez = vector_cross(ex, ey);
        d = norm(vector_subtract(p2, p1));
        j = dot(ey, vector_subtract(p3, p1));

        x = (sqr(p1.r) - sqr(p2.r) + sqr(d)) / (2 * d);
        y = (sqr(p1.r) - sqr(p3.r) + sqr(i) + sqr(j)) / (2 * j) - (i / j) * x;

        b = sqr(p1.r) - sqr(x) - sqr(y);

        // floating point math flaw in IEEE 754 standard
        // see https://github.com/gheja/trilateration.js/issues/2
        if (Math.abs(b) < 0.0000000001) {
            b = 0;
        }

        z = Math.sqrt(b);

        // no solution found
        if (isNaN(z)) {
            return null;
        }

        a = vector_add(p1, vector_add(vector_multiply(ex, x), vector_multiply(ey, y)));
        return a;
        // let p4a = vector_add(a, vector_multiply(ez, z));
        // let p4b = vector_subtract(a, vector_multiply(ez, z));

        // if (z == 0 || return_middle) {
        //     return a;
        // }
        // else {
        //     return [p4a, p4b];
        // }
    }


    static asHexString(i: number) {
        let hex: string;

        hex = i.toString(16);

        // zero padding
        if (hex.length === 1) {
            hex = "0" + hex;
        }

        return "0x" + hex;
    }

    static parseAdvertisingDataAndroid(buffer: Uint8Array) {
        let length: number, type: number, data: ArrayBufferLike, i: number = 0, advertisementData: any = {};
        let bytes: Uint8Array = new Uint8Array(buffer);

        while (length !== 0) {

            length = bytes[i] & 0xFF;
            i++;

            // decode type constants from https://www.bluetooth.org/en-us/specification/assigned-numbers/generic-access-profile
            type = bytes[i] & 0xFF;
            i++;

            data = bytes.slice(i, i + length - 1).buffer; // length includes type byte, but not length byte
            i += length - 2;  // move to end of data
            i++;

            advertisementData[BLEUtils.asHexString(type)] = data;
        }

        return advertisementData;
    }

    static parseId(device) {
        // get the temperature from service data in the advertising packet
        // let serviceData: any;
        // let id: string="";
        // let serviceId: string = "4fafc201-1fb5-459e-8fcc-c5c9c331914b";

        // if (cordova.platformId === 'ios') {
        //     serviceData = device.advertising.kCBAdvDataServiceData;
        //     if (serviceData && serviceData[serviceId]) {
        //         id = serviceData[serviceId][0];
        //     }
        // } else { // android
        //     var SERVICE_DATA_KEY = serviceId;
        //     var advertisingData = BLEUtils.parseAdvertisingData(device.advertising);
        //     serviceData = advertisingData[SERVICE_DATA_KEY];
        //     if (serviceData) {
        //         // first 2 bytes are the 16 bit UUID
        //         var uuidBytes = new Uint16Array(serviceData.slice(0,2));
        //         var uuid = uuidBytes[0].toString(16); // hex string
        //         console.log("Found service data for " + uuid);
        //         // remaining bytes are the service data, expecting 32bit floating point number
        //         var data = new Float32Array(serviceData.slice(2));
        //         celsius = data[0];
        //     }
        // }

        // if (celsius) {
        //     fahrenheit = (celsius * 1.8 + 32.0);
        //     temperature = '<br/>' + celsius.toFixed(1) + '&deg;C ' + fahrenheit.toFixed(1) + '&deg;F';
        // }
        // return temperature;
    }

    static getBeaconRef(deviceEntries: IBeaconSpecs[]) {
        let beaconRef: IBeaconSpecs = deviceEntries.find(dev => {
            if (dev.x === 0 && dev.y === 0 && dev.lat != null && dev.lng != null) {
                dev.isRef = true;
                return true;
            }
            return false;
        });
        return beaconRef;
    }

    /**
     * move to server-side (register beacons)
     * @param deviceEntries 
     * @returns 
     */
    static computeBeaconFleetCoords(deviceEntries: IBeaconSpecs[]) {
        // device at 0,0,0 should always have GPS coords
        // if other devices also have coords, apply calibration from reference device
        let beaconRef: IBeaconSpecs = BLEUtils.getBeaconRef(deviceEntries);
        if (beaconRef == null) {
            return false;
        }
        for (let dev of deviceEntries) {
            if (!dev.isRef) {
                if (dev.lat != null && dev.lng != null) {
                    // if other devices also have coords, apply calibration from reference device (adjust x,y)
                    let dax: IDistanceOnAxis = GeometryUtils.getDistanceBetweenEarthCoordinatesOnAxis(new ILatLng(beaconRef.lat, beaconRef.lng), new ILatLng(dev.lat, dev.lng));
                    dev.x = dax.dx;
                    dev.y = dax.dy;
                } else {
                    // assign coords to distance assigned beacons (use ref beacon for reference coords)
                    dev.lat = beaconRef.lat + GeometryUtils.getCoordDeltaFromDistanceDelta(dev.x - beaconRef.x);
                    dev.lng = beaconRef.lng + GeometryUtils.getCoordDeltaFromDistanceDelta(dev.y - beaconRef.y);
                }
            }
        }
        return true;
    }

    static toGPSCoords(ecoords: ILocationEuclidCoords, deviceEntries: IBeaconSpecs[]) {
        if (ecoords == null) {
            return null;
        }
        let beaconRef: IBeaconSpecs = BLEUtils.getBeaconRef(deviceEntries);
        if (beaconRef == null) {
            return null;
        }
        let coords: ILatLng = GeometryUtils.getPointOnDistanceDeltaXY(new ILatLng(beaconRef.lat, beaconRef.lng), ecoords.x, ecoords.y);
        return coords;
    }

    static computeDistanceDevice(device: IBeaconSpecs, bleDevice: IBLEDevice) {
        let data = [];
        for (let i = 0; i < device.sig.length; i++) {
            data.push([device.sig[i].s, device.sig[i].d])
        }
        if (data.length === 1) {
            // use RSSI formula (based on RSSI at 1 meter)
            return BLEUtils.rssiToDistance(bleDevice.rssiFiltered, data[0][0]);
        }
        // approximate distance using a linear model
        const result = RegressionUtils.linear(data);
        if (bleDevice.rssiFiltered == null) {
            return null;
        }
        let pred = result.predict(bleDevice.rssiFiltered);
        if (pred && pred.length == 2) {
            return pred[1];
        } else {
            return null;
        }
    }

    static printDeviceDictStats(devices: { [key: string]: IBLEDevice }) {
        let keys: string[] = Object.keys(devices);
        console.log("ID\tRSSI\tdist");
        for (let key of keys) {
            let device: IBLEDevice = devices[key];
            console.log(device.id + "\t" + device.rssiFiltered + "\t" + device.dist);
        }
    }

    static printDeviceStats(devices: IBLEDevice[]) {
        console.log("ID\tRSSI\tdist");
        for (let device of devices) {
            console.log(device.id + "\t" + device.rssiFiltered + "\t" + device.dist);
        }
    }

    /**
     * theoretical model
     * @param rssi 
     * @param ref1m 
     * @returns 
     */
    static rssiToDistance(rssi: number, ref1m: number) {
        // -54 is one of the average values i meassured at 1m. The beacons are set to TxPower 4dB. A
        if (!ref1m) {
            ref1m = -54;
        }
        return Math.pow(10.0, ((rssi - ref1m) / -25.0));
    }

}