
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdMobPro, AdMobOptions } from '@ionic-native/admob-pro/ngx';
import { AdsTemplateService, IAdConfig } from './ads-template';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { AdsDef, ERewardExitCodes } from '../../apis/ads-def';
import { EOS } from 'src/app/classes/def/app/app';
import { WaitUtils } from '../../utils/wait-utils';
import { SleepUtils } from '../../utils/sleep-utils';

interface IAdmobProDismissResponse {
    returnValue: boolean
}

@Injectable({
    providedIn: 'root'
})
export class AdmobproAdsService implements AdsTemplateService {

    reward: boolean = false;

    watchRewardCallback: BehaviorSubject<number>;

    initialized: boolean = false;

    adId = AdsDef.adId;
    adIdIos = AdsDef.adIdIOS;

    rewardAdConfig: IAdConfig = {
        id: null,
        isTesting: false,
        autoShow: true
    };

    interAdConfig: IAdConfig = {
        id: null,
        isTesting: false,
        autoShow: true
    };

    bannerAdConfig: IAdConfig = {
        id: null,
        isTesting: false,
        autoShow: true
    };

    constructor(
        private admob: AdMobPro
    ) {
        console.log("admobpro ads service created");
        this.watchRewardCallback = new BehaviorSubject(null);
    }

    setup() {
        
        // onRewardedVideoCompleted
        this.admob.onAdDismiss().subscribe((res: IAdmobProDismissResponse) => {
            console.log('User dismissed ad: ', res);
            this.watchRewardCallback.next(ERewardExitCodes.close);
        });

        this.admob.onAdPresent().subscribe((val) => {
            // this is only called after watched entire video ad (issue reward)
            this.reward = true;
            console.log("onAdPresent: ", val);
        });

        this.admob.onAdLoaded().subscribe(() => {
            this.watchRewardCallback.next(ERewardExitCodes.load);
            console.log("onAdLoaded");
        });

        this.admob.onAdFailLoad().subscribe(() => {
            this.watchRewardCallback.next(ERewardExitCodes.fail);
            console.log("onAdFailed");
        });

        this.admob.onAdLeaveApp().subscribe(() => {
            this.watchRewardCallback.next(ERewardExitCodes.close);
            console.log("onAdLeaveApp");
        });

        this.initialized = true;
        console.log("ads/admobpro setup complete");
    }

    /**
     * load ad ids, os based
     */
    setAdIds(testing: boolean) {
        if (testing) {
            // testing
            this.bannerAdConfig.isTesting = true;
            this.interAdConfig.isTesting = true;
            this.rewardAdConfig.isTesting = true;

            this.bannerAdConfig.id = AdsDef.adIdTesting.banner;
            this.interAdConfig.id = AdsDef.adIdTesting.interstitial;
            this.rewardAdConfig.id = AdsDef.adIdTesting.reward;
        } else {
            // real ads
            this.bannerAdConfig.isTesting = false;
            this.interAdConfig.isTesting = false;
            this.rewardAdConfig.isTesting = false;

            switch (GeneralCache.os) {
                case EOS.ios:
                    this.bannerAdConfig.id = this.adIdIos.banner;
                    this.interAdConfig.id = this.adIdIos.interstitial;
                    this.rewardAdConfig.id = this.adIdIos.reward;
                    break;
                case EOS.android:
                    this.bannerAdConfig.id = this.adId.banner;
                    this.interAdConfig.id = this.adId.interstitial;
                    this.rewardAdConfig.id = this.adId.reward;
                    break;
            }
        }

        console.log("set ad ids (reward) ", this.rewardAdConfig);
    }


    handleRewardVideo(onPrepared: () => Promise<any>): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let options: AdMobOptions = {
                adId: this.rewardAdConfig.id,
                isTesting: this.rewardAdConfig.isTesting
            };

            this.reward = false;
            this.watchRewardCallback.next(null);

            this.admob.prepareRewardVideoAd(options).then(async () => {
                console.log("ad prepared");

                this.admob.showRewardVideoAd();

                console.log("show reward video ad called");
                let returnCode: number = null;

                returnCode = await WaitUtils.waitObsAnyTimeout(this.watchRewardCallback, [ERewardExitCodes.load, ERewardExitCodes.fail], null);

                if (onPrepared) {
                    await SleepUtils.sleep(100);
                    await onPrepared();
                }

                if (returnCode === ERewardExitCodes.fail) {
                    resolve(null);
                    return;
                }

                returnCode = await WaitUtils.waitObsAnyTimeout(this.watchRewardCallback, [ERewardExitCodes.close, ERewardExitCodes.fail], null);

                console.log("return code: ", returnCode);

                if (returnCode === ERewardExitCodes.fail) {
                    resolve(null);
                    return;
                }

                resolve(this.reward);
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
    }

    showBanner(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            let options: AdMobOptions = {
                adId: this.bannerAdConfig.id,
                isTesting: this.bannerAdConfig.isTesting
            };
            this.admob.createBanner(options).then(() => {
                this.admob.showBanner(this.admob.AD_POSITION.BOTTOM_CENTER);
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }

    showInterstitial(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            let options: AdMobOptions = {
                adId: this.interAdConfig.id,
                isTesting: this.interAdConfig.isTesting
            };
            this.admob.prepareInterstitial(options).then(() => {
                console.log("ad prepared");
                this.admob.showInterstitial();
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * check consent, update ad config to include the npa flag
     * @param consentStatus 
     */
    updateConsent(_consentStatus: boolean) {
        console.warn("not implemented");
    }
}
