
import { ILeplaceTreasure } from "../places/leplace";
import { IGameItem } from "../items/game-item";

export enum EChallengeStatus {
    started = 0,
    expired = 1
}

export interface IChallengeParams {
    /**
     * seconds
     */
    timeLimit: number;
}

export interface IChallengeViewReturn {
    item: ILeplaceTreasure;
    status: number;
}

export interface IChallengeData {
    timerValue: number;
    status: number;
    percentComplete: number;
    timerDisp: string;
}

export enum EChallengeType {
    none = 0,
    photo = 1,
    sound = 2,
    AR = 4
    // run = 5,
    // explore = 6,
    // find = 7
}

export interface IChallengeProgressUntilNewMedalResponse {
    medals: IGameItemWithStats[];
}

export interface IGameItemWithStats {
    item: IGameItem;
    stats: IGameItemStats[];
}

export interface IGameItemStats {
    id: number;
    itemCode: number;
    statsCode: number;
    categoryCode: number;
    requiredAmount: number;
    count: number;
    progress: number;
}


export enum EChallengeReturnParams {
    returnNotStarted = 0,
    start = 1,
    complete = 2,
    quit = 3
}
