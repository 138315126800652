export interface IThemeSettings {
    [key: string]: IThemeSettingsElement;
    standard: IThemeSettingsElement;
    aubergine: IThemeSettingsElement;
}

export interface IThemeSettingsElement {
    name: string;
    css: string;
    chartTheme: string[];
    vgTheme: IVGTheme;
    lineColor: string;
}

export interface IMarkerTheme {
    name: string;
    lineColor: string;
    markerFrameColor: string;
}

export enum EFeatureColor {
    // categories
    sport = "rgb(218, 5, 5)",
    casual = "rgb(2, 127, 252)",
    explore = "rgb(2, 252, 52)",

    // interface
    leplaceBlue = "#1e72a3",
    lineColor = "#1e72a3",
    lineColorLight = "#46a898",
    chartColor = "#fcd182",

    // map
    userCircleColor = "#ebb24a",
    droneCircleColor = "#ebb24a",

    // action
    // green
    action1 = "#46b666",
    // red
    action2 = "#e75f4d",
    // yellow
    action3 = "#ebb24a",
    action3v2 = "#fcd182"
}

export enum EColorsRGBA {
    blue = "rgba(30,114,163,0.8)",
    yellow = "rgba(235, 178, 74, 0.8)",
    red = "rgba(231, 95, 77, 0.8)",
    green = "rgba(70, 182, 102, 0.8)"
}

export enum EColorsHEX {
    blue = "#1e72a3",
    yellow = "#ebb24a",
    red = "#e75f4d",
    green = "#46b666"
}

export enum EColorsNames {
    blue = "blue",
    yellow = "yellow",
    red = "red",
    green = "green"
}

export class ThemeColors {
    public static theme: IThemeSettings = {
        standard: {
            name: "standard",
            css: "theme-standard theme-standard-bg",
            chartTheme: ['#1e72a3', '#1e72a3', '#1e72a3', '#1e72a3'],
            lineColor: EFeatureColor.lineColor,
            vgTheme: {
                default: [EFeatureColor.lineColorLight, EFeatureColor.action3v2],
                accent1: [EFeatureColor.lineColorLight, EFeatureColor.action1],
                accent2: [EFeatureColor.lineColorLight, EFeatureColor.action3]
            }
        },
        aubergine: {
            name: "aubergine",
            css: "theme-aubergine theme-aubergine-bg",
            chartTheme: [EFeatureColor.chartColor, EFeatureColor.chartColor, EFeatureColor.chartColor, EFeatureColor.chartColor],
            // lineColor: "rgba(244, 244, 244, 0.8)"
            lineColor: EFeatureColor.lineColorLight,
            vgTheme: {
                default: [EFeatureColor.lineColorLight, EFeatureColor.action3v2],
                accent1: [EFeatureColor.lineColorLight, EFeatureColor.action1],
                accent2: [EFeatureColor.lineColorLight, EFeatureColor.action3]
            }
        }
    };
}

export interface IVGTheme {
    // yellow
    default: string[],
    // green
    accent1: string[],
    // red
    accent2: string[]
}

export enum EChartTheme {
    // yellow
    default = 1,
    // green
    accent1 = 2,
    // red
    accent2 = 3
}
