import { IGroup, IGroupMember } from "../mp/groups";
import { IChatElement } from "../mp/chat";
import { BehaviorSubject } from "rxjs";
import { IMPMessageDB } from "../mp/message";
import { IMPGenericGroupStat } from "../mp/game";
import { IMQTTChatMessage } from "src/app/services/telemetry/def";

export interface IArenaReturnToMapData {
    closeMpView: boolean;
    code: number;
    group: IGroup;
    groupOnline: boolean;
    groupResetConnection: boolean;
    groupUpdated: boolean;
    role: number;
}

export enum EArenaReturnCodes {
    // before game is on
    none = 0,
    start = 1,
    // game is on
    resume = 2,
    leave = 3
}

export enum EGroupMemberAction {
    remove = 1
}


export interface IArenaReturnData {
    status: number;
    group: IGroup;
    groupUpdated?: boolean;
    groupCreated?: boolean;
    groupJoined?: boolean;
    /**
     * the role of the user in the group
     */
    role: number;
    closeMpView?: boolean;
}


export interface IMPUserCardOptionsNavParams {
    member: IGroupMember;
    isAdmin: boolean;
}

export interface IChatModalNavParams {
    title: string;
    groupId: number;
    groupName: string;
    meetingPlaceId: number;
    meetingPlaceName: string;
    // group/meeting place
    context: number;
    showChatHeads: boolean;
    /**
     * the current chat rx
     */
    chatRx: IChatElement[];
    /**
     * chat rx raw observable
     */
    chatRxObservable: BehaviorSubject<IMPMessageDB>;
    /**
     * chat rx raw observable
     */
    chatRxMqttObservable?: BehaviorSubject<IMQTTChatMessage>;
    /**
    * chat rx sync raw observable
    */
    chatRxSyncObservable: BehaviorSubject<IMPMessageDB[]>;
    /**
     * send observable to pass the send event to the caller
     */
    sendObservable: BehaviorSubject<IChatElement>;
    /**
     * the group status, check if connected/disconnected members and show in chat
     */
    groupStatusObservable: BehaviorSubject<IMPGenericGroupStat>;
}
