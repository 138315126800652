import { IAppLocation } from "../places/app-location";
import { IStory } from "../core/story";
import { ILeplaceTreasure } from "../places/leplace";
import { IStoryListNavParams } from "./story";
import { IEventListNavParams } from "../events/nav-params";
import { IButtonOptions } from '../app/ui';

export interface IGmapNavParams {
  location: IAppLocation;
  story: IStory;
  treasure: ILeplaceTreasure;
  photoValidated: boolean;
  fromGmap: boolean;
  isPreloadStory?: boolean;
  interactions: IGmapDetailInteractions;
  startOptions?: IGmapActivityStartOptions;
}

export interface IGmapDetailInteractions {
  withDismiss: boolean;
  withStartOption: boolean;
  includeQuestOptions: boolean;
  /** start options w/ drone mode */
  startReady: boolean;
  /** define drone mode / buttons to be shown */
  withDrone: number;
  /** override default buttons */
  withCustomButtons?: boolean;
  buttons?: IButtonOptions[];
}

export interface IGmapSliderNavParams {
  locations: IAppLocation[];
  story: IStory;
  startIndex: number;
}

export interface IGmapDetailReturnParams {
  code: number;
  data?: any;
  startOptionsSelected?: IGmapActivityStartOptions;
}

export interface IGmapActivityStartOptions {
  optionsPreloaded?: boolean;
  launchDrone?: boolean;
  isNavPreview?: boolean;
  showBriefing?: boolean;
  autostart?: boolean;
  prestart?: boolean;
  isRestart?: boolean;
  isOverrideLocationStart?: boolean;
}

export interface IGmapActivityPreviewOptions {
  inProgress: boolean;
  withDismiss: boolean;
  withDrone: number;
  startReady: boolean;
  isPreview: boolean;
  isAutostart: boolean;
  overrideLocationIndex: boolean;
}

export enum EGmapDetailReturnCode {
  nop = 1,
  skip = 2,
  done = 3,
  failed = 4,
  // dismiss preview
  dismiss = 5,
  // proceed, enter activity
  proceed = 6,
  // show map clue, for quest activity
  showMapClue = 7,

  // custom button options
  drone = 11,
  gps = 12
}


export enum EGmapMode {
  blank = 0,
  worldMap = 1,
  storyline = 2,
  editor = 3,
  eventChallenge = 4,
  // used for custom events
  customMap = 5
}

export interface IGmapEntryNavParams {
  storyNavParams?: IStoryListNavParams;
  eventNavParams?: IEventListNavParams;
  mode: number;
}
