
import { Injectable } from '@angular/core';
import { AdsTemplateService } from './ads-template';

@Injectable({
    providedIn: 'root'
})
export class AdsDisabledService implements AdsTemplateService {

    constructor(

    ) {
        console.log("disabled ads service created");
    }

    setup() {
        console.warn("ad service disabled");
    }

    /**
     * check consent, update ad config to include the npa flag
     * @param consentStatus 
     */
    updateConsent(_consentStatus: boolean) {
        console.warn("ad service disabled");
    }


    /**
    * load ad ids, os based
    */
    setAdIds(_testing: boolean) {
        console.warn("ad service disabled");
    }


    showBanner(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            console.warn("ad service disabled");
            resolve(false);
        });
    }


    handleRewardVideo(_onPrepared: () => Promise<any>): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            console.warn("ad service disabled");
            resolve(false);
        });
        return promise;
    }


    showInterstitial(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            console.warn("ad service disabled");
            resolve(false);
        });
        return promise;
    }

}
