

import { Injectable } from "@angular/core";
import { TimeoutService } from "../timeout";
import { LocalNotificationsService } from "../../../general/apis/local-notifications";
import { EActivityCodes, IRunActivityDef, IWalkActivityDef, IEnduranceRunActivityDef } from "../../../../classes/def/core/activity";
import { IMoveMonitorParams, IMoveMonitorData, EMoveActivityStatus, IAverageHeading, IDistanceDump } from "../../../../classes/def/core/move-monitor";
import { SettingsManagerService } from "../../../general/settings-manager";

import { ESpeedMode, EGPSTimeout } from 'src/app/classes/def/map/geolocation';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { MathUtils } from 'src/app/classes/general/math';
import { GeometryUtils } from 'src/app/services/utils/geometry-utils';
import { AppConstants } from 'src/app/classes/app/constants';
import { VirtualPositionService, IVirtualLocation, EVirtualLocationSource } from '../virtual-position';
import { LocationMonitorService } from 'src/app/services/map/location-monitor';
import { ITimeoutMonitorParams, ITimeoutMonitorData } from 'src/app/classes/general/timeout';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { BehaviorSubject } from 'rxjs';
import { UserStatsDataService } from 'src/app/services/data/user-stats';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { ILatLng } from "src/app/classes/def/map/coords";


@Injectable({
    providedIn: 'root'
})
export class MoveActivityService {

    observables = {
        moveActivity: null,
        globalDistanceDump: null
    };

    currentLocation: ILatLng;
    previousLocation: ILatLng;
    previousLocationGlobal: ILatLng;

    watchMovement: boolean = false;
    watchMovementGlobal: boolean = false;
    testDistanceWatch: boolean = false;
    currentDistance: number = 0;

    moveParams: IMoveMonitorParams = {
        speedMode: ESpeedMode.speed,
        timeLimit: 0,
        speedHigh: 30,
        targetSpeed: 0,
        targetDistance: 0
    };

    moveTimer = null;
    lastUpdateTimestamp: number = 0;
    lastDumpTimestamp: number = 0;

    moveDataInit: IMoveMonitorData = {
        distance: 0,
        speed: 0,
        averageSpeed: 0,
        speedDisp: "",
        speedPercent: 0,
        distanceDisp: "",
        distancePercent: 0,
        timerValue: 0,
        elapsedValue: 0,
        elapsedValueAux: 0,
        status: EMoveActivityStatus.started,
        timerDisp: "",
        cheatCounter: 0
    };

    testDistanceIncrement: number = 1;
    testTsIncrement: number = 0;
    t0: number = null;

    moveData: IMoveMonitorData;

    averageHeading: IAverageHeading = {
        sum: 0,
        crt: 0,
        counter: 0,
        samples: 10,
        minSpeed: 1,
        ready: false
    };

    globalDistanceCounter: number = 0;

    platformFlags: IPlatformFlags = {} as IPlatformFlags;

    prevSource: number = null;

    gameContext: number = null;
    prevGameContext: number = null;

    timeoutUpdate = null;

    maxAbsoluteIncrementalDistance: number = 1000;
    maxIncrementalDistance: number = 1000; // 100 m/s = 360 km/h, 100 m/5s = 72 km/h => x10 (min DT)
    maxIncrementalDistanceMinDT: number = 10; // max number of seconds that should trigger a maxIncrementalDistance overflow (larger than that, it would be registered as valid distance)

    constructor(
        public timeoutMonitor: TimeoutService,
        public localNotifications: LocalNotificationsService,
        public locationMonitor: LocationMonitorService,
        public virtualPositionService: VirtualPositionService,
        public userStatsProvider: UserStatsDataService,
        public analytics: AnalyticsService
    ) {
        console.log("move activity service created");

        this.observables = ResourceManager.initBsubObj(this.observables);
        this.moveData = Object.assign({}, this.moveDataInit);

        this.watchLocationUpdate();
    }


    getMoveParams(): IMoveMonitorParams {
        return this.moveParams;
    }

    extractMoveParams(baseActivityCode: number, params: any) {
        let moveParams: IMoveMonitorParams;
        switch (baseActivityCode) {
            case EActivityCodes.run:
                // target distance, target speed, time limit
                let rap: IRunActivityDef = params;
                moveParams = {
                    speedMode: SettingsManagerService.settings.app.settings.speedMode.value,
                    timeLimit: rap.timeLimit,
                    targetSpeed: rap.speed,
                    speedHigh: rap.maxSpeed,
                    targetDistance: rap.distance
                };
                break;
            case EActivityCodes.enduranceRun:
                // target time
                let eap: IEnduranceRunActivityDef = params;
                moveParams = {
                    speedMode: SettingsManagerService.settings.app.settings.speedMode.value,
                    timeLimit: eap.timeLimit,
                    targetSpeed: eap.speed,
                    speedHigh: eap.maxSpeed,
                    targetDistance: null
                };
                break;
            case EActivityCodes.walk:
            default:
                // target distance
                let wap: IWalkActivityDef = params;
                moveParams = {
                    speedMode: SettingsManagerService.settings.app.settings.speedMode.value,
                    timeLimit: null,
                    targetSpeed: null,
                    speedHigh: wap.maxSpeed,
                    targetDistance: wap.distance
                };
                break;

        }
        return moveParams;
    }

    /**
     * watch (virtual) location update
     */
    watchLocationUpdate() {
        // console.log("watch location update");
        this.virtualPositionService.watchVirtualPosition().subscribe((loc: IVirtualLocation) => {
            this.onLocationUpdate(loc);
        }, (err: Error) => {
            console.error(err);
        });
    }

    /**
     * on (virtual) location update
     * @param data 
     */
    onLocationUpdate(data: IVirtualLocation) {
        // console.log("on location update: ", data);
        if (this.virtualPositionService.checkNavContext(data, true)) {
            // console.log("gps raw speed: " + data.speed);
            if ((data.speed != null) && (data.speed >= 0) && data.speed >= this.averageHeading.minSpeed) {
                // compute average heading
                if (data.heading != null) {
                    this.averageHeading.sum += data.heading;
                    this.averageHeading.counter += 1;
                    if (this.averageHeading.counter >= this.averageHeading.samples) {
                        this.averageHeading.crt = Math.floor(this.averageHeading.sum / this.averageHeading.counter);
                        this.averageHeading.counter = 0;
                        this.averageHeading.sum = 0;
                        this.averageHeading.ready = true;
                    }

                    if (!this.averageHeading.ready) {
                        // use moving average
                        this.averageHeading.crt = Math.floor(MathUtils.lowPassFilter(this.averageHeading.crt, data.heading, 0.7));
                    }
                }
            }

            // sync
            this.locationMonitor.averageHeading = this.averageHeading;

            if (data.source === EVirtualLocationSource.gps) {
                // set player location (GPS) only
                this.locationMonitor.setRealLocationCache(data.coords);
            }

            if (this.watchMovement) {
                this.watchMovementAction(data);
            }

            if (this.watchMovementGlobal) {
                let incrementalDistance: number = 0;
                let currentLocation: ILatLng = data.coords;

                if (currentLocation != null && this.previousLocationGlobal != null) {
                    incrementalDistance = Math.abs(GeometryUtils.getDistanceBetweenEarthCoordinates(currentLocation, this.previousLocationGlobal, 0));
                }

                if (incrementalDistance < this.maxAbsoluteIncrementalDistance) {
                    // don't add up unusually large coordinate changes
                    this.globalDistanceCounter += incrementalDistance;
                }

                // check dump distance counter
                if (this.prevSource == null) {
                    this.prevSource = data.source;
                }

                if (this.prevGameContext == null) {
                    this.prevGameContext = this.gameContext;
                }

                if ((data.source === this.prevSource) && (this.gameContext === this.prevGameContext)) {
                    this.checkDumpDistanceCounter(data.source, this.gameContext);
                } else {
                    // reset distance counter on switch between source (drone mode, gps navigation), and context (world map, storyline)
                    this.onSwitchLocationSource();
                }

                this.prevSource = data.source;
                this.prevGameContext = this.gameContext;

                if (currentLocation != null) {
                    this.previousLocationGlobal = Object.assign({}, currentLocation);
                }
            }
        }
    }

    setGameContext(gameContext: number) {
        this.gameContext = gameContext;
    }

    /**
     * get average heading for e.g. placing the coins in the direction of travel
     */
    getAverageHeading() {
        return this.averageHeading;
    }

    /**
     * periodic dump distance counter
     */
    checkDumpDistanceCounter(source: number, gameContext: number) {
        let timestamp: number = new Date().getTime();

        if (!this.watchMovementGlobal) {
            return;
        }
        if (!this.lastDumpTimestamp) {
            // should be initialized on start
            return;
        }

        let ts: number = AppConstants.gameConfig.dumpDistanceTimeout;
        // ts = 20000; // test
        if ((timestamp - this.lastDumpTimestamp) > ts) {
            this.dumpDistanceCounter(source, gameContext);
        }
    }

    /**
     * update distance travelled for user account
     * reset distance counter
     */
    dumpDistanceCounter(source: number, gameContext: number) {
        if (source == null) {
            source = this.prevSource;
        }
        if (gameContext == null) {
            gameContext = this.prevGameContext;
        }
        let distanceTravelled: number = Math.floor(this.globalDistanceCounter);
        // console.log("dump distance counter: ", distanceTravelled);
        this.globalDistanceCounter = 0;
        this.lastDumpTimestamp = new Date().getTime();

        if (distanceTravelled) {
            let distdump: IDistanceDump = {
                source: source,
                distance: distanceTravelled,
                gameContext: gameContext
            };
            this.observables.globalDistanceDump.next(distdump);
            this.observables.globalDistanceDump.next(null);
            this.userStatsProvider.updateGlobalDistanceCounter(source, distanceTravelled).then(() => {

            }).catch((err: Error) => {
                console.error(err);
                this.analytics.dispatchError(err, "gmap");
            });
        }
    }

    setTimeoutUpdate() {
        this.timeoutUpdate = ResourceManager.clearTimeout(this.timeoutUpdate);
        this.timeoutUpdate = setTimeout(() => {
            this.moveData.speed = 0;
            this.syncSpeedDisp();
        }, EGPSTimeout.slowUpdateReset);
    }

    syncSpeedDisp() {
        // check for target speed display
        if (this.moveParams.targetSpeed != null) {
            this.moveData.speedDisp = MathUtils.formatSpeedTargetDisp(this.moveData.speed, this.moveParams.targetSpeed);
            this.moveData.speedPercent = Math.floor(this.moveData.speed * 100 / this.moveParams.targetSpeed);
            if (this.moveData.speedPercent > 100) {
                this.moveData.speedPercent = 100;
            }
        }
    }

    clearTimeoutUpdate() {
        this.timeoutUpdate = ResourceManager.clearTimeout(this.timeoutUpdate);
    }

    /**
     * prevent large variations caused by returning from bg mode (esp. when bg location is not enabled)
     */
    checkValidSpeedFromDistanceDelta(dd: number, dt: number) {
        if ((dt !== 0) && ((dd < this.maxIncrementalDistance) || (dt > this.maxIncrementalDistanceMinDT))) {
            return true;
        }
        return false;
    }

    computeMaxDistanceIncrement() {
        // v = dd/dt
        // dd = v*dt
        this.maxIncrementalDistance = AppConstants.gameConfig.maxSpeedGPS * this.maxIncrementalDistanceMinDT;
        console.log("compute max distance increment: ", this.maxIncrementalDistance);
    }

    watchMovementAction(data: IVirtualLocation) {
        console.log("watch move action: ", data);

        let dd: number = 0;
        let dt: number = 0;

        let currentLocation: ILatLng = data.coords;

        if (currentLocation != null && this.previousLocation != null) {
            dd = Math.abs(GeometryUtils.getDistanceBetweenEarthCoordinates(currentLocation, this.previousLocation, 0));
        }

        let t1: number = new Date().getTime();

        if (this.t0 != null) {
            dt = (t1 - this.t0) / 1000;
        }

        this.t0 = t1;

        // console.log(incrementalDistance);
        let speed: number = 0;

        // this.running.previousLocation, this.running.currentLocation
        if (this.testDistanceWatch) {
            let testTs: number = new Date().getTime();
            // get the actual increment and speed based on the actual sampling time
            let dt: number = (testTs - this.testTsIncrement) / 1000;
            let dd1: number = this.testDistanceIncrement * dt;
            speed = dd1 - Math.floor(Math.random() * dd1 / 2);
            this.testTsIncrement = testTs;
            dd = speed * dt;
        } else {
            switch (this.moveParams.speedMode) {
                case ESpeedMode.speed:
                    if ((data.speed != null) && (data.speed >= 0)) {
                        speed = data.speed;
                        console.log("using GPS speed=" + speed);
                    } else {
                        // speed not available, calculate from distance and dt
                        if (this.checkValidSpeedFromDistanceDelta(dd, dt)) {
                            speed = dd / dt;
                            console.log("using incremental distance dd=" + dd + ", dt=" + dt + ", speed=" + speed);
                        }
                    }
                    break;
                case ESpeedMode.position:
                    if (this.checkValidSpeedFromDistanceDelta(dd, dt)) {
                        speed = dd / dt;
                        console.log("using incremental distance dd=" + dd + ", dt=" + dt + ", speed=" + speed);
                    }
                    break;
            }
        }

        let validMovement: boolean = true;

        if (data.source !== EVirtualLocationSource.drone) {
            // gps mode
            if ((AppConstants.gameConfig.maxSpeedGPS != null) && (speed > AppConstants.gameConfig.maxSpeedGPS)) {
                this.moveData.speed = 0;
                validMovement = false;
                console.log("GPS error, speed above threshold, not counting distance");
            } else {
                // get the movement speed 
                this.moveData.speed = speed * 3.6; // km/h
            }
        } else {
            // drone mode
            // get the movement speed 
            this.moveData.speed = speed * 3.6; // km/h
        }

        if (this.previousLocation != null) {
            if (validMovement) {
                if (this.moveParams.targetSpeed != null) {
                    // check for the required validation speed
                    if (this.moveData.speed >= this.moveParams.targetSpeed) {
                        this.timeoutMonitor.setAuxCounter(true);
                        this.currentDistance += dd;
                    } else {
                        // don't count elapsed time for average speed
                        this.timeoutMonitor.setAuxCounter(false);
                    }
                } else {
                    this.timeoutMonitor.setAuxCounter(true);
                    this.currentDistance += dd;
                }
            }
        }

        // console.log("location monitor: dd: " + dd + ", speed: " + this.moveData.speed + ", target speed: " + this.moveParams.targetSpeed + ", distance: " + this.moveData.distance);

        this.moveData.distance = this.currentDistance;

        // movement status state machine
        // format for display
        switch (this.moveData.status) {
            case EMoveActivityStatus.started:

                // check target distance required (not required for endurance run)
                if (this.moveParams.targetDistance != null) {
                    // format distance
                    this.moveData.distanceDisp = MathUtils.formatDistanceTargetDisp(this.moveData.distance, this.moveParams.targetDistance);

                    this.moveData.distancePercent = Math.floor(this.moveData.distance * 100 / this.moveParams.targetDistance);
                    if (this.moveData.distancePercent > 100) {
                        this.moveData.distancePercent = 100;
                    }

                    // check for target distance reached
                    if (this.moveData.distance >= this.moveParams.targetDistance) {
                        this.setDone();
                    }
                } else {
                    // format distance
                    this.moveData.distanceDisp = MathUtils.formatDistanceDisp(this.moveData.distance).disp;
                }

                this.syncSpeedDisp();
                this.setTimeoutUpdate();

                if (data.source !== EVirtualLocationSource.drone) {
                    // check for overspeed (drone mode excluded)
                    if (this.moveParams.speedHigh != null && (this.moveData.speed > this.moveParams.speedHigh)) {
                        this.moveData.cheatCounter++;
                        if (this.moveData.cheatCounter > 5) {
                            this.moveData.status = EMoveActivityStatus.cheated;
                        }
                    } else {
                        this.moveData.cheatCounter = 0;
                    }
                }

                break;
            case EMoveActivityStatus.done:
                console.log("location monitor done");
                break;
            case EMoveActivityStatus.cheated:
                console.log("location monitor cheated");
                break;
            case EMoveActivityStatus.failed:
                console.log("location monitor failed");
                break;
            default:
                break;
        }

        if (currentLocation != null) {
            this.previousLocation = Object.assign({}, currentLocation);
        }

        // if there is no timeout for the current activity, then update the position here
        if (!this.moveParams.timeLimit) {
            this.updatePositionObs(false);
        }
    }

    /**
     * using timeout provider
     */
    start(params: IMoveMonitorParams) {
        console.log("start movement activity: ", params);

        this.computeMaxDistanceIncrement();

        this.moveParams = params;
        this.moveData = Object.assign({}, this.moveDataInit);
        this.moveData.timerValue = this.moveParams.timeLimit;

        // desired seconds or time intervals/iterations
        let targetTimeTest: number = 10;
        if (params.targetDistance != null) {
            this.testDistanceIncrement = params.targetDistance / targetTimeTest;
            // target distance is in meters, target speed is in km/h, so there is a conversion here
            // the speed will be limited to the max allowed speed, so the target time limit might be higher in the end
            if (this.testDistanceIncrement > (params.speedHigh / 3.6)) {
                this.testDistanceIncrement = params.speedHigh / 3.6 - 0.001;
            }
            console.log("test distance increment: " + this.testDistanceIncrement);
        } else {
            if (params.timeLimit != null) {
                this.testDistanceIncrement = params.speedHigh / 3.6 - 0.001;
            }
        }
        this.currentDistance = 0;
        this.observables.moveActivity.next(null);
        if (params.targetSpeed) {
            // only count elapsed time while running
            this.timeoutMonitor.setAuxCounter(false);
        } else {
            // count total elapsed time
            this.timeoutMonitor.setAuxCounter(true);
        }
        this.startTimer();
        this.watchMovement = true;
    }

    /**
    * the main loop is based on time limit
    */
    startTimer() {
        if (this.moveTimer) {
            return;
        }
        if (!this.moveParams.timeLimit) {
            return;
        }

        let tmParams: ITimeoutMonitorParams = {
            timeLimit: this.moveParams.timeLimit
        };

        this.timeoutMonitor.start(tmParams);

        // the averaging time constant is related to the time limit
        // let alpha: number = MathUtils.computeAlphaFromTimeConstant(1, this.moveParams.timeLimit / 2);

        this.moveTimer = this.timeoutMonitor.getWatch().subscribe((tmData: ITimeoutMonitorData) => {
            if (tmData) {
                if (!tmData.isFallbackTimer) {
                    this.moveData.timerValue = tmData.timerValue;
                    this.moveData.timerDisp = tmData.timerDisp;
                    this.moveData.elapsedValue = tmData.elapsedValue;
                    this.moveData.elapsedValueAux = tmData.elapsedValueAux;
                }
                // console.log(this.moveData);

                // check for time expired
                if (this.moveParams.timeLimit != null) {
                    if (this.moveData.timerValue <= 0) {
                        console.log("move timeout expired from timer");

                        // check target distance required (not required for endurance run)
                        // if not required, then the time expired should trigger activity finished instead
                        if (this.moveParams.targetDistance) {
                            this.moveData.status = EMoveActivityStatus.failed;
                        } else {
                            this.setDone();
                        }
                    }
                }

                if (this.moveData.status !== EMoveActivityStatus.done) {
                    if (this.moveData.elapsedValueAux) {
                        // this is correct, even  though in browser testing it seems to be higher than expected
                        // that's because of the assumed speed = incremental distance (considering sampling time = 1)
                        this.moveData.averageSpeed = this.moveData.distance * 3.6 / this.moveData.elapsedValueAux;
                        // console.log(this.moveData.speed, this.moveData.distance, this.moveData.elapsedValue, this.moveData.averageSpeed);
                    }
                }

                // update position on timer, even if the gps is not available at the moment
                this.updatePositionObs(false);
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    getMoveActivityWatch(): BehaviorSubject<IMoveMonitorData> {
        return this.observables.moveActivity;
    }

    getMoveActivityData(): IMoveMonitorData {
        return this.moveData;
    }

    getGlobalDistanceDumpWatch(): BehaviorSubject<IDistanceDump> {
        return this.observables.globalDistanceDump;
    }

    triggerExpired() {
        this.timeoutMonitor.triggerExpired();
    }

    /**
     * define a min refresh rate
     */
    updatePositionObs(delay: boolean) {
        // console.log("update position obs");
        let crt: number = new Date().getTime();
        if (!delay || ((crt - this.lastUpdateTimestamp) > 3000)) {
            this.lastUpdateTimestamp = crt;
            this.observables.moveActivity.next(this.moveData);
        }
    }

    /**
    * reset distance monitor
    * @param test 
    */
    resetDistanceWatch(test: boolean) {
        this.currentDistance = 0;
        this.previousLocation = null;
        this.watchMovement = true;
        this.testDistanceWatch = test;
    }


    /**
     * start global odometer
     */
    startGlobalDistanceWatch() {
        this.lastDumpTimestamp = new Date().getTime();
        this.watchMovementGlobal = true;
        this.previousLocationGlobal = null;
        this.observables.globalDistanceDump.next(null);
    }

    /**
     * reset distance counter on switch between e.g. drone mode and gps navigation
     */
    onSwitchLocationSource() {
        this.currentDistance = 0;
        this.globalDistanceCounter = 0;
        this.previousLocation = null;
        this.startGlobalDistanceWatch();
    }

    /**
     * stop global odometer
     */
    stopGlobalDistanceWatch() {
        this.watchMovementGlobal = false;
        this.previousLocationGlobal = null;
        this.globalDistanceCounter = 0;
        this.observables.globalDistanceDump.next(null);
    }

    /**
    * stop distance monitor
    */
    stopDistanceWatch() {
        this.watchMovement = false;
    }

    stopTimer() {
        if (this.moveTimer) {
            this.moveTimer.unsubscribe();
            this.moveTimer = null;
        }
        console.log("stopping timer");
        this.timeoutMonitor.stop();
    }

    /**
     * complete move activity 
     * compute stats e.g. average speed
     */
    setDone() {
        if (this.moveData.elapsedValueAux) {
            this.moveData.averageSpeed = this.moveData.distance * 3.6 / this.moveData.elapsedValueAux;
        }
        this.moveData.status = EMoveActivityStatus.done;
    }

    stop() {
        console.log("stop movement activity");
        this.moveParams = null;
        this.t0 = null;
        this.stopDistanceWatch();
        this.stopTimer();
        this.clearTimeoutUpdate();
    }
}




