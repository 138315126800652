import { IActivity, EActivityCodes, IFindActivityDef, IExploreActivityDef } from "../def/core/activity";
import { ILocationContainer } from "../def/places/backend-location";
import { IAppLocation } from "../def/places/app-location";
import { ECustomParam, ICustomParamForActivity } from "../def/core/custom-param";
import { IActivityProviderContainer, EActivityProgressDisplay } from '../def/core/activity-manager';
import { IDynamicParamActivityParam } from '../def/nav-params/activity-details';
import { IIconSpec, EActivityIconsCustom, EActivityIcons } from '../def/app/icons';
import { IHudElement, HudUtils, EMapHudCodes } from "../def/app/hud";
import { EDroneMode } from "src/app/services/data/activities";

export class ActivityUtils {
    /**
     * check similar activity in terms of apis used
     * e.g. running is the same as cycling
     * @param activity 
     */
    static checkSimilarActivity(activity: IActivity) {
        if (!activity) {
            return null;
        }
        if (activity.similarCode != null) {
            return activity.similarCode;
        }
        return activity.code;
    }

    static checkActivityParams(params: IExploreActivityDef, customParams: ICustomParamForActivity[]) {
        if (params.timeLimit) {
            if (customParams && customParams.length > 0) {
                if (customParams.find(cpar => cpar.code === ECustomParam.noTimeLimit)) {
                    params.timeLimit = 36000;
                }
            }
        }
    }


    /**
     * check if the activity has similar core mechanics to the find activity
     * e.g. snapshot activity should be more or less the same
     * @param loc 
     */
    static isFindTypeActivity(loc: ILocationContainer) {
        if (loc.merged.activity.code === EActivityCodes.find) {
            return true;
        }
        if (loc.merged.activity.similarCode === EActivityCodes.find) {
            return true;
        }
        return false;
    }

    static isHiddenLoc(loc: ILocationContainer) {
        let hidden: boolean = false;
        if (loc.merged.activity.hiddenPlace) {
            hidden = true;
        }
        if (ActivityUtils.isFindTypeActivity(loc)) {
            hidden = true;
        }
        return hidden;
    }

    /**
     * check if the activity has similar core mechanics to the photo activity
     * e.g. snapshot activity should be more or less the same
     * @param loc 
     */
    static isPhotoTypeActivity(loc: ILocationContainer) {
        let photoTypes: number[] = [EActivityCodes.photo, EActivityCodes.snapshot];
        if (photoTypes.indexOf(loc.merged.activity.code) !== -1) {
            return true;
        }
        if (photoTypes.indexOf(loc.merged.activity.similarCode) !== -1) {
            return true;
        }
        return false;
    }

    static getStartRadius(bloc: ILocationContainer) {
        let startRadius: number = bloc.merged.startRadius;
        let circleDestination: boolean = ActivityUtils.isHiddenLoc(bloc);
        if (circleDestination) {
            let activityParams: IFindActivityDef = bloc.merged.activity.params;
            if (activityParams != null) {
                startRadius = activityParams.circleRadius;
            } else {
                circleDestination = false;
            }
        }
        return startRadius;
    }

    static getStartRadiusOrDefault(bloc: ILocationContainer, defaultRadius: number) {
        let startRadius: number = ActivityUtils.getStartRadius(bloc);
        if (startRadius == null) {
            startRadius = defaultRadius;
        }
        return startRadius;
    }


    /**
     * exclude saved locations from the next place search in the story
     */
    static getExcludeListFromSavedLocations(storyLocations: IAppLocation[]) {
        let excludeLocationIdList: string[] = [];
        for (let i = 0; i < storyLocations.length; i++) {
            let location: IAppLocation = storyLocations[i];
            if (location.loc.merged.googleId != null) {
                excludeLocationIdList.push(location.loc.merged.googleId);
            }
        }
        return excludeLocationIdList;
    }


    /**
    * get custom param slides (for display in slider component)
    * @param activity 
    */
    static getCustomParamSlides(activity: IActivity, scope: number) {
        let paramSlides: IDynamicParamActivityParam[] = [];
        if (activity && (activity.hasCustomParams === 1) && activity.customParams) {
            for (let i = 0; i < activity.customParams.length; i++) {
                let cpar: ICustomParamForActivity = activity.customParams[i];
                cpar.scope = scope;
                paramSlides.push({
                    data: cpar,
                    showLabel: false
                });
            }
        }
        return paramSlides;
    }


    static checkMultiDisp(activityContainer: IActivityProviderContainer) {
        // check for special disp elements that should be shown in separate view
        let multiDisp: boolean = false;
        let keys: string[] = Object.keys(activityContainer.validateDisp);
        for (let i = 0; i < keys.length; i++) {
            let found: boolean = false;
            switch (activityContainer.validateDisp[keys[i]].mode) {
                case EActivityProgressDisplay.soundButton:
                    multiDisp = true;
                    found = true;
                    break;
                default:
                    break;
            }
            if (found) {
                break;
            }
        }
        return multiDisp;
    }

    static getIcon(activityLabel: string): IIconSpec {
        let res: IIconSpec = {
            icon: "",
            custom: false
        };
        if (EActivityIconsCustom[activityLabel]) {
            res.icon = EActivityIconsCustom[activityLabel];
            res.custom = true;
            return res;
        } else {
            res.icon = EActivityIcons[activityLabel];
            return res;
        }
    }

    static getDroneModeAvailable(activity: IActivity, droneOnlyStory: boolean): number {
        let activityDroneMode: number = activity.enableDrone;
        if (droneOnlyStory && (activityDroneMode !== EDroneMode.noDrone)) {
            // enforce drone only if required in story
            activityDroneMode = EDroneMode.onlyDrone;
        }
        let activityNoDroneMode: number = EDroneMode.noDrone;
        let baseActivityCode: number = ActivityUtils.checkSimilarActivity(activity);
        let droneActivities: number[] = [
            EActivityCodes.walk,
            EActivityCodes.run,
            EActivityCodes.enduranceRun,
            EActivityCodes.explore,
            EActivityCodes.escape,
            EActivityCodes.find
        ];
        if (droneActivities.indexOf(baseActivityCode) !== -1) {
            return activityDroneMode;
        }
        return activityNoDroneMode;
    }

    static getSalesAvailable(activity: IActivity): boolean {
        let salesAvailable: boolean = activity.enableSales === 1;
        return salesAvailable;
    }

    /**
    * show/hide HUD elements based on the current activity scope
    * @param activity 
    * @param hud 
    */
    static getContextHudDisplayEnabled(activity: IActivity, hud: IHudElement[]) {
        let baseActivityCode: number = ActivityUtils.checkSimilarActivity(activity);
        // HudUtils.setHudShowAll(hud, true);
        let mode = HudUtils.getHudDisplayModes();
        HudUtils.setHudHighlightAll(hud, mode.default);
        switch (baseActivityCode) {
            case EActivityCodes.run:
                HudUtils.setHudShow(hud, EMapHudCodes.compassHeading, false);
                HudUtils.setHudHighlight(hud, EMapHudCodes.timer, mode.key);
                HudUtils.setHudHighlight(hud, EMapHudCodes.targetDistanceMove, mode.key);
                HudUtils.setHudHighlight(hud, EMapHudCodes.currentDistanceMove, mode.key);
                HudUtils.setHudHighlight(hud, EMapHudCodes.targetSpeedMove, mode.key);
                HudUtils.setHudHighlight(hud, EMapHudCodes.currentSpeedMove, mode.key);
                break;
            case EActivityCodes.walk:
                HudUtils.setHudShow(hud, EMapHudCodes.compassHeading, false);
                HudUtils.setHudHighlight(hud, EMapHudCodes.targetDistanceMove, mode.key);
                HudUtils.setHudHighlight(hud, EMapHudCodes.currentDistanceMove, mode.key);
                break;
            case EActivityCodes.explore:
                // handled by the map
                HudUtils.setHudShow(hud, EMapHudCodes.compassHeading, false);
                HudUtils.setHudShow(hud, EMapHudCodes.targetSpeedMove, false);
                HudUtils.setHudShow(hud, EMapHudCodes.currentSpeedMove, false);
                HudUtils.setHudHighlight(hud, EMapHudCodes.timer, mode.key);
                HudUtils.setHudHighlight(hud, EMapHudCodes.collectedCoins, mode.key);
                break;
            case EActivityCodes.find:
                // handled by the map
                HudUtils.setHudShow(hud, EMapHudCodes.compassHeading, false);
                // will be shown on search radius reached
                HudUtils.setHudUnlocked(hud, EMapHudCodes.compassHeading, true);
                HudUtils.setHudShow(hud, EMapHudCodes.targetSpeedMove, false);
                HudUtils.setHudShow(hud, EMapHudCodes.currentSpeedMove, false);
                HudUtils.setHudHighlight(hud, EMapHudCodes.timer, mode.key);
                HudUtils.setHudHighlight(hud, EMapHudCodes.navigateDistanceToTarget, mode.key);
                break;
            case EActivityCodes.snapshot:
                HudUtils.setHudShow(hud, EMapHudCodes.targetSpeedMove, false);
                HudUtils.setHudShow(hud, EMapHudCodes.currentSpeedMove, false);
                HudUtils.setHudHighlight(hud, EMapHudCodes.timer, mode.key);
                break;
            case EActivityCodes.photo:
                HudUtils.setHudShow(hud, EMapHudCodes.targetSpeedMove, false);
                HudUtils.setHudShow(hud, EMapHudCodes.currentSpeedMove, false);
                HudUtils.setHudHighlight(hud, EMapHudCodes.timer, mode.key);
                break;
            case EActivityCodes.screenshotAR:
                HudUtils.setHudShow(hud, EMapHudCodes.targetSpeedMove, false);
                HudUtils.setHudShow(hud, EMapHudCodes.currentSpeedMove, false);
                HudUtils.setHudHighlight(hud, EMapHudCodes.timer, mode.key);
                break;
            case EActivityCodes.eat:
            case EActivityCodes.drink:
            case EActivityCodes.visit:
                HudUtils.setHudHighlight(hud, EMapHudCodes.timer, mode.key);
                break;
            default:
                break;
        }
    }
}
