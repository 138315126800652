import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { TreasureHomePage } from './treasure-home.page';
import { PagesModule } from '../pages.module';

const routes: Routes = [
  {
    path: '',
    component: TreasureHomePage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [TreasureHomePage]
})
export class TreasureHomePageModule { }
