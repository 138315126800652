<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="!enableBackButton" [title]="title" leftButton="arrow-back" [leftIcon]="true"
    (leftClick)="back(false)" rightButton="settings" [rightIcon]="true" (rightClick)="presentPopover()"
    [loading]="!loaded">
  </leplace-toolbar>
  <!-- <ion-searchbar class="custom-searchbar" color="primary" ngDefaultControl [(ngModel)]="queryText" (ionInput)="findStory()" placeholder="Search">
        </ion-searchbar> -->
</ion-header>

<ion-content [ngClass]="theme">
  <div class="view-frame theme-background" fxLayout="column">

    <ion-grid class="full-w stick-top padding-top-s theme-border-bottom padding-bottom-none"
      *ngIf="category && showState">
      <ion-row *ngIf="!localStories">
        <ion-col size="12">
          <span class="info-text-m fab-display-top bold text-color-primary">{{category.name |
            uppercase}} {{selectedDisp}}</span>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="localStories" class="ion-align-items-center" tappable (click)="openLocationSelector()">
        <ion-col size="10">
          <span class="info-text-s fab-display-top bold text-color-primary">{{category.name |
            uppercase}} {{selectedDisp}}</span>
        </ion-col>
        <ion-col size="2" class="text-right">
          <!-- action-button-icon-only -->
          <!-- <ion-button class="action-button-icon-only margin-right-s button-color-alternate">
            <icon-wrapper slot="icon-only" [icon]="appIcons.gpsFixed" [custom]="true"></icon-wrapper>
          </ion-button> -->

          <blink-button [icon]="appIcons.city" [customIcon]="true" [setTimeout]="tutorialSeen" [set]="!tutorialSeen && !openedCitySelector"
            theme="primary" customBaseClass="action-button-xs" [noBorder]="false">
          </blink-button>

          <!-- <ion-button class="as-text element-selector info-text-s text-color-primary" (click)="openLocationSelector()">
            <icon-wrapper slot="icon-only" [icon]="appIcons.gpsFixed" [custom]="true"></icon-wrapper>
          </ion-button> -->
        </ion-col>
      </ion-row>
    </ion-grid>


    <!-- (swipe)="swipeEvent($event)" -->
    <div class="margin-top-s" [ngClass]="localStories ? 'scroll-type-3':'scroll-type-2'" #scrollContent>

      <div *ngIf="emptyResult" class="padding-s center-text">
        <span class="center-text info-text-m bold text-color-primary">No stories found</span>
      </div>

      <ion-list [@showState]="showState" class="list-padding">
        <ion-item class="list-item list-item-card-padding transparent-bg"
          *ngFor="let storyWithProgress of storiesWithProgress" #slidingItem [hidden]="storyWithProgress.hide">
          <story-info [data]="storyWithProgress" class="icon-color-primary full-w" tappable
            [showCategory]="globalCategory" [large]="storyWithProgress.large"
            (click)="goToStoryDetail(storyWithProgress.story)" [reload]="reloadItems"></story-info>
        </ion-item>
      </ion-list>
    </div>

    <div fxFlex></div>

    <div class="padding-horizontal-s theme-border-top padding-bottom-xs">
      <leplace-paginator [pagination]="pagination" (onSwitch)="doRefresh($event, false)"></leplace-paginator>
    </div>

  </div>

</ion-content>