import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { EPhotos } from 'src/app/classes/def/app/icons';
import { IKVStat } from 'src/app/classes/def/app/kval';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { IBLEDevice } from 'src/app/services/map/ble-utils';

@Component({
  selector: 'leplace-beacon',
  templateUrl: './leplace-beacon.component.html',
  styleUrls: ['./leplace-beacon.component.scss'],
})
export class LeplaceBeaconComponent implements OnInit, OnChanges, OnDestroy {
  id: string;
  stats: IKVStat[] = [];
  bgPhoto: string = EPhotos.userPlaceholder;

  @Input()
  data: IBLEDevice;

  @Input()
  reload: boolean;

  constructor(
    public uiext: UiExtensionService
  ) {

  }

  ngOnInit() {
    this.syncTable();
  }

  syncTable() {
    if (this.data) {
      this.id = this.data.id;
      this.stats = [];
      if (this.data.name) {
        this.stats.push({
          name: "Name",
          value: null,
          valueString: this.data.name
        });
      }
      this.stats = this.stats.concat([
        {
          name: "Tracked",
          value: 0,
          valueString: "" + this.data.registered
        },
        {
          name: "Ping (cnt)",
          value: this.data.pingCount,
          valueString: "" + this.data.pingCount
        },
        {
          name: "RSSI (raw)",
          value: this.data.rssi,
          valueString: "" + this.data.rssi
        },
        {
          name: "RSSI (flt)",
          value: Math.floor(this.data.rssiFiltered),
          valueString: "" + Math.floor(this.data.rssiFiltered)
        },
        {
          name: "Dist (mtr)",
          value: this.data.dist,
          valueString: "" + ((this.data.dist != null) ? this.data.dist : -1)
        }
      ]);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'data':
            this.syncTable();
            break;
          case 'reload':
            this.syncTable();
            break;
        }
      }
    }
  }

  ngOnDestroy() {

  }
}
