import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ModalController } from '@ionic/angular';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { PremiumDataService } from 'src/app/services/data/premium';
import { ParamHandler } from 'src/app/classes/general/params';
import { IActivityFailed } from 'src/app/classes/def/nav-params/activity';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { EFeatureCode, IFeatureDef } from 'src/app/classes/def/app/app';
import { EStandardCode, EFinishedActionParams } from 'src/app/classes/def/core/activity';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EStatCodes, IStatDef } from 'src/app/classes/def/user/stats';

@Component({
  selector: 'modal-activity-failed',
  templateUrl: './activity-failed.component.html',
  styleUrls: ['./activity-failed.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityFailedViewComponent implements OnInit, OnDestroy {
  theme: string = "theme-aubergine theme-aubergine-bg";
  title: string = "Challenge Failed";
  infoHTML: string = "";

  retryMessage: string = "Retry";
  vs: IViewSpecs = ViewSpecs.getDefault();

  retryEnabled: boolean = true;

  photoUrl: string = null;
  photoUrlLoaded: string = null;
  standardCode: number = 0;
  xpScaleFactor: number = null;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public resourcesProvider: ResourcesCoreDataService,
    public premiumProvider: PremiumDataService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IActivityFailed = np.params;
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);
      this.infoHTML = params.infoHTML;
      this.retryEnabled = params.retryEnabled;
      this.xpScaleFactor = params.xpScaleFactor;
      this.standardCode = params.reasonCode;
      console.log("activity failed code: ", this.standardCode);
      this.getStandardPhoto();
    }

    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    this.premiumProvider.checkFeaturePrice(EFeatureCode.retryActivity, null, false).then((price: IFeatureDef) => {
      if (price) {
        let amount: number = price.price;
        this.retryMessage += " (" + amount + " Eg)";
        if (this.retryEnabled) {
          let weight: number = null;
          let challengesRestarted: IStatDef = GeneralCache.resourceCache.general.statScores ? GeneralCache.resourceCache.general.statScores.content.find(s => s.code === EStatCodes.challengesRestarted) : null;
          if (challengesRestarted != null) {
            weight = challengesRestarted.weight;
          }
          if (this.xpScaleFactor != null) {
            weight *= this.xpScaleFactor;
          }
          this.infoHTML += "<p>Retry (" + amount + " energy" + ((weight != null) ? "/" + weight + " XP)" : ")");
        }
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngOnDestroy() {

  }

  getStandardPhoto() {
    if (!this.standardCode) {
      return;
    }
    this.resourcesProvider.getStandardPhotoByCode(EStandardCode.activityFailed + this.standardCode).then((photoUrl: string) => {
      this.photoUrl = photoUrl;
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }


  /**
   * dismiss modal
   */
  continue() {
    this.dismiss(EFinishedActionParams.default);
  }


  /**
  * dismiss modal
  */
  retry() {
    this.dismiss(EFinishedActionParams.retry);
  }

}
