import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { EPhotos, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { IAppFlags, ELocalAppDataKeys, AppFlags, IAppFlagsElement } from 'src/app/classes/def/app/storage-flags';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { Platform } from '@ionic/angular';
import { Market } from '@ionic-native/market/ngx';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { Messages } from 'src/app/classes/def/app/messages';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { IUserFlagDB } from 'src/app/classes/def/app/settings';
import { AppConstants } from 'src/app/classes/app/constants';
import { IGmapEntryNavParams, EGmapMode } from 'src/app/classes/def/nav-params/gmap';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { IAppVersionDB } from 'src/app/classes/def/app/app-version';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { StoryDataService } from 'src/app/services/data/story';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { NotificationsService } from 'src/app/services/general/apis/notifications';
import { UserDataService } from 'src/app/services/data/user';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { LiveUpdateService } from 'src/app/services/general/apis/live-update';
import { LiveUpdateCoreService } from 'src/app/services/general/apis/live-update-core';
import { NewsfeedDataService } from 'src/app/services/data/newsfeed';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { GenericQueueService } from 'src/app/services/general/generic-queue';
import { StorageOpsService } from 'src/app/services/general/data/storage-ops';
import { ResourceHandlerDataService } from 'src/app/services/data/resource-handler';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { ERouteDef } from 'src/app/app-utils';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { PhotosService } from 'src/app/services/media/photos';
import { PhotoViewService } from 'src/app/services/general/apis/photo-view';
import { IUserPublicData } from 'src/app/classes/def/user/general';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { AppVersionService } from 'src/app/services/general/app-version';
import { InventoryDataService } from 'src/app/services/data/inventory';
import { IGameItemIAP } from 'src/app/classes/def/items/game-item';
import { SleepUtils } from 'src/app/services/utils/sleep-utils';
import { IViewPhotoParams } from 'src/app/classes/def/nav-params/photo-view';
import { EOS } from 'src/app/classes/def/app/app';
import { ETrackedEvents } from 'src/app/classes/app/analytics';
import { Params, Router } from '@angular/router';
import { IDescriptionFrameParams, EDescriptionViewStyle } from 'src/app/modals/generic/modals/description-frame/description-frame.component';
import { PromiseUtils } from 'src/app/services/utils/promise-utils';
import { LocalNotificationsService } from 'src/app/services/general/apis/local-notifications';
import { SupportModalsService } from 'src/app/services/app/modules/minor/support-modals';
import { LocationPermissionManagerService } from 'src/app/services/general/permissions/location-permission-manager';
import { BackgroundModeService } from 'src/app/services/general/apis/background-mode';
import { StorageFlagsService } from 'src/app/services/general/data/storage-flags';

import { ISetCountrySendPromotionalEmail } from 'src/app/classes/def/promo/storyline';
import { IPromoStoriesParams, PromoStoriesViewComponent } from 'src/app/modals/app/modals/promo-stories/promo-stories.component';
import { IMasterLockResponse, IStory } from 'src/app/classes/def/core/story';
import { IStoryListNavParams } from 'src/app/classes/def/nav-params/story';
import { Util } from 'src/app/classes/general/util';
import { TestingManagerService } from 'src/app/services/general/testing-manager';
import { MQTTService } from 'src/app/services/telemetry/mqtt.service';
import { IPhotoResult } from 'src/app/classes/def/media/photo';
import { EConnectLinks } from 'src/app/classes/app/api';
import { IAPCoreService } from 'src/app/services/apis/iap-rc';
import { ILatLng } from 'src/app/classes/def/map/coords';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';


enum EHomeActiveMode {
  none = 0,
  worldMap = 1,
  storyline = 2
}

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class HomePage implements OnInit, OnDestroy {
  test: boolean = false;
  logoSrc: string = EPhotos.logo;
  // logoSrc: string = EPhotos.bike;
  backgroundSrc: string = EPhotos.home;
  periodic = null;
  backgroundLoaded: boolean = false;
  vs: IViewSpecs;
  loading: boolean = false;

  version: string = "1.0.0";

  platform: IPlatformFlags = {} as IPlatformFlags;

  theme: string = "theme-aubergine theme-aubergine-bg";

  subscription = {
    liveUpdateStatus: null,
    liveUpdateProgress: null
  };

  inboxCount: number = 0;

  blink = {
    inbox: false,
    featured: false,
    account: false,
    support: false,
    map: false
  };

  np: INavParams = null;

  fadeClass = {
    // init: "linear-gradient(-45deg, rgba(235,178,74,0.1) 25%, rgba(30,114,163,0.1) 80%, rgba(30,114,163,0.1) 100%)",
    init: null,
    worldMapSelected: "linear-gradient(-45deg, rgba(235,178,74,0.3) 25%, rgba(30,114,163,0.7) 80%, rgba(30,114,163,0.6) 100%)",
    storylineSelected: "linear-gradient(135deg, rgba(235,178,74,0.4) 25%, rgba(30,114,163,0.7) 80%, rgba(30,114,163,0.8) 100%)"
  };

  activeMode: number = EHomeActiveMode.none;
  activeFadeClass: string = null;

  btnPrimaryClass: string = "button-60 button-color-primary-70";
  btnAlternateClass: string = "button-60 button-color-alternate-70";

  btnPrimaryTheme: string = "primary70";
  btnAlternateTheme: string = "alternate70";

  btnWorldMapClass: string = "button-60 button-color-primary-70";
  btnStorylineClass: string = "button-60 button-color-primary-70";

  btnWorldMapTheme: string = "primary70";
  btnStorylineTheme: string = "primary70";

  setBlinkStart: boolean = false;
  plateSub: string = "";

  location: ILatLng;

  constructor(
    public settingsProvider: SettingsManagerService,
    public storyDataProvider: StoryDataService,
    public resourcesProvider: ResourcesCoreDataService,
    public router: Router,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public uiext: UiExtensionService,
    public locationManager: LocationManagerService,
    public locationPermissionManager: LocationPermissionManagerService,
    public market: Market,
    public backButton: BackButtonService,
    public notifications: NotificationsService,
    public userDataProvider: UserDataService,
    public analytics: AnalyticsService,
    public liveupdate: LiveUpdateService,
    public liveUpdateCore: LiveUpdateCoreService,
    public newsfeed: NewsfeedDataService,
    public popupFeatures: PopupFeaturesService,
    public q: GenericQueueService,
    public storageOps: StorageOpsService,
    public storageFlags: StorageFlagsService,
    public resourceHandler: ResourceHandlerDataService,
    public tutorials: TutorialsService,
    public photoProvider: PhotosService,
    public photoViewer: PhotoViewService,
    public nps: NavParamsService,
    public resources: ResourcesCoreDataService,
    public appVersionService: AppVersionService,
    public inventory: InventoryDataService,
    public iapService: IAPCoreService,
    public iapRC: IAPCoreService,
    public localNotifications: LocalNotificationsService,
    public supportModals: SupportModalsService,
    public backgroundModeProvider: BackgroundModeService,
    public testingManager: TestingManagerService,
    public userData: UserDataService,
    public mqttService: MQTTService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  initIapChain() {
    this.inventory.getItemIapList().then((iapItems: IGameItemIAP[]) => {
      // this.iapService.initChain(iapItems);
      this.iapService.registerIapItems(iapItems);
    }).catch((err: Error) => {
      console.error(err);
      // retry loading iap items
      SleepUtils.sleep(5000).then(() => {
        this.initIapChain();
      });
    });
  }


  /**
   * get db flags (used to sync across user devices)
   * WARNING: overrides local settings
   */
  getServerFlags() {
    this.userDataProvider.getAllFlagsServer(false).then((flags: IUserFlagDB[]) => {
      if (flags) {
        let synced: number = 0;
        for (let flag of flags) {
          synced += this.settingsProvider.updateSettingsElement(flag.code, flag.value) ? 1 : 0;
        }
        // override local settings
        this.settingsProvider.updateLocalSettingsNoAction();
        console.log("synced flags from server: " + synced + "/" + flags.length);
      }
    });
  }


  /**
   * check flags for app rate, signup agreement, etc
   */
  checkInitSequence() {
    let promise = new Promise(async (resolve) => {
      console.log("HOME INIT: check init sequence");
      // check storage flags for app rate flags (already loaded before)

      // check app updated via code push
      if (AppFlags.generalFlags.codePushUpdated.value) {
        AppFlags.generalFlags.codePushUpdated.value = false;
        this.storageOps.setStorageFlagNoAction(AppFlags.generalFlags.codePushUpdated); // reset flag
        await PromiseUtils.wrapResolve(this.uiext.showAlert(Messages.msg.codePushUpdated.after.msg, Messages.msg.codePushUpdated.after.sub, 1, null, true), true);
      }

      // check for agreement signed
      if (GeneralCache.agreementPending) {
        GeneralCache.agreementPending = false;
        let accept: boolean = await PromiseUtils.wrapResolve(this.tutorials.showSignupTerms(true), true);
        if (accept === false) {
          await PromiseUtils.wrapResolve(this.uiext.showAlert(Messages.msg.termsNotAgreed.after.msg, Messages.msg.termsNotAgreed.after.sub, 2, null), true);
        }
      }

      // check inbox
      await PromiseUtils.wrapResolve(this.checkInbox(), true);

      // check for R-code validated
      await PromiseUtils.wrapResolve(this.checkRCode(), true);

      let ts: number = new Date().getTime();
      // only ask for rating if opened 5 times on different days
      if ((ts - AppFlags.generalFlags.openCounterLastTimestamp.value) > 86400) {
        AppFlags.generalFlags.openCounterLastTimestamp.value = ts;
        this.storageOps.setStorageFlagNoAction(AppFlags.generalFlags.openCounterLastTimestamp); // update open counter timstamp
        // check for app rating required, and wait for confirmation
        AppFlags.generalFlags.openCounterAll.value += 1;
        this.checkEngaged(AppFlags.generalFlags.openCounterAll.value);
        this.storageOps.setStorageFlagNoAction(AppFlags.generalFlags.openCounterAll); // update open counter
        if (AppFlags.generalFlags.appRate.value && (AppFlags.generalFlags.openCounter.value >= AppConstants.gameConfig.openCounterUntilRate)) {
          let storeAvailable: boolean = await this.popupFeatures.userPromptRateAppCheckAvailableResolve();
          if (storeAvailable) {
            await PromiseUtils.wrapResolve(this.popupFeatures.userPromptRateApp(), true);
          } else {
            AppFlags.generalFlags.appRate.value = false; // stop asking for rating
            this.storageOps.setStorageFlagNoAction(AppFlags.generalFlags.appRate); // update flags with user input
          }
        } else {
          AppFlags.generalFlags.openCounter.value += 1;
          this.storageOps.setStorageFlagNoAction(AppFlags.generalFlags.openCounter); // update open counter           
        }
      }

      // check signup tutorial seen

      // first check in local storage if the tutorial was not seen
      if (!AppFlags.generalFlags.tutorialSeen.value) {
        // if not seen, then also check on the server
        try {
          let res: number = await this.resourcesProvider.getTutorialSeen();
          // if not seen also on the server, THEN show the tutorial page
          if (!res) {
            await this.tutorials.showSignupTutorial();
            if (!GeneralCache.externalLogin) {
              // upload profile photo
              this.checkUploadProfilePhoto();
            } else {
              // this.checkUseExternalPhoto();
              // photo no longer fetching from google, so keep profile photo popup
              this.checkUploadProfilePhoto();
            }
            // update local storage flags
            this.setTutorialSeenLocal();
            // update tutorial seen on the server now
            await PromiseUtils.wrapResolve(this.resourcesProvider.setTutorialSeen(), true);
            // tutorial seen just now
          } else {
            // was seen on the server but not local
            // this can happen when the user logs in on another device or clears app cache
            // in this case do not show the tutorial again
            this.setTutorialSeenLocal();
          }
        } catch (err) {
          console.error(err);
          this.setTutorialSeenLocal();
        }
      }

      // request local notifications permissions
      console.log("local notifications permission request");
      let notificationPermissionsAlreadyGranted: boolean = await this.localNotifications.checkPermission();
      if (!notificationPermissionsAlreadyGranted) {
        await this.localNotifications.initCheckup();
      }
      console.log("push notifications setup (no action)");
      await PromiseUtils.wrapResolve(this.notifications.enablePushNotifications(), true);

      // last checkup (after multiple requests to enable notification permissions from different plugins)
      if (!notificationPermissionsAlreadyGranted) {
        let granted: boolean = await this.localNotifications.checkPermission();
        if (!granted) {
          await this.uiext.dismissLoadingV2();
          await this.localNotifications.initCheckupFallback();
        }
      }

      // if (!notificationPermissionsAlreadyGranted) {
      //   this.backgroundModeProvider.init(); // reinit after notification permissions setup
      // }

      // setup location service
      await PromiseUtils.wrapResolve(this.locationPermissionManager.setupLocationService(false), true);

      resolve(true);
    });
    return promise;
  }

  /**
   * check/track user engagement by app open counter
   * @param openCounter 
   */
  checkEngaged(openCounter: number) {
    switch (openCounter) {
      case 2:
        this.analytics.sendCustomEvent(ETrackedEvents.leplaceEngaged2x, "engaged", "2x", openCounter, false);
        break;
      case 3:
        this.analytics.sendCustomEvent(ETrackedEvents.leplaceEngaged3x, "engaged", "3x", openCounter, false);
        break;
      case 5:
        this.analytics.sendCustomEvent(ETrackedEvents.leplaceEngaged5x, "engaged", "5x", openCounter, false);
        break;
      case 10:
        this.analytics.sendCustomEvent(ETrackedEvents.leplaceEngaged10x, "engaged", "10x", openCounter, false);
        break;
      default:
        break;
    }
  }

  /**
   * disabled
   */
  checkUploadProfilePhoto() {
    if (GeneralCache.checkPlatformOS() === EOS.android) {
      // on iOS it takes too long to open the camera the first time
      // this.goToPhotoSelection();
    }
  }


  /**
   * shortcut to upload a profile picture
   */
  goToPhotoSelection() {
    console.log("view and select photo");
    this.uiext.showAlert(Messages.msg.addProfilePhoto.after.msg, Messages.msg.addProfilePhoto.after.sub, 2, ["later", "ok"], true).then((res: number) => {
      switch (res) {
        case EAlertButtonCodes.ok:
          this.viewPhotoCore(() => {
            return this.uploadPhotoFromCamera();
          });
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  /**
   * shortcut to check if user wants to use the existing photo from ext provider
   */
  checkUseExternalPhoto() {
    console.log("check use external photo");
    this.uiext.showAlert(Messages.msg.addProfilePhotoExt.after.msg, Messages.msg.addProfilePhotoExt.after.sub, 2, ["nope", "sure"], true).then((res: number) => {
      switch (res) {
        case EAlertButtonCodes.ok:
          break;
        case EAlertButtonCodes.cancel:
          // set profile photo null
          this.photoProvider.removeProfilePicture().then(() => {
            console.log("profile photo removed");
          }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "home");
          })
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  viewPhotoCore(callback: () => any) {
    console.log("view and select photo");
    this.userDataProvider.getUserPublicProfile(true).then((data: IUserPublicData) => {
      if (data && data.photoUrl) {
        let params: IViewPhotoParams = {
          share: false,
          customModal: true,
          isDataUrl: false,
          changePhotoCallback: callback
        };
        this.photoViewer.viewPhoto(data.photoUrl, "Profile Photo", params);
      } else {
        // this.uiext.showAlertNoAction(Messages.msg.photoNotAvailable.after.msg, Messages.msg.photoNotAvailable.after.sub);
        callback();
      }
    }).catch((err: Error) => {
      console.error(err);
      this.uiext.showAlertNoAction(Messages.msg.photoNotAvailable.after.msg, Messages.msg.photoNotAvailable.after.sub);
    });
  }

  /**
   * call wizard
   * set callback
   * no action
   */
  uploadPhotoFromCamera() {
    this.photoProvider.uploadPhotoFromCameraWizard((data) => {
      return this.photoProvider.uploadProfilePicture(data);
    }, true, true).then((photoRes: IPhotoResult) => {
      if (photoRes) {
        console.log("done");
      }
      this.uiext.showToastNoAction(Messages.msg.photoUploaded.after.sub, true);
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "home");
      this.uiext.showAlertNoAction(Messages.msg.returned.after.msg, ErrorMessage.parse(err, Messages.msg.returned.after.sub));
    });
  }

  /**
   * set local storage flag (tutorial seen)
   */
  setTutorialSeenLocal() {
    AppFlags.generalFlags.tutorialSeen.value = true;
    this.storageOps.setStorageFlagNoAction(AppFlags.generalFlags.tutorialSeen);
  }


  periodicCheck() {
    this.periodic = setTimeout(() => {
      console.log("check");
      this.periodicCheck();
    }, 500);
  }

  selectStoryline() {
    if (this.activeMode === EHomeActiveMode.storyline) {
      this.goToStories();
      return;
    }
    this.activeMode = EHomeActiveMode.storyline;
    this.setActiveClass();
    this.setBlinkStart = true;
  }

  selectWorldMap() {
    if (this.activeMode === EHomeActiveMode.worldMap) {
      this.goToMap();
      return;
    }
    this.activeMode = EHomeActiveMode.worldMap;
    this.setActiveClass();
    this.setBlinkStart = true;
  }

  goToStories() {
    let params: IStoryListNavParams = {
      storyId: null,
      dynamic: null,
      reload: true,
      category: null,
      categoryCode: null,
      loadStory: true,
      selectedCityId: null,
      localStories: true,
      includeGlobal: true,
      links: null
    };

    this.activeMode = EHomeActiveMode.storyline;
    this.setActiveClass();
    // res = await this.tutorials.showTutorialResolve(null, null, null, params, true);

    let navParams: INavParams = {
      view: null,
      params: params
    };

    this.nps.set(ENavParamsResources.storyList, navParams);

    this.router.navigate([ERouteDef.storyList], { replaceUrl: true }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  goToNewsfeed(): void {
    this.router.navigate([ERouteDef.newsfeed], { replaceUrl: true }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  goToMyAccount(): void {
    this.router.navigate([ERouteDef.account], { replaceUrl: true }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  goToEvents(): void {
    this.router.navigate([ERouteDef.eventsList], { replaceUrl: true }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  goToMap() {
    let params: IDescriptionFrameParams = {
      title: "World Map",
      description: null,
      mode: EDescriptionViewStyle.withOk,
      photoUrl: null,
      loaderCode: ETutorialEntries.worldMap
    };

    params.mode = EDescriptionViewStyle.withCustomButton;
    params.customButton = {
      name: "Open World Map",
      code: null,
      icon: EAppIconsStandard.map,
      customIcon: false,
      class: null,
      size: null,
      disabled: false,
      enabled: true,
      highlight: null,
      callback: null
    };

    let res: number;
    this.activeMode = EHomeActiveMode.worldMap;
    this.setActiveClass();
    // res = await this.tutorials.showTutorialResolve(null, null, null, params, true);
    res = EAlertButtonCodes.ok;

    if (res === EAlertButtonCodes.ok) {
      let params: IGmapEntryNavParams = {
        mode: EGmapMode.worldMap
      };

      let navParams: INavParams = {
        view: null,
        params: params
      };

      this.nps.set(ENavParamsResources.gmap, navParams);
      this.router.navigate([ERouteDef.gmap], { replaceUrl: true }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  viewFeaturedStorylines() {
    this.locationManager.getCurrentLocationWrapper(true, true, true).then((loc: ILatLng) => {
      this.setCountryCheckFeatured(loc.lat, loc.lng, false, true);
    }).catch((err: Error) => {
      console.error(err);
      PromiseUtils.wrapNoAction(this.supportModals.showLocationErrorModal(err), true);
    });
  }

  /**
   * set country or just check for local promotions
   * @param lat 
   * @param lng 
   * @param apply 
   */
  setCountryCheckFeatured(lat: number, lng: number, apply: boolean, viewCheck: boolean) {
    let flag: IAppFlagsElement = {
      flag: ELocalAppDataKeys.locationChecked,
      value: true
    };
    let fn = apply ? this.userData.setCountry(lat, lng) : this.userData.checkCountryPromotion(lat, lng);
    fn.then((res: ISetCountrySendPromotionalEmail) => {
      console.log("check promotion: ", res);
      this.storageOps.setStorageFlagNoAction(flag);
      let params: IPromoStoriesParams = {
        title: "Featured storylines",
        stories: res && res.stories ? res.stories : []
      };
      let navParams: INavParams = {
        view: {
          fullScreen: false,
          transparent: false,
          large: true,
          addToStack: false,
          frame: false
        },
        params: params
      };
      if (viewCheck && (params.stories.length > 0)) {
        this.uiext.showCustomModal(null, PromoStoriesViewComponent, navParams).then((res: IStory) => {
          console.log("promo stories nearby result: ", res);
          if (res != null) {
            let coords: ILatLng = new ILatLng(lat, lng);
            this.goToStoryDetail(res, coords);
          }
        }).catch((err: Error) => {
          console.error(err);
        });
      }
    }).catch((err: Error) => {
      console.error(err);
      // set country update failed, unlock retry on next location update
      GeneralCache.locationChecked = false;
    });
  }

  /**
   * go to story detail on story select (tapped)
   * @param story 
   */
  goToStoryDetail(story: IStory, coords: ILatLng): void {
    if (!story) {
      return;
    }
    this.popupFeatures.openStoryUnlock(story, null, true, coords, null).then((resp: IMasterLockResponse) => {
      if (resp && resp.unlocked) {
        story.locked = false;
        if (resp.aux.reload) {
          this.enterStory(story);
        } else {
          this.enterStory(story);
        }
      }
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "home");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }

  /**
  * finally enter selected story
  * @param story 
  */
  enterStory(story: IStory) {
    let dynamic: boolean = story.dynamic === 1;
    let params: IStoryListNavParams = {
      storyId: story.id,
      dynamic: dynamic,
      reload: true,
      category: null,
      localStories: true,
      includeGlobal: true,
      selectedCityId: null,
      categoryCode: null,
      loadStory: true,
      storyOverview: story,
      useLoadedStory: false
    };

    let navParams: INavParams = {
      view: null,
      storyId: story.id,
      dynamic: dynamic,
      categoryCode: null,
      params: params
    };

    let queryParams: Params = { storyId: "" + story.id, dynamic: "" + dynamic, categoryCode: "" + story.categoryCode };
    this.nps.set(ENavParamsResources.storyHome, navParams);

    this.router.navigate([ERouteDef.storyHome], { replaceUrl: true, queryParams: queryParams }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * init location cache
   */
  initLocation() {
    this.locationManager.getCurrentLocationWrapper(true, false, true).then(async (location: ILatLng) => {
      console.log("init location: ", location);
      this.location = location;
      if (location && (location.lat != null) && (location.lng != null)) {
        await SleepUtils.sleep(500);
        if (!GeneralCache.locationChecked) {
          GeneralCache.locationChecked = true;
          this.storageOps.getLocalDataKey(ELocalAppDataKeys.locationChecked).then((val: boolean) => {
            if (!val) {
              this.setCountryCheckFeatured(location.lat, location.lng, true, false);
            } else {
              // flag was previously set (country was previously set)
              this.setCountryCheckFeatured(location.lat, location.lng, false, false);
            }
          }).catch((err: Error) => {
            console.error(err);
            this.setCountryCheckFeatured(location.lat, location.lng, true, false);
          });
        } else {
          this.setCountryCheckFeatured(location.lat, location.lng, false, false);
        }
      }

    }).catch((err: Error) => {
      console.error(err);
      PromiseUtils.wrapNoAction(this.supportModals.showLocationErrorModal(err), true);
    });
  }


  /**
  * check app flags from local storage or set defaults if not initialized
  */
  loadAppFlagsResolve(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      console.log("HOME INIT: check flags");
      this.storageOps.loadMultipleStorageFlags(AppFlags.generalFlags).then((newFlags: IAppFlags) => {
        if (newFlags) {
          console.log("new flags: ", newFlags);
          let newFlagKeys = Object.keys(newFlags);
          for (let i = 0; i < newFlagKeys.length; i++) {
            if (newFlags[newFlagKeys[i]].value) {
              AppFlags.generalFlags[newFlagKeys[i]].value = newFlags[newFlagKeys[i]].value;
            }
          }
        }
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "home");
        resolve(false);
      });
    });
    return promise;
  }


  checkAppVersionWeb() {
    let promise: Promise<string> = new Promise((resolve) => {
      this.appVersionService.getAppVersionWeb().then((bd: any) => {
        resolve(bd);
      }).catch((err: Error) => {
        console.error(err);
        resolve(null);
      });
    });
    return promise;
  }

  /**
   * check app version from app config
   * save the app version to local storage that may be used later in the app
   */
  checkAppVersionNative(): Promise<number> {
    let promise: Promise<number> = new Promise((resolve) => {
      this.appVersionService.getAppVersion().then((realVersionCode: number) => {
        console.log("real version code: ", realVersionCode);
        this.storageOps.getLocalDataKey(ELocalAppDataKeys.versionCode).then((storageVersionCode: number) => {
          if (storageVersionCode) {
            console.log("storage version code: ", storageVersionCode);
            // check version code
            if (storageVersionCode !== realVersionCode) {
              console.log("version code is different, let the settings manager handle the storage sync AND update local storage version code");
              this.saveVersionCode(realVersionCode);
              resolve(realVersionCode);
            } else {
              console.log("same version code, keep local storage");
              resolve(realVersionCode);
            }
          } else {
            console.log("storage has no version code, save it and continue");
            this.saveVersionCode(realVersionCode);
            resolve(realVersionCode);
          }
        }).catch((err: Error) => {
          console.error(err);
          console.log("storage has no version code, save it and continue");
          this.saveVersionCode(realVersionCode);
          resolve(realVersionCode);
        });
      }).catch((err: Error) => {
        console.error(err);
        resolve(null);
      });
    });
    return promise;
    // check app version
    // then store in local storage
    // and check if the app was updated by comparing these two values when app start
    // if different
    // if local storage empty then init local storage (no action needed)
    // else clear local storage => disabled
  }

  saveVersionCode(versionCode: number) {
    this.storageOps.setStorageFlag({
      flag: ELocalAppDataKeys.versionCode,
      value: versionCode
    }).then(() => {
      console.log("version code saved");
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  /**
   * check popup message e.g. unseen notification that is sent directly from LEPLACE (special notifications e.g. coin bonus)
   */
  checkInbox(): Promise<number> {
    let promise: Promise<number> = new Promise((resolve, reject) => {
      console.log("HOME INIT: check inbox");
      this.popupFeatures.checkInboxCore(false).then((res: number) => {
        if (res > 0) {
          this.blink.inbox = true;
        }
        resolve(res);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  checkRCode(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      console.log("HOME INIT: check R code");
      this.popupFeatures.checkReferralCodeValidatedCore().then((res: boolean) => {
        resolve(res);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }


  ngOnInit() {
    console.log('home page ion view will enter ' + new Date().getTime());

    this.setupBackButton();

    if (GeneralCache.homePageActiveMode != null) {
      this.activeMode = GeneralCache.homePageActiveMode;
      this.setActiveClass();
      this.setBlinkStart = true;
    } else {
      this.selectStoryline();
    }

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.home, null, this.np);

      console.log("home page nav params: ", npInfo.params);

      this.test = true;
      this.webView.ready().then(async () => {
        this.analytics.trackView("home");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
          if (loaded) {
            this.platform = SettingsManagerService.settings.platformFlags;
          }
        }, (err: Error) => {
          console.error(err);
        });

        this.settingsProvider.getSettingsLoaded(false).then((res) => {
          if (res) {
            this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
          }
        }).catch((err: Error) => {
          console.error(err);
        });

        let checkTbootReinit = () => {
          if (AppConstants.gameConfig.resumeAfterDays != null) {
            if ((new Date().getTime() - GeneralCache.tboot) > AppConstants.gameConfig.resumeAfterDays * 24 * 3600 * 1000) {
              return true;
            }
          }
          return false;
        };

        if (!GeneralCache.appLoaded) {
          this.initIapChain();
          // this.testingManager.checkTestFlightDistribution();
        }

        // all promises awaited should be resolve only

        // always check for location permission (don't wait too much, as this might confuse the user)
        // await PromiseUtils.wrapResolve(this.locationPermissionManager.setupLocationService(false), true);

        // initialization stuff that should be done AFTER the user is logged in
        // this will be called after the check-login that is called in app.component
        // so the tester flag should be initialized by now (on web, should load the root page for this to happen)
        if (!GeneralCache.appLoaded || checkTbootReinit()) {
          GeneralCache.appLoaded = true;
          await this.uiext.showLoadingV2Queue("Initializing..");
          // this.blink.map = true;
          this.uiext.resetOverlay();
          console.log("home init");

          await PromiseUtils.wrapResolve(this.storageFlags.loadAppFlags(), true);
          console.log("resources loaded cache: ", GeneralCache.resourceCache);

          this.settingsProvider.getSettingsLoaded(false).then((res: boolean) => {
            if (res) {
              // load server flags AFTER the local settings are loaded
              this.getServerFlags();
            }
          });

          if (!this.platform.WEB) {
            this.liveUpdateCore.confirmUpdateCompleted();
            await PromiseUtils.wrapResolve(this.liveUpdateCore.getCurrentPackageInfo(), true);
            await this.checkAppVersionWizard();
          } else {
            await this.checkAppVersionWizard();
          }

          // the app is up to date
          // proceed with the initialization sequence with regards to user flags
          await this.checkInitSequence();
          await this.uiext.dismissLoadingV2();
          console.log("HOME INIT: init sequence complete");
          this.initLocation();
        } else {
          // app resumed check for updates
          if (GeneralCache.appLoaded && GeneralCache.appResumedFlag) {
            GeneralCache.appResumedFlag = false;
            PromiseUtils.wrapNoAction(this.checkAppVersionWizard(), true);
          }
        }

      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  setupBackButton() {
    this.backButton.replace(() => {
      if (!this.uiext.isAlert()) {
        this.uiext.showAlert(Messages.msg.exit.before.msg, Messages.msg.exit.before.sub, 2, null, false).then((res: number) => {
          if (res === EAlertButtonCodes.ok) {
            this.backButton.exitApp();
          }
        }).catch((err: Error) => {
          console.error(err);
        });
      }
    });
  }

  /**
   * check for new app version (native + codepush)
   */
  checkAppVersionWizard(): Promise<boolean> {
    // check app version for settings loader
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      console.log("check app version wizard");
      try {
        if (this.platform.WEB) {
          let webVersion: string = await this.checkAppVersionWeb();
          await this.checkAppVersionNative(); // register to local storage (and check for updates)
          if (webVersion !== null) {
            let appVersionDB: IAppVersionDB = await this.resourcesProvider.getAppDetailsFromServer();
            if (!this.appVersionService.compareVersionDB(appVersionDB)) {
              resolve(false);
            } else {
              resolve(true);
            }
          } else {
            resolve(false);
          }
          resolve(true);
          return;
        }

        let realVersionCode: number = await this.checkAppVersionNative();
        if (realVersionCode !== null) {
          let appVersionDB: IAppVersionDB = await this.resourcesProvider.getAppDetailsFromServer();
          if (!this.appVersionService.compareVersionDB(appVersionDB)) {
            await this.popupFeatures.userPromptUpdateApp(appVersionDB, false);
          } else {
            // check for code push updates i.e. minor updates only
            let available: boolean = await this.liveUpdateCore.checkUpdateAddToCache();
            console.log("live update check: ", available);
            if (!available) {
              // proceed with the initialization sequence
              resolve(true);
            } else {
              let dismissed: boolean = await this.liveupdate.initUpdateCore();
              console.log("init update resolved: ", dismissed);
              if (dismissed) {
                resolve(true);
              } else {
                // well, the app will restart by now, so it doesn't matter
              }
            }
          }
        } else {
          resolve(false);
        }
      } catch (err) {
        console.error(err);
        resolve(false);
      }
    });
    return promise;
  }

  tapplate() {
    // this.activeMode = EHomeActiveMode.none;
    // this.setFadeClass();
    switch (this.activeMode) {
      case EHomeActiveMode.worldMap:
        this.goToMap();
        break;
      case EHomeActiveMode.storyline:
        this.goToStories();
        break;
    }
  }

  setActiveClass() {
    switch (this.activeMode) {
      case EHomeActiveMode.none:
        this.activeFadeClass = this.fadeClass.init;
        this.btnWorldMapClass = this.btnPrimaryClass;
        this.btnStorylineClass = this.btnPrimaryClass;

        this.btnWorldMapTheme = this.btnPrimaryTheme;
        this.btnStorylineTheme = this.btnPrimaryTheme;

        this.plateSub = "";
        break;
      case EHomeActiveMode.worldMap:
        this.activeFadeClass = this.fadeClass.worldMapSelected;
        this.btnWorldMapClass = this.btnAlternateClass;
        this.btnStorylineClass = this.btnPrimaryClass;

        this.btnWorldMapTheme = this.btnAlternateTheme;
        this.btnStorylineTheme = this.btnPrimaryTheme;

        this.plateSub = "Tap to open World Map";
        break;
      case EHomeActiveMode.storyline:
        this.activeFadeClass = this.fadeClass.storylineSelected;
        this.btnWorldMapClass = this.btnPrimaryClass;
        this.btnStorylineClass = this.btnAlternateClass;

        this.btnWorldMapTheme = this.btnPrimaryTheme;
        this.btnStorylineTheme = this.btnAlternateTheme;

        this.plateSub = "Tap to open Storyline";
        break;
    }
  }

  ngOnDestroy() {
    GeneralCache.homePageActiveMode = this.activeMode;
    console.log("ion view will leave " + new Date().getTime());
  }

  openSupportPage() {
    Util.openURLExt(EConnectLinks.support);
  }
}
