import { Component, OnInit, OnDestroy } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { ShareService } from 'src/app/services/general/apis/share';
import { MediaUtilsService } from 'src/app/services/media/media-utils';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ParamHandler } from 'src/app/classes/general/params';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';


export interface IYouTubeViewFrameNavParams {
  description?: string;
  title: string;
  videoId: string;
  videoUrl?: string;
  portrait: boolean;
}

@Component({
  selector: 'modal-youtube-view-frame',
  templateUrl: './youtube-view-frame.component.html',
  styleUrls: ['./youtube-view-frame.component.scss'],
})
export class YouTubeViewFrameComponent implements OnInit, OnDestroy {
  description: string = "";
  descriptionLines: string[];
  title: string = ""; 
  videoId: string = "";
  videoUrl: string = "";
  hasVideo: boolean = false;

  vs: IViewSpecs = ViewSpecs.getDefault();

  withButtons: boolean = false;

  params: IYouTubeViewFrameNavParams = null;
  appIconsStandard = EAppIconsStandard;

  np: INavParams = null;

  portrait: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public shareProvider: ShareService,
    public mediaUtils: MediaUtilsService,
    public uiext: UiExtensionService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IYouTubeViewFrameNavParams = np.params;
      this.params = params;

      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);

      this.description = params.description;

      this.title = params.title;
      this.videoId = params.videoId;
      this.videoUrl = params.videoUrl;
      this.portrait = params.portrait;

      if (this.videoId || this.videoUrl) {
        this.hasVideo = true;
      }
    }
  }

  ngOnDestroy() {

  }

  dismiss() {
    setTimeout(() => {
      this.modalCtrl.dismiss(null).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }
}
