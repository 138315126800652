<div fxLayout="column" [ngClass]="dframe?'description-frame padding-s padding-top-none':''">


  <!-- <div class="leplace-icon icon-color-primary margin-bottom-s">
    <icon-wrapper [icon]="selectedTypeIcon" [custom]="true"></icon-wrapper>
  </div> -->

  <ion-grid class="full-w margin-vertical-none padding-top-none">
    <ion-row>
      <ion-col size="4">
        <ion-button [disabled]="!withClue" class="action-button-fill button-color-transparent" (click)="requireClue()">
          <icon-wrapper slot="icon-only" [custom]="true" [icon]="appIcons.clue"></icon-wrapper>
        </ion-button>
      </ion-col>
      <ion-col size="4">
        <ion-button class="action-button-fill button-color-transparent" (click)="showQuestTypeDescription()">
          <icon-wrapper slot="icon-only" [custom]="true" [icon]="selectedTypeIcon">
          </icon-wrapper>
        </ion-button>
      </ion-col>
      <ion-col size="4">
        <ion-button [disabled]="!withMapClue" class="action-button-fill button-color-transparent" (click)="showMap()">
          <icon-wrapper slot="icon-only" icon="map"></icon-wrapper>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

  <div *ngIf="questType.text">
    <!-- <div [innerHTML]="data.q" class="info-text-m text-left text-color-primary"></div> -->
    <safe-html-renderer [imageWrapper]="true" [unsafeHtmlContent]="data.q"
      innerClass="info-text-m text-left text-color-primary"></safe-html-renderer>

    <ion-textarea #input
      class="info-text-xxl text-color-primary leplace-input-border margin-top-s leplace-textarea-padding padding-bottom-xs margin-bottom-xs"
      [rows]="3" [maxlength]="250" [(ngModel)]="resp.a" ngDefaultControl autocomplete="off" autocorrect="off"
      placeholder="Type your response" (ionFocus)="setInputFocus(true)" (ionBlur)="setInputFocus(false)"
      (input)="review(true, false)" [disabled]="disableInput">
    </ion-textarea>
    <!-- <edit-textarea class="full-w" [mainContainer]="respContainer" [editContainer]="respContainerCopy" key="a"
      [maxLength]="250" placeholder="Type your response" [popupInput]="true" (update)="review($event, true)"
      (focus)="textAreaFocused($event)">
      <inner-content>Edit response</inner-content>
    </edit-textarea> -->
  </div>

  <div *ngIf="questType.numeric">
    <!-- <div [innerHTML]="data.q" class="info-text-m text-left text-color-primary"></div> -->
    <safe-html-renderer [imageWrapper]="true" [unsafeHtmlContent]="data.q"
      innerClass="info-text-m text-left text-color-primary"></safe-html-renderer>

    <ion-item class="input-wrapper-item-none">
      <ion-input *ngIf="questType.numeric" [(ngModel)]="resp.a" ngDefaultControl name="respval" #respval="ngModel"
        type="number" inputmode="numeric" required placeholder="Enter number"
        class="text-color-primary leplace-input-border margin-top-s padding-bottom-xs margin-bottom-xs input-number-style"
        (input)="review(true, false)" (ionFocus)="setInputFocus(true)" (ionBlur)="setInputFocus(false)"
        [disabled]="disableInput">
      </ion-input>
    </ion-item>
  </div>

  <div *ngIf="questType.select && useDropdown">
    <!-- <div [innerHTML]="data.q" class="info-text-m text-left text-color-primary"></div> -->
    <safe-html-renderer [imageWrapper]="true" [unsafeHtmlContent]="data.q"
      innerClass="info-text-m text-left text-color-primary"></safe-html-renderer>

    <!-- <div *ngIf="questSelectOptions" class="padding-top-s">
      <span class="info-text-m text-left text-color-element">{{selectText}}</span>
    </div> -->
    <ion-item class="list-item transparent-bg item-noborder" *ngIf="questSelectOptions">
      <ion-select class="custom-select info-text-m full-w-imp leplace-input-border padding-horizontal-s padding-top-s"
        ngDefaultControl [(ngModel)]="questSelectOptionSelected" (ionChange)="updateSpec()" [disabled]="disableInput">
        <ion-label class="ion-text-wrap info-text-m">{{questionLine}}</ion-label>
        <ion-select-option *ngFor="let o of questSelectOptions; let i = index" [value]="i">
          <div [innerHTML]="o" class="text-color-primary"></div>
        </ion-select-option>
      </ion-select>
    </ion-item>
  </div>

  <div *ngIf="questType.select && !useDropdown">
    <!-- <div [innerHTML]="data.q" class="info-text-m text-left text-color-primary"></div> -->
    <safe-html-renderer [imageWrapper]="true" [unsafeHtmlContent]="data.q"
      innerClass="info-text-m text-left text-color-primary"></safe-html-renderer>

    <ion-list *ngIf="questSelectOptions" class="padding-top-s padding-bottom-xs margin-bottom-none">
      <ion-radio-group [value]="questSelectOptionSelected" (ionChange)="radioSelect($event)">
        <!-- <ion-list-header class="info-text-m text-left text-color-element list-header padding-left-none margin-bottom-s">
          <ion-label>{{selectText}}</ion-label>
        </ion-list-header> -->
        <ion-item *ngFor="let o of questSelectOptions; let i = index"
          class="list-item transparent-bg item-noborder quiz-item">
          <ion-label class="ion-text-wrap info-text-m-imp">
            <safe-html-renderer [imageWrapper]="true" [unsafeHtmlContent]="o" innerClass="text-color-primary"
              (innerClick)="questSelectOptionSelected = i;"></safe-html-renderer>
            <!-- <div [innerHTML]="o" class="text-color-primary"></div>   -->
          </ion-label>
          <ion-radio class="checkbox-large" slot="start" [value]="i" [disabled]="disableInput"></ion-radio>
        </ion-item>
      </ion-radio-group>
    </ion-list>
  </div>

  <div *ngIf="questType.selectMulti">
    <!-- <div [innerHTML]="data.q" class="info-text-m text-left text-color-primary"></div> -->
    <safe-html-renderer [imageWrapper]="true" [unsafeHtmlContent]="data.q"
      innerClass="info-text-m text-left text-color-primary"></safe-html-renderer>

    <ion-list *ngIf="questSelectMultiOptions" class="padding-top-s padding-bottom-xs margin-bottom-none">
      <!-- <ion-list-header class="info-text-m text-left text-color-element list-header padding-left-none margin-bottom-s">
        <ion-label>{{selectMultiText}}</ion-label>
      </ion-list-header> -->
      <ion-item *ngFor="let o of questSelectMultiOptions; let i = index"
        class="list-item transparent-bg item-noborder quiz-item">
        <ion-label class="ion-text-wrap info-text-m-imp">
          <!-- <div [innerHTML]="o.val" class="text-color-primary"></div> -->
          <safe-html-renderer [imageWrapper]="true" [unsafeHtmlContent]="o.val" innerClass="text-color-primary"
            (innerClick)="o.isChecked = !o.isChecked;"></safe-html-renderer>
        </ion-label>
        <ion-checkbox class="custom-checkbox checkbox-large" slot="start" [(ngModel)]="o.isChecked"
          (ionChange)="checkboxSelect($event)" [disabled]="disableInput">
        </ion-checkbox>
      </ion-item>
    </ion-list>
  </div>

  <!-- <div *ngIf="inputFocused" class="padding-keyboard"></div> -->

  <ion-grid class="full-w margin-vertical-none" *ngIf="ownButton">
    <ion-row>
      <ion-col size="12">
        <ion-button [disabled]="checkInvalid() || disableInput" class="action-button-fill button-color-accent"
          [ngClass]="shakeActive?'animate-shake':''" (click)="submit()">
          <mat-progress-spinner *ngIf="loading" [diameter]="20" color="primary" mode="indeterminate">
          </mat-progress-spinner>
          <icon-wrapper class="slot-icon-padding" [custom]="true" slot="start" [icon]="appIcons.check">
          </icon-wrapper>
          <span class="button-font-size-s">Check</span>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

</div>