<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()" title="Team stats"
    rightButton="settings" [rightIcon]="true" (rightClick)="presentPopover()" [loading]="!loaded">
  </leplace-toolbar>
</ion-header>

<!-- <ion-content padding-s class="theme-bg"> -->
<ion-content [ngClass]="theme">
  <!-- (swipe)="swipeEvent($event)" -->
  <div class="view-frame padding-s theme-background" fxLayout="column" style="text-align: center">

    <div [ngClass]="show.categoryTabs ? 'metrics-height' : 'full-height'" fxLayout="column">

      <div class="theme-border-bottom padding-bottom-s" fxLayout="column">
        <span class="info-text-m bold text-color-primary">{{heading}}</span>

        <!-- <group-card class="full-w" [group]="group">
        </group-card> -->

        <div class="margin-vertical-s progress-bar-alternate" *ngIf="show.levelBar">
          <mat-progress-bar class="progress-rounded" mode="determinate" [value]="levelPercent"></mat-progress-bar>
        </div>
        <span class="info-text-mlg text-color-primary padding-s" *ngIf="details">{{details}}</span>
      </div>

      <div class="scrollbar-y team-stats-height margin-top-s">
        <div *ngIf="groupStatsTable" class="theme-border-bottom">
          <kv-table [data]="groupStatsTable"></kv-table>
        </div>

        <div *ngIf="groupMembers">
          <ion-list class="list-padding">
            <ion-item *ngFor="let gm of groupMembers" class="list-item list-item-padding transparent-bg">
              <div class="full-w">
                <group-member-card class="full-w" [member]="gm"></group-member-card>
              </div>
            </ion-item>
          </ion-list>
        </div>
      </div>
    </div>

    <div class="theme-border-top">
      <nav-bar *ngIf="show.categoryTabs" class="padding-bottom-s padding-top-s stick-bottom theme-border-top"
        [slidesPerView]="3" [(selectedTabId)]="categoryCode" [navBarItems]="categoryTabs"
        (select)="selectCategoryFromNavItem($event)">
      </nav-bar>
    </div>
  </div>
</ion-content>