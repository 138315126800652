<ion-content class="margin-top-none no-scroll scroll-content-no-scroll">

  <div class="stick-top">
    <progress-loading *ngIf="loading" customClass="progress-bar-alternate-light" [small]="false"></progress-loading>
    <progress-loading *ngIf="!loading" [small]="false" customClass="progress-bar-alternate-light" [manual]="true"
      [progress]="0">
    </progress-loading>
  </div>

  <ion-fab vertical="top" horizontal="start" class="gmap-margin-top-fab">
    <ion-fab-button>
      <ion-button ion-fab class="round-fab no-border-width round-fab-size button-color-primary-70" (click)="showOptions()">
        <icon-wrapper slot="icon-only" [icon]="appIconsStandard.settings" class="text-color-primary"></icon-wrapper>
      </ion-button>
    </ion-fab-button>
    <!-- <ion-fab-button>
      <ion-button ion-fab class="round-fab no-border-width round-fab-size button-color-primary-70" (click)="exit(null)">
        <icon-wrapper slot="icon-only" icon="map" class="text-color-primary"></icon-wrapper>
      </ion-button>
    </ion-fab-button> -->
  </ion-fab>

  <ion-fab vertical="top" horizontal="end" class="gmap-margin-top-fab">
    <blink-button *ngIf="showStatButtons" theme="primary70" [icon]="appIcons.compass" [customIcon]="true"
      [set]="isGimbalLockZone" [customIcon]="true" [fab]="true" [noBorder]="true">
    </blink-button>
  </ion-fab>

  <div class="hud gmap-margin-top semi-transp-uniform" *ngIf="enableHud.main && !enableHud.debug && showHud">
    <leplace-hud [data]="hudMsgMap"></leplace-hud>
  </div>

  <div class="hud gmap-margin-top semi-transp-uniform" *ngIf="enableHud.debug && showHud">
    <leplace-hud [data]="hudMsgAR"></leplace-hud>
  </div>

  <div class="hud-left-m" *ngIf="navGauge.ngDict.left.showLevelGauge && navGaugeReady">
    <leplace-vertical-gauge [value]="navGauge.ngDict.left.levelGauge" [levels]="navGauge.ngDict.left.levelGaugeSize"
      [theme]="navGauge.ngDict.left.theme" [inverted]="navGauge.ngDict.left.inverted" [options]="vgOptions"
      (action)="onGaugeSelect($event)" class="account-chart">
    </leplace-vertical-gauge>
    <icon-wrapper [icon]="navGauge.ngDict.left.icon" [custom]="navGauge.ngDict.left.customIcon" class="hud-left-icon"
      customClass="leplace-icon-s"></icon-wrapper>
  </div>

  <div class="hud-right-m" *ngIf="navGauge.ngDict.right.showLevelGauge && navGaugeReady">
    <leplace-vertical-gauge [value]="navGauge.ngDict.right.levelGauge" [levels]="navGauge.ngDict.right.levelGaugeSize"
      [theme]="navGauge.ngDict.right.theme" [inverted]="navGauge.ngDict.right.inverted" [options]="vgOptions"
      (action)="onGaugeSelect($event)" class="account-chart" chartClass="ngx-charts-heatmap-right">
    </leplace-vertical-gauge>
    <icon-wrapper [icon]="navGauge.ngDict.right.icon" [custom]="navGauge.ngDict.right.customIcon" class="hud-right-icon"
      customClass="leplace-icon-s">
    </icon-wrapper>
  </div>

  <leplace-hud-slide *ngIf="enableHud.xp" [data]="hudMsgXP" [show]="hudShow" [config]="hudConfig"></leplace-hud-slide>

  <iframe *ngIf="show && !useARjs" id="myiframe" src="assets/AR/ar-view.html" class="ar-iframe" seamless></iframe>
  <iframe *ngIf="show && useARjs" id="myiframe" src="assets/AR.js/start.html" allow="camera;microphone"
    class="ar-iframe" [ngClass]="!initCheck.engineLoaded ? 'hidden' : ''" seamless></iframe>

  <div class="leplace-footer-container toolbar-background-md semi-transp-uniform footer-container padding-s"
    [ngClass]="footerClass">
    <div>
      <ion-grid class="full-w" [ngClass]="footerContentClass">
        <ion-row class="row-center" [ngClass]="footerContentClass">
          <ion-col size="3">
            <blink-button icon="map" theme="accent" [disabled]="!ARInitSignalReceived" (select)="returnToMap()"
              id="btnReturnToMap"></blink-button>
          </ion-col>
          <ion-col size="6">
            <div class="chart-container-in-footer">
              <div class="center-lines"></div>
              <canvas #myChart class="chart" [ngClass]="{'chart-view-angle':enable.arexplore}"></canvas>
            </div>
          </ion-col>
          <ion-col size="3">
            <!-- <blink-button icon="camera" [setTimeout]="blink.camera" [disabled]="!screenshotEnabled"
              (select)="captureScreen()" id="btnARCapture"></blink-button> -->
            <blink-button [disabled]="!flags.objectsNearby" theme="alternate" icon="magnet"
              [setTimeout]="flags.objectsNearby && !demoMode" (select)="collectNearbyObject()" id="btnARMagnet"></blink-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</ion-content>