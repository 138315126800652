import { Injectable } from "@angular/core";
import { UiExtensionService } from "../general/ui/ui-extension";
import { StoryDataService } from "../data/story";
import { AnalyticsService } from "../general/apis/analytics";
import { Messages } from "../../classes/def/app/messages";
import { ErrorMessage } from "../../classes/general/error-message";
import { IStory } from "../../classes/def/core/story";
import { EAlertButtonCodes } from "../../classes/def/app/ui";
import { ETrackedEvents } from 'src/app/classes/app/analytics';
import { IEventStoryGroupLinkData } from 'src/app/classes/def/core/links';
import { LinksDataService } from '../data/links';


@Injectable({
    providedIn: 'root'
})
export class ModularInteractionService {
    constructor(
        public uiext: UiExtensionService,
        public storyDataProvider: StoryDataService,
        public analytics: AnalyticsService,
        public links: LinksDataService
    ) {
        console.log("modaular interaction service created");
    }


    clearProgressCore(story: IStory): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            this.analytics.sendCustomEvent(ETrackedEvents.story, "clear progress", story.id + "", story.id, true);
            this.storyDataProvider.clearAllProgress(story).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    clearProgressPromise(story: IStory, prompt: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (prompt) {
                let msg: string;
                let sub: string;
                let links: IEventStoryGroupLinkData = this.links.getLinkData();
                if (links != null) {
                    msg = Messages.msg.clearProgressGroup.before.msg;
                    sub = Messages.msg.clearProgressGroup.before.sub;
                } else {
                    msg = Messages.msg.clearProgress.before.msg;
                    sub = Messages.msg.clearProgress.before.sub;
                }
                this.uiext.showAlert(msg, sub, 2, null).then((res: number) => {
                    if (res === EAlertButtonCodes.ok) {
                        this.clearProgressCore(story).then((res) => {
                            resolve(res);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                    } else {
                        resolve(false);
                    }
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                this.clearProgressCore(story).then((res) => {
                    resolve(res);
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }

    clearProgress(story: IStory, prompt: boolean) {
        this.clearProgressPromise(story, prompt).then(() => {
        }).catch((err: Error) => {
            this.analytics.dispatchError(err, "story-home");
            this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
        });
    }


    clearPlacesCore(story: IStory): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            this.analytics.sendCustomEvent(ETrackedEvents.story, "clear saved places", story.id + "", story.id, true);
            this.storyDataProvider.clearAllSavedLocations(story).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    clearPlacesPromise(story: IStory, prompt: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (prompt) {
                this.uiext.showAlert(Messages.msg.clearSavedPlaces.before.msg, Messages.msg.clearSavedPlaces.before.sub, 2, null).then((res: number) => {
                    if (res === EAlertButtonCodes.ok) {
                        this.clearPlacesCore(story).then((res) => {
                            resolve(res);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                    } else {
                        resolve(false);
                    }
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                this.clearPlacesCore(story).then((res) => {
                    resolve(res);
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }

    clearPlaces(story: IStory, prompt: boolean) {
        this.clearPlacesPromise(story, prompt).then(() => {
        }).catch((err: Error) => {
            this.analytics.dispatchError(err, "story-home");
            this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
        });
    }

}




