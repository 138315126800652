export class PromiseUtils {

    /**
     * wrap promise as resolve only
     * @param promise 
     * @param dispError 
     */
    static wrapResolve<T>(promise: Promise<T>, dispError: boolean): Promise<T> {
        return new Promise<T>((resolve) => {
            promise.then((res: T) => {
                resolve(res);
            }).catch((err: Error) => {
                if (dispError) {
                    console.error(err);
                }
                resolve(null);
            });
        });
    }

    /**
     * wrap promise as no action
     * @param promise 
     * @param dispError 
     */
    static wrapNoAction<T>(promise: Promise<T>, dispError: boolean): void {
        promise.then(() => {

        }).catch((err: Error) => {
            if (dispError) {
                console.error(err);
            }
        });
    }
}