import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ERouteDef } from './app-utils';
import { AuthGuard } from './services/general/auth.service';

export const routes: Routes = [
  // {
  //   path: '',
  //   redirectTo: ERouteDef.landing,
  //   pathMatch: 'full'
  // },
  { path: ERouteDef.landing, pathMatch: 'full', loadChildren: () => import('./pages/logins/landing/landing.module').then(m => m.LandingPageModule) },
  { path: ERouteDef.login, pathMatch: 'full', loadChildren: () => import('./pages/logins/login/login.module').then(m => m.LoginPageModule) },
  { path: ERouteDef.signup, pathMatch: 'full', loadChildren: () => import('./pages/logins/signup/signup.module').then(m => m.SignupPageModule) },
  { path: ERouteDef.signupTerms, pathMatch: 'full', loadChildren: () => import('./pages/logins/signup-terms/signup-terms.module').then(m => m.SignupTermsPageModule) },
  { path: ERouteDef.signupTutorial, pathMatch: 'full', loadChildren: () => import('./pages/logins/signup-tutorial/signup-tutorial.module').then(m => m.SignupTutorialPageModule) },

  { path: ERouteDef.home, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/home/home.module').then(m => m.HomePageModule) },
  { path: ERouteDef.about, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/about/about.module').then(m => m.AboutPageModule) },
  { path: ERouteDef.account, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/account/account-home/account-home.module').then(m => m.AccountHomePageModule) },
  { path: ERouteDef.accountDetails, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/account/account-details/account-details.module').then(m => m.AccountDetailsPageModule) },
  { path: ERouteDef.character, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/account/account-character/account-character.module').then(m => m.AccountCharacterPageModule) },
  { path: ERouteDef.arViewEntry, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/ar-view-entry/ar-view-entry.module').then(m => m.ARViewEntryPageModule) },
  { path: ERouteDef.eventsList, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/events/events-list/events-list.module').then(m => m.EventsListPageModule) },
  { path: ERouteDef.eventsHome, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/events/events-home/events-home.module').then(m => m.EventsHomePageModule) },
  { path: ERouteDef.eventsJoin, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/events/events-join/events-join.module').then(m => m.EventsJoinPageModule) },
  { path: ERouteDef.supportHome, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/support/support.module').then(m => m.SupportPageModule) },
  { path: ERouteDef.supportFaq, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/support/faq/faq.module').then(m => m.FaqPageModule) },
  { path: ERouteDef.extras, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/extras/extras.module').then(m => m.ExtrasPageModule) },
  { path: ERouteDef.gmap, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/gmap/gmap.module').then(m => m.GmapPageModule) },
  { path: ERouteDef.gmapDetail, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/gmap-detail/gmap-detail.module').then(m => m.GmapDetailPageModule) },
  { path: ERouteDef.inventory, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/inventory/inventory-home/inventory-home.module').then(m => m.InventoryHomePageModule) },
  { path: ERouteDef.inventoryDetail, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/inventory/inventory-detail/inventory-detail.module').then(m => m.InventoryDetailPageModule) },
  { path: ERouteDef.leaderboard, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/leaderboard/leaderboard.module').then(m => m.LeaderboardPageModule) },
  { path: ERouteDef.mpHome, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/mp/mp-home/mp-home.module').then(m => m.MPHomePageModule) },
  { path: ERouteDef.mpGroupsHome, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/mp/mp-groups-home/mp-groups-home.module').then(m => m.MPGroupsHomePageModule) },
  { path: ERouteDef.mpCreate, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/mp/mp-create/mp-create.module').then(m => m.MPCreatePageModule) },
  { path: ERouteDef.mpJoin, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/mp/mp-join/mp-join.module').then(m => m.MPJoinPageModule) },
  { path: ERouteDef.mpOptions, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/mp/mp-options/mp-options.module').then(m => m.MpOptionsPageModule) },
  { path: ERouteDef.mpGroupMembers, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/mp/mp-group-members/mp-group-members.module').then(m => m.MPGroupMembersPageModule) },
  { path: ERouteDef.mpGroupsList, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/mp/mp-groups-list/mp-groups-list.module').then(m => m.MPGroupsListPageModule) },
  { path: ERouteDef.mpGroupChat, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/mp/mp-groups-chat/mp-groups-chat.module').then(m => m.MPGroupsChatPageModule) },
  { path: ERouteDef.newsfeed, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/newsfeed/newsfeed.module').then(m => m.NewsfeedPageModule) },
  { path: ERouteDef.settings, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsPageModule) },
  { path: ERouteDef.storyCategories, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/storyline/story-categories/story-categories.module').then(m => m.StoryCategoriesPageModule) },
  { path: ERouteDef.storyList, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/storyline/story-list/story-list.module').then(m => m.StoryListPageModule) },
  { path: ERouteDef.storyHome, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/storyline/story-home/story-home.module').then(m => m.StoryHomePageModule) },
  { path: ERouteDef.test, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/test/test.module').then(m => m.TestPageModule) },
  { path: ERouteDef.treasureHome, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/treasure-home/treasure-home.module').then(m => m.TreasureHomePageModule) },
  { path: ERouteDef.tutorial, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/tutorial/tutorial.module').then(m => m.TutorialPageModule) },
  { path: ERouteDef.teamStats, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/groups/team-stats/team-stats.module').then(m => m.TeamStatsPageModule) },
  { path: ERouteDef.supportHome, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/support/support.module').then(m => m.SupportPageModule) },
  { path: ERouteDef.supportFaq, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/support/faq/faq.module').then(m => m.FaqPageModule) },
  { path: ERouteDef.supportChat, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/support/chat/chat.module').then(m => m.SupportChatPageModule) },
  { path: ERouteDef.mpOptions, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/mp/mp-options/mp-options.module').then(m => m.MpOptionsPageModule) },
  { path: ERouteDef.leaderboardExt, pathMatch: 'full', canActivate: [AuthGuard], loadChildren: () => import('./pages/leaderboard-ext/leaderboard-ext.module').then(m => m.LeaderboardExtPageModule) }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
