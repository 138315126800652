import { Component, OnInit, OnDestroy, OnChanges, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';

@Component({
    selector: 'progress-loading',
    templateUrl: './progress-loading.component.html',
    styleUrls: ['./progress-loading.component.scss'],
})
export class ProgressLoadingComponent implements OnInit, OnDestroy, OnChanges {

    loadingIncrement: number = 20;
    loadingValue: number = 0;
    skip: number = 0;
    skipDef: number = 1;
    timeout: any;
    timeoutValue: number = 500;
    first: boolean = true;
    class: string = "no-padding";
    classLarge: string = "leplace-progress-bar-large";
    enabled: boolean = true;

    @Input()
    small: boolean;

    @Input()
    rounded: boolean;

    @Input()
    manual: boolean;

    @Input()
    progress: number;

    @Input()
    customClass: string;

    @Input()
    indeterminate: boolean;

    constructor() {

    }

    ngOnInit() {
        // console.log("progress loading init");
        if (this.manual) {
            this.loadingValue = this.progress;
        } else {
            if (!this.indeterminate) {
                this.updateProgress();
            }
        }

        if (this.customClass) {
            this.class += " " + this.customClass;
        } else {
            this.class += " progress-bar-alternate";
        }

        if (GeneralCache.checkPlatformOSWithPWA() === EOS.ios) {
            this.classLarge = "leplace-progress-bar-large-ios";
        } else {
            if (GeneralCache.isWeb) {
                this.classLarge = "leplace-progress-bar-large-web";
            }
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        let keys: string[] = Object.keys(changes);
        for (let i = 0; i < keys.length; i++) {
            const chg: SimpleChange = changes[keys[i]];
            // console.log(chg);
            if (!chg.isFirstChange() || chg.currentValue === true) {
                // skip the first change only if the current value is not already set to true
                // this may happen if the button is just shown and it is set to blink
                switch (keys[i]) {
                    case 'progress':
                        this.loadingValue = this.progress;
                        break;
                }
            }
        }
    }

    ngOnDestroy() {
        // console.log("progress loading destroyed");
        if (!this.indeterminate) {
            this.enabled = false;
            this.clearTimeoutPb();
        }
    }

    updateProgress() {
        let tm = this.timeoutValue;
        if (this.first) {
            this.first = false;
            tm = 100;
        }
        this.clearTimeoutPb();
        if (this.enabled) {
            this.timeout = setTimeout(() => {
                if (this.enabled) {
                    this.updateProgressCore();
                    this.updateProgress();
                }
            }, tm);
        }
    }

    updateProgressCore() {
        if (this.loadingValue >= 100) {
            this.skip += 1;
            if (this.skip >= this.skipDef) {
                this.skip = 0;
                this.loadingValue = 0;
            }
        } else {
            if (this.loadingValue === 0) {
                this.skip += 1;
                if (this.skip >= this.skipDef) {
                    this.skip = 0;
                    this.loadingValue += this.loadingIncrement;
                }
            } else {
                this.loadingValue += this.loadingIncrement;
            }
        }
    }

    clearTimeoutPb() {
        if (this.timeout) {
            clearTimeout(this.timeout);
            this.timeout = null;
        }
    }

}
