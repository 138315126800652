import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { IEventGroupMember } from 'src/app/classes/def/mp/groups';
import { EPhotos } from 'src/app/classes/def/app/icons';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { StringUtils } from 'src/app/services/app/utils/string-utils';

@Component({
  selector: 'group-member-card',
  templateUrl: './group-member-card.component.html',
  styleUrls: ['./group-member-card.component.scss'],
})
export class GroupMemberCardComponent implements OnInit, OnDestroy {
  scoreDisp: string = "";
  headingDisp: string = "";
  levelDisp: string = "";
  bgPhoto: string = null;
  hasPhoto: boolean = false;
  userName: string = "";
  self: boolean = false;
  loaded: boolean = false;
  photoLoaded: boolean = false;

  @Input()
  member: IEventGroupMember;

  constructor() { }

  ngOnInit() {
    this.formatUser();
  }

  ngOnDestroy() {

  }

  formatUser() {
    if (this.member && this.member.user) {
      // console.log(JSON.stringify(this.member));
      if (!this.member.user.photoUrl) {
        if (!(this.member.groupRole && this.member.groupRole.photoUrl)) {
          this.bgPhoto = EPhotos.userPlaceholder;
        } else {
          this.bgPhoto = this.member.groupRole.photoUrl;
        }
      } else {
        this.bgPhoto = this.member.user.photoUrl;
        this.hasPhoto = true;
      }

      if (this.member.userId === GeneralCache.userId) {
        this.self = true;
      }

      if (this.member.groupRole) {
        this.headingDisp = this.member.groupRole.name;
      }

      this.userName = StringUtils.trimNameMultilineHTML(this.member.user.name, EMessageTrim.mpLeaderboardUsernameCard);
      this.loaded = true;
    }
  }

}


