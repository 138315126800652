<ng-template #template>
  <ng-content></ng-content>
</ng-template>
<ion-row *ngIf="loaded">
  <ion-col size="12" center *ngIf="!editSelected">
    <ion-button class="action-button-fill-xs button-color-transparent" (click)="onSwitchEditMode()" icon-end>
      <span class="button-font-size-s">{{label}}</span>
      <icon-wrapper slot="start" [icon]="appIconsStandard.edit" [custom]="false" class="slot-icon-padding">
      </icon-wrapper>
    </ion-button>
  </ion-col>
  <ion-col size="12" center *ngIf="editSelected">
    <ion-button class="action-button-fill-xs button-color-alternate" (click)="onSwitchEditMode()" icon-end
      [disabled]="lock">
      <span class="button-font-size-s">{{labelSelected}}</span>
      <icon-wrapper slot="start" [icon]="appIconsStandard.accept" [custom]="false" class="slot-icon-padding">
      </icon-wrapper>
    </ion-button>
  </ion-col>
</ion-row>

<ion-row *ngIf="loaded && !editSelected">
  <ion-col size="12">
    <div class="text-left">
      <div [innerHTML]="mainContainer[key] ? mainContainer[key] : divPlaceholder"
        class="info-text-m description-frame text-frame-card"
        [ngClass]="mainContainer[key] ? 'text-color-primary' : 'text-color-warn-high'">
      </div>
    </div>
  </ion-col>
</ion-row>

<div class="leplace-textarea-frame" *ngIf="loaded && editSelected">
  <ion-textarea class="info-text-m text-color-primary leplace-textarea ion-text-start" [rows]="5"
    (ionFocus)="adjustTextarea($event)" [maxlength]="maxLength" [(ngModel)]="editContainer[key]" ngDefaultControl
    autocomplete="off" autocorrect="off" [placeholder]="textareaPlaceholder"></ion-textarea>
  <div *ngIf="showCharCount" class="text-right">
    <span *ngIf="editContainer[key]" class="info-text-m text-color-primary padding-s">{{ maxLength -
      editContainer[key].length }}
      characters left</span>
  </div>
</div>