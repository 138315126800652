<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(false)" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div fxLayout="column">
    <div class="scrollbar-y scroll-content-modal-1">
      <div class="padding-s">
        <div [innerHTML]="infoHTML" class="info-text-m description-frame text-color-primary"></div>
      </div>

      <div class="padding-s text-center" *ngIf="initComplete">
        <span class="info-text-m bold text-color-primary">{{progressString}}</span>
      </div>

      <!-- <div class="padding-s progress-bar-alternate">
        <mat-progress-bar *ngIf="flags.downloading" mode="determinate" class="progress-rounded" color="primary"
          [value]="progressPercent"></mat-progress-bar>
        <progress-loading class="progress-rounded" [small]="true" [indeterminate]="true" *ngIf="flags.processing">
        </progress-loading>
      </div> -->

      <div class="padding-s progress-spinner-center-large">
        <mat-progress-spinner [diameter]="80" color="primary" mode="indeterminate" *ngIf="initComplete && !updateReady">
        </mat-progress-spinner>
      </div>
    </div>

    <div fxFlex></div>
    <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-row *ngIf="!initComplete">
          <ion-col size="6">
            <ion-button class="action-button-fill button-color-warn" [disabled]="!canIgnore" (click)="ignore()">
              <span class="button-font-size-m">Ignore</span>
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button class="action-button-fill button-color-accent" (click)="install()">
              <span class="button-font-size-m">Install</span>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="initComplete">
          <ion-col size="12">
            <ion-button class="action-button-fill button-color-accent" [disabled]="!canDismiss" (click)="dismiss(true)">
              <span class="button-font-size-m">{{btnContinue}}</span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</modal-container>