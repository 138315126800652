<div>
    <ion-grid class="full-w padding-xs">

        <ion-row *ngIf="titleDisp" class="center-text">
            <ion-col>
                <div [innerHTML]="titleDisp" class="info-text-m bold description-frame text-color-primary center-text">
                </div>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="descriptionDisp" class="text-left">
            <ion-col>
                <div [innerHTML]="descriptionDisp" class="info-text-m description-frame text-color-primary text-left">
                </div>
            </ion-col>
        </ion-row>


        <ion-row *ngIf="promo">
            <ion-col size="12">
                <span class="info-text-s center-text">{{getPromoDisp()}}</span>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col size="12">
                <ion-button class="action-button-fill button-color-alternate" (click)="tapCheckout()">
                    <span class="button-font-size-s">Checkout</span>
                    <span *ngIf="!promo" class="button-font-size-s padding-horizontal-s">({{price}})</span>
                    <!-- <span *ngIf="promo"
                        class="button-font-size-xs padding-horizontal-s text-strikethrough">({{price}}</span>
                    <span *ngIf="promo" class="button-font-size-s">{{promo}})</span> -->
                    <span *ngIf="promo" class="button-font-size-s padding-horizontal-s">({{promo}})</span>
                    <icon-wrapper slot="start" [icon]="appIconsStandard.locked" [custom]="false"
                        class="slot-icon-padding">
                    </icon-wrapper>
                </ion-button>
            </ion-col>
        </ion-row>

        <!-- <div class="text-center">
            <img [src]="stripeBadge" height="40" class="padding-top-s">
        </div> -->

        <ion-row *ngIf="footerDisp" class="text-left">
            <div [innerHTML]="footerDisp" class="info-text-s description-frame text-color-primary"></div>
        </ion-row>

    </ion-grid>
</div>