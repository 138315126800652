
export interface IGenericMessageQueueEvent {
    data: any;
    state: boolean;
}

export interface IMessageQueueEvent {
    data: IQueueMessage;
    state: boolean;
}

export interface IQElement {
    fn: (data: any) => any;
    callback: (qEvent: IGenericMessageQueueEvent) => any;
    id: string;
    data: any;
    timeout?: any;
    fallbackTimeoutRef?: any
}

export interface IQContainer {
    elements: IQElement[];
    id: number;
    processRunning: boolean;
    options: IQOptions;
}

export interface IQOptions {
    size: number;
    delay: number;
    timeout?: number;
    fallbackTimeout?: number;
}

export enum EQueueMessageCode {
    info = 0,
    warn = 1,
    error = 2
}

export interface IQueueMessage {
    message: any;
    priority: boolean;
    code: number;
    scheduled: boolean;
    timedelta: number;
    timeout: any;
    persistent?: boolean
}
