export enum EAlertButtonCodes {
    ok = 0,
    cancel = 10,
    aux1 = 1,
    aux2 = 2,
    aux3 = 3,
    aux4 = 4,
    aux5 = 5,
}

export enum EModalClass {
    modal = "modal",
    overlay = "overlay"
}

export enum EClass {
    HUD_BAN_NORMAL = "hud-xp gmap-margin-bottom semi-transp-uniform",
    HUD_BAN_SMALL = "hud-xp gmap-margin-bottom-small semi-transp-uniform",
    HUD_BAN_NORMAL_IOSX = "hud-xp gmap-margin-bottom-iosx semi-transp-uniform",
    HUD_BAN_SMALL_IOSX = "hud-xp gmap-margin-bottom-iosx-small semi-transp-uniform",

    HUD_BAN_AR_NORMAL = "hud-xp ar-margin-bottom semi-transp-uniform",
    HUD_BAN_AR_IOSX = "hud-xp ar-margin-bottom-iosx semi-transp-uniform",

    // MAP_VIEW = "map-view gmap-transp capacitor-google-map",
    // MAP_VIEW_HIDE = "map-view map-hide gmap-transp capacitor-google-map",

    MAP_VIEW = "map-view capacitor-google-map",
    MAP_VIEW_HIDE = "map-view map-hide capacitor-google-map",

    HUD_INFO = "semi-transp-uniform",
    HUD_WARN = "semi-transp-uniform-warn",
    HUD_ERROR = "semi-transp-uniform-error",

    HUD_INNER_INFO = "text-color-primary",
    HUD_INNER_WARN = "text-color-alternate",
    HUD_INNER_ERROR = "text-color-warn"
}

export enum EFooterClass {
    FOOTER_NORMAL = "toolbar-background-md semi-transp-uniform leplace-footer",
    FOOTER_SMALL = "toolbar-background-md semi-transp-uniform leplace-footer-small",
    FOOTER_NORMAL_IOSX = "toolbar-background-md semi-transp-uniform leplace-footer-iosx",
    FOOTER_SMALL_IOSX = "toolbar-background-md semi-transp-uniform leplace-footer-iosx-small",

    FOOTER_CONTENT = "content-height",
    FOOTER_CONTENT_IOSX = "content-height-iosx"
}

export enum EHeaderClass {
    HEADER_NORMAL = "toolbar-background-md semi-transp-uniform leplace-header",
    HEADER_SMALL = "toolbar-background-md semi-transp-uniform leplace-header-small"
}

export interface IButtonOptions {
    name: string;
    icon: string;
    customIcon?: boolean;
    class: string;
    class2?: string;

    /**
     * col size
     */
    size: number;
    disabled: boolean;
    enabled: boolean;
    highlight: boolean;
    callback: () => any;
    code: number;
}

export interface IButtonCollection {
    [key: string]: IButtonOptions;
}

export class ButtonDefaults {
    static getDefaultButton() {
        let button: IButtonOptions = {
            name: "",
            icon: "",
            class: "",
            class2: "",
            size: 1,
            disabled: true,
            enabled: true,
            highlight: false,
            callback: () => {

            },
            code: 1
        };
        return button;
    }
}



export interface IButtonStyle {
    name: string,
    color: string,
    textColor: string,
    highColor: string,
    highTextColor: string
}

export interface IButtonStyleContainer {
    primary: IButtonStyle,
    primary70: IButtonStyle,
    alternate: IButtonStyle,
    alternate70: IButtonStyle,
    warn: IButtonStyle,
    accent: IButtonStyle,
    transp: IButtonStyle
}


export class ButtonStyles {
    static style: IButtonStyleContainer = {
        primary: {
            name: "primary",
            color: "button-color-primary",
            textColor: "text-color-primary",
            highColor: "button-color-alternate",
            highTextColor: "text-color-alternate"
            // highColor: "button-color-accent",
            // highTextColor: "text-color-accent"
        },
        primary70: {
            name: "primary70",
            color: "button-color-primary-70",
            textColor: "text-color-primary",
            highColor: "button-color-alternate-70",
            highTextColor: "text-color-alternate"
        },        
        transp: {
            name: "transp",
            color: "button-color-transparent",
            textColor: "text-color-primary",
            highColor: "button-color-alternate-70",
            highTextColor: "text-color-alternate"
        },
        alternate: {
            name: "alternate",
            color: "button-color-alternate",
            textColor: "text-color-alternate",
            highColor: "button-color-accent",
            highTextColor: "text-color-accent"
        },
        alternate70: {
            name: "alternate70",
            color: "button-color-alternate",
            textColor: "text-color-alternate",
            highColor: "button-color-accent-70",
            highTextColor: "text-color-accent"
        },
        warn: {
            name: "warn",
            color: "button-color-warn",
            textColor: "text-color-warn",
            highColor: "button-color-alternate",
            highTextColor: "text-color-alternate"
        },
        accent: {
            name: "accent",
            color: "button-color-accent",
            textColor: "text-color-accent",
            highColor: "button-color-alternate",
            highTextColor: "text-color-alternate"
        }
    }
}