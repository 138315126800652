import { Component, OnInit, OnDestroy, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { IActivityQuestSpecs, IActivityQuestResponse, IQuestSelectMultiOption } from 'src/app/classes/def/nav-params/activity-details';
import { EAppIcons } from 'src/app/classes/def/app/icons';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EQuestAction, EQuestType } from 'src/app/classes/def/activity/quest';
import { QuestUtils } from 'src/app/services/app/modules/activities/quest-utils';
import { StringUtils } from 'src/app/services/app/utils/string-utils';
import { ArrayUtils } from 'src/app/services/utils/array-utils';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ITextInputParams, ITextInputResult, TextInputViewComponent } from 'src/app/modals/generic/modals/text-input/text-input.component';

@Component({
  selector: 'quest-item',
  templateUrl: './quest-item.component.html',
  styleUrls: ['./quest-item.component.scss'],
})
export class QuestItemComponent implements OnInit, OnChanges, OnDestroy {
  // @ViewChild('input', { read: IonInput, static: false }) myInput: IonInput;

  appIcons = EAppIcons;

  resp: IActivityQuestResponse = {
    q: "",
    a: null,
    valid: false
  };

  withClue: boolean = false;
  withMapClue: boolean = false;
  shakeActive: boolean = false;

  useDropdown: boolean = false;
  inputFocused: boolean = false;

  @Input()
  ownButton: boolean;

  @Input()
  dframe: boolean;

  @Input()
  shake: boolean;

  @Input()
  loading: boolean;

  @Input()
  showAnswers: boolean;

  @Input()
  data: IActivityQuestSpecs;

  @Output() action = new EventEmitter<number>();
  @Output() submitResponse = new EventEmitter<IActivityQuestResponse>();
  @Output() check = new EventEmitter<IActivityQuestResponse>();
  @Output() inputFocus = new EventEmitter<boolean>();

  shakeTimeout;

  questType = {
    text: false,
    numeric: false,
    select: false,
    selectMulti: false
  };

  noptions: number = 1;
  questSelectOptions: string[] = [];
  questSelectOptionSelected: number = null;
  questSelectMultiOptions: IQuestSelectMultiOption[] = [];
  questSelectShuffleIdx: number[] = [];
  type: number = EQuestType.text;
  questionLine: string = "";
  shuffle: boolean = false;

  disableInput: boolean = false;
  selectText: string = "<select the correct answer>";
  selectMultiText: string = "<select the correct answer(s)>";

  selectedTypeIcon: string = "";
  selectedQuestTypeName: string = "";
  selectedQuestTypeDescription: string = "";

  respContainer = {
    a: ""
  };

  respContainerCopy = {
    a: ""
  };

  constructor(
    public uiext: UiExtensionService
  ) { }

  ngOnInit() {
    if (!this.data) {
      return;
    }
    this.withClue = QuestUtils.checkAction(this.data, null, null, EQuestAction.requireClue, false).action != null;
    this.withMapClue = QuestUtils.checkAction(this.data, null, null, EQuestAction.requireMapClue, false).action != null;
    if (this.data.q) {
      this.questionLine = StringUtils.textFromHTML(this.data.q);
    }
    this.formatQuestParams();
    if (this.showAnswers) {
      this.revealAnswers();
      this.disableInput = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'shake':
            this.setShake();
            break;
          case 'showAnswers':
            if (this.showAnswers) {
              this.disableInput = true;
            }
            break;
          default:
            break;
        }
      }
    }
  }

  textToHTML() {
    this.respContainer.a = StringUtils.textToHTML(this.respContainerCopy.a);
  }

  textFromHTML() {
    this.respContainerCopy.a = StringUtils.textFromHTML(this.respContainer.a);
  }

  setInputFocus(state: boolean) {
    this.inputFocus.emit(state);
    // this.inputFocused = state;
    // UIUtils.scrollActiveElementIntoVIew();
  }

  textAreaFocused(focused: boolean) {
    this.inputFocused = focused;
    // UIUtils.scrollActiveElementIntoVIew();
  }

  formatQuestParams() {
    console.log("format quest params: ", this.data);
    this.type = this.data.t;
    this.shuffle = this.data.shuffle === true;
    if (!this.type) {
      return;
    }
    switch (this.type) {
      case EQuestType.text:
        if ((this.data.opt != null) && (this.data.opt.length > 0)) {
          this.noptions = this.data.opt.length;
          this.formatSelection();
          this.questType.text = true;
        } else {
          // this.questType.text = true;
          this.data.opt = [this.data.a as string];
          this.noptions = 1;
          this.formatSelection();
          this.questType.text = true;
        }
        this.selectedTypeIcon = this.appIcons.questText;
        this.selectedQuestTypeName = "Text-based quest";
        this.selectedQuestTypeDescription = "<p>Write the answer into the text area</p><p>Scroll down to check your answer</p>";
        break;
      case EQuestType.numeric:
        this.questType.numeric = true;
        this.selectedTypeIcon = this.appIcons.questNumeric;
        this.selectedQuestTypeName = "Number-based quest";
        this.selectedQuestTypeDescription = "<p>Insert the answer into the numeric input</p><p>Scroll down to check your answer</p>";
        break;
      case EQuestType.select:
        this.noptions = this.data.opt.length;
        this.formatSelection();
        this.questType.select = true;
        this.selectedTypeIcon = this.appIcons.questSelect;
        this.selectedQuestTypeName = "Multiple-selection quest";
        this.selectedQuestTypeDescription = "<p>Select the correct answer</p><p>Scroll down to check your answer</p>";
        break;
      case EQuestType.selectMulti:
        this.noptions = this.data.opt.length;
        this.formatSelectionMulti();
        this.questType.selectMulti = true;
        this.selectedTypeIcon = this.appIcons.questSelect;
        this.selectedQuestTypeName = "Multiple-selection quest";
        this.selectedQuestTypeDescription = "<p>Select the correct answer(s)</p><p>Scroll down to check your answer</p>";
        break;
      default:
        this.selectedQuestTypeName = "Generic-type quest";
        this.selectedQuestTypeDescription = "<p>Enter the correct answer</p><p>Scroll down to check your answer</p>";
        break;
    }
  }

  showQuestTypeDescription() {
    this.uiext.showAlertNoAction(this.selectedQuestTypeName, this.selectedQuestTypeDescription, false);
  }

  formatSelection() {
    let opts: string[] = this.data.opt;
    if (this.shuffle) {
      this.questSelectShuffleIdx = ArrayUtils.shuffleArrayGetIndex(opts);
      this.questSelectOptions = ArrayUtils.shuffleArrayByIndex(opts, this.questSelectShuffleIdx);
    } else {
      this.questSelectShuffleIdx = opts.map((_value, index, _array) => index);
      this.questSelectOptions = opts;
    }
    console.log("shuffle idx: ", this.questSelectShuffleIdx);
    console.log("opts: ", this.questSelectOptions);
  }

  formatSelectionMulti() {
    let opts: IQuestSelectMultiOption[] = this.data.opt.map(e => {
      let opt: IQuestSelectMultiOption = {
        val: e,
        isChecked: false
      };
      return opt;
    });
    if (this.shuffle) {
      this.questSelectShuffleIdx = ArrayUtils.shuffleArrayGetIndex(opts);
      this.questSelectMultiOptions = ArrayUtils.shuffleArrayByIndex(opts, this.questSelectShuffleIdx);
    } else {
      this.questSelectShuffleIdx = opts.map((_value, index, _array) => index);
      this.questSelectMultiOptions = opts;
    }
    console.log("shuffle idx: ", this.questSelectShuffleIdx);
    console.log("opts: ", this.questSelectMultiOptions);
  }

  revealAnswers() {
    switch (this.type) {
      case EQuestType.text:
        if ((this.data.opt != null) && (this.data.opt.length > 0)) {
          this.resp.a = this.data.opt.join(", ");
        } else {
          // this.questType.text = true;
          this.resp.a = this.data.a;
        }
        break;
      case EQuestType.numeric:
        this.resp.a = this.data.a;
        break;
      case EQuestType.select:
        this.questSelectOptionSelected = this.questSelectShuffleIdx[this.data.a as number];
        break;
      case EQuestType.selectMulti:
        let validSpecs: string[] = (this.data.a as string).split(",");
        let validSpecsInd: number[] = validSpecs.map(vs => Number.parseInt(vs));
        for (let vsi of validSpecsInd) {
          this.questSelectMultiOptions[this.questSelectShuffleIdx[vsi]].isChecked = true;
        }
        break;
      default:
        break;
    }
  }

  ngOnDestroy() {
    this.shakeTimeout = ResourceManager.clearTimeout(this.shakeTimeout);
  }

  setShake() {
    if (this.shakeActive) {
      return;
    }
    this.shakeActive = true;
    this.shakeTimeout = setTimeout(() => {
      this.shakeActive = false;
    }, 1000);
  }

  checkInvalid() {
    let valid: boolean = false;
    switch (this.type) {
      case EQuestType.text:
        valid = this.resp.a != null;
        break;
      case EQuestType.numeric:
        valid = this.resp.a != null;
        break;
      case EQuestType.select:
        valid = this.questSelectOptionSelected != null;
        break;
      case EQuestType.selectMulti:
        valid = true;
        break;
    }
    return !valid;
  }

  submit() {
    this.resp.valid = !this.checkInvalid();
    // submit response
    this.submitResponse.emit(this.resp);
    // then request validation
    setTimeout(() => {
      this.check.emit(this.resp);
    }, 1);
  }

  updateSpec() {
    console.log("update spec");
    console.log(this.questSelectOptionSelected);
    let actualQuestionIdx: number = this.questSelectShuffleIdx[this.questSelectOptionSelected];
    this.resp.a = actualQuestionIdx;
    this.resp.sel = this.questSelectMultiOptions;

    console.log("resp: " + actualQuestionIdx);
    console.log("resp multi: ", this.resp.sel);

    setTimeout(() => {
      this.resp.valid = !this.checkInvalid();
      // submit for review instead of confirm
      this.submitResponse.emit(this.resp);
    }, 1);
  }

  radioSelect(event: any) {
    console.log("radio select ", event.detail);
    this.questSelectOptionSelected = event.detail.value;
    this.updateSpec();
  }

  checkboxSelect(event: any) {
    console.log("checkbox select ", event.detail);
    this.updateSpec();
  }

  review(update: boolean, ext: boolean) {
    if (update) {
      // console.log("review: ", this.resp);
      // take the last char, ensure this via timeout
      if (ext) {
        this.resp.a = this.respContainerCopy.a;
      }
      setTimeout(() => {
        this.resp.valid = !this.checkInvalid();
        // submit for review instead of confirm
        this.submitResponse.emit(this.resp);
      }, 1);
    } else {
      // popup window
      let params: ITextInputParams = {
        description: "<p></p>",
        title: "Edit response",
        text: this.respContainerCopy.a,
        focused: true,
        allowEmpty: true
      };

      this.uiext.showCustomModal(null, TextInputViewComponent, {
        view: {
          fullScreen: false,
          transparent: false,
          large: false,
          addToStack: true,
          frame: false
        },
        params: params
      }).then((description: ITextInputResult) => {
        if (description) {
          console.log(description);
          this.respContainerCopy.a = description.text;
          this.textToHTML();
          this.resp.a = this.respContainer.a;
          setTimeout(() => {
            this.resp.valid = !this.checkInvalid();
            // submit for review instead of confirm
            this.submitResponse.emit(this.resp);
          }, 1);
        }
      });
    }
  }

  requireClue() {
    this.sendAction(EQuestAction.requireClue);
  }

  showMap() {
    this.sendAction(EQuestAction.requireMapClue);
  }

  sendAction(action: number) {
    this.action.emit(action);
  }

}
