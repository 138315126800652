import { Component, OnInit, Input, OnDestroy, SimpleChange, SimpleChanges } from '@angular/core';
import { timer } from 'rxjs';
import { EPhotos } from 'src/app/classes/def/app/icons';
import { ResourceManager } from 'src/app/classes/general/resource-manager';

@Component({
  selector: 'leplace-plate',
  templateUrl: './leplace-plate.component.html',
  styleUrls: ['./leplace-plate.component.scss'],
})
export class LeplacePlateComponent implements OnInit, OnDestroy {

  logoSrc: string = EPhotos.logo;

  @Input()
  plateSub: string;

  @Input()
  plateFooter: string;

  /** blink (absolute) */
  @Input()
  set: boolean;

  /**
   * blink (absolute) for a finite amount of time, on state transition
   */
  @Input()
  setTimeoutOnChange: boolean;

  blinkEnable: boolean = false;
  state: boolean = false;
  timerSub = null;
  blinkTimeout = null;

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      // console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'set':
            this.blinkEnable = chg.currentValue;
            this.setBlink(false);
            break;
          case 'setTimeoutOnChange':
            this.blinkEnable = true;
            this.disableBlink();
            this.setBlink(true);
            break;
        }
      }
    }
  }

  setBlink(timeout: boolean) {
    if (this.blinkEnable) {
      this.enableBlink(timeout ? 5 : 0);
      if (timeout) {
        this.blinkTimeout = setTimeout(() => {
          this.blinkEnable = false;
          this.disableBlink();
        }, 5500);
      }
    } else {
      this.disableBlink();
    }
  }

  enableBlink(count: number) {
    if (!this.timerSub) {
      let timer1 = timer(0, 500);
      let flagCount: boolean = ((count != null) && (count > 0));
      let countk: number = count * 2;
      this.timerSub = timer1.subscribe(() => {
        this.state = !this.state;
        if (flagCount) {
          countk -= 1;
          if (countk <= 0) {
            this.disableBlink();
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  disableBlink() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.blinkTimeout = ResourceManager.clearTimeout(this.blinkTimeout);
    this.state = false;
  }


  ngOnInit() {    
    if (this.set) {
      this.blinkEnable = true;
      this.setBlink(false);
    }
  }

  ngOnDestroy() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.blinkTimeout = ResourceManager.clearTimeout(this.blinkTimeout);
  }

}
