
import { Injectable } from '@angular/core';
import * as introJsLib from 'intro.js/intro.js';
import { ITutorial, ETutorialMode } from 'src/app/classes/def/app/tutorials';


@Injectable({
    providedIn: 'root'
})
export class WalkthroughService {

    constructor(

    ) {
        console.log("walkthrough service created");
    }

    showGmapIntroDroneOnly(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let intro = introJsLib(document.querySelector("app-gmap"));
            let options = {
                steps: [
                    {
                        intro: "This is your story map. Let me to show you around."
                    },
                    {
                        element: '#btnOptions',
                        intro: "Here you can open the Main Menu with additional game controls.",
                        position: 'right'

                    },
                    {
                        element: '#btnFollow',
                        intro: "Remember, it's a real world game. You can switch between current location and drone mode.",
                        position: 'left'
                    },
                    {
                        element: '#btnDrone',
                        intro: "This is a drone only story. GPS is disabled during this session. Use the drone to navigate through the story.",
                        position: 'right'
                    },
                    {
                        element: '#elementDashboard',
                        intro: "Here is your main dashboard that lets you control the game.",
                        position: 'top'
                    },
                    {
                        intro: "Now you are ready to begin the story!"
                    }
                ],
                showProgress: true,
                skipLabel: " Skip ",
                doneLabel: " Ok ",
                nextLabel: " > ",
                prevLabel: " < ",
                showStepNumbers: false,
                showBullets: false,
                disableInteraction: true
            };

            intro.setOptions(options);
            intro.start();

            intro.oncomplete(() => {
                console.log("walkthrough complete");
                resolve(true);
            });

            intro.onexit(() => {
                console.log("walkthrough exit");
                resolve(false);
            });
        });
        return promise;
    }


    showGmapIntroPart1(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let intro = introJsLib(document.querySelector("app-gmap"));
            let options = {
                steps: [
                    {
                        intro: "Hello world! This is your World Map. Let me to show you around."
                    },
                    {
                        element: '#btnOptions',
                        intro: "Here you can open the Main Menu with additional game controls.",
                        position: 'right'

                    },
                    {
                        element: '#btnFollow',
                        intro: "Remember, it's a real world game. You can switch between GPS lock, Nav mode and Drone mode.",
                        position: 'left'
                    },
                    {
                        element: '#elementDashboard',
                        intro: "Here is your main dashboard that lets you control the game.",
                        position: 'top'
                    },
                    {
                        intro: "Now you are ready to explore the World Map!"
                    }
                ],
                showProgress: true,
                skipLabel: " Skip ",
                doneLabel: " Ok ",
                nextLabel: " > ",
                prevLabel: " < ",
                showStepNumbers: false,
                showBullets: false,
                disableInteraction: true
            };

            intro.setOptions(options);
            intro.start();

            intro.oncomplete(() => {
                console.log("walkthrough complete");
                resolve(true);
            });

            intro.onexit(() => {
                console.log("walkthrough exit");
                resolve(false);
            });
        });
        return promise;
    }

    showGmapIntroPart2(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let intro = introJsLib(document.querySelector("app-gmap"));
            let options = {
                steps: [
                    {
                        intro: "This is your main menu with additional game controls."
                    },
                    {
                        intro: "Now you are ready to explore the World Map!"
                    }
                ],
                showProgress: true,
                skipLabel: " Skip ",
                doneLabel: " Ok ",
                nextLabel: " > ",
                prevLabel: " < ",
                showStepNumbers: false,
                showBullets: false,
                disableInteraction: true
            };

            intro.setOptions(options);
            intro.start();

            intro.oncomplete(() => {
                console.log("walkthrough complete");
                resolve(true);
            });

            intro.onexit(() => {
                console.log("walkthrough exit");
                resolve(false);
            });
        });
        return promise;
    }


    showDroneModeIntro(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let intro = introJsLib(document.querySelector("app-gmap"));
            let options = {
                steps: [
                    {
                        intro: "Welcome to Drone Mode! Now you can send your drone to explore the nearby places and collect treasures along the way!"
                    },
                    {
                        element: "#controlJoystick",
                        intro: "Use the joystick to control the speed and direction of the drone.",
                        position: 'top'
                    },
                    {
                        element: "#btnFollowDrone",
                        intro: "Land drone",
                        position: 'left'
                    },
                    {
                        intro: "You are now ready to fly!"
                    }
                ],
                showProgress: true,
                skipLabel: " Skip ",
                doneLabel: " Ok ",
                nextLabel: " > ",
                prevLabel: " < ",
                showStepNumbers: false,
                showBullets: false,
                disableInteraction: true
            };

            intro.setOptions(options);
            intro.start();

            intro.oncomplete(() => {
                console.log("walkthrough complete");
                resolve(true);
            });

            intro.onexit(() => {
                console.log("walkthrough exit");
                resolve(false);
            });
        });
        return promise;
    }


    showARViewIntro(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let intro = introJsLib(document.querySelector("app-ar-view-entry"));
            let options = {
                steps: [
                    {
                        intro: "Welcome to AR View! Look around and interact with the AR objects by tapping on them."
                    },
                    // {
                    //     element: "#btnARCapture",
                    //     intro: "You can capture images with AR objects",
                    //     position: 'left'
                    // },
                    {
                        element: "#btnARMagnet",
                        intro: "You can also use the magnet when you are nearby AR objects.",
                        position: 'top'
                    },
                    {
                        intro: "Now you can explore the World Map using the AR View!"
                    }
                ],
                showProgress: true,
                skipLabel: " Skip ",
                doneLabel: " Ok ",
                nextLabel: " > ",
                prevLabel: " < ",
                showStepNumbers: false,
                showBullets: false,
                disableInteraction: true
            };

            intro.setOptions(options);
            intro.start();

            intro.oncomplete(() => {
                console.log("walkthrough complete");
                resolve(true);
            });

            intro.onexit(() => {
                console.log("walkthrough exit");
                resolve(false);
            });
        });
        return promise;
    }


    showChallengeIntro(tutorials: ITutorial[]): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (!(tutorials && tutorials.length > 0)) {
                resolve(false);
                return;
            }
            let intro = introJsLib(document.querySelector("app-gmap"));
            let options = {
                steps: [],
                showProgress: true,
                skipLabel: " Skip ",
                doneLabel: " Ok ",
                nextLabel: " > ",
                prevLabel: " < ",
                showStepNumbers: false,
                showBullets: false,
                disableInteraction: true
            };

            for (let i = 0; i < tutorials.length; i++) {
                let t: ITutorial = tutorials[i];
                if (t && t.description) {
                    switch (t.mode) {
                        case ETutorialMode.leftGauge:
                            options.steps.push({
                                element: "#navGaugeLeft",
                                intro: t.description,
                                position: 'right'
                            });
                            break;
                        case ETutorialMode.rightGauge:
                            options.steps.push({
                                element: "#navGaugeRight",
                                intro: t.description,
                                position: 'left'
                            });
                            break;
                        default:
                            break;
                    }
                }
            }

            intro.setOptions(options);
            intro.start();

            intro.oncomplete(() => {
                console.log("walkthrough complete");
                resolve(true);
            });

            intro.onexit(() => {
                console.log("walkthrough exit");
                resolve(false);
            });
        });
        return promise;
    }



}
