
export enum ELeaderboardMode {
    global = 1,
    fresh = 2,
    local = 3
}

export enum ELeaderboardExtMode {
    combined = 1,
    players = 2,
    teams = 3
}