import { Component, OnInit, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { IButtonCollection, IButtonOptions, EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { IEventDetails, IEventGroup } from 'src/app/classes/def/events/events';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { EventsDataService } from 'src/app/services/data/events';
import { ModalController, Platform, PopoverController } from '@ionic/angular';
import { StoryDataService } from 'src/app/services/data/story';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { ModularViewsService } from 'src/app/services/utils/modular-views';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { BusinessDataService } from 'src/app/services/data/business';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ModularInteractionService } from 'src/app/services/utils/modular-interaction';
import { LocalDataService } from 'src/app/services/data/local';
import { MiscDataService } from 'src/app/services/data/misc';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { QRService } from 'src/app/services/general/apis/qr';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { Messages } from 'src/app/classes/def/app/messages';
import { ERouteDef } from 'src/app/app-utils';
import { IModalEvent, EModalEmitter } from 'src/app/classes/def/app/modal-events';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { IEventsJoinReturnParams, IEventsJoinNavParams } from 'src/app/classes/def/nav-params/events';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IGroup, IGroupRole } from 'src/app/classes/def/mp/groups';
import { ListSelectorViewComponent } from 'src/app/modals/generic/modals/list-selector/list-selector.component';
import { IListSelectorItem, IListSelectorParams } from 'src/app/classes/def/dynamic/list';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { Router } from '@angular/router';
import { EModalEvents, Events } from 'src/app/services/utils/events';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { ILatLng } from 'src/app/classes/def/map/coords';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';


interface IButtonCollectionViewMain extends IButtonCollection {
  join: IButtonOptions;
  quit: IButtonOptions;
  proceed: IButtonOptions;
}

interface IButtonsViewMain {
  buttons: IButtonCollectionViewMain;
}


interface IEventsHomeButtonOptions {
  main: IButtonsViewMain;
}

enum EEventsApiLevel {
  standard = 1,
  mp = 2
}

@Component({
  selector: 'app-events-join',
  templateUrl: './events-join.page.html',
  styleUrls: ['./events-join.page.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0
      })),
      state('active', style({
        // transform: 'translateY(0)',
        opacity: 1
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class EventsJoinPage implements OnInit, OnDestroy {
  event: IEventDetails;
  eventId: number = 0;
  title: string = "Join Event";

  eventProgress: number = 0;

  inProgress: boolean = false;

  finished: boolean = false;
  loaded: boolean = false;
  saved: boolean = false;

  reload: boolean = true;

  locationURL: string = null;

  params: IEventsJoinNavParams;
  coords: ILatLng;

  apiLevel: number = EEventsApiLevel.standard;

  appIcons = EAppIcons;

  showState: string = "inactive";
  timeout = null;

  theme: string = "theme-light theme-light-bg";

  photoUrl: string = "";
  photoLoaded: boolean = false;
  animate: boolean = true;
  registered: boolean = false;

  np: INavParams = null;

  groups: IGroup[] = [];
  eventGroups: IEventGroup[] = [];

  selectedGroup: IGroup = null;
  selectedGroupName: string = "Select team";
  selectedRoleCode: number = 0;
  selectedGroupId: number = -1;
  hasGroups: boolean = false;

  eventName: string = "";

  canJoin: boolean = false;
  canSelectTeam: boolean = false;
  canQuit: boolean = false;
  useCache: boolean = false;
  registeredGroupIds: number[] = [];

  vs: IViewSpecs;
  headerClass: string = "";

  isModal: boolean = false;
  alreadyRegistered: boolean = false;
  alreadyRegisteredId: number = null;

  viewContainer: IEventsHomeButtonOptions = {
    main: {
      buttons: {
        quit: {
          name: null,
          icon: "exit",
          customIcon: false,
          class: "action-button-fill button-color-warn",
          size: 1,
          disabled: false,
          enabled: false,
          highlight: false,
          callback: () => {
            this.quitEvent();
          },
          code: null
        },
        join: {
          name: null,
          customIcon: false,
          icon: "person-add",
          class: "action-button-fill button-color-alternate",
          size: 1,
          disabled: true,
          enabled: false,
          highlight: false,
          callback: () => {
            this.joinEventQR();
          },
          code: null
        },
        proceed: {
          name: null,
          customIcon: false,
          icon: "play",
          class: "action-button-fill button-color-accent",
          size: 1,
          disabled: true,
          enabled: false,
          highlight: false,
          callback: () => {
            this.proceed();
          },
          code: null
        }
      }
    }
  };

  @Input()
  public showChild: boolean = true;

  constructor(
    public eventDataProvider: EventsDataService,
    public router: Router,
    public storyDataProvider: StoryDataService,
    public modalCtrl: ModalController,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public analytics: AnalyticsService,
    public locationManager: LocationManagerService,
    public popoverCtrl: PopoverController,
    public backButton: BackButtonService,
    public modularViews: ModularViewsService,
    public resourcesProvider: ResourcesCoreDataService,
    public businessDataProvider: BusinessDataService,
    public settingsProvider: SettingsManagerService,
    public modularInteraction: ModularInteractionService,
    public localDataProvider: LocalDataService,
    public miscProvider: MiscDataService,
    public events: Events,
    public popupFeatures: PopupFeaturesService,
    public tutorials: TutorialsService,
    public nps: NavParamsService,
    public qrSecurity: QRService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  swipeEvent(e) {
    this.backButton.handleSwipeEvent(e);
  }

  ngOnInit() {

    this.uiext.disableSidemenu();

    this.analytics.trackView("events-join");

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.events, null, this.np);

      console.log("nav params: ", npInfo.params);

      let hasParams: boolean = npInfo.hasParams;

      if (hasParams) {

        let np: INavParams = npInfo.params;
        console.log(np);

        if (np && np.params) {
          this.params = np.params;
        }

        this.vs = np.view;        
        this.isModal = this.vs ? this.vs.isModal : false;

        this.reload = this.params.reload;

        if (this.params.event) {
          let event = this.params.event;

          this.eventId = event.id;
          this.eventName = event.name;

          this.apiLevel = event.apiLevel;
          this.canQuit = !event.adminControl;

          this.viewContainer.main.buttons.quit.disabled = !this.canQuit;
        }

        this.viewContainer.main.buttons.quit.disabled = !this.params.registered;
      } else {
        this.eventId = 0;
      }

      // can be opened either as a modal or a root page
      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back(false);
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });

      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
          this.loadEventGroupsWrapper();
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  loadEventGroupsWrapper() {
    this.loadEventGroups(this.eventId).then(() => {
      if (this.animate) {
        console.log("set show state active");
        this.showState = "active";
      }
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "events-join");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }

  loadEventGroups(eventId: number) {
    let promise = new Promise((resolve, reject) => {
      console.log("load event groups for eventId: " + eventId);
      this.eventDataProvider.viewGroups(eventId).then((egs: IEventGroup[]) => {
        console.log(egs);

        if (egs && egs.length > 0) {
          this.checkRegistered(egs);

          this.hasGroups = true;
          this.eventGroups = egs;
          this.groups = egs.map(eg => eg.group);

          for (let i = 0; i < this.groups.length; i++) {
            this.groups[i].alias = null;
          }

        } else {
          this.hasGroups = false;
        }
        this.loaded = true;
        resolve(true);
      }).catch((err: Error) => {
        this.loaded = true;
        reject(err);
      });
    });
    return promise;
  }

  checkRegistered(egs: IEventGroup[]) {
    for (let i = 0; i < egs.length; i++) {
      let group: IEventGroup = egs[i];
      if (group.registered) {
        this.selectGroup(group.group);
        this.viewContainer.main.buttons.join.disabled = true;
        this.alreadyRegistered = true;
        this.alreadyRegisteredId = group.groupId;
        this.selectedRoleCode = group.groupRole;
        // console.log("already registered: " + this.alreadyRegisteredId + " " + this.selectedRoleCode);
      }
    }
  }

  ngOnDestroy() {
    this.uiext.enableSidemenu();
    this.backButton.checkPop(this.vs);
  }


  back(join: boolean) {
    console.log("back: ", join);
    if (this.isModal) {
      let modalEvent: IModalEvent = {
        emitter: EModalEmitter.storyDetailBack
      };
      this.events.publish(EModalEvents.dismiss, modalEvent);
      this.dismissModal(join);
    } else {
      this.router.navigate([ERouteDef.eventsHome], { replaceUrl: true }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  /**
   * return handle to events home with the selected group details
   * the events home page will take care of the rest
   * @param join 
   */
  dismissModal(join: boolean) {
    let params: IEventsJoinReturnParams = {
      join: join,
      selectedGroupId: this.selectedGroup ? this.selectedGroup.id : null,
      selectedRoleCode: this.selectedRoleCode
    };
    console.log("dismiss modal: ", join);
    this.modalCtrl.dismiss(params).then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * select group
   * select/preview group roles
   * @param group 
   */
  selectGroup(group: IGroup) {
    if (!group) {
      return;
    }
    this.selectedGroupId = group.id;
    if (this.alreadyRegistered) {
      this.viewGroupRoles(group.id).then((data: any) => {
        if (data) {
          this.uiext.showAlertNoAction(Messages.msg.alreadyRegistered.before.msg, Messages.msg.alreadyRegistered.before.sub);
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    } else {
      if (group) {
        this.selectedGroup = group;
        this.selectedGroupName = "Selected team: " + group.name;
        this.viewContainer.main.buttons.join.disabled = false;
      }
    }
  }


  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      showDetails: {
        name: "Tutorial",
        code: 3,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      }
    };

    // let actions2: IPopoverActions = {
    //   joinQR: {
    //     name: "Join Event",
    //     code: 4,
    //     enabled: true
    //   },
    //   joinAutoQR: {
    //     name: "Join Event Default",
    //     code: 5,
    //     enabled: true
    //   }
    // };

    // actions = Object.assign(actions, actions2);

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: true,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((code: number) => {
      switch (code) {
        case 3:
          this.onHelpClick();
          break;
        case 4:
          this.joinEventQR();
          break;
        case 5:
          this.joinEventQRAuto();
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  proceed() {
    if (this.canJoin) {
      this.dismissModal(true);
    }
  }

  /**
   * view group roles
   * select or preview
   * @param groupId 
   */
  viewGroupRoles(groupId: number) {
    let promise = new Promise((resolve, reject) => {
      this.eventDataProvider.getRoles(this.eventId, groupId).then((roles: IGroupRole[]) => {
        // this.useCache = true;
        // wrap items to pass to the group role card component
        let rolesContainer = roles.map((role) => {
          let listItem: IListSelectorItem = {
            selected: false,
            available: role.available,
            item: role,
            inputName: "groupRole"
          };
          // set current group role for registered group

          let isRegistered: boolean =
            this.registeredGroupIds &&
            (this.registeredGroupIds.indexOf(groupId) !== -1) || (this.alreadyRegisteredId === groupId);

          if (role.code === this.selectedRoleCode && isRegistered) {
            listItem.selected = true;
          }
          return listItem;
        });

        let params: IListSelectorParams = {
          title: "Select a role",
          componentType: "groupRole",
          listItems: rolesContainer,
          direct: false,
          customSelect: false
        };

        console.log(params);

        this.uiext.showCustomModal(null, ListSelectorViewComponent, {
          view: {
            fullScreen: false,
            transparent: false,
            large: true,
            addToStack: true,
            frame: false
          },
          params: params
        }).then((data: any) => {
          console.log(data);
          resolve(data);
        }).catch((err: Error) => {
          reject(err);
        });
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * join event
   * select group and role
   */
  joinEventQR() {
    if (!this.selectedGroup) {
      this.uiext.showAlertNoAction(Messages.msg.groupNotSelected.after.msg, Messages.msg.groupNotSelected.after.sub);
      return;
    }
    // select role
    this.viewGroupRoles(this.selectedGroup.id).then((data: any) => {
      if (data) {
        let groupRole: IGroupRole = data.groupRole;
        if (groupRole != null) {
          this.selectedRoleCode = groupRole.code;
          this.canJoin = true;
          this.viewContainer.main.buttons.proceed.disabled = false;
        }
        // this.dismissModal(true);
      }
    }).catch((err: Error) => {
      console.error(err);
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
    });
  }

  /**
   * join event
   * scan the QR code provided for the group
   * select role
   */
  joinEventQRAuto() {
    this.dismissModal(true);
  }

  /**
   * check selected or already registered
   * @param g 
   */
  checkSelected(g: IGroup) {
    if (g.id === this.selectedGroupId) {
      return true;
    }
    if (this.registered) {
      let groupRegistered: boolean = this.eventGroups.find(eg => {
        if (eg.groupId === g.id && eg.registered) {
          return true;
        }
        return false;
      }) != null;
      return groupRegistered;
    }
    return false;
  }

  /**
   * remove user from current group
   */
  quitEvent() {
    console.log("quit event");
    this.uiext.showAlert(Messages.msg.quitEvent.before.msg, Messages.msg.quitEvent.before.sub, 2, null).then((res: number) => {
      if (res === EAlertButtonCodes.ok) {
        this.eventDataProvider.quitEventV2(this.eventId).then(() => {
          this.dismissModal(false);
        }).catch((err: Error) => {
          console.error(err);
          this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
        });
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  onHelpClick() {
    this.tutorials.showTutorialNoAction("Event Join Tutorial", ETutorialEntries.eventJoin, null, null, true);
  }

}



