import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LeaderboardPage } from './leaderboard.page';
import { PagesModule } from '../pages.module';
// import { PagesLinksModule } from '../pages-links.module';

const routes: Routes = [
  {
    path: '',
    component: LeaderboardPage
  }
];

@NgModule({
  imports: [
    PagesModule,
    // PagesLinksModule,
    RouterModule.forChild(routes)
  ],
  declarations: [LeaderboardPage]
})
export class LeaderboardPageModule { }
