import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { IGameItem } from 'src/app/classes/def/items/game-item';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { IShareMsgParams, MessageUtils, IShareMsg, EShareMsgFeatures } from 'src/app/classes/utils/message-utils';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ModalController } from '@ionic/angular';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AdsService } from 'src/app/services/general/apis/ads';
import { ParamHandler } from 'src/app/classes/general/params';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { EAchievementUnlockedParams } from 'src/app/classes/def/nav-params/achievement';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { NavParamsService } from 'src/app/services/app/nav-params';

@Component({
  selector: 'modal-achievement-unlocked',
  templateUrl: './achievement-unlocked.component.html',
  styleUrls: ['./achievement-unlocked.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AchievementUnlockedViewComponent implements OnInit, OnDestroy {
  theme: string = "theme-aubergine theme-aubergine-bg";
  title: string = "Reward Unlocked";
  infoHTML: string = "";
  shareMessage: string = "";
  /**
   * the achievements that will be shown in the grid
   */
  gameItems: IGameItem[] = [];

  coverItem: IGameItem = null;
  shareEnabled: boolean = true;
  watchEnabled: boolean = true;
  photoUrlLoaded: string = null;
  loading: boolean = false;
  reward: boolean = false;
  closeTimeout;
  closeTimerValue: number = 0;
  platform: IPlatformFlags = {} as IPlatformFlags;
  platformObs;
  params: IShareMsgParams;
  vs: IViewSpecs = ViewSpecs.getDefault();

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  footerMessage: string = "*already unlocked";
  showFooter: boolean = false;

  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController,
    public nps: NavParamsService,
    public settingsProvider: SettingsManagerService,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public adService: AdsService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    console.log(this.np);
    if (hasParams) {
      let np: INavParams = Object.assign({}, this.np as INavParams);
      this.params = Object.assign({}, MessageUtils.getDefaultShareMsgParams(), np.params);

      let gameItems: IGameItem[] = this.params.newAchievements.items;
      let gameItemsDict: { [key: string]: IGameItem } = {};

      // get distinct items, compute amounts
      if (gameItems && gameItems.length > 0) {
        let found: boolean = false;
        for (let gi of gameItems) {
          if (!found && gi.photoUrl) {
            this.coverItem = gameItems[0];
            found = true;
          }
          // show as unlocked (override)
          gi.newUnlockedAux = 1;
          gi.noBlink = true;
          if (gi.alreadyUnlocked) {
            this.showFooter = true;
          }
          let gid: IGameItem = gameItemsDict["" + gi.code];
          if (gid != null) {
            gid.validCount += 1;
          } else {
            if ((gi.validCount != null) && (gi.validCount != 0)) {
              // use server returned validCount
            } else {
              gi.validCount = 1;
            }
            gameItemsDict["" + gi.code] = gi;
          }
        }
      }

      for (let key in gameItemsDict) {
        this.gameItems.push(gameItemsDict[key]);
      }

      this.params.newAchievements.items = this.gameItems;

      if (np.view) {
        this.vs = np.view;
      }

      // console.log(np);
      let shareMsg: IShareMsg = MessageUtils.buildMessage(EShareMsgFeatures.achievementUnlocked, this.params);
      this.shareMessage = shareMsg.share;
      this.infoHTML = shareMsg.auxHTML;
    }

    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    this.platformObs = this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
      if (loaded) {
        this.onPlatformLoaded();
      }
    }, (err: Error) => {
      console.error(err);
    });

    // this.closeTimerLoop();
  }

  onPlatformLoaded() {
    this.platform = SettingsManagerService.settings.platformFlags;
  }

  /**
   * close the popup after 10 seconds of inactivity
   */
  closeTimerLoop() {
    this.closeTimeout = setTimeout(() => {
      if (this.closeTimerValue > 0) {
        this.closeTimerValue -= 1;
        this.closeTimerLoop();
      } else {
        // this.dismiss(null);
        this.continue();
      }
    }, 1000);
  }

  stopCloseTimeout() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
      this.closeTimeout = null;
      this.closeTimerValue = 0;
    }
  }

  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  /**
   * dismiss modal
   */
  continue() {
    this.stopCloseTimeout();
    this.dismiss(EAchievementUnlockedParams.default);
  }


  medals() {
    this.stopCloseTimeout();
    this.dismiss(EAchievementUnlockedParams.viewMedals);
  }

  /**
   * Share progress then dismiss
   */
  shareAndContinue() {
    this.stopCloseTimeout();
    this.loading = true;
    this.shareProvider.share(this.shareMessage).then(() => {
      this.loading = false;
    }).catch(() => {
      this.loading = false;
    });
  }

  ngOnDestroy() {
    console.log("close modal");
    this.stopCloseTimeout();
    if (this.platformObs) {
      this.platformObs.unsubscribe();
      this.platformObs = null;
    }
  }

  clearWatch() {

  }
}
