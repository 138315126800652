

import { Injectable } from '@angular/core';
// import * as crypto from 'crypto-js';
import { IPlatformFlags } from '../../../classes/def/app/platform';
import { SettingsManagerService } from '../settings-manager';
import { WebviewUtilsService } from '../../app/utils/webview-utils';
import { PermissionsService } from '../permissions/permissions';
import { PhotosService } from '../../media/photos';
import { UiExtensionService } from '../ui/ui-extension';
import { IUnlockContentParams, IUnlockContentReturnData, UnlockContentViewComponent } from 'src/app/modals/app/modals/unlock-content/unlock-content.component';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { CameraScanFrameViewComponent } from 'src/app/modals/generic/modals/camera-scan-frame/camera-scan-frame.component';
import { ICameraScanFrameNavParams, ICameraScanFrameReturnParams } from 'src/app/modals/generic/modals/camera-scan-frame/utils';
import {
    BarcodeScanner,
    BarcodeFormat,
    ScanResult,
    LensFacing,
    BarcodeValueType,
    IsSupportedResult,
    IsGoogleBarcodeScannerModuleAvailableResult,
} from '@capacitor-mlkit/barcode-scanning';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';

export interface IScanQRResult {
    scan: boolean,
    code: string,
    canceled: boolean,
    isCustom?: boolean
}

export interface IScanQRResultString {
    code: string,
    scan: boolean,
    isCustom?: boolean
}

export interface ICustomCodeInput {
    title: string,
    inputLabel: string,
    label: string,
    notes: string
}
@Injectable({
    providedIn: 'root'
})
export class QRService {
    serverUrl: string;
    key: string;
    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public settings: SettingsManagerService,
        public webviewUtils: WebviewUtilsService,
        public permissionsService: PermissionsService,
        public photosService: PhotosService,
        public uiext: UiExtensionService,
    ) {
        console.log("qr service created");
        this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    init() {
        console.log("qr security: " + this.key);
    }

    // setKey(key: string) {
    //     this.key = key;
    // }

    // encryptObjectAES(data) {
    //     let ciphertext = crypto.AES.encrypt(JSON.stringify(data), this.key).toString();
    //     return ciphertext;
    // }

    // decryptObjectAES(code) {
    //     let decrypted = crypto.AES.decrypt(code, this.key);
    //     decrypted = JSON.parse(decrypted.toString(crypto.enc.Utf8));
    //     return decrypted;
    // }

    scanQR(withModalOption: boolean, customTitle: string, customCodeInput: ICustomCodeInput): Promise<IScanQRResult> {
        let promise: Promise<IScanQRResult> = new Promise(async (resolve, reject) => {
            let scanQR: boolean = true;
            let unlockCode: string = null;

            let scanResult: IScanQRResult = {
                canceled: false,
                code: null,
                scan: true
            };

            if (withModalOption) {
                // show scan modal
                let params: IUnlockContentParams = {
                    title: customTitle != null ? customTitle : "Unlock content",
                    description: "<p>Scan the QR code or type in the unlock code to proceed.</p>",
                    customCodeInput: customCodeInput,
                    // photoUrl: EPhotos.QRInfo
                    photoUrl: null
                };

                let res: IUnlockContentReturnData = await this.uiext.showCustomModal(null, UnlockContentViewComponent, {
                    view: {
                        fullScreen: false,
                        transparent: false,
                        large: false,
                        addToStack: true,
                        frame: false
                    },
                    params: params
                });

                if ((res != null) && (res.actionCode === EAlertButtonCodes.ok)) {
                    if (res.isUnlockCode) {
                        unlockCode = res.unlockCode;
                        scanQR = false;
                        scanResult.scan = false;
                        scanResult.isCustom = res.isCustomUnlockCode;
                    }
                } else {
                    scanResult.canceled = true;
                    scanResult.code = null;
                    resolve(scanResult);
                    return;
                }
            }
            if (scanQR) {
                let useNativeScanner: boolean = false;
                if (!this.platform.WEB) {
                    let stat: IsSupportedResult = await BarcodeScanner.isSupported();
                    if (stat.supported) {
                        console.warn("barcode scanner not supported");
                        useNativeScanner = true;
                    }
                    if (GeneralCache.os === EOS.android) {
                        let isavail: IsGoogleBarcodeScannerModuleAvailableResult = await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable();
                        if (!isavail.available) {
                            console.warn("barcode scanner module not available");
                            useNativeScanner = false;
                        }
                    }
                }
                if (useNativeScanner) {
                    // native scanner
                    await this.permissionsService.requestCameraPermission();
                    let withLoading: boolean = await this.photosService.checkShowLoading("<p>Loading scanner</p>", true);

                    BarcodeScanner.scan({
                        // preferFrontCamera: false, // iOS and Android
                        // showFlipCameraButton: false, // iOS and Android
                        // showTorchButton: true, // iOS and Android
                        // torchOn: false, // Android, launch with the torch switched on (if available)
                        // prompt: "Scan QR code", // Android
                        // resultDisplayDuration: 0, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                        formats: [BarcodeFormat.QrCode], // default: all but PDF_417 and RSS_EXPANDED
                        // orientation: "portrait", // Android only (portrait|landscape), default unset so it rotates with the device
                        // disableAnimations: true, // iOS
                        // disableSuccessBeep: true // iOS and Android
                    }).then(async (barcodeData: ScanResult) => {
                        // Success! Barcode data is here
                        if (withLoading) {
                            await this.photosService.dismissLoading();
                        }
                        await this.webviewUtils.resetViewframe(false, false);
                        console.log("barcode data: ", barcodeData);
                        if (barcodeData && barcodeData.barcodes && barcodeData.barcodes.length > 0) {
                            scanResult.code = barcodeData.barcodes[0].displayValue;
                        }
                        resolve(scanResult);
                    }).catch(async (err: Error) => {
                        // An error occurred
                        console.error(err);
                        if (withLoading) {
                            await this.photosService.dismissLoading();
                        }
                        await this.webviewUtils.resetViewframe(false, false);
                        // reject(err);
                        scanResult.canceled = true;
                        resolve(scanResult);
                    });
                } else {
                    // WEB adapter
                    let params: ICameraScanFrameNavParams = {
                        title: "Scan QR code",
                        description: "",
                        caption: "lpworld"
                    };
                    let res: ICameraScanFrameReturnParams = await this.uiext.showCustomModal(null, CameraScanFrameViewComponent, {
                        view: {
                            fullScreen: false,
                            transparent: false,
                            large: true,
                            addToStack: true,
                            frame: false
                        },
                        params: params
                    });
                    if (res != null) {
                        scanResult.code = res.content;
                        resolve(scanResult);
                    } else {
                        reject(new Error("Scanner closed"));
                    }
                }
            } else {
                scanResult.code = unlockCode;
                resolve(scanResult);
            }
        });
        return promise;
    }

    /**
    * scan qr code
    * return content
    * @param withModalOption 
    * @returns QR code text
    */
    scanQRResultString(withModalOption: boolean, customTitle: string): Promise<string> {
        let promise: Promise<string> = new Promise(async (resolve, reject) => {
            this.scanQRResult(withModalOption, customTitle, null).then((res: IScanQRResultString) => {
                resolve(res.code);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    scanQRResultExtra(withModalOption: boolean, customTitle: string, customCodeInput: ICustomCodeInput): Promise<IScanQRResultString> {
        let promise: Promise<IScanQRResultString> = new Promise(async (resolve, reject) => {
            this.scanQRResult(withModalOption, customTitle, customCodeInput).then((res: IScanQRResultString) => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * scan qr code
     * return content
     * @param withModalOption 
     * @returns QR code text
     */
    scanQRResult(withModalOption: boolean, customTitle: string, customCodeInput: ICustomCodeInput): Promise<IScanQRResultString> {
        let promise: Promise<IScanQRResultString> = new Promise((resolve, reject) => {
            this.scanQR(withModalOption, customTitle, customCodeInput).then((data: IScanQRResult) => {
                if (data.canceled) {
                    reject(new Error("user canceled"));
                } else {
                    let res: IScanQRResultString = {
                        scan: data.scan,
                        code: data.code,
                        isCustom: data.isCustom
                    };
                    resolve(res);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    getQRScanSample() {
        // mock scan
        let bcr: ScanResult = {
            barcodes: [
                {
                    rawValue: "abc",
                    displayValue: "abc",
                    valueType: BarcodeValueType.Text,
                    format: BarcodeFormat.QrCode
                }
            ]
        };
        return bcr;
    }

}
