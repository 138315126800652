<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="true" rightButton="settings" [rightIcon]="true" title="Extras" (rightClick)="presentPopover()"
    [loading]="!loaded"></leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <!-- (swipe)="swipeEvent($event)" -->
  <div class="view-frame theme-background">
    <div [@showState]="showState" class="list-padding">
      <!-- no-lines -->
      <div *ngFor="let category of categories" #slidingItem class="list-item padding-s transparent-bg">
        <feature-info *ngIf="category.list" [data]="category" tappable (click)="openFeature(category)">
        </feature-info>
      </div>
    </div>
  </div>
</ion-content>