import { AfterContentInit, Component, ContentChild, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { InnerContentDirective } from 'src/app/directives/directives/inner-content';
import { KeyboardProvider } from 'src/app/services/apis/keyboard';

@Component({
  selector: 'edit-code',
  templateUrl: './edit-code.component.html',
  styleUrls: ['./edit-code.component.scss'],
})
export class EditCodeComponent implements OnInit, AfterContentInit {
  @ContentChild(InnerContentDirective, { read: ElementRef })
  content: ElementRef;
  _propVal: any;

  _prop: any;
  @Input()
  set prop(prop: any) {
    this._prop = prop;
  }
  get prop() {
    return this._prop;
  }
  @Output()
  propChange: EventEmitter<any> = new EventEmitter<any>();

  @Input()
  label: string;

  _disabled: boolean;
  @Input()
  set disabled(disabled: any) {
    this._disabled = disabled;
  }
  get disabled() {
    return this._disabled;
  }

  @Input()
  placeholder: string;

  @Input()
  dim: number;

  @Input()
  numeric: boolean;

  @Input()
  autocaps: boolean;

  @Output()
  inputChange: EventEmitter<any> = new EventEmitter();

  @Output()
  inputComplete: EventEmitter<any> = new EventEmitter();

  @Output()
  focusChange: EventEmitter<boolean> = new EventEmitter();

  autocapsMode: string = "off";
  inputType: string = "text";

  ios: boolean = false;

  backgroundStyle: string;

  constructor(
    public keyboard: KeyboardProvider
  ) {
    this.backgroundStyle = this.getBackgroundStyle();
  }

  ngOnInit(): void {
    this.ios = GeneralCache.checkPlatformOS() === EOS.ios;
    if (this.autocaps) {
      this.autocapsMode = "on";
    }
    if (this.numeric) {
      this.inputType = "number";
    }
    this.backgroundStyle = this.getBackgroundStyle();
  }


  onCodeChangedNoLag(_code: string) {
    console.log("on code changed nolag: ", this._prop);
    this.parseInput();
    // this actually does the trick
    this.propChange.emit(this._propVal);
    this.inputChange.emit(this._propVal);
    if (this.dim == null || ((this._prop != null) && (this._prop.length >= this.dim))) {
      this.keyboard.hide();
      this.inputComplete.emit(this._propVal);
    } else {
      this.inputComplete.emit(null);
    }
  }

  // this called every time when user changed the code
  onCodeChanged(code: string) {
    console.log(code);
    setTimeout(() => {
      this.parseInput();
      // this actually does the trick
      this.propChange.emit(this._propVal);
      setTimeout(() => {
        this.inputChange.emit(this._propVal);
      }, 1);
    }, 1);
  }

  onCodeFocused(focused: boolean) {
    console.log("on code focused");
    this.focusChange.emit(focused);
  }

  // this called only if user entered full code
  onCodeCompleted(code: string) {
    console.log("on code completed: ", code);
    setTimeout(() => {
      this.keyboard.hide();
      this.inputComplete.emit(code);
    }, 50);
  }

  onCodeNotCompleted() {
    console.log("on code not completed");
    setTimeout(() => {
      this.inputComplete.emit(null);
    }, 50);
  }

  parseInput() {
    let propVal: any;
    if (this.numeric) {
      propVal = Number.parseFloat(this._prop);
      if (Number.isNaN(propVal)) {
        propVal = null;
      }
    } else {
      // if (this._prop != null) {
      //   this._prop = this._prop.toUpperCase();
      // }
      propVal = this._prop;
    }
    this._propVal = propVal;
  }

  ngAfterContentInit() {
    if (!this.label) {
      if (this.content) {
        this.label = this.content.nativeElement.innerText;
      }
    }
  }

  getBackgroundStyle() {
    let nChar = this.dim;
    let charW = 1;
    let gap = 0.5;
    let charWgap = charW + gap;
    let inW = nChar * charWgap;
    let backgroundStyle = `repeating-linear-gradient(
      90deg,
      var(--lp-color-primary-text) 0,
      var(--lp-color-primary-text) ` + charW + "ch," + `
      transparent 0,
      transparent ` + charWgap + "ch" + `
  )
  0 100% / ` + (inW - gap) + `ch 2px no-repeat`;
    return backgroundStyle;
  }

  sendMsg() {

  }
}
