
import { Injectable } from "@angular/core";
import { Network } from '@ionic-native/network/ngx';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { BehaviorSubject } from 'rxjs';
import { WaitUtils } from '../../utils/wait-utils';

export enum ENetworkMonitorState {
    disconnected = 1,
    connected = 2,
    error = 3
}

@Injectable({
    providedIn: 'root'
})
export class NetworkMonitorService {
    observable: BehaviorSubject<number>;
    isActive: boolean = false;
    state: number = ENetworkMonitorState.connected;

    subscription = {
        networkDisconnected: null,
        networkConnected: null
    };

    constructor(
        public network: Network
    ) {
        console.log("network monitor service created");
        this.observable = new BehaviorSubject(null);
    }

    startMonitor() {
        this.observable.next(null);

        if (!this.subscription.networkDisconnected) {
            // watch network for a disconnection
            this.subscription.networkDisconnected = this.network.onDisconnect().subscribe(() => {
                this.state = ENetworkMonitorState.disconnected;
                this.observable.next(this.state);
            }, (err: Error) => {
                console.error(err);
                // fallback
                this.state = ENetworkMonitorState.error;
            });

            this.subscription.networkConnected = this.network.onConnect().subscribe(() => {
                this.state = ENetworkMonitorState.connected;
                this.observable.next(this.state);
            }, (err: Error) => {
                console.error(err);
                // fallback
                this.state = ENetworkMonitorState.error;
            });
            this.isActive = true;
        }
    }

    simulateNetworkState(state: number) {
        if (state == null) {
            state = (this.state !== ENetworkMonitorState.disconnected) ? ENetworkMonitorState.disconnected : ENetworkMonitorState.connected;
        }
        console.log("simulate network state: ", state);
        this.state = state;
        this.observable.next(this.state);
    }

    watchState() {
        return this.observable;
    }

    /**
     * http request managers should check this flag
     * only if network monitor was enabled, wait for network connected state before sending http request
     * basically keep the entire flow waiting for http request to resolve
     */
    checkEnabled() {
        return this.isActive;
    }

    waitConnected() {
        return WaitUtils.waitFlagResolve(this.state, this.observable, [ENetworkMonitorState.connected, ENetworkMonitorState.error], null);
    }

    checkConnected() {
        return this.state === ENetworkMonitorState.connected;
    }

    stopMonitor() {
        this.subscription = ResourceManager.clearSubObj(this.subscription);
        this.observable.next(null);
        this.isActive = false;
    }

}
