import { IActivity } from "../core/activity";
import { IStory } from "../core/story";
import { IBackendLocation } from "../places/backend-location";
import { IUserPublicData } from "../user/general";
import { ILeplaceTreasure } from '../places/leplace';
import { IGameItem } from '../items/game-item';
import { IGroup, IEventGroupMember } from '../mp/groups';
import { IStatDef } from '../user/stats';


export interface IEventDetailsResponse {
    event: IEventDetails;
    registered: boolean;
    registeredGroupIds: number[];
}

export interface IEventCheckStartAvailableResponse {
    available: boolean;
    code: number;
    message: string;
}

export enum EEventCheckStartAvailableCode {
    available = 1,
    notAvailable = 2,
    roleRequired = 3
}

export interface IEventDetails {
    id: number;
    type: number;
    mapStyleCode: number;
    name: string;
    description: string;
    lat: number;
    lng: number;
    radiusKm: number;
    photoUrl: string;
    photoUrlSmall: string;
    regionType: string;
    timestamp: string;

    startDate: string;
    endDate: string;


    endTs?: number;

    devOnly: number;
    enabled: number;
    appVersionMin: number;
    // check qr registration requirement
    qrRegistration: number;
    // simple registration (no groups involved)
    registrationCode: string;
    // check available public leaderboard
    publicLeaderboard: number;

    eventType: IEventType;

    // events with private world map
    eventTreasures: IEventTreasure[];
    // events with challenges (basic)
    eventChallenges: IEventChallenge[];
    // events with stories (basic, not implemented)
    eventStories: IStory[];
    // locations associated to the event (e.g. starting points)
    eventLocations: IBackendLocation[];
    // public leaderboards
    eventLeaderboards: IEventLeaderboard[];
    // associated private items (e.g. sponsor rewards)
    eventItems: IEventItem[];
    /**
     * check if the user is (already) registered to the event
     */
    registered: boolean;

    apiLevel: number;

    // check if the user is allowed to perform maintenance operations e.g. change teams
    adminControl: number;

    timeoutDescription: string;
}

export interface IEventGroupStatsResponse {
    groupId: number;
    eventGroupId: number;
    position: number;
    score: number;
    stats: IStatDef[];
    members: IEventGroupMember[];
    group: IGroup
}

export interface IEventGroupDetailsResponse {
    id: number;
    userId: number;
    groupId: number;
    groupRoleCode: number;
    type: number;
}

export interface IEventTreasure {
    id: number;
    eventId: number;
    treasureId: number;
    treasure: ILeplaceTreasure;
}

export interface IEventItem {
    id: number;
    eventId: number;
    itemCode: number;
    item: IGameItem;
}

export interface IEventType {
    id: number;
    code: number;
    name: string;
    description: string;
    // check default qr registration requirement
    qrRegistration: number;
}

export interface IEventGroupList {
    name: string;
    timeCategory: string;
    events: IEventDetails[];
}

export interface IEventGroup {
    id: number;
    eventId: number;
    groupId: number;
    registrationCode: string;
    rank: number;
    timestamp: string;
    // check if the user is registered within the group
    registered: boolean;
    // get the current user role within the group (if registered)
    groupRole: number;

    group: IGroup;
}

export interface IEventLeaderboardAux {
    computed: {
        done: number,
        failed: number
    };
    saved: {
        done: number,
        failed: number
    };
    fromCache: boolean;
    timestamp: string;
}


export interface IEventChallenge {
    id: number;
    eventId: number;
    activityCode: number;
    level: number;
    activity: IActivity;
}

export interface IEventLeaderboard {
    id: number;
    eventId: number;
    leaderboardId: number;
    timestamp: string;
    leaderboard: ILeaderboard;
}

export interface ILeaderboard {
    id: number;
    name: string;
    description: string;
    timestamp: string;
    players: IUserPublicData[];
}

export enum EEventType {
    freestyle = 0,
    story = 1,
    challenge = 2,
    custom = 3
}
