import { Injectable } from "@angular/core";
import { UiExtensionService } from "../../general/ui/ui-extension";
import { InventoryDataService } from "../../data/inventory";
import { EItemCodes, EItemActions, EItemCategoryCodes } from "../../../classes/def/items/game-item";
import { IGenericResponse } from "../../../classes/def/requests/general";
import { IInventoryNavParams, IInventoryReturnItem } from "../../../classes/def/nav-params/inventory";
import { INavParams } from "../../../classes/def/nav-params/general";
import { Messages } from "../../../classes/def/app/messages";
import { EAlertButtonCodes } from "../../../classes/def/app/ui";
import { BehaviorSubject } from "rxjs";
import { GeneralCache } from "../../../classes/app/general-cache";
import { AppConstants } from "../../../classes/app/constants";
import { InventoryHomePage } from 'src/app/pages/inventory/inventory-home/inventory-home.page';


@Injectable({
    providedIn: 'root'
})
export class InventoryWizardService {

    scanEnergyObs: BehaviorSubject<number>;
    constructor(
        public uiext: UiExtensionService,
        public inventory: InventoryDataService
    ) {
        console.log("inventory wizard service created");
        this.scanEnergyObs = new BehaviorSubject(null);
    }

    getScanEnergyWatch() {
        return this.scanEnergyObs;
    }

    /**
    * consume scan energy
    * handle scan energy depleted
    * @param onComplete on scan energy consumed
    * @param onRecharge on request recharge
    */
    consumeScanEnergyGenericWizard(onComplete: () => any, onRecharge: () => any, onReject: () => any, amount: number = null) {
        if (!amount) {
            amount = AppConstants.gameConfig.defaultScanEnergy;
        }
        this.inventory.removeInventoryItem(EItemCodes.scanEnergy, amount).then(() => {
            onComplete();
        }).catch((err: Error) => {
            console.error(err);
            this.uiext.showAlert(Messages.msg.scanEnergyDepleted.after.msg, Messages.msg.scanEnergyDepleted.after.sub, 2, ["Dismiss", "Recharge"]).then((res: number) => {
                GeneralCache.inventorySelectedCategory = EItemCategoryCodes.powers;
                if (res === EAlertButtonCodes.ok) {
                    onRecharge();
                } else {
                    if (onReject) {
                        onReject();
                    }
                }
            }).catch((err: Error) => {
                console.error(err);
                if (onReject) {
                    onReject();
                }
            });
        });
    }

    /**
     * consume scan energy
     * returns false if not avaialble
     * resolve only
     */
    consumeScanEnergyCore(amount: number) {
        let promise = new Promise((resolve) => {
            this.inventory.removeInventoryItem(EItemCodes.scanEnergy, amount).then((_response: IGenericResponse) => {
                this.scanEnergyObs.next(amount);
                this.scanEnergyObs.next(null);
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
        return promise;
    }


    /**
     * consume scan energy
     * recharge if depleted and try again while the user taps recharge
     * fails if the user taps dismiss
     */
    consumeScanEnergyRechargeDefaultWizard(amount: number) {
        let promise = new Promise((resolve) => {
            this.consumeScanEnergyCore(amount).then((status: boolean) => {
                if (status) {
                    resolve(status);
                } else {
                    this.tryRechargeCore().then((res) => {
                        console.log("try recharge core: ", res);
                        resolve(res);
                    }).catch((err: Error) => {
                        console.error(err);
                        resolve(false);
                    });
                }
            });
        });
        return promise;
    }

    /**
     * resolve only
     */
    tryRechargeWizard() {
        let promise = new Promise((resolve) => {
            this.tryRechargeCore().then((res) => {
                resolve(res);
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
        return promise;
    }

    /**
     * recursive method
     * try recharge until succeeded (maybe the user does not get how to recharge and he tries again to find the scan energy item)
     * or the user explicitly refused the recharge
     */
    tryRechargeCore() {
        let promise = new Promise((resolve, reject) => {
            let fn = () => {
                this.uiext.showAlert(Messages.msg.scanEnergyDepleted.after.msg, Messages.msg.scanEnergyDepleted.after.sub, 2, ["Dismiss", "Recharge"]).then((res: number) => {
                    if (res === EAlertButtonCodes.ok) {
                        this.goToInventoryForScanEnergy().then((res: boolean) => {
                            // true => scan energy purchased
                            // false => scan energy not purchased
                            if (res) {
                                setTimeout(() => {
                                    resolve(res);
                                }, 500);
                            } else {
                                fn();
                            }
                        }).catch((err: Error) => {
                            reject(err);
                        });
                    } else {
                        // the user does not want to recharge
                        // reject(new Error("no purchase"));
                        resolve(false);
                    }
                }).catch((err: Error) => {
                    reject(err);
                });
            };
            fn();
        });
        return promise;
    }

    /**
     * go to inventory
     * returns when the user leaves the inventory
     * returns true (scan energy purchased), false (scan energy not purchased)
     */
    goToInventoryForScanEnergy(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let params: IInventoryNavParams = {
                closeOnItemUse: true,
                fixedCategory: true,
                wantedItemGenericCode: EItemCodes.scanEnergy
            };

            let navParams: INavParams = {
                view: {
                    fullScreen: true,
                    transparent: false,
                    large: true,
                    addToStack: false,
                    frame: false
                },
                params: params
            };

            GeneralCache.inventorySelectedCategory = EItemCategoryCodes.powers;
            this.uiext.showCustomModal(null, InventoryHomePage, navParams).then((rets: IInventoryReturnItem[]) => {
                console.log("returned from modal to map: ", rets);
                let scanEnergyPurchased: boolean = false;
                if (rets) {
                    for (let i = 0; i < rets.length; i++) {
                        let ret: IInventoryReturnItem = rets[i];
                        switch (ret.action) {
                            case EItemActions.buy:
                                // an item was activated (check generic code)
                                let genCode: number = ret.item.code;
                                if (ret.item.delegateCode != null) {
                                    genCode = ret.item.delegateCode;
                                }
                                if (genCode === EItemCodes.scanEnergy) {
                                    scanEnergyPurchased = true;
                                }
                                break;
                        }
                        if (scanEnergyPurchased) {
                            break;
                        }
                    }
                    if (scanEnergyPurchased) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            }).catch(() => {
                resolve(false);
            });
        });
        return promise;
    }
}
