import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ILeplaceReg } from 'src/app/classes/def/places/google';
import { AppConstants } from 'src/app/classes/app/constants';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import {
  ILeplaceTreasure, ITreasureLocationExtended, IPlaceDataInternalStandard, ITreasureUpdateContent,
  IPlaceDataInternalStandardContainer, TreasureUtils, ETreasureLockedMode
} from 'src/app/classes/def/places/leplace';
import { IShareMsgParams, IShareMsg, MessageUtils, EShareMsgFeatures } from 'src/app/classes/utils/message-utils';
import { IBusinessFeaturesEnabled } from 'src/app/classes/def/business/business';
import { ModalController, Platform } from '@ionic/angular';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { IItemFound } from 'src/app/classes/def/nav-params/activity';
import { EViewLinkCodes } from 'src/app/classes/def/ar/core';
import { ETreasureType } from 'src/app/classes/def/items/treasures';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { ILocationContainer } from 'src/app/classes/def/places/backend-location';
import { LocationUtilsHelpers } from 'src/app/services/location/location-utils-helpers';
import { ITreasureFoundReturnData } from 'src/app/classes/def/nav-params/modal-finished-return';
import { EFinishedActionParams, EStandardCode, EStandardTreasureCode } from 'src/app/classes/def/core/activity';
import { IGmapDetailReturnParams, EGmapDetailReturnCode, IGmapActivityStartOptions } from 'src/app/classes/def/nav-params/gmap';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { Util } from 'src/app/classes/general/util';
import { IGetPhotoOptions } from 'src/app/services/location/location-utils-def';
import { IPlacePhotoContainer } from 'src/app/classes/def/places/container';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { ITextInputParams, ITextInputResult, TextInputViewComponent } from 'src/app/modals/generic/modals/text-input/text-input.component';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { UserContentDataService } from 'src/app/services/data/user-content';
import { PlacesDataService } from 'src/app/services/data/places';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { BusinessFeaturesService } from 'src/app/services/app/utils/business';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { IGameItem } from 'src/app/classes/def/items/game-item';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { SoundManagerService } from 'src/app/services/general/apis/sound-manager';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { LocationUtilsWizard } from 'src/app/services/location/location-utils-wizard';
import { AdHandlerService, IAdHandlerContext } from 'src/app/services/general/apis/ads-handler';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-treasure-home',
  templateUrl: './treasure-home.page.html',
  styleUrls: ['./treasure-home.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TreasureHomePage implements OnInit, OnDestroy {
  title: string = "Item Found";

  collectedItems: number = 0;
  collectedLP: number = 0;
  place: ILeplaceReg = null;
  // location: IBack
  theme: string = "theme-aubergine theme-aubergine-bg";
  shareEnabled: boolean = true;

  photoUrl: string = null;
  photoUrlLoaded: string = null;

  closeTimeout;
  closeTimerValue: number = 0;
  platform: IPlatformFlags = {} as IPlatformFlags;

  opened: boolean = true;
  item: ILeplaceTreasure;

  fullScreen: boolean = false;
  itemValue: number = 1;
  xp: number = 0;
  enabled: IBusinessFeaturesEnabled;
  locationExt: ITreasureLocationExtended;

  enableBusinessFeatures: boolean = false;
  placeSpecsAvailable: boolean = false;

  placeData: IPlaceDataInternalStandard = {
    registeredId: null,
    googleId: null,
    name: null,
    registered: false,
    registeredBusiness: false
  };

  customTreasure: boolean = false;
  editor: boolean = false;
  update: boolean = false;

  updateContentData: ITreasureUpdateContent = {
    id: null,
    lat: null,
    lng: null,
    description: null
  };

  subscription = {
    extLink: null,
    platformLoaded: null
  };

  updateSignal: boolean = false;

  previewEnabled: boolean = false;
  previewCallback: () => any = null;
  withDismissOptions: boolean = false;

  appIcons = EAppIcons;

  np: INavParams = null;
  vs: IViewSpecs;

  gameItems: IGameItem[] = [];
  gameItemsContainers: any[] = [];
  gameItemsRow: number = 4;

  adContext: IAdHandlerContext = null;
  shareEnabledGlobal = AppConstants.enableSocialSharing;

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public businessFeaturesProvider: BusinessFeaturesService,
    public ucp: UserContentDataService,
    public placeProvider: PlacesDataService,
    public resourcesProvider: ResourcesCoreDataService,
    public tutorials: TutorialsService,
    public nps: NavParamsService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public backButton: BackButtonService,
    public soundManager: SoundManagerService,
    public analytics: AnalyticsService,
    public locationUtilsWizard: LocationUtilsWizard,
    public adHandler: AdHandlerService
  ) {
    this.adHandler.initContext();
    this.adContext = this.adHandler.getContext();
    this.adContext.shareContext = EShareMsgFeatures.itemFound;
  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }
  
  ngOnInit() {

    this.analytics.trackView("treasure-home");

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.treasureHome, null, this.np);

      console.log("nav params: ", npInfo.params);

      let hasParams: boolean = npInfo.hasParams;
      if (hasParams) {
        let np: INavParams = npInfo.params;
        let params: IItemFound = np.params;
        console.log(params);

        this.vs = np.view;

        this.adContext.infoHTML = params.infoHTML;
        this.adContext.shareMessage = params.shareMessage;
        this.collectedItems = params.amount;
        this.place = params.place;
        this.opened = params.opened;
        this.title = params.title;
        this.item = params.item;
        this.withDismissOptions = params.withDismissOptions;

        console.log("origin viewId: ", this.item.viewId);

        this.formatGameItems();

        if (params.inputObservable) {
          this.subscription.extLink = params.inputObservable.subscribe((code: number) => {
            if (code === EViewLinkCodes.exit) {
              this.cancel();
            }
          }, (err: Error) => {
            console.error(err);
          });
        }

        if (params.previewCallback != null) {
          this.previewCallback = params.previewCallback;
          this.previewEnabled = true;
        }

        if (this.collectedItems == null) {
          this.collectedItems = 0;
        }
        // the treasures only contain coins (actually LP) at the moment, so the coin value is fixed (particular case!)
        // this.collectedLP = this.collectedItems * AppConstants.appStandardConfig.coinValue;
        this.collectedLP = this.collectedItems;
        this.xp = params.xp;

        let res: IPlaceDataInternalStandardContainer = TreasureUtils.getPlace(this.item);

        if (res != null) {
          this.placeSpecsAvailable = res.availableSpecs;

          if (res.availablePlace) {
            this.placeSpecsAvailable = true;
          } else if (res.availableLocationExt) {
            this.locationExt = this.item.location;
          }

          this.formatPlace();

          this.editor = this.item.owned;

          if (!this.editor) {
            this.updateSignal = true;
          }

          this.initContent();
        }


        if (!this.opened) {
          this.shareEnabled = false;
          this.adContext.watchEnabled = false;
          this.closeTimerValue = 0;
        } else {
          let params1: IShareMsgParams = {
            place: {
              name: this.placeData.name,
            },
            xp: this.xp,
            items: {
              collected: this.collectedItems,
              collectedValue: null,
              reward: 0,
              value: this.itemValue
            },
            treasureType: this.item ? this.item.type : ETreasureType.treasure,
            actionButtons: {
              watchAd: false,
              scanQR: false,
              share: false
            }
          };

          let shareMsg: IShareMsg = MessageUtils.buildMessage(EShareMsgFeatures.itemFound, params1);
          if (shareMsg.share) {
            this.shareEnabled = true;
          } else {
            this.shareEnabled = false;
          }
          this.adContext.infoHTML = shareMsg.auxHTML;
          this.adHandler.updateContext(params1, shareMsg.share, shareMsg.auxHTML, this.collectedLP, this.collectedLP);
          console.log("adContext updated: ", this.adContext);
          console.log("collected LP: ", this.collectedLP);
        }

        this.checkBusinessFeatures();
        this.checkCustomTreasure();

        this.loadAudioGuide();

      }

      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.cancel();
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });

      this.subscription.platformLoaded = this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
        if (loaded) {
          this.onPlatformLoaded();
        }
      }, (err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  private loadAudioGuide() {
    switch (this.item.type) {
      case ETreasureType.treasure:
        this.soundManager.ttsCodeWrapper(this.opened ? ETutorialEntries.ttsTreasureUnlocked : ETutorialEntries.ttsTreasureFound, true);
        break;
      case ETreasureType.challenge:
        this.soundManager.ttsCodeWrapper(ETutorialEntries.ttsChallenge, true);
        break;
      case ETreasureType.story:
        this.soundManager.ttsCodeWrapper(ETutorialEntries.ttsStory, true);
        break;
    }
  }

  private formatGameItems() {
    let hasItems: boolean = true;
    if (!(this.item && this.item.items && this.item.items.length > 0)) {
      hasItems = false;
    }

    if (hasItems) {
      this.gameItems = this.item.items;
      for (let i = 0; i < this.gameItems.length; i++) {
        // show as unlocked (override)
        this.gameItems[i].newUnlockedAux = 1;
        this.gameItems[i].noBlink = true;
        // this.gameItems[i].activeCount = 1;
        this.gameItems[i].validCount = 1;

        this.gameItemsContainers.push({
          data: this.gameItems[i],
          small: true,
          interact: true,
          basicView: true
        });
      }
    } else {
      this.gameItems = [];
    }

    let outliers: number = this.gameItemsRow - (this.gameItems.length - Math.floor(this.gameItems.length / this.gameItemsRow));
    for (let i = 0; i < outliers; i++) {
      this.gameItemsContainers.push({
        data: null,
        small: true,
        interact: true,
        basicView: true
      });
    }
  }


  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      tutorial: {
        name: "Tutorial",
        code: 3,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((code: number) => {
      switch (code) {
        case 3:
          this.tutorials.showTutorialNoAction(this.title + " Tutorial", 200 + this.item.type, null, null, true);
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  openBusinessFeatures() {
    let blocation: ILocationContainer = LocationUtilsHelpers.createNewLocationContainer();
    if (this.placeData) {
      // view sales
      blocation.merged.googleId = this.placeData.googleId;
      blocation.merged.name = this.placeData.name;
    }

    if (this.placeData && this.placeData.registeredId != null) {
      // location id is used to scan/register client
      blocation.merged.locationId = this.placeData.registeredId;
    }

    this.businessFeaturesProvider.openAsModal(blocation, this.enabled).then(() => {
      this.enabled = this.businessFeaturesProvider.getCurrentBusinessFeatures();
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  checkCustomTreasure() {
    if (this.item) {
      if (this.item.locked === ETreasureLockedMode.fixedScenePublic) {
        this.customTreasure = true;
      }
    }
  }

  /**
   * check if business is registered
   * and enable qr code and sales buttons
   */
  checkBusinessFeatures() {
    if (this.placeData) {
      this.enabled = this.businessFeaturesProvider.loadBusinessFeaturesCore(this.placeData.registeredBusiness, false, null);
      this.enableBusinessFeatures = this.enabled.qr || this.enabled.sales;
      if (!this.opened) {
        this.enableBusinessFeatures = false;
      }
    }
  }

  getBusinessFeatures() {
    this.enabled = this.businessFeaturesProvider.getCurrentBusinessFeatures();
  }


  onPlatformLoaded() {
    this.platform = SettingsManagerService.settings.platformFlags;
  }

  /**
   * close the popup after 10 seconds of inactivity
   */
  closeTimerLoop() {
    this.closeTimeout = setTimeout(() => {
      if (this.closeTimerValue > 0) {
        this.closeTimerValue -= 1;
        this.closeTimerLoop();
      } else {
        // this.dismiss(null);
        this.continue();
      }
    }, 1000);
  }

  stopCloseTimeout() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
      this.closeTimeout = null;
      this.closeTimerValue = 0;
    }
  }

  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }


  /**
   * reject challenge
   * relevant for MP game as member
   */
  cancel() {
    console.log("cancel/reject challenge");
    this.stopCloseTimeout();
    let returnData: ITreasureFoundReturnData = {
      status: EFinishedActionParams.cancel,
      finalLP: this.collectedItems
    };
    this.dismiss(returnData);
  }

  /**
   * return to map without starting the challenge (if the treasure is a challenge)
   */
  return() {
    console.log("return to map");
    this.stopCloseTimeout();
    let returnData: ITreasureFoundReturnData = {
      status: EFinishedActionParams.cancel,
      finalLP: this.collectedItems
    };
    this.dismiss(returnData);
  }


  /**
   * return to map
   * begin the challenge (if the treasure is a challenge)
   * preview the challenge first, allow proceed/dismiss
   */
  continue() {
    this.stopCloseTimeout();

    let promisePreview: Promise<boolean>;
    let startOptionsSelected: IGmapActivityStartOptions = null;
    if (this.previewEnabled) {
      promisePreview = new Promise((resolve) => {
        this.previewCallback().then((res: IGmapDetailReturnParams) => {
          console.log("preview complete: ", res);
          if (res != null) {
            startOptionsSelected = res.startOptionsSelected;
            if (res.code === EGmapDetailReturnCode.proceed) {
              resolve(true);
            } else {
              resolve(false);
            }
          } else {
            resolve(false);
          }
        }).catch((err: Error) => {
          console.error(err);
          resolve(false);
        });
      });
    } else {
      promisePreview = Promise.resolve(true);
    }

    promisePreview.then((proceed: boolean) => {
      if (proceed) {
        let returnData: ITreasureFoundReturnData = {
          status: null,
          finalLP: this.collectedLP + this.adContext.rewardLP,
          startOptionsSelected: startOptionsSelected
        };

        let promiseUpdate: Promise<boolean>;
        if (this.update) {
          promiseUpdate = this.updateContent();
        } else {
          promiseUpdate = Promise.resolve(true);
        }

        promiseUpdate.then(() => {
          if (this.adContext.rewardIssued) {
            returnData.status = EFinishedActionParams.reward;
            this.dismiss(returnData);
          } else {
            returnData.status = EFinishedActionParams.default;
            this.dismiss(returnData);
          }
        }).catch((err: Error) => {
          console.error(err);
        });
      }
    });
  }

  /**
   * Share progress then dismiss
   */
  shareAndContinue() {
    this.stopCloseTimeout();
    this.adContext.loading = true;
    this.shareProvider.share(this.adContext.shareMessage).then(() => {
      this.adContext.loading = false;
      // this.uiext.showAlert(Messages.msg.shareNotification.after.msg, Messages.msg.shareNotification.after.sub, 1, null).then(() => {
      //     // this.dismiss(EActivityFinishedParams.default);
      // }).catch(() => {
      //     // this.dismiss(EActivityFinishedParams.default);
      // });
    }).catch(() => {
      // this.dismiss(EActivityFinishedParams.default);
      this.adContext.loading = false;
    });
  }

  /**
   * play reward video then dismiss
   */
  playAndContinue() {
    this.stopCloseTimeout();
    this.adHandler.playAndContinue(null);
  }

  ngOnDestroy() {
    console.log("close modal");
    this.stopCloseTimeout();
    this.subscription = ResourceManager.clearSubObj(this.subscription);
    this.backButton.checkPop(this.vs);
  }

  clearWatch() {

  }

  /**
   * format place display
   */
  formatPlace() {

    if (!this.getChallengePhoto()) {
      this.getStandardPhoto();
    }

    if (this.place) {
      let mapsUrl: string = Util.generateMapsLink(this.place.place.googleId, this.place.place.name);
      this.place.aux = {
        mapsURL: mapsUrl,
        photoUrl: null,
        photoUrlCached: null,
        canvasLoaded: false,
        canvasURL: null
      };

      let options: IGetPhotoOptions = {
        noPlaceholder: true,
        redirect: true,
        cacheDisk: true,
        useGeneric: SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value
      };

      console.log("format place: ", this.place.place);

      // console.log(options);
      if (options.useGeneric) {
        this.placeProvider.getPlaceTemplatePhoto(this.place.place.type).then((p: IPlacePhotoContainer) => {
          // console.log(p);
          this.place.aux.photoUrl = p.photoUrl;
        });
      } else {
        this.locationUtilsWizard.loadPlacePhotoWizard1(this.place, options).then((photoUrl: string) => {
          this.place.aux.photoUrl = photoUrl;
        })
      }
    } else {
      if (this.locationExt && this.locationExt.location) {
        // if (!this.photoUrl) {
        //     this.photoUrl = this.locationExt.location.merged.photoUrl;
        // }
      }
    }
  }

  getChallengePhoto() {
    if (this.item.activity && this.item.activity.photoUrl) {
      this.photoUrl = this.item.activity.photoUrl;
      if (this.photoUrl) {
        return true;
      }
    }
    return false;
  }

  getStandardPhoto() {
    console.log("load standard photo");
    if (this.item && this.item.photoMarker && this.item.photoUrl) {
      this.photoUrl = this.item.photoUrl;
    } else {
      this.resourcesProvider.getStandardPhotoByCode(EStandardCode.treasure + EStandardTreasureCode.foundTreasure).then((photoUrl: string) => {
        this.photoUrl = photoUrl;
        console.log("standard photo loaded: " + photoUrl);
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  afterRemoveFixedTreasure() {
    let returnData: ITreasureFoundReturnData = {
      status: null,
      finalLP: 0
    };
    returnData.status = EFinishedActionParams.removeTreasure;
    this.dismiss(returnData);
  }

  removeFixedTreasure() {
    if (!this.item) {
      return;
    }

    if (this.item.isLocal) {
      this.afterRemoveFixedTreasure();
      return;
    }

    this.ucp.removeFixedTreasure(this.item.id, this.editor).then(() => {
      this.afterRemoveFixedTreasure();
    }).catch((err: Error) => {
      console.error(err);
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, "Not permitted"));
    });
  }


  initContent() {
    if (this.item.treasureContent) {
      this.updateContentData.description = this.item.treasureContent.description;
    }

    if (this.item.placeMarker) {
      // use the actual marker position (e.g. drag markers)
      this.updateContentData.lat = this.item.placeMarker.location.lat;
      this.updateContentData.lng = this.item.placeMarker.location.lng;
    }
  }

  syncContent() {
    if (this.item.treasureContent) {
      this.item.treasureContent.description = this.updateContentData.description;
    }
  }

  editContent() {
    let params: ITextInputParams = {
      description: "",
      text: this.updateContentData.description,
      title: "Edit content",
      focused: false,
      allowEmpty: true
    };
    this.uiext.showCustomModal(null, TextInputViewComponent, {
      view: {
        fullScreen: false,
        transparent: false,
        large: false,
        addToStack: true,
        frame: false
      },
      params: params
    }).then((description: ITextInputResult) => {
      if (description) {
        this.updateContentData.description = description.text;
        this.update = true;
        this.updateSignal = true;
        this.item.dynamic.updated = true;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * update treasure content
   */
  updateContent(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      if (!this.item) {
        reject(new Error("undefined treasure item"));
        return;
      }

      this.ucp.updateFixedTreasure(this.item.id, this.updateContentData, this.editor).then(() => {
        this.syncContent();
        this.update = false;
        this.uiext.showAlert(Messages.msg.success.after.msg, "Treasure content updated", 1, null, true).then(() => {
          resolve(true);
        }).catch(() => {
          resolve(false);
        });
      }).catch((err: Error) => {
        console.error(err);
        this.update = false;
        this.uiext.showAlert(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, "Not permitted"), 1, null, true).then(() => {
          reject(err);
        }).catch(() => {
          reject(err);
        });
      });
    });
    return promise;
  }
}
