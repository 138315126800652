import { ILocationContainer } from "./backend-location";
import { IPlaceMarkerContent } from '../map/map-data';
import { ILatLng } from "../map/coords";

export interface IAppLocation {
  /** story location index */
  index?: number;
  loc: ILocationContainer;
  prevLoc?: ILocationContainer;
  flag: number;
  dispDone: boolean;
  /** enable blinking */
  selected: boolean;
  inProgress?: boolean;
  location?: ILatLng;
  photoValidated?: boolean;
  /** preload story location reached */
  unlocked?: boolean;
  /** preload story location reached check index*/
  storyLocationId?: number;
  placeMarker?: IPlaceMarkerContent;
  /** aux flag, locked after complete, for preload story locations */
  lockedForSession?: boolean;
  /** check locked after complete */
  lockedForSessionComplete?: boolean;
  /** aux flag, check access restricted, for preload story locations */
  lockedForSessionNoAccess?: boolean;
  /** aux message, show why locked */
  lockedMessage?: string;
  /** aux flag, check if can unlock, based on proximity check */
  inRange?: boolean;
  /** keep the same offset center for find circle */
  keepOffsetCenter?: boolean;
  /** check navigation towards current app location */
  isNavigating?: boolean;

  requiresReload?: boolean;
  virtualCircle?: IPlaceMarkerContent;

  navSpec?: ILocationNavSpec;
}

export interface ILocationNavSpec {
  from: ILatLng,
  to: ILatLng
}

export enum ELocationFlag {
  RANDOM = 0,
  FIXED = 1,
  SAVED = 2,
  SAVED_LOCAL = 3
}

export enum EUnchartedMode {
  /** use google location data */
  disabled = 0,
  /** custom coords, keep link */
  detached = 1,
  /** custom coords, no link - override hiddenPlace, hiddenLocationName */
  uncharted = 2
}
