import { Component, OnInit, Input, Output, OnDestroy } from '@angular/core';
import { AppConstants } from 'src/app/classes/app/constants';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { ILocationItemsDef, IActivityRewardItem, ILocationContainer } from 'src/app/classes/def/places/backend-location';
import { IActivityParamsView, EActivityParamsViewModes, IDynamicParamActivityParam } from 'src/app/classes/def/nav-params/activity-details';
import { EventEmitter } from '@angular/core';
import { ICustomParamForActivity, ECustomParamScope2 } from 'src/app/classes/def/core/custom-param';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AdsService } from 'src/app/services/general/apis/ads';
import { InventoryDataService } from 'src/app/services/data/inventory';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { BusinessFeaturesService } from 'src/app/services/app/utils/business';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { MessageUtils } from 'src/app/classes/utils/message-utils';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { IInventoryNavParams } from 'src/app/classes/def/nav-params/inventory';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EItemCategoryCodes } from 'src/app/classes/def/items/game-item';
import { ResourceManager } from 'src/app/classes/general/resource-manager';

import {
    trigger,
    state,
    style,
    animate,
    transition
} from '@angular/animations';
import { InventoryHomePage } from 'src/app/pages/inventory/inventory-home/inventory-home.page';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { NavParamsService } from 'src/app/services/app/nav-params';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { ActivityParamDetailsViewComponent } from 'src/app/modals/app/modals/activity-param-details-vc/activity-param-details-vc.component';

@Component({
    selector: 'activity-details-entry',
    templateUrl: './activity-details-entry.component.html',
    styleUrls: ['./activity-details-entry.component.scss'],
    animations: [
        trigger('showState', [
            state('inactive', style({
                opacity: 0,
            })),
            state('active', style({
                opacity: 1,
            })),
            transition('inactive => active', animate(AppConstants.animationMode)),
            transition('active => inactive', animate(AppConstants.animationMode))
        ])
    ]
})
export class ActivityDetailsEntryComponent implements OnInit, OnDestroy {
    theme: string = "theme-aubergine theme-aubergine-bg";
    descriptionDefault: string = "<p>Special items required for this activity</p>";
    description: string = "";
    descriptionItems: string = "Unlocks the following items";
    descriptionItemsNav: string = "Unlocks the following items";
    shortDescription: string = "";
    shareMessage: string = "";
    collectedCoins: number = 0;
    shareEnabled: boolean = true;
    watchEnabled: boolean = true;
    loading: boolean = false;
    platform: IPlatformFlags = {} as IPlatformFlags;
    availableItems: ILocationItemsDef = null;
    unlockedItems: IActivityRewardItem[] = [];
    unlockedItemsFlag: boolean = false;
    unlockedItemsText: string = "";
    blocation: ILocationContainer = null;
    platformObs;
    enabled = {
        sales: false,
        qr: false
    };

    appIcons = EAppIcons;

    @Input()
    data: IActivityParamsView;

    @Input()
    gridOnly: boolean;

    @Input()
    isNavPreview: boolean;

    @Input()
    isPreview: boolean;

    @Input()
    min: boolean;

    @Output()
    action: EventEmitter<any> = new EventEmitter();

    activityParams: ICustomParamForActivity[];
    activityParamsNav: ICustomParamForActivity[];
    hasParamGrid: boolean = false;
    hasParamNavGrid: boolean = false;

    paramGrid: IDynamicParamActivityParam[] = [];
    paramNavGrid: IDynamicParamActivityParam[] = [];

    vs: IViewSpecs = ViewSpecs.getDefault();

    showState: string = "inactive";

    timeouts = {
        refresh: null
    };

    paramDetailsView: boolean = false;
    customParamSelected: ICustomParamForActivity = null;
    descriptionView: string = "";
    descriptionExpanded: boolean = false;
    canExpand: boolean = false;

    constructor(
        public settingsProvider: SettingsManagerService,
        public nps: NavParamsService,
        public shareProvider: ShareService,
        public uiext: UiExtensionService,
        public uiextStandard: UiExtensionStandardService,
        public adService: AdsService,
        public inventory: InventoryDataService,
        public analytics: AnalyticsService,
        public businessFeaturesProvider: BusinessFeaturesService,
        public resources: ResourcesCoreDataService
    ) {

    }

    ngOnInit() {
        if (!this.data) {
            return;
        }
        this.data = Object.assign({}, MessageUtils.getDefaultShareMsgParams(), this.data);
        this.activityParams = this.data.customParams;
        this.activityParamsNav = this.data.customParamsNav;
        this.formatItemGridWrapper();

        if (this.data.descriptionCode) {
            this.loadDescription(this.data.descriptionCode);
        } else {
            if (this.data.description) {
                this.description = this.data.description;
                this.shortDescription = this.data.description;
            } else {
                this.description = this.descriptionDefault;
                this.shortDescription = this.descriptionDefault;
            }

            if (this.data.shortDescription) {
                this.shortDescription = this.data.shortDescription;
            }
        }

        this.descriptionItems = MessageUtils.getActivityItemsDescription(this.data.scope, ECustomParamScope2.treasures, false);
        this.descriptionItemsNav = MessageUtils.getActivityItemsDescription(this.data.scope, ECustomParamScope2.treasures, true);

        this.canExpand = this.expandDescription(false);
        this.showState = "active";
        this.settingsProvider.getSettingsLoaded(false).then((res) => {
            if (res) {
                this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
            }
        }).catch((err: Error) => {
            console.error(err);
        });

        this.platformObs = this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.onPlatformLoaded();
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    formatItemGridWrapper() {
        this.formatItemGrid(this.activityParams, this.paramGrid);
        if (this.activityParams.length > 0) {
            this.hasParamGrid = true;
        }
        this.formatItemGrid(this.activityParamsNav, this.paramNavGrid);
        if (this.activityParamsNav.length > 0) {
            this.hasParamNavGrid = true;
        }
    }

    /**
     * format item grid 
     * with activity params
     */
    formatItemGrid(activityParams: ICustomParamForActivity[], paramGrid: IDynamicParamActivityParam[]) {
        if (activityParams) {
            for (let i = 0; i < activityParams.length; i++) {
                let cpar: ICustomParamForActivity = activityParams[i];
                // console.log(cpar);
                if (cpar != null) {
                    if (cpar.treasureSpec != null) {
                        // override description (and title) with the treasure spec description (and title)
                        // cpar.name = cpar.treasureSpec.dispName;
                        cpar.description = cpar.treasureSpec.description;
                    }
                    paramGrid.push({
                        data: cpar,
                        showLabel: false
                    });
                }
            }
        }
    }

    /**
    * load remote resource (description)
    * @param code 
    */
    loadDescription(code: number) {
        this.loading = true;
        this.resources.getInfoText(code).then((text: string) => {
            this.loading = false;
            this.description = text;
            this.expandDescription(true);
        }).catch(() => {
            this.loading = false;
            this.description = this.descriptionDefault;
            this.expandDescription(true);
        });
    }

    showOptions() {
        let actions: IPopoverActions = {};
        actions = {
            checkin: {
                name: "Check in",
                code: 2,
                icon: EAppIconsStandard.checkin,
                enabled: this.enabled.qr
            },
            viewSales: {
                name: "View sales",
                code: 3,
                icon: EAppIconsStandard.sales,
                enabled: this.enabled.sales
            },
            share: {
                name: "Share",
                code: 4,
                icon: EAppIconsStandard.share,
                enabled: this.data ? (this.data.mode === EActivityParamsViewModes.after) : false
            }
        };

        this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
            view: {
                fullScreen: false,
                transparent: AppConstants.transparentMenus,
                large: false,
                addToStack: true,
                frame: false
            },
            params: { actions: actions }
        }).then((result) => {
            switch (result) {
                case 2:
                    this.scanQR();
                    break;
                case 3:
                    this.viewSales();
                    break;
                case 4:
                    this.shareAndContinue();
                    break;
            }
        }).catch((err: Error) => {
            console.error(err);
        });
    }


    /**
     * check inventory for unlocked items
     */
    viewInventory() {
        let params: IInventoryNavParams = {
            closeOnItemUse: false,
            fixedCategory: false
        };
        let navParams: INavParams = {
            view: {
                fullScreen: true,
                transparent: false,
                large: true,
                addToStack: false,
                frame: false
            },
            params: params
        };
        GeneralCache.inventorySelectedCategory = EItemCategoryCodes.items;
        this.uiext.showCustomModal(null, InventoryHomePage, navParams).then(() => {
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    backToParams() {
        this.refreshShowState();
        this.paramDetailsView = false;
    }

    openParamDetailsView(ap: ICustomParamForActivity) {
        // console.log("open param details view: ", ap);       
        this.uiext.showCustomModal(null, ActivityParamDetailsViewComponent, {
            view: {
                fullScreen: false,
                transparent: AppConstants.transparentMenus,
                large: false,
                addToStack: true,
                frame: false
            },
            params: ap
        }).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    getTreasureIcon(cpar: ICustomParamForActivity) {
        if (cpar && cpar.treasureSpec) {
            return cpar.treasureSpec.photoUrl;
        }
        return null;
    }

    refreshShowState() {
        this.showState = "inactive";
        this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
        this.timeouts.refresh = setTimeout(() => {
            this.showState = "active";
        }, 100);
    }

    /**
    * view available sales for this place
    */
    viewSales() {
        this.businessFeaturesProvider.viewSalesBLocation(this.blocation).then(() => {
            this.enabled = this.businessFeaturesProvider.getCurrentBusinessFeatures();
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    scanQR() {
        this.businessFeaturesProvider.scanQrRegisterClientBLocation(this.blocation).then(() => {
            this.enabled = this.businessFeaturesProvider.getCurrentBusinessFeatures();
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    openBusinessFeatures() {
        this.businessFeaturesProvider.openAsModal(this.blocation, this.enabled).then(() => {
            this.enabled = this.businessFeaturesProvider.getCurrentBusinessFeatures();
        }).catch((err: Error) => {
            console.error(err);
        });
    }


    onPlatformLoaded() {
        this.platform = SettingsManagerService.settings.platformFlags;
    }

    dismiss(params: any) {
        this.action.emit(params);
    }

    continue() {
        this.dismiss(true);
    }

    /**
     * Share progress then dismiss
     */
    shareAndContinue() {
        this.loading = true;
        this.shareProvider.share(this.shareMessage).then(() => {
            this.loading = false;
        }).catch(() => {
            this.loading = false;
        });
    }

    ngOnDestroy() {
        console.log("close modal");
        this.platformObs = ResourceManager.clearSub(this.platformObs);
        this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
    }

    clearWatch() {

    }

    expandDescription(expand: boolean) {
        this.descriptionExpanded = !this.descriptionExpanded;
        let canExpand: boolean = false;

        if (expand != null) {
            this.descriptionExpanded = expand;
        }

        if (!this.descriptionExpanded) {
            this.descriptionView = this.shortDescription;
        } else {
            this.descriptionView = this.description;
        }

        canExpand = (this.description !== this.descriptionView);
        return canExpand;
    }
}
