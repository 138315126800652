<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div class="padding-s" fxLayout="column">
    <div class="scrollbar-y scroll-content-modal-1-border padding-s" fxLayout="column">
      <safe-html-renderer [unsafeHtmlContent]="infoHTML"
        innerClass="info-text-m description-frame text-color-primary theme-border-bottom-negative"></safe-html-renderer>
      <ion-item class="list-item transparent-bg settings-item item-noborder" *ngIf="unitSettings" #slidingItem>
        <icon-wrapper class="icon-wrapper slot-icon-padding"
          *ngIf="unitSettings.codeOptions && unitSettings.codeOptions.icon" slot="start"
          [icon]="unitSettings.codeOptions.icon" [custom]="unitSettings.codeOptions.customIcon">
        </icon-wrapper>
        <ion-label class="text-color-primary info-text-s-imp">{{unitSettings.name}}</ion-label>
        <ion-select class="custom-select info-text-s" ngDefaultControl [(ngModel)]="unitSettings.value"
          (ionChange)="updateUnitSettings(unitSettings)">
          <ion-select-option *ngFor="let o of unitSettings.optionsList; let i = index" [value]="o.value">{{o.name}}
          </ion-select-option>
        </ion-select>
      </ion-item>

    </div>
    <div fxFlex></div>
    <div class="stick-bottom">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col size="12">
            <ion-button class="action-button-fill button-color-primary-70" (click)="accept()" type="submit" block>
              <span class="button-font-size-m">
                Enter Leplace
              </span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>

</modal-container>