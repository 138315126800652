import { Component, OnInit, OnDestroy, OnChanges, ViewChild, Input, SimpleChanges, SimpleChange, ElementRef } from '@angular/core';
import { MathUtils } from 'src/app/classes/general/math';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { IKVStat, IKVStatDict } from 'src/app/classes/def/app/kval';


@Component({
  selector: 'kv-table',
  templateUrl: './kv-table.component.html',
  styleUrls: ['./kv-table.component.scss'],
})
export class KVTableComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('paginator', { read: ElementRef, static: false }) paginator: MatPaginator;

  dataSource: MatTableDataSource<any>;
  displayedColumns = ["Name", "Value"];

  @Input()
  data: IKVStat[];

  @Input()
  dict: IKVStatDict;

  @Input()
  format: boolean;

  constructor(
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      // console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'data':
            this.loadData();
            break;
          case 'dict':
            this.loadData();
            break;
        }
      }
    }
  }

  ngOnInit() {
    this.loadData();
  }

  loadData() {
    let data: IKVStat[] = [];

    if (this.data != null) {
      data = this.data;
    } else {
      if (this.dict != null) {
        let keys: string[] = Object.keys(this.dict);
        data = keys.map(key => this.dict[key]);
      }
    }
    if (this.format) {
      if (data != null) { 
        data.forEach((e) => {
          if (!e.valueString) {
            e.valueString = MathUtils.formatNumericString(e.value, 1);
          }
        });
      }
    }

    this.dataSource = new MatTableDataSource(data);
    // this.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {

  }
}
