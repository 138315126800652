import type { Plugin } from '@capacitor/core';
import { registerPlugin } from '@capacitor/core';

import type {
  CameraConfig,
  CameraPosition,
  Circle,
  LatLng,
  LatLngBounds,
  MapPadding,
  MapType,
  Marker,
  Polygon,
  Polyline,
  CreateMapOptions,
  UpdateMapOptions
} from './definitions';

/**
 * An interface containing the options used when creating a map.
 */
export interface CreateMapArgs extends CreateMapOptions { };
export interface UpdateMapArgs extends UpdateMapOptions { };

export interface DestroyMapArgs {
  id: string;
}

export interface SetMapAllGesturesArgs {
  id: string;
  enabled: boolean;
}

export interface SetMapVisibleArgs {
  id: string;
  visible: boolean;
}

export interface SetDivMapArgs {
  id: string;
  containerId: string;
}


export interface RemoveMarkerArgs {
  id: string;
  markerId: string;
}

export interface RemoveMarkersArgs {
  id: string;
  markerIds: string[];
}

export interface AddMarkerArgs {
  id: string;
  marker: Marker;
}

export interface UpdateMarkerArgs {
  id: string;
  markerId: string;
  options: Marker;
}

export interface UpdateMarkerPositionArgs {
  id: string;
  markerId: string;
  position: LatLng;
}

export interface UpdateCircleRadiusArgs {
  id: string;
  circleId: string;
  radius: number;
}

export interface UpdateCircleCenterArgs {
  id: string;
  circleId: string;
  center: LatLng;
}

export interface SetDraggableArgs {
  id: string;
  markerId: string;
  draggable: boolean;
}

export interface AddPolygonsArgs {
  id: string;
  polygons: Polygon[];
}

export interface RemovePolygonsArgs {
  id: string;
  polygonIds: string[];
}

export interface AddCirclesArgs {
  id: string;
  circles: Circle[];
}

export interface RemoveCirclesArgs {
  id: string;
  circleIds: string[];
}
export interface AddPolylinesArgs {
  id: string;
  polylines: Polyline[];
}

export interface RemovePolylinesArgs {
  id: string;
  polylineIds: string[];
}

export interface CameraArgs {
  id: string;
  config: CameraConfig;
}

export interface MapTypeArgs {
  id: string;
  mapType: MapType;
}

export interface IndoorMapArgs {
  id: string;
  enabled: boolean;
}

export interface TrafficLayerArgs {
  id: string;
  enabled: boolean;
}

export interface AccElementsArgs {
  id: string;
  enabled: boolean;
}

export interface PaddingArgs {
  id: string;
  padding: MapPadding;
}

export interface CurrentLocArgs {
  id: string;
  enabled: boolean;
}
export interface AddMarkersArgs {
  id: string;
  markers: Marker[];
}
export interface SelectMarkersArgs {
  id: string;
  markerId: string;
}

export interface MapBoundsArgs {
  id: string;
  mapBounds: {
    x: number;
    y: number;
    width: number;
    height: number;
  };
}

export interface MapBoundsContainsArgs {
  bounds: LatLngBounds;
  point: LatLng;
}

export type MapBoundsExtendArgs = MapBoundsContainsArgs;

export interface EnableClusteringArgs {
  id: string;
  minClusterSize?: number;
}

export interface FitBoundsArgs {
  id: string;
  bounds: LatLngBounds;
  padding?: number;
}

export interface CapacitorGoogleMapsPlugin extends Plugin {
  create(options: CreateMapArgs): Promise<void>;
  enableTouch(args: { id: string }): Promise<void>;
  disableTouch(args: { id: string }): Promise<void>;
  addMarker(args: AddMarkerArgs): Promise<{ id: string }>;
  addMarkers(args: AddMarkersArgs): Promise<{ ids: string[] }>;
  updateMarker(args: UpdateMarkerArgs): Promise<void>;
  updateMarkerPosition(args: UpdateMarkerPositionArgs): Promise<void>;
  removeMarker(args: RemoveMarkerArgs): Promise<void>;
  removeMarkers(args: RemoveMarkersArgs): Promise<void>;
  removeMarkersAny(args: RemoveMarkersArgs): Promise<void>;
  showMarker(args: SelectMarkersArgs): Promise<void>;
  hideMarker(args: SelectMarkersArgs): Promise<void>;
  addPolygons(args: AddPolygonsArgs): Promise<{ ids: string[] }>;
  removePolygons(args: RemovePolygonsArgs): Promise<void>;
  addCircles(args: AddCirclesArgs): Promise<{ ids: string[] }>;
  removeCircles(args: RemoveCirclesArgs): Promise<void>;
  setCircleRadius(args: UpdateCircleRadiusArgs): Promise<void>;
  setCircleCenter(args: UpdateCircleCenterArgs): Promise<void>;
  setDraggable(args: SetDraggableArgs): Promise<void>;
  addPolylines(args: AddPolylinesArgs): Promise<{ ids: string[] }>;
  removePolylines(args: RemovePolylinesArgs): Promise<void>;
  enableClustering(args: EnableClusteringArgs): Promise<void>;
  disableClustering(args: { id: string }): Promise<void>;
  clearMap(args: { id: string }): Promise<void>;
  setDiv(args: SetDivMapArgs): Promise<void>;
  setOptions(options: UpdateMapArgs): Promise<void>;
  setAllGesturesEnabled(args: SetMapAllGesturesArgs): Promise<void>;
  setVisible(args: SetMapVisibleArgs): Promise<void>;
  refreshLayout(args: { id: string }): Promise<void>;

  destroy(args: DestroyMapArgs): Promise<void>;
  setCamera(args: CameraArgs): Promise<void>;
  getCameraPosition(args: { id: string }): Promise<CameraPosition>;

  getMapType(args: { id: string }): Promise<{ type: string }>;
  setMapType(args: MapTypeArgs): Promise<void>;
  enableIndoorMaps(args: IndoorMapArgs): Promise<void>;
  enableTrafficLayer(args: TrafficLayerArgs): Promise<void>;
  enableAccessibilityElements(args: AccElementsArgs): Promise<void>;
  enableCurrentLocation(args: CurrentLocArgs): Promise<void>;
  setPadding(args: PaddingArgs): Promise<void>;
  onScroll(args: MapBoundsArgs): Promise<void>;
  onResize(args: MapBoundsArgs): Promise<void>;
  onDisplay(args: MapBoundsArgs): Promise<void>;
  dispatchMapEvent(args: { id: string; focus: boolean }): Promise<void>;
  getMapBounds(args: { id: string }): Promise<LatLngBounds>;
  fitBounds(args: FitBoundsArgs): Promise<void>;
  mapBoundsContains(
    args: MapBoundsContainsArgs,
  ): Promise<{ contains: boolean }>;
  mapBoundsExtend(args: MapBoundsExtendArgs): Promise<{ bounds: LatLngBounds }>;
}

const CapacitorGoogleMaps = registerPlugin<CapacitorGoogleMapsPlugin>(
  'CapacitorGoogleMaps',
  {
    web: () => import('./web').then(m => new m.CapacitorGoogleMapsWeb()),
  },
);

// Add the event listener for logging
CapacitorGoogleMaps.addListener('logEvent', (event) => {
  console.log(event.message);
});

CapacitorGoogleMaps.addListener('isMapInFocus', data => {
  const x = data.x;
  const y = data.y;

  const elem = document.elementFromPoint(x, y) as HTMLElement | null;
  const internalId = elem?.dataset?.internalId;
  const mapInFocus = internalId === data.mapId;

  CapacitorGoogleMaps.dispatchMapEvent({ id: data.mapId, focus: mapInFocus });
});

export { CapacitorGoogleMaps };
