import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericDataService } from '../general/data/generic';


@Injectable({
    providedIn: 'root'
})
export class PaymentStripeDataService {

    constructor(
        public http: HttpClient,
        public generic: GenericDataService
    ) {
    }

    /**
    * create payment checkout page
    * handle everything
    * include userId for additional features: auto-unlock
    */
    createPaymentCheckoutWizard(customerEmail: string, storyId: number, itemCode: number, connectUrl: string) {
        return this.generic.genericPostStandardWData("/business/payment/stripe/create-payment-checkout-session", {
            customerEmail: customerEmail,
            storyId: storyId,
            itemCode: itemCode,
            connectUrl: connectUrl
        });
    }
}
