<!-- swipeVertical (swipeUp)="swipeUp()" (swipeDown)="swipeDown()" -->
<div>
  <ion-grid class="full-w margin-top-s padding-bottom-s no-padding">
    <!-- (swipe)="swipeEvent($event)" -->
    <div *ngFor="let elem of data; let i = index;">
      <div *ngIf="checkShowElem(i)">
        <ion-row *ngIf="checkShow(elem) && !elem.wide" class="center-all">
          <ion-col size="4" class="no-padding">
            <leplace-hud-caption *ngIf="elem.caption" [data]="elem"></leplace-hud-caption>
          </ion-col>
          <ion-col size="4" class="no-padding">
            <div class="text-center">
              <span class="bold italic info-text-s" [ngClass]="elem.mode.class">{{elem.value}}</span>
            </div>
          </ion-col>
          <ion-col size="4" class="no-padding">
            <div class="text-center">
              <span class="info-text-xs bold text-color-primary">{{elem.unit}}</span>
            </div>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="checkShow(elem) && elem.wide" class="center-all">
          <ion-col size="4" class="no-padding">
            <leplace-hud-caption [data]="elem"></leplace-hud-caption>
          </ion-col>
          <ion-col size="8" class="no-padding">
            <div class="text-center">
              <span class="info-text-s bold italic" [ngClass]="elem.mode.class">{{elem.value}}</span>
            </div>
          </ion-col>
        </ion-row>
      </div>
    </div>

    <ion-row class="text-color-primary" *ngIf="expander" class="center-all">
      <ion-col size="12" class="no-padding">
        <div *ngIf="!expand" class="text-center" tappable (click)="toggleExpand(true)">
          <icon-wrapper icon="menu"></icon-wrapper>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>