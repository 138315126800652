import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupTutorialPage } from './signup-tutorial.page';
import { PagesModule } from '../../pages.module';

const routes: Routes = [
  {
    path: '',
    component: SignupTutorialPage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [SignupTutorialPage]
})
export class SignupTutorialPageModule {}
