
import { Injectable } from "@angular/core";
import { ResourceManager } from "../../../../classes/general/resource-manager";
import { TimeoutService } from "../timeout";
import { LocalNotificationsService } from "../../../general/apis/local-notifications";
import { BehaviorSubject } from "rxjs";
import { IARSpecialActivity } from "../../../../classes/def/ar/core";
import { IObjectPosition } from "../../../../classes/def/ar/minimap";
import { ECheckActivityResult } from "../../../../classes/def/core/activity";
import { GeometryUtils } from "../../../utils/geometry-utils";
import { ICustomParamForActivity, ECustomParamCategoryCode } from "../../../../classes/def/core/custom-param";
import { IARExploreStatus } from 'src/app/classes/def/activity/arexplore';
import { ITimeoutMonitorParams, ITimeoutMonitorData, ETimeoutStatus } from 'src/app/classes/general/timeout';

@Injectable({
    providedIn: 'root'
})
export class ARExploreActivityService {

    subscription = {
        timeoutActivityMonitor: null
    };

    params: IARSpecialActivity;

    objectsFound: boolean = false;
    unlocked: boolean = false;

    statusObservable: BehaviorSubject<IARExploreStatus>;

    moduleName: string = "AR ACTIVITY > ";

    maxDist: number = 100; // meters
    maxAngle: number = 30; // degrees

    activityFinished: boolean = false;

    constructor(
        public timeoutMonitor: TimeoutService,
        public localNotifications: LocalNotificationsService
    ) {
        console.log("arexplore activity service created");
        this.statusObservable = new BehaviorSubject(null);
        this.initData();
    }

    watchStatus() {
        return this.statusObservable;
    }

    initData() {
        this.unlocked = false;
        this.objectsFound = false;
        this.params = null;
    }

    /**
     * handle timeout activity
     */
    initActivity(timeLeft: number) {
        console.log(this.moduleName + "init");
        if (!this.subscription.timeoutActivityMonitor) {
            console.log(this.moduleName + "init started");
            this.statusObservable.next(null);

            let tmParams: ITimeoutMonitorParams = {
                timeLimit: timeLeft
            };
            this.timeoutMonitor.start(tmParams);

            let finished: boolean = false;

            let status: IARExploreStatus = {
                tmData: null,
                status: ECheckActivityResult.inProgress
            };

            this.initData();

            this.subscription.timeoutActivityMonitor = this.timeoutMonitor.getWatch().subscribe((tmData: ITimeoutMonitorData) => {

                if (tmData) {
                    // console.log(tmData.timerValue);

                    if (!tmData.isFallbackTimer) {
                        status.tmData = tmData;
                    }

                    if (this.objectsFound && this.unlocked) {
                        this.activityFinished = true;
                        status.status = ECheckActivityResult.done;
                        this.statusObservable.next(status);
                    }

                    switch (tmData.status) {
                        case ETimeoutStatus.expired:
                            status.status = ECheckActivityResult.failed;
                            this.statusObservable.next(status);
                            this.activityFinished = true;
                            break;
                    }

                    if (this.activityFinished) {
                        this.subscription.timeoutActivityMonitor = ResourceManager.clearSub(this.subscription.timeoutActivityMonitor);
                        console.log("stopping timer");
                        this.timeoutMonitor.stop();
                    } else {
                        this.statusObservable.next(status);
                    }
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    setActivityParams(params: IARSpecialActivity) {
        this.params = params;
        console.log("set activity params: ", this.params);
    }

    /**
     * set current objects that are found on AR
     * check if all required objects are found in the selection
     */
    checkRequiredObjects(objects: IObjectPosition[], enable: boolean) {
        // console.log("object snapshot: ", objects);
        if (!this.params || !objects) {
            // console.error("set objects undefined specs");
            return;
        }

        let objectTypesInView: number[] = this.getObjectTypesInView(objects);
        let countObjectsFound: number = 0;
        let countRequiredObjects: number = 0;
        let objectsFound: boolean = false;

        // console.log(objectTypesInView);

        for (let i = 0; i < this.params.customParams.length; i++) {
            let p: ICustomParamForActivity = this.params.customParams[i];
            if (p.customParamCategoryCode === ECustomParamCategoryCode.arPhotoChallenge) {
                countRequiredObjects += 1;
                // check if the required object is found in the photo
                for (let j = 0; j < objectTypesInView.length; j++) {
                    // the code is represented in the last 5 digits
                    if (objectTypesInView[j] === Math.floor(p.code % 100000)) {
                        countObjectsFound += 1;
                        break;
                    }
                }
            }
        }

        if (countObjectsFound >= countRequiredObjects) {
            objectsFound = true;
        } else {
            objectsFound = false;
        }

        if (enable) {
            this.objectsFound = objectsFound;
        }

        // console.log("found: ", countObjectsFound + " out of " + countRequiredObjects + " objects");
        return objectsFound;
    }

    /**
     * get objects that are within the view angle
     * (actually the object types that are visible)
     * @param snapshot 
     */
    getObjectTypesInView(snapshot: IObjectPosition[]) {
        let objectTypesInView: number[] = [];
        for (let i = 0; i < snapshot.length; i++) {
            let p: IObjectPosition = snapshot[i];
            p.alpha = GeometryUtils.translateTo180(GeometryUtils.rotateAngle(GeometryUtils.radiansToDegrees(Math.atan2(p.y, p.x)), -90));
            p.dist = Math.sqrt(p.x * p.x + p.y * p.y);
            let alpha1: number = Math.abs(p.alpha);
            if ((p.dist < this.maxDist) && ((alpha1 < this.maxAngle))) {
                p.inView = true;
                objectTypesInView.push(p.code);
            } else {
                p.inView = false;
            }
        }
        // snapshot = snapshot.sort((a, b) => {
        //     let aa = Math.abs(a.alpha);
        //     let ab = Math.abs(b.alpha);
        //     if (aa > ab) {
        //         return -1;
        //     }
        //     if (aa < ab) {
        //         return 1;
        //     }
        //     return 0;
        // });
        objectTypesInView = objectTypesInView.filter((value, index, self) => {
            return self.indexOf(value) === index;
        });
        // console.log("objects: ", snapshot);
        // console.log("object codes in view: ", objectTypesInView);
        return objectTypesInView;
    }

    unlock() {
        this.unlocked = true;
    }


    exitActivity() {
        this.subscription = ResourceManager.clearSubObj(this.subscription);
        console.log("stopping timer");
        this.timeoutMonitor.stop();
        this.activityFinished = false;
    }

}




