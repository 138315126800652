
import { EClass, EFooterClass, EHeaderClass } from './ui';
import { IQueueMessage, EQueueMessageCode } from '../../utils/queue';
import { EAppIcons, EAppIconsStandard } from './icons';


export interface IHudElement {
    name: string;
    // show caption/icon
    caption: boolean;
    value: string;
    unit: string;
    icon: string;
    customIcon?: boolean;
    icon2?: string;
    wide?: boolean;
    debug: boolean;
    show: boolean;
    unlocked?: boolean;
    index: number;
    code: number;
    mode?: IHudElementDisplayMode;
}

export interface IHudElementDisplayModesDef {
    key: IHudElementDisplayMode;
    complete: IHudElementDisplayMode;
    default: IHudElementDisplayMode;
}

export interface IHudElementDisplayMode {
    code: number;
    class: string;
}


export enum EMapHudCodes {
    timer = 1,
    timerAutostart = 2,
    fps = 3,
    locationWarning = 4,
    compassHeading = 5,
    currentSpeedMove = 6,
    targetDistanceMove = 7,
    targetSpeedMove = 8,
    navigateDistanceToTarget = 10,
    currentDistanceMove = 11,
    groupStatus = 12,
    collectedCoins = 13,
    collectedCoinsAll = 14,
    droneBatteryLevel = 15,
    gpsHeading = 16,
    droneSimulationRate = 17,
    compositeHeading = 18,
    altitude = 19,
    debug = 100
}

export enum EARHudCodes {
    headingGyroAligned = 0,
    headingGyroRaw = 1,
    headingGPS = 2,
    headingCompass = 3,
    speed = 4,
    alignDelta = 5,
    alignDeltaAux = 6,
    // coordsGPS  = 0,
    // coordsXYZ  = 0,
    lat = 7,
    lng = 8,
    x = 9,
    y = 10,
    fps = 11,
    altitude = 12,
    altitudeAccel = 13,
    tilt = 14,
    headingDelta = 15
}

export enum EHudContext {
    none = -1,
    map = 0,
    ar = 1,
    mapAndAr = 2,
    auto = 3
}

export interface IHudConfig {
    outerClass: string;
    innerClass: string;
    tintClass: string;
}

export interface IHudOverlayStyle {
    footerClass: string;
    headerClass: string;
    outerClass: string;
    footerContentClass: string;
}

/**
 * handle HUD CRUD and definitions
 */
export class HudUtils {

    static getXpHudClass(msg: IQueueMessage, hudConfig: IHudConfig) {
        if (msg) {
            switch (msg.code) {
                case EQueueMessageCode.info:
                    hudConfig.tintClass = EClass.HUD_INFO;
                    hudConfig.innerClass = EClass.HUD_INNER_INFO;
                    break;
                case EQueueMessageCode.warn:
                    hudConfig.tintClass = EClass.HUD_WARN;
                    hudConfig.innerClass = EClass.HUD_INNER_WARN;
                    break;
                case EQueueMessageCode.error:
                    hudConfig.tintClass = EClass.HUD_ERROR;
                    hudConfig.innerClass = EClass.HUD_INNER_ERROR;
                    break;
            }
        } else {
            hudConfig.tintClass = EClass.HUD_INFO;
            hudConfig.innerClass = EClass.HUD_INNER_INFO;
        }
        return hudConfig;
    }

    static setOverlayStyle(large: boolean, ios: boolean): IHudOverlayStyle {
        let style: IHudOverlayStyle = {
            footerClass: EFooterClass.FOOTER_SMALL,
            headerClass: EHeaderClass.HEADER_SMALL,
            outerClass: EClass.HUD_BAN_NORMAL,
            footerContentClass: EFooterClass.FOOTER_CONTENT
        };
        if (large) {
            style.footerClass = ios ? EFooterClass.FOOTER_NORMAL_IOSX : EFooterClass.FOOTER_NORMAL;
            style.headerClass = ios ? EHeaderClass.HEADER_NORMAL : EHeaderClass.HEADER_NORMAL;
            style.outerClass = ios ? EClass.HUD_BAN_NORMAL_IOSX : EClass.HUD_BAN_NORMAL;
        } else {
            style.footerClass = ios ? EFooterClass.FOOTER_SMALL_IOSX : EFooterClass.FOOTER_SMALL;
            style.headerClass = ios ? EHeaderClass.HEADER_SMALL : EHeaderClass.HEADER_SMALL;
            style.outerClass = ios ? EClass.HUD_BAN_SMALL_IOSX : EClass.HUD_BAN_SMALL;
        }
        style.footerContentClass = ios ? EFooterClass.FOOTER_CONTENT_IOSX : EFooterClass.FOOTER_CONTENT;
        return style;
    }

    static getHudDisplayModes() {
        let huds: IHudElementDisplayModesDef = {
            key: {
                code: 3,
                class: "text-color-warn-contrast"
            },
            complete: {
                code: 2,
                class: "text-color-accent-contrast"
            },
            default: {
                code: 1,
                class: "text-color-primary"
            }
        };
        return huds;
    }

    /**
     * highlight all hud elements
     * @param hudArray 
     * @param mode 
     */
    static setHudHighlightAll(hudArray: IHudElement[], mode: IHudElementDisplayMode) {
        for (let i = 0; i < hudArray.length; i++) {
            hudArray[i].mode = mode;
        }
    }

    /**
    * show/hide all hud elements
    * @param hudArray 
    * @param show 
    */
    static setHudShowAll(hudArray: IHudElement[], show: boolean) {
        for (let i = 0; i < hudArray.length; i++) {
            hudArray[i].show = show;
            hudArray[i].unlocked = true;
            if (!show) {
                // also clear highlight
                hudArray[i].mode = HudUtils.getHudDisplayModes().default;
            }
        }
    }

    /**
     * show/hide hud element
     * check unlocked or lock not set
     * @param hudArray 
     * @param code 
     * @param show true/false
     */
    static setHudShow(hudArray: IHudElement[], code: number, show: boolean) {
        let hudElem: IHudElement = hudArray.find(e => e.code === code);
        if (hudElem) {
            HudUtils.setHudShowCore(hudElem, show);
            HudUtils.clearHudMessage(hudArray, code);
        }
    }

    static setHudShowCore(hudElem: IHudElement, show: boolean) {
        if (!hudElem) {
            return;
        }
        if (show) {
            // check unlocked
            if (hudElem.unlocked || (hudElem.unlocked == null)) {
                hudElem.show = show;
            }
        } else {
            hudElem.show = show;
        }
    }

    static checkHudAnyShow(hudArray: IHudElement[], debug: boolean) {
        let show: boolean = false;
        for (let hudElem of hudArray) {
            if (hudElem.show && ((hudElem.debug && debug) || !hudElem.debug)) {
                show = true;
                break;
            }
        }
        return show;
    }

    static setHudUnlocked(hudArray: IHudElement[], code: number, unlocked: boolean) {
        let hudElem: IHudElement = hudArray.find(e => e.code === code);
        if (hudElem) {
            hudElem.unlocked = unlocked;
        }
    }

    static setHudUnlockedAll(hudArray: IHudElement[], unlocked: boolean) {
        for (let hudElem of hudArray) {
            hudElem.unlocked = unlocked;
        }
    }

    /**
     * highlight hud element
     * @param hudArray 
     * @param code 
     * @param mode 
     */
    static setHudHighlight(hudArray: IHudElement[], code: number, mode: IHudElementDisplayMode) {
        let hudElem: IHudElement = hudArray.find(e => e.code === code);
        if (hudElem) {
            hudElem.mode = mode;
        }
    }

    static getARHudInitValues() {
        let hudMsgAR: IHudElement[] = [
            {
                name: "h",
                value: null,
                caption: true,
                // icon: "compass",
                icon: null,
                unit: "",
                debug: true,
                show: true,
                index: 0,
                code: EARHudCodes.headingGyroAligned
            },
            {
                name: "h/gyro",
                value: null,
                caption: true,
                // icon: "compass",
                icon: null,
                unit: "",
                debug: true,
                show: true,
                index: 0,
                code: EARHudCodes.headingGyroRaw
            },
            {
                name: "h/gps",
                value: null,
                caption: true,
                // icon: "compass",
                icon: null,
                unit: "",
                debug: true,
                show: true,
                index: 0,
                code: EARHudCodes.headingGPS
            },
            {
                name: "h/comp",
                value: null,
                caption: true,
                // icon: "compass",
                icon: null,
                unit: "",
                debug: true,
                show: true,
                index: 0,
                code: EARHudCodes.headingCompass
            },
            {
                name: "h/delta",
                value: null,
                caption: true,
                // icon: "compass",
                icon: null,
                unit: "",
                debug: true,
                show: true,
                index: 0,
                code: EARHudCodes.headingDelta
            },
            {
                name: "tilt",
                value: null,
                caption: true,
                // icon: "compass",
                icon: null,
                unit: "",
                debug: true,
                show: true,
                index: 0,
                code: EARHudCodes.tilt
            },
            {
                name: "speed",
                value: null,
                caption: true,
                icon: "speedometer",
                unit: "",
                debug: false,
                show: true,
                index: 0,
                code: EARHudCodes.speed
            },
            // {
            //     name: "align/raw",
            //     value: null,
            //     caption: true,
            //     icon: null,
            //     unit: "",
            //     debug: true,
            //     show: true,
            //     index: 0,
            //     code: EARHudCodes.alignDeltaAux
            // },
            {
                name: "align",
                value: null,
                caption: true,
                icon: null,
                unit: "",
                debug: true,
                show: true,
                index: 0,
                code: EARHudCodes.alignDelta
            },
            // {
            //     name: "lat/gps",
            //     value: null,
            //     caption: true,
            //     icon: null,
            //     unit: "(+).4",
            //     debug: true,
            //     show: true,
            //     index: 0,
            //     code: EARHudCodes.lat
            // },
            // {
            //     name: "lng/gps",
            //     value: null,
            //     caption: true,
            //     icon: null,
            //     unit: "(+).4",
            //     debug: true,
            //     show: true,
            //     index: 0,
            //     code: EARHudCodes.lng
            // },
            {
                name: "x/3d",
                value: null,
                caption: true,
                icon: null,
                unit: "",
                debug: true,
                show: true,
                index: 0,
                code: EARHudCodes.x
            },
            {
                name: "y/3d",
                value: null,
                caption: true,
                icon: null,
                unit: "",
                debug: true,
                show: true,
                index: 0,
                code: EARHudCodes.y
            },
            {
                name: "fps",
                value: null,
                caption: true,
                icon: "film",
                unit: "",
                debug: true,
                show: true,
                index: 0,
                code: EARHudCodes.fps
            },
            {
                name: "altf",
                value: null,
                caption: true,
                icon: null,
                unit: "",
                debug: true,
                show: true,
                index: 0,
                code: EARHudCodes.altitudeAccel
            },
            {
                name: "alt",
                value: null,
                caption: true,
                icon: null,
                unit: "",
                debug: true,
                show: true,
                index: 0,
                code: EARHudCodes.altitude
            }
            // coordsGPS: {
            //     name: "coords/gps",
            //     value: null,
            //     unit: "(+).4"
            // },
            // coordsXYZ: {
            //     name: "coords/xyz",
            //     value: null,
            //     unit: "m/xy"
            // }
        ];

        return HudUtils.sortByCode(hudMsgAR);
    }

    /**
     * sort hud elements by code (index)
     */
    static sortByCode(hudElements: IHudElement[]) {
        hudElements.sort((a, b) => {
            if (a.index < b.index) {
                return -1;
            }
            if (a.index > b.index) {
                return 1;
            }
            return 0;
        });
        return hudElements;
    }



    static getMapHudInitValues() {
        let hudMsg: IHudElement[] = [
            {
                name: "debug",
                value: null,
                unit: "",
                caption: true,
                icon: null,
                wide: true,
                debug: true,
                show: true,
                index: 1000,
                code: EMapHudCodes.debug
            },
            {
                name: "time",
                value: null,
                unit: "",
                caption: true,
                icon: EAppIcons.stopwatch,
                customIcon: true,
                wide: true,
                debug: false,
                show: true,
                index: 110,
                code: EMapHudCodes.timer
            },
            {
                name: "start",
                value: null,
                caption: true,
                icon: "timer",
                customIcon: false,
                unit: "",
                debug: false,
                show: true,
                index: 100,
                wide: true,
                code: EMapHudCodes.timerAutostart
            },
            {
                name: "target",
                value: null,
                caption: true,
                icon: EAppIcons.distanceTarget,
                customIcon: true,
                unit: "", // dynamic
                debug: false,
                show: true,
                index: 50,
                code: EMapHudCodes.targetDistanceMove
            },
            {
                name: "dist.",
                value: null,
                caption: true,
                icon: EAppIcons.distance,
                customIcon: true,
                unit: "", // dynamic
                debug: false,
                show: true,
                index: 60,
                code: EMapHudCodes.currentDistanceMove
            },
            {
                name: "place",
                value: null,
                caption: true,
                icon: EAppIcons.distanceTarget,
                customIcon: true,
                unit: "", // dynamic
                debug: false,
                show: true,
                index: 40,
                code: EMapHudCodes.navigateDistanceToTarget
            },
            {
                name: "found",
                value: null,
                caption: true,
                icon: "ribbon",
                unit: "",
                debug: false,
                show: true,
                index: 130,
                wide: true,
                code: EMapHudCodes.collectedCoins
            },
            {
                name: "total",
                value: null,
                caption: true,
                icon: "ribbon",
                unit: "",
                debug: false,
                show: true,
                index: 120,
                wide: true,
                code: EMapHudCodes.collectedCoinsAll
            },
            {
                name: "target",
                value: null,
                caption: false,
                icon: "speedometer",
                unit: "", // dynamic
                debug: false,
                show: true,
                index: 20,
                code: EMapHudCodes.targetSpeedMove
            },
            {
                name: "speed",
                value: null,
                caption: true,
                icon: "speedometer",
                unit: "", // dynamic
                debug: false,
                show: true,
                index: 30,
                code: EMapHudCodes.currentSpeedMove
            },
            {
                name: "hdg",
                value: null,
                caption: true,
                icon: "compass",
                unit: "",
                debug: true,
                show: true,
                index: 15,
                code: EMapHudCodes.compassHeading
            },
            {
                name: "hdgx",
                value: null,
                caption: true,
                icon: EAppIcons.gpsFixed,
                customIcon: true,
                unit: "",
                debug: true,
                show: true,
                index: 16,
                code: EMapHudCodes.gpsHeading
            },
            {
                name: "hdgy",
                value: null,
                caption: true,
                icon: EAppIcons.compass,
                customIcon: true,
                unit: "",
                debug: true,
                show: true,
                index: 17,
                code: EMapHudCodes.compositeHeading
            },
            {
                name: "alt",
                value: null,
                caption: true,
                icon: "compass",
                unit: "",
                debug: true,
                show: true,
                index: 18,
                code: EMapHudCodes.altitude
            },
            {
                name: "battery",
                value: null,
                caption: true,
                icon: EAppIcons.battery,
                customIcon: true,
                unit: "%",
                debug: false,
                show: true,
                index: 45,
                code: EMapHudCodes.droneBatteryLevel
            },
            {
                name: "warn.",
                value: null,
                caption: true,
                icon: "alert",
                unit: "",
                wide: true,
                debug: false,
                show: true,
                index: 140,
                code: EMapHudCodes.locationWarning
            },
            {
                name: "group",
                value: null,
                caption: true,
                icon: "people",
                unit: "",
                wide: true,
                debug: false,
                show: true,
                index: 90,
                code: EMapHudCodes.groupStatus
            },
            {
                name: "fps",
                value: null,
                caption: true,
                icon: EAppIcons.film,
                customIcon: true,
                unit: "",
                debug: true,
                show: true,
                index: 10,
                code: EMapHudCodes.fps
            },
            {
                name: "dsr",
                value: null,
                caption: true,
                icon: EAppIconsStandard.settings,
                customIcon: false,
                unit: "",
                debug: true,
                show: true,
                index: 11,
                code: EMapHudCodes.droneSimulationRate
            }
        ];
        return HudUtils.sortByCode(hudMsg);
    }

    /**
     * fill in the hud element in the array
     * @param hud 
     * @param code 
     * @param value 
     * @param unit 
     * @param debug 
     */
    static showHudMessage(hud: IHudElement[], code: number, value: string, unit: string, debug: boolean) {
        let hudElement: IHudElement = hud.find(e => e.code === code);
        return HudUtils.showHudMessageCore(hudElement, value, unit, debug);
    }

    /**
     * clear the hud element in the array
     * @param hud 
     * @param code 
     */
    static clearHudMessage(hud: IHudElement[], code: number) {
        let hudElement: IHudElement = hud.find(e => e.code === code);
        HudUtils.clearHudMessageCore(hudElement);
    }

    /**
     * show hud empty message/placeholder element
     * @param hud 
     * @param code 
     */
    static showHudEmptyMessage(hud: IHudElement[], code: number) {
        let hudElement: IHudElement = hud.find(e => e.code === code);
        HudUtils.showHudEmptyMessageCore(hudElement);
    }

    /**
     * toggle debug hud elements
     * @param hud 
     * @param show 
     */
    static showDebugHudElements(hud: IHudElement[], show: boolean) {
        for (let hudElement of hud) {
            if (hudElement.debug) {
                hudElement.show = show;
            }
        }
    }

    /**
    * fill in the hud element
    * @param debug the HUD is shown in debug mode (also include hud elements that are for debug purpose)
    */
    private static showHudMessageCore(hudElement: IHudElement, value: string, unit: string, debug: boolean) {
        let shown: boolean = false;
        if (!hudElement) {
            return shown;
        }
        if ((hudElement.debug && debug) || (!hudElement.debug)) {
            hudElement.value = value != null ? value : null;
            hudElement.unit = unit != null ? unit : "";

        } else {
            hudElement.value = null;
        }
        shown = hudElement.value !== null;
        return shown;
    }

    /**
     * clear the hud element
     * @param hudElement 
     */
    private static clearHudMessageCore(hudElement: IHudElement) {
        if (!hudElement) {
            return;
        }
        hudElement.value = null;
        hudElement.unit = "";
    }


    /**
     * clear the hud element
     * @param hudElement 
     */
    private static showHudEmptyMessageCore(hudElement: IHudElement) {
        if (!hudElement) {
            return;
        }
        hudElement.value = "-";
        hudElement.unit = "";
    }
}
