import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { Injectable } from '@angular/core';
import { SettingsManagerService } from '../settings-manager';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { TextToSpeechService } from './tts';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { GenericQueueService } from '../generic-queue';
import { EQueues } from 'src/app/classes/def/app/app';
import { AppConstants } from 'src/app/classes/app/constants';
import { ISoundEffect, SoundUtils } from './sound-utils';


@Injectable({
    providedIn: 'root'
})
export class SoundEffectsService {
    
    platform: IPlatformFlags = {} as IPlatformFlags;

    useNative: boolean = true;

    audioReady: boolean = true;

    observable = {
        audioReady: null
    };

    subscription = {
        audioReady: null
    };

    initPreload: boolean = true;

    // hack for using TTS, use first play as native, and then use HTML5
    first: boolean = true;

    enable: boolean = AppConstants.enableSoundApi;

    constructor(
        private nativeAudio: NativeAudio,
        private settingsProvider: SettingsManagerService,
        private tts: TextToSpeechService,
        private genericQueue: GenericQueueService
    ) {
        console.log("sound effects service created");

        this.observable = ResourceManager.initBsubObj(this.observable);

        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    /**
    * wait until audio is ready (not playing sound)
    */
    waitAudioReady() {
        let promise = new Promise((resolve) => {
            if (this.audioReady) {
                resolve(true);
                return;
            }
            this.subscription.audioReady = this.observable.audioReady.subscribe((ready: boolean) => {
                if (ready) {
                    this.subscription.audioReady = ResourceManager.clearSub(this.subscription.audioReady);
                    resolve(true);
                }
            });
        });
        return promise;
    }

    /**
     * preload sounds
     */
    preload() {
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            let keys: string[] = Object.keys(SoundUtils.soundBank);
            if (this.initPreload && this.enable) {
                for (let i = 0; i < keys.length; i++) {
                    let soundEffect: ISoundEffect = SoundUtils.soundBank[keys[i]];
                    try {
                        if (!this.platform.WEB && this.useNative) {
                            await this.nativeAudio.preloadComplex(soundEffect.id, soundEffect.path, 1, 1, 0);
                            // await this.nativeAudio.preloadSimple(soundEffect.id, soundEffect.path);
                        } else {
                            // no preloading
                        }
                    } catch (err) {
                        console.error(err);
                        reject(err);
                    }
                }
                console.log("audio files preloaded");
            } else {
                console.log("skip preload");
            }

            resolve(true);
        });
        return promise;
    }

    clear() {
        this.first = false;
    }

    playQueueNoAction(id: string) {
        this.playQueueResolve(id).then(() => {

        }).catch((err) => {
            console.error(err);
        });
    }


    playNoAction(id: string) {
        this.play(id).then(() => {

        }).catch((err) => {
            console.error(err);
        });
    }

    /**
     * play sound
     * use queue
     * resolve when finished too
     * use TTS queue too (prevent overlapping between audio and tts)
     * @param id 
     */
    playQueueResolve(id: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (!this.enable) {
                console.log("sound api disabled");
                resolve(true);
                return;
            }

            this.genericQueue.enqueue(() => {
                return this.play(id);
            }, () => {
                this.audioReady = true;
                this.observable.audioReady.next(true);
                resolve(true);
            }, null, EQueues.audio, null, 20000);
        });
        return promise;
    }

    /**
     * play sound
     * resolve only
     * @param id 
     */
    play(id: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {

            if (!this.enable) {
                console.log("sound api disabled");
                resolve(true);
                return;
            }

            console.log("playing audio id: ", id);


            // wait until current sound is finished
            // await this.waitAudioReady();
            // make sure audio does not conflict with tts
            await this.tts.disableWakeLock(true);

            this.audioReady = false;
            this.observable.audioReady.next(false);

            let promiseAudio: Promise<boolean> = new Promise(async (resolve) => {
                if (!this.platform.WEB && this.useNative && this.first) {
                    this.first = false;
                    if (!this.initPreload) {
                        try {
                            await this.nativeAudio.preloadComplex(id, SoundUtils.soundBank[id].path, 1, 1, 0);
                        } catch (err) {
                            console.error(err);
                        }
                    }
                    this.nativeAudio.play(id, () => {
                        console.log("play audio completed");
                        resolve(true);
                    }).then(() => {
                        // resolves after starting audio?
                    }).catch((err) => {
                        console.error(err);
                        resolve(false);
                    });
                } else {
                    let audio: HTMLAudioElement = new Audio();

                    audio.preload = "auto";
                    audio.autoplay = false;

                    audio.src = SoundUtils.soundBank[id].path;

                    // audio.onload = () => {
                    //     audio.play().then(() => {
                    //         console.log("audio started");
                    //     }).catch((err) => {
                    //         console.error(err);
                    //     });
                    // };

                    audio.onerror = (err) => {
                        console.error(err);
                        resolve(false);
                    };
                    audio.onended = () => {
                        setTimeout(() => {
                            resolve(true);
                        }, 100);
                    };

                    audio.play().then(() => {
                        console.log("audio started");
                    }).catch((err) => {
                        console.error(err);
                    });

                    // audio.src = this.soundBank[id].path;
                }
            });

            promiseAudio.then((res: boolean) => {
                this.tts.resumeWakeLock();
                this.audioReady = true;
                this.observable.audioReady.next(true);
                resolve(res);
            });
        });
        return promise;
    }
}