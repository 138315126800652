import { Component, OnInit } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ParamHandler } from 'src/app/classes/general/params';
import { ModalController } from '@ionic/angular';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { IListSelectorItem, IListSelectorParams, IDynamicListSelection } from 'src/app/classes/def/dynamic/list';

@Component({
  selector: 'modal-grid-selector',
  templateUrl: './grid-selector.component.html',
  styleUrls: ['./grid-selector.component.scss'],
})
export class GridSelectorViewComponent implements OnInit {
  title: string = "";
  vs: IViewSpecs = ViewSpecs.getDefault();
  np: INavParams = null;
  componentType: string = "groupRole";
  listItems: IListSelectorItem[] = [];
  loaded: boolean = false;
  selectedItem: IListSelectorItem = null;
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;
  update: boolean = false;
  direct: boolean = true;
  customSelect: boolean = false;

  constructor(
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);
      let params: IListSelectorParams = np.params;
      this.title = params.title;
      this.componentType = params.componentType;
      this.listItems = params.listItems;
      this.direct = params.direct;
      this.customSelect = params.customSelect;

      for (let i = 0; i < this.listItems.length; i++) {
        let item: IListSelectorItem = this.listItems[i];
        item[item.inputName] = item.item;
      }

      console.log(this.listItems);
      
      this.checkSelectedItem();
      this.loaded = true;
    }
  }

  checkSelectedItem() {
    for (let i = 0; i < this.listItems.length; i++) {
      let li: IListSelectorItem = this.listItems[i];
      if (li && li.selected) {
        this.selectedItem = li;
        break;
      }
    }
  }

  /**
   * select item
   * apply radio button behavior
   * @param item 
   */
  selectItem(item: IDynamicListSelection) {
    console.log("list selector select item: ", item);
    if (item) {
      let selectedItem: IListSelectorItem = item.item;
      let selectedIndex: number = item.index;

      // uncheck items
      for (let i = 0; i < this.listItems.length; i++) {
        let li: IListSelectorItem = this.listItems[i];
        if (li) {
          li.selected = false;
        }
      }

      this.selectedItem = selectedItem;
      this.listItems[selectedIndex].selected = true;

      // // check selected item
      // if (selectedItem.available) {
      //   this.selectedItem = selectedItem;
      //   this.listItems[selectedIndex].selected = true;
      // } else {
      //   this.selectedItem = null;
      // }
    }

    // make the dynamic list pick up the change
    this.update = !this.update;
    if (this.direct) {
      this.submitSelection();
    }
  }

  submitSelection() {
    this.dismiss(this.selectedItem);
  }

  dismiss(data: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(data).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }
}
