import { ElementRef, Injectable } from '@angular/core';
import { GenericDataService } from '../general/data/generic';
import { IPlatformFlags } from '../../classes/def/app/platform';
import { SettingsManagerService } from '../general/settings-manager';
import { MediaTemplateService } from './media-template';
import { PermissionsService } from '../general/permissions/permissions';

declare var MediaRecorder: any;

export interface IAudioUploadResponse {
    status: boolean;
    uploadedUrl: string;
}

@Injectable({
    providedIn: 'root'
})
export class AudioService implements MediaTemplateService {

    platform: IPlatformFlags = {} as IPlatformFlags;

    mediaRecorder: any;
    recordBuffer: Blob[] = [];

    audioStream: MediaStream;
    audioCtx: AudioContext;
    canvasCtx: CanvasRenderingContext2D;

    audioTag: ElementRef<HTMLAudioElement>;
    audio: HTMLAudioElement;

    constructor(
        public generic: GenericDataService,
        public permissions: PermissionsService,
        public settingsProvider: SettingsManagerService
    ) {
        console.log("audio service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    setMediaElement(audioTag: ElementRef<HTMLAudioElement>) {
        this.audioTag = audioTag;
        if (audioTag != null) {
            this.audio = this.audioTag.nativeElement;
        } else {
            this.audio = null;
        }
    }

    setMediaElementPlayable(audioTag: ElementRef<HTMLAudioElement>) {
        if (audioTag != null) {
            let audio = audioTag.nativeElement;
            audio.controls = true;
        } else {
            return;
        }
    }

    playMediaElement(audioTag: ElementRef<HTMLAudioElement>, onpause: (paused: boolean) => any) {
        if (audioTag != null) {
            let audio = audioTag.nativeElement;
            audio.play();
            audio.onpause = () => {
                onpause(true);
            };
            audio.onended = () => {
                onpause(true);
            };
            audio.onplay = () => {
                onpause(false);
            };
        } else {
            return;
        }
    }

    pauseMediaElement(audioTag: ElementRef<HTMLAudioElement>) {
        if (audioTag != null) {
            let audio = audioTag.nativeElement;
            audio.pause();
        } else {
            return;
        }
    }

    startMediaCapture(canvas: ElementRef<HTMLCanvasElement>, _front: boolean) {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (navigator.mediaDevices) {
                console.log("getUserMedia supported.");
                let constraints = {
                    audio: {
                        sampleRate: {
                            ideal: 44100
                        }
                    },
                    video: false
                };

                this.permissions.requestMicrophoneRecordingPermissions().then(() => {
                    navigator.mediaDevices.getUserMedia(constraints).then((stream: MediaStream) => {
                        this.audioStream = stream;
                        this.visualize(canvas.nativeElement, stream);
                        resolve(true);
                    }).catch((err) => {
                        reject(err);
                    });
                }).catch((err) => {
                    reject(err);
                });
            } else {
                reject(new Error("not supported"));
            }
        });
        return promise;
    }


    stopMediaCapture() {
        try {
            if (this.audioStream != null) {
                this.pauseMediaCapture();
                this.audioStream = null;
            }
            if (this.mediaRecorder != null) {
                this.mediaRecorder.stop();
                this.mediaRecorder = null;
            }
        } catch (err) {
            console.error(err);
        }
    }

    pauseMediaCapture() {
        if (this.audioStream != null) {
            let tracks: MediaStreamTrack[] = this.audioStream.getTracks();
            console.log("tracks: ", tracks);
            for (let track of tracks) {
                if (track.readyState == 'live') {
                    track.stop();
                }
            }
        }
    }

    startRecording(oncomplete: (data: any) => any) {
        console.log("start recording");

        let options = {
            audioBitsPerSecond: 128000, // max 128 kpbs
            mimeType: "audio/webm",
        };

        if (!this.mediaRecorder) {
            this.mediaRecorder = new MediaRecorder(this.audioStream, options);
        }

        this.recordBuffer = []; // here we will store our recorded media chunks (Blobs)

        this.mediaRecorder.onstart = (e) => {
            console.log("recording started");
        };

        this.mediaRecorder.ondataavailable = (e) => {
            this.recordBuffer.push(e.data);
        };

        this.mediaRecorder.onerror = (e) => {
            console.error(e);
            oncomplete(null);
        };

        // only when the recorder stops, we construct a complete Blob from all the chunks
        this.mediaRecorder.onstop = (e) => {
            console.log("recording stopped");
            var rawBlob: Blob = new Blob(this.recordBuffer, { type: 'audio/webm' });
            oncomplete(rawBlob);
            this.saveBlob(rawBlob, "audio_raw_" + new Date().getTime() + ".webm");
        };

        if (this.mediaRecorder.state != 'recording') {
            this.mediaRecorder.start();
        }
    }

    saveBlob(blob: Blob, fileName: string) {
        var a = document.createElement("a");
        document.body.appendChild(a);
        // a.style = "display: none";
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    visualize(canvas: HTMLCanvasElement, stream: MediaStream) {
        if (!this.audioCtx) {
            this.audioCtx = new AudioContext();
        }

        const source = this.audioCtx.createMediaStreamSource(stream);

        const analyser: AnalyserNode = this.audioCtx.createAnalyser();
        analyser.fftSize = 2048;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        source.connect(analyser);
        //analyser.connect(this.audioCtx.destination);

        this.draw(canvas, analyser, dataArray, bufferLength);


    }

    draw(canvas: HTMLCanvasElement, analyser: AnalyserNode, dataArray: Uint8Array, bufferLength: number) {

        this.canvasCtx = canvas.getContext("2d");
        const WIDTH = canvas.width
        const HEIGHT = canvas.height;

        requestAnimationFrame(() => this.draw(canvas, analyser, dataArray, bufferLength));

        analyser.getByteTimeDomainData(dataArray);

        this.canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);

        this.canvasCtx.fillStyle = 'rgba(0, 0, 0, 0)';
        this.canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

        this.canvasCtx.lineWidth = 2;
        this.canvasCtx.strokeStyle = 'rgba(252, 209, 130, 1)';

        this.canvasCtx.beginPath();

        let sliceWidth = WIDTH * 1.0 / bufferLength;
        let x = 0;

        for (let i = 0; i < bufferLength; i++) {

            let v = dataArray[i] / 128.0;
            let y = v * HEIGHT / 2;

            if (i === 0) {
                this.canvasCtx.moveTo(x, y);
            } else {
                this.canvasCtx.lineTo(x, y);
            }

            x += sliceWidth;
        }

        this.canvasCtx.lineTo(canvas.width, canvas.height / 2);
        this.canvasCtx.stroke();
    }

    uploadMedia(storyId: number, storyLocationId: number, filename: string, data: any): Promise<IAudioUploadResponse> {
        return this.generic.genericPostStandardWData("/media/user/audio-upload", {
            storyId: storyId,
            storyLocationId: storyLocationId,
            file: filename,
            data: data
        });
    }
}




