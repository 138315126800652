import { ICustomParamForActivity } from '../core/custom-param';

export enum EPhotoValidatorModes {
    /**
     * test
     */
    compareSingleWithActivityRequired = 0,
    compareDual = 1,
    detectObject = 2
}

export interface IPhotoValidatorParams {
    enable: boolean;
    intro: boolean;
    mode: number;
    uploadFromGallery?: boolean;
    referenceUrl?: string;
    referenceData?: string;
    isGooglePhotoExt?: boolean;
    compareSelfTest?: boolean;
    objects: ICustomParamForActivity[];
}
