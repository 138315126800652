<div>
  <ion-grid class="full-w">
    <ion-row class="center-items center-text">
      <ion-col size="2">
        <img [src]="bgPhoto" alt="Avatar" class="img-avatar-large-crop">
      </ion-col>
      <ion-col size="10">
        <ion-row>
          <ion-col size="12" class="text-left">
            <span class="info-text-m bold text-color-primary">{{id}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <div>
              <kv-table [data]="stats"></kv-table>
            </div>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>