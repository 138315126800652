import { NgModule } from '@angular/core';
import { TeamStatsPage } from './team-stats.page';
import { PagesModule } from '../../pages.module';
import { RouterModule } from '@angular/router';


@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild([
      {
        path: '',
        component: TeamStatsPage
      }
    ])
  ],
  declarations: [
    TeamStatsPage
  ]
})
export class TeamStatsPageModule { }
