import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExtrasPage } from './extras.page';
import { PagesModule } from '../pages.module';

const routes: Routes = [
  {
    path: '',
    component: ExtrasPage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [ExtrasPage]
})
export class ExtrasPageModule {}
