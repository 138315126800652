

import { Injectable } from '@angular/core';
import { MPManagerService } from './mp-manager';
import { ILeplaceTreasure } from '../../../classes/def/places/leplace';
import { IMPMessageDataChallenge, IMPMessageDataChallengeStats } from '../../../classes/def/mp/message-data';
import { EMPEventSource, EMPUserInputCodes, ELeaderStates, EMemberStates, EMPMessageCodes, EMPVirtualGroupCodes, MPEncoding } from '../../../classes/def/mp/protocol';
import { IGroupMember, IGroup, EGroupRole } from '../../../classes/def/mp/groups';
import { UiExtensionService } from '../../general/ui/ui-extension';
import { ResourceManager } from '../../../classes/general/resource-manager';
import { IMPEventContainer } from '../../../classes/def/mp/events';
import { Messages } from '../../../classes/def/app/messages';
import { IActivityResultCore, ECheckActivityResult, IActivityStatsContainer } from '../../../classes/def/core/activity';
import { IActivityParam, IGenericActivityStats } from '../../../classes/def/core/activity-stats';
import { IEventLeaderboard } from '../../../classes/def/events/events';
import { IMPGameResults, IMPGameResultPlayer } from '../../../classes/def/mp/http';
import { ILeaderboardNavParams, ELeaderboardScope } from '../../../classes/def/nav-params/leaderboard';
import { IUserPublicData } from '../../../classes/def/user/general';
import { TimeUtils } from '../../../classes/general/time';
import { LeaderboardPage } from 'src/app/pages/leaderboard/leaderboard.page';
import { IMPGameSession } from 'src/app/classes/def/mp/game';
import { IPrepareCoinSpecs } from 'src/app/classes/def/core/objects';
import { IMPMessageDB } from 'src/app/classes/def/mp/message';
import { ICoinSpecsMpSyncData } from 'src/app/classes/def/activity/explore';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IFindSpecsMpSyncData } from 'src/app/classes/def/activity/find';
import { MessageQueueHandlerService } from '../../general/message-queue-handler';
import { EQueueMessageCode } from 'src/app/classes/utils/queue';
import { DeepCopy } from 'src/app/classes/general/deep-copy';


export enum EMPGameMode {
  worldMapChallenge = 1,
  storyPreload = 2
}

@Injectable({
  providedIn: 'root'
})
export class MPGameInterfaceService {

  gameContainerInit: IMPGameSession = {
    arenaIsOpen: false,
    currentArenaItem: null,
    currentGroup: null,
    currentMemberStatus: [],
    currentGroupRole: null,
    playerId: null,
    online: false
  };

  gameContainer: IMPGameSession;

  // last connected group (used for resetting connection via button)
  gameContainerPrev: IMPGameSession;

  subscription = {
    gameState: null,
    messageReceived: null,
    virtualState: null
  };

  connected: boolean = false;

  mode: number = EMPGameMode.worldMapChallenge;

  constructor(
    public mpManager: MPManagerService,
    public uiext: UiExtensionService,
    public messageQueueHandler: MessageQueueHandlerService,
  ) {
    console.log("mp game interface service created");
    this.gameContainer = DeepCopy.deepcopy(this.gameContainerInit);
    this.gameContainerPrev = DeepCopy.deepcopy(this.gameContainerInit);
  }

  setMode(mode: number) {
    this.mode = mode;
  }

  /**
   * set group specs if not already connected (online)
   * take snapshot with group info
   * contains (should) arena return info too
   * @param group 
   * @param role 
   */
  setGameContainerGroupConnect(group: IGroup, role: number) {
    let mp = this.gameContainer;
    console.log("set game container group connect");

    // console.log("check group: ", JSON.parse(JSON.stringify(group)));
    // console.log("check current group: ", JSON.parse(JSON.stringify(mp.currentGroup)));

    // sync states (quick fix)
    // if (group != null && mp.currentGroup != null) {
    //   mp.currentGroup.online = group.online;
    // }

    // check mp online (reset state otherwise)
    if (!mp.online) {
      mp.online = true;
      mp.currentGroupRole = role;
      mp.currentGroup = group;
      mp.playerId = GeneralCache.userId;
      console.log("set game container group connect: ", mp);
      this.gameContainerPrev = DeepCopy.deepcopy(mp);
    }
  }

  setArenaLocked(locked: boolean) {
    let mp = this.gameContainer;
    mp.arenaIsOpen = locked;
  }

  setGameContainerUpdateGroupScope(group: IGroup, groupMemberStatus: IGroupMember[], updateGroupMemberStatus: boolean) {
    this.gameContainer.currentGroup = group;
    this.gameContainerPrev.currentGroup = group;
    if (updateGroupMemberStatus) {
      this.gameContainer.currentMemberStatus = groupMemberStatus;
      this.gameContainerPrev.currentMemberStatus = groupMemberStatus;
    }
    console.log("set game container group scope: ", this.gameContainer);
  }

  setGameContainerArena(arena: ILeplaceTreasure) {
    this.gameContainer.currentArenaItem = arena;
    this.gameContainerPrev.currentArenaItem = arena;
    console.log("set game container arena: ", this.gameContainer);
  }

  setGameContainerOnline(online: boolean) {
    this.gameContainer.online = online;
    this.gameContainerPrev.online = online;
  }

  /**
   * reset previous connected group
   */
  setPrevGameContainer() {
    console.log("set prev game container");
    this.gameContainer = DeepCopy.deepcopy(this.gameContainerPrev);
  }

  getGameContainer() {
    // console.log("get game container: ", this.gameContainer);
    return this.gameContainer;
  }

  isOnline() {
    return this.gameContainer && this.gameContainer.online;
  }

  getGameContainerSnapshot() {
    return DeepCopy.deepcopy(this.gameContainer);
  }

  /**
   * connect to game session
   */
  connect() {
    this.connected = true;
    this.setGameContainerOnline(true);
    console.log("mp game interface connected with game container: ", this.gameContainer);
  }

  /**
   * disconnect from game session
   * reset connected flag
   */
  disconnect() {
    console.log("mp game interface request disconnect")
    this.connected = false;
    this.setGameContainerOnline(false);
    // reset wait handles
    this.subscription = ResourceManager.clearSubObj(this.subscription);
  }

  /**
  * disconnect from game session
  * reset game container
  */
  dispose() {
    console.log("mp game interface request dispose")
    this.connected = false;
    // reset current game container
    this.gameContainer = DeepCopy.deepcopy(this.gameContainerInit);
    // restore context data (e.g. arena)
    this.gameContainer.currentArenaItem = this.gameContainerPrev.currentArenaItem;
    this.gameContainer.arenaIsOpen = this.gameContainerPrev.arenaIsOpen;
    // previous game container is not removed so that it can be restored
    // reset wait handles
    this.subscription = ResourceManager.clearSubObj(this.subscription);
  }

  isConnected() {
    return this.connected;
  }

  formatLeaderboard(elb: IEventLeaderboard, game: IMPGameResults) {
    elb.leaderboard.description = "<p>Team event finished</p><p>Total duration: " + TimeUtils.formatTimeFromSeconds(game.duration, true) + "</p>";
  }

  isLeader(): boolean {
    if (!this.connected) {
      return false;
    }
    if (!this.gameContainer) {
      return false;
    }
    return this.gameContainer.currentGroupRole === EGroupRole.leader;
  }

  /**
   * combine user static data
   * merge results
   * handle dnf users, sort by name
   * @param groupMembers 
   * @param gameResults 
   */
  combineUserStaticData(groupMembers: IGroupMember[], gameResults: IMPGameResultPlayer[]) {

    // add game results to group member data
    for (let i = 0; i < groupMembers.length; i++) {
      let gm: IGroupMember = groupMembers[i];
      // get associated game results
      for (let j = 0; j < gameResults.length; j++) {
        let gmd: IMPGameResultPlayer = gameResults[j];
        // add game results
        if (gm.userId === gmd.playerId) {
          let upd: IUserPublicData = gm.user;
          upd.cachedScore = gmd.score;
          upd.rank = gmd.position;
          upd.activityStats = Object.assign({}, gmd.activityDetails);
          upd.gameFinishedVerdict = gmd.verdict;
          break;
        }
      }
    }

    // apply group member sorting based on rank (rank is already sorted on the server)
    let groupMembersSorted: IGroupMember[] = groupMembers.sort((a, b) => {
      if (a.user.rank > b.user.rank) {
        return 1;
      }
      if (a.user.rank < b.user.rank) {
        return -1;
      }
      return 0;
    });

    return groupMembersSorted;
  }

  /**
   * show mp session results, leaderboard
   * @param game 
   */
  showResults(group: IGroup, game: IMPGameResults) {
    let promise = new Promise((resolve) => {
      if (!game) {
        resolve(false);
        return;
      }
      let elb: IEventLeaderboard = null;
      let groupMembers: IGroupMember[] = group.members;

      elb = {
        id: null,
        eventId: null,
        leaderboardId: null,
        timestamp: null,
        leaderboard: {
          id: null,
          name: null,
          description: null,
          timestamp: null,
          players: []
        }
      };

      this.formatLeaderboard(elb, game);
      groupMembers = this.combineUserStaticData(groupMembers, game.items);
      elb.leaderboard.players = groupMembers.map(gm => gm.user);

      let params: ILeaderboardNavParams = {
        reload: false,
        scope: ELeaderboardScope.event,
        lb: elb,
        mpCard: true,
        eventListNavParams: null
      };

      console.log("showing mp leaderboard: ", elb);

      this.uiext.showCustomModal(null, LeaderboardPage, {
        view: {
          fullScreen: true,
          transparent: false,
          large: true,
          addToStack: false,
          frame: false
        },
        params: params
      }).then(() => {
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        resolve(false);
      });
    });
    return promise;
  }

  /**
   * communicate the challenge details to other players
   * @param item 
   */
  dispatchChallengeToMP(item: ILeplaceTreasure) {
    if (!this.connected) {
      console.error("not connected");
      return;
    }

    let params: IMPMessageDataChallenge = {
      challengeId: item.id,
      activityCode: item.activity ? item.activity.code : null
    };

    console.log("dispatch challenge to mp: ", params);

    switch (this.gameContainer.currentGroupRole) {
      case EGroupRole.member:
        this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.confirmChallenge, params);
        break;
      case EGroupRole.leader:
        this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.selectChallenge, params);
        break;
    }
  }

  dispatchCoinSpecsToMP(coinPositions: IPrepareCoinSpecs[]) {
    console.log("dispatch coin specs to mp");
    switch (this.gameContainer.currentGroupRole) {
      case EGroupRole.leader:
        this.mpManager.dispatchEventMux(EMPEventSource.message, EMPMessageCodes.leaderBroadcastCoinSpecs, coinPositions);
        break;
    }
  }

  /**
   * send finished challenge with activity stats
   * include score in activity result param
   * @param res 
   */
  dispatchChallengeCompleteToMP(item: ILeplaceTreasure, res: IActivityResultCore, _activityParams: IActivityParam[]) {
    if (!this.connected) {
      console.error("not connected");
      return;
    }

    let asc: IActivityStatsContainer = res.activityStats;
    let activityStats: IGenericActivityStats = asc ? asc.stats : null;

    console.log("dispatch challenge exit, stats: ", activityStats);

    let mdas: IMPMessageDataChallengeStats = {
      challengeId: item.id,
      activityCode: item.activity ? item.activity.code : null,
      stats: null
    };

    mdas.stats = activityStats;
    this.dispatchChallengeCompleteToMPCore(res.status, mdas);
  }


  dispatchMessageToMP(code: number, data: any, ext: boolean) {
    if (!this.connected && !ext) {
      console.error("not connected");
      return;
    }
    this.mpManager.dispatchMessageNoAction(code, data);
  }

  dispatchSyncStoryMapToMP() {
    console.log("dispatch mp > sync story map");
    this.dispatchMessageToMP(EMPMessageCodes.storyMapSync, null, false);
  }

  dispatchSyncStoryProgressToMP() {
    console.log("dispatch mp > sync story progress");
    this.dispatchMessageToMP(EMPMessageCodes.storyProgressSync, null, false);
  }

  dispatchStoryFinishedToMP() {
    console.log("dispatch mp > story finished");
    this.dispatchMessageToMP(EMPMessageCodes.storyFinished, null, false);
  }

  dispatchClearStoryProgressToMP() {
    console.log("dispatch mp > clear story progress");
    this.dispatchMessageToMP(EMPMessageCodes.clearStoryProgress, null, true);
  }

  /**
   * used to sync progress between group members
   * @param status 
   */
  dispatchStoryLocationCompleteToMP(status: number) {
    switch (status) {
      case ECheckActivityResult.done:
        console.log("dispatch mp > story location complete");
        this.dispatchMessageToMP(EMPMessageCodes.storyLocationComplete, null, false);
        break;
      case ECheckActivityResult.failed:

        break;
      case ECheckActivityResult.skipped:

        break;
    }
  }

  /**
   * send finished challenge with activity stats
   * @param status general status
   * @param activityStats 
   */
  dispatchChallengeCompleteToMPCore(status: number, activityStats: IMPMessageDataChallengeStats) {
    console.log("dispatch challenge complete to mp: ", activityStats);
    switch (status) {
      case ECheckActivityResult.done:
        this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.finishChallenge, activityStats);
        break;
      case ECheckActivityResult.failed:
        this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.failChallenge, activityStats);
        break;
      case ECheckActivityResult.skipped:
        this.dispatchStopChallengeToMP();
        break;
    }
  }

  dispatchStopChallengeToMP() {
    if (!this.connected) {
      console.error("not connected");
      return;
    }
    this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.stopChallenge, null);
  }

  /**
   * reject the proposed challenge, the host has to select another one
   */
  dispatchRejectChallengeToMP() {
    if (!this.connected) {
      console.error("not connected");
      return;
    }
    this.mpManager.dispatchMessageNoAction(EMPMessageCodes.memberRejectedChallenge, null);
  }

  /**
   * listen for input, one time
   * returns when an input is received
   * @param expectedStateCodes the message codes that will trigger resolve
   */
  private watchGameStateTransition(expectedStateCodes: number[]): Promise<IMPEventContainer> {
    let promise: Promise<IMPEventContainer> = new Promise((resolve, reject) => {
      console.log("watch game state transition: ", expectedStateCodes);
      if (this.subscription.gameState !== null) {
        let err: Error = new Error("input listener [state] already enabled");
        console.error(err);
        return;
      }

      this.subscription.gameState = this.mpManager.watchStateMachine().subscribe((state: number) => {
        console.log("watch state transition detected: ", state);
        if (expectedStateCodes.indexOf(state) !== -1) {
          this.clearStateListener();
          let event: IMPEventContainer = {
            code: state,
            data: this.mpManager.getCurrentStateParams()
          };
          resolve(event);
        }
      }, (err: Error) => {
        console.error(err);
        reject(err);
      });
    });
    return promise;
  }

  /**
   * watch raw message received
   * @param expectedMessageCodes 
   */
  private watchMessageReceived(expectedMessageCodes: number[]): Promise<IMPEventContainer> {
    let promise: Promise<IMPEventContainer> = new Promise((resolve, reject) => {
      console.log("watch message received: ", expectedMessageCodes);

      if (this.subscription.messageReceived !== null) {
        let err: Error = new Error("input listener [message] already enabled");
        console.error(err);
        reject(err);
        return;
      }

      this.subscription.messageReceived = this.mpManager.watchMessageRx().subscribe((message: IMPMessageDB) => {
        if (message != null) {
          console.log("watch message received: ", message);
          if (expectedMessageCodes.indexOf(message.type) !== -1) {
            this.clearMessageListener();
            let event: IMPEventContainer = {
              code: message.type,
              data: message.data
            };
            resolve(event);
          }
        }
      }, (err: Error) => {
        console.error(err);
        reject(err);
      });
    });
    return promise;
  }

  /**
   * watch virtual state transition event
   * @param virtualEventCodes 
   */
  private watchVirtualGroupStateTransition(virtualEventCodes: number[]): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      console.log("watch virtual group state transition: ", virtualEventCodes);

      if (this.subscription.virtualState !== null) {
        let err: Error = new Error("input listener [virtual state] already enabled");
        console.error(err);
        reject(err);
        return;
      }

      let virtualEventFullCodes: number[] = virtualEventCodes.map(vc => MPEncoding.encodeEvent(EMPEventSource.virtualGroupState, vc, null).code);
      this.subscription.virtualState = this.mpManager.watchEventMux().subscribe((gs: IMPEventContainer) => {
        if (gs != null) {
          console.log("watch virtual group state received: ", gs);
          if (virtualEventFullCodes.indexOf(gs.code) !== -1) {
            this.clearVirtualStateListener();
            resolve(true);
          }
        }
      }, (err: Error) => {
        console.error(err);
        reject(err);
      });
    });
    return promise;
  }

  private clearStateListener() {
    this.subscription.gameState = ResourceManager.clearSub(this.subscription.gameState);
  }

  private clearMessageListener() {
    this.subscription.messageReceived = ResourceManager.clearSub(this.subscription.messageReceived);
  }

  private clearVirtualStateListener() {
    this.subscription.virtualState = ResourceManager.clearSub(this.subscription.virtualState);
  }

  private checkSyncWaitApply() {
    if (this.mode !== EMPGameMode.worldMapChallenge) {
      return false;
    }
    return true;
  }

  /**
   * wait for message from leader
   * @param code 
   */
  waitForLeaderCoordsSync(code: number): Promise<any> {
    let promise: Promise<any> = new Promise(async (resolve) => {
      if (!this.connected) {
        // single player, nop
        resolve(null);
        return;
      }

      if (!this.checkSyncWaitApply()) {
        resolve(null);
        return;
      }

      if (this.gameContainer.currentGroupRole === EGroupRole.leader) {
        resolve(null);
        return;
      }

      let loadingMessage: string = "Waiting for coords sync...";
      await this.uiext.showLoadingV2Queue(loadingMessage);
      this.messageQueueHandler.prepare(loadingMessage, true, EQueueMessageCode.warn);

      this.clearMessageListener();

      this.watchMessageReceived([code]).then(async (event: IMPEventContainer) => {
        console.log("required message detected");
        await this.uiext.dismissLoadingV2();
        resolve(event.data);
      }).catch(async () => {
        await this.uiext.dismissLoadingV2();
        this.uiext.showAlert(Messages.msg.mpError.after.msg, Messages.msg.mpError.after.sub, 1, null).then(() => {
          resolve(null);
        }).catch(() => {
          resolve(null);
        });
      });
    });
    return promise;
  }

  /**
   * member wait for coin specs
   * leader does not wait (resolve null)
   */
  waitForLeaderBroadcastCoinSpecs(): Promise<ICoinSpecsMpSyncData> {
    return this.waitForLeaderCoordsSync(EMPMessageCodes.leaderBroadcastCoinSpecs);
  }

  /**
  * member wait for find specs
  * leader does not wait (resolve null)
  */
  waitForLeaderBroadcastFindSpecs(): Promise<IFindSpecsMpSyncData> {
    return this.waitForLeaderCoordsSync(EMPMessageCodes.leaderBroadcastFindSpecs);
  }

  broadcastCoinSpecs(cs: ICoinSpecsMpSyncData) {
    this.dispatchMessageToMP(EMPMessageCodes.leaderBroadcastCoinSpecs, cs, false)
  }

  broadcastFindSpecs(fs: IFindSpecsMpSyncData) {
    this.dispatchMessageToMP(EMPMessageCodes.leaderBroadcastFindSpecs, fs, false);
  }

  /**
  * leader/member wait for all members to load coin specs and view description and start the activity at the same time
  */
  waitForSyncStart(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      if (!this.connected) {
        // single player, nop
        resolve(true);
        return;
      }

      if (!this.checkSyncWaitApply()) {
        resolve(true);
        return;
      }

      // test
      // resolve(true);
      // return;

      let loadingMessage: string = "Waiting for sync start...";
      await this.uiext.showLoadingV2Queue(loadingMessage);
      this.messageQueueHandler.prepare(loadingMessage, true, EQueueMessageCode.warn);

      this.watchVirtualGroupStateTransition([EMPVirtualGroupCodes.allMembersSyncStart]).then(async () => {
        console.log("required message detected");
        await this.uiext.dismissLoadingV2();
        resolve(true);
      }).catch(async () => {
        await this.uiext.dismissLoadingV2();
        this.uiext.showAlert(Messages.msg.mpError.after.msg, Messages.msg.mpError.after.sub, 1, null).then(() => {
          resolve(false);
        }).catch(() => {
          resolve(false);
        });
      });
    });
    return promise;
  }

  broadcastSyncStart() {
    console.log("broadcast sync start");
    if (this.gameContainer.currentGroupRole === EGroupRole.leader) {
      this.dispatchMessageToMP(EMPMessageCodes.leaderSyncStart, null, false);
    } else {
      this.dispatchMessageToMP(EMPMessageCodes.memberSyncStart, null, false);
    }
  }

  /**
   * after the leader selects the challenge
   * wait for the others to begin the challenge
   * this is reflected into the mp game state (challenge ready)
   * so it makes sense to listen on the state
   * 
   * resolve only
   */
  waitForChallengeReadyToStart(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      if (!this.connected) {
        // single player, nop
        resolve(true);
        return;
      }

      if (!this.checkSyncWaitApply()) {
        resolve(true);
        return;
      }

      let loadingMessage: string;
      let checkState: number;
      let resetState: number;

      switch (this.gameContainer.currentGroupRole) {
        case EGroupRole.member:
          loadingMessage = "Waiting for host...";
          checkState = EMemberStates.CHALLENGE_IN_PROGRESS;
          resetState = EMemberStates.GO;
          break;
        case EGroupRole.leader:
          loadingMessage = "Waiting for others...";
          checkState = ELeaderStates.CHALLENGE_IN_PROGRESS;
          resetState = ELeaderStates.GO;
          break;
        default:
          console.warn("unknown group role");
          resolve(false);
          return;
      }

      await this.uiext.showLoadingV2Queue(loadingMessage);
      this.messageQueueHandler.prepare(loadingMessage, true, EQueueMessageCode.warn);

      let states: number[] = [checkState, resetState];
      console.log("waiting for state transition: ", states);

      this.watchGameStateTransition(states).then(async (event: IMPEventContainer) => {
        console.log("required state transition detected");
        await this.uiext.dismissLoadingV2();
        if (event.code === resetState) {
          resolve(false);
        } else {
          resolve(true);
        }
      }).catch(async () => {
        await this.uiext.dismissLoadingV2();
        this.uiext.showAlert(Messages.msg.mpError.after.msg, Messages.msg.mpError.after.sub, 1, null).then(() => {
          resolve(false);
        }).catch(() => {
          resolve(false);
        });
      });

    });
    return promise;
  }


  /**
   * wait for others to finish or reject the challenge
   * handles loading popup
   */
  waitForOthersToFinish(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve) => {
      if (!this.connected) {
        // single player, nop
        resolve(true);
        return;
      }

      if (!this.checkSyncWaitApply()) {
        resolve(true);
        return;
      }

      let loadingMessage: string;
      let checkState: number[];
      let resetState: number[];
      switch (this.gameContainer.currentGroupRole) {
        case EGroupRole.member:
          loadingMessage = "Waiting for others to finish...";
          checkState = [EMemberStates.ENDGAME, EMemberStates.WAIT_REQUEST_STATS];
          resetState = [EMemberStates.GO];
          break;
        case EGroupRole.leader:
          loadingMessage = "Waiting for others to finish...";
          checkState = [ELeaderStates.ENDGAME, ELeaderStates.WAIT_STATS_DISPATCH];
          resetState = [ELeaderStates.GO];
          break;
      }

      await this.uiext.showLoadingV2Queue(loadingMessage);
      this.messageQueueHandler.prepare(loadingMessage, true, EQueueMessageCode.warn);

      let messages: number[] = [...checkState, ...resetState];
      console.log("waiting for messages: ", messages);

      this.watchGameStateTransition(messages).then(async (event: IMPEventContainer) => {
        console.log("required state transition detected");
        await this.uiext.dismissLoadingV2();
        if (resetState.indexOf(event.code) !== -1) {
          resolve(false);
        } else {
          resolve(true);
        }
      }).catch(async () => {
        await this.uiext.dismissLoadingV2();
        this.uiext.showAlert(Messages.msg.mpError.after.msg, Messages.msg.mpError.after.sub, 1, null).then(() => {
          resolve(false);
        }).catch(() => {
          resolve(false);
        });
      });
    });
    return promise;
  }
}
