<div>
  <!-- <div class="center-text center-highlight center-highlight-animation"
    [ngClass]="state ? 'center-highlight-alternate' : 'center-highlight-primary'" fxLayout="column"
    fxLayoutAlign="space-between center"> -->
  <div class="center-text center-highlight"
    [ngClass]="state ? 'center-highlight-alternate' : 'center-highlight-primary'" fxLayout="column"
    fxLayoutAlign="space-between center">
    <span class="logo text-color-primary">
      Leplace
    </span>
    <!-- <span class="sub-logo text-color-primary">
      World
    </span> -->
    <span class="info-text-m text-color-primary">
      {{plateSub}}
    </span>

    <!-- [ngClass]="!state ? 'text-color-primary' : 'text-color-primary-hidden'" -->
    <span *ngIf="plateFooter" class="info-text-xs bold text-color-overlay-format-s text-color-overlay-color-faded">
      {{plateFooter}}
    </span>

    <div class="padding-s">
      <!-- height="80" -->
      <!-- [ngClass]="state ? 'plate-img-highlight' : ''" -->
      <img [src]="logoSrc" class="plate-img">
    </div>

  </div>
</div>