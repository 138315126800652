import { IStory } from "../core/story";
import { IStoryRatingNavParams } from "../nav-params/story";

export interface IRatingView {
    rating: number;
    review: string;
}
export class RatingUtils {
    /**
     * get rating information for selected story
     * @param story 
     */
    static getStoryRatingParams(story: IStory) {
        let averageRating: number = 0;
        let description: string = "<p>Story rating</p>";
        let ratingCount: number = 0;
        if (!story) {

        } else {
            averageRating = story.rating;
            if (averageRating != null) {
                averageRating = (Math.round(averageRating * 10)) / 10;
            }
            description = "<p>Story rating</p>";

            if (averageRating != null) {
                description += averageRating + "/5";
                description += "<p>Rated by</p>";
                ratingCount = story.ratingCount;
                description += ratingCount + " users";
            } else {
                description += "not available";
            }
        }
        let params: IStoryRatingNavParams = {
            title: "Story rating & review",
            description,
            averageRating,
            rating: 5,
            count: ratingCount
        };
        return params;
    }

    /**
    * get rating information for selected story location
    */
    static getStoryLocationRatingParams() {
        let averageRating: number = 0;
        let description: string = "<p>Checkpoint rating</p>";
        let ratingCount: number = 0;

        let params: IStoryRatingNavParams = {
            title: "Checkpoint rating",
            description,
            averageRating,
            rating: 5,
            count: ratingCount
        };
        return params;
    }
}

