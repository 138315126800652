import { Component, ViewChild, OnInit, OnDestroy, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { EPhotos } from 'src/app/classes/def/app/icons';
import { MenuController, Platform, IonSlides } from '@ionic/angular';
import { ELocalAppDataKeys } from 'src/app/classes/def/app/storage-flags';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { StorageOpsService } from 'src/app/services/general/data/storage-ops';
import { ERouteDef } from 'src/app/app-utils';
import { Router } from '@angular/router';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { IGenericSlideData } from 'src/app/classes/def/views/slides';
import { ISliderOptions } from 'src/app/classes/def/dynamic/slides';
import { IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-tutorial',
  templateUrl: './tutorial.page.html',
  styleUrls: ['./tutorial.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TutorialPage implements OnInit, OnDestroy, AfterViewInit {
  showSkip = true;
  skipLabel: string = "Skip";

  @ViewChild('slides', { read: IonSlides, static: false }) slides: IonSlides;

  sliderOptions: ISliderOptions = {
    slidesPerView: 1,
    initialSlide: 0
  };

  slidesData: IGenericSlideData[] = [];
  slidesDataDisp: IGenericSlideData[] = [];

  showLeftButton: boolean = true;
  showRightButton: boolean = true;

  platform: IPlatformFlags;
  vs: IViewSpecs;

  constructor(
    public menu: MenuController,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public router: Router,
    public backButton: BackButtonService,
    public storageOps: StorageOpsService,
    public settingsProvider: SettingsManagerService
  ) {

    this.webView.ready().then(() => {
      this.backButton.replace(() => {

      });
    }).catch((err: Error) => { console.error(err); });

    this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
      if (loaded) {
        this.platform = SettingsManagerService.settings.platformFlags;
        this.initData(this.platform.IOS);
      }
    }, (err: Error) => {
      console.error(err);
    });

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  initData(_ios: boolean) {
    this.slidesData = [
      {
        title: "Leplace World",
        // tslint:disable-next-line:max-line-length
        desc: "<p>Leplace is about connecting people and places with the most interactive outdoor exploration games, creating location-based experiences anywhere in the world.</p>",
        img: EPhotos.logo,
        // img: "assets/img/ica-slidebox-img-1.png",
        customFont: false,
        enabled: true,
        start: false
      }, {
        title: "World Map",
        // tslint:disable-next-line:max-line-length
        desc: "<p>The world map is the open world mode where you can explore the environment, collecting treasures and doing outdoor activities.</p><p>You should have GPS location enabled and good mobile data connectivity for a smooth experience.</p>",
        img: EPhotos.tutorialWm,
        // img: "assets/img/ica-slidebox-img-1.png",
        customFont: false,
        enabled: true,
        start: false
      }, {
        title: "Storyline",
        // tslint:disable-next-line:max-line-length
        desc: "<p>The storyline features app guided tours based on local places around the city where you have to complete interactive challenges.</p><p>You should have GPS location enabled and good mobile data connectivity for a smooth experience.</p>",
        img: EPhotos.tutorialStory,
        // img: "assets/img/ica-slidebox-img-1.png",
        customFont: false,
        enabled: true,
        start: false
      },
      {
        title: "AR View",
        // tslint:disable-next-line:max-line-length
        desc: "<p>The augmented reality extension provides an alternative view for the game, based on the most advanced real-time camera and geolocation tracking.</p>",
        img: EPhotos.tutorialAR,
        // img: "assets/img/ica-slidebox-img-1.png",
        customFont: false,
        // enabled: GeneralCache.os != EOS.ios,
        enabled: true,
        start: false
      },
      // {
      //   title: "Game Loop",
      //   // tslint:disable-next-line:max-line-length
      //   desc: "",
      //   img: EPhotos.tutorialGameLoop,
      //   // img: "assets/img/ica-slidebox-img-1.png",
      //   customFont: false,
      //   enabled: true,
      //   start: false
      // },
      // {
      //   title: "Newsfeed",
      //   // tslint:disable-next-line:max-line-length
      //   desc: "<p>You can find more information about Leplace World in the Newsfeed. Here you can view the latest news and timeline. Detailed tutorials are available in the Global section</p>",
      //   img: ios ? EPhotos.tutorialNewsfeedIos : EPhotos.tutorialNewsfeed,
      //   // img: "assets/img/ica-slidebox-img-1.png",
      //   customFont: false,
      //   enabled: true,
      //   action: false
      // },
      {
        title: "Leplace",
        desc: "<p>We place stories</p>",
        customFont: true,
        img: EPhotos.logo,
        enabled: true,
        start: true,
        actionLabel: "Continue",
        footer: "<p>Remember to be aware of your surroundings</p>"
      }
    ];

    this.slidesDataDisp = this.slidesData.filter(sd => sd.enabled);
  }

  startApp() {
    this.storageOps.setStorageFlagNoAction({
      flag: ELocalAppDataKeys.tutorialSeenLocal,
      value: true
    });
    this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  onSlideChangeStart(_slider: IonSlides) {
    this.slides.isEnd().then((isEnd: boolean) => {
      this.showSkip = !isEnd;
      this.skipLabel = !isEnd ? "Skip" : "";
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngOnInit() {
    // the root left menu should be disabled on the tutorial page
    this.menu.enable(false).then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngAfterViewInit() {
    this.slides.update().then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngOnDestroy() {
    // enable the root left menu when leaving the tutorial page
    this.menu.enable(true).then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  nop() {

  }

  /**
 * Method that shows the next slide
 * update: next GROUP of slides
 */
  public slideNext(): void {
    if (!this.slides) {
      return;
    }
    console.log("slide next");
    this.slides.getActiveIndex().then((currentIndex: number) => {
      this.slides.length().then((slidesLength: number) => {
        let slideToIndex: number = currentIndex + this.sliderOptions.slidesPerView;
        if (slideToIndex > slidesLength - 1) {
          slideToIndex = slidesLength - 1;
        }
        this.slides.slideTo(slideToIndex).then(() => {

        }).catch((err: Error) => {
          console.error(err);
        });
      }).catch((err: Error) => {
        console.error(err);
      });

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * Method that shows the previous slide
   * update: previous GROUP of slides
   */
  public slidePrev(): void {
    if (!this.slides) {
      return;
    }
    console.log("slide prev");
    this.slides.getActiveIndex().then((currentIndex: number) => {
      let slideToIndex: number = currentIndex - this.sliderOptions.slidesPerView;
      if (slideToIndex < this.sliderOptions.slidesPerView - 1) {
        slideToIndex = 0;
      }
      this.slides.slideTo(slideToIndex).then(() => {

      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }
}

