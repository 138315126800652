<!-- [@showState]="showState" -->
<!-- <div *ngIf="data" class="flex-card item-card bg-container bg-container-fill" tappable [ngClass]="{'bg-fadein': photoLoaded, 'bg-no-filter-animate':!isLocked, 'bg-filter-2-animate':isLocked}"
    [ngStyle]="photoLoaded && {'background-image': 'url(' + data.photoUrl + ')'}" [photoBg]="{fade:true,fadeClass:'bg-container-fade',url:data.photoUrl,loaded:photoLoaded" (click)="showDescription()">
    <img *ngIf="data.photoUrl" [src]="data.photoUrl" (load)="photoLoaded = true" hidden> -->


<div *ngIf="small" class="flex-card item-card-small bg-container bg-container-fill item-border-radius" tappable
  [ngClass]="{'bg-no-filter-animate':!isLocked, 'bg-filter-2-animate':isLocked}"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data ? data.photoUrl: null}" (click)="showDescription()">
</div>

<div *ngIf="!small" class="flex-card bg-container bg-container-fill item-card item-border-radius" tappable
  [ngClass]="{'bg-no-filter-animate':!isLocked, 'bg-filter-2-animate':isLocked}"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data ? data.photoUrl:null}" (click)="showDescription()">
  <div class="content" fxLayout="column">
    <div *ngIf="data && isLoading">
      <div class="progress-spinner-center">
        <mat-progress-spinner [diameter]="40" color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </div>
    </div>
    <ion-grid class="full-w grid-center-absolute" *ngIf="data && !data.loading && !isLoading">
      <ion-row class="padding-null">
        <ion-col size="2">
          <!-- <div *ngIf="newItem" class="ion-float-right leplace-icon-s icon-color-alternate">
                <icon-wrapper icon="star"></icon-wrapper>
            </div>  -->
        </ion-col>
        <ion-col size="8" class="self-center" *ngIf="isConsumable()">
          <div *ngIf="checkShowAmount()">
            <span class="info-text-s bold text-color-overlay-s">{{inventoryAmount}}</span>
          </div>
          <div *ngIf="checkLocked()">
            <div class="leplace-icon icon-color-primary">
              <icon-wrapper [icon]="appIconsStandard.locked"></icon-wrapper>
            </div>
          </div>
        </ion-col>
        <ion-col size="8" class="self-center" *ngIf="!isConsumable()">
          <div *ngIf="!checkUnlockedNonConsumable()">
            <div class="leplace-icon icon-color-primary">
              <icon-wrapper [icon]="appIconsStandard.locked"></icon-wrapper>
            </div>
          </div>
          <div *ngIf="checkUnlockedNonConsumable()">
            <div class="leplace-icon icon-color-accent">
              <icon-wrapper [icon]="appIconsStandard.unlocked"></icon-wrapper>
            </div>
          </div>
        </ion-col>
        <ion-col size="2">
          <div *ngIf="checkValid()" class="ion-float-right leplace-icon-m icon-color-accent">
            <icon-wrapper [icon]="appIcons.check" [custom]="true"></icon-wrapper>
          </div>
        </ion-col>
      </ion-row>
      <ion-row>
        <!-- <ion-col size="12">
          <span class="info-text-xs bold text-color-overlay-s">{{itemName}}</span>
        </ion-col> -->
        <ion-col size="12" class="text-div-container">
          <div class="info-text-xs bold text-color-overlay-s" [innerHtml]="itemName"></div>
        </ion-col>
      </ion-row>

      <ion-row class="padding-null">
        <!-- <ion-col size="12" class="self-center item-level"> -->
        <ion-col size="12">
          <div>
            <span *ngIf="data.level" class="info-text-xs bold text-color-overlay-s">Level
              {{data.level}}</span>
            <span *ngIf="!data.level" class="info-text-xs bold text-color-overlay-s">General</span>
          </div>

        </ion-col>

      </ion-row>

      <ion-row *ngIf="showProgress">
        <ion-col size="12" class="progress-bar-alternate">
          <mat-progress-bar mode="determinate" class="progress-rounded" color="primary" [value]="progressPercent">
          </mat-progress-bar>
        </ion-col>
      </ion-row>


      <ion-row>
        <ion-col size="12">
          <div *ngIf="!data.itemIap">
            <span *ngIf="priceCoins" class="info-text-xs bold text-color-overlay-s">{{priceCoins}}</span>
          </div>
          <div *ngIf="data.itemIap">
            <span *ngIf="priceIap" class="info-text-xs bold text-color-overlay-s">{{priceIap}}
            </span>
          </div>
        </ion-col>
      </ion-row>

      <!-- <ion-row>
        <ion-col size="12" >
            <div *ngIf="data.timeLeftString">
                <span class="info-text-xxs bold text-color-overlay-s">EXP {{data.timeLeftString}}</span>
            </div>
        </ion-col>
    </ion-row> -->


    </ion-grid>
  </div>
</div>