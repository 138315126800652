import { BehaviorSubject } from "rxjs";
import { ILatLng } from 'src/app/classes/def/map/coords';
import { Injectable } from "@angular/core";
import { IGeolocationResult } from "../../classes/def/map/map-data";
import { TimeoutService } from "../app/modules/timeout";
import { IAverageHeading } from "../../classes/def/core/move-monitor";
import { AnalyticsService } from "../general/apis/analytics";
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { StorageOpsService } from '../general/data/storage-ops';
import { UserDataService } from '../data/user';
import { MQTTService } from "../telemetry/mqtt.service";
import { EMQTTStatusKeys } from "../telemetry/def";


@Injectable({
    providedIn: 'root'
})
export class LocationMonitorService {

    observables = {
        unifiedLocation: null,
        locationTimeout: null
    };

    currentLocation: ILatLng;
    currentLocationUnified: ILatLng;

    manualOverride: boolean = false;

    averageHeading: IAverageHeading = {
        sum: 0,
        crt: 0,
        counter: 0,
        samples: 10,
        minSpeed: 1,
        ready: false
    };

    constructor(
        public timeoutMonitor: TimeoutService,
        public analytics: AnalyticsService,
        public storageOps: StorageOpsService,
        public userData: UserDataService,
        public mqttService: MQTTService
    ) {
        console.log("location monitor service created");
        this.observables = ResourceManager.initBsubObj(this.observables);
    }

    /**
     * get cached location (true location only)
     */
    getCachedLocation(): ILatLng {
        return this.currentLocation;
    }

    /**
     * get cached location (including manual location override)
     */
    getCachedLocationUnified(): ILatLng {
        return this.currentLocationUnified;
    }

    /**
     * subscribe to the internal location changes (GPS)
     */
    getUnifiedLocationObservable(): BehaviorSubject<IGeolocationResult> {
        return this.observables.unifiedLocation;
    }

    /**
     * real location timeout (GPS)
     */
    getLocationTimeoutObservable(): BehaviorSubject<number> {
        return this.observables.locationTimeout;
    }

    /**
     * real location timeout (GPS)
     */
    setLocationTimeoutObservable(event: number) {
        this.observables.locationTimeout.next(event);
    }

    resetUnifiedLocationObservable() {
        this.observables.unifiedLocation.next(null);
    }


    keyOverrideLocation(key: number) {
        let delta = 0.0001;
        let move = true;
        let loc = this.currentLocationUnified;

        if (loc) {
            switch (key) {
                case 119:
                    // w
                    loc.lat += delta;
                    break;
                case 115:
                    // s
                    loc.lat -= delta;
                    break;
                case 97:
                    // a
                    loc.lng -= delta;
                    break;
                case 100:
                    // d
                    loc.lng += delta;
                    break;
                default:
                    move = false;
                    break;
            }
            if (move) {
                // disable follow
                this.manualLocationOverrideLock(loc);
            }
        }
    }

    /**
     * switches to manual location override
     * e.g. move with keys
     * the internal location is disabled!
     */
    manualLocationOverrideLock(coords: ILatLng) {
        if (coords !== null) {
            console.log("location override: ", coords);
            this.currentLocation = coords;
            this.currentLocationUnified = coords;
            let fakeData: IGeolocationResult = {
                coords,
                timestamp: 0,
                elapsed: 0,
                heading: 0,
                accuracy: 100,
                altitude: 0,
                speed: 1
            };
            this.observables.unifiedLocation.next(fakeData);
            this.manualOverride = true;
        }
    }

    manualLocationRelease() {
        console.log("manual location lock released");
        this.manualOverride = false;
    }

    /**
     * set cached location
     * reset location fix timer
     * @param coords 
     */
    setRealLocationCache(coords: ILatLng) {
        if (coords !== null) {
            this.currentLocation = coords;
            if (!this.manualOverride) {
                this.currentLocationUnified = coords;
            }
        }
    }

    /**
     * get average heading for e.g. placing the coins in the direction of travel
     */
    getAverageHeading() {
        return this.averageHeading;
    }


    /**
     * set location from location manager, external event
     * calculate distance and speed if requested
     * @param data 
     */
    setLocationFromUnified(data: IGeolocationResult) {
        if (this.manualOverride) {
            return;
        }

        // console.log("set location from unified: ", data);
        if (this.observables.unifiedLocation !== null) {
            this.observables.unifiedLocation.next(data);
            if (data != null) {
                this.mqttService.updateStatus(EMQTTStatusKeys.location, data.coords);
            }
        }
    }
}
