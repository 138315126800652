import { EAlertButtonCodes } from "../def/app/ui";
import { EOS } from '../def/app/app';
import { GeneralCache } from "../app/general-cache";
import { Browser } from '@capacitor/browser';

export class Util {

    /**
     * open URL
     * OS based, using either system or InAppBrowser depending on the defined behavior
     * @param url 
     */
    static openURLAdaptive(url: string) {
        if (GeneralCache.checkPlatformOS() === EOS.android) {
            return Util.openURLExt(url);
        } else {
            return Util.openURL(url);
        }
    }

    static openURL(url: string) {
        if (GeneralCache.checkPlatformOS() !== EOS.browser) {
            Browser.open({ url: url });
        } else {
            /*
            _self: Opens in the Cordova WebView if the URL is in the white list, otherwise it opens in the InAppBrowser.
            _blank: Opens in the InAppBrowser.
            _system: Opens in the system's web browser.
            */
            // window.open(url, '_system', 'location=yes,footer=yes,closebuttoncaption=Done');
            window.open(url, '_blank', Util.getBrowserOptions());
        }
    }

    static openURLExt(url: string) {
        if (GeneralCache.checkPlatformOS() !== EOS.browser) {
            Browser.open({ url: url });
        } else {
            /*
            _self: Opens in the Cordova WebView if the URL is in the white list, otherwise it opens in the InAppBrowser.
            _blank: Opens in the InAppBrowser.
            _system: Opens in the system's web browser.
            */
            window.open(url, '_system', Util.getBrowserOptions());
        }
    }

    static getBrowserOptions(): string {
        let options: string = null;
        switch (GeneralCache.checkPlatformOS()) {
            case EOS.ios:
                options = 'location=yes,footer=yes,closebuttoncaption=Done';
                break;
            case EOS.android:
            default:
                options = 'location=yes,footer=no,closebuttoncaption=Done';
        }
        return options;
    }


    /**
     * generate link to open with google maps
     * using only google id
     * @param googleId 
     */
    static generateMapsLink(googleId: string, name: string = null) {
        if (name == null) {
            name = " ";
        }

        let prefix: string = "https://";

        if (GeneralCache.checkPlatformOS() === EOS.ios) {
            // prefix = "comgooglemapsurl://";
        }

        // https://developers.google.com/maps/documentation/urls/guide
        let locationUrl: string = prefix + "www.google.com/maps/search/?api=1&query=" +
            name +
            "&query_place_id=" +
            googleId;

        console.log("maps link: " + locationUrl);
        return locationUrl;
    }

    static checkImageLoadedEvent(event) {
        let imgWidth: number = 0;
        if (event && event.path && event.path.length > 0) {
            imgWidth = event.path[0].width;
        }
        if (imgWidth > 200) {
            return true;
        } else {
            return false;
        }
    }


    static formatPlaceType(gtype: string): string {
        if (!gtype) {
            return "";
        }
        let ptype: string = gtype.replace(/_/g, " ");
        return ptype;
    }


    static checkDuplicateUidArray(names: string[]) {
        const count = names =>
            names.reduce((a, b) =>
                Object.assign(a, { [b]: (a[b] || 0) + 1 }), {});

        const duplicates = dict =>
            Object.keys(dict).filter((a) => dict[a] > 1);

        // console.log(count(names)) // { Mike: 1, Matt: 1, Nancy: 2, Adam: 1, Jenny: 1, Carl: 1 }
        // console.log(duplicates(count(names))) // [ 'Nancy' ]
        return {
            count: count(names),
            dups: duplicates(count(names))
        };
    }

    static getAlertReturnStatus(initialPromise: () => Promise<number>): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            initialPromise().then((value: number) => {
                if (value === EAlertButtonCodes.ok) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(() => {
                resolve(false);
            });
        });
        return promise;
    }

    static getEnumMembers(myEnum) {
        return Object.keys(myEnum).map(key => myEnum[key]).filter(value => typeof value === 'string') as string[];
    }

}
