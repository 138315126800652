import { FacebookLoginResponse, Facebook } from '@ionic-native/facebook/ngx';
import { IFacebookProfileData, IGoogleProfileData, IAppleProfileData } from '../../../classes/def/social/profile';
import { Injectable } from '@angular/core';
import { ApiDef } from '../../../classes/app/api';
import { IPlatformFlags } from '../../../classes/def/app/platform';
import { SettingsManagerService } from '../settings-manager';
import { SignInWithApple, AppleSignInResponse, AppleSignInErrorResponse, ASAuthorizationAppleIDRequest, NSPersonNameComponents } from '@ionic-native/sign-in-with-apple/ngx';

// import {
//     SignInWithApple,
//     SignInWithAppleResponse,
//     SignInWithAppleOptions,
// } from '@capacitor-community/apple-sign-in';

// import { SocialAuthServiceConfig, SocialAuthService, SocialUser, GoogleLoginProvider, FacebookLoginProvider } from '@abacritt/angularx-social-login';

// // Firebase App (the core Firebase SDK) is always required and must be listed first
// import * as firebase from "firebase/app";

// // // If you enabled Analytics in your project, add the Firebase SDK for Analytics
// // import "firebase/analytics";

// // Add the Firebase products that you want to use
// import "firebase/auth";
// import "firebase/firestore";

import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { BackgroundModeWatchService, EBGMWatchId } from '../apis/background-mode-watch';
import { Subscription } from 'rxjs';
import { UiExtensionService } from '../ui/ui-extension';

import { GoogleAuth, User } from '@codetrix-studio/capacitor-google-auth';


export enum ESocialLoginProviders {
    native = 1,
    angularSocialLogin = 2
}

@Injectable({
    providedIn: 'root'
})
export class SocialAuthWrapperService {
    serverUrl: string;
    platformFlags: IPlatformFlags = {} as IPlatformFlags;
    platformObs;

    // config: SocialAuthServiceConfig;
    // socialAuthService: SocialAuthService;

    /**
     * social login provider
     */
    provider: number = ESocialLoginProviders.native;

    bgModeWatchSub: Subscription;
    googleLoginTimeout;

    constructor(
        public fb: Facebook,
        public signinWithApple: SignInWithApple,
        public settingsProvider: SettingsManagerService,
        public backgroundModeWatch: BackgroundModeWatchService,
        public uiext: UiExtensionService
    ) {
        console.log("social auth service created");

        this.platformObs = this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                if (GeneralCache.isWeb) {
                    this.provider = ESocialLoginProviders.angularSocialLogin;
                } else {
                    if (GeneralCache.os === EOS.ios) {
                        this.provider = ESocialLoginProviders.native;
                    } else {
                        this.provider = ESocialLoginProviders.native;
                    }
                }
                this.platformFlags = SettingsManagerService.settings.platformFlags;
                this.init();
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    init() {
        console.log("social auth config init");
        switch (this.provider) {
            case ESocialLoginProviders.native:
                GoogleAuth.initialize({
                    // clientId: this.platformFlags.IOS ? ApiDef.googleIOSClientId : ApiDef.googleWebClientId,
                    // scopes: ['profile', 'email'],
                    grantOfflineAccess: true
                });
                break;
            case ESocialLoginProviders.angularSocialLogin:
                // const fbLoginOptions = {
                //     scope: 'public_profile',
                //     return_scopes: true,
                //     enable_profile_selector: true
                // }; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

                // const googleLoginOptions: any = {
                //     scope: 'profile email'
                // };
                // // https://developers.google.com/api-client-library/javascript/reference/referencedocs#gapiauth2clientconfig

                // this.config = {
                //     providers: [
                //         {
                //             id: GoogleLoginProvider.PROVIDER_ID,
                //             provider: new GoogleLoginProvider(ApiDef.googleWebClientId, googleLoginOptions)
                //         },
                //         {
                //             id: FacebookLoginProvider.PROVIDER_ID,
                //             provider: new FacebookLoginProvider(ApiDef.facebookAppId, fbLoginOptions)
                //         }
                //     ]
                // };

                // this.socialAuthService = new SocialAuthService(this.config);
                break;
            default:
                break;
        }

    }

    setPlatform(platform: IPlatformFlags) {
        this.platformFlags = platform;
    }

    /**
     * sign in with facebook
     * https://developers.facebook.com/docs/facebook-login/multiple-providers#addingfb2
     */
    signInWithFacebookApp() {
        let promise = new Promise((resolve, reject) => {
            // should request permissions to use email first
            // this.fb.login(['public_profile', 'user_friends', 'email'])
            this.fb.login(['public_profile'])
                .then((res: FacebookLoginResponse) => {
                    console.log('Logged into Facebook!', res);
                    if (res.authResponse) {
                        resolve(res.authResponse.accessToken);
                    } else {
                        resolve(null);
                    }
                })
                .catch((err: Error) => {
                    console.log('Error logging into Facebook', err);
                    reject(err);
                });
        });
        return promise;
    }

    /**
     * log out from fb
     */
    facebookLogout() {
        let promise = new Promise((resolve, reject) => {
            this.getFacebookLoginStatus().then((res) => {
                if (res.status === "connected") {
                    this.fb.logout().then(() => {
                        resolve(true);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    reject(new Error("facebook login not found"));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * handle (now masked) error/crash via timeout after app resume
     * init when app is switched to background (google login opened)
     * start timeout when app is resumed from background (google login closed, may not resolve)
     * @param onResumedTimeout callback
     */
    watchGoogleSignInStatus(onResumedTimeout: () => any) {
        this.backgroundModeWatch.initBgmWatchTriggerOnResumed(EBGMWatchId.socialAuth, "Connecting to Google account..", onResumedTimeout, 10000);
    }

    /**
     * resolve, clear timeout
     */
    clearWatchGoogleSignInStatusResolve(): Promise<boolean> {
        return this.backgroundModeWatch.clearBgmWatchTrigger(EBGMWatchId.socialAuth, true);
    }

    /**
     * get google account info 
     */
    signInWithGoogle(): Promise<IGoogleProfileData> {
        let promise: Promise<IGoogleProfileData> = new Promise((resolve, reject) => {
            if (this.platformFlags.IOS) {
                console.log("using reversed client id for iOS");
            }

            console.log("sign in with google client id: ", ApiDef.googleWebClientId);

            switch (this.provider) {
                case ESocialLoginProviders.native:
                    this.watchGoogleSignInStatus(() => {
                        this.clearWatchGoogleSignInStatusResolve().then(() => {
                            reject(new Error("login timeout"));
                        });
                    });
                    GoogleAuth.signIn().then(async (user: User) => {
                        // console.log(user);
                        let googleData: IGoogleProfileData = {
                            name: user.name,
                            email: user.email,
                            // picture: user.imageUrl,
                            picture: null,
                            token: user.authentication.idToken,
                            id: user.id
                        };
                        await this.clearWatchGoogleSignInStatusResolve();
                        resolve(googleData);
                    }).catch(async (err: Error) => {
                        await this.clearWatchGoogleSignInStatusResolve();
                        reject(err);
                    });
                    break;
                case ESocialLoginProviders.angularSocialLogin:
                    // // this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID)
                    // // this.config.providers.get(GoogleLoginProvider.PROVIDER_ID).signIn
                    // this.socialAuthService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user: SocialUser) => {
                    //     // console.log(user);
                    //     let googleData: IGoogleProfileData = {
                    //         name: user.name,
                    //         email: user.email,
                    //         // picture: user.imageUrl,
                    //         picture: null,
                    //         token: user.idToken,
                    //         id: user.id
                    //     };
                    //     resolve(googleData);
                    // }).catch((err: Error) => {
                    //     reject(err);
                    // });
                    reject(new Error("Google Sign-In custom method not available in this version"));
                    break;                
                default:
                    reject(new Error("unknown provider"));
                    break;
            }
        });
        return promise;
    }

    /**
     * get apple account info
     */
    signInWithApple() {
        let promise = new Promise((resolve, reject) => {

            // let options: SignInWithAppleOptions = {
            //     clientId: 'com.leplace.global',
            //     redirectURI: 'https://www.yourfrontend.com/login',
            //     scopes: 'email name',
            //     state: '12345',
            //     nonce: 'nonce',
            // };

            // SignInWithApple.authorize(options).then((response: SignInWithAppleResponse) => {
            //     // Handle user information
            //     // Validate token with server and create new session
            //     if(response){
            //         let res = response.response;
            //         let name: string = res.givenName + " " + res.familyName;
            //         let appleData: IAppleProfileData = {
            //             name: name,
            //             email: res.email,
            //             picture: null,
            //             idToken: res.identityToken,
            //             authorizationCode: res.authorizationCode,
            //             orig: res
            //         };
            //         resolve(appleData);
            //     } else {
            //         reject(new Error("no data"));
            //         return;
            //     }             
            // }).catch((err) => {
            //     alert(err.code + ' ' + err.localizedDescription);
            //     console.error(err);
            //     reject(err);
            // });   


            this.signinWithApple.signin({
                requestedScopes: [
                    ASAuthorizationAppleIDRequest.ASAuthorizationScopeFullName,
                    ASAuthorizationAppleIDRequest.ASAuthorizationScopeEmail
                ]
            }).then((res: AppleSignInResponse) => {
                // https://developer.apple.com/documentation/signinwithapplerestapi/verifying_a_user
                // alert('Send token to apple for verification: ' + res.identityToken);
                // console.log(res);
                let name: NSPersonNameComponents = res.fullName;

                let appleData: IAppleProfileData = {
                    name: name ? (name.givenName + " " + name.familyName) : null,
                    email: res.email,
                    picture: null,
                    idToken: res.identityToken,
                    authorizationCode: res.authorizationCode,
                    orig: res
                };
                resolve(appleData);
            }).catch((err: AppleSignInErrorResponse) => {
                alert(err.code + ' ' + err.localizedDescription);
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }


    googleLogout() {
        let promise = new Promise((resolve, reject) => {
            GoogleAuth.signOut().then(() => {
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get login account info
     */
    getFacebookUserData() {
        let promise = new Promise((resolve, reject) => {
            this.fb.api('me?fields=id,name,email,first_name,user_birthday,user_friends,gender,picture.width(720).height(720).as(picture_large)', []).then((profile) => {
                let fbData: IFacebookProfileData = {
                    email: profile.email,
                    name: profile.first_name,
                    picture: profile.picture_large.data.url,
                    username: profile.name,
                    birthday: profile.user_friends,
                    gender: profile.gender
                };
                console.log("get facebook user data: ", profile);
                resolve(fbData);
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    getFacebookLoginStatus() {
        return this.fb.getLoginStatus();
    }
}
