import { Component, OnInit, OnDestroy, ViewEncapsulation, AfterViewInit, ViewChild } from '@angular/core';
import { ILeplaceReg } from 'src/app/classes/def/places/google';
import { IArenaNavParams } from 'src/app/classes/def/nav-params/activity';
import { IGroup, EGroupRole, EGroupModalResult, IGroupMember, EGroupType, EGroupContext, EGroupRoleName } from 'src/app/classes/def/mp/groups';
import { ModalController, Platform, IonSlides } from '@ionic/angular';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { EFeatureCode, IFeatureDef } from 'src/app/classes/def/app/app';
import { Messages } from 'src/app/classes/def/app/messages';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { IGenericResponse } from 'src/app/classes/def/requests/general';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IArenaReturnData } from 'src/app/classes/def/nav-params/arena';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { Util } from 'src/app/classes/general/util';
import { IGetPhotoOptions } from 'src/app/services/location/location-utils-def';
import { IPlacePhotoContainer } from 'src/app/classes/def/places/container';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { MPManagerService } from 'src/app/services/app/mp/mp-manager';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { PlacesDataService } from 'src/app/services/data/places';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { QRService } from 'src/app/services/general/apis/qr';
import { LocationUtilsWizard } from 'src/app/services/location/location-utils-wizard';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { INavBarItem } from 'src/app/classes/def/views/nav';
import { PremiumDataService } from 'src/app/services/data/premium';
import { OtherUtils } from 'src/app/services/utils/other-utils';
import { ISliderOptions } from 'src/app/classes/def/dynamic/slides';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { ISelectOptionsElement } from 'src/app/classes/def/app/settings';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';


/**
 * create/join options
 */
@Component({
  selector: 'app-mp-options',
  templateUrl: './mp-options.page.html',
  styleUrls: ['./mp-options.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MpOptionsPage implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('slides', { read: IonSlides, static: false }) slides: IonSlides;
  theme: string = "theme-light theme-light-bg";
  title: string = "Create/Join Team";
  infoHTML: string = "";
  shareMessage: string = "";
  place: ILeplaceReg = null;
  photoUrlLoaded: string = null;
  params: IArenaNavParams = null;

  joined: boolean = false;
  existingGroup: boolean = false;

  loaded: boolean = true;

  group: IGroup = {
    name: "",
    id: null,
    timestamp: null,
    photoUrl: null,
    alias: null,
    role: EGroupRole.leader,
    roleName: EGroupRoleName.leader,
    type: EGroupType.mp
  };

  chatOnly: boolean = false;

  appIcons = EAppIcons;

  np: INavParams = null;
  vs: IViewSpecs;

  sliderOptions: ISliderOptions = {
    slidesPerView: 1,
    initialSlide: 0
  };

  descriptionJoin: string = Messages.mp.joinGroupPrivate;
  descriptionCreate: string = Messages.mp.createGroupPrivateHost;

  selectedIndex: number = 0;
  categoryTabsGroupCount: number = 2;
  navBarItems: INavBarItem[] = [{
    name: "Join",
    value: 0
  }, {
    name: "Create",
    value: 1
  }];

  optionsList: ISelectOptionsElement[] = [
    {
      name: "Public",
      value: EGroupType.lobby
    },
    {
      name: "Private",
      value: EGroupType.mp
    }
  ];

  isCreate: boolean = false;

  multipleCategories: boolean = false;

  withPhoto: boolean = false;
  created: boolean = false;

  originalGroupName: string = "";
  originalGroupType: number = null

  createMessage: string = "Create";

  groupUpdated: boolean = false;
  groupCreated: boolean = false;

  innerClass: string = "view-frame-minimal";

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public mp: MPDataService,
    public analytics: AnalyticsService,
    public mpManager: MPManagerService,
    public resources: ResourcesCoreDataService,
    public placeProvider: PlacesDataService,
    public tutorials: TutorialsService,
    public nps: NavParamsService,
    public backButton: BackButtonService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public qrSecurity: QRService,
    public locationUtilsWizard: LocationUtilsWizard,
    public popupFeatures: PopupFeaturesService,
    public premiumProvider: PremiumDataService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, true);
  }

  isFilled(group: IGroup) {
    return !((group.alias == null) || (group.alias === ''));
  }

  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  ngOnInit() {
    this.analytics.trackView("mp-options");
    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.mpOptions, null, this.np);

      console.log("nav params: ", npInfo.params);

      let hasParams: boolean = npInfo.hasParams;
      // console.log(navParams.data);
      if (hasParams) {
        let np: INavParams = npInfo.params;
        this.params = np.params;
        this.vs = np.view;

        if (this.vs.frame) {
          this.innerClass = 'view-frame';
        } else {
          this.innerClass = 'view-frame-minimal';
        }

        this.place = this.params.place;
        this.formatPlace();

        if (this.params.isLobbyContext) {
          this.group.type = EGroupType.lobby;
        }

        if (this.params.group) {
          // view group mode
          this.group = this.params.group;
          // opened the view from group list, so it's already created
          this.joined = true;
          this.created = true;
          this.existingGroup = true;
          this.title = "View Team";
          this.setOriginalGroupSpecs();
        }
        this.evalGroupContext();
      }

      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back();
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });

      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  back() {
    this.dismiss(null);
  }

  evalGroupContext() {
    let isPublicContext: boolean = this.params.isLobbyContext;

    switch (this.group.type) {
      case EGroupType.mp:
        isPublicContext = false;
        break;
      case EGroupType.lobby:
        isPublicContext = true;
        break;
    }

    if (this.params.chatOnly) {
      this.chatOnly = true;
      this.descriptionJoin = Messages.mp.joinGroupChat;
      this.descriptionCreate = Messages.mp.createGroupChat;
    } else {
      if (isPublicContext) {
        if (this.joined) {
          // isMember
          this.descriptionJoin = Messages.mp.joinGroupPublicMember;
          this.descriptionCreate = Messages.mp.createGroupPublicHost;
        } else {
          this.descriptionJoin = Messages.mp.joinGroupPublic;
          this.descriptionCreate = Messages.mp.createGroupPublic;
        }
      } else {
        if (this.joined) {
          // isMember
          this.descriptionJoin = Messages.mp.joinGroupPrivateMember;
          this.descriptionCreate = Messages.mp.createGroupPrivateHost;
        } else {
          this.descriptionJoin = Messages.mp.joinGroupPrivate;
          this.descriptionCreate = Messages.mp.createGroupPrivate;
        }
      }
    }

    if (!this.created) {
      // create group mode
      this.getCostInfo();
    }
  }

  /**
   * request group join from the main server
   */
  async joinGroup() {
    this.loaded = false;
    let groupAlias: string = null;
    let groupId: number = null;
    try {
      if (!this.isFilled(this.group)) {
        // join using QR code
        groupAlias = await this.qrSecurity.scanQRResultString(false, "Join team");
        console.log("scan QR: " + groupAlias);
      } else {
        // group is selected
        // join using alias / group id
        groupAlias = this.group.alias;
        groupId = this.group.id;
        if (groupId != null) {
          groupAlias = null;
        }
      }
      let response: IGenericResponse = await this.mp.joinGroup(groupAlias, groupId);
      if (response.data) {
        let data: IGroupMember = response.data;
        this.group.id = data.groupId;
        this.joined = true;
        this.groupUpdated = true;
      }
      this.loaded = true;
      this.ok(false);
    } catch (err) {
      console.error(err);
      this.analytics.dispatchError(err, "mp");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
      this.loaded = true;
    }
  }


  leaveGroup() {
    this.uiext.showAlert(Messages.msg.leaveGroup.before.msg, Messages.msg.leaveGroup.before.sub, 2, null).then((res: number) => {
      switch (res) {
        case EAlertButtonCodes.ok:
          this.loaded = false;
          this.mp.leaveGroup(this.group.id).then((_response: IGenericResponse) => {
            this.loaded = true;
            this.groupUpdated = true;
            this.remove(false);
          }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "mp");
            this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
            this.loaded = true;
          });
          break;
        default:
          break;
      }
    });
  }

  /**
   * return to the main group view
   */
  ok(create: boolean) {
    if (create == null) {
      create = this.isCreate;
    }
    let returnData: IArenaReturnData = {
      status: null,
      group: this.group,
      groupUpdated: this.groupUpdated,
      groupCreated: this.groupCreated,
      role: create ? EGroupRole.leader : EGroupRole.member
    };
    returnData.status = EGroupModalResult.ok;
    this.dismiss(returnData);
  }


  cancel(create: boolean) {
    if (create == null) {
      create = this.isCreate;
    }
    let returnData: IArenaReturnData = {
      status: null,
      group: null,
      groupUpdated: this.groupUpdated,
      groupCreated: this.groupCreated,
      role: create ? EGroupRole.leader : EGroupRole.member
    };
    returnData.status = EGroupModalResult.cancel;
    this.dismiss(returnData);
  }

  /**
  * request group create from the main server
  */
  createGroup() {
    this.loaded = false;

    switch (this.params.context) {
      case EGroupContext.story:
        this.group.storyId = this.params.contextId;
        break;
      case EGroupContext.meetingPlace:
        this.group.meetingPlaceId = this.params.contextId;
        break;
    }

    this.mp.createGroup(this.group.name, this.group, this.withPhoto).then(async (response: IGenericResponse) => {
      if (response.data) {
        this.created = true;
        this.group = response.data;
        console.log("create group: ", this.group);
        this.groupUpdated = true;
        this.groupCreated = true;
        this.existingGroup = true;
        this.setOriginalGroupSpecs();
        await this.slides.lockSwipes(true);
      }
      this.loaded = true;
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "mp");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
      this.loaded = true;
    });
  }

  updateGroup() {
    this.loaded = false;
    this.mp.updateGroup(this.group.id, this.group.name, this.group, this.withPhoto).then((response: IGenericResponse) => {
      if (response.data) {
        this.created = true;
        this.group = response.data;
        this.setOriginalGroupSpecs();
      }
      this.loaded = true;
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "mp");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
      this.loaded = true;
    });
  }

  setOriginalGroupSpecs() {
    this.originalGroupName = this.group.name;
    this.originalGroupType = this.group.type;
  }

  deleteGroup() {
    this.uiext.showAlert(Messages.msg.deleteGroup.before.msg, Messages.msg.deleteGroup.before.sub, 2, null).then((res: number) => {
      switch (res) {
        case EAlertButtonCodes.ok:
          this.loaded = false;
          this.mp.deleteGroup(this.group.id).then((_response: IGenericResponse) => {
            this.loaded = true;
            this.remove(true);
          }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "mp");
            this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
            this.loaded = true;
          });
          break;
        default:
          break;
      }
    });
  }

  /**
  * remove the selected group
  */
  remove(create: boolean) {
    if (create == null) {
      create = this.isCreate;
    }
    let returnData: IArenaReturnData = {
      status: null,
      group: null,
      role: create ? EGroupRole.leader : EGroupRole.member
    };
    returnData.status = EGroupModalResult.remove;
    this.dismiss(returnData);
  }


  getCostInfo() {
    this.premiumProvider.checkFeaturePrice(this.params.isStoryline ? EFeatureCode.createGroupStoryline : EFeatureCode.createGroup, null, false).then((price: IFeatureDef) => {
      if (price) {
        this.createMessage = "Create (" + price.price + " LP)";
      }
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "mp");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
    });
  }


  /**
   * Share progress then dismiss
   */
  share() {
    this.loaded = false;
    this.shareProvider.share(this.shareMessage).then(() => {
      this.loaded = true;

    }).catch((err: Error) => {
      console.error(err);
    });
  }


  ngOnDestroy() {
    console.log("close modal");
    this.backButton.checkPop(this.vs);
  }

  clearWatch() {

  }

  /**
   * format place display
   */
  formatPlace() {
    if (this.place) {
      let mapsUrl: string = Util.generateMapsLink(this.place.place.googleId, this.place.place.name);
      this.place.aux = {
        mapsURL: mapsUrl,
        photoUrl: null,
        photoUrlCached: null,
        canvasLoaded: false,
        canvasURL: null
      };

      let options: IGetPhotoOptions = {
        noPlaceholder: true,
        redirect: true,
        cacheDisk: true,
        useGeneric: SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value
      };

      if (options.useGeneric) {
        this.placeProvider.getPlaceTemplatePhoto(this.place.place.type).then((p: IPlacePhotoContainer) => {
          this.place.aux.photoUrl = p.photoUrl;
        });
      } else {
        this.locationUtilsWizard.loadPlacePhotoWizard1(this.place, options);
      }
    }
  }

  options() {
    let actions: IPopoverActions = {};
    actions = {
      tutorial: {
        name: "Tutorial",
        code: 0,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 0:
          this.tutorials.showTutorialNoAction("Group Member Tutorial", ETutorialEntries.groupMemberTutorial, null, null, true);
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngAfterViewInit() {
    this.slides.update().then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  async slideChanged() {
    try {
      let activeIndex: number = await this.slides.getActiveIndex();
      this.selectedIndex = activeIndex;
      // 0 = join
      // 1 = create
      if (this.selectedIndex === 1) {
        this.isCreate = true;
      } else {
        this.isCreate = false;
      }
      this.clearInput();
    } catch (err) {
      console.error(err);
    }
  }

  clearInput() {
    this.group.name = "";
    this.group.alias = null;
  }

  selectIndexFromNavItem(item: INavBarItem) {
    this.slides.slideTo(item.value);
  }

  copyToClipboard(text: string) {
    OtherUtils.copyToClipboard(text);
    this.uiext.showToastNoAction("copied to clipboard", false);
  }

  isGroupUpdated() {
    if (this.group.name !== this.originalGroupName) {
      return true;
    }
    if (this.group.type !== this.originalGroupType) {
      return true;
    }
    return false;
  }

  onGroupTypeChanged() {
    console.log("group type changed: ", this.group.type);
    this.evalGroupContext();
  }
}
