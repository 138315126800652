import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { DirectivesModule } from '../directives/directives.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule } from '@angular/material/table';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { PipesModule } from '../pipes/pipes.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxStarsModule } from 'ngx-stars';
import { MatPaginatorModule } from '@angular/material/paginator';
import { CustomMaxlengthModule } from 'custom-maxlength';

const impex = [
  CommonModule,
  IonicModule,
  FormsModule,
  DirectivesModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatPaginatorModule,
  NgxChartsModule,
  PipesModule,
  FlexLayoutModule,
  NgxStarsModule,
  CustomMaxlengthModule
]

@NgModule({
  declarations: [],
  imports: [...impex],
  exports: [...impex]
})
export class ComponentsModule { }
