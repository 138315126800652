<modal-header-container [vs]="vs">
  <leplace-toolbar [menu]="false" [title]="title" [leftButton]="appIconsStandard.close" [leftIcon]="true"
    [customLeftIcon]="false" (leftClick)="cancel()" [rightButton]="appIconsStandard.check" [rightIcon]="true"
    [customRightIcon]="false" [rightButtonDisabled]="!enableAction" (rightClick)="ok()" [rounded]="vs.fullScreen">
  </leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div fxLayout="column">

    <!-- <div class="padding-s">
      <div [innerHTML]="description" class="info-text-m description-frame text-color-primary"></div>
    </div> -->

    <div class="scrollbar-y scroll-content-modal-1">
      <ion-textarea #input class="info-text-xl text-color-primary leplace-textarea theme-border-bottom" [rows]="10"
        [maxlength]="maxLength" [(ngModel)]="text" ngDefaultControl autocomplete="off" autocorrect="on"
        [placeholder]="description" (ionFocus)="setFocused()" (ionBlur)="setBlurred()" (input)="reviewChange($event)">
      </ion-textarea>
    </div>

    <!-- <div class="theme-border-top theme-border-bottom">
      <div class="scrollbar-y modal-large-height-inner">
        <ion-textarea #input class="info-text-m text-color-primary leplace-textarea" [rows]="10" [maxlength]="maxLength"
          [(ngModel)]="text" ngDefaultControl autocomplete="off" autocorrect="on" placeholder="Type a description"
          (ionFocus)="setFocused()" (ionBlur)="setBlurred()"></ion-textarea>
      </div>
    </div> -->


    <div fxFlex></div>

    <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col class="text-right">
            <span *ngIf="text" class="info-text-m text-color-primary">{{ maxLength - text.length }}
              characters left</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <ion-button class="action-button-fill button-color-accent" [disabled]="!enableAction" (click)="ok()">
              <icon-wrapper *ngIf="!okLabel" slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
              <icon-wrapper *ngIf="okLabel" slot="start" class="slot-icon-padding" [icon]="appIcons.check"
                [custom]="true"></icon-wrapper>
              <span *ngIf="okLabel" class="button-font-size-s">{{okLabel}}</span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</modal-container>

<ion-footer no-border class="chat-footer">
  <!-- <div class="padding-s" center >
      <ion-textarea #input class="info-text-m text-color-primary leplace-textarea" rows="3" maxLength="100" [(ngModel)]="text" ngDefaultControl autocomplete="off"
          autocorrect="on" placeholder="Type a description" (ionFocus)="setFocused()" (ionBlur)="setBlurred()"></ion-textarea>
  </div> -->
</ion-footer>