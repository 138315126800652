import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { WaitUtils } from '../utils/wait-utils';

/**
 * general app resource monitor
 */
@Injectable({
    providedIn: 'root'
})
export class ResourceMonitorDataService {

    watchLoader: BehaviorSubject<boolean>;
    loaded: boolean = false;

    constructor(

    ) {
        this.watchLoader = new BehaviorSubject(null);
    }

    setLoaded(loaded: boolean) {
        this.loaded = loaded;
        this.watchLoader.next(loaded);
    }

    getWatchLoader(): BehaviorSubject<boolean> {
        return this.watchLoader;
    }

    checkWatchLoader(): Promise<boolean> {
        if (this.loaded) {
            return Promise.resolve(true);
        }
        return WaitUtils.waitObsTimeout(this.getWatchLoader(), true, 10000);
    }

}
