
import { Injectable } from '@angular/core';
import { UiExtensionService } from '../../../general/ui/ui-extension';
import { AnalyticsService } from '../../../general/apis/analytics';
import { MiscDataService } from '../../../data/misc';
import { NewsfeedDataService } from '../../../data/newsfeed';
import { StoryDataService } from '../../../data/story';
import { ResourcesCoreDataService } from '../../../data/resources-core';
import { SettingsManagerService } from '../../../general/settings-manager';
import { IPlatformFlags } from '../../../../classes/def/app/platform';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';
import { INavParams } from '../../../../classes/def/nav-params/general';
import { UserStatsDataService } from '../../../data/user-stats';
import { UserDataService } from '../../../data/user';
import { AchievementsDataService } from '../../../data/achievements';
import { IInventoryNavParams, IInventoryReturnItem } from '../../../../classes/def/nav-params/inventory';
import { RewardModalsService } from './reward-modals';
import { InventoryHomePage } from 'src/app/pages/inventory/inventory-home/inventory-home.page';
import { EItemCategoryCodes } from 'src/app/classes/def/items/game-item';

@Injectable({
    providedIn: 'root'
})
export class GmapModalsService {

    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public uiext: UiExtensionService,
        public analytics: AnalyticsService,
        public misc: MiscDataService,
        public newsfeed: NewsfeedDataService,
        public dataProvider: StoryDataService,
        public resources: ResourcesCoreDataService,
        public settingsProvider: SettingsManagerService,
        public appVersion: AppVersion,
        public market: Market,
        public userStats: UserStatsDataService,
        public userData: UserDataService,
        public achievements: AchievementsDataService,
        public rewardModals: RewardModalsService
    ) {
        console.log("gmap modal service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    /**
     * wrapper
     * specialized for gmap
     */
    checkNewAchievementsCoreResolveOnly(): Promise<IInventoryReturnItem[]> {
        return new Promise((resolve) => {
            this.rewardModals.checkNewAchievementsCore(() => new Promise((resolve) => {
                this.goToInventoryGmap(false).then((res) => {
                    resolve(res);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(null);
                });
            })).then((_res: boolean) => {
                resolve(null);
            }).catch((err: Error) => {
                console.error(err);
                resolve(null);
            });
        });
    }


    /**
     * go to inventory
     * returns when the user leaves the inventory
     */
    goToInventoryGmap(checkMapChanged: boolean): Promise<IInventoryReturnItem[]> {
        let promise: Promise<IInventoryReturnItem[]> = new Promise((resolve, reject) => {
            let params: IInventoryNavParams = {
                closeOnItemUse: true,
                fixedCategory: false,
                fromMapOpened: true,
                wantedItemCategoryCode: checkMapChanged ? EItemCategoryCodes.maps : null,
                wantedOnActivate: checkMapChanged
            };

            let navParams: INavParams = {
                view: {
                    fullScreen: true,
                    transparent: false,
                    large: true,
                    addToStack: false,
                    frame: false
                },
                params: params
            };

            this.uiext.showCustomModal(null, InventoryHomePage, navParams).then((rets: IInventoryReturnItem[]) => {
                console.log("returned from inventory");
                resolve(rets);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * go to inventory view
     * on return check for activated items
     */
    goToInventoryGmapNoAction() {
        this.goToInventoryGmap(false).then(() => {

        }).catch(() => {

        });
    }
}
