import { Injectable } from "@angular/core";
import { ESmartZoomState, ESmartZoomTransitions } from 'src/app/classes/def/map/zoom';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ResourceManager } from 'src/app/classes/general/resource-manager';


interface IStateMachineData {
    state: number;
    observable: BehaviorSubject<any>;
    extObservable: BehaviorSubject<any>;
    subscription: Subscription;
    timeout: any;
    unlock: boolean;
}

interface IStateMachineDataObj {
    [key: string]: IStateMachineData;
    eventTreasuresZoom: IStateMachineData;
}

enum EStateMachine {
    eventTreasuresZoom = "eventTreasuresZoom"
}

@Injectable({
    providedIn: 'root'
})
export class SmartZoomService {

    smartZoomTransitions = {
        scannedFixedPlace: false,
        scannedRandomPlace: false,
        scanEventTreasures: false,
        scanPreloadStory: false
    };
    smartZoomState = ESmartZoomState.zoomInUser;

    stateMachines: IStateMachineDataObj = {
        eventTreasuresZoom: {
            state: ESmartZoomState.zoomInUser,
            observable: null,
            extObservable: null,
            subscription: null,
            timeout: null,
            unlock: false
        }
    }

    constructor(

    ) {
        console.log("smart zoom service created");
        let keys: string[] = Object.keys(this.stateMachines);
        for (let i = 0; i < keys.length; i++) {
            let s = this.stateMachines[keys[i]];
            s.observable = new BehaviorSubject(null);
            s.extObservable = new BehaviorSubject(null);
        }
        console.log("smart zoom service state machines initialized");
    }

    updateTransition(transition: number) {
        switch (transition) {
            case ESmartZoomTransitions.scanFixedPlace:
                this.smartZoomTransitions.scannedFixedPlace = true;
                break;
            case ESmartZoomTransitions.scanRandomPlace:
                this.smartZoomTransitions.scannedRandomPlace = true;
                break;
            case ESmartZoomTransitions.scanEventTreasures:
                this.smartZoomTransitions.scanEventTreasures = true;
                break;
            case ESmartZoomTransitions.scanPreloadStory:
                this.smartZoomTransitions.scanPreloadStory = true;
                break;
        }
    }

    /**
     * init state machine for treasures zoom sequence
     */
    startEventTreasuresZoom(auto: boolean) {
        this.setState(EStateMachine.eventTreasuresZoom, ESmartZoomState.init);
        if (auto) {
            this.startEventTreasuresStateMachine();
        } else {
            this.unlockStateTransition(EStateMachine.eventTreasuresZoom);
        }
        this.setState(EStateMachine.eventTreasuresZoom, ESmartZoomState.zoomToFitTreasures);
    }

    /**
     * deinit state machine for treasures zoom sequence
     */
    stopEventTreasuresZoom() {
        this.setState(EStateMachine.eventTreasuresZoom, ESmartZoomState.init);
        this.stopEventTreasuresStateMachine();
    }

    /**
     * watch treasures zoom sequence state
     */
    watchEventTreasuresZoomState() {
        return this.watchState(EStateMachine.eventTreasuresZoom);
    }

    /**
     * unlock transition to next state
     */
    unlockEventTreasuresZoomStateTransition() {
        return this.unlockStateTransition(EStateMachine.eventTreasuresZoom);
    }

    private watchState(key: string) {
        let s = this.stateMachines[key];
        if (!s) {
            return;
        }
        return s.extObservable;
    }

    /**
     * change state machine state
     */
    private setState(key: string, state: number) {
        let s = this.stateMachines[key];
        console.log("set state (" + key + "): " + state);
        setTimeout(() => {
            s.timeout = ResourceManager.clearTimeout(s.timeout);
            if (state !== s.state) {
                s.extObservable.next(state);
            }
            s.state = state;
            s.observable.next(state);
        }, 100);
    }

    startEventTreasuresStateMachine() {
        this.startStateMachine(EStateMachine.eventTreasuresZoom, () => {
            return this.runEventTreasuresZoomStateMachine();
        });
    }

    private startStateMachine(key: string, callback: () => any) {
        let s = this.stateMachines[key];
        if (!s) {
            return;
        }
        s.subscription = s.observable.subscribe(() => {
            // run state machine on state transition
            callback();
        }, (err: Error) => {
            console.error(err);
        });
    }

    stopEventTreasuresStateMachine() {
        this.stopStateMachine(EStateMachine.eventTreasuresZoom);
    }

    private stopStateMachine(key: string) {
        let s = this.stateMachines[key];
        if (!s) {
            return;
        }
        s.subscription = ResourceManager.clearSub(s.subscription);
    }

    private unlockStateTransition(key: string) {
        let s = this.stateMachines[key];
        if (!s) {
            return;
        }

        if (!s.unlock) {
            s.unlock = true;
            // refresh state
            this.setState(key, s.state);
        }
    }

    runEventTreasuresZoomStateMachine() {
        let s = this.stateMachines.eventTreasuresZoom;
        switch (s.state) {
            case ESmartZoomState.init:

                break;
            case ESmartZoomState.zoomInUser:
                if (s.unlock) {
                    s.timeout = setTimeout(() => {
                        this.setState(EStateMachine.eventTreasuresZoom, ESmartZoomState.zoomToFitTreasures);
                    }, 1000);
                }
                break;
            case ESmartZoomState.zoomToFitTreasures:
                if (s.unlock) {
                    s.timeout = setTimeout(() => {
                        this.setState(EStateMachine.eventTreasuresZoom, ESmartZoomState.zoomToFitUserAndTreasures);
                    }, 3000);
                }
                break;
            case ESmartZoomState.zoomToFitUserAndTreasures:
                if (s.unlock) {
                    s.timeout = setTimeout(() => {
                        this.setState(EStateMachine.eventTreasuresZoom, ESmartZoomState.init);
                    }, 2000);
                }
                break;
            default:

                break;
        }
    }

    /**
     * reset state machine to initial state
     * reset all transitions
     */
    resetState() {
        this.smartZoomState = ESmartZoomState.zoomInUser;
    }

    resetTransitions() {
        let keys = Object.keys(this.smartZoomTransitions);
        keys.forEach((key) => {
            this.smartZoomTransitions[key] = false;
        });
    }

    /**
     * on push button action
     */
    processStateTransition() {
        switch (this.smartZoomState) {
            // zoom in user
            case ESmartZoomState.zoomInUser:
                if (this.smartZoomTransitions.scanEventTreasures) {
                    this.smartZoomState = ESmartZoomState.zoomToFitTreasures;
                } else {
                    if (this.smartZoomTransitions.scannedFixedPlace || this.smartZoomTransitions.scannedRandomPlace) {
                        this.smartZoomState = ESmartZoomState.zoomInDestination;
                    } else {
                        this.smartZoomState = ESmartZoomState.zoomOutFixed;
                    }

                    if (this.smartZoomTransitions.scanPreloadStory) {
                        this.smartZoomState = ESmartZoomState.zoomToFitPreloadStory;
                    }
                }
                break;

            // world map
            case ESmartZoomState.zoomOutFixed:
                this.smartZoomState = ESmartZoomState.zoomInUser;
                break;

            // storyline
            case ESmartZoomState.zoomInDestination:
                this.smartZoomState = ESmartZoomState.zoomOutToFitDestination;
                break;
            case ESmartZoomState.zoomOutToFitDestination:
                if (this.smartZoomTransitions.scannedRandomPlace) {
                    this.smartZoomState = ESmartZoomState.zoomOutToFitAuxPlaces;
                } else {
                    this.smartZoomState = ESmartZoomState.zoomInUser;
                }
                break;
            case ESmartZoomState.zoomOutToFitAuxPlaces:
                this.smartZoomState = ESmartZoomState.zoomInUser;
                break;

            // preload stories
            case ESmartZoomState.zoomToFitPreloadStory:
                this.smartZoomState = ESmartZoomState.zoomInUser;
                break;

            // event treasures
            case ESmartZoomState.zoomToFitTreasures:
                this.smartZoomState = ESmartZoomState.zoomToFitUserAndTreasures;
                break;
            case ESmartZoomState.zoomToFitUserAndTreasures:
                this.smartZoomState = ESmartZoomState.zoomInUser;
                break;
            default:
                break;
        }
        return this.smartZoomState;
    }
}

