<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" leftButton="arrow-back" [leftIcon]="true" (leftClick)="return()"
    rightButton="settings" [rightIcon]="true" (rightClick)="presentPopover()" [title]="title"
    [loading]="adContext.loading">
  </leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <div class="view-frame theme-background">
    <!-- bg-filter-1 -->
    <div class="bg-container bg-container-fill" [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: photoUrl}">

      <div class="content">
        <div class="content-height">
          <div fxLayout="column" class="content-height">
            <!-- class="stick-top"  -->
            <!-- theme-border-bottom -->
            <div class="scrollbar-y inventory-height">
              <div *ngIf="placeSpecsAvailable">
                <ion-list class="list-padding-top-inner">
                  <ion-item class="list-item transparent-bg">
                    <place-card class="full-w" [place]="place" [location]="locationExt" [nofilter]="false" tappable>
                    </place-card>
                  </ion-item>
                </ion-list>
              </div>

              <div class="text-div-margin"
                [ngClass]="{'padding-top-xs': !placeSpecsAvailable, 'text-color-overlay': photoUrl}">
                <div>
                  <safe-html-renderer [unsafeHtmlContent]="adContext.infoHTML"
                    innerClass="info-text-m text-color-primary center-text description-frame"></safe-html-renderer>
                </div>

                <div *ngIf="opened && gameItemsContainers">
                  <dynamic-grid type="gameItem" [data]="gameItemsContainers" emptyMessage="" [rowItems]="gameItemsRow"
                    [blanks]="true"></dynamic-grid>
                </div>

                <div *ngIf="adContext.shareMessage && shareEnabledGlobal" class="padding-s" fxLayout="column">
                  <span class="info-text-m bold text-color-primary center-text">Share progress</span>
                  <span class="info-text-m text-color-primary center-text">{{adContext.shareMessage}}</span>
                </div>

                <div *ngIf="closeTimerValue > 0">
                  <span class="info-text-m bold text-color-primary center-text">This window will close in:
                    {{closeTimerValue}}
                  </span>
                </div>
              </div>

            </div>

            <div fxFlex></div>
            <div>
              <ion-grid class="full-w">

                <ion-row *ngIf="!editor">
                  <!-- player controls -->
                  <ion-col size="4">
                    <!-- cancel -->
                    <ion-button class="action-button-fill button-color-warn" (click)="cancel()"
                      [disabled]="!withDismissOptions">
                      <icon-wrapper slot="icon-only" [icon]="appIcons.cancel" [custom]="true"></icon-wrapper>
                    </ion-button>
                  </ion-col>
                  <ion-col size="4">
                    <!-- business features -->
                    <ion-button class="action-button-fill button-color-alternate" [disabled]="!enableBusinessFeatures"
                      (click)="openBusinessFeatures()">
                      <icon-wrapper slot="icon-only" [icon]="appIcons.qr" [custom]="true"></icon-wrapper>
                    </ion-button>
                  </ion-col>
                  <ion-col size="4">
                    <!-- continue -->
                    <blink-button [icon]="appIcons.check" [customIcon]="true" theme="accent" [setTimeout]="update"
                      (select)="continue()" [noBorder]="false">
                    </blink-button>
                  </ion-col>
                </ion-row>

                <ion-row *ngIf="editor">
                  <!-- editor controls -->
                  <ion-col size="4">
                    <!-- cancel, ad, create / different context -->
                    <div *ngIf="withDismissOptions">
                      <ion-button class="action-button-fill button-color-warn" (click)="cancel()">
                        <icon-wrapper slot="icon-only" [icon]="appIcons.cancel" [custom]="true"></icon-wrapper>
                      </ion-button>
                    </div>
                    <div *ngIf="!withDismissOptions">
                      <ion-button class="action-button-fill button-color-alternate" (click)="editContent()">
                        <icon-wrapper slot="icon-only" icon="create"></icon-wrapper>
                      </ion-button>
                    </div>
                  </ion-col>
                  <ion-col size="4">
                    <!-- remove treasure -->
                    <ion-button class="action-button-fill button-color-warn" [disabled]="!customTreasure"
                      (click)="removeFixedTreasure()">
                      <icon-wrapper slot="icon-only" [icon]="appIcons.cancel" [custom]="true"></icon-wrapper>
                    </ion-button>
                  </ion-col>
                  <ion-col size="4">
                    <!-- continue -->
                    <blink-button [icon]="appIcons.check" [customIcon]="true" theme="accent" [setTimeout]="update"
                      (select)="continue()" [noBorder]="false">
                    </blink-button>
                  </ion-col>
                </ion-row>

              </ion-grid>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>