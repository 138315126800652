import { Injectable } from '@angular/core';

import { UserStatsDataService } from '../data/user-stats';
import { IGameItem, IGameItemIAP } from '../../classes/def/items/game-item';
import { IPlatformFlags } from '../../classes/def/app/platform';
import { SettingsManagerService } from '../general/settings-manager';
import { InventoryDataService } from '../data/inventory';
import { WebviewUtilsService } from '../app/utils/webview-utils';
import { SyncService } from '../app/utils/sync';
import { UiExtensionService } from '../general/ui/ui-extension';
import { Subscription } from 'rxjs';
import { IAPUtils } from './iap-utils';
import { AnalyticsService } from '../general/apis/analytics';
import { IStory } from 'src/app/classes/def/core/story';
import { Purchases, CustomerInfo, LOG_LEVEL, PurchasesError, PurchasesStoreProduct, MakePurchaseResult, PRODUCT_TYPE, PRODUCT_CATEGORY } from '@revenuecat/purchases-capacitor/dist/esm';
import { ApiDef } from 'src/app/classes/app/api';
import { IAPStoreProduct, ICheckItemsList, IPurchaseInfo } from './iap-def';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { SleepUtils } from '../utils/sleep-utils';
import { Messages } from 'src/app/classes/def/app/messages';


@Injectable({
    providedIn: 'root'
})
export class IAPCoreService {
    enableIAP: boolean = false;
    platform: IPlatformFlags = {} as IPlatformFlags;
    customerInfo: CustomerInfo;

    cachedProducts: { [key: string]: IAPStoreProduct } = {};
    cachedProductsFlag: boolean = false;

    registeredProducts: string[] = [];
    loadProductDetailsOnRequest: boolean = true;
    registeredSubscribers: Subscription[] = [];
    registeredIapItems: IGameItemIAP[] = [];
    storeTimeout: number = 20000;

    constructor(
        public userStatsProvider: UserStatsDataService,
        public settingsProvider: SettingsManagerService,
        public inventory: InventoryDataService,
        public webviewUtils: WebviewUtilsService,
        public syncService: SyncService,
        public analytics: AnalyticsService,
        public uiext: UiExtensionService
    ) {
        console.log("iap service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.onPlatformLoaded();
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    syncPreloadedIAPData(item: IGameItem | IStory) {
        let cache: IAPStoreProduct[] = [];
        for (let key in this.cachedProducts) {
            cache.push(this.cachedProducts[key]);
        }
        IAPUtils.syncPreloadedIAPData(item, cache);
    }

    onPlatformLoaded() {
        this.platform = SettingsManagerService.settings.platformFlags;
        if (!this.platform.WEB) {
            this.enableIAP = true;
            this.initSDK();
        }
    }

    initSDK() {
        console.log("init IAP SDK");
        Purchases.setLogLevel({ level: LOG_LEVEL.DEBUG });
        Purchases.configure({
            apiKey: GeneralCache.os === EOS.ios ? ApiDef.revenueCatApiKeyIOS : ApiDef.revenueCatApiKey,
            usesStoreKit2IfAvailable: false
        });

        // Purchases.configure(<public_google_sdk_key>);
    }

    /**
    * register all products with IAP on init (once per app session)
    * @param iapItems 
    */
    registerIapItems(iapItems: IGameItemIAP[]) {
        console.log("IAP console > register iap items: ", iapItems);
        if (!this.enableIAP) {
            console.warn("iap not available");
            return;
        }
        this.registeredIapItems = iapItems;
        this.preloadStoreProducts(iapItems, true);
    }

    /**
     * preload (all) store products
     * @param iapItems 
     * @param forceReloadIAP 
     */
    preloadStoreProducts(iapItems: IGameItemIAP[], forceReloadIAP: boolean) {
        return new Promise((resolve, reject) => {
            if (!iapItems) {
                iapItems = this.registeredIapItems;
            }
            console.log("preload store products");
            let pids: string[] = [];
            for (let i = 0; i < iapItems.length; i++) {
                let productId: string = IAPUtils.getProductId(iapItems[i]);
                if (productId) {
                    let add: boolean = true;
                    if (!forceReloadIAP) {
                        if (this.cachedProducts[productId] != null) {
                            add = false;
                        }
                    }
                    if (add) {
                        // TODO: handle non consumable items too (in the future)
                        pids.push(productId);
                        console.log("product [" + productId + "] registered");
                    } else {
                        console.log("product [" + productId + "] already registered");
                    }
                }
            }

            if ((pids.length > 0) && (!this.cachedProductsFlag || forceReloadIAP)) {
                /**
                 * Issue: empty array
                 * I tested only with in-app purchases ids and calling 'GetProducts' with the 'inapp' parameter and it works.
                 * Also only with subscription ids calling 'GetProducts' with the 'subs' parameter and it works too.
                 */
                // type: PURCHASE_TYPE.INAPP
                Purchases.getProducts({ productIdentifiers: pids, type: PRODUCT_CATEGORY.NON_SUBSCRIPTION }).then((value: { products: PurchasesStoreProduct[] }) => {
                    if (value != null) {
                        console.log("fetched products from the store: ", value.products);
                        for (let product of value.products) {
                            let prod: IAPStoreProduct = {
                                identifier: product.identifier,
                                description: product.description,
                                title: product.title,
                                price: product.price,
                                priceString: product.priceString,
                                currencyCode: product.currencyCode,
                                product: product
                            };
                            this.cachedProducts[product.identifier] = prod;
                            this.cachedProductsFlag = true;
                        }
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                }).catch((error: PurchasesError) => {
                    console.error(error);
                    reject(error);
                });
            } else {
                resolve(true);
            }
        });

    }

    /**
      * retrieve product list (info) from the store
      * update item data: price, name, description to match the store data
      * @param items
      * @param reload force reload
      */
    retrieveProductList(items: (IGameItem | IStory)[], reload: boolean, updateContent: boolean, forceReloadIAP: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {

            if (!items) {
                items = [];
            }

            let hasIap: boolean = false;

            if (GeneralCache.os === EOS.browser) {
                console.log("retrieve product list (test browser)");
                IAPUtils.checkItemListHidePrice(items);

                for (let item of items) {
                    if (item.itemIap) {
                        hasIap = true;
                        break;
                    }
                }

                if (!hasIap) {
                    resolve(true);
                    return;
                }

                setTimeout(() => {
                    console.log("store resolve");
                    for (let item of items) {
                        IAPUtils.setAvailableTest(item);
                    }
                    resolve(true);
                }, 500);
            } else {
                // check and hide product prices at the moment
                IAPUtils.checkItemListHidePrice(items);

                if (!this.enableIAP) {
                    console.warn("iap not available");
                    resolve(true);
                    return;
                }

                if (!this.loadProductDetailsOnRequest) {
                    resolve(true);
                    return;
                }

                // check iap loading required
                for (let item of items) {
                    if (item.itemIap) {
                        hasIap = true;
                        break;
                    }
                }

                if (!hasIap) {
                    resolve(true);
                    return;
                }

                // preload all iap items (otherwise it doesn't load a new batch)
                try {
                    await this.preloadStoreProducts(null, forceReloadIAP);
                } catch (err) {
                    reject(err);
                    return;
                }

                // just for effect (to see that it's loaded smth)
                await SleepUtils.sleep(500);

                let check: ICheckItemsList = IAPUtils.checkItemDataMulti(items, reload, true);

                if (check.hasItems) {
                    for (let i = 0; i < check.checkItems.length; i++) {
                        let pid: string = check.checkItems[i].productId;
                        // check local cache (previously loaded iap data)
                        switch (GeneralCache.os) {
                            case EOS.ios:
                                if (this.cachedProducts[pid]) {
                                    IAPUtils.updateIapItemData(check.itemsList[i], this.cachedProducts[pid], updateContent);
                                }
                                break;
                            case EOS.android:
                            default:
                                if (this.cachedProducts[pid]) {
                                    IAPUtils.updateIapItemData(check.itemsList[i], this.cachedProducts[pid], updateContent);
                                }
                                break;
                        }
                    }
                }
                console.log("iap items details loaded: ", items);
                resolve(true);
            }
        });
        return promise;
    }

    getValidationData() {
        return this.customerInfo;
    }

    purchaseProduct(pid: string): Promise<boolean> {
        return new Promise((resolve, reject) => {
            let p: PurchasesStoreProduct = this.cachedProducts[pid].product;
            if (!p) {
                reject(new Error("product not loaded"));
                return;
            }

            Purchases.purchaseStoreProduct({ product: p }).then((res: MakePurchaseResult) => {
                console.log("customer info: ", res.customerInfo);
                resolve(true);
            }).catch((err) => {
                console.error(err);
                if (err.userCancelled) {
                    resolve(false);
                } else {
                    reject(err.error);
                }
            });
        });
    }

    getCustomerInfo(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            Purchases.getCustomerInfo().then((value: { customerInfo: CustomerInfo }) => {
                if (value != null) {
                    console.log("customer info: ", value.customerInfo);
                    this.customerInfo = value.customerInfo;
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((err) => {
                console.error(err);
                reject(err);
            });
        });
    }

    /**
    * request purchase game item
    * returns false if cancelled
    * @param code
    */
    buyItemConsume(item: IGameItem): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            try {
                let iapCode: string = IAPUtils.getProductId(item.itemIap);
                if (!iapCode) {
                    reject(new Error("This item is not currently available for sale"));
                    return;
                }
                let purchaseData: IPurchaseInfo = await this.buyItemConsumeCore(iapCode);
                if (!purchaseData) {
                    resolve(false);
                    return;
                }
                await this.inventory.buyInventoryItemConfirmRC(item.code, purchaseData);
                resolve(true);
            } catch (err) {
                this.analytics.dispatchError(err, "leplace_IAP");
                reject(err);
            }
        });
        return promise;
    }


    /**
     * request purchase any item
     * returns null if cancelled
     */
    buyItemConsumeCore(productId: string): Promise<IPurchaseInfo> {
        let promise: Promise<IPurchaseInfo> = new Promise(async (resolve, reject) => {
            try {
                if (!this.customerInfo) {
                    await this.getCustomerInfo();
                }
                let proceeded: boolean = await this.purchaseProduct(productId);
                if (!proceeded) {
                    resolve(null);
                    return;
                }
                await this.getCustomerInfo();
                let purchaseData: IPurchaseInfo = {
                    customer: this.customerInfo
                };
                resolve(purchaseData);
            } catch (err) {
                this.analytics.dispatchError(err, "leplace_IAP");
                reject(err);
            }
        });
        return promise;
    }
}

