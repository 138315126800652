
export interface ISetProgressData {
    photoFinishUpload: string;
    questMainInput: string;
    questFinishInput: string;
    multiPhotoMainUpload: IMultiPhotoUploadSpec[];
    droneUsed: boolean;
    locationSkipped: boolean;
}

export interface IProgressCache {
    photoMainUpload: string;
    photoFinishUpload: string;

    questMainInput: string;
    questFinishInput: string;

    multiPhotoMainUpload: IMultiPhotoUploadSpec[];
}

export interface IMultiPhotoUploadSpec {
    data: string;
    index: number;
    ts?: Date,
    url?: string;
}

export enum EPhotoCardMode {
    uploaded = 1,
    reference = 2,
    finishUploaded = 3
}

export enum EPhotoValidationStatus {
    accepted = 1,
    rejected = 0,
    pending = 2
}