import { Component, OnInit, OnDestroy } from '@angular/core';
import { IPopoverActions, IPopoverAction } from 'src/app/classes/def/app/modal-interaction';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { ParamHandler } from 'src/app/classes/general/params';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';

@Component({
  selector: 'modal-options-list',
  templateUrl: './options-list.component.html',
  styleUrls: ['./options-list.component.scss'],
})
export class OptionsListViewComponent implements OnInit, OnDestroy {
  actions: IPopoverActions = {};
  title: string = "";
  vs: IViewSpecs = ViewSpecs.getDefault();

  actionsList: IPopoverAction[] = [];
  appIconsStandard = EAppIconsStandard;

  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);
      this.actions = np.params.actions;
      this.title = np.params.title;

      this.getOptions();
    }
  }

  ngOnDestroy() {

  }

  getOptions() {
    let keys: string[] = Object.keys(this.actions);
    for (let i = 0; i < keys.length; i++) {
      this.actionsList.push(this.actions[keys[i]]);
    }
  }

  sendAction(action) {
    setTimeout(() => {
      this.modalCtrl.dismiss(action).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  dismiss() {
    this.sendAction(null);
  }
}
