import { UiExtensionService } from './../ui/ui-extension';
import { IYouTubeViewFrameNavParams, YouTubeViewFrameComponent } from './../../../modals/generic/modals/youtube-view-frame/youtube-view-frame.component';
import { EOS } from './../../../classes/def/app/app';
import { GeneralCache } from './../../../classes/app/general-cache';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { ResourcesCoreDataService } from '../../data/resources-core';
import { IGenericResponse } from '../../../classes/def/requests/general';
import { IVideoRef } from '../../../classes/def/media/videos';
import { PromiseUtils } from '../../utils/promise-utils';
import { WebviewUtilsService } from '../../app/utils/webview-utils';


@Injectable({
    providedIn: 'root'
})
export class YouTubeService {
    constructor(
        public plt: Platform,
        public webView: WebviewUtilsService,
        public youtube: YoutubeVideoPlayer,
        public resources: ResourcesCoreDataService,
        public uiext: UiExtensionService
    ) {
        console.log("you tube service created");
    }

    open(id: string, url: string, title: string, portrait: boolean) {
        // if (!GeneralCache.isWeb && GeneralCache.checkPlatformOS() === EOS.android) {
        //     this.youtube.openVideo(id);
        // } else {
        //     PromiseUtils.wrapNoAction(this.openIframe(id, url, title ? title : "YouTube Video"), true);
        // }
        PromiseUtils.wrapNoAction(this.openIframe(id, url, title ? title : "YouTube Video", portrait), true);
    }

    openIframe(videoId: string, videoUrl: string, title: string, portrait: boolean): Promise<boolean> {
        return new Promise((resolve) => {
            let p: IYouTubeViewFrameNavParams = {
                videoId: videoId,
                videoUrl: videoUrl,
                title: title,
                portrait: portrait
            };
            this.uiext.showCustomModal(null, YouTubeViewFrameComponent, {
                view: {
                    fullScreen: false,
                    transparent: false,
                    large: true,
                    addToStack: true,
                    frame: false
                },
                params: p
            }).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
    }

    openWithCode(code: number) {
        this.resources.getVideoTutorial(code).then((response: IGenericResponse) => {
            let video: IVideoRef = response.data;
            if (video) {
                this.open(video.videoId, null, video.name, video.portrait === 1);
            }
        }).catch((err: Error) => {
            console.error(err);
        });
    }

}
