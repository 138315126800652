import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { PhotoViewService } from 'src/app/services/general/apis/photo-view';
import { ParamHandler } from 'src/app/classes/general/params';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';

export interface IPhotoValidatedViewParams {
  description: string;
  title: string;
  photoUrl: string;
  isDataUrl: boolean;
}


@Component({
  selector: 'modal-photo-validated',
  templateUrl: './photo-validated.component.html',
  styleUrls: ['./photo-validated.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PhotoValidatedViewComponent implements OnInit, OnDestroy {
  description: string = "";
  title: string = "";
  selectAll: boolean = false;
  wrapperClass: string = "";
  innerClass: string = "";
  loading: boolean = false;
  photoUrl: string = null;
  params: IPhotoValidatedViewParams;
  vs: IViewSpecs = ViewSpecs.getDefault();
  appIconsStandard = EAppIconsStandard;
  appIcons = EAppIcons;
  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController,
    public resources: ResourcesCoreDataService,
    public analytics: AnalyticsService,
    public photoViewer: PhotoViewService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      this.params = np.params;

      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);

      if (this.params.description) {
        this.description = this.params.description;
      }
      // this.descriptionLines = this.formatText(this.description);
      this.title = this.params.title;
      this.photoUrl = this.params.photoUrl;
    }

    this.getClass();
  }

  ngOnDestroy() {

  }

  getClass() {
    this.wrapperClass = "padding-s";
    this.innerClass = "scrollbar-y scroll-content-modal-1";
  }

  dismiss(value: number) {
    if (value == null) {
      value = EAlertButtonCodes.cancel;
    }
    setTimeout(() => {
      this.modalCtrl.dismiss(value).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  viewPhoto() {
    this.photoViewer.viewPhoto(this.photoUrl, this.title, {
      share: true,
      customModal: true,
      isDataUrl: this.params.isDataUrl,
      changePhotoCallback: null
    });
  }

  ok() {
    this.dismiss(EAlertButtonCodes.ok);
  }

  cancel() {
    this.dismiss(EAlertButtonCodes.cancel);
  }
}
