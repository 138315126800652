<ion-row class="filters">
  <ion-col class="col-with-arrow" (click)="slidePrev()" no-padding size="1" *ngIf="paginate">
    <icon-wrapper *ngIf="showLeftButton" icon="caret-back" iconIos="chevron-back-circle-outline"></icon-wrapper>
  </ion-col>
  <ion-col [size]="paginate ? 10 : 12" #sliderContainer>
    <ion-slides (ionSlideDidChange)="slideChanged()" [options]="sliderOptions" *ngIf="loaded">
      <ion-slide *ngFor="let slide of data; let k = index;">
        <div class="multistep-progress full-w">
          <div class="container">
            <ul class="progress-bar-multi">
              <li *ngFor="let item of slide; let i = index;" [class]="getClassV2(item)"
                (click)="clicked(k*itemsPerSlide + i)"></li>
            </ul>
          </div>
        </div>
      </ion-slide>
    </ion-slides>
  </ion-col>
  <ion-col class="col-with-arrow" (click)="slideNext()" no-padding size="1" *ngIf="paginate">
    <icon-wrapper *ngIf="showRightButton" icon="caret-forward" iconIos="chevron-forward-circle-outline"></icon-wrapper>
  </ion-col>
</ion-row>