
import { Injectable } from "@angular/core";
import { Vibration } from '@ionic-native/vibration/ngx';
import { UserDataService } from "../../data/user";
import { GeneralCache } from "../../../classes/app/general-cache";
import { SettingsManagerService } from "../settings-manager";
import { IPlatformFlags } from "../../../classes/def/app/platform";
import { UiExtensionService } from "../ui/ui-extension";
import { PromiseUtils } from "../../utils/promise-utils";
import { PermissionsService } from "../permissions/permissions";
import { Messages } from "src/app/classes/def/app/messages";
import { EAlertButtonCodes } from "src/app/classes/def/app/ui";
import { OpenNativeSettings } from "@ionic-native/open-native-settings/ngx";
import { BackgroundModeWatchService } from "./background-mode-watch";
import { ENativeSettingsContext } from "../permissions/permission.utils";
import { ResourceManager } from "src/app/classes/general/resource-manager";
import { CancelOptions, LocalNotifications, PermissionStatus, ScheduleOptions, ScheduleResult } from "@capacitor/local-notifications";


export enum ELocalNotificationId {
    single = 1,
    persistent = 2,
    warn = 3,
    persistentWarn = 4
}

@Injectable({
    providedIn: 'root'
})
export class LocalNotificationsService {
    registered: boolean = false;
    platform: IPlatformFlags = {} as IPlatformFlags;
    subscription = {
        foregroundWeb: null
    };

    constructor(
        public vibration: Vibration,
        public userData: UserDataService,
        public settingsProvider: SettingsManagerService,
        public permissions: PermissionsService,
        public openNativeSettings: OpenNativeSettings,
        public bgmWatch: BackgroundModeWatchService,
        public uiext: UiExtensionService
    ) {
        console.log("local notifications service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    /**
     * init notifications w/ permissions checkup
     * @returns 
     */
    async initCheckup() {
        let hasPermission: boolean = await PromiseUtils.wrapResolve(this.checkPermission(), true);
        if (!this.platform.WEB) {
            if (!hasPermission) {
                let check: boolean = await this.permissions.requestAndroidNotificationPermission();
                console.log("local notifications permission request check: ", check);
                await PromiseUtils.wrapResolve(this.requestPermission(), true);
                console.log("local notifications permission request complete");
                hasPermission = await PromiseUtils.wrapResolve(this.checkPermission(), true);
                console.log("local notifications permission granted: ", hasPermission);
                // if (!hasPermission && this.platform.ANDROID) {
                //     console.log("requesting notification permissions via firebaseX (android only)");
                //     hasPermission = await this.firebaseX.grantPermission();
                //     console.log("local notifications permission granted: ", hasPermission);
                // }
                // this.notify("Hello", "Welcome to Leplace World", true, null);            
            } else {
                console.log("local notifications permissions already granted");
            }
        }
        return hasPermission;
    }

    async initCheckupFallback() {
        if (this.platform.WEB) {
            return false;
        }
        let hasPermission: boolean = false;
        let res: number = await PromiseUtils.wrapResolve(this.uiext.showAlert(Messages.msg.notificationPermissionsRequireAction.after.msg, Messages.msg.notificationPermissionsRequireAction.after.sub, 2, null, true), true);
        if (res === EAlertButtonCodes.ok) {
            await this.requireNativePermissionChange(ENativeSettingsContext.app);
            hasPermission = await PromiseUtils.wrapResolve(this.checkPermission(), true);
            console.log("local notifications permission granted from settings: ", hasPermission);
        }
        return false;
    }

    async requireNativePermissionChange(type: string = ENativeSettingsContext.app) {
        await this.openNativeSettings.open(type);
        await this.bgmWatch.waitDefaultBgmWatch(false);
    }

    requestPermission() {
        if (this.platform.WEB) {
            // web api includes request permission
            return this.checkPermission();
        }
        return new Promise<boolean>((resolve, reject) => {
            this.checkPermission().then((granted: boolean) => {
                if (!granted) {
                    // request permissions
                    LocalNotifications.requestPermissions().then((res: PermissionStatus) => {
                        if (res && res.display === 'granted') {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    // permissions already granted
                    resolve(true);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }

    checkPermission() {
        return new Promise<boolean>((resolve, reject) => {
            if (this.platform.WEB) {
                console.log("requesting browser notifications permissions");
                if (!("Notification" in window)) {
                    console.warn("This browser does not support notifications.");
                    GeneralCache.appFlags.notificationPermissionsGranted = false;
                    resolve(false);
                    return;
                }
                // web api checks permissions while requesting (same method)
                Notification.requestPermission().then((result) => {
                    console.log("request notification permissions: ", result);
                    if (Notification.permission === "granted") {
                        GeneralCache.appFlags.notificationPermissionsGranted = true;
                        resolve(true);
                    } else {
                        GeneralCache.appFlags.notificationPermissionsGranted = false;
                        resolve(false);
                    }
                }).catch((err) => {
                    reject(err);
                });
            } else {
                LocalNotifications.checkPermissions().then((res: PermissionStatus) => {
                    if (res && res.display === 'granted') {
                        GeneralCache.appFlags.notificationPermissionsGranted = true;
                        resolve(true);
                    } else {
                        GeneralCache.appFlags.notificationPermissionsGranted = false;
                        resolve(false);
                    }
                }).catch((err: Error) => {
                    GeneralCache.appFlags.notificationPermissionsGranted = false;
                    reject(err);
                });
            }
        });
    }

    /**
     * set local notification
     * @param always schedule the notification regardless of the app state
     */
    notify(title: string, text: string, always: boolean, customId: number) {

        if (!this.platform.WEB) {
            if (!always && !GeneralCache.paused) {
                return;
            }
        }

        // Schedule a single notification
        if (!title) {
            title = "Leplace World";
        }

        console.log("schedule notification title: " + title + ", content: " + text);

        this.requestPermission().then((res: boolean) => {
            if (res) {
                if (this.platform.WEB) {
                    console.log("creating browser notification");
                    const notification = new Notification("Leplace World (Lite)", { body: title });
                    notification.onshow = (ev) => {
                        console.log("notification presented");
                        this.subscription.foregroundWeb = ResourceManager.clearSub(this.subscription.foregroundWeb);
                    };
                    notification.onerror = (ev) => {
                        console.warn("notification error");
                        this.subscription.foregroundWeb = ResourceManager.clearSub(this.subscription.foregroundWeb);
                    };
                    notification.onclose = (ev) => {
                        console.log("notification closed");
                        this.subscription.foregroundWeb = ResourceManager.clearSub(this.subscription.foregroundWeb);
                    };
                    this.subscription.foregroundWeb = ResourceManager.clearSub(this.subscription.foregroundWeb);
                    this.subscription.foregroundWeb = this.bgmWatch.getForegroundWebWatch().subscribe((state: boolean) => {
                        if (state) {
                            notification.close();
                            this.subscription.foregroundWeb = ResourceManager.clearSub(this.subscription.foregroundWeb);
                        }
                    }, (err: Error) => {
                        console.error(err);
                    });
                } else {
                    let opts: ScheduleOptions = {
                        notifications: [
                            {
                                id: customId ? customId : ELocalNotificationId.single,
                                title: title,
                                body: text,
                                // sound: null,
                                ongoing: false
                            }
                        ]
                    };

                    LocalNotifications.schedule(opts).then((res: ScheduleResult) => {
                        console.log(res);
                    }).catch((err: Error) => {
                        console.error(err);
                    });
                }
            }
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    getProgressLevel(value: number, target: number) {
        return Math.floor(value / target * 100);
    }

    /**
     * set persistent notification
     * update existing
     * WARN: not working
     * @param title 
     * @param text 
     * @param always 
     */
    setPersistentNotification(title: string, text: string, always: boolean, customId: number, progress: number) {
        if (!this.platform.WEB) {
            if (!always && !GeneralCache.paused) {
                return;
            }
        }

        if (!title) {
            title = "Leplace World";
        }

        let options: ScheduleOptions = {
            notifications: [
                {
                    id: customId ? customId : ELocalNotificationId.persistent,
                    title: title,
                    body: text,
                    ongoing: true
                }
            ]
        };

        let cancelOptions: CancelOptions = {
            notifications: [
                {
                    id: customId ? customId : ELocalNotificationId.persistent
                }
            ]
        }

        // cancel previous/existing notification
        LocalNotifications.cancel(cancelOptions).then(() => {
            this.requestPermission().then((res: boolean) => {
                if (res) {
                    // schedule persistent notification
                    LocalNotifications.schedule(options).then((res: ScheduleResult) => {
                        console.log(res);
                    }).catch((err: Error) => {
                        console.error(err);
                    });
                }
            }).catch((err: Error) => {
                console.error(err);
            });
        }).catch((err: Error) => {
            console.error(err);
        });
    }


    /**
     * clear local/in-app notifications
     */
    clearLocal() {
        if (this.platform.WEB) {
            return;
        }
        LocalNotifications.removeAllDeliveredNotifications().then(() => {
            console.log("cleared local notifications")
        }).catch((err: Error) => {
            console.error(err);
        });
    }

}
