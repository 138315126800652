import { IMeasurementDisp } from "../def/app/measurement";
import { EMeasurementSystem } from "../def/map/geolocation";
import { SettingsManagerService } from 'src/app/services/general/settings-manager';


export interface IFormatDisp {
    value: string;
    unit: string;
    disp: string;
}

export class MathUtils {

    static formatNumericString(val: number, decimals: number) {
        function isInt(n: any) {
            return Number(n) === n && n % 1 === 0;
        }

        function isFloat(n: any) {
            return Number(n) === n && n % 1 !== 0;
        }

        if (isInt(val)) {
            return "" + val;
        } else {
            if (isFloat(val)) {
                return val.toFixed(decimals);
            } else {
                return "";
            }
        }
    }

    /**
     * random index is within [min, max-1]
     */
    static randomIndex(min: number, max: number) {
        // let min = 0;
        // let max = Math.floor(count);
        let randomIndex: number = Math.floor(Math.random() * (max - min)) + min; // The maximum is exclusive and the minimum is inclusive
        // randomIndex is within [min, max-1]
        return randomIndex;
    }

    static metersToFeet(val: number, round: boolean = false) {
        if ((val != null) || (val === 0)) {
            val = val * 3.2808;
            if (round) {
                val = Math.floor(val);
            }
        } else {
            val = null;
        }
        return val;
    }

    static metersToMiles(val: number, round: boolean = false) {
        if ((val != null) || (val === 0)) {
            val = val * 0.00062137;
            if (round) {
                val = Math.floor(val * 100) / 100;
            }
        } else {
            val = null;
        }
        return val;
    }

    static kphToMph(val: number, round: boolean = false) {
        if ((val != null) || (val === 0)) {
            val = val / 1.609344;
            if (round) {
                val = Math.floor(val * 10) / 10;
            }
        } else {
            val = null;
        }
        return val;
    }

    static formatDistanceTargetDisp(val: number, target: number) {
        if ((val == null || target == null) && !(val === 0 && target === 0)) {
            return null;
        }
        let measurementDisp: IMeasurementDisp = SettingsManagerService.getUnits();
        let short1: boolean = MathUtils.checkDistanceDispShort(val);
        let short2: boolean = MathUtils.checkDistanceDispShort(target);
        let short: boolean = false;
        let unit: string = measurementDisp.distance;
        if (short1 || short2) {
            short = true;
            unit = measurementDisp.distanceShort;
        }
        return MathUtils.formatDistanceDispCore(val, short) + " of " + MathUtils.formatDistanceDispCore(target, short) + " " + unit;
    }

    static formatSpeedTargetDisp(val: number, target: number) {
        if ((val == null || target == null) && !(val === 0 && target === 0)) {
            return null;
        }
        let measurementDisp: IMeasurementDisp = SettingsManagerService.getUnits();
        return MathUtils.formatSpeedDispCore(val) + " of " + MathUtils.formatSpeedDispCore(target) + " " + measurementDisp.speed;
    }


    static formatDistanceDisp(val: number): IFormatDisp {
        let measurementDisp: IMeasurementDisp = SettingsManagerService.getUnits();
        let short: boolean = MathUtils.checkDistanceDispShort(val);
        let unit: string = measurementDisp.distance;
        if (short) {
            unit = measurementDisp.distanceShort;
        }
        let valString: string = MathUtils.formatDistanceDispCore(val, short);
        let disp: string = valString + " " + unit;
        if (valString == null) {
            disp = null;
        }
        return {
            value: valString,
            unit: unit,
            disp
        } as IFormatDisp;
    }

    static formatSpeedDisp(val: number, decimals: boolean = true): IFormatDisp {
        let measurementDisp: IMeasurementDisp = SettingsManagerService.getUnits();
        let valString: string = MathUtils.formatSpeedDispCore(val, decimals);
        let disp: string = valString + " " + measurementDisp.speed;
        if (valString == null) {
            disp = null;
        }
        return {
            value: valString,
            unit: measurementDisp.speed,
            disp
        } as IFormatDisp;
    }

    static formatSpeedDispCore(val: number, decimals: boolean = true) {
        let valString: string = "";
        if ((val == null) && (val !== 0)) {
            return null;
        }
        let valFormat: number;
        if (SettingsManagerService.getMeasurementSystem() === EMeasurementSystem.imperial) {
            valFormat = MathUtils.kphToMph(val, true);
            if (decimals) {
                valString = valFormat.toFixed(1);
            } else {
                valString = Math.floor(valFormat).toString();
            }
        } else {
            // valString = Math.floor(val * 10) / 10;
            if (val === 0) {
                valString = "" + val;
            } else {
                valFormat = val;
                if (decimals) {
                    valString = valFormat.toFixed(1);
                } else {
                    valString = Math.floor(valFormat).toString();
                }
            }
        }
        return valString;
    }

    static checkDistanceDispShort(val: number) {
        let short: boolean = false;
        if ((val == null) && (val !== 0)) {
            return null;
        }
        if (SettingsManagerService.getMeasurementSystem() === EMeasurementSystem.imperial) {
            let miles: number = MathUtils.metersToMiles(val, false);
            short = miles < 1;
        } else {
            short = val < 1000;
        }
        return short;
    }

    static formatDistanceDispCore(val: number, short: boolean) {
        let valString: string = "";
        if ((val == null) && (val !== 0)) {
            return null;
        }
        if (SettingsManagerService.getMeasurementSystem() === EMeasurementSystem.imperial) {
            let miles: number = MathUtils.metersToMiles(val, false);
            if (short) {
                valString = "" + Math.floor(miles * 5280);
                short = true;
            } else {
                valString = miles.toFixed(1);
            }
        } else {
            if (short) {
                valString = Math.floor(val) + "";
            } else {
                val = val / 1000;
                valString = val.toFixed(1);
            }
        }
        return valString;
    }


    /**
     * compute low pass filter with cutoff frequency spec
     * @param filter 
     * @param data 
     * @param fc 
     * @param dt actual dt
     */
    static lowPassFilterFc(filter: number, data: number, fc: number, dt: number) {
        let rc: number = 1 / (2 * Math.PI * fc);
        return MathUtils.lowPassFilterRC(filter, data, rc, dt);
    }

    static lowPassFilterRC(filter: number, data: number, rc: number, dt: number) {
        let alpha: number = dt / (dt + rc);
        alpha = 1 - alpha;
        return MathUtils.lowPassFilter(filter, data, alpha);
    }

    static lowPassFilter(filter: number, data: number, alpha: number) {
        filter = filter * alpha + data * (1 - alpha);
        return filter;
    }

    /**
     * unsigned
     * @param filter 
     * @param data 
     * @param average 
     * @param alpha 
     */
    static envelopeFilter(filter: number, data: number, average: number, alpha: number) {
        if ((data - average) > filter) {
            filter = data - average;
        } else {
            filter = filter * alpha;
        }
        return filter;
    }

    static computeAlphaFromCutoffFrequency(samplingTime: number, cutoffFrequency: number) {
        let filterTimeConstant: number = 1 / (2 * Math.PI * cutoffFrequency);
        return MathUtils.computeAlphaFromTimeConstant(samplingTime, filterTimeConstant);
    }

    static computeAlphaFromTimeConstant(samplingTime: number, filterTimeConstant: number) {
        let alpha: number = filterTimeConstant / (filterTimeConstant + samplingTime);
        console.log("compute filter, sampling time = " + samplingTime + ", time constant = " + filterTimeConstant + ", alpha = " + alpha);
        return alpha;
    }

    static log10(x: number) {
        return Math.log(x) / Math.LN10;
    }


    /**
     * add 2 decimal values given by strings
     * @param d1 
     * @param d2 
     */
    static decimalAdd(d1: string, d2: string) {
        if (d1 == null || d2 == null) {
            return null;
        }
        let res = {
            decimals: 0,
            integerResult: 0,
            stringResult: "0"
        };

        let len1 = d1.length;
        let len2 = d2.length;
        let p1 = d1.indexOf(".");
        let p2 = d2.indexOf(".");
        let nd1 = len1 - p1 - 1;
        let nd2 = len2 - p2 - 1;

        let ndMax = Math.max(nd1, nd2);

        // tslint:disable-next-line:radix
        let int1 = Number.parseInt(d1.slice(0, p1));
        // tslint:disable-next-line:radix
        let int2 = Number.parseInt(d2.slice(0, p2));
        // tslint:disable-next-line:radix
        let frac1 = Number.parseInt(d1.slice(p1 + 1));
        // tslint:disable-next-line:radix
        let frac2 = Number.parseInt(d2.slice(p2 + 1));


        if (nd1 > nd2) {
            frac2 *= Math.pow(10, nd1 - nd2);
        }
        if (nd2 > nd1) {
            frac1 *= Math.pow(10, nd2 - nd1);
        }

        let dm = Math.pow(10, ndMax);

        let dd1 = int1 * dm + frac1;
        let dd2 = int2 * dm + frac2;

        res.decimals = ndMax;
        res.integerResult = dd1 + dd2;
        res.stringResult = (res.integerResult / dm).toFixed(ndMax);
        return res;
    }

    /**
     * scale a decimal value with an integer value
     * @param d1 
     * @param d2 
     */
    static decimalScale(d1: string, d2: number) {
        let res = {
            decimals: 0,
            integerResult: 0,
            stringResult: "0"
        };
        let len1 = d1.length;
        let p1 = d1.indexOf(".");
        let nd1 = len1 - p1 - 1;
        // tslint:disable-next-line:radix
        let int1 = Number.parseInt(d1.slice(0, p1));
        // tslint:disable-next-line:radix
        let frac1 = Number.parseInt(d1.slice(p1 + 1));
        let dm = Math.pow(10, nd1);
        let dd1 = int1 * dm + frac1;
        res.decimals = nd1;
        res.integerResult = dd1 * d2;
        res.stringResult = (res.integerResult / dm).toFixed(nd1);
        return res;
    }


    static formatFileSize(bytes, decimalPoint) {
        if (bytes === 0) { return '0 Bytes'; }
        let k = 1000,
            dm = decimalPoint || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
}
