

import { Component, Input, OnInit, Output, OnChanges, SimpleChanges, SimpleChange, OnDestroy } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { timer } from 'rxjs';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { ButtonStyles, IButtonStyle } from 'src/app/classes/def/app/ui';


@Component({
  selector: 'blink-button',
  templateUrl: './blink-button.component.html',
  styleUrls: ['./blink-button.component.scss'],
})
export class BlinkButtonComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  name: string;
  @Input()
  icon: string;
  @Input()
  disabled: boolean;
  /**
   * blink (toggle on state change)
   */
  @Input()
  toggle: boolean;
  /**
   * blink (absolute)
   */
  @Input()
  set: boolean;

  @Input()
  huge: boolean;

  /**
   * blink (toggle on state change) for a finite amount of time
   */
  @Input()
  toggleTimeout: boolean;

  /**
   * blink (absolute) for a finite amount of time
   */
  @Input()
  setTimeout: boolean;

  /**
   * blink (absolute) for a finite amount of time, on state transition
   */
  @Input()
  setTimeoutOnChange: boolean;

  @Input()
  fab: boolean;

  @Input()
  defaultClass: string;

  @Input()
  highClass: string;

  /**
   * set global theme incl. text: primary, alternate, warn, accent
   */
  @Input()
  theme: string;

  @Input()
  noBorder: boolean;

  @Input()
  xs: boolean;

  @Input()
  customIcon: boolean;

  @Input()
  customBaseClass: string;

  @Input()
  duration: number;

  @Input()
  customFontClass: string;

  @Output()
  select: EventEmitter<any> = new EventEmitter();

  blinkEnable: boolean = false;
  state: boolean = false;
  timerSub = null;
  blinkTimeout;

  defaultClassActive: string;
  highClassActive: string;

  btnBaseClass: string = "action-button-fill";
  fabBtnBaseClass: string = "round-fab round-fab-size";

  btnDefaultClass: string;
  btnHighClass: string;
  fabBtnDefaultClass: string;
  fabBtnHighClass: string;

  iconOnly: boolean = false;

  fontClass: string = "button-font-size-s";

  textDefaultClass: string;
  textHighClass: string;

  constructor() {
    let defaultClass: string = ButtonStyles.style.alternate.color;
    let highClass: string = ButtonStyles.style.alternate.highColor;

    this.btnDefaultClass = this.btnBaseClass + " " + defaultClass;
    this.btnHighClass = this.btnBaseClass + " " + highClass;

    this.fabBtnDefaultClass = this.fabBtnBaseClass + " " + ButtonStyles.style.primary70.color;
    this.fabBtnHighClass = this.fabBtnBaseClass + " " + ButtonStyles.style.primary70.highColor;

    this.textDefaultClass = ButtonStyles.style.alternate.textColor;
    this.textHighClass = ButtonStyles.style.alternate.highTextColor;
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      // console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'name':
            this.checkName();
            break;
          case 'toggle':
            this.blinkEnable = !this.blinkEnable;
            this.setBlink(false);
            break;
          case 'toggleTimeout':
            // only start blinking
            // the timeout will take care of the rest
            if (!this.blinkEnable) {
              this.blinkEnable = true;
              this.setBlink(true);
            }
            break;
          case 'set':
            this.blinkEnable = chg.currentValue;
            this.setBlink(false);
            break;
          case 'setTimeout':
            this.blinkEnable = chg.currentValue;
            if (this.blinkEnable) {
              // start blinking and timeout
              this.setBlink(true);
            } else {
              // stop blinking on demand
              this.disableBlink();
            }
            break;
          case 'defaultClass':
          case 'theme':
          case 'xs':
          case 'huge':
            this.initDisp();
            break;
          case 'setTimeoutOnChange':
            this.blinkEnable = true;
            this.disableBlink();
            this.setBlink(true);
            break;
        }
      }
      // const chg: SimpleChange = changes[keys[i]];
      // console.log('prev value: ', chg.previousValue);
      // console.log('got name: ', chg.currentValue);
    }
  }

  setTheme(mode: string) {
    if (!mode) {
      return;
    }

    let style: IButtonStyle = ButtonStyles.style[mode];

    if (!style) {
      return;
    }

    // keep custom classes even if theme is defined
    if (!this.defaultClass) {
      this.defaultClassActive = style.color;
    }
    if (!this.highClass) {
      this.highClassActive = style.highColor;
    }

    this.textDefaultClass = style.textColor;
    this.textHighClass = style.highTextColor;
  }

  setDefaultClass() {
    if (this.defaultClass) {
      this.defaultClassActive = this.defaultClass;
    }
    if (this.highClass) {
      this.highClassActive = this.highClass;
    }
  }

  setBlink(timeout: boolean) {
    if (this.blinkEnable) {
      let duration: number = 5000;

      if (this.duration) {
        duration = this.duration;
      }
      let count: number = Math.floor(duration / 1000);

      this.enableBlink(timeout ? count : 0);
      if (timeout) {
        this.blinkTimeout = setTimeout(() => {
          this.blinkEnable = false;
          this.disableBlink();
        }, duration + 500);
      }
    } else {
      this.disableBlink();
    }
  }

  enableBlink(count: number) {
    if (!this.timerSub) {
      let timer1 = timer(0, 500);
      let flagCount: boolean = ((count != null) && (count > 0));
      let countk: number = count * 2;
      this.timerSub = timer1.subscribe(() => {
        this.state = !this.state;
        if (flagCount) {
          countk -= 1;
          if (countk <= 0) {
            this.disableBlink();
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  disableBlink() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.blinkTimeout = ResourceManager.clearTimeout(this.blinkTimeout);
    this.state = false;
  }


  clicked(index: number) {
    this.select.emit([index]);
  }


  checkName() {
    if (!this.name) {
      this.iconOnly = true;
    } else {
      this.iconOnly = false;
    }
  }

  ngOnInit() {
    this.initDisp();
  }

  getTextClass() {
    let tclass: string = "";
    tclass += !this.state ? this.textDefaultClass : this.textHighClass;
    tclass += " " + this.fontClass;
    return tclass;
  }

  initDisp() {
    this.checkName();
    this.setDefaultClass();
    this.setTheme(this.theme);

    if (this.xs) {
      this.btnBaseClass = "action-button-xs";
      this.fab = false;
      this.iconOnly = true;
    }

    if (this.huge) {
      this.fabBtnBaseClass = "round-fab round-fab-size-huge";
    }

    if (this.customFontClass) {
      this.fontClass = this.customFontClass;
    }

    if (this.customBaseClass) {
      this.btnBaseClass = this.customBaseClass;
    }

    if (this.defaultClassActive) {
      this.btnDefaultClass = this.btnBaseClass + " " + this.defaultClassActive;
      this.fabBtnDefaultClass = this.fabBtnBaseClass + " " + this.defaultClassActive;
    }
    if (this.highClassActive) {
      this.btnHighClass = this.btnBaseClass + " " + this.highClassActive;
      this.fabBtnHighClass = this.fabBtnBaseClass + " " + this.highClassActive;
    }

    if (this.noBorder) {
      this.btnDefaultClass += " no-border-width";
      this.fabBtnDefaultClass += " no-border-width";
      this.btnHighClass += " no-border-width";
      this.fabBtnHighClass += " no-border-width";
    }

    if (this.set) {
      this.setBlink(false);
    }
  }

  ngOnDestroy() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.blinkTimeout = ResourceManager.clearTimeout(this.blinkTimeout);
  }
}
