<!-- <ion-header no-border class="tutorial-header">
  <ion-toolbar class="tutorial-toolbar">
    <ion-buttons slot="end" *ngIf="showSkip">
      <ion-button slot="icon-only" (click)="startApp()" class="text-color-primary">Skip</ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header> -->

<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" leftButton="" [leftIcon]="false" (leftClick)="nop()"
    [rightButton]="showSkip ? skipLabel : ''" [rightIcon]="false" (rightClick)="startApp()" title="">
  </leplace-toolbar>
</ion-header>

<ion-content no-bounce class="scroll-content-margin-top-1">
  <ion-row class="slider-controls">
    <!-- <ion-col class="col-with-arrow control-icon" (click)="slidePrev()" no-padding size="1">
      <icon-wrapper *ngIf="showLeftButton" icon="caret-back" iconIos="chevron-back-circle-outline"></icon-wrapper>
    </ion-col> -->
    <ion-col size="12" #sliderContainer>
      <ion-slides #slides (ionSlideWillChange)="onSlideChangeStart($event)" [pager]="true" [options]="sliderOptions"
        class="slider-tutorial">
        <ion-slide *ngFor="let slide of slidesDataDisp">
          <div
            class="scrollbar-y custom-scrollbar scroll-height-slide border-radius-frame background-white full-w padding-s">
            <!-- <div class="padding-s slide-height"> -->
            <img [src]="slide.img" class="slide-image" />
            <h2 class="slide-title text-color-alternate-high" *ngIf="!slide.customFont">
              <b>{{slide.title}}</b>
            </h2>
            <h2 class="slide-title logo text-color-alternate-high" *ngIf="slide.customFont">
              {{slide.title}}
            </h2>
            <div [innerHTML]="slide.desc" class="info-text-m bold text-color-alternate-high"></div>
            <ion-button *ngIf="slide.start" large fill="clear" (click)="startApp()">
              Continue
              <!-- <icon-wrapper class="slot-icon-padding" slot="start" icon="arrow-forward"></icon-wrapper> -->
            </ion-button>
            <div *ngIf="slide.footer" [innerHTML]="slide.footer" class="info-text-m text-color-alternate-high">
            </div>
            <!-- </div> -->
          </div>
        </ion-slide>
      </ion-slides>
    </ion-col>
    <!-- <ion-col class="col-with-arrow control-icon" (click)="slideNext()" no-padding size="1">
      <icon-wrapper *ngIf="showRightButton" icon="caret-forward" iconIos="chevron-forward-circle-outline">
      </icon-wrapper>
    </ion-col> -->
  </ion-row>
</ion-content>