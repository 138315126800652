import { Injectable } from "@angular/core";
import { GenericDataService } from "../general/data/generic";
import { IGenericResponse } from "../../classes/def/requests/general";
import { INewsfeedInbox } from "../../classes/def/newsfeed/newsfeed";
import { GeneralCache } from 'src/app/classes/app/general-cache';


@Injectable({
    providedIn: 'root'
})
export class NewsfeedDataService {

    constructor(
        public generic: GenericDataService
    ) {
        console.log("newsfeed data service created");
    }

    getNewsfeedTopic(topic: number, page: number) {
        return this.generic.genericPostStandard("/newsfeed/user/get-topic", {
            topic: topic,
            page: page
        });
    }

    checkGlobalInbox(): Promise<INewsfeedInbox> {
        let promise: Promise<INewsfeedInbox> = new Promise((resolve, reject) => {
            if (GeneralCache.resourceCache.user.newsfeedInbox.loaded) {
                resolve(GeneralCache.resourceCache.user.newsfeedInbox.content);
                return;
            }

            this.generic.genericPostStandard("/newsfeed/user/check-inbox", {

            }).then((data: IGenericResponse) => {
                let rd: INewsfeedInbox = data.data;
                GeneralCache.resourceCache.user.newsfeedInbox.content = rd;
                GeneralCache.resourceCache.user.newsfeedInbox.loaded = true;
                resolve(rd);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;

    }

    getNewsfeedTopicHeaders() {
        return this.generic.genericGetStandard("/newsfeed/user/topics", {

        });
    }
}
