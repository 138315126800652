import { IStoryListNavParams } from "../nav-params/story";

export interface IModalEvent {
    emitter: number;
}

export interface IModalEventPickStory {
    emitter: number;
    data: IStoryListNavParams;
}

export enum EModalEmitter {
    storyList = 1,
    storyDetailBack = 2,
    storyDetailGoToMap = 3,
    eventList = 4,
    eventDetailBack = 5,
    eventDetailGoToMap = 6
}
