

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { ScriptLoaderService } from 'src/app/services/general/script-loader';
import { EScripts } from 'src/app/classes/utils/script-loader';


// import stickerbomb from '../../assets/js/stickerbomb-master/stickerbomb.min.js';

declare var stickerbomb: any; 

export interface ISBConfig {
  target?: string;
  /**
   * the background choices
   */
  backdrops: string[];
  /**
   * sticker drawers
   */
  stickers: {
    /**
     * sticker set
     */
    [key: string]: ISBSticker[]
  };
}

export interface ISBConfigList {
  target?: string;
  /**
   * the background choices
   */
  backdrops: string[];
  /**
   * sticker drawers
   */
  stickerSets: ISBStickerSet[];
}

export interface ISBStickerSet {
  name: string;
  stickers: ISBSticker[];
}

export interface ISBSticker {
  name: string;
  src: string;
}

const TARGET = "#sb";

// https://github.com/userexec/stickerbomb
@Component({
  selector: 'leplace-stickerbomb',
  templateUrl: './leplace-stickerbomb.component.html',
  styleUrls: ['./leplace-stickerbomb.component.scss'],
})
export class StickerbombComponent implements OnInit, OnDestroy {

  sbConfig: ISBConfig = {
    target: TARGET,
    backdrops: ['assets/img/photos/explore.jpg', 'assets/img/photos/eat.jpg'],
    stickers: {
      // 'regular items': [
      //     {
      //         name: 'item 1',
      //         src: 'assets/img/markers/user_v2.png'
      //     },
      //     {
      //         name: 'item 2',
      //         src: 'assets/img/markers/mp_user.png'
      //     }
      // ],
      // 'special items': [
      //     {
      //         name: 'item 3',
      //         src: 'assets/img/markers/m2.png'
      //     },
      //     {
      //         name: 'item 4',
      //         src: 'assets/img/markers/m3.png',
      //     }
      // ]
    }
  };

  @Input()
  config: ISBConfigList;

  constructor(public scriptLoader: ScriptLoaderService) {

  }

  ngOnInit() {
    if (this.config) {
      this.formatConfig(this.config);
      this.sbConfig.target = TARGET;
    }
    this.initSB();
  }

  ngOnDestroy() {

  }

  /**
   * load config from input data
   * array to dict
   * @param configList 
   */
  formatConfig(configList: ISBConfigList) {
    this.sbConfig.backdrops = configList.backdrops;
    for (let i = 0; i < configList.stickerSets.length; i++) {
      let s: ISBStickerSet = configList.stickerSets[i];
      // let skey: string = i.toString();
      let skey: string = s.name;
      this.sbConfig.stickers[skey] = s.stickers;
    }
  }

  /**
   * load and init stickerbomb with specified params
   */
  initSB() {
    this.scriptLoader.load([EScripts.stickerbomb]).then(() => {
      if (this.sbConfig) {
        let show = stickerbomb(this.sbConfig);
        console.log(show);
      } else {
        console.log("no SB config to show");
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }
}
