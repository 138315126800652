import { Component, NgZone, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ParamHandler } from 'src/app/classes/general/params';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { Messages } from 'src/app/classes/def/app/messages';
import { GooglePlaceServiceProvider } from 'src/app/services/apis/google-places';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';

export interface IPlaceSearch {

}

export interface IAutocompletePlace {
  placeId: string,
  name: string
}

@Component({
  selector: 'modal-place-search',
  templateUrl: './place-search.component.html',
  styleUrls: ['./place-search.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlaceSearchViewComponent implements OnInit, OnDestroy {
  title: string = "Place Finder";
  theme: string = "theme-aubergine theme-aubergine-bg";
  autocompleteItems: IAutocompletePlace[] = [];
  latitude: number = 0;
  longitude: number = 0;
  geo: any;
  vs: IViewSpecs = ViewSpecs.getDefault();
  np: INavParams = null;
  hasParams: boolean;
  loaded: boolean = true;
  searchTimeout;
  hasItems: boolean = false;
  emptyMessage: string = "<p>Use the search bar to find your place on Google.</p><p>Select the place to add it on the map and register it on your account.</p>";
  autocompleteQuery: string = "";
  lastQuery: string = null;
  appIconsStandard = EAppIconsStandard;

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService,
    public googleplaces: GooglePlaceServiceProvider,
    private zone: NgZone
  ) {

  }

  ngOnInit() {
    this.autocompleteItems = [];
    this.hasParams = ParamHandler.checkParams(this.np);
    if (!this.hasParams) {

    } else {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IPlaceSearch = np.params;
      console.log(params);
      if (np.view) {
        this.vs = np.view;
      }
    }
  }

  ngOnDestroy() {

  }

  dismiss() {
    this.returnModal(null);
  }

  /**
   * select autocomplete place result
   * @param item
   */
  chooseItem(item: IAutocompletePlace) {
    this.geo = item;
    this.googleplaces.getPlaceDetails(item.placeId).then((place) => {
      this.returnModal(place);
    }).catch((err) => {
      console.error(err);
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
    })
    // this.geoCode(this.geo);//convert Address to lat and long
  }

  /**
   * return modal with selected place result
   * @param place 
   */
  returnModal(place: google.maps.places.PlaceResult) {
    setTimeout(() => {
      this.modalCtrl.dismiss(place).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  /**
   * on input changed
   */
  updateSearch() {
    if (this.autocompleteQuery === '' && this.lastQuery != null) {
      console.log("empty query / clear");
      this.autocompleteItems = [];
      this.loaded = true;
      this.hasItems = false;
      // fix first input not registered after clear
      this.lastQuery = null;
      this.searchTimeout = ResourceManager.clearTimeout(this.searchTimeout);
      return;
    }
    this.updateSearchWithDelay();
  }

  updateSearchWithDelay() {
    this.loaded = false;
    this.searchTimeout = ResourceManager.clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(() => {
      console.log("update search with delay");
      if (this.autocompleteQuery === '') {
        console.log("empty query");
        this.autocompleteItems = [];
        this.hasItems = false;
        this.loaded = true;
        this.lastQuery = null;
        return;
      }

      this.lastQuery = this.autocompleteQuery;
      this.googleplaces.getAutocompleteService().getPlacePredictions({
        input: this.autocompleteQuery
      }, (predictions, status) => {
        console.log(status);
        this.autocompleteItems = [];
        this.zone.run(() => {
          if (predictions != null) {
            if (predictions.length > 0) {
              this.hasItems = true;
            }
            predictions.forEach((prediction: google.maps.places.AutocompletePrediction) => {
              let place: IAutocompletePlace = {
                placeId: prediction.place_id,
                name: prediction.description
              };
              this.autocompleteItems.push(place);
            });
            this.loaded = true;
          }
        });
      });
    }, 500);
  }

  // convert Address string to lat and long
  geoCode(address: any) {
    let geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      console.log(status);
      this.latitude = results[0].geometry.location.lat();
      this.longitude = results[0].geometry.location.lng();
      alert("lat: " + this.latitude + ", long: " + this.longitude);
    });
  }
}
