import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MPCreatePage } from './mp-create.page';
import { PagesModule } from '../../pages.module';

const routes: Routes = [
  {
    path: '',
    component: MPCreatePage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [MPCreatePage]
})
export class MPCreatePageModule { }
