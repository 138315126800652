<!-- class="group-card" -->
<div *ngIf="group" (click)="clicked()">
  <ion-grid class="full-w" [ngClass]="{'card-selected': selected, 'theme-border-bottom-thin': !expires && border}">

    <ion-row class="center-items center-text">
      <ion-col size="12">
        <span class="info-text-m bold text-color-primary">{{group.name}}</span>
      </ion-col>
    </ion-row>

    <ion-row class="center-items center-text">

      <ion-col size="4" class="text-left">
        <img [src]="bgPhoto" alt="Avatar" class="img-avatar-crop">
      </ion-col>

      <ion-col size="2">
        <icon-wrapper-extra icon="settings" [disabled]="!isAdmin">
        </icon-wrapper-extra>
      </ion-col>

      <ion-col size="2">
        <icon-wrapper-extra *ngIf="isMember" [icon]="appIcons.gpsFixed" [custom]="true">
        </icon-wrapper-extra>
        <icon-wrapper-extra *ngIf="!isMember" [icon]="appIconsStandard.circle" [custom]="false">
        </icon-wrapper-extra>
      </ion-col>

      <ion-col size="4" class="text-right">
        <icon-wrapper-extra [text]="countActiveUsers" icon="people">
        </icon-wrapper-extra>
      </ion-col>

    </ion-row>

    <ion-row class="center-text padding-bottom-s">
      <ion-col size="12">
        <span class="info-text-m bold text-color-primary">{{groupTypeName}}</span>
      </ion-col>
    </ion-row>

    <progress-timeout *ngIf="expires" pid="group-card" [small]="true" customClass="progress-bar-alternate-light"
      [progress]="group.expirationTimeLeftPercent" [resetTimeoutToggle]="false" [enabled]="false">
    </progress-timeout>

    <progress-timeout *ngIf="withReload" pid="groups-home" [small]="true" customClass="progress-bar-alternate-light"
      [timeoutValue]="reloadTimeout" [resetTimeoutToggle]="resetLoadingTimeout" [enabled]="true">
    </progress-timeout>

  </ion-grid>
</div>