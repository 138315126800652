import { ElementRef } from '@angular/core';


export class ScrollUtils {

    static checkScrollEnd(element: ElementRef) {
        let nativeElement = element.nativeElement;
        // scrollTop: the pointer to the selection from top
        // scrollHeight: the height of the entire content
        // clientHeight: the height of the visible area
        let isScrollEnd: boolean = (nativeElement.scrollTop + nativeElement.clientHeight) >= (nativeElement.scrollHeight - 10);
        // console.log("check scroll end -> scroll top: " + nativeElement.scrollTop + " client height: " + nativeElement.clientHeight + ", scroll height: " + nativeElement.scrollHeight + ", is scroll end: " + isScrollEnd);
        return isScrollEnd;
    }

    static scrollEnd(element: ElementRef, offset: number) {
        let nativeElement = element.nativeElement;
        nativeElement.scrollTop = nativeElement.scrollHeight - nativeElement.clientHeight + offset;
        console.log("after scroll end -> scroll top: " + nativeElement.scrollTop);
    }

    static scrollBottom(element: ElementRef) {
        let nativeElement = element.nativeElement;
        nativeElement.scrollTop = nativeElement.scrollHeight;
        console.log("after scroll bottom -> scroll top: " + nativeElement.scrollTop);
    }

    static scrollActiveElementIntoVIew(native: boolean, container: Element, offset: number, offsetPercent: number) {
        setTimeout(() => {
            let activeElement: Element = document.activeElement;
            console.log("focused, active element: ", activeElement);
            if (native) {
                if (activeElement) {
                    activeElement.scrollIntoView(true);
                }
            } else {
                try {
                    ScrollUtils.scrollIntoView(container, activeElement, offset, offsetPercent);
                } catch (err) {
                    console.error(err);
                }
            }
        }, 10);
    }

    // This is the main function where which pass two ref parameters of Parent element & Child element
    static scrollIntoView(parent: Element, child: Element, offset: number, offsetPercent: number) {
        console.log("scroll into view: ", parent != null, child != null);
        if (!parent) {
            let parentAux: Element;
            let parentElementCrt: Element = child;
            let found: boolean = false;
            for (let i = 0; i < 10; i++) {
                parentAux = parentElementCrt.parentElement;
                if (!parentAux) {
                    break;
                }
                if (parentAux.classList.contains("scroll-into-view-container")) {
                    found = true;
                    break;
                }
                parentElementCrt = parentAux;
            }
            if (found) {
                parent = parentAux;
            } else {
                parent = parentElementCrt.parentElement;
            }
            console.log("parent element found ", found, parent);
        }

        if (parent == null || child == null) {
            console.warn("scoll into view element not defined");
            return;
        }

        const parentBounding = parent.getBoundingClientRect();
        const clientBounding = child.getBoundingClientRect();

        const parentBottom = parentBounding.bottom,
            parentTop = parentBounding.top,
            clientBottom = clientBounding.bottom,
            clientTop = clientBounding.top;

        console.log("parent top: ", parentTop);
        console.log("client top: ", clientTop);
        console.log("parent btm: ", parentBottom);
        console.log("client btm: ", clientBottom);

        if (offsetPercent != null) {
            const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
            offset = Math.floor(offsetPercent * windowHeight / 100) + 100;
        }

        console.log("offset: ", offset);

        if (parentTop >= clientTop - offset) {
            console.log("case 1");
            ScrollUtils.scrollTo(parent, -(parentTop - clientTop - offset), 300);
        } else if (clientBottom > parentBottom - offset) {
            console.log("case 2");
            ScrollUtils.scrollTo(parent, clientBottom - parentBottom + offset, 300);
        }
    }

    static scrollTo(element: Element, to: number, duration: number) {
        let start = element.scrollTop,
            currentTime = 0,
            increment = 20;

        let animateScroll = function () {
            currentTime += increment;
            let val = ScrollUtils.easeInOutQuad(currentTime, start, to, duration);
            element.scrollTop = val;
            if (currentTime < duration) {
                setTimeout(animateScroll, increment);
            }
        };

        animateScroll();
    }

    // Function for smooth scroll animation with the time duration
    static easeInOutQuad(time: number, startPos: number, endPos: number, duration: number) {
        time /= duration / 2;

        if (time < 1) return (endPos / 2) * time * time + startPos;
        time--;
        return (-endPos / 2) * (time * (time - 2) - 1) + startPos;
    }
}