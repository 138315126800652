import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ISupportCategory } from 'src/app/classes/def/support/support';

@Component({
  selector: 'support-category',
  templateUrl: './support-category.component.html',
  styleUrls: ['./support-category.component.scss'],
})
export class SupportCategoryComponent implements OnInit, OnDestroy {
  @Input()
  data: ISupportCategory;

  name: string;

  locked: boolean = false;

  constructor() { }

  ngOnInit() {
    if (this.data) {
      this.name = this.data.name;
      if (!this.data.enabled) {
        // this.name += " (locked)";
        this.locked = true;
      }
    }
  }


  ngOnDestroy() {

  }

}

