import { Injectable } from "@angular/core";
import { LocationMonitorService } from "../../map/location-monitor";
import {
    IRunActivityStats, IExploreActivityStats, IEnduranceRunActivityStats,
    IActivityParam, IGenericActivityStats, IActivityScoreResponse, IFindActivityStats, IQuestActivityStats, IPhotoActivityStats
} from "../../../classes/def/core/activity-stats";
import { ExploreActivityService } from "./activities/explore";
import { TimeoutService } from "./timeout";
import { IStatDef, EStatCodes, EStatType } from "../../../classes/def/user/stats";
import { IActivityStatsContainer } from "../../../classes/def/core/activity";
import { GenericDataService } from "../../general/data/generic";
import { IGenericResponse } from "../../../classes/def/requests/general";
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IEventStoryGroupLinkData } from 'src/app/classes/def/core/links';
import { ILeplaceTreasure } from 'src/app/classes/def/places/leplace';
import { ExploreActivityUtilsService } from './activities/explore-utils';
import { FindActivityService } from './activities/find';
import { MoveActivityService } from './activities/move';
import { QuestActivityService } from './activities/quest';
import { LinksDataService } from '../../data/links';
import { IMoveMonitorData } from 'src/app/classes/def/core/move-monitor';
import { MathUtils } from "src/app/classes/general/math";
import { TimeUtils } from "src/app/classes/general/time";
import { PhotoActivityService } from "./activities/photo";

/**
 * aggregate stats from multiple activity providers
 */
@Injectable({
    providedIn: 'root'
})
export class ActivityStatsService {

    constructor(
        public locationMonitor: LocationMonitorService,
        public exploreProvider: ExploreActivityService,
        public exploreUtils: ExploreActivityUtilsService,
        public findService: FindActivityService,
        public timeoutMonitor: TimeoutService,
        public moveProvider: MoveActivityService,
        public questProvider: QuestActivityService,
        public photoProvider: PhotoActivityService,
        public generic: GenericDataService,
        public links: LinksDataService
    ) {
        console.log("activity stats service created");
    }

    /**
    * request activity score
    * via activity score service
    * return score only
    * resolve only
    * @param activityParams 
    * @param stats 
    */
    getActivityScoreResolve(activityCode: number, activityBaseCode: number, activityParams: IActivityParam[], stats: IGenericActivityStats, treasure: ILeplaceTreasure, customRewardXp: number): Promise<IActivityScoreResponse> {
        let promise: Promise<IActivityScoreResponse> = new Promise((resolve) => {
            this.getActivityScore(activityCode, activityBaseCode, activityParams, stats, treasure, customRewardXp).then((res: IActivityScoreResponse) => {
                resolve(res);
            }).catch(() => {
                resolve(null);
            });
        });
        return promise;
    }

    /**
     * request activity score
     * via activity score service
     * @param activityParams 
     * @param stats 
     */
    getActivityScore(activityCode: number, activityBaseCode: number, activityParams: IActivityParam[], stats: IGenericActivityStats, treasure: ILeplaceTreasure, customRewardXp: number): Promise<IActivityScoreResponse> {
        let promise: Promise<IActivityScoreResponse> = new Promise((resolve, reject) => {
            let links: IEventStoryGroupLinkData = this.links.getLinkData();
            console.log("get activity score for stats: ", stats);
            this.generic.genericPostStandard("/activity-stats/get-default-activity-score", {
                activityCode: activityCode,
                activityBaseCode: activityBaseCode,
                activityParams: activityParams,
                stats: stats,
                links: links,
                treasure: treasure,
                customRewardXp: customRewardXp
            }).then((response: IGenericResponse) => {
                let data: IActivityScoreResponse = response.data;
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get stat def for display purpose only!
     * using generic code only, so the level and activity is not considered
     * @param genericStatCode 
     * @param value 
     */
    getStatDef(genericStatCode: number, value: number) {
        let statDef: IStatDef = null;
        statDef = GeneralCache.resourceCache.general.statScores.content.find(s => s.code === genericStatCode);
        if (!statDef) {
            return null;
        }
        if (value != null) {
            statDef.value = value;
            statDef.valueString = "" + value;
            switch (statDef.typeCode) {
                case EStatType.distance:
                    statDef.valueString = MathUtils.formatDistanceDisp(value).disp;
                    break;
                case EStatType.speed:
                    statDef.valueString = MathUtils.formatSpeedDisp(value).disp;
                    break;
                case EStatType.time:
                    statDef.valueString = TimeUtils.formatTimeFromSeconds(value, true);
                    break;
                default:
                    break;
            }
        }
        return statDef;
    }

    addStatDef(statList: IStatDef[], sd: IStatDef) {
        if (sd != null) {
            statList.push(sd);
        }
    }

    getMoveStats(): IActivityStatsContainer {
        let data: IMoveMonitorData = this.moveProvider.moveData;
        let stats: IRunActivityStats = {
            elapsedTime: data.elapsedValue,
            coinsCollected: this.exploreUtils.getExploreStats().collectedCoins,
            targetCoins: this.exploreUtils.getExploreStats().targetCoins,
            averageSpeed: Math.floor(data.averageSpeed * 10) / 10,
            totalDistance: Math.floor(data.distance)
        };

        let statList: IStatDef[] = [];

        let sd = this.getStatDef(EStatCodes.challengeCachedTimedelta, stats.elapsedTime);
        this.addStatDef(statList, sd);
        sd = this.getStatDef(EStatCodes.challengeCoinsCollected, stats.coinsCollected);
        this.addStatDef(statList, sd);
        sd = this.getStatDef(EStatCodes.challengeCachedAverageSpeed, stats.averageSpeed);
        this.addStatDef(statList, sd);
        sd = this.getStatDef(EStatCodes.challengeCachedDistance, stats.totalDistance);
        this.addStatDef(statList, sd);

        return {
            stats: stats,
            statsList: statList
        };
    }

    getRunStats(): IActivityStatsContainer {
        let data: IMoveMonitorData = this.moveProvider.moveData;
        let stats: IRunActivityStats = {
            elapsedTime: data.elapsedValue,
            coinsCollected: this.exploreUtils.getExploreStats().collectedCoins,
            targetCoins: this.exploreUtils.getExploreStats().targetCoins,
            averageSpeed: Math.floor(data.averageSpeed * 10) / 10,
            totalDistance: Math.floor(data.distance)
        };

        let statList: IStatDef[] = [];
        let sd = this.getStatDef(EStatCodes.challengeCachedTimedelta, stats.elapsedTime);
        this.addStatDef(statList, sd);
        sd = this.getStatDef(EStatCodes.challengeCoinsCollected, stats.coinsCollected);
        this.addStatDef(statList, sd);
        sd = this.getStatDef(EStatCodes.challengeCachedAverageSpeed, stats.averageSpeed);
        this.addStatDef(statList, sd);
        sd = this.getStatDef(EStatCodes.challengeCachedDistance, stats.totalDistance);
        this.addStatDef(statList, sd);

        return {
            stats: stats,
            statsList: statList
        };
    }

    getEnduranceRunStats(): IActivityStatsContainer {
        let data: IMoveMonitorData = this.moveProvider.moveData;
        let stats: IEnduranceRunActivityStats = {
            totalDistance: Math.floor(data.distance),
            coinsCollected: this.exploreUtils.getExploreStats().collectedCoins,
            targetCoins: this.exploreUtils.getExploreStats().targetCoins,
            averageSpeed: Math.floor(data.averageSpeed * 10) / 10,
        };

        let statList: IStatDef[] = [];
        let sd = this.getStatDef(EStatCodes.challengeCoinsCollected, stats.coinsCollected);
        this.addStatDef(statList, sd);
        sd = this.getStatDef(EStatCodes.challengeCachedAverageSpeed, stats.averageSpeed);
        this.addStatDef(statList, sd);
        sd = this.getStatDef(EStatCodes.challengeCachedDistance, stats.totalDistance);
        this.addStatDef(statList, sd);

        return {
            stats: stats,
            statsList: statList
        };
    }

    getExploreStats(): IActivityStatsContainer {
        // let data = this.locationMonitor.moveData;
        // console.log("explore stats: ", data);
        // console.log(GeneralCache.resourceCache.general.statScores.content);
        let stats: IExploreActivityStats = {
            elapsedTime: this.timeoutMonitor.timeData.elapsedValue,
            coinsCollected: this.exploreUtils.getExploreStats().collectedCoins,
            targetCoins: this.exploreUtils.getExploreStats().targetCoins,
            totalDistance: Math.floor(this.exploreProvider.estatus.distanceTravelled),
            coinsCollectedValue: this.exploreUtils.getExploreStats().collectedCoinsValue
        };

        let statList: IStatDef[] = [];
        let sd = this.getStatDef(EStatCodes.challengeCachedTimedelta, stats.elapsedTime);
        this.addStatDef(statList, sd);
        sd = this.getStatDef(EStatCodes.challengeCoinsCollected, stats.coinsCollected);
        this.addStatDef(statList, sd);
        sd = this.getStatDef(EStatCodes.challengeCachedDistance, stats.totalDistance);
        this.addStatDef(statList, sd);

        return {
            stats: stats,
            statsList: statList
        };
    }

    getFindStats(): IActivityStatsContainer {
        // let data = this.locationMonitor.moveData;
        // console.log("explore stats: ", data);
        // console.log(GeneralCache.resourceCache.general.statScores.content);
        let stats: IFindActivityStats = {
            elapsedTime: this.timeoutMonitor.timeData.elapsedValue,
            totalDistance: Math.floor(this.findService.fstatus.distanceTravelled),
            coinsCollected: this.findService.fstatus.foundCoins
        };

        let statList: IStatDef[] = [];
        let sd = this.getStatDef(EStatCodes.challengeCachedTimedelta, stats.elapsedTime);
        this.addStatDef(statList, sd);
        sd = this.getStatDef(EStatCodes.challengeCoinsCollected, stats.coinsCollected);
        this.addStatDef(statList, sd);
        sd = this.getStatDef(EStatCodes.challengeCachedDistance, stats.totalDistance);
        this.addStatDef(statList, sd);

        return {
            stats: stats,
            statsList: statList
        };
    }

    getQuestStats(): IActivityStatsContainer {
        let stats: IQuestActivityStats = {
            elapsedTime: this.timeoutMonitor.timeData.elapsedValue,
            penalty: this.questProvider.questStatus.penalty
        };

        let statList: IStatDef[] = [];
        let sd = this.getStatDef(EStatCodes.challengeCachedTimedelta, stats.elapsedTime);
        this.addStatDef(statList, sd);

        return {
            stats: stats,
            statsList: statList
        };
    }

    getPhotoStats(): IActivityStatsContainer {
        let stats: IPhotoActivityStats = {
            elapsedTime: this.timeoutMonitor.timeData.elapsedValue,
            targetPhotos: this.photoProvider.params.photoGridCount,
            photosTaken: this.photoProvider.getGridStats().gridPhotoUploadCount
        };

        let statList: IStatDef[] = [];
        let sd = this.getStatDef(EStatCodes.challengeCachedTimedelta, stats.elapsedTime);
        this.addStatDef(statList, sd);

        return {
            stats: stats,
            statsList: statList
        };
    }

    getGenericStats(): IActivityStatsContainer {
        let stats: IPhotoActivityStats = {
            elapsedTime: this.timeoutMonitor.timeData.elapsedValue
        };

        let statList: IStatDef[] = [];
        let sd = this.getStatDef(EStatCodes.challengeCachedTimedelta, stats.elapsedTime);
        this.addStatDef(statList, sd);

        return {
            stats: stats,
            statsList: statList
        };
    }

    /**
     * apply percent penalty
     * @param score 
     * @param penalty 
     */
    applyPenalty(score: number, penalty: number) {
        return Math.floor(score * (100 - penalty) / 100);
    }
}
