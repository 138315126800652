<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="isRoot" [title]="title" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()"
    rightButton="settings" [rightIcon]="true" (rightClick)="options()" [loading]="!loaded">
  </leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <!-- (swipe)="swipeEvent($event)" -->
  <div class="view-frame theme-background" fxLayout="column">

    <div *ngIf="!hasGroups && loaded">
      <div [innerHTML]="description" class="padding-s info-text-m description-frame text-color-primary"></div>
    </div>

    <div class="scrollbar-y">
      <ion-list *ngIf="groups">
        <ion-item *ngFor="let group of groups" class="list-item list-item-padding transparent-bg full-w">
          <div class="full-w list-padding">
            <group-card tappable class="full-w" [group]="group" (select)="goToGroup(group)" [disableTracker]="true"
              [noBorder]="true"></group-card>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>
</ion-content>