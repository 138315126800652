<ion-row *ngIf="entry.newEntry">
  <ion-col size="2">
    <div class="icon-color-alternate">
      <icon-wrapper [icon]="appIconsStandard.alert" [custom]="false"></icon-wrapper>
    </div>
  </ion-col>
</ion-row>

<ion-row>
  <ion-col size="12" class="animate-expander animate-expander-text" [ngClass]="entry.open?'active':''">
    <div class="animate-expander-element animate-expander-element-text" [ngClass]="entry.open?'active':''">
      <span class="text-color-primary bold" *ngIf="entry.isGeneral">
        {{entry.name}}
      </span>
      <span class="text-color-accent bold" *ngIf="!entry.isGeneral">
        {{entry.name}}
      </span>
    </div>
  </ion-col>
</ion-row>

<div *ngIf="bgPhoto" class="padding-s">
  <responsive-img [photoUrl]="bgPhoto" class="border-radius"></responsive-img>
</div>

<div>
  <ion-row>
    <ion-col size="12">
      <safe-html-renderer [unsafeHtmlContent]="trimDescription"
        innerClass="text-color-primary info-text-m description-frame description-frame-p"
        *ngIf="!showOpen"></safe-html-renderer>
      <safe-html-renderer [unsafeHtmlContent]="entry.description"
        innerClass="text-color-primary info-text-m description-frame description-frame-p"
        *ngIf="showOpen"></safe-html-renderer>
    </ion-col>
  </ion-row>
</div>

<ion-row *ngIf="entry.showTimestamp">
  <ion-col size="12">
    <span class="text-color-primary info-text-s bold">
      {{entry.timestampDisp}}
    </span>
  </ion-col>
</ion-row>

<div *ngIf="showOpen">
  <ion-row *ngIf="hasFeature" class="margin-top-xs">
    <ion-col size="6" class="center-text">
      <ion-button class="action-button-fill button-color-transparent" (click)="openFeature()">
        <icon-wrapper slot="icon-only" icon="link"></icon-wrapper>
      </ion-button>
    </ion-col>
    <ion-col size="6">
      <expander [expanded]="entry.open" [large]="true" (expand)="setOpen(entry, $event)"></expander>
    </ion-col>
  </ion-row>
  <ion-row *ngIf="!hasFeature">
    <ion-col size="12" class="padding-top-s">
      <expander [expanded]="entry.open" (expand)="setOpen(entry, $event)"></expander>
    </ion-col>
  </ion-row>
</div>

<div *ngIf="!showOpen">
  <ion-row>
    <ion-col size="12" class="padding-top-s">
      <expander [expanded]="entry.open" (expand)="setOpen(entry, $event)"></expander>
    </ion-col>
  </ion-row>
</div>