import { Component, OnInit, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { IStoryCategory } from 'src/app/classes/def/core/category';

@Component({
  selector: 'category-info',
  templateUrl: './category-info.component.html',
  styleUrls: ['./category-info.component.scss'],
})
export class CategoryInfoComponent implements OnInit, OnDestroy {
  @Input()
  data: IStoryCategory;

  @Output()
  select: EventEmitter<any> = new EventEmitter();

  constructor() {

  }

  ngOnInit() {
    if (this.data) {

    }
  }

  ngOnDestroy() {

  }

  onSelect() {
    this.select.emit(this.data);
  }
}
