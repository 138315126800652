<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="isRoot" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()" rightButton="settings"
    [rightIcon]="true" (rightClick)="presentPopover()" [title]="title" [loading]="!loaded"></leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">

  <div class="view-frame theme-background no-scroll" fxLayout="column">

    <div class="theme-border-bottom">
      <ion-row>
        <ion-col size="12" class="text-center padding-s">
          <span class="info-text-m fab-display-top bold text-color-primary">{{heading}}</span>
        </ion-col>
      </ion-row>
    </div>

    <!-- (swipe)="swipeEvent($event)" -->
    <div class="scrollbar-y inventory-height theme-border-bottom">
      <div *ngIf="loaded">
        <grid-item [data]="gameItems" [interact]="true" [basicView]="false" [emptyMessage]="emptyMessage"
          [reload]="reloadItems" [enableLockedDescription]="enableLockedDescription"
          (action)="itemActionContainer($event)">
        </grid-item>
      </div>
    </div>

    <div fxFlex></div>

    <div class="padding-horizontal-s padding-bottom-xs">
      <leplace-paginator [pagination]="pagination" (onSwitch)="switchPage($event)"></leplace-paginator>
    </div>

  </div>
</ion-content>