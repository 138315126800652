import { IMPStatusDB } from './status';


export interface IMPMeetingPlace {
    id: number;
    name: string;
    timestamp: string;
    photoUrl: string;
    players?: IMPStatusDB[];
}

export enum EMPCooldownScope {
    readyCooldown = 1,
    autoStart = 2,
    all = 0
}