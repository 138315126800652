import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";


@Injectable({
    providedIn: 'root'
})
export class KeyHandlerService {

    keyEventObservable: BehaviorSubject<number>;

    constructor(

    ) {
        console.log("key handler service created");
        this.keyEventObservable = new BehaviorSubject(null);
    }

    registerKeyPress(code: number) {
        // console.log(event, event.keyCode, event.keyIdentifier);
        // let key: string = event.code;

        // if (!event.keyCode && event.charCode) {
        //   key = event.charCode;
        // } else {
        //   key = event.keyCode;
        // }
        this.keyEventObservable.next(code);
    }

    watchKeyPress() {
        this.keyEventObservable.next(null);
        return this.keyEventObservable;
    }
}
