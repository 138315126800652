import { ESettingsCode, EServerInitStatus, IAppSettings, ESettingsType, ESettingsPreset, IAppSettingsContent, SettingsGroupsDef, IAppLocalSettings } from '../../classes/def/app/settings';
import { EGeolocationModes, EMeasurementSystem, ESpeedMode } from '../../classes/def/map/geolocation';
import { EARGenModes, EARCalibrationModes } from '../../classes/def/ar/core';
import { ThemeColors } from '../../classes/def/app/theme';
import { EHudContext } from '../../classes/def/app/hud';
import { EFilePathOption } from 'src/app/classes/def/media/file';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';


export class AppSettings {

    /**
     * update the build number when updating anything that uses local storage
     * used for development mode so that you don't have to change the version 
     * every time you update the data
     * 
     * also update when renaming a settings object
     * 
     * updating an option (name, add, remove) requires setting the reset flag to true, and updating the build number at the same time
     * the change will take effect immediately at the next update
     * 
     * code settings will be overridden regardless the reset flag state
     * 
     */
    public static build: number = 285;

    public static useMobileStyleNavigationAlways: boolean = true; // no links but works in prod mode

    /**
     * allow access to unreleased content and features
     * may be enabled only by users that have the tester flag in the DB
     */
    public static testerMode: boolean = false;

    /**
     * only use in debug mode
     */
    public static allowSwitchDevMode: boolean = true;

    public static globalEnableAnalytics: boolean = true;

    public static settingsArray: IAppSettingsContent;

    public static localSettings: IAppLocalSettings = {
        mapDebugMode: false,
        ARDemoMode: false,
        disableARTicker: false,
        useWebkitCompass: true
    };

    /** don't use this directly, use SettingsManager instead to check for settings */
    public static settings: IAppSettings = {
        build: null, // change it when updating setting definition
        settings: {

            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // APP
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            keepScreenOn: {
                name: "Keep screen on (map)",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.app.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 10,
                    icon: EAppIconsStandard.view
                },
                type: ESettingsType.checkbox
            },

            enableTutorial: {
                name: "Enable tutorial messages",
                value: true,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.app.name,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 40,
                    icon: EAppIconsStandard.tutorial
                },
                type: ESettingsType.checkbox
            },

            enableWalkthroughs: {
                name: "Enable tutorial overlays",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.app.name,
                    code: null,
                    devOnly: false,
                    reset: true, // reset (use for value: false)
                    serverSync: EServerInitStatus.none,
                    position: 50,
                    icon: EAppIconsStandard.tutorial
                },
                type: ESettingsType.checkbox
            },

            mapDensityFiltering: {
                name: "Map density filtering",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.app.name,
                    code: null,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 60,
                    icon: EAppIconsStandard.densityFiltering
                },
                type: ESettingsType.checkbox
            },

            smartMapSelect: {
                name: "Map select auto zoom",
                value: true,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.app.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 70,
                    icon: EAppIconsStandard.search
                },
                type: ESettingsType.checkbox
            },

            backgroundMode: {
                name: "Allow background mode*",
                value: true,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.app.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 80,
                    icon: EAppIconsStandard.key
                },
                type: ESettingsType.checkbox
            },

            useDefaultPlacePhotos: {
                name: "Generic place photos",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.app.name,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 90,
                    icon: EAppIconsStandard.viewPhoto
                },
                type: ESettingsType.checkbox
            },

            showQuestPreview: {
                name: "Show quest preview",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.app.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 95,
                    icon: EAppIconsStandard.view
                },
                type: ESettingsType.checkbox
            },

            ARPauseDisplayLoopEnable: {
                name: "AR safe mode update",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.app.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 100,
                    icon: EAppIconsStandard.ar
                },
                type: ESettingsType.checkbox
            },

            ARExploreAutoCollect: {
                name: "AR explore auto collect",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.app.name,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 110,
                    icon: EAppIconsStandard.ar
                },
                type: ESettingsType.checkbox
            },

            HDPIMode: {
                name: "HDPI mode (map markers)",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.app.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 120,
                    icon: EAppIconsStandard.viewPhoto
                },
                type: ESettingsType.checkbox
            },

            tts: {
                name: "Sound",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.app.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    requiresAppRestart: false,
                    position: 130,
                    icon: EAppIconsStandard.sound
                },
                type: ESettingsType.checkbox
            },


            ttsMute: {
                name: "Mute speech",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.app.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 135,
                    icon: EAppIconsStandard.speakerOff
                },
                type: ESettingsType.checkbox
            },

            vibrateAlways: {
                name: "Vibrate always",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.app.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    requiresAppRestart: false,
                    position: 140,
                    icon: EAppIconsStandard.ping
                },
                type: ESettingsType.checkbox
            },

            onScreenHomeButtonFix: {
                name: "Virtual home button padding",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.app.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    requiresAppRestart: false,
                    position: 145,
                    iosOnly: true,
                    icon: EAppIconsStandard.dash
                },
                type: ESettingsType.checkbox
            },

            treasuresInStoryline: {
                name: "Treasures in story mode",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.app.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    requiresAppRestart: false,
                    position: 150,
                    icon: EAppIconsStandard.placeTreasure
                },
                type: ESettingsType.checkbox
            },


            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // APP EXTRAS
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            useProfilePictureOnTheMap: {
                name: "Map photo marker",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: ESettingsCode.showUserMarker,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 10,
                    icon: EAppIconsStandard.userMarker
                },
                type: ESettingsType.checkbox
            },


            useProfilePictureOnTheMapMP: {
                name: "Map photo marker (public)",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: ESettingsCode.showUserMarkerMp,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.sync,
                    position: 20,
                    icon: EAppIconsStandard.userMarker
                },
                type: ESettingsType.checkbox
            },

            showScanCooldown: {
                name: "Show scan cooldown",
                value: true,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 30,
                    icon: EAppIconsStandard.hourglass
                },
                type: ESettingsType.checkbox
            },

            AREnableScaling: {
                name: "AR enable scaling",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: null,
                    devOnly: false,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 40,
                    icon: EAppIconsStandard.frame
                },
                type: ESettingsType.checkbox
            },

            ARShortcut: {
                name: "AR shortcut",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: null,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 42,
                    icon: EAppIconsStandard.frame
                },
                type: ESettingsType.checkbox

            },


            ////

            locationInterpolate: {
                name: "Location interpolate",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 45
                },
                type: ESettingsType.checkbox,
            },

            hideDevStories: {
                name: "Hide dev stories",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 61
                },
                type: ESettingsType.checkbox
            },

            fadeLockedStoryLocations: {
                name: "Fade locked story locations",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: null,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 62
                },
                type: ESettingsType.checkbox
            },
            markerLocatingMode: {
                name: "Marker locating mode",
                value: true,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 65
                },
                type: ESettingsType.checkbox,
            },
            noActivityValidation: {
                name: "No activity validation",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: null,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 70
                },
                type: ESettingsType.checkbox
            },

            moveActivityTest: {
                name: "Move activity test",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: null,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 80
                },
                type: ESettingsType.checkbox
            },

            uploadFromGallery: {
                name: "Photo upload via gallery",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: null,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 85
                },
                type: ESettingsType.checkbox
            },

            autoStart: {
                name: "Story auto start",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: null,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 90
                },
                type: ESettingsType.checkbox
            },
            useMarkerClustering: {
                name: "Marker clustering",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: null,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 100
                },
                type: ESettingsType.checkbox
            },

            ARFixedScene: {
                name: "AR fixed scene",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: null,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 120
                },
                type: ESettingsType.checkbox
            },

            ARRecordDual: {
                name: "AR dual record",
                value: true,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: null,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 130
                },
                type: ESettingsType.checkbox
            },

            ARHighQuality: {
                name: "AR HQ",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: null,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 140
                },
                type: ESettingsType.checkbox
            },

            sensorStreamingAR: {
                name: "AR send experimental data",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 150
                },
                type: ESettingsType.checkbox
            },

            useRequestLogger: {
                name: "Enable request logger",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: ESettingsCode.useRequestLogger,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.sync,
                    position: 155
                },
                type: ESettingsType.checkbox
            },

            useEventLogger: {
                name: "Enable event logger",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: ESettingsCode.useCustomEventLogger,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.sync,
                    position: 157
                },
                type: ESettingsType.checkbox
            },

            useRemoteConsoleLogger: {
                name: "Enable remote console",
                value: false,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: ESettingsCode.useRemoteConsoleLogger,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.sync,
                    position: 158
                },
                type: ESettingsType.checkbox
            },

            smartMapNav: {
                name: "Smart map nav",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 160
                },
                type: ESettingsType.checkbox
            },

            useLocalServiceUrl: {
                name: "Local service URL",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 170
                },
                type: ESettingsType.checkbox
            },

            autoBgMode: {
                name: "Auto switch background mode",
                value: true,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 180
                },
                type: ESettingsType.checkbox
            },

            useDebugServiceUrls: {
                name: "Use debug service URL",
                value: false,
                codeOptions: {
                    code: ESettingsCode.serviceUrls,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 200
                },
                type: ESettingsType.checkbox
            },

            ARObjectHeightAdjust: {
                name: "AR object height adjust (cm)",
                value: 0,
                codeOptions: {
                    group: SettingsGroupsDef.groups.appExtras.name,
                    code: null,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 210
                },
                type: ESettingsType.checkbox
            },

            showMarkerIds: {
                name: "Show marker ids",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 220
                },
                type: ESettingsType.checkbox
            },

            worldEditorShowLockedOnly: {
                name: "World editor locked mode",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 230,
                    icon: EAppIconsStandard.locked
                },
                type: ESettingsType.checkbox
            },

            includeNearbyScan: {
                name: "Treasure scanner dual mode",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 240
                },
                type: ESettingsType.checkbox
            },

            expandTreasuresMode: {
                name: "Treasure scanner expand mode",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 245
                },
                type: ESettingsType.checkbox
            },

            useExternalBgModeNotification: {
                name: "Use ext bg notification",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 250
                },
                type: ESettingsType.checkbox
            },

            useHiddenBgModeNotification: {
                name: "Use hidden bg notification",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 260
                },
                type: ESettingsType.checkbox
            },

            disableTestAds: {
                name: "Disable test ads",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 270
                },
                type: ESettingsType.checkbox
            },

            enableStripeCheckoutAlways: {
                name: "Stripe checkout always",
                value: false,
                codeOptions: {
                    code: null,
                    group: SettingsGroupsDef.groups.appExtras.name,
                    devOnly: true,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 280
                },
                type: ESettingsType.checkbox
            },


            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // OPTIONS
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            settingsPreset: {
                name: "Preset",
                value: ESettingsPreset.custom,
                codeOptions: {
                    group: SettingsGroupsDef.groups.options.name,
                    code: ESettingsCode.preset,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 10,
                    icon: EAppIconsStandard.settings
                },
                options: {
                    custom: {
                        name: "Custom",
                        value: ESettingsPreset.custom
                    },
                    normal: {
                        name: "Normal",
                        value: ESettingsPreset.normal
                    },
                    demo: {
                        name: "Demo",
                        value: ESettingsPreset.demo
                    }
                },
                type: ESettingsType.select

            },

            hudMode: {
                name: "Display HUD",
                value: EHudContext.auto,
                codeOptions: {
                    group: SettingsGroupsDef.groups.options.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 20,
                    icon: EAppIconsStandard.hud
                },
                options: {
                    none: {
                        name: "None",
                        value: EHudContext.none
                    },
                    contextual: {
                        name: "Auto",
                        value: EHudContext.auto
                    },
                    map: {
                        name: "Map only",
                        value: EHudContext.map
                    },
                    ar: {
                        name: "AR only",
                        value: EHudContext.ar
                    },
                    mapAndAr: {
                        name: "Map and AR",
                        value: EHudContext.mapAndAr
                    }
                },
                type: ESettingsType.select
            },

            units: {
                name: "Units",
                value: EMeasurementSystem.metric,
                codeOptions: {
                    group: SettingsGroupsDef.groups.options.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 30,
                    icon: EAppIconsStandard.hud
                },
                type: ESettingsType.select,
                options: {
                    metric: {
                        name: "Metric",
                        value: EMeasurementSystem.metric
                    },
                    imperial: {
                        name: "Imperial",
                        value: EMeasurementSystem.imperial
                    }
                },
            },


            speedMode: {
                name: "Speed mode",
                value: ESpeedMode.speed,
                codeOptions: {
                    group: SettingsGroupsDef.groups.options.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 40,
                    icon: EAppIconsStandard.settings
                },
                type: ESettingsType.select,
                options: {
                    speed: {
                        name: "GPS Speed",
                        value: ESpeedMode.speed
                    },
                    position: {
                        name: "GPS Position",
                        value: ESpeedMode.position
                    }
                }
            },

            droneSimulationRate: {
                name: "Drone simulation rate / FPS",
                value: 2,
                codeOptions: {
                    group: SettingsGroupsDef.groups.options.name,
                    code: null,
                    devOnly: false,
                    reset: false,
                    serverSync: EServerInitStatus.none,
                    position: 45,
                    icon: EAppIconsStandard.settings
                },
                options: {
                    low: {
                        name: "Low",
                        value: 1
                    },
                    normal: {
                        name: "Normal",
                        value: 2
                    },
                    high: {
                        name: "High",
                        value: 3
                    }
                    // adaptive: {
                    //     name: "Adaptive",
                    //     value: 0
                    // }
                },
                type: ESettingsType.select
            },

            AREngine: {
                name: "AR Engine",
                value: 2,
                codeOptions: {
                    group: SettingsGroupsDef.groups.options.name,
                    code: null,
                    androidOnly: true,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 47,
                    icon: EAppIconsStandard.ar
                },
                options: {
                    armax1: {
                        name: "ARMAX 1",
                        value: 1
                    },
                    armax2: {
                        name: "ARMAX 2",
                        value: 2
                    }
                },
                type: ESettingsType.select
            },

            ARCalibrationMode: {
                name: "AR calibration mode",
                value: EARCalibrationModes.precise,
                codeOptions: {
                    group: SettingsGroupsDef.groups.options.name,
                    code: null,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 50,
                    icon: EAppIconsStandard.settings
                },
                options: {
                    precise: {
                        name: "Precise",
                        value: EARCalibrationModes.precise
                    },
                    smooth: {
                        name: "Smooth",
                        value: EARCalibrationModes.smooth
                    }
                },
                type: ESettingsType.select
            },

            ARVisibleObjects: {
                name: "AR object density",
                value: 16,
                codeOptions: {
                    group: SettingsGroupsDef.groups.options.name,
                    code: null,
                    devOnly: false,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 60,
                    icon: EAppIconsStandard.ar
                },
                options: {
                    low: {
                        name: "Low",
                        value: 8
                    },
                    medium: {
                        name: "Medium",
                        value: 16
                    },
                    high: {
                        name: "High",
                        value: 24
                    },
                    all: {
                        name: "Maximum",
                        value: 0
                    }
                },
                type: ESettingsType.select
            },

            ARFieldOfView: {
                name: "AR show distance",
                // meters
                value: 50,
                codeOptions: {
                    group: SettingsGroupsDef.groups.options.name,
                    code: null,
                    devOnly: false,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 70,
                    icon: EAppIconsStandard.ar
                },
                options: {
                    low: {
                        name: "Low",
                        value: 25
                    },
                    medium: {
                        name: "Medium",
                        value: 50
                    },
                    high: {
                        name: "High",
                        value: 75
                    },
                    all: {
                        name: "Maximum",
                        value: 0
                    }
                },
                type: ESettingsType.select
            },

            ARScale: {
                name: "AR scale",
                value: 1,
                codeOptions: {
                    group: SettingsGroupsDef.groups.options.name,
                    code: null,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 75,
                    icon: EAppIconsStandard.ar
                },
                options: {
                    near: {
                        name: "Near",
                        value: 0.5
                    },
                    normal: {
                        name: "Normal",
                        value: 1
                    },
                    far: {
                        name: "Far",
                        value: 2
                    }
                },
                type: ESettingsType.select
            },

            ARGimbalLockLevel: {
                name: "AR calibration zone",
                value: 1,
                codeOptions: {
                    group: SettingsGroupsDef.groups.options.name,
                    code: null,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 80,
                    icon: EAppIconsStandard.settings
                },
                options: {
                    disabled: {
                        name: "Disabled",
                        value: 4
                    },
                    low: {
                        name: "Narrow",
                        value: 0
                    },
                    medium: {
                        name: "Medium",
                        value: 1
                    },
                    high: {
                        name: "Wide",
                        value: 2
                    },
                    comapssOnly: {
                        name: "Compass only",
                        value: 3
                    }
                },
                type: ESettingsType.select
            },

            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
            // OPTIONS EXTRA
            ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

            testObjectAddMode: {
                name: "AR test mode",
                value: EARGenModes.compassRelative,
                codeOptions: {
                    group: SettingsGroupsDef.groups.optionsExtra.name,
                    code: null,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 10
                },
                options: {
                    relative: {
                        name: "Relative",
                        value: EARGenModes.compassRelative
                    },
                    absolute: {
                        name: "Absolute",
                        value: EARGenModes.compassAbsolute
                    }
                },
                type: ESettingsType.select
            },


            ARCalibrationFiltering: {
                name: "AR calibration filtering",
                value: 0.9,
                codeOptions: {
                    group: SettingsGroupsDef.groups.optionsExtra.name,
                    code: null,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 20
                },
                options: {
                    low: {
                        name: "Low",
                        value: 0.8
                    },
                    medium: {
                        name: "Medium",
                        value: 0.9
                    },
                    high: {
                        name: "High",
                        value: 0.95
                    }
                },
                type: ESettingsType.select
            },

            theme: {
                name: "UI theme",
                value: ThemeColors.theme.aubergine.name,
                codeOptions: {
                    group: SettingsGroupsDef.groups.optionsExtra.name,
                    code: ESettingsCode.theme,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 30
                },
                type: ESettingsType.select,
                options: {
                    speed: {
                        name: "Standard",
                        value: ThemeColors.theme.standard.name
                    },
                    position: {
                        name: "Aubergine",
                        value: ThemeColors.theme.aubergine.name
                    }
                }
            },

            locationMode: {
                name: "Location mode",
                value: EGeolocationModes.cordova,
                codeOptions: {
                    group: SettingsGroupsDef.groups.optionsExtra.name,
                    code: null,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 40
                },
                type: ESettingsType.select,
                options: {
                    navigator: {
                        name: "Navigator",
                        value: EGeolocationModes.native
                    },
                    // navigatorPolling: {
                    //     name: "Navigator (polling)",
                    //     value: EGeolocationModes.nativePolling
                    // },
                    // background: {
                    //   name: "BGL",
                    //   value: GeolocationModes.background
                    // },
                    cordova: {
                        name: "Geo-watch",
                        value: EGeolocationModes.cordova
                    },
                    cordovaPolling: {
                        name: "Geo-locate",
                        value: EGeolocationModes.cordovaPolling
                    }
                }
            },

            fileBasePath: {
                name: "File base path",
                value: EFilePathOption.EXT_DATA_DIR,
                iosValue: EFilePathOption.DATA_DIR,
                codeOptions: {
                    group: SettingsGroupsDef.groups.optionsExtra.name,
                    code: ESettingsCode.basePath,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 41
                },
                type: ESettingsType.select,
                options: {
                    s1: {
                        name: "app",
                        value: EFilePathOption.APP_DIR
                    },
                    s2: {
                        name: "app storage",
                        value: EFilePathOption.APP_STORAGE_DIR
                    },
                    s3: {
                        name: "cache",
                        value: EFilePathOption.CACHE_DIR
                    },
                    s4: {
                        name: "data",
                        value: EFilePathOption.DATA_DIR
                    },
                    s5: {
                        name: "doc",
                        value: EFilePathOption.DOC_DIR
                    },
                    s6: {
                        name: "ext app storage",
                        value: EFilePathOption.EXT_APP_STORAGE_DIR
                    },
                    s7: {
                        name: "ext data",
                        value: EFilePathOption.EXT_DATA_DIR
                    },
                    s8: {
                        name: "ext root data",
                        value: EFilePathOption.EXT_ROOT_DIR
                    },
                    s9: {
                        name: "shared",
                        value: EFilePathOption.SHARED_DIR
                    },
                    s10: {
                        name: "temp",
                        value: EFilePathOption.TEMP_DIR
                    }
                }
            },
            fileSavePath: {
                name: "File save path",
                value: EFilePathOption.EXT_ROOT_DIR,
                iosValue: EFilePathOption.DATA_DIR,
                codeOptions: {
                    group: SettingsGroupsDef.groups.optionsExtra.name,
                    code: ESettingsCode.savePath,
                    devOnly: true,
                    reset: true,
                    serverSync: EServerInitStatus.none,
                    position: 42
                },
                type: ESettingsType.select,
                options: {
                    s1: {
                        name: "app",
                        value: EFilePathOption.APP_DIR
                    },
                    s2: {
                        name: "app storage",
                        value: EFilePathOption.APP_STORAGE_DIR
                    },
                    s3: {
                        name: "cache",
                        value: EFilePathOption.CACHE_DIR
                    },
                    s4: {
                        name: "data",
                        value: EFilePathOption.DATA_DIR
                    },
                    s5: {
                        name: "doc",
                        value: EFilePathOption.DOC_DIR
                    },
                    s6: {
                        name: "ext app storage",
                        value: EFilePathOption.EXT_APP_STORAGE_DIR
                    },
                    s7: {
                        name: "ext data",
                        value: EFilePathOption.EXT_DATA_DIR
                    },
                    s8: {
                        name: "ext root data",
                        value: EFilePathOption.EXT_ROOT_DIR
                    },
                    s9: {
                        name: "shared",
                        value: EFilePathOption.SHARED_DIR
                    },
                    s10: {
                        name: "temp",
                        value: EFilePathOption.TEMP_DIR
                    }
                }
            }
        }
    };

    constructor(

    ) {

    }
}
