import { Injectable } from "@angular/core";
import { TimeoutService } from "./timeout";
import { LocalNotificationsService } from "../../general/apis/local-notifications";
import { LocationMonitorService } from "../../map/location-monitor";
import { ResourceManager } from "../../../classes/general/resource-manager";
import { MathUtils } from "../../../classes/general/math";
import { TimeUtils } from "../../../classes/general/time";
import { IMoveMonitorData, IMoveMonitorParams } from "../../../classes/def/core/move-monitor";
import { PhotoValidatorService } from "../utils/photo-validator";
import { ActivityUtils } from "../../../classes/utils/activity-utils";
import { UiExtensionService } from "../../general/ui/ui-extension";
import { ILocationContainer } from "../../../classes/def/places/backend-location";
import { BehaviorSubject } from "rxjs";
import { BusinessFeaturesService } from "../utils/business";
import { ExploreActivityService } from "./activities/explore";
import { MapManagerService } from "../../map/map-manager";
import { ARExploreActivityService } from "./activities/arexplore";
import { SettingsManagerService } from "../../general/settings-manager";
import { IPhotoActivityGridStats, PhotoActivityService } from "./activities/photo";
import { FindActivityService } from "./activities/find";
import { DecibelActivityService } from "./activities/decibel";
import { DanceActivityService, IDanceRTData, IDanceActivityParams } from "./activities/dance";
import { Messages } from "../../../classes/def/app/messages";
import { TutorialsService } from "./minor/tutorials";
import { IActivityParamsView, EActivityParamsViewModes, IActivityQuestSpecs, IActivityQuestResponse, IActivityVideoGuideSpecs, IActivityPhotoSpecs } from "../../../classes/def/nav-params/activity-details";
import {
    IActivity, EValidateTypes, EActivityCodes, IRunActivityDef, IEnduranceRunActivityDef, IWalkActivityDef,
    IVisitActivityDef, IDanceActivityDef, ECheckActivityResult, IDecibelActivityDef, IExploreActivityDef, IFindActivityDef, IQuestActivityDef, IMediaActivityDef
} from 'src/app/classes/def/core/activity';
import { ActivityParamsViewComponent } from 'src/app/modals/app/modals/activity-params-vc/activity-params-vc.component';
import { IDanceActivityStatus } from 'src/app/classes/def/activity/dance';
import { IDecibelRTData, IDecibelActivityParams } from 'src/app/classes/def/activity/decibel';
import { IPhotoActivityStatus, IPhotoActivityParams } from 'src/app/classes/def/activity/photo';
import { EExploreModes, EExploreObjectDynamics, IExploreActivityInit, IFindActivityInit } from 'src/app/classes/def/activity/explore';
import {
    IActivityValidateStatus, IActivityButtonsEnable, IActivityProviderContainer, EActivityExitState,
    EActivityProgressDisplayCategory, EActivityProgressDisplay, EScanCodeMode, IActivityButtonsDtreeFlags
} from 'src/app/classes/def/core/activity-manager';
import { ETimeoutStatus, ITimeoutMonitorData, ITimeoutMonitorParams } from 'src/app/classes/general/timeout';
import { MoveActivityService } from './activities/move';
import { SoundManagerService } from '../../general/apis/sound-manager';
import { ActivitiesDataService, IActivityTutorialContainer, EDroneMode } from '../../data/activities';
import { IDescriptionFrameParams, EDescriptionViewStyle } from 'src/app/modals/generic/modals/description-frame/description-frame.component';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { AnalyticsService } from '../../general/apis/analytics';
import { ECustomParamCategoryCode, ICustomParamForActivity } from 'src/app/classes/def/core/custom-param';
import { QuestActivityService } from './activities/quest';
import { IQuestActivityStatus } from 'src/app/classes/def/activity/quest';
import { LatLng } from '@ionic-native/google-maps';
import { IScanQRResultString, QRService } from '../../general/apis/qr';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { FallbackUtils } from '../../utils/fallback-utils';
import { AppSettings } from '../../utils/app-settings';
import { IPhotoResultResponse } from 'src/app/classes/def/media/processing';
import { OtherUtils } from '../../utils/other-utils';
import { EGmapDetailReturnCode } from "src/app/classes/def/nav-params/gmap";
import { AppConstants } from "src/app/classes/app/constants";
import { WaitUtils } from "../../utils/wait-utils";
import { IMediaActivityParams, IMediaActivityStatus } from "src/app/classes/def/activity/media";
import { MediaActivityService } from "./activities/media";
import { IMultiPhotoUploadSpec } from "src/app/classes/def/story/progress";
import { ExploreActivityUtilsService } from "./activities/explore-utils";
import { Util } from "src/app/classes/general/util";
import { SleepUtils } from "../../utils/sleep-utils";
import { ECheckpointCollectMode } from "src/app/classes/def/core/interactive-features";
import { ILatLng } from "src/app/classes/def/map/coords";
import { IStory } from "src/app/classes/def/core/story";

export enum EExploreMoveStat {
    moveComplete = 1,
    exploreComplete = 2,
    simulateMoveComplete = 11,
    simulateExploreComplete = 12
}

/**
 * General activity manager
 * Decodes the activities and the return values (real time display)
 * Used for activity validation especially in the gmap detail
 * 
 * Also provides entry points to activities (that should be called by the master view e.g. gmap)
 * And dispose methods
 */
@Injectable({
    providedIn: 'root'
})
export class ActivityService {

    timeouts = {
        validateExpired: null,
        ui: null
    };

    subscription = {
        monitor: null,
        monitorRealTime: null,
        location: null
    };

    validateInit: IActivityValidateStatus = {
        enable: false,
        done: false,
        failed: false,
        photoGridItemAdded: false,
        photoValidated: false,
        photoValidationFailed: false,
        questValidated: false,
        qrValidated: false,
        type: 0,
        canSwitchMapView: false
    };

    btnInit: IActivityButtonsEnable = {
        map: false,
        scan: false,
        photo: false,
        retry: false,
        return: false,
        check: false,
        skip: false,
        record: false,
        continue: false,
        navigate: false,
        start: false,
        finish: false
    };

    containerInit: IActivityProviderContainer = {
        activity: null,
        blocation: null,
        registered: false,
        scanEnable: false,
        scanType: null,
        description: "",
        enable: null,
        unlock: null,
        disabled: null,
        optional: null,
        validate: null,
        validateDisp: null
    };

    defaultClass = {
        progressBarDefault: "progress-bar-alternate",
        progressBarAccent: "progress-bar-accent",
        progressBarWarn: "progress-bar-warn"
    };

    running: IActivityProviderContainer;
    preview: IActivityProviderContainer;
    moduleName: string = "ACTIVITY MANAGER > ";

    /**
     * there can be only a single activity running at any given time
     */
    activityStarted: boolean = false;

    activityCollectType: number = null;
    activityNavCollectType: number = null;

    /**
     * watch the validation status of the current activity
     */
    statusObservable: BehaviorSubject<IActivityValidateStatus>;
    /**
     * watch the entry/exit events for any activity
     */
    exitObservable: BehaviorSubject<number>;

    exploreMoveObservable: BehaviorSubject<number>;

    updateChartsObservable: BehaviorSubject<boolean>;
    updateCharts: boolean = false;

    constructor(
        public timeoutMonitor: TimeoutService,
        public localNotifications: LocalNotificationsService,
        public locationMonitor: LocationMonitorService,
        public photoValidator: PhotoValidatorService,
        public businessFeaturesProvider: BusinessFeaturesService,
        public exploreProvider: ExploreActivityService,
        public exploreUtils: ExploreActivityUtilsService,
        public arexploreProvider: ARExploreActivityService,
        public photoActivityProvider: PhotoActivityService,
        public findActivityProvider: FindActivityService,
        public danceActivityProvider: DanceActivityService,
        public decibelActivityProvider: DecibelActivityService,
        public mediaActivityProvider: MediaActivityService,
        public questActivityProvider: QuestActivityService,
        public mapManager: MapManagerService,
        public uiext: UiExtensionService,
        public tutorials: TutorialsService,
        public moveActivityProvider: MoveActivityService,
        public soundManager: SoundManagerService,
        public activityDataService: ActivitiesDataService,
        public analytics: AnalyticsService,
        public qrSecurity: QRService
    ) {
        console.log("activity service created");
        this.statusObservable = new BehaviorSubject(null);
        this.exitObservable = new BehaviorSubject(null);
        this.updateChartsObservable = new BehaviorSubject(null);
        this.exploreMoveObservable = new BehaviorSubject(null);
        this.running = Object.assign({}, this.containerInit);
        this.preview = Object.assign({}, this.containerInit);
        this.init();
    }

    /**
     * get init container with the default values
     */
    getInitContainer() {
        let initContainer: IActivityProviderContainer = Object.assign({}, this.containerInit);
        this.formatInnerContainer(initContainer);
        // return JSON.parse(JSON.stringify(initContainer));
        return initContainer;
    }

    init() {
        this.containerInit.validate = Object.assign({}, this.validateInit);
        this.containerInit.validateDisp = {};
        this.formatInnerContainer(this.running);
        this.formatInnerContainer(this.preview);
    }

    setExploreMoveTransition(stat: number) {
        this.exploreMoveObservable.next(stat);
        this.exploreMoveObservable.next(null);
    }

    watchMoveExplore() {
        this.moveActivityProvider.getMoveActivityWatch().subscribe(async (moveData: IMoveMonitorData) => {
            if (moveData) {

            }
        });
    }

    /**
     * item auto collect when opening the AR (default values)
     */
    setAutoCollectOpenARDefault() {
        this.exploreProvider.setAutoCollectInt(false);
        this.findActivityProvider.setAutoCollectInt(false);
    }

    /**
     * item auto collect when closing the AR/return to map (default values)
     */
    setAutoCollectExitARDefault() {
        this.exploreProvider.setAutoCollectInt(true);
        this.findActivityProvider.setAutoCollectInt(true);
    }

    /**
     * set auto/collect context
     */
    setCollectContext(collectMode: number, keepCollectedCoins: boolean) {
        console.log("set collect context: ", collectMode, keepCollectedCoins);
        switch (collectMode) {
            case ECheckpointCollectMode.auto:
                // auto collect (by location)
                this.exploreUtils.setAutoCollect(true);
                this.exploreUtils.setKeepCollected(keepCollectedCoins);
                break;
            case ECheckpointCollectMode.manual:
                // collect by magnet (manual)
                this.exploreUtils.setAutoCollect(false);
                this.exploreUtils.setKeepCollected(keepCollectedCoins);
                break;
            default:
                // manual
                this.exploreUtils.setAutoCollect(false);
                this.exploreUtils.setKeepCollected(keepCollectedCoins);
                break;
        }
    }

    setActivityCollectType(type: number) {
        this.activityCollectType = type;
    }

    getActivityCollectType() {
        return this.activityCollectType;
    }

    setActivityNavCollectType(type: number) {
        this.activityNavCollectType = type;
    }

    getActivityNavCollectType() {
        return this.activityNavCollectType;
    }

    checkExploreMoveTransition(stat: number) {
        return WaitUtils.waitObsTimeout(this.exploreMoveObservable, stat, null);
    }

    /**
     * init the sub containers of the activity container
     * @param container 
     */
    formatInnerContainer(container: IActivityProviderContainer) {
        container.validate = Object.assign({}, this.validateInit);
        container.validateDisp = {};
        this.formatInnerDisplayFlags(container);
    }

    formatInnerDisplayFlags(container: IActivityProviderContainer) {
        container.enable = Object.assign({}, this.btnInit);
        container.unlock = Object.assign({}, this.btnInit);
        container.disabled = Object.assign({}, this.btnInit);
        container.optional = Object.assign({}, this.btnInit);
    }

    /**
     * deinit all resources for activities 
     */
    deinitActivity(preview: boolean) {
        console.log("deinit activity, preview: ", preview);
        this.deinitActivityMonitor(preview);

        if (preview) {
            this.preview = Object.assign({}, this.containerInit);
        } else {
            // clear the running activity
            // if (!this.running.validate.canSwitchMapView) {
            //     this.exitAll();
            // }
            this.running = Object.assign({}, this.containerInit);
            this.activityStarted = false;
        }

        this.exitObservable.next(EActivityExitState.exit);
    }

    /**
     * deinit the local observables
     * not the actual core activity observables, those keep running in their containing provider
     */
    deinitActivityMonitor(preview: boolean) {
        console.log("deinit activity monitor, preview: ", preview);
        if (preview) {
            // also clear the preview activity (redundant)
            this.preview = Object.assign({}, this.containerInit);
        } else {
            // just close the observables in the general activity manager
            // the activity providers keep running!
            this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
            this.subscription = ResourceManager.clearSubObj(this.subscription);
        }
    }

    /**
     * watch general status
     */
    watchStatus() {
        return this.statusObservable;
    }

    watchExitState() {
        return this.exitObservable;
    }

    watchChartUpdate() {
        return this.updateChartsObservable;
    }

    getDisp(preview: boolean) {
        if (!preview) {
            return this.running.validateDisp;
        } else {
            return this.preview.validateDisp;
        }
    }

    setPhotoValidated(status: boolean, preview: boolean) {
        if (preview) {
            if (this.preview) {
                this.preview.validate.photoValidated = status;
            }
        } else {
            if (this.running) {
                this.running.validate.photoValidated = status;
            }
        }
    }

    setPhotoGridItemFilled(status: boolean, preview: boolean) {
        if (preview) {
            if (this.preview) {
                this.preview.validate.photoGridItemAdded = status;
            }
        } else {
            if (this.running) {
                this.running.validate.photoGridItemAdded = status;
            }
        }
    }


    getActivityContainer(preview: boolean) {
        if (!preview) {
            return this.running;
        } else {
            return this.preview;
        }
    }

    /**
     * init
     * set current activity
     * call check activity
     * @param activity 
     * @param started check if the selected activity is running
     * @param description 
     */
    loadActivity(activity: IActivity, description: string, loc: ILocationContainer, started: boolean) {
        console.log(this.moduleName + "loaded: ", activity, ", started: ", started);

        if (started) {
            if (!this.activityStarted) {
                this.statusObservable.next(null);
                this.updateChartsObservable.next(null);
                console.log("set activity started");
                this.formatInnerContainer(this.running);
                this.running.registered = loc.merged.registered;
                this.running.activity = activity;
                this.running.blocation = loc;
                this.updateScanActivityProps(activity, loc.merged.registered, this.running);
                this.checkActivity(activity, false, started);
                this.running.description = description;
                this.activityStarted = true;
            } else {
                // activity already started
                // do nothing
                console.log("set activity already started: ", this.running);
                this.checkActivity(activity, false, started);
            }
        } else {
            console.log("set activity preview");
            this.formatInnerContainer(this.preview);
            this.preview.registered = loc.merged.registered;
            this.preview.activity = activity;
            this.preview.blocation = loc;
            this.updateScanActivityProps(activity, loc.merged.registered, this.preview);
            this.checkActivity(activity, true, started);
            this.preview.description = description;
        }
        // this.checkFlags(!started);
        if (started) {
            return this.running;
        } else {
            return this.preview;
        }
    }


    /**
     * show/hide flags/action buttons
     */
    checkFlags(previewContainer: boolean, started: boolean) {
        if (previewContainer) {
            this.checkFlagsCore(this.preview, true, started);
        } else {
            this.checkFlagsCore(this.running, false, started);
        }
    }

    /**
     * the class (preview/running) is specified by the container AND the preview flag
     * these params should be given in sync
     * @param container 
     * @param previewContainer specifiy if the preview container is used 
     */
    private checkFlagsCore(container: IActivityProviderContainer, previewContainer: boolean, started: boolean) {
        console.log("refresh buttons container init: ", container, ", preview container: ", previewContainer);
        if (!(container && container.validate)) {
            console.warn("container is not initialized!");
            return;
        }

        if (container.validate.failed) {
            this.formatInnerDisplayFlags(container);
            container.unlock.return = true;
            // no more options here
            return;
        }

        // let unlocked: boolean = previewContainer || this.activityStarted;
        // let strictUnlocked: boolean = this.activityStarted;
        let unlocked: boolean = previewContainer || started;

        console.log("unlocked: ", unlocked);
        // show if the activity requires photo validation and not already validated
        console.log("photo validate: ", container.activity.photoValidate === 1);

        let testPreview: boolean = false;
        // test activity in progress
        // testPreview = true;

        if (started || testPreview) {
            if ((container.activity.photoValidate === 1) && !container.validate.photoValidationFailed && !container.validate.photoValidated) {
                console.log("will enable photo validate");
                switch (container.activity.code) {
                    case EActivityCodes.audio:
                    case EActivityCodes.video:
                        container.unlock.record = true;
                        break;
                    default:
                        container.unlock.photo = true;
                        break;
                }
            }
            // show for distance based activities 
            // if the activity requires photo validation, hide map/return button until photo validated
            if (!(container.unlock.photo || container.unlock.record || container.unlock.retry)) {
                switch (container.validate.type) {
                    case EValidateTypes.distance:
                        console.log("enable map TRUE");
                        container.unlock.map = true;
                        break;
                    case EValidateTypes.text:
                        container.unlock.check = true;
                        break;
                    case EValidateTypes.scanCode:
                        break;
                }
            }

            if (container.validate.photoValidationFailed) {
                container.unlock.retry = true; // warning: overrides every other button in running mode
                container.unlock.photo = false;
            } else {
                container.unlock.retry = false; // reset state on reupload
            }

            // scan even if not already started? (may be redundant)
            // actually no, because we want to prevent double scan
            if (container.scanEnable) {
                container.unlock.scan = true;
            }
        }

        container.unlock.return = true; // always available if needed

        console.log("refresh buttons unlock: ", container, ", preview: ", previewContainer);
    }

    /**
    * show/hide flags/action buttons
    */
    checkFlagsDtreeOutput(previewContainer: boolean, decisions: IActivityButtonsDtreeFlags) {
        if (previewContainer) {
            this.checkFlagsDtreeOutputCore(this.preview, true, decisions);
        } else {
            this.checkFlagsDtreeOutputCore(this.running, false, decisions);
        }
    }

    checkFlagsDtreeOutputCore(container: IActivityProviderContainer, previewContainer: boolean, decisions: IActivityButtonsDtreeFlags) {
        console.log("refresh buttons container init apply: ", container, ", preview container: ", previewContainer, ", decisions: ", decisions);
        if (!(container && container.validate)) {
            console.warn("container is not initialized!");
            return;
        }

        let keys: string[] = Object.keys(container.enable);

        for (let key of keys) {
            container.enable[key] = false;
        }

        let checkRequiredQRScan = () => {
            if (decisions.isQRScanRequired) {
                if (decisions.isQRValid) {
                    container.enable.continue = true;
                } else {
                    container.enable.continue = false;
                    container.enable.scan = true;
                }
            }
        };

        if (previewContainer) {
            if (decisions.isStoryPreview) {
                // n/a
            } else {
                if (decisions.isInProgress) {
                    container.enable.continue = true;
                } else {
                    if (decisions.isBriefing) {
                        container.enable.navigate = true;
                    } else {
                        // n/a
                    }
                }
            }
        } else {
            if (decisions.canRetry) {
                container.enable.retry = true;
            } else {
                if (decisions.isInProgress) {
                    if (decisions.isPhotoChallenge) {
                        if (decisions.isPhotoGrid) {
                            if (decisions.isPhotoTaken) {
                                if (decisions.isPhotoGridFilled) {
                                    container.enable.continue = true;
                                    checkRequiredQRScan();
                                } else {
                                    container.enable.finish = true;
                                }
                            } else {
                                container.enable.skip = true;
                            }
                        } else {
                            if (decisions.isPhotoTaken) {
                                container.enable.continue = true;
                                checkRequiredQRScan();
                            } else {
                                container.enable.photo = true;
                            }
                        }
                    } else {
                        if (decisions.isQRScan) {
                            if (decisions.isQRValid) {
                                container.enable.continue = true;
                            } else {
                                container.enable.scan = true;
                            }
                        } else {
                            if (decisions.isQuestValidated) {
                                container.enable.continue = true;
                                checkRequiredQRScan();
                            } else {
                                if (decisions.canReturnToMap) {
                                    container.enable.return = true;
                                } else {
                                    if (decisions.isActivityComplete) {
                                        container.enable.continue = true;
                                    } else {
                                        container.enable.skip = true;
                                    }
                                }
                            }
                        }
                    }
                } else {
                    if (decisions.isBriefing) {
                        container.enable.navigate = true;
                    } else {
                        if (decisions.isReady) {
                            container.enable.start = true;
                        } else {
                            // container.enable.navigate = true;
                        }
                    }
                }
            }
        }

        console.log("refresh buttons: ", container, ", preview: ", previewContainer);
    }

    // activity watch entry points

    /**
     * preview: the data source (container)
     * init: init only or watch progress
     */
    watchMoveActivity(params: any, type: number, previewContainer: boolean) {
        if (previewContainer) {
            this.watchMoveActivityCore(params, type, this.preview, previewContainer);
        } else {
            this.watchMoveActivityCore(params, type, this.running, previewContainer);
        }
    }

    /**
     * validates on timeout
     * @param params 
     * @param scanEnable 
     * @param previewContainer 
     */
    watchCasualActivity(params: any, previewContainer: boolean) {
        if (previewContainer) {
            this.watchCasualActivityCore(params, this.preview, previewContainer);
        } else {
            this.watchCasualActivityCore(params, this.running, previewContainer);
        }
    }

    watchDanceActivity(params: any, previewContainer: boolean) {
        if (previewContainer) {
            this.watchDanceActivityCore(params, this.preview, previewContainer);
        } else {
            this.watchDanceActivityCore(params, this.running, previewContainer);
        }
    }

    watchDecibelActivity(params: any, previewContainer: boolean) {
        if (previewContainer) {
            this.watchDecibelActivityCore(params, this.preview, previewContainer);
        } else {
            this.watchDecibelActivityCore(params, this.running, previewContainer);
        }
    }



    watchTimeoutActivity(params: any, previewContainer: boolean) {
        if (previewContainer) {
            this.watchTimeoutActivityCore(params, this.preview, previewContainer);
        } else {
            this.watchTimeoutActivityCore(params, this.running, previewContainer);
        }
    }

    watchPhotoActivity(params: any, previewContainer: boolean) {
        if (previewContainer) {
            this.watchPhotoActivityCore(params, this.preview, previewContainer);
        } else {
            this.watchPhotoActivityCore(params, this.running, previewContainer);
        }
    }

    watchMediaActivity(params: any, previewContainer: boolean) {
        if (previewContainer) {
            this.watchMediaActivityCore(params, this.preview, previewContainer);
        } else {
            this.watchMediaActivityCore(params, this.running, previewContainer);
        }
    }


    watchARActivity(params: any, previewContainer: boolean) {
        if (previewContainer) {
            this.watchARActivityCore(params, this.preview, previewContainer);
        } else {
            this.watchARActivityCore(params, this.running, previewContainer);
        }
    }

    watchQuestActivity(params: any, previewContainer: boolean) {
        if (previewContainer) {
            this.watchQuestActivityCore(params, this.preview, previewContainer);
        } else {
            this.watchQuestActivityCore(params, this.running, previewContainer);
        }
    }


    /**
     * move activity watch core
     * @param params 
     * @param type 
     * @param container 
     * @param previewContainer 
     */
    private watchMoveActivityCore(params: any, type: number, container: IActivityProviderContainer, previewContainer: boolean) {
        console.log(this.moduleName + "check move activity, preview: ", previewContainer);
        let targetSpeed: number = 0;
        let targetDistance: number = 0;
        let timeLimit: number = 0;

        switch (type) {
            case EActivityCodes.run:
                let rap: IRunActivityDef = params;
                targetSpeed = rap.speed;
                targetDistance = rap.distance;
                timeLimit = rap.timeLimit;

                container.validateDisp = {
                    distance: {
                        name: "Target Distance",
                        value: 100,
                        raw: 0,
                        showText: true,
                        animate: true,
                        show: true,
                        category: EActivityProgressDisplayCategory.default,
                        mode: EActivityProgressDisplay.slider,
                        dispValue: MathUtils.formatDistanceDisp(targetDistance).disp
                    },
                    speed: {
                        name: "Target Speed",
                        value: 100,
                        raw: 0,
                        showText: true,
                        animate: true,
                        show: true,
                        category: EActivityProgressDisplayCategory.default,
                        mode: EActivityProgressDisplay.slider,
                        dispValue: MathUtils.formatSpeedDisp(targetSpeed).disp
                    },
                    time: {
                        name: "Time Limit",
                        value: 100,
                        raw: 0,
                        showText: true,
                        animate: true,
                        blink: true,
                        show: true,
                        highClass: this.defaultClass.progressBarAccent,
                        category: EActivityProgressDisplayCategory.default,
                        mode: EActivityProgressDisplay.slider,
                        dispValue: TimeUtils.formatTimeFromSeconds(timeLimit, true)
                    },
                    averageSpeed: {
                        name: "Average Speed",
                        value: 100,
                        raw: 0,
                        showText: false,
                        show: true,
                        category: EActivityProgressDisplayCategory.default,
                        animate: true,
                        mode: EActivityProgressDisplay.textOnly,
                        dispValue: "0"
                    }
                };
                break;
            case EActivityCodes.enduranceRun:
                let eap: IEnduranceRunActivityDef = params;
                targetSpeed = eap.speed;
                timeLimit = eap.timeLimit;
                container.validateDisp = {
                    time: {
                        name: "Target Time",
                        value: 100,
                        raw: 0,
                        showText: true,
                        animate: true,
                        show: true,
                        category: EActivityProgressDisplayCategory.default,
                        mode: EActivityProgressDisplay.slider,
                        dispValue: TimeUtils.formatTimeFromSeconds(timeLimit, true)
                    },
                    speed: {
                        name: "Target Speed",
                        value: 100,
                        raw: 0,
                        showText: true,
                        animate: true,
                        show: true,
                        category: EActivityProgressDisplayCategory.default,
                        mode: EActivityProgressDisplay.slider,
                        dispValue: MathUtils.formatSpeedDisp(targetSpeed).disp
                    },
                    distance: {
                        name: "Total Distance",
                        value: 100,
                        raw: 0,
                        showText: true,
                        animate: true,
                        show: true,
                        category: EActivityProgressDisplayCategory.default,
                        mode: EActivityProgressDisplay.textOnly,
                        dispValue: MathUtils.formatDistanceDisp(0).disp
                    },
                    averageSpeed: {
                        name: "Average Speed",
                        value: 100,
                        raw: 0,
                        showText: false,
                        animate: true,
                        show: true,
                        category: EActivityProgressDisplayCategory.default,
                        mode: EActivityProgressDisplay.textOnly,
                        dispValue: "0"
                    }
                };
                break;
            case EActivityCodes.walk:
            default:
                let wap: IWalkActivityDef = params;
                targetDistance = wap.distance;
                container.validateDisp = {
                    distance: {
                        name: "Target Distance",
                        value: 100,
                        raw: 0,
                        showText: true,
                        animate: true,
                        show: true,
                        category: EActivityProgressDisplayCategory.default,
                        mode: EActivityProgressDisplay.slider,
                        dispValue: MathUtils.formatDistanceDisp(targetDistance).disp
                    }
                };
                break;
        }

        container.validate.enable = true;
        container.validate.type = EValidateTypes.distance;

        this.setCanSwitchMapView(container, true, previewContainer);
        if (container.activity.photoValidate === 1) {
            this.setCanSwitchMapView(container, false, previewContainer);
        } else {
            this.setCanSwitchMapView(container, true, previewContainer);
        }

        if (!this.activityStarted) {
            // preview only
            return;
        }

        console.log(this.moduleName + "started");

        if (!this.subscription.monitor && !previewContainer) {
            console.log(this.moduleName + "watch started");
            this.subscription.monitor = this.moveActivityProvider.getMoveActivityWatch().subscribe((moveData: IMoveMonitorData) => {
                if (moveData) {

                    // console.log(moveData);
                    // update the initial values only if first change is detected (prevent null values)

                    if (moveData.distancePercent) {
                        container.validateDisp.distance.value = moveData.distancePercent;
                    }
                    if (moveData.distanceDisp) {
                        container.validateDisp.distance.dispValue = moveData.distanceDisp;
                    }

                    // display target speed
                    if (container.validateDisp.speed) {
                        if (moveData.speed != null) {
                            container.validateDisp.speed.value = moveData.speedPercent;
                            if (moveData.speedDisp) {
                                container.validateDisp.speed.dispValue = moveData.speedDisp;
                            }
                            this.setTargetSpeedHighlight(container, moveData.speed >= targetSpeed);
                        } else {
                            container.validateDisp.speed.value = 0;
                            container.validateDisp.speed.dispValue = MathUtils.formatSpeedDisp(targetSpeed).disp;
                            this.setTargetSpeedHighlight(container, false);
                        }
                    }

                    if (container.validateDisp.averageSpeed) {
                        container.validateDisp.averageSpeed.dispValue = MathUtils.formatSpeedDisp(moveData.averageSpeed).disp;
                    }

                    // display timer
                    if (container.validateDisp.time) {
                        if ((moveData.timerValue != null) && (timeLimit > 0)) {
                            container.validateDisp.time.raw = timeLimit - moveData.timerValue;
                            container.validateDisp.time.value = moveData.timerValue * 100 / timeLimit;
                            container.validateDisp.time.dispValue = moveData.timerDisp;
                            if (container.validateDisp.time.value < 10) {
                                container.validateDisp.time.highClass = this.defaultClass.progressBarWarn;
                            }
                        }
                    }

                    // check for timeout finished
                    switch (moveData.status) {
                        case ETimeoutStatus.expired:
                            this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
                            break;
                    }

                    this.statusObservable.next(container.validate);
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    setTargetSpeedHighlight(container: IActivityProviderContainer, highight: boolean) {
        if (highight) {
            container.validateDisp.distance.highlight = true;
            container.validateDisp.speed.highlight = true;
            container.validateDisp.distance.highClass = this.defaultClass.progressBarAccent;
            container.validateDisp.speed.highClass = this.defaultClass.progressBarAccent;
        } else {
            container.validateDisp.distance.highlight = false;
            container.validateDisp.speed.highlight = false;
            container.validateDisp.distance.highClass = this.defaultClass.progressBarDefault;
            container.validateDisp.speed.highClass = this.defaultClass.progressBarDefault;
        }
    }

    /**
     * check elapsed time
     * temp disable back button to prevent race conditions
     * @param container 
     */
    checkReturnCondition(container: IActivityProviderContainer) {
        let params: IVisitActivityDef = container.activity.params;
        if (params.timeLimit) {
            // check time elapsed 90%
            // disable back button until finished, to prevent race conditions
            // let timePercent: number = container.validateDisp.time.value;
            // if (timePercent > 90 && timePercent < 100) {
            //     container.enable.return = false;
            // }
            let timeLeft: number = container.validateDisp.time.raw;
            if (timeLeft < 10 && timeLeft > 0) {
                container.unlock.return = false;
            }
        }
    }


    /**
     * check casual activity e.g. eat, drink, visit
     * validate by time required to stay there
     * retrieve a reward on complete
     * handle QR code if enabled
     * @param scanEnable 
     */
    private watchCasualActivityCore(params: any, container: IActivityProviderContainer, previewContainer: boolean) {
        console.log(this.moduleName + "check casual activity, preview: ", previewContainer);
        let timeLimit: number = 0;

        // cast to the most general activity with just the time limit attribute
        let visitParams: IVisitActivityDef = params;
        timeLimit = visitParams.timeLimit;

        if (timeLimit > 0) {
            container.validateDisp = {
                time: {
                    name: "Time Limit",
                    value: 100,
                    raw: 0,
                    showText: true,
                    animate: true,
                    blink: true,
                    show: true,
                    highClass: this.defaultClass.progressBarAccent,
                    category: EActivityProgressDisplayCategory.default,
                    mode: EActivityProgressDisplay.slider,
                    dispValue: TimeUtils.formatTimeFromSeconds(timeLimit, true)
                }
            };
        }

        if (!this.activityStarted) {
            return;
        }

        console.log(this.moduleName + "started");
        container.validate.enable = true;

        let timeoutComplete = () => {
            this.checkNotifyActivityFinished(container);
            container.validate.done = true;
            console.log("expired");
            this.checkFlags(previewContainer, true);
            this.statusObservable.next(container.validate);
            this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
        };

        if (!this.subscription.monitor && timeLimit && !previewContainer) {
            console.log(this.moduleName + "watch started");
            this.subscription.monitor = this.timeoutMonitor.getWatch().subscribe((tmData: ITimeoutMonitorData) => {
                // console.log(tmData);
                if (tmData) {
                    if (!tmData.isFallbackTimer) {
                        if (container.validateDisp.time) {
                            container.validateDisp.time.dispValue = tmData.timerDisp;
                            if (timeLimit > 0) {
                                container.validateDisp.time.raw = timeLimit - tmData.timerValue;
                                container.validateDisp.time.value = tmData.timerValue * 100 / timeLimit;
                                if (container.validateDisp.time.value < 10) {
                                    container.validateDisp.time.highClass = this.defaultClass.progressBarWarn;
                                }
                            }
                        }
                    }

                    // wait for timeout complete
                    switch (tmData.status) {
                        case ETimeoutStatus.expired:
                            timeoutComplete();
                            break;
                        default:
                            if (container.validate.qrValidated) {
                                timeoutComplete();
                            }
                            this.statusObservable.next(container.validate);
                            break;
                    }
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }



    /**
    * check casual activity dance
    * validate by time required to stay there
    * retrieve a reward on complete
    * handle QR code if enabled
    * @param scanEnable 
    */
    private watchDanceActivityCore(params: any, container: IActivityProviderContainer, previewContainer: boolean) {
        console.log(this.moduleName + "check casual activity, preview: ", previewContainer);
        let timeLimit: number = 0;

        // cast to the most general activity with just the time limit attribute
        let activityParams: IDanceActivityDef = params;
        timeLimit = activityParams.timeLimit;

        if (timeLimit > 0) {
            container.validateDisp = {
                time: {
                    name: "Time Limit",
                    value: 100,
                    raw: 0,
                    showText: true,
                    animate: true,
                    blink: true,
                    show: true,
                    highClass: this.defaultClass.progressBarAccent,
                    category: EActivityProgressDisplayCategory.default,
                    mode: EActivityProgressDisplay.slider,
                    dispValue: TimeUtils.formatTimeFromSeconds(timeLimit, true)
                },
                beatCount: {
                    name: "Total Beats",
                    value: 100,
                    raw: 0,
                    showText: true,
                    animate: true,
                    show: true,
                    category: EActivityProgressDisplayCategory.default,
                    mode: EActivityProgressDisplay.slider,
                    dispValue: activityParams.requiredCount ? activityParams.requiredCount.toString() : "0"
                },
                beatZone: {
                    name: "Beat Zone",
                    value: 100,
                    raw: 0,
                    showText: true,
                    animate: false,
                    show: true,
                    category: EActivityProgressDisplayCategory.default,
                    mode: EActivityProgressDisplay.slider,
                    dispValue: null,
                    highlight: false
                },
                soundButton: {
                    name: "Sound Button",
                    value: 0,
                    raw: 0,
                    showText: false,
                    animate: false,
                    show: true,
                    category: EActivityProgressDisplayCategory.extra,
                    mode: EActivityProgressDisplay.soundButton,
                    dispValue: null
                },
                tapButton: {
                    name: "tap",
                    value: 0,
                    raw: 0,
                    showText: false,
                    animate: false,
                    show: true,
                    category: EActivityProgressDisplayCategory.all,
                    mode: EActivityProgressDisplay.inputButton,
                    dispValue: null,
                    callback: () => {
                        this.danceActivityProvider.tapOnTheBeat();
                    },
                    highlight: false
                }
            };
        }

        if (!this.activityStarted) {
            return;
        }

        console.log(this.moduleName + "started");
        container.validate.enable = true;

        if (!this.subscription.monitor && timeLimit && !previewContainer) {
            console.log(this.moduleName + "watch started");
            this.subscription.monitor = this.danceActivityProvider.watchStatus().subscribe((status: IDanceActivityStatus) => {
                // console.log(tmData);
                if (status) {
                    if (container.validateDisp.time) {
                        let tmData: ITimeoutMonitorData = status.tmData;
                        container.validateDisp.time.dispValue = tmData.timerDisp;
                        if (timeLimit > 0) {
                            container.validateDisp.time.raw = timeLimit - tmData.timerValue;
                            container.validateDisp.time.value = tmData.timerValue * 100 / timeLimit;
                            if (container.validateDisp.time.value < 10) {
                                container.validateDisp.time.highClass = this.defaultClass.progressBarWarn;
                            }
                        }
                    }

                    let finish: boolean = false;

                    // wait for status change events
                    switch (status.status) {
                        case ECheckActivityResult.failed:
                            container.validate.failed = true;
                            finish = true;
                            this.checkNotifyActivityFailed(container);
                            break;
                        case ECheckActivityResult.done:
                            container.validate.done = true;
                            finish = true;
                            this.checkNotifyActivityFinished(container);
                            break;
                    }

                    if (finish) {
                        this.checkFlags(previewContainer, true);
                        this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
                        this.subscription.monitorRealTime = ResourceManager.clearSub(this.subscription.monitorRealTime);
                    }

                    this.statusObservable.next(container.validate);
                }
            }, (err: Error) => {
                console.error(err);
            });

            this.subscription.monitorRealTime = this.danceActivityProvider.watchRT().subscribe((rtData: IDanceRTData) => {
                if (rtData) {

                    if (activityParams.requiredCount) {
                        container.validateDisp.beatCount.value = rtData.beatCount * 100 / activityParams.requiredCount;
                        container.validateDisp.beatCount.dispValue = rtData.beatCount + " of " + activityParams.requiredCount;
                    } else {
                        container.validateDisp.beatCount.dispValue = rtData.beatCount.toString();
                    }

                    container.validateDisp.soundButton.dispAux = rtData.rawSoundData;
                    this.updateCharts = !this.updateCharts;
                    this.updateChartsObservable.next(this.updateCharts);


                    container.validateDisp.beatZone.value = rtData.beatZone;
                    // container.validateDisp.beatZone.dispValue = rtData.beatZone.toString();

                    container.validateDisp.tapButton.highlight = rtData.beatLock;
                    container.validateDisp.beatZone.highlight = rtData.beatLock;

                    if (container.validateDisp.beatCount.value >= 100) {
                        container.validate.done = true;
                        this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
                        this.subscription.monitorRealTime = ResourceManager.clearSub(this.subscription.monitorRealTime);
                    }
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    /**
     * notify event for activities that are handled by gmap detail (single api)
     * for the other activities, the notification is handled by the gmap, because there can be combinations of activities
     * e.g. move + explore
     * @param container 
     */
    private checkNotifyActivityFinished(container: IActivityProviderContainer) {
        if (!container.validate.canSwitchMapView) {
            this.localNotifications.notify(Messages.notification.activityFinished.after.msg, Messages.notification.activityFinished.after.sub, false, null);
            this.soundManager.vibrateContext(false);
        }
    }

    /**
     * notify event for activities that are handled by gmap detail (single api)
     * for the other activities, the notification is handled by the gmap, because there can be combinations of activities
     * e.g. move + explore
     * @param container 
     */
    private checkNotifyActivityFailed(container: IActivityProviderContainer) {
        if (!container.validate.canSwitchMapView) {
            this.localNotifications.notify(Messages.notification.activityFailed.after.msg, Messages.notification.activityFailed.after.sub, false, null);
            this.soundManager.vibrateContext(false);
        }
    }


    /**
   * check casual activity decibel
   * validate by time required to stay there
   * retrieve a reward on complete
   * handle QR code if enabled
   * @param scanEnable 
   */
    private watchDecibelActivityCore(params: any, container: IActivityProviderContainer, previewContainer: boolean) {
        console.log(this.moduleName + "check casual activity, preview: ", previewContainer);
        let timeLimit: number = 0;

        // cast to the most general activity with just the time limit attribute
        let activityParams: IDecibelActivityDef = params;
        timeLimit = activityParams.timeLimit;

        let targetDB: number = activityParams.soundLevel;

        if (timeLimit > 0) {
            container.validateDisp = {
                time: {
                    name: "Time Limit",
                    value: 100,
                    raw: 0,
                    showText: true,
                    animate: true,
                    blink: true,
                    show: true,
                    highClass: this.defaultClass.progressBarAccent,
                    category: EActivityProgressDisplayCategory.default,
                    mode: EActivityProgressDisplay.slider,
                    dispValue: TimeUtils.formatTimeFromSeconds(timeLimit, true)
                },
                decibel: {
                    name: "Sound level",
                    value: 100,
                    raw: 0,
                    showText: true,
                    animate: false,
                    show: true,
                    category: EActivityProgressDisplayCategory.default,
                    mode: EActivityProgressDisplay.slider,
                    dispValue: targetDB ? targetDB.toString() : "0"
                },
                progress: {
                    name: "Duration",
                    value: 100,
                    raw: 0,
                    showText: true,
                    animate: true,
                    show: true,
                    category: EActivityProgressDisplayCategory.default,
                    mode: EActivityProgressDisplay.slider,
                    dispValue: activityParams.requiredDuration ? activityParams.requiredDuration.toString() : "0"
                },
                soundButton: {
                    name: "Sound Button",
                    value: 0,
                    raw: 0,
                    showText: false,
                    animate: false,
                    show: true,
                    category: EActivityProgressDisplayCategory.extra,
                    mode: EActivityProgressDisplay.soundButton,
                    dispValue: null
                }
            };
        }

        if (!this.activityStarted) {
            return;
        }

        console.log(this.moduleName + "started");
        container.validate.enable = true;

        if (!this.subscription.monitor && timeLimit && !previewContainer) {
            console.log(this.moduleName + "watch started");
            this.subscription.monitor = this.decibelActivityProvider.watchStatus().subscribe((status: IDanceActivityStatus) => {
                // console.log(tmData);
                if (status) {
                    if (container.validateDisp.time) {
                        let tmData: ITimeoutMonitorData = status.tmData;
                        container.validateDisp.time.dispValue = tmData.timerDisp;
                        if (timeLimit > 0) {
                            container.validateDisp.time.raw = timeLimit - tmData.timerValue;
                            container.validateDisp.time.value = tmData.timerValue * 100 / timeLimit;
                            if (container.validateDisp.time.value < 10) {
                                container.validateDisp.time.highClass = this.defaultClass.progressBarWarn;
                            }
                        }
                    }

                    let finish: boolean = false;

                    // wait for status change events
                    switch (status.status) {
                        case ECheckActivityResult.failed:
                            container.validate.failed = true;
                            finish = true;
                            this.checkNotifyActivityFailed(container);
                            break;
                        case ECheckActivityResult.done:
                            container.validate.done = true;
                            finish = true;
                            this.checkNotifyActivityFinished(container);
                            break;
                    }

                    if (finish) {
                        this.checkFlags(previewContainer, true);
                        this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
                        this.subscription.monitorRealTime = ResourceManager.clearSub(this.subscription.monitorRealTime);
                    }

                    this.statusObservable.next(container.validate);
                }
            }, (err: Error) => {
                console.error(err);
            });

            this.subscription.monitorRealTime = this.decibelActivityProvider.watchRT().subscribe((rtData: IDecibelRTData) => {
                if (rtData) {
                    if (rtData.db != null) {
                        let dbValue: number = Math.floor(rtData.db);
                        if (targetDB) {
                            container.validateDisp.decibel.value = dbValue * 100 / targetDB;
                            container.validateDisp.decibel.dispValue = dbValue + " of " + targetDB;
                        } else {
                            container.validateDisp.decibel.dispValue = dbValue.toString();
                            container.validateDisp.decibel.value = dbValue;
                        }
                    }

                    container.validateDisp.soundButton.dispAux = rtData.raw;
                    this.updateCharts = !this.updateCharts;
                    this.updateChartsObservable.next(this.updateCharts);

                    if (activityParams.requiredDuration) {
                        container.validateDisp.progress.value = rtData.timeCounter * 100 / activityParams.requiredDuration;
                        container.validateDisp.progress.dispValue = rtData.timeCounter + " of " + activityParams.requiredDuration;
                    } else {
                        container.validateDisp.progress.dispValue = rtData.timeCounter.toString();
                    }

                    // if (rtData.targetBpm) {
                    //     container.validateDisp.bpm.value = rtData.bpm * 100 / rtData.targetBpm;
                    //     container.validateDisp.bpm.dispValue = rtData.bpm + "/" + rtData.targetBpm;
                    // } else {
                    //     container.validateDisp.bpm.dispValue = rtData.bpm.toString();
                    // }
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }



    /**
     * this is opposite of casual activity
     * when the time expires the activity is failed instead of completed
     */
    private watchPhotoActivityCore(params: any, container: IActivityProviderContainer, previewContainer: boolean) {
        console.log(this.moduleName + "check photo activity, preview: ", previewContainer);
        let timeLimit: number = 0;
        let visitParams: IVisitActivityDef = params;
        timeLimit = visitParams.timeLimit;
        if (timeLimit > 0) {
            container.validateDisp = {
                time: {
                    name: "Time Limit",
                    value: timeLimit,
                    raw: 0,
                    showText: true,
                    animate: true,
                    blink: true,
                    show: true,
                    highClass: this.defaultClass.progressBarAccent,
                    category: EActivityProgressDisplayCategory.default,
                    mode: EActivityProgressDisplay.slider,
                    dispValue: TimeUtils.formatTimeFromSeconds(timeLimit, true)
                }
            };
            container.validateDisp.time.value = 100;
        }

        if (!this.activityStarted) {
            return;
        }

        console.log(this.moduleName + "started");
        container.validate.enable = true;
        if (!this.subscription.monitor && timeLimit && !previewContainer) {
            console.log(this.moduleName + "watch started");
            this.subscription.monitor = this.photoActivityProvider.watchStatus().subscribe((status: IPhotoActivityStatus) => {
                console.log("photo activity status: ", status);
                if (status) {
                    if (container.validateDisp.time) {
                        let tmData: ITimeoutMonitorData = status.tmData;
                        // console.log(tmData);
                        container.validateDisp.time.dispValue = tmData.timerDisp;
                        if (timeLimit > 0) {
                            container.validateDisp.time.raw = timeLimit - tmData.timerValue;
                            container.validateDisp.time.value = tmData.timerValue * 100 / timeLimit;
                            if (container.validateDisp.time.value < 10) {
                                container.validateDisp.time.highClass = this.defaultClass.progressBarWarn;
                            }
                        }
                    }

                    // check activity status
                    switch (status.status) {
                        case ECheckActivityResult.failed:
                            container.validate.failed = true;
                            this.checkFlags(previewContainer, true);
                            this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
                            this.checkNotifyActivityFailed(container);
                            break;
                        case ECheckActivityResult.done:
                            container.validate.done = true;
                            this.checkFlags(previewContainer, true);
                            this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
                            this.checkNotifyActivityFinished(container);
                            break;
                    }

                    this.statusObservable.next(container.validate);
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    /**
     * this is opposite of casual activity
     * when the time expires the activity is failed instead of completed
     */
    private watchMediaActivityCore(params: any, container: IActivityProviderContainer, previewContainer: boolean) {
        console.log(this.moduleName + "check media activity, preview: ", previewContainer);
        let timeLimit: number = 0;
        let visitParams: IVisitActivityDef = params;
        timeLimit = visitParams.timeLimit;
        if (timeLimit > 0) {
            container.validateDisp = {
                time: {
                    name: "Time Limit",
                    value: timeLimit,
                    raw: 0,
                    showText: true,
                    animate: true,
                    blink: true,
                    show: true,
                    highClass: this.defaultClass.progressBarAccent,
                    category: EActivityProgressDisplayCategory.default,
                    mode: EActivityProgressDisplay.slider,
                    dispValue: TimeUtils.formatTimeFromSeconds(timeLimit, true)
                }
            };
            container.validateDisp.time.value = 100;
        }

        if (!this.activityStarted) {
            return;
        }

        console.log(this.moduleName + "started");
        container.validate.enable = true;
        if (!this.subscription.monitor && timeLimit && !previewContainer) {
            console.log(this.moduleName + "watch started");
            this.subscription.monitor = this.mediaActivityProvider.watchStatus().subscribe((status: IMediaActivityStatus) => {

                if (status) {
                    if (container.validateDisp.time) {
                        let tmData: ITimeoutMonitorData = status.tmData;
                        console.log(tmData);
                        container.validateDisp.time.dispValue = tmData.timerDisp;
                        if (timeLimit > 0) {
                            container.validateDisp.time.raw = timeLimit - tmData.timerValue;
                            container.validateDisp.time.value = tmData.timerValue * 100 / timeLimit;
                            if (container.validateDisp.time.value < 10) {
                                container.validateDisp.time.highClass = this.defaultClass.progressBarWarn;
                            }
                        }
                    }

                    // check activity status
                    switch (status.status) {
                        case ECheckActivityResult.failed:
                            container.validate.failed = true;
                            this.checkFlags(previewContainer, true);
                            this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
                            this.checkNotifyActivityFailed(container);
                            break;
                        case ECheckActivityResult.done:
                            container.validate.done = true;
                            this.checkFlags(previewContainer, true);
                            this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
                            this.checkNotifyActivityFinished(container);
                            break;
                    }
                    this.statusObservable.next(container.validate);
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }


    /**
    * this is opposite of casual activity
    * when the time expires the activity is failed instead of completed
    */
    private watchARActivityCore(params: any, container: IActivityProviderContainer, previewContainer: boolean) {
        console.log(this.moduleName + "check AR activity, preview: ", previewContainer);
        let timeLimit: number = 0;
        let visitParams: IVisitActivityDef = params;
        timeLimit = visitParams.timeLimit;
        if (timeLimit > 0) {
            container.validateDisp = {
                time: {
                    name: "Time Limit",
                    value: timeLimit,
                    raw: 0,
                    showText: true,
                    animate: true,
                    blink: true,
                    show: true,
                    highClass: this.defaultClass.progressBarAccent,
                    category: EActivityProgressDisplayCategory.default,
                    mode: EActivityProgressDisplay.slider,
                    dispValue: TimeUtils.formatTimeFromSeconds(timeLimit, true)
                }
            };
            container.validateDisp.time.value = 100;
        }

        if (!this.activityStarted) {
            return;
        }

        console.log(this.moduleName + "started");
        container.validate.enable = true;
        if (!this.subscription.monitor && timeLimit && !previewContainer) {
            console.log(this.moduleName + "watch started");
            this.subscription.monitor = this.arexploreProvider.watchStatus().subscribe((status: IPhotoActivityStatus) => {

                if (status) {
                    if (container.validateDisp.time) {
                        let tmData: ITimeoutMonitorData = status.tmData;
                        container.validateDisp.time.dispValue = tmData.timerDisp;
                        if (timeLimit > 0) {
                            container.validateDisp.time.raw = timeLimit - tmData.timerValue;
                            container.validateDisp.time.value = tmData.timerValue * 100 / timeLimit;
                            if (container.validateDisp.time.value < 10) {
                                container.validateDisp.time.highClass = this.defaultClass.progressBarWarn;
                            }
                        }
                    }

                    // check activity status
                    switch (status.status) {
                        case ECheckActivityResult.failed:
                            container.validate.failed = true;
                            this.checkFlags(previewContainer, true);
                            this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
                            this.checkNotifyActivityFailed(container);
                            break;
                        case ECheckActivityResult.done:
                            container.validate.done = true;
                            this.checkFlags(previewContainer, true);
                            this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
                            this.checkNotifyActivityFinished(container);
                            break;
                    }
                    this.statusObservable.next(container.validate);
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }


    /**
    * this is opposite of casual activity
    * when the time expires the activity is failed instead of completed
    */
    private watchQuestActivityCore(params: any, container: IActivityProviderContainer, previewContainer: boolean) {
        console.log(this.moduleName + "check quest activity, preview: ", previewContainer);
        let timeLimit: number = 0;
        let visitParams: IVisitActivityDef = params;
        timeLimit = visitParams.timeLimit;
        if (timeLimit > 0) {
            container.validateDisp = {
                time: {
                    name: "Time Limit",
                    value: timeLimit,
                    raw: 0,
                    showText: true,
                    animate: true,
                    blink: true,
                    show: true,
                    highClass: this.defaultClass.progressBarAccent,
                    category: EActivityProgressDisplayCategory.default,
                    mode: EActivityProgressDisplay.slider,
                    dispValue: TimeUtils.formatTimeFromSeconds(timeLimit, true)
                },
                reward: {
                    name: "Reward",
                    value: visitParams.standardRewardCap,
                    raw: 0,
                    showText: true,
                    animate: true,
                    show: true,
                    category: EActivityProgressDisplayCategory.default,
                    mode: EActivityProgressDisplay.sliderLMH,
                    // dispValue: visitParams.standardRewardCap.toString() + "%"
                    dispValue: "100%"
                }
            };
            container.validateDisp.time.value = 100;
            container.validateDisp.reward.value = 100;
        }

        if (!this.activityStarted) {
            return;
        }

        console.log(this.moduleName + "started");
        container.validate.enable = true;
        container.validate.type = EValidateTypes.text;

        let onFailed = () => {
            container.validate.failed = true;
            this.checkFlags(previewContainer, true);
            this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
            this.checkNotifyActivityFailed(container);
            this.statusObservable.next(container.validate);
        };

        if (!this.subscription.monitor && timeLimit && !previewContainer) {
            console.log(this.moduleName + "watch started");
            this.subscription.monitor = this.questActivityProvider.watchStatus().subscribe((status: IQuestActivityStatus) => {
                if (status) {
                    // console.log("status: ", status);
                    if (container.validateDisp.time) {
                        let tmData: ITimeoutMonitorData = status.tmData;
                        container.validateDisp.time.dispValue = tmData.timerDisp;
                        if (timeLimit > 0) {
                            container.validateDisp.time.raw = timeLimit - tmData.timerValue;
                            container.validateDisp.time.value = tmData.timerValue * 100 / timeLimit;
                            if (container.validateDisp.time.value < 10) {
                                container.validateDisp.time.highClass = this.defaultClass.progressBarWarn;
                            }
                        }
                    }

                    if (container.validateDisp.reward) {
                        container.validateDisp.reward.value = 100 - status.penalty;
                        container.validateDisp.reward.dispValue = container.validateDisp.reward.value.toString() + "%";
                    }

                    // check activity status
                    switch (status.status) {
                        case ECheckActivityResult.failed:
                            onFailed();
                            break;
                        case ECheckActivityResult.done:
                            // if (!(container.scanEnable && this.checkScanAfter(container.activity)) || container.validate.qrValidated) {
                            //     // scan is not required, set validate flag
                            //     container.validate.done = true;
                            //     this.checkFlags(previewContainer, true);
                            //     this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
                            //     this.questActivityProvider.validate();
                            //     this.statusObservable.next(container.validate);
                            // } else {
                            //     this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
                            //     // request scan QR code
                            //     this.scanBarcodeWizardNoAction(container.activity, container.blocation, true, true, () => {
                            //         container.validate.done = true;
                            //         if (container) {
                            //             this.questActivityProvider.validate();
                            //             this.statusObservable.next(container.validate);
                            //         }
                            //     }, () => {
                            //         this.questActivityProvider.triggerFailed();
                            //         onFailed();
                            //     }, true, container.scanType);
                            // }
                            container.validate.done = true;
                            this.checkFlags(previewContainer, true);
                            this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
                            this.questActivityProvider.validate();
                            this.statusObservable.next(container.validate);
                            break;
                        default:
                            this.statusObservable.next(container.validate);
                            break;
                    }

                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    /**
     * this is opposite of casual activity
     * when the time expires the activity is failed instead of completed
     */
    private watchTimeoutActivityCore(params: any, container: IActivityProviderContainer, previewContainer: boolean) {
        console.log("check timeout activity, preview: ", previewContainer);
        let timeLimit: number = 0;
        let visitParams: IVisitActivityDef = params;
        timeLimit = visitParams.timeLimit;
        if (timeLimit > 0) {
            container.validateDisp = {
                time: {
                    name: "Time Limit",
                    value: timeLimit,
                    raw: 0,
                    showText: true,
                    animate: true,
                    blink: true,
                    show: true,
                    highClass: this.defaultClass.progressBarAccent,
                    category: EActivityProgressDisplayCategory.default,
                    mode: EActivityProgressDisplay.slider,
                    dispValue: TimeUtils.formatTimeFromSeconds(timeLimit, true)
                }
            };
            container.validateDisp.time.value = 100;
        }

        if (!this.activityStarted) {
            return;
        }

        console.log("started");
        container.validate.enable = true;
        if (!this.subscription.monitor && timeLimit && !previewContainer) {
            console.log("watch started");
            this.subscription.monitor = this.timeoutMonitor.getWatch().subscribe((tmData: ITimeoutMonitorData) => {
                // console.log(tmData);
                if (tmData) {
                    if (!tmData.isFallbackTimer) {
                        if (container.validateDisp.time) {
                            container.validateDisp.time.dispValue = tmData.timerDisp;
                            if (timeLimit > 0) {
                                container.validateDisp.time.raw = timeLimit - tmData.timerValue;
                                container.validateDisp.time.value = tmData.timerValue * 100 / timeLimit;
                                if (container.validateDisp.time.value < 10) {
                                    container.validateDisp.time.highClass = this.defaultClass.progressBarWarn;
                                }
                            }
                        }
                    }

                    // wait for timeout complete
                    switch (tmData.status) {
                        case ETimeoutStatus.expired:
                            this.checkNotifyActivityFailed(container);
                            container.validate.failed = true;
                            this.checkFlags(previewContainer, true);
                            this.subscription.monitor = ResourceManager.clearSub(this.subscription.monitor);
                            break;
                    }
                    this.statusObservable.next(container.validate);
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    private setCanSwitchMapView(container: IActivityProviderContainer, flag: boolean, preview: boolean) {
        container.validate.canSwitchMapView = flag;
        if (!preview) {
            container.unlock.map = flag;
        }
    }

    /**
     * check activity
     * start activity monitor
     * or just preview the static info (switching between locations during the game)
     */
    checkActivity(activity: IActivity, previewContainer: boolean, started: boolean) {
        let globalValidationEnable: boolean = !SettingsManagerService.settings.app.settings.noActivityValidation.value;
        if (globalValidationEnable || previewContainer) {
            if (activity) {
                let container: IActivityProviderContainer = previewContainer ? this.preview : this.running;
                // needs refresh here to update the scan flag
                this.checkFlags(previewContainer, started);
                let baseActivityCode: number = ActivityUtils.checkSimilarActivity(container.activity);
                console.log("check activity: ", container.activity.name, ", base: ", baseActivityCode, ", scan enabled: ", container.scanEnable, ", preview: ", previewContainer);
                this.setCanSwitchMapView(container, false, previewContainer);
                let visitParams: IVisitActivityDef = activity.params;
                console.log("params: ", visitParams);
                // check the activity
                // if the activity requires photo validation it will act accordingly 
                // (e.g. the distance will not update until validated, etc)
                switch (baseActivityCode) {
                    case EActivityCodes.run:
                        let runParams: IRunActivityDef = activity.params;
                        this.setCanSwitchMapView(container, true, previewContainer);
                        this.watchMoveActivity(runParams, baseActivityCode, previewContainer);
                        break;
                    case EActivityCodes.enduranceRun:
                        let enduranceRunParams: IEnduranceRunActivityDef = activity.params;
                        this.setCanSwitchMapView(container, true, previewContainer);
                        this.watchMoveActivity(enduranceRunParams, baseActivityCode, previewContainer);
                        break;
                    case EActivityCodes.walk:
                        let walkParams: IWalkActivityDef = activity.params;
                        this.setCanSwitchMapView(container, true, previewContainer);
                        this.watchMoveActivity(walkParams, baseActivityCode, previewContainer);
                        break;
                    case EActivityCodes.explore:
                        // handled by the map
                        let exploreParams: IExploreActivityDef = activity.params;
                        this.setCanSwitchMapView(container, true, previewContainer);
                        this.watchCasualActivity(exploreParams, previewContainer); // for time display 
                        break;
                    case EActivityCodes.escape:
                        // handled by the map
                        let escapeParams: IExploreActivityDef = activity.params;
                        this.setCanSwitchMapView(container, true, previewContainer);
                        this.watchCasualActivity(escapeParams, previewContainer); // for time display 
                        break;
                    case EActivityCodes.find:
                        // handled by the map
                        let findParams: IFindActivityDef = activity.params;
                        this.setCanSwitchMapView(container, true, previewContainer);
                        this.watchCasualActivity(findParams, previewContainer); // for time display 
                        break;
                    case EActivityCodes.snapshot:
                        let snapshotParams: IPhotoActivityParams = activity.params;
                        this.setCanSwitchMapView(container, true, previewContainer);
                        this.watchPhotoActivity(snapshotParams, previewContainer);
                        break;
                    case EActivityCodes.photo:
                        let photoParams: IPhotoActivityParams = activity.params;
                        this.setCanSwitchMapView(container, false, previewContainer);
                        this.watchPhotoActivity(photoParams, previewContainer);
                        break;
                    case EActivityCodes.screenshotAR:
                        this.setCanSwitchMapView(container, true, previewContainer);
                        this.watchARActivity(visitParams, previewContainer); // for time display
                        break;
                    case EActivityCodes.audio:
                    case EActivityCodes.video:
                        let mediaParams: IMediaActivityDef = activity.params;
                        this.setCanSwitchMapView(container, false, previewContainer);
                        this.watchMediaActivity(mediaParams, previewContainer);
                        break;
                    case EActivityCodes.dance:
                        let danceParams: IDanceActivityDef = activity.params;
                        this.setCanSwitchMapView(container, false, previewContainer);
                        this.watchDanceActivity(danceParams, previewContainer); // for time display 
                        break;
                    case EActivityCodes.decibel:
                        let decibelParams: IDecibelActivityDef = activity.params;
                        this.setCanSwitchMapView(container, false, previewContainer);
                        this.watchDecibelActivity(decibelParams, previewContainer); // for time display 
                        break;
                    case EActivityCodes.quest:
                        let questParams: IQuestActivityDef = activity.params;
                        this.setCanSwitchMapView(container, true, previewContainer);
                        container.unlock.map = false;
                        container.optional.skip = true;
                        this.watchQuestActivity(questParams, previewContainer); // for time display 
                        break;
                    case EActivityCodes.eat:
                    case EActivityCodes.drink:
                    case EActivityCodes.visit:
                    case EActivityCodes.watch:
                        this.setCanSwitchMapView(container, false, previewContainer);
                        this.watchCasualActivity(visitParams, previewContainer); // for time display
                        break;
                    default:
                        this.setCanSwitchMapView(container, false, previewContainer);
                        this.watchCasualActivity(visitParams, previewContainer); // for time display
                        break;
                }
            }
        }
    }


    // general purpose

    /**
    * qr code scan for registered locations
    */
    private updateScanActivityProps(activity: IActivity, registeredPlace: boolean, container: IActivityProviderContainer) {
        let scanEnable: boolean = false;
        if ((activity.scan != null) && (activity.scan !== EScanCodeMode.none)) {
            if (registeredPlace || activity.qrCode) {
                container.disabled.scan = false;
                container.validate.enable = true;
                scanEnable = true;
            } else {
                container.disabled.scan = true;
                container.validate.enable = false;
            }

            switch (activity.scan) {
                case EScanCodeMode.required:
                    container.validate.type = EValidateTypes.scanCode;
                    break;
                default:
                    break;
            }
        }

        container.scanEnable = scanEnable;
        container.scanType = activity.scan;
    }

    // checkScanBefore(activity: IActivity) {
    //     return [EScanCodeMode.required, EScanCodeMode.optional].indexOf(activity.scan) !== -1;
    // }

    // checkScanAfter(activity: IActivity) {
    //     return [EScanCodeMode.required, EScanCodeMode.optional].indexOf(activity.scan) !== -1;
    // }

    validateQuest(resp: IActivityQuestResponse, coords: ILatLng, test: boolean, questData: IActivityQuestSpecs) {
        return this.questActivityProvider.submitResponse(resp, coords, test, questData);
    }

    showQuestValidateFeedback(match: boolean) {
        return this.questActivityProvider.showValidateFeedback(match);
    }

    confirmValidateQuest() {
        return this.questActivityProvider.confirmValidate();
    }

    checkConfirmValidateQuestComplete() {
        return this.questActivityProvider.checkConfirmValidateComplete();
    }

    checkQuestAction(action: number, apply: boolean) {
        return this.questActivityProvider.checkAction(action, apply);
    }

    validateMedia(preview: boolean) {
        let container: IActivityProviderContainer;
        if (!preview) {
            container = this.running;
        } else {
            container = this.preview;
        }
        console.log("validate done > media");
        container.validate.done = true;
        return this.mediaActivityProvider.validate();
    }

    validatePhotoGridPartial(preview: boolean) {
        this.validatePhotoGridPartialCore(preview ? this.preview : this.running);
    }

    /**
     * validate photo activity
     * this actually triggers the photo validation which will be retrieved by the activity watch
     * @param isRetry 
     * @param preview 
     */
    validatePhoto(isRetry: boolean, preview: boolean, uploadFromGallery: boolean): Promise<IPhotoResultResponse> {
        let promise: Promise<IPhotoResultResponse> = new Promise((resolve, reject) => {
            if (!preview) {
                this.validatePhotoCore(this.running, isRetry, uploadFromGallery).then((res: IPhotoResultResponse) => {
                    resolve(res);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                if (AppSettings.testerMode) {
                    this.validatePhotoCore(this.preview, isRetry, uploadFromGallery).then((res: IPhotoResultResponse) => {
                        resolve(res);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    reject(new Error("cannot validate preview activity"));
                }
            }
        });
        return promise;
    }

    /**
     * proceed to photo validation
     * @param isRetry 
     * @param container 
     */
    private validatePhotoCore(container: IActivityProviderContainer, isRetry: boolean, uploadFromGallery: boolean): Promise<IPhotoResultResponse> {
        let promise: Promise<IPhotoResultResponse> = new Promise((resolve, reject) => {
            if (this.activityStarted) {
                // check if the photo is already validated
                if (this.photoValidator.checkPhotoValidateFlag(container.activity)) {
                    container.validate.enable = true;
                    container.validate.done = false;
                }
            }
            this.photoActivityProvider.validatePhoto(isRetry, uploadFromGallery).then((res: IPhotoResultResponse) => {
                console.log("validate photo complete: ", res.valid);
                if (res && res.valid) {
                    console.log("validate done > photo core");
                    container.validate.done = true; // can return to map now to resume the activity
                    container.validate.photoValidationFailed = false;
                    this.checkFlags(false, true);
                    resolve(res);
                } else {
                    container.validate.photoValidationFailed = true;
                    this.checkFlags(false, true);
                    resolve(null);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
    * validate photo activity
    * this actually triggers the photo validation which will be retrieved by the activity watch
    * @param isRetry 
    * @param preview 
    */
    validatePhotoGrid(preview: boolean, photoUploads: IMultiPhotoUploadSpec[]): Promise<IPhotoActivityGridStats> {
        let promise: Promise<IPhotoActivityGridStats> = new Promise((resolve, reject) => {
            if (!preview) {
                this.validatePhotoGridCore(this.running, photoUploads).then((res: IPhotoActivityGridStats) => {
                    resolve(res);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                if (AppSettings.testerMode) {
                    this.validatePhotoGridCore(this.preview, photoUploads).then((res: IPhotoActivityGridStats) => {
                        resolve(res);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    reject(new Error("cannot validate preview activity"));
                }
            }
        });
        return promise;
    }

    validatePhotoGridPartialCore(container: IActivityProviderContainer) {
        container.validate.done = true; // can return to map now to resume the activity
    }

    /**
    * proceed to photo validation
    * @param isRetry 
    * @param container 
    */
    private validatePhotoGridCore(container: IActivityProviderContainer, photoUploads: IMultiPhotoUploadSpec[]): Promise<IPhotoActivityGridStats> {
        let promise: Promise<IPhotoActivityGridStats> = new Promise((resolve, reject) => {
            if (this.activityStarted) {
                // check if the photo is already validated
                if (this.photoValidator.checkPhotoValidateFlag(container.activity)) {
                    container.validate.enable = true;
                    container.validate.done = false;
                }
            }
            this.photoActivityProvider.validatePhotoGrid(photoUploads).then((res: IPhotoActivityGridStats) => {
                console.log("validate photo complete: ", res);
                if (res.gridFilled) {
                    // grid filled
                    console.log("validate done > photo core");
                    container.validate.done = true; // can return to map now to resume the activity
                    container.validate.photoValidationFailed = false;
                    this.checkFlags(false, true);
                    resolve(res);
                } else {
                    container.validate.done = false; // not all photos validated yet
                    container.validate.photoValidationFailed = false;
                    container.validate.photoGridItemAdded = res.gridPhotoValidated;
                    this.checkFlags(false, true);
                    resolve(res);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    validatePhotoUploadAccepted(validated: boolean, preview: boolean) {
        this.validatePhotoUploadAcceptedCore(validated, preview ? this.preview : this.running);
    }

    validatePhotoUploadAcceptedCore(validated: boolean, container: IActivityProviderContainer) {
        if (!container.validate.done || validated) {
            // if not already validated or validation status set true
            console.log("validate done > photo upload accepted");
            container.validate.done = validated; // may return to map now to complete the activity
            container.validate.photoValidationFailed = false;
        }
    }

    /**
     * 
     * @param activity 
     * @param backendLocation 
     * @param retry 
     * @param apply apply callbacks
     * @param onValidate should trigger activity complete
     * @param onFail should trigger activity failed
     * @param prompt 
     * @param scanType 
     */
    async scanBarcodeWizardNoAction(activity: IActivity, backendLocation: ILocationContainer, retry: boolean, apply: boolean, onValidate: () => any, onFail: () => any, prompt: boolean, scanType: number) {

        let checkHandleApply = () => {
            if (apply) {
                if (onValidate != null) {
                    onValidate();
                } else {
                    console.log("validate done > scan barcode");
                    this.running.validate.done = true;
                }
            }
        };

        let checkHandleFail = () => {
            if (apply) {
                if (onFail != null) {
                    onFail();
                } else {
                    this.running.validate.failed = true;
                }
            }
        };

        try {
            if (prompt) {
                if (scanType === EScanCodeMode.required) {
                    // scan is required
                    await this.uiext.showAlert(Messages.msg.qrScanRequired.before.msg, Messages.msg.qrScanRequired.before.sub, 1, null, true);
                } else {
                    // scan is optional
                    let res: number = await this.uiext.showAlert(Messages.msg.qrScanRequired.before.msg, Messages.msg.qrScanRequired.before.sub, 2, null, true);
                    if (res !== EAlertButtonCodes.ok) {
                        // fake validate scan to proceed
                        checkHandleApply();
                        return;
                    }
                }
            }

            this.scanBarcodeWizard(activity, backendLocation, retry).then((res: boolean) => {
                if (res || (scanType === EScanCodeMode.optional)) {
                    checkHandleApply();
                } else {
                    checkHandleFail();
                }
            }).catch(async (err: Error) => {
                await this.uiext.showAlert(Messages.msg.registerClientError.after.msg, ErrorMessage.parse(err, Messages.msg.registerClientError.after.sub), 2, null, true);
                checkHandleFail();
            });
        } catch (err) {
            console.error(err);
            await this.uiext.showAlert(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub, 2, null, true);
            checkHandleFail();
        }
    }

    scanBarcodeWizard(activity: IActivity, backendLocation: ILocationContainer, retry: boolean) {
        if (retry) {
            return new Promise((resolve) => {
                FallbackUtils.retry(() => {
                    return this.scanBarcodeWizardCore(activity, backendLocation);
                }, 0, 100, () => {
                    return new Promise<boolean>((resolve) => {
                        this.uiext.showAlert(Messages.msg.registerClientError.after.msg, Messages.msg.registerClientError.after.sub + "<p>You may try again</p>", 2, ["quit", "retry"], true).then((res: number) => {
                            if (res === EAlertButtonCodes.ok) {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        }).catch((err: Error) => {
                            console.error(err);
                            resolve(false);
                        });
                    });
                }, null).then(async (result: boolean) => {
                    resolve(result);
                }).catch((err) => {
                    // quit retry, resolve not validated
                    console.error(err);
                    resolve(false);
                });
            });
        } else {
            return this.scanBarcodeWizardCore(activity, backendLocation);
        }
    }

    scanBarcodeWizardCore(activity: IActivity, backendLocation: ILocationContainer): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {
                let validated: boolean = false;
                if (activity.qrCode != null) {
                    // validate activity qr code
                    validated = await this.scanBarcodeSloc(activity.qrCode, activity.qrMode);
                } else {
                    // validate / register location client
                    validated = await this.scanBarcodeBLocation(backendLocation);
                }
                resolve(validated);
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     * scan qr code for locations that require this feature
     */
    scanBarcodeBLocation(backendLocation: ILocationContainer): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.businessFeaturesProvider.scanQrRegisterClientBLocation(backendLocation).then(async () => {
                await this.uiext.showRewardPopupQueue("", "Validated ", null, false, 1000);
                // this.running.validate.done = true;
                resolve(true);
            }).catch((err: Error) => {
                reject(new Error(ErrorMessage.parse(err, Messages.msg.registerClientError.after.sub)));
            });
        });
    }

    /**
     * scan qr code for locations that require this feature
     */
    scanBarcodeSloc(ref: string, mode: number): Promise<boolean> {
        return new Promise((resolve, reject) => {
            this.qrSecurity.scanQRResult(true, "Scan QR code", null).then(async (data: IScanQRResultString) => {
                // console.log("scan QR code result: ", data);
                // console.log("expected: " + ref);
                if ((data.scan && data.code === ref) || (!data.scan && (data.code.toLowerCase() === ref.toLowerCase()))) {
                    await this.uiext.showRewardPopupQueue("", "Validated ", null, false, 1000);
                    if (mode === 1) {
                        // open link
                        let res: number = await this.uiext.showAlert(Messages.msg.QRCodeOpenLink.before.msg, Messages.msg.QRCodeOpenLink.before.sub, 2, null, true);
                        if (res === EAlertButtonCodes.ok) {
                            // open link
                            Util.openURLAdaptive(ref);
                            await SleepUtils.sleep(2000);
                            await this.uiext.showAlert("", "Checked", 1, ["ok"], true);
                        }
                    }
                    // this.running.validate.done = true;
                    resolve(true);
                } else {
                    reject(new Error(Messages.msg.registerClientError.after.sub));
                }
            }).catch((err: Error) => {
                reject(new Error(ErrorMessage.parse(err, Messages.msg.registerClientError.after.sub)));
            });
        });
    }


    // init methods

    /**
     * initialize the location monitor with the move params
     */
    initMoveActivity(moveParams: IMoveMonitorParams) {
        this.moveActivityProvider.resetDistanceWatch(SettingsManagerService.settings.app.settings.moveActivityTest.value);
        this.moveActivityProvider.start(moveParams);
    }

    initQuestActivity(qad: IQuestActivityDef, questData: IActivityQuestSpecs) {
        this.questActivityProvider.initActivity(qad, questData);
    }

    /**
     * initialize generic casual activity
     * when no other activity is selected
     */
    initCasualActivity(timeLimit: number) {
        let tmParams: ITimeoutMonitorParams = {
            timeLimit: timeLimit
        };

        this.timeoutMonitor.start(tmParams);
    }

    /**
     * initialize the timeout monitor with the timer preset
     */
    initGenericTimeoutActivity(timeLimit: number) {
        let tmParams: ITimeoutMonitorParams = {
            timeLimit: timeLimit
        };

        this.timeoutMonitor.start(tmParams);
    }

    initPhotoActivity(params: IPhotoActivityParams) {
        this.photoActivityProvider.initActivity(params);
    }

    initDanceActivity(params: IDanceActivityDef) {
        this.danceActivityProvider.initActivity(params.timeLimit);
        let dparams: IDanceActivityParams = {
            targetBpm: params.BPM,
            targetCount: params.requiredCount,
            beatZoneThreshold: 60
        };
        this.danceActivityProvider.setActivityParams(dparams);
        this.danceActivityProvider.startMonitor();
    }

    initDecibelActivity(params: IDecibelActivityDef) {
        this.decibelActivityProvider.initActivity(params.timeLimit);
        let dparams: IDecibelActivityParams = {
            targetDB: params.soundLevel,
            targetDuration: params.requiredDuration
        };
        this.decibelActivityProvider.setActivityParams(dparams);
        this.decibelActivityProvider.startMonitor();
    }

    initMediaActivity(params: IMediaActivityDef) {
        let dparams: IMediaActivityParams = {
            timeLimit: params.timeLimit
        };

        this.mediaActivityProvider.initActivity(dparams);
    }


    /**
     * start coin generator and collector
     * @param params 
     */
    initExploreActivityStage2(params: IExploreActivityInit) {
        return this.exploreProvider.initStage2(params);
    }

    /**
     * initialize the explore provider and the timeout generic activity
     */
    initExploreActivityStage1(params: IExploreActivityInit) {
        params = this.exploreProvider.initStage1(params);
        if (params.timeLimit) {
            this.initGenericTimeoutActivity(params.timeLimit);
        }
        return params;
    }

    getExploreActivityInitNav(coinCap: number) {
        let exploreInitParams: IExploreActivityInit = {
            timeLimit: 0,
            startTime: 0,
            coinCap: coinCap ? coinCap : null,
            coinRadius: null,
            collectDistance: AppConstants.gameConfig.collectDistance,
            objectDynamics: EExploreObjectDynamics.static,
            exploreMode: EExploreModes.fixed,
            useCheckpoints: false, // explore mode
            activeInventoryItems: [],
            currentLocation: null,
            // activeInventoryItems: this.activeInventoryItems,
            // currentLocation: this.virtualPositionService.getCurrentPosition(),
            targetSpeed: 0,
            minRadius: 0,
            randomCoins: true,
            minCollectedCoins: null,
            evadeRadius: null,
            isMainObjective: false,
            syncData: null,
            // syncData: preparedCoinSpecs,
            singleTarget: false,
            publishSyncData: false,
            timeGauge: false, // handled by move activity
            collectGauge: false, // no more slots
            evadeGauge: false,
            fixedCoins: [],
            fixedCoinCap: false
            // fixedCoins: activity.fixedCoins
        };
        return exploreInitParams;
    }


    /**
     * initialize the find provider and the timeout generic activity
     */
    initFindActivity(params: IFindActivityInit) {
        // if (params.timeLimit) {
        //     this.initGenericTimeoutActivity(params.timeLimit);
        // }
        return params;
    }


    /**
     * stop the explore provider
     * @param isMain check main activity context
     */
    async exitExploreActivity(isMain: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            this.exploreProvider.exitExploreActivity(true).then(() => {
                if (isMain) {
                    this.deinitActivity(false);
                }
                resolve(true);
            });
        });
        return promise;
    }

    exitMoveActivity() {
        this.moveActivityProvider.stop();
        this.deinitActivity(false);
    }

    /**
     * stop the timeout monitor
     */
    exitGenericTimeoutActivity() {
        console.log("exit generic timeout activity");
        console.log("stopping timer");
        this.timeoutMonitor.stop();
        this.deinitActivity(false);
    }

    exitPhotoActivity() {
        this.photoActivityProvider.exitActivity();
    }

    async exitFindActivity(): Promise<boolean> {
        return this.findActivityProvider.exitFindActivity();
    }

    exitDanceActivity() {
        this.danceActivityProvider.stopMonitor();
        this.danceActivityProvider.exitActivity();
    }

    exitDecibelActivity() {
        this.decibelActivityProvider.stopMonitor();
        this.decibelActivityProvider.exitActivity();
    }

    exitQuestActivity() {
        this.questActivityProvider.exitActivity();
    }

    /**
     * stop all activities
     */
    async exitAll(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            console.log(this.moduleName + "exit all");
            await this.exitExploreActivity(true);
            this.exitMoveActivity();
            this.exitPhotoActivity();
            await this.exitFindActivity();
            this.exitGenericTimeoutActivity();
            this.exitDanceActivity();
            this.exitDecibelActivity();
            this.arexploreProvider.exitActivity();
            this.exitQuestActivity();
            this.triggerActivityExit();
            resolve(true);
        });
        return promise;
    }

    triggerActivitySkip() {
        console.log(this.moduleName + " trigger skip");
        this.exitObservable.next(EActivityExitState.skip);
        this.exitObservable.next(null);
    }

    triggerActivityExit() {
        console.log(this.moduleName + " trigger exit");
        this.exitObservable.next(EActivityExitState.exit);
        this.exitObservable.next(null);
    }

    /**
     * show activity tutorial
     * handle drone mode entry
     * resolve enterDrone option
     * @param activity 
     * @param withDrone 
     */
    showActivityTutorialResolve(activity: IActivity, withDrone: number, autostart: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            this.activityDataService.getActivityTutorials(activity.code).then((tutorials: IActivityTutorialContainer) => {

                if (withDrone == null) {
                    withDrone = EDroneMode.withDrone;
                }

                let params: IDescriptionFrameParams = {
                    title: activity.title + " Challenge",
                    description: tutorials.spec.description,
                    mode: withDrone !== EDroneMode.noDrone ? EDescriptionViewStyle.withCustomButtonArray : EDescriptionViewStyle.withOk,
                    photoUrl: tutorials.spec.photoUrl != null ? tutorials.spec.photoUrl : activity.photoUrl,
                    loaderCode: null,
                    cooldown: autostart ? 10 : null,
                    buttons: []
                };

                params.buttons = OtherUtils.buildDroneModeButtons(withDrone);

                this.tutorials.showTutorialResolve(null, null, null, params, false).then((res: number) => {
                    if (res === EGmapDetailReturnCode.drone) {
                        resolve(withDrone !== EDroneMode.noDrone);
                    } else {
                        resolve(false);
                    }
                });
            }).catch((err: Error) => {
                this.analytics.dispatchError(err, "activity");
                resolve(false);
            });
        });
        return promise;
    }

    /**
     * view tutorial 
     * showing custom params view if existing
     */
    viewTutorial(activity: IActivity, blocation: ILocationContainer, scope: number) {
        if (activity.customParams) {
            this.viewCustomActivityParams(activity, blocation, scope);
        } else {
            this.showDescription(activity, blocation);
        }
    }

    /**
     * view custom params for the activity
     */
    viewCustomActivityParams(activity: IActivity, loc: ILocationContainer, scope: number) {
        this.uiext.showCustomModal(null, ActivityParamsViewComponent, {
            view: {
                fullScreen: false,
                transparent: false,
                large: true,
                addToStack: true,
                frame: false
            },
            params: this.getCustomActivityParams(activity, loc, scope, null)
        }).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * get activity params view
     * format custom param details (e.g. quest content, photo content)
     * @param activity 
     * @param loc 
     * @param scope 
     * @returns 
     */
    getCustomActivityParams(activity: IActivity, loc: ILocationContainer, scope: number, story: IStory): IActivityParamsView {
        let params: IActivityParamsView = {
            title: "Details",
            photoUrl: "",
            customParams: [],
            customParamsNav: [], // caller should use this function with activityNav, then add the customParams here

            questData: null,
            multiQuestData: [],

            photoData: null,
            multiPhotoData: [],

            mode: EActivityParamsViewModes.before,
            videoGuideSpecs: {
                before: null,
                target: null,
                after: null
            },
            scope: scope
        };
        if (activity.customParams) {
            params.customParams = [];
            let questStringParams: string[] = [];
            let photoStringParams: string[] = [];
            let photoParams: ICustomParamForActivity[] = [];

            switch (activity.code) {
                case EActivityCodes.runTarget:
                    // only a single param is supported for run-x challenge
                    params.customParams = [params.customParams[0]];
                    break;
                default:
                    break;
            }

            // check which custom params are shown here
            for (let cpar of activity.customParams) {
                if (cpar != null) {
                    if (activity.code === EActivityCodes.find) {
                        if (story != null && story.enableAr !== 1) {
                            cpar.hide = true;
                        }
                    }
                    let add: boolean = false;
                    // if (cpar.items && cpar.items.length > 0) {
                    //     add = true;
                    // }
                    // show all custom params, not just those associated to items
                    add = true;
                    switch (cpar.customParamCategoryCode) {
                        case ECustomParamCategoryCode.quest:
                            questStringParams.push(cpar.dataString);
                            add = false;
                            break;
                        case ECustomParamCategoryCode.photoChallenge:
                            photoStringParams.push(cpar.dataString);
                            photoParams.push(cpar);
                            break;
                        case ECustomParamCategoryCode.videoGuide:
                            add = false;
                            try {
                                let videoSpec: IActivityVideoGuideSpecs = JSON.parse(cpar.dataString);
                                switch (videoSpec.type) {
                                    case 1:
                                        params.videoGuideSpecs.before = videoSpec;
                                        break;
                                    case 2:
                                        params.videoGuideSpecs.target = videoSpec;
                                        break;
                                    case 3:
                                        params.videoGuideSpecs.after = videoSpec;
                                        break;
                                }
                            } catch (err) {
                                console.error(err);
                            }
                            break;
                        default:
                            break;
                    }
                    if (add) {
                        params.customParams.push(cpar);
                    }
                }
            }

            // only one param supported for each type, take first
            if (questStringParams.length > 0) {
                if (questStringParams.length > 1) {
                    // multi quest
                    try {
                        params.multiQuestData = JSON.parse(questStringParams[0]);
                    } catch (err) {
                        console.error(err);
                        params.multiQuestData = [];
                    }
                } else {
                    // standard quest
                    try {
                        params.questData = JSON.parse(questStringParams[0]);
                    } catch (err) {
                        console.error(err);
                        params.questData = null;
                    }
                }
            }

            if (photoStringParams.length > 0) {
                if (photoStringParams.length > 1) {
                    // multi photo
                    try {
                        params.multiPhotoData = [];
                        for (let psp of photoStringParams) {
                            params.multiPhotoData.push(JSON.parse(psp));
                        }
                        params.photoData = JSON.parse(photoStringParams[0]);
                    } catch (err) {
                        console.error(err);
                        params.multiPhotoData = [];
                        params.photoData = null;
                    }
                } else {
                    // standard photo
                    try {
                        if (photoStringParams[0] != null) {
                            params.photoData = JSON.parse(photoStringParams[0]);
                        } else {
                            // use generic photo from standard item
                            let photoParam: ICustomParamForActivity = photoParams[0];
                            params.photoData = {
                                ref: photoParam.photoUrl,
                                d: photoParam.photoUrl,
                                title: photoParam.name
                            };
                        }
                    } catch (err) {
                        console.error(err);
                        params.photoData = null;
                    }
                }
            }
        } else {
            params.customParams = [];
        }

        let description: string = "<p>Challenge description:</p>" + activity.description;

        // if (params.tutorial) {
        //     description += "<p></p>" + params.tutorial;
        // }

        let shortDescription: string = description;

        if (loc && loc.merged) {
            if (loc.merged.description) {
                description = loc.merged.description + "<p></p>" + description;
            }
            if (loc.merged.shortDescription) {
                shortDescription = loc.merged.shortDescription + "<p></p>" + shortDescription;
            }

        }

        // params.description = description;
        params.description = shortDescription;
        params.shortDescription = shortDescription;

        return params;
    }


    showDescription(activity: IActivity, loc: ILocationContainer) {
        let description: string = activity.description;
        if (loc) {
            description = loc.merged.description;
        }
        this.tutorials.showTutorialNoAction("Place Tutorial", null, description, null, true);
    }
}




