

/**
 *  address_components: GeocoderAddressComponent[];
    adr_address: string;
    formatted_address: string;
    formatted_phone_number: string;
    geometry: PlaceGeometry;
    html_attributions: string[];
    icon: string;
    id: string;
    international_phone_number: string;
    name: string;
    permanently_closed: boolean;
    photos: PlacePhoto[];
    place_id: string;
    price_level: number;
    rating: number;
    reviews: PlaceReview[];
    types: string[];
    url: string;
    vicinity: string;
    website: string;
 */

export interface IPlaceExtContainer {
    providerCode: number;
    name: string;
    tempName?: string;
    description?: string;
    id: number;
    googleId: string;
    shortDescription?: string;
    // original as loaded from db
    photoUrl: string;
    // original as loaded from db
    photoUrlSmall?: string;
    // check if photo is loaded as a fallback
    isGenericPhotoLoaded: boolean;
    type: string;
    flag: number;
    lat: number;
    lng: number;
    rating: number;
    reviewCount?: number;
    photoLoadedFinal?: boolean;
    existingPhoto?: boolean;
    aux?: {
        photoUrlFn?: any;
        photos?: any;
    };
    distanceToUser?: number;
}

export interface IPlacePhotoContainer {
    photoUrl: string;
    flag: number;
}

export enum EPlacePhotoContainerFlag {
    /**
     * not loaded
     */
    empty = 1,
    /**
     * photo loaded
     */
    loaded = 2
}


export interface ILocationPhotoUrlContainer {
    googleId: string,
    photoUrl: string,
    photoUrlSmall: string
}

