import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { IPageInterface } from 'src/app/app-utils';

@Injectable({
    providedIn: 'root'
})
export class NavUtilsService {

    transparentBgObservable: BehaviorSubject<boolean>;
    transparentBg: boolean = false;

    constructor(
    ) {
        this.transparentBgObservable = new BehaviorSubject(null);
    }


    /**
     * check enabled transparent bg based on the current view
     * @param p 
     */
    setOpenView(p: IPageInterface) {
        if (p) {
            if (p.transparent) {
                this.setTransparentBg(true);
            } else {
                this.setTransparentBg(false);
            }
        } else {
            this.setTransparentBg(false);
        }
    }

    /**
     * set transparent bg
     * @param enabled 
     */
    setTransparentBg(enabled: boolean) {
        this.transparentBg = enabled;
        this.transparentBgObservable.next(enabled);
    }

    getTransparentBgObservable() {
        return this.transparentBgObservable;
    }
}
