import { Injectable } from '@angular/core';
import { UiExtensionService } from '../../../general/ui/ui-extension';
import { AnalyticsService } from '../../../general/apis/analytics';
import { MiscDataService } from '../../../data/misc';
import { NewsfeedDataService } from '../../../data/newsfeed';
import { StoryDataService } from '../../../data/story';
import { ResourcesCoreDataService } from '../../../data/resources-core';
import { SettingsManagerService } from '../../../general/settings-manager';
import { IPlatformFlags } from '../../../../classes/def/app/platform';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { Market } from '@ionic-native/market/ngx';
import { INavParams } from '../../../../classes/def/nav-params/general';
import { UserStatsDataService } from '../../../data/user-stats';
import { UserDataService } from '../../../data/user';
import { AchievementsDataService } from '../../../data/achievements';
import { IUserStatsChangedResult, IStatChange, EStatChangeCode } from '../../../../classes/def/user/stats';
import { IGameItem, EItemCategoryCodes } from '../../../../classes/def/items/game-item';
import { IShareMsgParams } from '../../../../classes/utils/message-utils';
import { GeneralCache } from '../../../../classes/app/general-cache';
import { AchievementUnlockedViewComponent } from 'src/app/modals/app/modals/achievement-unlocked/achievement-unlocked.component';
import { EAchievementUnlockedParams } from 'src/app/classes/def/nav-params/achievement';
import { SoundEffectsService } from 'src/app/services/general/apis/sound-effects';
import { SoundManagerService } from 'src/app/services/general/apis/sound-manager';
import { Messages } from 'src/app/classes/def/app/messages';
import { ETrackedEvents } from 'src/app/classes/app/analytics';
import { SoundUtils } from 'src/app/services/general/apis/sound-utils';
import { MessageQueueHandlerService } from 'src/app/services/general/message-queue-handler';

@Injectable({
    providedIn: 'root'
})
export class RewardModalsService {

    platform: IPlatformFlags = {} as IPlatformFlags;
    isRewardPopupActive: boolean = false;

    constructor(
        public uiext: UiExtensionService,
        public analytics: AnalyticsService,
        public misc: MiscDataService,
        public newsfeed: NewsfeedDataService,
        public dataProvider: StoryDataService,
        public resources: ResourcesCoreDataService,
        public settingsProvider: SettingsManagerService,
        public appVersion: AppVersion,
        public market: Market,
        public userStats: UserStatsDataService,
        public userData: UserDataService,
        public achievements: AchievementsDataService,
        public soundEffects: SoundEffectsService,
        public soundManager: SoundManagerService,
        public messageQueueHandler: MessageQueueHandlerService
    ) {
        console.log("reward modals service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    /**
     * check user stats changed
     * e.g. level up!
     */
    checkUserStatsChanged(enableReload: boolean, showPopup: boolean): Promise<IUserStatsChangedResult> {
        let promise: Promise<IUserStatsChangedResult> = new Promise((resolve, reject) => {
            this.userStats.checkUserStatsChanged().then(async (res: IStatChange[]) => {
                try {
                    let resCheck: IUserStatsChangedResult = {
                        reload: false,
                        statChangedCodes: [],
                        hasLeveledUp: false,
                        message: ""
                    };
                    if (!(res && res.length > 0)) {
                        resolve(resCheck);
                        return;
                    }
                    let statChangedCodes: number[] = res.map(r => r.type);
                    let statCategories: { [key: string]: IStatChange[] } = {};
                    for (let sc of res) {
                        let key: string = "" + sc.type;
                        if (statCategories[key] != null) {
                            statCategories[key].push(sc);
                        } else {
                            statCategories[key] = [sc];
                        }
                    }
                    let keys: string[] = Object.keys(statCategories);
                    let hasLeveledUp: boolean = false;
                    for (let key of keys) {
                        let scs: IStatChange[] = statCategories[key];
                        if (scs != null && scs.length > 0) {
                            let scGen: IStatChange = scs[0];
                            let head: string = scs[0].heading;
                            let msg: string = "";
                            for (let sc of scs) {
                                if (sc.message != null) {
                                    msg += "<p>" + sc.message + "</p>";
                                }
                            }
                            let isLevelUp: boolean = scGen.type === EStatChangeCode.levelup;
                            if (isLevelUp) {
                                hasLeveledUp = true;
                                resCheck.hasLeveledUp = true;
                                resCheck.message = head + " (" + msg + ")";
                                this.soundEffects.playQueueNoAction(SoundUtils.soundBank.levelUp.id);
                                this.analytics.sendCustomEvent(ETrackedEvents.levelUp, "done", "reward", 0, false);
                            }
                            if (showPopup) {
                                await this.uiext.showRewardPopupQueue(head, msg, null, false, isLevelUp ? 5000 : 3000);
                            }
                        }
                    }
                    if (hasLeveledUp && enableReload) {
                        await this.userData.reloadProfile();
                    }
                    resCheck.reload = hasLeveledUp;
                    resCheck.statChangedCodes = statChangedCodes;
                    resolve(resCheck);
                } catch (err) {
                    reject(err);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
    * check for new achievements
    * let the user view the inventory 
    * then return
    */
    checkNewAchievementsCoreResolveOnly(viewMedalsFn: () => Promise<any>) {
        return new Promise((resolve) => {
            this.checkNewAchievementsCore(viewMedalsFn).then((res) => {
                resolve(res);
            }).catch(() => {
                resolve(false);
            });
        });
    }

    /**
     * check for new achievements
     * let the user view the inventory 
     * then return
     */
    checkNewAchievementsCore(viewMedalsFn: () => Promise<any>): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            this.achievements.checkNewAchievements(false).then((medals: IGameItem[]) => {
                if (medals && medals.length > 0) {
                    this.soundManager.vibrateContext(true);
                    this.soundManager.ttsWrapper(Messages.tts.achievementsUnlocked, true, SoundUtils.soundBank.complete.id);
                    this.handleAchievementsUnlocked(medals, viewMedalsFn).then((res: boolean) => {
                        resolve(res);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    resolve(false);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * handle achievements unlocked
     */
    handleAchievementsUnlocked(items: IGameItem[], viewMedalsFn: () => Promise<any>): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            let params: IShareMsgParams = {
                xp: 0,
                newAchievements: {
                    items: items,
                    categoryName: "new achievements"
                },
                actionButtons: {
                    watchAd: false,
                    scanQR: false,
                    share: false
                }
            };

            let navParams: INavParams = {
                params: params,
                view: {
                    fullScreen: true,
                    transparent: false,
                    large: true,
                    addToStack: true,
                    frame: true
                }
            };

            let categoryCode: number = EItemCategoryCodes.medals;

            // check category
            if (items && items.length > 0) {
                categoryCode = items[0].itemCategoryCode;
            }

            this.uiext.showCustomModal(null, AchievementUnlockedViewComponent, navParams).then((res: number) => {
                switch (res) {
                    case EAchievementUnlockedParams.viewMedals:
                        GeneralCache.inventorySelectedCategory = categoryCode;
                        // e.g. go to inventory to view the medals
                        // return to inventory if modal opened from there
                        viewMedalsFn().then(() => {
                            resolve(true);
                        }).catch(() => {
                            resolve(false);
                        });
                        break;
                    default:
                        resolve(true);
                }
            }).catch((err: Error) => {
                console.error(err);
                this.analytics.dispatchError(err, "popup-features");
                reject(err);
            });
        });
        return promise;
    }
}
