
import { ICameraPosition } from "../../classes/def/map/map-data";
import { IGameConfig } from "../app/config";
import { AppConstants } from "../app/constants";
import { ILatLng } from "../def/map/coords";

export class MapSettings {

  public static mapOptions: any = {
    camera: {
      target: {
        // lat: 44.439663,
        // lng: 26.096306
        lat: 0,
        lng: 0
      },
      zoom: 18,
      tilt: 0
      // tilt: 50
    }
  };

  public static cameraPosition: ICameraPosition = {
    target: new ILatLng(0, 0),
    zoom: 17.5,
    tilt: 0,
    bearing: 0,
    duration: 500,
    padding: 0
  };

  public static navTilt: number = 40;
  public static droneTilt: number = 40;
  public static zoomOutLevel: number = 15;
  public static zoomInLevelDefault: number = 17.5;
  public static zoomInLevelChallenge: number = 18;
  public static zoomInLevelNav: number = 18;
  public static zoomInLevelCrt: number = 17.5;
  public static zoomInLevelDroneTakeoff = 19;
  public static zoomOutLevelDrone: number = 17;

  constructor() {
    MapSettings.cameraPosition.zoom = MapSettings.zoomInLevelCrt;
  }

  static setZoomInLevel(zoomInLevel: number, delta: number) {
    if (delta != null) {
      zoomInLevel += delta;
    }
    MapSettings.zoomInLevelCrt = zoomInLevel;
  }

  static reloadConfig() {
    let gc: IGameConfig = AppConstants.gameConfig;
    if (gc.mapZoomOutLevel) {
      MapSettings.zoomOutLevel = gc.mapZoomOutLevel;
    }
    if (gc.mapZoomInLevel) {
      MapSettings.zoomInLevelCrt = gc.mapZoomInLevel;
      MapSettings.sync();
    }
  }

  static sync() {
    MapSettings.cameraPosition.zoom = MapSettings.zoomInLevelCrt;
    MapSettings.mapOptions.camera.zoom = MapSettings.zoomInLevelCrt;
  }

  static useIntegerZoomLevels() {
    MapSettings.zoomOutLevel = Math.floor(MapSettings.zoomOutLevel);
    MapSettings.zoomInLevelCrt = Math.floor(MapSettings.zoomInLevelCrt);
    MapSettings.sync();
  }
}
