import { Component, ViewChild, ElementRef, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IButtonOptions } from 'src/app/classes/def/app/ui';
import { ITreasureSpec } from 'src/app/classes/def/places/leplace';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController, IonInput } from '@ionic/angular';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { ParamHandler } from 'src/app/classes/general/params';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';


export interface ITreasureSpecSelectorParams {
  description: string;
  loaderCode: number;
  mode: number;
  title: string;
  photoUrl: string;
  buttons?: IButtonOptions[];
  treasureSpecs: ITreasureSpec[];
}

export interface IDynamicParamTreasureSpec {
  data: ITreasureSpec;
  select: boolean;
}

export interface ITreasureSpecInput {
  code: number;
  spec: ITreasureSpec;
  content: string;
  withPhoto: boolean;
}

export enum ETreasureSpecInput {
  add = 1,
  syncPositions = 2
}

@Component({
  selector: 'modal-treasure-spec-selector',
  templateUrl: './treasure-spec-selector.component.html',
  styleUrls: ['./treasure-spec-selector.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TreasureSpecSelectorViewComponent implements OnInit, OnDestroy {
  @ViewChild('input', { read: ElementRef, static: false }) myInput: IonInput;
  description: string = "";
  title: string = "";
  selectAll: boolean = false;

  loaderCode: number = null;

  loading: boolean = false;

  timeout;


  photoUrl: string = null;
  vs: IViewSpecs = ViewSpecs.getDefault();

  buttonCount: number = 0;

  buttons: IButtonOptions[] = [];

  treasureSpecs: IDynamicParamTreasureSpec[] = [];

  hasParams: boolean = false;

  selectedSpec: ITreasureSpec = null;

  inFocus: boolean = false;
  inputMode: boolean = false;

  customContent: string;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController,
    public resources: ResourcesCoreDataService,
    public analytics: AnalyticsService
  ) {

  }

  /**
   * load remote resource (description)
   * @param code 
   */
  load(code: number) {
    this.loading = true;
    this.resources.getInfoText(code).then((text: string) => {
      this.loading = false;
      this.description = text;
    }).catch((err: Error) => {
      this.loading = false;
      this.description = "Description not found";
      this.analytics.dispatchError(err, "description-frame");
    });
  }

  dismiss(value: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(value).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  cancel() {
    this.dismiss(null);
  }

  submit() {
    let withPhoto: boolean = false;
    if (this.selectedSpec.customContent) {
      withPhoto = true;
    }

    let res: ITreasureSpecInput = {
      code: ETreasureSpecInput.add,
      spec: this.selectedSpec,
      content: this.customContent,
      withPhoto: withPhoto
    };

    this.dismiss(res);
  }


  setFocused() {
    this.inFocus = true;
  }

  setBlurred() {
    this.inFocus = false;
  }

  back() {
    this.inputMode = false;
  }

  onSelect(item: IDynamicParamTreasureSpec) {
    console.log(item);
  }


  addContent() {
    console.log("focus");
    this.inputMode = true;
    if (!this.inFocus) {
      this.timeout = setTimeout(() => {
        if (this.myInput) {
          // this.keyboard.show() // for android
          this.myInput.setFocus();
          // this.myInput.nativeElement.setFocus();
        }
        this.inFocus = true;
      }, 350);
    }
  }

  syncPositions() {
    let res: ITreasureSpecInput = {
      code: ETreasureSpecInput.syncPositions,
      spec: null,
      content: null,
      withPhoto: null
    };
    this.dismiss(res);
  }


  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: ITreasureSpecSelectorParams = np.params;

      if (np.view) {
        this.vs = np.view;
      }

      if (params.buttons && (params.buttons.length > 0)) {
        this.buttonCount = params.buttons.length;
        if (this.buttonCount > 2) {
          this.buttonCount = 2;
        }
        this.buttons = params.buttons;
      }

      if (params.treasureSpecs && (params.treasureSpecs.length > 0)) {
        for (let i = 0; i < params.treasureSpecs.length; i++) {
          this.treasureSpecs.push({
            data: params.treasureSpecs[i],
            select: false
          });
        }

        this.treasureSpecs[0].select = true;
        this.hasParams = true;
        this.selectedSpec = params.treasureSpecs[0];
      }

      console.log(np);

      if (params.description) {
        this.description = params.description;
      }

      this.loaderCode = params.loaderCode;
      // this.descriptionLines = this.formatText(this.description);
      this.title = params.title;
      this.photoUrl = params.photoUrl;
    }

    if (this.loaderCode != null) {
      this.load(this.loaderCode);
    }

  }


  ngOnDestroy() {
    this.timeout = ResourceManager.clearTimeout(this.timeout);
  }
}
