import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { AboutPage } from './about.page';
import { PagesModule } from '../pages.module';

const routes: Routes = [
  {
    path: '',
    component: AboutPage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [AboutPage]
})
export class AboutPageModule { }
