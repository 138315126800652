import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'values'
})
export class MyValuesPipe implements PipeTransform {
  transform(value: any, _args: any[] = null): any {
    return Object.keys(value).map(key => value[key]);
  }
}
