<div *ngIf="showData">

  <!-- <div class="center-text margin-top-s">
    <span class="info-text-m bold text-color-primary">Forecast</span>
  </div> -->

  <ion-row class="center-items">
    <ion-col [size]="2">
      <ion-button class="action-button-xs button-color-transparent" (click)="switchUnitsAction()">
        <!-- <icon-wrapper [icon]="appIcons.googleMaps" [custom]="true"></icon-wrapper> -->
        <span class="button-font-size-lg">{{units}}</span>
      </ion-button>
    </ion-col>
    <ion-col [size]="10">

      <div>
        <kv-table [data]="weatherStatsTable" [format]="true"></kv-table>
      </div>

      <!-- <ion-row class="text-left margin-top-s">
        <span class="info-text-m bold text-color-primary">High: {{tempHigh}}</span>
      </ion-row>
      <ion-row class="center-text margin-top-s">
        <span class="info-text-m bold text-color-primary">Low: {{tempLow}}</span>
      </ion-row> -->

    </ion-col>
  </ion-row>


  <!-- <div class="text-left margin-top-s">
    <span class="info-text-m bold text-color-primary">Clouds: {{cloudsAvg}}</span>
  </div> -->

  <!-- <div class="center-text margin-top-s">
    <span class="info-text-m bold text-color-primary">Score</span>
  </div> -->

  <!-- <mat-progress-bar mode="determinate" class="mat-progress-bar-loading progress-rounded"
    [value]="weatherScore"></mat-progress-bar> -->

</div>