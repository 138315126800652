<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" [title]="title" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()"
    rightButton="settings" [rightIcon]="true" (rightClick)="options()" [loading]="!loaded"></leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <div class="view-frame theme-background">
    <!-- bg-filter-1 -->
    <div class="bg-container bg-container-fill"
      [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: photoUrlSource}">

      <div class="content">
        <div class="content-height no-scroll" fxLayout="column">

          <div class="text-div-margin content-height-button-frame text-color-overlay margin-top-s" fxLayout="column">
            <div fxLayout="column" class="scrollbar-y content-height" *ngIf="loaded">

              <div *ngIf="selectedGroup" class="padding-bottom-xs">
                <ion-row class="full-w center-items">
                  <ion-col size="2" class="text-left">
                    <ion-button class="action-button-icon-only margin-left-s"
                      [disabled]="!showQRAvailable || !selectedGroup" (click)="toggleShowQR()">
                      <icon-wrapper slot="icon-only" [icon]="appIcons.qr" [custom]="true"></icon-wrapper>
                    </ion-button>
                  </ion-col>
                  <ion-col size="8" fxLayout="column">
                    <span class="heading text-color-primary">{{selectedGroup.name}}</span>
                    <span class="info-text-s text-color-primary padding-top-s">Team ID: {{selectedGroup.alias}}</span>
                    <span class="info-text-s text-color-primary padding-top-s">{{roleText}}</span>
                  </ion-col>
                  <ion-col size="2" class="text-right">
                    <ion-button class="action-button-icon-only margin-right-s" [disabled]="!selectedGroup.alias"
                      (click)="copyToClipboard(selectedGroup.alias)">
                      <icon-wrapper slot="icon-only" icon="copy"></icon-wrapper>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </div>

              <progress-timeout *ngIf="showProgressReload" pid="groups-home" [small]="true"
                customClass="progress-bar-alternate-light" [timeoutValue]="loadingTimeout"
                [resetTimeoutToggle]="resetLoadingTimeout" [enabled]="autoReload">
              </progress-timeout>

              <div class="padding-top-s padding-horizontal-s" *ngIf="groupMembersLoaded">
                <chat-heads-slider [chatheads]="nearbyPlayers" [update]="updateGridItemsContent"></chat-heads-slider>
              </div>

              <div class="padding-horizontal-s padding-bottom-xs theme-border-top">
                <ion-row>
                  <ion-col size="6">
                    <ion-button class="action-button-icon-only button-color-transparent" [disabled]="!canRefresh()"
                      (click)="reloadOnTap()">
                      <span class="button-font-size-xs">Reload</span>
                      <icon-wrapper class="slot-icon-padding" slot="start" icon="refresh"></icon-wrapper>
                    </ion-button>
                  </ion-col>
                  <ion-col size="6">
                    <ion-button class="action-button-icon-only button-color-transparent" [disabled]="!canRefresh()"
                      (click)="manageGroup()">
                      <span class="button-font-size-xs">Manage</span>
                      <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIcons.personCircleOutline"
                        [custom]="true"></icon-wrapper>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </div>

              <div class="theme-border-top"></div>

              <!-- theme-border-top -->
              <div *ngIf="infoText" [innerHTML]="infoText"
                class="padding-s info-text-m text-color-primary description-frame  text-center">
              </div>

              <!-- <div *ngIf="!groupOnline">
                <div *ngFor="let e of infoTextGraphics" class="list-item list-item-padding transparent-bg text-left">
                  <plain-entry-expand [e]="e" [fixed]="true" (action)="onTapSelectGraphic($event)"></plain-entry-expand>
                </div>
              </div> -->

              <div *ngIf="infoTextCustom" [innerHTML]="infoTextCustom"
                class="padding-s info-text-m text-color-primary description-frame theme-border-top text-left">
              </div>
              <div *ngIf="infoTextAux" [innerHTML]="infoTextAux"
                class="padding-s info-text-m text-color-primary description-frame theme-border-top text-left">
              </div>

              <!-- QR code for join -->
              <div *ngIf="showQRAvailable && showQR && selectedGroup" class="padding-s">
                <qr-code [code]="selectedGroup.alias" [version]="4"></qr-code>
              </div>

              <!-- members grid -->
              <!-- <div class="padding-s theme-border-top">
                <dynamic-grid type="groupMemberItem" [data]="gridItemsContainers" emptyMessage=""
                  [rowItems]="gridItemsRow" [updateGrid]="updateGridItems" [update]="updateGridItemsContent"
                  [blanks]="false" (action)="groupMemberAction($event)"></dynamic-grid>
              </div> -->

            </div>
          </div>

          <!-- bottom section -->
          <div fxFlex></div>
          <div>
            <ion-grid class="full-w">

              <ion-row>
                <ion-col size="4">
                  <!-- <blink-button [icon]="appIcons.backStep" [disabled]="!(canExit && checkCanExit())"
                    [customIcon]="true" theme="warn" name="exit" (select)="leaveSession()">
                  </blink-button> -->
                  <blink-button *ngIf="!groupOnline && !exitOnly" [icon]="appIcons.leaveGroup" [customIcon]="true"
                    theme="warn" name="leave" customFontClass="button-font-size-xs" (select)="cancelStep()">
                  </blink-button>
                  <blink-button *ngIf="groupOnline && !exitOnly" [icon]="appIcons.disconnect" [customIcon]="true"
                    theme="warn" name="logout" customFontClass="button-font-size-xs" (select)="cancelStep()">
                  </blink-button>
                  <blink-button *ngIf="exitOnly" [icon]="appIcons.logout" [customIcon]="true" theme="warn" name="exit"
                    customFontClass="button-font-size-xs" [disabled]="true" (select)="leaveSession()">
                  </blink-button>
                </ion-col>

                <ion-col size="4">
                  <blink-button [disabled]="!groupOnline" icon="chatbox" theme="primary" name="chat"
                    customFontClass="button-font-size-xs" [setTimeout]="chatBtnBlink" (select)="showChatModal()">
                  </blink-button>
                </ion-col>

                <ion-col size="4">

                  <div *ngIf="!groupOnline">
                    <blink-button [disabled]="!buttonEnabled.ready" icon="play" [name]="buttonTextFn.ready()"
                      customFontClass="button-font-size-xs" theme="accent" [setTimeout]="buttonEnabled.ready"
                      (select)="tapReady()">
                    </blink-button>
                  </div>
                  <div *ngIf="groupOnline && isRunning">
                    <blink-button [disabled]="!buttonEnabled.return" [name]="buttonTextFn.return()"
                      customFontClass="button-font-size-xs" icon="play" theme="accent"
                      [setTimeout]="buttonEnabled.return" (select)="continueToMap()"></blink-button>
                  </div>
                  <div *ngIf="groupOnline && !isRunning">
                    <blink-button [disabled]="!buttonEnabled.start" [name]="buttonTextFn.start()"
                      customFontClass="button-font-size-xs" icon="play" theme="accent"
                      [setTimeout]="buttonEnabled.start" (select)="tapStart()"></blink-button>
                  </div>
                </ion-col>
              </ion-row>

              <!-- group not selected mode active -->
              <ion-row *ngIf="!selectedGroup && loaded">
                <ion-col size="12">
                  <ion-button class="action-button-fill button-color-alternate" [disabled]="false"
                    (click)="viewGroups()">
                    <icon-wrapper slot="start" class="slot-icon-padding" icon="contacts"></icon-wrapper>
                    <span class="button-font-size-s">Select group</span>
                  </ion-button>
                </ion-col>
              </ion-row>

            </ion-grid>
          </div>

        </div>
      </div>
    </div>
  </div>
</ion-content>