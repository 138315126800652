<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="true" title="Home" [loading]="loading"></leplace-toolbar>
</ion-header>

<!-- class="ion-padding" -->
<ion-content [ngClass]="theme">

  <div class="view-frame">
    <div class="bg-container bg-container-fill"
      [photoBg]="{fade: false, fadeClass: 'bg-container-fade', fadeClassInner: activeFadeClass, url: backgroundSrc}">

      <div class="content">
        <div>
          <div fxLayout="column" class="content-height" fxLayoutAlign="space-between center">
            <div class="padding-s stick-top text-left margin-top-home">
              <!-- <blink-button icon="bookmarks" [setTimeout]="false" [theme]="btnStorylineTheme"
                [defaultClass]="btnStorylineClass" highClass="button-60 button-color-alternate-70" theme="primary"
                (select)="goToStories()" [noBorder]="false" name="Storyline">
              </blink-button> -->
              <blink-button icon="bookmarks" [setTimeout]="false" [theme]="btnStorylineTheme"
                customBaseClass="button-60 action-button-border" (select)="selectStoryline()" [noBorder]="false"
                name="Storyline">
              </blink-button>
              <span class="info-text-m bold margin-vertical-s margin-horizontal-s text-color-primary block">App Guided
                Tours</span>
            </div>

            <!-- <div class="padding-s stick-top-right text-right margin-top-home">
              <blink-button icon="mail" [setTimeout]="blink.inbox" [fab]="true" (select)="goToNewsfeed()"
                [noBorder]="false"></blink-button>
            </div> -->

            <div class="padding-s stick-top-right text-right margin-top-home">
              <blink-button icon="bookmark" [setTimeout]="blink.featured" [fab]="true"
                (select)="viewFeaturedStorylines()" [noBorder]="false"></blink-button>
              <span class="info-text-m bold margin-vertical-s margin-horizontal-s text-color-primary block">Featured / Unlock</span>
            </div>

            <div tappable (click)="tapplate()">
              <leplace-plate [set]="setBlinkStart" [plateFooter]="plateSub"></leplace-plate>
            </div>

            <div class="padding-s stick-bottom text-right margin-bottom-home">
              <!-- <blink-button icon="map" [setTimeout]="blink.map" [theme]="btnWorldMapTheme"
                [defaultClass]="btnWorldMapClass" highClass="button-60 button-color-alternate-70" theme="primary"
                (select)="goToMap()" [noBorder]="false" name="World Map">
              </blink-button> -->
              <span class="info-text-m bold margin-vertical-s margin-horizontal-s text-color-primary block">Open
                World Exploration</span>
              <blink-button icon="map" [setTimeout]="blink.map" [theme]="btnWorldMapTheme"
                customBaseClass="button-60 action-button-border" (select)="selectWorldMap()" [noBorder]="false"
                name="World Map">
              </blink-button>
            </div>

            <div class="padding-s stick-bottom-left text-left margin-bottom-home">
              <span class="info-text-m bold margin-vertical-s margin-horizontal-s text-color-primary block">Support</span>
              <blink-button icon="chatbubbles" [setTimeout]="blink.support" [fab]="true" (select)="openSupportPage()"
                [noBorder]="false"></blink-button>
              <!-- <blink-button icon="person" [setTimeout]="blink.account" [fab]="true" (select)="goToMyAccount()"
                [noBorder]="false"></blink-button> -->
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
</ion-content>