
export class GeolocationUtils {
    static formatGPSHeading(heading: number) {
        if (heading == null) {
            heading = null;
        }
        if (isNaN(heading)) {
            heading = null;
        }
        return heading;
    }
}