
export enum EMoveActivityStatus {
    started = 0,
    done = 1,
    cheated = 2,
    failed = 3
}

export interface IMoveMonitorParams {
    speedMode: number;
    timeLimit: number;
    speedHigh: number;
    targetSpeed: number;
    targetDistance: number;
}

export interface IAverageHeading {
    sum: number;
    crt: number;
    counter: number;
    samples: number;
    /**
     * m/s
     */
    minSpeed: number;
    ready: boolean;
}

export interface IMoveMonitorData {
    distancePercent: number;
    /**
     * distance travelled
     */
    distance: number;
    /**
     * current speed
     */
    speed: number;
    /**
     * average speed (dist/time)
     */
    averageSpeed: number;
    speedPercent: number;
    speedDisp: string;
    distanceDisp: string;
    /**
     * current timer
     */
    timerValue: number;
    timerDisp: string;
    /**
     * elapsed time
     */
    elapsedValue: number;
    // elapsed time count as running
    elapsedValueAux: number;
    status: number;
    cheatCounter: number;
}


export interface IDistanceDump {
    source: number;
    distance: number;
    gameContext: number;
}