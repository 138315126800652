import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { StoryListPage } from './story-list.page';
import { PagesModule } from '../../pages.module';

const routes: Routes = [
  {
    path: '',
    component: StoryListPage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [StoryListPage]
})
export class StoryListPageModule {}
