
export interface IGetCountriesWithDetailsAndNearby {
  /** client added */
  global?: IDBModelCountry,
  nearby: IDBModelCountry,
  list: IDBModelCountry[]
}

export interface IDBModelCountry {
  id?: number,
  name?: string,
  /** app internal */
  auxName?: string,
  photoUrl?: string,
  lat?: number,
  lng?: number,
  enabled?: number,
  enableStoreAndroid?: number,
  enableStoreIos?: number,
  countryCode?: string,
  /** app internal */
  nearby?: boolean,
  cities?: IDBModelCity[]
}

export interface IDBModelCity {
  id?: number,
  countryId?: number,
  name?: string,
  photoUrl?: string,
  lat?: number,
  lng?: number,
  enabled?: number,
  /** extracted */
  storyCount?: number,
  country?: IDBModelCountry,
  countryName?: string
}

export enum ECityCodes {
  global = -1,
  scanQR = -2,
  myList = -3
}

export enum ECountryCodes {
  global = -1,
  demo = -2
}
