import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IGroupMember } from 'src/app/classes/def/mp/groups';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { QRService } from 'src/app/services/general/apis/qr';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { ParamHandler } from 'src/app/classes/general/params';
import { IMPUserCardOptionsNavParams } from 'src/app/classes/def/nav-params/arena';
import { IItemAction } from 'src/app/classes/def/items/action';
import { EItemActions } from 'src/app/classes/def/items/game-item';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { Messages } from 'src/app/classes/def/app/messages';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';


@Component({
  selector: 'modal-mp-user-card-options',
  templateUrl: './mp-user-card-options.component.html',
  styleUrls: ['./mp-user-card-options.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MpUserCardOptionsViewComponent implements OnInit, OnDestroy {
  description: string = "";
  descriptionLines: string[];
  title: string = "Player";
  actionDescription: string = "";
  statusDescription: string = "";
  playerName: string = "";

  player: IGroupMember;

  enable = {
    add: true,
    disable: true,
    remove: true
  };

  disabled = {
    add: true,
    disable: true,
    remove: true
  };

  strictMode: boolean = true;

  isAdmin: boolean = false;
  vs: IViewSpecs = ViewSpecs.getDefault();

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService,
    public qrSecurity: QRService,
    public multiplayer: MPDataService
  ) {

  }

  add() {
    let itemAction: IItemAction = {
      code: EItemActions.buy,
      data: null
    };
    this.dismiss(itemAction);
  }


  setInactive() {
    let itemAction: IItemAction = {
      code: EItemActions.drop,
      data: null
    };
    this.dismiss(itemAction);
  }

  exclude() {
    this.uiext.showAlert(Messages.msg.removeGroupMember.before.msg, Messages.msg.removeGroupMember.before.sub, 2, null).then((res: number) => {
      switch (res) {
        case EAlertButtonCodes.ok:
          let itemAction: IItemAction = {
            code: EItemActions.cancel,
            data: null
          };
          this.dismiss(itemAction);
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }


  ngOnInit() {

    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IMPUserCardOptionsNavParams = np.params;
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);
      this.player = params.member;
      this.isAdmin = params.isAdmin;
      this.playerName = this.player.user.name;
    }


    if (this.player) {

      this.statusDescription = "<p>Player status</p>";

      if (this.player.staticFlags) {
        this.disabled.add = this.player.staticFlags.enabled;
        this.disabled.disable = !this.player.staticFlags.enabled;
        this.disabled.remove = false;

        if (!this.isAdmin) {
          this.enable.add = false;
          this.enable.disable = false;
          this.enable.remove = false;
        }

        if (this.player.staticFlags.self) {
          this.enable.add = false;
          this.enable.disable = false;
          this.enable.remove = false;
          this.playerName = this.playerName + " (you)";
        }

        if (this.enable.add || this.enable.disable || this.enable.remove) {
          this.actionDescription = "<p>Available options: </p>";
          if (this.enable.add) {
            this.actionDescription += "<p>>Add to current session</p>";
          }

          if (this.enable.disable) {
            this.actionDescription += "<p>>Remove from current session</p>";
          }
        }

        if (this.enable.remove) {
          this.actionDescription += "<p>>Remove from group</p>";
        }
      }

      if (!this.player.dynamic) {
        this.statusDescription += "<p>offline</p>";
      } else {

        if (this.player.dynamic.flags.ready) {
          this.statusDescription += "<p>ready</p>";
        } else {
          if (this.player.dynamic.connected) {
            this.statusDescription += "<p>online</p>";
          } else {
            this.statusDescription += "<p>offline</p>";
          }
        }
      }
    }

    console.log(this.enable);
  }

  ngOnDestroy() {

  }
}
