import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { ParamHandler } from 'src/app/classes/general/params';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';

export interface IProgressNavParams {
  progressObservable: Observable<number>;
  progress: number;
  caption: string;
  title: string;
  info: string;
}
export interface IProgressDisplay {
  id: number;
  progress: number;
  caption: string;
  determinate: boolean;
}

@Component({
  selector: 'modal-progress-view',
  templateUrl: './progress-view.component.html',
  styleUrls: ['./progress-view.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProgressViewComponent implements OnInit, OnDestroy {
  theme: string = "theme-aubergine theme-aubergine-bg";
  title: string = "Progress";
  infoHTML: string = "";

  flags = {
    downloading: true,
    processing: false
  };
  canDismiss: boolean = false;
  canIgnore: boolean = false;
  subscription = {
    progress: null
  };
  observable = {
    progress: null
  };
  timeout = {
    init: null
  };
  initComplete: boolean = false;
  btnContinue: string = "Close";
  vs: IViewSpecs = ViewSpecs.getDefault();
  disp: IProgressDisplay[] = [];
  np: INavParams = null;
  appIconsStandard = EAppIconsStandard;

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public analytics: AnalyticsService
  ) {

  }

  checkMultiDisp(progress: IProgressDisplay) {
    let index: number = this.disp.findIndex(d => d.id === progress.id);
    if (index !== -1) {
      this.disp[index] = progress;
    } else {
      this.disp.push(progress);
    }
    console.log(this.disp);
  }


  dismiss() {
    setTimeout(() => {
      this.modalCtrl.dismiss(null).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }


  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IProgressNavParams = np.params;

      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);

      this.title = params.title;

      this.infoHTML = params.info;


      if (params.progressObservable) {
        this.observable.progress = params.progressObservable;

        // self-update/modal handled update
        this.subscription.progress = this.observable.progress.subscribe((progress: IProgressDisplay) => {
          if (progress) {
            this.checkMultiDisp(progress);
          }
        }, (err: Error) => {
          console.error(err);
        });
      }
    }
  }

  ngOnDestroy() {
    ResourceManager.clearSubObj(this.subscription);
    ResourceManager.clearTimeoutObj(this.timeout);
  }
}
