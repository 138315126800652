
export class ILatLng implements ILatLng {
    lat: number;
    lng: number;
    constructor(lat: number, lng: number) {
        this.lat = lat;
        this.lng = lng;
    }
}

export class ILatLngBounds implements ILatLngBounds {
    northeast: ILatLng;
    southwest: ILatLng;
    constructor(points: ILatLng[]) {
        this.northeast = points.length > 0 ? new ILatLng(points[0].lat, points[0].lng) : null;
        this.southwest = points.length > 1 ? new ILatLng(points[1].lat, points[1].lng) : null;
    };
    toString(): string {
        return `Bounds: NE(${this.northeast.lat}, ${this.northeast.lng}), SW(${this.southwest.lat}, ${this.southwest.lng})`;
    }

    toUrlValue(precision: number = 6): string {
        const format = (num: number) => num.toFixed(precision);
        return `${format(this.southwest.lat)},${format(this.southwest.lng)},${format(this.northeast.lat)},${format(this.northeast.lng)}`;
    }

    extend(point: ILatLng): void {
        // Logic to extend bounds to include a new point
        if (point.lat < this.southwest.lat) this.southwest.lat = point.lat;
        if (point.lat > this.northeast.lat) this.northeast.lat = point.lat;
        if (point.lng < this.southwest.lng) this.southwest.lng = point.lng;
        if (point.lng > this.northeast.lng) this.northeast.lng = point.lng;
    }

    contains(point: ILatLng): boolean {
        // Check if the point is within the bounds
        return point.lat >= this.southwest.lat &&
            point.lat <= this.northeast.lat &&
            point.lng >= this.southwest.lng &&
            point.lng <= this.northeast.lng;
    }

    getCenter(): ILatLng {
        const midLat = (this.southwest.lat + this.northeast.lat) / 2;
        const midLng = (this.southwest.lng + this.northeast.lng) / 2;
        return new ILatLng(midLat, midLng);
    }
}
