import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { TimeUtils } from "../../../classes/general/time";
import { ResourceManager } from "../../../classes/general/resource-manager";
import { IChallengeParams, IChallengeData } from "../../../classes/def/core/challenge";
import { UiExtensionService } from "../../general/ui/ui-extension";
import { ILeplaceTreasure } from "../../../classes/def/places/leplace";
import { PlacesDataService } from "../../data/places";
import { ItemScannerService } from "./item-scanner";
import { InventoryDataService } from "../../data/inventory";
import { ETimeoutStatus } from 'src/app/classes/general/timeout';


/**
 * the core functionality behind any challenge
 * init timeout
 * status watch
 * this may be called from challenge entry and from any challenge related provider e.g. sound validator, photo validator
 */
@Injectable({
    providedIn: 'root'
})
export class ChallengeCoreService {
    timeObs = null;
    challengeTimer = null;
    challengeParams: IChallengeParams = {
        timeLimit: 0
    };
    challengeDataInit: IChallengeData = {
        timerValue: 0,
        percentComplete: 0,
        status: ETimeoutStatus.started,
        timerDisp: ""
    };
    challengeData: IChallengeData = {
        timerValue: 0,
        percentComplete: 0,
        status: ETimeoutStatus.started,
        timerDisp: ""
    };

    selectedChallengeItem: ILeplaceTreasure = null;

    constructor(
        public uiext: UiExtensionService,
        public placesData: PlacesDataService,
        public itemScanner: ItemScannerService,
        public inventory: InventoryDataService
    ) {
        console.log("challenge core service created");
        this.timeObs = new BehaviorSubject(null);
    }

    startTimer() {
        if (this.challengeTimer) {
            return;
        }
        if (!this.challengeParams.timeLimit) {
            return;
        }
        this.runTimerAction();
    }

    runTimerAction() {
        this.challengeTimer = setTimeout(() => {
            this.challengeData.timerValue -= 1;
            if (this.challengeData.timerValue < 0) {
                this.challengeData.timerValue = 0;
                this.challengeData.status = ETimeoutStatus.expired;
                this.challengeData.percentComplete = 100;
            } else {
                if (this.challengeParams.timeLimit) {
                    this.challengeData.percentComplete = 100 - Math.floor(this.challengeData.timerValue / this.challengeParams.timeLimit * 100);
                }
            }

            this.challengeData.timerDisp = TimeUtils.formatTimeFromSeconds(this.challengeData.timerValue, true);
            this.timeObs.next(this.challengeData);
            this.runTimerAction();
        }, 1000);
    }

    stopTimer() {
        this.challengeTimer = ResourceManager.clearTimeout(this.challengeTimer);
    }

    getTimeoutData() {
        return this.challengeData;
    }

    getWatch(): BehaviorSubject<IChallengeData> {
        return this.timeObs;
    }

    /**
     * start challenge
     * start timer and 
     */
    startChallenge(params: IChallengeParams) {
        console.log("start challenge monitor: ", params);
        this.challengeParams = params;
        this.challengeData = Object.assign({}, this.challengeDataInit);
        this.challengeData.timerValue = this.challengeParams.timeLimit;
        this.timeObs.next(null);
        this.startTimer();
    }

    triggerExpired() {
        this.challengeData.timerValue = 0;
    }

    stopChallenge() {
        console.log("stop challenge monitor");
        this.stopTimer();
    }
}
