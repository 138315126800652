import { Injectable } from "@angular/core";
import { EChartTheme } from 'src/app/classes/def/app/theme';
import { EAppIcons } from 'src/app/classes/def/app/icons';
import { UiExtensionService } from "../../general/ui/ui-extension";


export interface INavGaugeStat {
    // distance to destination init
    start: number;
    // distance to destination crt
    crt: number;
    levelGaugeSize: number;
    levelGauge: number;
    showLevelGauge: boolean;
    mode: number;
    // inverted (fill 1/0)
    inverted: boolean;
    // use target - crt
    elapsedMode: boolean;
    theme: number;
    // disp icon scope
    icon: string;
    customIcon: boolean;
    tooltipMessage?: string;
    callback?: () => any;
}

export interface INavGaugeDict {
    left: INavGaugeStat,
    right: INavGaugeStat
}

export enum ENavGaugeMode {
    // bottom up fill (used in ascending pattern, default)
    dist = 1,
    collectibles = 5,
    // bottom up fill (used in ascending pattern, show dist left)
    distLeft = 2,
    // top down fill (used in ascending pattern)
    distLeftInverted = 2,
    // bottom up fill (used in descending pattern)
    timeLeft = 4
}

export enum ENavGaugeTooltip {
    coins = "showing collected items",
    distanceToTarget = "showing distance to target location",
    distanceLeftToTarget = "showing distance left to target location",
    distanceLeft = "showing distance left",
    timeLeft = "showing time left",
    distanceFromTarget = "showing distance from target"
}

export enum ENavGaugeDict {
    left = "left",
    right = "right"
}

@Injectable({
    providedIn: 'root'
})
export class NavGaugeService {

    ngInit: INavGaugeStat = {
        start: null,
        crt: null,
        levelGaugeSize: 10,
        levelGauge: 0,
        showLevelGauge: false,
        mode: ENavGaugeMode.distLeftInverted,
        inverted: true,
        elapsedMode: false,
        theme: EChartTheme.default,
        icon: EAppIcons.distance,
        customIcon: true,
        tooltipMessage: ""
    };

    ngDict: INavGaugeDict = {
        left: null,
        right: null
    };

    constructor(
        public uiext: UiExtensionService
    ) {
        console.log("nav gauge service created");
        this.reset();
    }

    /**
     * reset gauge config and data
     */
    reset() {
        let keys: string[] = Object.keys(this.ngDict);
        for (let i = 0; i < keys.length; i++) {
            this.ngDict[keys[i]] = Object.assign({}, this.ngInit);
        }

        // this.ngDict.left.theme = EChartTheme.default;
        // this.ngDict.right.theme = EChartTheme.accent1;
    }

    /**
     * show gauge
     */
    show(id: string) {
        if (this.ngDict[id].start) {
            this.ngDict[id].showLevelGauge = true;
        }
    }

    /**
     * hide gauge
     */
    hide(id: string) {
        if (id != null) {
            this.ngDict[id].showLevelGauge = false;
        } else {
            let keys: string[] = Object.keys(this.ngDict);
            for (let i = 0; i < keys.length; i++) {
                this.ngDict[keys[i]].showLevelGauge = false;
            }
        }
    }

    /**
     * set nav mode and initial distance
     * @param mode 
     * @param startDist 
     */
    setMode(id: string, mode: number, tooltip: string, startDist: number) {
        let ng: INavGaugeStat = this.ngDict[id];

        ng.mode = mode;
        ng.start = startDist;
        ng.tooltipMessage = tooltip;
        ng.callback = () => {
            this.uiext.showToastNoAction("HUD Info: " + tooltip, true, 3000);
        };

        switch (mode) {
            case ENavGaugeMode.dist:
            case ENavGaugeMode.collectibles:
                // bottom up fill
                ng.inverted = false;
                ng.elapsedMode = false;
                break;
            case ENavGaugeMode.distLeft:
                // bottom up fill
                ng.inverted = false;
                ng.elapsedMode = true;
                break;
            case ENavGaugeMode.distLeftInverted:
                // top down fill
                ng.inverted = true;
                ng.elapsedMode = true;
                break;
            case ENavGaugeMode.timeLeft:
                // bottom up fill, descending
                ng.inverted = false;
                ng.elapsedMode = false;
                break;
        }
    }

    setIcon(id: string, icon: string, custom: boolean) {
        let ng: INavGaugeStat = this.ngDict[id];
        ng.icon = icon;
        ng.customIcon = custom;
    }

    /**
     * set level, relative to the initial distance
     * @param crt 
     */
    setLevel(id: string, crt: number) {
        let ng: INavGaugeStat = this.ngDict[id];
        if (ng.showLevelGauge) {
            ng.crt = crt;
            if (ng.elapsedMode) {
                ng.levelGauge = ng.levelGaugeSize - Math.floor(crt / ng.start * ng.levelGaugeSize);
            } else {
                ng.levelGauge = Math.floor(crt / ng.start * ng.levelGaugeSize);
            }
            // console.log("set level: ", ng.levelGauge);
        }
    }

    getLevel(id: string): number {
        return this.ngDict[id].levelGauge;
    }

    setTheme(id: string, code: number) {
        this.ngDict[id].theme = code;
    }

    /**
     * check target
     * set theme if threshold reached
     * @param targetLevel 
     */
    checkTargetSetLockTheme(id: string, levelPercent: number) {
        let ng: INavGaugeStat = this.ngDict[id];
        let targetLevel: number = Math.floor(ng.levelGaugeSize * levelPercent);
        this.checkTargetSetLockThemeCore(id, targetLevel);
    }

    checkTargetSetLockThemeCore(id: string, targetLevel: number) {
        let ng: INavGaugeStat = this.ngDict[id];
        if (ng.crt >= targetLevel) {
            console.log("nav gauge set lock theme (target level): ", id);
            ng.theme = EChartTheme.accent1;
        }
    }

    /**
     * check target
     * set theme if threshold reached
     */
    checkLowLevelSetLockTheme(id: string, levelPercent: number) {
        let ng: INavGaugeStat = this.ngDict[id];
        let lowLevel: number = Math.floor(ng.levelGaugeSize * levelPercent);
        if (ng.levelGauge <= lowLevel) {
            console.log("nav gauge set lock theme (low level): ", id);
            ng.theme = EChartTheme.accent2;
        }
    }
}
