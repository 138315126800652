<div *ngIf="loaded" [ngClass]="(self || selected) ? 'leader-card-selected-v2':'leader-card-v2'">
  <ion-grid class="full-w">
    <ion-row class="center-items center-text" [ngClass]="{'card-selected': self || selected}">
      <ion-col size="4">
        <img [src]="bgPhoto" alt="Avatar" class="img-avatar-large-crop">
      </ion-col>
      <ion-col size="8">
        <ion-row>
          <ion-col size="12" class="text-left">
            <span class="info-text-m bold text-color-primary">{{headingDisp}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <!-- <ion-col size="12" class="text-left">
            <span class="info-text-m bold text-color-primary">{{entryName}}</span>
          </ion-col>    -->
          <ion-col size="12" class="text-div-container-blank text-left">
            <div class="info-text-xs bold text-color-primary text-left" [innerHtml]="entryName"></div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" class="text-left">
            <span class="info-text-xs bold text-color-primary">{{scoreDisp}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" class="text-left">
            <span class="info-text-xs bold uppercase text-color-primary">{{typeDisp}}</span>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>