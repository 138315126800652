<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="rightBtnIcon" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss()" [rounded]="vs.fullScreen">
  </leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <ion-list class="list-md-noborder">

    <ion-item class="list-item transparent-bg settings-item">
      <ion-label class="text-color-primary label-text-s">Select All</ion-label>
      <ion-checkbox [(ngModel)]="selectAll" ngDefaultControl (ionChange)="onSelectAll($event)" class="custom-checkbox">
      </ion-checkbox>
    </ion-item>

    <div>
      <dynamic-grid type="checkboxCard" [data]="gridItemsContainers" emptyMessage="No options" [rowItems]="2"
        [blanks]="true" [update]="updateGrid" (action)="onChange($event)"></dynamic-grid>
    </div>

  </ion-list>
</modal-container>