
import { Injectable } from "@angular/core";
import { GeoObjectsService } from "./geo-objects";
import { ExploreActivityService } from "./activities/explore";
import { FindActivityService } from "./activities/find";
import { ItemScannerService } from "./item-scanner";
import { ItemCollectorUtils, ICheckCollectItem } from "./item-collector-utils";
import { ILeplaceObjectContainer } from "../../../classes/def/core/objects";
import { ETreasureType } from "../../../classes/def/items/treasures";
import { EActivityCodes } from "src/app/classes/def/core/activity";


@Injectable({
    providedIn: 'root'
})
export class ItemCollectorService {


    constructor(
        public geoObjects: GeoObjectsService,
        public itemScanner: ItemScannerService,
        public exploreProvider: ExploreActivityService,
        public findActivityProvider: FindActivityService
    ) {
        console.log("item collector service created");
    }

    getARObjectByUid(uid: string): ILeplaceObjectContainer {
        let obj: ILeplaceObjectContainer = this.geoObjects.getGlobalObjectByUid(uid);
        if (!obj) {
            obj = this.geoObjects.getMapFirstObjectByUid(uid);
            console.log("selected M object: ", obj);
        } else {
            console.log("selected G object: ", obj);
        }

        if (!obj) {
            console.error("object not found");
            return null;
        }

        return obj;
    }

    /**
      * run action defined when the object is tapped
      * e.g.
      * remove AR object from the AR view, minimap and global object provider
      * also remove from M objects now because the external collect handler is disabled while AR is open
      */
    collectFromAR(uid: string, activityBaseType: number): ICheckCollectItem {
        console.log("global collect from AR: " + uid);
        console.log("activity base type: ", activityBaseType);
        let obj: ILeplaceObjectContainer = this.getARObjectByUid(uid);

        if (!obj) {
            console.error("object not found");
            return null;
        }

        let typeOverride: number = null;

        if (activityBaseType != null) {
            switch (activityBaseType) {
                case EActivityCodes.explore:
                    typeOverride = ETreasureType.exploreObject;
                    break;
                default:
                    break;
            }
        }

        let check: ICheckCollectItem = ItemCollectorUtils.checkCollectItemGenericCode(obj, typeOverride, true);

        switch (check.type) {
            case ETreasureType.findTarget:
                // notify find provider
                this.findActivityProvider.targetReachedFromAR();
                break;
            case ETreasureType.exploreObject:
                // notify explore provider
                this.exploreProvider.collectFromAR(obj);
                break;
            case ETreasureType.treasure:
                this.itemScanner.collectFromAR(obj);
                break;
            default:
                // notify item scanner for any other types of objects
                // this.messageQueueHandler.prepare("Return to map to use this feature");
                this.itemScanner.collectFromAR(obj);
                break;
        }

        return check;
    }
}
