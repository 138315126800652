import { AppSettings } from 'src/app/services/utils/app-settings';
import { GeneralCache } from '../../app/general-cache';


export enum EPlaceUnifiedSource {
    google = 1,
    here = 2
}

export interface IPlaceProvider {
    id: number;
    code: number;
    name: string;
    enabled: number;
    enabledDev: number;
}

export class PlaceProvider {

    static getOneAvailableLocationProviderDefault() {
        return PlaceProvider.getOneAvailableLocationProvider(GeneralCache.resourceCache.general.placeProviders.content);
        // return EPlaceUnifiedSource.here;
    }

    static getOneAvailableLocationProvider(providers: IPlaceProvider[]) {
        let lpcodes: number[] = [];
        let lpcode: number = EPlaceUnifiedSource.google;
        let devMode: boolean = AppSettings.testerMode;
        for (let i = 0; i < providers.length; i++) {
            let lp: IPlaceProvider = providers[i];
            if (!devMode) {
                if (lp.enabledDev) {
                    lpcodes.push(lp.code);
                }
            } else {
                if (lp.enabled) {
                    lpcodes.push(lp.code);
                }
            }
        }
        if (lpcodes.length > 0) {
            let rindex: number = Math.floor(Math.random() * lpcodes.length);
            if (rindex > lpcodes.length - 1) {
                rindex = lpcodes.length - 1;
            }
            lpcode = lpcodes[rindex];
        }
        return lpcode;
    }
}
