<div fxLayout="row" fxLayoutAlign="space-between center" *ngIf="pagination && (pagination.pages > 0)"
  class="paginator-bar-height">
  <ion-button class="action-button-paging" [disabled]="pagination.hideLeft" (click)="switchPage(-1)">
    <icon-wrapper slot="icon-only" icon="caret-back" iconIos="chevron-back-circle-outline"></icon-wrapper>
  </ion-button>
  <span class="info-text-m text-color-primary" tappable (click)="openPaginateSelector()">page {{pagination.pageDisp}}
    of
    {{pagination.pages}}</span>
  <ion-button class="action-button-paging" [disabled]="pagination.hideRight" (click)="switchPage(1)">
    <icon-wrapper slot="icon-only" icon="caret-forward" iconIos="chevron-forward-circle-outline"></icon-wrapper>
  </ion-button>
</div>