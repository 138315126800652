import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { IonInput, ModalController } from '@ionic/angular';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ParamHandler } from 'src/app/classes/general/params';
import { IStoryRatingNavParams } from 'src/app/classes/def/nav-params/story';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { IRatingView } from 'src/app/classes/def/views/rating';
import { Messages } from 'src/app/classes/def/app/messages';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { StringUtils } from 'src/app/services/app/utils/string-utils';
import { ITextInputParams, ITextInputResult, TextInputViewComponent } from '../text-input/text-input.component';
import { ScrollUtils } from 'src/app/services/general/ui/scroll-utils';

@Component({
  selector: 'modal-rating-frame',
  templateUrl: './rating-frame.component.html',
  styleUrls: ['./rating-frame.component.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        opacity: 0,
      })),
      state('active', style({
        opacity: 1,
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ]
})
export class RatingFrameViewComponent implements OnInit, OnDestroy {
  @ViewChild('myInput', { read: IonInput, static: false }) myInput: IonInput;
  title: string = "";
  selectAll: boolean = false;
  rating: number = 5;
  text: string = "";
  description: string = "";
  inFocus: boolean = false;
  inputMode: boolean = false;
  showState: string = "inactive";
  inputFocused: boolean = false;

  info = {
    ratedBy: "0 users",
    averageRating: "5/5"
  };
  vs: IViewSpecs = ViewSpecs.getDefault();

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  maxLength: number = EMessageTrim.userInput;

  timeouts = {
    kb: null,
    refresh: null
  };

  np: INavParams = null;

  review = {
    description: ""
  };

  reviewCopy = {
    description: ""
  };

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService
  ) {

  }

  refreshShowState() {
    this.showState = "inactive";
    this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
    this.timeouts.refresh = setTimeout(() => {
      this.showState = "active";
    }, 100);
  }

  close() {
    this.dismissCore(null);
  }

  submit() {
    this.text = this.review.description;

    let data: IRatingView = {
      rating: this.rating,
      review: this.text
    };

    if (!this.text) {
      this.uiext.showAlert(Messages.msg.submitWithoutReview.after.msg, Messages.msg.submitWithoutReview.after.sub, 2, null).then((res: number) => {
        switch (res) {
          case EAlertButtonCodes.ok:
            this.dismissCore(data);
            break;
          case EAlertButtonCodes.cancel:
          default:
            break;
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    } else {
      this.dismissCore(data);
    }
  }

  dismissCore(data: IRatingView) {
    setTimeout(() => {
      this.modalCtrl.dismiss(data).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  addReview() {
    console.log("focus");
    this.inputMode = true;
    this.refreshShowState();
    if (!this.inFocus) {
      this.timeouts.kb = setTimeout(() => {
        if (this.myInput) {
          // this.keyboard.show() // for android
          this.myInput.setFocus();
          // this.myInput.nativeElement.setFocus();
        }
        this.inFocus = true;
      }, 350);
    }

  }

  reviewChange(update: boolean) {
    if (update) {
      this.textToHTML();
    } else {
      // popup window
      let params: ITextInputParams = {
        description: "<p></p>",
        title: "Edit review",
        text: this.reviewCopy.description,
        focused: true,
        allowEmpty: true
      };

      this.uiext.showCustomModal(null, TextInputViewComponent, {
        view: {
          fullScreen: false,
          transparent: false,
          large: false,
          addToStack: true,
          frame: false
        },
        params: params
      }).then((description: ITextInputResult) => {
        if (description) {
          console.log(description);
          this.reviewCopy.description = description.text;
          this.textToHTML();
        }
      });
    }
  }

  textToHTML() {
    this.review.description = StringUtils.textToHTML(this.reviewCopy.description);
  }

  textFromHTML() {
    this.reviewCopy.description = StringUtils.textFromHTML(this.review.description);
  }

  textAreaFocused(focused: boolean) {
    console.log("focus: ", focused);
    this.inputFocused = focused;
    if (focused) {
      ScrollUtils.scrollActiveElementIntoVIew(false, null, null, 30);
    }
  }

  checkBlur() {
    console.log("blur");
  }


  setFocused() {
    this.inFocus = true;
  }

  setBlurred() {
    this.inFocus = false;
  }

  back() {
    this.inputMode = false;
    this.refreshShowState();
  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IStoryRatingNavParams = np.params;
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);

      this.title = params.title;
      this.description = params.description;
      if (params.count) {
        this.info.ratedBy = params.count === 1 ? params.count + " user" : params.count + " users";
      }

      this.rating = params.rating;

      if (params.averageRating) {
        this.info.averageRating = params.averageRating + "/5";
      } else {
        this.info.averageRating = "N/A";
      }

      this.textFromHTML();
    }

    this.showState = "active";
  }

  ngOnDestroy() {
    this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
  }
}
