import { NgModule, Injector } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

// theming
import { IonicModule, IonicRouteStrategy, Config } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { AppComponentsModule } from './components/app/app-components.module';
import { AppModalsModule } from './modals/app/app-modals.module';
import { GenericModalsModule } from './modals/generic/generic-modals.module';
import { PipesModule } from './pipes/pipes.module';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { DirectivesModule } from './directives/directives.module';
import { HttpClientModule } from '@angular/common/http';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { DeviceOrientation } from '@ionic-native/device-orientation/ngx';
import { BackgroundMode } from '@ionic-native/background-mode/ngx';
import { Brightness } from '@ionic-native/brightness/ngx';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { Market } from '@ionic-native/market/ngx';
import { FileTransferObject } from '@ionic-native/file-transfer/ngx';
import { Vibration } from '@ionic-native/vibration/ngx';
import { Facebook } from '@ionic-native/facebook/ngx';
import { AdMobFree } from '@ionic-native/admob-free/ngx';
import { Gyroscope } from '@ionic-native/gyroscope/ngx';
import { YoutubeVideoPlayer } from '@ionic-native/youtube-video-player/ngx';
import { CodePush } from '@ionic-native/code-push/ngx';
import { IsDebug } from '@ionic-native/is-debug/ngx';
import { DBMeter } from '@ionic-native/db-meter/ngx';
import { DeviceMotion } from '@ionic-native/device-motion/ngx';
import { AppAvailability } from '@ionic-native/app-availability/ngx';
import { WheelSelector } from '@ionic-native/wheel-selector/ngx';
import { HTTP } from '@ionic-native/http/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { File } from '@ionic-native/file/ngx';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { Media } from '@ionic-native/media/ngx';
import { SocialSharing } from '@ionic-native/social-sharing/ngx';
import { Network } from '@ionic-native/network/ngx';
import { SignInWithApple } from '@ionic-native/sign-in-with-apple/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AppComponentsGenericModule } from './components/generic/generic-components.module';
import { PagesLinksModule } from './pages/pages-links.module';
// import { InventoryHomePage } from './pages/inventory/inventory-home/inventory-home.page';

import { BackgroundGeolocation } from "@ionic-native/background-geolocation/ngx";
import { NativeAudio } from '@ionic-native/native-audio/ngx';
import { AdMobPro } from '@ionic-native/admob-pro/ngx';

// import { SocialLoginModule } from '@abacritt/angularx-social-login';
import { Keyboard } from '@ionic-native/keyboard/ngx';
import { OpenNativeSettings } from '@ionic-native/open-native-settings/ngx';
import { BLE } from '@awesome-cordova-plugins/ble/ngx';
import { MediaCapture } from '@ionic-native/media-capture/ngx';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { IMqttServiceOptions, MqttModule } from 'ngx-mqtt';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

export const connection: IMqttServiceOptions = {
  hostname: 'mq.leplace-api.com',
  port: 9101,
  path: '/',
  clean: true, // Retain session
  connectTimeout: 4000, // Timeout period
  reconnectPeriod: 4000, // Reconnect period
  // Authentication information
  clientId: 'lpw',
  username: 'pi',
  password: 'raspberry',
  // protocol: 'ws',
  protocol: 'wss',
  connectOnCreate: false // will be started from service
};
@NgModule({
  declarations: [
    AppComponent,
    // InventoryHomePage,
  ],
  entryComponents: [
    // InventoryHomePage
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    DirectivesModule,
    AppComponentsModule,
    AppComponentsGenericModule,
    AppModalsModule,
    GenericModalsModule,
    PipesModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    FlexLayoutModule,
    // SocialLoginModule,
    IonicModule.forRoot({
      // scrollPadding: false,
      // scrollAssist: true,
      mode: 'md',
      menuType: 'overlay',
      // autoFocusAssist: false
      // popoverEnter: 'custom-popover-enter'
    }),
    IonicStorageModule.forRoot({
      name: '__lpworlddb',
      // driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    // SharedModule.forRoot(),
    MqttModule.forRoot(connection),
    AppRoutingModule,
    PagesLinksModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    })
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    // { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
    // { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    // native plugins
    // only include native plugins as the others are provided in root
    Geolocation,
    DeviceOrientation,
    BackgroundMode,
    Brightness,
    Diagnostic,
    AppVersion,
    LocationAccuracy,
    SocialSharing,
    Market,
    FileTransfer,
    FileTransferObject,
    File,
    Camera,
    Vibration,
    Facebook,
    AdMobFree,
    AdMobPro,
    Gyroscope,
    YoutubeVideoPlayer,
    CodePush,
    IsDebug,
    Media,
    DBMeter,
    DeviceMotion,
    AppAvailability,
    WheelSelector,
    Network,
    BackgroundGeolocation,
    HTTP,
    SignInWithApple,
    NativeAudio,
    Keyboard,
    AndroidPermissions,
    OpenNativeSettings,
    BLE,
    MediaCapture,
    NgbCarouselConfig
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;
  constructor(
    public config: Config,
    public injector: Injector
  ) {
    this.setCustomTransitions();
    AppModule.injector = injector;
  }
  private setCustomTransitions() {
    // this.config.setTransition('leplace-modal-transition-enter', ModalTranslateEnterTransition);
    // this.config.setTransition('leplace-modal-transition-leave', ModalTranslateLeaveTransition);
  }
}
