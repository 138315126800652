
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject, timer } from "rxjs";
import { ResourceManager } from "../../classes/general/resource-manager";
import { IQueueMessage, IMessageQueueEvent, EQueueMessageCode } from 'src/app/classes/utils/queue';


/**
 * handle message display queue
 * regular delay or priority mode
 * not real time, only for UI messages
 */
@Injectable({
    providedIn: 'root'
})
export class MessageQueueHandlerService implements OnDestroy {
    messageQueue: IQueueMessage[] = [];
    messageQueueOutputObservable: BehaviorSubject<IMessageQueueEvent>;
    outputDelay: number = 5000;
    outputDelayLong: number = 20000;
    observables = {
        qWatch: null
    };
    timeouts = {
        hide: null
    };
    debug: boolean = false;
    testId: number = 1;

    constructor(

    ) {
        console.log("message queue handler service");
        this.messageQueueOutputObservable = new BehaviorSubject(null);
        this.queueWatcher();
    }

    getQueueWatch() {
        return this.messageQueueOutputObservable;
    }


    prepareLong(message: string, priority: boolean, code: number) {
        this.prepareCore(message, priority, code, true);
    }


    /**
     * add item to message queue
     * @param message 
     */
    prepare(message: string, priority: boolean, code: number) {
        this.prepareCore(message, priority, code, false);
    }


    /**
     * add item to message queue
     * @param message 
     */
    prepareCore(message: string, priority: boolean, code: number, persistent: boolean) {
        if (this.debug) {
            message += " " + this.testId;
            this.testId += 1;
            console.log("qPrepare: ", message);
        }
        this.messageQueue.push({
            message: message,
            priority: priority,
            code: code,
            scheduled: false,
            timedelta: 0,
            timeout: null,
            persistent: persistent
        });
    }


    schedule(message: string, timedelta: number, code: number) {
        if (this.debug) {
            message += " " + this.testId;
            this.testId += 1;
            console.log("qSchedule: ", message);
        }
        this.messageQueue.push({
            message: message,
            priority: true,
            code: code,
            scheduled: false,
            timedelta: timedelta,
            timeout: null
        });
    }

    /**
     * watch queue and trigger output handler if it is not active and there are items in the queue
     */
    queueWatcher() {
        if (!this.observables.qWatch) {
            let timer1 = timer(0, 500);
            this.observables.qWatch = timer1.subscribe(() => {
                // check if there are messages in the queue
                if (this.checkUnscheduledMessages()) {
                    // handle next message
                    this.outputHandler();
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    checkUnscheduledMessages() {
        let count: number = this.messageQueue.filter(m => m.scheduled === false).length;
        if (count > 0) {
            if (this.debug) {
                console.log("unscheduled messages: " + count);
            }
            return true;
        }
        return false;
    }

    /**
     * check next message
     * return by priority
     * @param unscheduled 
     */
    checkNextMessage(unscheduled: boolean) {
        if (this.messageQueue.length > 0) {
            if (unscheduled) {
                let first: IQueueMessage = null;
                let priorityFirst: IQueueMessage = null;
                for (let i = 0; i < this.messageQueue.length; i++) {
                    let qm: IQueueMessage = this.messageQueue[i];
                    if (!qm.scheduled) {
                        if (!first) {
                            first = qm;
                        }
                        if (!priorityFirst && qm.priority) {
                            priorityFirst = qm;
                        }
                        if (first && priorityFirst) {
                            break;
                        }
                    }
                }

                if (priorityFirst) {
                    return priorityFirst;
                } else {
                    return first;
                }
            } else {
                return this.messageQueue[0];
            }
        } else {
            return null;
        }
    }

    /**
     * trigger timeout for message dequeue
     */
    outputHandler() {
        let nextMessage: IQueueMessage = this.checkNextMessage(true);
        if (!nextMessage) {
            return false;
        }

        if (nextMessage.priority) {
            nextMessage.scheduled = true;
            // check for timedelta
            let timedelta: number = nextMessage.timedelta;
            if (timedelta > 0) {
                // wait for timedelta until output next
                // console.log("schedule message: ", nextMessage.message);
                nextMessage.timeout = setTimeout(() => {
                    this.outputNextCore(nextMessage);
                }, timedelta * 100);
            } else {
                // immediate entry
                this.outputNextCore(nextMessage);
            }
        } else {
            // wait regular timeout (until the last message expired)
            if (!this.timeouts.hide) {
                if (!this.messageQueue.length) {
                    return;
                }
                nextMessage.scheduled = true;
                this.outputNextCore(nextMessage);
            }
        }
    }


    /**
     * show next message
     * hide (replace) current shown message
     * @param nextMessage 
     */
    outputNextCore(nextMessage: IQueueMessage) {
        // hide (dispose) current message
        this.timeouts.hide = ResourceManager.clearTimeout(this.timeouts.hide);
        this.hideCurrentMessage();

        if (!nextMessage) {
            return false;
        }

        // remove from queue
        let index: number = this.messageQueue.map(m => m.message).indexOf(nextMessage.message);
        this.messageQueue.splice(index, 1);

        if (this.debug) {
            console.log(new Date().getTime() + " output next: " + nextMessage.message);
        }

        let qEvent: IMessageQueueEvent = {
            data: nextMessage,
            state: true
        };

        // send message show event to UI
        this.messageQueueOutputObservable.next(qEvent);

        // auto hide (dispose) after delay
        this.timeouts.hide = setTimeout(() => {
            // hide
            this.hideCurrentMessage();
            // timeout expired
            this.timeouts.hide = ResourceManager.clearTimeout(this.timeouts.hide);
        }, nextMessage.persistent ? this.outputDelayLong : this.outputDelay);
    }

    hideCurrentMessage() {
        // hide
        let qEvent: IMessageQueueEvent = {
            data: null,
            state: false
        };

        if (this.debug) {
            console.log(new Date().getTime() + " output hide");
        }

        // send message hide event to UI
        this.messageQueueOutputObservable.next(qEvent);
    }

    /**
     * hide current message
     * cancel all messages in the queue
     * reset the queue
     */
    cleanup() {
        this.hideCurrentMessage();
        if (this.messageQueue.length > 0) {
            for (let i = 0; i < this.messageQueue.length; i++) {
                ResourceManager.clearTimeout(this.messageQueue[i].timeout);
            }
        }
        this.messageQueue = [];
        this.messageQueueOutputObservable.next(null);
    }

    /**
     * clear timeouts
     */
    ngOnDestroy() {
        this.observables = ResourceManager.clearSubObj(this.observables);
        this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
    }
}
