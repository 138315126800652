import { IPlatformFlags } from "../../classes/def/app/platform";
import { UiExtensionService } from "../general/ui/ui-extension";
import { Injectable } from "@angular/core";
import { MapManagerService } from './map-manager';
import { MapSettings } from 'src/app/classes/utils/map-settings';
import { IMoveMapOptions } from 'src/app/classes/def/map/interaction';
import { EMarkerLayers } from 'src/app/classes/def/map/marker-layers';
import { MarkerHandlerService } from './markers';
import { IPlaceMarkerContent } from 'src/app/classes/def/map/map-data';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { GeometryUtils } from '../utils/geometry-utils';
import { MarkerUtilsService } from './marker-utils-provider';
import { IJoystickStatusUpdate } from 'src/app/components/generic/components/joystick-ngx/joystick-ngx.component';
import { AppConstants } from 'src/app/classes/app/constants';
import { MathUtils } from 'src/app/classes/general/math';
import { BehaviorSubject, timer } from 'rxjs';
import { InventoryWizardService } from '../app/modules/inventory-wizard';
import { MessageQueueHandlerService } from '../general/message-queue-handler';
import { UserDataService } from '../data/user';
import { EStatCache } from 'src/app/classes/def/app/settings';
import { Messages } from 'src/app/classes/def/app/messages';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { VirtualPositionService, IVirtualLocation, EVirtualLocationSource } from '../app/modules/virtual-position';
import { ResourceMonitorDataService } from '../data/resource-monitor';
import { HeadingService } from './heading';
import { SleepUtils } from '../utils/sleep-utils';
import { GenericQueueService } from '../general/generic-queue';
import { EItemCodes, IGameItem } from 'src/app/classes/def/items/game-item';
import { GameUtils } from 'src/app/classes/utils/game-utils';
import { ActivityStatsTrackerService } from '../app/modules/activity-stats-tracker';
import { SettingsManagerService } from "../general/settings-manager";
import { IJoystickDrivingSteeringData, JoystickUtils } from "../utils/joystick-utils";
import { EQueueMessageCode } from "src/app/classes/utils/queue";
import { ILatLng } from "src/app/classes/def/map/coords";


export interface IDroneConfig {
    /* deg/s */
    rotateSensitivity: number;
    /* deg/s */
    tiltSensitivity: number;
    /** controls acceleration */
    throttleSensitivity: number;
    /** nominal throttle level */
    nominalThrottle: number;
    /* speed/throttle sensitivity, controls top speed and acceleration */
    speedSensitivity: number;
    /** limits top speed */
    maxSpeed: number;
    /** limits throttle for low speed zones */
    speedLimitFactor: number;
    /* battery/throttle/s sensitivity, controls battery drain, computed from nominal capacity and throttle level */
    batterySensitivity: number;
    /** controls nominal capacity */
    batteryDurationNominal: number;
    batteryLowThreshold: number;
    batteryEmptyThreshold: number;
    adaptiveRate: boolean;
    simulationRate: number;
}

export interface IDroneConfigSession {
    animate: boolean;
    mapAnimation: number;
}

export interface IDroneStatus {
    location: ILatLng;
    heading: number;
    altitudeLevel: number;
    zoomLevel: number;
    /* 3d mode up/down */
    tilt: number;
    /* m/s */
    speed: number;
    throttle: number;
    lastTimestamp: number;
    lastStatusUpdate: number;
    averageTs: number;
    averageTsLong: number;
    averageTsCounter: number;
    batteryLevel: number;
    batteryLowAlarm: boolean;
    /** handle initial tilt settings */
    viewInitialized: boolean;
}

export interface IDroneStatusUpdate {
    code: number;
    value: number;
    message?: string;
}

export enum EDroneStatusUpdateCode {
    statusCheck = 0,
    batteryLevel = 1,
    batteryWarn = 2,
    lowFPSMode = 3,
    exitBatteryEmpty = -1
}

export enum EDroneSimRate {
    low = 100,
    medium = 40,
    high = 40
}

export enum EDroneSimRateIOS {
    low = 100,
    medium = 40,
    high = 40
}

export enum EDroneSimParams {
    averageTsCounter = 100
}

@Injectable({
    providedIn: 'root'
})
export class DroneSimulatorService {

    platform: IPlatformFlags = {} as IPlatformFlags;
    mapOptions: any;

    // flag to stop simulation
    simulationRunning: boolean = false;
    // flag to land drone
    requestLanding: boolean = false;
    // check if the drone was launched
    simulationStarted: boolean = false;

    unlockLimiter: boolean = true;
    waitForUpdate: boolean = true;

    subscription = {
        mainLoop: null
    };

    timeout = {
        mainLoop: null,
        resume: null,
        registerDroneUsed: null
    };

    observable = {
        status: null
    };

    droneStatusInit: IDroneStatus = {
        location: null,
        heading: 0,
        altitudeLevel: 1,
        zoomLevel: null,
        tilt: MapSettings.droneTilt,
        speed: 0,
        throttle: 10,
        batteryLevel: 100,
        averageTs: 0,
        averageTsLong: 0,
        averageTsCounter: 0,
        lastTimestamp: null,
        lastStatusUpdate: null,
        batteryLowAlarm: false,
        viewInitialized: false
    };

    droneConfigInit: IDroneConfig = {
        // orig 90
        rotateSensitivity: 50,
        tiltSensitivity: 30,
        throttleSensitivity: 20,
        nominalThrottle: 10,
        speedSensitivity: 1,
        batterySensitivity: null,
        // batteryDurationNominal: 300,
        batteryDurationNominal: 720,
        // batteryDurationNominal: 60,
        batteryLowThreshold: 10,
        batteryEmptyThreshold: 3,
        maxSpeed: 25,
        speedLimitFactor: 1,
        adaptiveRate: false,
        simulationRate: EDroneSimRate.medium
    };

    droneConfigSessionInit: IDroneConfigSession = {
        animate: false,
        mapAnimation: 30
    };

    droneConfigSession: IDroneConfigSession = {} as IDroneConfigSession;
    droneConfig: IDroneConfig = {} as IDroneConfig;

    statusUpdateRate: number = 200;

    droneStatus: IDroneStatus;
    lastJoystickStatus: IJoystickStatusUpdate;

    simulationDisconnected: boolean = false;
    simulationPausedCompute: boolean = false;
    batteryStatusLoaded: boolean = false;
    adjCompat: number = 4;
    itemReady: IGameItem;

    constructor(
        public uiext: UiExtensionService,
        public mapManager: MapManagerService,
        public markerHandler: MarkerHandlerService,
        public markerUtils: MarkerUtilsService,
        public inventoryWizard: InventoryWizardService,
        public messageQueueHandler: MessageQueueHandlerService,
        public userData: UserDataService,
        public virtualPositionService: VirtualPositionService,
        public resourceMonitor: ResourceMonitorDataService,
        public headingService: HeadingService,
        public activityStatsTracker: ActivityStatsTrackerService,
        public settings: SettingsManagerService,
        public q: GenericQueueService
    ) {
        console.log("flight simulator service created");
        this.mapOptions = Object.assign({}, MapSettings.mapOptions);
        this.observable = ResourceManager.initBsubObj(this.observable);
        this.droneConfig = Object.assign({}, this.droneConfigInit);
        this.droneConfigSession = Object.assign({}, this.droneConfigSessionInit);
        this.resourceMonitor.getWatchLoader().subscribe((loaded: boolean) => {
            if (loaded) {
                // 180 x 4 = 720
                this.droneConfigInit.batteryDurationNominal = AppConstants.gameConfig.droneBatteryDurationNominal * this.adjCompat;
                this.droneConfig.batteryDurationNominal = AppConstants.gameConfig.droneBatteryDurationNominal * this.adjCompat;
                this.initSpecs();
            }
        }, (err: Error) => {
            console.error(err);
            this.initSpecs();
        });

        this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.onPlatformLoaded();
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    onPlatformLoaded() {
        this.platform = SettingsManagerService.settings.platformFlags;
    }

    /**
     * init drone specs
     * mechanical and electrical characteristics
     */
    initSpecs() {
        this.droneConfig.batterySensitivity = 100 / this.droneConfig.batteryDurationNominal / this.droneStatusInit.throttle;
    }

    setDroneMarker(um: IPlaceMarkerContent) {
        this.mapManager.setDroneMarker(um);
    }

    initDroneMarker(callback: (data: IPlaceMarkerContent) => void) {
        this.mapManager.initDroneMarker(callback);
    }

    getDroneStatus(): IDroneStatus {
        return this.droneStatus;
    }

    /**
     * loaded from db (current battery level for user)
     * @param battery 
     */
    setBatteryLevel(battery: number) {
        this.droneStatus.batteryLevel = battery;
        this.droneStatusInit.batteryLevel = battery;
    }

    watchStatus(): BehaviorSubject<IDroneStatusUpdate> {
        return this.observable.status;
    }

    /**
     * set altitude level 1-10
     * returns zoom level
     * @param altitudeLevel 
     */
    setAltitude(altitudeLevel: number): number {
        // given point a (xa, ya), point b (xb, yb)
        // y = x*(yb-ya)/(xb-xa) + (ya*xb-yb*xa)/(xb-xa)
        let ya: number = MapSettings.zoomInLevelDroneTakeoff;
        let yb: number = MapSettings.zoomOutLevelDrone;
        let xa: number = 1;
        let xb: number = 10;

        if (altitudeLevel < xa || altitudeLevel > xb) {
            return null;
        }

        // console.log("set altitude: ", altitudeLevel);

        let zoomLevel: number = altitudeLevel * (yb - ya) / (xb - xa) + (ya * xb - yb * xa) / (xb - xa);
        this.droneStatus.altitudeLevel = altitudeLevel;
        this.droneStatus.zoomLevel = zoomLevel;
        return zoomLevel;
    }

    /**
     * set low speed zone
     * e.g. story location nearby
     * @param enable 
     */
    setLowSpeedZone(enable: boolean) {
        if (this.simulationStarted) {
            if (enable) {
                this.droneStatusInit.throttle = this.droneConfigInit.nominalThrottle / 3;
                this.droneConfig.throttleSensitivity = this.droneConfigInit.throttleSensitivity / 4;
            } else {
                this.droneStatusInit.throttle = this.droneConfigInit.nominalThrottle;
                this.droneConfig.throttleSensitivity = this.droneConfigInit.throttleSensitivity;
            }
        }
    }

    /**
     * adaptive zoom based on airspeed
     * @param speed 
     */
    setZoomLevelBasedOnSpeed(speed: number): number {
        let ya: number = MapSettings.zoomInLevelDroneTakeoff;
        let yb: number = MapSettings.zoomOutLevelDrone;
        let xa: number = 0;
        let xb: number = this.droneConfig.maxSpeed;

        // account for speed upgrades to increase zoom levels too
        let multiplierAlpha: number = -0.11; // 0 means no change, -1 means 1:1 change with speed level
        let speedMultiplier: number = this.droneConfig.speedSensitivity / this.droneConfigInit.speedSensitivity;
        let multiplier: number = 1 + multiplierAlpha * (speedMultiplier - 1);
        // check max zoom out level, adjust multiplier if required
        if ((ya * multiplier) < MapSettings.zoomOutLevel) {
            let multiplierAdj: number = MapSettings.zoomOutLevel / (ya * multiplier);
            multiplier = multiplier * multiplierAdj;
        }

        ya = ya * multiplier;
        yb = yb * multiplier;

        let zoomLevel: number = speed * (yb - ya) / (xb - xa) + (ya * xb - yb * xa) / (xb - xa);
        this.droneStatus.zoomLevel = zoomLevel;
        return zoomLevel;
    }

    setHeading(heading: number) {
        // console.log("set heading: ", heading);
        this.droneStatus.heading = heading;
    }

    setTilt(tilt: number) {
        // console.log("set tilt: ", tilt);
        this.droneStatus.tilt = tilt;
    }

    setHeadingDelta(headingDelta: number) {
        this.droneStatus.heading += headingDelta;
        this.setHeading(GeometryUtils.translateTo360(this.droneStatus.heading));
    }

    setTiltDelta(tiltDelta: number) {
        let tilt: number = this.droneStatus.tilt;
        tilt += tiltDelta;
        if (tilt > MapSettings.droneTilt) {
            tilt = MapSettings.droneTilt;
        }
        if (tilt < 0) {
            tilt = 0;
        }
        this.setTilt(tilt);
    }

    /**
     * set throttle delta from nominal value
     * @param delta 
     */
    setThrottleDeltaAbs(delta: number) {
        let throttle: number = this.droneStatusInit.throttle + delta;
        this.setThrottle(throttle);

    }

    setThrottle(throttle: number) {
        if (throttle < 0) {
            throttle = 0;
        }
        this.droneStatus.throttle = throttle;
    }

    setAltitudeDelta(delta: number) {
        this.setAltitude(this.droneStatus.altitudeLevel + delta);
    }

    onJoystickAction(event: IJoystickStatusUpdate) {
        this.lastJoystickStatus = event;
    }

    onButtonAction(mode: number) {
        this.lastJoystickStatus = {
            status: true,
            outputData: {
                angle: {
                    degree: 0,
                    radian: 0
                },
                direction: null,
                vector: {
                    x: 0,
                    y: 0
                },
                raw: {
                    distance: 0,
                    position: null
                },
                distance: 0,
                force: 1,
                identifier: 0,
                instance: null,
                position: null,
                pressure: 0
            }
        }
        switch (mode) {
            case 0:
                this.lastJoystickStatus.outputData.angle.degree = 0;
                break;
            case 1:
                this.lastJoystickStatus.outputData.angle.degree = 135;
                break;
            case 2:
                this.lastJoystickStatus.outputData.angle.degree = 45;
                break;
        }
    }

    /**
     * ts is the norm for control sensitivity
     * represents scaling to units/second (e.g. force/second)
     * @param ts 
     */
    processJoystickControls(ts: number) {
        if (!this.lastJoystickStatus) {
            return;
        }
        if (this.lastJoystickStatus.status) {
            let dsData: IJoystickDrivingSteeringData = JoystickUtils.extractDrivingSteering(this.lastJoystickStatus.outputData);
            let driving: number = dsData.driving;
            let steering: number = dsData.steering;
            this.setHeadingDelta(steering / 100 * this.droneConfig.rotateSensitivity * ts / 1000);
            this.setThrottleDeltaAbs(driving * this.droneConfig.throttleSensitivity);
        } else {
            // joystick released
            this.setHeadingDelta(0);
            this.setTiltDelta(0);
            this.setThrottle(this.droneStatusInit.throttle);
        }
    }

    /**
    * move map w/ drone marker
    * main loop simulation fn
    * @param coordinates 
    * @param zoom 
    */
    moveMapDroneMode(coordinates: ILatLng): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            let options: IMoveMapOptions = {
                animateCamera: this.droneConfigSession.animate,
                animateMarker: this.droneConfigSession.animate,
                syncMode: false,
                // zoom: !this.droneStatus.viewInitialized ? this.droneStatus.zoomLevel : null,
                zoom: this.droneStatus.zoomLevel,
                bearing: this.droneStatus.heading,
                // tilt: !this.droneStatus.viewInitialized ? this.droneStatus.tilt : null,
                tilt: this.droneStatus.tilt,
                moveMap: true,
                // force: false,
                force: this.unlockLimiter,
                // unlock map update rate limiter, remove queue delay too
                unlockLimiter: this.unlockLimiter,
                bumpLimiter: true,
                userMarker: false,
                droneMarker: true,
                duration: this.droneConfigSession.mapAnimation
            };

            // the marker location is set here
            options.location = coordinates;
            // this.droneMarker.location = coordinates;

            if (!this.simulationDisconnected) {
                // this.headingService.updateHeadingViaDrone()
                this.mapManager.moveMapWrapper(coordinates, options).then(() => {
                    this.droneStatus.viewInitialized = true;
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            } else {
                resolve(false);
            }
        });
        return promise;
    }

    /**
     * apply drone upgrade item
     * @param code 
     * @param value 
     * @param add 
     */
    applyUpgrade(code: number, value: number, add: boolean) {
        if (!add) {
            value = 1;
        }
        switch (code) {
            case EItemCodes.greenTech:
                this.droneConfig.batteryDurationNominal = AppConstants.gameConfig.droneBatteryDurationNominal * this.adjCompat * value;
                break;
            case EItemCodes.turboTech:
                this.droneConfig.maxSpeed = this.droneConfigInit.maxSpeed * value;
                this.droneConfig.speedSensitivity = this.droneConfigInit.speedSensitivity * value;
                break;
            default:
                break;
        }
        this.initSpecs();
    }

    /**
     * prepare upgrade
     * disable / un-apply on remove
     * @param item 
     * @param add 
     */
    prepareUpgrade(item: IGameItem, add: boolean) {
        if (!add) {
            this.applyUpgradePrepared(false);
            this.itemReady = null;
        }
        else {
            this.itemReady = item;
        }
    }

    /**
     * apply prepared upgrade
     * @param apply 
     */
    applyUpgradePrepared(apply: boolean) {
        if (!this.itemReady) {
            return;
        }
        let itemCode: number = GameUtils.getItemCode(this.itemReady.code);
        let value: number = this.itemReady.value;
        this.applyUpgrade(itemCode, value, apply);
    }

    /**
     * check upgrade prepared
     */
    hasPreparedUpgrade() {
        return this.itemReady != null;
    }

    /**
     * recharge battery, reset alarm
     */
    rechargeBattery() {
        this.droneStatus.batteryLowAlarm = false;
        this.setBatteryLevel(100);
        this.sendBatteryStatusResolve().then(() => {

        });
    }

    /**
     * ask for recharge
     * check required scan energy
     * handle recharge scan energy
     * handle user interaction
     */
    rechargeBatteryWizard(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let amount: number = AppConstants.gameConfig.droneScanEnergy;
            // the user already confirmed the intention to charge the battery
            let rechargeIntentionConfirmed: boolean = false;
            let fn: () => any = () => {
                // ask for recharge
                let promiseAskRechargeBattery: Promise<number>;
                if (!rechargeIntentionConfirmed) {
                    let sub: string = Messages.msg.rechargeDrone.before.sub;
                    sub += "<p>Requires: " + amount + " energy</p>";
                    promiseAskRechargeBattery = this.uiext.showAlert(Messages.msg.rechargeDrone.before.msg, sub, 2, null);
                } else {
                    // don't ask again for charging drone battery
                    promiseAskRechargeBattery = Promise.resolve(EAlertButtonCodes.ok);
                }
                promiseAskRechargeBattery.then((res: number) => {
                    if (res === EAlertButtonCodes.ok) {
                        // the user wants to recharge the battery
                        this.inventoryWizard.consumeScanEnergyGenericWizard(() => {
                            // on complete, proceed with recharge, then resolve
                            this.messageQueueHandler.prepare("-" + amount + " energy", false, EQueueMessageCode.info);
                            this.rechargeBattery();
                            resolve(true);
                        }, () => {
                            // on recharge request
                            // there is not enough scan energy to recharge the battery
                            rechargeIntentionConfirmed = true;
                            this.inventoryWizard.goToInventoryForScanEnergy().then((res: boolean) => {
                                // true => scan energy purchased
                                // false => scan energy not purchased
                                if (res) {
                                    // the user recharged scan energy, try again to charge drone battery
                                    fn();
                                } else {
                                    // the user did not manage to charge scan energy for some reason
                                    fn();
                                }
                            }).catch((err: Error) => {
                                console.error(err);
                                // an error occured
                                resolve(false);
                            });
                        }, () => {
                            // on recharge reject
                            // the user does not want to recharge the battery
                            resolve(false);
                        }, amount);
                    } else {
                        // the user does not want to recharge the battery
                        resolve(false);
                    }
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                });
            }
            fn();
        });
        return promise;
    }

    /**
     * launch drone at hover altitude
     */
    launchDrone(location: ILatLng) {
        this.droneStatus = Object.assign({}, this.droneStatusInit);
        this.droneStatus.zoomLevel = this.setAltitude(this.droneStatus.altitudeLevel);
        this.droneStatus.location = new ILatLng(location.lat, location.lng);
        this.droneConfigSession = Object.assign({}, this.droneConfigSessionInit);
        // set default throttle
        this.droneStatusInit.throttle = this.droneConfig.nominalThrottle;
        this.droneStatus.throttle = this.droneConfig.nominalThrottle;
    }

    async landDrone() {
        await this.markerHandler.disposeLayerResolve(EMarkerLayers.DRONE);
        await this.markerHandler.disposeLayerResolve(EMarkerLayers.DRONE_CIRCLE);
    }

    /**
    * process drone mechanics
    * (location, speed, heading) => next_location
    * @dt in ms
    */
    computeNextPosition(dt: number) {
        let nextDistance: number = this.droneStatus.speed * dt / 1000;
        let nextPosition: ILatLng = GeometryUtils.getPointOnHeading(this.droneStatus.location, nextDistance, this.droneStatus.heading);
        return nextPosition;
    }

    private async exitBatteryEmpty() {
        await this.stopSimulation();
        let status: IDroneStatusUpdate = {
            code: EDroneStatusUpdateCode.exitBatteryEmpty,
            value: 0,
            message: "Drone battery empty. Please recharge to continue flying"
        };
        this.observable.status.next(status);
    }

    /**
     * compute battery level drain based on throttle, battery sensitivity and dt
     * @param throttle 
     * @param dt 
     */
    computeBatteryLevel(throttle: number, dt: number) {
        let drain: number = throttle * this.droneConfig.batterySensitivity * dt / 1000;
        let bat: number = this.droneStatus.batteryLevel - drain;
        if (bat < this.droneConfig.batteryEmptyThreshold) {
            this.pauseSimulation();
            this.rechargeBatteryWizard().then((charged: boolean) => {
                if (charged) {
                    this.uiext.showRewardPopupQueue("", Messages.msg.droneRecharged.after.sub, null, false, 2000).then(() => {
                        this.resumeSimulation();
                    });
                } else {
                    this.exitBatteryEmpty();
                }
            }).catch((err: Error) => {
                console.error(err);
                this.exitBatteryEmpty();
            });
        } else {
            this.setBatteryLevel(bat);

            let status: IDroneStatusUpdate = {
                code: EDroneStatusUpdateCode.batteryLevel,
                value: this.droneStatus.batteryLevel
            };
            this.observable.status.next(status);

            if (bat < this.droneConfig.batteryLowThreshold) {
                if (!this.droneStatus.batteryLowAlarm) {
                    this.droneStatus.batteryLowAlarm = true;
                    let status: IDroneStatusUpdate = {
                        code: EDroneStatusUpdateCode.batteryWarn,
                        value: this.droneStatus.batteryLevel,
                        message: "Drone battery running low"
                    };
                    this.observable.status.next(status);
                }
            }
        }
    }

    setSimulationRateMode(mode: number) {
        let ios: boolean = this.platform.IOS;
        let ts: number = ios ? EDroneSimRateIOS.medium : EDroneSimRate.medium;
        switch (mode) {
            case 1:
                ts = ios ? EDroneSimRateIOS.low : EDroneSimRate.low;
                this.unlockLimiter = false;
                this.waitForUpdate = true;
                break;
            case 2:
                ts = ios ? EDroneSimRateIOS.medium : EDroneSimRate.medium;
                this.unlockLimiter = true;
                this.waitForUpdate = true;
                break;
            case 3:
                ts = ios ? EDroneSimRateIOS.high : EDroneSimRate.high;
                this.unlockLimiter = true;
                this.waitForUpdate = false;
                break;
            default:
                ts = ios ? EDroneSimRateIOS.medium : EDroneSimRate.medium;
                this.unlockLimiter = false;
                this.waitForUpdate = true;
                break;
        }
        this.setSimulationRate(ts);
    }

    /**
     * set simulation rate / FPS
     * @param ts 
     */
    setSimulationRate(ts: number) {
        if (!ts) {
            this.droneConfig.adaptiveRate = true;
            ts = EDroneSimRate.medium;
        }
        this.setSimulationRateCore(ts);
    }

    /**
     * set simulation rate / FPS core
     * @param ts 
     */
    setSimulationRateCore(ts: number) {
        this.droneConfig.simulationRate = ts;
        this.setAnimationRate(ts);
    }

    /**
     * set animation rate relative to simulation rate / FPS
     * @param ts 
     */
    setAnimationRate(ts: number) {
        let animationRate: number = ts - 10;
        if (ts > 100) {
            animationRate = 100;
        }
        if (animationRate < 0) {
            animationRate = 0;
        }
        this.droneConfigSession.mapAnimation = animationRate;
    }

    checkAdaptiveSimulationRate(actualTs: number) {
        this.droneStatus.averageTsCounter += 1;
        if (this.droneStatus.averageTs === 0) {
            this.droneStatus.averageTs = actualTs;
            this.droneStatus.averageTsLong = actualTs;
        } else {
            this.droneStatus.averageTs = MathUtils.lowPassFilterRC(this.droneStatus.averageTs, actualTs, 0.1, 0.04);
            this.droneStatus.averageTsLong = MathUtils.lowPassFilterRC(this.droneStatus.averageTs, actualTs, 0.8, 0.04);
        }

        if (this.droneStatus.averageTsCounter >= EDroneSimParams.averageTsCounter) {
            this.droneStatus.averageTsCounter = 0;
            let averageTs: number = Math.floor(this.droneStatus.averageTsLong);
            let enableLowFpsMode: boolean = false;
            if (enableLowFpsMode && (averageTs > 300)) {
                // switch to animated mode, low FPS
                this.droneConfigSession.animate = true;
                this.setAnimationRate(averageTs);
                let status: IDroneStatusUpdate = {
                    code: EDroneStatusUpdateCode.lowFPSMode,
                    value: 1,
                    message: "Switched to low FPS mode"
                };
                this.observable.status.next(status);
            } else {
                // remove animation
                this.droneConfigSession.animate = false;
            }
            console.log("drone simulator average ts: ", averageTs, " of: ", this.droneConfig.simulationRate);
            if (this.droneConfig.adaptiveRate) {
                // check adaptive rate
                let runningLow: boolean = averageTs > (this.droneConfig.simulationRate * 1.5);
                let runningHigh: boolean = averageTs < this.droneConfig.simulationRate * 1.1;
                let newSimRate: number = 0;
                switch (this.droneConfig.simulationRate) {
                    case EDroneSimRate.high:
                        newSimRate = runningLow ? EDroneSimRate.medium : runningHigh ? EDroneSimRate.high : EDroneSimRate.high;
                        break;
                    case EDroneSimRate.medium:
                        newSimRate = runningLow ? EDroneSimRate.low : runningHigh ? EDroneSimRate.high : EDroneSimRate.medium;
                        break;
                    case EDroneSimRate.low:
                        newSimRate = runningLow ? EDroneSimRate.low : runningHigh ? EDroneSimRate.medium : EDroneSimRate.low;
                        break;
                    default:
                        newSimRate = this.droneConfig.simulationRate;
                        break;
                }
                this.setSimulationRateCore(newSimRate);
                console.log("drone simulator new rate: ", this.droneConfig.simulationRate);
            }
        }
    }

    /**
     * simulation loop
     */
    runLoop() {
        // console.log("loop");
        if (!this.simulationRunning) {
            console.warn("simulation not running");
            return;
        }

        if (this.subscription.mainLoop != null) {
            console.warn("main loop already running");
            return;
        }

        let moveMapInProgress: boolean = false;
        let timer1 = timer(0, 10);

        // using timer is better for simulation accuracy
        // decoupled simulation (true speed) from rendering (eventually true speed)

        // can be called at any rate, limited by simulation rate
        let loopFn = () => {
            if (!moveMapInProgress) {
                // loop fn
                let t1: number = new Date().getTime();
                let actualTs: number;

                if (!this.droneStatus.lastTimestamp) {
                    this.droneStatus.lastTimestamp = t1;
                    actualTs = this.droneConfig.simulationRate;
                    console.log("drone simulator initial frame");
                } else {
                    actualTs = t1 - this.droneStatus.lastTimestamp;
                    if (actualTs > 1000) {
                        // max limiter, prevent battery usage for exceptional cases (e.g. resumed from background, returned from inventory)
                        actualTs = 1000;
                    }
                    if (actualTs >= this.droneConfig.simulationRate) {
                        this.droneStatus.lastTimestamp = t1;
                        this.checkAdaptiveSimulationRate(actualTs);
                    } else {
                        return;
                    }
                }

                // rate limiter
                if (this.waitForUpdate) {
                    moveMapInProgress = true;
                }

                if (!this.droneStatus.lastStatusUpdate) {
                    this.droneStatus.lastStatusUpdate = t1;
                }
                let dtStatusUpdate: number = t1 - this.droneStatus.lastStatusUpdate;
                if (dtStatusUpdate >= this.statusUpdateRate) {
                    let status: IDroneStatusUpdate = {
                        code: EDroneStatusUpdateCode.statusCheck,
                        value: null
                    };
                    this.observable.status.next(status);
                    this.droneStatus.lastStatusUpdate = t1;
                }
                // compute drone speed based on throttle
                this.droneStatus.speed = MathUtils.lowPassFilterRC(this.droneStatus.speed, this.droneStatus.throttle * this.droneConfig.speedSensitivity, 0.4, actualTs / 1000);
                if (this.droneStatus.speed > this.droneConfig.maxSpeed) {
                    this.droneStatus.speed = this.droneConfig.maxSpeed;
                }
                this.setZoomLevelBasedOnSpeed(this.droneStatus.speed);
                // compute battery drain based on throttle
                this.computeBatteryLevel(this.droneStatus.throttle, actualTs);
                // compute drone location based on previous commands
                this.droneStatus.location = this.computeNextPosition(actualTs);
                // process joystick controls
                this.processJoystickControls(actualTs);
                // move map
                this.moveMapDroneMode(this.droneStatus.location).then(() => {
                    this.afterMoveMap();
                    moveMapInProgress = false;
                }).catch((err) => {
                    console.error(err);
                    this.afterMoveMap();
                    moveMapInProgress = false;
                });
            }

            // queue double buffering so that there are lower chances of freeze frames / input bottlenecks
            // simple works better

            // this.q.enqueueWithDataSnapshot((data) => {
            //     return this.moveMapDroneMode(data);
            // }, (_res: IGenericMessageQueueEvent) => {
            //     this.afterMoveMap();
            //     moveMapInProgress = false;
            // }, null, this.droneStatus.location, EQueues.drone, {
            //     size: 2,
            //     delay: this.droneConfig.simulationRate
            // });
        };
        this.subscription.mainLoop = timer1.subscribe(() => {
            if (!this.simulationRunning) {

            } else {
                loopFn();
            }
        }, (err) => {
            console.error(err);
        });
    }

    private afterMoveMap() {
        let loc: IVirtualLocation = {
            coords: this.droneStatus.location,
            speed: this.droneStatus.speed,
            heading: this.droneStatus.heading,
            source: EVirtualLocationSource.drone,
            // updateMarker: true
            updateMarker: false
        };
        this.virtualPositionService.updateVirtualPosition(loc);
        if (this.simulationRunning) {
            // trigger next loop
            // this.runLoop();
        } else {
            if (this.requestLanding) {
                this.landDrone();
            }
        }
    }

    /**
     * disconnect map
     * allow external control e.g. via gestures
     */
    disconnectMapUpdates() {
        ResourceManager.clearTimeout(this.timeout.resume);
        this.simulationDisconnected = true;
        console.log("simulation paused");
    }

    /**
     * connect map
     */
    resumeMapUpdates() {
        this.timeout.resume = setTimeout(() => {
            this.simulationDisconnected = false;
            console.log("simulation resumed");
        }, 100);
    }

    /**
     * check if drone was used just before completing the challenge
     * e.g. user may complete the challenge before the drone used flag is registered after timeout
     */
    onCompleteChallengeCheck() {
        console.log("on complete challenge check")
        if (this.simulationStarted) {
            this.activityStatsTracker.registerDroneUsed();
        }
    }

    /**
     * main entry point
     * @param location 
     */
    startSimulation(location: ILatLng) {
        if (!location) {
            console.error("undefined takeoff location");
            return;
        }
        if (this.simulationRunning) {
            console.warn("simulation already running");
            return;
        }
        this.requestLanding = false;
        console.log("drone simulator start");
        // prepare drone
        this.launchDrone(location);
        this.simulationStarted = true;
        ResourceManager.broadcastBsubObj(this.observable, null);

        let afterInitCheck = () => {
            this.timeout.registerDroneUsed = setTimeout(() => {
                this.activityStatsTracker.registerDroneUsed();
            }, 10000);
        };

        // check battery status
        if (!this.batteryStatusLoaded) {
            this.getBatteryStatus().then((batteryLevel: number) => {
                this.batteryStatusLoaded = true;
                console.log("battery status loaded: ", batteryLevel);
                this.setBatteryLevel(batteryLevel);
                // start simulation loop
                this.simulationRunning = true;
                this.simulationDisconnected = false;
                afterInitCheck();
                this.runLoop();
            }).catch((err: Error) => {
                console.error(err);
                this.uiext.showAlertNoAction(Messages.msg.batteryCheckFailed.after.msg, Messages.msg.batteryCheckFailed.after.sub);
            });
        } else {
            // start simulation loop
            this.simulationRunning = true;
            this.simulationDisconnected = false;
            afterInitCheck();
            this.runLoop();
        }
    }

    /**
     * pause simulation (if started)
     */
    pauseSimulation() {
        if (this.simulationStarted) {
            this.timeout.mainLoop = ResourceManager.clearTimeout(this.timeout.mainLoop);
            this.subscription.mainLoop = ResourceManager.clearSub(this.subscription.mainLoop);
            this.simulationRunning = false;
        }
    }

    /**
     * resume simulation (if started)
     */
    resumeSimulation() {
        if (this.simulationStarted) {
            this.simulationRunning = true;
            this.runLoop();
        }
    }

    getBatteryStatus(): Promise<number> {
        let promise: Promise<number> = new Promise((resolve, reject) => {
            this.userData.getFlagServer(EStatCache.droneBatteryLevel).then((value: number) => {
                if (value == null) {
                    resolve(100);
                } else {
                    resolve(value);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    sendBatteryStatusResolve(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            this.userData.setFlagServer(EStatCache.droneBatteryLevel, Math.floor(this.droneStatus.batteryLevel)).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
        return promise;

    }

    /**
     * main exit point
     */
    stopSimulation(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            if (this.simulationStarted) {
                console.log("drone simulator exit");
                // before resetting the flag
                this.pauseSimulation();
                this.simulationStarted = false;
                this.requestLanding = true;
                // make sure simulation is stopped before unloading the marker
                await SleepUtils.sleep(500);
                await this.sendBatteryStatusResolve();
                await this.landDrone();
                // clear timeouts
                ResourceManager.clearTimeout(this.timeout.registerDroneUsed);
                resolve(true);
            } else {
                resolve(true);
            }
        });
        return promise;
    }
}
