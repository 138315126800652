import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { EStoryLocationDoneFlag } from 'src/app/classes/def/nav-params/story';
import { IAppLocation } from 'src/app/classes/def/places/app-location';

@Component({
  selector: 'location-dots',
  templateUrl: './location-dots.component.html',
  styleUrls: ['./location-dots.component.scss'],
})
export class LocationDotsComponent implements OnInit, OnDestroy {

  @Input()
  data: IAppLocation[];

  @Input()
  dark: boolean;

  @Output()
  select: EventEmitter<any> = new EventEmitter();

  color: string = "light";

  constructor() {

  }

  isLast(index: number) {
    if (this.data) {
      if (index < this.data.length - 1) {
        return false;
      }
      return true;
    }
    return false;
  }

  getClass(item: IAppLocation) {
    // return this.isDone(item) ? 'list active' : '';
    let css = "";
    if (item && item.loc && item.loc.merged) {
      if (item.loc.merged.googleId) {
        css = "opened";
      }
      if (item.dispDone) {
        css = "active";
      }
      if (item.loc.merged.done === EStoryLocationDoneFlag.done) {
        css = "done";
      }
    }
    return css;
  }


  getClassV2(item: IAppLocation) {
    // return this.isDone(item) ? 'list active' : '';
    let css = "";
    if (item && item.loc && item.loc.merged) {
      if (item.loc.merged.googleId) {
        css = "opened";
      }
      if (item.dispDone) {
        css = "active";
      }
      if (item.loc.merged.done === EStoryLocationDoneFlag.done) {
        css = "done";
      }
    }
    return css;
  }

  isDone(item: IAppLocation) {
    if (item && item.loc && item.loc.merged) {
      return item.loc.merged.googleId && ((item.loc.merged.done === EStoryLocationDoneFlag.done) || item.dispDone);
    }
    return false;
  }

  clicked(index: number) {
    this.select.emit([index]);
  }

  ngOnInit() {
    if (this.dark) {
      this.color = "primary";
    }
  }

  ngOnDestroy() {

  }

}
