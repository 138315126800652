import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { ResourceManager } from "../../../classes/general/resource-manager";
import { LocationApiService } from "../../location/location-api";
import { ILeplaceRegMulti, ILeplaceReg } from "../../../classes/def/places/google";
import { LocationMonitorService } from "../../map/location-monitor";
import { IPlaceMarkerContent, IUserLocationData } from "../../../classes/def/map/map-data";
import { ILatLng } from 'src/app/classes/def/map/coords';
import { AppConstants } from "../../../classes/app/constants";
import { PlacesDataService } from "../../data/places";
import { IGenericResponse, IGenericResponseDataWrapper } from "../../../classes/def/requests/general";
import { ILeplaceWrapper, ILeplaceTreasure, ILeplaceTreasureAction, ETreasureMode, ILeplaceTreasureDynamic, ILeplaceTreasureMin } from "../../../classes/def/places/leplace";
import { MarkerUtils } from "../../map/marker-utils";
import { UiExtensionService } from "../../general/ui/ui-extension";
import { GeoObjectsService } from "./geo-objects";
import { ILeplaceObject, ILeplaceObjectContainer, ECRUD, IActionLayers, IActionLayer } from "../../../classes/def/core/objects";
import { MarkerUtilsService } from "../../map/marker-utils-provider";
import { ITreasureScanResponse, ETreasureType, IEventTreasureScanResponse } from "../../../classes/def/items/treasures";
import { EMarkerScope } from "../../../classes/def/map/markers";
import { ItemScannerUtils } from "./item-scanner-utils";
import { GeometryUtils } from "../../utils/geometry-utils";
import { MapManagerService } from "../../map/map-manager";
import { MarkerHandlerService } from "../../map/markers";
import { GeneralCache } from "../../../classes/app/general-cache";
import { StorageOpsService } from "../../general/data/storage-ops";
import { ELocalAppDataKeys } from "../../../classes/def/app/storage-flags";
import { IPlaceExtContainer } from "../../../classes/def/places/container";
import { BufferUtilsService } from "../utils/buffer-utils";
import { IPlatformFlags } from "../../../classes/def/app/platform";
import { EGameContext } from "../../../classes/def/core/game";
import { EMarkerLayers } from "../../../classes/def/map/marker-layers";
import { MapSettings } from "../../../classes/utils/map-settings";
import { IGameConfig } from "../../../classes/app/config";
import { SettingsManagerService } from "../../general/settings-manager";
import { ItemCollectorCoreService } from "./item-collector-core";
import { ItemCollectorUtils, ICheckCollectItem } from "./item-collector-utils";
import { IWorldMapRefreshOptions, EItemScanRequired, IPlaceScanParamsResponse, IPlaceScanParams, EPrivateScannerMode } from 'src/app/classes/def/items/scanner';
import { EGeoObjectsProviderCode } from 'src/app/classes/def/places/geo-objects';
import { EProcessSearchResultsModes } from 'src/app/classes/def/places/search';
import { EventsDataService } from '../../data/events';
import { Messages } from 'src/app/classes/def/app/messages';
import { LocationUtils } from '../../location/location-utils';
import { AnalyticsService } from '../../general/apis/analytics';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IAppSettingsContent } from 'src/app/classes/def/app/settings';
import { MPUtils } from '../mp/mp-utils';
import { ArrayUtils } from '../../utils/array-utils';
import { GenericQueueService } from '../../general/generic-queue';
import { EOS, EQueues } from 'src/app/classes/def/app/app';
import { VirtualPositionService, IVirtualLocation, EVirtualLocationSource } from './virtual-position';
import { StoryDataService } from '../../data/story';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { LinksDataService } from '../../data/links';
import { PromiseUtils } from '../../utils/promise-utils';
import { SleepUtils } from '../../utils/sleep-utils';


interface IItemScannerObservableMultiplex {
    item: BehaviorSubject<any>;
    scan: BehaviorSubject<any>;
    available: BehaviorSubject<any>;
    collect: BehaviorSubject<any>;
    scanCooldown: BehaviorSubject<number>;
    initVirtualPosition: BehaviorSubject<boolean>;
}

/**
 * also cached to local storage
 * don't change the key names if possible
 */
interface IItemScannerSnapshot {
    // rawPlaces: IItemScannerRawPlacesContainer,
    /**
     * this holds the snapshot of the places at the current scan samples
     */
    places: IItemScannerPlacesContainer;
    /**
     * this holds the snapshot of the treasures at the current scan sample
     * it holds both valid and hidden treasures !
     */
    treasures: IItemScannerTreasureContainer;
    /**
     * last ads scan
     */
    ads: IItemScannerAdsContainer;
}

interface IItemScannerGenericContainer {
    scanTimestamp: number;
    // last scan location
    scanLocation: ILatLng;
    filterLocation: ILatLng;
    gpsDebounceCounter: number;
    retryScan: boolean;
    firstScan: boolean;
    initSync: boolean;
    /**
     * scan in progress
     */
    locked: boolean;
    data: any[];
}

interface IItemScannerTreasureContainer extends IItemScannerGenericContainer {
    data: ILeplaceTreasure[];
}

interface IItemScannerPlacesContainer extends IItemScannerGenericContainer {
    data: ILeplaceWrapper[];
}

// interface IItemScannerRawPlacesContainer extends IItemScannerGenericContainer {
//     data: IPlaceExtContainer[]
// }

interface IItemScannerAdsContainer extends IItemScannerGenericContainer {
    data: ILeplaceReg[];
}

interface ITreasureProcessingOptions {
    partialSync: boolean;
    radius: number;
}


@Injectable({
    providedIn: 'root'
})
export class ItemScannerService {
    itemTimer = null;
    currentLocation: ILatLng = null;
    currentSpeed: number = 0;
    gpsDebouncePreset: number = 3;
    virtualMode: boolean = false;

    timeout = {
        notify: null
    };

    unlockScanner: boolean = true;
    enabled: boolean = false;
    enabledExt: boolean = true;

    /**
     * used to sync new markers (new item scan) with the existing constraints
     * so that unlocked markers are not added to a locked item type set
     */
    unlockedLayers: IActionLayers = {};
    unlockedLayersSnapshot: IActionLayers = {};

    editorMode: boolean = false;
    lockedOnly: boolean = false;
    includeNearbyScan: boolean = false;
    expandTreasuresMode: boolean = false;

    obs: IItemScannerObservableMultiplex = {
        item: null,
        scan: null,
        available: null,
        collect: null,
        scanCooldown: null,
        initVirtualPosition: null
    };

    sub = {
        location: null,
        virtualPosition: null,
        locationTimeout: null
    };

    worldMapRefreshOptions: IWorldMapRefreshOptions = {
        hardReset: false,
        markerClustering: false,
        gameContextCode: EGameContext.all,
        privateScannerMode: EPrivateScannerMode.disabled,
        privateScannerItemId: null,
        groupRole: null,
        fixed: false,
        timeBasedScanner: false,
        locationBasedFiltering: true
    };

    cache: IItemScannerSnapshot = {
        // rawPlaces: {
        //     scanTimestamp: 0,
        //     scanLocation: null,
        //     gpsDebounceCounter: 0,
        //     retryScan: false,
        //     firstScan: false,
        //     locked: false,
        //     initSync: false,
        //     data: []
        // },
        places: {
            scanTimestamp: 0,
            scanLocation: null,
            filterLocation: null,
            gpsDebounceCounter: 0,
            retryScan: false,
            firstScan: false,
            locked: false,
            initSync: false,
            data: []
        },
        treasures: {
            scanTimestamp: 0,
            scanLocation: null,
            filterLocation: null,
            gpsDebounceCounter: 0,
            retryScan: false,
            firstScan: false,
            locked: false,
            initSync: false,
            data: []
        },
        ads: {
            scanTimestamp: 0,
            scanLocation: null,
            filterLocation: null,
            gpsDebounceCounter: 0,
            retryScan: false,
            firstScan: false,
            locked: false,
            initSync: false,
            data: []
        },
    };

    zoomLevel: number = null;
    autoCollect: boolean = false;
    platform: IPlatformFlags = {} as IPlatformFlags;
    scannerInit: boolean = true;
    jumpToMpEnabled: boolean = false;
    storyCentral: ILatLng = null;
    currentLocationGmap: IUserLocationData;
    customStoryMap: boolean = false;

    constructor(
        public locationApi: LocationApiService,
        public locationMonitor: LocationMonitorService,
        public placesData: PlacesDataService,
        public uiext: UiExtensionService,
        public geoObjects: GeoObjectsService,
        public markerUtilsProvider: MarkerUtilsService,
        public mapManager: MapManagerService,
        public markerHandler: MarkerHandlerService,
        public storageOps: StorageOpsService,
        public itemCollectorCore: ItemCollectorCoreService,
        public eventData: EventsDataService,
        public analytics: AnalyticsService,
        public q: GenericQueueService,
        public virtualPositionService: VirtualPositionService,
        public storyData: StoryDataService,
        public links: LinksDataService
    ) {
        console.log("item scanner service created");
        let keys = Object.keys(this.obs);
        keys.forEach(key => {
            this.obs[key] = new BehaviorSubject(null);
        });
    }


    setPlatform(platform: IPlatformFlags) {
        this.platform = platform;
    }

    /**
     * enable internal object collect watch
     * @param enabled 
     */
    setAutoCollect(enabled: boolean) {
        console.log("item scanner set auto collect: ", enabled);
        this.autoCollect = enabled;
    }

    setEditorMode(editor: boolean) {
        this.editorMode = editor;

        // if (editor) {
        //     this.clearCache();
        // }
    }

    loadSettingsFlags() {
        let settings: IAppSettingsContent = SettingsManagerService.settings.app.settings;
        this.lockedOnly = settings.worldEditorShowLockedOnly.value;
        this.includeNearbyScan = settings.includeNearbyScan.value;
        this.expandTreasuresMode = settings.expandTreasuresMode.value;
    }

    setExpandTreasuresMode(expandTreasuresMode: boolean) {
        this.expandTreasuresMode = expandTreasuresMode;
    }

    getExpandTreasuresMode() {
        return this.expandTreasuresMode;
    }

    /**
     * lock/unlock actual item scan
     * improve performance because not scanning in the background when there is no need for that
     * also hide world map objects (sync with empty array)
     * @param enable 
     */
    setUnlockScanner(enable: boolean) {
        console.log("set unlocked scanner: ", enable);
        this.unlockScanner = enable;
        if (!enable) {
            this.refreshARObjectsCore([]);
        }
    }

    /**
     * load marker layers from the server
     * set unlocked by default
     * saves the configuration as default
     */
    setShowLayers(layersDict: IActionLayers) {
        if (layersDict) {
            this.setShowLayersCore(layersDict, true);
        } else {
            this.geoObjects.getShowLayersResolve(false).then((layersDict: IActionLayers) => {
                if (layersDict) {
                    this.setShowLayersCore(layersDict, true);
                }
            }).catch((err: Error) => {
                console.error(err);
            });
        }
    }

    /**
     * set layers
     * filter by world map layers only
     * @param layersDict 
     * @param snapshot 
     */
    private setShowLayersCore(layersDict: IActionLayers, snapshot: boolean) {
        this.unlockedLayers = {};
        let keys: string[] = Object.keys(layersDict);
        for (let i = 0; i < keys.length; i++) {
            let layer: IActionLayer = layersDict[keys[i]];
            if (layer.type === ETreasureMode.worldMap) {
                this.unlockedLayers[keys[i]] = Object.assign({}, layer);
            }
        }
        // this.unlockedLayers = Object.assign({}, layersDict);
        console.log("item scanner init world map layers: ", this.unlockedLayers);
        if (snapshot) {
            this.unlockedLayersSnapshot = Object.assign({}, layersDict);
        }
    }

    // private restoreShowLayersCore() {
    //     this.unlockedLayers = Object.assign({}, this.unlockedLayersSnapshot);
    //     console.log("item scanner restore layers: ", this.unlockedLayers);
    // }

    /**
     * check if the layer is unlocked/enabled
     * @param type 
     */
    private checkShowLayer(type: number) {
        if (!this.unlockedLayers) {
            return false;
        }
        let keys: string[] = Object.keys(this.unlockedLayers);
        for (let i = 0; i < keys.length; i++) {
            let layer: IActionLayer = this.unlockedLayers[keys[i]];
            if (layer.code === type) {
                // console.log("layer enabled");
                return layer.enabled;
            }
        }
        return false;
    }

    /**
     * set show all treasure types
     * @param flag 
     */
    private setShowLayersGlobalCore(flag: boolean) {
        let keys: string[] = Object.keys(this.unlockedLayers);
        for (let i = 0; i < keys.length; i++) {
            let key: string = keys[i];
            this.unlockedLayers[key].enabled = flag;
        }
    }

    setWorldMapRefreshOptions(options: IWorldMapRefreshOptions) {
        console.log("set world map refresh options: ", options);

        // handle context change
        if (this.worldMapRefreshOptions.privateScannerMode !== options.privateScannerMode) {
            // require rescan on context change e.g. world map to nonlinear story w/ private scanner
            this.cache.treasures.firstScan = true;
        }

        // update context
        this.worldMapRefreshOptions = options;
    }

    setLocationBasedFiltering(enable: boolean) {
        this.worldMapRefreshOptions.locationBasedFiltering = enable;
    }

    getWorldMapRefreshOptions() {
        return this.worldMapRefreshOptions;
    }

    /**
     * toggles the visibility of all treasure markers
     * e.g. when starting/exiting a challenge on the world map
     */
    setShowLayersGlobal(layer: string, show: boolean) {
        this.setShowLayersGlobalCore(show);
        this.setShowMarkersFromUnlockedLayers();
        if (layer) {
            this.refreshWorldMapMarkerLayerNoAction(layer, this.getAttachedTreasurePlaceMarkers(), this.worldMapRefreshOptions);
            this.mapManager.showHideLayers(layer, show);
        }

        if (!show) {
            this.geoObjects.updateMapFirstBuffer([], EGeoObjectsProviderCode.itemScanner);
            this.geoObjects.refreshAll(EGeoObjectsProviderCode.itemScanner);
        } else {
            this.refreshARObjectsCore(this.cache.treasures.data);
            this.geoObjects.refreshAll(EGeoObjectsProviderCode.itemScanner);
        }
    }

    /**
     * subscribe to item generation around the user
     * for display on map
     */
    getItemGenerateObservable() {
        this.obs.item.next(null);
        return this.obs.item;
    }

    getItemAvailableObservable() {
        this.obs.available.next(null);
        return this.obs.available;
    }

    /**
     * subscribe to item collect around the user
     */
    getItemCollectObservable() {
        this.obs.collect.next(null);
        return this.obs.collect;
    }
    /**
     * subscribe to scan event
     * for progress bar display
     */
    getScanEventObservable() {
        this.obs.scan.next(null);
        return this.obs.scan;
    }

    getScanCooldownObservable() {
        this.obs.scanCooldown.next(null);
        return this.obs.scanCooldown;
    }

    getInitVirtualPositionObservable() {
        this.obs.initVirtualPosition.next(null);
        return this.obs.initVirtualPosition;
    }

    private updateScanDetails(container: IItemScannerGenericContainer) {
        container.scanTimestamp = new Date().getTime();
        container.scanLocation = new ILatLng(this.currentLocation.lat, this.currentLocation.lng);
    }

    /**
     * clear treasures, keep places
     */
    clearTreasureCache() {
        this.clearCacheContainer(this.cache.treasures);
    }

    /**
     * clear all cache
     */
    clearCache() {
        let keys: string[] = Object.keys(this.cache);
        for (let i = 0; i < keys.length; i++) {
            this.clearCacheContainer(this.cache[keys[i]]);
        }
    }

    /**
     * clear cache container
     * @param container 
     */
    private clearCacheContainer(container: IItemScannerGenericContainer) {
        container.data = [];
    }

    /**
     * scan for nearby treasures
     * check scope
     */
    treasureScan() {
        let promise: Promise<boolean>;
        switch (this.worldMapRefreshOptions.privateScannerMode) {
            case EPrivateScannerMode.event:
            case EPrivateScannerMode.story:
                promise = this.treasureScanPrivate();
                break;
            case EPrivateScannerMode.disabled:
            default:
                promise = this.treasureScanPublic();
        }
        return promise;
    }

    jumpToMeetingPlaceWhenReady(central: ILatLng, currentLoc: IUserLocationData) {
        return new Promise<boolean>(async (resolve, reject) => {
            try {
                this.storyCentral = central;
                this.currentLocationGmap = currentLoc;
                if (this.cache.treasures.data.length > 0) {
                    this.jumpToMpEnabled = false;
                    await this.uiext.showLoadingV2Queue("Transferring to meeting place..");
                    await SleepUtils.sleep(1000);
                    await this.uiext.dismissLoadingV2();
                    await this.jumpToMeetingPlaceCore(central, currentLoc);
                } else {
                    await this.uiext.showLoadingV2Queue("Searching for meeting place..");
                    this.jumpToMpEnabled = true;
                }
                resolve(true);
            } catch (err) {
                console.error(err);
                reject(err);
            }
        });
    }

    async jumpToMeetingPlaceCore(central: ILatLng, currentLoc: IUserLocationData) {
        try {
            let found: boolean = false;
            for (let t of this.cache.treasures.data) {
                if (t.type === ETreasureType.arena) {
                    console.log("found meeting place: ", t);
                    let coords: ILatLng = GeometryUtils.getRandomPointInRadius(new ILatLng(t.lat, t.lng), 50, 100);
                    if (currentLoc != null) {
                        currentLoc.location = coords;
                    }
                    this.virtualPositionService.placeUserAtLocation(coords, true);
                    await PromiseUtils.wrapResolve(this.mapManager.moveMapExt(coords), true);
                    found = true;
                    break;
                }
            }
            if (found) {
                await this.uiext.dismissLoadingV2();
            } else {
                await this.uiext.dismissLoadingV2();
                console.log("place user at central: ", central);
                if (currentLoc != null) {
                    currentLoc.location = central;
                }
                this.virtualPositionService.placeUserAtLocation(central, true);
                await PromiseUtils.wrapResolve(this.mapManager.moveMapExt(central), true);
                PromiseUtils.wrapNoAction(this.uiext.showRewardPopupQueue("", "No meeting place found. Landed at central point.", null, false, null), true);
            }
            this.obs.initVirtualPosition.next(true);
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * treasure scanner for global scope
     * the item scan should request the nearby places
     * and place the corresponding items for each place type
     * in a nearby location
     * a place can have multiple items (crates)
     */
    treasureScanPublic() {
        return new Promise<boolean>((resolve, reject) => {
            this.obs.scan.next(true);
            this.setLocked(this.cache.places, true);
            this.setLocked(this.cache.treasures, true);
            console.log("scan radius: ", AppConstants.gameConfig.placeScanRadius);
            let promiseFindPlaces: Promise<IPlaceExtContainer[]>;

            if (!this.editorMode) {
                promiseFindPlaces = this.findNearbyPlaces(this.currentLocation);
            } else {
                promiseFindPlaces = Promise.resolve(null);
            }

            promiseFindPlaces.then((np: IPlaceExtContainer[]) => {
                this.obs.scan.next(false);
                this.obs.scan.next(true);
                // this.cache.rawPlaces.data = np;

                // trim based on distance
                if (np != null) {
                    np = this.trimDistance(this.currentLocation, np);
                }

                this.treasureScanProcessing(np, {
                    partialSync: false,
                    radius: AppConstants.gameConfig.placeScanRadius
                }).then(() => {
                    this.obs.scan.next(false);
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    this.obs.scan.next(false);
                    this.showScannerLoadError();
                    reject(err);
                });
            }).catch((err: Error) => {
                this.handleScanError(err);
                reject(err);
            });
        });
    }

    /**
     * get a limited number of scan places based on distance and max limit
     * @param loc 
     * @param np 
     */
    trimDistance(loc: ILatLng, np: IPlaceExtContainer[]) {
        let npTrim: IPlaceExtContainer[] = [];
        let gc: IGameConfig = AppConstants.gameConfig;
        // console.log("game config: ", gc);
        for (let i = 0; i < np.length; i++) {
            np[i].distanceToUser = GeometryUtils.getDistanceBetweenEarthCoordinates(loc, new ILatLng(np[i].lat, np[i].lng), Number.MAX_VALUE);
        }
        np = np.sort((a, b) => {
            return a.distanceToUser - b.distanceToUser;
        });
        for (let i = 0; i < np.length && i < gc.maxTreasurePlaces; i++) {
            npTrim.push(np[i]);
        }
        console.log("item scanner trim distance: " + npTrim.length + " out of " + np.length + " place markers (max " + gc.maxTreasurePlaces + ")");
        return npTrim;
    }

    handleScanError(err: any) {
        this.cache.treasures.retryScan = true; // next scan sooner
        this.obs.scan.next(false);
        this.setLocked(this.cache.places, false);
        this.setLocked(this.cache.treasures, false);
        console.error(err);
        this.showScannerLoadError();
        this.analytics.dispatchError(err, "treasure-scanner");
    }


    showScannerLoadError() {
        if (this.scannerInit) {
            // only show the first time
            this.uiext.showAlertNoAction(Messages.msg.treasureScannerLoadError.after.msg, Messages.msg.treasureScannerLoadError.after.sub);
            this.scannerInit = false;
        }
    }

    /**
    * treasure scanner for event scope
    */
    treasureScanPrivate() {
        return new Promise<boolean>((resolve, reject) => {
            this.obs.scan.next(true);
            this.setLocked(this.cache.treasures, true);

            let itemId: number = this.worldMapRefreshOptions.privateScannerItemId;
            let groupRole: number = this.worldMapRefreshOptions.groupRole;

            if (!itemId) {
                console.error("treasure scanner event id not provided");
                return;
            }

            let promiseTreasures: Promise<ILeplaceTreasure[]>;
            switch (this.worldMapRefreshOptions.privateScannerMode) {
                case EPrivateScannerMode.event:
                    promiseTreasures = new Promise((resolve, reject) => {
                        this.eventData.treasureScan(itemId, groupRole, this.currentLocation, AppConstants.gameConfig.placeScanRadiusCustomMap).then((res: IEventTreasureScanResponse) => {
                            this.obs.scan.next(false);
                            if (res) {
                                console.log("scan event world map");
                                // console.log(JSON.parse(JSON.stringify(res)));
                                console.log(res);
                                let mks: ILeplaceTreasure[] = res.treasures;
                                resolve(mks);
                            } else {
                                resolve([]);
                            }
                        }).catch((err) => {
                            reject(err);
                        });
                    });
                    break;
                case EPrivateScannerMode.story:
                    promiseTreasures = new Promise((resolve, reject) => {
                        this.storyData.getCustomStoryTreasures(itemId, this.currentLocation, AppConstants.gameConfig.placeScanRadiusCustomMap).then((mks: ILeplaceTreasure[]) => {
                            this.obs.scan.next(false);
                            console.log("scan story world map");
                            resolve(mks);
                        }).catch((err) => {
                            reject(err);
                        });
                    });
                    break;
                default:
                    promiseTreasures = Promise.reject(new Error("undefined private scanner mode"));
                    break;
            }

            promiseTreasures.then((mks: ILeplaceTreasure[]) => {
                this.obs.scan.next(false);
                console.log("scan event world map");

                // format location containers as standard representation
                // moved to server side
                for (let i = 0; i < mks.length; i++) {
                    let mk: ILeplaceTreasure = mks[i];
                    LocationUtils.formatTreasureLocationDB(mk);
                }

                console.log("valid items: ", mks.filter(tm => this.checkValidTreasure(tm)).length + "/" + mks.length);
                let challengeItems: ILeplaceTreasure[] = mks.filter(item => item.type === ETreasureType.challenge);
                console.log("format params ok (challenges): ",
                    challengeItems.filter(item => ItemScannerUtils.checkActivityFormatParams(item) === true).length + "/" + challengeItems.length);
                mks = mks.filter(item => ItemScannerUtils.checkActivityFormatParams(item) === true);

                for (let i = 0; i < mks.length; i++) {
                    this.initCrate(mks[i], null, i);
                }

                this.cache.treasures.data = this.syncItemCache(mks);

                for (let i = 0; i < this.cache.treasures.data.length; i++) {
                    this.cache.treasures.data[i].dynamic.cacheIndex = i;
                }

                this.updateScanDetails(this.cache.treasures);
                this.afterTreasureScan(false);
                this.notifyTreasureScan();
                resolve(true);
            }).catch((err: Error) => {
                this.handleScanError(err);
                reject(err);
            });
        });
    }


    /**
     * scan treasures around a place id
     * @param placeId 
     */
    treasureScanByPlaceId(placeId: number) {
        let promise = new Promise((resolve, reject) => {
            console.log("treasure scan by place id: ", placeId);
            if (placeId == null) {
                reject(new Error("invalid place id"));
                return;
            }
            let lp: ILeplaceWrapper = this.cache.places.data.find(d => d.id === placeId);
            if (lp != null) {
                let place: IPlaceExtContainer = lp.place.place;
                this.treasureScanProcessing([place], {
                    partialSync: true,
                    radius: AppConstants.gameConfig.placeScanRadius
                }).then(() => {
                    this.obs.scan.next(false);
                    resolve(true);
                }).catch((err: Error) => {
                    this.obs.scan.next(false);
                    reject(err);
                });
            } else {
                this.obs.scan.next(false);
                reject(new Error("place not found in local cache"));
            }
        });
        return promise;
    }

    /**
     * show treasure scan report for debug purpose
     */
    showTreasureReport() {
        let items: ILeplaceTreasure[] = this.cache.treasures.data;
        console.log("treasure scanner report: " + items.length);
        let challengesDict: { [name: string]: ILeplaceTreasure[] } = {};

        for (let i = 0; i < items.length; i++) {
            let item: ILeplaceTreasure = items[i];
            if (item.spec) {
                let key: string = item.spec.dispName;
                if (key in challengesDict) {
                    challengesDict[key].push(item);
                } else {
                    challengesDict[key] = [item];
                }
            }
        }

        challengesDict = ArrayUtils.sortDictKeys(challengesDict);

        console.log("group by dispName:");
        console.log(challengesDict);
    }

    /**
     * apply zoom and location based filtering
     * working on treasures registered through item scanner
     */
    applyDensityFiltering(locationBasedFiltering: boolean) {
        if (!SettingsManagerService.settings.app.settings.mapDensityFiltering.value) {
            return;
        }

        if (GeneralCache.os !== EOS.ios) {
            return;
        }

        if (this.worldMapRefreshOptions.fixed) {
            return;
        }

        console.log("apply density filtering, current loc: ", this.currentLocation, ", zoom level: ", this.zoomLevel, ", location based: ", locationBasedFiltering);
        let gc: IGameConfig = AppConstants.gameConfig;
        // set max visible range (radius)
        let radZoomIn: number = gc.smartZoomInRange; // meters
        let radZoomOut: number = gc.smartZoomOutRange; // meters
        // zoom in > zoom out (actual value)
        let zoomInLevel: number = MapSettings.cameraPosition.zoom;
        let zoomOutLevel: number = MapSettings.zoomOutLevel;
        let crtRad: number = radZoomOut + (this.zoomLevel - zoomOutLevel) / (zoomInLevel - zoomOutLevel) * (radZoomIn - radZoomOut);

        if (crtRad < radZoomIn) {
            crtRad = radZoomIn;
        }
        // console.log("zoom level: " + this.zoomLevel + ", current location: " + this.currentLocation + ", max rad: " + crtRad);

        let items: ILeplaceTreasure[] = this.cache.treasures.data;
        console.log("density filtering treasures: " + items.length);

        for (let i = 0; i < items.length; i++) {
            let item: ILeplaceTreasure = items[i];
            if (item.placeMarker) {
                // filter by distance
                let distanceToDestination: number = GeometryUtils.getDistanceBetweenEarthCoordinates(this.currentLocation, item.placeMarker.location, 0);
                // console.log("item " + i + ": " + distanceToDestination);
                if (item.pinnedView === 1) {
                    item.placeMarker.visible = true;
                } else {
                    if (distanceToDestination > crtRad) {
                        if (locationBasedFiltering) {
                            item.placeMarker.visible = false;
                        } else {
                            item.placeMarker.visible = true;
                        }
                    } else {
                        item.placeMarker.visible = true;
                    }
                }
                // filter by density (zoom level)
            } else {
                // console.log("item " + i + ": " + "no place marker");
            }
        }

        interface IAdjListElement {
            from: number;
            to: number[];
        }

        interface IAdjPairElement {
            a: number;
            b: number;
        }

        // density filtering works in contrast to distance filtering
        // distance filtering works by reducing the viewport to the visible viewport
        // the number of objects shown decreases as the zoom increases (zoom in)
        // density filtering reduces the number of objects as the zoom decreases (zoom out)


        let minDistZoomIn: number = gc.smartZoomInDensity; // meters
        let minDistZoomOut: number = gc.smartZoomOutDensity; // meters
        // zoom in > zoom out (actual value)
        let minDistBetweenPoints: number = minDistZoomOut + (this.zoomLevel - zoomOutLevel) / (zoomInLevel - zoomOutLevel) * (minDistZoomIn - minDistZoomOut);

        if (minDistBetweenPoints < minDistZoomIn) {
            minDistBetweenPoints = minDistZoomIn;
        }

        let adjPairs: IAdjPairElement[] = [];

        let removeIndex: number[] = [];

        // get matching pairs (distance below threshold)
        for (let i = 0; i < items.length; i++) {
            for (let j = i + 1; j < items.length; j++) {
                if (items[i].placeMarker && items[j].placeMarker) {
                    let dist: number = GeometryUtils.getDistanceBetweenEarthCoordinates(items[i].placeMarker.location, items[j].placeMarker.location, Number.MAX_VALUE);
                    if (dist < minDistBetweenPoints) {
                        let pair = {
                            a: i,
                            b: j
                        };
                        adjPairs.push(pair);
                    }
                }
            }
        }


        // get number of occurences of each individual point in the found matches/adj list
        let adjList: IAdjListElement[] = [];
        for (let i = 0; i < adjPairs.length; i++) {
            let a = adjPairs[i].a;
            let b = adjPairs[i].b;
            let adjElementExists: boolean = false;
            for (let j = 0; j < adjList.length; j++) {
                if (a === adjList[j].from) {
                    adjElementExists = true;
                    // add b
                    adjList[j].to.push(b);
                    break;
                }

                if (b === adjList[j].from) {
                    adjElementExists = true;
                    // add a
                    adjList[j].to.push(a);
                    break;
                }
            }
            if (!adjElementExists) {
                let adjListElement: IAdjListElement = {
                    from: a,
                    to: [b]
                };
                adjList.push(adjListElement);
            }
        }

        // sort by number of occurences

        adjList.sort((a, b) => {
            if (a.to.length > b.to.length) {
                return 1;
            }
            if (a.to.length < b.to.length) {
                return -1;
            }
            return 0;
        });

        // iterative removal of points
        for (let i = 0; i < adjList.length; i++) {
            // check if all adj point already in remove list
            // then don't remove the point anymore (the adj point will be removed)
            let skip: boolean = true;
            for (let j = 0; j < adjList[i].to.length; j++) {
                if (removeIndex.indexOf(adjList[i].to[j]) === -1) {
                    skip = false;
                    break;
                }
            }

            if (!skip) {
                // remove current point, if e.g. there are still adj points that were not registered as removed
                removeIndex.push(adjList[i].from);
            }
        }

        for (let i = 0; i < removeIndex.length; i++) {
            let item: ILeplaceTreasure = items[removeIndex[i]];
            if (item.placeMarker && (item.pinnedView !== 1)) {
                item.placeMarker.visible = false;
            }
        }
        let showItemsLength: number = items.filter(item => {
            if (item.placeMarker) {
                return item.placeMarker.visible;
            }
            return false;
        }).length;
        console.log("display filtering: " + showItemsLength + "/" + items.length);
        console.log("removed from view: ", items.filter(item => {
            if (item && item.placeMarker && !item.placeMarker.visible) {
                return item.uid;
            }
        }));

        // console.log("min dist: ", minDistBetweenPoints);
        // console.log("adj pairs: ", adjPairs);
        // console.log("adj list: ", adjList);
        // console.log("remove index: ", removeIndex);
    }


    /**
     * set zoom level
     * detect change
     * call filtering method
     * @param zoom 
     */
    setZoomLevel(zoom: number, apply: boolean) {
        if (this.worldMapRefreshOptions.fixed) {
            return;
        }
        if (apply && (zoom !== this.zoomLevel)) {
            console.log("item scanner set zoom level: ", zoom);
            this.zoomLevel = zoom;
            this.refreshCratesWrapperNoAction();
        }
        this.zoomLevel = zoom;
    }

    resetQueue() {
        this.q.resetQueue(EQueues.refreshCrates);
    }

    refreshCratesWrappper() {
        return this.refreshCratesWQ();
        // return this.refreshCrates();
    }

    refreshCratesWrapperNoAction() {
        this.refreshCratesWrappper().then(() => {
            console.log("refresh crates complete");
        }).catch((err) => {
            console.error(err);
        });
    }

    refreshCratesWQ(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let res = () => {
                resolve(true);
            };
            this.q.enqueueWithData((data) => {
                return this.refreshWorldMapMarkerLayerWrapperResolve(EMarkerLayers.CRATES, data, null);
            }, res, null, this.getAttachedTreasurePlaceMarkers(), EQueues.refreshCrates, {
                size: 10,
                delay: 250,
                timeout: 20000
            }, 25000);
        });
        return promise;
    }

    refreshCrates(): Promise<boolean> {
        return this.refreshWorldMapMarkerLayerWrapperResolve(EMarkerLayers.CRATES, this.getAttachedTreasurePlaceMarkers(), null);
    }


    /**
     * init crate
     * @param crate 
     * @param place 
     */
    initCrate(crate: ILeplaceTreasure, place: ILeplaceWrapper, index: number) {
        crate.dynamic = {} as ILeplaceTreasureDynamic;
        if (place) {
            crate.place = place.place;
        }
        crate.dynamic.inRange = false;
        crate.dynamic.index = index; // the index is (should be) only for display
        crate.dynamic.showMarker = this.checkShowLayer(crate.type);
        return crate;
    }

    /**
     * process scan results
     * @param rawPlaces 
     */
    treasureScanProcessing(rawPlaces: IPlaceExtContainer[], options: ITreasureProcessingOptions) {
        let promise = new Promise((resolve, reject) => {

            if (rawPlaces === null) {
                rawPlaces = this.cache.places.data.map(d => d.place.place);
            }

            console.log("process google places: ", rawPlaces);
            this.processTreasurePlaces(this.currentLocation, rawPlaces, options.radius).then((leplaceArray: ILeplaceWrapper[]) => {
                console.log("processed google places: ", leplaceArray);
                if (options.partialSync) {
                    // update only around a place
                    // TODO: not working yet, this requires more work
                    let placeCache: ILeplaceWrapper[] = this.cache.places.data;
                    let placeCacheSlice: ILeplaceWrapper[] = [];

                    placeCache.forEach(e => {
                        let match = rawPlaces.find(p => p.googleId === e.place.place.googleId);
                        if (match) {
                            placeCacheSlice.push(e);
                        }
                    });

                    // let key: string = StringUtils.getPropToStringGenerator()((o: ILeplaceWrapper) => { o.id });
                    let key: string = "id";
                    placeCacheSlice = BufferUtilsService.crudSync(placeCacheSlice, leplaceArray, key);
                    console.log("partial sync: ", placeCacheSlice);
                } else {
                    this.cache.places.data = leplaceArray;
                    this.updateScanDetails(this.cache.places);
                }

                let rawItems: ILeplaceTreasure[] = [];
                let ncTotal: number = 0;
                let ncEmpty: number = 0;

                leplaceArray = this.cache.places.data;

                for (let i = 0; i < leplaceArray.length; i++) {
                    // compute item location nearby the specified location
                    // alter original location within a specified radius
                    let place: ILeplaceWrapper = leplaceArray[i];

                    if (place && place.place && place.place.place) {
                        LocationUtils.checkExistingPhotoUrlInit(place.place.place);
                    }

                    if (place && place.id && place.treasures && place.treasures.length > 0) {
                        for (let j = 0; j < place.treasures.length; j++) {
                            let crate: ILeplaceTreasure = DeepCopy.deepcopy(place.treasures[j]);
                            // copy the place details to each treasure to make it easier later
                            // uses more memory though!

                            if (crate) {
                                crate = this.initCrate(crate, place, j);
                                rawItems.push(crate);
                            } else {
                                // console.log("empty crate in: ", place);
                                ncEmpty += 1;
                            }
                            ncTotal += 1;
                        }
                    }
                }

                console.log("total crates: ", ncTotal);
                console.log("empty crates: ", ncEmpty);

                // console.log("raw item uids: ", rawItems.map(e => e.uid));

                // check already collected item locations on server
                // also register items into user item location table
                this.checkAvailableTreasuresServer(rawItems).then((processedItems: ILeplaceTreasure[]) => {

                    // console.log("processed item uids: ", processedItems.map(e => e.uid));

                    console.log("valid items: ", processedItems.filter(tm => this.checkValidTreasure(tm)).length + "/" + processedItems.length);
                    let challengeItems: ILeplaceTreasure[] = processedItems.filter(item => item.type === ETreasureType.challenge);
                    console.log("format params ok (challenges): ",
                        challengeItems.filter(item => ItemScannerUtils.checkActivityFormatParams(item) === true).length + "/" + challengeItems.length);
                    processedItems = processedItems.filter(item => ItemScannerUtils.checkActivityFormatParams(item) === true);
                    // console.log("raw items photos: ", rawItems.map(r => r.place.place.photos));
                    processedItems = this.assignOriginalAuxPlaceDataTreasure(processedItems, rawItems);
                    // console.log("processed items photos: ", processedItems.map(r => r.place.place.photos));
                    this.cache.treasures.data = this.syncItemCache(processedItems);

                    for (let i = 0; i < this.cache.treasures.data.length; i++) {
                        this.cache.treasures.data[i].dynamic.cacheIndex = i;
                    }

                    this.updateScanDetails(this.cache.treasures);
                    this.afterTreasureScan(true);
                    this.notifyTreasureScan();
                    resolve(true);
                }).catch((err: Error) => {
                    this.cache.treasures.retryScan = true; // next scan sooner
                    this.afterTreasureScan(true);
                    console.error(err);
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    private afterTreasureScan(locationBasedFiltering: boolean) {
        this.setLocked(this.cache.places, false);
        this.setLocked(this.cache.treasures, false);
        this.showTreasureReport();
        this.applyDensityFiltering(locationBasedFiltering);
        this.setShowMarkersFromUnlockedLayers();
    }

    private setLocked(container: IItemScannerGenericContainer, locked: boolean) {
        container.locked = locked;
    }

    private isLocked(container: IItemScannerGenericContainer) {
        return container.locked;
    }

    checkTreasuresLoaded() {
        return this.cache.treasures.data && (this.cache.treasures.data.length > 0);
    }

    /**
     * notify external providers (e.g. show map markers)
     */
    private notifyTreasureScan() {
        console.log("notify items scan");
        this.obs.item.next(this.cache.treasures.data);
        this.refreshARObjectsCore(this.cache.treasures.data);
        if (this.jumpToMpEnabled) {
            this.jumpToMpEnabled = false;
            this.jumpToMeetingPlaceCore(this.storyCentral, this.currentLocationGmap);
        }
    }


    refreshARObjects() {
        this.refreshARObjectsCore(this.cache.treasures.data);
    }


    /**
     * add map first objects that will be dispatched to the AR view
     * only of type world map
     * other types are handled by external providers (e.g. explore)
     * @param treasures 
     */
    refreshARObjectsCore(treasures: ILeplaceTreasure[]) {
        console.log("item scanner refresh AR objects: ", treasures);
        // let crates: ILeplaceTreasure[] = treasures.filter(c => c.type === ETreasureType.treasure);
        let crates: ILeplaceTreasure[] = treasures;
        let objects: ILeplaceObjectContainer[] = [];
        for (let i = 0; i < crates.length; i++) {
            // let object: ILeplaceObject = Object.assign({}, this.treasureARSpecs.crate);
            let crate: ILeplaceTreasure = crates[i];

            // console.log("crate #" + i + ": ", crate);
            let object: ILeplaceObject = ItemScannerUtils.getCrateObjectSpecs(crate);

            if (object) {
                object.id = crate.index;
                // don't confuse the object type with the crate type: object.code = crates[i].type;
                object.uid = crate.uid;
                let objectContainer: ILeplaceObjectContainer = {
                    location: new ILatLng(crate.lat, crate.lng),
                    object,
                    providerCode: EGeoObjectsProviderCode.itemScanner,
                    treasure: crate,
                    dynamic: {
                        distance: null
                    }
                };
                objects.push(objectContainer);
            }
            // console.log("add object: ", object);
            // console.log("add object: " + object.uid);
        }

        if (!this.unlockScanner) {
            objects = [];
        }

        this.geoObjects.updateMapFirstBuffer(objects, EGeoObjectsProviderCode.itemScanner);
        this.geoObjects.refreshAll(EGeoObjectsProviderCode.itemScanner);
    }

    /**
     * sync the item cache
     * keep the items that exist in both the cache and the new coords (existing google_id)
     * only add/remove items
     * return synced items
     * CREATE, REMOVE
     * and also INIT
     * @param newItems 
     */
    syncItemCache(newItems: ILeplaceTreasure[]) {

        let ctd = this.cache.treasures.data;

        if (ctd == null || ctd.length === 0) {
            console.log("sync treasure cache init: ", newItems);
            return newItems;
        }

        let trimmedItemCache: ILeplaceTreasure[] = [];
        // check for removed items
        // i.e. items that were in the local cache and they are no longer in the current results
        // => intersection
        for (let i = 0; i < ctd.length; i++) {
            let exists: boolean = false;
            let newItem: ILeplaceTreasure = null;

            for (let j = 0; j < newItems.length; j++) {
                if (newItems[j].uid === ctd[i].uid) {
                    exists = true;
                    newItem = newItems[j];
                    break;
                }
            }

            if (exists) {
                // testing
                // ctd[i] = JSON.parse(JSON.stringify(newItem));
                if (newItem.type === ETreasureType.treasure) {
                    // treasures do not update location/props (because they would be moving around otherwise)
                    // keep old item from the local cache
                    // just update the required flags

                    console.log("treasure update in place");

                    ctd[i].valid = newItem.valid;
                    ctd[i].lockedForUser = newItem.lockedForUser;
                    try {
                        ctd[i].spec.dispNameAux = newItem.spec.dispNameAux;
                        ctd[i].spec.dispName = newItem.spec.dispName;
                    } catch (e) {
                        console.error(e);
                    }

                    // v2 update except coords
                    // delete newItem.lat;
                    // delete newItem.lng;
                    // delete newItem.placeMarker;
                    // ctd[i] = Object.assign(ctd[i], newItem);


                } else {
                    // ctd[i].valid = newItem.valid;
                    // ctd[i].lat = newItem.lat;
                    // ctd[i].lng = newItem.lng;
                    delete newItem.placeMarker;
                    ctd[i] = Object.assign(ctd[i], newItem);
                }

                trimmedItemCache.push(ctd[i]);
            } else {
                // not added to the new array = REMOVED
                // notify the gmap so that it's not considered for treasure magnet anymore
                this.notifyAvailable(ctd[i], false);
                // keep old treasures
                if (this.expandTreasuresMode) {
                    trimmedItemCache.push(ctd[i]);
                }
            }
        }

        let grownItemCache: ILeplaceTreasure[] = trimmedItemCache.slice();
        // check for added items
        // => reunion
        for (let i = 0; i < newItems.length; i++) {
            let exists: boolean = false;
            for (let j = 0; j < trimmedItemCache.length; j++) {
                if (newItems[i].uid === trimmedItemCache[j].uid) {
                    // already exists in the item cache
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                // does not exist in the item cached
                // i.e. a new item has to be added to the local cache
                grownItemCache.push(newItems[i]);
            }
        }


        console.log("sync treasure cache: ", grownItemCache);
        return grownItemCache;
    }


    /**
     * post processing on the server to check user collected crates for the last 24 hours, etc
     * input: original crates (full spec)
     * request (backend I/O) min specs
     * output: processed crates (full spec)
     * extract/merge data on client
     */
    checkAvailableTreasuresServer(treasures: ILeplaceTreasure[]) {
        let promise = new Promise((resolve, reject) => {
            console.log("process user treasures request");
            // remove un-needed data for reducing data exchange with the server
            let treasuresMin: ILeplaceTreasureMin[] = [];
            for (let i = 0; i < treasures.length; i++) {
                let tm: ILeplaceTreasure = DeepCopy.deepcopy(treasures[i]);
                delete tm.source;
                delete tm.place;
                delete tm.location;
                delete tm.activity;
                treasuresMin.push(tm as ILeplaceTreasureMin);
            }

            this.placesData.processUserTreasuresMin(treasuresMin, this.editorMode, this.lockedOnly).then((response: IGenericResponse) => {
                let availableItemsResult: ITreasureScanResponse = response.data;
                let availableCrates: ILeplaceTreasureMin[] = availableItemsResult.crates;
                let mergedCrates: ILeplaceTreasure[] = [];
                // merge data from server with input data
                for (let i = 0; i < treasures.length; i++) {
                    let input: ILeplaceTreasure = treasures[i];
                    if (input.uid != null) {
                        // start from 0 as the results are not always sorted
                        for (let j = 0; j < availableCrates.length; j++) {
                            let processed: ILeplaceTreasureMin = availableCrates[j];
                            if (input.uid === processed.uid) {
                                let merged: ILeplaceTreasure = Object.assign(input, processed);
                                mergedCrates.push(merged);
                                break;
                            }
                        }
                    } else {
                        console.log("merger > input uid null on crate " + (i + 1) + "/" + treasures.length);
                    }
                }
                resolve(mergedCrates);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }



    /**
     * monitor item collect
     * only valid items i.e. that are available to collect from server
     * only enabled items i.e. items that are enabled (they can be disabled from the map)
     */
    itemCollectMonitor(loc: ILatLng) {

        for (let i = 0; i < this.cache.treasures.data.length; i++) {
            let tm: ILeplaceTreasure = this.cache.treasures.data[i];

            if (tm && this.checkValidTreasure(tm) && tm.dynamic.showMarker) {
                let treasureLocation: ILatLng = new ILatLng(tm.lat, tm.lng);
                // this.acquireItemHandle(item);
                let distanceToDestination = GeometryUtils.getDistanceBetweenEarthCoordinates(loc, treasureLocation, Number.MAX_VALUE);
                let collectDistance: number = AppConstants.gameConfig.collectDistance;
                tm.dynamic.distanceFromUser = distanceToDestination;

                switch (tm.type) {
                    case ETreasureType.story:
                    case ETreasureType.arena:
                        // collectDistance *= 2;
                        collectDistance = AppConstants.gameConfig.itemEnableDistance;
                        break;
                }

                if (distanceToDestination < collectDistance) {
                    if (!tm.dynamic.inRange) {
                        tm.dynamic.inRange = true;
                        this.notifyAvailable(tm, true);
                        if (this.autoCollect) {
                            this.collectItemNoAction(tm, i, false);
                        }
                    }

                } else if (distanceToDestination > collectDistance * 2) {
                    // with histeresis/threshold
                    if (tm.dynamic.inRange) {
                        tm.dynamic.inRange = false;
                        this.notifyAvailable(tm, false);
                    }
                }
            }
        }
    }


    notifyAvailable(item: ILeplaceTreasure, available: boolean) {
        // console.log("notify available: ", item.uid, available);
        if (available) {
            this.notifyAdd(item);
        } else {
            this.notifyRemove(item);
        }
    }

    notifyAdd(item: ILeplaceTreasure) {
        let itemAction: ILeplaceTreasureAction = {
            code: ECRUD.add,
            treasure: item
        };
        this.obs.available.next(itemAction);
    }

    notifyRemove(item: ILeplaceTreasure) {
        let itemAction: ILeplaceTreasureAction = {
            code: ECRUD.remove,
            treasure: item
        };
        this.obs.available.next(itemAction);
    }


    /**
     * collect item
     * lock while collecting
     * @param item 
     */
    collectItemNoAction(item: ILeplaceTreasure, index: number, ontap: boolean) {
        this.collectItem(item, index, true, ontap).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * collect item
     * lock while collecting
     * returns promise
     * @param item 
     */
    collectItem(item: ILeplaceTreasure, index: number, notify: boolean, ontap: boolean) {
        let promise = new Promise((resolve, reject) => {
            if (index === null) {
                index = this.cache.treasures.data.indexOf(item);
            }
            item.notifyCollectPopup = notify;
            item.ontap = ontap;

            let check: ICheckCollectItem = null;

            let enableCollect: boolean = true;

            if (item.lockedForUser) {
                enableCollect = false;
            }

            // check private event
            // e.g. challenges are not client collectible

            switch (this.worldMapRefreshOptions.privateScannerMode) {
                case EPrivateScannerMode.event:
                    check = ItemCollectorUtils.checkCollectEventItemGenericCodeCore(item.type);
                    break;
                case EPrivateScannerMode.disabled:
                default:
                    check = ItemCollectorUtils.checkCollectItemGenericCodeCore(item.type, ontap);
            }

            console.log("check collectItem via itemScanner: ", check);

            let promiseCollectServer: Promise<boolean>;

            if (check.client) {
                // remove collected treasure
                if (check.server && enableCollect) {
                    promiseCollectServer = this.itemCollectorCore.updateCollectedItemServer(item, true);
                } else {
                    promiseCollectServer = Promise.resolve(true);
                }
                promiseCollectServer.then(() => {
                    // collect item and register on the server
                    this.obs.collect.next(item);
                    if (enableCollect) {
                        this.notifyRemove(item);
                        this.cache.treasures.data.splice(index, 1);
                    }
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
                    reject(err);
                });
            } else {
                // only register collected treasure
                if (check.server && enableCollect) {
                    promiseCollectServer = this.itemCollectorCore.updateCollectedItemServer(item, true);
                } else {
                    promiseCollectServer = Promise.resolve(true);
                }
                promiseCollectServer.then(() => {
                    // the item is a story/arena (non collectible item), so just return the handle to the gmap
                    this.obs.collect.next(item);
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            }
        });
        return promise;
    }

    /**
     * register challenge complete
     * remove challenge from map
     * @param item 
     */
    registerChallengeComplete(item: ILeplaceTreasure) {
        let promise = new Promise((resolve, reject) => {
            let index: number = this.cache.treasures.data.indexOf(item);
            console.log("register challenge complete index: " + index);
            item.notifyCollectPopup = false;
            this.itemCollectorCore.updateCollectedItemServer(item, true).then(() => {
                // collect item and register on the server
                this.obs.collect.next(item);
                this.notifyRemove(item);
                this.cache.treasures.data.splice(index, 1);
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }


    // /**
    //  * remove item
    //  * @param item 
    //  */
    // removeItem(item: ILeplaceTreasure, collected: boolean) {
    //     let index: number = this.cache.treasures.data.indexOf(item);
    //     this.itemCollectorCore.updateCollectedItemServer(item, collected).then(() => {
    //         // collect item and register on the server
    //         this.notifyRemove(item);
    //         this.cache.treasures.data.splice(index, 1);
    //     }).catch((err: Error) => {
    //         console.error(err);
    //     });
    // }

    /**
     * collect item from AR view
     * @param object 
     */
    collectFromAR(object: ILeplaceObjectContainer) {
        let item: ILeplaceTreasure = null;
        let index: number = 0;
        console.log("item scanner collect from AR: ", object);

        for (let i = 0; i < this.cache.treasures.data.length; i++) {
            if (this.cache.treasures.data[i].uid === object.object.uid) {
                item = this.cache.treasures.data[i];
                index = i;
            }
        }
        if (item) {
            this.collectItemNoAction(item, index, true);
        }
    }

    getItemCache(): ILeplaceTreasure[] {
        return this.cache.treasures.data;
    }

    /**
     * attach markers to the items
     * @param worldMapMode 
     * @param debug 
     */
    attachPlaceMarkers(viewId: number, worldMapMode: boolean, debug: boolean, layer: string, callback: (item: ILeplaceTreasure) => any) {
        console.log("attach place markers");
        if (!this.cache.treasures.data) {
            return;
        }

        for (let i = 0; i < this.cache.treasures.data.length; i++) {
            let tm: ILeplaceTreasure = this.cache.treasures.data[i];
            if (this.editorMode) {
                tm.owned = true;
            }
            if (this.checkValidTreasure(tm)) {
                tm.viewId = viewId;
                this.markerUtilsProvider.attachPlaceMarkerToTreasure(tm, layer, worldMapMode, debug, callback, !this.platform.WEB);
            }
        }
    }

    /**
     * detach markers to the items
     */
    detachPlaceMarkers() {
        for (let i = 0; i < this.cache.treasures.data.length; i++) {
            let tm: ILeplaceTreasure = this.cache.treasures.data[i];
            if (tm) {
                tm.placeMarker = null;
            }
        }
    }

    /**
     * set enable collect flag (hide/show marker) based on the unlocked layers
     */
    setShowMarkersFromUnlockedLayers() {
        if (!this.cache.treasures.data) {
            return;
        }
        this.cache.treasures.data = this.setShowMarkersFromUnlockedLayersCore(this.cache.treasures.data);
    }

    private setShowMarkersFromUnlockedLayersCore(data: ILeplaceTreasure[]) {
        console.log("set show markers from unlocked layers");
        for (let i = 0; i < data.length; i++) {
            let tm: ILeplaceTreasure = data[i];
            if (this.checkValidTreasure(tm)) {
                // if (tm.id === 77727) {
                //     // console.log("CHECK TREASURE (1)");
                // }
                tm.dynamic.showMarker = this.checkShowLayer(tm.type);
                // console.log(tm.dynamic.showMarker);
            }
        }
        return data;
    }

    private checkValidTreasure(tm: ILeplaceTreasure) {
        // console.log("treasure: " + tm.id + " valid: " + tm.valid);
        return tm.valid || (tm.valid == null);
    }

    /**
     * get attached markers and filter by the enable collect flag
     * get markers from cached treasures
     */
    getAttachedTreasurePlaceMarkers() {
        console.log("get attached treasure markers, unlocked: ", this.unlockScanner);

        if (!this.cache.treasures.data) {
            return null;
        }

        if (!this.unlockScanner) {
            return null;
        }

        let placeMarkers: IPlaceMarkerContent[] = [];
        console.log("get attached treasure markers treasure count: " + this.cache.treasures.data.length);
        placeMarkers = this.cache.treasures.data.filter((tm: ILeplaceTreasure) => {
            let show: boolean = false;
            if (tm != null) {
                show = true;
                if (tm.dynamic && !tm.dynamic.showMarker) {
                    show = false;
                }
            }
            return show;
        }).map(item => item.placeMarker);
        console.log("place marker count: " + placeMarkers.length);
        return placeMarkers;
    }

    /**
     * clear a single place marker
     * @param index 
     */
    clearAttachedTreasurePlaceMarkerUid(uid: string, destroy: boolean) {
        if (!this.cache.treasures.data) {
            return;
        }

        let index: number = this.cache.treasures.data.findIndex(e => e.uid === uid);
        this.clearAttachedTreasurePlaceMarker(index, destroy);
    }

    /**
     * clear a single place marker
     * @param index 
     */
    clearAttachedTreasurePlaceMarker(index: number, destroy: boolean) {
        if (!this.cache.treasures.data) {
            return;
        }
        console.log("clear attached treasure place marker: " + index + ", destroy: " + destroy);
        console.log("input len: " + this.cache.treasures.data.length);
        if (index != null && index !== -1 && index < this.cache.treasures.data.length) {
            this.cache.treasures.data[index].placeMarker = null;
            console.log("removed item: " + this.cache.treasures.data[index].uid);
            if (destroy) {
                this.cache.treasures.data.splice(index, 1);
            }
        }
        console.log("output len: " + this.cache.treasures.data.length);
    }

    /**
     * clear all place markers
     */
    clearAttachedTreasurePlaceMarkers() {
        if (!this.cache.treasures.data) {
            return;
        }
        for (let i = 0; i < this.cache.treasures.data.length; i++) {
            this.clearAttachedTreasurePlaceMarker(i, false);
        }
    }

    /**
     * get nearest place from last item scan
     * @param currentLocation 
     */
    getNearestPlaceFromCache(currentLocation: ILatLng) {
        if (!(this.cache.places && (this.cache.places.data.length > 0))) {
            return null;
        }

        let distMin: number = Number.MAX_VALUE;
        let placeIndex: number = 0;

        for (let i = 0; i < this.cache.places.data.length; i++) {
            let place: ILeplaceWrapper = this.cache.places.data[i];
            let distCrt: number = GeometryUtils.getDistanceBetweenEarthCoordinates(currentLocation, new ILatLng(place.place.place.lat, place.place.place.lng), Number.MAX_VALUE);
            if (distCrt < distMin) {
                distMin = distCrt;
                placeIndex = i;
            }
        }

        let np: ILeplaceWrapper = this.cache.places.data[placeIndex];
        console.log("nearest place: ", np);
        return np;
    }

    /**
     * get all owned custom treasures
     */
    getOwnedTreasuresFromCache(owned: boolean) {
        if (!(this.cache.treasures && (this.cache.treasures.data.length > 0))) {
            return null;
        }
        let customContent: ILeplaceTreasure[] = this.cache.treasures.data.filter(d => (d.owned || !owned) && ((d.type === ETreasureType.customContent) || this.editorMode));
        return customContent;
    }

    getVisibleTreasureIdsFromCache() {
        if (!(this.cache.treasures && (this.cache.treasures.data.length > 0))) {
            return null;
        }
        return this.cache.treasures.data.map(treasure => treasure.id);
    }

    /**
     * refresh atached place marker
     * e.g. the locked flag is updated and the marker has to be grayed out
     * @param index 
     * @param worldMapMode 
     * @param debug 
     */
    refreshAttachedPlaceMarker(index: number) {
        if (!this.cache.treasures.data) {
            return;
        }
        if (index != null && index < this.cache.treasures.data.length) {
            let tm: ILeplaceTreasure = this.cache.treasures.data[index];
            let crateMarkersContent1: IPlaceMarkerContent = tm.placeMarker;
            if (!crateMarkersContent1) {
                return;
            }
            MarkerUtils.setMarkerDisplayOptions(crateMarkersContent1, tm.dynamic.showMarker ? EMarkerScope.item : EMarkerScope.auxItem);
            MarkerUtils.setTreasureMarkerZindex(crateMarkersContent1, tm, !this.platform.WEB);
            crateMarkersContent1.callback = () => {
                let crateOpenInfo = MarkerUtils.getCrateOpenInfo(tm, tm.isWorldMap, tm.isDebug);
                this.uiext.showAlertNoAction(crateOpenInfo.title, crateOpenInfo.sub);
            };
            tm.placeMarker = crateMarkersContent1;
        }
    }

    private stopTimer() {
        this.itemTimer = ResourceManager.clearTriggerableTimeout(this.itemTimer);
    }

    setLocation(location: ILatLng) {
        this.currentLocation = location;
    }

    /**
     * start item scanner
     * get cached treasures from local storage
     */
    start() {
        console.log("item scanner start");
        this.enabled = true;
        this.enabledExt = true;

        let keys = Object.keys(this.obs);
        keys.forEach(key => {
            this.obs[key].next(null);
        });

        let promiseCache: Promise<boolean>;
        if (!this.cache.treasures.data) {
            // e.g. restarted app/refreshed browser
            promiseCache = new Promise((resolve) => {
                this.storageOps.getLocalDataKey(ELocalAppDataKeys.treasureCache).then((data: IItemScannerSnapshot) => {
                    if (data) {
                        this.cache = data;
                        console.log("cache loaded: ", DeepCopy.deepcopy(this.cache));
                    }
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                });
            });
        } else {
            promiseCache = Promise.resolve(true);
        }

        promiseCache.then(() => {
            console.log("after cache loaded");
            this.init();
            this.subscribeToLocationUpdates();
        });
    }

    init() {
        let currentTime: number = new Date().getTime();
        let keys = Object.keys(this.cache);
        keys.forEach(key => {
            let c: IItemScannerGenericContainer = this.cache[key];
            c.gpsDebounceCounter = 0;
            c.retryScan = false;
            c.firstScan = true;
            c.locked = false;
            c.initSync = true;
            c.scanTimestamp = currentTime;
        });
        this.scannerInit = true;
        console.log("item scanner start cache: ", DeepCopy.deepcopy(this.cache));
    }

    /**
     * stop item scanner
     * update local cache with item cache
     */
    stop() {
        console.log("item scanner stop");
        this.unsubscribeFromLocation();
        this.stopTimer();
        this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
        // this.cache.items.data = [];

        // this.cache = this.removeProviderData(this.cache);
        // // dump cache to local storage
        // let flag: IAppFlagsElement = {
        //     flag: ELocalAppDataKeys.treasureCache,
        //     value: this.cache
        // };
        // console.log(flag);
        // this.storageOps.setStorageFlag(flag);

        this.setShowLayersGlobal(null, true);
        this.obs.item.next(null);
    }

    removeProviderData(cache: IItemScannerSnapshot) {
        cache.treasures.data.forEach(treasure => {
            delete treasure.place.place.aux;
        });
        cache.places.data.forEach(place => {
            delete place.place.place.aux;
        });
        // cache.rawPlaces.data.forEach(place => {
        //     delete place.aux;
        // });
        cache.ads.data.forEach(ad => {
            delete ad.place.aux;
        });
        return cache;
    }

    /**
     * pause item scan
     */
    pause() {
        this.enabled = false;
    }

    /**
     * resume item scan
     */
    resume() {
        this.enabled = true;
    }

    pauseExt() {
        this.enabledExt = false;
    }

    resumeExt() {
        this.enabledExt = false;
    }

    /**
     * toggle item scan enabled ext
     */
    toggleExt() {
        this.enabledExt = !this.enabledExt;
    }

    /**
     * get enabled ext status
     * @returns 
     */
    checkEnabledExt() {
        return this.enabledExt;
    }

    setVirtualMode(enabled: boolean) {
        console.log("item scanner set virtual mode: ", enabled);
        this.virtualMode = enabled;
    }

    /**
     * watch location updates
     * check scan conditions and scan for treasures
     */
    private subscribeToLocationUpdates() {
        if (!this.sub.location) {
            let updateTs: number = null;
            this.sub.location = this.virtualPositionService.watchVirtualPosition().subscribe((data: IVirtualLocation) => {
                // console.log(data);
                if (this.virtualPositionService.checkNavContext(data, true)) {
                    // console.log("item scanner location updated: ", data.coords);
                    this.currentLocation = data.coords;
                    if (data.speed != null) {
                        this.currentSpeed = data.speed * 3.6;
                    } else {
                        this.currentSpeed = 0;
                    }
                    // rate limiter (may be called via drone updates)
                    let isDroneUpdate: boolean = data.source === EVirtualLocationSource.drone;
                    let ts: number = new Date().getTime();
                    if (!isDroneUpdate || !updateTs || ((ts - updateTs) > 100)) {
                        updateTs = ts;
                        this.checkScanTreasures(false, data.scanRequired);
                        if (!this.virtualMode) {
                            this.itemCollectMonitor(this.currentLocation);
                        }
                        this.checkFiltering(isDroneUpdate);
                    }
                }
            }, (err: Error) => {
                console.error(err);
            });
        }

        if (!this.sub.virtualPosition) {
            this.sub.virtualPosition = this.virtualPositionService.watchVirtualPosition().subscribe((loc: IVirtualLocation) => {
                if (this.virtualPositionService.checkNavContext(loc, true)) {
                    if (this.virtualMode) {
                        this.itemCollectMonitor(loc.coords);
                    }
                }
            });
        }

        if (!this.sub.locationTimeout) {
            this.sub.locationTimeout = this.locationMonitor.getLocationTimeoutObservable().subscribe((_timeout: number) => {

            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    /**
     * check and scan for treasures
     */
    private checkScanTreasures(strict: boolean, scanRequired: boolean) {
        if (this.isLocked(this.cache.treasures) || this.isLocked(this.cache.places)) {
            console.log("item scanner container locked");
            return;
        }

        let container: IItemScannerTreasureContainer = this.cache.treasures;
        let res: number = this.checkNewScanRequired(container, strict, scanRequired);

        switch (res) {
            case EItemScanRequired.scan:
                PromiseUtils.wrapNoAction(this.treasureScan(), true);
                container.initSync = false;
                break;
            case EItemScanRequired.cacheSync:
                if (container.initSync) {
                    this.timeout.notify = setTimeout(() => {
                        this.notifyTreasureScan();
                    }, 10000);
                    container.initSync = false;
                }
                break;
            default:
                break;
        }
    }


    /**
     * check if scan is unlocked
     * if not required then check if the cache is empty
     * if the cache is empty then should scan
     * @param container 
     * @param strict 
     */
    private checkNewScanRequired(container: IItemScannerGenericContainer, strict: boolean, override: boolean) {
        let scanRequired: number = EItemScanRequired.none;
        let scanCore: boolean = false;
        if (this.checkScanExtra(container) || this.checkScanCore(container, strict) || override) {
            // new scan required (init scan, distance, time since last scan)
            scanRequired = EItemScanRequired.scan;
            container.firstScan = false;
            container.retryScan = false;
            scanCore = true;
        } else {
            // check if the cache is empty
            if (!((container.data != null) && (container.data.length > 0))) {
                // cache is empty, require new scan
                if (this.checkScanTimedelta(container)) {
                    scanRequired = EItemScanRequired.scan;
                    container.firstScan = false;
                    container.retryScan = false;
                }
            } else {
                // cache is initialized, notify only, no delay
                if (container.initSync) {
                    scanRequired = EItemScanRequired.cacheSync;
                }
            }
        }

        if (scanRequired !== EItemScanRequired.none) {
            console.log("new scan required: " + scanRequired + ", core: " + scanCore);
        }

        return scanRequired;
    }

    /**
     * check timedelta (time since last scan)
     * use case:
     * retry rate limiter (when the cache is empty)
     * first scan check (no delay)
     * @param container 
     */
    private checkScanTimedelta(container: IItemScannerGenericContainer) {
        let currentTime: number = new Date().getTime();
        let minTd: number = AppConstants.gameConfig.scanPlacesMinTimeDelta;
        if (container.firstScan) {
            minTd = AppConstants.gameConfig.scanPlacesMinTimeDeltaInit;
        }
        if (container.retryScan) {
            minTd = AppConstants.gameConfig.scanPlacesRetryTimeDelta;
        }
        let timedelta: number = currentTime - container.scanTimestamp;
        // console.log("check scan timedelta: " + timedelta + "/" + minTd);
        let percent: number = Math.floor(timedelta / minTd * 100);
        this.obs.scanCooldown.next(100 - percent);
        if (timedelta >= minTd) {
            container.scanTimestamp = currentTime;
            // console.log("check scan timedelta elapsed");
            return true;
        }
        return false;
    }

    /**
     * check scan extra conditions
     * use case:
     * specs not initialized
     * first scan
     * retry scan
     * @param container 
     */
    private checkScanExtra(container: IItemScannerGenericContainer) {
        let currentTime: number = new Date().getTime();
        // console.log("check scan exc: ", JSON.parse(JSON.stringify(container)));
        // do not run item scanner while app is running in background
        if (GeneralCache.paused || !this.enabled || !this.enabledExt) {
            return false;
        }

        let initRequired: boolean = false;
        // error prevent, init defaults
        if (!container.scanTimestamp) {
            container.scanTimestamp = currentTime;
            initRequired = true;
        }

        if (!container.scanLocation) {
            container.scanLocation = new ILatLng(this.currentLocation.lat, this.currentLocation.lng);
            initRequired = true;
        }

        // first scan (init)
        if (initRequired || container.retryScan) {
            return this.checkScanTimedelta(container);
        }
        return false;
    }

    /**
     * check if the scan conditions are valid
     * enough time has passed since last scan AND
     * the user has moved the specified minimum distance from the last scan location AND
     * the user speed is less than the max allowed speed (e.g. not scanning while in a car) [optional]
     */
    private checkScanCore(container: IItemScannerGenericContainer, strict: boolean) {
        let currentTime: number = new Date().getTime();
        // console.log("check scan core: ", JSON.parse(JSON.stringify(container)));
        // do not run item scanner while app is running in background
        if (GeneralCache.paused || !this.enabled || !this.enabledExt) {
            return false;
        }
        // check for min time delta has passed since last scan
        // this is for data limiting in the case of some unexpected (?) errors
        // if first scan, then check for distance from last scan location
        // so that the cache is bypassed/updated if the user is too far away
        let timedelta: number = currentTime - container.scanTimestamp;
        let minTd: number = AppConstants.gameConfig.scanPlacesMinTimeDelta;
        if (container.firstScan) {
            minTd = AppConstants.gameConfig.scanPlacesMinTimeDeltaInit;
        }

        // console.log("check scan timedelta 2: " + timedelta + "/" + minTd);
        // get time delta
        let percentTime: number = Math.floor(timedelta / minTd * 100);
        if (percentTime > 100) {
            percentTime = 100;
        }

        // get distance delta
        let distanceDelta: number = GeometryUtils.getDistanceBetweenEarthCoordinates(this.currentLocation, container.scanLocation, 0);
        let percentDistance: number = Math.floor(distanceDelta / AppConstants.gameConfig.scanPlacesMinDist * 100);
        if (percentDistance > 100) {
            percentDistance = 100;
        }

        let shouldScan: boolean = false;

        if (this.worldMapRefreshOptions.timeBasedScanner || container.firstScan) {
            // use only time based scanner
            this.obs.scanCooldown.next(100 - percentTime);
            shouldScan = timedelta >= minTd;
            // don't care about speed here
            strict = false;
        } else {
            // combined timedelta and distance percent with equal weights
            this.obs.scanCooldown.next(100 - Math.floor((percentTime / 2 + percentDistance / 2)));
            // include first scan so that the distance is evaluated after a sync
            shouldScan = (timedelta >= minTd) && (distanceDelta >= AppConstants.gameConfig.scanPlacesMinDist);
        }

        if (shouldScan) {
            if ((!strict || (this.currentSpeed < AppConstants.gameConfig.scanPlacesMaxSpeed)) && this.unlockScanner) {
                // console.log("item scanner: scan");
                container.scanTimestamp = currentTime;
                container.gpsDebounceCounter = 0;
                container.scanLocation = new ILatLng(this.currentLocation.lat, this.currentLocation.lng);
                // now run the actual scan
                // this.itemScan();
                console.log("check scan core distance: " + distanceDelta + ", timedelta: " + timedelta);
                return true;
            }
        }
        return false;
    }


    /**
     * check refresh density filtering
     */
    private checkFiltering(droneMode: boolean) {
        let container: IItemScannerTreasureContainer = this.cache.treasures;
        let check: boolean = this.checkFilteringRequired(container, droneMode);
        // console.log("check filtering required: ", check);

        if (!container.filterLocation) {
            container.filterLocation = new ILatLng(this.currentLocation.lat, this.currentLocation.lng);
        }

        if (check) {
            this.refreshCratesWrapperNoAction();
            container.filterLocation = new ILatLng(this.currentLocation.lat, this.currentLocation.lng);
        }
    }

    /**
     * check refresh density filtering required
     * @param container 
     */
    private checkFilteringRequired(container: IItemScannerGenericContainer, droneMode: boolean) {
        let distanceDelta: number = GeometryUtils.getDistanceBetweenEarthCoordinates(this.currentLocation, container.filterLocation, 0);
        let minDist: number = AppConstants.gameConfig.filteringRequiredMinDist;
        if (droneMode) {
            minDist *= 5;
        }
        if (distanceDelta >= minDist) {
            return true;
        }
        return false;
    }


    /**
     * refresh the crate markers
     * remove all and then add them again
     * the marker content data is defined in the item scanner itself
     * apply density filtering
     */
    refreshWorldMapMarkerLayer(layer: string, placeMarkerContentArray: IPlaceMarkerContent[], options: IWorldMapRefreshOptions): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {

            if (!placeMarkerContentArray) {
                placeMarkerContentArray = [];
            }

            console.log("refresh world map marker layer");
            let allCrateMarkers: IPlaceMarkerContent[] = [];

            // clear and re-add markers
            allCrateMarkers = placeMarkerContentArray;

            if (allCrateMarkers == null) {
                allCrateMarkers = [];
            }

            if (!options) {
                options = this.worldMapRefreshOptions;
            }

            // if (!options.init) {
            //     // apply filtering before refresh
            //     this.applyDensityFiltering();
            // }

            // apply filtering before refresh
            this.applyDensityFiltering(options.locationBasedFiltering);

            console.log("refresh world map layer: ", layer);

            // apply filtering based on game context
            switch (options.gameContextCode) {

                // mp only filtering
                case EGameContext.mpOnly:
                    // enable mp only treasures
                    // filter by visible flag
                    allCrateMarkers = allCrateMarkers.filter(cm => {
                        // console.log(cm);
                        if (cm && cm.treasure) {
                            // filter treasures only
                            let show: boolean = MPUtils.checkMpAvailableItemsLock(cm.treasure);

                            if (!cm.visible) {
                                show = false;
                            }

                            if (cm.treasure && !this.checkShowLayer(cm.treasure.type)) {
                                show = false;
                            }

                            return show;
                        } else {
                            return true;
                        }
                    });
                    break;
                default:
                    // pass/enable all treasures
                    // still apply filtering by visible flag

                    allCrateMarkers = allCrateMarkers.filter(cm => {
                        let show: boolean = true;
                        if (cm) {
                            if (!cm.visible) {
                                show = false;
                            }
                            if (cm.treasure && !this.checkShowLayer(cm.treasure.type)) {
                                show = false;
                            }
                        }
                        return show;
                    });
                    break;
            }


            if (!options.hardReset) {
                this.markerHandler.syncMarkerArray(layer, allCrateMarkers).then(() => {
                    if (options.markerClustering) {
                        this.markerHandler.addMarkerClusterer();
                    }
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                let clearMarkerList = [layer];
                for (let e of clearMarkerList) {
                    await this.markerHandler.clearMarkersResolve(e);
                    this.markerHandler.clearMarkerLayer(e);
                }
                this.markerHandler.insertMultipleMarkers(allCrateMarkers, true).then(() => {
                    if (options.markerClustering) {
                        this.markerHandler.addMarkerClusterer();
                    }
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }

    /**
     * refresh world map marker layer 
     * apply filtering
     * @param layer 
     * @param placeMarkerContentArray 
     * @param options 
     */
    refreshWorldMapMarkerLayerNoAction(layer: string, placeMarkerContentArray: IPlaceMarkerContent[], options: IWorldMapRefreshOptions) {
        this.refreshWorldMapMarkerLayerWrapperResolve(layer, placeMarkerContentArray, options).then(() => {

        }).catch(() => {

        });
    }

    /**
     * refresh layer
     * resolve only
     * get stats wrapper
     * @param layer 
     * @param placeMarkerContentArray 
     * @param options 
     */
    refreshWorldMapMarkerLayerWrapperResolve(layer: string, placeMarkerContentArray: IPlaceMarkerContent[], options: IWorldMapRefreshOptions): Promise<boolean> {
        return new Promise((resolve) => {
            if (!placeMarkerContentArray) {
                placeMarkerContentArray = [];
            }
            this.refreshWorldMapMarkerLayer(layer, placeMarkerContentArray, options).then(() => {
                // get stats
                try {
                    let markerLayer: IPlaceMarkerContent[] = this.markerHandler.getMarkerDataMultiLayer(layer);
                    let inputIds: number[] = placeMarkerContentArray.map(m => {
                        if (m && m.treasure) {
                            return m.treasure.id;
                        }
                    }).sort((a, b) => {
                        return a - b;
                    });
                    let outputIds: number[] = markerLayer.map(m => {
                        if (m && m.treasure) {
                            return m.treasure.id;
                        }
                    }).sort((a, b) => {
                        return a - b;
                    });
                    console.log("refresh marker layer: ", layer, ", input: ", inputIds, ", output: ", outputIds);
                    resolve(true);
                } catch (err) {
                    console.log("refresh marker layer: ", layer, ", error resolve");
                    console.error(err);
                    resolve(false);
                }
            }).catch((err: Error) => {
                console.log("refresh marker layer: ", layer, ", error resolve");
                console.error(err);
                resolve(false);
            });
        });
    }

    private unsubscribeFromLocation() {
        if (this.sub.location) {
            this.sub.location = ResourceManager.clearSub(this.sub.location);
        }
        if (this.sub.locationTimeout) {
            this.sub.locationTimeout = ResourceManager.clearSub(this.sub.locationTimeout);
        }
    }


    /**
     * find nearby places for advertising purpose
     */
    findNearbyPlacesSuggestionAds(currentLocation: ILatLng) {
        let promise = new Promise((resolve, reject) => {
            if (!currentLocation) {
                reject(new Error("location not specified"));
                return;
            }

            let res: number = this.checkNewScanRequired(this.cache.ads, false, false);
            switch (res) {
                case EItemScanRequired.scan:
                    this.placesData.getPlaceScanParams().then((params: IPlaceScanParamsResponse) => {
                        let typesObj: IPlaceScanParams[] = params.placeAds;
                        let limitPerType: number[] = typesObj.map(type => type.limit);
                        let types: string[] = typesObj.map(type => type.type);
                        console.log("scan: ", types, limitPerType);
                        this.locationApi.findPlacesNearbySuggestionMultiPass(currentLocation,
                            types, limitPerType, EProcessSearchResultsModes.business).then((result: ILeplaceRegMulti) => {
                                for (let i = 0; i < result.array.length; i++) {
                                    if (result.array[i]) {
                                        LocationUtils.checkExistingPhotoUrlInit(result.array[i].place);
                                    }
                                }
                                this.cache.ads.data = result.array;
                                this.updateScanDetails(this.cache.ads);
                                this.cache.ads.scanLocation = new ILatLng(currentLocation.lat, currentLocation.lng);
                                resolve(result.array);
                            }).catch((err: Error) => {
                                reject(err);
                            });
                    }).catch((err: Error) => {
                        // there is no reject on this provider though
                        reject(err);
                    });
                    break;
                default:
                    resolve(this.cache.ads.data);
                    break;
            }
        });
        return promise;
    }


    /**
     * find nearby places ext provider
     */
    findNearbyPlaces(currentLocation: ILatLng): Promise<IPlaceExtContainer[]> {
        let promise: Promise<IPlaceExtContainer[]> = new Promise((resolve, reject) => {
            if (!currentLocation) {
                reject(new Error("location not specified"));
                return;
            }
            this.placesData.getPlaceScanParams().then((params: IPlaceScanParamsResponse) => {
                let typesObj: IPlaceScanParams[] = params.treasures;
                let limitPerType: number[] = typesObj.map(type => type.limit);
                let types: string[] = typesObj.map(type => type.type);
                console.log("scan: ", types, limitPerType);
                this.locationApi.findPlacesNearbySuggestionMultiPassCore(currentLocation, types, limitPerType).then((placeResults: IPlaceExtContainer[]) => {
                    resolve(placeResults);
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                // there is no reject on this provider though
                reject(err);
            });
        });
        return promise;
    }

    /**
     * process treasures DMS
     */
    processTreasurePlaces(currentLocation: ILatLng, placeResults: IPlaceExtContainer[], radius: number): Promise<ILeplaceWrapper[]> {
        let promise: Promise<ILeplaceWrapper[]> = new Promise((resolve, reject) => {
            this.placesData.processScanTreasuresDMS(currentLocation, [null, null], placeResults, false, radius, this.includeNearbyScan).then((resp: IGenericResponseDataWrapper<ILeplaceWrapper[]>) => {
                let leplacesWT: ILeplaceWrapper[] = resp.data;
                if (!leplacesWT) {
                    reject(new Error("no data"));
                    return;
                }
                leplacesWT = this.assignOriginalAuxPlaceData(leplacesWT, placeResults);
                resolve(leplacesWT);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }



    /**
     * re-assign google specific data
     * aux container
     * @param leplaces 
     * @param places 
     */
    assignOriginalAuxPlaceData(leplaces: ILeplaceWrapper[], places: IPlaceExtContainer[]) {
        // overwrite with original google maps place results
        // because some info might be lost in the exchange such as the method to get photo url
        for (let i = 0; i < leplaces.length; i++) {
            for (let j = 0; j < places.length; j++) {
                if (leplaces[i].place.place.googleId === places[j].googleId) {
                    leplaces[i].place.place.aux = places[j].aux;
                    // console.log("replaced: ", places[i].photos);
                    break;
                }
            }
        }
        return leplaces;
    }

    /**
     * re-assign google specific data
     * aux container
     * @param treasures 
     * @param originalTreasures 
     */
    assignOriginalAuxPlaceDataTreasure(treasures: ILeplaceTreasure[], originalTreasures: ILeplaceTreasure[]) {
        // overwrite with original google maps place results
        // because some info might be lost in the exchange such as the method to get photo url
        for (let i = 0; i < treasures.length; i++) {
            for (let j = 0; j < originalTreasures.length; j++) {
                if (treasures[i].place.place.googleId === originalTreasures[j].place.place.googleId) {
                    treasures[i].place.place.aux = originalTreasures[j].place.place.aux;
                    break;
                }
            }
        }
        return treasures;
    }
}
