<ion-header [ngClass]="getHeaderClass()">
    <leplace-toolbar [menu]="false" [title]="title" leftButton="arrow-back" [leftIcon]="true" (leftClick)="cancel()"
        rightButton="settings" [rightIcon]="true" (rightClick)="options()" [loading]="!loaded"></leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
    <div class="theme-background" [ngClass]="innerClass" fxLayout="column">

        <ion-list no-lines class="padding-s" *ngIf="group && loaded">

            <ion-item class="transparent">
                <ion-label position="stacked" class="text-color info-text-m">Team name</ion-label>
                <ion-input [(ngModel)]="group.name" ngDefaultControl icon="name" type="text"
                    class="text-color info-text-m" [disabled]="false" placeholder="Enter team name">
                </ion-input>
            </ion-item>

            <ion-item class="transparent">
                <ion-row class="full-w center-items">
                    <ion-col size="10" class="no-padding">
                        <span class="text-color info-text-m input-span-replacer">Team ID: {{group.alias}}</span>
                    </ion-col>
                    <ion-col size="2" class="text-right">
                        <ion-button class="action-button-icon-only margin-right-s" [disabled]="!group.alias"
                            (click)="copyToClipboard(group.alias)">
                            <icon-wrapper slot="icon-only" icon="copy"></icon-wrapper>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-item>

            <ion-item class="transparent">
                <ion-row class="full-w center-items">
                    <ion-col size="10" class="no-padding">
                        <div class="border-frame">
                            <edit-code [(prop)]="group.quickJoinCode" placeholder="" [numeric]="false"
                                [dim]="unlockCodeShortLength" [autocaps]="true" [disabled]="false">
                                <inner-content>Quick join code</inner-content>
                            </edit-code>
                        </div>
                    </ion-col>
                    <ion-col size="2" class="text-right">
                        <ion-button class="action-button-icon-only margin-right-s" [disabled]="!group.quickJoinCode"
                            (click)="copyToClipboard(group.quickJoinCode)">
                            <icon-wrapper slot="icon-only" icon="copy"></icon-wrapper>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-item>

            <ion-item class="transparent" *ngIf="existingGroup">
                <ion-row class="full-w center-items">
                    <ion-col>
                        <span class="text-color info-text-m input-span-replacer">Team photo</span>
                    </ion-col>
                </ion-row>
                <!-- <ion-label position="stacked" class="text-color info-text-m">Team photo</ion-label> -->
            </ion-item>

            <ion-item class="transparent" *ngIf="existingGroup">
                <ion-row class="full-w center-items">
                    <ion-col>
                        <responsive-img [withUpload]="true" (upload)="uploadPhotoFromGallery()" [photoUrl]="photoUrl"
                            class="border-radius" customClass="img-place-header" (zoom)="onZoomImage($event)">
                        </responsive-img>
                    </ion-col>
                </ion-row>
            </ion-item>

            <ion-item class="transparent" *ngIf="existingGroup">
                <span class="text-color info-text-m input-span-replacer">Role: {{group.roleName}}</span>
            </ion-item>
            <ion-item class="transparent">
                <ion-label class="text-color-primary info-text-m-imp">Visibility</ion-label>
                <ion-select class="custom-select info-text-m" ngDefaultControl [(ngModel)]="group.type"
                    (ionChange)="onGroupTypeChanged()">
                    <ion-select-option *ngFor="let o of optionsList; let i = index" [value]="o.value">{{o.name}}
                    </ion-select-option>
                </ion-select>
            </ion-item>
        </ion-list>

        <!-- <div tappable class="padding-xs text-center" *ngIf="descriptionHeader">
            <span class="as-text element-selector button-font-size-m text-color-primary">{{descriptionHeader}}</span>
        </div> -->

        <!-- QR code for join -->
        <div *ngIf="created" class="padding-s">
            <qr-code [code]="group.alias" [version]="4"></qr-code>
        </div>

        <div class="padding-s">
            <div [innerHTML]="description" class="info-text-m description-frame text-color-primary text-center"></div>
        </div>

        <div fxFlex></div>

        <div>
            <ion-grid *ngIf="!existingGroup">
                <!-- create group, show before and after create context -->
                <ion-row>
                    <ion-col size="12">
                        <ion-button *ngIf="!created" class="action-button-fill button-color-alternate"
                            [disabled]="!canCreate()" (click)="createGroup()">
                            <span class="button-font-size-s">{{createMessage}}</span>
                            <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIcons.personCircleOutline"
                                [custom]="true"></icon-wrapper>
                        </ion-button>
                        <ion-button *ngIf="created" class="action-button-fill button-color-accent" (click)="ok()">
                            <span class="button-font-size-s">Continue</span>
                            <icon-wrapper slot="start" class="slot-icon-padding" [icon]="appIcons.continue"
                                [custom]="true"></icon-wrapper>
                        </ion-button>
                    </ion-col>
                </ion-row>
            </ion-grid>

            <ion-grid *ngIf="existingGroup">
                <!-- modify existing group, delete, update, start session -->
                <ion-row>
                    <ion-col size="12">
                        <ion-button *ngIf="isGroupUpdated()" [disabled]="!created" ion-button
                            class="action-button-fill button-color-alternate" (click)="updateGroup()">
                            <span class="button-font-size-s">Update</span>
                            <icon-wrapper slot="start" class="slot-icon-padding" icon="create"></icon-wrapper>
                        </ion-button>

                        <ion-button *ngIf="!isGroupUpdated() && !chatOnly" [disabled]="!created || fromTeamLobby"
                            ion-button class="action-button-fill button-color-accent" (click)="ok()">
                            <span class="button-font-size-s">Connect</span>
                            <icon-wrapper slot="start" class="slot-icon-padding" [icon]="appIconsStandard.play"
                                [custom]="false"></icon-wrapper>
                        </ion-button>

                        <ion-button *ngIf="!isGroupUpdated() && chatOnly" [disabled]="!created" ion-button
                            class="action-button-fill button-color-accent" (click)="openChat()">
                            <span class="button-font-size-s">Chat</span>
                            <icon-wrapper slot="start" class="slot-icon-padding" icon="send"></icon-wrapper>
                        </ion-button>
                    </ion-col>
                </ion-row>

            </ion-grid>
        </div>

    </div>
</ion-content>