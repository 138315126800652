<div *ngIf="show" class="flex-card place-ads-card bg-container item-border-radius bg-container-fill bg-filter-2"
  tappable [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data.photoUrl}" (click)="showDescription()">

  <div class="content" fxLayout="column">
     <ion-grid class="full-w grid-center-absolute">
      <ion-row>
        <ion-col size="12">
          <span class="info-text-xs bold uppercase text-color-element">
            {{data.dispName}}
          </span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>