import { Injectable } from "@angular/core";
import { ILatLng } from 'src/app/classes/def/map/coords';
import { ILeplaceObjectContainer, ILeplaceObject, IPrepareCoinSpecs } from "../../../../classes/def/core/objects";
import { GeoObjectsService } from "../geo-objects";
import { ITreasureSpec } from "../../../../classes/def/places/leplace";
import { ETreasureType } from "../../../../classes/def/items/treasures";
import { EMarkerIcons } from "../../../../classes/def/app/icons";
import { ItemScannerUtils } from "../item-scanner-utils";
import { EGeoObjectsProviderCode } from 'src/app/classes/def/places/geo-objects';
import { MarkerHandlerService } from 'src/app/services/map/markers';
import { EMarkerLayers } from 'src/app/classes/def/map/marker-layers';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { NavigationHandlerService, INavUpdateResult, ENavUpdateResult } from 'src/app/services/map/navigation';
import { IExploreFindActivityInit, EExploreCoinAction, IExploreCollectibleParams, EExploreCoinScope } from 'src/app/classes/def/activity/explore';
import { DirectionsService } from 'src/app/services/utils/directions';
import { IWaypointCoordsMulti, IUserLocationData, IPlaceMarkerContent } from 'src/app/classes/def/map/map-data';
import { GeometryUtils } from 'src/app/services/utils/geometry-utils';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { ExploreActivityUtilsService } from './explore-utils';
import { GameUtils } from 'src/app/classes/utils/game-utils';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { MarkerUtils } from 'src/app/services/map/marker-utils';
import { IFindMarkers } from 'src/app/classes/def/activity/find';
import { VirtualPositionService, IVirtualLocation } from '../virtual-position';
import { HeadingService } from 'src/app/services/map/heading';
import { EMarkerTypes } from 'src/app/classes/def/map/markers';
import { PromiseUtils } from "src/app/services/utils/promise-utils";
import { EColorsNames, EColorsRGBA } from "src/app/classes/def/app/theme";

interface IFindActivityStatus {
    collectReady: boolean;
    distanceTravelled: number;
    foundCoins: number;
    lastPosition: ILatLng;
    targetLocation: ILatLng;
    level: number;
    // min timeout between level ups to prevent confusion
    lastLevelUpTimestamp: number;
    // set level up (preset)
    presetLevelUp: boolean;
    // orig. marker title
    title: string;
    // orig. marker color
    color: string;
    // radius decreasing circles
    levelCircleRadius: number[];
}

@Injectable({
    providedIn: 'root'
})
export class FindActivityService {

    targetSpecs: ILeplaceObject = null;
    targetSpecsDefault: ILeplaceObject = null;
    moduleName: string = "FIND ACTIVITY > ";
    targetObjects: ILeplaceObjectContainer[] = [];

    testCoins: boolean = false;
    withCoins: boolean = false;

    observable = {
        targetReachedAR: null
    };

    subscription = {
        navUpdate: null,
        targetReachedAR: null,
        navigateToCollectible: null,
        canClearMap: null
    };

    coinCallback: () => any = null;

    autoCollect: boolean = false;
    currentLocationPos: ILatLng;

    params: IExploreFindActivityInit;
    preparedCoinSpecs: IPrepareCoinSpecs[] = [];

    fstatusInit: IFindActivityStatus = {
        collectReady: false,
        distanceTravelled: 0,
        foundCoins: 0,
        lastPosition: null,
        targetLocation: null,
        lastLevelUpTimestamp: null,
        presetLevelUp: false,
        title: null,
        color: null,
        level: 0,
        levelCircleRadius: [0, 0, 0]
    };

    minRadius: number = 100;

    fstatus: IFindActivityStatus;

    constructor(
        private geoObjects: GeoObjectsService,
        private markerHandler: MarkerHandlerService,
        private nav: NavigationHandlerService,
        private directions: DirectionsService,
        private analytics: AnalyticsService,
        private exploreUtils: ExploreActivityUtilsService,
        private virtualPositionService: VirtualPositionService,
        private headingService: HeadingService
    ) {
        console.log("find activity service created");
        this.observable = ResourceManager.initBsubObj(this.observable);
        this.resetStatus();
        this.initTargetSpecs();
    }

    resetStatus() {
        this.fstatus = Object.assign({}, this.fstatusInit);
    }

    init(currentLocation: IUserLocationData) {
        this.currentLocationPos = currentLocation.location;
    }

    /**
     * start coin collector part of the explore activity
     * watch distance to objects &
     * watch AR collected objects
     */
    startCoinCollector(params: IExploreFindActivityInit) {
        console.log("start coin collector");
        this.exploreUtils.initSession(params.collectDistance);
        console.log("start coin collector, collect distance: " + this.exploreUtils.getExploreStats().collectDistance);

        if (!this.subscription.navigateToCollectible) {
            this.subscription.navigateToCollectible = this.virtualPositionService.watchVirtualPosition().subscribe((data: IVirtualLocation) => {
                if (this.virtualPositionService.checkNavContext(data, true)) {
                    // if (this.virtualPositionService.checkNavContext(data, false)) {
                    this.currentLocationPos = data.coords;
                    let levelUp: boolean = this.checkLevels(GeometryUtils.getDistanceBetweenEarthCoordinates(data.coords, params.target, Number.MAX_VALUE));

                    if (this.withCoins) {
                        this.checkRevealCoins(params.collectDistance, data.coords);
                    }

                    this.checkLevelUp(levelUp);

                    if (!this.fstatus.lastPosition) {
                        this.fstatus.lastPosition = new ILatLng(data.coords.lat, data.coords.lng);
                    }

                    this.fstatus.distanceTravelled += GeometryUtils.getDistanceBetweenEarthCoordinates(this.fstatus.lastPosition, data.coords, 0);
                    this.fstatus.lastPosition = new ILatLng(data.coords.lat, data.coords.lng);

                    // console.log("find challenge status update: ", this.fstatus);
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
        return true;
    }

    checkCollect(params: IExploreFindActivityInit) {
        return this.exploreUtils.checkCollect(params.collectDistance, params.coinCap, null, this.currentLocationPos, false);
    }

    /**
     * check level up
     * w/ timeout
     * @param levelUp 
     */
    checkLevelUp(levelUp: boolean) {
        if (levelUp || this.fstatus.presetLevelUp) {
            this.fstatus.presetLevelUp = true;
            let timeCrt: number = new Date().getTime();

            // check timeout
            if (this.fstatus.lastLevelUpTimestamp == null) {
                this.fstatus.lastLevelUpTimestamp = timeCrt;
            }

            if ((timeCrt - this.fstatus.lastLevelUpTimestamp) >= 10000) {
                // reset condition
                this.fstatus.lastLevelUpTimestamp = timeCrt;
                this.fstatus.presetLevelUp = false;
                // proceed with level up (update circle)

                this.exploreUtils.register({
                    index: null,
                    amount: 1,
                    target: this.getLevelCrtRadius(),
                    action: EExploreCoinAction.update
                });
            }
        }
    }

    /**
     * check coins revealed
     * core function
     * @param params 
     */
    checkRevealCoins(collectDistance: number, currentLocation: ILatLng) {
        if (!currentLocation) {
            return;
        }

        if (!this.preparedCoinSpecs) {
            return;
        }

        let coinCap: number = this.preparedCoinSpecs.length;
        // console.log("check reveal: ", this.preparedCoinSpecs);
        for (let i = 0; i < this.preparedCoinSpecs.length; i++) {
            let pc: IPrepareCoinSpecs = this.preparedCoinSpecs[i];
            let distanceToObject: number = GeometryUtils.getDistanceBetweenEarthCoordinates(currentLocation, pc.position, Number.MAX_VALUE);
            // check collect distance
            if (!pc.minted && (distanceToObject < collectDistance)) {
                // reveal (mint) and set as collected
                console.log("reveal coin: " + this.fstatus.foundCoins);
                PromiseUtils.wrapNoAction(this.exploreUtils.mintCoinCore(pc.position, pc.specIndex, pc.customParamCode, null, false, true, pc, null), true);
                this.exploreUtils.register({
                    index: this.fstatus.foundCoins + 1,
                    amount: 1,
                    target: coinCap,
                    action: EExploreCoinAction.create
                });
                this.exploreUtils.register({
                    index: null,
                    amount: this.fstatus.foundCoins + 1,
                    action: EExploreCoinAction.collect
                });
                this.fstatus.foundCoins += 1;
                pc.minted = true;
            }
        }
    }

    /**
    * main api for movement activity with coins
    */
    generateCoinsFind(params: IExploreFindActivityInit) {
        // this observes how many coins were generated
        // this.coinCollectObservable.next(null);

        let showLine: boolean = this.testCoins;

        let promiseGetWaypoints: Promise<IWaypointCoordsMulti> = new Promise((resolve, reject) => {
            if (params.syncData && params.syncData.waypoints) {
                resolve(params.syncData.waypoints);
            } else {
                this.directions.getDirectionsToTarget(params.initialLocation, params.target, showLine).then((waypointsMultipleDirections: IWaypointCoordsMulti) => {
                    resolve(waypointsMultipleDirections);
                }).catch((err) => {
                    reject(err);
                });
            }
        });

        let coinRadius: number = GeometryUtils.getDistanceBetweenEarthCoordinates(params.initialLocation, params.target, params.circleRadius);
        promiseGetWaypoints.then((waypointsMultipleDirections: IWaypointCoordsMulti) => {
            // generate coins with directions
            let preparedCoinSpecs: IPrepareCoinSpecs[];
            if (params.syncData && params.syncData.coins) {
                preparedCoinSpecs = params.syncData.coins;
            } else {
                preparedCoinSpecs = this.exploreUtils.prepareCoinsOnWaypoints(params.initialLocation, params.coinCap, coinRadius, waypointsMultipleDirections.waypointArray[0].waypoints, EExploreCoinScope.reach);
            }
            this.preparedCoinSpecs = preparedCoinSpecs;

            console.log("prepared coin specs: ", preparedCoinSpecs);

            if (this.testCoins) {
                PromiseUtils.wrapNoAction(this.mintCoinMultiplePrepared(preparedCoinSpecs, null), true);
            }

        }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "gmap-explore");
            // cannot generate coins if there are no directions for move activity
            // for safety reasons
        });
    }


    /**
     * get init marker specs w/ circle
     * @param placeMarkerData 
     * @param circleRadius 
     * @param offsetCenter 
     */
    getFindMarkers(placeMarkerData: IPlaceMarkerContent, circleRadius: number, offsetCenter: ILatLng): IFindMarkers {
        // placeMarkerData.icon = MarkerIcons.location;
        if (!offsetCenter) {
            offsetCenter = GeometryUtils.getRandomPointInRadius(placeMarkerData.location, 0, circleRadius);
        }
        console.log("get find markers around: ", offsetCenter);
        let virtualPlaceMarkerData: IPlaceMarkerContent = DeepCopy.deepcopy(placeMarkerData);
        virtualPlaceMarkerData.location = offsetCenter;
        virtualPlaceMarkerData.icon = EMarkerIcons.findCircle;
        virtualPlaceMarkerData.mode = EMarkerTypes.canvasPlain;
        virtualPlaceMarkerData.radius = 40;
        virtualPlaceMarkerData.label = "search zone";
        virtualPlaceMarkerData.title = virtualPlaceMarkerData.label;
        virtualPlaceMarkerData.layer = EMarkerLayers.CIRCLE_AUX_MARKERS;
        virtualPlaceMarkerData.disableGooglePhotoLoading = true;
        // virtualPlaceMarkerData.mode = EMarkerTypes.plain;

        let placeMarkerContentCircle: IPlaceMarkerContent = MarkerUtils.createCircleMarker(virtualPlaceMarkerData, circleRadius, EMarkerLayers.FIND_CIRCLES);

        placeMarkerData.visible = false;
        if (offsetCenter && (!isNaN(offsetCenter.lat) && !isNaN(offsetCenter.lng))) {
            placeMarkerData.fakeLocation = offsetCenter;
        }
        placeMarkerData.location = placeMarkerData.location;

        this.fstatus.title = placeMarkerContentCircle.title;
        this.fstatus.color = placeMarkerContentCircle.color;

        let fm: IFindMarkers = {
            place: placeMarkerData,
            circleAuxMarker: virtualPlaceMarkerData,
            circleMarker: placeMarkerContentCircle
        };

        return fm;
    }

    getCircleReachedMarker(center: ILatLng, radius: number): IPlaceMarkerContent {
        let placeMarkerContentCircle: IPlaceMarkerContent = MarkerUtils.createCircleMarkerCore(center, "Destination", EColorsNames.blue, radius, EMarkerLayers.MARKER_CIRCLES);
        return placeMarkerContentCircle;
    }

    getEscapeZoneMarker(center: ILatLng, radius: number): IPlaceMarkerContent {
        let placeMarkerContentCircle: IPlaceMarkerContent = MarkerUtils.createCircleMarkerCore(center, "Danger Zone", EColorsNames.red, radius, EMarkerLayers.MARKER_CIRCLES);
        return placeMarkerContentCircle;
    }

    /**
     * update and redraw find circle marker
     * @param center 
     * @param circleRadius 
     * @param title 
     * @param color 
     */
    updateFindCircleMarker(center: ILatLng, circleRadius: number, title: string, color: string): Promise<IPlaceMarkerContent> {
        let promise: Promise<IPlaceMarkerContent> = new Promise(async (resolve, reject) => {
            await this.markerHandler.disposeLayerResolve(EMarkerLayers.FIND_CIRCLES);
            let placeMarkerContentCircle: IPlaceMarkerContent = MarkerUtils.createCircleMarkerCore(center, title, color, circleRadius, EMarkerLayers.FIND_CIRCLES);
            try {
                await this.markerHandler.insertMultipleMarkers([placeMarkerContentCircle], true);
                resolve(placeMarkerContentCircle);
            } catch (e) {
                console.error(e);
                reject(e);
            }
        });
        return promise;
    }

    /**
     * get new random circle with radius
     *  update and redraw find circle marker
     */
    updateFindCircleMarkerWrapper(): Promise<IPlaceMarkerContent> {
        let circleRadius: number = this.getLevelCrtRadius();
        let center: ILatLng = GeometryUtils.getRandomPointInRadius(this.fstatus.targetLocation, 0, circleRadius);
        return this.updateFindCircleMarker(center, circleRadius, this.fstatus.title, this.fstatus.color);
    }

    /**
    * initialize the find provider
    */
    initStage1(params: IExploreFindActivityInit): IExploreFindActivityInit {
        console.log("find init stage 1");
        params = GameUtils.checkExploreGameItems(params, params.activeInventoryItems) as IExploreFindActivityInit;
        this.init(params.currentLocation);
        this.resetStatus();
        this.exploreUtils.register(null);
        this.exploreUtils.initSession(params.collectDistance);
        // this.mapManager.setExploreRadiusCircleSize(params.collectDistance);
        this.params = params;
        return params;
    }


    /**
     * set levels (circles decreasing radius)
     * @param circleRadius 
     */
    setLevels(circleRadius: number) {
        for (let i = 0; i < this.fstatus.levelCircleRadius.length; i++) {
            this.fstatus.levelCircleRadius[i] = this.minRadius;
        }

        this.fstatus.levelCircleRadius[0] = circleRadius;

        if (circleRadius <= this.minRadius) {
            return;
        }

        let level2: number = circleRadius / 2;
        if (level2 <= this.minRadius) {
            return;
        }

        this.fstatus.levelCircleRadius[1] = level2;
        this.fstatus.levelCircleRadius[2] = this.minRadius;
    }

    showLevels() {
        let findLevels: string = "find levels: ";
        for (let i = 0; i < this.fstatus.levelCircleRadius.length; i++) {
            findLevels += this.fstatus.levelCircleRadius[i];
            if (i < this.fstatus.levelCircleRadius.length - 1) {
                findLevels += ", ";
            }
        }
        console.log(findLevels);
    }

    /**
     * check levels (circles decreasing radius)
     * @param distanceToDestination 
     */
    checkLevels(distanceToDestination: number): boolean {
        let levelUp: boolean = false;
        let crtLevelRadius: number = this.fstatus.levelCircleRadius[this.fstatus.level];

        // console.log("check levels distance: ", distanceToDestination);
        if (crtLevelRadius !== 0) {
            if (distanceToDestination < crtLevelRadius) {
                if (this.fstatus.level < this.fstatus.levelCircleRadius.length - 1) {
                    let nextLevelRadius: number = this.fstatus.levelCircleRadius[this.fstatus.level];
                    if (nextLevelRadius !== 0) {
                        // go to next level, create new circle with smaller radius
                        this.fstatus.level += 1;
                        console.log("find level check increase: ", this.fstatus.level);
                        levelUp = true;
                    }
                }
            }
        }
        return levelUp;
    }

    getLevelCrtRadius(): number {
        let crtLevelRadius: number = this.fstatus.levelCircleRadius[this.fstatus.level];
        if (!crtLevelRadius) {
            crtLevelRadius = this.minRadius;
        }
        return crtLevelRadius;
    }

    /**
     * launches the second stage (coin revealer)
     * set reached distance (proximity requirement)
     */
    initStage2(initLocation: ILatLng) {
        console.log("find init stage 2");
        console.log(this.params);
        // set initial location for coin generation
        this.params.initialLocation = initLocation;
        this.generateCoinsFind(this.params);
        this.nav.setReachedDistance(this.params.collectDistance);
        this.resetStatus();
        this.fstatus.targetLocation = this.params.target;
        this.setLevels(this.params.circleRadius);
        this.showLevels();

        if (!this.testCoins) {
            this.startCoinCollector(this.params);
        }
    }

    /**
    * mint/place prepared coins
    * broadcast to MP if enabled
    * @param preparedCoinSpecs 
    * @param waypoints
    */
    async mintCoinMultiplePrepared(preparedCoinSpecs: IPrepareCoinSpecs[], collectibleParams: IExploreCollectibleParams) {
        console.log("mint prepared coins: ", preparedCoinSpecs);
        for (let i = 0; i < preparedCoinSpecs.length; i++) {
            try {
                let pc: IPrepareCoinSpecs = preparedCoinSpecs[i];
                await this.exploreUtils.mintCoinCore(pc.position, pc.specIndex, pc.customParamCode, null, false, false, pc, collectibleParams);
                this.checkGenComplete(i, preparedCoinSpecs.length);
            } catch (err) {
                console.error(err);
            }
        }
        this.exploreUtils.setCollectLock(true);
        await PromiseUtils.wrapResolve(this.exploreUtils.mintPreparedCoins(), true);
        this.exploreUtils.setCollectLock(false);
    }

    /**
     * check coins generated
     * generate notifications
     * @param index 
     * @param coinCap 
     */
    checkGenComplete(index: number, coinCap: number) {
        this.exploreUtils.register({
            index: index + 1,
            amount: 1,
            target: coinCap,
            action: EExploreCoinAction.create
        });
    }

    /**
     * set default coin specs
     */
    initTargetSpecs() {
        this.targetSpecs = ItemScannerUtils.getLocalObjectSpecs(ETreasureType.findTarget);
        this.targetSpecs.marker = EMarkerIcons.coin;
        this.targetSpecsDefault = Object.assign({}, this.targetSpecs);
    }

    /** 
     * set custom target specs
     * the code represents the generic type
     * if a target is derived from this type it has a delegate code and the delegate code is used instead
     * to identify the object functional type
     * the specific code can be found in aux/specificCode if needed
     * @param specs 
     */
    changeTargetSpecs(specs: ITreasureSpec, saveDefaults: boolean) {
        console.log(this.moduleName + "set target specs: ", specs);
        if (specs) {
            this.targetSpecs = ItemScannerUtils.getCrateObjectSpecsFromTreasureSpecs(specs);
            if (saveDefaults) {
                this.targetSpecsDefault = Object.assign({}, this.targetSpecs);
            }
            // this.exploreUtils.changeCoinSpecs([specs], saveDefaults);
        }
    }

    setDefaultTargetSpecs() {
        console.log(this.moduleName + "set default target specs: ", this.targetSpecsDefault);
        if (this.targetSpecsDefault) {
            this.targetSpecs = Object.assign({}, this.targetSpecsDefault);
        }
    }

    setTargetCallback(callback: () => any) {
        console.log("set target callback");
        this.coinCallback = callback;
    }

    setAutoCollectInt(enabled: boolean) {
        console.log("set find auto collect: ", enabled);
        this.autoCollect = enabled;
    }

    checkAutoCollect() {
        return this.autoCollect;
    }


    /**
     * collect from AR
     * only works for a single target at the moment (may be extended, like explore provider)
     */
    targetReachedFromAR() {
        console.log("find collect from AR");
        // allow collect from map
        this.fstatus.collectReady = true;
        // may override the map navigation (collect from AR first)
        this.observable.targetReachedAR.next(true);
    }


    placeDestinationAR(location: ILatLng) {
        let coinSpecs: ILeplaceObject = Object.assign({}, this.targetSpecs);
        coinSpecs.code = ETreasureType.findTarget;
        coinSpecs.genericCode = ETreasureType.findTarget;
        coinSpecs.id = 0;
        coinSpecs.uid = "LOCAL/" + coinSpecs.genericCode + "/" + coinSpecs.id;
        let myObject: ILeplaceObjectContainer = {
            location: location,
            object: coinSpecs,
            treasure: null,
            providerCode: EGeoObjectsProviderCode.find,
            dynamic: {
                distance: null
            },
            minimapLocked: true
        };
        this.targetObjects.push(myObject);
        this.geoObjects.addMapFirstObject(myObject);
    }


    getWatchTargetReached() {
        return this.observable.targetReachedAR;
    }

    start(onTargetReached: () => any) {
        this.subscription.navUpdate = this.nav.getNavUpdateObservable().subscribe((res: INavUpdateResult) => {
            if (res != null) {
                // console.log("nav update: " + res.result);
                switch (res.result) {
                    case ENavUpdateResult.destinationFound:
                    case ENavUpdateResult.destinationFoundAckRequired:
                        // if (this.checkAutoCollect()) {
                        onTargetReached();
                        // }
                        break;
                    default:
                        break;
                }
            }
        }, (err: Error) => {
            console.error(err);
        });

        this.subscription.targetReachedAR = this.observable.targetReachedAR.subscribe((res: boolean) => {
            if (res) {
                onTargetReached();
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    /**
     * reset collected coins so that there is no possibility of collecting infinite amounts of coins
     * when skipping location or scanning places
     * this should be always called on view unload to clear the subs!
     */
    async exitFindActivity(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            this.targetObjects = [];
            this.fstatus.collectReady = false;
            try {
                ResourceManager.broadcastBsubObj(this.observable, null);
                this.subscription = ResourceManager.clearSubObj(this.subscription);
                this.geoObjects.removeMapFirstObjectsByType(ETreasureType.findTarget);
                await this.exploreUtils.checkClearMap();
                await this.exitFindActivityCleanup();
            } catch (e) {
                console.error(e);
            }
            resolve(true);
        });
        return promise;
    }

    async exitFindActivityCleanup(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            await this.markerHandler.clearMarkersResolve(EMarkerLayers.FIND_CIRCLES);
            await this.markerHandler.clearMarkersResolve(EMarkerLayers.CIRCLE_AUX_MARKERS);
            // explore addons
            await this.directions.clearDirectionsToTarget();
            await this.exploreUtils.clearCoins();
            // don't reset status - keep stats for later processing
            // this.resetStatus();
            this.params = null;
            resolve(true);
        });
        return promise;
    }

    /**
    * compute gauge level for find activity
    * @param coords 
    * @param realDestinationOverride 
    * @param distanceToRealDestination 
    */
    computeFindGaugeLevel(coords: ILatLng, realDestinationOverride: ILatLng, distanceToRealDestination: number) {
        let targetHeading: number = GeometryUtils.toDeg(GeometryUtils.computeHeading(coords, realDestinationOverride));
        targetHeading = GeometryUtils.translateTo360(targetHeading);
        let diffHeading: number = this.headingService.compareHeading(targetHeading);
        // add heading factor
        let level: number = distanceToRealDestination + (diffHeading - 0.5) * distanceToRealDestination;
        // console.log("diff heading: ", diffHeading, " level: ", level, " dist: ", distanceToRealDestination);
        return level;
    }
}


