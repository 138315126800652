import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AlertController, PopoverController, Platform } from '@ionic/angular';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { AuthRequestService } from 'src/app/services/general/auth-request/auth-request';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { ERouteDef } from 'src/app/app-utils';
import { ISBConfigList } from 'src/app/components/generic/components/leplace-stickerbomb/leplace-stickerbomb.component';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { Router } from '@angular/router';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
    selector: 'app-account-character',
    templateUrl: './account-character.page.html',
    styleUrls: ['./account-character.page.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AccountCharacterPage implements OnInit, OnDestroy {
    username: string = "";
    loaded: boolean = true;
    theme: string = "theme-light theme-light-bg";

    config: ISBConfigList = {
        target: null,
        backdrops: ['assets/img/photos/explore.jpg', 'assets/img/photos/eat.jpg'],
        stickerSets: [
            {
                name: "regular items",
                stickers: [
                    {
                        name: 'item 1',
                        src: 'assets/img/markers/user_v2.png'
                    },
                    {
                        name: 'item 2',
                        src: 'assets/img/markers/mp_user.png'
                    }
                ]
            }, {
                name: "special items",
                stickers: [
                    {
                        name: 'item 3',
                        src: 'assets/img/markers/m2.png'
                    },
                    {
                        name: 'item 4',
                        src: 'assets/img/markers/m3.png',
                    }
                ]
            }
        ]
    };

    np: INavParams = null;
    vs: IViewSpecs;
    headerClass: string = "";

    constructor(
        public router: Router,
        public authService: AuthRequestService,
        public alertCtrl: AlertController,
        public uiext: UiExtensionService,
        public uiextStandard: UiExtensionStandardService,
        public popoverCtrl: PopoverController,
        public plt: Platform,
        public webView: WebviewUtilsService,
        public nps: NavParamsService,
        public backButton: BackButtonService,
        public analytics: AnalyticsService,
        public settingsProvider: SettingsManagerService
    ) {

    }

    getHeaderClass() {
        return ViewSpecs.getHeaderClass(this.vs, false);
      }

    ngOnInit() {
        this.analytics.trackView('account-character');
        this.nps.checkParamsLoaded().then(() => {
            let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.account, null, this.np);

            console.log("nav params: ", npInfo.params);

            this.username = GeneralCache.resourceCache.user.general.content.name;
            this.settingsProvider.getSettingsLoaded(false).then((res) => {
                if (res) {
                    this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
                }
            }).catch((err: Error) => {
                console.error(err);
            });

            if (npInfo.hasParams) {
                // the view was opened with parameters
                // either for business registration or for payment (bill)
                let np: INavParams = npInfo.params;
                console.log(np.params);
            }
            this.webView.ready().then(() => {
                this.backButton.replace(() => {
                    this.back();
                });
            }).catch((err: Error) => {
                console.error(err);
            });
        }).catch((err: Error) => {
            console.error(err);
        });

    }

    ngOnDestroy() {

    }

    swipeEvent(e) {
        this.backButton.handleSwipeEvent(e);
    }

    presentPopover() {
        let actions: IPopoverActions = {};
        actions = {
            reload: {
                name: "Refresh",
                code: 7,
                enabled: true
            }
        };

        this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
            view: {
                fullScreen: false,
                transparent: AppConstants.transparentMenus,
                large: false,
                addToStack: true,
                frame: false
            },
            params: { actions: actions }
        }).then((result) => {
            switch (result) {
                case 7:

                    break;
                default:
                    break;
            }
        }).catch((err: Error) => {
            console.error(err);
        });
    }


    back() {
        this.router.navigate([ERouteDef.account], { replaceUrl: true }).then(() => {
        }).catch((err: Error) => {
            console.error(err);
        });
    }

}

