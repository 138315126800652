<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title" (rightClick)="ok()"
    [loading]="!loaded" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div class="content">
    <div class="content-height">
      <div fxLayout="column" class="content-height">

        <div class="scrollbar-y scroll-content-modal-1-wide" fxLayout="column">
          <div [innerHTML]="description"
            class="info-text-m description-frame text-color-primary theme-border-bottom-negative padding-s">
          </div>

          <ion-row>
            <ion-col>
              <div class="margin-top-s">
                <div *ngFor="let beacon of dataList; trackBy: trackByFn">
                  <leplace-beacon [data]="beacon" [reload]="reload"></leplace-beacon>
                </div>
              </div>
            </ion-col>
          </ion-row>

          <ion-row *ngIf="hasData">
            <ion-col>
              <div class="generic-chart-container">
                <leplace-xy-chart [data]="chartData" [update]="updateChart" [scaleIndex]="0" (action)="onSelect($event)"
                  class="account-chart"></leplace-xy-chart>
              </div>
            </ion-col>
          </ion-row>

        </div>

        <div fxFlex></div>

        <div class="stick-bottom theme-border-top">
          <ion-grid class="full-w">
            <ion-row>
              <ion-col size="12">
                <ion-button class="action-button-fill"
                  [ngClass]="!connected ? 'button-color-accent' : 'button-color-warn'" (click)="toggleBeaconScanner()">
                  <span class="button-font-size-m">
                    {{connectButtonLabel}}
                  </span>
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-grid>
        </div>
      </div>
    </div>
  </div>
</modal-container>