<video #avStream playsinline [class]="innerClassActive" [ngClass]="{'height-none': !show || loading}">
  <source *ngIf="isVideoContent()" [src]="videoUrl" type="video/mp4" />
</video>

<div *ngIf="loading" class="progress-spinner-center">
  <mat-progress-spinner [diameter]="40" color="primary" mode="indeterminate">
  </mat-progress-spinner>
</div>

<div>
  <ion-grid class="full-w">
    <ion-row>
      <ion-col size="6">
        <!-- <ion-button *ngIf="!started" [disabled]="locked" class="action-button-fill button-color-accent"
          (click)="start(false)">
          <icon-wrapper slot="icon-only" [icon]="appIconsStandard.play" [custom]="false"></icon-wrapper>
        </ion-button> -->
        <ion-button *ngIf="!started" [disabled]="locked" class="action-button-fill button-color-accent"
          (click)="startPlay()">
          <icon-wrapper slot="icon-only" [icon]="appIconsStandard.play" [custom]="false"></icon-wrapper>
        </ion-button>
        <ion-button *ngIf="started" [disabled]="locked || recording" class="action-button-fill button-color-alternate"
          (click)="pause()">
          <icon-wrapper slot="icon-only" [icon]="appIconsStandard.pause" [custom]="false"></icon-wrapper>
        </ion-button>
      </ion-col>
      <!-- <ion-col size="4">
        <ion-button class="action-button-fill button-color-warn" [disabled]="locked" (click)="stop()">
          <icon-wrapper slot="icon-only" [icon]="appIconsStandard.stop" [custom]="false"></icon-wrapper>
        </ion-button>
      </ion-col> -->
      <ion-col size="6">
        <ion-button *ngIf="!recording" class="action-button-fill button-color-warn" [disabled]="locked"
          (click)="record()">
          <icon-wrapper slot="icon-only" [icon]="appIconsStandard.record" [custom]="false"></icon-wrapper>
        </ion-button>
        <ion-button *ngIf="recording" class="action-button-fill button-color-warn" [disabled]="locked" (click)="stop()">
          <icon-wrapper slot="icon-only" [icon]="appIconsStandard.stop" [custom]="false"></icon-wrapper>
        </ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>