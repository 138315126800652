import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { EStoryUnlockTokenType, IStory } from 'src/app/classes/def/core/story';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ParamHandler } from 'src/app/classes/general/params';
import { IAPCoreService } from 'src/app/services/apis/iap-rc';
import { IAPUtils } from 'src/app/services/apis/iap-utils';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { StoryDataService } from 'src/app/services/data/story';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { QRService } from 'src/app/services/general/apis/qr';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';

export interface IPromoStoriesParams {
  stories: IStory[];
  title: string;
}

export interface IStoryCityGroup {
  name: string;
  stories: IStory[];
}

@Component({
  selector: 'promo-stories',
  templateUrl: './promo-stories.component.html',
  styleUrls: ['./promo-stories.component.scss'],
})
export class PromoStoriesViewComponent implements OnInit, OnDestroy {
  appIconsStandard = EAppIconsStandard;
  hasParams: boolean = false;
  title: string = "Nearby storylines";
  stories: IStory[];
  storiesGrouped: IStoryCityGroup[] = [];
  theme: string = "theme-aubergine theme-aubergine-bg";
  vs: IViewSpecs = ViewSpecs.getDefault();

  np: INavParams = null;

  reloadItems: boolean = false;
  loaded: boolean = false;
  hasItems: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService,
    public settingsProvider: SettingsManagerService,
    public tutorials: TutorialsService,
    public iapService: IAPCoreService,
    public analytics: AnalyticsService,
    public qrSecurity: QRService,
    public storyDataProvider: StoryDataService
  ) {

  }


  /**
   * load iap details for current products
   * @param reload 
   */
  loadIapDetails(stories: IStory[], reload: boolean, showLoading: boolean, forceReloadIAP: boolean) {
    showLoading = false; // override, show loading for each item instead
    // check products loading required
    let promiseCheckLoading: Promise<boolean> = new Promise(async (resolve) => {
      let check = IAPUtils.checkItemDataMulti(stories, reload, false);
      if (!showLoading) {
        resolve(false);
      } else {
        if (check.checkItems.length > 0) {
          await this.uiext.showLoadingV2Queue("Loading products..");
          resolve(true);
        } else {
          resolve(false);
        }
      }
    });

    promiseCheckLoading.then((loading: boolean) => {
      this.iapService.retrieveProductList(stories, reload, false, forceReloadIAP).then(async () => {
        console.log("iap products loaded: ", stories);
        this.reloadItems = !this.reloadItems;
        if (loading) {
          await this.uiext.dismissLoadingV2();
        }
      }).catch(async (err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "story-list");
        if (loading) {
          await this.uiext.dismissLoadingV2();
        }
      });
    });
  }

  ngOnInit() {
    this.hasParams = ParamHandler.checkParams(this.np);

    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    if (this.hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IPromoStoriesParams = np.params;
      console.log(params);
      this.title = params.title;
      this.stories = params.stories;
      this.groupStoriesByCity("NEARBY");
      if (np.view) {
        this.vs = np.view;
      }
      this.loadIapDetails(this.stories, false, false, false);
      console.log(np);
    }
  }

  groupStoriesByCity(title: string) {
    for (let story of this.stories) {
      if (!story.regionType) {
        story.regionType = title;
      }
      let group = this.storiesGrouped.find(sg => sg.name === story.regionType);
      if (group != null) {
        group.stories.push(story);
      } else {
        this.storiesGrouped.push({
          name: story.regionType,
          stories: [story]
        });
      }
    }
    this.hasItems = this.stories.length > 0;
    this.loaded = true;
  }

  ngOnDestroy() {

  }


  getDetails() {

  }

  goToStoryDetail(story: IStory) {
    this.dismiss(story);
  }

  dismiss(data: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(data).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  showDescription() {
    this.tutorials.showTutorialNoAction("Nearby stories", ETutorialEntries.placeAdsTutorial, null, null, true);
  }

  nop() {

  }

  /**
  * search by QR token
  */
  searchByToken() {
    this.qrSecurity.scanQRResultString(true, "Find story").then(async (token: string) => {
      console.log(token);
      try {
        let storyData = await this.storyDataProvider.getAllStoriesFromServerSearchByToken(null, token, EStoryUnlockTokenType.leplace, false, false);
        console.log(storyData);
        this.stories = storyData.stories;
        this.groupStoriesByCity("FOUND");
        this.loadIapDetails(this.stories, false, false, false);
      } catch (err) {
        this.analytics.dispatchError(err, "promo-stories");
      }
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "promo-stories");
    });
  }

}
