<div *ngIf="loaded" class="mp-user-card">
  <ion-grid class="full-w" [ngClass]="{'card-selected': self}">
    <ion-row class="center-items center-text">
      <ion-col size="4">
        <img [src]="bgPhoto" alt="Avatar" class="img-avatar-small-crop">
      </ion-col>
      <ion-col size="8">
        <ion-row>
          <ion-col size="12">
            <span class="info-text-m text-color-primary">{{headingDisp}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <!-- <ion-col size="12">
            <span class="info-text-m bold text-color-primary">{{userName}}</span>
          </ion-col> -->
          <ion-col size="12" class="text-div-container-blank">
            <div class="info-text-m bold text-color-primary" [innerHtml]="userName"></div>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>