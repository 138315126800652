

export interface IBrowserType {
    code: number,
    name: string,
    supported: boolean
}

interface IDynamicKeysInterface {
    [key: string]: IBrowserType;
}

export class BrowserUtils {

    static browserType = {
        chrome: {
            code: 1,
            name: "Google Chrome",
            supported: true
        },
        safari: {
            code: 2,
            name: "Safari",
            supported: true
        },
        edge: {
            code: 3,
            name: "Edge",
            supported: false
        },
        firefox: {
            code: 4,
            name: "Firefox",
            supported: false
        },
        ie: {
            code: 5,
            name: "Internet Explorer",
            supported: false
        },
        opera: {
            code: 6,
            name: "Opera",
            supported: false
        },
        unknown: {
            code: -1,
            name: "Unknown",
            supported: false
        }
    };
    // satisfies IDynamicKeysInterface;

    static detectBrowser() {
        let browserType: number = BrowserUtils.browserType.unknown.code;
        if (navigator.userAgent.indexOf("Opera") != -1) {
            browserType = BrowserUtils.browserType.opera.code;
        } else if (navigator.userAgent.indexOf("Edg") != -1) {
            browserType = BrowserUtils.browserType.edge.code;
        } else if (navigator.userAgent.indexOf("Chrome") != -1) {
            browserType = BrowserUtils.browserType.chrome.code;
        } else if (navigator.userAgent.indexOf("Safari") != -1) {
            browserType = BrowserUtils.browserType.safari.code;
        } else if (navigator.userAgent.indexOf("Firefox") != -1) {
            browserType = BrowserUtils.browserType.firefox.code;
        } else if (navigator.userAgent.indexOf("MSIE") != -1) {
            browserType = BrowserUtils.browserType.ie.code;
        } else {
            browserType = BrowserUtils.browserType.unknown.code;
        }
        return browserType;
    }

    static checkIOSBrowser() {
        return navigator.userAgent.match(/(iPod|iPhone|iPad)/) != null;
    }

    static checkPWA() {
        return window.matchMedia('(display-mode: standalone)').matches;
    }

    static checkSupportedBrowser() {
        let browserType: number = BrowserUtils.detectBrowser();
        let supported: boolean = false;
        for (let key of Object.keys(BrowserUtils.browserType)) {
            let bt: IBrowserType = BrowserUtils.browserType[key];
            if (bt.code === browserType) {
                supported = bt.supported;
            }
        }
        return supported;
    }

    static dispSupportedBrowsers() {
        let msg: string = "";
        for (let key of Object.keys(BrowserUtils.browserType)) {
            let bt: IBrowserType = BrowserUtils.browserType[key];
            if (bt.supported) {
                msg += "<p>>" + bt.name + "</p>";
            }
        }
        return msg;
    }

    static dispUnsupportedBrowserMessage() {
        let msg: string = "<p>You are using an unsupported browser and the app might not work as expected. Please reopen the app by pasting the URL in a supported browser:</p>";
        msg += BrowserUtils.dispSupportedBrowsers();
        return msg;
    }
}