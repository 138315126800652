import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { IHudElement } from 'src/app/classes/def/app/hud';

@Component({
  selector: 'leplace-hud-caption',
  templateUrl: './leplace-hud-caption.component.html',
  styleUrls: ['./leplace-hud-caption.component.scss'],
})
export class LeplaceHudCaptionComponent implements OnInit, OnDestroy {
  @Input()
  data: IHudElement;

  constructor() {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }
}
