import { Injectable } from "@angular/core";
import { ILeplaceTreasure, TreasureUtils } from "../../../classes/def/places/leplace";
import { IActivity } from "../../../classes/def/core/activity";
import { IAppLocation, ELocationFlag } from "../../../classes/def/places/app-location";
import { LocationUtilsHelpers } from "../../location/location-utils-helpers";
import { IEventDetails } from "../../../classes/def/events/events";
import { IBackendLocation } from "../../../classes/def/places/backend-location";
import { IPlaceExtContainer } from "../../../classes/def/places/container";
import { EStoryLocationDoneFlag } from "src/app/classes/def/nav-params/story";


@Injectable({
    providedIn: 'root'
})
export class ChallengeEntryService {
    challengeItemCache: ILeplaceTreasure = null;
    appLocationCache: IAppLocation = null;

    constructor(

    ) {
        console.log("challenge entry service created");
    }

    /**
     * get activity associated to the challenge
     * if the requested item is null, then use the last item from cache
     */
    getActivity(item?: ILeplaceTreasure) {
        let item1: ILeplaceTreasure = this.selectItem(item);
        if (!item1) {
            return null;
        }
        return this.getActivityCore(item1);
    }

    getActivityEventChallenge(event: IEventDetails) {
        if (!event) {
            return null;
        }
        if (!event.eventChallenges) {
            return null;
        }
        return event.eventChallenges[0].activity;
    }

    /**
     * get the last used item from cache
     */
    getCurrentChallengeItem() {
        return this.challengeItemCache;
    }

    setCurrentAppLocation(appLocation: IAppLocation) {
        this.appLocationCache = appLocation;
    }

    getCurrentAppLocation() {
        return this.appLocationCache;
    }

    private getActivityCore(item1: ILeplaceTreasure) {
        let activity: IActivity = item1.activity;
        return activity;
    }

    /**
     * get activity generated from the item/challenge specs
     * if the requested item is null, then use the last item from cache
     */
    getAppLocationWithActivity(itemTreasure?: ILeplaceTreasure) {
        let item1: ILeplaceTreasure = this.selectItem(itemTreasure);
        if (!item1) {
            return null;
        }
        let activity: IActivity = this.getActivityCore(item1);
        if (activity) {
            let appLocation: IAppLocation;
            if (item1.place) {
                let placeExt: IPlaceExtContainer = item1.place.place;
                appLocation = {
                    flag: ELocationFlag.SAVED,
                    selected: false,
                    loc: {
                        ext: placeExt,
                        db: null,
                        extLoaded: true,
                        dispPhoto: LocationUtilsHelpers.createNewPhotoContainer(),
                        index: null,
                        merged: {
                            id: item1.locationId,
                            providerCode: placeExt.providerCode,
                            locationId: item1.place.registeredId,
                            googleId: placeExt.googleId,
                            lat: placeExt.lat,
                            lng: placeExt.lng,
                            heading: null,
                            url: null,
                            rating: placeExt.rating,
                            // photoUrl: LocationUtils.getGooglePlacePhoto(placeExt, true).iconRegular,
                            photoUrl: placeExt.photoUrl,
                            name: placeExt.name,
                            // flag: item.place.registered ? ELocationFlag.FIXED : ELocationFlag.RANDOM,
                            flag: ELocationFlag.RANDOM,
                            type: placeExt.type,
                            activity: activity,
                            samePlace: null,
                            done: EStoryLocationDoneFlag.pending,
                            dynamic: 0,
                            registered: item1.place.registeredBusiness,
                            locationType: null
                        }
                    },
                    dispDone: false
                };
            } else {
                if (item1.location && item1.location.location) {
                    appLocation = {
                        flag: ELocationFlag.SAVED,
                        selected: false,
                        loc: {
                            ext: item1.place ? item1.place.place : null,
                            db: null,
                            extLoaded: item1.place != null,
                            dispPhoto: LocationUtilsHelpers.createNewPhotoContainer(),
                            index: null,
                            merged: {
                                id: item1.locationId,
                                providerCode: item1.location.location.merged.providerCode,
                                locationId: item1.locationId,
                                url: null,
                                heading: item1.location.location.merged.heading,
                                googleId: item1.location.location.merged.googleId,
                                photoUrl: item1.location.location.merged.photoUrl,
                                name: item1.location.location.merged.name,
                                flag: ELocationFlag.RANDOM,
                                type: item1.location.location.merged.type,
                                activity: activity,
                                lat: item1.location.location.merged.lat,
                                lng: item1.location.location.merged.lng,
                                rating: item1.location.location.merged.rating,
                                samePlace: null,
                                done: EStoryLocationDoneFlag.pending,
                                dynamic: 0,
                                registered: item1.location.registered,
                                locationType: null
                            }
                        },
                        dispDone: false
                    };
                }
            }

            this.appLocationCache = appLocation;

            return appLocation;
        }

        this.appLocationCache = null;
        return null;
    }


    /**
    * get activity generated from the item/challenge specs
    * if the requested item is null, then use the last item from cache
    */
    getEventAppLocationWithActivity(event1?: IEventDetails) {
        let event: IEventDetails = event1;
        if (!event) {
            return null;
        }
        let activity: IActivity = this.getActivityEventChallenge(event);
        if (activity) {
            let appLocation: IAppLocation;
            if (event.eventLocations) {
                let eventLocation: IBackendLocation = event.eventLocations[0];
                appLocation = {
                    flag: ELocationFlag.SAVED,
                    selected: false,
                    loc: {
                        ext: null,
                        db: null,
                        extLoaded: false,
                        dispPhoto: LocationUtilsHelpers.createNewPhotoContainer(),
                        index: null,
                        merged: {
                            id: eventLocation.id,
                            providerCode: null,
                            url: null,
                            heading: eventLocation.heading,
                            locationId: eventLocation.id,
                            googleId: eventLocation.googleId,
                            lat: eventLocation.lat,
                            lng: eventLocation.lng,
                            rating: eventLocation.rating,
                            photoUrl: eventLocation.photoUrl,
                            name: eventLocation.name,
                            flag: ELocationFlag.RANDOM,
                            type: eventLocation.type,
                            activity: activity,
                            samePlace: null,
                            done: EStoryLocationDoneFlag.pending,
                            dynamic: 0,
                            registered: eventLocation.registered,
                            locationType: null
                        }
                    },
                    dispDone: false
                };
            }

            this.appLocationCache = appLocation;

            return appLocation;
        }

        this.appLocationCache = null;

        return null;
    }


    private selectItem(item?: ILeplaceTreasure) {
        if (!item) {
            item = this.challengeItemCache;
        } else {
            this.challengeItemCache = item;
        }

        return item;
    }

    getChallengeName(item?: ILeplaceTreasure) {
        if (!item) {
            return "Challenge";
        }

        let item1: ILeplaceTreasure = this.selectItem(item);
        let activity: IActivity = this.getActivityCore(item1);
        let name: string = "";

        if (activity) {
            name = TreasureUtils.getChallengeName(item, true);
        } else {
            if (item.spec) {
                name = "Challenge (" + activity.level + ")";
            }
        }

        return name;
    }


    getEventChallengeName(event: IEventDetails) {
        let item1: IEventDetails = event;
        let activity: IActivity = this.getActivityEventChallenge(item1);
        let name: string = "";

        if (activity) {
            name = activity.name + " (" + activity.level + ")";
        }
        return name;
    }
}
