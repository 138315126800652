
import { Injectable } from "@angular/core";
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
// declare var FirebaseCrashlytics;

@Injectable({
    providedIn: 'root'
})
export class CrashlyticsService {
    initialized: boolean = false;

    constructor(

    ) {

    }

    init() {
        let promise = new Promise((resolve) => {
            console.log("crashlytics > init");
            this.initialized = true;
            resolve(true);
        });
        return promise;
    }

    crash() {
        if (!this.initialized) {
            return;
        }
        console.log("crashlytics > crash");
        FirebaseCrashlytics.crash({ message: 'Test' }).then(() => {
            console.log("simulated crash");
        }).catch((err: Error) => {
            console.error(err);
        });
    };

    setEnabled() {
        return new Promise((resolve, reject) => {
            if (!this.initialized) {
                resolve(false);
            }
            console.log("crashlytics > enable");
            FirebaseCrashlytics.setEnabled({
                enabled: true,
            }).then(() => {
                console.log('enabled crashlytics');
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
    };

    didCrashOnPreviousExecution() {
        return new Promise(async (resolve, reject) => {
            try {
                const { crashed } = await FirebaseCrashlytics.didCrashOnPreviousExecution();
                console.log("crashlytics > crashed on previous execution: " + crashed);
                resolve(crashed);
            } catch (err) {
                reject(err);
            }
        });
    };

    async sendUnsentReports() {
        return new Promise(async (resolve, reject) => {
            try {
                await FirebaseCrashlytics.sendUnsentReports();
                console.log("crashlytics > send unsent reports");
                resolve(true);
            } catch (err) {
                reject(err);
            }
        });
    };

    async deleteUnsentReports() {
        await FirebaseCrashlytics.deleteUnsentReports();
    };
}
