import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { IButtonOptions, EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { ParamHandler } from 'src/app/classes/general/params';
import { IDescriptionFrameParams } from 'src/app/modals/generic/modals/description-frame/description-frame.component';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';

@Component({
  selector: 'modal-story-prologue',
  templateUrl: './story-prologue.component.html',
  styleUrls: ['./story-prologue.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoryPrologueViewComponent implements OnInit, OnDestroy {
  description: string = "";
  title: string = "";
  selectAll: boolean = false;
  loaderCode: number = null;
  loading: boolean = false;
  photoUrl: string = null;
  vs: IViewSpecs = ViewSpecs.getDefault();
  buttonCount: number = 0;
  buttons: IButtonOptions[] = [];
  appIconsStandard = EAppIconsStandard;
  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController,
    public resources: ResourcesCoreDataService,
    public analytics: AnalyticsService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IDescriptionFrameParams = np.params;

      if (np.view) {
        this.vs = np.view;
      }

      if (params.buttons && (params.buttons.length > 0)) {
        this.buttonCount = params.buttons.length;
        if (this.buttonCount > 2) {
          this.buttonCount = 2;
        }
        this.buttons = params.buttons;
      }

      console.log(np);

      if (params.description) {
        this.description = params.description;
      }
      this.loaderCode = params.loaderCode;
      // this.descriptionLines = this.formatText(this.description);
      this.title = params.title;
      this.photoUrl = params.photoUrl;
    }

    if (this.loaderCode != null) {
      this.load(this.loaderCode);
    }
    // console.log(this.wrapperClass);
    // console.log(this.innerClass);
  }

  ngOnDestroy() {

  }

  /**
   * load remote resource (description)
   * @param code 
   */
  load(code: number) {
    this.loading = true;
    this.resources.getInfoText(code).then((text: string) => {
      this.loading = false;
      this.description = text;
    }).catch((err: Error) => {
      this.loading = false;
      this.description = "Description not found";
      this.analytics.dispatchError(err, "description-frame");
    });
  }


  dismiss(value: number) {
    if (value == null) {
      value = EAlertButtonCodes.cancel;
    }
    setTimeout(() => {
      this.modalCtrl.dismiss(value).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  cancel() {
    this.dismiss(EAlertButtonCodes.cancel);
  }

  onButtonClick(code: number) {
    this.dismiss(code);
  }
}
