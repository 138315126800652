
export interface ISampleBuffer {
    array: ISampleBufferElement[];
    size: number;
}

export interface IScopeBuffer extends ISampleBuffer {
    fillIndex: number;
}

export interface ICircularBuffer extends ISampleBuffer {
    fillIndex: number;
    circularIndex: number;
    computeIndex: number;
}

export interface ISampleBufferElement {
    value: number;
    timestamp: number;
}

export interface ISampleBufferContainer<T> {
    [key: string]: T;
}

export enum EBufferContext {
    location = "location",
    sound = "sound",
    dance = "dance",
    soundBpm = "sound_bpm"
}