

import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ILeplaceObjectContainer, ILeplaceObjectGenerator, ECRUD, ILeplaceObject, IActionLayers } from '../../../classes/def/core/objects';
import { SettingsManagerService } from '../../general/settings-manager';
import { IPlatformFlags } from '../../../classes/def/app/platform';

import { ETreasureType } from '../../../classes/def/items/treasures';
import { IPopoverAction } from '../../../classes/def/app/modal-interaction';
import { ResourcesCoreDataService } from '../../data/resources-core';
import { ITreasureSpec, ETreasureMode, ILeplaceTreasure } from '../../../classes/def/places/leplace';
import { GameUtils } from '../../../classes/utils/game-utils';
import { ItemScannerUtils } from './item-scanner-utils';
import { GeometryUtils } from '../../utils/geometry-utils';
import { LocationMonitorService } from '../../map/location-monitor';
import { AppConstants } from '../../../classes/app/constants';
import { Platform } from '@ionic/angular';
import { EGeoObjectsProviderCode } from 'src/app/classes/def/places/geo-objects';
import { UiExtensionService } from '../../general/ui/ui-extension';
import { Messages } from 'src/app/classes/def/app/messages';
import { VirtualPositionService, IVirtualLocation } from './virtual-position';
import { ILatLng } from 'src/app/classes/def/map/coords';


@Injectable({
    providedIn: 'root'
})
export class GeoObjectsService {
    geometries = [];

    /**
     * the object buffer that contains all map first objects
     */
    mapFirstObjectBuffer: ILeplaceObjectContainer[] = [];
    /**
     * the object buffer that contains all global objects
     */
    globalObjectBuffer: ILeplaceObjectContainer[] = [];

    /**
     * object buffer containing the global (provider-AR/map) objects within FOV
     */
    globalObjects: ILeplaceObjectContainer[] = [];
    /**
     * object buffer containing the map first objects (provider-AR, provider-map) within FOV
     */
    mapFirstObjects: ILeplaceObjectContainer[] = [];

    globalObjectsObservable: BehaviorSubject<ILeplaceObjectGenerator>;
    mapFirstObjectsObservable: BehaviorSubject<ILeplaceObjectGenerator>;

    platformFlags: IPlatformFlags = {} as IPlatformFlags;

    testObjectId: number = 0;

    layers: IActionLayers = {
        crates: {
            name: "crates",
            code: ETreasureType.treasure,
            enabled: true,
            type: ETreasureMode.worldMap
        },
        stories: {
            name: "stories",
            code: ETreasureType.story,
            enabled: true,
            type: ETreasureMode.worldMap
        },
        challenges: {
            name: "challenges",
            code: ETreasureType.challenge,
            enabled: true,
            type: ETreasureMode.worldMap
        },
        arenas: {
            name: "meeting places",
            code: ETreasureType.arena,
            enabled: true,
            type: ETreasureMode.worldMap
        }
    };

    layersInitialized: boolean = false;
    unifiedGeolocationSub: any;
    currentLocation: ILatLng;
    lastRefreshLocation: ILatLng;
    lastRefreshTimestamp: number;

    debug: boolean = true;

    constructor(
        public settingsProvider: SettingsManagerService,
        public resources: ResourcesCoreDataService,
        public locationMonitor: LocationMonitorService,
        public virtualPositionService: VirtualPositionService,
        public uiext: UiExtensionService
    ) {
        console.log("geo objects service created");
        this.mapFirstObjectsObservable = new BehaviorSubject(null);
        this.globalObjectsObservable = new BehaviorSubject(null);
        this.watchLocation();
    }

    setPlatform(platformFlags: IPlatformFlags) {
        this.platformFlags = platformFlags;
    }


    watchLocation() {
        if (!this.unifiedGeolocationSub) {
            this.unifiedGeolocationSub = this.virtualPositionService.watchVirtualPosition().subscribe((data: IVirtualLocation) => {
                // console.log(data);
                if (this.virtualPositionService.checkNavContext(data, true)) {
                    // console.log("item scanner location updated: ", data.coords);
                    this.currentLocation = data.coords;
                    this.dynamicSyncBuffer();
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    /**
     * check for distance and time from last refresh
     * update the visible objects within the current buffer
     */
    dynamicSyncBuffer() {
        let currentTime: number = new Date().getTime();
        if (!this.lastRefreshTimestamp) {
            this.lastRefreshTimestamp = currentTime;
        }
        if (!this.lastRefreshLocation) {
            this.lastRefreshLocation = Object.assign({}, this.currentLocation);
        }

        let minTimeDelta: number = AppConstants.gameConfig.refreshARMinTimeDelta;
        // minTimeDelta = 0;
        // check for min time delta
        if ((currentTime - this.lastRefreshTimestamp) >= minTimeDelta) {
            // check for min refresh distance
            let distanceDelta: number = GeometryUtils.getDistanceBetweenEarthCoordinates(this.currentLocation, this.lastRefreshLocation, 0);
            if (distanceDelta >= AppConstants.gameConfig.refreshARMinDist) {
                // do refresh
                // reset timestamp and last refresh location (within method)
                this.refreshAll(null);
            }
        }
    }

    /**
     * refresh all objects from object buffers
     * provider code filter
     */
    refreshAll(providerCode: number) {
        if (this.debug) {
            console.log("refresh sync objects");
        }
        this.lastRefreshLocation = Object.assign({}, this.currentLocation);
        this.lastRefreshTimestamp = new Date().getTime();
        this.refreshSyncObjects(this.mapFirstObjectBuffer, this.mapFirstObjects, providerCode);
        this.refreshSyncObjects(this.globalObjectBuffer, this.globalObjects, providerCode);
    }

    /**
     * update the visible objects within the current buffer
     */
    refreshSyncObjects(scopeSource: ILeplaceObjectContainer[], scopeTarget: ILeplaceObjectContainer[], providerCode: number) {
        // max number of objects on the AR
        let trim: number = SettingsManagerService.settings.app.settings.ARVisibleObjects.value;
        let maxDist: number = 2 * SettingsManagerService.settings.app.settings.ARFieldOfView.value;
        // console.log("source: ", scopeSource);
        // console.log("target: ", scopeTarget);
        if (trim > 0) {

            // first sort by distance
            for (let i = 0; i < scopeSource.length; i++) {
                scopeSource[i].dynamic.distance = GeometryUtils.getDistanceBetweenEarthCoordinates(this.currentLocation, scopeSource[i].location, Number.MAX_SAFE_INTEGER);
            }

            let tempScopeSource: ILeplaceObjectContainer[] = scopeSource.sort((a, b) => {
                if (a.dynamic.distance < b.dynamic.distance) {
                    return -1;
                }
                if (a.dynamic.distance > b.dynamic.distance) {
                    return 1;
                }
                return 0;
            });

            // console.log(objects.map(o => o.dynamic.distance));
            // get only the first N nearby objects
            // tempScopeSource = tempScopeSource.slice(0, trim);

            // if (maxDist > 0) {
            //     // then filter them by max distance/field of view
            //     tempScopeSource = tempScopeSource.filter(e => e.dynamic.distance < maxDist);
            // }

            // sorted array
            // get only the first N nearby objects
            // then filter them by max distance/field of view

            for (let i = 0; i < tempScopeSource.length; i++) {
                let e: ILeplaceObjectContainer = tempScopeSource[i];
                e.object.available = this.getVisibleLayer(e.object.genericCode);
                if (i >= trim) {
                    e.object.show = false;
                } else {
                    if (maxDist > 0) {
                        if (e.dynamic.distance < maxDist) {
                            // show object (only if layer is enabled)
                            e.object.show = this.getVisibleLayer(e.object.genericCode);
                        } else {
                            e.object.show = false;
                        }
                    } else {
                        // maximum FOV
                        // show object (only if layer is enabled)
                        e.object.show = this.getVisibleLayer(e.object.genericCode);
                    }
                }
            }
            // console.log(trim, maxDist);
            // finally show only a subset of the object buffer
            this.syncObjectsMulti(scopeTarget, tempScopeSource, providerCode);
        } else {
            this.syncObjectsMulti(scopeTarget, scopeSource, providerCode);
        }
    }

    /**
     * the coin generator can add the coin position here
     * provider code is used for sync filter/mask
     * WARNING
     * mix of sync and add is not tested
     */
    addMapFirstObject(object: ILeplaceObjectContainer) {
        this.mapFirstObjectBuffer.push(object);
        // this.addObject(this.mapFirstObjects, this.mapFirstObjectsObservable, object);
        this.refreshAll(object.providerCode);
    }

    updateMapFirstObject(object: ILeplaceObjectContainer) {
        let item: ILeplaceObjectContainer = this.mapFirstObjectBuffer.find(e => e.object.uid === object.object.uid);
        if (!item) {
            return;
        }
        let index: number = this.mapFirstObjectBuffer.indexOf(item);
        if (index === -1) {
            return;
        }
        this.mapFirstObjectBuffer[index] = object;
        this.refreshAll(object.providerCode);
    }

    /**
     * add multiple objects at once
     * provider code is used for sync filter/mask
     * @param objects 
     */
    addMapFirstObjectsMulti(objects: ILeplaceObjectContainer[]) {
        if (!(objects && objects.length > 0)) {
            return;
        }
        for (let i = 0; i < objects.length; i++) {
            this.mapFirstObjectBuffer.push(objects[i]);
            // this.addObject(this.mapFirstObjects, this.mapFirstObjectsObservable, objects[i]);
        }
        this.refreshAll(objects[0].providerCode);
        if (this.debug) {
            console.log("GEO_OBJ/added: ", objects.length, ", store: ", this.mapFirstObjects.length);
        }
    }

    /**
     * clear all buffers and refresh layers
     */
    clearAllBuffers() {
        this.updateMapFirstBuffer([], null);
        this.refreshAll(null);
    }

    /**
     * update map first buffer with new elements (replace)
     * selected layer (providerCode) only
     * @param objects 
     * @param providerCode 
     */
    updateMapFirstBuffer(objects: ILeplaceObjectContainer[], providerCode: number) {
        if (this.debug) {
            console.log("update map first buffer");
        }
        this.syncObjectBufferCore(this.mapFirstObjectBuffer, objects, providerCode, null, null, null);
    }

    /**
     * can be used with the item scanner to sync the map objects with the AR
     * updates object buffer
     * @param objects 
     * @param providerCode
     * @param updateBuffer
     */
    syncMapFirstObjectsMulti(objects: ILeplaceObjectContainer[], providerCode: number, updateBuffer: boolean) {
        if (updateBuffer) {
            this.updateMapFirstBuffer(objects, providerCode);
        }
        this.syncObjectsMulti(this.mapFirstObjects, objects, providerCode);
    }

    /**
     * CRUD/sync operations on array
     * @param scope current object buffer
     * @param objects new object buffer
     * @param providerCode filter/mask, null = any
     * @param callbackCreate callback create object
     * @param callbackRemove callback remove object
     */
    private syncObjectBufferCore(scope: ILeplaceObjectContainer[], objects: ILeplaceObjectContainer[], providerCode: number,
        callbackCreate: (item: ILeplaceObjectContainer) => any,
        callbackRemove: (item: ILeplaceObjectContainer) => any,
        callbackUpdate: (item: ILeplaceObjectContainer) => any) {
        // CRUD

        let created: number = 0;
        let removed: number = 0;
        let updated: number = 0;

        // create new/update existing
        for (let i = 0; i < objects.length; i++) {
            let existing: boolean = false;

            let providerCheck: boolean = ((objects[i].providerCode === providerCode) || (providerCode === null));

            for (let j = 0; j < scope.length; j++) {
                if (objects[i] && (objects[i].object.uid === scope[j].object.uid) && providerCheck) {
                    existing = true;
                    scope[j] = objects[i];
                    updated += 1;
                    if (callbackUpdate) {
                        callbackUpdate(objects[i]);
                    }
                    break;
                }
            }
            if (!existing && providerCheck) {
                // create new
                scope.push(objects[i]);
                created += 1;
                if (callbackCreate) {
                    callbackCreate(objects[i]);
                }
            }
        }

        let removeList: ILeplaceObjectContainer[] = [];

        // check removed
        for (let i = 0; i < scope.length; i++) {
            let existing: boolean = false;
            let providerCode1: number = null;
            if (scope[i]) {
                providerCode1 = scope[i].providerCode;
            }
            for (let j = 0; j < objects.length; j++) {
                if (scope[i] && (scope[i].object.uid === objects[j].object.uid)) {
                    existing = true;
                    break;
                }
            }
            let providerCheck: boolean = ((providerCode1 === providerCode) || (providerCode === null));
            if (!existing && providerCheck) {
                // add to remove list
                removed += 1;
                removeList.push(scope[i]);
            }
        }

        // remove objects
        for (let i = 0; i < removeList.length; i++) {
            let index: number = scope.indexOf(removeList[i]);
            if (index !== -1) {
                scope.splice(index, 1);
                if (callbackRemove) {
                    callbackRemove(removeList[i]);
                }
            }
        }

        if (this.debug) {
            console.log("sync object buffer core, created: " + created + ", removed: " + removed + ", updated: " + updated);
        } else {
            console.log("sync object buffer core, created: " + created + ", removed: " + removed + ", updated: " + updated);
        }
    }

    /**
     * sync objects instead of clear/add method
     * provides a more smooth experience
     * selective/partial sync based on provider (generator) code
     * @param scope 
     * @param objects 
     */
    private syncObjectsMulti(scope: ILeplaceObjectContainer[], objects: ILeplaceObjectContainer[], providerCode: number) {
        if (this.debug) {
            console.log("sync objects multi");
        }
        // console.log("scope: ", scope);
        // console.log("objects: ", objects);
        this.syncObjectBufferCore(scope, objects, providerCode, (obj: ILeplaceObjectContainer) => {
            this.addObjectCore(this.mapFirstObjectsObservable, obj);
        }, (obj: ILeplaceObjectContainer) => {
            this.removeObjectFromAR(this.mapFirstObjectsObservable, obj);
        }, (obj: ILeplaceObjectContainer) => {
            this.updateObjectFromAR(this.mapFirstObjectsObservable, obj);
        });
    }


    /**
     * send remove event
     * @param obsScope 
     * @param object 
     */
    private removeObjectFromAR(obsScope: BehaviorSubject<ILeplaceObjectGenerator>, object: ILeplaceObjectContainer) {
        obsScope.next({
            operation: ECRUD.remove,
            container: object
        });
    }

    /**
     * send update event
     * @param obsScope 
     * @param object 
     */
    private updateObjectFromAR(obsScope: BehaviorSubject<ILeplaceObjectGenerator>, object: ILeplaceObjectContainer) {
        obsScope.next({
            operation: ECRUD.update,
            container: object
        });
    }

    /**
     * send create event
     * @param obsScope 
     * @param object 
     */
    private addObjectCore(obsScope: BehaviorSubject<ILeplaceObjectGenerator>, object: ILeplaceObjectContainer) {
        // check if the layer is enabled to sync with the object visible state
        object.object.show = this.getVisibleLayer(object.object.genericCode);
        if (!obsScope) {
            return;
        }
        obsScope.next({
            operation: ECRUD.add,
            container: object
        });
    }


    /**
     * add other objects that are directly managed by this provider
     * sync between map and AR
     * add to buffer and visible layer as well
     * @param object 
     */
    addGlobalObject(object: ILeplaceObjectContainer) {
        this.addObject(this.globalObjectBuffer, null, object);
        this.addObject(this.globalObjects, this.globalObjectsObservable, object);
    }

    /**
     * set visible layers for AR objects
     * @param layer 
     * @param show 
     */
    setShowLayer(layer: number, show: boolean) {
        // let keys: string[] = Object.keys(this.layers);
        this.layers[layer].enabled = show;
    }

    setShowLayersAll(layers: IActionLayers, show: boolean) {
        let keys: string[] = Object.keys(layers);
        // check all existing objects if they should be visible or not based on the updated layers
        // the AR view entry should reload/sync the objects after this
        for (let i = 0; i < keys.length; i++) {
            let layer: IPopoverAction = layers[keys[i]];
            layer.enabled = show;
            this.setShowObjectsLayer(this.mapFirstObjects, layer.code, layer.enabled);
            this.setShowObjectsLayer(this.globalObjects, layer.code, layer.enabled);
        }
    }

    /**
     * set visible layers for AR objects
     * @param layer 
     * @param show 
     */
    setShowLayers(layers: IActionLayers) {
        let keys: string[] = Object.keys(layers);
        // assign new layers
        this.layers = layers;
        // check all existing objects if they should be visible or not based on the updated layers
        // the AR view entry should reload/sync the objects after this
        for (let i = 0; i < keys.length; i++) {
            let layer: IPopoverAction = layers[keys[i]];
            this.setShowObjectsLayer(this.mapFirstObjects, layer.code, layer.enabled);
            this.setShowObjectsLayer(this.globalObjects, layer.code, layer.enabled);
        }
        if (this.debug) {
            console.log("set show layers: ", layers);
            console.log(this.mapFirstObjects);
            console.log(this.globalObjects);
        }
    }


    private getVisibleLayer(layerCode: number) {
        let keys: string[] = Object.keys(this.layers);
        for (let i = 0; i < keys.length; i++) {
            if (this.layers[keys[i]].code === layerCode) {
                return this.layers[keys[i]].enabled;
            }
        }
        // maybe the layer is not registered e.g. coins
        return true;
    }

    /**
     * set visible state for all objects within a layer
     * @param scope 
     * @param layerCode 
     * @param visible 
     */
    private setShowObjectsLayer(scope: ILeplaceObjectContainer[], layerCode: number, visible: boolean) {
        for (let i = 0; i < scope.length; i++) {
            if (scope[i].object.genericCode === layerCode) {
                scope[i].object.show = visible;
                scope[i].object.showMinimap = visible;
            }
        }
    }

    /**
     * get visible layers for AR objects
     * request treasure specs from the server
     * fallback to local specs
     * resolve only
     * @param reset use this when switching from storyline to world map (to restore the treasures on the map)
     */
    getShowLayersResolve(reset: boolean): Promise<IActionLayers> {
        let promise: Promise<IActionLayers> = new Promise((resolve) => {
            if (this.layersInitialized && !reset) {
                resolve(this.layers);
                return;
            }

            // if (this.layersInitialized) {
            // this one triggered the duplicate bug!
            // if (reset) {
            //     this.setShowLayersAll(this.layers, true);
            // }

            this.resources.getTreasureSpecsGeneric().then((data: ITreasureSpec[]) => {
                if (data) {
                    this.layers = {};
                    for (let spec of data) {
                        this.layers[spec.name] = {
                            name: spec.dispName,
                            code: spec.code,
                            enabled: true,
                            photoUrl: spec.photoUrl,
                            type: spec.worldMap === 1 ? ETreasureMode.worldMap : ETreasureMode.collectible
                        };
                    }
                    this.layersInitialized = true;
                    resolve(this.layers);
                } else {
                    this.uiext.showAlertNoAction(Messages.msg.layersNotInitialized.after.msg, Messages.msg.layersNotInitialized.after.sub);
                    resolve(this.layers);
                }
            }).catch((err: Error) => {
                console.error(err);
                this.uiext.showAlertNoAction(Messages.msg.layersNotInitialized.after.msg, Messages.msg.layersNotInitialized.after.sub);
                resolve(this.layers);
            });
        });
        return promise;
    }


    private addObject(scope: ILeplaceObjectContainer[], obsScope: BehaviorSubject<ILeplaceObjectGenerator>, object: ILeplaceObjectContainer) {
        // console.log("GEO_OBJ/add object: ", object);
        if ((scope.find(e => e.object.uid === object.object.uid)) != null) {
            console.error("object already exists in the global store");
            return;
        }

        if (!object.object) {
            console.error("undefined object");
            return;
        }

        if (object.object.genericCode == null) {
            console.error("undefined object generic type: ", object.object.genericCode);
            return;
        }

        scope.push(object);
        this.addObjectCore(obsScope, object);
    }


    /**
     * add other objects that are directly managed by this provider
     * sync between map and AR
     * add default test object with only the location specified
     * @param location 
     */
    addGlobalObjectByLocation(location: ILatLng) {
        let obj: ILeplaceObject = ItemScannerUtils.getLocalObjectSpecs(ETreasureType.exploreObject);
        obj.id = this.testObjectId;
        obj.uid = GameUtils.getDefaultObjectUid(this.testObjectId, 1000);
        this.testObjectId += 1;
        let object: ILeplaceObjectContainer = {
            location,
            providerCode: EGeoObjectsProviderCode.default,
            object: obj,
            treasure: null,
            dynamic: {
                distance: null
            }
        };
        this.addGlobalObject(object);
    }

    /**
     * e.g. place custom (user generated) content on the map
     * @param location 
     * @param treasure 
     * @param lock lock item on sync, only remove when disposed
     */
    addGlobalObjectByTreasureSpecs(location: ILatLng, treasure: ILeplaceTreasure, lock: boolean) {
        if (!treasure) {
            return;
        }
        let obj: ILeplaceObject = ItemScannerUtils.getCrateObjectSpecsFromTreasureSpecs(treasure.spec);
        obj.id = treasure.id;
        obj.uid = treasure.uid;
        let object: ILeplaceObjectContainer = {
            location,
            providerCode: EGeoObjectsProviderCode.default,
            object: obj,
            treasure,
            dynamic: {
                distance: null
            },
            lock: lock
        };
        console.log("add global object by treasure specs");
        this.addGlobalObject(object);
    }

    removeAllGlobalObjects() {
        this.removeAllObjectsSplice(this.globalObjects);
    }

    private removeAllObjectsSplice(scope: ILeplaceObjectContainer[]) {
        let n = scope.length;
        for (let i = 0; i < n; i++) {
            scope.pop();
        }
    }


    /**
     * get all global added objects
     */
    getGlobalObjects() {
        return this.globalObjects;
    }

    /**
     * get all map first added objects
     */
    getMapFirstObjects() {
        return this.mapFirstObjects;
    }

    getAllBufferedObjects() {
        let all: ILeplaceObjectContainer[] = [];
        all = all.concat(this.globalObjectBuffer);
        all = all.concat(this.mapFirstObjectBuffer);
        return all;
    }

    /**
     * cleanup
     * clear all objects
     */
    clearGlobalObjects() {
        this.globalObjects = this.clearObjects(this.globalObjects);
    }


    /**
     * cleanup
     * clear all objects
     */
    clearMapFirstObjects() {
        this.mapFirstObjects = this.clearObjects(this.mapFirstObjects);
    }


    /**
     * cleanup
     * clear all objects
     */
    private clearObjects(scope: ILeplaceObjectContainer[]) {
        scope = [];
        return scope;
    }


    /**
     * collect object by id and type
     * if type is not specified, then it removes any existing object with the specified id
     * triggers observable
     * @param id 
     * @param type
     * @param raw keep original type also in platform web
     */
    collectGlobalObjectByIdAndType(id: number, type: number) {
        this.collectObjectByIdAndType(this.globalObjects, this.globalObjectsObservable, id, type);
    }


    /**
     * collect object by id and type
     * if type is not specified, then it removes any existing object with the specified id
     * triggers observable
     * @param id 
     * @param type
     * @param raw keep original type also in platform web
     */
    collectMapFirstObjectByIdAndType(id: number, type: number) {
        this.collectObjectByIdAndType(this.mapFirstObjects, this.mapFirstObjectsObservable, id, type);
    }


    /**
     * collect object by id and type
     * if type is not specified, then it removes any existing object with the specified id
     * triggers observable
     * @param id 
     * @param type
     * @param raw keep original type also in platform web
     */
    private collectObjectByIdAndType(scope: ILeplaceObjectContainer[], obsScope: BehaviorSubject<ILeplaceObjectGenerator>, id: number, type: number) {
        let removedObject: ILeplaceObjectContainer = this.removeObjectByIdAndType(scope, id, type);
        if (removedObject) {
            obsScope.next({
                operation: ECRUD.remove,
                container: removedObject
            });
        }
        return removedObject;
    }


    getGlobalObjectByUid(uid: string) {
        let obj: ILeplaceObjectContainer = this.removeObjectByUid(this.globalObjectBuffer, this.globalObjectsObservable, uid, false, false);
        return obj;
    }

    getMapFirstObjectByUid(uid: string) {
        let obj: ILeplaceObjectContainer = this.removeObjectByUid(this.mapFirstObjectBuffer, this.mapFirstObjectsObservable, uid, false, false);
        return obj;
    }

    removeGlobalObjectByUid(uid: string, event: boolean) {
        let obj: ILeplaceObjectContainer = this.removeObjectByUid(this.globalObjectBuffer, this.globalObjectsObservable, uid, true, event);
        return obj;
    }

    removeMapFirstObjectByUid(uid: string, event: boolean) {
        let obj: ILeplaceObjectContainer = this.removeObjectByUid(this.mapFirstObjectBuffer, this.mapFirstObjectsObservable, uid, true, event);
        return obj;
    }

    removeAnyObjectByUid(uid: string, event: boolean) {
        let obj: ILeplaceObjectContainer = this.removeMapFirstObjectByUid(uid, event);
        if (!obj) {
            obj = this.removeGlobalObjectByUid(uid, event);
        }
        return obj;
    }

    /**
     * remove ONE object by id and type
     * if type is not specified, then it removes any existing object with the specified id
     * @param id 
     * @param type
     * @param raw keep original type also in platform web
     */
    private removeObjectByIdAndType(scope: ILeplaceObjectContainer[], id: number, type: number) {
        let removedObject: ILeplaceObjectContainer = null;
        for (let i = 0; i < scope.length; i++) {
            let obj = scope[i];
            let condition: boolean = obj.object.id === id;
            if (type) {
                condition = condition && obj.object.genericCode === type;
            }
            if (condition) {
                let removedObjects: ILeplaceObjectContainer[] = scope.splice(i, 1);
                if (removedObjects) {
                    removedObject = removedObjects[0];
                }
                break;
            }
        }
        return removedObject;
    }

    /**
     * remove object by id and type
     * if type is not specified, then it removes any existing object with the specified id
     * @param id 
     * @param type
     * @param raw keep original type also in platform web
     */
    private removeObjectByUid(scope: ILeplaceObjectContainer[], obsScope: BehaviorSubject<ILeplaceObjectGenerator>, uid: string, remove: boolean, event: boolean) {
        let obj: ILeplaceObjectContainer = null;
        // console.log(scope, uid);
        for (let i = 0; i < scope.length; i++) {
            let obj1 = scope[i];
            let condition: boolean = obj1.object.uid === uid;
            if (condition) {
                if (remove) {
                    let removedObjects: ILeplaceObjectContainer[] = scope.splice(i, 1);
                    // console.log(removedObjects);
                    if (removedObjects) {
                        obj = removedObjects[0];
                        if (obj && obj.treasure && obj.treasure.placeMarker) {
                            // disable sync lock (will now remove the marker on sync)
                            console.log("lock sync disabled");
                            obj.lock = false;
                            obj.treasure.placeMarker.lockSync = false;
                        }
                        if (event && obj) {
                            obsScope.next({
                                operation: ECRUD.remove,
                                container: obj
                            });
                        }
                    }
                } else {
                    obj = obj1;
                }
                break;
            }
        }
        return obj;
    }



    /**
     * clear objects of type
     * i.e. with the specified code
     * global objects are added to the map and AR, through this method
     * @param type 
     */
    removeGlobalObjectsByType(type: number) {
        this.removeObjectsByType(this.globalObjects, this.globalObjectsObservable, type);
    }

    /**
     * clear objects of type
     * i.e. with the specified code
     * map first objects are separately added to the map, not through this method
     * @param type 
     */
    removeMapFirstObjectsByType(type: number) {
        this.removeObjectsByType(this.mapFirstObjects, this.mapFirstObjectsObservable, type);
    }

    /**
     * clear objects of type
     * i.e. with the specified code
     * @param type 
     */
    private removeObjectsByType(scope: ILeplaceObjectContainer[], obsScope: BehaviorSubject<ILeplaceObjectGenerator>, type: number) {
        // let filteredObjects: ILeplaceObjectContainer[] = scope.filter(o => o.object.code === type);
        let index: number[] = [];
        for (let i = 0; i < scope.length; i++) {
            if (scope[i].object.genericCode === type) {
                index.push(i);
            }
        }
        let n: number = index.length;
        // the backward for loop is mandatory for this operation
        // you can now .splice() without messing up the indexes of the yet-to-be-removed items.
        for (let i = n - 1; i >= 0; i--) {
            let objects: ILeplaceObjectContainer[] = scope.splice(index[i], 1);
            if (objects) {
                let object: ILeplaceObjectContainer = objects[0];
                if (object) {
                    obsScope.next({
                        operation: ECRUD.remove,
                        container: object
                    });
                }
            }
        }
        if (this.debug) {
            console.log("GEO_OBJ/removed by type: ", type, ", ", n, ", store: ", scope.length);
            console.log(scope);
        }
    }


    /**
     * the ar view can view the objects via observable
     * this is used to detect when an object is added
     * the objects were already added to the map, so the map is not notified
     */
    getMapFirstObjectWatch() {
        return this.mapFirstObjectsObservable;
    }


    /**
     * 
     * @param currentLocation 
     */
    checkNearbyMapFirstObjects(currentLocation: ILatLng) {
        return this.checkNearbyObjects(this.mapFirstObjects, currentLocation);
    }

    /**
     * check distance to objects and find the objects that can be collected
     */
    checkNearbyObjects(scope: ILeplaceObjectContainer[], currentLocation: ILatLng) {
        let nearbyObjects: ILeplaceObject[] = [];
        for (let i = 0; i < scope.length; i++) {
            let objectLocation: ILatLng = scope[i].location;
            let distance: number = GeometryUtils.getDistanceBetweenEarthCoordinates(currentLocation, objectLocation, Number.MAX_VALUE);
            if (distance < AppConstants.gameConfig.ARCollectDistance) {
                nearbyObjects.push(scope[i].object);
            }
        }
        // console.log("check nearby: ", nearbyObjects);
        return nearbyObjects;
    }


    /**
     * this is used for objects that are directly added on this provider
     * they are displayed then both on the map and on the AR view 
     * both the map and the AR views are notified
     */
    getGlobalObjectWatch() {
        return this.globalObjectsObservable;
    }

}




