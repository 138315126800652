import { IActivityQuestSpecs } from 'src/app/classes/def/nav-params/activity-details';
import { IQuestAction, EQuestAction, IQuestActionMapClue, IQuestActivityStatus, IQuestOptions, EQuestType } from 'src/app/classes/def/activity/quest';
import { IQuestActivityDef } from 'src/app/classes/def/core/activity';

export class QuestUtils {
    /**
     * send quest action
     * validate, check penalty
     */
    static checkAction(questData: IActivityQuestSpecs, questStatus: IQuestActivityStatus, options: IQuestOptions, action: number, apply: boolean): IQuestAction {
        let qa: IQuestAction = {
            action: null,
            data: null
        };

        if (!questData) {
            return qa;
        }

        switch (action) {
            case EQuestAction.requireClue:
                // check available clue
                if (questData.clue) {
                    qa.action = action;
                    qa.data = questData.clue;
                    if (apply) {
                        if (!questStatus.usedClue) {
                            questStatus.usedClue = true;
                            questStatus.penalty += options.cluePenalty;
                        }
                    }
                }
                break;
            case EQuestAction.requireMapClue:
                if (questData.lat && questData.lng) {
                    let qa1: IQuestActionMapClue = qa;
                    qa1.action = action;
                    qa1.data = {
                        lat: questData.lat,
                        lng: questData.lng
                    };
                    if (apply) {
                        if (!questStatus.usedMapClue) {
                            questStatus.usedMapClue = true;
                            questStatus.penalty += options.mapCluePenalty;
                        }
                    }
                }
                break;
            default:
                break;
        }
        QuestUtils.checkPenaltyCap(questStatus);
        return qa;
    }

    static checkPenaltyCap(questStatus: IQuestActivityStatus) {
        if (questStatus) {
            if (questStatus.penalty > 100) {
                questStatus.penalty = 100;
            }
        }
    }

    static applyRetryPenalty(questData: IActivityQuestSpecs, questStatus: IQuestActivityStatus, qad: IQuestActivityDef) {
        if (!questData) {
            return;
        }
        questStatus.penalty = QuestUtils.getRetryPenalty(questData, questStatus, qad);
        QuestUtils.checkPenaltyCap(questStatus);
    }

    static getRetryPenalty(questData: IActivityQuestSpecs, questStatus: IQuestActivityStatus, qad: IQuestActivityDef) {
        if (!questData) {
            return;
        }
        let retryPenalty: number = 10;
        if (qad && (qad.maxRetry != null) && (qad.maxRetry > 0)) {
            retryPenalty = Math.floor(100 / qad.maxRetry);
        }
        let dec: number = 0;
        let cumulativePenalty: number = questStatus.penalty;
        switch (questData.t) {
            case EQuestType.text:
                cumulativePenalty += retryPenalty;
                break;
            case EQuestType.numeric:
                cumulativePenalty += retryPenalty;
                break;
            case EQuestType.select:
                if (questData.opt != null && questData.opt.length > 0) {
                    dec = Math.floor((100 + retryPenalty) / questData.opt.length);
                }
                cumulativePenalty += dec;
                break;
            case EQuestType.selectMulti:
                if (questData.opt != null && questData.opt.length > 0) {
                    dec = Math.floor((100 + retryPenalty) / questData.opt.length);
                }
                cumulativePenalty += dec;
                break;
            default:
                break;
        }
        if (cumulativePenalty > 100) {
            cumulativePenalty = 100;
        }
        return cumulativePenalty;
    }

    static getFeedback(qd: IActivityQuestSpecs, match: boolean, showCorrectAnswer: boolean) {
        let feedback: string = "";      

        if (!qd) {
            return feedback;
        }

        if (qd.free) {
            return "<p>Your response was registered</p>";
        }

        if (!match && !showCorrectAnswer) {
            return feedback;
        }

        if (qd.opt && qd.opt.length > 0) {
            switch (qd.t) {
                case EQuestType.text:
                    if (qd.opt != null && qd.opt.length > 0) {
                        feedback += !match ? "<p>The correct answer is:</p>" : "";
                        for (let a of qd.opt) {
                            feedback += "<p>" + a + "</p>";
                        }
                    } else {
                        feedback += !match ? "<p>The correct answer is:</p>" : "";
                        feedback += "<p>" + qd.a + "</p>";
                    }
                    break;
                case EQuestType.select:
                    if (qd.a != null) {
                        feedback += !match ? "<p>The correct answer is:</p>" : "";
                        feedback += "<p>" + qd.opt[qd.a] + "</p>";
                    } else {
                        feedback += !match ? "<p>The correct answers are:</p>" : "";
                        for (let a of qd.opt) {
                            feedback += "<p>" + a + "</p>";
                        }
                    }
                    break;
                case EQuestType.selectMulti:
                    if (qd.a != null) {
                        feedback += !match ? "<p>The correct answers are:</p>" : "";
                        // valid specs defined in question params as csv string
                        let validSpecs: string[] = (qd.a as string).split(",");
                        let validSpecsInd: number[] = validSpecs.map(vs => Number.parseInt(vs));
                        for (let ind of validSpecsInd) {
                            feedback += "<p>" + qd.opt[ind] + "</p>";
                        }
                    } else {
                        feedback += !match ? "<p>The correct answers are:</p>" : "";
                        for (let a of qd.opt) {
                            feedback += "<p>" + a + "</p>";
                        }
                    }
                    break;
            }
        } else {
            feedback += !match ? "<p>The correct answer is:</p>" : "";
            feedback += "<p>" + qd.a + "</p>";
        }
        return feedback;
    }
}