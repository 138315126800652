import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IBackendLocation, ILocationContainer } from 'src/app/classes/def/places/backend-location';
import { ELocationFlag } from 'src/app/classes/def/places/app-location';
import { EAppIconsStandard, IIconSpec } from 'src/app/classes/def/app/icons';
import { ActivityUtils } from 'src/app/classes/utils/activity-utils';
import { ILocationInfoText, LocationDispUtils } from 'src/app/services/location/location-disp-utils';
import { EStoryLocationDoneFlag } from 'src/app/classes/def/nav-params/story';

@Component({
  selector: 'location-info-core',
  templateUrl: './location-info-core.component.html',
  styleUrls: ['./location-info-core.component.scss'],
})
export class LocationInfoCoreComponent implements OnInit, OnDestroy {
  hasItems: boolean = false;
  iconClass: string = "";

  footerText: string = "";

  icons = {
    progress: {
      icon: "",
      custom: false
    },
    place: {
      icon: "pin",
      custom: false
    },
    flag: {
      icon: "",
      custom: false
    },
    activity: {
      icon: "",
      custom: false
    }
  };


  show = {
    placeName: false,
    activityIcon: false,
    placeIcon: true,
    placePhoto: false,
    flagIcon: false,
    progressIcon: false
  };


  progressClass: string = "";
  locationType: string = "";
  bloc: IBackendLocation = null;
  headerText: string = "";
  locationNameClass: string = "text-color-element";

  @Input()
  data: ILocationContainer;

  constructor(

  ) {

  }

  ngOnInit() {
    if (this.data) {
      console.log("format location info: ", this.data);
      this.bloc = this.data.merged;

      if (this.bloc.activity) {
        let icon: IIconSpec = {
          icon: "compass",
          custom: false
        };

        icon = ActivityUtils.getIcon(this.bloc.activity.name);
        if (!icon.icon) {
          if (this.bloc.activity.icon) {
            icon.icon = this.bloc.activity.icon;
          }
        }

        if (icon) {
          this.icons.activity = icon;
          this.show.activityIcon = true;
        }
      }

      this.iconClass = "leplace-icon icon-color-primary";
      this.progressClass = this.iconClass;
      this.icons.flag = {
        icon: "",
        custom: false
      };

      switch (this.bloc.flag) {
        case ELocationFlag.FIXED:
          // this.statusIcon = "leplace-icon fa fa-2x fa-check-square-o"
          this.icons.flag.icon = "star";
          break;
        case ELocationFlag.RANDOM:
          // this.statusIcon = "leplace-icon fa fa-2x fa-random"
          this.icons.flag.icon = "shuffle";
          break;
        case ELocationFlag.SAVED:
          // this.statusIcon = "leplace-icon fa fa-2x fa-floppy-o"
          this.icons.flag.icon = "bookmark";
          break;
      }

      this.show.flagIcon = true;

      if (this.bloc.locationType) {
        if (!this.bloc.locationType.photo) {
          if (this.bloc.locationType.icon) {
            this.show.placeIcon = true;
            this.icons.place.icon = this.bloc.locationType.icon;
          }
        } else {
          this.show.placePhoto = true;
          this.icons.place.icon = this.bloc.locationType.photoUrl;
        }
      }

      if (this.bloc.done === EStoryLocationDoneFlag.done) {
        // this.progressIcon = "margin-right-s leplace-icon cursor-pointer fa fa-2x fa-check-square-o";
        this.icons.progress.icon = EAppIconsStandard.check;
        this.progressClass = "leplace-icon icon-color-primary";
      } else {
        // this.progressIcon = "margin-right-s leplace-icon cursor-pointer fa fa-2x fa-square-o";
        this.icons.progress.icon = EAppIconsStandard.close;
      }

      this.show.progressIcon = true;

      let hidden: boolean = LocationDispUtils.checkHiddenPlace(this.data, false);
      let locInfo: ILocationInfoText = LocationDispUtils.getLocationInfoText(this.bloc, hidden);
      this.headerText = locInfo.headerText;
      this.footerText = locInfo.footerText;

      // this.locationNameClass = locInfo.nameClass;
      // this.headerText = GeneralUtils.trimName(this.headerText, EMessageTrim.locationInfoCard);
      // this.footerText = GeneralUtils.trimName(this.footerText, EMessageTrim.locationInfoCard);
    }
  }

  ngOnDestroy() {

  }
}
