<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [loading]="loading" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>


<!-- <ion-content class="custom-modal-style">

  <div [class]="wrapperClass" fxLayout="column" [ngClass]="vs.transparent?'view-frame-minimal':'view-frame-minimal theme-background'"> -->
<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div fxLayout="column">
    <div class="scrollbar-y scroll-content-modal-1">
      <div *ngIf="photoUrl" class="padding-s ">
        <!-- <img [src]="photoUrl" class="border-radius img-small"> -->
        <responsive-img [photoUrl]="photoUrl" class="border-radius img-small"></responsive-img>
      </div>
      <safe-html-renderer [unsafeHtmlContent]="description"
        innerClass="info-text-m description-frame text-color-primary text-left"></safe-html-renderer>
    </div>
    <div fxFlex></div>
    <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col size="12" class="padding-xs">
            <ion-button class="action-button-fill button-color-accent" (click)="ok()">
              <span class="button-font-size-m">Continue</span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</modal-container>