import { IUserPublicData } from "../user/general";
import { IEventLeaderboard } from "../events/events";
import { IEventListNavParams } from "../events/nav-params";


export interface ILeaderboardNavParams {
    scope: number;
    mpCard: boolean;
    reload: boolean;
    lb?: IEventLeaderboard;
    eventListNavParams?: IEventListNavParams;
}

export interface ILeaderboardExtNavParams {
    storyId: number;
    reload: boolean;
    xpScaleFactor?: number;
}

export interface ILeaderboardDetailNavParams {
    leaderId: number;
    leaderName: string;
    storyId: number;
    isGroup: boolean;
    details: IUserPublicData;
    xpScaleFactor?: number;
    startDate?: string,
    endDate?: string
}

export enum ELeaderboardScope {
    global = 1,
    event = 2
}

export interface ITimespan {
    start: number,
    end: number
}

export interface ITimespanString {
    start: string,
    end: string
}
