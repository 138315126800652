import { Injectable } from "@angular/core";
import { ILatLng } from 'src/app/classes/def/map/coords';
import { IPathContent, IWaypointCoordsMulti } from "../../classes/def/map/map-data";
import { MathUtils } from "../../classes/general/math";
import { MarkerHandlerService } from "../map/markers";
import { GeometryUtils } from "./geometry-utils";
import { EActivityDirectionsMode } from 'src/app/classes/def/map/navigation';
import { EMarkerLayers } from 'src/app/classes/def/map/marker-layers';


@Injectable({
    providedIn: 'root'
})
export class DirectionsService {
    serverUrl: string;

    directionsService: google.maps.DirectionsService;
    currentMode: number = EActivityDirectionsMode.multi;

    directionsIndex: number = 0;

    constructor(
        private markerHandler: MarkerHandlerService
    ) {
        console.log("directions service created");
    }

    init(directionsService: google.maps.DirectionsService) {
        this.directionsService = directionsService;
    }

    async clearDirectionsForActivity() {
        let promise = new Promise(async (resolve) => {
            for (let i = 0; i < this.directionsIndex; i++) {
                try {
                    await this.markerHandler.disposeLayerResolve(EMarkerLayers.ACTIVITY_PATH + i);
                    console.log("cleared activity direction " + i);
                } catch (e) {
                    console.error(e);
                }
            }
            console.log("activity directions cleared");
            resolve(true);
        });
        return promise;
    }


    clearDirectionsToTarget() {
        return this.markerHandler.disposeLayerResolve(EMarkerLayers.ACTIVITY_PATH_TARGET);
    }

    /**
     * get directions for activity (move, explore)
     * @param mode 
     * @param start 
     * @param radius 
     * @param heading match heading +- 45 deg
     */
    getDirectionsForActivity(mode: number, start: ILatLng, radius: number, heading: number): Promise<IWaypointCoordsMulti> {
        let coordDelta: number = GeometryUtils.getCoordDeltaFromDistanceDelta(radius);
        // console.log("radius: " + radius + " coord delta: " + delta);
        let promises = [];
        let promise: Promise<IWaypointCoordsMulti> = new Promise(async (resolve, reject) => {

            if (!radius) {
                resolve(null);
                return;
            }

            this.currentMode = mode;
            this.directionsIndex = 0;

            let destinations = [];
            let waypointsMultipleDirections: IWaypointCoordsMulti = {
                waypointArray: []
            };

            if (heading != null) {
                destinations.push(GeometryUtils.getPointOnHeading(start, radius, heading));
                destinations.push(GeometryUtils.getPointOnHeading(start, radius, heading + 45));
                destinations.push(GeometryUtils.getPointOnHeading(start, radius, heading - 45));
            } else {
                destinations.push(new ILatLng(start.lat + coordDelta, start.lng));
                destinations.push(new ILatLng(start.lat, start.lng + coordDelta));
                destinations.push(new ILatLng(start.lat - coordDelta, start.lng));
                destinations.push(new ILatLng(start.lat, start.lng - coordDelta));
            }

            switch (mode) {
                case EActivityDirectionsMode.multi:
                    break;
                case EActivityDirectionsMode.single:
                    if (heading != null) {
                        destinations = [destinations[0]];
                    } else {
                        let randomIndex = MathUtils.randomIndex(0, 4);
                        destinations = [destinations[randomIndex]];
                    }
                    break;
            }

            let promiseIndexDone: number = 0;

            destinations.forEach((destination: ILatLng) => {
                waypointsMultipleDirections.waypointArray.push({
                    waypoints: []
                });
                promises.push(new Promise((resolve) => {
                    this.directionsService.route({
                        origin: start,
                        destination,
                        travelMode: google.maps.TravelMode.WALKING
                    }, (response, status) => {
                        if (status === google.maps.DirectionsStatus.OK) {
                            let route: google.maps.LatLng[] = response.routes[0].overview_path;

                            // add waypoints to array
                            let wpoints: ILatLng[] = [];
                            route.forEach(element => {
                                wpoints.push(new ILatLng(element.lat(), element.lng()));
                            });

                            waypointsMultipleDirections.waypointArray[this.directionsIndex].waypoints = wpoints;

                            let promisesMarkers = [];
                            let pd: IPathContent = {
                                arrows: false,
                                path: EMarkerLayers.ACTIVITY_PATH + this.directionsIndex,
                                callback: null
                            };
                            let promise1 = this.markerHandler.addPath(wpoints, EMarkerLayers.ACTIVITY_PATH + this.directionsIndex, pd, true);
                            promise1.then(() => {

                            }).catch(() => {

                            });

                            promisesMarkers.push(promise1);
                            this.directionsIndex += 1;

                            Promise.all(promisesMarkers).then(() => {
                                promiseIndexDone += 1;
                                resolve(true);
                            }).catch((err: Error) => {
                                console.error(err);
                                resolve(false);
                            });
                        } else {
                            resolve(false);
                        }
                    });
                }));
            });

            Promise.all(promises).then(() => {
                if (promiseIndexDone === 0) {
                    reject(new Error("no directions found"));
                } else {
                    resolve(waypointsMultipleDirections);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get directions to target
     * will fallback to straight line
     * @param start 
     * @param target 
     * @param show 
     */
    getDirectionsToTarget(start: ILatLng, target: ILatLng, show: boolean): Promise<IWaypointCoordsMulti> {
        let promise: Promise<IWaypointCoordsMulti> = new Promise((resolve) => {

            let waypointsMultipleDirections: IWaypointCoordsMulti = {
                waypointArray: []
            };

            this.directionsService.route({
                origin: start,
                destination: target,
                travelMode: google.maps.TravelMode.WALKING
            }, (response, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    let route = response.routes[0].overview_path;

                    // add waypoints to array
                    let wpoints: ILatLng[] = [];
                    route.forEach(element => {
                        wpoints.push(new ILatLng(element.lat(), element.lng()));
                    });

                    waypointsMultipleDirections.waypointArray.push({
                        waypoints: wpoints
                    });
                    let pd: IPathContent = {
                        arrows: false,
                        distanceLabels: true,
                        path: EMarkerLayers.ACTIVITY_PATH_TARGET,
                        callback: null
                    };
                    this.markerHandler.addPath(wpoints, EMarkerLayers.ACTIVITY_PATH_TARGET, pd, show).then(() => {
                        resolve(waypointsMultipleDirections);
                    }).catch(() => {
                        resolve(waypointsMultipleDirections);
                    });
                } else {
                    this.getDirectionsToTargetFallback(start, target, show).then((res) => {
                        resolve(res);
                    });
                }
            });
        });
        return promise;
    }

    /**
     * get directions to target
     * fallback to straight line
     * @param start 
     * @param target 
     * @param show 
     */
    getDirectionsToTargetFallback(start: ILatLng, target: ILatLng, show: boolean): Promise<IWaypointCoordsMulti> {
        let promise: Promise<IWaypointCoordsMulti> = new Promise((resolve) => {

            let waypointsMultipleDirections: IWaypointCoordsMulti = {
                waypointArray: []
            };

            let wpoints: ILatLng[] = [];

            wpoints.push(start);
            wpoints.push(target);

            waypointsMultipleDirections.waypointArray.push({
                waypoints: wpoints
            });

            let pd: IPathContent = {
                arrows: false,
                distanceLabels: true,
                path: EMarkerLayers.ACTIVITY_PATH_TARGET,
                callback: null
            };

            this.markerHandler.addPath(wpoints, EMarkerLayers.ACTIVITY_PATH_TARGET, pd, show).then(() => {
                resolve(waypointsMultipleDirections);
            }).catch(() => {
                resolve(waypointsMultipleDirections);
            });
        });

        return promise;
    }

}


