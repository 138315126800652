import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, Self, SimpleChange, SimpleChanges } from '@angular/core';
import { INgxChartOptions, IChartSeriesContainer, IChartDataContainer } from 'src/app/classes/def/app/charts';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { NgxChartParams } from 'src/app/classes/general/params';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';


@Component({
  selector: 'leplace-xy-chart',
  templateUrl: './leplace-xy-chart.component.html',
  styleUrls: ['./leplace-xy-chart.component.scss'],
})
export class LeplaceXYChartComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  chartOptions: INgxChartOptions = NgxChartParams.getXYOptions();

  polarDataDemo: IChartSeriesContainer[] = [
    {
      name: "Beacons",
      series: [
        {
          name: "B1",
          x: 0,
          y: 0,
          r: 10
        },
        {
          name: "B2",
          x: 2,
          y: 0,
          r: 10
        },
        {
          name: "B3",
          x: 2,
          y: 2,
          r: 10
        },
        {
          name: "B4",
          x: 0,
          y: 2,
          r: 10
        },
      ]
    },
    {
      name: 'Location',
      series: [
        {
          name: "P",
          x: 1,
          y: 1,
          r: 15
        }
      ]
    }
  ];

  @Input()
  data: IChartDataContainer;

  @Input()
  update: boolean;

  @Input()
  scaleIndex: number;

  @Input()
  options: INgxChartOptions;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  init: boolean = false;

  modalHeight: number;
  modalWidth: number;
  positionLeft: string;
  positionTop: string;

  constructor(
    public uiext: UiExtensionService,
    public settingsProvider: SettingsManagerService,
    @Self() public element: ElementRef,
    public cdr: ChangeDetectorRef
  ) {

    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        let theme: string = SettingsManagerService.settings.app.settings.theme.value;
        this.chartOptions.colorScheme.domain = ThemeColors.theme[theme].chartTheme;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'update':
            this.getScale();
            break;
        }
      }
    }
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
    // this.action.emit(event);
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }

  ngAfterViewInit() {
    this.getDimensions();
    this.cdr.detectChanges();
  }

  /**
   * get component dimensions (from parent reference element) and update the chart view frame
   */
  getDimensions() {
    let container = this.element.nativeElement.parentElement;
    this.modalHeight = container.clientHeight;
    this.modalWidth = container.clientWidth;
    this.chartOptions.view[0] = this.modalWidth;
    this.chartOptions.view[1] = this.modalHeight;
    console.log(this.chartOptions.view);
  }

  ngOnInit() {
    if (this.options) {
      this.chartOptions = this.options;
    }
    if (this.data) {
      this.init = true;
    }
    this.getDimensions();
  }

  getScale() {
    if (this.data != null && this.scaleIndex != null) {
      let xmin: number = null;
      let xmax: number = null;
      let ymin: number = null;
      let ymax: number = null;

      let pseries = this.data.data[this.scaleIndex];
      if (pseries.series.length > 0) {
        for (let p of pseries.series) {
          if (xmin == null || p.x < xmin) {
            xmin = p.x;
          }
          if (ymin == null || p.y < ymin) {
            ymin = p.y;
          }
          if (xmax == null || p.x > xmax) {
            xmax = p.x;
          }
          if (ymax == null || p.y > ymax) {
            ymax = p.y;
          }
        }
        let xdiff = (xmax - xmin) / 4;
        let ydiff = (ymax - ymin) / 4;
        this.chartOptions.xScaleMin = xmin - xdiff;
        this.chartOptions.xScaleMax = xmax + xdiff;
        this.chartOptions.yScaleMin = ymin - ydiff;
        this.chartOptions.yScaleMax = ymax + ydiff;
      }
    }
  }

  ngOnDestroy() {

  }

  @HostListener('window:resize', ['$event'])
  onResize(_event) {
    console.log("resize event");
    this.getDimensions();
    // this.chartOptions.view = [event.target.innerWidth / 2, event.target.innerHeight / 2];
    // console.log(this.chartOptions.view);
  }
}

