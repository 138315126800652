import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { EActivityProgressDisplay, IActivityProviderContainer, EActivityProgressDisplayCategory, IActivityProgressDisplay } from 'src/app/classes/def/core/activity-manager';

@Component({
  selector: 'activity-progress-data',
  templateUrl: './activity-progress-data.component.html',
  styleUrls: ['./activity-progress-data.component.scss'],
})
export class ActivityProgressDataComponent implements OnInit, OnDestroy {

  dispModes = EActivityProgressDisplay;

  @Input()
  activityContainer: IActivityProviderContainer;

  @Input()
  update: BehaviorSubject<boolean>;

  @Input()
  showExtra: boolean;

  @Input()
  flat: boolean;

  @Input()
  active: boolean;

  category = EActivityProgressDisplayCategory;

  constructor(
    public uiext: UiExtensionService
  ) {
  }

  ngOnInit() {
    if (this.activityContainer) {
      console.log("activity container: ", this.activityContainer);
    }
  }

  ngOnDestroy() {

  }

  onSelect(event: any) {
    console.log("event: ", event);
  }

  getCustomCss(e: IActivityProgressDisplay) {
    let css: string = "full-w progress-rounded ";
    if (!e.animate) {
      css += "progress-no-animation ";
    }

    return css;
  }

  getPbClass(e: IActivityProgressDisplay) {
    let css: string = "";
    if (e.highlight) {
      css += ((e.highClass != null) ? e.highClass : "progress-bar-accent");
    } else {
      css += "progress-bar-alternate";
    }
    return css;
  }

  getText(e: IActivityProgressDisplay) {
    let text: string = e.name;
    if (e.dispValue) {
      if (e.mode === EActivityProgressDisplay.sliderLMH) {
        e.dispLevels = [false, false, false];
        let text: string = "LOW";
        if (e.value > 0) {
          e.dispLevels[0] = true;
        }
        if (e.value > 33) {
          text = "MEDIUM";
          e.dispLevels[1] = true;
        }
        if (e.value > 66) {
          text = "HIGH";
          e.dispLevels[2] = true;
        }
        text += ": " + text;
      } else {
        text += ": " + e.dispValue;
      }
    }
    return text;
  }

  getSliderTheme(e: IActivityProgressDisplay, level: number) {
    let c: string = "button-color-primary";
    if (e.value > 66) {
      c = "button-color-accent";
      return c;
    }
    if (e.value > 33 && level <= 1) {
      c = "button-color-alternate";
      return c;
    }
    if (e.value > 0 && level <= 0) {
      c = "button-color-warn";
      return c;
    }
    return c;
  }

}
