<modal-header-container [vs]="vs">
  <leplace-toolbar leftButton="close" [leftIcon]="true" rightButton="" [rightIcon]="false" [title]="title"
    (leftClick)="dismiss()" (rightClick)="nop()" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div class="scrollbar-y places-list theme-border-bottom">
    <ion-list>
      <ion-item-group *ngFor="let country of countriesGen">
        <!-- theme-background -->
        <ion-item-divider sticky class="list-item-divider padding-top-s">
          <!-- <ion-label class="info-text-xs bold text-color-primary">
            {{country.name | uppercase}}
          </ion-label> -->
          <span class="info-text-s bold text-color-primary"> {{country.name | uppercase}}</span>
        </ion-item-divider>
        <!-- <ion-item class="list-item transparent-bg settings-item item-noborder" *ngFor="let city of country.cities"
          #slidingItem tappable (click)="select(city)">
          <span class="info-text-s text-color-primary" *ngIf="city.storyCount != null">{{city.name}}
            ({{city.storyCount}})</span>
          <span class="info-text-s text-color-primary" *ngIf="city.storyCount == null">{{city.name}}</span>
        </ion-item> -->

        <dynamic-grid type="cardItem" [data]="country.list" emptyMessage="No cities" [rowItems]="2" [blanks]="true"
          [update]="updateGrid" (action)="selectGen($event)"></dynamic-grid>

      </ion-item-group>
    </ion-list>
  </div>
</modal-container>