import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IUserStatsOverview } from 'src/app/classes/def/user/stats';
import { EPhotos, EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { ISkillSetResponse, ISkillSet } from 'src/app/classes/def/user/skills';
import { IChartDataContainer, INgxChartOptions, IChartSeries } from 'src/app/classes/def/app/charts';
import { NgxChartParams } from 'src/app/classes/general/params';
import { Platform } from '@ionic/angular';
import { AppSettings } from 'src/app/services/utils/app-settings';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { IUserPublicData } from 'src/app/classes/def/user/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IGenericResponse } from 'src/app/classes/def/requests/general';
import { IPopoverActions, IPopoverInputs } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { IArenaNavParams } from 'src/app/classes/def/nav-params/activity';
import { IInventoryNavParams } from 'src/app/classes/def/nav-params/inventory';
import { IGmapEntryNavParams, EGmapMode } from 'src/app/classes/def/nav-params/gmap';
import { IAccountDetailsNavParams } from 'src/app/classes/def/nav-params/account';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { INavBarItem } from 'src/app/classes/def/views/nav';
import { AuthRequestService } from 'src/app/services/general/auth-request/auth-request';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { UserStatsDataService } from 'src/app/services/data/user-stats';
import { PhotosService } from 'src/app/services/media/photos';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { PhotoViewService } from 'src/app/services/general/apis/photo-view';
import { UserDataService } from 'src/app/services/data/user';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { ERouteDef } from 'src/app/app-utils';
import { MPGroupsChatPage } from '../../mp/mp-groups-chat/mp-groups-chat.page';
import { InventoryHomePage } from '../../inventory/inventory-home/inventory-home.page';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { AccountManagerService } from 'src/app/services/app/modules/account-manager';
import { Router } from '@angular/router';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { EGroupContext } from 'src/app/classes/def/mp/groups';
import { IPhotoResult } from 'src/app/classes/def/media/photo';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-account-home',
  templateUrl: './account-home.page.html',
  styleUrls: ['./account-home.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountHomePage implements OnInit, OnDestroy {

  username: string = "";
  password: string = "";
  confirmPassword: string = "";
  isOnline: boolean = false;
  userStatus: IUserStatsOverview = null;
  levelPercent: number = 0;
  levelMsg: string = "";
  loaded: boolean = false;
  appIconsStandard = EAppIconsStandard;

  photoUrl: string = EPhotos.userPlaceholder;

  skillLabels: string[] = [];

  useGraph: boolean = true;

  devMode: boolean = false;

  skillSetResponse: ISkillSetResponse;

  oldPassword: string = "";
  validationCode: string = "";

  init: boolean = false;

  theme: string = "theme-light theme-light-bg";

  vs: IViewSpecs;
  headerClass: string = "";

  single: any[] = [
    {
      name: "Germany",
      value: 8940000
    },
    {
      name: "USA",
      value: 5000000
    },
    {
      name: "France",
      value: 7200000
    }
  ];

  polarData: IChartDataContainer = {
    data: [
      {
        name: "Germany",
        series: [
          {
            name: "2010",
            value: 7300000
          },
          {
            name: "2011",
            value: 8940000
          }
        ]
      }
    ]
  };

  chartOptions: INgxChartOptions = NgxChartParams.getDefaultOptions();

  appIcons = EAppIcons;

  np: INavParams = null;
  enableBackButton: boolean = true;

  onSelect(event: any) {
    console.log(event);
  }


  constructor(
    public router: Router,
    public authService: AuthRequestService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public userStatsProvider: UserStatsDataService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public photoProvider: PhotosService,
    public settings: SettingsManagerService,
    public backButton: BackButtonService,
    public analytics: AnalyticsService,
    public userProvider: UserDataService,
    public photoViewer: PhotoViewService,
    public accountManager: AccountManagerService,
    public tutorials: TutorialsService,
    public nps: NavParamsService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  ngOnInit() {

    this.analytics.trackView("account");
    this.devMode = AppSettings.testerMode;

    this.username = GeneralCache.resourceCache.user.general.content.name;
    this.getUsername();
    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.account, null, this.np);
      console.log("nav params: ", npInfo.params);

      this.settings.getSettingsLoaded(false).then((res) => {
        if (res) {
          let theme: string = SettingsManagerService.settings.app.settings.theme.value;
          this.theme = ThemeColors.theme[theme].css;
          this.userProvider.getUserPublicProfile(false).then((data: IUserPublicData) => {
            if (data) {
              if (data.photoUrl) {
                this.photoUrl = data.photoUrl;
              }
            }
            this.chartOptions.colorScheme.domain = ThemeColors.theme[theme].chartTheme;
            console.log("photo url: ", this.photoUrl);
          }).catch((err: Error) => {
            console.error(err);
          });
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      this.webView.ready().then(() => {
        this.backButton.replace(() => {
          this.back();
        });
      }).catch((err: Error) => { console.error(err); });

      this.loadData(false);
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  back() {
    this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngOnDestroy() {

  }

  loadData(refreshCache: boolean) {
    this.getAccountStats(refreshCache).then(() => {
      this.createGraph();
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "account");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }

  swipeEvent(_e) {
    // this.backButton.handleSwipeEvent(e);
  }


  getAccountStats(refreshCache: boolean) {
    console.log("get account stats");
    this.loaded = false;
    let promise = new Promise((resolve, reject) => {
      this.userStatsProvider.getUserStatsOverview(refreshCache).then((response: IGenericResponse) => {
        this.userStatus = response.data;
        if (this.userStatus.root.refLevelUp - this.userStatus.root.refStart > 0) {
          this.levelPercent = (this.userStatus.root.score - this.userStatus.root.refStart) / (this.userStatus.root.refLevelUp - this.userStatus.root.refStart) * 100;
        } else {
          this.levelPercent = 0;
        }
        if (this.userStatus.root.score > this.userStatus.root.refLevelUp) {
          this.levelMsg = "Level " + this.userStatus.root.level + " (" + this.userStatus.root.score + " XP)";
        } else {
          this.levelMsg = "Level " + this.userStatus.root.level + " (" + this.userStatus.root.score + "/" + this.userStatus.root.refLevelUp + " XP)";
        }

        this.loaded = true;

        this.userStatsProvider.getSkillsFromStatsOverview(this.userStatus).then((response: ISkillSetResponse) => {
          this.skillSetResponse = response;
          resolve(true);
        }).catch((err: Error) => {
          console.error(err);
          reject(err);
        });
      }).catch((err: Error) => {
        console.error(err);
        reject(err);
      });
    });
    return promise;
  }


  presentPopoverProfilePhoto() {
    let actions: IPopoverActions = {};
    actions = {
      viewPhoto: {
        name: "View photo",
        code: 4,
        icon: EAppIconsStandard.viewPhoto,
        enabled: true
      },
      changePhotoCamera: {
        name: "Take photo",
        code: 5,
        icon: EAppIconsStandard.takePhoto,
        enabled: true
      },
      changePhotoGallery: {
        name: "Choose photo",
        code: 6,
        icon: EAppIconsStandard.pickPhoto,
        enabled: true
      },
      removePhoto: {
        name: "Remove photo",
        code: 7,
        icon: EAppIconsStandard.removePhoto,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, "Profile settings", {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 4:
          this.viewPhoto();
          break;
        case 5:
          this.uploadPhotoFromCamera();
          break;
        case 6:
          this.uploadPhotoFromGallery();
          break;
        case 7:
          this.removePhoto();
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  presentPopoverAccount() {
    let actions: IPopoverActions = {};
    actions = {
      validate: {
        name: "Validate",
        code: 4,
        icon: EAppIconsStandard.validate,
        enabled: true
      },
      changeUsername: {
        name: "Change Username",
        code: 0,
        icon: EAppIconsStandard.edit,
        enabled: true
      },
      changePassword: {
        name: "Change Password",
        code: 1,
        icon: EAppIconsStandard.edit,
        enabled: true
      },
      logOut: {
        name: "Log Out",
        code: 2,
        icon: EAppIconsStandard.exit,
        enabled: true
      },
      deleteAccount: {
        name: "Delete Account",
        code: 3,
        icon: EAppIconsStandard.remove,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, "Account settings", {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 0:
          this.changeUsername();
          break;
        case 1:
          this.changePassword();
          break;
        case 2:
          this.logout();
          break;
        case 3:
          this.deleteAccount();
          break;
        case 4:
          this.validateAccount();
          break;
        default:
          break;

      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      tutorial: {
        name: "Tutorial",
        code: 101,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      },
      account: {
        name: "Account",
        code: 0,
        icon: EAppIconsStandard.account,
        enabled: true
      },
      profilePicture: {
        name: "Profile",
        code: 4,
        icon: EAppIconsStandard.profile,
        enabled: true
      },
      myGroups: {
        name: "Teams",
        code: 8,
        icon: EAppIconsStandard.groups,
        enabled: true
      },
      reload: {
        name: "Refresh",
        code: 7,
        icon: EAppIconsStandard.refresh,
        enabled: true
      }
    };

    if (this.devMode) {
      let actions2: IPopoverActions = {
        // myGroups: {
        //   name: "Teams*",
        //   code: 8,
        //   enabled: true
        // },
        explorer: {
          name: "Explorer*",
          code: 10,
          enabled: true
        }
      };
      Object.assign(actions, actions2);
    }

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: true,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 0:
          this.presentPopoverAccount();
          break;
        case 4:
          this.presentPopoverProfilePhoto();
          break;
        case 6:
          this.goToInventory();
          break;
        case 7:
          this.loadData(true);
          break;
        case 8:
          this.openGroupView();
          break;
        case 10:
          this.goToExplorer();
          break;
        case 101:
          this.onHelpClick();
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  goToExplorer() {
    let navParams: INavParams = {
      view: null,
      params: null
    };
    console.log(navParams);
  }

  openGroupView() {
    let params: IArenaNavParams = {
      place: null,
      group: null,
      groupId: null,
      meetingPlace: null,
      testing: true,
      groupRole: null,
      chatOnly: true,
      playerId: null,
      inRange: true,
      canExit: true,
      enableGroups: true,
      isStoryline: false,
      isLobbyContext: false,
      context: EGroupContext.global,
      contextId: null,
      synchronized: false
    };
    this.uiext.showCustomModal(null, MPGroupsChatPage, {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: true,
        frame: true
      },
      params: params
    }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  viewPhoto() {
    this.viewPhotoCore(null);
  }

  /**
   * shortcut to upload a profile picture
   */
  goToPhotoSelection() {
    console.log("view and select photo");
    this.viewPhotoCore(() => {
      this.uploadPhotoFromCamera();
    });
  }

  viewPhotoCore(callback: () => any) {
    console.log("view and select photo");
    this.userProvider.getUserPublicProfile(false).then((data: IUserPublicData) => {
      if (data && data.photoUrl) {
        this.photoViewer.viewPhoto(data.photoUrl, this.username, {
          share: false,
          customModal: true,
          isDataUrl: false,
          changePhotoCallback: callback
        });
      } else {
        // this.uiext.showAlertNoAction(Messages.msg.photoNotAvailable.after.msg, Messages.msg.photoNotAvailable.after.sub);
        callback();
      }
    }).catch((err: Error) => {
      console.error(err);
      this.uiext.showAlertNoAction(Messages.msg.photoNotAvailable.after.msg, Messages.msg.photoNotAvailable.after.sub);
    });
  }

  goToInventory() {
    let params: IInventoryNavParams = {
      closeOnItemUse: false,
      fixedCategory: false
    };
    this.uiext.showCustomModal(null, InventoryHomePage, {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: false
      },
      params: params
    }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  goToMapEditor() {

    let params: IGmapEntryNavParams = {
      mode: EGmapMode.editor
    };

    let navParams: INavParams = {
      view: null,
      params: params
    };

    this.nps.set(ENavParamsResources.gmap, navParams);

    this.router.navigate([ERouteDef.gmap], { replaceUrl: true }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  uploadPhotoFromCamera() {
    this.photoProvider.uploadPhotoFromCameraWizard((data) => {
      return this.photoProvider.uploadProfilePicture(data);
    }, true, true).then((photoRes: IPhotoResult) => {
      // console.log("uploaded photo: ", photoRes);
      if (photoRes && photoRes.fileURI) {
        this.photoUrl = photoRes.fileURI;
      }
      this.uiext.showToastNoAction(Messages.msg.photoUploaded.after.sub, true);
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "account");
      this.uiext.showAlertNoAction(Messages.msg.returned.after.msg, ErrorMessage.parse(err, Messages.msg.returned.after.sub));
    });
  }

  uploadPhotoFromGallery() {
    this.photoProvider.uploadPhotoFromGalleryWizard((data) => {
      return this.photoProvider.uploadProfilePicture(data);
    }, true).then((photoRes: IPhotoResult) => {
      // console.log("uploaded photo: ", photoRes);
      if (photoRes && photoRes.fileURI) {
        this.photoUrl = photoRes.fileURI;
      }
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "account");
      this.uiext.showAlertNoAction(Messages.msg.returned.after.msg, ErrorMessage.parse(err, Messages.msg.returned.after.sub));
    });
  }

  removePhoto() {
    this.photoProvider.removeProfilePicture().then(() => {
      this.uiext.showAlertNoAction(Messages.msg.removePhoto.after.msg, Messages.msg.removePhoto.after.sub);
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "account");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }

  getUsername() {
    this.authService.checkLoggedInStorage().then(() => {
      this.isOnline = true;
      this.authService.getUsername().then((username: string) => {
        if (username) {
          this.username = username;
        }
      }).catch((err: Error) => {
        console.error(err);
        this.isOnline = false;
      });
    }).catch((err: Error) => {
      console.error(err);
      this.isOnline = false;
    });
  }

  selectSkillTree(skill) {
    console.log(skill);
    this.goToAccountDetails(skill, skill);
  }

  getNameFromLabel(skills: ISkillSet, label: string) {
    for (let i = 0; i < skills.tree.length; i++) {
      if (skills.tree[i].label === label) {
        return skills.tree[i].categoryCode;
      }
    }
  }

  /**
   * create graph and only show categories that have the disp flag 1
   */
  createGraph() {
    let skills: ISkillSet = this.skillSetResponse.skillSet;
    console.log(skills);
    // this.chartOptions.yAxisMinScale = skills.root.max;
    this.polarData.data = [
      {
        name: "Progress",
        series: [

        ]
      }
    ];

    this.polarData.data[0].series = skills.tree.filter(skill => skill.plot).map((e) => {
      let s: IChartSeries = {
        name: e.label,
        value: e.level
      };
      return s;
    });

    console.log(this.polarData);
    this.init = true;
  }

  goToAccountDetails(type: number, label: string) {
    let params: IAccountDetailsNavParams = {
      categoryTabs: this.getCategoryTabs(),
      categoryCode: type,
      label: label
    };

    let navParams: INavParams = {
      view: null,
      params: params
    };

    this.nps.set(ENavParamsResources.accountDetails, navParams);

    this.router.navigate([ERouteDef.accountDetails], { replaceUrl: true, queryParams: {} }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  onHelpClick() {
    this.tutorials.showTutorialNoAction("Account Tutorial", ETutorialEntries.accountTutorial, null, null, true);
  }


  showOverview() {
    this.goToAccountDetails(GeneralCache.metricsSelectedCategory, "general");
  }


  getCategoryTabs() {
    let categoryTabs: INavBarItem[] = [];
    if (!this.skillSetResponse) {
      return categoryTabs;
    }
    let skills: ISkillSet = this.skillSetResponse.skillSet;
    if (!skills) {
      return categoryTabs;
    }

    categoryTabs.push({
      name: "general",
      value: -1
    });

    for (let i = 0; i < skills.tree.length; i++) {
      let e = skills.tree[i];
      categoryTabs.push({
        name: e.label,
        value: e.categoryCode
      });
    }
    return categoryTabs;
  }

  showCategories() {
    let actions: IPopoverActions = {};
    if (!this.skillSetResponse) {
      return;
    }
    let skills: ISkillSet = this.skillSetResponse.skillSet;
    if (!skills) {
      return;
    }
    for (let i = 0; i < skills.tree.length; i++) {
      let e = skills.tree[i];
      actions[i.toString()] = {
        name: e.label,
        code: e.categoryCode,
        enabled: true
      };
    }

    // actions[skills.tree.length.toString()] = {
    //   //name: skills.root.label,
    //   name: "general",
    //   code: skills.root.categoryCode,
    //   enabled: true
    // };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, "Stats", {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result: number) => {
      let actk = Object.keys(actions);

      for (let i = 0; i < actk.length; i++) {
        if (actions[actk[i]].code === result) {
          this.goToAccountDetails(result, actions[actk[i]].name);
          break;
        }
      }
    }).catch((err: Error) => {
      console.error(err);

    });
  }


  changeUsername() {
    let popoverInputs: IPopoverInputs = {
      username: {
        // the name is the actual tag here
        name: "username",
        value: this.username,
        placeholder: "username",
        enabled: true
      }
    };
    let popoverActions: IPopoverActions = {
      cancel: {
        name: "Cancel",
        code: 0,
        enabled: true
      },
      ok: {
        name: "Ok",
        code: 1,
        enabled: true
      }
    };

    this.uiext.showCustomAlertInput("Change username", null, popoverInputs, popoverActions).then((res: any) => {
      let code = res.code;
      let data = res.data;
      switch (code) {
        case 0:
          break;
        case 1:
          // console.log(res.data.username);
          this.authService.changeUsernameServer(data.username).then((response: IGenericResponse) => {
            this.username = response.data.username;
            this.authService.saveUsername(this.username);
          }).catch((err: Error) => {
            console.error(err);
            this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
          });
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }



  changePassword() {
    let popoverInputs: IPopoverInputs = {
      oldPassword: {
        // the name is the actual tag here
        name: "oldPassword",
        value: this.oldPassword,
        placeholder: "current password",
        type: "password",
        enabled: true
      },
      password: {
        name: "password",
        value: this.password,
        placeholder: "new password",
        type: "password",
        enabled: true
      },
      confirmPassword: {
        name: "confirmPassword",
        value: this.confirmPassword,
        placeholder: "confirm password",
        type: "password",
        enabled: true
      }
    };
    let popoverActions: IPopoverActions = {
      cancel: {
        name: "Cancel",
        code: 0,
        enabled: true
      },
      ok: {
        name: "Ok",
        code: 1,
        enabled: true
      }
    };

    this.uiext.showCustomAlertInput("Change password", null, popoverInputs, popoverActions).then((res: any) => {
      let code = res.code;
      let data = res.data;
      switch (code) {
        case 0:
          break;
        case 1:
          if (data.password === data.confirmPassword) {
            this.authService.changePasswordServer(data.oldPassword, data.password).then((_response: IGenericResponse) => {
              this.password = "";
              this.confirmPassword = "";
              this.uiext.showAlertNoAction(Messages.msg.passwordChanged.after.msg, Messages.msg.passwordChanged.after.sub);
            }).catch((err: Error) => {
              console.error(err);
              this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
              this.password = "";
              this.confirmPassword = "";
            });
          } else {
            this.uiext.showAlertNoAction(Messages.msg.passwordsDoNotMatch.after.msg, Messages.msg.passwordsDoNotMatch.after.sub);
            this.password = "";
            this.confirmPassword = "";
          }
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  validateAccount() {
    let popoverInputs: IPopoverInputs = {
      code: {
        // the name is the actual tag here
        name: "code",
        value: this.validationCode,
        placeholder: "validation code",
        enabled: true
      }
    };
    let popoverActions: IPopoverActions = {
      cancel: {
        name: "Cancel",
        code: 0,
        enabled: true
      },
      ok: {
        name: "Ok",
        code: 1,
        enabled: true
      }
    };

    this.uiext.showCustomAlertInput("Validate account", null, popoverInputs, popoverActions).then((res: any) => {
      let code = res.code;
      let data = res.data;
      switch (code) {
        case 0:
          break;
        case 1:
          this.authService.validateAccount(data.code).then((_response: IGenericResponse) => {
            this.validationCode = "";
            this.uiext.showAlertNoAction(Messages.msg.accountValidated.after.msg, Messages.msg.accountValidated.after.sub);
          }).catch((err: Error) => {
            console.error(err);
            this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
            this.validationCode = "";
          });
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }



  deleteAccount() {
    let popoverInputs: IPopoverInputs = {
      password: {
        // the name is the actual tag here
        name: 'password',
        value: this.password,
        placeholder: 'password',
        type: "password",
        enabled: true
      }
    };

    let popoverActions: IPopoverActions = {
      cancel: {
        name: "Cancel",
        code: 0,
        enabled: true
      },
      ok: {
        name: "Ok",
        code: 1,
        enabled: true
      }
    };

    this.uiext.showCustomAlertInput("Delete account", "Leave password empty if logged in via external provider", popoverInputs, popoverActions).then((res: any) => {
      let code = res.code;
      let data = res.data;
      switch (code) {
        case 0:
          break;
        case 1:
          this.authService.deleteAccountServer(data.password).then((_response: IGenericResponse) => {
            this.authService.removeLoginData();
            this.uiext.showAlertNoAction(Messages.msg.accountDeleted.after.msg, Messages.msg.accountDeleted.after.sub);
          }).catch((err: Error) => {
            console.error(err);
            this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
          });
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  logout() {
    this.accountManager.logoutPromptNoAction();
  }
}
