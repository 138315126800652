import { Injectable } from "@angular/core";
import { Platform } from '@ionic/angular';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { BehaviorSubject, Observable, Subscription, timer } from 'rxjs';
import { BackgroundModeService } from './background-mode';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { SleepUtils } from '../../utils/sleep-utils';
import { UiExtensionService } from '../ui/ui-extension';
import { TimeUtils } from "src/app/classes/general/time";
import { SettingsManagerService } from "../settings-manager";
import { IPlatformFlags } from "src/app/classes/def/app/platform";
import { WebviewUtilsService } from "../../app/utils/webview-utils";


export enum EBGMWatchId {
    socialAuth = "social_auth",
    checkApp = "check_app",
    viewCheck = "view_check"
}

/**
 * watch without external service calls
 */
@Injectable({
    providedIn: 'root'
})
export class BackgroundModeWatchService {
    observable: BehaviorSubject<boolean>;
    observableEvent: BehaviorSubject<boolean>;

    backgroundModeActivatedTs: number = null;

    bgModeWatchSub: { [key: string]: Subscription } = {};
    bgModeWatchTimeout: { [key: string]: any } = {};
    timerSub: Subscription;

    foregroundWebObservable: BehaviorSubject<boolean>;

    platform: IPlatformFlags;

    constructor(
        public bgmService: BackgroundModeService,
        public uiext: UiExtensionService,
        public plt: Platform,
        public webView: WebviewUtilsService,
        public settings: SettingsManagerService
    ) {
        console.log("background mode watch service created");
        this.observable = new BehaviorSubject(null);
        this.observableEvent = new BehaviorSubject(null);
        this.foregroundWebObservable = new BehaviorSubject(null);

        this.webView.ready().then(() => {
            this.initSubscriber();
            this.bgWatch();
        });

        this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
                if (this.platform.WEB) {
                    this.checkForegroundWeb();
                }
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    getBackgroundWatch() {
        return this.observable;
    }

    getBackgroundWatchTimer() {
        return this.observableEvent;
    }

    bgWatch() {
        let timer1: Observable<number> = timer(0, 500);
        this.timerSub = timer1.subscribe(() => {
            // console.log("bgmWatch: tick");
            if (GeneralCache.paused && this.bgmService.isBgModeRunning()) {
                let timeCrt: number = new Date().getTime();
                let elapsed: number = timeCrt - this.backgroundModeActivatedTs;
                elapsed = Math.floor(elapsed / 1000);
                console.log("bgWatch: " + TimeUtils.formatTimeFromSeconds(elapsed, true));
                this.observableEvent.next(true);
            }
        }, (err) => {
            console.error(err);
        });
    }

    checkForegroundWeb() {
        console.log("check foreground web");
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                console.log("browser visibility change / foreground");
                this.foregroundWebObservable.next(true);
            } else {
                console.log("browser visibility change / background");
                this.foregroundWebObservable.next(false);
            }
        });
    }

    getForegroundWebWatch() {
        return this.foregroundWebObservable;
    }

    initSubscriber() {
        this.plt.pause.subscribe(() => {
            console.log("app paused");
            GeneralCache.paused = true;
            GeneralCache.appResumedFlag = true;
            this.backgroundModeActivatedTs = new Date().getTime();
            GeneralCache.pausedTimestamp = this.backgroundModeActivatedTs;
            // enable low power mode
            // disable gps, etc
            // this.notifications.clear();
            this.observable.next(true);
            this.observable.next(null);
        }, (err: Error) => {
            console.error(err);
        });

        this.plt.resume.subscribe(() => {
            console.log("app resumed");
            GeneralCache.paused = false;
            this.observable.next(false);
            this.observable.next(null);
        }, (err: Error) => {
            console.error(err);
        });
    }


    /**
     * init timeout
     * triggerOnResumed: init when app is switched to background, trigger (start timeout) when app is resumed
     * @param id
     * @param withLoadingMessage
     * @param onTimeout
     * @param timeoutMs
     */
    async initBgmWatchTriggerOnResumed(id: string, withLoadingMessage: string, onTimeout: () => any, timeoutMs: number) {
        await this.clearBgmWatchTrigger(id, withLoadingMessage != null);

        let engaged: boolean = false;

        let triggerFn = async () => {
            if (withLoadingMessage) {
                await SleepUtils.sleep(200);
                await this.uiext.dismissLoadingV2();
                await this.uiext.showLoadingV2Queue(withLoadingMessage);
            }
            console.log("watch bgm status triggered");
            this.bgModeWatchTimeout[id] = ResourceManager.clearTimeout(this.bgModeWatchTimeout[id]);
            this.bgModeWatchTimeout[id] = setTimeout(() => {
                console.log("watch bgm status timeout");
                onTimeout();
            }, timeoutMs);
        };

        this.bgModeWatchSub[id] = this.getBackgroundWatch().subscribe((paused: boolean) => {
            if (paused != null) {
                if (paused) {
                    console.log("watch bgm status engaged");
                    engaged = true;
                } else {
                    if (engaged) {
                        triggerFn();
                    }
                }
            }
        }, (err: Error) => {
            console.error(err);
        });
    }


    /**
    * init timeout
    * triggerOnResumed: init default and trigger (start timeout) when app is switched to background
    * @param id
    * @param withLoadingMessage
    * @param onTimeout
    * @param timeoutMs
    */
    async initBgmWatchTriggerAndClearOnPaused(id: string, withLoadingMessage: string, onTimeout: () => any, timeoutMs: number) {

        await this.clearBgmWatchTrigger(id, withLoadingMessage != null);

        let triggerFn = async () => {
            if (withLoadingMessage) {
                await SleepUtils.sleep(200);
                await this.uiext.dismissLoadingV2();
                await this.uiext.showLoadingV2Queue(withLoadingMessage);
            }
            console.log("watch bgm status triggered");
            this.bgModeWatchTimeout[id] = ResourceManager.clearTimeout(this.bgModeWatchTimeout[id]);
            this.bgModeWatchTimeout[id] = setTimeout(() => {
                console.log("watch bgm status timeout");
                onTimeout();
            }, timeoutMs);
        };

        await triggerFn();

        this.bgModeWatchSub[id] = this.getBackgroundWatch().subscribe((paused: boolean) => {
            if (paused != null) {
                if (paused) {
                    this.clearBgmWatchTrigger(id, withLoadingMessage != null);
                } else {

                }
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    waitDefaultBgmWatch(state: boolean): Promise<boolean> {
        return new Promise((resolve) => {
            this.observable.next(null);
            this.bgModeWatchSub[EBGMWatchId.viewCheck] = this.getBackgroundWatch().subscribe((paused: boolean) => {
                if (paused === state) {
                    resolve(true);
                }
            }, (err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
    }

    clearDefaultBgmWatch() {
        this.bgModeWatchSub[EBGMWatchId.viewCheck] = ResourceManager.clearSub(this.bgModeWatchSub[EBGMWatchId.viewCheck]);
    }


    /**
     * resolve, clear timeout
     */
    clearBgmWatchTrigger(id: string, withLoading: boolean): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            this.bgModeWatchSub[id] = ResourceManager.clearSub(this.bgModeWatchSub[id]);
            this.bgModeWatchTimeout[id] = ResourceManager.clearTimeout(this.bgModeWatchTimeout[id]);
            if (withLoading) {
                await SleepUtils.sleep(500);
                await this.uiext.dismissLoadingV2();
            }
            resolve(true);
        });
    }
}
