import { Component, OnInit, Input, ElementRef, Renderer2, OnDestroy } from '@angular/core';
import { IHudElement } from 'src/app/classes/def/app/hud';
import { DomController, Platform } from '@ionic/angular';

@Component({
  selector: 'leplace-hud',
  templateUrl: './leplace-hud.component.html',
  styleUrls: ['./leplace-hud.component.scss'],
})
export class LeplaceHudComponent implements OnInit, OnDestroy {
  @Input()
  data: IHudElement[];
  @Input()
  expander: boolean;

  handleHeight: number = 50;
  bounceBack: boolean = true;
  thresholdTop: number = 200;
  thresholdBottom: number = 200;
  expand: boolean = true;


  constructor(public element: ElementRef, public renderer: Renderer2, public domCtrl: DomController, public platform: Platform) {

  }

  ngOnInit() {
    if (this.expander) {

    }
  }

  ngOnDestroy() {

  }

  checkShow(elem: IHudElement) {
    return elem.show && (elem.value != null) && (elem.value != "");
  }


  toggleExpand(expand: boolean) {
    if (expand !== this.expand) {
      console.log("toggle expand");
      this.expand = expand;
    }
  }

  checkShowElem(i: number) {
    return this.expand || (i < 3);
  }

  swipeEvent(e) {
    if (e) {
      switch (e.direction) {
        case 2:
          // right to left

          break;
        case 4:
          // left to right

          break;
      }
    }
  }

  swipeUp() {
    this.toggleExpand(true);
  }


  swipeDown() {
    this.toggleExpand(false);
  }


}
