<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="false" leftButton="arrow-back" [leftIcon]="true" [title]="title"
    (leftClick)="dismiss()" rightButton="settings" [rightIcon]="true" (rightClick)="showOptions()" [rounded]="false"
    [transparent]="true">
  </leplace-toolbar>
</modal-header-container>

<!-- don't use modal container because the content cannot be referenced anymore (needed for auto scroll) -->

<!-- <ion-content class="custom-modal-style full-height"> -->
<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div class="main">

    <div class="chat-box" [ngStyle]="{'bottom':footerHeightPx}">

      <!-- <div class="list-area">
        <dynamic-slides [slideContent]="nearbyPlayersSlides" type="mpStatusCard" slideClass="slide-class"
          [trackByFn]="trackByFn"></dynamic-slides>
      </div> -->

      <div class="theme-border-bottom" *ngIf="showChatHeads">
        <chat-heads-slider [chatheads]="nearbyPlayers" [update]="statusUpdate"></chat-heads-slider>
      </div>

      <div class="message-area" [ngClass]="showChatHeads ? 'message-area-with-chatheads' : 'message-area-standard'"
        [ngStyle]="{'padding-top': messagePadPx}" #chat_content (scroll)="onUserScoll($event)">
        <div class="message" *ngFor="let item of chatRx" class="full-w">
          <!-- <p>{{item.text}}</p> -->
          <div *ngIf="!item.self">
            <ion-row class="padding-horizontal-s" *ngIf="item.nextUser">
              <ion-col size="6" class="ion-float-left ion-text-left">
                <span class="info-text-m bold text-color-primary">{{item.user}}</span>
              </ion-col>
              <ion-col size="6" class="ion-float-right ion-text-right">
                <span class="info-text-xs bold text-color-primary">{{item.timestamp}}</span>
              </ion-col>
            </ion-row>
            <ion-row [ngClass]="item.nextUser?'padding-xs':''">
              <ion-col size="12" class="ion-float-left ion-text-left">
                <div class="speech-bubble-point-left">
                  <span class="info-text-m padding-left-s text-color-primary-chat dispblock"
                    [innerHTML]="item.message"></span>
                </div>
              </ion-col>
            </ion-row>
          </div>
          <div *ngIf="item.self">
            <ion-row class="padding-horizontal-s" *ngIf="item.nextUser">
              <ion-col size="6" class="ion-float-left ion-text-left">
                <span class="info-text-xs bold text-color-primary">{{item.timestamp}}</span>
              </ion-col>
              <ion-col size="6" class="ion-float-right ion-text-right">
                <span class="info-text-m bold text-color-primary">{{item.user}}</span>
              </ion-col>
            </ion-row>
            <ion-row [ngClass]="item.nextUser?'padding-xs':''">
              <ion-col size="12" class="ion-float-right ion-text-right">
                <div class="speech-bubble-point-right">
                  <span class="info-text-m text-color-primary-chat-low padding-right-s dispblock"
                    [innerHTML]="item.message"></span>
                </div>
              </ion-col>
            </ion-row>
          </div>
        </div>
        <div class="entry-height" fxLayout="column">
          <span *ngIf="chatTx && !infoMessage.typing.message" class="info-text-s text-color-primary">{{ maxLength -
            chatTx.length }}
            characters left</span>
          <span class="info-text-s text-color-primary"
            *ngIf="infoMessage.connected.message">{{infoMessage.connected.message}}</span>
          <span class="info-text-s text-color-primary"
            *ngIf="!infoMessage.connected.message">{{infoMessage.typing.message}}</span>
        </div>
      </div>

      <div class="emo-area">
        <!-- emoji-panel component comes here -->
      </div>

      <div class="input-area" [ngClass]="inputClass">
        <form (submit)="sendMsg()" name="messageForm" #messageForm="ngForm">
          <ion-input type="text" class="text-color-primary info-text-m" (ionBlur)="onBlur()" (keyup.enter)="sendMsg()"
            (ionFocus)="onFocus()" name="message" id="message" [maxlength]="maxLength" [(ngModel)]="chatTx"
            [placeholder]="writeMessage" (ngModelChange)="typing()">
          </ion-input>
          <!-- <button>
            <ion-icon name="send"></ion-icon>
          </button> -->
          <ion-button item-right (click)="sendMsg()" class="button-color-transparent text-color-primary">
            <icon-wrapper slot="icon-only" icon="send"></icon-wrapper>
          </ion-button>
        </form>
      </div>
    </div>
  </div>

</modal-container>