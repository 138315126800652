
import { IStatDef, IGameStats, EStatCodes, ISpecificStat } from "../../../classes/def/user/stats";
import { IStory } from "../../../classes/def/core/story";
import { IActivity, IActivityStatsContainer } from "../../../classes/def/core/activity";
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IBackendLocation } from "src/app/classes/def/places/backend-location";

export class GameStatsUtils {

    /**
    * get game stat def as object
    * @param stats 
    */
    static getGameStatDef(stats: IStatDef[]) {
        let gameStats: IGameStats = {} as IGameStats;
        let statkeys = Object.keys(EStatCodes);
        for (let i = 0; i < stats.length; i++) {
            for (let j = 0; j < statkeys.length; j++) {
                if (stats[i].code === EStatCodes[statkeys[j]]) {
                    gameStats[statkeys[j]] = stats[i];
                    break;
                }
            }
        }
        return gameStats;
    }

    /**
     * e.g. placeVisited -> placeVisited/level 3
     * replace default weight with specific weight
     * @param stats 
     * @param genericCode 
     */
    static getGradedStat(genericCode: number, level: number): IStatDef {
        let stats: IStatDef[] = GeneralCache.resourceCache.general.statScores.content;
        if (!stats) {
            return null;
        }
        let gs: IStatDef;
        let gsFound: boolean = false;

        for (let i = 0; i < stats.length; i++) {
            let s: IStatDef = stats[i];
            if (s.code === genericCode) {
                // check specific stats
                if (s.statsLevelActivities) {
                    for (let j = 0; j < s.statsLevelActivities.length; j++) {
                        let sla: ISpecificStat = s.statsLevelActivities[j];
                        // level can also be null
                        if (sla.levelCode === level) {
                            gsFound = true;
                            gs = Object.assign({}, s);
                            // assign spec weight
                            gs.weight = sla.weight;
                            break;
                        }
                    }
                }
            }
        }

        if (!gsFound) {
            // fallback to generic stat
            gs = Object.assign({}, stats.find(s => s.code === genericCode));
        }

        gs.description = gs.singleName;

        // console.log("get graded stat: ", gs);
        // console.log("stats: ", stats);
        return gs;
    }

    /**
    * e.g. placeVisited -> placeVisited/level 3
    * replace default weight
    * @param applyScaleFactor if specified xpScaleFactor
    */
    static getGradedStatAdjusted(genericCode: number, level: number, overrideXP: number, addedXP: number, xpScaleFactor: number, applyScaleFactor: boolean): IStatDef {
        let stat: IStatDef = GameStatsUtils.getGradedStat(genericCode, level);

        if (overrideXP != null) {
            stat.weight = overrideXP;
        }

        if (addedXP != null) {
            stat.weight += addedXP;
        }

        if (xpScaleFactor != null) {
            if (applyScaleFactor) {
                stat.weight *= xpScaleFactor;
                stat.weight = Math.floor(stat.weight);
            }
            stat.description += " (scaled)";
        }

        return stat;
    }

    static getGradedStatWeight(genericCode: number, level: number): number {
        // console.log("get graded stat weight: " + genericCode + ", " + level);
        let stat: IStatDef = GameStatsUtils.getGradedStat(genericCode, level);
        if (!stat) {
            return 0;
        }
        return stat.weight;
    }

    /**
     * 
     * @param applyScaleFactor if specified xpScaleFactor
     * @returns 
     */
    static getGradedStatWeightAdjusted(genericCode: number, level: number, overrideXP: number, addedXP: number, xpScaleFactor: number, applyScaleFactor: boolean): number {
        // console.log("get graded stat weight: " + genericCode + ", " + level);
        let stat: IStatDef = GameStatsUtils.getGradedStatAdjusted(genericCode, level, overrideXP, addedXP, xpScaleFactor, applyScaleFactor);
        if (!stat) {
            return 0;
        }
        return stat.weight;
    }

    static getStoryFinishedWeightAdjusted(story: IStory): number {
        // console.log("get story finished weight: ", story);
        if (!story) {
            return 0;
        }
        let stat: IStatDef = GameStatsUtils.getGradedStat(EStatCodes.storiesFinished, story.level);
        if (!stat) {
            return 0;
        }

        let totalXp: number = stat.weight;

        if (story.rewardXp != null) {
            totalXp = story.rewardXp;
        }

        if (!totalXp) {
            return 0;
        }

        if (story.xpScaleFactor != null) {
            totalXp *= story.xpScaleFactor;
        }

        return totalXp;
    }

    static getStoryRewardDisp(story: IStory): string {
        let rewardDisp: string = "";
        let potentialXP: number = GameStatsUtils.getStoryFinishedWeightAdjusted(story);
        if (potentialXP > 0) {
            rewardDisp = potentialXP + " XP";
            if ((story.rewardCoins != null) && (story.rewardCoins > 0)) {
                rewardDisp += " / " + story.rewardCoins + " LP";
            }
        } else {
            if ((story.rewardCoins != null) && (story.rewardCoins > 0)) {
                rewardDisp = story.rewardCoins + " LP";
            }
        }
        return rewardDisp;
    }

    static getActivityFinishedWeight(activity: IActivity): number {
        // console.log("get activity finished weight: ", activity);
        if (!activity) {
            return 0;
        }
        let stat: IStatDef = GameStatsUtils.getGradedStat(EStatCodes.challengesCompleted, activity.level);
        if (!stat) {
            return 0;
        }
        return stat.weight;
    }

    static getActivityFinishedWeightAdjusted(activity: IActivity, loc: IBackendLocation, activityStats: IActivityStatsContainer, xpScaleFactor: number) {

        let totalXP: number = GameStatsUtils.getActivityFinishedWeight(activity);
        let isDefault: boolean = true;

        console.log("xp (default) ", totalXP);

        if ((loc != null) && (loc.rewardXp != null)) {
            totalXP = loc.rewardXp; // appLocation.loc.merged.rewardXp
            isDefault = false;
        }

        console.log("xp (reward) ", totalXP);

        if ((activityStats != null) && (activityStats.stats.computed != null)) {
            if (activityStats.stats.computed.defaultScore != null) {
                console.log("xp (reward) via server (for reference) ", activityStats.stats.computed.defaultScore);
                if (isDefault) {
                    totalXP = activityStats.stats.computed.defaultScore;
                }
            }
            if (activityStats.stats.computed.specScore != null) {
                totalXP += activityStats.stats.computed.specScore;
            }
        }

        console.log("xp (added stats) ", totalXP);

        if (xpScaleFactor != null) {
            totalXP *= xpScaleFactor;
            totalXP = Math.floor(totalXP);
        }

        console.log("xp (scaled) ", totalXP);

        return totalXP;

    }

}
