<div *ngIf="story" class="flex-card story-card-small bg-container bg-container-fill item-border-radius bg-hidden"
    [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: story.photoUrl}">
    <!-- bg-filter-2 -->
    <div class="content padding-s">

        <div *ngIf="isLoading">
            <div class="progress-spinner-center">
                <mat-progress-spinner [diameter]="40" color="primary" mode="indeterminate">
                </mat-progress-spinner>
            </div>
        </div>

        <!-- <ion-grid *ngIf="!isLoading" class="full-w grid-center-absolute text-div-container">
            <div class="text-color-overlay-s">
                <ion-row>
                    <ion-col size="12">
                        <span class="info-text-m bold text-color-element">{{story.name}}</span>
                    </ion-col>
                </ion-row>
            </div>
        </ion-grid> -->

        <div *ngIf="!isLoading">
            <ion-row>
                <ion-col size="12" class="text-div-container">
                    <div class="text-color-overlay-s">
                        <span class="info-text-m bold text-color-element">{{story.name}}</span>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row class="padding-top-s center-items">
                <ion-col size="4">
                    <div class="ion-float-left text-color-overlay-s" *ngIf="paidStory && price">
                        <span *ngIf="!pricePromo" class="info-text-xs text-color-element">{{price}}</span>
                        <span *ngIf="pricePromo"
                            class="info-text-xs text-color-element text-strikethrough">{{price}}</span>
                        <span *ngIf="pricePromo"
                            class="info-text-xs text-color-element padding-horizontal-s">{{pricePromo}}</span>
                    </div>
                </ion-col>
                <ion-col size="4" class="text-div-container">
                    <div class="ion-float-left text-color-overlay-s">
                        <span class="info-text-xs text-color-element">{{levelDisp}}</span>
                    </div>
                </ion-col>
                <ion-col size="4">
                    <div *ngIf="locked">
                        <div class="ion-float-right leplace-icon icon-color-primary">
                            <icon-wrapper [icon]="appIconsStandard.locked"></icon-wrapper>
                        </div>
                    </div>
                    <div *ngIf="(paidStory || qrEnabled) && !locked">
                        <div class="ion-float-right leplace-icon icon-color-accent">
                            <icon-wrapper [icon]="appIconsStandard.unlocked"></icon-wrapper>
                        </div>
                    </div>
                </ion-col>
            </ion-row>
            <ion-row *ngIf="providerSpec" class="padding-top-s">
                <ion-col size="12" class="text-div-container">
                    <div class="text-color-overlay-s">
                        <span class="info-text-xs margin-top-s bold text-color-element">{{providerSpec}}</span>
                    </div>
                </ion-col>
            </ion-row>
        </div>

    </div>
</div>