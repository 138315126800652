<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="!isModal" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back(false)" [title]="title"
    [loading]="!loaded" [rounded]="true"></leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <!-- (swipe)="swipeEvent($event)" -->
  <div class="view-frame theme-background list-padding-top" fxLayout="column">

    <nav-bar *ngIf="multipleCategories" [slidesPerView]="categoryTabsGroupCount"
      [(selectedTabId)]="selectedCategoryMode" [loadData]="loaded" [navBarItems]="navBarItems"
      (select)="selectCategoryFromNavItem($event)">
    </nav-bar>

    <!-- border-radius -->
    <!-- (swipe)="swipeEvent($event)" -->

    <div [ngClass]="multipleCategories?'scrollbar-y categories-height theme-border-top':''">
      <ion-list [@showState]="showState">
        <ion-item *ngFor="let category of categoriesDisp" #slidingItem
          class="list-item list-item-card-padding transparent-bg">
          <category-info class="full-w" [data]="category" (select)="goToStoryList(category)"></category-info>
        </ion-item>
      </ion-list>
    </div>

    <!-- <div [ngClass]="multipleCategories?'scrollbar-y categories-height theme-border-top':''">
      <div class="list-padding" [@showState]="showState">
        <div *ngFor="let category of categoriesDisp" class="list-item padding-s transparent-bg">
          <category-info class="full-w" [data]="category" tappable (click)="goToStoryList(category)"></category-info>
        </div>
      </div>
    </div> -->

  </div>
</ion-content>