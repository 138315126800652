export enum EARShowOptions {
    exitAR = -1,
    gyroAlignReset = 2,
    showTutorial = 3,
    filterLayers = 4,
    selectObject = 5,
    geoSync = 6,
    gyroAlign = 7,
    triggerExpireActivity = 8,
    treasureScan = 9,
    toggleHud = 10,
    resizeCanvas = 11,
    startRecording = 12,
    stopRecording = 13,
    refreshViewObjects = 14,
    showARStats = 15,
    toggleHudXp = 16,
    showCompassAdjustInput = 17,
    toggleDebugHud = 18
}