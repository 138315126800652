import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChange, SimpleChanges, OnDestroy } from '@angular/core';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { StringUtils } from 'src/app/services/app/utils/string-utils';
import { TextToSpeechService } from 'src/app/services/general/apis/tts';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { SleepUtils } from 'src/app/services/utils/sleep-utils';

export enum EAudioGuideStatus {
  started = 1,
  finished = 2
}

@Component({
  selector: 'audio-guide',
  templateUrl: './audio-guide.component.html',
  styleUrls: ['./audio-guide.component.scss'],
})
export class AudioGuideComponent implements OnInit, OnChanges, OnDestroy {

  subscription = {
    audioEvent: null
  };

  appIconsStandard = EAppIconsStandard;
  loaded: boolean = false;

  useCloudTTS: boolean = true;

  progress: number = 0;

  started: boolean = false;

  @Input()
  label: string;

  @Input()
  content: string;

  @Input()
  autoplay: boolean;

  @Input()
  contentHTML: string;

  @Output()
  status: EventEmitter<number> = new EventEmitter();

  constructor(
    public uiext: UiExtensionService,
    public tts: TextToSpeechService
  ) { }


  ngOnInit(): void {
    if (this.contentHTML != null) {
      this.content = StringUtils.textFromHTML(this.contentHTML);
    }
    this.loaded = true;
    if (this.autoplay) {
      this.start();
    }
  }

  async start() {
    try {
      this.progress = 0;
      console.log("start tts: ", this.content);
      this.started = true;
      this.status.next(EAudioGuideStatus.started);
      if (this.content == null || this.content == "") {
        this.status.next(EAudioGuideStatus.finished);
        console.warn("audio guide > no content");
        return;
      }
      if (this.tts.speechInProgress) {
        console.warn("audio guide > already started");
        this.tts.stop(this.useCloudTTS);
        await SleepUtils.sleep(100);
      }
      this.tts.textToSpeechQueue(this.content, false, 1, () => {
        console.log("tts queue finished");
        this.started = false;
        this.status.next(EAudioGuideStatus.finished);
        this.progress = 100;
      }, 120000, true);
    } catch (err) {
      console.error(err);
    }
  }

  stop() {
    this.tts.stop(this.useCloudTTS);
    this.started = false;
    this.progress = 0;
  }


  ngOnDestroy(): void {
    if (this.started) {
      this.stop();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'content':
            // this.reviewChange();
            break;
          case 'autoplay':
            if (this.autoplay) {
              this.start();
            }
            break;
        }
      }
    }
  }

}
