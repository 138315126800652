

import { Injectable } from "@angular/core";

import { GeometryUtils } from '../utils/geometry-utils';
import { AppConstants } from 'src/app/classes/app/constants';
import { ILatLng } from "src/app/classes/def/map/coords";


export interface IMapEngineFlags {
    returnLocationFromBuffer: boolean;
    previousSearchUserLocation: ILatLng;
}


@Injectable({
    providedIn: 'root'
})
export class MapEngineUtilsService {
    engineFlagsInit: IMapEngineFlags = {
        returnLocationFromBuffer: false,
        previousSearchUserLocation: null
    };

    mapEngineFlags: IMapEngineFlags = {} as any;

    constructor(

    ) {
        console.log("map engine utils service created");
    }

    /**
     * init only once per map open
     * map handles flags too
     */
    initMapEngine() {
        this.mapEngineFlags = Object.assign(this.mapEngineFlags, this.engineFlagsInit);
    }

    /**
     * get map engine flags to sync with map
     */
    getMapEngineFlags() {
        return this.mapEngineFlags;
    }

    /**
     * check scan distance for story locations buffering
     * @param currentLocation 
     */
    checkLocationScanDistance(currentLocation: ILatLng) {
        // return from buffer only if user position not changed by more than 100 m since last (real) search
        this.mapEngineFlags.returnLocationFromBuffer = true;
        let distanceLastSearch = 0;
        if (this.mapEngineFlags.previousSearchUserLocation) {
            distanceLastSearch = GeometryUtils.getDistanceBetweenEarthCoordinates(currentLocation, this.mapEngineFlags.previousSearchUserLocation, Number.MAX_VALUE);
            if (distanceLastSearch > AppConstants.gameConfig.scanPlacesMinDist) {
                this.mapEngineFlags.returnLocationFromBuffer = false;
            }
        } else {
            this.mapEngineFlags.returnLocationFromBuffer = false;
        }
        if (!this.mapEngineFlags.returnLocationFromBuffer || !this.mapEngineFlags.previousSearchUserLocation) {
            // if real search, then consider the current location as the reference for subsequent searches
            if (!this.mapEngineFlags.previousSearchUserLocation) {
                this.mapEngineFlags.previousSearchUserLocation = new ILatLng(currentLocation.lat, currentLocation.lng);
            } else {
                this.mapEngineFlags.previousSearchUserLocation.lat = currentLocation.lat;
                this.mapEngineFlags.previousSearchUserLocation.lng = currentLocation.lng;
            }
        }
    }

}
