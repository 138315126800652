import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MPGroupsChatPage } from './mp-groups-chat.page';
import { PagesModule } from '../../pages.module';

const routes: Routes = [
  {
    path: '',
    component: MPGroupsChatPage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [MPGroupsChatPage]
})
export class MPGroupsChatPageModule { }
