<ion-header [ngClass]="getHeaderClass()">
    <leplace-toolbar [menu]="!enableBackButton" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()"
        rightButton="settings" [rightIcon]="true" (rightClick)="presentPopover()" [title]="title" [loading]="!loaded">
    </leplace-toolbar>
</ion-header>

<ion-content no-padding [ngClass]="theme">

    <div class="view-frame theme-background" fxLayout="column">

        <div class="theme-border-bottom">
            <ion-row>
                <ion-col size="12" class="text-center padding-s">
                    <blink-span baseClass="info-text-m bold fab-display-top" [text]="heading"
                        [setTimeoutOnChange]="updateCheck"></blink-span>
                </ion-col>
            </ion-row>
        </div>

        <nav-bar *ngIf="!fixedCategory" [slidesPerView]="categoryTabsGroupCount"
            [(selectedTabId)]="selectedCategoryCode" [loadData]="initLoaded" [navBarItems]="navBarItems"
            (select)="selectCategoryFromNavItem($event)">
        </nav-bar>

        <!-- border-radius -->
        <!-- (swipe)="swipeEvent($event)" -->
        <div class="scrollbar-y inventory-height theme-border-top theme-border-bottom">
            <div *ngIf="loaded && !userStatsTable">
                <grid-item [data]="gameItems" [interact]="true" [basicView]="leaderboardMode"
                    emptyMessage="No available items in this category" [reload]="reloadItems"
                    (action)="itemActionContainer($event)">
                </grid-item>
            </div>
            <div *ngIf="loaded && userStatsTable" class="padding-top-s">
                <div class="padding-s text-center">
                    <span class="info-text-m bold text-color-primary">Stats</span>
                </div>
                <kv-table [data]="userStatsTable"></kv-table>
                <div class="padding-s text-center" *ngIf="userStoryTable">
                    <span class="info-text-m bold text-color-primary">Progress</span>
                </div>
                <kv-table [data]="userStoryTable"></kv-table>
            </div>
        </div>

        <div fxFlex></div>

        <div class="padding-horizontal-s padding-bottom-xs" *ngIf="checkPaginationEnabled()">
            <leplace-paginator [pagination]="pagination" (onSwitch)="switchPage($event)"></leplace-paginator>
        </div>

    </div>
</ion-content>