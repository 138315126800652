
import { IAppLocation } from '../places/app-location';
import { IPlaceMarkerContent } from './map-data';
import { ILatLng } from "./coords";

export enum EFollowMode {
    /**
     * only the user marker is updating
     */
    NONE = 0,
    /**
     * user marker and map is updating
     */
    MOVE_MAP = 1,
    /**
     * user marker and map is updating in 3D mode with heading (GPS)
     */
    MOVE_MAP_HEADING_3D = 2,
    /**
     * user marker and map is updating with heading (compass)
     */
    MOVE_MAP_HEADING_2D = 3,
    /**
     * user marker is updating, map is moving with drone
     */
    DRONE = 4
}

export interface IGmapApp {
    storyLocations: IAppLocation[];
    storyLocationsSlides: Array<IAppLocation[]>;
    storyLocationsActiveSlide: number;
    prevOffsetCenters: ILatLng[];
    auxPlaceMarkers: IPlaceMarkerContent[];
    itemsGenerated: number;
    rewardLpStoryTotal: number;
    collectedItemsCurrentActivity: number;
    collectedItemsValueCurrentActivity: number;
    canCompleteActivity: boolean;
    canExitMap: boolean;
    rewardLpCurrentActivity: number;
    displayMsg: string;
    hud: boolean;
    hudXP: boolean;
    firstStart: boolean;
    firstCountdownTick: boolean;
    msg: string;
    startTime: number;
    stopTime: number;
    state: number;
    start: boolean;
    challengeInProgress: boolean;
    mapSyncRequested: boolean;
    entry: boolean;
    finishedStory: boolean;
    skipAnimation: boolean;
    skipNextAnimation: boolean;
    locationIndex: number;
    locationIndexView: number;
    // used for circle radius reached (single use)
    offsetCenter: ILatLng;
    activeItems: number;
}

export enum EGmapStates {
    INIT = 0,
    SEARCH = 1,
    SHOW_LOCATION = 2,
    MOVE_TO_USER = 3,
    GET_DIRECTIONS = 4,
    SHOW_DIRECTIONS = 5,
    MOVE_TO_FIT = 6,
    MOVE_TO_FIT_SCANNED = 7,
    MOVE_TO_USER_FOLLOW = 8,
    NAVIGATE = 9,
    REACHED = 10,
    CHECK_ACTIVITY = 11,
    FINISHED_LOCATION = 12,
    SET_NEXT_LOCATION = 13,
    FINISHED_STORY = 14,
    SHOW_PLACE_ADS = 15,
    BEFORE_RETRY = 16,   
    WAYPOINT = 101,
    NAVIGATE_PENDING = 20,
    ERROR = -1
}


export interface IGmapUser {
    canSkipAnimation: boolean;
    canScan: boolean;
    canTakePhoto: boolean;
    clickedScan: boolean;
    canSkip: boolean;
    canShowNext: boolean;
    canRequestDirections: boolean;
    arMode: number;
    hasScanned: boolean;
    canZoom: boolean;
}

export enum EMapInteraction {
    none = -1,
    enter = 0,
    exit = 1,
    switch2dHeading = 2
}
