
import { Injectable } from "@angular/core";
import { ResourceManager } from "../../../../classes/general/resource-manager";
import { TimeoutService } from "../timeout";
import { LocalNotificationsService } from "../../../general/apis/local-notifications";
import { BehaviorSubject } from "rxjs";
import { ECheckActivityResult } from "../../../../classes/def/core/activity";
import { DeviceMotion, DeviceMotionAccelerationData, DeviceMotionAccelerometerOptions } from "@ionic-native/device-motion/ngx";
import { SignalProcessingService } from "../../utils/signal-processing";
import { SoundService } from "../../../media/sound";
import { SettingsManagerService } from "../../../general/settings-manager";
import { IPlatformFlags } from "../../../../classes/def/app/platform";
import { MathUtils } from "../../../../classes/general/math";
import { ISignalData, ISignalProcessingParams, ESignalProcessingFilter } from "../../../../classes/def/processing/signal";
import { IDanceAccActivityParams, IDanceAccActivityStatus, IDanceAccRTData } from 'src/app/classes/def/activity/dance';
import { ITimeoutMonitorParams, ITimeoutMonitorData, ETimeoutStatus } from 'src/app/classes/general/timeout';
import { EBufferContext } from "src/app/classes/utils/buffer";
import { PermissionsService } from "src/app/services/general/permissions/permissions";

@Injectable({
    providedIn: 'root'
})
export class DanceAccActivityService {

    subscription = {
        timeoutActivityMonitor: null,
        deviceMotion: null,
        sound: null
    };

    params: IDanceAccActivityParams;

    statusObservable: BehaviorSubject<IDanceAccActivityStatus>;
    monitorObservable: BehaviorSubject<IDanceAccRTData>;

    moduleName: string = "DECIBEL ACTIVITY > ";


    rtDataInit: IDanceAccRTData = {
        beatCount: 0,
        beatZone: 0,
        danceZone: 0,
        targetBpm: 0,
        bpm: 0,
        db: 0,
        acc: 0,
        rawSoundData: null
    };

    rtData: IDanceAccRTData;


    /**
     * the target has been reached
     */
    unlocked: boolean = false;

    /**
     * the target is too far
     */
    failed: boolean = false;


    check: boolean = true;

    platform: IPlatformFlags = {} as IPlatformFlags;

    activityFinished: boolean = false;

    constructor(
        public timeoutMonitor: TimeoutService,
        public localNotifications: LocalNotificationsService,
        public deviceMotion: DeviceMotion,
        public beatDetector: SignalProcessingService,
        public sound: SoundService,
        public permissions: PermissionsService,
        public settingsProvider: SettingsManagerService
    ) {
        console.log("dance acc activity service created");
        this.statusObservable = new BehaviorSubject(null);
        this.monitorObservable = new BehaviorSubject(null);

        this.initData();

        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
                if (this.platform.WEB) {
                    this.check = false;
                }
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    watchStatus() {
        return this.statusObservable;
    }

    watchRT() {
        return this.monitorObservable;
    }

    initData() {
        this.unlocked = false;
        this.params = null;
        this.rtData = Object.assign({}, this.rtDataInit);
        this.beatDetector.initData(EBufferContext.dance);
    }

    /**
     * handle timeout activity
     */
    initActivity(timeLeft: number) {
        console.log(this.moduleName + "init");
        if (!this.subscription.timeoutActivityMonitor) {
            console.log(this.moduleName + "init started");
            this.statusObservable.next(null);
            this.monitorObservable.next(null);
            let tmParams: ITimeoutMonitorParams = {
                timeLimit: timeLeft
            };
            this.timeoutMonitor.start(tmParams);
            this.activityFinished = false;

            let status: IDanceAccActivityStatus = {
                tmData: null,
                status: ECheckActivityResult.inProgress
            };
            this.initData();
            this.subscription.timeoutActivityMonitor = this.timeoutMonitor.getWatch().subscribe((tmData: ITimeoutMonitorData) => {
                if (tmData) {
                    // console.log(tmData.timerValue);
                    if (!tmData.isFallbackTimer) {
                        status.tmData = tmData;
                    }

                    if (this.unlocked) {
                        this.activityFinished = true;
                        status.status = ECheckActivityResult.done;
                        this.statusObservable.next(status);
                    }

                    if (this.failed) {
                        status.status = ECheckActivityResult.failed;
                        this.statusObservable.next(status);
                        this.activityFinished = true;
                    }

                    switch (tmData.status) {
                        case ETimeoutStatus.expired:
                            status.status = ECheckActivityResult.failed;
                            this.statusObservable.next(status);
                            this.activityFinished = true;
                            break;
                    }

                    if (this.activityFinished) {
                        this.subscription.timeoutActivityMonitor = ResourceManager.clearSub(this.subscription.timeoutActivityMonitor);
                        console.log("stopping timer");
                        this.timeoutMonitor.stop();
                    } else {
                        this.statusObservable.next(status);
                    }
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    /**
     * set activity params
     * required dbs and duration
     * @param params 
     */
    setActivityParams(params: IDanceAccActivityParams) {
        this.params = params;
        console.log("set activity params: ", this.params);
        this.rtData.targetBpm = params.targetBpm;
    }

    /**
     * start measuring the acceleration
     * method 1: pulse detection
     * method 2: FFT and match with the beat
     * the beat can be provided from the microphone too (dance to the actual live music)
     * 
     * 
     * proposed method
     * validate beat within focus zone
     * the next beat may be validated only within a time frame that corresponds to the BPM (w/ delta)
     */
    startMonitor() {
        if (!this.subscription.deviceMotion) {

            let samplingTime: number = 20;
            let options: DeviceMotionAccelerometerOptions = {
                frequency: samplingTime
            };

            let promiseCheck: any;
            if (this.check) {
                promiseCheck = this.permissions.requestMicrophonePermissions();
            } else {
                promiseCheck = Promise.resolve(true);
            }
            promiseCheck.then((status: boolean) => {
                if (status) {
                    this.subscription.sound = this.sound.startWatchDBMonitor(true).subscribe((data: ISignalData) => {
                        if (data) {
                            this.rtData.rawSoundData = data;
                            this.rtData.beatZone = data.beatDetection.beatZone;
                            this.rtData.bpm = Math.floor(data.beatDetection.bpm);
                            this.rtData.db = Math.floor(data.timeDomain.sample);
                            this.monitorObservable.next(this.rtData);
                        }
                    }, (err: Error) => {
                        console.error(err);
                    });

                    let params: ISignalProcessingParams = this.beatDetector.getBpParams(EBufferContext.dance);
                    params.cutoffLevel = 0;
                    params.alphaDecay = MathUtils.computeAlphaFromTimeConstant(samplingTime, 2);

                    this.subscription.deviceMotion = this.deviceMotion.watchAcceleration(options).subscribe((accel: DeviceMotionAccelerationData) => {
                        let accMag: number = Math.sqrt(accel.x * accel.x + accel.y * accel.y + accel.z * accel.z);
                        let rtData: ISignalData = this.beatDetector.processSample(EBufferContext.dance, accMag, {
                            filter: ESignalProcessingFilter.lowPassDual,
                            beatZone: true,
                            bpm: true,
                            level: true
                        });

                        this.rtData.acc = accMag;

                        // check for beat zone delay (beat zone should be >50 percent to count the beats)
                        if (this.rtData.beatZone > 50) {
                            this.rtData.beatCount = this.rtData.beatCount + 1;
                        }

                        this.rtData.danceZone = rtData.beatDetection.beatZone;

                        if (this.rtData.beatCount >= this.params.targetCount) {
                            this.unlocked = true;
                        }

                        if (this.rtData.beatCount < 0) {
                            // too many false beats
                            this.failed = true;
                        }

                        this.monitorObservable.next(this.rtData);
                    }, (err: Error) => {
                        console.error(err);
                    });
                }
            });
        }
    }

    /**
     * stop measuring the acceleration and the music beat
     */
    stopMonitor() {
        this.subscription.deviceMotion = ResourceManager.clearSub(this.subscription.deviceMotion);
        this.subscription.sound = ResourceManager.clearSub(this.subscription.sound);
        this.sound.stopDBMonitor();
    }

    exitActivity() {
        this.subscription = ResourceManager.clearSubObj(this.subscription);
        console.log("stopping timer");
        this.timeoutMonitor.stop();
        this.initData();
        this.activityFinished = false;
    }
}




