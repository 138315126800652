import { Component, OnInit, AfterViewInit, Input, Output, Self, ElementRef, ChangeDetectorRef, HostListener, OnDestroy } from '@angular/core';
import { NgxChartParams } from 'src/app/classes/general/params';
import { EventEmitter } from '@angular/core';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { INgxChartOptions, IChartSeriesContainer, IChartDataContainer } from 'src/app/classes/def/app/charts';


@Component({
  selector: 'leplace-polar-chart',
  templateUrl: './leplace-polar-chart.component.html',
  styleUrls: ['./leplace-polar-chart.component.scss'],
})
export class LeplacePolarChartComponent implements OnInit, AfterViewInit, OnDestroy {

  chartOptions: INgxChartOptions = NgxChartParams.getDefaultOptions();

  polarDataDemo: IChartSeriesContainer[] = [
    {
      name: "Germany",
      series: [
        {
          name: "2010",
          value: 7300000
        },
        {
          name: "2011",
          value: 8940000
        }
      ]
    }
  ];

  @Input()
  data: IChartDataContainer;

  @Input()
  options: INgxChartOptions;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  init: boolean = false;

  modalHeight: number;
  modalWidth: number;
  positionLeft: string;
  positionTop: string;

  constructor(
    public uiext: UiExtensionService,
    public settingsProvider: SettingsManagerService,
    @Self() public element: ElementRef,
    public cdr: ChangeDetectorRef
  ) {

    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        let theme: string = SettingsManagerService.settings.app.settings.theme.value;
        this.chartOptions.colorScheme.domain = ThemeColors.theme[theme].chartTheme;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  onSelect(event: any) {
    console.log("polar chart tap: ", event);
    this.action.emit(event);
  }

  ngAfterViewInit() {
    this.getDimensions();
    this.cdr.detectChanges();
  }

  /**
   * get component dimensions (from parent reference element) and update the chart view frame
   */
  getDimensions() {
    let container = this.element.nativeElement.parentElement;
    this.modalHeight = container.clientHeight;
    this.modalWidth = container.clientWidth;
    this.chartOptions.view[0] = this.modalWidth;
    this.chartOptions.view[1] = this.modalHeight;
    console.log(this.chartOptions.view);
  }

  ngOnInit() {

    if (this.options) {
      this.chartOptions = this.options;
    }

    if (this.data) {
      this.init = true;
    }

    this.getDimensions();
  }

  ngOnDestroy() {

  }

  @HostListener('window:resize', ['$event'])
  onResize(_event) {
    console.log("resize event");
    this.getDimensions();
    // this.chartOptions.view = [event.target.innerWidth / 2, event.target.innerHeight / 2];
    // console.log(this.chartOptions.view);
  }
}

