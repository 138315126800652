/// <reference types="@types/google.maps" />

// declare var google: any;

/**
    * declares the custom marker class
    * @param latlng
    * @param map
    * @param imageSrc
    */
export function CustomMarker(latlng, map, data) {
    this.latlng_ = latlng;
    this.position = latlng;
    this.imageSrc = data.icon;
    this.data = data;
    this.getPosition = () => {
        return latlng;
    };
    // Once the LatLng and text are set, add the overlay to the map.  This will
    // trigger a call to panes_changed which should in turn call draw.
    this.setMap(map);
}

export function initCustomMarker() {
    // extends the OverlayView class
    CustomMarker.prototype = new google.maps.OverlayView();

    // creates the custom HTML for the marker
    CustomMarker.prototype.draw = () => {
        // Check if the div has been created.
        let div = this.div_;

        if (!div) {
            // Create a overlay text DIV
            div = this.div_ = document.createElement('div');
            // Create the DIV representing our CustomMarker
            div.className = "customMarker";
            let img = document.createElement("img");
            img.src = this.imageSrc;
            div.appendChild(img);
            google.maps.event.addDomListener(div, "click", (() => {
                // console.log(event);
                google.maps.event.trigger(this, "click");
            }));

            // Then add the overlay to the DOM
            let panes = this.getPanes();
            panes.overlayImage.appendChild(div);
        }

        // Position the overlay
        let point = this.getProjection().fromLatLngToDivPixel(this.latlng_);
        if (point) {
            div.style.left = point.x + 'px';
            div.style.top = point.y + 'px';
        }
    };

    CustomMarker.prototype.remove = () => {
        // Check if the overlay was on the map and needs to be removed.
        if (this.div_) {
            this.div_.parentNode.removeChild(this.div_);
            this.div_ = null;
        }
    };

    CustomMarker.prototype.getPosition = () => {
        return;
    };
}
