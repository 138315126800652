import { Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { IonSlides } from '@ionic/angular';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EPhotos } from 'src/app/classes/def/app/icons';
import { ISliderOptions } from 'src/app/classes/def/dynamic/slides';
import { IMPStatusDB } from 'src/app/classes/def/mp/status';

@Component({
  selector: 'chat-heads-slider',
  templateUrl: './chat-heads-slider.component.html',
  styleUrls: ['./chat-heads-slider.component.scss'],
})
export class ChatHeadsSliderComponent implements OnInit, OnChanges {
  @ViewChild('slides', { read: IonSlides, static: false }) slides: IonSlides;

  @Input()
  chatheads: IMPStatusDB[];

  @Input()
  update: boolean;

  mini: boolean = true;

  sliderOptions: ISliderOptions = {
    slidesPerView: 3,
    initialSlide: 0
  };

  showLeftButton: boolean = true;
  showRightButton: boolean = true;
  loaded: boolean = false;

  summaryDisp: string = "";

  constructor() { }

  ngOnInit() {
    this.formatChatheads();
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'update':
            this.formatChatheads();
            this.updateSlides();
            break;
        }
      }
    }
  }

  trackByFn(_index: number, item: IMPStatusDB) {
    return item.playerId;
  }

  formatChatheads() {
    let connected: number = 0;
    for (let p of this.chatheads) {
      if (!p.photoUrl) {
        p.photoUrlDisp = EPhotos.userPlaceholder;
      } else {
        p.photoUrlDisp = p.photoUrl;
      }
      if (p.playerId === GeneralCache.userId) {
        p.self = true;
      }
      if (p.isOnline) {
        connected += 1;
      }
    }
    if (connected === 0) {
      this.summaryDisp = this.chatheads.length + " members";
    } else {
      this.summaryDisp = connected + "/" + this.chatheads.length + " members online";
    }
    // console.log("format chatheads: ", this.chatheads);
    this.loaded = true;
  }


  ngAfterViewInit() {
    this.updateSlides();
  }

  updateSlides() {
    this.slides.update().then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * Method that shows the next slide
   * update: next GROUP of slides
   */
  public slideNext(): void {
    if (!this.slides) {
      return;
    }
    console.log("slide next");
    this.slides.getActiveIndex().then((currentIndex: number) => {
      this.slides.length().then((slidesLength: number) => {
        let slideToIndex: number = currentIndex + this.sliderOptions.slidesPerView;
        if (slideToIndex > slidesLength - 1) {
          slideToIndex = slidesLength - 1;
        }
        this.slides.slideTo(slideToIndex).then(() => {

        }).catch((err: Error) => {
          console.error(err);
        });
      }).catch((err: Error) => {
        console.error(err);
      });

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * Method that shows the previous slide
   * update: previous GROUP of slides
   */
  public slidePrev(): void {
    if (!this.slides) {
      return;
    }
    console.log("slide prev");
    this.slides.getActiveIndex().then((currentIndex: number) => {
      let slideToIndex: number = currentIndex - this.sliderOptions.slidesPerView;
      if (slideToIndex < this.sliderOptions.slidesPerView - 1) {
        slideToIndex = 0;
      }
      this.slides.slideTo(slideToIndex).then(() => {

      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }


}
