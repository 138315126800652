<modal-header-container [vs]="vs">

  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="continue()" [loading]="adContext.loading" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>


<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div class="bg-container bg-container-fill bg-filter-2"
    [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: photoUrl}">

    <div class="content">
      <div class="content-height">
        <div fxLayout="column" class="content-height">

          <div class="scrollbar-y scroll-content-modal-1 theme-border-bottom-negative scroll-into-view-container">

            <ion-grid class="full-w" [ngClass]="inputFocused ? 'scroll-padding' : ''">

              <ion-row *ngIf="placeFound">
                <ion-col class="list-item-padding-top">
                  <place-card class="full-w" [place]="placeFound" tappable></place-card>
                </ion-col>
              </ion-row>

              <ion-row>
                <ion-col>
                  <safe-html-renderer [unsafeHtmlContent]="adContext.infoHTML" [imageWrapper]="true"
                    innerClass="info-text-m description-frame text-color-primary text-center"></safe-html-renderer>

                  <audio-guide *ngIf="descriptionPlain && withAudioGuide" label="" [contentHTML]="descriptionPlain"
                    [autoplay]="audioGuideAutoplay" (status)="updateAudioGuideStatus($event)"></audio-guide>

                  <safe-html-renderer *ngIf="customDescription" [imageWrapper]="true"
                    [unsafeHtmlContent]="customDescription"
                    innerClass="info-text-m description-frame text-left padding-horizontal-s padding-top-s"></safe-html-renderer>
                </ion-col>
              </ion-row>

              <div *ngIf="videoUrl" class="margin-top-xs margin-bottom-xs">
                <youtube-view [videoUrl]="videoUrl" customClass="height-40"></youtube-view>
              </div>

              <ion-row class="center-items padding-horizontal-s padding-bottom-s"
                *ngIf="hasActivityFinish && dispActivityFinish">
                <ion-col size="2" class="margin-vertical-none">
                  <icon-wrapper [icon]="icons.activityFinish.icon"
                    [custom]="icons.activityFinish.custom"></icon-wrapper>
                </ion-col>
                <ion-col size="10" class="margin-vertical-none">
                  <div [innerHTML]="activityFinishDescriptionDisp" class="info-text-m description-frame text-left">
                  </div>
                </ion-col>
              </ion-row>


              <div *ngIf="photoFinish" class="padding-s center-text">
                <responsive-img [withUpload]="true" (upload)="uploadPhotoFromCamera($event)"
                  [photoUrl]="photoUploadUrlSanitized != null ? photoUploadUrlSanitized : photoUploadUrl"
                  class="border-radius">
                </responsive-img>
              </div>

              <div *ngIf="questFinish && questData">
                <quest-item [dframe]="true" [ownButton]="false" [data]="questData" [shake]="false"
                  [showAnswers]="checkQuestComplete()" [loading]="false" (submitResponse)="submitResponse($event)"
                  (check)="checkInput()" (action)="questAction($event)" (inputFocus)="questFocus($event)">
                </quest-item>
              </div>


              <div *ngIf="activityStats">
                <ion-row>
                  <ion-col>
                    <div class="margin-top-s">
                      <span class="info-text-m bold text-color-primary center-text">Game stats</span>
                      <kv-table [data]="activityStats" [format]="true"></kv-table>
                    </div>
                  </ion-col>
                </ion-row>
              </div>

              <ion-row *ngIf="gameItems">
                <ion-col class="center-text">
                  <span class="info-text-m bold text-color-primary center-text">Achievements progress</span>
                  <grid-item [data]="gameItems" [interact]="true" [basicView]="true" emptyMessage="No medals available">
                  </grid-item>
                </ion-col>
              </ion-row>



              <div *ngIf="adContext.shareMessage && shareEnabled && shareEnabledGlobal" class="padding-s"
                fxLayout="column">
                <span class="info-text-m bold text-color-primary center-text">Share progress</span>
                <span class="info-text-m text-color-primary center-text">{{adContext.shareMessage}}</span>
              </div>

              <div class="text-center" class="padding-top-s" *ngIf="ratingEnabled">
                <span class="text-center info-text-m bold text-color-primary">Rate this checkpoint</span>
              </div>

              <div class="text-center" *ngIf="ratingEnabled">
                <!-- <rating class="rating-input" [(ngModel)]="rating" [max]="5" [float]="false" [readonly]="false">
                </rating> -->
                <div class="center-flex padding-s">
                  <ngx-stars [readonly]="false" [size]="2" [color]="'#fcd182'" [initialStars]="rating" [maxStars]="5"
                    [wholeStars]="true" (ratingOutput)="onRatingSet($event)">
                  </ngx-stars>
                </div>
                <div class="center-flex padding-s" *ngIf="isReviewEnabled()">
                  <edit-textarea class="full-w" [mainContainer]="review" [editContainer]="reviewCopy" key="description"
                    [maxLength]="maxLength" placeholder="Write a review" [popupInput]="true"
                    (update)="reviewChange($event)" (focus)="textAreaFocused($event)">
                    <inner-content>Edit review</inner-content>
                  </edit-textarea>
                </div>
              </div>

              <!-- <div *ngIf="inputFocused" class="padding-keyboard"></div> -->

            </ion-grid>
          </div>

          <div fxFlex></div>
          <div class="stick-bottom theme-border-top">
            <ion-grid class="full-w">
              <ion-row *ngIf="enabledFlags && canContinue">
                <!-- <ion-col size="4">
                  <ion-button class="action-button-fill button-color-primary-70"
                    [disabled]="!shareEnabled || adContext.loading" (click)="shareAndContinue()">
                    <icon-wrapper slot="icon-only" icon="share"></icon-wrapper>
                  </ion-button>
                </ion-col> -->

                <ion-col size="4">
                  <ion-button class="action-button-fill button-color-alternate"
                    [disabled]="!enabledFlags.qr && !enabledFlags.sales" (click)="openBusinessFeatures()">
                    <icon-wrapper slot="icon-only" [icon]="appIcons.qr" [custom]="true"></icon-wrapper>
                  </ion-button>
                </ion-col>

                <ion-col size="4">

                </ion-col>

                <!-- <ion-col size="3">
                  <cooldown-button [active]="adContext.watchEnabled && !adContext.loading"
                    [triggerCooldown]="adContext.triggerCooldown" [cooldown]="10" [icon]="appIcons.videoAd"
                    [customIcon]="true" [name]="" theme="alternate" (select)="playAndContinue()">
                  </cooldown-button>
                </ion-col> -->

                <ion-col size="4">
                  <ion-button class="action-button-fill button-color-accent" (click)="continue()">
                    <icon-wrapper slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
                  </ion-button>
                </ion-col>
              </ion-row>

              <ion-row *ngIf="photoFinish && !canContinue">
                <ion-col size="12">
                  <ion-button class="action-button-fill button-color-alternate" [disabled]="validationInProgress"
                    (click)="uploadPhotoFromCamera($event)">
                    <icon-wrapper class="slot-icon-padding" slot="start" icon="camera"></icon-wrapper>
                    <span class="button-font-size-s">Take photo</span>
                  </ion-button>
                </ion-col>
              </ion-row>

              <ion-row *ngIf="QRFinish && !canContinue">
                <ion-col size="12">
                  <ion-button class="action-button-fill button-color-alternate" [disabled]="validationInProgress"
                    (click)="scanBarcodeOnClick()">
                    <icon-wrapper class="slot-icon-padding" slot="start" [custom]="true"
                      [icon]="appIcons.qr"></icon-wrapper>
                    <span class="button-font-size-s">Scan QR</span>
                  </ion-button>
                </ion-col>
              </ion-row>

            </ion-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</modal-container>