import { Component, OnInit, OnDestroy, OnChanges, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';

@Component({
  selector: 'progress-timeout',
  templateUrl: './progress-timeout.component.html',
  styleUrls: ['./progress-timeout.component.scss'],
})
export class ProgressTimeoutComponent implements OnInit, OnDestroy, OnChanges {

  loadingIncrement: number = 0;
  loadingValue: number = 0;

  timeout: any;

  class: string = "no-padding";
  classLarge: string = "leplace-progress-bar-large";

  @Input()
  small: boolean;

  @Input()
  rounded: boolean;

  @Input()
  resetTimeoutToggle: boolean;

  @Input()
  timeoutValue: number;

  @Input()
  progress: number;

  @Input()
  enabled: boolean;

  @Input()
  customClass: string;

  @Input()
  pid: string;

  constructor() {

  }

  ngOnInit() {
    if (this.customClass) {
      this.class += " " + this.customClass;
    } else {
      this.class += " progress-bar-alternate";
    }

    if (GeneralCache.checkPlatformOSWithPWA() === EOS.ios) {
      this.classLarge = "leplace-progress-bar-large-ios";
    } else {
      if (GeneralCache.isWeb) {
        this.classLarge = "leplace-progress-bar-large-web";
      }
    }

    if (this.progress != null) {
      this.loadingValue = this.progress;
    }

    if (this.enabled) {
      this.updateProgressTimeout();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      // console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'resetTimeoutToggle':
            this.loadingValue = 100;
            this.loadingIncrement = 100 / this.timeoutValue;
            console.log("reset timeout value: ", this.timeoutValue);
            break;
          case 'enabled':
            if (!this.enabled) {
              this.loadingValue = 0;
              this.clearTimeoutPb();
            } else {
              this.clearTimeoutPb();
              this.updateProgressTimeout();
            }
            break;
        }
      }
    }
  }

  ngOnDestroy() {
    console.log("progress timeout destroy [" + this.pid + "]");
    this.enabled = false;
    this.clearTimeoutPb();
  }

  updateProgressTimeout() {
    this.clearTimeoutPb();
    if (this.enabled) {
      this.timeout = setTimeout(() => {
        if (this.enabled) {
          this.updateProgressCore();
          this.updateProgressTimeout();
        }
      }, 1000);
    }
  }

  updateProgressCore() {
    if (this.loadingValue === 0) {
      return;
    }
    this.loadingValue -= this.loadingIncrement;
    if (this.loadingValue <= 0) {
      this.loadingValue = 0;
    }
    // console.log("update progress core [" + this.pid + "] " + this.loadingValue);
  }

  clearTimeoutPb() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
  }

}
