import { Injectable } from '@angular/core';
import { StorageOpsService } from './data/storage-ops';
import { SettingsManagerService } from './settings-manager';
import { AdsService } from './apis/ads';
import { AppSettings } from '../utils/app-settings';
import { ELocalAppDataKeys } from 'src/app/classes/def/app/storage-flags';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
declare var TFDetect: any;
@Injectable({
  providedIn: 'root'
})
export class TestingManagerService {
  constructor(
    public settingsManager: SettingsManagerService,
    public ads: AdsService,
    public storageOps: StorageOpsService
  ) {
    console.log("tester manager service created");
  }

  /**
   * update tester mode and all associated services
   * this is to be called after all resources have been loaded (e.g. app settings)
   * @param tester 
   */
  updateTesterMode(tester: boolean) {
    AppSettings.testerMode = tester;
    console.log("set tester: ", AppSettings.testerMode);
    // set local storage as NOT tester (actually confirmed NOT tester!!)
    this.storageOps.setStorageFlagNoAction({
      flag: ELocalAppDataKeys.isTester,
      value: AppSettings.testerMode
    });
    this.settingsManager.updateTesterMode(tester);
    this.ads.initializeAdService(tester);
  }

  checkTestFlightDistribution() {
    switch (GeneralCache.os) {
      case EOS.ios:
        if (TFDetect != null) {
          console.log("TFDetect: ", TFDetect);
          // this is the only clear result from the plugin (app store vs no app store)
          if (TFDetect.isAppStore) {
            // production
            GeneralCache.isPublicDistribution = true;
          } else {
            // testflight or xcode debug
            GeneralCache.isPublicDistribution = false;
          }
        } else {
          console.log("TFDetect was not found");
        }
        break;
      case EOS.android:
        GeneralCache.isPublicDistribution = true;
        break;
      case EOS.browser:
        GeneralCache.isPublicDistribution = !GeneralCache.isDev;
        break;
      default:
        GeneralCache.isPublicDistribution = false;
        break;
    }
    console.log("isPublicDistribution: ", GeneralCache.isPublicDistribution);
    // // simulate testflight
    // GeneralCache.isPublicDistribution = false;
  }

  overridePublicDistribution(set: boolean) {
    if (set == null) {
      GeneralCache.isPublicDistribution = !GeneralCache.isPublicDistribution;
    }
  }

  isPublicDistribution() {
    return GeneralCache.isPublicDistribution;
  }
}
