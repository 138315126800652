<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="rightBtnIcon" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss()" [rounded]="vs.fullScreen">
  </leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <ion-list class="list-md-noborder">

    <ion-item class="list-item transparent-bg settings-item">
      <ion-label class="text-color-primary label-text-s">Select All</ion-label>
      <ion-checkbox [(ngModel)]="selectAll" ngDefaultControl (ionChange)="onSelectAll($event)" class="custom-checkbox">
      </ion-checkbox>
    </ion-item>

    <div *ngFor="let a of actionsList">
      <ion-item class="list-item transparent-bg settings-item">
        <ion-label class="text-color-primary label-text-s">{{a.name}}</ion-label>
        <ion-checkbox [(ngModel)]="a.enabled" ngDefaultControl (ionChange)="onChange($event)" class="custom-checkbox">
        </ion-checkbox>
      </ion-item>
    </div>
  </ion-list>
</modal-container>