import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Component({
    selector: 'safe-html-renderer',
    templateUrl: './safe-html-renderer.component.html',
    styleUrls: ['./safe-html-renderer.component.scss']
})
export class SafeHtmlRendererComponent implements OnInit, OnChanges {

    @Input() unsafeHtmlContent: string;
    @Input() imageWrapper: boolean;
    @Input() innerClass: string;
    @Input() innerNgClass: string;
    @Output() innerClick: EventEmitter<boolean> = new EventEmitter();

    safeHtmlContent: SafeHtml;

    constructor(private sanitizer: DomSanitizer) { }

    ngOnInit() {
        this.sanitizeContent();
    }

    ngOnChanges(changes: SimpleChanges) {
        let keys: string[] = Object.keys(changes);
        for (let i = 0; i < keys.length; i++) {
            const chg: SimpleChange = changes[keys[i]];
            if (!chg.isFirstChange()) {
                switch (keys[i]) {
                    case 'unsafeHtmlContent':
                        this.sanitizeContent();
                        break;
                }
            }
        }
    }

    sanitizeContent() {
        // console.log("safe html renderer sanitize content: ", this.unsafeHtmlContent);
        this.safeHtmlContent = this.sanitizer.bypassSecurityTrustHtml(this.unsafeHtmlContent);
    }


    onInnerClick() {
        this.innerClick.emit(true);
    }
}
