
export enum EScripts {
    stickerbomb = "stickerbomb",
    segmentDisplay = "segmentDisplay",
    cropperJs = "cropperJs",
    cropperJsCss = "cropperJsCss",
    joyStick = "joyStick",
    facebook = "facebook",
    googleSignin = "googleSignin"
}

export interface IScriptStore {
    name: string;
    src: string;
    type: string;
    loaded?: boolean;
}

export interface IScriptStoreObj {
    [key: string]: IScriptStore;
}

export const ScriptStore: IScriptStore[] = [
    { name: 'stickerbomb', src: 'assets/js/stickerbomb-master/stickerbomb.min.js', type: 'js' },
    { name: 'segmentDisplay', src: 'assets/js/SegmentDisplay/segment-display.js', type: 'js' },
    { name: 'cropperJs', src: 'assets/js/cropperjs/dist/cropper.min.js', type: 'js' },
    { name: 'cropperJsCss', src: 'assets/js/cropperjs/dist/cropper.min.css', type: 'css' },
    { name: 'joyStick', src: 'assets/js/JoyStick/joy.min.js', type: 'js' },
    { name: 'facebook', src: 'https://connect.facebook.net/en_US/sdk.js', type: 'js'},
    { name: 'googleSignin', src: 'https://accounts.google.com/gsi/client', type: 'js' }
];

