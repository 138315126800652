
/// <reference types="@types/google.maps" />
import { ILatLng, ILatLngBounds } from 'src/app/classes/def/map/coords';
import { IAverageHeading } from '../../classes/def/core/move-monitor';

declare var google: any;


export interface IIndexedSegment {
    index: number;
    pointA: ILatLng;
    pointB: ILatLng;
    indexA: number;
    indexB: number;
}

export interface ISegment {
    pointA: ILatLng;
    pointB: ILatLng;
}

export interface IPointOnSegment {
    point: ILatLng;
    segmentIndex: number;
}

export interface IDistanceOnAxis {
    dx: number;
    dy: number;
}

export interface IQuaternions {
    x: number;
    y: number;
    z: number;
    w: number;
}



export class GeometryUtils {
    public static RAD: number = 0.000008998719243599958;
    // earth radius in meters
    public static earthRadius = 6371000; // meters
    // earth circumference in meters
    public static earthCircumference = 40075000; // meters
    public static deg2rad: number = Math.PI / 180;
    public static rad2deg: number = 180 / Math.PI;


    static getLineSegmentsWithIndex(wpts) {
        let segments: IIndexedSegment[] = [];
        for (let i = 0; i < wpts.length; i++) {
            if (i > 0) {
                segments.push({
                    index: i,
                    pointA: wpts[i - 1],
                    pointB: wpts[i],
                    indexA: i - 1,
                    indexB: i
                });
            }
        }
        return segments;
    }


    static getLineSegments(wpts) {
        let segments: ISegment[] = [];
        for (let i = 0; i < wpts.length; i++) {
            if (i > 0) {
                segments.push({
                    pointA: wpts[i - 1],
                    pointB: wpts[i]
                });
            }
        }
        return segments;
    }

    static getMetersPerPx(lat: number, zoom: number) {
        let metersPerPx: number = 156543.03392 * Math.cos(lat * GeometryUtils.deg2rad) / Math.pow(2, zoom);
        return metersPerPx;
    }

    /**
     * get intersection of two lines
     * if limitSegment is true
     * then check if point of intersection is on the second segment and not outside of it
     * @param a 
     * @param b 
     * @param limitSegment 
     */
    static getIntersection(a: ILatLng[], b: ILatLng[], limitSegment: boolean = false) {
        if (a.length !== 2 || b.length !== 2) {
            return null;
        }
        // console.log(a, b);
        // if (!this.checkSegmentIntersect(a[0].lng, a[0].lat, a[1].lng, a[1].lat, b[0].lng, b[0].lat, b[1].lng, b[1].lat)) {
        //   return null;
        // }


        let s1 = (a[0].lat - a[1].lat) / (a[0].lng - a[1].lng);  // slope of line 1
        // console.log(s1);
        let s2 = (b[0].lat - b[1].lat) / (b[0].lng - b[1].lng);  // slope of line 2
        // console.log(s2);

        if (isNaN(s1) || isNaN(s2) || (Math.abs(s1 - s2) < Number.EPSILON)) {
            return null;
        }

        let px = (s1 * a[0].lng - s2 * b[0].lng + b[0].lat - a[0].lat) / (s1 - s2);
        let py = (s1 * s2 * (b[0].lng - a[0].lng) + s2 * a[0].lat - s1 * b[0].lat) / (s2 - s1);
        let point: ILatLng = new ILatLng(py, px);

        if (limitSegment && (point.lng > b[1].lng || point.lng < b[0].lng)) {
            return null;
        }
        // console.log("intersection: ", point);
        return point;
    }


    /**
     * calculate the projection to the path as the
     * projection to the segment that is on the segment and not outside the bounds
     */
    static getProjectionOnPath(point: ILatLng, path: ILatLng[]) {
        let segments: ISegment[] = this.getLineSegments(path);
        let pointProjectionOk = point;
        segments.forEach((segm) => {
            let pointProjection = this.getProjection(point, [segm.pointA, segm.pointB]);
            if ((pointProjection.lng > segm.pointB.lng || pointProjection.lng < segm.pointA.lng)) {

            } else {
                pointProjectionOk = pointProjection;
            }
        });
        return pointProjectionOk;
    }

    static checkXBounds(a: ILatLng, segm: ILatLng[]) {
        if (segm.length !== 2) {
            return false;
        }
        return (a.lng > segm[0].lng && a.lng < segm[1].lng) || (a.lng > segm[1].lng && a.lng < segm[0].lng);
    }

    static checkYBounds(a: ILatLng, segm: ILatLng[]) {
        if (segm.length !== 2) {
            return false;
        }
        return (a.lat > segm[0].lat && a.lat < segm[1].lat) || (a.lat > segm[1].lat && a.lat < segm[0].lat);
    }

    static getNearestPoint(x: ILatLng, points: ILatLng[]) {
        let np = points[0];
        let minDist = null;
        for (let i = 0; i < points.length; i++) {
            let dist = this.computeDistance(x, points[i]);
            if (minDist === null || dist < minDist) {
                minDist = dist;
                np = points[i];
            }
        }
        return np;
    }

    static getSegmentStartingWithPoint(segments: IIndexedSegment[], point: ILatLng) {
        let segment: IIndexedSegment = null;
        for (let i = 0; i < segments.length; i++) {
            if (segments[i].pointA === point) {
                segment = segments[i];

                break;
            }
        }
        return segment;
    }

    static getSegmentEndingWithPoint(segments: IIndexedSegment[], point: ILatLng) {
        let segment: IIndexedSegment = null;
        for (let i = 0; i < segments.length; i++) {
            if (segments[i].pointB === point) {
                segment = segments[i];
                break;
            }
        }
        return segment;
    }

    static getAllProjectionsOnPath(point: ILatLng, path: ILatLng[]) {
        let segments: ISegment[] = this.getLineSegments(path);
        let projections = [];
        for (let i = 0; i < segments.length; i++) {
            let segm = segments[i];
            let pp = this.getProjection(point, [segm.pointA, segm.pointB]);
            projections.push(pp);
        }
        return projections;
    }

    static getProjectionOnSegment(point: ILatLng, segm: ISegment) {
        let pp = this.getProjection(point, [segm.pointA, segm.pointB]);
        if (this.checkXBounds(pp, [segm.pointA, segm.pointB]) && this.checkYBounds(pp, [segm.pointA, segm.pointB])) {
            return pp;
        } else {
            return null;
        }
    }

    static getNearestProjectionOnPath(point: ILatLng, path: ILatLng[]) {
        let segments: ISegment[] = this.getLineSegments(path);
        let pointProjectionOk = null;
        let minDist = null;
        let segmentIndex = null;

        for (let i = 0; i < segments.length; i++) {
            let segm: ISegment = segments[i];
            let pp = this.getProjection(point, [segm.pointA, segm.pointB]);
            if (this.checkXBounds(pp, [segm.pointA, segm.pointB]) && this.checkYBounds(pp, [segm.pointA, segm.pointB])) {
                let dist = this.computeDistance(point, pp);
                if (minDist === null || dist < minDist) {
                    minDist = dist;
                    pointProjectionOk = pp;
                    segmentIndex = i;
                }
            } else {
            }
        }
        let result = {
            point: pointProjectionOk,
            segment: segmentIndex
        };
        return result;
    }


    static getNearestProjectionOnPathSegments(point: ILatLng, segments: IIndexedSegment[]) {
        let pointProjectionOk = null;
        let minDist = null;
        let segmentIndex = null;

        for (let i = 0; i < segments.length; i++) {
            let segm: IIndexedSegment = segments[i];
            let pp = this.getProjection(point, [segm.pointA, segm.pointB]);
            if (this.checkXBounds(pp, [segm.pointA, segm.pointB]) && this.checkYBounds(pp, [segm.pointA, segm.pointB])) {
                let dist = this.computeDistance(point, pp);
                if (minDist === null || dist < minDist) {
                    minDist = dist;
                    pointProjectionOk = pp;
                    segmentIndex = segm.index;
                }
            } else {
            }
        }
        let result: IPointOnSegment = {
            point: pointProjectionOk,
            segmentIndex
        };
        return result;
    }


    /**
     * calculate the projection of the point to the line
     */
    static getProjection(point: ILatLng, line: ILatLng[]) {
        if (line.length !== 2) {
            return point;
        }

        let x1 = line[0].lng, y1 = line[0].lat, x2 = line[1].lng, y2 = line[1].lat, x3 = point.lng, y3 = point.lat;
        let px = x2 - x1;
        let py = y2 - y1;
        let dAB = px * px + py * py;
        let u = 0;
        if (dAB > 0) {
            u = ((x3 - x1) * px + (y3 - y1) * py) / dAB;
        }
        let x = x1 + u * px, y = y1 + u * py;
        return new ILatLng(y, x);
    }


    static computeDistance(from: ILatLng, to: ILatLng) {
        let distance: number;
        let p1 = new google.maps.LatLng(from.lat, from.lng);
        let p2 = new google.maps.LatLng(to.lat, to.lng);
        // console.log(p1);
        // console.log(p2);
        if (google.maps.geometry) {
            distance = google.maps.geometry.spherical.computeDistanceBetween(p1, p2);
        } else {
            console.log("geometry not available");
            distance = null;
        }
        return distance;
    }

    static degreesToRadians(degrees: number) {
        return degrees * GeometryUtils.deg2rad;
    }

    static radiansToDegrees(radians: number) {
        return radians * GeometryUtils.rad2deg;
    }

    /**
     * get min distance between angles
     * @param alpha deg
     * @param beta deg
     */
    static getAngularDiff(alpha: number, beta: number) {
        let phi: number = Math.abs(beta - alpha) % 360;       // This is either the distance or 360 - distance
        let distance: number = phi > 180 ? 360 - phi : phi;
        return distance;
    }

    /**
    * get min distance between angles (signed)
    * @param target deg
    * @param source deg
    */
    static getAngularDiffSigned(target: number, source: number) {
        let diff = target - source
        diff = (diff + 180) % 360 - 180;
        return diff
    }

    static normalizeAngle180(angle: number) {
        return (angle >= 180) ? (angle - 360) : ((angle < -180) ? angle + 360 : angle);
    };

    /**
     * get compass heading error for gyro calibration
     * @param gyroHeading reversed (CCW)
     * @param targetHeading CW
     * @returns 
     */
    static getCompassHeadingError(gyroHeading: number, targetHeading: number) {
        let a = gyroHeading, b = targetHeading;
        let compassHeadingError = GeometryUtils.normalizeAngle180((b - a) < (360 + a - b) ? (b - a) : (360 + a - b));
        return compassHeadingError;
    }

    /**
     * This uses the "haversine" formula to calculate the great-circle distance between two points 
     * that is, the shortest distance over the earth’s surface – giving an "as-the-crow-flies" 
     * distance between the points (ignoring any hills they fly over, of course!).
     * https://www.movable-type.co.uk/scripts/latlong.html
     */
    static getDistanceBetweenEarthCoordinates(x1: ILatLng, x2: ILatLng, defaultValue: number) {
        if (x1 == null || x2 == null) {
            return defaultValue;
        }
        let lat1 = x1.lat, lng1 = x1.lng, lat2 = x2.lat, lng2 = x2.lng;

        let phi1 = this.degreesToRadians(lat1);
        let phi2 = this.degreesToRadians(lat2);

        let deltaPhi = this.degreesToRadians(lat2 - lat1);
        let deltaLambda = this.degreesToRadians(lng2 - lng1);

        let a = Math.sin(deltaPhi / 2) * Math.sin(deltaPhi / 2) +
            Math.cos(phi1) * Math.cos(phi2) *
            Math.sin(deltaLambda / 2) * Math.sin(deltaLambda / 2);
        let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

        return this.earthRadius * c;
    }


    static getCoordDeltaFromDistanceDelta(distanceDelta: number) {
        let coordDelta: number = 0;
        coordDelta = distanceDelta / this.earthCircumference * 360;
        return coordDelta;
    }

    static getDistanceBetweenEarthCoordinatesOnAxis(x1: ILatLng, x2: ILatLng) {
        let result: IDistanceOnAxis = {
            dx: 0,
            dy: 0
        };

        if (!x1 || !x2) {
            return result;
        }

        let earthRadius = 6371000;
        let latB = x2.lat;
        let lngB = x2.lng;
        let latA = x1.lat;
        let lngA = x1.lng;
        // let deltaLat = latB - latA;
        // let deltaLng = lngB - lngA;

        // calculate the distance on the x axis between A and B
        // the circumference of the earth is multiplied by the cosine of the latitude
        // to get the circumference of the paralel
        // then it is multiplied to get the distance of the arc on the circle
        let dx = 2 * Math.PI * earthRadius * Math.cos(latA) * (lngB - lngA) / 360;
        let dy = 2 * Math.PI * earthRadius * (latB - latA) / 360;

        result.dx = dx;
        result.dy = dy;

        return result;
    }

    static computeHeading(from: ILatLng, to: ILatLng) {
        // let dy = to.lat - from.lat;
        // let dx = to.lng - from.lng;
        // let heading = Math.atan2(dy, dx);
        // return heading;
        let startLat = this.degreesToRadians(from.lat);
        let startLng = this.degreesToRadians(from.lng);
        let destLat = this.degreesToRadians(to.lat);
        let destLng = this.degreesToRadians(to.lng);
        let y = Math.sin(destLng - startLng) * Math.cos(destLat);
        let x = Math.cos(startLat) * Math.sin(destLat) -
            Math.sin(startLat) * Math.cos(destLat) * Math.cos(destLng - startLng);
        let heading = Math.atan2(y, x);
        return heading;
    }

    static toDeg(rad: number) {
        if (rad != null) {
            return rad * GeometryUtils.rad2deg;
        }
        return null;
    }

    /**
     * translate angle to [0, 359]
     * @param angle 
     */
    static translateTo360(angle: number) {
        if (angle >= 360) {
            angle -= 360;
        }
        if (angle < 0) {
            angle += 360;
        }
        return angle;
    }

    /**
     * translate angle to [-180, 179]
     */
    static translateTo180(angle: number) {
        if (angle >= 180) {
            angle -= 360;
        }
        if (angle < -180) {
            angle += 360;
        }
        return angle;
    }

    /**
     * flip angle (180->0, 90->270)
     * angle is [0, 359]
     * @param angle 
     */
    static flipAngle(angle: number) {
        return 360 - angle - 1;
    }

    /**
     * rotate angle by delta [0, 359]
     * @param angle [0, 359]
     * @param delta [0, 359]
     */
    static rotateAngle(angle: number, delta: number): number {
        angle += delta;
        angle = this.translateTo360(angle);
        return angle;
    }

    static getTotalDistance(points: ILatLng[]) {
        let totalDistance = 0;
        for (let i = 0; i < points.length - 1; i++) {
            totalDistance += this.computeDistance(points[i], points[i + 1]);
        }
        return totalDistance;
    }

    /**
     * get point within segment [from, to]
     * @param from 
     * @param to 
     * @param fraction 
     */
    static getInterpolate(from: ILatLng, to: ILatLng, fraction: number) {
        let point: ILatLng;
        let distance: number = GeometryUtils.getDistanceBetweenEarthCoordinates(from, to, 0);
        let heading: number = GeometryUtils.computeHeading(from, to);
        point = GeometryUtils.moveDeltaDistanceHeading(from, distance * fraction, heading);
        return point;
    }

    static flipAngleRad(angle: number) {
        return (angle + Math.PI) % (2 * Math.PI);
    }

    /**
     *  get point within segment [from, to] at specified distance from origin
     * @param from 
     * @param to 
     * @param distanceDelta 
     */
    static moveDelta(from: ILatLng, to: ILatLng, distanceDelta: number, inv: boolean) {
        let point: ILatLng;
        let distance: number = GeometryUtils.getDistanceBetweenEarthCoordinates(from, to, 0);

        if (distanceDelta > distance) {
            distanceDelta = distance;
        }

        let heading: number = GeometryUtils.computeHeading(from, to);
        if (inv) {
            heading = GeometryUtils.flipAngleRad(heading);
        }

        // going from CCW to CW and rotate by 90 deg (trig to compass)
        // heading = (2 * Math.PI - heading + Math.PI / 2) % (2 * Math.PI);
        point = GeometryUtils.moveDeltaDistanceHeading(from, distanceDelta, heading);
        return point;
    }


    static extendBounds(points: ILatLng[], delta: number = 0.001) {
        let pointMinLng: ILatLng = new ILatLng(0, 0);
        let pointMaxLng: ILatLng = new ILatLng(0, 0);
        let pointMinLat: ILatLng = new ILatLng(0, 0);
        let pointMaxLat: ILatLng = new ILatLng(0, 0);
        let first = true;

        points.forEach((point) => {
            if (first) {
                first = false;
                pointMinLng.lat = point.lat;
                pointMinLng.lng = point.lng;

                pointMinLat.lat = point.lat;
                pointMinLat.lng = point.lng;

                pointMaxLng.lat = point.lat;
                pointMaxLng.lng = point.lng;

                pointMaxLat.lat = point.lat;
                pointMaxLat.lng = point.lat;

            } else {
                if (point.lat > pointMaxLat.lat) {
                    pointMaxLat.lat = point.lat;
                    pointMaxLat.lng = point.lng;
                    // pointMaxLat = point;
                }
                if (point.lng > pointMaxLng.lng) {
                    pointMaxLng.lat = point.lat;
                    pointMaxLng.lng = point.lng;

                }
                if (point.lat < pointMinLat.lat) {
                    pointMinLat.lat = point.lat;
                    pointMinLat.lng = point.lng;
                }
                if (point.lng < pointMinLng.lng) {
                    pointMinLng.lat = point.lat;
                    pointMinLng.lng = point.lng;
                }
            }
        });

        points.forEach((point) => {
            if (point.lng === pointMinLng.lng) {
                point.lng -= delta;
            }
            if (point.lng === pointMaxLng.lng) {
                point.lng += delta;
            }
            if (point.lat === pointMinLat.lat) {
                point.lat -= delta;
            }
            if (point.lat === pointMaxLat.lat) {
                point.lat += delta;
            }

        });
        return points;
    }

    // /**
    //  * @param point starting point
    //  * @param dist distance in meters
    //  * @param headingRad heading in radians
    //  */
    // static moveDeltaDistanceHeading(point: ILatLng, dist: number, headingRad: number) {
    //     let lat1Rad: number = point.lat * GeometryUtils.deg2rad;
    //     let lng1Rad: number = point.lng * GeometryUtils.deg2rad;
    //     let newPoint: ILatLng = Object.assign({}, point);
    //     let distFrac: number = dist / GeometryUtils.earthRadius;
    //     let lat2Rad: number = Math.asin(Math.sin(lat1Rad) * Math.cos(distFrac) + Math.cos(lat1Rad) * Math.sin(distFrac) * Math.cos(headingRad));
    //     let a: number = Math.atan2(Math.sin(headingRad) * Math.sin(distFrac) * Math.cos(lat1Rad), Math.cos(distFrac) - Math.sin(lat1Rad) * Math.sin(lat2Rad));
    //     let lng2Rad: number = (lng1Rad + a + 3 * Math.PI) % (2 * Math.PI) - Math.PI;
    //     newPoint.lng = lng2Rad * GeometryUtils.rad2deg;
    //     newPoint.lat = lat2Rad * GeometryUtils.rad2deg;
    //     return newPoint;
    // }


    /**
     * @param point starting point
     * @param dist distance in meters
     * @param headingRad heading in radians
     */
    static moveDeltaDistanceHeading(point: ILatLng, dist: number, headingRad: number) {
        let distRatio = dist / GeometryUtils.earthRadius;
        let distRatioSine = Math.sin(distRatio);
        let distRatioCosine = Math.cos(distRatio);

        let startLatRad = point.lat * GeometryUtils.deg2rad;
        let startLonRad = point.lng * GeometryUtils.deg2rad;

        let startLatCos = Math.cos(startLatRad);
        let startLatSin = Math.sin(startLatRad);

        let endLatRads = Math.asin((startLatSin * distRatioCosine) + (startLatCos * distRatioSine * Math.cos(headingRad)));

        let endLonRads = startLonRad
            + Math.atan2(
                Math.sin(headingRad) * distRatioSine * startLatCos,
                distRatioCosine - startLatSin * Math.sin(endLatRads));

        let pointNew: ILatLng = Object.assign({}, point);
        pointNew.lat = endLatRads * GeometryUtils.rad2deg;
        pointNew.lng = endLonRads * GeometryUtils.rad2deg;

        return pointNew;
    }

    /**
     * NOT WORKING
     */
    static isLocationOnEdge(point: ILatLng, path: ILatLng[], tol: number) {
        // To determine whether a point falls on or near a polyline, or on or near the edge of a polygon, 
        // pass the point, the polyline/polygon, and optionally a tolerance value in degrees to
        console.log(point);
        console.log(path);
        let point1 = new google.maps.LatLng(point.lat, point.lng);
        console.log(point1);
        let path1 = [];
        for (let i = 0; i < path.length; i++) {
            path1.push(new google.maps.LatLng(path[i].lat, path[i].lng));
        }

        if (google.maps.geometry.poly.isLocationOnEdge(point1, path1, tol)) {
            return true;
        }
        return false;
    }


    static getQuarter(point: ILatLng, ref: ILatLng) {
        let q: number = 1;
        if (point.lat > ref.lat && point.lng > ref.lng) {
            q = 1;
        } else if (point.lat > ref.lat && point.lng < ref.lng) {
            q = 2;
        } else if (point.lat < ref.lat && point.lng < ref.lng) {
            q = 3;
        } else if (point.lat < ref.lat && point.lng > ref.lng) {
            q = 4;
        }
        return q;
    }

    static getDeltaForQuarter(delta: IDistanceOnAxis, q) {
        let dx = delta.dx;
        let dy = delta.dy;
        switch (q) {
            case 1:
                dx = dx;
                dy = dy;
                break;
            case 2:
                dx = -dx;
                dy = dy;
                break;
            case 3:
                dx = -dx;
                dy = -dy;
                break;
            case 4:
                dx = dx;
                dy = -dy;
                break;
        }
        let d: IDistanceOnAxis = {
            dy,
            dx
        };
        return d;
    }


    /**
     * get a random point within a given radius of the center point
     * @param center 
     * @param maxRadius 
     */
    static getRandomPointInRadius(center: ILatLng, minRadius: number, maxRadius: number) {
        let t = 2 * Math.PI * Math.random();
        let u = Math.random() + Math.random();
        let r = (maxRadius - minRadius) * GeometryUtils.RAD * (u > 1 ? 2 - u : u) + minRadius * GeometryUtils.RAD;

        let deltax: number = r * Math.cos(t);
        let deltay: number = r * Math.sin(t);

        let newPoint: ILatLng = new ILatLng(0, 0);
        newPoint.lat = center.lat + deltax;
        newPoint.lng = center.lng + deltay;
        return newPoint;
    }


    /**
     * get a random point within a given radius of the center point
     * @param center 
     * @param radius 
     */
    static getRandomPointInRadiusWHeading(center: ILatLng, minRadius: number, maxRadius: number, heading: IAverageHeading) {
        let headingRad: number = heading.crt * GeometryUtils.deg2rad;
        // add some randomness to the heading
        let t = headingRad + (Math.random() - 0.5) * Math.PI;
        let u = Math.random() + Math.random();
        let r = (maxRadius - minRadius) * GeometryUtils.RAD * (u > 1 ? 2 - u : u) + minRadius * GeometryUtils.RAD;

        let deltax: number = r * Math.cos(t);
        let deltay: number = r * Math.sin(t);

        let newPoint: ILatLng = new ILatLng(0, 0);
        newPoint.lat = center.lat + deltax;
        newPoint.lng = center.lng + deltay;
        return newPoint;
    }

    /**
     * compute the GPS coords of the point that is specified
     * as a distance from the center, with a given heading
     * @param center
     * @param distance
     * @param heading deg
     */
    static getPointOnHeading(center: ILatLng, distance: number, heading: number) {
        // let t = heading * GeometryUtils.deg2rad;
        // let r = distance * GeometryUtils.RAD;

        // let deltax: number = r * Math.cos(t);
        // let deltay: number = r * Math.sin(t);

        // let newPoint: ILatLng = new ILatLng(0, 0);
        // newPoint.lat = center.lat + deltax;
        // newPoint.lng = center.lng + deltay;

        let lat1 = center.lat * GeometryUtils.deg2rad;
        let lon1 = center.lng * GeometryUtils.deg2rad;
        let radMeters: number = this.earthRadius;
        let headingRad: number = heading * GeometryUtils.deg2rad;

        let lat2 = Math.asin(Math.sin(lat1) * Math.cos(distance / radMeters) +
            Math.cos(lat1) * Math.sin(distance / radMeters) * Math.cos(headingRad))

        let lon2 = lon1 + Math.atan2(Math.sin(headingRad) * Math.sin(distance / radMeters) * Math.cos(lat1),
            Math.cos(distance / radMeters) - Math.sin(lat1) * Math.sin(lat2))

        lat2 = lat2 * GeometryUtils.rad2deg;
        lon2 = lon2 * GeometryUtils.rad2deg;

        let newPoint: ILatLng = new ILatLng(lat2, lon2);
        return newPoint;
    }

    static getPointOnDistanceDeltaXY(center: ILatLng, dx: number, dy: number) {
        let newPoint: ILatLng = new ILatLng(0, 0);
        newPoint.lat = center.lat + dx * GeometryUtils.RAD;
        newPoint.lng = center.lng + dy * GeometryUtils.RAD;
        return newPoint;
    }

    static makeQuat(x: number, y: number, z: number, w: number) {
        let quat: IQuaternions = {
            x,
            y,
            z,
            w
        };
        return quat;
    }
    static getQuatVector(quat: IQuaternions): number[] {
        return [quat.x, quat.y, quat.z, quat.w];
    }

    static multiplyQuaternions(q: number[], r: number[]): number[] {
        if (r.length < 3) {
            return null;
        }
        return [r[0] * q[0] - r[1] * q[1] - r[2] * q[2] - r[3] * q[3],
        r[0] * q[1] + r[1] * q[0] - r[2] * q[3] + r[3] * q[2],
        r[0] * q[2] + r[1] * q[3] + r[2] * q[0] - r[3] * q[1],
        r[0] * q[3] - r[1] * q[2] + r[2] * q[1] + r[3] * q[0]]
    }

    static rotateVectorByQuaternion(point: number[], q: number[]): number[] {
        if (point.length < 3) {
            return null;
        }
        let r: number[] = [0].concat(point);
        let q_conj: number[] = [q[0], -1 * q[1], -1 * q[2], -1 * q[3]];
        let r2: number[] = GeometryUtils.multiplyQuaternions(GeometryUtils.multiplyQuaternions(q, r), q_conj);
        return r2.slice(1);
        // return r2;
    }

    static getCenterPointExt(points: ILatLng[]) {
        let bound: ILatLngBounds = new ILatLngBounds(points);
        return bound.getCenter();
    }


    /**
     * Get a center latitude,longitude from an array of like geopoints
     * @param data
     * For Example:
     * $data = ILatLng[]
     * (
     *   0 = > ILatLng(45.849382, 76.322333),
     *   1 = > ILatLng(45.843543, 75.324143),
     *   2 = > ILatLng(45.765744, 76.543223),
     *   3 = > ILatLng(45.784234, 74.542335)
     * );
    */
    static getCenterPoint(data: ILatLng[]) {
        if (!(data.length > 0)) {
            return null;
        }
        // can't just average the points (degrees, inaccurate, overlaps)
        // https://stackoverflow.com/a/42234774/9326673

        let nc = data.length;

        let X = 0.0;
        let Y = 0.0;
        let Z = 0.0;

        for (let i = 0; i < data.length; i++) {
            let lat = data[i].lat * GeometryUtils.deg2rad;
            let lng = data[i].lng * GeometryUtils.deg2rad;

            let a = Math.cos(lat) * Math.cos(lng);
            let b = Math.cos(lat) * Math.sin(lng);
            let c = Math.sin(lat);

            X += a;
            Y += b;
            Z += c;
        }

        X /= nc;
        Y /= nc;
        Z /= nc;

        let lng = Math.atan2(Y, X);
        let hyp = Math.sqrt(X * X + Y * Y);
        let lat = Math.atan2(Z, hyp);

        let newX = (lat * GeometryUtils.rad2deg);
        let newY = (lng * GeometryUtils.rad2deg);

        return new ILatLng(newX, newY);
    }

    static getZoomLevelForBounds(data: ILatLng[], pixelWidth: number, heading: number, integer: boolean) {
        if (!(data.length > 0)) {
            return null;
        }
        let GLOBE_WIDTH = 256; // a constant in Google's map projection
        let west: number = data[0].lng;
        let east: number = data[0].lng;
        for (let p of data) {
            if (p.lng < west) {
                west = p.lng;
            }
            if (p.lng > east) {
                east = p.lng;
            }
        }
        let angle = east - west;
        if (angle < 0) {
            angle += 360;
        }
        if (heading != null) {
            angle = angle * (1 + Math.cos(heading));
        }
        let zoom: number = Math.log(pixelWidth * 360 / angle / GLOBE_WIDTH) / Math.LN2;
        if (integer) {
            zoom = Math.round(zoom);
        }
        return zoom;
    }

    static extendBoundsExtra(position: ILatLng[], withDelta: boolean, extraDelta: boolean, zoom: number) {
        let target: ILatLng[] = [];
        let avgLat: number = 0;
        for (let i = 0; i < position.length; i++) {
            target.push(new ILatLng(position[i].lat, position[i].lng));
            avgLat += position[i].lat;
        }
        avgLat /= position.length;

        let metersPerPx: number = GeometryUtils.getMetersPerPx(avgLat, zoom);
        let deltaPx: number = extraDelta ? 200 : 50;
        // let deltaPx: number = extraDelta ? 400 : 100;
        let deltaMeters: number = 0;
        let delta: number = 0;

        if (withDelta) {
            // delta = 0.005;
            deltaMeters = deltaPx * metersPerPx;
            delta = GeometryUtils.getCoordDeltaFromDistanceDelta(deltaMeters);
            console.log("delta px: " + deltaPx + ", meters: " + deltaMeters + ", deg: " + delta + ", mpp: " + metersPerPx);
            // should be calculated from the actual pixels offset
        }
        target = GeometryUtils.extendBounds(target, delta);
        return target;
    }
}
