import { IGroup, EGroupRole, IGroupMember } from "../../../classes/def/mp/groups";
import { IChatElement } from "../../../classes/def/mp/chat";
import { IMPMessageDataChat } from "../../../classes/def/mp/message-data";
import { IMPMessageDB } from "../../../classes/def/mp/message";
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { ETreasureType } from 'src/app/classes/def/items/treasures';
import { Messages } from 'src/app/classes/def/app/messages';
import { EGameContext } from 'src/app/classes/def/core/game';
import { ILeplaceTreasure } from 'src/app/classes/def/places/leplace';
import { StringUtils } from "../utils/string-utils";
import { ArrayUtils } from "../../utils/array-utils";
import { TimeUtils } from "src/app/classes/general/time";
import { EMPContext } from "src/app/classes/def/mp/generic";
import { IMPStatusDB } from "src/app/classes/def/mp/status";


export interface IMPAvailableItems {
    available: boolean,
    message: string,
    sub: string
}

export class MPUtils {

    /**
     * filter group to get only the other members (excl. self)
     * @param group 
     */
    static getOthersInGroup(group: IGroup, playerId: number) {
        if (!group) {
            return null;
        }
        return group.members.filter(m => {

            // don't show current player on the map (duplicate)
            if (m.userId === playerId) {
                return false;
            }

            // don't show disconnected members on the map
            if (m.dynamic && !m.dynamic.connected) {
                return false;
            }

            // don't show self on the map
            if (m.staticFlags && !m.staticFlags.self) {
                return true;
            }

            return false;
        });
    }

    /**
     * format group members static flags
     */
    static formatGroupMembers(group: IGroup, selfId: number) {
        if (!(group && group.members)) {
            return;
        }
        // console.log("format group members");
        for (let i = 0; i < group.members.length; i++) {
            if (!group.members[i].staticFlags) {
                group.members[i].staticFlags = {
                    enabled: true,
                    self: false
                };
            }
        }
        // console.log("self: ", playerId);
        for (let i = 0; i < group.members.length; i++) {
            if (selfId === group.members[i].userId) {
                // set the player role as the group role of self
                group.members[i].staticFlags.self = true;
                // console.log("you are: ", group.members[i]);
            }
        }
    }

    /**
    * check member belongs to group
    */
    static isGroupMember(group: IGroup, playerId: number) {
        if (!(group && group.members)) {
            return false;
        }
        for (let member of group.members) {
            if (member && member.userId === playerId) {
                return true;
            }
        }
        return false;
    }

    static checkGroupMembersChanged(groupOld: IGroup, groupNew: IGroup) {
        if (!(groupOld && groupOld.members && groupNew && groupNew.members)) {
            return false;
        }
        let groupMemberOldIds: number[] = groupOld.members.map(member => member.userId);
        let groupMemberNewIds: number[] = groupNew.members.map(member => member.userId);
        return !ArrayUtils.arraysEqualAnyOrder(groupMemberOldIds, groupMemberNewIds);
    }

    static checkGroupListChanged(groupOld: IGroup[], groupNew: IGroup[]) {
        if (!(groupOld && groupNew)) {
            return false;
        }
        let groupOldIds: number[] = groupOld.map(member => member.id);
        let groupNewIds: number[] = groupNew.map(member => member.id);
        return !ArrayUtils.arraysEqualAnyOrder(groupOldIds, groupNewIds);
    }

    /**
     * get chat message to be shown in gmap hud
     */
    static getStringMessageFromMessageDB(message: IMPMessageDB, maxLength: number) {
        let stringMsg: string = "";
        let chatMsg: IChatElement = MPUtils.getChatMessageFromMessageDB(message);
        if (!chatMsg) {
            return stringMsg;
        }

        stringMsg = chatMsg.user + ": " + chatMsg.message;
        if (maxLength != null) {
            stringMsg = StringUtils.trimName(stringMsg, maxLength);
        }
        return stringMsg;
    }

    static getPlayerNameFromMessageDB(message: IMPMessageDB) {
        let username: string;
        if (message.userData && message.userData.user) {
            username = message.userData.user.name;
        } else {
            if (message.userStatus) {
                username = message.userStatus.playerName;
            }
        }
        return username;
    }

    static getSelfFromMessageDB(message: IMPMessageDB) {
        let self: boolean = false;
        if (message.userData && message.userData.user) {
            if (message.userData.staticFlags) {
                self = message.userData.staticFlags.self;
            }
        } else {
            if (message.userStatus) {
                self = message.playerId === GeneralCache.userId;
            }
        }
        return self;
    }

    /**
     * get chat message to be shown in chat modal
     * fetch username and other props from status data
     */
    static getChatMessageFromMessageDB(message: IMPMessageDB) {
        if (!message) {
            return null;
        }
        let username: string = MPUtils.getPlayerNameFromMessageDB(message);
        let self: boolean = MPUtils.getSelfFromMessageDB(message);       
    
        if (!username) {
            // username = "Player " + message.playerId;
            username = "unidentified player";
        }

        let data: IMPMessageDataChat = message.data as IMPMessageDataChat;

        let messageText: string;

        if (data && data.text) {
            // in the new convention, the message is stored as a dynamic param, in the data object
            messageText = data.text;
        } else {
            messageText = "CODE" + message.type;
        }

        let timestampString: string = "";
        let timestampValue: number = 0;
        if (message.timestamp2) {
            // 1 ms
            // timestampString = "[" + new Date(message.timestamp2).toLocaleTimeString() + "]";
            // timestampString = new Date(message.timestamp2).toLocaleTimeString();
            timestampValue = message.timestamp2;
            timestampString = TimeUtils.formatDateTimeDisp(timestampValue, true, false);
        } else {
            if (message.timestamp1) {
                // 100 ns
                // timestampString = "[" + new Date(message.timestamp1 / 10000).toLocaleTimeString() + "]";
                // timestampString = new Date(message.timestamp1 / 10000).toLocaleTimeString();
                timestampValue = message.timestamp1 / 10000;
                timestampString = TimeUtils.formatDateTimeDisp(timestampValue, true, false);
            }
        }

        let chatMsg: IChatElement = {
            user: username,
            message: messageText,
            timestamp: timestampString ? timestampString : "",
            timestampValue: timestampValue,
            self: self,
            type: message.type,
            userId: message.playerId
        };

        return chatMsg;
    }


    /**
     * check if map item is available for mp
     * @param treasureType 
     * @param gameContextCode 
     * @param groupRole 
     */
    static checkMpAvailableItems(treasureType: number, gameContextCode: number, groupRole: number): IMPAvailableItems {
        let res: IMPAvailableItems = {
            available: true,
            message: null,
            sub: null
        };

        // unavailable items
        if ([ETreasureType.story, ETreasureType.arena].indexOf(treasureType) !== -1) {
            res.available = false;
            res.message = Messages.msg.treasureLockedForMp.after.msg;
            res.sub = Messages.msg.treasureLockedForMp.after.sub;
            return res;
        }

        // check for unavailable challenges
        if ([ETreasureType.challenge].indexOf(treasureType) !== -1) {
            if (gameContextCode != null) {
                if ([EGameContext.mpExcluded].indexOf(gameContextCode) !== -1) {
                    res.available = false;
                    res.message = Messages.msg.treasureLockedForMp.after.msg;
                    res.sub = Messages.msg.treasureLockedForMp.after.sub;
                    return res;
                }
            }
        }

        // unavailable items for member
        if (groupRole === EGroupRole.member) {
            if ([ETreasureType.story, ETreasureType.challenge].indexOf(treasureType) !== -1) {
                res.available = false;
                res.message = Messages.msg.treasureLockedForMember.after.msg;
                res.sub = Messages.msg.treasureLockedForMember.after.sub;
                return res;
            }
        }

        return res;
    }

    /**
     * check if map item is available for mp
     * show/hide items on the map from the start
     * @param treasure 
     */
    static checkMpAvailableItemsLock(treasure: ILeplaceTreasure): boolean {
        let show: boolean;
        switch (treasure.type) {
            case ETreasureType.challenge:
                if (treasure && treasure.activity) {
                    show = treasure.activity.gameContextCode !== EGameContext.mpExcluded;
                } else {
                    show = true;
                }
                break;
            case ETreasureType.story:
                show = false;
                break;
            default:
                show = true;
                break;
        }
        return show;
    }

    static getMPStatusFromGroupMember(m: IGroupMember, groupId: number) {
        if (m && m.dynamic && m.dynamic.status) {
            let stat: IMPStatusDB = m.dynamic.status;
            if (m.user != null && stat.photoUrl == null) {
                stat.photoUrl = m.user.photoUrl;
            }
            return stat;
        } else {
            if (m.user) {
                let stat: IMPStatusDB = {
                    playerId: m.user.id,
                    photoUrl: m.user.photoUrl,
                    context: EMPContext.GROUP,
                    contextId: groupId,
                    lat: null,
                    lng: null,
                    droneLat: null,
                    droneLng: null,
                    type: null,
                    isOnline: false,
                    playerName: m.user.name,
                    self: false
                };
                return stat;
            }
        }
        return null;
    }
}
