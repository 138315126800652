import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { GmapPage } from './gmap.page';
import { PagesModule } from '../pages.module';

const routes: Routes = [
  {
    path: '',
    component: GmapPage
  }
];

@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [GmapPage]
})
export class GmapPageModule { }
