<div *ngIf="gridOnly">
  <div *ngIf="!paramDetailsView" fxLayout="column">
    <div [@showState]="showState">
      <activity-details [data]="data" [gridOnly]="gridOnly" [isNavPreview]="isNavPreview" [isPreview]="isPreview"
        [min]="min" (select)="openParamDetailsView($event)">
      </activity-details>
    </div>
  </div>
  <div *ngIf="paramDetailsView && customParamSelected" fxLayout="column">
    <div [@showState]="showState">
      <activity-param-details [data]="customParamSelected"></activity-param-details>
    </div>
  </div>
</div>

<div fxLayout="column" *ngIf="!gridOnly">
  <!-- scroll-height-btn-1-console-border -->
  <div class="padding-s scrollbar-y scroll-content-modal-1-border-flush" fxLayout="column">
    <div *ngIf="!paramDetailsView" fxLayout="column">
      <div [@showState]="showState">
        <activity-details [data]="data" [gridOnly]="gridOnly" [isNavPreview]="isNavPreview" [isPreview]="isPreview"
          (select)="openParamDetailsView($event)">
        </activity-details>
      </div>
    </div>
    <div *ngIf="paramDetailsView && customParamSelected" fxLayout="column">
      <div [@showState]="showState">
        <activity-param-details [data]="customParamSelected"></activity-param-details>
      </div>
    </div>
  </div>
  <div fxFlex></div>
  <div class="stick-bottom theme-border-top">
    <ion-grid class="full-w">
      <ion-row>
        <ion-col size="12">
          <!-- <ion-button class="action-button-fill button-color-alternate" [disabled]="!enabled.qr && !enabled.sales"
            (click)="openBusinessFeatures()" *ngIf="!paramDetailsView">
            <icon-wrapper slot="icon-only" [icon]="appIcons.qr" [custom]="true"></icon-wrapper>
          </ion-button> -->
          <ion-button class="action-button-fill button-color-alternate" [disabled]="!enabled.qr && !enabled.sales"
            (click)="openBusinessFeatures()" *ngIf="!paramDetailsView">
            <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIcons.qr" [custom]="true">
            </icon-wrapper>
            <span class="button-font-size-s">scan</span>
          </ion-button>

          <!-- <ion-button class="action-button-fill button-color-warn" (click)="backToParams()" *ngIf="paramDetailsView">
            <icon-wrapper slot="icon-only" icon="arrow-back"></icon-wrapper>
          </ion-button> -->
          <ion-button class="action-button-fill button-color-warn" (click)="backToParams()" *ngIf="paramDetailsView">
            <icon-wrapper class="slot-icon-padding" slot="start" icon="arrow-back">
            </icon-wrapper>
            <span class="button-font-size-s">return</span>
          </ion-button>
        </ion-col>

        <!-- <ion-col size="6">
          <ion-button class="action-button-fill button-color-accent" (click)="continue()">
            <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIcons.check" [custom]="true">
            </icon-wrapper>
            <span class="button-font-size-s">next</span>
          </ion-button>
        </ion-col> -->

      </ion-row>
    </ion-grid>
  </div>
</div>