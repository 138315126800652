<ion-header [ngClass]="getHeaderClass()">
    <leplace-toolbar [menu]="true" title="Loading" [loading]="true"></leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <div class="view-frame">
    <!-- bg-filter-gradient-1 -->
    <div class="bg-container bg-container-fill"
      [photoBg]="{fade: false, fadeClass: 'bg-container-fade', url: backgroundSrc}">

      <div class="content">
        <div menuToggle>
          <leplace-plate></leplace-plate>
        </div>
      </div>
    </div>
  </div>
</ion-content>