

import { Injectable } from '@angular/core';
import { UiExtensionService } from '../../general/ui/ui-extension';
import { IPlatformFlags } from '../../../classes/def/app/platform';
import { UserContentDataService } from '../../data/user-content';
import { GeoObjectsService } from './geo-objects';
import { ITreasureSpec, ILeplaceTreasure, ITreasureCustomContent } from '../../../classes/def/places/leplace';

import { ETreasureType } from '../../../classes/def/items/treasures';
import { INavParams } from '../../../classes/def/nav-params/general';
import { InventoryWizardService } from './inventory-wizard';
import { NavigationHandlerService } from '../../map/navigation';
import { IRecordWaypoint } from '../../../classes/def/map/map-data';
import { TourContentDataService } from '../../data/tours';
import { ISaveTourContent, ILoadTourContent } from '../../../classes/def/tours/tours';
import { IPopoverActions } from '../../../classes/def/app/modal-interaction';
import { AppConstants } from '../../../classes/app/constants';
import {
    ITreasureSpecSelectorParams, ITreasureSpecInput, ETreasureSpecInput,
    TreasureSpecSelectorViewComponent
} from 'src/app/modals/app/modals/treasure-spec-selector/treasure-spec-selector.component';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { UiExtensionStandardService } from '../../general/ui/ui-extension-standard';
import { ILatLng } from 'src/app/classes/def/map/coords';


@Injectable({
    providedIn: 'root'
})
export class ContentCreatorService {

    platform: IPlatformFlags = {} as IPlatformFlags;

    contentCache: ILeplaceTreasure[] = [];

    constructor(
        public uiext: UiExtensionService,
        public uiextStandard: UiExtensionStandardService,
        public userContentProvider: UserContentDataService,
        public inventoryWizard: InventoryWizardService,
        public geoObjects: GeoObjectsService,
        public navigationHandler: NavigationHandlerService,
        public tourContentProvider: TourContentDataService

    ) {
        console.log("content creator service created");
    }

    resetTour() {
        this.contentCache = [];
    }

    /**
     * save waypoint list and places (treasures) that were registered along the way
     */
    saveCurrentTour() {
        let promise = new Promise((resolve, reject) => {
            let waypoints: IRecordWaypoint[] = this.navigationHandler.getRecordWaypoints();
            let items: ILeplaceTreasure[] = this.contentCache;
            // upload waypoints and treasures
            console.log("waypoints: " + waypoints.length + ", treasures: " + items.length);
            let tourData: ISaveTourContent = {
                waypoints: waypoints,
                markers: items
            };
            this.tourContentProvider.saveTour(tourData).then((res) => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * load a previously saved tour with all registered places along the way
     */
    loadTour(tourId: number): Promise<ILoadTourContent> {
        console.log("load tour: " + tourId);
        let promise: Promise<ILoadTourContent> = new Promise((resolve, reject) => {
            this.tourContentProvider.loadTour(tourId).then((res: ILoadTourContent) => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * list saved tours, select tour from the list
     */
    listSelectTour() {
        let promise = new Promise((resolve, reject) => {
            this.tourContentProvider.listUserTours().then((res: ILoadTourContent[]) => {
                let actions: IPopoverActions = {};

                for (let i = 0; i < res.length; i++) {
                    actions[i.toString()] = {
                        name: res[i].id.toString(),
                        code: res[i].id,
                        enabled: true
                    };
                }

                this.uiextStandard.showStandardModal(null, EModalTypes.options, "Options", {
                    view: {
                        fullScreen: false,
                        transparent: AppConstants.transparentMenus,
                        large: true,
                        addToStack: true,
                        frame: false
                    },
                    params: { actions: actions }
                }).then((code: number) => {
                    resolve(code);
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * place treasure content on the map
     * get treasure specs, show list of available items and upload custom content (description, photo)
     * @param location 
     * @param nearbyLocationUid 
     * @param addMarker 
     */
    placeContent(location: ILatLng, nearbyLocationUid: string, addMarker: boolean, push: boolean, lock: boolean, selectedSpec: ITreasureSpecInput): Promise<ITreasureSpecInput> {
        let promise: Promise<ITreasureSpecInput> = new Promise((resolve, reject) => {
            if (nearbyLocationUid == null) {
                reject(new Error("undefined place id"));
                return;
            }

            let promiseSelectedSpec: Promise<ITreasureSpecInput>;

            if (selectedSpec != null) {
                // use previous specs (quick add)
                selectedSpec.withPhoto = false;
                promiseSelectedSpec = Promise.resolve(selectedSpec);
            } else {
                promiseSelectedSpec = new Promise((resolve, reject) => {
                    this.userContentProvider.getFixedTreasureSpecs().then((treasureSpecs: ITreasureSpec[]) => {
                        let modalParams: ITreasureSpecSelectorParams = {
                            description: null,
                            loaderCode: null,
                            mode: 2,
                            title: "Select object",
                            photoUrl: null,
                            treasureSpecs: treasureSpecs
                        };

                        let navParams: INavParams = {
                            view: {
                                fullScreen: false,
                                transparent: false,
                                large: true,
                                addToStack: false,
                                frame: false
                            },
                            params: modalParams
                        };

                        // not added to stack = cannot dismiss
                        this.uiext.showCustomModal(null, TreasureSpecSelectorViewComponent, navParams).then((selected: ITreasureSpecInput) => {
                            if (!selected) {
                                reject(new Error("undefined specs"));
                                return;
                            }

                            switch (selected.code) {
                                case ETreasureSpecInput.add:
                                    if (selected.spec == null) {
                                        reject(new Error("undefined specs selected"));
                                        return;
                                    }

                                    // console.log("will add custom treasure: ", res);
                                    if (location === null) {
                                        reject(new Error("undefined location"));
                                        return;
                                    }

                                    resolve(selected);
                                    break;
                                case ETreasureSpecInput.syncPositions:
                                    resolve(selected);
                                    break;
                                default:
                                    resolve(selected);
                                    break;
                            }

                        }).catch((err: Error) => {
                            reject(err);
                        });
                    });
                });
            }

            promiseSelectedSpec.then((selected: ITreasureSpecInput) => {

                // after select treasure
                // register the map marker

                if (selected.code === ETreasureSpecInput.add) {
                    this.inventoryWizard.consumeScanEnergyGenericWizard(() => {
                        let contentData: ITreasureCustomContent = {
                            description: selected.content,
                            withPhoto: selected.withPhoto,
                            id: null,
                            photoUrl: null
                        };

                        this.userContentProvider.createFixedTreasure(location,
                            ETreasureType.customContent, selected.spec.code, nearbyLocationUid, contentData, push).then((treasure: ILeplaceTreasure) => {
                                // this.geoObjects.removeAllGlobalObjects();
                                treasure.spec = selected.spec;

                                console.log("fixed treasure created: ", treasure);

                                // treasure
                                this.contentCache.push(treasure);

                                if (addMarker) {
                                    this.geoObjects.addGlobalObjectByTreasureSpecs(location, treasure, lock);
                                }
                                resolve(selected);
                            }).catch((err: Error) => {
                                reject(err);
                            });
                    }, () => {
                        this.inventoryWizard.goToInventoryForScanEnergy().then(() => {
                            resolve(selected);
                        }).catch((err: Error) => {
                            console.error(err);
                            reject(err);
                        });
                    }, null, AppConstants.gameConfig.placeContentScanEnergy);
                } else {
                    resolve(selected);
                }
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

}
