export interface IAppVersionDB {
    buildId: number;
    buildIdString: string;
    contactEmail: string;
    package: string;
    store: string;
    title: string;
    fbLink: string;
    fbId: string;
    webpage: string;
}

export class AppVersionUtils {

    /**
     * not yet implemented
     * @param appVersion 
     * @param codePushVersion 
     */
    static getFullNumber(appVersion: number, codePushVersion: number): number {
        // appVersion = appVersion * 1000;
        // if (codePushVersion != null) {
        //     appVersion += codePushVersion;
        // }
        console.log("app version code: ", appVersion);
        console.log("code push version code: ", codePushVersion);
        return appVersion;
    }

    /**
     * not yet implemented
     * @param appVersion 
     * @param codePushVersion 
     */
    static getFullNumberIOS(appVersion: string, codePushVersion: number): number {
        // appVersion = appVersion * 1000;
        // if (codePushVersion != null) {
        //     appVersion += codePushVersion;
        // }
        console.log("app version code: ", appVersion);
        console.log("code push version code: ", codePushVersion);
        return AppVersionUtils.stringToNumber(appVersion);
    }

    /**
     * create build version code from build version tokens
     * @param tokens 
     */
    static tokensToNumber(tokens: number[]) {
        let n: number = 0;
        let multiplier = 10;
        for (let i = tokens.length - 1; i >= 0; i--) {
            n += tokens[i] * multiplier;
            multiplier = multiplier * 100;
        };
        n = n + 0;
        return n;
    }

    /**
     * create build version code from build version string
     * @param version 
     */
    static stringToNumber(version: string): number {
        let tokens: number[] = version.split(".").map(e => Number.parseInt(e));
        return AppVersionUtils.tokensToNumber(tokens);
    }

    static compareVersionCore(current: number, target: number) {
        if (target == null) {
            return true;
        }
        if (current < target) {
            return false;
        } else {
            return true;
        }
    }
}
