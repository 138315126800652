

export enum ECheckpointNavMode {
    manual = 0,
    auto = 1
}

export enum ECheckpointCollectMode {
    manual = 0,
    auto = 1
}

export enum EAudioGuide {
    disabled = 0,
    ttsManual = 1,
    ttsAuto = 2
}
