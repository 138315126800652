import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ILeplaceReg } from 'src/app/classes/def/places/google';
import { IArenaNavParams } from 'src/app/classes/def/nav-params/activity';
import { IGroup, EGroupRole, EArenaEvent, EGroupModalResult } from 'src/app/classes/def/mp/groups';
import { Messages } from 'src/app/classes/def/app/messages';
import { ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject } from 'rxjs';
import { IChatElement } from 'src/app/classes/def/mp/chat';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EServiceUrlCodes, EFeatureCode, IFeatureDef } from 'src/app/classes/def/app/app';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { EStandardCode, EStandardTreasureCode } from 'src/app/classes/def/core/activity';
import { IArenaReturnToMapData, IArenaReturnData } from 'src/app/classes/def/nav-params/arena';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { Util } from 'src/app/classes/general/util';
import { IGetPhotoOptions } from 'src/app/services/location/location-utils-def';
import { IPlacePhotoContainer } from 'src/app/classes/def/places/container';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { AppSettings } from 'src/app/services/utils/app-settings';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { ETreasureType } from 'src/app/classes/def/items/treasures';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AdsService } from 'src/app/services/general/apis/ads';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { MPManagerService } from 'src/app/services/app/mp/mp-manager';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { UserStatsDataService } from 'src/app/services/data/user-stats';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { PlacesDataService } from 'src/app/services/data/places';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { IMPStatusDB } from 'src/app/classes/def/mp/status';
import { ILeplaceTreasure } from 'src/app/classes/def/places/leplace';
import { IMPMeetingPlace } from 'src/app/classes/def/mp/meeting-place';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { MPGroupsHomePage } from '../mp-groups-home/mp-groups-home.page';
import { MPGameInterfaceService } from 'src/app/services/app/mp/mp-game-interface';
import { TextToSpeechService } from 'src/app/services/general/apis/tts';
import { SoundManagerService } from 'src/app/services/general/apis/sound-manager';
import { LocationUtilsWizard } from 'src/app/services/location/location-utils-wizard';
import { ResourceMonitorDataService } from 'src/app/services/data/resource-monitor';
import { PremiumDataService } from 'src/app/services/data/premium';
import { MPGroupsListPage } from '../mp-groups-list/mp-groups-list.page';
import { SleepUtils } from 'src/app/services/utils/sleep-utils';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { IStory } from 'src/app/classes/def/core/story';
import { IFaqEntry } from 'src/app/classes/def/support/support';
import { MessagesExt } from 'src/app/classes/def/app/messages-ext';
import { IMPGenericGroupStat } from 'src/app/classes/def/mp/game';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';


@Component({
  selector: 'app-mp-home',
  templateUrl: './mp-home.page.html',
  styleUrls: ['./mp-home.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MPHomePage implements OnInit, OnDestroy {
  theme: string = "theme-light theme-light-bg";
  title: string = "Meeting Place";

  shareMessage: string = "";
  place: ILeplaceReg = null;
  photoUrlLoaded: string = null;
  loading: boolean = false;
  params: IArenaNavParams = null;
  loaded: boolean = true;
  selectedGroup: IGroup = null;
  selectedMeetingPlace: IMPMeetingPlace = null;
  groupRole: number = EGroupRole.member;
  groupClosed: boolean = false;
  allConnected: boolean = false;
  appIcons = EAppIcons;

  buttonText = {
    ready: "Connect",
    start: "Start",
    ready2: "",
    start2: ""
  };

  buttonEnabled = {
    ready: true,
    start: false,
    return: false
  };

  cooldownTimer = null;

  infoText: string = Messages.mp.init;
  infoTextAux: string = null;

  infoTextGraphics: IFaqEntry[] = MessagesExt.mpInit;

  photoUrlSource: string = null;
  placePhotoUrlSource: string = null;

  autoDismiss: boolean = false;

  statusUpdate: boolean = false;

  subscription = {
    groupStatus: null,
    mpStateMachine: null,
    chatRx: null,
    arenaEvent: null,
    dispTimer: null
  };

  timeout = {
    other: null,
    enterMap: null,
    tapStartAuto: null,
    auxMessage: null
  };

  observables = {
    chatInput: null
  };

  gameIsAlreadyOn: boolean = false;
  canChat: boolean = false;
  chatMessageReceived: boolean = false;
  chatBtnBlink: boolean = false;

  np: INavParams = null;
  vs: IViewSpecs;

  nearbyPlayers: IMPStatusDB[] = [];

  inRange: boolean = false;

  connectedMeetingPlace: boolean = false;
  // connect by direct user input, authorize future connections e.g. returning from groups home
  authorizedMeetingPlaceMode: boolean = false;
  connecting: boolean = false;
  lockedForUser: boolean = true;
  mpLocked: boolean = false;

  synchronized: boolean = true;

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public adService: AdsService,
    public mpDataService: MPDataService,
    public analytics: AnalyticsService,
    public mpManager: MPManagerService,
    public resourcesProvider: ResourcesCoreDataService,
    public resourceMonitor: ResourceMonitorDataService,
    public userStatsProvider: UserStatsDataService,
    public popupFeatures: PopupFeaturesService,
    public placeProvider: PlacesDataService,
    public tutorials: TutorialsService,
    public nps: NavParamsService,
    public backButton: BackButtonService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public mpGameInterface: MPGameInterfaceService,
    public tts: TextToSpeechService,
    public soundManager: SoundManagerService,
    public locationUtilsWizard: LocationUtilsWizard,
    public premiumProvider: PremiumDataService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  /**
  * view a list of created groups
  */
  viewGroups() {
    this.params.fromMpHome = true;
    this.uiext.showCustomModal(null, MPGroupsListPage, {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: true
      },
      params: this.params
    }).then(async (res: IArenaReturnData) => {
      console.log("mp groups list return data: ", res);
      if (res) {
        switch (res.status) {
          case EGroupModalResult.ok:
          case EGroupModalResult.goToOptions:
            if (res.closeMpView) {
              let groupOnline: boolean = false;
              if (res.group != null && res.group.dynamic != null && this.mpManager.isGroupOnline()) {
                groupOnline = true;
              }
              let resMap: IArenaReturnToMapData = {
                closeMpView: true,
                code: EGroupModalResult.ok,
                group: res.group,
                groupOnline: groupOnline,
                groupResetConnection: false,
                groupUpdated: res.groupUpdated,
                role: res.role
              };
              this.dismiss(resMap);
            } else {
              // disconnect/reset group mode
              this.mpGameInterface.disconnect();
              this.mpGameInterface.dispose();
              if (this.authorizedMeetingPlaceMode) {
                this.connectMeetingPlaceMode(false);
              }
              if (res.group) {
                // this.params.group = res.group;
                // only the group id should be passed to initialize the group home page on init
                this.params.group = null;
                this.params.groupId = res.group.id;
                await SleepUtils.sleep(1);
                this.goToGroupsHome();
              }
            }
            break;
          default:
            break;
        }
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  goToGroupsHome() {
    this.disconnectMeetingPlaceMode(false);

    let params: IArenaNavParams = this.params;

    this.uiext.showCustomModal(null, MPGroupsHomePage, {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: true
      },
      params: params
    }).then((res: IArenaReturnToMapData) => {
      console.log("returned from mp groups home page: ", res);

      if (res != null) {
        if (res.closeMpView) {
          if (!params.testing) {
            // cascade dismiss
            this.dismiss(res);
          }
        } else {
          // disconnect/reset group mode
          this.mpGameInterface.disconnect();
          this.mpGameInterface.dispose();
          if (this.authorizedMeetingPlaceMode) {
            this.connectMeetingPlaceMode(false);
          }
        }
      } else {
        // disconnect/reset group mode
        this.mpGameInterface.disconnect();
        this.mpGameInterface.dispose();
        if (this.authorizedMeetingPlaceMode) {
          this.connectMeetingPlaceMode(false);
        }
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  getStandardPhoto() {
    this.resourcesProvider.getStandardPhotoByCode(EStandardCode.treasure + EStandardTreasureCode.foundArena).then((photoUrl: string) => {
      this.photoUrlSource = photoUrl;
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  onSend(data: IChatElement) {
    console.log("send: ", data);
  }

  ngOnInit() {

    this.analytics.trackView("mp-home");

    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    this.nps.checkParamsLoaded().then(async () => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.mpHome, null, this.np);
      console.log("nav params: ", npInfo.params);
      this.observables.chatInput = new BehaviorSubject<IChatElement>(null);
      let hasParams: boolean = npInfo.hasParams;
      // console.log(navParams.data);
      await this.resourceMonitor.checkWatchLoader();
      let available: boolean = await this.popupFeatures.checkServiceAvailable(EServiceUrlCodes.mp);

      if (available) {
        this.mpManager.changeServerUrl(GeneralCache.resourceCache.general.appServices.content.object.mp.url);
      } else {
        this.dismiss(null);
        return;
      }

      // disconnect/reset group mode
      this.mpGameInterface.disconnect();

      if (hasParams) {
        let np: INavParams = npInfo.params;
        this.params = np.params;
        this.vs = np.view;

        if (!this.params.testing) {
          this.place = this.params.place;
          this.selectedGroup = this.params.group;
          let mplace: ILeplaceTreasure = this.params.meetingPlace;
          if (mplace) {
            this.getMeetingPlaceSpec(mplace);
          } else {
            this.getStorySpecs(this.params.story);
          }
          this.groupRole = this.params.groupRole;
          this.formatPlace();
        }

        this.inRange = this.params.inRange;
        this.synchronized = this.params.synchronized;

        if (!this.inRange) {
          this.infoText += "<p>You are too far away</p>";
        }

        // group is not specified, check meeting place
        this.getStandardPhoto();
        this.checkUnlocked();

        if (!this.params.enableGroups) {
          this.mpLocked = true;
          this.infoText = Messages.mp.initNoMp;
        }

        if (this.selectedMeetingPlace != null) {
          // meeting place full specs
          // this.connectMeetingPlaceMode();
        } else {
          // meeting place id specs
          let mpId: number = this.params.meetingPlaceId;
          if (mpId != null) {
            this.placeProvider.loadTreasure(mpId).then((treasure: ILeplaceTreasure) => {
              if (treasure) {
                this.getMeetingPlaceSpec(treasure);
                // this.connectMeetingPlaceMode();
              }
            }).catch((err: Error) => {
              console.error(err);
              this.uiext.showAlertNoAction(Messages.msg.mpChallengeLoadError.after.msg, Messages.msg.mpChallengeLoadError.after.sub);
            });
          }
        }

        if (this.params.fromMapOpened) {
          this.soundManager.ttsCodeWrapper(ETutorialEntries.ttsMeetingPlace, true);
        }
      }

      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back();
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  checkUnlocked() {
    if (this.params.isStoryline) {
      this.lockedForUser = false;
      return;
    }
    if (!this.params.enableGroups) {
      return;
    }

    let onCheck = (locked: boolean, price: IFeatureDef) => {
      this.lockedForUser = locked;
      if (locked) {
        this.infoText += "<p>MP is locked.</p>";
        if (price) {
          this.infoText += "<p>Level " + price.minLevel + " is required.</p>";
        }
      }
    };

    // request reload (user level might have changed)
    this.premiumProvider.checkFeaturePrice(this.params.isStoryline ? EFeatureCode.createGroupStoryline : EFeatureCode.createGroup, null, true).then((price: IFeatureDef) => {
      onCheck(price.lockedForUser ? true : false, price);
    }).catch((err: Error) => {
      console.error(err);
      onCheck(true, null);
    });
  }

  isMeetingPlaceDisabled() {
    return !(this.selectedMeetingPlace && this.inRange);
  }

  getMeetingPlaceSpec(mplace: ILeplaceTreasure) {
    this.selectedMeetingPlace = {
      id: mplace.id,
      name: mplace.place ? mplace.place.place.name : "",
      timestamp: null,
      photoUrl: mplace.photoUrl,
      players: []
    };
  }

  getStorySpecs(story: IStory) {
    this.selectedMeetingPlace = {
      id: -story.id,
      name: story.name,
      timestamp: null,
      photoUrl: story.photoUrl,
      players: []
    };
  }

  /**
   * exit arena view and return to map
   * @param params 
   */
  dismiss(params: IArenaReturnToMapData) {
    if (!params) {
      this.disposeMP();
    }
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  selectNearbyPlayer(p) {
    console.log(p);
  }

  leaveSession() {
    this.disposeMP();
  }

  toggleConnectMeetingPlaceModeWrapper(withAuth: boolean) {
    if (!this.connectedMeetingPlace) {
      return this.connectMeetingPlaceModeWrapper(withAuth);
    } else {
      return this.disconnectMeetingPlaceMode(withAuth);
    }
  }


  async connectMeetingPlaceModeWrapper(withAuth: boolean) {
    // console.log("try connecting");
    // await this.connectMeetingPlaceMode(withAuth);
    // console.log("reset connection");
    // await SleepUtils.sleep(1000);
    // this.disposeMP();
    // await SleepUtils.sleep(1000);
    // console.log("now connecting");
    await this.connectMeetingPlaceMode(withAuth);
  }

  /**
   * wrapper, prevents connecting to the previous group instead (quick hack)
   * @param withAuth 
   */
  async connectMeetingPlaceMode(withAuth: boolean) {
    if (withAuth) {
      this.authorizedMeetingPlaceMode = true;
    }
    this.connecting = true;
    await this.mpManager.loadAuthDataResolve();
    this.mpManager.setMeetingPlaceScope(this.selectedMeetingPlace);
    this.mpManager.switchMeetingPlaceMode();
    await this.mpManager.start(this.synchronized);
    await this.initServicesMeetingPlaceMode();
    this.connectedMeetingPlace = true;
  }

  checkCanExit() {
    return this.mpManager.canExit;
  }

  isSelf(p: IMPStatusDB) {
    return p.playerId === GeneralCache.userId;
  }

  disconnectMeetingPlaceMode(withAuth: boolean) {
    if (withAuth) {
      this.authorizedMeetingPlaceMode = false;
    }
    this.canChat = false;
    this.chatBtnBlink = false;

    this.connecting = false;
    this.disposeMP();
    this.mpDataService.clearGroupCache();
    this.connectedMeetingPlace = false;
    this.nearbyPlayers = [];
  }

  initServicesMeetingPlaceMode(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      this.timeout.other = setTimeout(() => {
        this.watchMeetingPlaceStatus();
        this.watchGroupChatRx();
        this.canChat = true;
        resolve(true);
      }, 100);
    });
    return promise;
  }

  /**
   * subscribe to group status events
   * for display purpose
   * the group sync with dynamic data is done internally by the mp manager
   */
  watchMeetingPlaceStatus() {
    if (!this.subscription.groupStatus) {
      let obs = this.mpManager.getGroupStatusObservable();
      if (!obs) {
        return;
      }
      this.subscription.groupStatus = obs.subscribe((data: IMPGenericGroupStat) => {
        if (data && data.meetingPlace) {
          // let key: string = StringUtils.getPropToStringGenerator()((o: IMPStatusDB) => { o.playerId });
          // ArrayUtils.syncArrayInPlace(this.nearbyPlayers, data.players, key, false);
          this.connecting = false;
          this.nearbyPlayers = data.meetingPlace.players;
          this.statusUpdate = !this.statusUpdate;
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  watchArenaEvent() {
    // connect to arena events e.g. disconnected member
    if (!this.subscription.arenaEvent) {
      this.subscription.arenaEvent = this.mpManager.watchArenaEvent().subscribe((event: number) => {
        if (event) {
          switch (event) {
            case EArenaEvent.requireUserAction:
              // already returned to arena
              break;
            case EArenaEvent.connectionProblem:
              if (!this.timeout.auxMessage) {
                this.infoTextAux = "<p>Connection problems</p><p>Check your data connection</p>";
                this.timeout.auxMessage = setTimeout(() => {
                  this.infoTextAux = null;
                  this.timeout.auxMessage = null;
                }, 5000);
              }
              break;
            case EArenaEvent.chatWindowClosed:
              // this event is received from the mp manager as it handles the chat ui
              this.chatMessageReceived = false;
              this.chatBtnBlink = false;
              break;
            default:
              break;
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  stopWatchArenaEvent() {
    this.subscription.arenaEvent = ResourceManager.clearSub(this.subscription.arenaEvent);
    this.infoTextAux = null;
  }

  /**
   * check when a message arrives so that it can make the button blinking
   */
  watchGroupChatRx() {
    if (!this.subscription.chatRx) {
      this.subscription.chatRx = this.mpManager.watchChatWS().subscribe((event: number) => {
        if (event) {
          this.chatMessageReceived = true;
          this.chatBtnBlink = false;
          setTimeout(() => {
            this.chatBtnBlink = true;
          }, 1);
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  stopWatchGroupChatRx() {
    this.subscription.chatRx = ResourceManager.clearSub(this.subscription.chatRx);
  }

  stopWatchGroupStatus() {
    this.subscription.groupStatus = ResourceManager.clearSub(this.subscription.groupStatus);
    this.mpManager.clearGroupMemberStatus(this.selectedGroup);
  }

  stopWatchMPStateMachine() {
    this.subscription.mpStateMachine = ResourceManager.clearSub(this.subscription.mpStateMachine);
  }

  disposeMP() {
    this.mpManager.quitSession();
    this.stopWatchGroupStatus();
    this.stopWatchMPStateMachine();
    this.stopWatchGroupChatRx();
    this.stopWatchArenaEvent();
  }

  back() {
    this.dismiss(null);
  }

  ngOnDestroy() {
    console.log("mp home on destroy");
    this.clearWatch();
    this.backButton.checkPop(this.vs);
  }

  clearWatch() {
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
    this.subscription = ResourceManager.clearSubObj(this.subscription);
  }

  /**
   * format place display
   */
  formatPlace() {
    if (this.place) {
      let mapsUrl: string = Util.generateMapsLink(this.place.place.googleId, this.place.place.name);
      this.place.aux = {
        mapsURL: mapsUrl,
        photoUrl: null,
        photoUrlCached: null,
        canvasLoaded: false,
        canvasURL: null
      };

      let options: IGetPhotoOptions = {
        noPlaceholder: true,
        redirect: true,
        cacheDisk: true,
        useGeneric: SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value
      };

      if (options.useGeneric) {
        this.placeProvider.getPlaceTemplatePhoto(this.place.place.type).then((p: IPlacePhotoContainer) => {
          this.place.aux.photoUrl = p.photoUrl;
          this.placePhotoUrlSource = p.photoUrl;
        });
      } else {
        this.locationUtilsWizard.loadPlacePhotoWizard1(this.place, options).then(() => {
          this.placePhotoUrlSource = this.place.place.photoUrl;
        });
      }
    }
  }

  options() {
    let actions: IPopoverActions = {};
    actions = {
      tutorial: {
        name: "Tutorial",
        code: 10,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      }
    };

    if (AppSettings.testerMode) {
      let actions2: IPopoverActions = {
        simulateConnectionLost: {
          name: "Test connection lost*",
          code: 1,
          enabled: true
        },
        simulateConnectionResume: {
          name: "Test connection resume*",
          code: 2,
          enabled: true
        },
        chatHUD: {
          name: "Chat HUD*",
          code: 3,
          enabled: true
        }
      };
      Object.assign(actions, actions2);
    }

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 1:
          this.mpManager.simulateConnectionLost();
          break;
        case 2:
          this.mpManager.simulateConnectionResume();
          break;
        case 3:
          this.showChatModal();
          break;
        case 10:
          this.tutorials.showTutorialNoAction(this.title + " Tutorial", 200 + ETreasureType.arena, null, null, true);
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  trackByFn(_index: number, item: IMPStatusDB) {
    // return index; // or item.id, if your image has an ID
    return item.playerId;
  }

  async showChatModal() {
    // do not disconnect on chat window close
    this.mpManager.openChatWindow(false, true);
  }

  onTapSelectGraphic(code: number) {
    switch (code) {
      case 1:
        this.toggleConnectMeetingPlaceModeWrapper(true);
        break;
      case 2:
        if (this.canChat) {
          this.showChatModal();
        }
        break;
      case 3:
        if (!(this.mpLocked || this.lockedForUser)) {
          this.viewGroups();
        }
        break;
    }
  }
}
