import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MPGroupsListPage } from './mp-groups-list.page';
import { PagesModule } from '../../pages.module';

const routes: Routes = [
  {
    path: '',
    component: MPGroupsListPage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [MPGroupsListPage]
})
export class MPGroupsListPageModule { }
