<div *ngFor="let e of activityContainer.validateDisp | values">
  <div
    *ngIf="e.show && ((showExtra && e.category === category.extra) || (!showExtra && (e.category === category.default)) || e.category === category.all)">
    <div class="text-div-margin" *ngIf="e.showText">
      <span class="info-text-m bold" [ngClass]="flat ? '' : 'text-color-overlay-s'">{{getText(e)}}
      </span>
      <!-- <blink-span baseClass="info-text-m bold" [ngClass]="flat ? '' : 'text-color-overlay-s'" [text]="getText(e)"
        [set]="e.blink"></blink-span> -->
    </div>
    <!-- [ngClass]="getPbClass(e)" -->
    <div *ngIf="e.mode === dispModes.slider" class="padding-horizontal-s">
      <!-- <mat-progress-bar [ngClass]="getCustomCss(e)" mode="determinate" [value]="e.value"></mat-progress-bar> -->
      <blink-progress-bar [enableBlink]="active" [config]="e"></blink-progress-bar>
    </div>
    <div *ngIf="e.mode === dispModes.sliderLMH" class="padding-horizontal-s">
      <!-- <mat-progress-bar [ngClass]="getCustomCss(e)" mode="determinate" [value]="e.value"></mat-progress-bar> -->
      <ion-row *ngIf="e.dispLevels">
        <ion-col size="4">
          <ion-button [disabled]="!e.dispLevels[0]" class="action-button-fill-xxs" [ngClass]="getSliderTheme(e, 0)">
          </ion-button>
        </ion-col>
        <ion-col size="4">
          <ion-button [disabled]="!e.dispLevels[1]" class="action-button-fill-xxs" [ngClass]="getSliderTheme(e, 1)">
          </ion-button>
        </ion-col>
        <ion-col size="4">
          <ion-button [disabled]="!e.dispLevels[2]" class="action-button-fill-xxs" [ngClass]="getSliderTheme(e, 2)">
          </ion-button>
        </ion-col>
      </ion-row>
    </div>
    <div *ngIf="e.mode === dispModes.soundButton" class="padding-horizontal-s">
      <div *ngIf="!e.dispAux" class="text-center">
        <span class="info-text-m text-color-overlay-s">View not available</span>
      </div>
      <sound-button *ngIf="e.dispAux" (action)="onSelect($event)" class="full-w progress-rounded" mode="determinate"
        [data]="e.dispAux" [update]="update">
      </sound-button>
    </div>

    <div *ngIf="e.mode === dispModes.inputButton" class="padding-horizontal-s">
      <ion-button class="action-button-fill" [ngClass]="e.highlight?'button-color-accent':'button-color-primary'"
        ion-button (click)="e.callback()">
        <span class="button-font-size-m">
          {{e.name}}
        </span>
      </ion-button>
    </div>
  </div>
</div>