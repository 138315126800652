import { Component, OnInit, OnDestroy, OnChanges, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { EClass } from 'src/app/classes/def/app/ui';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { IHudConfig } from 'src/app/classes/def/app/hud';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';


@Component({
  selector: 'leplace-hud-slide',
  templateUrl: './leplace-hud-slide.component.html',
  styleUrls: ['./leplace-hud-slide.component.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0,
        transform: 'translateY(100%) scale(1, 0)'
      })),
      state('active', style({
        // transform: 'translateY(0)',
        opacity: 1,
        transform: 'translateY(0) scale(1, 1)'
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ]
})
export class LeplaceHudSlideComponent implements OnInit, OnDestroy, OnChanges {
  @Input()
  data: string;
  @Input()
  class: string;
  @Input()
  show: boolean;
  @Input()
  config: IHudConfig;
  @Input()
  scrolling: boolean;

  showState: string = "inactive";
  showCtrl: boolean = false;

  outerClass: string = "";
  innerClass: string = "";
  tintClass: string = "";

  timeout;

  constructor(

  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      // console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'show':
            this.setHudState(chg.currentValue);
            break;
          case 'data':
            this.setup();
            break;
        }
      }
    }
  }

  ngOnInit() {
    console.log(this.config);
    this.setup();
  }

  setup() {
    if (!this.config) {
      this.outerClass = EClass.HUD_BAN_NORMAL;
      this.innerClass = EClass.HUD_INNER_INFO;
      this.tintClass = EClass.HUD_INFO;
    } else {
      this.outerClass = this.config.outerClass;
      this.innerClass = this.config.innerClass;
      this.tintClass = this.config.tintClass;
    }
    this.outerClass += " text-center";
    if (this.data != null) {
      let shouldScroll: boolean = this.data.length > EMessageTrim.xpMessageHud;
      if (shouldScroll) {
        console.log("should scroll");
        this.outerClass = this.outerClass.replace("hud-xp", "hud-xp-ml");
      }
    }
  }

  setHudState(enabled: boolean) {
    console.log("set hud state: ", enabled);
    this.timeout = ResourceManager.clearTimeout(this.timeout);
    if (enabled) {
      this.showCtrl = true;
      this.showState = "active";
    } else {
      this.showState = "inactive";
      this.timeout = setTimeout(() => {
        this.showCtrl = false;
      }, 500);
    }
  }


  ngOnDestroy() {
    this.timeout = ResourceManager.clearTimeout(this.timeout);
  }
}

