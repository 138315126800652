import { Injectable, NgZone } from "@angular/core";
import { Platform } from "@ionic/angular";
import { SettingsManagerService } from "../settings-manager";
import { IPlatformFlags } from "../../../classes/def/app/platform";
import { Subscription } from "rxjs";
import { ResourceManager } from "../../../classes/general/resource-manager";
import { BackButtonEventDetail } from '@ionic/core';
import { EKeyCodes } from 'src/app/classes/utils/general';
import { IViewSpecs } from 'src/app/classes/def/nav-params/general';
import { KeyHandlerService } from './key-handler';
import { GeneralCache } from "src/app/classes/app/general-cache";
import { WebviewUtilsService } from "../../app/utils/webview-utils";

@Injectable({
    providedIn: 'root'
})
export class BackButtonService {

    callbackStack: (() => any)[] = [];
    platform: IPlatformFlags = {} as IPlatformFlags;
    backButtonSub: Subscription;
    keyEventSub: Subscription;
    // keyPressEnabled: boolean = true;
    keyPressEnabled: boolean = true;
    allowBrowserHistory: boolean = false;

    constructor(
        public plt: Platform,
        public webView: WebviewUtilsService,
        public settingsProvider: SettingsManagerService,
        public ngZone: NgZone,
        public keyHandler: KeyHandlerService
    ) {
        console.log("back button service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                console.log("platform flags loaded");
                this.platform = SettingsManagerService.settings.platformFlags;
                if (this.platform.WEB) {
                    if (this.allowBrowserHistory) {
                        this.setBrowserHistory();
                    } else {
                        this.setBrowserHistory();
                    }
                }
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    exitApp() {
        navigator['app'].exitApp();
    }

    enableGlobalKeyPress() {
        this.keyPressEnabled = true;
    }

    disableGLobalKeyPress() {
        this.keyPressEnabled = false;
    }

    /**
     * register back button action
     * wrapper for mobile/web
     * @param callback 
     */
    registerBackButtonAction(callback: () => any) {
        if (!this.platform.WEB) {
            if (this.platform.ANDROID) {
                this.backButtonSub = ResourceManager.clearSub(this.backButtonSub);
                this.backButtonSub = this.plt.backButton.subscribe((bed: BackButtonEventDetail) => {
                    console.log("back button action registered: ", bed);
                    // setTimeout(() => {
                    //     callback();
                    // }, 100);
                    this.ngZone.run(() => {
                        if (callback) {
                            callback();
                        } else {
                            console.warn("undefined callback");
                        }
                    });
                }, (err: Error) => {
                    console.error(err);
                });
            } else {
                // for iOS use swipe gesture
            }
        } else {
            // web
            // Register browser back button action(s)
            // window.onpopstate = (evt) => {
            //     console.log("onpopstate: ", evt);
            //     this.handlePopStateEvent();
            // };
            this.handleSetBrowserHistory();

            if (!GeneralCache.isPublicDistribution || GeneralCache.canBeTester) {
                ResourceManager.clearSub(this.keyEventSub);
                let state: number = 0;
                this.keyEventSub = this.keyHandler.watchKeyPress().subscribe((key: number) => {
                    if (this.keyPressEnabled) {
                        switch (key) {
                            case EKeyCodes.b:
                                switch (state) {
                                    case 0:
                                        state = 1;
                                        break;
                                    case 1:
                                        state = 0;
                                        console.log("back key detected");
                                        if (callback) {
                                            callback();
                                        } else {
                                            console.warn("undefined callback");
                                        }
                                        break;
                                }
                                break;
                            default:
                                break;
                        }
                    }
                }, (err: Error) => {
                    console.error(err);
                });
            }
        }
    }

    /**
     * run callback
     */
    runCurrentBackButtonAction() {
        console.log("bb run callback");
        let callback: () => any = this.callbackStack.pop();
        if (callback) {
            callback();
            return true;
        }
        return false;
    }

    /**
     * push back button action to stack 
     * @param callback 
     */
    push(callback: () => any) {
        console.log("callback stack push: ", callback);
        this.callbackStack.push(callback);
        console.log("callback stack: ", this.callbackStack);
        this.registerBackButtonAction(callback);
    }

    /**
     * dismiss current back button action in the stack and 
     * retrieve and set previous back button action
     */
    pop(): () => any {
        let callback: () => any = null;
        let callbackStackLength: number = this.callbackStack.length;
        if (callbackStackLength > 0) {
            callback = this.callbackStack.pop();
        }
        console.log("callback stack length before pop: " + callbackStackLength);
        console.log("callback stack: ", this.callbackStack);
        if (callbackStackLength > 1) {
            // check next callback
            let popCallback: () => any = this.callbackStack.pop();
            this.registerBackButtonAction(popCallback);
            this.callbackStack.push(popCallback);
        } else {
            this.registerBackButtonAction(null);
        }
        return callback;
    }

    reset() {
        this.callbackStack = [];
        this.backButtonSub = ResourceManager.clearSub(this.backButtonSub);
    }


    /**
     * replace the last back button action and set
     * @param callback 
     */
    replace(callback: () => any) {
        console.log("replace callback");
        let callbackStackLength: number = this.callbackStack.length;
        if (callbackStackLength > 0) {
            this.callbackStack.pop();
        }
        this.push(callback);
    }


    handleSetBrowserHistory() {
        if (this.platform.WEB) {
            if (!this.allowBrowserHistory) {
                // Disabling browser back navigation in Angular
                this.setBrowserHistory();
            }
        }
    }

    /**
     * push back button action to stack 
     * or
     * replace the last back button action and set
     * used for views that can be opened either as root or modals
     * @param callback 
     */
    pushOrReplace(callback: () => any, vs: IViewSpecs) {
        if (vs) {
            if (vs.isModal && !vs.addToStack) {
                this.push(callback);
            } else {
                this.replace(callback);
            }
        } else {
            this.replace(callback);
        }
    }

    checkPop(vs: IViewSpecs) {
        if (vs && vs.isModal && !vs.addToStack) {
            this.pop();
        }
    }

    /**
     *  Register browser back button action(s)
     */
    handlePopStateEvent() {
        if (this.allowBrowserHistory) {
            if (this.runCurrentBackButtonAction()) {
                this.setBrowserHistory();
            }
            return true;
        } else {
            this.setBrowserHistory();
            return false;
        }
    }

    /**
     * Fake browser history on each view enter
     */
    setBrowserHistory() {
        console.log("set browser history");
        // Fake browser history on each view enter
        window.history.pushState(null, '');
    }


    /**
     * handle swipe event 
     * iOS use as back button function
     * Android nop
     * returns false if swipe should be handled by the caller instead
     * @param e 
     */
    handleSwipeEvent(e) {
        console.log("bb handle swipe event: ", e);
        if (this.platform.IOS) {
            console.log("bb handle ios swipe");
            if (e) {
                switch (e.direction) {
                    case 2:
                        // right to left

                        break;
                    case 4:
                        // left to right
                        // back button iOS
                        this.runCurrentBackButtonAction();
                        break;
                }
            }
            return true;
        } else {
            return false;
        }
    }
}
