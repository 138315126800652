
import { Injectable } from '@angular/core';
import { UiExtensionService } from '../ui/ui-extension';
import { AuthRequestService, ILoginContainer } from './auth-request';
import { Messages } from '../../../classes/def/app/messages';
import { AnalyticsService } from '../apis/analytics';
import { ErrorMessage } from '../../../classes/general/error-message';
import { IGoogleProfileData, IFacebookProfileData, IFacebookLoginStatus, IAppleProfileData } from '../../../classes/def/social/profile';
import { IUserLoginResponse } from '../../../classes/def/user/general';
import { GeneralCache } from '../../../classes/app/general-cache';
import { IPopoverInputs, IPopoverActions } from 'src/app/classes/def/app/modal-interaction';

/**
 * encapsulates login flow for external providers (google, facebook)
 * calls external apis and then request authentication on the server
 */
@Injectable({
    providedIn: 'root'
})
export class LoginExtService {

    constructor(
        public authentication: AuthRequestService,
        public uiext: UiExtensionService,
        public analytics: AnalyticsService
    ) {
        console.log("login ext service created");
    }

    /**
     * login with email on the server
     * @param email 
     * @param password 
     */
    loginWithEmail(email: string, password: string) {
        let promise = new Promise((resolve, reject) => {
            this.authentication.submitLoginWithEmail(email, password).then((result: ILoginContainer<IUserLoginResponse>) => {
                this.afterLoginResponse(result);
                resolve(true);
            }).catch((err: Error) => {
                this.analytics.dispatchError(err, "login");
                this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
                reject(err);
            });
        });
        return promise;
    }

    /**
     * login with google
     * the backend validates the access token and retrieves the username and email from google api
     */
    loginWithGoogle(googleData: IGoogleProfileData) {
        let promise = new Promise((resolve, reject) => {
            let signinPromise: Promise<IGoogleProfileData>;
            if (!googleData) {
                signinPromise = this.authentication.signInWithGoogleService();
            } else {
                signinPromise = Promise.resolve(googleData);
            }
            signinPromise.then((res: IGoogleProfileData) => {
                console.log("sign in with google: ", res);
                // this.submitSocialLogin(this.getGoogleLoginData(res))
                this.authentication.submitLoginWithGoogle(res.token, res.id).then(async (result: ILoginContainer<IUserLoginResponse>) => {
                    GeneralCache.externalLogin = true;
                    this.afterLoginResponse(result);
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    this.analytics.dispatchError(err, "login");
                    this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
                    reject(err);
                });
            }).catch((err: Error) => {
                console.error(err);
                let message: string = Messages.msg.extLoginFailed.after.sub;
                let extMessage: string = ErrorMessage.parse(err, null);
                if (extMessage) {
                    message += " Error reference: " + extMessage;
                    this.uiext.showAlertNoAction(Messages.msg.extLoginFailed.after.msg, message);
                    this.analytics.dispatchError(err, "login");
                }
                reject(err);
            });
        });
        return promise;
    }

    /**
     * login with apple
     * the backend validates the access token and retrieves the username and email from apple
     */
    signInWithApple() {
        let promise = new Promise((resolve, reject) => {
            this.authentication.signInWithApple().then((res: IAppleProfileData) => {
                // console.log("sign in with google: ", res);
                // this.submitSocialLogin(this.getGoogleLoginData(res))
                let promiseName: Promise<boolean> = new Promise((resolve) => {
                    if (!res.name || res.name === "" || res.name === " ") {
                        // name is not returned by the apple sign in (happens if the user has previously signed in with apple)
                        // check signed up
                        this.authentication.checkUserSignedUp(res.email, res).then((signedUp: boolean) => {
                            GeneralCache.externalLogin = true;
                            if (!signedUp) {
                                // the user has (probably) previously deleted his account
                                // request enter name manually
                                this.changeUsername().then((username: string) => {
                                    if (username != null) {
                                        res.name = username;
                                        resolve(true);
                                    } else {
                                        resolve(false);
                                    }
                                }).catch((err: Error) => {
                                    console.error(err);
                                    resolve(false);
                                })
                            } else {
                                // the username was (hopefully) registered when first signed up
                                resolve(true);
                            }
                        }).catch((err: Error) => {
                            console.error(err);
                            resolve(false);
                        });
                    } else {
                        resolve(true);
                    }
                });

                promiseName.then((ok: boolean) => {
                    if (ok) {
                        // proceed with signup
                        this.authentication.submitLoginWithApple(res.idToken, res.authorizationCode, res).then((result: ILoginContainer<IUserLoginResponse>) => {
                            this.afterLoginResponse(result);
                            resolve(true);
                        }).catch((err: Error) => {
                            console.error(err);
                            this.analytics.dispatchError(err, "login");
                            this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
                            reject(err);
                        });
                    } else {
                        this.uiext.showAlertNoAction(Messages.msg.usernameUndefined.after.msg, Messages.msg.usernameUndefined.after.sub);
                        reject(new Error("Username is undefined"));
                    }
                });
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    /**
     * override login username 
     * e.g. apple sign in does not return the username the second time it's called
     * which might lead to some undefined username signups in extreme cases
     */
    changeUsername(): Promise<string> {
        let promise: Promise<string> = new Promise((resolve, reject) => {
            let popoverInputs: IPopoverInputs = {
                username: {
                    // the name is the actual tag here
                    name: "username",
                    value: "",
                    placeholder: "username",
                    enabled: true
                }
            };
            let popoverActions: IPopoverActions = {
                cancel: {
                    name: "Cancel",
                    code: 0,
                    enabled: true
                },
                ok: {
                    name: "Ok",
                    code: 1,
                    enabled: true,
                    handler: (data) => {
                        console.log("handler ok: ", data);
                        if (!data.username) {
                            return false;
                        }
                        return true;
                    }
                }
            };

            this.uiext.showCustomAlertInput("Create username", null, popoverInputs, popoverActions, false).then((res: any) => {
                let code = res.code;
                let data = res.data;
                switch (code) {
                    case 0:
                        // cancel signup
                        resolve(null);
                        break;
                    case 1:
                        // console.log(data.username);
                        resolve(data.username);
                        break;
                }
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    /**
     * sign in with facebook
     */
    loginWithFacebook() {
        let promise = new Promise((resolve, reject) => {
            this.authentication.getFacebookLoginStatus().then((res: IFacebookLoginStatus) => {
                console.log(res);
                if (res.status === "connected") {
                    // already signed in with facebook app
                    this.authentication.getFacebookUserData().then((fbData: IFacebookProfileData) => {
                        this.loginWithFacebookCore(res.authResponse.accessToken, fbData).then(() => {
                            resolve(true);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                    }).catch((err: Error) => {
                        console.error(err);
                        this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, "Facebook error");
                        reject(err);
                    });
                } else {
                    // sign in with facebook app
                    this.authentication.signInWithFacebookService().then((accessToken: string) => {
                        this.authentication.getFacebookUserData().then((fbData: IFacebookProfileData) => {
                            this.loginWithFacebookCore(accessToken, fbData).then(() => {
                                resolve(true);
                            }).catch((err: Error) => {
                                reject(err);
                            });
                        }).catch((err: Error) => {
                            console.error(err);
                            this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, "Facebook error");
                            reject(err);
                        });
                    }).catch((err: Error) => {
                        console.error(err);
                        this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, "Facebook error");
                        reject(err);
                    });
                }
            }).catch((err: Error) => {
                console.error(err);
                this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, "Facebook error");
                reject(err);
            });
        });
        return promise;
    }

    /**
     * login with facebook
     * the backend validates the access token and retrieves the username and email from the graph api
     */
    private loginWithFacebookCore(accessToken: string, fbData: IFacebookProfileData) {
        let promise = new Promise((resolve, reject) => {
            console.log("login with facebook core, data: ", fbData);
            this.authentication.submitLoginWithFacebook(accessToken, fbData).then((result: ILoginContainer<IUserLoginResponse>) => {
                GeneralCache.externalLogin = true;
                this.afterLoginResponse(result);
                resolve(true);
            }).catch((err: Error) => {
                this.analytics.dispatchError(err, "login");
                this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
                reject(err);
            });
        });
        return promise;
    }

    /**
     * check response data
     * @param result 
     */
    private afterLoginResponse(result: ILoginContainer<IUserLoginResponse>) {
        let data: IUserLoginResponse = result.user;
        // if ((!data || !data.valid) && !justSignedUp) {
        //     this.uiext.showAlertNoAction(Messages.msg.accountNotValidated.after.msg, Messages.msg.accountNotValidated.after.sub);
        // }

        if (data != null) {
            GeneralCache.agreementPending = data.newUser;

            if (data.userId != null) {
                this.analytics.setUserId(data.userId);
            }
        }

        // this.notifications.registerOnesignalAppId();
        GeneralCache.justLoggedIn = true;
        console.log("logged in");
        // the app component will redirect
    }

}
