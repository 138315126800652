import { Component, OnInit, Input, Output, EventEmitter, OnChanges, OnDestroy, SimpleChanges, SimpleChange } from '@angular/core';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { timer } from 'rxjs';
import { IButtonStyle, ButtonStyles } from 'src/app/classes/def/app/ui';

@Component({
  selector: 'cooldown-button',
  templateUrl: './cooldown-button.component.html',
  styleUrls: ['./cooldown-button.component.scss'],
})
export class CooldownButtonComponent implements OnInit, OnChanges, OnDestroy {

  @Input()
  name: string;
  @Input()
  icon: string;
  @Input()
  active: boolean;
  @Input()
  triggerCooldown: boolean;
  @Input()
  cooldown: number;
  @Input()
  fab: boolean;
  @Input()
  defaultClass: string;
  @Input()
  highClass: string;
  @Input()
  noBorder: boolean;
  @Input()
  xs: boolean;
  @Input()
  customIcon: boolean;
  @Input()
  customBaseClass: string;
  @Input()
  autoTriggerMode: boolean;

  /**
   * set global theme incl. text: primary, alternate, warn, accent
   */
  @Input()
  theme: string;

  @Output()
  select: EventEmitter<any> = new EventEmitter();

  state: boolean = false;

  disabled: boolean = false;
  inCooldown: boolean = false;

  actualName: string = "";

  timerSub = null;
  cooldownTimeout;

  btnBaseClass: string = "action-button-fill";
  fabBtnBaseClass: string = "round-fab round-fab-size";

  btnDefaultClass: string;
  btnHighClass: string;
  fabBtnDefaultClass: string;
  fabBtnHighClass: string;

  textDefaultClass: string;
  textHighClass: string;

  iconOnly: boolean = false;

  constructor() { }

  checkName() {
    if (!this.actualName) {
      this.iconOnly = true;
    } else {
      this.iconOnly = false;
    }
  }

  setTheme(mode: string) {
    if (!mode) {
      return;
    }

    let style: IButtonStyle = ButtonStyles.style[mode];
    if (!style) {
      return;
    }

    this.defaultClass = style.color;
    this.highClass = style.highColor;
    this.textDefaultClass = style.textColor;
    this.textHighClass = style.highTextColor;
  }


  ngOnInit() {
    this.checkName();

    this.setTheme(this.theme);

    if (this.xs) {
      this.btnBaseClass = "action-button-xs";
      this.fab = false;
      this.iconOnly = true;
    }

    if (this.customBaseClass) {
      this.btnBaseClass = this.customBaseClass;
    }

    if (this.defaultClass) {
      this.btnDefaultClass = this.btnBaseClass + " " + this.defaultClass;
      this.fabBtnHighClass = this.fabBtnBaseClass + " " + this.defaultClass;
    }
    if (this.highClass) {
      this.btnHighClass = this.btnBaseClass + " " + this.highClass;
      this.fabBtnHighClass = this.fabBtnBaseClass + " " + this.highClass;
    }

    if (this.noBorder) {
      this.btnDefaultClass += " no-border-width";
      this.fabBtnDefaultClass += " no-border-width";
      this.btnHighClass += " no-border-width";
      this.fabBtnHighClass += " no-border-width";
    }

    this.disabled = !this.active;
  }

  ngOnDestroy() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.cooldownTimeout = ResourceManager.clearTimeout(this.cooldownTimeout);
  }


  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      // console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'triggerCooldown':
            this.setCooldown();
            break;
          case 'active':
            this.disabled = !this.active;
            break;
        }
      }
    }
  }

  setActualName(counter: number) {
    if (counter > 0) {
      if (!this.name) {
        this.actualName = "" + counter;
      } else {
        this.actualName = this.name + " (" + counter + ")";
      }
    } else {
      if (!this.name) {
        this.actualName = null;
      } else {
        this.actualName = this.name;
      }
    }
  }

  setCooldown() {
    if (!this.inCooldown) {
      this.inCooldown = true;
      if (this.cooldown) {
        let timer1 = timer(0, 1000);
        let counter: number = this.cooldown;
        this.timerSub = timer1.subscribe(() => {
          counter -= 1;
          this.setActualName(counter);
          this.checkName();
          if (counter == 0) {
            this.timerSub = ResourceManager.clearSub(this.timerSub);
            this.inCooldown = false;
            if (this.autoTriggerMode) {
              this.clicked(0);
            }
            this.cooldownTimeout = ResourceManager.clearTimeout(this.cooldownTimeout);
          }
        }, (err: Error) => {
          console.error(err);
        });

        // fallback
        this.cooldownTimeout = setTimeout(() => {
          this.timerSub = ResourceManager.clearSub(this.timerSub);
          this.inCooldown = false;
          if (this.autoTriggerMode) {
            this.clicked(0);
          }
        }, this.cooldown * 1000);
      }
    } else {
      // only enabled on cooldown complete
      // this.state = true;
    }
  }

  clicked(index: number) {
    this.select.emit([index]);
  }
}
