import { Directive, ElementRef, Renderer2, Input, OnInit } from '@angular/core';

@Directive({
    selector: '[dynamicCol]'
})

export class DynamicColDirective implements OnInit {
    @Input('dynamicCol') value: string;

    constructor(private el: ElementRef, private _renderer: Renderer2) { }

    ngOnInit() {
        this._renderer.setAttribute(this.el.nativeElement, 'col-' + this.value, '');
    }
}
