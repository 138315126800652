import { ILocationContainer, IBackendLocation } from "../places/backend-location";
import { IGameItemIAP } from "../items/game-item";
import { IOpenStoryCache } from "src/app/services/data/story";
import { ICurrency } from "../business/payment";


export interface IStoriesPageResponse {
    stories: IStory[];
    page: number;
    pages: number;
}

export interface IStoriesPageRequest {
    lat: number,
    lng: number,
    categoryCode?: number,
    providerId?: number,
    /** for local stories */
    cityId?: number,
    includeBeta?: boolean,
    name?: string,
    page?: number,
    token?: string,
    tokenType?: number,
    /** tester mode override (hide dev stories) */
    testerMode?: boolean,
    hideDevStories?: boolean // additional lock to prevent unintended access to beta content
}

export interface IMasterLockResponse {
    message: string;
    unlocked: boolean;
    /**
     * response code
     * 0 - unlocked
     * 1 - locked/level
     * 2 - locked/coins
     */
    code: number;
    aux?: {
        reload: boolean,
        alert: boolean
    };
}

export enum EStoryUnlockedCode {
    unlocked = 0,
    lockedLevel = 1,
    lockedCoins = 2
}

export enum EStoryUnlock {
    none = 0,
    required = 1,
    redeemOnly = 2
}

export enum EStoryUnlockTokenType {
    leplace = 1,
    booking = 2
}

export enum EStoryUnlockScope {
    leplace = 1,
    partner = 2
}

export enum EStoryUnlockType {
    branded = 1,
    partner = 2,
    short = 3,
    redeem = 4,
    redeemShort = 5,
    searchOnly = 6,
    searchOnlyShort = 7
}

export enum EStoryReleaseFlag {
    released = 0,
    devOnly = 1,
    unlisted = 2
}

export enum EStoryMode {
    linear = 1,
    mp = 2,
    preload = 3
}

export enum EGameplayMode {
    single = 1,
    teams = 2
}

export interface IStoryResponse {
    story: IStory;
    locked: boolean;
    masterLock: IMasterLockResponse;
    message: string;
}

export interface IMapStyle {
    id: number;
    code: number;
    name: string;
    version: number;
    mapId: string;
    config?: any;
    lineColor?: string;
}

export interface IStory {
    id: number;
    name: string;
    shortName?: string;
    description: string;
    finishedDescription: string;

    locs: ILocationContainer[];
    /**
     * db locations
     */
    locations: IBackendLocation[];
    dynamic: number;
    // favorited: boolean;
    // active: boolean;
    islocal: boolean;
    /**
     * dynamic, from server
     */
    locked: boolean;
    categoryCode: number;
    categoryName: string;
    timestamp: string;
    lat: number;
    lng: number;
    level: number;
    regionType: string;
    appVersionMin: number;
    /**
     * dynamic, from server
     */
    rating: number;
    /**
     * dynamic, from server
     */
    ratingCount: number;
    providerId: number;
    mapStyleCode: number;
    mapStyle: IMapStyle;
    photoUrl: string;
    photoUrlSmall: string;
    videoUrl?: string;
    xpScaleFactor?: number;

    priceCoins: number;
    devOnly: number;
    providers: IStoryProvider[];
    primaryProvider?: IBackendLocation;

    /**
     * the standard coin reward cap for the story
     */
    rewardCoins: number;
    rewardXp: number;
    rewardXpUnlock: number;

    itemIapCode: number;
    itemIapCodePromo?: number;
    itemIap?: IGameItemIAP;
    itemIapPromo?: IGameItemIAP;

    storyPhotos?: IStoryPhoto[];
    /**
     * dynamic, from server
     */
    premium: boolean;
    unlockCode: string;
    qrUnlock: number;
    partnerUnlock?: number;
    alternativeCheckout?: number;
    // check if story is linear or mp
    mode: number;
    // check custom map stories (private treasure map)
    customMap: number;
    // check enable treasures on the map
    enableTreasures: number;
    droneOnly: number;
    enableAr: number;
    showBuildings?: number;
    showIndoor?: number;
    teams: number;
    teamPurchase?: number;
    checkpointReview: number;
    // search by name
    found?: boolean;
    // IAP loading
    loading?: boolean;
    // resume interrupted context
    resume?: boolean;
    localCache?: IOpenStoryCache;
    language?: IDBModelLanguage;

    audioGuide?: number;
    navMode?: number;
    collectMode?: number;
    zoomLevelDelta?: number;

    currency?: ICurrency;
    defaultPrice?: string;
    promoPrice?: string;
    // leplace connect link
    connectLink?: string;
    minAge?: number;
}

export interface IDBModelLanguage {
    id: number;
    name: string;
    nameLocale: string;
    langCode: string;
    icon: string;
    photoUrl: string;
    ttsVoice: string;
}

export interface ITreasureStoryLocation {
    id: number;
    storyId: number;
    locationId: number;
    treasureId: number;
    activityCode: number;
    name: string;
    description: string;
    shortDescription: string;
    finishedDescription: string;
    photoUrl: string;
    level: number;
    hiddenPreview: number;
    hiddenMarkerMode: number;
}

export interface IStoryPhoto {
    name: string;
    description: string;
    photoUrl: string;
}

export enum EHiddenMarkerModes {
    show = 0,
    index = 1,
    circle = 2,
    noFrame = 3
}

export interface IUserStory {
    id: number;
    userId: number;
    storyId: number;
    done: number;
}

export interface IStoryProvider {
    id: number;
    storyId: number;
    locationId: number;
    location: IBackendLocation;
}

export interface ILocalStories {
    stories: IStory[];
    build: number;
}

export interface IStoryExtended {
    story: IStory;
    storyProgress: number;
    hide: boolean;
    show: boolean;
    photoUrl: string;
    color: string;
    canvasUrl?: string;
    canvasLoaded?: boolean;
    levels?: boolean[];
    large: boolean;
    modeIcon: string;
    modeText: string;
}

export interface IStoryExtendedGroup {
    hide: boolean;
    show: boolean;
    categoryCode: number;
    categoryName: string;
    stories: IStoryExtended[];
}

export enum ECheckpointMarkerStatus {
    done = ".DONE",
    skipped = ".SKIP",
    failed = ".FAIL"
}

export interface IStoryUnlockContext {
    valid: boolean,
    resolveUnlocked: boolean
}

export interface IStoryUnlockCode {
    storyId: number,
    unlockCode: string,
    enabled: number,
    scope: number,
    type: number,
    fixed: number,
    timestamp: string,
    timestampValidated: string,
    locked: number,
    credits: number,
    creditsLeft: number,
}

