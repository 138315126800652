<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs" class="camera-scan-frame-view"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <!-- <div class="scrollbar-y padding-s scroll-content-modal-1">
    
  </div> -->

  <div *ngIf="loading" class="progress-spinner-center">
    <mat-progress-spinner [diameter]="40" color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>

  <div class="contentarea">
    <div class="camera">

      <!-- <video playsinline id="video" #inputcamera>Camera stream not available.</video> -->

      <!-- <div #inputreader id="reader" width="600px" height="600px" style="width: 600px; height: 600px;"></div> -->

      <div id="reader" width="600px"></div>

      <!-- <video playsinline id="video" #inputcamera>Camera stream not available.</video>

      <div *ngIf="!loading && !showPhoto" class="actionbutton-row">
        <button class="actionbutton actionbutton-close" (click)="dismiss(null)">
          <icon-wrapper slot="icon-only" [icon]="appIconsStandard.close" [custom]="false">
          </icon-wrapper>
        </button>
        <button class="actionbutton actionbutton-switch" [disabled]="!enable.take" (click)="switchCameraFacingMode()">
          <icon-wrapper slot="icon-only" [icon]="appIconsStandard.sync" [custom]="false">
          </icon-wrapper>
        </button>
        <button class="actionbutton actionbutton-take" [disabled]="!enable.take" (click)="takePhotoWrapper()">
          <icon-wrapper slot="icon-only" [icon]="appIconsStandard.takePhoto" [custom]="false" [huge]="true">
          </icon-wrapper>
        </button>
      </div>

      <div *ngIf="!loading && showPhoto" class="actionbutton-row">
        <button [disabled]="!enable.retry" class="actionbutton actionbutton-retry" (click)="retry()">
          <icon-wrapper slot="icon-only" [icon]="appIconsStandard.refresh" [custom]="false">
          </icon-wrapper>
        </button>
        <button [disabled]="!enable.save" class="actionbutton actionbutton-save" (click)="savePhoto()">
          <icon-wrapper slot="icon-only" [icon]="appIconsStandard.accept" [custom]="false">
          </icon-wrapper>
        </button>
      </div> -->
    </div>
  </div>
</modal-container>