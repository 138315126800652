import { Injectable, ElementRef } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ShadowDomService {

  constructor() {


  }  

  /**
  * workaround for overriding shadow dom css for ion-menu
  * @param el 
  */
  updateSidemenu(el: ElementRef) {

    const styles = `
         background: rgba(0, 0, 0, 0) !important;
        .menu-header {
            background: var(--background-linear-gradient-menu);
        }      
        .menu-inner {
          background: rgba(0, 0, 0, 0) !important;
          border-bottom-right-radius: 40px;
          border-top-right-radius: 40px;
        }
        .scroll-content {
          background: var(--background-linear-gradient-menu);  
          // ios
          border-bottom-right-radius: var(--border-radius-inner-global);
        }

        ion-list .list-md {
          background: var(--background-linear-gradient-menu);  
          // ios
          border-bottom-right-radius: var(--border-radius-inner-global);
        }
  
        .header-md::after, .header-ios::after {
            background: var(--lp-color-bg);
        }
      
        .inner-scroll {
          background: var(--transparent);
        }`;

    this.updateElement(el, 'ion-menu', styles);
  }

  /**
   * workaround for overriding shadow dom css for ion component e.g. ion-menu
   * @param el 
   * @param name
   * @param styles
   */
  updateElement(el: ElementRef, name: string, styles: string) {
    console.log("update element shadow dom: ", el);
    const menu = (el.nativeElement.querySelector(name) as HTMLElement);
    this.overrideShadowDomCSS(menu, styles);
  }


  private overrideShadowDomCSS(host: HTMLElement, styles: string) {
    // host is the element that holds the shadow root:
    let style = document.createElement('style');
    style.innerHTML = styles;
    host.shadowRoot.appendChild(style);
  }
}
