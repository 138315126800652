<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div class="padding-s">
    <div [ngClass]="direct ? '' : 'scroll-type-frame-list'">
      <dynamic-list *ngIf="loaded" [type]="componentType" [data]="listItems" [customSelect]="customSelect"
        [spaceNoBorder]="spaceNoBorder" emptyMessage="No items" [update]="update" (action)="selectItem($event)">
      </dynamic-list>
    </div>
  </div>

  <div fxFlex>
  </div>

  <div *ngIf="!direct">
    <ion-row [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-col size="12">
        <ion-button class="action-button-fill button-color-alternate" (click)="submitSelection()"
          [disabled]="!selectedItem">
          <span class="button-font-size-s">Select</span>
          <icon-wrapper slot="start" class="slot-icon-padding" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
        </ion-button>
      </ion-col>
    </ion-row>
  </div>

</modal-container>