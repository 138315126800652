

import { ElementRef, Input, OnInit, OnDestroy, Directive, Renderer2, AfterViewInit, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ResourceManager } from 'src/app/classes/general/resource-manager';

export interface IPhotoBgOptions {
    url: string;
    fadeClassInner: string;
    fade: boolean;
    fadeClass: string;
}

@Directive({
    selector: '[photoBg]'
})
export class PhotoBgDirective implements OnInit, OnDestroy, AfterViewInit, OnChanges {
    @Input('photoBg') options: IPhotoBgOptions;

    initClass: string = "";
    timeout: any;
    state: boolean = false;

    imgLoaded: boolean = false;

    image: HTMLImageElement = null;


    constructor(private el: ElementRef, private _renderer: Renderer2) { }

    ngOnInit() {
        // console.log("photobg directive init");
    }


    ngOnChanges(changes: SimpleChanges) {
        // console.log(changes);
        let keys: string[] = Object.keys(changes);
        for (let i = 0; i < keys.length; i++) {
            const chg: SimpleChange = changes[keys[i]];
            // console.log(chg);
            if (!chg.isFirstChange()) {
                // skip the first change
                this.preload();
            }
        }
    }

    ngAfterViewInit() {
        this.initClass = this.el.nativeElement.className;
        // console.log(this.initClass);
        // console.log(this.options);
        this.preload();
    }

    preload() {
        // console.log("preload: ", this.options);
        if (this.options.url) {
            // console.log("loading image: " + this.options.url);
            this.disposeImage();

            this.image = new Image();
            this.image.onload = () => {
                // setTimeout(() => {
                let imgContext: string = "";
                if (this.options.fadeClassInner) {
                    imgContext = this.options.fadeClassInner + ",";
                }
                imgContext += 'url(' + this.options.url + ')';
                
                this.el.nativeElement.style.backgroundImage = imgContext;
                // }, 2000);
                this.imgLoaded = true;
            };
            this.image.src = this.options.url;
        }

        this.setClass();
    }

    disposeImage() {
        if (this.image) {
            this.image.remove();
        }
    }

    ngOnDestroy() {
        this.timeout = ResourceManager.clearTimeout(this.timeout);
        this.disposeImage();
    }

    setClass() {
        if (this.options.fade) {
            if (!this.imgLoaded) {
                this.timeout = setTimeout(() => {
                    if (!this.imgLoaded) {
                        // if the img is already loaded (e.g. previous load), don't apply fade class
                        this._renderer.addClass(this.el.nativeElement, this.options.fadeClass);
                    }
                }, 100);
            }
        }
    }
}
