import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IUserLoginRequest, IUserLoginCommons } from 'src/app/classes/def/user/general';
import { Platform } from '@ionic/angular';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { Messages } from 'src/app/classes/def/app/messages';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { NgForm } from '@angular/forms';
import { IGenericResponse } from 'src/app/classes/def/requests/general';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { SignupTermsPage } from '../../logins/signup-terms/signup-terms.page';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { AuthRequestService } from 'src/app/services/general/auth-request/auth-request';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ERouteDef } from 'src/app/app-utils';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { NavParamsService } from 'src/app/services/app/nav-params';
import { MiscDataService } from 'src/app/services/data/misc';
import { ETrackedEvents } from 'src/app/classes/app/analytics';
import { ISignupTermsNavParams } from 'src/app/classes/def/nav-params/modals';
import { Router } from '@angular/router';
import { KeyboardProvider } from 'src/app/services/apis/keyboard';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.page.html',
  styleUrls: ['./signup.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignupPage implements OnInit, OnDestroy {
  signup: IUserLoginRequest = {
    email: '',
    username: '',
    password: ''
  };
  checkPassword: string = "";
  submitted = false;

  theme: string = "theme-light theme-light-bg";

  loading: boolean = false;

  np: INavParams = null;
  vs: IViewSpecs;

  constructor(
    public router: Router,
    public authentication: AuthRequestService,
    public misc: MiscDataService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public uiext: UiExtensionService,
    public backButton: BackButtonService,
    public analytics: AnalyticsService,
    public settingsProvider: SettingsManagerService,
    public nps: NavParamsService,
    public keyboardProvider: KeyboardProvider
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  requestNewValidationEmail(form: NgForm) {
    console.log(form.controls.email.valid);
    if (form.controls.email.valid) {
      this.loading = true;
      this.misc.requestNewValidationEmail(form.value.email).then((_response: IGenericResponse) => {
        this.loading = false;
        this.uiext.showAlertNoAction(Messages.msg.requestNewValidationEmail.after.msg, Messages.msg.requestNewValidationEmail.after.sub);
      }).catch((err: Error) => {
        this.loading = false;
        this.analytics.dispatchError(err, "signup");
        this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
      });
    } else {
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, "Please enter your valid email");
    }
  }

  ngOnDestroy() {
    this.keyboardProvider.setEnableScroll(true);
  }

  ngOnInit() {

    this.keyboardProvider.setEnableScroll(false);

    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    this.webView.ready().then(() => {
      this.backButton.replace(() => {
        if (!this.uiext.isAlert()) {
          this.uiext.showAlert(Messages.msg.exit.before.msg, Messages.msg.exit.before.sub, 2, null, false).then((res: number) => {
            if (res === EAlertButtonCodes.ok) {
              this.backButton.exitApp();
            }
          }).catch(() => {
          });
        }
      });
    }).catch((err: Error) => { console.error(err); });
  }


  onSignup(form: NgForm) {
    this.submitted = true;
    console.log("signing up");
    if (form.valid) {
      console.log("valid");

      let params: ISignupTermsNavParams = {
        withButtons: true
      };

      this.uiext.showCustomModal(null, SignupTermsPage, {
        view: {
          fullScreen: false,
          transparent: false,
          large: true,
          addToStack: true,
          frame: false
        },
        params: params
      }).then((accept: boolean) => {
        if (accept) {
          this.loading = true;
          this.authentication.submitSignUpWithEmail(this.signup.email, this.signup.username, this.signup.password).then((_result: IGenericResponse) => {
            this.loading = false;
            this.uiext.showAlert(Messages.msg.signup.after.msg, Messages.msg.signup.after.sub, 1, null).then(() => {
              this.analytics.sendEvent(ETrackedEvents.registered, "signup", "signup", 1);
              this.onLogin(this.signup.email, this.signup.password);
            }).catch((err: Error) => {
              console.error(err);
            });
          }).catch((err: Error) => {
            this.analytics.dispatchError(err, "signup");
            this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
            this.loading = false;
          });
        }
      }).catch((err: Error) => {
        console.error(err);
        this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
      });
    } else {
      console.log("invalid");
    }
  }

  onLogin(email: string, password: string) {
    if (!email) {
      email = this.signup.email;
    }
    if (!password) {
      password = this.signup.password;
    }

    let params: IUserLoginCommons = {
      email: email,
      password: password
    };

    let navParams: INavParams = {
      view: null,
      params: params
    };

    this.nps.set(ENavParamsResources.login, navParams);

    this.router.navigate([ERouteDef.login], { replaceUrl: true }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }

}
