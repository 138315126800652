

import { Injectable, isDevMode } from "@angular/core";
import { AppVersion } from '@ionic-native/app-version/ngx';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { AppVersionUtils, IAppVersionDB } from 'src/app/classes/def/app/app-version';
import { EOS } from 'src/app/classes/def/app/app';
import { HttpClient } from "@angular/common/http";
import { TimeUtils } from "src/app/classes/general/time";

export interface IAppVersionWeb {
    date: string;
    build: number;
}

@Injectable({
    providedIn: 'root'
})
export class AppVersionService {
    constructor(
        public appVersion: AppVersion,
        public http: HttpClient
    ) {
        console.log("app version service created");
    }

    /**
     * get current app version
     */
    getAppVersion(): Promise<number> {
        let promise: Promise<number> = new Promise((resolve, reject) => {
            if (GeneralCache.versionCode != null) {
                resolve(GeneralCache.versionCode);
                return;
            }
            this.appVersion.getVersionCode().then((realVersionCode: any) => {
                if (GeneralCache.os === EOS.ios) {
                    GeneralCache.versionCode = AppVersionUtils.getFullNumberIOS(realVersionCode as string, 0);
                } else {
                    GeneralCache.versionCode = AppVersionUtils.getFullNumber(realVersionCode as number, 0);
                }

                this.appVersion.getVersionNumber().then((versionString: string) => {
                    GeneralCache.versionString = versionString;
                    resolve(GeneralCache.versionCode);
                }).catch((err) => {
                    console.error(err);
                    resolve(GeneralCache.versionCode);
                });
            }).catch((err) => {
                GeneralCache.versionCode = null;
                reject(err);
            });
        });
        return promise;
    }

    getAppVersionWeb() {
        let promise: Promise<string> = new Promise((resolve, reject) => {
            this.http.get('./assets/build-info.json').subscribe((bd: IAppVersionWeb) => {
                console.log("get app version: ", bd);
                if (bd != null) {
                    GeneralCache.versionString = TimeUtils.formatDateDisp(bd.date) + " (" + bd.build + ")";
                    resolve(bd.date);
                } else {
                    resolve("WEB");
                }
            }, (err) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
    * compare current app version with required app version
    * returns true is current >= target
    * @param appVersionDB 
    */
    compareVersionDB(appVersionDB: IAppVersionDB) {
        return AppVersionUtils.compareVersionCore(GeneralCache.versionCode, appVersionDB.buildId);
    }

    /**
     * compare current app version with required app version
     * returns true is current >= target
     * @param targetAndroid 
     * @param targetIOS 
     */
    compareVersion(targetAndroid: number, targetIOS: number): boolean {
        if (GeneralCache.os === EOS.ios) {
            return AppVersionUtils.compareVersionCore(GeneralCache.versionCode, targetIOS);
        } else {
            return AppVersionUtils.compareVersionCore(GeneralCache.versionCode, targetAndroid);
        }
    }

    checkWebProdInit() {
        console.log("check web prod init os: ", GeneralCache.os);
        if (GeneralCache.os !== EOS.browser) {
            return;
        }
        if (this.checkWebProd()) {
            console.log("running web prod");
            // disable console logs
            window.console.warn = function () { };
            window.console.error = function () { };
            window.console.log = function () { };
            // flags
            GeneralCache.useWebGL = true;
            GeneralCache.isPublicDistribution = true;
        } else {
            console.log("running web dev");
            // flags
            GeneralCache.isDev = true;
            // GeneralCache.useWebGL = false;
            GeneralCache.useWebGL = true; // testing
            GeneralCache.isPublicDistribution = false;
        }
    }

    checkWebProd() {
        return !isDevMode();
    }
}




