import { IMPEventContainer } from "./events";


/**
 * received directly via message
 */
export enum EMPMessageCodes {
    // general
    ping = 100,
    leaderReady = 101,
    memberReady = 102,

    // chat
    chat = 200,
    typing = 201,

    // challenges
    leaderSelectChallenge = 301,
    memberLoadChallenge = 302,
    leaderStartChallenge = 303,
    memberStartChallenge = 304,
    leaderFinishedChallenge = 305,
    memberFinishedChallenge = 306,
    leaderFailedChallenge = 307,
    memberFailedChallenge = 308,
    leaderStopChallenge = 311,
    memberStopChallenge = 312,
    memberRejectedChallenge = 314,

    leaderBroadcastCoinSpecs = 315,

    leaderSyncStart = 317,
    memberSyncStart = 318,

    leaderBroadcastFindSpecs = 319,

    // stats
    leaderStatsReady = 391,
    leaderBroadcastEndgame = 392,

    // not used
    storyLocationComplete = 330,

    storyMapSync = 331,
    storyFinished = 332,
    storyProgressSync = 333,
    clearStoryProgress = 341   
}

/**
 * user input (e.g. tap button)
 */
export enum EMPUserInputCodes {
    tapReady = 1,
    tapStart = 2,
    back = 3,
    /**
     * leader
     */
    selectChallenge = 4,
    /**
     * member
     */
    confirmChallenge = 5,

    finishChallenge = 6,
    failChallenge = 7,
    stopChallenge = 8,

    statsLoaded = 9
}

/**
 * indirect state (e.g. group state aggregator events)
 */
export enum EMPVirtualGroupCodes {
    leaderConnectedViaStatus = 1,
    leaderDisconnectedViaStatus = 2,
    allMembersReady = 3,
    notAllMembersReady = 4,
    allMembersLoadedChallenge = 5,
    allMembersStartedChallenge = 7,
    /**
     * complete = finished/failed
     */
    allMembersExitChallenge = 8,
    // after show description (sync from that moment)
    allMembersSyncStart = 9,
    newMemberDetected = 10
}

export enum EMPVirtualMemberCodes {
    disconnected = 1
}

/**
 * encode the event source
 */
export enum EMPEventSource {
    // message = 1000000,
    // userInput = 2000000,
    // groupStateAggregator = 3000000
    message = 1000,
    userInput = 2000,
    virtualGroupState = 3000,
    virtualMemberState = 4000
}


export class MPEncoding {
    /**
     * get event encoding/multiplex
     * @param source 
     * @param action 
     * @param data additional params
     */
    static encodeEvent(source: number, action: number, data: any) {
        let event: IMPEventContainer = {
            code: source + action,
            data: data
        };
        return event;
    }
}















export enum ELeaderStates {
    INIT = 0,
    READY = 1,
    SET = 2,
    SET_AND_TAPPED_START = 3,
    SET_AND_ALL_READY = 4,
    GO = 5,

    CHALLENGE_LOADED = 6,
    CHALLENGE_READY = 7,
    CHALLENGE_IN_PROGRESS = 8,

    CHALLENGE_FAILED = 9,
    CHALLENGE_COMPLETE = 10,
    CHALLENGE_STOPPED = 11,

    WAIT_STATS_DISPATCH = 12,
    REQUEST_STATS_DISPATCH = 13,
    WAIT_FOR_ENDGAME = 14,
    ENDGAME = 15,

    HANDLE_DISCONNECTED_MEMBER = 100,
    ERROR = -1
}

export enum EMemberStates {
    INIT = 0,
    READY = 1,
    SET = 2,
    WAIT_GO = 3,
    GO = 4,

    CHALLENGE_LOADED = 6,
    CHALLENGE_READY = 7,
    CHALLENGE_IN_PROGRESS = 8,

    CHALLENGE_FAILED = 9,
    CHALLENGE_COMPLETE = 10,
    CHALLENGE_STOPPED = 11,
    CHALLENGE_STOPPED_EXT = 12,

    WAIT_FOR_ENDGAME = 13,
    WAIT_REQUEST_STATS = 14,
    REQUEST_STATS = 15,

    ENDGAME = 16,

    HANDLE_DISCONNECTED_LEADER = 100,
    ERROR = -1
}