import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { IonInput, ModalController } from '@ionic/angular';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ParamHandler } from 'src/app/classes/general/params';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';

export interface ITextInputParams {
  title: string;
  description: string;
  text: string;
  maxLength?: number;
  okLabel?: string;
  focused: boolean;
  allowEmpty: boolean;
}

export interface ITextInputResult {
  apply: boolean;
  text: string;
}

@Component({
  selector: 'modal-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        opacity: 0,
      })),
      state('active', style({
        opacity: 1,
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ]
})
export class TextInputViewComponent implements OnInit, OnDestroy {
  @ViewChild('input', { read: IonInput, static: false }) myInput: IonInput;
  title: string = "";
  selectAll: boolean = false;
  text: string;
  description: string;
  inFocus: boolean = false;
  inputMode: boolean = false;
  fixedMode: boolean = false;
  showState: string = "inactive";

  maxLength: number = EMessageTrim.userInput;

  vs: IViewSpecs = ViewSpecs.getDefault();
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  timeouts = {
    kb: null,
    refresh: null
  };

  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService
  ) {

  }

  dismiss(text: ITextInputResult) {
    this.modalCtrl.dismiss(text).then(() => {
    }).catch(() => {
    });
  }

  cancel() {
    this.dismiss(null);
  }

  submit() {
    this.dismiss({
      apply: true,
      text: this.text
    });
  }

  clear() {
    this.text = "";
  }


  refreshShowState() {
    this.showState = "inactive";
    this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
    this.timeouts.refresh = setTimeout(() => {
      this.showState = "active";
    }, 100);
  }

  checkFocus() {

    console.log("focus");

    this.inputMode = true;
    this.refreshShowState();

    console.log(this.myInput);

    if (!this.inFocus) {
      this.timeouts.kb = setTimeout(() => {
        if (this.myInput) {
          // this.keyboard.show() // for android
          this.myInput.setFocus();
          // this.myInput.nativeElement.setFocus();
        }
        this.inFocus = true;
      }, 350);
    }

    // if (this.inFocus) {
    //     return;
    // }

    // let this.np: INavParams = {
    //     view: {
    //         fullScreen: false,
    //         transparent: true,
    //         large: true,
    //         addToStack: true,
    //         frame: false
    //     },
    //     params: { title: "Description", text: this.text }
    // };

    // this.uiext.showCustomModal(null, TextInputCorePage, this.np).then((result: string) => {
    //     this.text = result;
    //     this.inFocus = false;
    // }).catch((err: Error) => {
    //     console.error(err);
    //     this.inFocus = false;
    // });
    // console.log("focus");
  }

  checkBlur() {
    console.log("blur");
  }


  setFocused() {
    this.inFocus = true;
  }

  setBlurred() {
    this.inFocus = false;
  }

  back() {
    this.inputMode = false;
    this.refreshShowState();
  }

  ngOnDestroy() {
    this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: ITextInputParams = np.params;
      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);

      this.title = params.title;
      this.description = params.description;
      this.text = params.text;
      this.inputMode = params.focused;
      this.fixedMode = params.focused;
    }

    this.showState = "active";
  }
}
