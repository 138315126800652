

import { Injectable } from "@angular/core";
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { SettingsManagerService } from '../settings-manager';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { OpenNativeSettings } from "@ionic-native/open-native-settings/ngx";
import { BackgroundModeWatchService } from "../apis/background-mode-watch";
import { ENativeSettingsContext } from "./permission.utils";
import { AndroidPermissionResponse, AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { PromiseUtils } from "../../utils/promise-utils";
import { AppDiagnosticService } from "../app-diagnostic.service";
import { RequestAuthorizationStatus } from "capacitor-plugin-diagnostics/dist/esm";


@Injectable({
    providedIn: 'root'
})
export class PermissionsService {

    private platform: IPlatformFlags;

    constructor(
        public diagnostic: Diagnostic,
        public appDiagnostic: AppDiagnosticService,
        public settingsProvider: SettingsManagerService,
        public openNativeSettings: OpenNativeSettings,
        public bgmWatch: BackgroundModeWatchService,
        public androidPermissions: AndroidPermissions
    ) {
        console.log("diagnostic version service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    /**
    * check for permissions first, before using the microphone
    */
    requestMicrophonePermissions(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (this.platform.WEB) {
                resolve(true);
                return;
            }
            this.appDiagnostic.isMicrophoneAuthorized().then((status: RequestAuthorizationStatus) => {
                if (status && status.authorized) {
                    resolve(true);
                } else {
                    this.appDiagnostic.requestMicrophoneAuthorizationWizard().then(() => {
                        console.log("request microphone authorization");
                        resolve(true);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
    * check for permissions first, before using the microphone
    */
    requestMicrophoneRecordingPermissions(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            try {
                let status: boolean = false;
                status = await this.requestMicrophonePermissions();
                if (!status) {
                    reject(new Error("microphone permissions denied"));
                    return;
                }
                status = await this.checkFilePermission();
                if (!status) {
                    reject(new Error("storage permissions not granted"));
                    return;
                }
                resolve(true);
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

    /**
     * check camera permission authorized
     * @returns 
     */
    checkCameraPermission(): Promise<boolean> {
        console.log("check camera permission");
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (this.platform.WEB) {
                resolve(true);
                return;
            }
            this.appDiagnostic.isCameraAuthorized().then((status: RequestAuthorizationStatus) => {
                console.log("is camera authorized: ", status);
                resolve(status?.authorized);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * initialize camera permission for first time users
     */
    requestCameraPermission(): Promise<boolean> {
        console.log("request camera permission");
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            try {
                let status: boolean = false;
                status = await this.checkCameraPermission();
                if (status) {
                    resolve(true);
                    return;
                }
                // not authorized, request authorization
                let data = await this.appDiagnostic.requestCameraAuthorizationWizard();
                console.log("request camera authorization: ", data);
                resolve(true);
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

    /**
     * request camera and microphone permissions
     * @returns 
     */
    requestCameraRecordingPermission(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            let status: boolean = false;
            try {
                status = await this.requestCameraPermission();
                if (!status) {
                    reject(new Error("camera permissions not granted"));
                    return;
                }
                status = await this.requestMicrophonePermissions();
                if (!status) {
                    reject(new Error("microphone permissions not granted"));
                    return;
                }
                status = await this.checkFilePermission();
                if (!status) {
                    reject(new Error("storage permissions not granted"));
                    return;
                }
                resolve(true);
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

    /**
    * check for storage permission first, before using the camera
    * resolve anyway
    * 
    * Android api 33
    * Not much as changed in API 33, except for that READ_EXTERNAL_STORAGE is now an auto reject and instead they have 3 new permissions READ_MEDIA_IMAGES, READ_MEDIA_VIDEO, and READ_MEDIA_AUDIO
    * Comment out everything about READ_EXTERNAL_STORAGE and WRITE_EXTERNAL_STORAGE
    * Pre-request permissions for READ_MEDIA_IMAGES, READ_MEDIA_VIDEO, and READ_MEDIA_AUDIO with the following plugin
    */
    checkFilePermission(): Promise<boolean> {
        console.log("request file permission");
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            try {
                if (this.platform.WEB) {
                    resolve(true);
                    return;
                }
                if (this.platform.IOS) {
                    resolve(true);
                    return;
                }

                let permissions: string[] = [
                    this.androidPermissions.PERMISSION.READ_MEDIA_VIDEO,
                    this.androidPermissions.PERMISSION.READ_MEDIA_AUDIO,
                    this.androidPermissions.PERMISSION.READ_MEDIA_IMAGES
                ];

                // let status: boolean = await this.diagnostic.isExternalStorageAuthorized();
                let permissionsGranted: boolean = true;
                for (let perm of permissions) {
                    let check = await this.androidPermissions.checkPermission(perm);
                    if (check && check.hasPermission) {

                    } else {
                        permissionsGranted = false;
                        break;
                    }
                }

                if (permissionsGranted) {
                    resolve(true);
                } else {
                    // let data = await this.diagnostic.requestExternalStorageAuthorization();
                    console.log("request ext storage authorization: unavailable since android api level 33");
                    let apr: AndroidPermissionResponse = await this.androidPermissions.requestPermissions(permissions);
                    console.log("request android read media video permission: ", apr);
                    resolve(true);
                }
            } catch (err) {
                console.error(err);
                resolve(false);
            }
        });
        return promise;
    }

    /**
     * check for notification permission
     */
    requestAndroidNotificationPermission(): Promise<boolean> {
        console.log("request notification permission (android only)");
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            try {
                if (this.platform.WEB) {
                    resolve(true);
                    return;
                }
                if (this.platform.IOS) {
                    resolve(true);
                    return;
                }
                let apr: AndroidPermissionResponse = await this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.POST_NOTIFICATIONS);
                // check why this returns false on Android 13
                resolve(apr.hasPermission);
            } catch (err) {
                console.error(err);
                resolve(false);
            }
        });
        return promise;
    }


    /**
     * check rientation sensor permission required (iOS)
     * @returns 
     */
    checkOrientationSensorsPermissionsNotRequired(): Promise<boolean> {
        console.log("check orientation sensors permission");
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (!this.platform.IOS) {
                resolve(true);
                return;
            }
            // Browser doesn't support or doesn't require permission to DeviceOrientationEvent API.
            if (typeof DeviceOrientationEvent === 'undefined' || !(DeviceOrientationEvent as any).requestPermission) {
                console.log("already granted");
                resolve(true);
                return;
            }
            resolve(false);
        });
        return promise;
    }

    /**
     * request orientation sensor permission (iOS)
     * @returns 
     */
    requestOrientationSensorsPermissions(): Promise<boolean> {
        return new Promise((resolve) => {
            console.log("request orientation sensors permission");
            this.checkOrientationSensorsPermissionsNotRequired().then((status: boolean) => {
                if (status) {
                    console.log("request orientation permission not required");
                    resolve(true);
                    return;
                }
                // Show dialog only if permission has not yet been granted.
                if (typeof (DeviceOrientationEvent as any).requestPermission === 'function') {
                    (DeviceOrientationEvent as any).requestPermission().then((permissionState: string) => {
                        let permissionGranted = false;
                        if (permissionState === 'granted') {
                            console.log("granted");
                            permissionGranted = true;
                        } else {
                            console.log("not granted");
                        }
                        resolve(permissionGranted);
                    }).catch((err: Error) => {
                        console.warn("request orientation permission error");
                        console.error(err);
                        resolve(false);
                    });
                } else {
                    console.warn("request orientation permission not available");
                    resolve(true);
                }
            });
        });
    }

    async requireNativePermissionChange(type: string = ENativeSettingsContext.app) {
        await this.openNativeSettings.open(type);
        await this.bgmWatch.waitDefaultBgmWatch(false);
    }

    checkNativePermission() {
        // redirect to app settings to enable location
        return this.requireNativePermissionChange(ENativeSettingsContext.app);
    }

    async checkNativeCameraPermission() {
        // redirect to app settings to enable location
        await this.requireNativePermissionChange(ENativeSettingsContext.app);
        console.log("native permission request complete");
        let granted = await this.checkCameraPermission();
        console.log("permission check after settings: ", granted);
        return granted;
    }

    async checkNativeOrientationPermission() {
        // redirect to app settings to enable location
        await this.requireNativePermissionChange(ENativeSettingsContext.app);
        console.log("native permission request complete");
        let granted = await this.checkOrientationSensorsPermissionsNotRequired();
        console.log("permission check after settings: ", granted);
        return granted;
    }

    async selfTest() {
        try {
            console.log("permissions check self test: camera check");
            await PromiseUtils.wrapResolve(this.checkCameraPermission(), true);
            console.log("permissions check self test: camera request");
            await PromiseUtils.wrapResolve(this.requestCameraPermission(), true);
            console.log("permissions check self test: camera recording request");
            await PromiseUtils.wrapResolve(this.requestCameraRecordingPermission(), true);
            console.log("permissions check self test: file check");
            await PromiseUtils.wrapResolve(this.checkFilePermission(), true);
            console.log("permissions check self test: android notification request");
            await PromiseUtils.wrapResolve(this.requestAndroidNotificationPermission(), true);
            console.log("permissions check self test: orientation request");
            await PromiseUtils.wrapResolve(this.requestOrientationSensorsPermissions(), true);
        } catch (err) {
            console.error(err);
        }
    }
}