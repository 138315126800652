
import { Injectable } from '@angular/core';
import { Brightness } from '@ionic-native/brightness/ngx';
import { GeneralCache } from 'src/app/classes/app/general-cache';

@Injectable({
    providedIn: 'root'
})
export class ScreenService {
    wakeLock: any;

    constructor(
        public brightness: Brightness
    ) {
        console.log("screen service created");
    }

    setKeepScreenOn(enabled: boolean) {
        if (!GeneralCache.isWeb) {
            this.brightness.setKeepScreenOn(enabled);
        } else {
            if ("wakeLock" in navigator) {
                if (enabled) {          
                    (navigator as any).wakeLock.request("screen").then((wakeLock: any) => {
                        this.wakeLock = wakeLock;
                    }).catch((err: Error) => {
                        console.error(err);
                    });
                } else {
                    if (this.wakeLock != null) {
                        this.wakeLock.release().then(() => {
                            this.wakeLock = null;
                        });
                    }
                }
            } else {
                console.warn("wakeLock not available");
            }
        }
    }
}
