/**
 * the geo objects generator
 * used for partial sync
 */
export enum EGeoObjectsProviderCode {
    itemScanner = 1,
    explore = 2,
    find = 3,
    AR = 4,
    default = 0
}
