
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericDataService } from '../general/data/generic';
import { IGenericResponse } from '../../classes/def/requests/general';
import { IGameItem, ICheckUnlockNewAchievements } from '../../classes/def/items/game-item';
import { InventoryDataService } from './inventory';
import { ICustomParamForActivity } from '../../classes/def/core/custom-param';
import { IChallengeProgressUntilNewMedalResponse, IGameItemWithStats } from '../../classes/def/core/challenge';
import { EInventorySync } from 'src/app/classes/def/items/inventory';


@Injectable({
    providedIn: 'root'
})
export class AchievementsDataService {
    constructor(
        public http: HttpClient,
        public generic: GenericDataService,
        public inventory: InventoryDataService
    ) {
        console.log("achievements data service created");
    }

    /**
     * check for new (not seen by the user yet) achievements in the inventory
     * to be shown in real time, after the user unlocks an achievement
     */
    checkNewAchievements(test: boolean) {
        let promise = new Promise((resolve, reject) => {
            this.generic.genericPostStandard("/stats/check-new-achievements", {
                test: test
            }).then((resp: IGenericResponse) => {
                let gameItemsMedals: IGameItem[] = resp.data;
                resolve(gameItemsMedals);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * compute achievements that should be actually unlocked
     */
    recalculateAchievements() {
        let promise = new Promise((resolve, reject) => {
            this.generic.genericGetStandard("/stats/check-unlock-any-achievement", null).then((resp: IGenericResponse) => {
                let check: ICheckUnlockNewAchievements = resp.data;
                resolve(check.new);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * get progress towards new medal e.g. when showing the challenge complete view
     * @param customParamCategoryCode 
     */
    getProgressTowardsNewMedal(activityCustomParams: ICustomParamForActivity[]): Promise<IGameItemWithStats[]> {
        let promise: Promise<IGameItemWithStats[]> = new Promise((resolve, reject) => {
            let hasParams: boolean = false;
            let customParamCategoryCode: number = 0;

            if (activityCustomParams && activityCustomParams.length > 0) {
                hasParams = true;
                customParamCategoryCode = activityCustomParams[0].customParamCategoryCode;
            }

            if (!hasParams) {
                reject(new Error("undefined custom params"));
                return;
            }

            this.generic.genericPostStandard("/stats/progress-towards-new-medal", {
                customParamCategoryCode: customParamCategoryCode
            }).then((response: IGenericResponse) => {
                let data: IChallengeProgressUntilNewMedalResponse = response.data;

                if (data) {
                    // an achievement might be unlocked
                    this.inventory.resetSyncFlag(EInventorySync.items);
                    // coins might be added
                    this.inventory.resetSyncFlag(EInventorySync.coins);

                    let items: IGameItemWithStats[] = [];
                    if (data.medals) {
                        items = data.medals;
                    }
                    resolve(items);
                } else {
                    resolve(null);
                }

            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }
}



