

import { Injectable } from '@angular/core';
import { SettingsManagerService } from '../settings-manager';
import { IPlatformFlags } from '../../../classes/def/app/platform';
import { UiExtensionService } from '../ui/ui-extension';
import { IPhotoFrameNavParams, PhotoFrameViewComponent } from 'src/app/modals/generic/modals/photo-frame/photo-frame.component';
import { IViewPhotoParams } from 'src/app/classes/def/nav-params/photo-view';


@Injectable({
    providedIn: 'root'
})
export class PhotoViewService {

    platform: IPlatformFlags;

    constructor(
        public settingsProvider: SettingsManagerService,
        public uiext: UiExtensionService
    ) {
        console.log("photo view service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    viewPhoto(url: string, title: string, params: IViewPhotoParams) {
        let pfparams: IPhotoFrameNavParams = {
            photoUrl: url, 
            title: title,
            sharePhoto: params.share,
            isDataUrl: params.isDataUrl,
            uploadPhoto: (params.changePhotoCallback != null) ? true : false,
            uploadPhotoFn: params.changePhotoCallback
        };
        this.uiext.showCustomModal(null, PhotoFrameViewComponent, {
            view: {
                fullScreen: false,
                transparent: false,
                large: false,
                addToStack: true,
                frame: false
            },
            params: pfparams
        }).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }
}


