import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ParamHandler } from 'src/app/classes/general/params';
import { IBase64Ext } from 'src/app/classes/def/media/photo';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { ICameraFrameNavParams, ICameraFrameReturnParams } from './utils';
import { ImageUtilsService } from 'src/app/services/media/image-utils';
import { AppConstants } from 'src/app/classes/app/constants';

/**
 * https://developer.mozilla.org/en-US/docs/Web/API/Media_Capture_and_Streams_API/Taking_still_photos
 */
@Component({
  selector: 'modal-camera-frame',
  templateUrl: './camera-frame.component.html',
  styleUrls: ['./camera-frame.component.scss'],
})
export class CameraFrameViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('inputcamera', { read: ElementRef, static: false }) cameraInput: ElementRef;
  @ViewChild('imgcanvas', { read: ElementRef, static: false }) imgCanvas: ElementRef;
  @ViewChild('imgresult', { read: ElementRef, static: false }) imgResult: ElementRef;

  img: string = "";

  description: string = "";
  title: string = "";
  caption: string = "";

  vs: IViewSpecs = ViewSpecs.getDefault();

  enable = {
    take: true,
    save: true,
    retry: false
  };

  appIconsStandard = EAppIconsStandard;
  params: ICameraFrameNavParams = null;
  np: INavParams = null;

  loading: boolean = true;

  showPhoto: boolean = false;
  streaming: boolean = false;

  imgWidth: number = 1200;
  imgHeight: number = 0;
  // Set the JPEG quality (0.1 to 1.0) scaled
  jpegQuality: number = 60;

  stream: MediaStream;
  selfie: boolean = false;

  eventHandler;

  constructor(
    public modalCtrl: ModalController,
    public shareProvider: ShareService,
    public imgUtils: ImageUtilsService,
    public uiext: UiExtensionService
  ) {

  }

  initConfig() {
    if (AppConstants.gameConfig.photoTargetWidth != null) {
      this.imgWidth = AppConstants.gameConfig.photoTargetWidth;
    }
    if (AppConstants.gameConfig.photoTargetQuality != null) {
      this.jpegQuality = AppConstants.gameConfig.photoTargetQuality;
    }
  }

  displayCard() {
    return this.img !== '';
  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    this.initConfig();

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: ICameraFrameNavParams = np.params;
      this.params = params;
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);
      this.description = params.description;
      this.title = params.title;
      this.caption = params.caption;

      this.eventHandler = (ev) => {
        this.oncanplay(ev);
      };
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.startPreview(false);
      this.loading = false;
    }, 1000);
  }

  switchCameraFacingMode() {
    this.selfie = !this.selfie;
    this.enable.take = false;
    this.stopPreview();
    setTimeout(() => {
      this.startPreview(this.selfie);
      this.enable.take = true;
    }, 500);
  }

  stopPreview() {
    if (!this.stream) {
      return;
    }
    this.stream.getTracks().forEach((track) => {
      track.stop();
    });
    let video: HTMLVideoElement = this.cameraInput.nativeElement;
    video.removeEventListener("canplay", this.eventHandler, false);
  }

  startPreview(selfie: boolean) {
    let video: HTMLVideoElement = this.cameraInput.nativeElement;
    let constraints: MediaStreamConstraints = {
      video: {
        facingMode: selfie ? 'user' : 'environment'
      },
      audio: false
    };

    navigator.mediaDevices.getUserMedia(constraints).then((stream: MediaStream) => {
      this.stream = stream;
      video.srcObject = stream;
      video.play();
    }).catch((err) => {
      console.error(`An error occurred: ${err}`);
    });

    video.addEventListener("canplay", this.eventHandler, false);
  }

  oncanplay(ev) {
    let video = this.cameraInput.nativeElement;
    let canvas = this.imgCanvas.nativeElement;
    if (!this.streaming) {
      this.imgHeight = video.videoHeight / (video.videoWidth / this.imgWidth);
      // Firefox currently has a bug where the height can't be read from
      // the video, so we will make assumptions if this happens.
      if (isNaN(this.imgHeight)) {
        this.imgHeight = this.imgWidth / (4 / 3);
      }
      // video.setAttribute("width", this.imgWidth);
      // video.setAttribute("height", this.imgHeight);
      canvas.setAttribute("width", this.imgWidth);
      canvas.setAttribute("height", this.imgHeight);
      this.streaming = true;
    }
  }

  ngOnDestroy() {
    this.stopPreview();
  }

  dismiss(data: ICameraFrameReturnParams) {
    setTimeout(() => {
      this.modalCtrl.dismiss(data).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  retry() {
    this.enable.retry = false;
    this.showPhoto = false;
    this.clearPhotoWrapper();
  }

  takePhotoWrapper() {
    this.enable.retry = true;
    this.img = this.takePhoto();
    this.showPhoto = true;
  }

  takePhoto() {
    let video = this.cameraInput.nativeElement;
    let canvas = this.imgCanvas.nativeElement;
    let photo = this.imgResult.nativeElement;
    const context = canvas.getContext("2d");
    if (this.imgWidth && this.imgHeight) {
      canvas.width = this.imgWidth;
      canvas.height = this.imgHeight;
      context.drawImage(video, 0, 0, this.imgWidth, this.imgHeight);
      const data = canvas.toDataURL("image/jpeg", this.jpegQuality * 0.1);
      photo.setAttribute("src", data);
      video.pause();
      return data;
    } else {
      return null;
    }
  }

  clearPhotoWrapper() {
    this.img = this.clearPhoto();
    let video = this.cameraInput.nativeElement;
    video.play();
  }

  clearPhoto() {
    let canvas = this.imgCanvas.nativeElement;
    let photo = this.imgResult.nativeElement;
    const context = canvas.getContext("2d");
    context.fillStyle = "#AAA";
    context.fillRect(0, 0, canvas.width, canvas.height);
    const data = canvas.toDataURL("image/jpeg");
    photo.setAttribute("src", data);
    return data;
  }

  savePhoto() {
    if (!this.showPhoto) {
      return;
    }
    let res: ICameraFrameReturnParams = {
      photoFile: null,
      photoContent: null
    };
    let title: string = this.title;
    if (title != null) {
      title = title.replace(/\s/g, '').toLowerCase();
    } else {
      title = "image";
    }
    // let filename: string = this.title.replace(/\s/g, '').toLowerCase() + "_" + new Date().getTime().toString() + ".jpg";
    let filename: string = this.caption + "_" + new Date().getTime().toString() + ".jpg";
    let b64data: IBase64Ext = this.imgUtils.formatBase64PhotoContent(this.img);
    // console.log(b64data);
    res.photoContent = b64data.content;
    this.imgUtils.saveBase64ImageToFile(filename, b64data.content, true, b64data.contentType).then((filenameFull: string) => {
      this.uiext.showToast("Saved to gallery", true, 2000).then(() => {
        res.photoFile = filenameFull;
        this.dismiss(res);
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }
}
