
import { ILeplaceReg } from "../places/google";
import { IStory, IMasterLockResponse } from "../core/story";
import { IStoryCategory } from "../core/category";
import { IEventStoryGroupLinkData } from '../core/links';

export interface IStoryListNavParams {
    storyId: number;
    dynamic: boolean;
    reload: boolean;
    reloadProgress?: boolean;
    category: IStoryCategory;
    categoryCode: number;
    mapStyle?: string;
    mapId?: string;
    parentView?: any;
    loadStory: boolean;
    entryPlace?: ILeplaceReg;
    storyOverview?: IStory;
    useLoadedStory?: boolean;
    links?: IEventStoryGroupLinkData;
    fromMapOpened?: boolean;
    lockPreview?: boolean;
    localStories: boolean;
    selectedCityId: number;
    includeGlobal: boolean;
}

export interface IStoryBuyNavParams {
    storyExt: IStory;
    links: IEventStoryGroupLinkData;
    masterLockData: IMasterLockResponse;
    prefillToken: string;
}

export interface IStoryRatingNavParams {
    title: string;
    description: string;
    /**
     * combined rating
     */
    averageRating: number;
    /**
     * user input rating
     */
    rating: number;
    /**
     * number of ratings
     */
    count: number;
}

export enum EStoryProvider {
    leplace = -1
}

export enum EStartCodes {
    start = 0,
    continue = 1,
    restart = 2
}


export enum EStoryLocationDoneFlag {
    pending = 0,
    done = 1,
    started = 2
}

export enum EStoryLocationStatusFlag {
    pending = 0,
    done = 1,
    started = 2,
    skipped = 3,
    failed = 4,
    retry = 5
}