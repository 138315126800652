<modal-header-container [vs]="vs">
  <!-- <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title" (rightClick)="dismiss()"
    [rounded]="vs.fullScreen"></leplace-toolbar> -->

    <!-- showCancelButton="focus" -->
  <ion-searchbar class="ion-searchbar-custom" [(ngModel)]="autocompleteQuery" ngDefaultControl showCancelButton="always"
    (ionInput)="updateSearch()" (ionCancel)="dismiss()"></ion-searchbar>

</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <ion-list>
    <ion-item *ngFor="let item of autocompleteItems" tappable (click)="chooseItem(item)"
      class="list-item transparent-bg">
      <icon-wrapper [icon]="appIconsStandard.place" [custom]="false"></icon-wrapper>
      <span class="info-text-s text-color-primary">{{ item.name }}</span>
    </ion-item>
  </ion-list>

  <div *ngIf="!hasItems" class="padding-s center-text">
    <div [innerHTML]="emptyMessage" class="padding-s info-text-m description-frame text-color-primary"></div>
    <!-- <span class="center-text info-text-m bold text-color-primary">{{emptyMessage}}</span> -->
  </div>

  <div *ngIf="!loaded" class="progress-spinner-center">
    <mat-progress-spinner [diameter]="40" color="primary" mode="indeterminate">
    </mat-progress-spinner>
  </div>

</modal-container>