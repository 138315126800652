import { IPlatformFlags } from "../../classes/def/app/platform";
import { UiExtensionService } from "../general/ui/ui-extension";
import { MarkerHandlerService } from "./markers";
import { Injectable, ElementRef } from "@angular/core";
import { SettingsManagerService } from "../general/settings-manager";
import { EMarkerLayers } from "../../classes/def/map/marker-layers";
import { MapSettings } from "../../classes/utils/map-settings";
import { ResourceManager } from "../../classes/general/resource-manager";
import { IPlaceMarkerContent, IJSMapOptions, ICameraPosition } from "../../classes/def/map/map-data";
import { MarkerUtilsService } from "./marker-utils-provider";
import { EMarkerIcons } from "../../classes/def/app/icons";
import { EMarkerPriority } from "../../classes/def/map/markers";
import { GeometryUtils } from "../utils/geometry-utils";
import { GenericQueueService } from "../general/generic-queue";
import { EOS, EQueues } from "../../classes/def/app/app";
import { EFeatureColor, IMarkerTheme, ThemeColors } from "../../classes/def/app/theme";
import { IMoveMapOptions, IMoveMapContext } from 'src/app/classes/def/map/interaction';
import { IGenericMessageQueueEvent } from 'src/app/classes/utils/queue';
import { BehaviorSubject } from 'rxjs';
import { IObservableMultiplex } from 'src/app/classes/def/mp/subs';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { WaitUtils } from '../utils/wait-utils';
import { EFollowMode } from 'src/app/classes/def/map/gmap';
import { mapStyles } from 'src/app/classes/def/map/map-styles';
import { ResourcesCoreDataService } from '../data/resources-core';
import { IMapStyle } from 'src/app/classes/def/core/story';
import { SleepUtils } from '../utils/sleep-utils';
import { AnalyticsService } from '../general/apis/analytics';
import { EAppConstants } from 'src/app/classes/app/constants';
import { UserDataService } from '../data/user';
import { IUserPublicData } from 'src/app/classes/def/user/general';
import { EGPSTimeout } from 'src/app/classes/def/map/geolocation';
import { IAppSettingsContent } from 'src/app/classes/def/app/settings';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { ILatLng } from "src/app/classes/def/map/coords";
import { ApiDef } from "src/app/classes/app/api";

import {
    CreateMapOptions,
    GoogleMap,
    UpdateMapOptions,
    CameraConfig,
    CameraIdleCallbackData,
    CameraPosition,
    MarkerClickCallbackData
} from 'capacitor-plugin-google-maps';

import { PromiseUtils } from "../utils/promise-utils";


/// <reference types="@types/google.maps" />

// interface CameraConfig {
//     [key: string]: any
// }

interface IMapManagerObservableMultiplex extends IObservableMultiplex {
    cameraMoveInProgress: BehaviorSubject<boolean>
}

enum EMapMode {
    normal = 1,
    drone = 2
}

interface IMapPixel {
    x: number,
    y: number
}

enum EMapIds {
    leplace = "7fa032bdaa54aa7e",
    aubergine = "8bc29e6330b6c4f5"
}

@Injectable({
    providedIn: 'root'
})
export class MapManagerService {

    platform: IPlatformFlags = {} as IPlatformFlags;
    test: boolean = false;

    mapElement: HTMLElement;

    mapOptions: CreateMapOptions = {
        id: "leplace-map",
        element: null,
        apiKey: "",
        config: {
            center: {
                // lat: 44.439663,
                // lng: 26.096306
                lat: 0,
                lng: 0
            },
            zoom: 0,
            tilt: 0,
            ui: {
                // controls
                mapToolbarEnabled: false,
                mapTypeControlEnabled: true,
                streetViewControlEnabled: true,
                indoorLevelPickerEnabled: true,

                myLocationButtonEnabled: false,
                zoomControlsEnabled: false,
                compassEnabled: false,

                // gestures
                zoomGesturesEnabled: true,
                rotateGesturesEnabled: true,
                tiltGesturesEnabled: true
            },
            androidMapId: null,
            mapId: null,
            styles: mapStyles.standard // default fallback style
        }
    };

    // jsMapOptions: google.maps.MapOptions = {
    jsMapOptions: IJSMapOptions = {
        zoom: 15,
        center: null,
        rotateControl: true,
        scaleControl: true,
        mapTypeId: 'roadmap',
        disableDefaultUI: false,
        mapTypeControl: false,
        streetViewControl: true,
        streetViewControlOptions: {
            position: null
        },
        fullscreenControl: false,
        zoomControl: true,
        zoomControlOptions: {
            position: null
        },
        // override
        gestureHandling: 'greedy',
        heading: 0,
        tilt: 0,
        styles: mapStyles.standard, // default fallback style,
        mapId: null
    };

    cameraPositionDefault: CameraConfig;
    cameraPosition: CameraConfig;

    map: GoogleMap;
    jsMap: google.maps.Map;

    cameraMoveInProgress: boolean = false;
    resetMarkerInProgress: boolean = false;

    enabled: boolean = true;

    exploreRadiusCircleSize: number = EAppConstants.exploreCircleRadiusDefault;

    exploreCircle: boolean = false;
    exploreCirclePrev: boolean = false;

    exploreCircleDrone: boolean = false;
    exploreCircleDronePrev: boolean = false;

    userMarkerIcon: string = EMarkerIcons.user;
    mapVisible: boolean = true;

    jsMapListeners = [];

    zoomLayers = [
        {
            layer: EMarkerLayers.CRATES,
            prevZoom: 0,
            threshold: 16
        }
    ];

    observables: IMapManagerObservableMultiplex = {
        cameraMoveInProgress: null
    };

    dedicatedTimeouts = {
        locating: null
    };

    currentMapStyle: string = null;

    refreshMapTimeout: any;
    userMarker: IPlaceMarkerContent;
    droneMarker: IPlaceMarkerContent;
    navMarker: IPlaceMarkerContent;

    lastMoveOpts: IMoveMapOptions = null;
    lastMoveNavOpts: IMoveMapOptions = null;

    placeMarker: IPlaceMarkerContent;

    viewId: number = 0;

    keepJsMap: boolean = true;
    keepGmap: boolean = true;
    mapMode: number = null;

    useCustomZoomBounds: boolean = true;
    useNativeApi: boolean = false;

    constructor(
        public uiext: UiExtensionService,
        public markerHandler: MarkerHandlerService,
        public settings: SettingsManagerService,
        public markerUtils: MarkerUtilsService,
        public resourcesProvider: ResourcesCoreDataService,
        public analytics: AnalyticsService,
        public q: GenericQueueService,
        public userDataProvider: UserDataService
    ) {
        console.log("map manager service created");
        this.observables = ResourceManager.initBsubObj(this.observables);
        this.resetMapDefaultOptions();
        this.setMapNormalMode();
        this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.useNativeApi = !this.platform.WEB;
            }
        }, (err: Error) => {
            console.error(err);
        });

        this.settings.checkMapReady().then(() => {
            this.jsMapOptions.mapTypeId = google.maps.MapTypeId.ROADMAP;
            this.jsMapOptions.streetViewControlOptions.position = google.maps.ControlPosition.RIGHT_CENTER;
            this.jsMapOptions.zoomControlOptions.position = google.maps.ControlPosition.RIGHT_CENTER;
            console.log("map ready: ", this.jsMapOptions);
        });

        this.initCameraSettings();
        this.initNavMarker();
    }

    initCameraSettings() {
        let cpos: ICameraPosition = MapSettings.cameraPosition;
        this.cameraPositionDefault = {
            coordinate: cpos.target,
            zoom: cpos.zoom,
            bearing: cpos.bearing,
            angle: cpos.bearing,
            animate: cpos.duration != 0 ? true : false,
            animationDuration: cpos.duration
        };
        this.cameraPosition = Object.assign({}, this.cameraPositionDefault);
    }

    setNative(enabled: boolean) {
        this.useNativeApi = enabled;
        this.onPlatformLoaded();
    }

    /**
     * duplicate instance checker
     */
    getViewId(): number {
        this.viewId += 1;
        return this.viewId;
    }

    /**
     * duplicate instance checker
     * @param viewId 
     */
    checkViewId(viewId: number) {
        return viewId === this.viewId;
    }

    /**
     * init map coords via gmap
     * @param target 
     */
    initMapCoords(target: ILatLng) {
        this.mapOptions.config.center = target;
        this.mapOptions.config.zoom = MapSettings.zoomInLevelCrt;
        this.mapOptions.config.tilt = 0;
        this.jsMapOptions.center = target;
        this.jsMapOptions.zoom = MapSettings.zoomInLevelCrt;
        this.jsMapOptions.tilt = 0;
        // this.jsMapOptions.zoom = this.jsMap.getZoom();

        // ios
        // this.mapOptions.config.width = window.innerWidth;
        // this.mapOptions.config.height = window.innerHeight;
        // this.mapOptions.config.x = 0;
        // this.mapOptions.config.y = 0;
    }

    /**
     * update map options coords
     * not working/ still changing viewport location on map options update
     * @param coords 
     */
    updateMapOptionsCoords(coords: ILatLng) {
        let zoom: number = this.cameraPosition.zoom;
        this.mapOptions.config.center.lat = coords.lat;
        this.mapOptions.config.center.lng = coords.lng;
        this.jsMapOptions.center.lat = coords.lat;
        this.jsMapOptions.center.lng = coords.lng;
        this.mapOptions.config.zoom = zoom;
        this.jsMapOptions.zoom = zoom;
    }

    /**
     * apply map options
     * warning: resets map viewport location
     */
    applyMapOptions() {
        console.log("apply map options");
        if (this.useNativeApi) {
            let updateMapOptions: UpdateMapOptions = {
                id: this.mapOptions.id,
                config: this.mapOptions.config
            };
            // updateMapOptions["options"] = this.mapOptions.config; // temporary fix
            PromiseUtils.wrapNoAction(this.map.setOptions(updateMapOptions), true);
        } else {
            this.jsMap.setOptions(this.jsMapOptions);
        }
    }

    /**
     * set map drone mode w/ actions
     * warning: resets map viewport location when applied
     * @param apply 
     */
    setMapDroneMode() {
        if (this.mapMode !== EMapMode.drone) {
            this.mapMode = EMapMode.drone;
        } else {
            return;
        }
        if (this.useNativeApi) {
            this.mapOptions.config.ui.rotateGesturesEnabled = false;
            this.mapOptions.config.ui.tiltGesturesEnabled = false;
            this.mapOptions.config.ui.zoomGesturesEnabled = false;
        } else {
            this.jsMapOptions.gestureHandling = 'none';
        }
    }

    /**
     * set map normal mode w/ actions
     * warning: resets map viewport location when applied
     * @param apply 
     */
    setMapNormalMode() {
        if (this.mapMode !== EMapMode.normal) {
            this.mapMode = EMapMode.normal;
        } else {
            return;
        }
        if (this.useNativeApi) {
            this.mapOptions.config.ui.rotateGesturesEnabled = true;
            this.mapOptions.config.ui.tiltGesturesEnabled = true;
            this.mapOptions.config.ui.zoomGesturesEnabled = true;
        } else {
            this.jsMapOptions.gestureHandling = 'greedy';
        }
    }

    setMapBuildingsVisible(enabled: boolean) {
        console.log("set map buildings visible: ", enabled);
        if (this.useNativeApi) {
            // this.mapOptions.preferences.building = enabled;
        }
    }

    setMapIndoorMode(enabled: boolean) {
        console.log("set map indoor mode available: ", enabled);
        if (this.useNativeApi) {
            this.mapOptions.config.ui.indoorLevelPickerEnabled = enabled;
            // this.map.setIndoorEnabled(enabled);
        }
    }

    resetMapDefaultOptions() {
        console.log("set map default options");
        if (this.useNativeApi) {
            // this.mapOptions.preferences.building = true;
            this.mapOptions.config.ui.indoorLevelPickerEnabled = false;
        }
    }

    /**
     * load map style w/ apply/ no action
     * @param style 
     */
    setMapStyle(style: string) {
        this.setMapStyleCoreResolve(style).then(() => {
            console.log("set map style resolved");
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * check map style changed by name
     * @param style 
     */
    checkMapStyleChanged(style: string) {
        return style !== this.currentMapStyle;
    }

    /**
     * register map style changed by name
     * @param style 
     */
    setMapStyleRegister(style: string) {
        this.currentMapStyle = style;
    }

    /**
     * load map style w/ apply
     * @param style 
     */
    setMapStyleCoreResolve(style: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (!this.checkMapStyleChanged(style)) {
                resolve(true);
                return;
            }

            this.resourcesProvider.getMapStyle(style).then(async (mapStyle: IMapStyle) => {
                // console.log(mapStyle);
                if (mapStyle.config) {
                    let markerTheme: IMarkerTheme = {
                        name: mapStyle.name,
                        lineColor: mapStyle.lineColor,
                        // lineColor: "#dc4a38",
                        markerFrameColor: ThemeColors.theme.standard.lineColor
                        // markerFrameColor: mapStyle.lineColor,
                        // markerFrameColor: "#dc4a38"
                    };

                    if (
                        (this.useNativeApi && GeneralCache.useWebGL && GeneralCache.useWebGLMobile) ||
                        (this.platform.WEB && GeneralCache.useWebGL)
                    ) {
                        // don't apply custom style (loaded via mapId)
                        if (mapStyle.mapId != null) {
                            this.jsMapOptions.mapId = mapStyle.mapId;
                        } else {
                            this.jsMapOptions.mapId = EMapIds.leplace;
                        }
                        // this.jsMapOptions.mapId = EMapIds.aubergine;
                    } else {
                        if (mapStyle.mapId != null) {
                            this.mapOptions.config.mapId = mapStyle.mapId;
                        } else {
                            this.mapOptions.config.mapId = EMapIds.leplace;
                        }
                        this.mapOptions.config.androidMapId = this.mapOptions.config.mapId;
                        this.mapOptions.config.styles = mapStyle.config;

                        this.jsMapOptions.styles = mapStyle.config;
                    }

                    this.applyMapOptions();
                    this.markerHandler.setTheme(markerTheme);
                    this.setMapStyleRegister(style);
                }
                await SleepUtils.sleep(100);
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                this.applyMapOptions();
                this.analytics.dispatchError(err, "map-manager");
                resolve(false);
            });
        });
        return promise;
    }

    onPlatformLoaded() {
        this.platform = SettingsManagerService.settings.platformFlags;
    }


    setUserMarker(um: IPlaceMarkerContent) {
        console.log("set user marker");
        this.userMarker = um;
    }

    setUserMarkerCallback(callback: (data: IPlaceMarkerContent) => any) {
        this.userMarker.callback = (data: IPlaceMarkerContent) => {
            callback(data);
        };
    }

    setDroneMarker(um: IPlaceMarkerContent) {
        console.log("set drone marker");
        this.droneMarker = um;
    }

    initDroneMarker(callback: (data: IPlaceMarkerContent) => void) {
        this.droneMarker = this.markerUtils.getDronePlaceMarker(EMarkerLayers.DRONE, "FLY-DRONE", EMarkerIcons.drone);
        this.droneMarker.callback = callback;
        console.log("init drone marker: ", this.droneMarker);
    }

    initNavMarker() {
        this.navMarker = this.markerUtils.getUserNavMarker(EMarkerLayers.USER_NAV, null, EMarkerIcons.navPointer);
        this.navMarker.callback = (data: IPlaceMarkerContent) => {
            console.log("clicked on nav marker: ", data);
        };
        console.log("init nav marker: ", this.navMarker);
    }

    /**
     * load user marker as profile picture (if allowed)
     */
    loadUserMarker(callback: (data: IPlaceMarkerContent) => any): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {

            this.userMarker = this.markerUtils.getUserPlaceMarker(EMarkerLayers.USER, null, EMarkerIcons.user);
            // don't refresh until label changed (e.g. locating)
            this.userMarker.locationFixed = true;

            this.userMarker.callback = (data: IPlaceMarkerContent) => {
                callback(data);
            };

            if (SettingsManagerService.settings.app.settings.useProfilePictureOnTheMap.value) {
                this.userDataProvider.getUserPublicProfile(false).then((data: IUserPublicData) => {
                    if (data && data.photoUrl) {
                        this.userMarker.icon = data.photoUrl;
                    }
                    this.setUserMarker(this.userMarker);
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    this.setUserMarker(this.userMarker);
                    resolve(false);
                });
            } else {
                this.userMarker.icon = EMarkerIcons.user;
                this.setUserMarker(this.userMarker);
                resolve(true);
            }
        });
        return promise;
    }

    /**
     * set location found, start timeout, show label above user marker if location is not available
     * @param found 
     */
    setLocationFound(found: boolean, withTimeout: boolean) {
        // console.log("set location found: ", found);

        if (!this.userMarker) {
            return;
        }

        let appSettings: IAppSettingsContent = this.settings.getAppSettings();
        if (!appSettings.markerLocatingMode.value) {
            return;
        }
        if (this.platform.IOS || this.platform.WEB) {
            // if (this.platform.WEB) {
            // prevent duplicating marker issue
            return;
        }

        if (found) {
            if (!this.userMarker.locationFixed) {
                this.userMarker.locationFixed = true;
                this.userMarker.label = "";
                // this.setUserMarker(this.userMarker);
                this.resetUserMarker();
            }

            this.dedicatedTimeouts.locating = ResourceManager.clearTimeout(this.dedicatedTimeouts.locating);
            if (withTimeout) {
                // start location timeout
                this.dedicatedTimeouts.locating = setTimeout(() => {
                    if (!GeneralCache.paused) {
                        this.setLocationFound(false, withTimeout);
                    }
                }, EGPSTimeout.watchdog);
            }
        } else {
            if (!GeneralCache.paused) {
                if (this.userMarker.locationFixed) {
                    this.userMarker.locationFixed = false;
                    this.userMarker.label = "Locating..";
                    // this.setUserMarker(this.userMarker);
                    this.resetUserMarker();
                }
            }
        }
    }


    /**
     * clear flags as part of the deinitialization procedure
     * allows for a clean new session on map reload
     */
    clearFlags() {
        this.userMarker = null;
        this.droneMarker = null;
        this.lastMoveOpts = null;
        this.lastMoveNavOpts = null;
        this.setCameraMoveInProgress(false);
        this.exploreCircle = false;
        this.exploreCirclePrev = false;
        this.exploreCircleDrone = false;
        this.exploreCircleDronePrev = false;
    }

    clearDroneMarker() {
        if (!this.droneMarker) {
            return;
        }
        this.droneMarker.location = { lat: null, lng: null } as any;
    }

    setMap(map: GoogleMap) {
        this.map = map;
    }

    getMap() {
        return this.map;
    }

    setJsMap(map: google.maps.Map) {
        this.jsMap = map;
    }

    createJsMap(div) {
        this.jsMap = new google.maps.Map(div, this.jsMapOptions);
    }

    /**
     * init the google maps js api for the entire app
     * the js map is used as a singleton (and should be)
     * if the map is already initialized then just change the div container
     * @param element 
     * @param options 
     */
    initJsMap(element: ElementRef, isWeb: boolean, jsMapOptions: IJSMapOptions = null): boolean {
        console.log("init js map");
        if (jsMapOptions) {
            this.jsMapOptions = jsMapOptions;
        }
        if (GeneralCache.useWebGL) {
            // use webgl/vector map for js map
            if (isWeb) {
                this.jsMapOptions.mapId = EMapIds.leplace;
                this.jsMapOptions.styles = null;
            } else {
                if (GeneralCache.useWebGLMobile) {
                    switch (GeneralCache.os) {
                        case EOS.android:
                            this.jsMapOptions.mapId = EMapIds.leplace;
                            break;
                        case EOS.ios:
                            this.jsMapOptions.mapId = EMapIds.leplace;
                            break;
                    }
                    this.jsMapOptions.styles = null;
                }
            }
        }
        if (GeneralCache.isPublicDistribution) {
            this.jsMapOptions.streetViewControl = false;
        }
        if (this.jsMap && this.keepJsMap) {
            this.changeJsMapDiv(element);
            return true;
        } else {
            this.jsMap = new google.maps.Map(element.nativeElement, this.jsMapOptions);
            return false;
        }
    }

    getJsMap(): google.maps.Map {
        return this.jsMap;
    }

    calculateZoomLevel(distance: number) {
        // Implement your logic to calculate the new zoom level here
        // You may need to consider factors like startCoords and e.touches[0]
        // to determine how much to zoom in/out.
        // let startDistance = GeometryUtils.getDistanceBetweenEarthCoordinates(startCoords, endCoords, 0);
        let currentZoomLevel: number = this.jsMap.getZoom();
        let zoomFactor: number = 1; //sensitivity factor
        let newZoomLevel = currentZoomLevel + (Math.log2(distance / zoomFactor));

        // Optionally, set constraints on the newZoomLevel (e.g., minimum and maximum values)
        // newZoomLevel = Math.min(maxZoom, Math.max(minZoom, newZoomLevel));

        return newZoomLevel;
    }

    addNativeMapListeners() {
        this.map.setOnMarkerClickListener((data: MarkerClickCallbackData) => {
            console.log("marker clicked: ", data);
            if (data && data.markerId) {
                this.markerHandler.handleMarkerCallbackExt(data.markerId);
            }
        });
    }

    addJsMapListeners(onDrag: () => any, onDragStart: () => any, onDragEnd: () => any, onZoom: (zoom: number) => any) {
        let startCoords: ILatLng;
        let startPixel: IMapPixel;
        let isPinchZoom: boolean = false;
        let isDoubleTap: boolean = false;

        console.log("add js map listeners");
        if (this.jsMapListeners.length > 0) {
            console.warn("js map listeners already initialized");
        }
        this.appendJsMapListener(this.jsMap.addListener('drag', (e) => {
            if (!isPinchZoom) {
                onDrag();
            }
        }));

        this.appendJsMapListener(this.jsMap.addListener('dragstart', (e) => {
            onDragStart();
        }));

        this.appendJsMapListener(this.jsMap.addListener('dragend', (e) => {
            onDragEnd();
        }));

        // this.appendJsMapListener(this.jsMap.addListener('mousemove', (e: google.maps.MapMouseEvent) => {
        //     if (isPinchZoom) {
        //         console.log("mouse move pinch zoom: ", e);
        //         let endCoords = new LatLng(e.latLng.lat(), e.latLng.lng());
        //         let endPixel: IMapPixel = e["pixel"];

        //         let distance: number = GeometryUtils.getDistanceBetweenEarthCoordinates(startCoords, endCoords, 0);
        //         let pixelDistance: number = Math.sqrt(
        //             Math.pow(endPixel.x - startPixel.x, 2) +
        //             Math.pow(endPixel.y - startPixel.y, 2)
        //         );
        //         console.log("pinch zoom distance: " + distance + " pixel distance: " + pixelDistance);
        //         if (pixelDistance < 10) {
        //             // Consider it a double tap if the touch points are close enough
        //             isDoubleTap = true;
        //         } else {
        //             // Calculate new zoom level based on touch movement
        //             let zoomLevel = this.calculateZoomLevel(distance);
        //             console.log("compute pinch zoom level: ", zoomLevel);
        //             this.jsMap.setZoom(zoomLevel);
        //         }
        //         isPinchZoom = false;
        //         startCoords = null;
        //         startPixel = null;
        //     }
        // }));

        this.appendJsMapListener(this.jsMap.addListener('zoom_changed', () => {
            let zoom: number = this.jsMap.getZoom();
            // console.log("set zoom level (3)/disabled ", zoom);
            // console.log("set zoom level (3)");
            onZoom(zoom);
        }));

        // this.appendJsMapListener(this.jsMap.addListener('click', (e: google.maps.MapMouseEvent) => {
        //     console.log("caught click: ", e);
        //     if (!isPinchZoom) {
        //         isPinchZoom = true;
        //         if (!startCoords) {
        //             startCoords = new LatLng(e.latLng.lat(), e.latLng.lng());
        //         }
        //         if (!startPixel) {
        //             startPixel = e["pixel"];
        //         }
        //     } else {
        //         isPinchZoom = false;
        //     }

        //     // e.stop();
        // }));
    }

    /**
     * change/update js map div container
     * @param newElement 
     */
    changeJsMapDiv(newElement: ElementRef) {
        console.log("change js map div");
        if (!this.jsMap) {
            return;
        }

        // The Node.appendChild() method adds a node to the end of the list of children of a specified parent node. 
        // If the given child is a reference to an existing node in the document, appendChild() moves it from its current position to the new position 
        // (there is no requirement to remove the node from its parent node before appending it to some other node).
        try {
            let mapNode = this.jsMap.getDiv();
            newElement.nativeElement.appendChild(mapNode);
            // newElement.nativeElement = mapNode;
        } catch (e) {
            console.error("change js map div error: ", e);
        }
    }

    deinitJsMap(newElement: ElementRef) {
        console.log("remove js map div: ", newElement.nativeElement);
        if (!this.jsMap) {
            return;
        }
        try {
            this.clearJsMapListeners();
            // let mapNode = this.jsMap.getDiv();
            // console.log(mapNode);
            // console.log(newElement.nativeElement.parentElement);
            // newElement.nativeElement.parentElement.removeChild(mapNode);
            if (!this.keepJsMap) {
                this.jsMap = null;
            }
        } catch (e) {
            console.error("remove js map div error: ", e);
            if (!this.keepJsMap) {
                this.jsMap = null;
            }
        }
    }

    private appendJsMapListener(listener: any) {
        this.jsMapListeners.push(listener);
    }

    clearJsMapListeners() {
        console.log("clear js map listeners");
        for (let i = 0; i < this.jsMapListeners.length; i++) {
            this.jsMapListeners[i].remove();
        }
        this.jsMapListeners = [];
    }


    // setJsMap(map: google.maps.Map) {
    //     this.jsMap = map;
    // }

    /**
     * EXPERIMENTAL
     * toggle map view
     * used to disable DOM watch and improve performance while e.g. opening a modal over the map 
     * NOT WORKING AS EXPECTED
     */
    toggleMap() {
        if (this.map) {
            if (this.mapVisible) {
                this.mapVisible = false;
                this.map.setVisible(false);
            } else {
                this.mapVisible = true;
                this.map.setVisible(true);
            }
        }
    }

    /**
     * create google map (for the first time)
     * @param container 
     */
    createMap(container: HTMLElement): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            console.log("create map");
            console.log("container: ", container);
            this.mapOptions.element = container;
            this.mapElement = container;

            this.mapOptions.apiKey = ApiDef.googleMapsApiKeyJS;
            try {
                console.log("creating map");
                this.map = await GoogleMap.create(this.mapOptions);
                console.log("map created");
                if (this.platform.IOS) {
                    console.log("waiting for map loaded");
                    await SleepUtils.sleep(500);
                }
                await this.map.clearMap();
                console.log("map cleared");
                resolve(true);
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

    /**
     * call when map has been previously initialized once, e.g. re-entering map view
     * https://forum.ionicframework.com/t/ionic-native-google-maps-plugin-map-disappears-after-navigating-to-internal-page-possible-stacking-issue/122372
     */
    reattachMapContainer(container: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            console.log("reattach map container");

            let refreshFn = (counter: number) => {
                this.refreshMapTimeout = setTimeout(() => {
                    try {
                        // this.map.setDiv(container);
                        this.map.setVisible(true);
                        // this.map.setClickable(true);
                        // this.map.refreshLayout();
                        this.map.setAllGesturesEnabled(true);
                        console.log("reattach map container (retry) done");
                    } catch (e) {
                        console.log("reattach map container (error)", e);
                    }

                    if (counter > 0) {
                        refreshFn(counter - 1);
                    } else {
                        this.refreshMapTimeout = null;
                        console.log("reattach map container (timeout resolve)");
                    }
                }, 1000); // timeout is a bit of a hack but it helps here
            }

            if (this.map) {
                // map has to be already initialized once
                try {
                    // this.map.setDiv(container);
                    this.map.setVisible(true);
                    // this.map.setClickable(true);
                    this.map.setAllGesturesEnabled(true);
                    console.log("reattach map container done");
                } catch (e) {
                    console.log("reattach map container (error)", e);
                }

                // refreshFn(5);
            }

            // retry refresh but resolve anyways
            // the fix is async
            resolve(true);
        });
        return promise;
    }


    async detachMapContainer() {
        // unset div & visibility on exit

        // Before showing your overlaying div:
        // One thing I've noticed, though, is that if you are dynamically showing or hiding the div, 
        // the plugin doesn't always pick it up, and the clicks go straight through to the map. 
        // I've been able to get around this by calling

        console.log("detach map container");

        if (this.map) {
            try {
                this.markerHandler.setEnabled(false);
                await SleepUtils.sleep(1000);
                this.refreshMapTimeout = ResourceManager.clearTimeout(this.refreshMapTimeout);
                // this.map.setClickable(false);
                this.map.setAllGesturesEnabled(false);
                this.map.setVisible(false);
                // this.map.setDiv(null);
            } catch (e) {
                console.error(e);
            }
        }
    }

    quitSession() {
        this.dedicatedTimeouts = ResourceManager.clearTimeoutObj(this.dedicatedTimeouts);
    }

    resetQueue() {
        this.q.resetQueue(EQueues.map);
    }

    /**
     * enable/disable map interaction
     */
    setEnabled(enabled: boolean) {
        console.log("map manager set: ", enabled);
        this.markerHandler.setEnabled(enabled);
        this.enabled = enabled;
        if (this.map) {
            // this.map.setClickable(enabled);
        }
    }

    /**
     * clear markers and map
     */
    clearMapWithMarkers() {
        return this.clearMapWithMarkersCore(() => { return this.markerHandler.clearAllResolve(); })
    }

    /**
     * clear markers and map
     * clear marker layers, don't actually remove markers (speed up unloading on mobile platform)
     */
    clearMapWithMarkersNoRemove() {
        return this.clearMapWithMarkersCore(() => { return this.markerHandler.clearAllNoRemove(); })
    }

    /**
     * clear markers and map
     * custom removeFn
     * @param removeFn 
     */
    clearMapWithMarkersCore(removeFn: () => Promise<any>) {
        let promise = new Promise((resolve) => {
            removeFn().then(() => {
                this.clearMapCore().then((res) => {
                    resolve(res);
                });
            }).catch((err: Error) => {
                console.error(err);
                this.clearMapCore().then((res) => {
                    resolve(res);
                });
            });
        });
        return promise;
    }

    /**
     * clear map
     * remove all overlays
     */
    clearMapCore(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            if (this.map) {
                await SleepUtils.sleep(500);
                this.map.clearMap().then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });
        return promise;
    }

    /**
     * cleanup all markers and div element
     * destroy the map object
     * clear listeners for js map too
     */
    removeMap() {
        return new Promise(async (resolve) => {
            console.log("remove map");
            try {
                if (this.map) {
                    try {
                        // await this.map.clearMap();
                        // this.map.setDiv(null);
                    } catch (err) {
                        console.error(err);
                    }
                    await this.removeMapCore();
                }
                if (this.jsMap) {
                    this.clearJsMapListeners();
                }
                resolve(true);
            } catch (err) {
                console.error(err);
                resolve(false);
            }
        });
    }

    /**
     * destroy the map object
     */
    removeMapCore() {
        return new Promise((resolve) => {
            this.map.destroy().then(() => {
                console.log("map removed");
                this.map = null; // disabled reattaching
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
    }

    setUserMarkerIcon(url: string) {
        if (url) {
            this.userMarkerIcon = url;
        } else {
            this.userMarkerIcon = EMarkerIcons.user;
        }
    }

    /**
     * set size of circle around user
     * also enable/disable circle (e.g. if the size is 0/null then disable circle)
     * @param size 
     */
    setExploreRadiusCircleSize(size: number) {
        console.log("set explore radius size: ", size);
        if (!size) {
            this.exploreCircle = false;
            this.exploreCircleDrone = false;
        } else {
            this.exploreCircle = true;
            this.exploreCircleDrone = true;
            this.exploreRadiusCircleSize = size;
        }
    }

    enableExploreCircle(enable: boolean) {
        this.exploreCircle = enable;
        this.exploreCircleDrone = enable;
    }


    moveToFitWeb(bounds: any) {
        if (this.useNativeApi) {

        } else {
            this.jsMap.fitBounds(bounds);
        }
    }

    /**
     * set zoom from external (map event)
     * @param zoom
     */
    setZoom(zoom: number) {
        this.cameraPosition.zoom = zoom;
    }

    /**
     * set camera from external (map event)
     * @param data 
     */
    setCameraStats(data: CameraIdleCallbackData) {
        this.cameraPosition.angle = data.tilt;
        this.cameraPosition.bearing = data.bearing;
        this.cameraPosition.zoom = data.zoom;
    }

    /**
     * handle marker display based on zoom level
     * @param zoomLevel 
     */
    showLayersBasedOnZoom(zoomLevel: number) {
        this.zoomLayers.forEach((zl) => {
            if (zoomLevel === null) {
                this.markerHandler.setVisibleLayer(zl.layer, true);
            } else {
                if (zl.prevZoom < zl.threshold && zoomLevel >= zl.threshold) {
                    // transition from hide to show
                    this.markerHandler.setVisibleLayer(zl.layer, true);
                } else if (zl.prevZoom >= zl.threshold && zoomLevel < zl.threshold) {
                    // transition from show to hide
                    this.markerHandler.setVisibleLayer(zl.layer, false);
                }
                zl.prevZoom = zoomLevel;
            }
        });
    }


    /**
     * show/hide layers
     * if show is null, then enforce the latest settings (e.g. the item scanner would add markers and then they will be hidden from here)
     * @param layer 
     * @param show 
     */
    showHideLayers(layer: string, show: boolean) {
        console.log("show hide layer: ", layer);
        this.markerHandler.setVisibleLayer(layer, show);
    }

    restoreShowHideLayers(layer: string) {
        // enforce existing settings on all new markers
        console.log("reset show hide layer: ", layer);
        this.markerHandler.setVisibleLayer(layer, this.markerHandler.getVisibleLayer(layer));
    }


    moveCameraToPositionMobile(coords: ILatLng[], animate: boolean, duration: number, withDelta: boolean, extraDelta: boolean): Promise<boolean> {
        // let position: mapint.CameraPosition2 = {
        //   target: ILatLng,
        //   tilt: 0,
        //   duration: 1000
        // };
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {

            if (!this.enabled) {
                resolve(false);
                return;
            }

            if (duration !== null) {
                this.cameraPosition.animationDuration = duration;
            } else {
                this.cameraPosition.animationDuration = this.cameraPositionDefault.animationDuration;
            }

            this.cameraPosition.animate = animate;

            // this.cameraPosition.tilt = 0;
            // let crtCameraPosition: CameraPosition<LatLng> = Object.assign({} as CameraPosition<LatLng>, this.cameraPosition);

            let crtCameraPositionBounds: CameraConfig = null;

            let target: ILatLng;

            if (coords.length === 1) {
                target = coords[0];
                this.cameraPosition.coordinate = target;
                console.log("move map point");
            } else {
                let targets: ILatLng[] = GeometryUtils.extendBoundsExtra(coords, withDelta, extraDelta, this.getZoom());
                console.log("move map boundary");
                crtCameraPositionBounds = {
                    // target: position,
                    coordinate: null,
                    angle: 0,
                    zoom: this.cameraPosition.zoom,
                    // bearing: this.cameraPosition.bearing,
                    bearing: 0,
                    animate: animate,
                    animationDuration: this.cameraPosition.animationDuration
                    // padding: this.cameraPosition.padding
                };

                // update camera target for zoom out
                this.cameraPosition.coordinate = GeometryUtils.getCenterPoint(targets);

                if (!this.useCustomZoomBounds) {
                    // fit bounds sets bearing to 0
                    // sync current camera position to prevent glitches
                    this.cameraPosition.bearing = 0;
                    this.cameraPosition.angle = 0;
                    console.log("move map no custom bounds");
                } else {
                    // use custom target/zoom mode instead of native method (which messes up orientation)
                    let pixelWidth: number = this.getPixelWidth();
                    let zoom: number = GeometryUtils.getZoomLevelForBounds(targets, pixelWidth, this.cameraPosition.bearing, false);
                    console.log("move map to fit bounds, pixelWidth: " + pixelWidth + ", zoom: " + zoom + ", hdg: " + this.cameraPosition.bearing);
                    this.cameraPosition.zoom = zoom;
                    crtCameraPositionBounds = null;
                }
            }

            // prevent overrides
            let center: ILatLng = this.cameraPosition.coordinate;

            try {
                if (crtCameraPositionBounds !== null) {
                    console.log("move camera with bounds: ", crtCameraPositionBounds);
                    await this.map.setCamera(crtCameraPositionBounds);
                    if (withDelta && extraDelta) {
                        await this.zoomOutDelta(0.5, center);
                    }
                    resolve(true);
                } else {
                    console.log("move camera with position, zoom level: ", this.cameraPosition.zoom);
                    await this.map.setCamera(this.cameraPosition);
                    resolve(true);
                }
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

    /**
     * rotate map with absolute heading and animation duration
     * @param heading 
     * @param duration 
     */
    rotateMap(heading: number, duration: number) {
        let promise = new Promise((resolve, reject) => {
            if (!this.enabled) {
                resolve(false);
                return;
            }
            this.cameraPosition.bearing = heading;
            let animate = true;
            if (duration === 0) {
                animate = false;
            }

            if (this.test) {
                reject();
            }

            if (this.useNativeApi) {
                this.moveCameraToPositionMobile([this.cameraPosition.coordinate], animate, duration, false, false).then(() => {
                    resolve(true);
                });
            } else {
                this.jsMap.setHeading(heading);
                resolve(true);
            }
        });
        return promise;
    }

    /**
     * move map to fit bounds
     * return if aux places list is empty
     * always resolve, no reject
     */
    moveMapToFitBounds(wpoints: ILatLng[], duration: number, delta: boolean, extraDelta: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            console.log("move to fit wp: ", wpoints);
            if (wpoints.length > 0) {
                if (this.useNativeApi) {
                    this.moveCameraToPositionMobile(wpoints, true, duration, delta, extraDelta).then(() => {
                        resolve(true);
                    }).catch(() => {
                        resolve(true);
                    });
                } else {
                    if (!this.useCustomZoomBounds) {
                        let bounds = new google.maps.LatLngBounds();
                        wpoints.forEach(wp => {
                            bounds.extend(wp);
                        });
                        this.moveToFitWeb(bounds);
                    } else {
                        let targetPoints: ILatLng[] = GeometryUtils.extendBoundsExtra(wpoints, delta, extraDelta, this.getZoom());
                        let target: ILatLng = GeometryUtils.getCenterPoint(targetPoints);
                        let pixelWidth: number = this.getPixelWidth();
                        let zoom: number = GeometryUtils.getZoomLevelForBounds(targetPoints, pixelWidth, null, true);
                        console.log("move map to fit bounds, pixelWidth: " + pixelWidth + ", zoom: " + zoom);
                        this.jsMap.panTo(target);
                        this.jsMap.setZoom(zoom);
                    }
                    resolve(true);
                }
            } else {
                resolve(true);
            }
        });
        return promise;
    }

    /**
     * get map zoom
     */
    getZoom() {
        let zoom: number = null;
        if (this.useNativeApi) {
            // zoom = this.map.getCameraZoom();
            zoom = this.cameraPosition.zoom; // updated on camera idle event (ext)
            if (zoom == null) {
                // zoom = this.mapOptions.camera.zoom;
                zoom = this.mapOptions.config.zoom;
            }
            if (zoom == null) {
                zoom = MapSettings.zoomInLevelCrt;
            }
        } else {
            zoom = this.jsMap.getZoom();
            if (zoom == null) {
                zoom = this.jsMapOptions.zoom;
            }
        }
        console.log("getzoom: ", zoom);
        return zoom;
    }

    getPixelWidth() {
        let pixelWidth: number = 100;
        // let pixelWidth: number = this.map.getDiv().offsetWidth;
        if (this.useNativeApi) {
            let element = this.mapElement;
            console.log("get pixel width for element: ", element);
            if (element == null) {
                element = this.mapOptions.element;
            }
            console.log("get pixel width for element: ", element);
            if (element != null) {
                pixelWidth = element.clientWidth;
            }
            // pixelWidth = this.map.getDiv().clientWidth;
        } else {
            pixelWidth = this.jsMap.getDiv().clientWidth;
        }
        if (pixelWidth == null) {
            pixelWidth = 384;
            console.warn("using default pixel width");
        }
        return pixelWidth;
    }

    zoomOutDelta(delta: number, center: ILatLng) {
        let zoom: number = this.getZoom();
        zoom -= delta;
        this.cameraPosition.zoom = zoom;
        if (center != null) {
            this.cameraPosition.coordinate = center;
        }
        return this.map.setCamera(this.cameraPosition);
    }

    /**
     * prevent glitches when handling gestures while moving map
     * get current camera position from map object and restore settings on next map update
     */
    reposition(apply: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            try {
                if (!this.lastMoveOpts) {
                    reject(new Error("unknown last position"));
                    return;
                }
                console.log("reposition camera");
                let opts: IMoveMapOptions = Object.assign({}, this.lastMoveOpts);
                opts.force = false;
                opts.userMarker = false;
                opts.animateCamera = false;
                if (this.useNativeApi) {
                    let campos: CameraPosition = await this.map.getCameraPosition();
                    if (campos != null) {
                        opts.bearing = campos.bearing;
                        opts.zoom = campos.zoom;
                        opts.tilt = campos.tilt;
                        opts.location = new ILatLng(campos.target.lat, campos.target.lng);
                    } else {
                        console.warn("undefined camera position");
                    }
                } else {
                    let mapCenter = this.jsMap.getCenter();
                    opts.bearing = this.jsMap.getHeading();
                    opts.zoom = this.jsMap.getZoom(); // could that be the zoom out bug?
                    opts.tilt = this.jsMap.getTilt();
                    opts.location = new ILatLng(mapCenter.lat(), mapCenter.lng());
                }
                if (apply) {
                    this.moveMapWrapper(opts.location, opts).then((res) => {
                        resolve(res);
                    }).catch((err) => {
                        reject(err);
                    });
                } else {
                    this.lastMoveOpts = opts;
                    resolve(true);
                }
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

    initNavMoveOpts(opts: IMoveMapOptions, init: boolean) {
        if (!this.lastMoveNavOpts || init) {
            this.lastMoveNavOpts = Object.assign({}, opts);
        }
    }

    moveMapExt(location: ILatLng): Promise<boolean> {
        let options: IMoveMapOptions = {
            animateCamera: false,
            animateMarker: false,
            zoom: null,
            bearing: null,
            tilt: null,
            moveMap: true,
            force: true,
            userMarker: true,
            duration: null
        };
        return this.moveMapWrapper(location, options);
    }

    /**
     * move map to location and set user marker
     * handle timeout
     * @param location
     * @param animate
     * @param defaults
     */
    moveMapWrapper(location: ILatLng, opts: IMoveMapOptions): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            let timeout = null;
            let timeoutSpec: number = opts.timeout != null ? opts.timeout : 5000;
            if (timeoutSpec != null) {
                // launch timeout, resolve fallback
                timeout = setTimeout(() => {
                    console.warn("move map wrapper timeout resolve");
                    resolve(false);
                }, timeoutSpec);
            }
            this.moveMap(location, opts).then((res: boolean) => {
                ResourceManager.clearTimeout(timeout);
                resolve(res);
            }).catch((err: Error) => {
                ResourceManager.clearTimeout(timeout);
                reject(err);
            });
        });
        return promise;
    }

    /**
     * move map to location and set user marker
     * @param location
     * @param animate
     * @param defaults
     */
    moveMap(location: ILatLng, opts: IMoveMapOptions): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            // console.log("move map: " + opts.moveMap + ", zoom: " + opts.zoom);
            if (!this.enabled) {
                resolve(false);
                return;
            }

            if (opts.userMarker && !this.userMarker) {
                reject(new Error("user marker not initialized"));
                return;
            }

            if (opts.droneMarker && !this.droneMarker) {
                reject(new Error("drone marker not initialized"));
                return;
            }

            // don't update map while running in background
            if (GeneralCache.paused) {
                resolve(false);
                return;
            }


            // console.log("move map to coords: " + location.lat + ", " + location.lng);

            let res = (data: IGenericMessageQueueEvent) => {
                // console.log(data);
                if (data.state) {
                    resolve(data.data);
                } else {
                    reject(data.data);
                }
            };

            if (opts.userMarker) {
                this.userMarker.location = location as any;
            }
            if (opts.droneMarker) {
                this.droneMarker.location = location as any;
            }

            if (!opts.droneMarker) {
                // only track user location marker options
                this.lastMoveOpts = opts;
            }

            opts.location = location;
            let delay: number = opts.unlockLimiter ? 0 : (opts.bumpLimiter ? 55 : 100);

            if (this.useNativeApi) {
                if (!this.map) {
                    resolve(false);
                    return;
                }

                if (opts.moveMap) {
                    if (opts.bearing !== null) {
                        this.cameraPosition.bearing = opts.bearing;
                    } else {
                        // this.cameraPosition.bearing = this.map.getCameraBearing();
                    }
                    if (opts.zoom !== null) {
                        this.cameraPosition.zoom = opts.zoom;
                    } else {
                        // this.cameraPosition.zoom = this.map.getCameraZoom();
                    }
                    if (opts.tilt !== null) {
                        this.cameraPosition.angle = opts.tilt;
                    } else {
                        // this.cameraPosition.angle = this.map.getCameraTilt();
                    }
                    this.updateMapOptionsCoords(location);
                }

                this.cameraPosition.coordinate = location;

                if (opts.force) {
                    this.moveCameraToPositionMobileCore(opts).then((res) => {
                        resolve(res);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    this.q.enqueueWithDataSnapshot((data) => {
                        return this.moveCameraToPositionMobileCore(data);
                    }, res, null, opts, EQueues.map, {
                        size: 10,
                        delay: delay,
                        timeout: 3000
                    }, 50000);
                }
            } else {
                if (opts.force) {
                    this.moveCameraToPositionWebCore(opts).then((res) => {
                        resolve(res);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    this.q.enqueueWithDataSnapshot((data) => {
                        return this.moveCameraToPositionWebCore(data);
                    }, res, null, opts, EQueues.map, {
                        size: 10,
                        delay: delay,
                        timeout: 3000
                    }, 50000);
                }
            }
        });
        return promise;
    }

    refreshMapLayout() {
        PromiseUtils.wrapNoAction(this.map.refreshLayout(), true);
    }

    /**
    * function to be called on GPS position update
    * moving map based on the view mode 2d/3d
    * @param coordinates 
    */
    moveMapContext(coordinates: ILatLng, options: IMoveMapContext): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            // console.log("move map context: ", options);
            let animate: boolean = options.duration > 0;

            if (!options.mapInitialized) {
                // set zoom to default and tilt to 0
                this.moveMapWrapper(coordinates, {
                    animateCamera: false,
                    animateMarker: false,
                    zoom: MapSettings.zoomInLevelCrt,
                    bearing: options.filteredHeading,
                    tilt: 0,
                    moveMap: true,
                    force: false,
                    userMarker: true,
                    duration: null
                }).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                switch (options.followMode) {
                    case EFollowMode.NONE:
                        // just update the marker position
                        this.moveMapWrapper(coordinates, {
                            animateCamera: animate,
                            animateMarker: false,
                            zoom: MapSettings.zoomInLevelCrt,
                            bearing: 0,
                            tilt: 0,
                            moveMap: false,
                            force: false,
                            userMarker: true,
                            duration: options.duration
                        }).then(() => {
                            resolve(true);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                        break;
                    case EFollowMode.MOVE_MAP:
                        // move map on gps heading
                        // set zoom, heading and tilt to defaults
                        this.moveMapWrapper(coordinates, {
                            animateCamera: animate,
                            animateMarker: false,
                            zoom: MapSettings.zoomInLevelCrt,
                            bearing: 0,
                            tilt: 0,
                            moveMap: true,
                            force: false,
                            userMarker: true,
                            duration: options.duration
                        }).then(() => {
                            resolve(true);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                        break;
                    case EFollowMode.MOVE_MAP_HEADING_2D:
                        // move map on gps heading
                        // set zoom and tilt to defaults, use current heading
                        this.moveMapWrapper(coordinates, {
                            animateCamera: animate,
                            animateMarker: false,
                            zoom: MapSettings.zoomInLevelCrt,
                            bearing: options.filteredHeading,
                            tilt: 0,
                            moveMap: true,
                            force: false,
                            userMarker: true,
                            duration: options.duration
                        }).then(() => {
                            resolve(true);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                        break;
                    case EFollowMode.MOVE_MAP_HEADING_3D:
                        // move map on gps heading
                        // set zoom and tilt to defaults
                        this.moveMapWrapper(coordinates, {
                            animateCamera: animate,
                            animateMarker: false,
                            zoom: MapSettings.zoomInLevelCrt,
                            bearing: options.filteredHeading,
                            tilt: MapSettings.navTilt,
                            moveMap: true,
                            force: false,
                            userMarker: true,
                            duration: options.duration
                        }).then(() => {
                            resolve(true);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                        break;
                    default:
                        resolve(true);
                        break;
                }
            }
        });
        return promise;
    }


    /**
     * move camera w/ user marker, circle marker
     * @param opts 
     */
    moveCameraToPositionWebCore(opts: IMoveMapOptions): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            console.log("move camera to position web core");
            if (this.jsMap !== undefined) {
                if (opts.moveMap) {
                    this.jsMap.panTo(opts.location);
                    if (opts.zoom !== null) {
                        this.jsMap.setZoom(opts.zoom);
                    }
                    this.jsMap.setTilt(opts.tilt);
                    this.jsMap.setHeading(opts.bearing);
                }
                if (opts.userMarker || opts.droneMarker) {
                    let markerCallback: Promise<boolean> = opts.droneMarker ? this.moveDroneMarkerCore(opts) : this.moveUserMarkerCore(opts);
                    this.setCameraMoveInProgress(true);
                    markerCallback.then((res) => {
                        this.setCameraMoveInProgress(false);
                        resolve(res);
                    }).catch((err: Error) => {
                        this.setCameraMoveInProgress(false);
                        reject(err);
                    });
                } else {
                    console.log("no marker");
                    console.log(opts);
                    resolve(false);
                }
            } else {
                reject(new Error("map undefined"));
            }
        });
        return promise;
    }

    setCameraMoveInProgress(set: boolean) {
        this.cameraMoveInProgress = set;
        this.observables.cameraMoveInProgress.next(set);
    }

    /**
     * move camera w/ user marker, circle marker
     * @param opts 
     */
    moveCameraToPositionMobileCore(opts: IMoveMapOptions): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            let inSequence: boolean = true;
            this.setCameraMoveInProgress(true);
            if (opts.animateCamera && opts.animateMarker) {
                inSequence = false;
            }
            if (opts.syncMode) {
                inSequence = false;
            }
            if (inSequence) {
                let promiseMove: Promise<boolean>;
                if (opts.moveMap) {
                    promiseMove = this.moveCameraToPositionMobile([this.cameraPosition.coordinate], opts.animateCamera, opts.duration, true, false);
                } else {
                    promiseMove = Promise.resolve(true);
                }
                promiseMove.then(() => {
                    if (opts.userMarker || opts.droneMarker) {
                        let markerCallback: Promise<boolean> = opts.droneMarker ? this.moveDroneMarkerCore(opts) : this.moveUserMarkerCore(opts);
                        markerCallback.then((res) => {
                            this.setCameraMoveInProgress(false);
                            resolve(res);
                        }).catch((err: Error) => {
                            this.setCameraMoveInProgress(false);
                            reject(err);
                        });
                    } else {
                        this.setCameraMoveInProgress(false);
                        resolve(true);
                    }
                }).catch((err: Error) => {
                    this.setCameraMoveInProgress(false);
                    reject(err);
                });
            } else {
                let promises: Promise<boolean>[] = [];
                let resContainer = {
                    markerPos: null
                };
                if (opts.moveMap) {
                    promises.push(this.moveCameraToPositionMobile([this.cameraPosition.coordinate], opts.animateCamera, opts.duration, true, false));
                } else {
                    promises.push(Promise.resolve(true));
                }
                promises.push(new Promise((resolve, reject) => {
                    if (opts.userMarker || opts.droneMarker) {
                        let markerCallback = opts.droneMarker ? this.moveDroneMarkerCore(opts) : this.moveUserMarkerCore(opts);
                        markerCallback.then((res) => {
                            resContainer.markerPos = res;
                            resolve(true);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                    } else {
                        resolve(true);
                    }
                }));
                Promise.all(promises).then(() => {
                    this.setCameraMoveInProgress(false);
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    this.setCameraMoveInProgress(false);
                    reject(err);
                });
            }
        });
        return promise;
    }

    /**
     * reload user marker for e.g. label update
     * @param opts 
     */
    resetUserMarker() {
        if (!this.lastMoveOpts) {
            console.warn("marker opts not set");
            return;
        }

        if (this.resetMarkerInProgress) {
            // already pending reset
            return;
        }

        WaitUtils.waitFlagResolve(this.cameraMoveInProgress, this.observables.cameraMoveInProgress, [false], 5000).then((ok: boolean) => {
            if (ok) {
                this.resetUserMarkerCore();
            } else {
                // error
                this.resetMarkerInProgress = false;
            }
        });
    }

    resetUserMarkerCore() {
        if (!this.enabled) {
            return;
        }
        this.setCameraMoveInProgress(true);
        this.markerHandler.disposeLayerResolve(EMarkerLayers.USER).then(() => {
            this.moveUserMarkerCore(this.lastMoveOpts).then(() => {
                // timeout to prevent marker duplicate (extra fallback)
                setTimeout(() => {
                    this.setCameraMoveInProgress(false);
                    this.resetMarkerInProgress = false;
                }, 500);
            }).catch((err: Error) => {
                console.error(err);
                // timeout to prevent marker duplicate (extra fallback)
                setTimeout(() => {
                    this.setCameraMoveInProgress(false);
                    this.resetMarkerInProgress = false;
                }, 500);
            });
        });
    }

    /**
    * move subject (user/drone) marker and circle (if enabled)
    */
    private moveSubjectMarkerCore(opts: IMoveMapOptions, isDrone: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            this.moveSubjectMarkerCoreGen(opts,
                isDrone ? EMarkerLayers.DRONE : EMarkerLayers.USER,
                isDrone ? this.droneMarker : this.userMarker,
                isDrone ? EMarkerLayers.DRONE_CIRCLE : EMarkerLayers.USER_CIRCLE,
                isDrone ? this.exploreCircleDrone : this.exploreCircle,
                isDrone ? this.exploreCircleDronePrev : this.exploreCirclePrev,
                isDrone ? EMarkerPriority.drone : EMarkerPriority.user,
                isDrone ? EFeatureColor.droneCircleColor : EFeatureColor.userCircleColor,
                false
            ).then(() => {
                if (isDrone) {
                    this.exploreCircleDronePrev = this.exploreCircleDrone;
                } else {
                    this.exploreCirclePrev = this.exploreCircle;
                }
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * move subject (user/drone) marker and circle (if enabled)
     */
    private moveSubjectMarkerCoreGen(opts: IMoveMapOptions,
        markerLayer: string,
        marker: IPlaceMarkerContent,
        circleLayer: string,
        hasCircle: boolean,
        hasCirclePrev: boolean,
        circlePriority: number,
        circleColor: string,
        compassRotate: boolean
    ): Promise<boolean> {
        let promises = [];
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            // move user marker
            console.log("move user marker: ", opts.location);
            promises.push(new Promise((resolve, reject) => {
                opts.compassHeading = compassRotate;
                this.markerHandler.syncMarker(
                    markerLayer,
                    marker,
                    opts
                ).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            }));
            // move user circle (if enabled)
            promises.push(new Promise((resolve, reject) => {
                if (hasCircle) {
                    this.markerHandler.syncCircleMarker(
                        circleLayer,
                        opts.location as any,
                        {
                            zindex: circlePriority,
                            size: this.exploreRadiusCircleSize,
                            color: circleColor,
                            unlockLimiter: opts.unlockLimiter
                        }
                    ).then(() => {
                        resolve(true);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    if (hasCirclePrev) {
                        this.markerHandler.clearMarkersNoAction(circleLayer);
                        this.markerHandler.clearMarkerLayer(circleLayer);
                    }
                    resolve(true);
                }
            }));
            // move complete
            Promise.all(promises).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    updateUserHeading(heading: number) {
        this.initNavMoveOpts(this.lastMoveOpts, true);
        this.lastMoveNavOpts.location = this.userMarker.location;
        this.lastMoveNavOpts.bearing = heading;
        let dist: number = 50;
        let dx: number = Math.abs(GeometryUtils.translateTo180(heading));
        // let pback: number = 0.6;
        // dist = dist + dx * dist * (pback - 1) / (180 - 0);
        let anchorLocation: ILatLng = this.lastMoveOpts.location;
        this.lastMoveNavOpts.location = GeometryUtils.getPointOnHeading(anchorLocation, dist, heading);
        this.navMarker.location = this.lastMoveNavOpts.location as any;
        this.navMarker.compassRotate = heading;
        console.log("update user heading marker: " + heading + ", dist: " + dist + ", dx: " + dx);
        return this.moveSubjectMarkerCoreGen(this.lastMoveNavOpts,
            EMarkerLayers.USER_NAV,
            this.navMarker,
            null, false, false, null, null,
            true
        );
    }

    moveUserMarkerToCoords(coords: ILatLng) {
        let moveOpts: IMoveMapOptions = {
            animateCamera: true,
            animateMarker: false,
            zoom: 0,
            bearing: null,
            tilt: null,
            moveMap: true,
            force: true,
            userMarker: true,
            droneMarker: false,
            duration: null,
            location: coords
        };
        return this.moveUserMarkerCore(moveOpts);
    }

    /**
     * move user marker and circle (if enabled)
     */
    private moveUserMarkerCore(opts: IMoveMapOptions): Promise<boolean> {
        if (opts.location != null) {
            if (this.userMarker != null) {
                this.userMarker.location = opts.location;
            }
            if (this.lastMoveOpts != null) {
                this.lastMoveOpts.location = opts.location;
            }
        }
        return this.moveSubjectMarkerCore(opts, false);
    }

    /**
     * move drone marker and circle (if enabled)
     */
    private moveDroneMarkerCore(opts: IMoveMapOptions): Promise<boolean> {
        if (opts.location != null) {
            if (this.droneMarker != null) {
                this.droneMarker.location = opts.location;
            }
        }
        return this.moveSubjectMarkerCore(opts, true);
    }


    syncMarkerGenNoAction(layer: string, location: ILatLng, photo: string, callback: (data: IPlaceMarkerContent) => any, opts: IMoveMapOptions) {
        this.syncMarkerGen(layer, location, photo, callback, opts).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    syncMarkerGen(layer: string, location: ILatLng, photo: string, callback: (data: IPlaceMarkerContent) => any, opts: IMoveMapOptions) {
        return new Promise<boolean>((resolve, reject) => {
            if (!this.placeMarker) {
                reject(new Error("undefined place marker"));
                return;
            }
            let pm: IPlaceMarkerContent = DeepCopy.deepcopy(this.placeMarker);
            pm.icon = photo;
            pm.callback = callback;
            pm.location = location;
            this.markerHandler.syncMarker(layer, pm, opts).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
    }

    /**
     * remove marker, clear layer
     * @param layer 
     */
    removeMarkerGen(layer: string) {
        return new Promise((resolve) => {
            this.markerHandler.clearMarkersResolve(layer).then(() => {
                this.markerHandler.clearMarkerLayer(layer);
                resolve(true);
            });
        });
    }

    loadPlaceMarkerNoAction() {
        this.loadPlaceMarker().then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    loadPlaceMarker(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let pm: IPlaceMarkerContent = this.markerUtils.getUserPlaceMarker(EMarkerLayers.PLACE_FIXER, null, EMarkerIcons.location);
            pm.locationFixed = true;
            pm.icon = EMarkerIcons.location;
            this.setPlaceMarker(pm);
            resolve(true);
        });
        return promise;
    }

    setPlaceMarker(pm: IPlaceMarkerContent) {
        this.placeMarker = pm;
    }

    setPlaceMarkerCallback(callback: (data: IPlaceMarkerContent) => any) {
        this.placeMarker.callback = (data: IPlaceMarkerContent) => {
            callback(data);
        };
    }

    getCurrentMapLocation(): Promise<ILatLng> {
        return new Promise(async (resolve) => {
            try {
                let loc: ILatLng = null;
                if (this.useNativeApi) {
                    if (!this.map) {
                        console.warn("map not initialized");
                        resolve(null);
                        return;
                    }
                    let pos: CameraPosition = await this.map.getCameraPosition();
                    // console.log("get current pos data: ", pos);
                    loc = pos != null ? new ILatLng(pos.target.lat, pos.target.lng) : null;
                } else {
                    let pos = this.jsMap?.getCenter();
                    loc = pos != null ? new ILatLng(pos.lat(), pos.lng()) : null;
                }
                resolve(loc);
            } catch (err) {
                console.error(err);
                resolve(null);
            }
        });
    }

    getCurentUserMarkerLocation() {
        if (this.userMarker != null) {
            let loc: ILatLng = this.userMarker.location;
            return loc;
        }
        return null;
    }

    getCurrentDroneMarkerLocation() {
        if (this.droneMarker != null) {
            let loc: ILatLng = this.droneMarker.location;
            return loc;
        }
        return null;
    }
}
