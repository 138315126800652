import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PhotoBgDirective } from './directives/photo-bg.directive';
import { BlinkDirective } from './directives/blink.directive';
import { DynamicColDirective } from './directives/dynamic-col.directive';
import { DynamicKbFrameDirective } from './directives/dynamic-kb-frame.directive';
import { MyFocusDirective } from './directives/my-focus.directive';
import { NgInitDirective } from './directives/ng-init.directive';
import { SwipeVerticalDirective } from './directives/swipe-vertical.directive';
import { LinkIntereptorDirective } from './directives/link-interceptor';
import { InnerContentDirective } from './directives/inner-content';
import { LinkIntereptorListenerDirective } from './directives/link-interceptor-listener';
import { InteractiveImageWrapperDirective } from './directives/interactive-wrapper.directive';
import { SafeHtmlDirective } from './directives/safe-html.directive';
import { EnterKeyPressDirective } from './directives/enter-key-press.directive';

const components = [
  PhotoBgDirective,
  BlinkDirective,
  DynamicColDirective,
  DynamicKbFrameDirective,
  EnterKeyPressDirective,
  InnerContentDirective,
  LinkIntereptorDirective,
  LinkIntereptorListenerDirective,
  MyFocusDirective,
  NgInitDirective,
  SwipeVerticalDirective,
  InteractiveImageWrapperDirective,
  SafeHtmlDirective
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule
  ],
  exports: [...components]
})
export class DirectivesModule { }
