

import { ILatLng } from 'src/app/classes/def/map/coords';
import { IPlatformFlags } from "../../classes/def/app/platform";
import { IMarkerTheme } from "../../classes/def/app/theme";
import { IPathContent, IPlaceMarkerContent, IShowMarkerOptions } from "../../classes/def/map/map-data";
import { Injectable, NgZone } from "@angular/core";
import { SettingsManagerService } from "../general/settings-manager";
import { MarkersWeb } from "./markers-web";
import { MarkersMobile } from "./markers-mobile";
import { GenericQueueService } from "../general/generic-queue";
import { MarkerUtils } from "./marker-utils";
import { EOS } from "../../classes/def/app/app";
import { ISetThisMarkerOptions } from "../../classes/def/map/markers";
import { AnalyticsService } from "../general/apis/analytics";
import { ImageLoaderTSService } from "../media/image-loader-ts";
import { IMoveMapOptions } from 'src/app/classes/def/map/interaction';
import { SyncService } from '../app/utils/sync';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IGenericFlags } from 'src/app/classes/utils/general';
import { IObservableMultiplex } from 'src/app/classes/def/mp/subs';
import { BehaviorSubject } from 'rxjs';
import { WaitUtils } from '../utils/wait-utils';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { PlacesDataService } from '../data/places';
import { SleepUtils } from "../utils/sleep-utils";
import { MarkersMobileCapacitor } from "./markers-mobile-capacitor";


interface IMasterLockFlags extends IGenericFlags {
    updatePath: boolean
}

interface IMarkerHandlerObservableMultiplex extends IObservableMultiplex {
    updatePath: BehaviorSubject<boolean>
}

@Injectable({
    providedIn: 'root'
})
export class MarkerHandlerService {

    markersClassWeb: MarkersWeb;
    markersClassMobile: MarkersMobileCapacitor;
    // markerClassMobileCapacitor: MarkersMobileCapacitor;

    platform: IPlatformFlags = {} as IPlatformFlags;
    test: boolean = false;

    masterLock: IMasterLockFlags = {
        updatePath: false
    };

    masterLockObs: IMarkerHandlerObservableMultiplex = {
        updatePath: null
    };

    pathUpdateTs: number = null;
    useNativeApi: boolean = false;
    useCapacitor: boolean = true;

    constructor(
        public settings: SettingsManagerService,
        public q: GenericQueueService,
        public ngZone: NgZone,
        public imageLoaderTS: ImageLoaderTSService,
        public analytics: AnalyticsService,
        public syncService: SyncService,
        public placeData: PlacesDataService
    ) {
        console.log("marker handler service created");

        this.masterLockObs = ResourceManager.initBsubObj(this.masterLockObs);

        this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.useNativeApi = !this.platform.WEB;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    setNative(enabled: boolean) {
        this.useNativeApi = enabled;
        this.onPlatformLoaded();
    }

    /**
     * master lock
     * @param enable 
     */
    setEnabled(enable: boolean) {
        if (this.useNativeApi) {
            this.markersClassMobile.setEnabled(enable);
        } else {
            this.markersClassWeb.setEnabled(enable);
        }
    }

    handleMarkerCallbackExt(markerId: string){
        if (this.useNativeApi) {
            this.markersClassMobile.handleMarkerCallbackExt(markerId);
        }
    }

    onPlatformLoaded() {
        this.platform = SettingsManagerService.settings.platformFlags;
        console.log("MarkersModule platform: ", this.platform);
        if (this.useNativeApi) {
            this.markersClassMobile = new MarkersMobileCapacitor(this.imageLoaderTS, this.syncService);
        } else {
            this.markersClassWeb = new MarkersWeb(this.ngZone, this.placeData);
        }
    }

    setTheme(theme: IMarkerTheme) {
        if (this.useNativeApi) {
            this.markersClassMobile.setTheme(theme);
        } else {
            this.markersClassWeb.setTheme(theme);
        }
    }

    /**
     * clear all markers and associated resources
     */
    async clearAllResolve(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (this.useNativeApi) {
                this.markersClassMobile.clearAll(GeneralCache.os === EOS.ios).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                });
            } else {
                this.markersClassWeb.clearAll();
                resolve(true);
            }
        });
        return promise;
    }

    /**
     * clear all marker layers without actually removing the markers from the map
     * to be used with map.clearAll before map unload
     */
    clearAllNoRemove(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (this.useNativeApi) {
                this.markersClassMobile.clearAllNoRemove().then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                });
            } else {
                this.markersClassWeb.clearAllNoRemove();
                resolve(true);
            }
        });
        return promise;
    }

    clearAllNoAction() {
        this.clearAllResolve().then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    checkOverlaps(lat: number, lng: number, zoom: number, excludeLayers: string[]) {
        if (this.useNativeApi) {
            return MarkerUtils.checkOverlapsCore(lat, lng, zoom, this.markersClassMobile.getAllMarkersData(excludeLayers));
        } else {
            return MarkerUtils.checkOverlapsCore(lat, lng, zoom, this.markersClassWeb.getAllMarkersData(excludeLayers));
        }
    }

    setMap(map: any) {
        console.log("module setMap");
        if (this.useNativeApi) {
            this.markersClassMobile.setMap(map);
        } else {
            this.markersClassWeb.setMap(map);
        }
    }

    setOptions(opts: any) {
        if (this.useNativeApi) {
            this.markersClassMobile.setOptions(opts);
        } else {

        }
    }


    /**
     * add marker clusterer to all registered markers
     */
    async addMarkerClusterer() {
        let promise = new Promise((resolve, reject) => {
            if (this.useNativeApi) {
                this.markersClassMobile.addMarkerClusterer().then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                this.markersClassWeb.addMarkerClusterer();
                resolve(true);
            }
        });
        return promise;
    }


    /**
     * set this marker, change position if existing
     * @param layer 
     * @param data 
     */
    async syncMarker(layer: string, data: IPlaceMarkerContent, opts: IMoveMapOptions): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            // console.log("sync marker: ", layer, data.mode, data);
            if (this.useNativeApi) {
                this.markersClassMobile.syncMarker(layer, data, opts).then((result) => {
                    resolve(result);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                this.markersClassWeb.syncMarker(layer, data, opts).then((result) => {
                    resolve(result);
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }

    /**
     * set this marker of circle type, change position if existing
     * @param layer 
     * @param position 
     * @param options 
     */
    syncCircleMarker(layer: string, position: ILatLng, options: ISetThisMarkerOptions) {
        let promise = new Promise((resolve, reject) => {
            if (this.useNativeApi) {
                this.markersClassMobile.syncCircleMarker(layer, position, options).then((thisMarker) => {
                    resolve(thisMarker);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                this.markersClassWeb.syncCircleMarker(layer, position, options).then((thisMarker) => {
                    resolve(thisMarker);
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }


    /**
     * set entire layer visible/invisible
     * it can be any kind of marker, also circle
     * @param layer 
     * @param visible 
     */
    setVisibleLayer(layer: string, visible: boolean) {
        if (this.useNativeApi) {
            this.markersClassMobile.setVisibleLayer(layer, visible);
        } else {
            this.markersClassWeb.setVisibleLayer(layer, visible);
        }
    }

    getVisibleLayer(layer: string) {
        if (this.useNativeApi) {
            return this.markersClassMobile.getVisibleLayer(layer);
        } else {
            return this.markersClassWeb.getVisibleLayer(layer);
        }
    }


    /**
     * clear markers from marker array from google map
     * clear the marker array
     * auto browser/mobile
     * @param layer
     */
    async clearMarkersResolve(layer: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (this.useNativeApi) {
                this.markersClassMobile.clearMarkersResolve(layer).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                })
            } else {
                this.markersClassWeb.clearMarkers(layer);
                resolve(true);
            }
        });
        return promise;
    }

    clearMarkersNoAction(layer: string) {
        this.clearMarkersResolve(layer).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * to be used for marker layers that are updated in sequence
     * or with a long delay between insert operations
     * (so that the order is guaranteed)
     */
    clearLastArrayMarker(layer: string, clearData: boolean = false) {
        if (this.useNativeApi) {
            this.markersClassMobile.clearLastArrayMarker(layer, clearData);
        } else {
            this.markersClassWeb.clearLastArrayMarker(layer, clearData);
        }
    }

    /**
     * show/hide last marker from array
     * @param layer 
     * @param show 
     */
    toggleLastArrayMarkerShow(layer: string, show: boolean) {
        if (this.useNativeApi) {
            this.markersClassMobile.toggleLastArrayMarkerShow(layer, show);
        } else {
            this.markersClassWeb.toggleLastArrayMarkerShow(layer, show);
        }
    }


    /**
     * update selected marker from array
     * resolve only
     * @param layer 
     * @param data 
     */
    updateArrayMarkerCore(layer: string, data: IPlaceMarkerContent) {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let prom: Promise<any>;
            if (this.useNativeApi) {
                prom = this.markersClassMobile.updateArrayMarkerCore(layer, data);
            } else {
                prom = this.markersClassWeb.updateArrayMarkerCore(layer, data);
            }
            prom.then(() => {
                resolve(true);
            }).catch((err) => {
                console.error(err);
                resolve(false);
            });
        });
        return promise;
    }

    /**
    * show/hide marker from array by uid
    * @param layer 
    * @param show 
    */
    toggleStoryMarkerShow(layer: string, storyLocationId: number, show: boolean) {
        if (this.useNativeApi) {
            let mkd = this.markersClassMobile.getArrayMarkerDataByLayer(layer);
            let index: number = -1;
            for (let i = 0; i < mkd.length; i++) {
                let mk = mkd[i];
                if (mk.data.storyLocationId === storyLocationId) {
                    this.markersClassMobile.toggleLastArrayMarkerShow(layer, show);
                }
            }
            if (index !== -1) {
                this.markersClassMobile.toggleArrayMarkerShow(layer, index, show);
            }
        } else {
            let mkd = this.markersClassWeb.getArrayMarkerDataByLayer(layer);
            let index: number = -1;
            console.log("toggle story marker show");
            console.log(mkd);
            for (let i = 0; i < mkd.length; i++) {
                let mk = mkd[i];
                if (mk.data.storyLocationId === storyLocationId) {
                    this.markersClassWeb.toggleLastArrayMarkerShow(layer, show);
                }
            }
            console.log(index);
            if (index !== -1) {
                this.markersClassWeb.toggleArrayMarkerShow(layer, index, show);
            }
        }
    }


    /**
     * warning: this is not reliable to use directly
     * please clear marker by uid instead
     */
    clearArrayMarkerByIndex(layer: string, index: number) {
        if (this.useNativeApi) {
            this.markersClassMobile.clearArrayMarkerByIndex(layer, index);
        } else {
            this.markersClassWeb.clearArrayMarkerByIndex(layer, index);
        }
    }

    /**
     * completely remove the marker from the array
     * @param layer 
     * @param uid 
     */
    clearMarkerByUid(layer: string, uid: string) {
        if (this.useNativeApi) {
            this.markersClassMobile.clearArrayMarkerByUid(layer, uid);
        } else {
            this.markersClassWeb.clearArrayMarkerByUid(layer, uid);
        }
    }

    /**
     * get data layer content
     * @param layer 
     */
    getMarkerByUid(layer: string, uid: string) {
        if (this.useNativeApi) {
            return this.markersClassMobile.getArrayMarkerDataByUid(layer, uid);
        } else {
            return this.markersClassWeb.getArrayMarkerDataByUid(layer, uid);
        }
    }

    /**
     * get data layer content
     * @param layer 
     */
    getMarkerDataSingleLayer(layer: string) {
        if (this.useNativeApi) {
            return this.markersClassMobile.getSingleMarkerDataByLayer(layer);
        } else {
            return this.markersClassWeb.getSingleMarkerDataByLayer(layer);
        }
    }

    /**
     * get data layer content
     * @param layer 
     */
    getMarkerDataMultiLayer(layer: string) {
        if (this.useNativeApi) {
            return this.markersClassMobile.getArrayMarkerDataByLayer(layer);
        } else {
            return this.markersClassWeb.getArrayMarkerDataByLayer(layer);
        }
    }

    /**
     * clear the marker layer permanently
     * @param layer 
     */
    clearMarkerLayer(layer: string) {
        if (this.useNativeApi) {
            this.markersClassMobile.clearMarkerLayer(layer);
        } else {
            this.markersClassWeb.clearMarkerLayer(layer);
        }
    }

    /**
     * completely remove the marker layer
     * @param layer 
     */
    async disposeLayerResolve(layer: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (this.useNativeApi) {
                this.markersClassMobile.disposeLayerResolve(layer).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                });
            } else {
                try {
                    this.markersClassWeb.disposeLayer(layer);
                    resolve(true);
                }
                catch (e) {
                    console.error(e);
                    // reject(e);
                    resolve(false);
                }
            }
        });
        return promise;
    }

    /**
     * add marker for google map into the marker array
     * different for native and browser
     * the jobs are offloaded to the queue manager, 
     * so that markers are added in the same order that they were requested and there are no overlapping calls
     * @param data
     * @param type
     * @param icon
     */
    async insertArrayMarker(data: IPlaceMarkerContent, show: boolean) {
        let promise = new Promise((resolve, reject) => {
            let promiseInsert: any;
            if (this.useNativeApi) {
                promiseInsert = this.markersClassMobile.insertArrayMarker(data, show);
                promiseInsert.then((val: any) => {
                    resolve(val);
                }).catch((err: any) => {
                    reject(err);
                });
            } else {
                promiseInsert = this.markersClassWeb.insertArrayMarker(data, show);
                promiseInsert.then((val: any) => {
                    resolve(val);
                }).catch((err: any) => {
                    reject(err);
                });
            }
        });
        return promise;
    }


    /**
     * add multiple markers while not preserving the original order
     * async
     * @param data 
     * @param show 
     */
    async insertMultipleMarkers(data: IPlaceMarkerContent[], show: boolean): Promise<boolean> {
        // let index = 1;
        // let promises = [];
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            let sync: boolean = GeneralCache.os === EOS.ios;
            let promises = [];
            // sync = true;
            if (sync) {
                try {
                    // never use forEach when you want to wait for all to complete via await
                    for (let element of data) {
                        await this.insertArrayMarker(element, show);
                        console.log("insert 1");
                    }
                    console.log("insert all complete");
                    resolve(true);
                } catch (e) {
                    reject(e);
                }
            } else {
                for (let element of data) {
                    let promise = this.insertArrayMarker(element, show);
                    promises.push(promise);
                }
                Promise.all(promises).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }


    /**
     * sync external buffered data array with marker array using CRUD operations
     * dynamic update markers e.g. position
     * @param data 
     */
    async syncMarkerArray(layer: string, data: IPlaceMarkerContent[]): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (this.useNativeApi) {
                this.markersClassMobile.syncMarkerArray(layer, data, GeneralCache.os === EOS.ios).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                this.markersClassWeb.syncMarkerArray(layer, data).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }

    /**
    * sync external buffered data array with marker array using CRUD operations
    * dynamic update markers e.g. position
    * resolve only
    * @param data 
    */
    async syncMarkerArrayResolve(layer: string, data: IPlaceMarkerContent[]): Promise<boolean> {
        return new Promise((resolve) => {
            this.syncMarkerArray(layer, data).then(() => {
                resolve(true);
            }).catch((err) => {
                console.error(err);
                resolve(false);
            });
        });
    }

    /**
    * sync external buffered data array with marker array using CRUD operations
    * dynamic update markers e.g. position
    * no action
    * @param data 
    */
    syncMarkerArrayNoAction(layer: string, data: IPlaceMarkerContent[]) {
        this.syncMarkerArrayResolve(layer, data).then(() => {

        });
    }


    /**
     * show markers on the map from the marker array
     * @param layer
     */
    async showMarkerArray(layer: string, circularFrame: boolean): Promise<boolean> {
        console.log("show " + layer + " markers ");
        // marker size for canvas
        let opts: IShowMarkerOptions = {
            mh: 40,
            mw: 20,
            width: 120,
            type: layer,
            circularFrame
        };

        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (this.useNativeApi) {
                this.markersClassMobile.showMarkerArrayMobile(opts).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            } else {
                this.markersClassWeb.showMarkerArrayWeb(opts).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            }

            if (this.test) {
                reject();
            }
        });

        return promise;
    }

    async waitForUpdatePath() {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            let ok: boolean = await WaitUtils.waitFlagResolve(this.masterLock.updatePath, this.masterLockObs.updatePath, [false], 5000);
            if (!ok) {
                resolve(false);
                return;
            } else {
                resolve(true);
            }
        });
        return promise;
    }


    /**
     * update path (polyline)
     * defined by waypoints
     * @param waypoints 
     * @param layer 
     * @param show 
     */
    async updatePath(waypoints: ILatLng[], layer: string, pathData: IPathContent, show: boolean, rateLimiter: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            console.log("update path requested");
            if (rateLimiter) {
                let timeCrt: number = new Date().getTime();
                if ((this.pathUpdateTs == null) || ((timeCrt - this.pathUpdateTs) >= 500)) {
                    this.pathUpdateTs = timeCrt;
                    // continue
                } else {
                    // skip path update, wait for next call
                    resolve(false);
                    return;
                }
            }
            console.log("pending update path");
            await this.waitForUpdatePath();

            this.masterLock.updatePath = true;
            this.masterLockObs.updatePath.next(true);

            await this.disposeLayerResolve(layer);
            console.log("pending add path");
            if (this.useNativeApi) {
                this.markersClassMobile.addPathMobile(waypoints, layer, show).then(() => {
                    this.masterLock.updatePath = false;
                    this.masterLockObs.updatePath.next(false);
                    console.log("update path done");
                    resolve(true);
                }).catch((err: Error) => {
                    this.masterLock.updatePath = false;
                    this.masterLockObs.updatePath.next(false);
                    console.log("update path error");
                    console.error(err);
                    reject(err);
                });
            } else {
                await SleepUtils.sleep(10);
                this.markersClassWeb.addPathWeb(waypoints, layer, pathData, show);
                await SleepUtils.sleep(10);
                this.masterLock.updatePath = false;
                this.masterLockObs.updatePath.next(false);
                resolve(true);
            }
        });
        return promise;
    }

    updatePathNoAction(waypoints: ILatLng[], layer: string, pathData: IPathContent, show: boolean, rateLimiter: boolean) {
        this.updatePath(waypoints, layer, pathData, show, rateLimiter).then(() => {

        }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "markers");
        });
    }

    /**
     * create path (polyline)
     * defined by waypoints
     * @param waypoints 
     * @param layer 
     * @param show 
     */
    async addPath(waypoints: ILatLng[], layer: string, pathData: IPathContent, show: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (this.useNativeApi) {
                this.markersClassMobile.addPathMobile(waypoints, layer, show).then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                this.markersClassWeb.addPathWeb(waypoints, layer, pathData, show);
                resolve(true);
            }
        });
        return promise;
    }

    /**
     *  to be used for marker layers that are updated in sequence
     * or with a long delay between insert operations
     * (so that the order is guaranteed)
     * @param index 
     * @param layer 
     */
    getMarkerLocationByIndex(index: number, layer: string) {
        let pos;
        if (this.useNativeApi) {
            pos = this.markersClassMobile.getMarkerLocationByIndex(index, layer);
        } else {
            pos = this.markersClassWeb.getMarkerLocationByIndex(index, layer);
        }
        return pos;
    }

    /**
     * to be used for marker layers that are updated in sequence
     * or with a long delay between insert operations
     * (so that the order is guaranteed) 
     * @param index 
     * @param layer 
     */
    getMarkerByIndex(index: number, layer: string) {
        let marker: any;
        if (this.useNativeApi) {
            marker = this.markersClassMobile.getArrayMarkerByIndex(index, layer);
        } else {
            marker = this.markersClassWeb.getArrayMarkerByIndex(index, layer);
        }
        return marker;
    }

    /**
     * to be used for marker layers that are updated in sequence
     * or with a long delay between insert operations
     * (so that the order is guaranteed)
     * @param index 
     * @param layer 
     */
    getMarkerDataByIndex(index: number, layer: string) {
        let marker: any;
        if (this.useNativeApi) {
            marker = this.markersClassMobile.getArrayMarkerDataByIndex(index, layer);
        } else {
            marker = this.markersClassWeb.getArrayMarkerDataByIndex(index, layer);
        }
        return marker;
    }

    /**
     * should be called just after init map
     */
    setGlobalMarkerInitTimestamp() {
        if (this.useNativeApi) {
            this.markersClassMobile.setGlobalMarkerInitTimestamp();
        } else {
            this.markersClassWeb.setGlobalMarkerInitTimestamp();
        }
    }
}
