import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccountHomePage } from './account-home.page';
import { PagesModule } from '../../pages.module';

const routes: Routes = [
  {
    path: '',
    component: AccountHomePage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [AccountHomePage]
})
export class AccountHomePageModule { }
