<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="continue()" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div class="bg-container bg-container-fill bg-filter-2"
    [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: photoUrl}">

    <div class="content">
      <div class="content-height">
        <div fxLayout="column" class="content-height">

          <div class="scrollbar-y scroll-content-modal-1 padding-top-s center-all" fxLayout="column">
            <safe-html-renderer [unsafeHtmlContent]="infoHTML"
              innerClass="info-text-m description-frame text-color-primary"></safe-html-renderer>
          </div>

          <div fxFlex></div>
          <div class="stick-bottom">
            <ion-grid class="full-w">
              <ion-row>
                <ion-col size="6">
                  <!-- <ion-button class="action-button-fill button-color-alternate" (click)="retry()" [disabled]="!retryEnabled">
                    <icon-wrapper slot="icon-only" icon="flash"></icon-wrapper>
                  </ion-button> -->
                  <ion-button class="action-button-fill button-color-alternate" [disabled]="!retryEnabled"
                    (click)="retry()">
                    <icon-wrapper class="slot-icon-padding" slot="start" icon="flash"></icon-wrapper>
                    <span class="button-font-size-xs">{{retryMessage}}</span>
                  </ion-button>
                </ion-col>
                <ion-col size="6">
                  <!-- <ion-button class="action-button-fill button-color-accent" (click)="continue()">
                    <icon-wrapper slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
                  </ion-button> -->
                  <ion-button class="action-button-fill button-color-accent" (click)="continue()">
                    <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIcons.continue" [custom]="true">
                    </icon-wrapper>
                    <span class="button-font-size-xs">continue</span>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </div>
      </div>
    </div>
  </div>