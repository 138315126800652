import { Component, OnDestroy, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { ILeaderboardExtNavParams, ILeaderboardDetailNavParams } from 'src/app/classes/def/nav-params/leaderboard';
import { IPaginationContainer } from 'src/app/classes/def/views/pagination';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { Platform, ModalController } from '@ionic/angular';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IPageResponse, IGenericResponse } from 'src/app/classes/def/requests/general';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { EventsDataService } from 'src/app/services/data/events';
import { PaginationHandlerService } from 'src/app/services/general/ui/pagination-handler';
import { ERouteDef } from 'src/app/app-utils';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { EAppIconsStandard, EAppIcons } from 'src/app/classes/def/app/icons';
import { INavBarItem } from 'src/app/classes/def/views/nav';
import { LocationMonitorService } from 'src/app/services/map/location-monitor';
import { ELeaderboardExtMode } from 'src/app/classes/def/leaderboard/leaderboard';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { Router } from '@angular/router';
import { IGenericLeaderboardEntry } from 'src/app/classes/def/user/leaderboard';
import { IInventoryNavParams } from 'src/app/classes/def/nav-params/inventory';
import { InventoryHomePage } from '../inventory/inventory-home/inventory-home.page';
import { DateSelectorViewComponent, IDateSelectorParams, IDateSelectorReturn, EDateSelectorReturnCode } from 'src/app/modals/generic/modals/date-selector/date-selector.component';
import { TimeUtils } from 'src/app/classes/general/time';
import { PromiseUtils } from 'src/app/services/utils/promise-utils';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-leaderboard-ext',
  templateUrl: './leaderboard-ext.page.html',
  styleUrls: ['./leaderboard-ext.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LeaderboardExtPage implements OnInit, OnDestroy {
  title: string;
  board: IGenericLeaderboardEntry[];

  infoHTML: string = "";
  timeframeDisp: string = "ALL TIME";

  loaded: boolean = false;

  timeout = {
    autoReload: null
  };

  theme: string = "theme-light theme-light-bg";
  params: ILeaderboardExtNavParams;
  isModal: boolean = false;
  enableBackButton: boolean = false;

  pagination: IPaginationContainer = {
    page: 0,
    pages: 1,
    pageDisp: 1,
    hideLeft: true,
    hideRight: false
  };

  searchName: string = null;
  emptyResult: boolean = false;
  platform: IPlatformFlags = {} as IPlatformFlags;
  autoReload: boolean = false;

  np: INavParams = null;
  vs: IViewSpecs;

  categoryTabsGroupCount: number = 3;
  navBarItems: INavBarItem[] = [{
    name: "global",
    value: ELeaderboardExtMode.combined
  }, {
    name: "players",
    value: ELeaderboardExtMode.players
  }, {
    name: "teams",
    value: ELeaderboardExtMode.teams
  }];

  selectedTabId: number = ELeaderboardExtMode.combined;

  initLoaded: boolean = false;
  storyId: number = null;
  withTimeframe: boolean = false;
  startDate: string = null;
  endDate: string = null;

  xpScaleFactor: number = null;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  loadingTimeout: number = 60;
  resetLoadingTimeout: boolean = false;
  showProgressReload: boolean = true;

  enableAutoReload: boolean = true;

  constructor(
    public router: Router,
    public settingsProvider: SettingsManagerService,
    public zone: NgZone,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public backButton: BackButtonService,
    public analytics: AnalyticsService,
    public modalCtrl: ModalController,
    public multiplayerDataProvider: MPDataService,
    public eventDataProvider: EventsDataService,
    public paginationHandler: PaginationHandlerService,
    public nps: NavParamsService,
    public locationMonitor: LocationMonitorService,
    public locationManager: LocationManagerService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  openPaginateSelector() {
    this.paginationHandler.openPaginateSelector(() => {
      return this.loadDataByPageNoAction(this.pagination.page);
    });
  }

  switchPage(dir: number) {
    this.paginationHandler.setPaginationPage(dir);
    console.log("set pagination (" + dir + ") - " + this.pagination.page);
    this.loadDataByPageNoAction(this.pagination.page);
  }

  ngOnDestroy() {
    // console.log("will leave");
    this.clearWatch();
    console.log("destroy");
    this.backButton.checkPop(this.vs);
  }

  back() {
    if (this.isModal) {
      this.modalDismiss();
    } else {
      this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  modalDismiss() {
    setTimeout(() => {
      this.modalCtrl.dismiss().then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }


  ngOnInit(): void {
    this.title = "Leaderboard";
    this.analytics.trackView("leaderboard-ext");
    this.initLoaded = true;
    this.paginationHandler.init(this.pagination);

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.leaderboardExt, null, this.np);
      console.log("nav params: ", npInfo.params);
      let npar = npInfo.params;
      let hasParams: boolean = npInfo.hasParams;
      this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
        if (loaded) {
          this.platform = SettingsManagerService.settings.platformFlags;
          if (this.platform.WEB) {
            this.enableAutoReload = true;
            this.toggleAutoReload(true);
          } else {
            this.enableAutoReload = true;
            this.toggleAutoReload(true);
          }
        }
      }, (err: Error) => {
        console.error(err);
      });

      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      if (hasParams) {
        let np: INavParams = npar;
        this.params = np.params;
        console.log(this.params);
        this.vs = np.view;
        this.storyId = this.params.storyId;
        this.isModal = this.vs ? this.vs.isModal : false;
        this.enableBackButton = this.isModal;
        this.xpScaleFactor = this.params.xpScaleFactor;
      }

      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back();
        }, this.vs);
      }).catch((err: Error) => { console.error(err); });

      this.loadDataByPage(this.pagination.page).then(() => {
        this.loaded = true;
      }).catch((err: Error) => {
        this.loaded = true;
        this.analytics.dispatchError(err, "leaderboard-ext");
        this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
      });
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  loadDataByPageNoAction(page: number) {
    this.loadDataByPage(page).then(() => {

    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "story-list");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }

  loadDataByPage(page: number): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
      try {
        this.loaded = false;
        let response: IGenericResponse;
        if (!this.withTimeframe) {
          response = await this.multiplayerDataProvider.getLeaderboardExtByPage(page, this.storyId, this.selectedTabId);
        } else {
          response = await this.multiplayerDataProvider.getLeaderboardExtTimeframeByPage(page, this.storyId, this.selectedTabId, this.startDate, this.endDate);
        }

        this.dispTimeframe(this.withTimeframe);
        this.loadDataCore(response.data);
        this.loaded = true;
        resolve(true);
      } catch (err) {
        this.loaded = true;
        reject(err);
      }
    });
    return promise;
  }

  selectCategoryFromNavItem(navItem: INavBarItem) {
    this.pagination.page = 0;
    this.pagination.pageDisp = 1;
    if (navItem) {
      this.selectedTabId = navItem.value;
      this.toggleAutoReload(false);
      this.loadDataByPageNoAction(0);
      setTimeout(() => {
        this.toggleAutoReload(true);
      }, 1);
    }
  }


  loadDataCore(resp: IPageResponse) {
    if (!resp) {
      return;
    }

    // resp.data is sometimes retrieved from resp.users
    if (!resp.data) {
      resp.data = [];
    }

    let board: IGenericLeaderboardEntry[] = resp.data;
    let entriesFound: IGenericLeaderboardEntry[] = board.filter(entry => entry.found);

    if (entriesFound && entriesFound.length > 0) {
      board = entriesFound;
    }

    if (this.xpScaleFactor != null) {
      for (let leader of board) {
        leader.score = Math.floor(leader.score * this.xpScaleFactor);
      }
    }

    this.board = board;
    this.emptyResult = this.board.length === 0;

    this.pagination.pages = resp.pages;
    this.pagination.page = resp.page;
    this.paginationHandler.setPaginationControls();
    this.loaded = true;
  }


  swipeEvent(e) {
    console.log("swipe event: ", e);
    if (!this.platform.IOS) {
      if (e) {
        switch (e.direction) {
          case 2:
            // right to left
            if (!this.pagination.hideRight) {
              this.switchPage(1);
            }
            break;
          case 4:
            // left to right
            if (!this.pagination.hideLeft) {
              this.switchPage(-1);
            }
            break;
        }
      }
    }
  }

  clearWatch() {
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
  }


  openEntry(entry: IGenericLeaderboardEntry): void {
    console.log(entry);

    let leaderId: number = 0;
    let leaderName: string;

    if (entry.group) {
      leaderId = entry.group.id;
      leaderName = entry.group.name;
    }
    if (entry.user) {
      leaderId = entry.user.id;
      leaderName = entry.user.name;
    }

    let params1: ILeaderboardDetailNavParams = {
      leaderId: leaderId,
      leaderName: leaderName,
      isGroup: entry.group != null,
      storyId: this.storyId,
      xpScaleFactor: this.params != null ? this.params.xpScaleFactor : null,
      startDate: this.startDate,
      endDate: this.endDate,
      details: null
    };

    let params: IInventoryNavParams = {
      leaderboardDetail: params1,
      closeOnItemUse: false,
      fixedCategory: false
    };

    this.uiext.showCustomModal(null, InventoryHomePage, {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: true
      },
      params: params
    }).then(() => {
      // reset pagination handler
      // this.paginationHandler.init(this.pagination);
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "leaderboard-ext");
      // reset pagination handler
      // this.paginationHandler.init(this.pagination);
    });
  }


  options() {
    let actions: IPopoverActions = {};
    actions = {
      refresh: {
        name: "Reload",
        code: 0,
        icon: EAppIconsStandard.refresh,
        enabled: true
      },
      autoRefresh: {
        name: this.autoReload ? "Stop reload" : "Auto reload*",
        code: 2,
        icon: EAppIconsStandard.sync,
        enabled: true
      }
      // dateSelector: {
      //   name: "Select interval",
      //   code: 3,
      //   icon: EAppIconsStandard.calendar,
      //   enabled: true
      // },
      // removeInterval: {
      //   name: "Remove interval",
      //   code: 4,
      //   icon: EAppIconsStandard.calendar,
      //   enabled: true
      // }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 0:
          this.reloadOnTap();
          break;
        case 2:
          if (this.autoReload) {
            this.toggleAutoReload(false);
          } else {
            this.toggleAutoReload(true);
          }
          break;
        case 3:
          this.openDateSelector();
          break;
        case 4:
          this.checkLoadTimeframe(false, null, null);
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  async reloadOnTap() {
    try {
      this.toggleAutoReload(false);
      this.pagination.page = 0;
      await PromiseUtils.wrapResolve(this.loadDataByPage(this.pagination.page), true);
      this.toggleAutoReload(true);
    } catch (err) {
      console.error(err);
      this.toggleAutoReload(true);
    }
  }

  /**
   * set or toggle auto reload
   * @param enable 
   */
  toggleAutoReload(enable: boolean) {
    if (enable && !this.enableAutoReload) {
      console.warn("auto reload not enabled");
      return;
    }
    console.log("toggle auto reload: ", enable);
    this.resetLoadingTimeout = !this.resetLoadingTimeout;
    if (enable && !this.autoReload) {
      this.autoReload = true;
      this.timeout.autoReload = ResourceManager.clearTimeout(this.timeout.autoReload);
      this.doAutoReload();
    }
    if (!enable && this.autoReload) {
      this.autoReload = false;
      this.timeout.autoReload = ResourceManager.clearTimeout(this.timeout.autoReload);
    }
  }

  /**
   * auto reload every 10 minutes for showcase (e.g. event stand)
   */
  doAutoReload() {
    this.timeout.autoReload = setTimeout(() => {
      this.loadDataByPage(this.pagination.page).then(() => {
        console.log("auto reload complete");
        this.resetLoadingTimeout = !this.resetLoadingTimeout;
        this.doAutoReload();
      }).catch((err: Error) => {
        console.error(err);
        this.doAutoReload();
      });
    }, this.loadingTimeout * 1000);
  }

  openDateSelector() {
    let params: IDateSelectorParams = {
      title: "Timeframe",
      entries: [
        {
          heading: "Start date",
          code: 1,
          value: this.startDate
        },
        {
          heading: "End date",
          code: 2,
          value: this.endDate
        }
      ],
      withClear: true
    };
    this.uiext.showCustomModal(null, DateSelectorViewComponent, {
      view: {
        fullScreen: false,
        transparent: false,
        large: false,
        addToStack: true,
        frame: false,
        backdropDismiss: false
      },
      params: params
    }).then((res: IDateSelectorReturn) => {
      if (res != null) {
        switch (res.code) {
          case EDateSelectorReturnCode.set:
            this.checkLoadTimeframe(true, res.entries[0].value, res.entries[1].value);
            break;
          case EDateSelectorReturnCode.clear:
            this.checkLoadTimeframe(false, null, null);
            break;
        }
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  checkLoadTimeframe(enable: boolean, startDate: string, endDate: string) {
    this.withTimeframe = enable;
    this.startDate = startDate;
    this.endDate = endDate;
    this.pagination.page = 0;
    this.pagination.pageDisp = 1;
    this.loadDataByPageNoAction(0);
  }

  dispTimeframe(enable: boolean) {
    if (enable) {
      this.timeframeDisp = TimeUtils.formatDateTimeDisp(this.startDate, false, false) + " - " + TimeUtils.formatDateTimeDisp(this.endDate, false, false);
    } else {
      this.timeframeDisp = "ALL TIME";
    }
  }
}
