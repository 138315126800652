
import { ILatLng } from "../map/coords";
import { IPlaceExtContainer } from "./container";

export interface IPlaceAux {
    mapsURL: string;
    photoUrl: string;
    photoUrlCached: string;
    canvasURL: string;
    canvasLoaded: boolean;
}

export interface IAppPlaceResult {
    googlePlaceSelected: ILeplaceReg;
    googlePlaceArray?: ILeplaceReg[];
    type: number;
    fallback: boolean;
    skip: boolean;
    customCoords?: ILatLng;
    samePlace?: boolean;
}

export interface ILeplaceRegMulti {
    selected: ILeplaceReg;
    array: ILeplaceReg[];
}

export interface ILeplaceReg {
    place: IPlaceExtContainer;
    registered?: boolean;
    // set if the place is registered to enable the business features
    registeredBusiness: boolean;
    // registered in the db => id
    registeredId: number;
    aux?: IPlaceAux;
    /** server set flag, hide treasure label for registered place */
    hideLabel?: boolean;
}

export enum ELocationTypeScan {
    restaurant = "restaurant",
    park = "park",
    store = "store"
}
