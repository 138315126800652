import { Pipe, PipeTransform } from '@angular/core';
import { TimeUtils } from 'src/app/classes/general/time';

@Pipe({
  name: 'myDate2'
})
export class MyDate2Pipe implements PipeTransform {
  /**
  * Takes a date value and returns a pretty string from current time, 
  * for instance: "four hours ago" or "in eleven minutes".
  */
  transform(value: string, ..._args) {
    return TimeUtils.formatDateDispV2(value);
  }
}
