
export interface INotificationContent {
    type: number;
    data: any;
}

export interface INotificationDataChat {
    groupId: number;
    groupName: string;
    playerName: string;
}

export enum ENotificationType {
    chat = 1
}
