import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppConstants } from 'src/app/classes/app/constants';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { IPopoverInputs, IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ParamHandler } from 'src/app/classes/general/params';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { ICustomCodeInput } from 'src/app/services/general/apis/qr';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';


export interface IUnlockContentParams {
  description: string;
  descriptionFooter?: string;
  title: string;
  customCodeInput: ICustomCodeInput;
  photoUrl: string;
}

export interface IUnlockContentReturnData {
  actionCode: number;
  unlockCode: string;
  isUnlockCode: boolean;
  isCustomUnlockCode?: boolean;
}


@Component({
  selector: 'modal-unlock-content',
  templateUrl: './unlock-content.component.html',
  styleUrls: ['./unlock-content.component.scss'],
})
export class UnlockContentViewComponent implements OnInit, OnDestroy {
  description: string = "";
  descriptionFooter: string = "";
  title: string = "";
  selectAll: boolean = false;

  wrapperClass: string = "";
  innerClass: string = "";
  buttonBar: string = "";

  loading: boolean = false;

  photoUrl: string = null;
  vs: IViewSpecs = ViewSpecs.getDefault();

  params: IUnlockContentParams = null;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  np: INavParams = null;

  continueBtnText: string = "Scan QR";
  unlockCode: string = "";
  isUnlockCode: boolean = false;
  unlockCodeShortLength: number = 5;
  proceedHighlight: boolean = false;
  inputFocused: boolean = false;
  customCodeInput: string = null;
  customCodeInputTitle: string = null;
  customCodeInputLabel: string = null;
  customCodeInputNotes: string = null;
  withCustomCodeInput: boolean = false;

  constructor(
    public uiext: UiExtensionService,
    public modalCtrl: ModalController,
    public resources: ResourcesCoreDataService,
    public analytics: AnalyticsService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IUnlockContentParams = np.params;
      this.params = params;
      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);

      this.unlockCodeShortLength = AppConstants.gameConfig.unlockCodeLength;

      if (params.description) {
        this.description = params.description;
      }

      if (params.descriptionFooter) {
        this.descriptionFooter = params.descriptionFooter;
      }

      this.title = params.title;
      this.photoUrl = params.photoUrl;

      if (params.customCodeInput != null) {
        this.customCodeInput = params.customCodeInput.label;
        this.customCodeInputTitle = params.customCodeInput.title;
        this.customCodeInputLabel = params.customCodeInput.inputLabel;
        this.customCodeInputNotes = params.customCodeInput.notes;
        this.withCustomCodeInput = true;
      }
    }

    this.getClass();
  }

  ngOnDestroy() {

  }

  textAreaFocused(focused: boolean) {
    console.log("focus: ", focused);
    // this.inputFocused = focused;
  }

  updateUnlockCode(event: any) {
    console.log("update unlock code: ", event);
    console.log("prop: ", this.unlockCode);
    // this.unlockCode = event;
    this.proceedHighlight = event != null;
    this.checkCode();
  }

  checkCode() {
    setTimeout(() => {
      if ((this.unlockCode != null) && (this.unlockCode.length > 0)) {
        this.continueBtnText = "Continue";
        this.isUnlockCode = true;
      } else {
        this.continueBtnText = "Scan QR";
        this.isUnlockCode = false;
      }
    }, 1);
  }

  getClass() {
    this.wrapperClass = "";
    this.innerClass = "padding-s scrollbar-y scroll-content-modal-1-border-flush";
    this.buttonBar = "theme-border-top";
  }

  dismiss(params: IUnlockContentReturnData) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  ok(event: Event) {
    if (event != null) {
      console.log("event prevent default");
      event.preventDefault();
    }
    let params: IUnlockContentReturnData = {
      actionCode: EAlertButtonCodes.ok,
      unlockCode: this.unlockCode,
      isUnlockCode: this.isUnlockCode
    }
    this.dismiss(params);
  }

  okScan(event: Event) {
    if (event != null) {
      console.log("event prevent default");
      event.preventDefault();
    }
    let params: IUnlockContentReturnData = {
      actionCode: EAlertButtonCodes.ok,
      unlockCode: null,
      isUnlockCode: false
    }
    this.dismiss(params);
  }

  okEnter(event: Event) {
    if (event != null) {
      console.log("event prevent default");
      event.preventDefault();
    }
    let params: IUnlockContentReturnData = {
      actionCode: EAlertButtonCodes.ok,
      unlockCode: this.unlockCode,
      isUnlockCode: true
    }
    this.dismiss(params);
  }

  cancel() {
    this.dismiss(null);
  }

  enterCustomCode() {
    let searchName: string = "";
    let popoverInputs: IPopoverInputs = {
      token: {
        // the name is the actual tag here
        name: "token",
        value: searchName,
        placeholder: this.customCodeInputLabel,
        enabled: true
      }
    };
    let popoverActions: IPopoverActions = {
      cancel: {
        name: "Cancel",
        code: 0,
        enabled: true
      },
      ok: {
        name: "Ok",
        code: 1,
        enabled: true
      }
    };

    let title: string = this.customCodeInputTitle;

    this.uiext.showCustomAlertInput(title, null, popoverInputs, popoverActions).then((res: any) => {
      let code = res.code;
      let data = res.data;
      // console.log(data);
      switch (code) {
        case 0:
          break;
        case 1:
          searchName = data.token;
          let params: IUnlockContentReturnData = {
            actionCode: EAlertButtonCodes.ok,
            unlockCode: searchName,
            isUnlockCode: true,
            isCustomUnlockCode: true
          }
          this.dismiss(params);
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

}
