<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [loading]="loading" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div [class]="wrapperClass" fxLayout="column">

    <div [class]="innerClass" fxLayout="column">
      <safe-html-renderer [unsafeHtmlContent]="description"
        innerClass="info-text-m description-frame text-color-primary scroll-into-view-container"></safe-html-renderer>

      <div [ngClass]="inputFocused ? 'scroll-padding' : ''">
        <ion-row>
          <ion-col size="12">
            <!-- <ion-label position="stacked" class="text-color info-text-m">Unlock code</ion-label>
                <ion-input [(ngModel)]="unlockCode" ngDefaultControl icon="name" type="text"
                  class="text-color info-text-m" placeholder="Enter unlock code" (input)="checkCode()">
                </ion-input> -->
            <div class="border-frame">
              <edit-code [(prop)]="unlockCode" placeholder="" [numeric]="false" [dim]="unlockCodeShortLength"
                [autocaps]="true" (inputComplete)="updateUnlockCode($event)" (focusChange)="textAreaFocused($event)"
                enterKeyPress (enterKeyPress)="ok($event)">
                <inner-content>Enter unlock code</inner-content>
              </edit-code>
            </div>
          </ion-col>
          <!-- <ion-col size="2" class="center-text self-center">
            <ion-button [disabled]="!isUnlockCode" class="button-color-transparent text-color-primary" (click)="ok()">
              <icon-wrapper slot="icon-only" [icon]="appIconsStandard.check"></icon-wrapper>
            </ion-button>
          </ion-col> -->
        </ion-row>


        <div *ngIf="withCustomCodeInput" tappable class="text-center padding-s" (click)="enterCustomCode()">
          <span class="as-text element-selector button-font-size-m text-color-primary">{{customCodeInput}}</span>
        </div>

        <safe-html-renderer *ngIf="withCustomCodeInput" [unsafeHtmlContent]="customCodeInputNotes"
          innerClass="info-text-s description-frame text-color-primary"></safe-html-renderer>

        <!-- <div *ngIf="inputFocused" class="padding-keyboard"></div> -->
      </div>

      <div *ngIf="photoUrl" class="padding-s">
        <responsive-img [photoUrl]="photoUrl" class="border-radius"></responsive-img>
      </div>

      <safe-html-renderer *ngIf="descriptionFooter" [unsafeHtmlContent]="descriptionFooter"
        innerClass="info-text-m description-frame text-color-primary"></safe-html-renderer>

    </div>

    <div fxFlex>
    </div>

    <div class="stick-bottom" [ngClass]="buttonBar">
      <ion-grid class="full-w">
        <ion-row>
          <!-- <ion-col size="12">
            <ion-button class="action-button-fill"
              [ngClass]="proceedHighlight ? 'button-color-accent' : 'button-color-alternate'" (click)="ok($event)">
              <span class="button-font-size-m">
                {{continueBtnText}}
              </span>
            </ion-button>
          </ion-col> -->

          <ion-col size="6">
            <ion-button class="action-button-fill button-color-alternate" (click)="okScan($event)">
              <span class="button-font-size-m">
                Scan
              </span>
              <icon-wrapper slot="start" [icon]="appIcons.qr" [custom]="true" class="slot-icon-padding">
              </icon-wrapper>
            </ion-button>
          </ion-col>

          <ion-col size="6">
            <ion-button class="action-button-fill"
              [ngClass]="proceedHighlight ? 'button-color-accent' : 'button-color-alternate'" (click)="okEnter($event)">
              <span class="button-font-size-m">
                Enter
              </span>
              <icon-wrapper slot="start" [icon]="appIconsStandard.unlocked" [custom]="false" class="slot-icon-padding">
              </icon-wrapper>
            </ion-button>
          </ion-col>

        </ion-row>
      </ion-grid>
    </div>
  </div>
</modal-container>