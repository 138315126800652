import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiDef } from '../../classes/app/api';
import { IRegisterBusinessRequest } from '../../classes/def/requests/register-business';
import { IUpdateBusinessRequest } from '../../classes/def/requests/update-business';
import { IGenericResponse } from '../../classes/def/requests/general';
import { IBusiness } from '../../classes/def/business/business';
import { GenericDataService } from '../general/data/generic';


@Injectable({
    providedIn: 'root'
})
export class BusinessDataService {
    serverUrl: string;

    constructor(
        public http: HttpClient,
        public generic: GenericDataService
    ) {
        console.log("business data service created");
        this.serverUrl = ApiDef.mainServerURL;
    }

    init() {

    }


    getAllPlaces(mode: number) {
        let promise = new Promise((resolve, reject) => {
            this.generic.genericGetStandard("/business/account/get-all-places", {
                mode: mode
            }).then((response: IGenericResponse) => {
                if (response.data.places) {
                    resolve(response.data.places as IBusiness[]);
                } else {
                    reject(new Error("no data"));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    getPlaceOverview(locationId: number, mode: number) {
        return this.generic.genericGetStandard("/business/account/get-place-overview", {
            locationId: locationId,
            mode: mode
        });
    }

    registerBusiness(data: IRegisterBusinessRequest) {
        return this.generic.genericPostStandard("/business/locations/register", data);
    }

    updateBusinessDetails(data: IUpdateBusinessRequest) {
        return this.generic.genericPostStandard("/business/locations/update", data);
    }


    checkRegistered(gid: string) {
        return this.generic.genericPostStandard("/business/locations/check-registered", {
            googleId: gid
        });
    }

    checkRegisteredMultiple(gids: string[]) {
        return this.generic.genericPostStandard("/business/locations/check-registered-multi", {
            googleId: gids
        });
    }



    requestToken(placeId: number, mode: number) {
        return this.generic.genericPostStandard("/business/locations/get-token", {
            placeId: placeId,
            mode: mode
        });
    }

    registerEmployee(placeId: number, token: string) {
        return this.generic.genericPostStandard("/business/locations/register-employee", {
            placeId: placeId,
            token: token
        });
    }

    registerClient(placeId: number, token: string) {
        return this.generic.genericPostStandard("/business/locations/register-client", {
            placeId: placeId,
            token: token
        });
    }


    subscribeToPrivateStoryProvider(token: string) {
        return this.generic.genericPostStandard("/business/providers/subscribe-to-private-story-provider", {
            token: token
        });
    }


    unsubscribeFromPrivateStoryProvider(providerId: number) {
        return this.generic.genericPostStandard("/business/locations/remove-from-private-story-provider", {
            providerId: providerId
        });
    }

    /**
     * TODO: implement
     * @param _storyId 
     */
    removePrivateStory(_storyId: number) {
        // return this.generic.genericPostStandard("/user-place/remove-private-story", {
        //     storyId: storyId
        // });
        return Promise.resolve(true);
    }

    unsubscribe(placeId: number) {
        return this.generic.genericPostStandard("/business/locations/remove", {
            placeId: placeId
        });
    }


    removeEmployee(placeId: number) {
        return this.generic.genericPostStandard("/business/locations/remove-employee", {
            placeId: placeId
        });
    }

}
