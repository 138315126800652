<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title" (rightClick)="dismiss()"
    [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div fxLayout="column">
    <div class="scrollbar-y scroll-content-modal-1">
      <div class="padding-s">
        <div [innerHTML]="infoHTML" class="info-text-m description-frame text-color-primary center-text"></div>
      </div>

      <div *ngFor="let d of disp">
        <div>
          <div class="padding-s">
            <span class="info-text-m bold text-color-primary">{{d.caption}}</span>
          </div>
          <div class="padding-s progress-bar-alternate">
            <mat-progress-bar *ngIf="d.determinate" mode="determinate" class="progress-rounded" color="primary"
              [value]="d.progress"></mat-progress-bar>
            <progress-loading [small]="true" class="progress-rounded" *ngIf="!d.determinate"></progress-loading>
          </div>
        </div>
      </div>
    </div>

    <div fxFlex></div>
    <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-col size="12">
          <ion-button class="action-button-fill button-color-accent" (click)="dismiss()">
            <span class="button-font-size-m">{{btnContinue}}</span>
          </ion-button>
        </ion-col>
      </ion-grid>
    </div>
  </div>
</modal-container>