import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ModalController } from '@ionic/angular';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AdsService } from 'src/app/services/general/apis/ads';
import { ParamHandler } from 'src/app/classes/general/params';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { EAchievementUnlockedParams } from 'src/app/classes/def/nav-params/achievement';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { NavParamsService } from 'src/app/services/app/nav-params';
import { ICustomParamForActivity } from 'src/app/classes/def/core/custom-param';
import { IActivity } from 'src/app/classes/def/core/activity';
import { INavigationStats } from 'src/app/classes/def/map/navigation';
import { IKVStat } from 'src/app/classes/def/app/kval';
import { EStatCodes } from 'src/app/classes/def/user/stats';


export interface IItemsCollectedParams {
  title: string;
  description: string;
  activity: IActivity,
  activityParams: ICustomParamForActivity[];
  coinsCollected: number;
  coinsCollectedValue: number;
  stats: INavigationStats;
  autoClose: boolean;
}

@Component({
  selector: 'modal-items-collected',
  templateUrl: './items-collected.component.html',
  styleUrls: ['./items-collected.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ItemsCollectedViewComponent implements OnInit, OnDestroy {
  theme: string = "theme-aubergine theme-aubergine-bg";
  title: string = "Items Collected";
  infoHTML: string = "";
  shareMessage: string = "";
  /**
   * the achievements that will be shown in the grid
   */
  activityParams: ICustomParamForActivity[] = [];
  shareEnabled: boolean = true;
  watchEnabled: boolean = true;
  photoUrlLoaded: string = null;
  photoUrl: string = null;
  loading: boolean = false;
  reward: boolean = false;
  closeTimeout;
  closeTimerValue: number = 0;
  platform: IPlatformFlags = {} as IPlatformFlags;
  platformObs;
  params: IItemsCollectedParams;
  vs: IViewSpecs = ViewSpecs.getDefault();

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  footerMessage: string = "*already unlocked";
  showFooter: boolean = false;

  stats: INavigationStats = null;
  kvStats: IKVStat[] = [];

  np: INavParams = null;
  autoClose: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public nps: NavParamsService,
    public settingsProvider: SettingsManagerService,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public adService: AdsService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    console.log(this.np);
    if (hasParams) {
      let np: INavParams = Object.assign({}, this.np as INavParams);
      this.params = np.params;
      let cparams: ICustomParamForActivity[] = this.params.activityParams;

      if (np.view) {
        this.vs = np.view;
      }

      let cparamsDict: { [key: string]: ICustomParamForActivity } = {};

      // get distinct items, compute amounts
      if (cparams && cparams.length > 0) {
        let found: boolean = false;
        for (let gi of cparams) {
          if (!found && gi.photoUrl) {
            found = true;
          }
          let gid: ICustomParamForActivity = cparamsDict["" + gi.code];
          if (gid != null) {
            gid.amount += 1;
          } else {
            if ((gi.amount != null) && (gi.amount != 0)) {
              // use server returned validCount
            } else {
              gi.amount = 1;
            }
            cparamsDict["" + gi.code] = gi;
          }
        }
      }

      for (let key in cparamsDict) {
        this.activityParams.push(cparamsDict[key]);
      }

      if (this.params.title != null) {
        this.title = this.params.title;
      }
      if (this.params.activity) {
        this.photoUrl = this.params.activity.photoUrl;
      }

      this.autoClose = this.params.autoClose;
      this.stats = this.params.stats;
      this.formatStatsTable();
      this.infoHTML = this.params.description;
    }

    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    this.platformObs = this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
      if (loaded) {
        this.onPlatformLoaded();
      }
    }, (err: Error) => {
      console.error(err);
    });

    if (this.autoClose) {
      this.closeTimerValue = 10;
      this.closeTimerLoop();
    }
  }

  formatStatsTable() {
    if (!this.stats) {
      return;
    }
    let distanceStat = this.stats.statsList.find(sd => sd.code === EStatCodes.challengeCachedDistance);
    this.kvStats = [
      {
        name: "Distance walked",
        value: this.stats.distanceTravelled,
        valueString: (distanceStat != null) ? distanceStat.valueString : null
      },
      {
        name: "Items collected",
        value: this.stats.collectedCoins
      },
      {
        name: "LP",
        value: this.stats.collectedValue,
      },
      {
        name: "XP" + (((this.stats.xpScaleFactor != null) && (this.stats.xpScaleFactor !== 1)) ? " (scaled)" : ""),
        value: this.stats.gainedXp
      }
    ];
  }

  onPlatformLoaded() {
    this.platform = SettingsManagerService.settings.platformFlags;
  }

  /**
   * close the popup after 10 seconds of inactivity
   */
  closeTimerLoop() {
    this.closeTimeout = setTimeout(() => {
      if (this.closeTimerValue > 0) {
        this.closeTimerValue -= 1;
        this.closeTimerLoop();
      } else {
        // this.dismiss(null);
        this.continue();
      }
    }, 1000);
  }

  stopCloseTimeout() {
    if (this.closeTimeout) {
      clearTimeout(this.closeTimeout);
      this.closeTimeout = null;
      this.closeTimerValue = 0;
    }
  }

  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  /**
   * dismiss modal
   */
  continue() {
    this.stopCloseTimeout();
    this.dismiss(EAchievementUnlockedParams.default);
  }

  /**
   * Share progress then dismiss
   */
  shareAndContinue() {
    this.stopCloseTimeout();
    this.loading = true;
    this.shareProvider.share(this.shareMessage).then(() => {
      this.loading = false;
    }).catch(() => {
      this.loading = false;
    });
  }

  ngOnDestroy() {
    console.log("close modal");
    this.stopCloseTimeout();
    if (this.platformObs) {
      this.platformObs.unsubscribe();
      this.platformObs = null;
    }
  }

  clearWatch() {

  }
}
