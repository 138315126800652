
import { Injectable } from "@angular/core";
import { ResourceManager } from "../../../../classes/general/resource-manager";
import { TimeoutService } from "../timeout";
import { BehaviorSubject } from "rxjs";
import { ECheckActivityResult, IQuestActivityDef } from "../../../../classes/def/core/activity";
import { ITimeoutMonitorParams, ITimeoutMonitorData, ETimeoutStatus } from 'src/app/classes/general/timeout';
import { IQuestActivityStatus, IQuestAction, IQuestOptions, EQuestDynamicType } from 'src/app/classes/def/activity/quest';
import { IActivityQuestSpecs, IActivityQuestResponse } from 'src/app/classes/def/nav-params/activity-details';
import { MiscDataService } from 'src/app/services/data/misc';
import { QuestUtils } from './quest-utils';
import { LocationApiService } from 'src/app/services/location/location-api';

import { LocationApiHelpersService } from 'src/app/services/location/location-api-helpers';
import { ActivitiesDataService } from 'src/app/services/data/activities';
import { UiExtensionService } from "src/app/services/general/ui/ui-extension";
import { ILatLng } from "src/app/classes/def/map/coords";


@Injectable({
    providedIn: 'root'
})
export class QuestActivityService {

    subscription = {
        timeoutActivityMonitor: null
    };

    params: IQuestActivityDef;
    moduleName: string = "QUEST ACTIVITY > ";
    statusObservable: BehaviorSubject<IQuestActivityStatus>;

    questData: IActivityQuestSpecs;
    questStatus: IQuestActivityStatus;

    options: IQuestOptions = {
        /** percent */
        cluePenalty: 25,
        /** percent */
        mapCluePenalty: 50
    };

    activityFinished: boolean = false;

    constructor(
        public timeoutMonitor: TimeoutService,
        public misc: MiscDataService,
        public locationApi: LocationApiService,
        public locationApiHelpers: LocationApiHelpersService,
        public activityData: ActivitiesDataService,
        public uiext: UiExtensionService
    ) {
        console.log("quest activity service created");
        this.statusObservable = new BehaviorSubject(null);
    }

    watchStatus() {
        return this.statusObservable;
    }

    requestClue() {
        if (this.questData.clue) {
            return this.questData.clue;
        }
    }

    /**
     * get nearby street name
     * @param checkpoint 
     */
    requestGeocodeStreetNameOptions(checkpoint: ILatLng): Promise<string[]> {
        return new Promise((resolve, reject) => {
            if (checkpoint == null) {
                reject(new Error("undefined geocode coords"));
                return;
            }
            this.locationApi.reverseGeocode(checkpoint).then((res: google.maps.GeocoderResult[]) => {
                this.locationApiHelpers.getStreetNames(res).then((streetNames: string[]) => {
                    resolve(streetNames);
                }).catch((err) => {
                    reject(err);
                });
            }).catch((err) => {
                reject(err);
            });
        });
    }


    /**
     * send quest action
     * validate, check penalty
     * @param action 
     */
    checkAction(action: number, apply: boolean): IQuestAction {
        return QuestUtils.checkAction(this.questData, this.questStatus, this.options, action, apply);
    }

    /**
     * apply retry penalty based on activity params and specs
     */
    applyRetryPenalty() {
        QuestUtils.applyRetryPenalty(this.questData, this.questStatus, this.params);
    }

    /**
    * get retry penalty based on activity params and specs
    */
    getRetryPenalty() {
        return QuestUtils.getRetryPenalty(this.questData, this.questStatus, this.params);
    }

    checkInitialized() {
        return this.questStatus && this.questStatus.internal;
    }

    /**
     * submit response
     * update response / confirm response
     * resolve only
     * @param data 
     * @param resp 
     * @param confirm 
     */
    submitResponse(resp: IActivityQuestResponse, coords: ILatLng, test: boolean, questData: IActivityQuestSpecs): Promise<boolean> {
        return new Promise((resolve) => {
            if (!this.params && !test) {
                resolve(false);
                return;
            }

            if (test) {
                this.questData = questData;
            }

            let checkDynamicProm: Promise<boolean>;

            if (this.questData.dynamic) {
                switch (this.questData.type) {
                    case EQuestDynamicType.streetName:
                        checkDynamicProm = new Promise((resolve) => {
                            this.requestGeocodeStreetNameOptions(coords).then((streetNames: string[]) => {
                                this.questData.opt = streetNames;
                                resolve(true);
                            }).catch((err) => {
                                console.error(err);
                                // use default keyword as a fallback
                                resolve(null);
                            });
                        });
                        break;
                    default:
                        checkDynamicProm = Promise.resolve(null);
                        break;
                }
            } else {
                checkDynamicProm = Promise.resolve(null);
            }

            checkDynamicProm.then(() => {
                // check validate
                this.activityData.validateQuestData(this.questData, resp).then(async (match: boolean) => {
                    if (this.questStatus != null) {
                        this.questStatus.internal.questValidated = match;
                    }
                    resolve(match);
                }).catch((err) => {
                    console.error(err);
                    if (this.questStatus != null) {
                        this.questStatus.internal.questValidated = false;
                    }
                    resolve(false);
                });
            }).catch((err) => {
                console.error(err);
                if (this.questStatus != null) {
                    this.questStatus.internal.questValidated = false;
                }
                resolve(false);
            });
        });
    }

    async showValidateFeedback(match: boolean) {
        let retryPenalty: number = 0;
        let feedback: string = QuestUtils.getFeedback(this.questData, match, false);
        if (match) {
            await this.uiext.showAlert("Got it!", feedback, 1, null, true);
        } else {
            retryPenalty = this.getRetryPenalty();
        }

        if (!match) {
            if (retryPenalty < 100) {
                await this.uiext.showAlert("Oops, that's not quite right.", "<p>Don't worry, you'll get it next time! Keep trying!</p>", 1, null, true);
            } else {
                await this.uiext.showAlert("Oops, that's not quite right.", "<p>Looks like you've given it your best shot! Don't worry, you can always come back later to try again.</p>" + feedback, 1, null, true);
                match = true; // register activity complete after too many attempts
            }
            this.applyRetryPenalty();
        }
    }

    async showValidateFeedbackBasic(questData: IActivityQuestSpecs, match: boolean, showCorrectAnswer: boolean) {
        let feedback: string = QuestUtils.getFeedback(questData, match, showCorrectAnswer);
        if (match) {
            // await this.uiext.showRewardPopupQueue("Got it!", feedback, null, false, 3000);
            await this.uiext.showAlert("Got it!", feedback, 1, null, true);
        }
        if (!match) {
            await this.uiext.showAlert("Oops, that's not quite right.", feedback, 1, null, true);
        }
    }

    validate() {
        if (!this.checkInitialized()) {
            return;
        }
        this.questStatus.status = ECheckActivityResult.validated;
        this.statusObservable.next(this.questStatus);
    }

    confirmValidate() {
        if (!this.checkInitialized()) {
            return;
        }
        this.questStatus.internal.questValidatedConfirmed = true;
    }

    triggerFailed() {
        if (!this.checkInitialized()) {
            return;
        }
        this.questStatus.status = ECheckActivityResult.failed;
        this.statusObservable.next(this.questStatus);
    }

    checkConfirmValidateComplete() {
        if (!this.checkInitialized()) {
            return;
        }
        if (this.questStatus.internal.questValidated && this.questStatus.internal.questValidatedConfirmed) {
            // validate complete pending
            this.questStatus.status = ECheckActivityResult.done;
            this.statusObservable.next(this.questStatus);
        }
    }

    /**
     * handle timeout activity
     */
    initActivity(params: IQuestActivityDef, questData: IActivityQuestSpecs) {
        console.log(this.moduleName + "init");
        if (!this.subscription.timeoutActivityMonitor) {
            console.log(this.moduleName + "init started");
            this.params = params;
            this.statusObservable.next(null);

            let tmParams: ITimeoutMonitorParams = {
                timeLimit: params.timeLimit
            };

            this.timeoutMonitor.start(tmParams);
            this.activityFinished = false;

            this.questStatus = {
                tmData: null,
                status: ECheckActivityResult.inProgress,
                penalty: 0,
                usedClue: false,
                usedMapClue: false,
                internal: {
                    questValidated: false,
                    questValidatedConfirmed: false
                }
            };

            this.questData = questData;

            let setFailed = () => {
                this.questStatus.status = ECheckActivityResult.failed;
                this.statusObservable.next(this.questStatus);
                this.activityFinished = true;
            };

            this.subscription.timeoutActivityMonitor = this.timeoutMonitor.getWatch().subscribe((tmData: ITimeoutMonitorData) => {
                if (tmData) {

                    if (!tmData.isFallbackTimer) {
                        this.questStatus.tmData = tmData;
                    }

                    if (this.questStatus.internal.questValidated && this.questStatus.internal.questValidatedConfirmed) {
                        // quest validated, and picked up by the view processor
                        this.activityFinished = true;
                    }

                    switch (tmData.status) {
                        case ETimeoutStatus.expired:
                            setFailed();
                            break;
                    }

                    if (this.questStatus.penalty >= 100) {
                        setFailed();
                    }

                    if (this.activityFinished) {
                        this.subscription.timeoutActivityMonitor = ResourceManager.clearSub(this.subscription.timeoutActivityMonitor);
                        console.log("stopping timer");
                        this.timeoutMonitor.stop();
                    } else {
                        this.statusObservable.next(this.questStatus);
                    }
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    exitActivity() {
        console.log(this.moduleName + "exit");
        this.subscription = ResourceManager.clearSubObj(this.subscription);
        console.log("stopping timer");
        this.timeoutMonitor.stop();
        this.activityFinished = false;
    }
}
