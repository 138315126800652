import { Injectable } from "@angular/core";
import { GenericDataService } from "../general/data/generic";
import { IFeatureDef } from "../../classes/def/app/app";
import { IGenericResponse } from "../../classes/def/requests/general";
import { GeneralCache } from 'src/app/classes/app/general-cache';


@Injectable({
    providedIn: 'root'
})
export class PremiumDataService {

    constructor(
        public generic: GenericDataService
    ) {
        console.log("premium data service created");
    }

    /**
     * check price (LP) for a given feature (e.g. private story, create arena)
     * @param featureCode 
     * @param data 
     */
    requestPremiumFeature(featureCode: number, data: any) {
        return this.generic.genericPostStandard("/premium/unlock-feature", {
            featureCode: featureCode,
            data: data
        });
    }


    /**
     * check price (LP) for a given feature (e.g. private story, create arena)
     * use caching from previous check prices (list) request
     * no reject
     * @param featureCode 
     * @param data 
     */
    checkFeaturePrice(featureCode: number, data: any, reload: boolean): Promise<IFeatureDef> {
        let promise: Promise<IFeatureDef> = new Promise((resolve, reject) => {
            if (GeneralCache.resourceCache.general.featureList.loaded && !reload) {
                let price: IFeatureDef = GeneralCache.resourceCache.general.featureList.content.find(e => e.code === featureCode);
                if (price) {
                    resolve(price);
                    return;
                }
            }
            this.generic.genericPostStandard("/premium/check-price", {
                featureCode: featureCode,
                data: data
            }).then((response: IGenericResponse) => {
                resolve(response.data);
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;

    }

    /**
     * check and sync feature price/def list to be shown in the app
     * use caching
     */
    getFeatureDef(reload: boolean): Promise<IFeatureDef[]> {
        let promise: Promise<IFeatureDef[]> = new Promise((resolve, reject) => {
            if (!GeneralCache.resourceCache.general.featureList.loaded && !reload) {
                resolve(GeneralCache.resourceCache.general.featureList.content);
                return;
            }
            this.generic.genericPostStandard("/premium/check-price-list", {

            }).then((response: IGenericResponse) => {
                let feats: IFeatureDef[] = response.data;
                GeneralCache.resourceCache.general.featureList.content = feats;
                GeneralCache.resourceCache.general.featureList.loaded = true;
                resolve(feats);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }
}
