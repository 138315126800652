import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { IKVStat } from 'src/app/classes/def/app/kval';
import { IWeatherData } from 'src/app/classes/def/world/weather';

@Component({
  selector: 'weather-info',
  templateUrl: './weather-info.component.html',
  styleUrls: ['./weather-info.component.scss'],
})
export class WeatherInfoComponent implements OnInit, OnChanges {

  @Input()
  wd: IWeatherData;

  @Input()
  celsius: boolean;

  @Input()
  refresh: boolean;

  @Output() switchUnits = new EventEmitter<boolean>();

  weatherScore: number = 0;
  tempHigh: string = "";
  tempLow: string = "";
  cloudsAvg: string = "";
  units: string = "°C";

  weatherStatsTable: IKVStat[] = null;
  showData: boolean = false;

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'refresh':
            this.initFn();
            break;
        }
      }
    }
  }

  ngOnInit() {
    this.initFn();
  }

  initFn() {
    console.log("weather data init: ", this.wd);
    // if (this.wd.forecast) {
    //   let clouds: number[] = this.wd.forecast.map(f => f.clouds);
    //   let temps: number[] = this.wd.forecast.map(f => f.temp);
    // }
    if (!this.wd) {
      return;
    }
    this.setUnits();
    if (this.wd.stats) {
      this.weatherStatsTable = [];
      this.weatherStatsTable.push({
        name: "High",
        value: 0,
        valueString: "" + Math.floor(this.wd.stats.tempHigh) + "°"
      });
      this.weatherStatsTable.push({
        name: "Low",
        value: 0,
        valueString: "" + Math.floor(this.wd.stats.tempLow) + "°"
      });
      this.weatherStatsTable.push({
        name: "Clouds",
        value: 0,
        valueString: "" + this.wd.stats.avgClouds + "%"
      });
      this.weatherStatsTable.push({
        name: "Score",
        value: 0,
        valueString: this.wd.stats.avgScore + "%"
      });
    }
    this.showData = true;
  }

  switchUnitsAction() {
    this.celsius = !this.celsius;
    this.setUnits();
    this.switchUnits.emit(this.celsius);
  }

  setUnits() {
    this.units = this.celsius ? "°C" : "°F";
  }

}
