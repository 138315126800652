
import { ILocationContainer } from "../places/backend-location";
import { ISponsor } from '../business/sponsor';

export interface IGameItemCategoryWithItems {
    code: number;
    id: number;
    name: string;
    items: IGameItem[];
    icon: string;
    separateRequest: number;
    // allow showing details when locked
    peek: number;
}

export interface IGameItemCategory {
    code: number;
    id: number;
    name: string;
    icon: string;
}

export interface ICheckUnlockNewAchievements {
    achievements: any;
    new: number;
}

export interface IGameItemOptions {
    item: IGameItem;
    basicView: boolean;
}

export interface IGameItem {
    id: number;
    code: number;
    name: string;
    /** unique identifier, e.g. custom map */
    tag: string;
    description: string;
    shortDescription: string;
    buyAmount: number;
    itemIap: IGameItemIAP;
    count: number;
    status: number;
    itemCategoryCode: number;
    /**
     * used for check achievements unlocked, to display the category names as message
     */
    itemCategory: IGameItemCategory;
    /**
     * flag if item photo is available
     */
    photo: number;
    /**
     * requested separately from the local storage, downloading from cloud storage
     */
    photoUrl?: string;
    wikiUrl: string;
    /**
     * item version, this way the item photo can be updated
     */
    version: number;
    icon: string;
    consumable: number;
    priceCoins: number;
    resellValuePercent: number;
    timestamp: string;
    timeLeft: number;
    timeLeftPercent: number;
    timeLeftString: string;
    timeExpireDef: number;
    /**
     * item level, for info purpose
     */
    level: number;
    /**
     * item value, the actual value
     * coin magnet: percent increase in radius
     * coin double: percent increase in number of coins
     * etc
     */
    value: number;
    /**
     * check if the item can be activated or it's just enabled by default e.g. scan energy
     */
    requiresEnable: number;
    /**
     * check if the item can be reactivated e.g. maps
     */
    reactivateEnable: number;
    /**
     * the base item (e.g. scan energy x25 base item is scan energy)
     */
    delegateCode: number;
    /**
     * specifies whether the item has to (not) be enabled by the user
     */
    appHandled: number;
    /**
     * achievements cannot be bought, they are unlocked by playing the game
     */
    achievement: number;
    /**
     * the number of items for which the iap has failed
     */
    pendingCount: number;
    /**
     * the number of items that are activated in the user inventory
     */
    activeCount: number;
    /**
     * the number of items that are available in the user inventory
     */
    validCount: number;
    /**
     * used for items that are provided by a particular location e.g. sales
     */
    locationItems?: ILocationGameItem[];
    /**
     * if true, the item should be enabled only after scanning the QR code
     */
    enableByQr: boolean;

    /**
     * display progress towards unlocking the item (e.g. medal/achievement)
     */
    progress: {
        current: number,
        required: number,
        percent: number
    };

    /**
     * highlight new items (new content)
     */
    new: number;
    /**
     * highlight newly unlocked medal
     */
    newUnlocked: number;
    /**
     * items that were just now unlocked
     */
    newUnlockedAux: number;

    alreadyUnlocked?: boolean;

    noBlink: boolean;

    itemSponsors?: IItemSponsor[];
    // dynamic: IGameItemDynamic;

    // show description even when locked
    peek: number;

    // loading in progress (e.g. iap data)
    loading?: boolean;
}

export interface IGameItemDynamic {
    noBlink: boolean;
}

export interface IItemSponsor {
    id: number;
    sponsorId: number;
    itemCode: number;
    timestamp: string;
    sponsor: ISponsor;
}

/**
 * used for items that are provided by a particular location e.g. sales
 */
export interface ILocationGameItem {
    id: number;
    itemCode: number;
    description: string;
    locationId: number;
    location: ILocationContainer;
}

export interface IGameItemIAP {
    id: number;
    code: number;
    playStoreCode: string;
    appStoreCode: string;
    defaultPrice?: string;
    price: string;
    priceIos: string;
    type: number;
    typeIos: number;
    // check if IAP data is loaded (e.g. exact price)
    iapLoaded?: boolean;
    // check if the product is ready for purchase
    iapReady?: boolean;
    // hidden price
    hiddenPrice?: boolean;
    valid?: boolean;
}

export enum EIAPType {
    other = 0,
    consumable = 1,
    nonConsumable = 2,
    subscription = 3
}

export enum EInventoryCategoryCode {
    items = 2,
    maps = 3,
    medals = 4,
    powers = 5,
    sales = 6
}

export enum EItemCodes {
    coinMagnet = 201,
    scanEnergy = 101,
    eyeInTheSky = 202,
    coinMultiplier = 203,
    greenTech = 204,
    turboTech = 205,

    removeAds = 410
}

export enum EItemIapCodes {
    adminRequest = -1
}

export enum EItemFoundParams {
    default = 0,
    reward = 1
}

export enum EItemStatus {
    pending = 0,
    valid = 1,
    active = 2
}

export enum EItemActions {
    use = 1,
    buy = 2,
    retry = 3,
    cancel = 4,
    drop = 5
}

/**
 * item category codes for special categories
 */
export enum EItemCategoryCodes {
    sales = 2,
    maps = 3,
    medals = 4,
    powers = 5,
    ads = 6,
    items = 7
}

export enum EItemIAPStatus {
    request = 0,
    buy = 1,
    consume = 2
}


export class GameItemDef {
    /**
     * icon names for most important items,
     * others are retrieved from the db
     */
    public static itemDisp: IItemDisp = {
        coinMagnet: {
            code: EItemCodes.coinMagnet,
            icon: "magnet"
        },
        scanEnergy: {
            code: EItemCodes.scanEnergy,
            icon: "flash"
        },
        eyeInTheSky: {
            code: EItemCodes.eyeInTheSky,
            icon: "eye"
        },
        coinMultiplier: {
            code: EItemCodes.coinMultiplier,
            icon: "archive"
        }
    };
}

export interface IItemDisp {
    [key: string]: {
        code: number,
        icon?: string,
        photo?: string
    };
}

