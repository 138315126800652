


import { Injectable } from "@angular/core";
import { ISampleBufferContainer, ISampleBuffer, ICircularBuffer, IScopeBuffer } from 'src/app/classes/utils/buffer';


@Injectable({
    providedIn: 'root'
})
export class BufferUtilsService {

    buffer: ISampleBufferContainer<any> = {};
    
    constructor() {
        console.log("buffer utils service created");
    }


    /**
    * sync elements from array2 to array1 by key
    */
    static crudSync(array1: any[], array2: any[], key: string) {
        // insert/update
        // check elements that are added in array2
        // update common elements (intersection)

        let counter = {
            update: 0,
            create: 0,
            remove: 0
        };

        for (let i = 0; i < array2.length; i++) {
            let a2: any = array2[i];
            let exists: boolean = false;

            // check existing
            for (let j = 0; j < array1.length; j++) {
                let a1 = array1[j];
                if (a1[key] === a2[key]) {
                    // update existing
                    a1 = a2;
                    exists = true;
                    break;
                }
            }

            // create
            if (!exists) {
                array1.push(a2);
                counter.create += 1;
            } else {
                // update existing
                counter.update += 1;
            }
        }

        // remove
        // check elements that are removed in array2 i.e. exist in array1 but not in array2
        let trimArray1: any[] = [];

        for (let i = 0; i < array1.length; i++) {
            let a1: any = array1[i];
            let exists: boolean = false;
            // add only the elements that exist in array2 as well
            for (let j = 0; j < array2.length; j++) {
                let a2: any = array2[j];
                if (a1[key] === a2[key]) {
                    trimArray1.push(a1);
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                counter.remove += 1;
            }
        }

        console.log("crud sync create: " + counter.create + ", update: " +
            counter.update + ", remove: " + counter.remove + ", prev: " + array1.length + ", new: " + array2.length + ", crt: ", trimArray1.length);
        return trimArray1;
    }

    initBuffer(tag: string, container: ISampleBuffer) {
        this.buffer[tag] = container;
    }

    disposeBuffer(tag: string) {
        this.buffer[tag] = null;
    }

    /**
     * fill the buffer then use circular append
     * @param tag 
     * @param value 
     */
    circularAdd(tag: string, value: any): ICircularBuffer {
        let buffer: ICircularBuffer = this.buffer[tag];
        if (!buffer) {
            return null;
        }

        if (buffer.fillIndex > buffer.size) {
            // buffer.array.splice(0, 1);
            // buffer.array.push(sb);
            buffer.circularIndex += 1;
            if (buffer.circularIndex >= buffer.size) {
                buffer.circularIndex = 0;
            }
            buffer.array[buffer.circularIndex] = value;
        } else {
            buffer.array.push(value);
            buffer.fillIndex += 1;
        }
        return buffer;
    }

    /**
     * get the content of the circular buffer in order, as it were a normal buffer
     * @param tag 
     */
    circularGetInOrder(tag: string): any {
        let buffer: ICircularBuffer = this.buffer[tag];
        if (!buffer) {
            return null;
        }
        let buffer2: any[] = [];
        for (let j = buffer.circularIndex + 1; j < buffer.size + buffer.circularIndex + 1; j++) {
            let j2 = j;
            if (j >= buffer.size) {
                j2 = j % buffer.size;
            }
            buffer2.push(buffer.array[j2]);
        }
        return buffer2;
    }

    /**
     * fill the buffer then use scope append (add to last index, remove from first index)
     * @param tag 
     * @param value 
     */
    scopeAdd(tag: string, value: any): IScopeBuffer {
        let buffer: IScopeBuffer = this.buffer[tag];
        if (!buffer) {
            return null;
        }
        if (buffer.fillIndex > buffer.size) {
            for (let i = 1; i < buffer.array.length; i++) {
                buffer.array[i - 1] = buffer.array[i];
            }
            buffer.array[buffer.array.length - 1] = value;
        } else {
            buffer.array.push(value);
            buffer.fillIndex += 1;
        }
        return buffer;
    }

    /**
     * get the current buffer
     * @param tag 
     * @returns 
     */
    scopeGet(tag: string): IScopeBuffer {
        let buffer: IScopeBuffer = this.buffer[tag];
        if (!buffer) {
            return null;
        }
        return buffer;
    }

}


