
import { IMPStatusDB } from "./status";
import { IUserPublicData } from "../user/general";
import { IPageResponse } from '../requests/general';


export interface IGroupsPageResponse extends IPageResponse {
    data: IGroup[];
}

export enum EGroupType {
    mp = 1,
    event = 2,
    public = 0,
    lobby = 3
}

export interface IGroup {
    id: number;
    name: string;
    timestamp: string;
    expirationTimeLeft?: number;
    expirationTimeLeftPercent?: number;
    photoUrl: string;
    storyId?: number;
    meetingPlaceId?: number;
    members?: IGroupMember[];
    /**
     * the actual group id that will be seen by the users
     * auto generated based on the group name
     */
    alias: string;
    role: number;
    roleName?: string;
    type?: number;
    quickJoinCode?: string;

    found?: boolean;
    // online?: boolean;

    dynamic?: IGroupDynamic<boolean>;
    dynamicPrev?: IGroupDynamic<boolean>;
    dynamicDiff?: IGroupDynamic<boolean>;

    // debounce transition so that it evens out unwanted transitions (maybe the opposite transition is not detected and the state hangs)
    dynamicDiffCounter?: IGroupDynamic<number>;
    // value pre-debounce
    dynamicPrevDebounced?: IGroupDynamic<boolean>;
    dynamicDiffDebounced?: IGroupDynamic<boolean>;
}

export interface IGroupRole {
    id: number;
    code: number;
    categoryCode: number;
    name: string;
    description: string;
    photoUrl: string;
    // check if the role is available for the group
    available: boolean;
    // check if the group role is assigned to current user
    self: boolean;
}


export interface IGroupMember {
    id: number;
    userId: number;
    groupId: number;
    type: number;
    timestamp: string;
    user: IUserPublicData;
    /**
     * dynamic data
     */
    dynamic?: IGroupMemberDynamic;
    staticFlags?: IGroupMemberStaticFlags;

    dynamicPrev?: IGroupMemberDynamic;
    dynamicDiff?: IGroupMemberDynamic;

    cache?: {
        dynamic?: IGroupMemberDynamic;
        dynamicPrev?: IGroupMemberDynamic;
        dynamicDiff?: IGroupMemberDynamic;
    };

    isDynamicDefault?: boolean;
}

export interface IEventGroupMember extends IGroupMember {
    groupRoleCode: number;
    groupRole: IGroupRole;
}

export interface IGroupChatMember {
    id: number;
    name: string;
    connected: boolean;
}

export interface IGroupMemberStaticFlags {
    enabled: boolean;
    self: boolean;
}



export interface IGroupMemberDynamic {
    flags: {
        /**
        * the member has sent the ready signal
        */
        ready: boolean,
        /**
         * the member has loaded the challenge
         */
        challengeLoaded: boolean,

        challengeStarted: boolean,
        /**
         * the member has finished/failed/stopped the challenge
         */
        exitChallenge: boolean,
        // the member has read the description and all is set for starting the activity
        syncStart: boolean
    };
    /**
     * dynamic data available
     */
    connected: boolean;

    /**
     * dynamic data
     */
    status: IMPStatusDB;
}

/**
 * should only contain flags (boolean)
 */
export interface IGroupDynamic<T> {
    complete: T;
    initialized: T;
    allMembersReady: T;

    allMembersLoadedChallenge: T;
    allMembersStartedChallenge: T;
    allMembersSyncStart: T;

    /**
     * finished/failed/stopped (any exit code)
     */
    allMembersExitChallenge: T;
    leaderOk: T;
    leaderReady: T;

    refreshToggle: T;
}


export enum EGroupModalResult {
    cancel = 100,
    ok = 101,
    remove = 102,
    goToOptions = 103
}

export enum EGroupRole {
    discover = 0,
    member = 1,
    leader = 2,
    lobby = 3
}

export enum EGroupRoleName {
    member = "member",
    leader = "host",
    lobby = "explorer"
}

export enum EGroupMode {
    private = 1,
    public = 2
}

export enum EGroupContext {
    global = 0,
    story = 1,
    meetingPlace = 2
}

export interface IGroupStatus {
    /**
     * all group members are connected
     */
    complete: boolean;
}

export enum EArenaEvent {
    requireUserAction = 100,
    connectionProblem = 101,
    chatWindowClosed = 102
}

export enum EArenaParams {
    maxCounterTimeoutDebounce = 3
}


export class GroupDynamic {
    static getMemberDefault() {
        let md: IGroupMemberDynamic = {
            connected: false,
            status: null,
            flags: {
                ready: false,
                challengeLoaded: false,
                challengeStarted: false,
                exitChallenge: false,
                syncStart: false
            }
        };
        return md;
    }
    static getGroupDefault() {
        let gd: IGroupDynamic<boolean> = {
            complete: false,
            allMembersReady: false,
            leaderOk: false,
            leaderReady: false,
            initialized: false,
            allMembersLoadedChallenge: false,
            allMembersStartedChallenge: false,
            allMembersSyncStart: false,
            allMembersExitChallenge: false,
            refreshToggle: false
        };
        return gd;
    }
    static getGroupCounterDefault() {
        let n: number = EArenaParams.maxCounterTimeoutDebounce;
        let gd: IGroupDynamic<number> = {
            complete: n,
            allMembersReady: n,
            leaderOk: n,
            leaderReady: n,
            initialized: n,
            allMembersLoadedChallenge: n,
            allMembersStartedChallenge: n,
            allMembersSyncStart: n,
            allMembersExitChallenge: n,
            refreshToggle: n
        };
        return gd;
    }
}



