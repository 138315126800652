import { Injectable } from "@angular/core";
import { GenericDataService } from "../general/data/generic";
import { IPageResponse, IGenericResponse } from "../../classes/def/requests/general";
import { IEventGroup, IEventDetailsResponse, IEventGroupDetailsResponse, IEventCheckStartAvailableResponse, IEventItem } from "../../classes/def/events/events";
import { ILatLng } from 'src/app/classes/def/map/coords';
import { AnalyticsService } from "../general/apis/analytics";
import { IActivityParam, IGenericActivityStats } from "../../classes/def/core/activity-stats";
import { IGroupRole } from 'src/app/classes/def/mp/groups';
import { IEventTreasureScanResponse } from 'src/app/classes/def/items/treasures';


@Injectable({
    providedIn: 'root'
})
export class EventsDataService {

    roles: IGroupRole[] = null;

    constructor(
        public generic: GenericDataService,
        public analytics: AnalyticsService
    ) {
        console.log("events data service created");
    }


    nearbyScan(coords: ILatLng, page: number): Promise<IPageResponse> {
        return this.generic.genericPostStandardWData("/events/main/nearby-scan", {
            lat: coords ? coords.lat : null,
            lng: coords ? coords.lng : null,
            page: page
        });
    }

    // searchByName(coords: ILatLng, name: string): Promise<IPageResponse> {
    //     return this.generic.genericPostStandardWData("/events/search", {
    //         lat: coords.lat,
    //         lng: coords.lng,
    //         name: name
    //     });
    // }

    checkStartAvailable(eventId: number, groupId: number): Promise<IEventCheckStartAvailableResponse> {
        return this.generic.genericPostStandardWData("/events/v1/check-start-available", {
            eventId: eventId,
            groupId: groupId
        });
    }

    getDetails(eventId: number): Promise<IEventDetailsResponse> {
        return this.generic.genericPostStandardWData("/events/v1/details", {
            eventId: eventId
        });
    }

    getDetailsV2(eventId: number): Promise<IEventDetailsResponse> {
        return this.generic.genericPostStandardWData("/events/v2/user/details", {
            eventId: eventId
        });
    }

    getInventoryV2(eventId: number): Promise<IEventItem[]> {
        return this.generic.genericPostStandardWData("/events/v2/user/get-inventory", {
            eventId: eventId
        });
    }



    treasureScan(eventId: number, eventGroupRole: number, currentLocation: ILatLng, radius: number): Promise<IEventTreasureScanResponse> {
        return this.generic.genericPostStandardWData("/events/v2/map/treasure-scan", {
            eventId: eventId,
            eventGroupRole: eventGroupRole,
            lat: currentLocation.lat,
            lng: currentLocation.lng,
            radius: radius
        });
    }

    viewGroups(eventId: number): Promise<IEventGroup[]> {
        return this.generic.genericPostStandardWData("/events/v2/user/view-groups-user-data", {
            eventId: eventId
        });
    }

    getLeaderboardByPage(eventId: number, leaderboardId: number, page: number): Promise<IPageResponse> {
        return this.generic.genericPostStandardWData("/events/v1/leaderboard", {
            eventId: eventId,
            leaderboardId: leaderboardId,
            page: page
        });
    }

    joinEvent(eventId: number): Promise<boolean> {
        return this.generic.genericPostStandardWData("/events/v1/join", {
            eventId: eventId
        });
    }

    joinEventQR(eventId: number, groupId: number, roleCode: number, token: string): Promise<boolean> {
        return this.generic.genericPostStandardWData("/events/v2/user/register-user", {
            eventId: eventId,
            groupId: groupId,
            token: token,
            roleCode: roleCode
        });
    }

    setGroupRole(groupId: number, roleCode: number): Promise<boolean> {
        return this.generic.genericPostStandardWData("/events/v2/user/set-group-role", {
            groupId: groupId,
            groupRoleCode: roleCode
        });
    }

    quitEventV2(eventId: number): Promise<boolean> {
        return this.generic.genericPostStandardWData("/events/v2/groups/remove-user-all-groups", {
            eventId: eventId
        });
    }


    /**
     * get available group roles
     * use cache
     * @param fromCache 
     */
    getRoles(eventId: number, groupId: number) {
        let promise = new Promise((resolve, reject) => {
            this.generic.genericPostStandardWData("/events/v2/user/view-roles", {
                eventId: eventId,
                groupId: groupId
            }).then((res) => {
                this.roles = res;
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    viewGroupStats(eventId: number, groupId: number) {
        return this.generic.genericPostStandardWData("/events/v2/user/view-group-stats", {
            groupId: groupId,
            eventId: eventId
        });
    }

    viewUserGroupDetails(eventId: number, groupId: number): Promise<IEventGroupDetailsResponse> {
        return this.generic.genericPostStandardWData("/events/v2/groups/view-user-group-details", {
            groupId: groupId,
            eventId: eventId
        });
    }


    registerUserStartNoAction(eventId: number) {
        this.registerUserStart(eventId).then(() => {

        }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "events");
        });
    }

    registerUserQuitNoAction(eventId: number) {
        this.registerUserQuit(eventId).then(() => {

        }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "events");
        });
    }

    registerUserFinishNoAction(eventId: number) {
        this.registerUserFinish(eventId).then(() => {

        }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "events");
        });
    }

    registerUserStart(eventId: number): Promise<boolean> {
        return this.generic.genericPostStandardWData("/events/v1/register-user", {
            eventId: eventId,
            flag: 1
        });
    }

    registerUserQuit(eventId: number): Promise<boolean> {
        return this.generic.genericPostStandardWData("/events/v1/register-user", {
            eventId: eventId,
            flag: 2
        });
    }

    registerUserFinish(eventId: number): Promise<boolean> {
        return this.generic.genericPostStandardWData("/events/v1/register-user", {
            eventId: eventId,
            flag: 3
        });
    }

    /**
     * 
     * @param eventId 
     * @param activityParams 
     * @param stats object stats (key value pairs)
     */
    registerUserStatsNoAction(eventId: number, activityParams: IActivityParam[], stats: IGenericActivityStats) {
        this.registerUserStats(eventId, activityParams, stats).then(() => {
            console.log("user stats registered");
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * register scaled user stats for activity
     */
    registerUserStats(eventId: number, activityParams: IActivityParam[], stats: IGenericActivityStats) {
        let promise = new Promise((resolve, reject) => {
            this.generic.genericPostStandardWData("/events/v1/register-score", {
                eventId: eventId,
                activityParams: activityParams,
                stats: stats
            }).then((response: IGenericResponse) => {
                resolve(response);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }
}
