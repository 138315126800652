import { IGenericActivityStats } from "../core/activity-stats";

export interface IMPGameResults {
    groupId: number;
    challengeId: number;
    duration: number;
    items: IMPGameResultPlayer[];
}

export interface IMPGameResultPlayer {
    score: number;
    playerId: number;
    position: number;
    verdict: number;
    activityDetails: IGenericActivityStats;
}

export enum EMPGameResultVerdict {
    TBD = 0,
    finished = 1,
    failed = 2,
    stopped = 3
}
