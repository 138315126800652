<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="rightBtnIcon" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [rounded]="vs.fullScreen">
  </leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <ion-list class="list-md-noborder" *ngIf="loaded">
    <ion-item class="list-item transparent-bg settings-item" *ngFor="let sel of selectors">
      <ion-label>{{sel.heading}}</ion-label>
      <!-- [pickerOptions]="sel.customPickerOptions"  -->
      <ion-datetime pickerFormat="DD-MMM-YY HH:mm" displayFormat="DD-MMM-YYYY HH:mm" [min]="sel.minValue"
        [max]="sel.maxValue" [(ngModel)]="sel.value" (ionChange)="onChange(sel)">
      </ion-datetime>
    </ion-item>
  </ion-list>

  <div fxFlex>
  </div>

  <div class="stick-bottom theme-border-top">
    <ion-grid class="full-w">
      <ion-row *ngIf="!withClear">
        <ion-col size="12">
          <ion-button class="action-button-fill button-color-primary" (click)="ok()">
            <span class="button-font-size-m">
              Apply
            </span>
          </ion-button>
        </ion-col>
      </ion-row>
      <ion-row *ngIf="withClear">
        <ion-col size="6">
          <ion-button class="action-button-fill button-color-primary" (click)="clear()">
            <span class="button-font-size-m">
              Clear
            </span>
          </ion-button>
        </ion-col>
        <ion-col size="6">
          <ion-button class="action-button-fill button-color-primary" (click)="ok()">
            <span class="button-font-size-m">
              Apply
            </span>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</modal-container>