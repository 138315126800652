<!-- theme-toolbar-color -->
<div>
    <progress-loading *ngIf="loading" [small]="true"></progress-loading>
    <div [ngClass]="rounded ? 'progress-bar-filler-s' : 'progress-bar-filler-s-transparent'" *ngIf="!loading">
    </div>
</div>

<ion-toolbar hideBackButton="true" class="toolbar-style"
    [ngClass]="rounded ? 'rounded-toolbar toolbar-inline' : transparent ? 'toolbar-inline' : 'toolbar-background-primary'">

    <ion-buttons slot="start" *ngIf="menu && !noBackButton && show" class="height-none">
        <ion-menu-button class="as-text menu-button-size"></ion-menu-button>
    </ion-buttons>

    <ion-buttons slot="start" *ngIf="!menu && !noBackButton && show">
        <ion-button (click)="leftClicked()" [disabled]="leftButtonDisabled" class="as-text menu-button-size">
            <span *ngIf="!leftIcon" class="leplace-menu-text"
                [ngClass]="leftIcon ? 'button-font-size-s' : 'button-font-size-m'">
                {{leftButton}}
            </span>
            <div class="leplace-menu-icon" *ngIf="leftIcon">
                <icon-wrapper [icon]="leftButton" [custom]="customLeftIcon"></icon-wrapper>
            </div>
        </ion-button>
    </ion-buttons>

    <ion-title [ngClass]="!noBackButton ? 'text-center toolbar-title-center' : 'padding-left-m toolbar-title-left'">
        <span [ngClass]="titleSmall ? 'title-xs' : 'title-s'">
            {{trimTitle}}
        </span>
    </ion-title>

    <ion-buttons slot="end">
        <ion-button (click)="rightClicked()" [disabled]="rightButtonDisabled"
            class="as-text menu-button-size menu-button-size-right" *ngIf="show">
            <span *ngIf="!rightIcon" class="leplace-menu-text"
                [ngClass]="rightIcon ? 'button-font-size-s' : 'button-font-size-m'">
                {{rightButton}}
            </span>
            <div class="leplace-menu-icon" *ngIf="rightIcon">
                <icon-wrapper [icon]="rightButton" [custom]="customRightIcon"></icon-wrapper>
            </div>
        </ion-button>
    </ion-buttons>
</ion-toolbar>