<ng-template #template>
  <ng-content></ng-content>
</ng-template>

<ion-row *ngIf="loaded" class="center-items">
  <ion-col size="2">
    <ion-button *ngIf="!started" class="action-button-fill-xs button-color-transparent" (click)="start()" icon-end>
      <icon-wrapper slot="start" [icon]="appIconsStandard.play" [custom]="false" class="slot-icon-padding">
      </icon-wrapper>
    </ion-button>
    <ion-button *ngIf="started" class="action-button-fill-xs button-color-transparent" (click)="stop()" icon-end>
      <icon-wrapper slot="start" [icon]="appIconsStandard.stop" [custom]="false" class="slot-icon-padding">
      </icon-wrapper>
    </ion-button>
  </ion-col>
  <ion-col size="10">
    <progress-loading *ngIf="!started" [manual]="true" [progress]="progress" [small]="true"></progress-loading>
    <progress-loading *ngIf="started" [manual]="false" [indeterminate]="true" [small]="true"></progress-loading>
  </ion-col>
</ion-row>