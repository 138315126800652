import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IUserPublicData } from 'src/app/classes/def/user/general';
import { EPhotos } from 'src/app/classes/def/app/icons';
import { GeneralUtils } from 'src/app/classes/utils/general';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { IGenericLeaderboardEntry } from 'src/app/classes/def/user/leaderboard';
import { IGroup } from 'src/app/classes/def/mp/groups';
import { StringUtils } from 'src/app/services/app/utils/string-utils';

@Component({
  selector: 'leader-card-ext',
  templateUrl: './leader-card-ext.component.html',
  styleUrls: ['./leader-card-ext.component.scss'],
})
export class LeaderCardExtComponent implements OnInit, OnDestroy {
  photoLoaded: boolean = false;
  loaded: boolean = false;

  @Input()
  entry: IGenericLeaderboardEntry;

  @Input()
  selected: boolean;

  scoreDisp: string = "";
  levelDisp: string = "";
  typeDisp: string = "";
  headingDisp: string = "";
  bgPhoto: string = null;
  entryName: string = "";
  userAlias: string = "";
  isGroup: boolean = false;
  self: boolean = false;

  constructor(

  ) {

  }

  ngOnInit() {
    if (this.entry) {
      this.formatEntry();
    }
  }

  ngOnDestroy() {

  }

  formatUser(user: IUserPublicData) {
    if (!user.photoUrl) {
      this.bgPhoto = EPhotos.userPlaceholder;
    } else {
      this.bgPhoto = user.photoUrl;
    }
    this.entryName = StringUtils.trimNameMultilineHTML(user.name, EMessageTrim.leaderboardUsernameCard);
  }

  formatGroup(group: IGroup) {
    if (!group.photoUrl) {
      this.bgPhoto = EPhotos.userPlaceholder;
    } else {
      this.bgPhoto = group.photoUrl;
    }
    this.entryName = StringUtils.trimNameMultilineHTML(group.name, EMessageTrim.leaderboardUsernameCard);
  }

  formatEntry() {
    if (this.entry.group) {
      this.isGroup = true;
      this.typeDisp = "team";
      this.formatGroup(this.entry.group);
    }
    if (this.entry.user) {
      this.typeDisp = "player";
      this.formatUser(this.entry.user);
    }
    this.headingDisp = "" + GeneralUtils.getOrdinalString(this.entry.rank);
    this.scoreDisp = "" + this.entry.score + " XP";
    this.loaded = true;
  }
}
