import { Component, OnDestroy, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { IUserPublicData } from 'src/app/classes/def/user/general';
import { IEventLeaderboardAux } from 'src/app/classes/def/events/events';
import { ILeaderboardNavParams, ELeaderboardScope, ILeaderboardDetailNavParams } from 'src/app/classes/def/nav-params/leaderboard';
import { IPaginationContainer } from 'src/app/classes/def/views/pagination';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { Platform, ModalController } from '@ionic/angular';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IPageResponse, IGenericResponse } from 'src/app/classes/def/requests/general';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { IInventoryNavParams } from 'src/app/classes/def/nav-params/inventory';
import { IPopoverActions, IPopoverInputs } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { EventsDataService } from 'src/app/services/data/events';
import { PaginationHandlerService } from 'src/app/services/general/ui/pagination-handler';
import { ERouteDef } from 'src/app/app-utils';
import { InventoryHomePage } from '../inventory/inventory-home/inventory-home.page';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { INavBarItem } from 'src/app/classes/def/views/nav';
import { LocationMonitorService } from 'src/app/services/map/location-monitor';
import { ELeaderboardMode } from 'src/app/classes/def/leaderboard/leaderboard';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { Router } from '@angular/router';
import { ILatLng } from 'src/app/classes/def/map/coords';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.page.html',
  styleUrls: ['./leaderboard.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LeaderboardPage implements OnInit, OnDestroy {
  title: string;
  board: IUserPublicData[];
  info: IEventLeaderboardAux = null;
  infoHTML: string = "";

  loaded: boolean = false;
  enableDemo: boolean = false;
  timeout = {
    autoReload: null
  };
  theme: string = "theme-light theme-light-bg";
  params: ILeaderboardNavParams;
  isModal: boolean = false;
  enableBackButton: boolean = false;

  localItemsPerPage: number = 10;
  withDetails: boolean = false;

  pagination: IPaginationContainer = {
    page: 0,
    pages: 1,
    pageDisp: 1,
    hideLeft: true,
    hideRight: false
  };

  searchName: string = null;

  scope: number = ELeaderboardScope.global;
  leaderboardName: string = null;
  emptyResult: boolean = false;

  platform: IPlatformFlags = {} as IPlatformFlags;

  autoReload: boolean = false;
  small: boolean = false;

  mpCard: boolean = false;

  np: INavParams = null;
  vs: IViewSpecs;

  mode: number = ELeaderboardMode.global;
  prevMode: number = ELeaderboardMode.global;

  categoryTabsGroupCount: number = 3;
  navBarItems: INavBarItem[] = [{
    name: "world",
    value: ELeaderboardMode.global
  }, {
    name: "local",
    value: ELeaderboardMode.local
  }, {
    name: "fresh",
    value: ELeaderboardMode.fresh
  }];

  initLoaded: boolean = false;

  constructor(
    public router: Router,
    public settingsProvider: SettingsManagerService,
    public zone: NgZone,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public backButton: BackButtonService,
    public analytics: AnalyticsService,
    public modalCtrl: ModalController,
    public multiplayerDataProvider: MPDataService,
    public eventDataProvider: EventsDataService,
    public paginationHandler: PaginationHandlerService,
    public nps: NavParamsService,
    public locationMonitor: LocationMonitorService,
    public locationManager: LocationManagerService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  openPaginateSelector() {
    this.paginationHandler.openPaginateSelector(() => {
      return this.loadDataByPageNoAction(this.pagination.page, this.mode);
    });
  }

  switchPage(dir: number) {
    this.paginationHandler.setPaginationPage(dir);
    console.log("set pagination (" + dir + ") - " + this.pagination.page);
    this.loadDataByPageNoAction(this.pagination.page, this.mode);
  }

  ngOnDestroy() {
    // console.log("will leave");
    this.clearWatch();
    console.log("destroy");
    this.backButton.checkPop(this.vs);
  }

  back() {
    if (this.isModal) {
      this.modalDismiss();
    } else {
      switch (this.scope) {
        case ELeaderboardScope.event:
          let navParams: INavParams = {
            view: null,
            eventId: this.params.eventListNavParams.eventId,
            params: this.params.eventListNavParams
          };

          this.nps.set(ENavParamsResources.events, navParams);

          this.router.navigate([ERouteDef.eventsHome], { replaceUrl: true }).then(() => {
          }).catch((err: Error) => {
            console.error(err);
          });
          break;
        case ELeaderboardScope.global:
        default:
          this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
          }).catch((err: Error) => {
            console.error(err);
          });
          break;
      }

    }
  }

  modalDismiss() {
    setTimeout(() => {
      this.modalCtrl.dismiss().then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }


  ngOnInit(): void {
    this.title = "Leaderboard";
    this.analytics.trackView("leaderboard");
    this.initLoaded = true;
    this.paginationHandler.init(this.pagination);
    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.leaderboard, null, this.np);
      console.log("nav params: ", npInfo.params);
      let npar = npInfo.params;
      let hasParams: boolean = npInfo.hasParams;
      this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
        if (loaded) {
          this.platform = SettingsManagerService.settings.platformFlags;
          if (this.platform.WEB) {
            this.toggleAutoReload(true);
          }
        }
      }, (err: Error) => {
        console.error(err);
      });

      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      if (hasParams) {
        let np: INavParams = npar;
        this.params = np.params;
        console.log(this.params);
        this.vs = np.view;
        this.isModal = this.vs ? this.vs.isModal : false;
        // this.enableBackButton = this.isModal;
        this.enableBackButton = true;
        this.scope = this.params.scope;
        this.mpCard = this.params.mpCard;
        switch (this.scope) {
          case ELeaderboardScope.event:
            if (this.params.lb) {
              this.withDetails = true;
              this.leaderboardName = this.params.lb.leaderboard.name;
              this.infoHTML = this.params.lb.leaderboard.description;
            }
            this.small = true;
            this.enableBackButton = true;
            break;
          default:
            break;
        }
      } else {
        this.enableBackButton = true;
      }

      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back();
        }, this.vs);
      }).catch((err: Error) => { console.error(err); });

      this.loadDataByPage(this.pagination.page, this.mode).then(() => {
        this.loaded = true;
      }).catch((err: Error) => {
        this.loaded = true;
        this.analytics.dispatchError(err, "leaderboard");
        this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
      });
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  loadDataByPageNoAction(page: number, mode: number) {
    this.loadDataByPage(page, mode).then(() => {
      this.prevMode = this.mode;
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "story-list");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
      this.mode = this.prevMode;
    });
  }

  loadDataByPage(page: number, mode: number): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      this.loaded = false;

      // get current location
      let promiseGetLocation: Promise<ILatLng> = new Promise((resolve) => {
        if (mode === ELeaderboardMode.local) {
          // location is important, request from location provider
          this.locationManager.getCurrentLocationWrapper(true, true, true).then((coords: ILatLng) => {
            resolve(coords);
          }).catch((err: Error) => {
            console.error(err);
            resolve(this.locationMonitor.getCachedLocation());
          });
        } else {
          // use cached location
          resolve(this.locationMonitor.getCachedLocation());
        }
      });

      promiseGetLocation.then((currentLocation: ILatLng) => {
        if (this.params && this.params.lb && this.params.lb.leaderboard && !this.params.reload) {
          this.loadDataCoreLocalPagination(this.params.lb.leaderboard.players, page);
        } else {
          switch (this.scope) {
            case ELeaderboardScope.event:
              this.eventDataProvider.getLeaderboardByPage(this.params.lb.eventId, this.params.lb.leaderboardId, page).then((response: IPageResponse) => {
                this.loadDataCore(response);
                this.loaded = true;
                resolve(true);
              }).catch((err: Error) => {
                reject(err);
              });
              break;
            case ELeaderboardScope.global:
            default:
              this.multiplayerDataProvider.getLeaderboardByPage(page, mode, currentLocation ? currentLocation.lat : null, currentLocation ? currentLocation.lng : null).then((response: IGenericResponse) => {
                this.loadDataCore(response.data);
                this.loaded = true;
                resolve(true);
              }).catch((err: Error) => {
                reject(err);
              });
              break;
          }
        }
      });
    });
    return promise;
  }

  selectCategoryFromNavItem(navItem: INavBarItem) {
    this.pagination.page = 0;
    this.pagination.pageDisp = 1;
    if (navItem) {
      this.mode = navItem.value;
      this.loadDataByPageNoAction(0, navItem.value);
    }
  }

  loadDataByName(name: string) {
    let promise = new Promise((resolve, reject) => {
      this.loaded = false;
      this.multiplayerDataProvider.getLeaderboardByName(name).then((response: IGenericResponse) => {
        this.loadDataCore(response.data);
        this.loaded = true;
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  findMe() {
    let promise = new Promise((resolve, reject) => {
      this.loaded = false;
      this.multiplayerDataProvider.getLeaderboardByUserId().then((response: IGenericResponse) => {
        this.loadDataCore(response.data);
        this.loaded = true;
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * local pagination
   * @param data 
   * @param page 
   */
  loadDataCoreLocalPagination(data: IUserPublicData[], page: number) {
    if (!data) {
      this.loaded = true;
      return;
    }
    this.emptyResult = !(data.length > 0);
    this.pagination.pages = Math.ceil(data.length / this.localItemsPerPage);
    this.board = data.slice(page * this.localItemsPerPage, (page + 1) * this.localItemsPerPage);
    this.pagination.page = page;
    this.paginationHandler.setPaginationControls();
    this.loaded = true;
  }

  loadDataCore(resp: IPageResponse) {
    if (!resp) {
      return;
    }

    // resp.data is sometimes retrieved from resp.users
    if (!resp.data) {
      resp.data = [];
    }

    let board: IUserPublicData[] = resp.data;
    let usersFound: IUserPublicData[] = board.filter(user => user.found);
    if (usersFound && usersFound.length > 0) {
      board = usersFound;
    }
    this.board = board;

    // console.log(resp);
    this.emptyResult = this.board.length === 0;

    if (resp.aux) {
      this.info = resp.aux;
      console.log(this.info);
    }

    this.pagination.pages = resp.pages;
    this.pagination.page = resp.page;
    this.paginationHandler.setPaginationControls();
    this.loaded = true;
  }


  swipeEvent(e) {
    // if (!this.backButton.handleSwipeEvent(e)) {

    console.log("swipe event: ", e);
    if (!this.platform.IOS) {
      if (e) {
        switch (e.direction) {
          case 2:
            // right to left
            if (!this.pagination.hideRight) {
              this.switchPage(1);
            }
            break;
          case 4:
            // left to right
            if (!this.pagination.hideLeft) {
              this.switchPage(-1);
            }
            break;
        }
      }
    }
  }


  clearWatch() {
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
  }


  goToLeader(user: IUserPublicData): void {

    let params1: ILeaderboardDetailNavParams = {
      leaderId: user.id,
      leaderName: user.name,
      isGroup: false,
      storyId: null,
      details: user
    };

    let params: IInventoryNavParams = {
      leaderboardDetail: params1,
      closeOnItemUse: false,
      fixedCategory: false
    };

    this.uiext.showCustomModal(null, InventoryHomePage, {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: true
      },
      params: params
    }).then(() => {
      // reset pagination handler
      this.paginationHandler.init(this.pagination);
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "leaderboard");
      // reset pagination handler
      this.paginationHandler.init(this.pagination);
    });
  }


  options() {
    let actions: IPopoverActions = {};
    actions = {
      refresh: {
        name: "Reload",
        code: 0,
        icon: EAppIconsStandard.refresh,
        enabled: true
      },
      search: {
        name: "Search",
        code: 1,
        icon: EAppIconsStandard.search,
        enabled: this.scope === ELeaderboardScope.global
      },
      findMe: {
        name: "Find me",
        code: 3,
        icon: EAppIconsStandard.userMarker,
        enabled: this.scope === ELeaderboardScope.global
      },
      autoRefresh: {
        name: this.autoReload ? "Stop reload" : "Auto reload*",
        code: 2,
        icon: EAppIconsStandard.sync,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 0:
          this.pagination.page = 0;
          this.loadDataByPage(this.pagination.page, this.mode).then(() => {

          }).catch((err: Error) => {
            console.error(err);
          });
          break;
        case 1:
          this.searchByName();
          break;
        case 2:
          if (this.autoReload) {
            this.toggleAutoReload(false);
          } else {
            this.toggleAutoReload(true);
          } 
          break;
        case 3:
          this.findMeWizard();
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * set or toggle auto reload
   * @param enable 
   */
  toggleAutoReload(enable: boolean) {
    console.log("toggle auto reload: ", enable);
    this.timeout.autoReload = ResourceManager.clearTimeout(this.timeout.autoReload);

    if (enable && !this.autoReload) {
      this.autoReload = true;
      this.doAutoReload();
    }

    if (!enable && this.autoReload) {
      this.autoReload = false;
      this.timeout.autoReload = ResourceManager.clearTimeout(this.timeout.autoReload);
    }
  }

  /**
   * auto reload every 10 minutes for showcase (e.g. event stand)
   */
  doAutoReload() {
    this.timeout.autoReload = setTimeout(() => {
      this.loadDataByPage(this.pagination.page, this.mode).then(() => {
        console.log("auto reload complete");
        this.doAutoReload();
      }).catch((err: Error) => {
        console.error(err);
        this.doAutoReload();
      });
    }, 600000);
  }

  findMeWizard() {
    this.findMe().then(() => {
      this.mode = ELeaderboardMode.global;
    }).catch((err: Error) => {
      console.error(err);
      this.pagination.page = 0;
      this.loadDataByPage(this.pagination.page, ELeaderboardMode.global).then(() => {

      }).catch((err: Error) => {
        console.error(err);
      });
    });
  }


  /**
   * search by name in the leaderboard and retrieve the containing page
   */
  searchByName() {
    this.searchName = null;
    let popoverInputs: IPopoverInputs = {
      username: {
        // the name is the actual tag here
        name: "username",
        value: this.searchName,
        placeholder: "username/@alias",
        enabled: true
      }
    };
    let popoverActions: IPopoverActions = {
      cancel: {
        name: "Cancel",
        code: 0,
        enabled: true
      },
      ok: {
        name: "Ok",
        code: 1,
        enabled: true
      }
    };

    this.uiext.showCustomAlertInput("Find username", null, popoverInputs, popoverActions).then((res: any) => {
      let code = res.code;
      let data = res.data;
      switch (code) {
        case 0:
          break;
        case 1:
          this.searchName = data.username;
          this.loadDataByName(this.searchName).then(() => {
            this.mode = ELeaderboardMode.global;
          }).catch((err: Error) => {
            console.error(err);
            this.pagination.page = 0;
            this.loadDataByPage(this.pagination.page, ELeaderboardMode.global).then(() => {

            }).catch((err: Error) => {
              console.error(err);
            });
          });
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

}
