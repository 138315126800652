import { BehaviorSubject, Observable, timer } from "rxjs";
import { Injectable } from "@angular/core";
import { TimeUtils } from "../../../classes/general/time";
import { ResourceManager } from "../../../classes/general/resource-manager";
import { ITimeoutMonitorParams, ITimeoutMonitorData, ETimeoutStatus } from 'src/app/classes/general/timeout';
import { IObservableMultiplex, ISubMultiplex } from "src/app/classes/def/mp/subs";

@Injectable({
    providedIn: 'root'
})
export class TimeoutService {

    sub: ISubMultiplex = {
        timer: null,
        rescueTimer: null
    };

    obs: IObservableMultiplex = {
        timer: null,
        rescueTimer: null
    };

    enableAuxCounter: boolean = false;

    timeParams: ITimeoutMonitorParams = {
        timeLimit: 0
    };

    timeDataInit: ITimeoutMonitorData = {
        timerValue: 0,
        elapsedValue: 0,
        elapsedValueAux: 0,
        status: ETimeoutStatus.started,
        timerDisp: "",
        isFallbackTimer: false
    };

    timeData: ITimeoutMonitorData;

    constructor(
    ) {
        console.log("timeout service created");
        this.obs.timer = new BehaviorSubject(null);
        this.timeData = Object.assign({}, this.timeDataInit);
        let timer1: Observable<number> = timer(0, 3000);
        this.sub.rescueTimer = timer1.subscribe(() => {
            this.runRescueTimerAction();
        });
    }

    private startTimer() {
        if (this.sub.timer) {
            console.warn("timer already running");
            return;
        }

        if (!this.timeParams.timeLimit) {
            console.warn("time limit not specified");
            return;
        }

        let timer1: Observable<number> = timer(0, 1000);

        this.sub.timer = timer1.subscribe(() => {
            this.runTimerAction(1);
        });
    }

    runTimerAction(elapsedSecs: number) {
        this.timeData.timerValue -= elapsedSecs;
        this.timeData.elapsedValue += elapsedSecs;
        if (this.enableAuxCounter) {
            this.timeData.elapsedValueAux += elapsedSecs;
        }
        if (this.timeData.timerValue < 0) {
            this.timeData.timerValue = 0;
            this.timeData.status = ETimeoutStatus.expired;
        }
        this.timeData.timerDisp = TimeUtils.formatTimeFromSeconds(this.timeData.timerValue, true);
        this.obs.timer.next(this.timeData);
    }

    runRescueTimerAction() {
        let timerData: ITimeoutMonitorData = Object.assign({}, this.timeDataInit);
        timerData.isFallbackTimer = true;
        this.obs.timer.next(timerData);
    }

    setAuxCounter(enable: boolean) {
        this.enableAuxCounter = enable;
    }

    private stopTimer() {
        this.sub.timer = ResourceManager.clearSub(this.sub.timer);
    }

    getTimeoutData() {
        return this.timeData;
    }

    getWatch() {
        return this.obs.timer;
    }

    start(params: ITimeoutMonitorParams) {
        console.log("start timeout monitor: ", params);
        this.timeParams = params;
        this.timeData = Object.assign({}, this.timeDataInit);
        this.timeData.timerValue = this.timeParams.timeLimit;
        this.obs.timer.next(null);
        this.startTimer();
    }

    triggerExpired() {
        this.timeData.timerValue = 0;
    }

    triggerDecreaseTimer(increment: number) {
        this.timeData.timerValue -= increment;
        if (this.timeData.timerValue <= 0) {
            this.timeData.timerValue = 0;
            return true;
        }
        return false;
    }

    stop() {
        console.log("stop timeout monitor");
        this.obs.timer.next(null);
        this.stopTimer();
    }
}
