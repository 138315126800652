
import { Injectable } from '@angular/core';
import { IPlatformFlags } from '../../../classes/def/app/platform';
import { SettingsManagerService } from '../settings-manager';
import { WebviewUtilsService } from '../../app/utils/webview-utils';
import { UiExtensionService } from '../ui/ui-extension';
import { AdColonyAdsService } from './ads-adcolony';
import { AdmobproAdsService } from './ads-admobpro';
import { AdmobFreeAdsService } from './ads-admobfree';
import { AdsTemplateService } from './ads-template';
import { EAdService } from 'src/app/classes/def/app/ads';
import { AppConstants } from 'src/app/classes/app/constants';
import { AdmobplusAdsService } from './ads-admobplus';
import { AdSupportService } from './ads-support';
import { AdsDisabledService } from './ads-disabled';


@Injectable({
    providedIn: 'root'
})
export class AdsService {
    platform: IPlatformFlags = {} as IPlatformFlags;

    testing: boolean = false;

    // adServiceSelector: number = EAdService.adMobFree;
    adServiceSelector: number = EAdService.disabled;
    adService: AdsTemplateService = null;

    initialized: boolean = false;
    adsDisabledFlag: boolean = true;

    constructor(
        private settingsProvider: SettingsManagerService,
        private webviewUtils: WebviewUtilsService,
        private adColony: AdColonyAdsService,
        private admobPlusAds: AdmobplusAdsService,
        private admobproAds: AdmobproAdsService,
        private admobfreeAds: AdmobFreeAdsService,
        private adSupport: AdSupportService,
        private adsDisabled: AdsDisabledService,
        private uiext: UiExtensionService
    ) {
        console.log("ads service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.onPlatformLoaded();
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    /**
     * service selector factory
     */
    initServiceSelector() {
        console.log("init ad service selector: ", this.adServiceSelector);
        switch (this.adServiceSelector) {
            case EAdService.adMobFree:
                this.adService = this.admobfreeAds;
                break;
            case EAdService.adMobPro:
                this.adService = this.admobproAds;
                break;
            case EAdService.adMobPlus:
                this.adService = this.admobPlusAds;
                break;
            case EAdService.adColony:
                this.adService = this.adColony;
                break;
            case EAdService.disabled:
            default:
                this.adsDisabledFlag = true;
                this.adService = this.adsDisabled;
        }
    }

    onPlatformLoaded() {

    }

    /**
     * set testing mode (test ads)
     * initialize ad service according to ad service spec selector
     * @param testing 
     */
    initializeAdService(testing: boolean) {
        this.testing = testing;
        this.checkSettings();
    }


    checkSettings() {
        this.settingsProvider.getSettingsLoaded(false).then((res) => {
            if (res) {
                // check disable testing option
                if (SettingsManagerService.settings.app.settings.disableTestAds.value) {
                    this.testing = false;
                }
                console.log("ad testing: " + this.testing);

                if (!this.initialized) {
                    console.log("initializing ad service selector");
                    this.adServiceSelector = AppConstants.gameConfig.adServiceSelector;
                    this.initServiceSelector();
                    this.adService.setup();
                    this.initialized = true;
                }

                this.adService.setAdIds(this.testing);
            }
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    showBanner() {
        return this.adService.showBanner();
    }

    /**
     * the main entry point to reward video display
     * called from outside the provider
     * it calls the handle reward video that runs in a loop until the video is shown
     */
    showRewardVideo(withLoading: boolean, popupNoAds: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
            if (this.platform.WEB) {
                console.warn("ads not available on platform web");
                resolve(true);
                return;
            }

            if (this.adsDisabledFlag) {
                return Promise.resolve(false);
            }

            this.handleRewardVideoWrapper(withLoading).then(async (status: boolean) => {
                console.log("handle reward resolve: ", status);
                await this.webviewUtils.resetViewframe(true, false);
                if (status == null) {
                    status = false;

                    // dismiss loading on error loading
                    if (withLoading) {
                        await this.uiext.dismissLoadingV2();
                    }

                    if (popupNoAds) {
                        await this.uiext.showRewardPopupQueue("Video Ads", "Reward video not available at the moment. Please try again later.", null, false);
                    }
                }
                resolve(status);
            }).catch(async (err: Error) => {
                await this.webviewUtils.resetViewframe(true, false);
                if (popupNoAds) {
                    await this.uiext.showRewardPopupQueue("Video Ads", "Reward video not available at the moment. Please try again later.", null, false);
                }
                reject(err);
            });
        });
        return promise;
    }

    async handleRewardVideoWrapper(withLoading: boolean) {

        let onPrepared = () => {
            if (withLoading) {
                return this.uiext.dismissLoadingV2();
            } else {
                return Promise.resolve(true);
            }
        };

        let promise: Promise<boolean> = new Promise((resolve) => {

            let onFallback = () => {
                onPrepared();
                this.showInterstitial().then(() => {
                    // resolve(false);
                    // assign reward on fallback (it's not the user's fault)
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(null);
                });
            };

            let onShowAd = () => {
                let promiseLoading: Promise<boolean>;
                if (withLoading) {
                    promiseLoading = this.uiext.showLoadingV2Queue("Loading Ad..");
                } else {
                    promiseLoading = Promise.resolve(true);
                }

                promiseLoading.then(() => {
                    this.adService.handleRewardVideo(onPrepared).then((res: boolean) => {
                        if (res == null) {
                            onFallback();
                        } else {
                            onPrepared();
                            resolve(res);
                        }
                    }).catch(() => {
                        onFallback();
                    });
                });
            };

            let useNpaAlways: boolean = AppConstants.gameConfig.useNpaAlways === 1;
            let showConsentAlways: boolean = AppConstants.gameConfig.showConsentAlways === 1;

            if (useNpaAlways && !showConsentAlways) {
                this.adService.updateConsent(false);
                onShowAd();
            } else {
                this.adSupport.checkSavedConsent().then(async (consentStatus: boolean) => {
                    if (consentStatus == null) {
                        this.adSupport.showConsent().then(async (consentStatus: boolean) => {
                            console.log('consentStatus', consentStatus);
                            if (useNpaAlways) {
                                console.log("override consent use npa always");
                                this.adService.updateConsent(false);
                            } else {
                                this.adService.updateConsent(consentStatus);
                            }
                            onShowAd();
                        }).catch((err: Error) => {
                            console.error(err);
                            this.adService.updateConsent(false);
                            onShowAd();
                        });
                    } else {
                        if (useNpaAlways) {
                            console.log("override consent use npa always");
                            this.adService.updateConsent(false);
                        } else {
                            this.adService.updateConsent(consentStatus);
                        }
                        onShowAd();
                    }
                });
            }
        });
        return promise;
    }

    showInterstitial() {
        return this.adService.showInterstitial();
    }

}
