import { Component, OnInit, OnDestroy, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { ILeplaceReg } from 'src/app/classes/def/places/google';
import { IArenaNavParams } from 'src/app/classes/def/nav-params/activity';
import { IGroup, EGroupRole, EGroupModalResult, IGroupsPageResponse, EGroupMode, EGroupContext, EArenaEvent } from 'src/app/classes/def/mp/groups';
import { Platform, ModalController } from '@ionic/angular';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { MPUtils } from 'src/app/services/app/mp/mp-utils';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EArenaReturnCodes, IArenaReturnData, IArenaReturnToMapData } from 'src/app/classes/def/nav-params/arena';
import { IPopoverActions, IPopoverInputs } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { PlacesDataService } from 'src/app/services/data/places';
import { ERouteDef } from 'src/app/app-utils';
import { MpJoinPage } from '../mp-join/mp-join.page';
import { MPCreatePage } from '../mp-create/mp-create.page';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { LocationUtilsWizard } from 'src/app/services/location/location-utils-wizard';
import { Router } from '@angular/router';
import { IPaginationContainer } from 'src/app/classes/def/views/pagination';
import { PaginationHandlerService } from 'src/app/services/general/ui/pagination-handler';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { MpOptionsPage } from '../mp-options/mp-options.page';
import { INavBarItem } from 'src/app/classes/def/views/nav';
import { MPManagerService } from 'src/app/services/app/mp/mp-manager';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { IMPMeetingPlace } from 'src/app/classes/def/mp/meeting-place';
import { IStory } from 'src/app/classes/def/core/story';
import { IMPGenericGroupStat } from 'src/app/classes/def/mp/game';
import { ILeplaceTreasure } from 'src/app/classes/def/places/leplace';
import { IMPStatusDB } from 'src/app/classes/def/mp/status';
import { MPGameInterfaceService } from 'src/app/services/app/mp/mp-game-interface';
import { ResourceMonitorDataService } from 'src/app/services/data/resource-monitor';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { EFeatureCode, EServiceUrlCodes, IFeatureDef } from 'src/app/classes/def/app/app';
import { PremiumDataService } from 'src/app/services/data/premium';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { SoundManagerService } from 'src/app/services/general/apis/sound-manager';
import { MPGroupsHomePage } from '../mp-groups-home/mp-groups-home.page';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-mp-groups-list',
  templateUrl: './mp-groups-list.page.html',
  styleUrls: ['./mp-groups-list.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MPGroupsListPage implements OnInit, OnDestroy {
  @ViewChild('scrollContent', { static: false }) scrollContent: ElementRef;
  theme: string = "theme-light theme-light-bg";
  title: string = "Main Lobby";
  infoHTML: string = "";
  shareMessage: string = "";
  place: ILeplaceReg = null;
  photoUrlLoaded: string = null;
  params: IArenaNavParams = null;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  joined: boolean = false;

  loaded: boolean = true;

  groups: IGroup[] = [];
  selectedGroup: IGroup = null;
  closeMpView: boolean = false;

  description: string = `
  <p>View and manage your teams</p>
  <p>You have to create or join a team first</p>`;

  hasGroups: boolean = false;

  np: INavParams = null;
  vs: IViewSpecs;

  pagination: IPaginationContainer = {
    page: 0,
    pages: 1,
    pageDisp: 1,
    hideLeft: true,
    hideRight: false
  };

  selectedIndex: number = EGroupMode.private;
  categoryTabsGroupCount: number = 2;

  navBarItems: INavBarItem[] = [{
    name: "Public",
    value: EGroupMode.public
  }, {
    name: "Private",
    value: EGroupMode.private
  }];

  isLobbyContext: boolean = false;
  mode: number = EGroupMode.private;

  context: number = EGroupContext.global;
  contextId: number = null;

  // mp
  fromMpHome: boolean = false;

  infoText: string = Messages.mp.init;

  infoTextAux: string = null;
  statusUpdate: boolean = false;

  inRange: boolean = false;
  synchronized: boolean = true;

  loading: boolean = false;
  selectedMeetingPlace: IMPMeetingPlace = null;
  groupRole: number = EGroupRole.member;
  groupClosed: boolean = false;
  allConnected: boolean = false;

  connectedMeetingPlace: boolean = false;
  // connect by direct user input, authorize future connections e.g. returning from groups home
  authorizedMeetingPlaceMode: boolean = false;
  connecting: boolean = false;
  isChatOpened: boolean = false;
  canChat: boolean = false;

  nearbyPlayers: IMPStatusDB[] = [];

  subscription = {
    groupStatus: null,
    mpStateMachine: null,
    chatRx: null,
    arenaEvent: null,
    dispTimer: null
  };

  timeout = {
    other: null,
    enterMap: null,
    tapStartAuto: null,
    auxMessage: null,
    autoReload: null
  };

  observables = {
    chatInput: null
  };

  gameIsAlreadyOn: boolean = false;
  chatMessageReceived: boolean = false;
  chatBtnBlink: boolean = false;

  lockedForUser: boolean = true;
  mpLocked: boolean = false;

  autoReload: boolean = false;
  autoReloadPrev: boolean = false;

  loadingTimeout: number = 20;
  resetLoadingTimeout: boolean = false;
  showProgressReload: boolean = true;

  updateGroupsReloaded: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public mpDataService: MPDataService,
    public analytics: AnalyticsService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public backButton: BackButtonService,
    public router: Router,
    public placeProvider: PlacesDataService,
    public mpManager: MPManagerService,
    public mpGameInterface: MPGameInterfaceService,
    public resourceMonitor: ResourceMonitorDataService,
    public popupFeatures: PopupFeaturesService,
    public soundManager: SoundManagerService,
    public nps: NavParamsService,
    public locationUtilsWizard: LocationUtilsWizard,
    public premiumProvider: PremiumDataService,
    public paginationHandler: PaginationHandlerService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  ngOnInit() {

    this.analytics.trackView("mp-groups-list");
    this.paginationHandler.init(this.pagination);

    this.nps.checkParamsLoaded().then(async () => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.mpGroupList, null, this.np);

      console.log("nav params: ", npInfo.params);
      let hasParams: boolean = npInfo.hasParams;

      await this.resourceMonitor.checkWatchLoader();
      let available: boolean = await this.popupFeatures.checkServiceAvailable(EServiceUrlCodes.mp);

      if (available) {
        this.mpManager.changeServerUrl(GeneralCache.resourceCache.general.appServices.content.object.mp.url);
      } else {
        this.dismiss(null);
        return;
      }

      // disconnect/reset group mode
      this.mpGameInterface.disconnect();
      this.mpGameInterface.dispose();

      // console.log(navParams.data);
      if (hasParams) {
        let np: INavParams = npInfo.params;
        this.params = np.params;
        this.place = this.params.place;

        this.vs = np.view;

        this.isLobbyContext = this.params.isLobbyContext;
        this.context = this.params.context;
        this.contextId = this.params.contextId;

        this.fromMpHome = this.params.fromMpHome;

        this.inRange = this.params.inRange;
        this.synchronized = this.params.synchronized;

        if (this.isLobbyContext) {
          this.setContextTabs(EGroupMode.public);
        } else {
          this.setContextTabs(EGroupMode.private);
        }

        if (!this.fromMpHome) {
          // mp home functionality
          if (!this.params.testing) {
            this.place = this.params.place;
            this.selectedGroup = this.params.group;
            let mplace: ILeplaceTreasure = this.params.meetingPlace;
            if (mplace) {
              this.getMeetingPlaceSpec(mplace);
            } else {
              this.getStorySpecs(this.params.story);
            }
            this.groupRole = this.params.groupRole;
          }

          // group is not specified, check meeting place

          this.checkUnlocked();

          if (!this.params.enableGroups) {
            this.mpLocked = true;
            this.infoText = Messages.mp.initNoMp;
          }

          if (this.selectedMeetingPlace != null) {
            // meeting place full specs
            // this.connectMeetingPlaceMode();
            // PromiseUtils.wrapNoAction(this.connectMeetingPlaceModeWrapper(true), true);
          } else {
            // meeting place id specs
            let mpId: number = this.params.meetingPlaceId;
            if (mpId != null) {
              this.placeProvider.loadTreasure(mpId).then((treasure: ILeplaceTreasure) => {
                if (treasure) {
                  this.getMeetingPlaceSpec(treasure);
                  // PromiseUtils.wrapNoAction(this.connectMeetingPlaceModeWrapper(true), true);
                }
              }).catch((err: Error) => {
                console.error(err);
                this.uiext.showAlertNoAction(Messages.msg.mpChallengeLoadError.after.msg, Messages.msg.mpChallengeLoadError.after.sub);
              });
            }
          }
          if (this.params.fromMapOpened) {
            this.soundManager.ttsCodeWrapper(ETutorialEntries.ttsMeetingPlace, true);
          }
        }
      } else {
        // root
        this.description += `
            <p>You can create groups @Meeting Places<p>
            <p>>Open World Map</p>
            <p>>Select a Meeting Place</p>`;
      }

      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back();
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });

      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      this.loadGroups(null, true);
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  checkUnlocked() {
    if (this.params.isStoryline) {
      this.lockedForUser = false;
      return;
    }
    if (!this.params.enableGroups) {
      return;
    }

    let onCheck = (locked: boolean, price: IFeatureDef) => {
      this.lockedForUser = locked;
      if (locked) {
        this.infoText += "<p>MP is locked.</p>";
        if (price) {
          this.infoText += "<p>Level " + price.minLevel + " is required.</p>";
        }
      }
    };

    // request reload (user level might have changed)
    this.premiumProvider.checkFeaturePrice(this.params.isStoryline ? EFeatureCode.createGroupStoryline : EFeatureCode.createGroup, null, true).then((price: IFeatureDef) => {
      onCheck(price.lockedForUser ? true : false, price);
    }).catch((err: Error) => {
      console.error(err);
      onCheck(true, null);
    });
  }


  dismiss(params: any) {
    console.log("mp lobby dismiss: ", params);
    if (!params) {
      this.disposeMP();
    }
    setTimeout(() => {
      if (this.vs && this.vs.isModal) {
        this.modalCtrl.dismiss(params).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
      } else {
        this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
      }
    }, 1);
  }


  /**
   * reload stories from the server
   * go to next/prev page
   * @param dir
   * @param reload
   * @param searchName
   */
  refresh(dir: number, searchName: string, resetViewAlways: boolean) {
    // refresh stories
    console.log("refresh options");
    this.paginationHandler.setPaginationPage(dir);
    return this.loadGroups(searchName, resetViewAlways);
  }


  async loadGroups(searchName: string, resetViewAlways: boolean) {
    if (resetViewAlways) {
      this.loaded = false;
    }
    let groupData: IGroupsPageResponse;
    try {
      if (searchName != null) {
        groupData = await this.mpDataService.viewAllGroupsSearchByAlias(searchName, this.mode, this.context, this.contextId);
      } else {
        groupData = await this.mpDataService.viewAllGroups(this.pagination.page, this.mode, this.context, this.contextId);
      }

      let groups: IGroup[] = groupData != null ? groupData.data : [];

      let groupsFound: IGroup[] = groups.filter(g => g.found);
      if (groupsFound && groupsFound.length > 0) {
        groups = groupsFound;
      }

      if (!groups) {
        groups = [];
      }

      // check group list updated
      let groupListUpdated: boolean = MPUtils.checkGroupListChanged(this.groups, groups);

      this.groups = groups;
      if (groups) {
        this.hasGroups = true;
      } else {
        this.hasGroups = false;
      }

      if (groupListUpdated || resetViewAlways) {
        // update pagination
        this.pagination.page = groupData.page;
        this.pagination.pages = groupData.pages;
        this.paginationHandler.setPaginationControls();
      }

      this.updateGroupsReloaded = !this.updateGroupsReloaded;
      this.loaded = true;
    } catch (err) {
      console.error(err);
      this.loaded = true;
      // this.analytics.dispatchError(err, "mp");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
    }
  }


  back() {
    this.dismiss(null);
  }

  goToGroupsHome() {
    this.disconnectMeetingPlaceMode(false);
    let params: IArenaNavParams = this.params;
    this.pauseAutoReload();
    this.uiext.showCustomModal(null, MPGroupsHomePage, {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: true
      },
      params: params
    }).then((res: IArenaReturnToMapData) => {
      console.log("returned from mp groups home page: ", res);
      if (res != null) {
        this.closeMpView = res.closeMpView;
      }
      if (!this.fromMpHome) {
        if (res != null) {
          if (res.closeMpView) {
            if (!params.testing) {
              // cascade dismiss
              // this.dismiss(res);
              this.ok();
            }
          } else {
            // disconnect/reset group mode
            this.mpGameInterface.disconnect();
            this.mpGameInterface.dispose();
            if (this.authorizedMeetingPlaceMode) {
              this.connectMeetingPlaceMode(false);
            }
          }
        } else {
          // disconnect/reset group mode
          this.mpGameInterface.disconnect();
          this.mpGameInterface.dispose();
          if (this.authorizedMeetingPlaceMode) {
            this.connectMeetingPlaceMode(false);
          }
        }
      } else {
        this.ok();
      }
    }).catch((err: Error) => {
      console.error(err);
      this.resumeAutoReload();
    });
  }

  // open create/join group with filled in group details from existing group
  goToGroup(group: IGroup) {
    // select group and open create page

    if (!this.params) {
      this.params = {
        group: null,
        place: null,
        meetingPlace: null,
        groupRole: null,
        groupId: null,
        testing: false,
        chatOnly: this.params.chatOnly,
        playerId: null,
        inRange: this.params.inRange,
        canExit: true,
        enableGroups: true,
        isStoryline: false,
        isLobbyContext: null,
        context: null,
        contextId: null,
        synchronized: this.params.synchronized
      };
    }

    this.params.group = group;
    this.selectedGroup = group;

    this.mpDataService.viewGroup(group.id, true).then((group: IGroup) => {
      // fill group with details about registered (static) members
      this.params.group = group;

      MPUtils.formatGroupMembers(this.params.group, GeneralCache.userId);

      let params = Object.assign({}, this.params);
      params.isLobbyContext = this.isLobbyContext;
      this.disconnectMeetingPlaceMode(false);
      this.pauseAutoReload();
      this.uiext.showCustomModal(null, MPGroupsHomePage, {
        view: {
          fullScreen: true,
          transparent: false,
          large: true,
          addToStack: false,
          frame: true
        },
        params: params
      }).then(async (res: IArenaReturnToMapData) => {
        this.resumeAutoReload();
        console.log("returned from mp groups home page: ", res);
        console.log("from mp home selected: ", this.fromMpHome);
        if (res != null) {
          this.closeMpView = res.closeMpView;
        }

        if (!this.fromMpHome) {
          if (res != null) {
            if (res.groupUpdated) {
              await this.loadGroups(null, true);
            }
            if (res.closeMpView) {
              if (!params.testing) {
                // cascade dismiss
                // this.dismiss(res);
                this.ok();
              }
            } else {
              // disconnect/reset group mode
              this.mpGameInterface.disconnect();
              this.mpGameInterface.dispose();
              if (this.authorizedMeetingPlaceMode) {
                this.connectMeetingPlaceMode(false);
              }
            }
          } else {
            // disconnect/reset group mode
            this.mpGameInterface.disconnect();
            this.mpGameInterface.dispose();
            if (this.authorizedMeetingPlaceMode) {
              this.connectMeetingPlaceMode(false);
            }
          }
        } else {
          if (res != null) {
            switch (res.code) {
              case EArenaReturnCodes.leave:

                break;
              default:
                this.ok();
                break;
            }
          } else {
            this.ok();
          }
        }
      }).catch((err: Error) => {
        console.error(err);
        this.resumeAutoReload();
      });

      // this.uiext.showCustomModal(null, [EGroupRole.member, EGroupRole.lobby].indexOf(group.role) !== -1 ? MpJoinPage : MPCreatePage, {
      //   view: {
      //     fullScreen: true,
      //     transparent: false,
      //     large: true,
      //     addToStack: false,
      //     frame: true
      //   },
      //   params: params
      // }).then(async (res: IArenaReturnData) => {
      //   console.log("mp groups return data: ", res);
      //   this.resumeAutoReload();
      //   if (res) {
      //     switch (res.status) {
      //       case EGroupModalResult.ok:
      //         if (res.groupUpdated) {
      //           await this.loadGroups(null, true);
      //         }
      //         this.selectedGroup = res.group;
      //         // return to main view with selected group    
      //         this.mpGameInterface.setGameContainerUpdateGroupScope(this.selectedGroup, null, false);
      //         this.params.group = this.selectedGroup;
      //         if (!this.fromMpHome) {
      //           this.goToGroupsHome();
      //         } else {
      //           this.ok();
      //         }
      //         break;
      //       case EGroupModalResult.remove:
      //         this.params.group = null;
      //         this.pagination.page = 0;
      //         this.loadGroups(null, true);
      //         break;
      //       case EGroupModalResult.cancel:
      //         if (res.groupUpdated) {
      //           this.loadGroups(null, true);
      //         }
      //         break;
      //       default:
      //         break;
      //   }
      // }
      // }).catch((err: Error) => {
      //   console.error(err);
      //   this.resumeAutoReload();
      // });
    }).catch((err: Error) => {
      console.error(err);
      this.resumeAutoReload();
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
    });
  }

  ok() {
    let returnData: IArenaReturnData = {
      status: null,
      group: this.selectedGroup,
      closeMpView: this.closeMpView,
      role: EGroupRole.leader
    };
    returnData.status = EGroupModalResult.ok;
    this.dismiss(returnData);
  }

  cancel() {
    let returnData: IArenaReturnData = {
      status: null,
      group: null,
      closeMpView: this.closeMpView,
      role: EGroupRole.leader
    };
    returnData.status = EGroupModalResult.cancel;
    this.dismiss(returnData);
  }

  /**
   * Share progress then dismiss
   */
  share() {
    this.loaded = false;
    this.shareProvider.share(this.shareMessage).then(() => {
      this.loaded = true;

    }).catch(() => {
      this.loaded = true;
    });
  }


  ngOnDestroy() {
    console.log("close modal");
    this.clearWatch();
    this.backButton.checkPop(this.vs);
  }

  clearWatch() {
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
    this.subscription = ResourceManager.clearSubObj(this.subscription);
  }

  options() {
    let actions: IPopoverActions = {};
    actions = {
      reload: {
        name: "Reload",
        code: 0,
        icon: EAppIconsStandard.refresh,
        enabled: true
      },
      search: {
        name: "Search",
        code: 1,
        icon: EAppIconsStandard.search,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 0:
          this.reloadOnTap();
          break;
        case 1:
          this.searchByName();
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * search by name in the leaderboard and retrieve the containing page
   */
  searchByName() {
    let searchName: string = "";
    let popoverInputs: IPopoverInputs = {
      name: {
        // the name is the actual tag here
        name: "alias",
        value: searchName,
        placeholder: "team id",
        enabled: true
      }
    };
    let popoverActions: IPopoverActions = {
      cancel: {
        name: "Cancel",
        code: 0,
        enabled: true
      },
      ok: {
        name: "Ok",
        code: 1,
        enabled: true
      }
    };

    let title: string = "Find team";

    this.uiext.showCustomAlertInput(title, null, popoverInputs, popoverActions).then((res: any) => {
      let code = res.code;
      let data = res.data;
      // console.log(data);
      switch (code) {
        case 0:
          break;
        case 1:
          searchName = data.alias;
          this.refresh(0, searchName, true).then(() => {

          }).catch((err: Error) => {
            this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
          });
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  swipeEvent(e) {
    if (!this.backButton.handleSwipeEvent(e)) {
      if (e) {
        switch (e.direction) {
          case 2:
            // right to left
            if (!this.pagination.hideRight) {
              this.doRefresh(1);
            }
            break;
          case 4:
            // left to right
            if (!this.pagination.hideLeft) {
              this.doRefresh(-1);
            }
            break;
        }
      }
    }
  }

  async reloadOnTap() {
    try {
      this.toggleAutoReload(false);
      await this.doRefresh(0);
      this.toggleAutoReload(true);
    } catch (err) {
      console.error(err);
      this.toggleAutoReload(true);
    }
  }

  doRefresh(dir: number) {
    return new Promise<boolean>((resolve) => {
      this.refresh(dir, null, true).then(() => {
        this.scrollTop();
        resolve(true);
      }).catch((err: Error) => {
        this.analytics.dispatchError(err, "mp=groups-list");
        this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
        resolve(false);
      });
    });
  }

  pauseAutoReload() {
    console.log("pause auto reload");
    if (!this.autoReload) {
      return;
    }
    this.autoReloadPrev = true;
    this.showProgressReload = false;
    this.toggleAutoReload(false);
  }

  resumeAutoReload() {
    console.log("resume auto reload");
    if (!this.autoReloadPrev) {
      return;
    }
    this.autoReloadPrev = false;
    this.showProgressReload = true;
    this.toggleAutoReload(true);
  }

  /**
  * set or toggle auto reload
  * @param enable 
  */
  toggleAutoReload(enable: boolean) {
    console.log("toggle auto reload: ", enable);
    this.resetLoadingTimeout = !this.resetLoadingTimeout;
    if (enable && !this.autoReload) {
      this.autoReload = true;
      this.timeout.autoReload = ResourceManager.clearTimeout(this.timeout.autoReload);
      this.doAutoReload();
    }
    if (!enable && this.autoReload) {
      this.autoReload = false;
      this.timeout.autoReload = ResourceManager.clearTimeout(this.timeout.autoReload);
    }
  }

  /**
   * auto reload every 20 sec
   */
  doAutoReload() {
    this.timeout.autoReload = setTimeout(() => {
      this.refresh(0, null, false).then(() => {
        console.log("auto reload complete");
        this.resetLoadingTimeout = !this.resetLoadingTimeout;
        this.doAutoReload();
      }).catch((err: Error) => {
        console.error(err);
        this.doAutoReload();
      });
    }, this.loadingTimeout * 1000);
  }

  scrollTop() {
    this.scrollContent.nativeElement.scrollTop = 0;
  }

  goBackThenSelectOptions() {
    let returnData: IArenaReturnData = {
      status: null,
      group: null,
      closeMpView: this.closeMpView,
      role: EGroupRole.leader
    };
    returnData.status = EGroupModalResult.goToOptions;
    this.dismiss(returnData);
  }

  createJoinOptions(create: boolean) {
    let params = Object.assign({}, this.params);
    params.isLobbyContext = this.isLobbyContext;
    params.group = null;
    // create = null;
    this.pauseAutoReload();
    this.uiext.showCustomModal(null, create === true ? MPCreatePage : create === false ? MpJoinPage : MpOptionsPage, {
      view: {
        fullScreen: false,
        transparent: false,
        large: true,
        addToStack: false,
        frame: false
      },
      params: params
    }).then(async (res: IArenaReturnData) => {
      console.log("mp groups return data: ", res);
      this.resumeAutoReload();
      if (res) {
        switch (res.status) {
          case EGroupModalResult.ok:
            let defaultOptions: boolean = true;
            if (res.groupUpdated) {
              defaultOptions = false;
              this.loadGroups(null, true);
            }
            if (res.groupCreated) {
              defaultOptions = false;
              this.uiext.showAlertNoAction(Messages.msg.groupCreatedSelect.after.msg, Messages.msg.groupCreatedSelect.after.sub);
            }
            if (res.groupJoined) {
              await this.uiext.showRewardPopupQueue(Messages.msg.groupJoinedSelect.after.msg, Messages.msg.groupJoinedSelect.after.sub, null, false);
            }
            if (defaultOptions) {
              let returnData: IArenaReturnData = {
                status: null,
                group: res.group,
                closeMpView: this.closeMpView,
                role: res.role
              };
              returnData.status = EGroupModalResult.goToOptions;
              this.dismiss(returnData);
            }
            break;
          case EGroupModalResult.remove:
            this.selectedGroup = null;
            break;
          case EGroupModalResult.cancel:
            if (res.groupUpdated) {
              this.loadGroups(null, true);
            }
            if (res.groupCreated) {
              this.uiext.showAlertNoAction(Messages.msg.groupCreatedSelect.after.msg, Messages.msg.groupCreatedSelect.after.sub);
            }
            break;
          default:
            break;
        }
      }
    }).catch((err: Error) => {
      console.error(err);
      this.resumeAutoReload();
    });
  }

  selectIndexFromNavItem(item: INavBarItem) {
    let oldContext: number = this.mode;
    this.setContextTabs(item.value);
    if (this.mode !== oldContext) {
      // reload groups list
      this.pagination.page = 0;
      this.refresh(0, null, true);
    }
  }

  setContextTabs(context: number) {
    console.log("set context tabs: ", context);
    this.mode = context;
    this.selectedIndex = context;
    this.isLobbyContext = context === EGroupMode.public;
    if (this.isLobbyContext) {
      this.toggleAutoReload(true);
    } else {
      this.toggleAutoReload(false);
    }
  }


  async showChatModal() {
    // do not disconnect on chat window close
    this.mpManager.openChatWindow(false, true);
  }



  isMeetingPlaceDisabled() {
    return !(this.selectedMeetingPlace && this.inRange);
  }

  getMeetingPlaceSpec(mplace: ILeplaceTreasure) {
    this.selectedMeetingPlace = {
      id: mplace.id,
      name: mplace.place ? mplace.place.place.name : "",
      timestamp: null,
      photoUrl: mplace.photoUrl,
      players: []
    };
  }

  getStorySpecs(story: IStory) {
    this.selectedMeetingPlace = {
      id: -story.id,
      name: story.name,
      timestamp: null,
      photoUrl: story.photoUrl,
      players: []
    };
  }

  toggleConnectMeetingPlaceModeWrapper(withAuth: boolean) {
    if (!this.connectedMeetingPlace) {
      return this.connectMeetingPlaceModeWrapper(withAuth);
    } else {
      return this.disconnectMeetingPlaceMode(withAuth);
    }
  }

  checkCanExit() {
    return this.mpManager.canExit;
  }

  async connectMeetingPlaceModeWrapper(withAuth: boolean) {
    // console.log("try connecting");
    // await this.connectMeetingPlaceMode(withAuth);
    // console.log("reset connection");
    // await SleepUtils.sleep(1000);
    // this.disposeMP();
    // await SleepUtils.sleep(1000);
    // console.log("now connecting");
    await this.connectMeetingPlaceMode(withAuth);
  }

  /**
   * wrapper, prevents connecting to the previous group instead (quick hack)
   * @param withAuth 
   */
  async connectMeetingPlaceMode(withAuth: boolean) {
    if (withAuth) {
      this.authorizedMeetingPlaceMode = true;
    }
    this.connecting = true;
    this.mpManager.clearScope();
    await this.mpManager.loadAuthDataResolve();
    this.mpManager.setMeetingPlaceScope(this.selectedMeetingPlace);
    this.mpManager.switchMeetingPlaceMode();
    await this.mpManager.start(this.synchronized);
    await this.initServicesMeetingPlaceMode();
    this.connectedMeetingPlace = true;
  }

  disconnectMeetingPlaceMode(withAuth: boolean) {
    if (withAuth) {
      this.authorizedMeetingPlaceMode = false;
    }
    this.canChat = false;
    this.chatBtnBlink = false;

    this.connecting = false;
    this.disposeMP();
    this.mpDataService.clearGroupCache();
    this.connectedMeetingPlace = false;
    this.nearbyPlayers = [];
  }

  disposeMP() {
    this.mpManager.quitSession();
    this.stopWatchGroupStatus();
    this.stopWatchMPStateMachine();
    this.stopWatchGroupChatRx();
    this.stopWatchArenaEvent();
  }

  /**
    * check when a message arrives so that it can make the button blinking
    */
  watchGroupChatRx() {
    if (!this.subscription.chatRx) {
      this.subscription.chatRx = this.mpManager.watchChatWS().subscribe((event: number) => {
        if (event) {
          this.chatMessageReceived = true;
          this.chatBtnBlink = false;
          setTimeout(() => {
            this.chatBtnBlink = true;
          }, 1);
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  stopWatchGroupChatRx() {
    this.subscription.chatRx = ResourceManager.clearSub(this.subscription.chatRx);
  }

  stopWatchGroupStatus() {
    this.subscription.groupStatus = ResourceManager.clearSub(this.subscription.groupStatus);
    this.mpManager.clearGroupMemberStatus(this.selectedGroup);
  }

  stopWatchMPStateMachine() {
    this.subscription.mpStateMachine = ResourceManager.clearSub(this.subscription.mpStateMachine);
  }

  watchArenaEvent() {
    // connect to arena events e.g. disconnected member
    if (!this.subscription.arenaEvent) {
      this.subscription.arenaEvent = this.mpManager.watchArenaEvent().subscribe((event: number) => {
        if (event) {
          switch (event) {
            case EArenaEvent.requireUserAction:
              // already returned to arena
              break;
            case EArenaEvent.connectionProblem:
              if (!this.timeout.auxMessage) {
                this.infoTextAux = "<p>Connection problems</p><p>Check your data connection</p>";
                this.timeout.auxMessage = setTimeout(() => {
                  this.infoTextAux = null;
                  this.timeout.auxMessage = null;
                }, 5000);
              }
              break;
            case EArenaEvent.chatWindowClosed:
              // this event is received from the mp manager as it handles the chat ui
              this.chatMessageReceived = false;
              this.chatBtnBlink = false;
              break;
            default:
              break;
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  stopWatchArenaEvent() {
    this.subscription.arenaEvent = ResourceManager.clearSub(this.subscription.arenaEvent);
    this.infoTextAux = null;
  }

  initServicesMeetingPlaceMode(): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve) => {
      this.timeout.other = setTimeout(() => {
        this.watchMeetingPlaceStatus();
        this.watchGroupChatRx();
        this.canChat = true;
        resolve(true);
      }, 100);
    });
    return promise;
  }

  /**
   * subscribe to group status events
   * for display purpose
   * the group sync with dynamic data is done internally by the mp manager
   */
  watchMeetingPlaceStatus() {
    if (!this.subscription.groupStatus) {
      let obs = this.mpManager.getGroupStatusObservable();
      if (!obs) {
        return;
      }
      this.subscription.groupStatus = obs.subscribe((data: IMPGenericGroupStat) => {
        if (data && data.meetingPlace) {
          // let key: string = StringUtils.getPropToStringGenerator()((o: IMPStatusDB) => { o.playerId });
          // ArrayUtils.syncArrayInPlace(this.nearbyPlayers, data.players, key, false);
          this.connecting = false;
          this.nearbyPlayers = data.meetingPlace.players;
          this.statusUpdate = !this.statusUpdate;
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

}
