import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'plain-entry-header',
  templateUrl: './plain-entry-header.component.html',
  styleUrls: ['./plain-entry-header.component.scss'],
})
export class PlainEntryHeaderComponent implements OnInit, OnDestroy {

  @Input()
  icon: string;

  @Input()
  customIcon: boolean;

  @Input()
  photoUrl: string;

  @Input()
  name: string;

  @Input()
  withButton: boolean;

  @Input()
  center: boolean;

  withGraphic: boolean;

  @Output()
  action: EventEmitter<boolean> = new EventEmitter();

  isZoom: boolean = false;

  constructor(

  ) { }

  ngOnInit() {
    if (this.icon || this.photoUrl) {
      this.withGraphic = true;
    }
  }


  ngOnDestroy() {

  }

  onTapZoom(zoom: boolean) {
    console.log("on tap zoom: ", zoom);
    this.isZoom = zoom;
  }

  onTapSelect() {
    this.action.emit(true);
  }
}

