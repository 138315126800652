



import { Injectable } from '@angular/core';
import { ApiDef } from '../../../classes/app/api';
import { IGenericResponse } from '../../../classes/def/requests/general';
import { AuthRequestService, IApiFlatGenericRequest } from '../auth-request/auth-request';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { File, Entry, DirectoryEntry } from '@ionic-native/file/ngx';
import { GeneralCache } from '../../../classes/app/general-cache';
import { EOS } from '../../../classes/def/app/app';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HTTP } from '@ionic-native/http/ngx';
import { IAuthData } from 'src/app/classes/def/app/auth';
import { HTTPErrorHandlerService } from './error-handler';
import { AuthUtils } from '../auth-request/utils';

@Injectable({
    providedIn: 'root'
})
export class GenericDataService {
    serverUrl: string = ApiDef.mainServerURL;

    constructor(
        public authentication: AuthRequestService,
        public file: File,
        public http: HttpClient,
        public nativeHttp: HTTP,
        public errorHandler: HTTPErrorHandlerService,
        public transfer: FileTransfer
    ) {
        console.log("generic data service created");
    }


    /**
     * do a http request with JWT auth
     * return raw response
     * @param url 
     * @param data 
     */
    genericPostStandard(url: string, data: any, customApiUrl?: string): Promise<IGenericResponse> {
        let promise: Promise<IGenericResponse> = new Promise((resolve, reject) => {
            this.authentication.httpPostSecureUserId(url, data, customApiUrl).then((response: IGenericResponse) => {
                resolve(response);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * do a http request with JWT auth
     * return raw response
     * @param url 
     * @param data 
     */
    genericGetStandard(url: string, data: any, customApiUrl?: string): Promise<IGenericResponse> {
        let promise: Promise<IGenericResponse> = new Promise((resolve, reject) => {
            this.authentication.httpGetSecureUserId(url, data, customApiUrl).then((response: IGenericResponse) => {
                resolve(response);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * generic request 
     * return data
     * @param url 
     * @param data 
     * @param customApiUrl 
     */
    genericGetStandardWData(url: string, data: any, customApiUrl?: string): Promise<any> {
        let promise: Promise<any> = new Promise((resolve, reject) => {
            this.genericGetStandard(url, data, customApiUrl).then((response: IGenericResponse) => {
                resolve(response.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * generic request
     * return data
     * @param url 
     * @param data 
     * @param customApiUrl 
     */
    genericPostStandardWData(url: string, data: any, customApiUrl?: string): Promise<any> {
        let promise: Promise<any> = new Promise((resolve, reject) => {
            this.genericPostStandard(url, data, customApiUrl).then((response: IGenericResponse) => {
                resolve(response.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }




    /**
     * do a http request with JWT auth
     * check for message and reject if status is false with the specified message
     * @param url 
     * @param data 
     * @param strict 
     */
    genericPost(url: string, data: any, strict: boolean = true): Promise<IGenericResponse> {
        let promise: Promise<IGenericResponse> = new Promise((resolve, reject) => {
            this.authentication.httpPostSecureUserId(url, data).then((response: IGenericResponse) => {
                if (response) {
                    if (!strict) {
                        resolve(response);
                        return;
                    }
                    if (response.status) {
                        resolve(response);
                    } else {
                        reject(new Error(response.message));
                    }
                } else {
                    reject(new Error("no data"));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * do a http request with JWT auth
     * check for message and reject if status is false with the specified message
     * @param url 
     * @param data 
     * @param strict 
     */
    genericGet(url: string, data: any, strict: boolean = true): Promise<IGenericResponse> {
        let promise: Promise<IGenericResponse> = new Promise((resolve, reject) => {
            this.authentication.httpGetSecureUserId(url, data).then((response: IGenericResponse) => {
                if (response) {
                    if (!strict) {
                        resolve(response);
                        return;
                    }
                    if (response.status) {
                        resolve(response);
                    } else {
                        reject(new Error(response.message));
                    }
                } else {
                    reject(new Error("no data"));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    genericGetExt(url: string, data: any): Promise<any> {
        let promise: Promise<any> = new Promise((resolve, reject) => {
            let headers = AuthUtils.getHeaders();

            if (data) {
                headers.params = data;
            }

            this.http.get(url, headers).subscribe((response: any) => {
                resolve(response);
            }, (err: HttpErrorResponse) => {
                reject(new Error(this.errorHandler.handleHttpError(err)));
            });
        });
        return promise;
    }


    /**
     * generic get no auth
     * use with caution
     * e.g. only for GDPR popup before signup
     */
    genericGetNoAuth(url: string, data: any, strict: boolean = true): Promise<IGenericResponse> {
        let promise: Promise<IGenericResponse> = new Promise((resolve, reject) => {
            this.authentication.genericGetNoAuth(url, data).then((response: IGenericResponse) => {
                if (response) {
                    if (!strict) {
                        resolve(response);
                        return;
                    }
                    if (response.status) {
                        resolve(response);
                    } else {
                        reject(new Error(response.message));
                    }
                } else {
                    reject(new Error("no data"));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
    * generic get no auth
    * use with caution
    * e.g. only for GDPR popup before signup
    */
    genericGetNoAuthWData(url: string, data: any, strict: boolean = true): Promise<any> {
        let promise: Promise<any> = new Promise((resolve, reject) => {
            this.genericGetNoAuth(url, data, strict).then((response: IGenericResponse) => {
                resolve(response.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
    * generic post no auth
    * use with caution
    * e.g. only for GDPR popup before signup
    */
    genericPostNoAuth(url: string, data: any, strict: boolean = true): Promise<IGenericResponse> {
        let promise: Promise<IGenericResponse> = new Promise((resolve, reject) => {
            this.authentication.genericPostNoAuth(url, data).then((response: IGenericResponse) => {
                if (response) {
                    if (!strict) {
                        resolve(response);
                        return;
                    }
                    if (response.status) {
                        resolve(response);
                    } else {
                        reject(new Error(response.message));
                    }
                } else {
                    reject(new Error("no data"));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * generic post no auth
     * use with caution
     * e.g. only for GDPR popup before signup
     */
    genericPostNoAuthWData(url: string, data: any, strict: boolean = true): Promise<any> {
        let promise: Promise<any> = new Promise((resolve, reject) => {
            this.genericPostNoAuth(url, data, strict).then((response: IGenericResponse) => {
                resolve(response.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * directly upload a file given by the file path along with other params that should be read by the server
     * @param fileName 
     * @param filePath 
     * @param api 
     * @param params 
     */
    uploadFile(fileName: string, filePath: string, api: string, params: any, customApiUrl?: string) {
        let mimeType: string = "";
        switch (GeneralCache.os) {
            case EOS.android:
                mimeType += "audio/aac";
                break;
            case EOS.ios:
                mimeType += "audio/wav";
                break;
            default:
                break;
        }
        return this.uploadFileCore(fileName, null, filePath, mimeType, false, api, params, customApiUrl);
    }


    /**
    * directly upload a file given by the file path along with other params that should be read by the server
    * WARNING: does not work without  <preference name="AndroidPersistentFileLocation" value="Compatibility" />
    * HOWEVER: using this preference breaks image cache preloading (DO NOT USE at the moment)
    * @param fileName 
    * @param filePath 
    * @param api 
    * @param params 
    */
    uploadFileCore(fileName: string, fileKey: string, filePath: string, mimeType: string, _chunkedMode: boolean, api: string, params: any, customApiUrl?: string) {
        let promise = new Promise(async (resolve, reject) => {

            try {
                let authdata: IAuthData = await this.authentication.getAuthHeaders();
                const formData = new FormData();
                console.log("resolving file uri: ", filePath);
                const fileUri: Entry = await this.file.resolveLocalFilesystemUrl(filePath);
                console.log("resolved file uri: ", fileUri);
                let promiseDir: Promise<DirectoryEntry> = new Promise((resolve, reject) => {
                    fileUri.getParent(dir => {
                        resolve(dir);
                    }, (err) => {
                        reject(err);
                    });
                });
                let dir: DirectoryEntry = await promiseDir;
                console.log("get parent dir: ", dir);
                const fileEntry = await this.file.getFile(dir, fileUri.name, null);
                console.log("get file entry: ", fileEntry);
                const arrayBuffer = await this.file.readAsArrayBuffer(dir.nativeURL, fileEntry.name);
                console.log("loaded blob");
                console.log("params: ", params);
                formData.append("userId", "" + GeneralCache.userId);
                formData.append("storyId", "" + params.storyId);
                formData.append("storyLocationId", "" + params.storyLocationId);
                formData.append("fileType", mimeType);
                formData.append(fileKey, new Blob([arrayBuffer], { type: mimeType }), fileName);

                let options = {
                    headers: {
                        // 'Content-Type': 'multipart/form-data; boundary=' + formData["_boundary"]
                    },
                    params: {}
                };

                options.headers = Object.assign(options.headers, authdata.headers.headers);

                let endpoint: string = "";

                if (customApiUrl) {
                    endpoint = customApiUrl + api;
                } else {
                    endpoint = this.serverUrl + api;
                }

                this.http.post(endpoint, formData, options).subscribe((response: IGenericResponse) => {
                    resolve(response);
                }, (err: HttpErrorResponse) => {
                    reject(new Error(this.errorHandler.handleHttpError(err)));
                });

            } catch (err) {
                reject(err);
            }


            // const fileTransfer: FileTransferObject = this.transfer.create();
            // this.authentication.getAuthHeaders().then((res: IAuthData) => {
            //     if (!params) {
            //         params = {};
            //     }
            //     params.userId = res.userId;
            //     params.testerMode = AppSettings.testerMode;
            //     params.fileType = mimeType;

            //     let options: FileUploadOptions = {
            //         fileKey: fileKey != null ? fileKey : 'file', // optional
            //         fileName: fileName, // The file name to use when saving the file on the server, optional
            //         httpMethod: "POST",
            //         mimeType: mimeType,
            //         params: params,
            //         headers: res.headers.headers,
            //         chunkedMode: chunkedMode
            //     };

            //     let endpoint: string = null;

            //     if (customApiUrl) {
            //         endpoint = customApiUrl + api;
            //     } else {
            //         endpoint = this.serverUrl + api;
            //     }

            //     console.log("upload options: ", options);

            //     fileTransfer.upload(filePath, endpoint, options).then((data: FileUploadResult) => {
            //         if (data && data.responseCode === 200 && data.response) {
            //             let resp: IGenericResponse = JSON.parse(data.response);
            //             resolve(resp);
            //         } else {
            //             reject(new Error("No response from the server"));
            //         }
            //     }).catch((err: Error) => {
            //         reject(err);
            //     });
            // }).catch((err: Error) => {
            //     reject(err);
            // });
        });
        return promise;
    }
}
