import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'direction-arrow',
  templateUrl: './direction-arrow.component.html',
  styleUrls: ['./direction-arrow.component.scss'],
})
export class DirectionArrowComponent implements OnInit {
  private _destinationBearing = 0;
  private _compassHeading = 0;

  @Input() set destinationBearing(value: number) {
    this._destinationBearing = value;
    this.updateArrow();
  }

  @Input() set compassHeading(value: number) {
    this._compassHeading = value;
    this.updateArrow();
  }

  constructor() { }

  ngOnInit(): void {
  }

  private updateArrow() {
    // Adjust the arrow based on compass heading
    const adjustedBearing = this._destinationBearing - this._compassHeading;
    this.heading = adjustedBearing * Math.PI / 180; // Convert to radians for the SVG
  }
  // Angular template needs to use this.heading
  heading = 0; // This is in radians
}
