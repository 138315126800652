
export enum ESmartZoomState {
    init = -1,
    zoomInUser = 0,
    zoomOutFixed = 1,
    zoomInDestination = 2,
    zoomOutToFitDestination = 3,
    zoomOutToFitAuxPlaces = 4,

    zoomToFitTreasures = 5,
    zoomToFitUserAndTreasures = 6,

    zoomToFitPreloadStory = 7
}

export enum ESmartZoomTransitions {
    scanFixedPlace = 0,
    scanRandomPlace = 1,
    scanEventTreasures = 2,
    scanPreloadStory = 3
}
