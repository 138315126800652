import { IGeneralAppFlags } from "src/app/classes/app/general-cache";
import { IChatElement } from "src/app/classes/def/mp/chat"
import { EMPMessageCodes } from "src/app/classes/def/mp/protocol"
import { TimeUtils } from "src/app/classes/general/time"

export interface IMQTTStatusCache {
    userId: number,
    location: ICoords,
    mapCenter: ICoords,
    userMarker: ICoords,
    droneMarker: ICoords,
    inGame: boolean,
    ts: Date,
    appFlags: IGeneralAppFlags
}

export interface IMQTTStatusCacheElement {
    _status: boolean;
    _ts: Date;
    _data?: any;
    [key: string]: any;
}
export interface ICoords extends IMQTTStatusCacheElement {
    lat: number;
    lng: number;
}


export interface IMQTTLog {
    category: string,
    action: string,
    label: string,
    ts: Date
}

export interface IMQTTCommand {
    code: number,
    value: any,
    data: any
}

export interface IMQTTChatMessage {
    senderId: number,
    message: string,
    priority: boolean,
    ts: Date,
    ext?: boolean,
    username?: string
}

export enum EMQTTTopics {
    status = "lp/status/dist",
    console = "lp/console/dist",
    cmd = "lp/cmd/dist",
    resp = "lp/resp/dist"
}

export enum EMQTTStatusKeys {
    location = "location",
    mapCenter = "mapCenter",
    userMarker = "userMarker",
    droneMarker = "droneMarker",
    inGame = "inGame",
    gameContext = "gameContext",
    backgroundMode = "backgroundMode",
    chatOpen = "chatOpen",
    appFlags = "appFlags"
}

export interface IGameContextStatus {
    storyId: number,
    worldMap: boolean,
    previewMode: boolean
}

export enum EMQTTCommandCodes {
    activateTelemetry = 25,
    inboundChat = 100
}

export class TelemetryDef {
    static getChatMessageFromMqttMessage(message: IMQTTChatMessage) {
        if (!message) {
            return null;
        }
        let self: boolean = !message.ext;
        let timestampValue: number = message.ts ? message.ts.getTime() : null;
        let timestampString: string = timestampValue ? TimeUtils.formatDateTimeDisp(timestampValue, true, false) : "";
        let chatMsg: IChatElement = {
            user: message.username,
            message: message.message,
            timestamp: timestampString ? timestampString : "",
            timestampValue: timestampValue,
            self: self,
            type: EMPMessageCodes.chat,
            userId: message.senderId
        };
        return chatMsg;
    }

    static getMqttMessageFromChatMessage(chatMsg: IChatElement) {
        if (!chatMsg) {
            return null;
        }
        let message: IMQTTChatMessage = {
            senderId: chatMsg.userId,
            message: chatMsg.message,
            priority: false,
            ts: chatMsg.timestamp != null ? new Date(chatMsg.timestamp) : null
        };
        return message;
    }
}