import wordwrap from 'wordwrapjs';

export class StringUtils {

    /**
     * WARNING: not working in production !!!!!
     * use case: StringUtils.getPropToStringGenerator()((o: IPlaceExtContainer) => { o.googleId })
     * https://stackoverflow.com/questions/29600539/typescript-interface-property-to-string
     */
    static getPropToStringGenerator(): <T>(property: (object: T) => void) => string {
        const P = <T>(property: (object: T) => void) => {
            const chaine = property.toString();
            const arr = chaine.match(/[\s\S]*{[\s\S]*\.([^\.; ]*)[ ;\n]*}/);
            return arr[1];
        };
        return P;
    }

    static textFromHTML(text: string): string {
        if (!text) {
            return "";
        }
        var find = "<p>";
        var re = new RegExp(find, 'g');
        let desc: string = text.replace(re, "");
        var find = "</p>";
        var re = new RegExp(find, 'g');
        return desc.replace(re, "\n");
    }

    static textFromHTMLPlain(text: string): string {
        if (!text) {
            return "";
        }
        var find = "<p>";
        var re = new RegExp(find, 'g');
        let desc: string = text.replace(re, "");
        var find = "</p>";
        var re = new RegExp(find, 'g');
        desc = desc.replace(re, "\n");
        desc = desc.replace(/<[^>]*>?/gm, "");
        return desc;
    }

    static textToHTML(text: string): string {
        let htmlText: string = "";
        if (!text) {
            htmlText = "";
        } else {
            let pars: string[] = text.split("\n");
            // remove trailing empty lines
            let lastEmptyLineIndex: number = -1;

            for (let i = pars.length - 1; i >= 0; i--) {
                if (pars[i].length === 0) {
                    lastEmptyLineIndex = i;
                } else {
                    break;
                }
            }

            for (let i = 0; i < pars.length; i++) {
                if (lastEmptyLineIndex === -1 || i < lastEmptyLineIndex) {
                    let par = pars[i];
                    let txt = par.replace("\n", "");
                    if (txt[0] === "<") {
                        // already HTML - skip format
                        htmlText += txt;
                    } else {
                        // check HTML on the same line - separate from current paragraph
                        let index = txt.indexOf("<");
                        if (index !== -1) {
                            // HTML detected on the same line
                            let txtContent = txt.slice(0, index);
                            htmlText += "<p>" + txtContent + "</p>";
                            let lastIndexOfHtmlPart = txt.lastIndexOf(">");
                            let htmlContent = txt.slice(index, lastIndexOfHtmlPart + 1);
                            htmlText += htmlContent;
                            if (lastIndexOfHtmlPart < txt.length) {
                                htmlText += txt.slice(lastIndexOfHtmlPart + 1, txt.length - 1);
                            }
                        } else {
                            // no HTML on the same line
                            htmlText += "<p>" + txt + "</p>";
                        }
                    }
                } else {
                    break;
                }
            }
        }
        return htmlText;
    }


    /**
    * trim name and add ..
    * @param name 
    * @param maxn 
    */
    static trimName(name: string, maxn: number) {
        let trim: string = name;
        if (!trim) {
            return trim;
        }
        if ((maxn - 2) <= 0) {
            // cannot trim to less than string length
            return trim;
        }
        if (trim.length > maxn) {
            trim = trim.slice(0, maxn - 2) + "..";
        }
        return trim;
    }

    static trimNameMultilineHTML(name: string, maxn: number) {
        let trim: string = name;
        if (!trim) {
            return trim;
        }
        let lines: string[] = [];

        lines = wordwrap.lines(name, { width: maxn })
        trim = "";
        for (let line of lines) {
            trim += "<p>" + line + "</p>";
        }
        return trim;
    }

    /**
    * trim name and add ..
    * @param name 
    * @param maxn 
    */
    static trimNameExact(name: string, maxn: number) {
        let trim = name;
        if (!trim) {
            return trim;
        }
        if ((maxn - 2) <= 0) {
            // cannot trim to less than string length
            return trim;
        }
        if (trim.length > maxn) {
            trim = trim.slice(0, maxn) + "..";
        }
        return trim;
    }


    /**
     * trim to first p
     * and max number of chars
     * and min specified number of ps
     * 
     * rule:
     * text length < maxn
     * p count > minpar && p count < minpar + 1
     * @param html 
     * @param maxn 
     * @param minpar 
     */
    static trimHtmlPar(html: string, maxn: number, minpar: number) {
        let desc = html;
        if (!desc) {
            return desc;
        }

        let parser = new DOMParser();
        let htmlDoc = parser.parseFromString(desc, 'text/html');
        let rawPars = htmlDoc.getElementsByTagName("p");

        if (rawPars && rawPars.length > 0) {
            if (minpar) {
                desc = "";
                let ncount = 0;
                for (let i = 0; i < rawPars.length; i++) {
                    let crtPar = "" + rawPars[i].innerHTML;
                    let crtLen = crtPar.length;
                    ncount += crtLen;
                    let addDots = false;
                    let stop = false;

                    // check p count exceeded
                    if (i >= minpar) {
                        addDots = true;
                        stop = true;
                    }

                    // check text length exceeded
                    if (ncount >= maxn) {
                        addDots = false;
                        /*  crtPar = trimName(crtPar, ncount-maxn-1); */
                        // check the exceed length and trim to fit
                        let trimSize = crtLen - (ncount - maxn);

                        crtPar = StringUtils.trimNameExact(crtPar, trimSize);
                        stop = true;
                    }

                    desc += "<p>" + crtPar;

                    if (addDots) {
                        desc += "..</p>";

                    } else {
                        desc += "</p>";
                    }

                    if (stop) {
                        break;
                    }
                }
            } else {
                if (rawPars.length === 1) {
                    desc = "<p>" + rawPars[0].innerHTML + "</p>";
                } else {
                    desc = "<p>" + rawPars[0].innerHTML + "..</p>";
                }
            }
        } else {
            desc = StringUtils.trimNameExact(desc, maxn);
        }
        return desc;
    }

    static getParCount(html: string) {
        let desc: string = html;
        if (!desc) {
            return desc;
        }

        let parser = new DOMParser();
        let htmlDoc = parser.parseFromString(desc, 'text/html');
        let rawPars = htmlDoc.getElementsByTagName("p");

        let npar: number = rawPars ? rawPars.length : 0;
        return npar;
    }

    static generateRandomStringCode(length: number, uppercase: boolean, includeDigits: boolean) {
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        if (!uppercase) {
            characters += 'abcdefghijklmnopqrstuvwxyz';
        }
        if (includeDigits) {
            characters += '0123456789';
        }
        let generateString = (length: number) => {
            let result: string = '';
            let charactersLength: number = characters.length;
            for (let i = 0; i < length; i++) {
                result += characters.charAt(Math.floor(Math.random() * charactersLength));
            }
            return result;
        }
        let str: string = generateString(length);
        return str;
    }

}