import { Component, NgZone, OnDestroy, OnInit, ViewEncapsulation, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { IStoryExtended, IStoryExtendedGroup, IStoriesPageResponse, IStory, IMasterLockResponse, IStoryResponse, EStoryUnlockTokenType } from 'src/app/classes/def/core/story';
import { IStoryCategory, ECategoryCodes, ECategoryModes } from 'src/app/classes/def/core/category';
import { IPaginationContainer } from 'src/app/classes/def/views/pagination';
import { IStoryListNavParams } from 'src/app/classes/def/nav-params/story';
import { Platform, ModalController } from '@ionic/angular';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IModalEvent, EModalEmitter } from 'src/app/classes/def/app/modal-events';
import { IPopoverActions, IPopoverInputs } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { IDescriptionFrameParams, EDescriptionViewStyle } from 'src/app/modals/generic/modals/description-frame/description-frame.component';
import { ITextInputParams, ITextInputResult, TextInputViewComponent } from 'src/app/modals/generic/modals/text-input/text-input.component';
import { ICheckPricePrivateStory } from 'src/app/classes/def/requests/check-price';
import { EFeatureCode, IFeatureDef } from 'src/app/classes/def/app/app';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { IGenericResponse } from 'src/app/classes/def/requests/general';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { StoryDataService } from 'src/app/services/data/story';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { BusinessDataService } from 'src/app/services/data/business';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { MiscDataService } from 'src/app/services/data/misc';
import { CanvasLoaderService } from 'src/app/services/utils/canvas-loader';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { PremiumDataService } from 'src/app/services/data/premium';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { PaginationHandlerService } from 'src/app/services/general/ui/pagination-handler';
import { ICustomCodeInput, IScanQRResultString, QRService } from 'src/app/services/general/apis/qr';
import { ERouteDef } from 'src/app/app-utils';
import { StoryHomePage } from '../story-home/story-home.page';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { EAppIconsStandard, EAppIcons } from 'src/app/classes/def/app/icons';
import { WorldDataService } from 'src/app/services/data/world';
import { IGetCountriesWithDetailsAndNearby, IDBModelCity, IDBModelCountry, ECityCodes, ECountryCodes } from 'src/app/classes/def/world/world';
import { LocationSelectorViewComponent, ILocationSelectorParams } from 'src/app/modals/app/modals/location-selector/location-selector.component';
import { StoryUtils, IStoryProgress } from 'src/app/classes/utils/story-utils';
import { IAPUtils } from 'src/app/services/apis/iap-utils';
import { IListSelectorItem } from 'src/app/classes/def/dynamic/list';
import { IGenericEntry } from 'src/app/classes/def/app/entry';
import { StorageFlagsService } from 'src/app/services/general/data/storage-flags';
import { ELocalAppDataKeys, IStoryFlags } from 'src/app/classes/def/app/storage-flags';
import { EModalEvents, Events } from 'src/app/services/utils/events';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { PromiseUtils } from 'src/app/services/utils/promise-utils';
import { SupportModalsService } from 'src/app/services/app/modules/minor/support-modals';
import { ArrayUtils } from 'src/app/services/utils/array-utils';
import { IAPCoreService } from 'src/app/services/apis/iap-rc';
import { EVideoCodes } from 'src/app/classes/def/media/videos';
import { YouTubeService } from 'src/app/services/general/apis/youtube';
import { StorageOpsService } from 'src/app/services/general/data/storage-ops';
import { ILatLng } from 'src/app/classes/def/map/coords';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-story-list',
  templateUrl: './story-list.page.html',
  styleUrls: ['./story-list.page.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0
      })),
      state('active', style({
        // transform: 'translateY(0)',
        opacity: 1
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class StoryListPage implements OnInit, OnDestroy {
  // @ViewChild(IonContent, { static: false }) scrollContent: IonContent;
  @ViewChild('scrollContent', { static: false }) scrollContent: ElementRef;
  public title: string = "Storyline";
  public storiesWithProgress: IStoryExtended[];
  public groupedStoriesWithProgress: IStoryExtendedGroup[];

  category: IStoryCategory;
  info: string = "";
  useStoryColors: boolean = false;
  queryText = '';
  coords: ILatLng = null;
  loaded: boolean = false;

  pagination: IPaginationContainer = {
    page: 0,
    pages: 1,
    pageDisp: 1,
    hideLeft: true,
    hideRight: false
  };

  showState: string = "inactive";
  timeout = {
    animate: null,
    tutorial: null
  };
  params: IStoryListNavParams;
  emptyResult: boolean = false;
  reload: boolean = true;
  theme: string = "theme-light theme-light-bg";
  animate: boolean = true;
  np: INavParams = null;
  vs: IViewSpecs;
  isModal: boolean = false;
  localStories: boolean = false;
  showRegionType: boolean = true;
  includeGlobal: boolean = true;

  localData: IGetCountriesWithDetailsAndNearby = null;
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  selectedDispDefault: string = " > select a city >";
  selectedDisp: string;
  selectedCity: IDBModelCity;
  selectedCityId: number;

  globalCategory: boolean = false;
  reloadItems: boolean = false;

  categories: IStoryCategory[] = [];
  categoriesDisp: IStoryCategory[] = [];
  categoryCode: number = 0;
  categoryMode: number = ECategoryModes.global;
  includeBeta: boolean = false;
  hideDevStories: boolean = false;

  localFlags: IStoryFlags = {} as any;
  categoryName: string = "";
  currentActivationToken: string = null;
  enableBackButton: boolean = true;
  tutorialSeen: boolean = false; // blink select a city button continuously
  openedCitySelector: boolean = false;
  qparams: Params;

  constructor(
    public storyDataProvider: StoryDataService,
    public settingsProvider: SettingsManagerService,
    public router: Router,
    public zone: NgZone,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public locationManager: LocationManagerService,
    public qrSecurity: QRService,
    public worldService: WorldDataService,
    public backButton: BackButtonService,
    public analytics: AnalyticsService,
    public businessDataProvider: BusinessDataService,
    public resourcesProvider: ResourcesCoreDataService,
    public miscProvider: MiscDataService,
    public canvasLoader: CanvasLoaderService,
    public modalCtrl: ModalController,
    public events: Events,
    public popupFeatures: PopupFeaturesService,
    public supportModals: SupportModalsService,
    public premiumProvider: PremiumDataService,
    public tutorials: TutorialsService,
    public paginationHandler: PaginationHandlerService,
    public route: ActivatedRoute,
    public nps: NavParamsService,
    public iapService: IAPCoreService,
    public storageFlags: StorageFlagsService,
    public ytb: YouTubeService,
    public storageOps: StorageOpsService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  scrollTop() {
    this.scrollContent.nativeElement.scrollTop = 0;
  }

  ngOnInit() {
    this.analytics.trackView("story-list");

    this.paginationHandler.init(this.pagination);
    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        // this.theme = Constants.theme["aubergine"].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    this.title = "Storyline";
    this.storiesWithProgress = [];
    console.log("activated route: ", this.route.snapshot.queryParams);

    this.nps.checkParamsLoaded().then(async () => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.storyList, null, this.np);
      console.log("nav params: ", npInfo.params);

      this.qparams = this.route.snapshot.queryParams;
      console.log("activated route: ", this.qparams);

      let npar = npInfo.params;
      let hasParams: boolean = npInfo.hasParams;
      console.log(npar);

      // this.timeout.tutorial = setTimeout(() => {
      //   this.checkShowTutorialVideo();
      // }, 1000);

      if (hasParams) {
        let np: INavParams = npar;
        console.log(np);
        this.params = np.params;
        this.vs = np.view;
        this.isModal = this.vs ? this.vs.isModal : false;
        this.category = this.params.category;
        this.localStories = this.params.localStories;
        this.includeGlobal = this.params.includeGlobal;
        this.reload = this.params.reload;
        this.selectedCityId = this.params.selectedCityId;
        this.checkShowContextCity();
        if (this.category != null) {
          this.categoryName = this.category.name;
          this.globalCategory = this.category.baseCode == ECategoryCodes.global;
          this.categoryCode = this.category.baseCode;
          this.categoryMode = this.category.mode;
        } else {
          this.globalCategory = true;
          this.categoryCode = ECategoryCodes.global;
          this.categoryMode = ECategoryModes.global;
        }
      } else {
        this.localStories = true;
        this.includeGlobal = true;
        this.reload = true;
        this.selectedCityId = null;

        this.globalCategory = true;
        this.categoryCode = ECategoryCodes.global;
        this.categoryMode = ECategoryModes.global;
      }

      if (this.qparams && (Object.keys(this.qparams).length > 0)) {
        try {
          if (this.qparams.categoryCode) {
            this.categoryCode = Number.parseInt(this.qparams.categoryCode);
            if (isNaN(this.categoryCode)) {
              this.categoryCode = ECategoryCodes.global;
            }
          }
          if (this.qparams.city) {
            this.selectedCityId = Number.parseInt(this.qparams.city);
          }
        } catch (err) {
          console.error(err);
        }
      }

      console.log("selected category: ", this.categoryCode);

      if (!this.reload || this.isModal) {
        this.animate = false;
        setTimeout(() => {
          this.showState = 'active';
        }, 250);
      }

      try {
        let coords: ILatLng = await this.locationManager.getCurrentLocationWrapper(true, false, true);
        console.log("story list current location: ", coords);
        this.coords = coords;
      } catch (err) {
        console.error(err);
        let res = await this.supportModals.showLocationErrorModal(err);
        if (res) {
          this.coords = await this.locationManager.getCurrentLocationWrapper(true, true, true);
        }
      }

      try {
        this.localFlags = await this.storageFlags.loadFlagsGroup(ELocalAppDataKeys.storyFlags, null, true) as IStoryFlags;
        this.includeBeta = this.localFlags.includeBeta;
      } catch (err) {
        console.error(err);
      }

      if (SettingsManagerService.settings.app.settings.hideDevStories.value) {
        this.hideDevStories = true;
      }

      this.refresh(0, this.reload, null, null, null, false).then(() => {

      }).catch((err: Error) => {
        this.analytics.dispatchError(err, "story-list");
        this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
      });
    });

    this.webView.ready().then(() => {
      this.backButton.replace(() => {
        this.back(true);
      });
    }).catch((err: Error) => {
      console.error(err);
    });

    this.events.subscribe(EModalEvents.dismiss, (data: IModalEvent) => {
      // // user and time are the same arguments passed in `events.publish(user, time)`
      // console.log('Welcome', user, 'at', time);
      console.log("dismiss event");
      if (data.emitter === EModalEmitter.storyDetailGoToMap) {
        this.modalDismiss(false);
      }
    });
  }

  async checkShowTutorialVideo() {
    try {
      let tutorialSeen: boolean = await this.storageOps.checkStorageFlagResolve({
        flag: ELocalAppDataKeys.tutorialVideoSeen,
        value: false
      }, true);
      if (!tutorialSeen) {
        this.tutorialSeen = false;
        this.showTutorialVideo();
        this.storageOps.setStorageFlagNoAction({
          flag: ELocalAppDataKeys.tutorialVideoSeen,
          value: true
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  showTutorialVideo() {
    this.ytb.openWithCode(EVideoCodes.storyline);
  }

  checkShowContextCity() {
    console.log("check show context city");
    if (this.selectedCityId != null) {
      this.showRegionType = false;
    } else {
      this.showRegionType = true;
    }
  }

  ngOnDestroy() {
    this.clearWatch();
    console.log("destroy");
    this.events.unsubscribe(EModalEvents.dismiss);
    if (this.localFlags.includeBeta !== this.includeBeta) {
      this.localFlags.includeBeta = this.includeBeta;
      this.storageFlags.updateFlagsGroup(ELocalAppDataKeys.storyFlags, this.localFlags);
      this.storageFlags.saveFlagsGroup(ELocalAppDataKeys.storyFlags).then(() => {

      });
    }
  }

  back(animate: boolean) {
    // this.showState = "inactive";
    // this.timeout = setTimeout(() => {
    //   this.navCtrl.setRoot(StoryCategoryListPage).then(() => {
    //   }).catch((err: Error) => {
    //     console.error(err);
    //   });
    // }, Constants.ANIMATION_TIME);
    if (this.params) {
      this.params.reload = false;
    }

    if (this.isModal) {
      let modalEvent: IModalEvent = {
        emitter: EModalEmitter.storyList
      };
      this.events.publish(EModalEvents.dismiss, modalEvent);
      this.modalDismiss(animate);
    } else {
      let navParams: INavParams = {
        view: null,
        params: null
      };

      this.nps.set(ENavParamsResources.home, navParams);

      this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  modalDismiss(_animate: boolean) {
    setTimeout(() => {
      this.modalCtrl.dismiss().then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  scanBarcodeOnClick() {
    this.qrSecurity.scanQRResultString(true, "Find story").then((token: string) => {
      this.businessDataProvider.subscribeToPrivateStoryProvider(token).then(() => {
        this.uiext.showAlert(Messages.msg.subscribeToPrivateStory.after.msg, Messages.msg.subscribeToPrivateStory.after.sub, 1, null).then(() => {
          this.doRefresh(0, false);
        }).catch((err: Error) => {
          console.error(err);
        });
      }).catch((err: Error) => {
        console.error(err);
        this.uiext.showAlertNoAction(Messages.msg.registerClientError.after.msg, ErrorMessage.parse(err, Messages.msg.registerClientError.after.sub));
      });
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "story-list");
    });
  }

  doRefresh(dir: number, forceReloadIAP: boolean) {
    this.refresh(dir, true, null, null, null, forceReloadIAP).then(() => {
      this.scrollTop();
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "story-list");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }


  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      tutorial: {
        name: "Tutorial",
        code: 1,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      },
      reload: {
        name: "Reload",
        code: 0,
        icon: EAppIconsStandard.refresh,
        enabled: true
      },
      reloadIAP: {
        name: "Reload Store",
        code: 10,
        icon: EAppIconsStandard.refresh,
        enabled: true
      },
      // findByQR: {
      //   name: "Enter code",
      //   code: 5,
      //   icon: EAppIcons.qr,
      //   customIcon: true,
      //   enabled: true
      // },
      // search: {
      //   name: "Search",
      //   code: 2,
      //   icon: EAppIconsStandard.search,
      //   enabled: true
      // },
      selectCategoroy: {
        name: "Filter category",
        code: 6,
        icon: EAppIconsStandard.stories,
        customIcon: false,
        enabled: true
      },
      // selectCity: {
      //   name: "Select city",
      //   code: 4,
      //   icon: EAppIcons.world,
      //   customIcon: true,
      //   enabled: this.localStories
      // },
      enableBeta: {
        name: !this.includeBeta ? "Show unlisted" : "Hide unlisted",
        code: 7,
        icon: EAppIconsStandard.stories,
        customIcon: false,
        enabled: true
      },
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: true,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((code: number) => {
      switch (code) {
        case 0:
          this.doRefresh(0, false);
          break;
        case 10:
          this.doRefresh(0, true);
          break;
        case 1:
          // this.onHelpClick();
          this.showTutorialVideo();
          break;
        case 2:
          this.searchByName();
          break;
        case 3:
          this.storyRequest();
          break;
        case 4:
          this.openLocationSelector();
          break;
        case 5:
          this.searchByToken();
          break;
        case 6:
          this.openCategorySelector();
          break;
        case 7:
          this.includeBeta = !this.includeBeta;
          this.doRefresh(0, false);
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  onHelpClick() {
    let videoString: string = null;
    let videoCode: number = (this.category.video && this.category.videoCode != null) ? this.category.videoCode : null;

    let params: IDescriptionFrameParams = {
      title: "Storyline Tutorial",
      description: null,
      mode: EDescriptionViewStyle.plain,
      photoUrl: null,
      loaderCode: ETutorialEntries.storiesTutorial
    };

    videoCode = null;
    params = this.tutorials.checkVideoCode(params, videoCode, videoString);
    this.tutorials.showTutorialNoAction(null, null, null, params, true);
  }



  /**
   * request private story
   * unused/to be updated
   */
  storyRequest() {
    let params: ITextInputParams = {
      description: "<p></p>",
      title: "Story request",
      text: null,
      focused: false,
      allowEmpty: false
    };

    this.uiext.showCustomModal(null, TextInputViewComponent, {
      view: {
        fullScreen: false,
        transparent: false,
        large: false,
        addToStack: true,
        frame: false
      },
      params: params
    }).then((description: ITextInputResult) => {
      if (description) {
        let request: ICheckPricePrivateStory = {
          categoryCode: this.category.code,
          description: description.text
        };

        // check pricing
        this.premiumProvider.checkFeaturePrice(EFeatureCode.privateStories, request, false).then((price: IFeatureDef) => {
          this.uiext.showAlert(Messages.msg.info.after.msg, price.message, 2, null, true).then((res: number) => {
            switch (res) {
              case EAlertButtonCodes.ok:
                // confirmed, submit request
                this.premiumProvider.requestPremiumFeature(EFeatureCode.privateStories, request).then((resp: IGenericResponse) => {
                  let serverMessage: string = resp.data.message;
                  this.uiext.showAlertNoAction(Messages.msg.success.after.msg, serverMessage);
                }).catch((err: Error) => {
                  this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
                  this.analytics.dispatchError(err, "story-list");
                });
                break;
              case EAlertButtonCodes.cancel:

                break;
            }
          }).catch((err: Error) => {
            this.analytics.dispatchError(err, "story-list");
          });
        }).catch((err: Error) => {
          this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
          this.analytics.dispatchError(err, "story-list");
        });
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * reload stories from the server
   * go to next/prev page
   * @param dir
   * @param reload
   * @param searchName
   */
  refresh(dir: number, reload: boolean, searchName: string, token: string, tokenType: number, forceReloadIAP: boolean) {
    // refresh stories
    this.paginationHandler.setPaginationPage(dir);
    return this.refreshLoadPage(this.pagination.page, reload, searchName, token, tokenType, forceReloadIAP);
  }


  addGlobal() {
    let globalCategory: IStoryCategory = this.categories.find(c => c.baseCode === ECategoryCodes.global);
    let photoUrl: string = globalCategory ? globalCategory.photoUrl : null;
    let globalCity: IDBModelCity = {
      id: ECityCodes.global,
      name: "Global",
      storyCount: null,
      photoUrl: photoUrl
    };
    let scanQRCity: IDBModelCity = {
      id: ECityCodes.scanQR,
      name: "Unlock Code",
      storyCount: null,
      photoUrl: photoUrl
    };
    let global: IDBModelCountry = {
      id: ECountryCodes.global,
      auxName: "Global",
      name: "Global",
      photoUrl: photoUrl,
      cities: [
        globalCity,
        scanQRCity
      ]
    };
    this.localData.global = global;
  }

  /**
   * refresh load page using specified page number
   * @param page 
   * @param reload 
   * @param searchName 
   */
  refreshLoadPage(page: number, reload: boolean, searchName: string, token: string, tokenType: number, forceReloadIAP: boolean) {
    let promise = new Promise(async (resolve, reject) => {
      this.loaded = false;
      this.emptyResult = false;
      let storyData: IStoriesPageResponse = null;

      try {
        if (this.categories.length === 0) {
          await this.loadCategories(reload, this.categoryCode);
        }
        let categoryCode = this.category ? this.category.code : null;
        if (this.localStories) {
          // load cities
          this.localData = await this.worldService.getCountriesWithDetailsAndNearby(this.coords ? this.coords.lat : null, this.coords ? this.coords.lng : null, categoryCode, reload);
          // this.addGlobal();

          // select nearby (null) / selected city
          if (this.includeGlobal && (this.selectedCityId == null)) {
            this.selectGlobal();
          } else {
            this.dispSelectedCityById(this.selectedCityId);
          }
        }

        this.selectedCityId = this.selectedCity ? this.selectedCity.id : this.selectedCityId;

        if (reload) {
          if (token != null) {
            storyData = await this.storyDataProvider.getAllStoriesFromServerSearchByToken(this.coords, token, tokenType, this.includeBeta, this.hideDevStories);
          } else if (searchName != null) {
            storyData = await this.storyDataProvider.getAllStoriesFromServerSearchByName(this.coords, categoryCode, null, this.selectedCityId, searchName, this.includeBeta, this.hideDevStories);
          } else {
            storyData = await this.storyDataProvider.getAllStoriesFromServer(this.coords, categoryCode, null, this.selectedCityId, this.includeBeta, page, this.hideDevStories);
          }
        } else {
          storyData = await this.storyDataProvider.getCurrentStoryList(this.coords, categoryCode, null, this.selectedCityId, this.includeBeta, page, this.hideDevStories);
        }

        let stories: IStory[] = [...storyData.stories];

        this.clearCachedOpenStoryFlags(stories);

        if (categoryCode == null && this.selectedCityId == null && token == null && searchName == null) {
          await this.checkLoadCachedOpenStory(stories);
        }

        this.pagination.page = storyData.page;
        this.pagination.pages = storyData.pages;
        this.showStories(stories, reload, forceReloadIAP);
        this.loaded = true;
        this.paginationHandler.setPaginationControls();

        if (this.animate) {
          setTimeout(() => {
            this.showState = 'active';
          }, 250);
        }
        resolve(true);
      } catch (err) {
        console.error(err);
        this.loaded = true;
        reject(err);
      }
    });
    return promise;
  }

  clearCachedOpenStoryFlags(stories: IStory[]) {
    for (let story of stories) {
      story.resume = false;
      story.localCache = null;
    }
  }

  checkLoadCachedOpenStory(stories: IStory[]) {
    return new Promise((resolve) => {
      this.storyDataProvider.checkResumeStoryFromCache(this.coords).then((sd: IStoryResponse) => {
        if (sd != null) {
          let sp: IStoryProgress = StoryUtils.getStoryProgress(sd.story);
          if (sp.finished) {
            resolve(false);
            return;
          }
          sd.story.resume = true;
          let sdFoundIndex: number = stories.findIndex(s => s.id === sd.story.id);
          if (sdFoundIndex !== -1) {
            let sdFound = stories[sdFoundIndex];
            sdFound.resume = true;
            sdFound.localCache = sd.story.localCache;
            // move to top of the list
            ArrayUtils.arrayMove(stories, sdFoundIndex, 0);
          } else {
            // add to the top of the list
            stories.unshift(sd.story);
          }
          resolve(true);
        } else {
          resolve(false);
        }
      }).catch((err: Error) => {
        console.error(err);
        resolve(false);
      });
    });
  }

  refreshLoadPageNoAction(page: number, reload: boolean) {
    this.refreshLoadPage(page, reload, null, null, null, false).then(() => {

    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "story-list");
    });
  }

  /**
   * search by name in the leaderboard and retrieve the containing page
   */
  searchByName() {
    let searchName: string = "";
    let popoverInputs: IPopoverInputs = {
      name: {
        // the name is the actual tag here
        name: "name",
        value: searchName,
        placeholder: "story name",
        enabled: true
      }
    };
    let popoverActions: IPopoverActions = {
      cancel: {
        name: "Cancel",
        code: 0,
        enabled: true
      },
      ok: {
        name: "Ok",
        code: 1,
        enabled: true
      }
    };

    let title: string = "Find Story";
    if (this.selectedCity) {
      title += " @" + this.selectedCity.name;
    }

    this.uiext.showCustomAlertInput(title, null, popoverInputs, popoverActions).then((res: any) => {
      let code = res.code;
      let data = res.data;
      // console.log(data);
      switch (code) {
        case 0:
          break;
        case 1:
          searchName = data.name;
          // console.log(searchName);
          this.refresh(0, true, searchName, null, null, false).then(() => {

          }).catch((err) => {
            this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
          });
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  /**
  * search by QR token
  */
  searchByToken() {
    let customCodeInput: ICustomCodeInput = {
      title: "Find your booking",
      inputLabel: "booking reference number",
      label: "<use booking reference>",
      notes: "<p>Examples:</p><p>Standard unlock code: ######</p><p>Viator/Tripadvisor: VIA-########</p><p>GetYourGuide: GYG#########</p>"
    };
    this.qrSecurity.scanQRResultExtra(true, "Find story", customCodeInput).then((token: IScanQRResultString) => {
      this.refresh(0, true, null, token.code, token.isCustom ? EStoryUnlockTokenType.booking : EStoryUnlockTokenType.leplace, false).then(() => {
        this.currentActivationToken = token.code;
        this.selectFiltered();
      }).catch((err) => {
        PromiseUtils.wrapNoAction(this.uiext.showRewardPopupQueue(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub), null, false, 2000), true);
      });
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "story-list");
    });
  }


  public showStories(stories: IStory[], reload: boolean, forceReloadIAP: boolean): void {
    console.log("load stories: ", stories);
    if (stories && stories.length > 0) {
      this.storiesWithProgress = [];
      this.groupedStoriesWithProgress = [];

      let storiesFound: IStory[] = stories.filter(story => story.found);
      if (storiesFound && storiesFound.length > 0) {
        stories = storiesFound;
      }

      this.loadIapDetails(stories, reload, false, forceReloadIAP);

      for (let story of stories) {
        let levels: boolean[] = [];
        if (!story.islocal) {
          for (let i = 1; i <= 5; i++) {
            if (i <= story.level) {
              levels.push(true);
            } else {
              levels.push(false);
            }
          }
        }
        let storyWithProgress: IStoryExtended = {
          story: story,
          storyProgress: this.getStoryProgress(story),
          // photoUrl: this.getStoryPhotoUrl(story)
          photoUrl: null,
          color: null,
          // color: this.useStoryColors ? GetDefaults.addColorAlpha(GetDefaults.getColorBase(story.categoryCode), 0.2) : null,
          levels: levels,
          hide: false,
          show: true,
          large: false,
          modeIcon: null,
          modeText: null
        };

        if (story.regionType != null) {
          storyWithProgress.large = true;
        }

        storyWithProgress.modeIcon = StoryUtils.getModeIcon(story);
        // storyWithProgress.modeText = StoryUtils.getModeText(story);

        // this.canvasLoader.getCanvasURL(storyWithProgress.story.photoUrl).then((canvasUrl: string) => {
        //   storyWithProgress.canvasUrl = canvasUrl;
        //   storyWithProgress.canvasLoaded = true;
        // }).catch((err: Error) => {
        //   console.error(err);
        // });

        this.storiesWithProgress.push(storyWithProgress);

      }

      console.log("stories with progress: ", this.storiesWithProgress);

      let storyGroup: IStoryExtendedGroup = {
        stories: this.storiesWithProgress,
        show: true,
        hide: false,
        categoryCode: this.category?.code,
        categoryName: this.category?.name
      };
      this.groupedStoriesWithProgress.push(storyGroup);
    } else {
      this.storiesWithProgress = [];
      this.groupedStoriesWithProgress = [];
      this.emptyResult = true;
    }
    this.info = "showing " + stories.length + "/" + stories.length + " stories";

  }

  clearWatch() {
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
  }


  /**
  * load iap details for current products
  * @param reload 
  */
  loadIapDetails(stories: IStory[], reload: boolean, showLoading: boolean, forceReloadIAP: boolean) {
    // this.loaded = false;

    this.loaded = true;
    showLoading = false; // override, show loading for each item instead
    // check products loading required
    let promiseCheckLoading: Promise<boolean> = new Promise(async (resolve) => {
      let check = IAPUtils.checkItemDataMulti(stories, reload, false);
      if (!showLoading) {
        resolve(false);
      } else {
        if (check.checkItems.length > 0) {
          await this.uiext.showLoadingV2Queue("Loading products..");
          resolve(true);
        } else {
          resolve(false);
        }
      }
    });

    promiseCheckLoading.then((loading: boolean) => {
      this.iapService.retrieveProductList(stories, reload, false, forceReloadIAP).then(async () => {
        console.log("iap products loaded: ", stories);
        this.reloadItems = !this.reloadItems;
        if (loading) {
          await this.uiext.dismissLoadingV2();
        }
        this.loaded = true;
      }).catch(async (err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "story-list");
        if (loading) {
          await this.uiext.dismissLoadingV2();
        }
        this.loaded = true;
      });
    });
  }


  public getStoryProgress(story: IStory): number {
    if (!story.locs) {
      return null;
    }

    let sp: IStoryProgress = StoryUtils.getStoryProgress(story);
    let storyProgress: number = sp.progress;
    if (!this.showRegionType) {
      // show region type only in world mode
      story.regionType = null;
    }
    return storyProgress;
  }


  /**
   * go to story detail on story select (tapped)
   * @param story 
   */
  goToStoryDetail(story: IStory): void {
    if (!story) {
      return;
    }
    this.popupFeatures.openStoryUnlock(story, null, true, this.coords, this.currentActivationToken).then((resp: IMasterLockResponse) => {
      if (resp && resp.unlocked) {
        story.locked = false;
        if (resp.aux.reload) {
          this.refresh(0, true, null, null, null, false).then(() => {
            this.enterStory(story);
          }).catch((err: Error) => {
            this.analytics.dispatchError(err, "story-list");
            this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
          });
        } else {
          this.enterStory(story);
        }
      }
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "story-list");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }


  swipeEvent(e) {
    if (!this.backButton.handleSwipeEvent(e)) {
      if (e) {
        switch (e.direction) {
          case 2:
            // right to left
            if (!this.pagination.hideRight) {
              this.doRefresh(1, false);
            }
            break;
          case 4:
            // left to right
            if (!this.pagination.hideLeft) {
              this.doRefresh(-1, false);
            }
            break;
        }
      }
    }
  }

  /**
   * finally enter selected story
   * @param story 
   */
  enterStory(story: IStory) {
    let dynamic: boolean = story.dynamic === 1;
    let categoryCode: number = this.categoryCode;
    let params: IStoryListNavParams = {
      storyId: story.id,
      dynamic: dynamic,
      reload: true,
      category: this.category,
      localStories: this.localStories,
      includeGlobal: true,
      selectedCityId: this.selectedCityId,
      categoryCode: categoryCode,
      loadStory: this.params ? this.params.loadStory : true,
      storyOverview: story,
      useLoadedStory: false
    };
    this.timeout.animate = setTimeout(() => {
      let promiseView: Promise<any>;
      if (this.animate) {
        this.showState = "inactive";
      }
      if (this.isModal) {
        promiseView = this.uiext.showCustomModal(null, StoryHomePage, {
          view: {
            fullScreen: true,
            transparent: false,
            large: true,
            addToStack: true,
            frame: true
          },
          storyId: story.id,
          dynamic: dynamic,
          categoryCode: categoryCode,
          params: params
        });
      } else {
        let navParams: INavParams = {
          view: null,
          storyId: story.id,
          dynamic: dynamic,
          categoryCode: categoryCode,
          params: params
        };

        let queryParams: Params = { storyId: "" + story.id, dynamic: "" + dynamic, categoryCode: "" + categoryCode };
        this.nps.set(ENavParamsResources.storyHome, navParams);

        promiseView = this.router.navigate([ERouteDef.storyHome], { replaceUrl: true, queryParams: queryParams });
      }
      promiseView.then(() => {
        // if (!GeneralCache.isWeb) {
        //   this.storyDataProvider.clearResumeStoryCache();
        // }
      }).catch((err: Error) => {
        this.uiext.showAlertNoAction(Messages.msg.storyNotAvailable.after.msg, Messages.msg.storyNotAvailable.after.sub);
        console.error(err);
      });
    }, this.animate ? AppConstants.animationTime : 0);
  }


  public loadCategories(reload: boolean, selectedCategoryCode: number) {
    let promise = new Promise((resolve, reject) => {
      this.loaded = false;
      this.categories = [];
      let promise: Promise<IStoryCategory[]>;
      if (reload) {
        promise = this.storyDataProvider.getStoryCategoriesFromServer(true);
      } else {
        promise = this.storyDataProvider.getCurrentCategoryList();
      }
      promise.then((response: IStoryCategory[]) => {
        // console.log(response);
        this.categories = response.filter(c => c.mode === this.categoryMode);
        this.category = this.categories.find(c => c.baseCode === selectedCategoryCode);
        if (this.category != null) {
          this.categoryName = this.category.name;
        }
        console.log("loaded category: ", this.category);
        resolve(true);
      }).catch((err) => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * load more stories from the server
   * based on the categories selected
   * or all stories if no category filter is used
   */
  showMore() {
    // console.log("show more");
  }


  /**
   * select city and load stories
   * @param city 
   */
  selectCity(city: IDBModelCity) {
    console.log("select city: ", city);
    console.log("prev selected: ", this.selectedCity);
    let loadingRequired: boolean = this.dispSelectedCity(city);
    this.checkShowContextCity();
    console.log("loading required: ", loadingRequired);
    if (loadingRequired) {
      this.pagination.page = 0;
      this.refreshLoadPageNoAction(this.pagination.page, true);
    }
  }

  /**
   * show selected city info
   * by cityId (check cities, find by id)
   * check new selection
   * @param cityId 
   */
  dispSelectedCityById(cityId: number) {
    console.log("disp selected city by id: " + cityId);
    if (!(this.localData && this.localData.list)) {
      return this.dispSelectedCity(null);
    }
    for (let country of this.localData.list) {
      let city: IDBModelCity = country.cities.find(c => c.id === cityId);
      if (city != null) {
        city.countryName = country.name;
        return this.dispSelectedCity(city);
      }
    }
    return this.dispSelectedCity(null);
  }

  selectGlobal() {
    this.selectedCity = null;
    this.selectedCityId = null;
    this.selectedDisp = this.selectedDispDefault;
    this.checkShowContextCity();
  }

  selectFiltered() {
    this.selectGlobal();
    this.selectedDisp = " > search results";
  }

  /**
   * show selected city info
   * check new selection
   * @param city 
   */
  dispSelectedCity(city: IDBModelCity): boolean {
    let newSelection: boolean = true;
    console.log("disp selected city: ", city);
    if (!city) {
      // select nearby
      if (!this.localData) {
        return newSelection;
      }
      newSelection = this.selectedCityId != null ? (this.localData.nearby.cities[0].id !== this.selectedCityId) : true;
      this.selectedCity = this.localData.nearby.cities[0];
      this.selectedCityId = this.selectedCity.id;
      this.selectedDisp = "(" + this.selectedCity.name + ", " + this.localData.nearby.name + ")";
      return newSelection;
    }
    newSelection = this.selectedCity != null ? (city.id !== this.selectedCityId) : true;
    this.selectedCity = city;
    this.selectedCityId = city.id;
    this.selectedDisp = "(" + this.selectedCity.name + ", " + city.countryName + ")";
    return newSelection;
  }

  openPaginateSelector() {
    this.paginationHandler.openPaginateSelector((page: number) => {
      console.log("select page: " + page);
      return this.refreshLoadPageNoAction(page, true);
    });
  }

  openLocationSelector() {
    let params: ILocationSelectorParams = {
      title: "Local stories",
      specs: this.localData,
      includeGlobal: this.includeGlobal
    };
    this.openedCitySelector = true;
    this.uiext.showCustomModal(null, LocationSelectorViewComponent, {
      view: {
        fullScreen: false,
        transparent: false,
        large: true,
        addToStack: true,
        frame: false
      },
      params: params
    }).then((city: IDBModelCity) => {
      if (city != null) {
        switch (city.id) {
          case ECityCodes.global:
            this.selectGlobal();
            this.pagination.page = 0;
            this.refreshLoadPageNoAction(this.pagination.page, true);
            break;
          case ECityCodes.scanQR:
            this.searchByToken();
            break;
          default:
            this.selectCity(city);
            break;
        }
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  openCategorySelector() {
    let title: string = "Category Filter";
    let items: IListSelectorItem[] = this.categories.map(item => {
      let ge: IGenericEntry = {
        code: item.baseCode,
        name: item.name,
        description: item.description,
        photoUrl: item.photoUrl,
        icon: null,
        customIcon: false,
        enabled: 1,
        position: null,
        withButton: true,
        center: true
      };
      let li: IListSelectorItem = {
        selected: false,
        item: ge,
        available: true,
        inputName: "data",
        heading: true
      };
      return li;
    });

    this.popupFeatures.openCustomListSelector(title, items).then((ge: IGenericEntry) => {
      if (ge != null) {
        for (let category of this.categories) {
          if (category.baseCode === ge.code) {
            this.category = category;
            this.categoryName = category.name;
            this.categoryCode = category.baseCode;
            this.pagination.page = 0;
            this.refreshLoadPageNoAction(this.pagination.page, true);
            break;
          }
        }
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }
}

