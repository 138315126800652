import { Component, OnInit, OnDestroy } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { ParamHandler } from 'src/app/classes/general/params';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';

export interface IDateSelectorParams {
  title: string;
  entries: IDateSelectorEntry[];
  withClear: boolean;
}

export interface IDateSelectorEntry {
  heading: string;
  code: number;
  value?: any;
  minValue?: any;
  maxValue?: any;
  customPickerOptions?: any;
}

export interface IDateSelectorReturn {
  code: number;
  entries: IDateSelectorEntry[];
}

export enum EDateSelectorReturnCode {
  set = 1,
  clear = 2
}

@Component({
  selector: 'modal-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
})
export class DateSelectorViewComponent implements OnInit, OnDestroy {
  title: string = "";
  rightBtnIcon: string = EAppIconsStandard.close;
  vs: IViewSpecs = ViewSpecs.getDefault();
  appIcons = EAppIcons;
  np: INavParams = null;
  customPickerOptions: any;
  selectors: IDateSelectorEntry[] = [];
  loaded: boolean = false;
  withClear: boolean = false;

  constructor(
    public modalCtrl: ModalController
  ) {

  }

  setup() {
    let ts: Date = new Date();
    ts.setSeconds(0);
    let nsel: number = this.selectors.length;
    for (let i = 0; i < this.selectors.length; i++) {
      let sel = this.selectors[i];
      if (!sel.value) {
        let tsVal: number = ts.getTime();
        tsVal -= ((nsel - i - 1) * 86400000);
        let ts1: Date = new Date(tsVal);
        // use local time display with timezone offset in UTC format
        let tzOffset = ts1.getTimezoneOffset();
        let tzOffsetString = "+" + ("" + (-tzOffset / 60)).padStart(2, '0') + ":00";
        let dateOffset = new Date(ts1.getTime() - (tzOffset * 60000)).toISOString().replace(/\..+/, '') + tzOffsetString;
        sel.value = dateOffset;
      }
      sel.customPickerOptions = {
        showBackdrop: true,
        backdropDismiss: true,
        cssClass: 'custom-picker'
      };
    }

    this.checkLimits();
  }

  checkLimits() {
    let nsel: number = this.selectors.length;
    for (let i = 0; i < this.selectors.length; i++) {
      let sel = this.selectors[i];
      // let ts1: Date;
      // let tsVal: number;
      if (i > 0) {
        // ts1 = new Date(this.selectors[i - 1].value);
        // tsVal = ts1.getTime();
        // tsVal += 3600000;
        // sel.minValue = new Date(tsVal).toISOString();
        sel.minValue = this.selectors[i - 1].value;
      }
      if (i < nsel - 1) {
        // ts1 = new Date(this.selectors[i + 1].value);
        // tsVal = ts1.getTime();
        // tsVal -= 3600000;
        // sel.maxValue = new Date(tsVal).toISOString();
        sel.maxValue = this.selectors[i + 1].value;
      }
    }
  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IDateSelectorParams = np.params;
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);
      this.title = params.title;
      this.selectors = params.entries;
      this.withClear = params.withClear;
      this.setup();
      this.loaded = true;
    }
  }

  ngOnDestroy() {

  }

  onChange(_sel: IDateSelectorEntry) {
    this.checkLimits();
  }

  dismiss(data: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(data).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  ok() {
    let ret: IDateSelectorReturn = {
      code: EDateSelectorReturnCode.set,
      entries: this.selectors
    };
    this.dismiss(ret);
  }

  clear() {
    let ret: IDateSelectorReturn = {
      code: EDateSelectorReturnCode.clear,
      entries: null
    };
    this.dismiss(ret);
  }
}
