import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AppConstants } from 'src/app/classes/app/constants';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { IGenericResponse, IRefCodeResponse } from 'src/app/classes/def/requests/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AdsService } from 'src/app/services/general/apis/ads';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { MiscDataService } from 'src/app/services/data/misc';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ISelectSettings } from 'src/app/classes/def/app/settings';


@Component({
  selector: 'app-signup-tutorial',
  templateUrl: './signup-tutorial.page.html',
  styleUrls: ['./signup-tutorial.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SignupTutorialPage implements OnInit, OnDestroy {
  title: string = AppConstants.viewTitle.signupTutorial;
  infoHTML: string = "";
  photoUrlLoaded: string = null;
  loading: boolean = false;
  refCode: string = "";
  vs: IViewSpecs = ViewSpecs.getDefault();

  np: INavParams = null;
  appIconsStandard = EAppIconsStandard;
  unitSettings: ISelectSettings;

  constructor(
    public modalCtrl: ModalController,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public adService: AdsService,
    public resources: ResourcesCoreDataService,
    public misc: MiscDataService,
    public settingsProvider: SettingsManagerService,
    public nps: NavParamsService
  ) {

  }


  ngOnInit() {
    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.login, null, this.np);

      console.log("nav params: ", npInfo.params);
      let hasParams: boolean = npInfo.hasParams;
      if (hasParams) {
        let np: INavParams = npInfo.params;
        if (np.view) {
          this.vs = np.view;
        }
        console.log(np);
      }
      this.loadSignupTutorial();
      this.loadUnitSettings();
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  loadSignupTutorial() {
    this.loading = true;
    this.resources.getSignupTutorial().then((response: IGenericResponse) => {
      this.loading = false;
      this.infoHTML = response.data;
    }).catch((_err) => {
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
      this.loading = false;
    });
  }


  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  loadUnitSettings() {
    let set = SettingsManagerService.settings.app.settings.units;
    if (set.options) {
      set.optionsList = [];
      let optk: string[] = Object.keys(set.options);
      for (let j = 0; j < optk.length; j++) {
        set.optionsList.push(set.options[optk[j]]);
      }
    }
    this.unitSettings = set;
  }

  updateUnitSettings(set: ISelectSettings) {
    SettingsManagerService.settings.app.settings.units.value = set.value;
    this.settingsProvider.updateLocalSettingsNoAction();
  }

  /**
   * accept terms and continue
   * use referral code if entered
   */
  accept() {
    if (this.refCode) {
      this.misc.useReferralCode(this.refCode).then((res: IRefCodeResponse) => {
        this.uiext.showAlert(Messages.msg.referralCodeUsed.after.msg, res.message, 1, null).then(() => {
          this.dismiss(true);
        }).catch(() => {
          this.dismiss(true);
        });
      }).catch((err: Error) => {
        this.refCode = "";
        this.uiext.showAlert(Messages.msg.referralCodeUsed.after.msg, ErrorMessage.parse(err, "R-Code could not be validated"), 1, null).then(() => {

        }).catch(() => {
          this.dismiss(true);
        });
      });
    } else {
      this.dismiss(true);
    }
  }

  cancel() {
    this.dismiss(false);
  }

  ngOnDestroy() {

  }
  clearWatch() {

  }
}
