import { ElementRef, Injectable } from '@angular/core';
import { GenericDataService } from '../general/data/generic';
import { IPlatformFlags } from '../../classes/def/app/platform';
import { SettingsManagerService } from '../general/settings-manager';
import { MediaTemplateService } from './media-template';
import { PermissionsService } from '../general/permissions/permissions';
import { MediaCapture, MediaFile, CaptureError, CaptureVideoOptions } from '@ionic-native/media-capture/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { Camera } from '@ionic-native/camera/ngx';
import { WebviewUtilsService } from '../app/utils/webview-utils';
import { FileTransfer } from '@ionic-native/file-transfer/ngx';
import { IGenericResponse } from 'src/app/classes/def/requests/general';

declare var MediaRecorder: any;

export interface IVideoUploadResponse {
    status: boolean;
    uploadedUrl: string;
}

@Injectable({
    providedIn: 'root'
})
export class VideoService implements MediaTemplateService {

    targetWidth: number = 640;
    quality: number = 60;

    platform: IPlatformFlags = {} as IPlatformFlags;

    videoTag: ElementRef<HTMLVideoElement>;
    video: HTMLVideoElement;
    mediaRecorder: any;
    recordBuffer: Blob[] = [];

    // useNative: boolean = false;
    useNative: boolean = true;

    constructor(
        public generic: GenericDataService,
        public permissions: PermissionsService,
        public settingsProvider: SettingsManagerService,
        public mediaCapture: MediaCapture,
        public sanitizer: DomSanitizer,
        public camera: Camera,
        public webviewUtils: WebviewUtilsService,
        public transfer: FileTransfer
    ) {
        console.log("video service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    setMediaElement(videoTag: ElementRef<HTMLVideoElement>) {
        this.videoTag = videoTag;
        if (videoTag != null) {
            this.video = this.videoTag.nativeElement;
        } else {
            this.video = null;
        }
    }

    setMediaElementPlayable(videoTag: ElementRef<HTMLVideoElement>) {
        if (videoTag != null) {
            let video = videoTag.nativeElement;
            video.controls = true;
        } else {
            return;
        }
    }

    playMediaElement(videoTag: ElementRef<HTMLVideoElement>, onpause: (paused: boolean) => any) {
        if (videoTag != null) {
            let video = videoTag.nativeElement;
            video.play();
            video.onpause = () => {
                onpause(true);
            };
            video.onended = () => {
                onpause(true);
            };
            video.onplay = () => {
                onpause(false);
            };
        } else {
            return;
        }
    }

    pauseMediaElement(videoTag: ElementRef<HTMLVideoElement>) {
        if (videoTag != null) {
            let video = videoTag.nativeElement;
            video.pause();
        } else {
            return;
        }
    }

    startMediaCapture(_canvas: any, front: boolean, oncomplete: (data: any) => any) {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (this.platform.WEB || !this.useNative) {
                if (navigator.mediaDevices) {
                    console.log("getUserMedia supported.");

                    let constraints = {
                        audio: {
                            sampleRate: {
                                ideal: 44100
                            }
                        },
                        video: {
                            // get max supported resolution
                            advanced: [
                                { width: { exact: 1920 } },
                                { width: { exact: 1280 } },
                                { width: { exact: 1024 } },
                                { width: { exact: 900 } },
                                { width: { exact: 800 } },
                                { width: { exact: 640 } },
                                { width: { exact: 320 } }
                            ],
                            facingMode: front ? "user" : "environment"
                        }
                    };

                    this.permissions.requestCameraRecordingPermission().then(() => {
                        navigator.mediaDevices.getUserMedia(constraints).then((stream: MediaStream) => {
                            if (this.video != null) {
                                // this.video.src = window.URL.createObjectURL(stream);
                                this.video.srcObject = stream;
                                this.video.controls = true;
                                this.video.muted = true;
                                this.video.onloadedmetadata = () => {
                                    if (this.video != null) {
                                        this.video.play();
                                    }
                                };
                            }
                            resolve(true);
                        }).catch((err) => {
                            reject(err);
                        });
                    }).catch((err) => {
                        reject(err);
                    });
                } else {
                    reject(new Error("not supported"));
                }
            } else {
                this.permissions.requestCameraRecordingPermission().then(() => {
                    // this.recordVideoNative(false, true).then((fileURI: string) => {
                    //     this.video.src = fileURI;
                    //     this.video.controls = true;
                    //     this.video.muted = true;
                    //     oncomplete(fileURI);
                    // }).catch((err) => {
                    //     reject(err);
                    // });
                    this.startMediaCaptureNative(null, oncomplete).then((res: boolean) => {
                        resolve(res);
                    }).catch((err) => {
                        reject(err);
                    });
                }).catch((err) => {
                    reject(err);
                });
            }
        });
        return promise;
    }

    startMediaCaptureNative(duration: number, oncomplete: (data: any) => any) {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            let options: CaptureVideoOptions = {
                limit: 1
            };
            if (duration != null) {
                options.duration = duration;
            }
            this.mediaCapture.captureVideo(options).then(async (data: MediaFile[]) => {
                console.log(data);
                if (data != null && data.length > 0) {
                    let file: MediaFile = data[0];
                    try {

                        console.log("resolving local filesystem");
                        // let lfs: string = (this.platform.ANDROID ? "filesystem:" : "") + file.fullPath;
                        // let entry: Entry = await this.file.resolveLocalFilesystemUrl(lfs);
                        // console.log("local filesystem resolved: ", entry);
                        // // get the path..
                        // let path: string = entry.nativeURL.substring(0, entry.nativeURL.lastIndexOf("/"));
                        // console.log("path", path);
                        // console.log("fileName", entry.name);
                        // // we are provided the name, so now read the file into
                        // // a buffer
                        // let buffer: ArrayBuffer = await this.file.readAsArrayBuffer(path, entry.name);
                        // console.log("buffer read complete");
                        // var rawBlob: Blob = new Blob([buffer], { type: 'video/mp4' });
                        // console.log("blob created");
                        // oncomplete(rawBlob);
                        // this.saveBlob(rawBlob, "video_raw_" + new Date().getTime() + ".mp4");

                        oncomplete(file.fullPath);

                        // if (this.video != null) {
                        //     // this.video.src = window.URL.createObjectURL(stream);
                        //     // this.video.src = this.sanitizer.bypassSecurityTrustResourceUrl(file.fullPath);
                        //     this.video.src = file.fullPath;
                        //     this.video.controls = true;
                        //     this.video.muted = false;
                        // }
                        resolve(true);
                    } catch (err) {
                        console.error(err);
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            }).catch((err: CaptureError) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    startRecording(oncomplete: (data: any) => any) {
        console.log("start recording");

        if (this.platform.WEB || !this.useNative) {
            let options = {
                audioBitsPerSecond: 128000, // max 128 kbps
                videoBitsPerSecond: 2500000,
                mimeType: "video/webm",
                // mimeType: 'video/mp4; codecs="avc1.424028, mp4a.40.2"'
            };
            let stream: MediaStream = this.video.srcObject as MediaStream;
            if (!this.mediaRecorder) {
                this.mediaRecorder = new MediaRecorder(stream, options);
            }

            this.recordBuffer = []; // here we will store our recorded media chunks (Blobs)

            this.mediaRecorder.onstart = (e) => {
                console.log("recording started");
            };

            this.mediaRecorder.ondataavailable = (e) => {
                this.recordBuffer.push(e.data);
            };

            this.mediaRecorder.onerror = (e) => {
                console.error(e);
                oncomplete(null);
            };

            // only when the recorder stops, we construct a complete Blob from all the chunks
            this.mediaRecorder.onstop = (e) => {
                console.log("recording stopped");
                var rawBlob: Blob = new Blob(this.recordBuffer, { type: 'video/webm' });
                oncomplete(rawBlob);
                this.saveBlob(rawBlob, "video_raw_" + new Date().getTime() + ".webm");
            };

            if (this.mediaRecorder.state != 'recording') {
                this.mediaRecorder.start();
            }
        } else {
            console.warn("not implemented");
        }
    }

    saveBlob(blob: Blob, fileName: string) {
        var a = document.createElement("a");
        document.body.appendChild(a);
        // a.style = "display: none";
        var url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = fileName;
        a.click();
        window.URL.revokeObjectURL(url);
    }

    pauseMediaCapture() {
        if (this.video != null) {
            this.video.pause();
            // stop both mic and camera
            let mediaStream: MediaStream = this.video.srcObject as MediaStream;
            if (mediaStream != null) {
                let tracks: MediaStreamTrack[] = mediaStream.getTracks();
                console.log("tracks: ", tracks);
                for (let track of tracks) {
                    if (track.readyState == 'live') {
                        track.stop();
                    }
                }
            }
        }
    }

    stopMediaCapture() {
        try {
            if (this.video != null) {
                this.pauseMediaCapture();
                this.video.srcObject = null;
                this.video = null;
                this.videoTag = null;
            }
            if (this.mediaRecorder != null) {
                this.mediaRecorder.stop();
                this.mediaRecorder = null;
            }
        } catch (err) {
            console.error(err);
        }
    }

    uploadMedia(storyId: number, storyLocationId: number, fileType: string, data: any): Promise<IVideoUploadResponse> {
        return this.generic.genericPostStandardWData("/media/user/video-upload", {
            storyId: storyId,
            storyLocationId: storyLocationId,
            fileType: fileType,
            data: data
        });
    }

    uploadVideo(videoUrl: string, storyId: number, storyLocationId: number, filename: string): Promise<IVideoUploadResponse> {
        return new Promise<IVideoUploadResponse>((resolve, reject) => {
            let fileType: string = "video/mp4";
            this.generic.uploadFileCore("recording", "video", videoUrl, fileType, true, "/media/user/video-upload-file", {
                storyId: storyId,
                storyLocationId: storyLocationId,
                fileType: fileType,
                filename: filename
            }, null).then((resp: IGenericResponse) => {
                // Handle successful video upload
                console.log('Video uploaded:', resp);
                resolve(resp.data);
            }).catch((err: Error) => {
                console.log('Error uploading video:', err);
                reject(err);
            });
        });
    }

}
