


import { Injectable } from "@angular/core";
import { KalmanFilter } from "kalman-filter";

export enum EKalmanContext {
    location = "location"
}

@Injectable({
    providedIn: 'root'
})
export class KalmanFilterService {
    kFilter: { [key: string]: any } = {};
    previousCorrected: { [key: string]: any } = {};

    constructor(
    ) {
        console.log("kalman filter service created");
    }

    init2D(context: string, constant: number) {
        console.log("kalman filter init context: ", context);
        this.previousCorrected[context] = null;
        this.kFilter[context] = new KalmanFilter({
            observation: {
                name: 'sensor',
                sensorDimension: 2
            },
            dynamic: {
                name: 'constant-position',// observation.sensorDimension == dynamic.dimension
                covariance: [constant, constant]// equivalent to diag([x, x])
                // smaller values result in higher low pass filtering
            }
            // dynamic: {
            // 	name: 'constant-speed',// observation.sensorDimension * 2 == state.dimension
            // 	timeStep: 1,
            // 	covariance: [3, 3, 3, 4, 4, 4]// equivalent to diag([3, 3, 3, 4, 4, 4])
            // }
        });
    }

    filter2D(context: string, observation: number[]) {
        if (!this.kFilter[context]) {
            console.warn("kalman filter not initialized for specified context");
            return null;
        }
        // online kalman filter
        let pc = this.previousCorrected[context];
        this.previousCorrected[context] = this.kFilter[context].filter({ pc, observation });
        return this.previousCorrected[context].mean;
    }
}