import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ParamHandler } from 'src/app/classes/general/params';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { IGetCountriesWithDetailsAndNearby, IDBModelCountry, IDBModelCity, ECountryCodes } from 'src/app/classes/def/world/world';
import { EAppIcons } from 'src/app/classes/def/app/icons';
import { IListSelectorCategory, IListSelectorItem } from 'src/app/classes/def/dynamic/list';
import { IGenericEntry } from 'src/app/classes/def/app/entry';
import { DeepCopy } from 'src/app/classes/general/deep-copy';

export interface ILocationSelectorParams {
  title: string;
  specs: IGetCountriesWithDetailsAndNearby;
  includeGlobal: boolean;
};

@Component({
  selector: 'modal-location-selector',
  templateUrl: './location-selector.component.html',
  styleUrls: ['./location-selector.component.scss'],
})
export class LocationSelectorViewComponent implements OnInit, OnDestroy {
  hasParams: boolean = false;
  title: string = "Select a city";
  theme: string = "theme-aubergine theme-aubergine-bg";
  vs: IViewSpecs = ViewSpecs.getDefault();
  np: INavParams = null;
  specs: IGetCountriesWithDetailsAndNearby;
  countries: IDBModelCountry[] = [];
  appIcons = EAppIcons;
  includeGlobal: boolean = false;
  countriesGen: IListSelectorCategory[] = [];
  updateGrid: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService,
    public settingsProvider: SettingsManagerService
  ) {

  }

  ngOnInit() {
    this.hasParams = ParamHandler.checkParams(this.np);
    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    if (!this.hasParams) {

    } else {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: ILocationSelectorParams = np.params;
      console.log(params);

      if (params.title) {
        this.title = params.title;
      }

      this.includeGlobal = params.includeGlobal;
      this.specs = params.specs;
      this.formatList();

      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);
    }
  }

  formatList() {
    this.countries = [];
    this.specs.nearby.nearby = true;
    this.specs.nearby.auxName = "local (" + this.specs.nearby.name + ")";
    let countryList: IDBModelCountry[] = this.specs.list;
    for (let c of countryList) {
      c.auxName = c.name;
    }
    let global: IDBModelCountry;
    let demo: IDBModelCountry;

    if (this.includeGlobal) {
      if (this.specs.global != null) {
        // no longer used
        this.countries.push(this.specs.global);
      } else {
        global = countryList.find(spec => spec.id === ECountryCodes.global);
        demo = countryList.find(spec => spec.id === ECountryCodes.demo);
        if (global != null) {
          // global country included in list (updated api)
          // reverse array cities (negative ids)
          let globalCountry: IDBModelCountry = DeepCopy.deepcopy(global);
          globalCountry.cities.reverse();
          this.countries.push(globalCountry);
          countryList = countryList.filter(spec => [ECountryCodes.global, ECountryCodes.demo].indexOf(spec.id) === -1);
          countryList.sort((a, b) => {
            if (a.id > b.id) {
              return 1;
            }
            if (a.id < b.id) {
              return -1;
            }
            return 0;
          });
        }
      }
    }
    this.countries.push(this.specs.nearby);
    this.countries = this.countries.concat(countryList);

    if (demo != null) {
      this.countries.push(demo);
    }

    for (let c of this.countries) {
      let items: IListSelectorItem[] = c.cities.map(item => {
        let name: string = item.name;
        if (c.id !== ECountryCodes.global) {
          if (item.storyCount != null) {
            name += " (" + item.storyCount + ")";
          }
        }
        let ge: IGenericEntry = {
          code: item.id,
          name: name,
          description: null,
          photoUrl: item.photoUrl,
          icon: null,
          customIcon: false,
          enabled: 1,
          position: null,
          withButton: true,
          center: true
        };
        let li: IListSelectorItem = {
          selected: false,
          item: null,
          available: true,
          inputName: "data"
        };
        li[li.inputName] = ge;
        return li;
      });
      this.countriesGen.push({
        name: c.auxName,
        item: c,
        list: items
      });
    }
  }

  ngOnDestroy() {

  }

  dismiss() {
    this.dismissWithData(null);
  }

  dismissWithData(data: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(data).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  getAttachedCountry(city: IDBModelCity, countries: IDBModelCountry[]) {
    for (let country of countries) {
      if ((country.id === city.countryId) && !country.nearby) {
        let countryRef: IDBModelCountry = Object.assign({}, country);
        delete countryRef.cities;
        return countryRef;
      }
    }
    return null;
  }

  select(city: IDBModelCity) {
    city.country = this.getAttachedCountry(city, this.countries);
    this.dismissWithData(city);
  }

  selectGen(item: IGenericEntry) {
    console.log(item);
    if (!item) {
      return;
    }
    let foundCity: IDBModelCity = null;
    for (let country of this.countries) {
      for (let city of country.cities) {
        if (item.code === city.id) {
          city.countryName = country.name;
          foundCity = city;
          break;
        }
      }
      if (foundCity != null) {
        break;
      }
    }
    this.dismissWithData(foundCity);
  }

  nop() {

  }

}
