
import { Injectable } from '@angular/core';
import { ELocalAppDataKeys, IAppFlagsGen, IAppFlagsElement, IAppFlagsGenContent, ILocalShowFlags, IStoryFlags, AppFlags, IAppFlags } from '../../../classes/def/app/storage-flags';
import { StorageOpsService } from './storage-ops';
import { ArrayUtils } from '../../utils/array-utils';

@Injectable({
    providedIn: 'root'
})
export class StorageFlagsService {

    flagsGroups: IAppFlagsGenContent = {};
    updated: { [key: string]: boolean } = {};
    loaded: { [key: string]: boolean } = {};

    constructor(
        public storageOps: StorageOpsService
    ) {
        console.log("storage flags service created");

        // init defaults
        let localShowFlags: ILocalShowFlags = {
            gmapTutorial: false,
            droneTutorial: false,
            arTutorial: false
        };

        let storyFlags: IStoryFlags = {
            includeBeta: false
        };

        this.flagsGroups[ELocalAppDataKeys.localShowFlags] = localShowFlags;
        this.flagsGroups[ELocalAppDataKeys.storyFlags] = storyFlags;
    }

    /**
     * load flags group
     * check cache
     * load from local storage
     * defaults must be initialized in service!
     * @param groupCode ELocalAppDataKeys.localShowFlags
     * @param defaults use ext defaults
     * @param syncCode sync flags with code provided flags (set false if flags are added dynamically)
     */
    loadFlagsGroup(groupCode: string, defaults: IAppFlagsGenContent, syncCode: boolean): Promise<IAppFlagsGenContent> {
        let promise: Promise<IAppFlagsGenContent> = new Promise((resolve) => {
            console.log("load flags group code: " + groupCode);
            if (this.loaded[groupCode]) {
                console.log("load flags group: ", this.flagsGroups[groupCode]);
                resolve(this.flagsGroups[groupCode]);
                return;
            }

            let codeFlags: IAppFlagsGenContent = {};

            if (defaults) {
                // init defaults (use ext defaults)
                codeFlags = defaults;
            } else {
                // use local defaults
                codeFlags = this.flagsGroups[groupCode];
            }

            this.updated[groupCode] = false;
            this.storageOps.checkStorageFlagResolve({
                flag: groupCode,
                value: codeFlags
            }, false).then((storageFlags: IAppFlagsGen) => {
                let syncedFlags: IAppFlagsGenContent = {};
                if (storageFlags) {
                    if (syncCode) {
                        // update storage flags with code flags specs (static loading, CRUD enabled)
                        syncedFlags = ArrayUtils.syncObjectKeys(storageFlags, codeFlags, false);
                    } else {
                        // keep only storage flags (dynamic loading, no CRUD)
                        syncedFlags = storageFlags;
                    }
                    // update cache
                    this.flagsGroups[groupCode] = syncedFlags;
                } else {
                    syncedFlags = codeFlags;
                }
                this.loaded[groupCode] = true;
                // console.log("synced flags (" + groupCode + ") " + JSON.stringify(syncedFlags));
                console.log("synced flags group code: " + groupCode);
                console.log("storage flags group: ", storageFlags);
                console.log("synced flags group: ", syncedFlags);
                resolve(syncedFlags);
            }).catch((err: Error) => {
                console.error(err);
                resolve(codeFlags);
            });
        });
        return promise;
    }

    updateFlagsGroup(groupCode: string, flags: IAppFlagsGenContent) {
        this.updated[groupCode] = true;
        this.flagsGroups[groupCode] = flags;
    }

    /**
     * save flags to local storage
     * @param groupCode 
     */
    saveFlagsGroupNoAction(groupCode: string) {
        this.saveFlagsGroup(groupCode).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * save flags to local storage
     * resolve only
     */
    saveFlagsGroup(groupCode: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (!this.flagsGroups[groupCode]) {
                console.error("local show flags not loaded. skip update storage. group: " + groupCode);
                resolve(false);
                return;
            }
            if (!this.updated[groupCode]) {
                console.error("local show flags not updated. nothing to update. group: " + groupCode);
                resolve(false);
                return;
            }

            let flag: IAppFlagsElement = {
                flag: groupCode,
                value: this.flagsGroups[groupCode]
            };

            this.storageOps.setStorageFlag(flag).then(() => {
                this.updated[groupCode] = false;
                console.log("flags group updated: " + groupCode);
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
        return promise;
    }


    /**
     * check app flags from local storage or set defaults if not initialized
     */
    loadAppFlags(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            console.log("Storage Flags: check flags");
            this.storageOps.loadMultipleStorageFlags(AppFlags.generalFlags).then((newFlags: IAppFlags) => {
                if (newFlags) {
                    console.log("new flags: ", newFlags);
                    let newFlagKeys = Object.keys(newFlags);
                    for (let i = 0; i < newFlagKeys.length; i++) {
                        if (newFlags[newFlagKeys[i]].value) {
                            AppFlags.generalFlags[newFlagKeys[i]].value = newFlags[newFlagKeys[i]].value;
                        }
                    }
                }
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

}
