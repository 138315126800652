import { ILeplaceObject } from "../core/objects";
import { IStatDef } from "../user/stats";

export enum ENavigateReturnCodes {
    reached = 0,
    recalculate = 1,
    findActivityExpired = 2,
    error = -1,
    inProgress = -2
}


export enum EActivityDirectionsMode {
    single = 0,
    multi = 1
}

export interface INavigationStats {
    distanceTravelled: number;
    collectedCoins: number;
    collectedValue: number;
    gainedXp: number;
    xpScaleFactor: number;
    collectedList: ILeplaceObject[];
    statsList: IStatDef[];
}