import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { LiveUpdateCoreService } from 'src/app/services/general/apis/live-update-core';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { ParamHandler } from 'src/app/classes/general/params';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { IUpdatingAppNavParms, ELiveUpdateStatus } from 'src/app/classes/def/app/liveupdate';
import { MathUtils } from 'src/app/classes/general/math';
import { DownloadProgress, IRemotePackage } from '@ionic-native/code-push';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { SleepUtils } from 'src/app/services/utils/sleep-utils';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';

@Component({
  selector: 'modal-updating-app',
  templateUrl: './updating-app.component.html',
  styleUrls: ['./updating-app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class UpdatingAppViewComponent implements OnInit, OnDestroy {
  theme: string = "theme-aubergine theme-aubergine-bg";
  title: string = "LP Update";
  infoHTML: string = "";
  progressString: string = "Please wait..";
  progressPercent: number = 0;
  flags = {
    downloading: true,
    processing: false
  };
  canDismiss: boolean = false;
  canIgnore: boolean = false;
  subscription = {
    status: null,
    progress: null
  };
  observable = {
    status: null,
    progress: null
  };
  timeout = {
    init: null
  };
  initComplete: boolean = false;
  updateReady: boolean = false;
  btnContinue: string = "Update";
  package1: IRemotePackage = null;
  vs: IViewSpecs = ViewSpecs.getDefault();
  np: INavParams = null;
  appIconsStandard = EAppIconsStandard;

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public liveUpdateCore: LiveUpdateCoreService,
    public analytics: AnalyticsService
  ) {

  }

  /**
   * install (manual confirm)
   */
  install() {
    console.log("request install update");
    this.initComplete = true;
    this.canDismiss = false;
    this.progressString = "Downloading update";
    this.liveUpdateCore.update(this.package1).then(async () => {
      this.flags.processing = false;
      this.progressString = "Update ready";
      this.canDismiss = true;
      this.btnContinue = "Update";
      this.updateReady = true;
      await SleepUtils.sleep(1000);
      let counter: number = 3;
      while (counter >= 0) {
        if (counter > 0) {
          this.progressString = "Reloading.. " + counter;
        } else {
          this.progressString = "Reloading..";
        }
        await SleepUtils.sleep(1000);
        counter -= 1;
      }
      this.dismiss(true);
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "updating-app");
      this.flags.processing = false;
      this.progressString = "Update failed";
      this.canDismiss = true;
      this.btnContinue = "Return";
      this.updateReady = true;
    });
  }

  dismiss(restart: boolean) {
    if (this.canDismiss) {
      setTimeout(() => {
        console.log("dismiss triggered");
        this.modalCtrl.dismiss(restart).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
      }, 1);
    }
  }

  ignore() {
    this.canDismiss = true;
    this.dismiss(false);
  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IUpdatingAppNavParms = np.params;

      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);

      this.title = params.title;

      if (!params.progressObservable) {
        this.observable.progress = this.liveUpdateCore.getProgressObservable();
      }

      if (params.package1) {
        this.package1 = params.package1;
        this.canIgnore = !this.package1.isMandatory;

        this.infoHTML = `
              <p>New update package</p>`;
        if (this.package1.isMandatory) {
          this.infoHTML += `<p>Important update</p>`;
        }

        this.infoHTML += `<p>Release notes:</p><p>`;
        this.infoHTML += this.package1.description;
        this.infoHTML += "</p>";

        this.infoHTML += "<p>Download: " + MathUtils.formatFileSize(this.package1.packageSize, 1) + "</p>";

      } else {
        // the self update method is used that does not require additional user input
        this.initComplete = true;
      }

      // self-update/modal handled update
      this.subscription.progress = this.observable.progress.subscribe((progress: DownloadProgress) => {
        if (progress) {
          this.progressString = "Downloading update: " + MathUtils.formatFileSize(progress.totalBytes, 1);
          this.progressPercent = Math.floor(progress.receivedBytes / progress.totalBytes * 100);
          if (this.progressPercent >= 100) {
            this.flags.downloading = false;
            this.flags.processing = true;
            // this.infoHTML = "<p>Processing update</p>";
            this.progressString = "Processing update";
          }
        }
        // console.log(this.progressString);
      }, (err: Error) => {
        console.error(err);
      });

      // only for self update method
      if (params.statusObservable) {
        this.observable.status = params.statusObservable;
        this.subscription.status = this.observable.status.subscribe((status: number) => {
          if (status === ELiveUpdateStatus.finished) {
            // completed
            // this.infoHTML = "<p>Update ready</p>";
            this.progressString = "Update ready";
            this.updateReady = true;
            this.flags.processing = false;
            this.canDismiss = true;
          }
          if (status === ELiveUpdateStatus.error) {
            // this.infoHTML = "<p>Update failed</p>";
            this.progressString = "Update failed";
            this.updateReady = true;
            this.flags.processing = false;
            this.canDismiss = true;
          }
        }, (err: Error) => {
          console.error(err);
        });
      }
    }
  }

  ngOnDestroy() {
    ResourceManager.clearSubObj(this.subscription);
    ResourceManager.clearTimeoutObj(this.timeout);
  }

}
