
export interface IBenchObject {
    t0: number,
    t1: number
}

export interface IBenchResult {
    dt: number,
    count: boolean
}

// contains multiple bench results
export interface IBenchResultContainer {
    [key: string]: IBenchResult
}

// contains multiple bench result containers
export interface IBenchReportContainer {
    [key: string]: IBenchResultContainer
}

export class BenchUtils {
    static sumBench(obj: { [key: string]: IBenchResult }): IBenchResult {
        let sum: number = 0;
        for (let key of Object.keys(obj)) {
            if (obj[key] != null) {
                if (obj[key].count) {
                    sum += obj[key].dt;
                }
            }
        }
        return {
            dt: sum,
            count: false
        };
    }

    /**
     * create new container for bench
     */
    static start(): IBenchObject {
        let t: number = new Date().getTime();
        let b: IBenchObject = {
            t1: t,
            t0: t
        };
        return b;
    }

    /**
     * get incremental result (dt)
     * reset timer if result is counted (count == true)
     * result not counted => used for concurrent measurements, where t0 is the same
     * @param d 
     * @param count 
     */
    static lap(d: IBenchObject, count: boolean): IBenchResult {
        let t: number = new Date().getTime();
        d.t1 = t;
        let b: IBenchResult = {
            dt: d.t1 - d.t0,
            count: count
        };
        if (count) {
            d.t0 = t;
        }
        return b;
    }

}