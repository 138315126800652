import { IFaqEntry } from "../support/support";
import { EAppIcons } from "./icons";


export class MessagesExt {

    public static mpInit: IFaqEntry[] = [
        {
            id: 1,
            categoryCode: null,
            code: 1,
            name: "",
            description: "Join the lobby to meet other players",
            photoUrl: "",
            icon: EAppIcons.gpsFixed,
            customIcon: true,
            enabled: 1,
            position: 1,
            faqCategory: null
        },
        {
            id: 2,
            categoryCode: null,
            code: 2,
            name: "",
            description: "Chat with other players in the lobby",
            photoUrl: "",
            icon: "chatbox",
            customIcon: false,
            enabled: 1,
            position: 1,
            faqCategory: null
        },
        {
            id: 3,
            categoryCode: null,
            code: 3,
            name: "",
            description: "Create or join a team to start a game",
            photoUrl: "",
            icon: "people",
            customIcon: false,
            enabled: 1,
            position: 1,
            faqCategory: null
        }
    ];


    public static mpInitGroup: IFaqEntry[] = [
        {
            id: 1,
            categoryCode: null,
            code: 1,
            name: "",
            description: "Exit team lobby",
            photoUrl: "",
            icon: EAppIcons.logout,
            customIcon: true,
            enabled: 1,
            position: 1,
            faqCategory: null
        },
        {
            id: 2,
            categoryCode: null,
            code: 2,
            name: "",
            description: "Chat with your team",
            photoUrl: "",
            icon: "chatbox",
            customIcon: false,
            enabled: 1,
            position: 1,
            faqCategory: null
        },
        {
            id: 3,
            categoryCode: null,
            code: 3,
            name: "",
            description: "Connect to multiplayer session",
            photoUrl: "",
            icon: "play",
            customIcon: false,
            enabled: 1,
            position: 1,
            faqCategory: null
        }
    ];
}