
import { Injectable } from "@angular/core";
import { PlacesDataService } from "../../data/places";
import { ILeplaceTreasure, IPlaceDataInternalStandardContainer, TreasureUtils } from "../../../classes/def/places/leplace";
import { IEventStoryGroupLinkData } from 'src/app/classes/def/core/links';

@Injectable({
    providedIn: 'root'
})
export class ItemCollectorCoreService {

    startTs: number = null;
    
    constructor(
        public placesData: PlacesDataService
    ) {
        console.log("item collector core service created");
    }

    initCollectTs(ts: number) {
        this.startTs = ts;
    }

    /**
     * save collected crate in db
     * so that it's registered and not shown again until some time
     * the item can also be a story/arena, for which it's not hidden after found
     * @param item 
     */
    updateCollectedItemServer(item: ILeplaceTreasure, collected: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            // item.registerInProgress = true;
            let res: IPlaceDataInternalStandardContainer = TreasureUtils.getPlace(item);

            if (!res.availableSpecs) {
                reject(new Error("undefined specs"));
                return;
            }

            this.placesData.saveCollectedTreasureV3(item.uid, res.placeData.googleId, item.type, item, collected, this.startTs).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

}
