import { Component, OnInit, OnDestroy, Input, Output } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { EventEmitter } from '@angular/core';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';

@Component({
  selector: 'responsive-img',
  templateUrl: './responsive-img.component.html',
  styleUrls: ['./responsive-img.component.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0
      })),
      state('active', style({
        // transform: 'translateY(0)',
        opacity: 1
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ]
})
export class ResponsiveImgComponent implements OnInit, OnDestroy {
  showState: string = "inactive";
  loaded: boolean = false;
  appIconsStandard = EAppIconsStandard;
  customClassCrt: string = "border-radius-img paper-card";

  @Input()
  zoomState: boolean;

  @Input()
  photoUrl: string;

  @Input()
  showLoading: boolean;

  @Input()
  customClass: string;

  @Input()
  withUpload: boolean;

  @Input()
  withZoom: boolean;

  @Input()
  noAnimation: boolean;

  @Input()
  fixedZoom: boolean;

  @Input()
  innerClass: string;

  @Output()
  zoom: EventEmitter<boolean> = new EventEmitter();

  @Output()
  upload: EventEmitter<string> = new EventEmitter();

  defaultClass: string = "img-small";
  containerClass: string = "text-center";
  withZoomOption: boolean = true;

  constructor() {

  }

  ngOnInit() {
    if (this.customClass) {
      this.defaultClass = this.customClass;
    }
    if (this.innerClass) {
      this.containerClass += " " + this.innerClass;
    }
    if (this.zoomState == null) {
      this.zoomState = false;
    }
    this.setZoom(this.zoomState);
    if (this.noAnimation) {
      this.setLoaded();
    }
    if (this.withZoom === false) {
      this.withZoomOption = false;
    }
  }

  ngOnDestroy() {

  }

  setLoaded() {
    this.loaded = true;
    this.showState = "active";
    console.log("responsive img loaded");
  }

  setZoom(_zoom: boolean) {

  }

  tapZoom() {
    if (!this.fixedZoom) {
      this.zoomState = !this.zoomState;
    }
    this.setZoom(this.zoomState);
    console.log("tap zoom: ", this.zoomState);
    this.zoom.emit(this.zoomState);
  }

  tapUpload() {
    console.log("tap upload");
    this.upload.emit(null);
  }
}
