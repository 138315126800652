import { PromiseUtils } from './../../utils/promise-utils';
import { Injectable } from "@angular/core";
import { Vibration } from '@ionic-native/vibration/ngx';
import { ApiDef } from "../../../classes/app/api";
import { UserDataService } from "../../data/user";
import { INotificationContent, ENotificationType, INotificationDataChat } from "../../../classes/def/app/notifications";
import { IArenaNavParams } from "../../../classes/def/nav-params/activity";
import { UiExtensionService } from "../ui/ui-extension";
import { StorageOpsService } from "../data/storage-ops";
import { ELocalAppDataKeys } from "../../../classes/def/app/storage-flags";
import { MPGroupsHomePage } from 'src/app/pages/mp/mp-groups-home/mp-groups-home.page';
import { Messages } from 'src/app/classes/def/app/messages';
import os from 'onesignal-cordova-plugin';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { SettingsManagerService } from '../settings-manager';
import { EGroupContext } from 'src/app/classes/def/mp/groups';
import { NotificationWillDisplayEvent, NotificationClickEvent, OSNotification } from 'onesignal-cordova-plugin';
import { GeneralCache } from 'src/app/classes/app/general-cache';

// declare var os;
// interface NotificationWillDisplayEvent { [key: string]: any };
// interface NotificationClickEvent { [key: string]: any };
// interface OSNotification { [key: string]: any };

// 0 = NotificationClicked, 1 = ButtonClicked
export type OpenedEventActionType = 0 | 1;

/* N O T I F I C A T I O N S */
export interface OSNotificationV2 {
    body: string;
    sound?: string;
    title?: string;
    launchURL?: string;
    rawPayload: object;
    actionButtons?: object[];
    additionalData: object;
    notificationId: string;
    // android only
    groupKey?: string;
    groupMessage?: string;
    ledColor?: string;
    priority?: number;
    smallIcon?: string;
    largeIcon?: string;
    bigPicture?: string;
    collapseId?: string;
    fromProjectNumber?: string;
    smallIconAccentColor?: string;
    lockScreenVisibility?: string;
    androidNotificationId?: number;
    // ios only
    badge?: string;
    badgeIncrement?: string;
    category?: string;
    threadId?: string;
    subtitle?: string;
    templateId?: string;
    templateName?: string;
    attachments?: object;
    mutableContent?: boolean;
    contentAvailable?: string;
    relevanceScore?: number;
    interruptionLevel?: string;
}

/* N O T I F I C A T I O N   &   I A M   E V E N T S */
export interface NotificationReceivedEvent {
    complete: (notification?: OSNotificationV2) => void;
    getNotification: () => OSNotificationV2;
}

export interface OpenedEvent {
    action: OpenedEventAction;
    notification: OSNotificationV2;
}

export interface OpenedEventAction {
    type: OpenedEventActionType
}

/* D E V I C E */
export interface DeviceState {
    userId: string;
    pushToken: string;
    emailUserId: string;
    emailAddress: string;
    smsUserId: string;
    smsNumber: string;
    isSubscribed: boolean;
    isPushDisabled: boolean;
    isEmailSubscribed: boolean;
    isSMSSubscribed: boolean;
    hasNotificationPermission: boolean;
    notificationPermissionStatus?: PermissionStatus;  // ios only
    // areNotificationsEnabled (android) not included since it is converted to hasNotificationPermission in bridge
}

// 0 = NotDetermined, 1 = Denied, 2 = Authorized, 3 = Provisional, 4 = Ephemeral
export type PermissionStatus = 0 | 1 | 2 | 3 | 4;


@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    registered: boolean = false;
    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public vibration: Vibration,
        public settingsProvider: SettingsManagerService,
        public userData: UserDataService,
        public uiext: UiExtensionService,
        public storageOps: StorageOpsService
    ) {
        console.log("notifications service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }


    /**
     * check with local storage if the token has changed and only update it on the server if true
     * @param token 
     */
    registerToken(userToken: string) {
        this.storageOps.getLocalDataKey(ELocalAppDataKeys.pushId).then((data) => {
            let requireUpdate: boolean = ((data != null) && (data !== userToken)) || (data == null);
            if (requireUpdate) {
                this.registerTokenServer(userToken).then(() => {
                    this.storageOps.setStorageFlagNoAction({ flag: ELocalAppDataKeys.pushId, value: userToken });
                }).catch((err: Error) => {
                    console.error(err);
                });
            }
        }).catch((err) => {
            console.error(err);
            PromiseUtils.wrapNoAction(this.registerTokenServer(userToken), true);
        });
    }

    /**
     * save the token server-side and use it to push notifications to this device
     * also used to save the current device and add to the list of user devices in the database
     * @param userToken 
     */
    registerTokenServer(userToken: string) {
        return this.userData.updatePushNotificationsId(userToken);
    }

    enablePushNotifications(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (this.platform.WEB) {
                console.warn("push notifications not implemented in browser context");
                resolve(false);
                return;
            }
            if (this.registered) {
                console.warn('Push notifications already registered');
                resolve(true);
                return;
            }
            // let oneSignalAppId: string = ApiDef.oneSignalAppId;
            // let senderId: string = ApiDef.pushSenderId;
            console.log("enable push notifications");
            if (this.platform.WEB) {
                console.warn('Push notifications not initialized. Cordova is not available - Run in physical device');
                reject(new Error('Push notifications not initialized. Cordova is not available - Run in physical device'));
                return;
            }
            console.log("initializing onesignal");
            this.enablePushNotificationsCore().then((res) => {
                resolve(res);
            }).catch((err) => {
                reject(err);
            });

        });
        return promise;
    }

    /**
     * register to onesignal push notification service
     */
    enablePushNotificationsCore(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            let oneSignalAppId: string = ApiDef.oneSignalAppId;
            os.initialize(oneSignalAppId);
            os.Debug.setLogLevel(6); // verbose
            os.Debug.setAlertLevel(0); // none
            // os.login()        
            os.setConsentRequired(true);
            // show message about consent for push notifications (included in signup terms)
            os.setConsentGiven(true);
            os.Location.setShared(true);

            try {
                let permission: boolean = await os.Notifications.getPermissionAsync();
                console.log("onesignal has permissions: ", permission);
                if (!permission) {
                    let canRequest: boolean = await os.Notifications.canRequestPermission();
                    console.log("onesignal can request permissions: ", canRequest);
                    if (canRequest) {
                        let accepted: boolean = await os.Notifications.requestPermission(true);
                        console.log("User accepted notifications: " + accepted);
                    }
                }
                os.User.pushSubscription.optIn();
                console.log("opted in");
            } catch (err) {
                console.error(err);
            }

            os.Notifications.addEventListener("foregroundWillDisplay", (ev: NotificationWillDisplayEvent) => {
                console.log("incoming notification: ", ev);
                ev.preventDefault(); // don't show notification when in foreground (process the data in the app instead and handle display logic)
            });

            os.Notifications.addEventListener("click", (ev: NotificationClickEvent) => {
                console.log("notification opened: ", ev);
                let notification: OSNotification = ev?.notification;
                if (notification != null) {
                    this.handleNotification(notification.additionalData as any);
                }
            });

            os.User.addAlias("lp", "" + GeneralCache.userId);

            console.log("onesignal push subscription: ", os.User.pushSubscription);

            try {
                if (os.User && os.User.pushSubscription) {
                    let id: string = await os.User.pushSubscription.getIdAsync();
                    console.log("onesignal push subscription id: ", id);
                    this.registerToken(id);
                }
            } catch (err) {
                console.error(err);
            }

            this.registered = true;
            resolve(true);
        });
        return promise;
    }

    /**
     * handle notification on open
     * @param data 
     */
    handleNotification(data: INotificationContent) {
        if (data) {
            switch (data.type) {
                case ENotificationType.chat:
                    let chatNotificationData: INotificationDataChat = data.data;
                    let message: string = "<p>New message from your group";

                    if (chatNotificationData.playerName) {
                        message += ": " + chatNotificationData.playerName;
                    }
                    if (chatNotificationData.groupName) {
                        message += " via " + chatNotificationData.groupName;
                    } else {
                        message += " via " + "groupId: " + chatNotificationData.groupId;
                    }

                    message += "</p><p>Open a Meeting Place in World Map to connect.</p>";

                    this.uiext.showAlertNoAction(Messages.msg.mpNotification.after.msg, message);
                    // this.handleGroupChatNotification(chatNotificationData.groupId);
                    break;
            }
        }
    }

    /**
     * handle group chat notification, open group chat for the specified group id
     * @param groupId 
     */
    handleGroupChatNotification(groupId: number) {
        let params: IArenaNavParams = {
            place: null,
            group: null,
            meetingPlace: null,
            groupId: groupId,
            testing: true,
            groupRole: null,
            chatOnly: true,
            playerId: null,
            inRange: true,
            canExit: true,
            customMessage: "<p>Warning: Preview and chat only.</p><p>Connect to this group from a Meeting Place to join a multiplayer game.</p>",
            enableGroups: true,
            isStoryline: false,
            isLobbyContext: false,
            context: EGroupContext.global,
            contextId: null,
            synchronized: false
        };
        this.uiext.showCustomModal(null, MPGroupsHomePage, {
            view: {
                fullScreen: true,
                transparent: false,
                large: true,
                addToStack: true,
                frame: true
            },
            params: params
        }).then(() => {
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * clear onesignal notifications
     */
    clearOSNotifications() {
        if (this.platform.WEB) {
            console.warn("os notifications not available for web");
            return;
        }

        os.Notifications.clearAll();
    }
}
