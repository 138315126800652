import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IGameItem } from 'src/app/classes/def/items/game-item';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { IActivityParamDetails } from 'src/app/classes/def/nav-params/activity-details';
import { ModalController } from '@ionic/angular';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ParamHandler } from 'src/app/classes/general/params';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';

@Component({
  selector: 'modal-activity-params-vc',
  templateUrl: './activity-params-vc.component.html',
  styleUrls: ['./activity-params-vc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityParamsViewComponent implements OnInit, OnDestroy {
  title: string = "";
  items: IGameItem[] = null;
  loading: boolean = false;
  photoUrl: string = null;
  vs: IViewSpecs = ViewSpecs.getDefault();
  params: IActivityParamDetails = null;
  loaded: boolean = false;

  np: INavParams = null;
  appIconsStandard = EAppIconsStandard;

  constructor(
    public modalCtrl: ModalController,
    public resources: ResourcesCoreDataService,
    public analytics: AnalyticsService,
    public settingsProvider: SettingsManagerService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IActivityParamDetails = np.params;
      this.params = params;
      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);

      this.title = params.title;
      this.photoUrl = params.photoUrl;
      this.items = params.items;
      this.formatItems();

      this.loaded = true;
    }
  }

  ngOnDestroy() {

  }

  formatItems() {
    if (this.items) {
      for (let i = 0; i < this.items.length; i++) {
        let item: IGameItem = this.items[i];
        item.validCount = 1;
      }
    }
  }


  dismiss(value: number) {
    if (value == null) {
      value = EAlertButtonCodes.cancel;
    }
    setTimeout(() => {
      this.modalCtrl.dismiss(value).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  ok() {
    this.dismiss(EAlertButtonCodes.ok);
  }

  cancel() {
    this.dismiss(EAlertButtonCodes.cancel);
  }
}
