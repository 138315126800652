

import { IVideoRef } from "../media/videos";

export interface IStoryCategory {
    code: number;
    baseCode?: number;
    mode: number;
    modeLabel?: string;
    name: string;
    description: string;
    icon: string;
    photo: number;
    photoUrl?: string;
    canvasUrl?: string;
    canvasLoaded?: boolean;
    photoUrlLoaded?: string;
    videoCode?: number;
    video?: IVideoRef;
}

export enum ECategoryCodes {
    explore = 0,
    sport = 1,
    casual = 2,
    private = -1,
    global = null,
    stats = -2
}

export enum ECategoryModes {
    global = 0,
    local = 1
}
