import { Component, OnDestroy, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { IFeatureDef, EFeatureTypes, EFeatureCode } from 'src/app/classes/def/app/app';
import { Platform } from '@ionic/angular';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { TimeUtils } from 'src/app/classes/general/time';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { IGmapEntryNavParams, EGmapMode } from 'src/app/classes/def/nav-params/gmap';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { PremiumDataService } from 'src/app/services/data/premium';
import { ERouteDef } from 'src/app/app-utils';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { Router } from '@angular/router';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-extras',
  templateUrl: './extras.page.html',
  styleUrls: ['./extras.page.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0
      })),
      state('active', style({
        // transform: 'translateY(0)',
        opacity: 1
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})

export class ExtrasPage implements OnInit, OnDestroy {
  categories: IFeatureDef[] = [];
  loaded: boolean = false;
  showState: string = "inactive";
  timeout = null;
  reload: boolean = true;

  theme: string = "theme-light theme-light-bg";

  isModal: boolean = false;
  animate: boolean = true;

  np: INavParams = null;
  vs: IViewSpecs;
  headerClass: string = "";

  constructor(
    public router: Router,
    public settingsProvider: SettingsManagerService,
    public zone: NgZone,
    public plt: Platform,
    public webView: WebviewUtilsService,    
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public backButton: BackButtonService,
    public analytics: AnalyticsService,
    public premiumProvider: PremiumDataService,
    public tutorials: TutorialsService,
    public nps: NavParamsService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  swipeEvent(_e) {
    // this.backButton.handleSwipeEvent(e);
  }

  ngOnInit() {
    this.analytics.trackView("extras");

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.events, null, this.np);

      console.log("nav params: ", npInfo.params);

      let hasParams: boolean = npInfo.hasParams;

      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
          // this.theme = Constants.theme["aubergine"].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      if (hasParams) {
        let np: INavParams = npInfo.params;
        console.log(np);
        this.animate = false;
        this.showState = 'active';
      }

      this.webView.ready().then(() => {
        this.backButton.replace(() => {
          this.back();
        });
      }).catch((err: Error) => {
        console.error(err);
      });

      this.loadData(this.reload).then(() => {
        this.loaded = true;
        if (this.animate) {
          this.showState = 'active';
        }
      }).catch((err: Error) => {
        this.loaded = true;
        this.analytics.dispatchError(err, "extras");
        this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
      });
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  ngOnDestroy() {
    console.log("destroy");
    this.clearWatch();
  }

  back() {
    this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  doRefresh() {
    this.loadData(true).then(() => {
      this.loaded = true;
    }).catch((err: Error) => {
      this.loaded = true;
      this.analytics.dispatchError(err, "extras");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }

  public loadData(reload: boolean) {
    let promise = new Promise((resolve, reject) => {
      this.loaded = false;
      this.premiumProvider.getFeatureDef(reload).then((feats: IFeatureDef[]) => {
        this.loaded = true;
        // show only persistent (unlockable) features
        this.categories = feats.filter(f => f.type === EFeatureTypes.unlockable && f.list);
        console.log(feats);
        resolve(feats);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }


  clearWatch() {
    ResourceManager.clearTimeout(this.timeout);
    this.timeout = null;
  }


  showLockedTemp(feat: IFeatureDef) {
    let promise = new Promise((resolve, reject) => {
      console.log("promise show locked");
      let msgInfo: string = "<p>Release date: " + TimeUtils.formatDateDisp(feat.releaseDate) + "</p><p>Global: " + (feat.global ? "Yes" : "No") + "</p>";
      this.uiext.showAlert(Messages.msg.featureLockedTemp.after.msg, msgInfo, 1, ["ok"]).then(() => {
        resolve(false);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  openFeature(feat: IFeatureDef): void {
    console.log(feat);
    if (!feat) {
      return;
    }

    let promiseUnlock: Promise<boolean>;

    if (!feat.available) {
      promiseUnlock = new Promise((resolve, reject) => {
        if (feat.unlocked) {
          // already unlocked, but not available in this context
          console.log("show locked 1");
          this.showLockedTemp(feat).then((res: boolean) => {
            resolve(res);
          }).catch((err: Error) => {
            reject(err);
          });
        } else {
          if (feat.available1) {
            // available to unlock
            this.uiext.showAlert(Messages.msg.featureLocked.after.msg, Messages.msg.featureLocked.after.sub, 2, ["Dismiss", "Unlock"]).then((res: number) => {
              switch (res) {
                case EAlertButtonCodes.ok:
                  this.premiumProvider.requestPremiumFeature(feat.code, null).then((_data) => {
                    resolve(true);
                  }).catch((err: Error) => {
                    reject(err);
                  });
                  break;
                default:
                  reject(new Error("locked"));
                  break;
              }
            }).catch((err: Error) => {
              reject(err);
            });
          } else {
            // not available in this context (should not attempt to unlock with coins)
            console.log("show locked 2");
            this.showLockedTemp(feat).then((res: boolean) => {
              resolve(res);
            }).catch((err: Error) => {
              reject(err);
            });
          }
        }
      });
    } else {
      promiseUnlock = Promise.resolve(true);
    }

    promiseUnlock.then((unlocked: boolean) => {
      if (unlocked) {
        switch (feat.code) {
          case EFeatureCode.worldEditorMode:
            if (this.animate) {
              this.showState = "inactive";
            }
            this.timeout = setTimeout(() => {
              this.goToMapEditor();
            }, this.animate ? AppConstants.animationTime : 0);
            break;
          case EFeatureCode.events:
            this.router.navigate([ERouteDef.eventsList], { replaceUrl: true }).then(() => {
            }).catch((err: Error) => {
              console.error(err);
            });
            break;
          default:
            this.uiext.showAlertNoAction(Messages.msg.featureUnlocked.after.msg, Messages.msg.featureUnlocked.after.sub);
            this.doRefresh();
            break;
        }
      }
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "extras");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
    });
  }


  goToMapEditor() {
    let params: IGmapEntryNavParams = {
      mode: EGmapMode.editor
    };

    let navParams: INavParams = {
      view: null,
      params: params
    };


    this.nps.set(ENavParamsResources.gmap, navParams);

    this.router.navigate([ERouteDef.gmap], { replaceUrl: true }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      tutorial: {
        name: "Tutorial",
        code: 1,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      },
      reload: {
        name: "Reload",
        code: 2,
        icon: EAppIconsStandard.refresh,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((code: number) => {
      switch (code) {
        case 1:
          this.onHelpClick();
          break;
        case 2:
          this.doRefresh();
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  onHelpClick() {
    this.tutorials.showTutorialNoAction("Extras Tutorial", ETutorialEntries.extras, null, null, true);
  }
}
