<div *ngIf="availableItems">
  <ion-grid class="full-w">
    <ion-row *ngFor="let itemRow of itemGrid; let i = index">
      <ion-col *ngFor="let item of itemRow" class="padding-s">
        <game-item class="icon-contrast" [data]="item" [interact]="interact" [basicView]="basicView" [reload]="reload"
          [blinkTimerSub]="blinkTimerSub" [enableLockedDescription]="enableLockedDescription"
          (action)="itemAction($event, item)"></game-item>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<div *ngIf="!availableItems" class="padding-s center-text">
  <span class="center-text info-text-m bold text-color-primary">{{emptyMessage}}</span>
</div>