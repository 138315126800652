import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { EPhotos } from 'src/app/classes/def/app/icons';
import { Platform } from '@ionic/angular';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { Messages } from 'src/app/classes/def/app/messages';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.page.html',
  styleUrls: ['./landing.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LandingPage implements OnInit, OnDestroy {
  theme: string = "theme-light theme-light-bg";

  logoSrc: string = EPhotos.logo;
  // logoSrc: string = EPhotos.bike;
  backgroundSrc: string = EPhotos.home;
  periodic = null;
  backgroundLoaded: boolean = false;
  vs: IViewSpecs;

  constructor(
    public plt: Platform,
    public webView: WebviewUtilsService,
    public uiext: UiExtensionService,
    public backButton: BackButtonService,
    public settingsProvider: SettingsManagerService
  ) {
    this.webView.ready().then(() => {

      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      this.backButton.replace(() => {
        if (!this.uiext.isAlert()) {

          this.uiext.showAlert(Messages.msg.exit.before.msg, Messages.msg.exit.before.sub, 2, null, false).then((res: number) => {
            if (res === EAlertButtonCodes.ok) {
              this.backButton.exitApp();
            }
          }).catch(() => {
          });
        }
      });

    }).catch((err: Error) => { console.error(err); });
  }


  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }
  
  ngOnInit() {
    console.log('ion view will enter ' + new Date().getTime());
  }

  ngOnDestroy() {
    console.log("ion view will leave " + new Date().getTime());
    // if (this.periodic) {
    //   clearTimeout(this.periodic);
    //   this.periodic = null;
    // }
  }

}

