<div>
  <div *ngIf="dataSource">
    <mat-table #table [dataSource]="dataSource" ion-scroll style="height: 100%" class="transparent-bg">
      <!-- Name Column -->
      <ng-container matColumnDef="Name">
        <mat-header-cell *matHeaderCellDef> name </mat-header-cell>
        <mat-cell *matCellDef="let element" class="mat-column-first text-color-primary info-text-m"> {{element.name}}
        </mat-cell>
      </ng-container>
      <!-- Value Column -->
      <ng-container matColumnDef="Value">
        <mat-header-cell *matHeaderCellDef> value </mat-header-cell>
        <mat-cell *matCellDef="let element" class="mat-column-last text-color-primary info-text-m">
          {{element.valueString}}
        </mat-cell>
      </ng-container>
      <!-- <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row> -->
      <mat-row *matRowDef="let data; columns: displayedColumns;" class="mat-row-format"></mat-row>
    </mat-table>
    <!-- <mat-paginator #paginator [pageSize]="3" [pageSizeOptions]="[3, 5]">
  </mat-paginator> -->
    <!-- </ion-scroll> -->
  </div>
</div>