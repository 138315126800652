import { Component, ViewChild, ElementRef, OnInit, OnDestroy, AfterViewInit } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { IonInput, ModalController } from '@ionic/angular';
import { ParamHandler } from 'src/app/classes/general/params';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { ITextInputParams } from '../text-input/text-input.component';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';

@Component({
  selector: 'modal-text-input-core',
  templateUrl: './text-input-core.component.html',
  styleUrls: ['./text-input-core.component.scss'],
})
export class TextInputCoreViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('input', { read: ElementRef, static: false }) myInput: IonInput;
  title: string = "";
  text: string;
  timeout;
  focused: boolean = false;
  vs: IViewSpecs = ViewSpecs.getDefault();
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  np: INavParams = null;

  maxLength: number = EMessageTrim.userInput;
  description: string;

  okLabel: string = null;
  allowEmpty: boolean = true;
  enableAction: boolean = true;

  constructor(
    public viewCtrl: ModalController
  ) {

  }

  ngOnInit() {

    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: ITextInputParams = np.params;
      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);

      this.text = params.text;
      this.title = params.title;
      if (!params.description) {
        this.description = "Type a description";
      } else {
        this.description = params.description;
      }

      if (params.maxLength) {
        this.maxLength = params.maxLength;
      }

      this.okLabel = params.okLabel;
      this.allowEmpty = params.allowEmpty;
      this.checkEnableAction();
    }
  }

  ngAfterViewInit() {
    if (!this.focused) {
      this.timeout = setTimeout(() => {
        if (this.myInput) {
          // this.keyboard.show() // for android
          this.myInput.setFocus();
          // this.myInput.nativeElement.setFocus();
        }
        this.focused = true;
      }, 350);
    }
  }

  reviewChange(_event: any) {
    setTimeout(() => {
      this.checkEnableAction();
    }, 1);
  }

  checkEnableAction() {
    if (!this.allowEmpty) {
      if (this.text != null && this.text.length > 0) {
        this.enableAction = true;
      } else {
        this.enableAction = false;
      }
    }
  }

  ngOnDestroy() {
    this.timeout = ResourceManager.clearTimeout(this.timeout);
  }

  dismiss(text: string) {
    this.viewCtrl.dismiss(text).then(() => {
    }).catch(() => {
    });
  }

  clear() {
    this.text = "";
  }

  cancel() {
    this.dismiss(null);
  }

  ok() {
    let text: string = null;
    if (this.text) {
      text = this.text;
    }
    this.dismiss(text);
  }

  setFocused() {
    this.focused = true;
  }

  setBlurred() {
    this.focused = false;
  }
}
