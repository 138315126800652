import { IButtonOptions } from 'src/app/classes/def/app/ui';
import { EDroneMode } from '../data/activities';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { EGmapDetailReturnCode } from 'src/app/classes/def/nav-params/gmap';

export class OtherUtils {
    static copyToClipboard(val: string) {
        const selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = val;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }

    /**
     * check best possible array split from provided options and min spec
     * @param val 
     * @param min 
     * @param options 
     */
    static checkDivSpec(val: number, min: number, options: number[]) {
        let divSpec = 0;
        let divSpecExact = 0;
        let ltg = false;
        let maxOpt = options[0];
        for (let i = 0; i < options.length; i++) {
            let ds = options[i];
            if (ds >= val) {
                ltg = true;
            }
            if (ds > maxOpt) {
                maxOpt = ds;
            }
            let rem = val - Math.floor(val / ds) * ds;
            if (rem >= min) {
                // valid spec
                if (ds > divSpec) {
                    divSpec = ds;
                }
            }
            if (rem === 0) {
                if (ds > divSpecExact) {
                    divSpecExact = ds;
                }
            }
        }
        if (divSpecExact > 0 && ((divSpecExact > divSpec) || ltg)) {
            divSpec = divSpecExact;
        }
        if (divSpec === 0) {
            divSpec = val;
        }
        if (divSpec > maxOpt) {
            divSpec = maxOpt;
        }
        return divSpec;
    }

    static checkDefinedPhotoUrl(photoUrl: string) {
        return [null, undefined, ""].indexOf(photoUrl) === -1;
    }

    static buildDroneModeButtons(withDrone: number) {
        let buttons: IButtonOptions[] = [];
        switch (withDrone) {
            case EDroneMode.withDrone:
                buttons = [
                    {
                        name: "GPS",
                        code: EGmapDetailReturnCode.gps,
                        icon: EAppIcons.gpsFixed,
                        customIcon: true,
                        class: "action-button-fill button-color-accent",
                        size: 6,
                        disabled: false,
                        enabled: true,
                        highlight: null,
                        callback: null
                    },
                    {
                        name: "Virtual",
                        code: EGmapDetailReturnCode.drone,
                        icon: EAppIcons.drone,
                        customIcon: true,
                        class: "action-button-fill button-color-alternate",
                        size: 6,
                        disabled: false,
                        enabled: true,
                        highlight: null,
                        callback: null
                    }
                ];
                break;
            case EDroneMode.onlyDrone:
                buttons = [
                    {
                        name: "Virtual",
                        code: EGmapDetailReturnCode.drone,
                        icon: EAppIcons.drone,
                        customIcon: true,
                        class: "action-button-fill button-color-accent",
                        size: 12,
                        disabled: false,
                        enabled: true,
                        highlight: null,
                        callback: null
                    }
                ];
                break;
            default:
                buttons = [
                    {
                        name: "Start",
                        code: EGmapDetailReturnCode.gps,
                        icon: EAppIconsStandard.map,
                        customIcon: false,
                        class: "action-button-fill button-color-accent",
                        size: 12,
                        disabled: false,
                        enabled: true,
                        highlight: null,
                        callback: null
                    }
                ];
                break;
        }
        return buttons;
    }
}