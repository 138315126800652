
import { Injectable } from "@angular/core";
import { ResourceManager } from "../../../../classes/general/resource-manager";
import { TimeoutService } from "../timeout";
import { LocalNotificationsService } from "../../../general/apis/local-notifications";
import { BehaviorSubject } from "rxjs";
import { ECheckActivityResult, EActivityCodes } from "../../../../classes/def/core/activity";
import { PhotoValidatorService } from "../../utils/photo-validator";
import { IPhotoResultResponse } from "../../../../classes/def/media/processing";
import { IPhotoActivityParams, IPhotoActivityStatus } from 'src/app/classes/def/activity/photo';
import { EPhotoValidatorModes, IPhotoValidatorParams } from 'src/app/classes/def/media/photo-validator';
import { ITimeoutMonitorParams, ITimeoutMonitorData, ETimeoutStatus } from 'src/app/classes/general/timeout';
import { IMultiPhotoUploadSpec } from "src/app/classes/def/story/progress";


export interface IPhotoActivityGridStats {
    gridFilled: boolean;
    gridPhotoValidated: boolean;
    gridPhotoUploadCount: number;
}

@Injectable({
    providedIn: 'root'
})
export class PhotoActivityService {

    subscription = {
        timeoutActivityMonitor: null
    };

    gridStatsInit: IPhotoActivityGridStats = {
        gridFilled: false,
        gridPhotoValidated: false,
        gridPhotoUploadCount: 0
    };

    gridStats: IPhotoActivityGridStats = {} as any;

    photoValidated: boolean = false;
    photoResultResponse: IPhotoResultResponse;
    params: IPhotoActivityParams;
    moduleName: string = "PHOTO ACTIVITY > ";
    statusObservable: BehaviorSubject<IPhotoActivityStatus>;
    activityFinished: boolean = false;

    constructor(
        public timeoutMonitor: TimeoutService,
        public localNotifications: LocalNotificationsService,
        public photoValidator: PhotoValidatorService
    ) {
        console.log("photo activity service created");
        this.statusObservable = new BehaviorSubject(null);
        this.initGridStats();
    }

    initGridStats() {
        this.gridStats = Object.assign({}, this.gridStatsInit);
    }

    getGridStats() {
        return this.gridStats;
    }

    watchStatus() {
        return this.statusObservable;
    }

    getPhotoResultResponse() {
        return this.photoResultResponse;
    }

    /**
     * check the type of photo activity
     * get the required params for the photo validator
     * @param started 
     * @param isRetry 
     * @param init 
     */
    private getValidatorParams(init: IPhotoActivityParams, started: boolean, isRetry: boolean) {
        let mode: number = this.getPhotoValidatorMode(init.activity.code);
        // let withReference: boolean = ([EPhotoValidatorModes.compareDual].indexOf(mode) !== -1);
        let referenceUrl: string = (init.referencePhotoUrl != null) ? init.referencePhotoUrl : init.loc.merged.photoUrl;
        let validatorParams: IPhotoValidatorParams = {
            enable: started && !init.photoValidated,
            intro: !isRetry,
            mode: mode,
            referenceUrl: referenceUrl,
            objects: init.activity ? init.activity.customParams : [],
            isGooglePhotoExt: !init.referencePhotoUrl
        };
        return validatorParams;
    }

    private getPhotoValidatorMode(activityCode: number) {
        let mode: number = 0;
        switch (activityCode) {
            case EActivityCodes.photo:
                mode = EPhotoValidatorModes.detectObject;
                break;
            case EActivityCodes.snapshot:
                mode = EPhotoValidatorModes.compareDual;
                break;
            default:
                mode = EPhotoValidatorModes.compareSingleWithActivityRequired;
                break;
        }
        return mode;
    }

    /**
     * validate photo activity
     * called externally
     * @param isRetry 
     * @param preview 
     */
    validatePhoto(isRetry: boolean, uploadFromGallery: boolean): Promise<IPhotoResultResponse> {
        let promise: Promise<IPhotoResultResponse> = new Promise((resolve, reject) => {
            this.photoResultResponse = null;
            if (!this.params) {
                reject(new Error("Cannot upload a new photo in preview mode. You have to be in the required location and start the challenge first."));
                return;
            }

            let validatorParams: IPhotoValidatorParams = this.getValidatorParams(this.params, true, isRetry);
            validatorParams.uploadFromGallery = uploadFromGallery;
            this.photoValidator.checkPhotoActivity(this.params.storyId, this.params.storyLocationId, this.params.activity, this.params.loc.merged.shortDescription, validatorParams).then((res: IPhotoResultResponse) => {
                console.log("check photo activity complete: ", res.valid);
                this.photoValidated = res.valid;
                this.photoResultResponse = res;
                resolve(res);
            }).catch((err: Error) => {
                this.photoValidated = false;
                reject(err);
            });
        });
        return promise;
    }

    /**
     * validate photo activity
     * called externally
     * @param isRetry 
     * @param preview 
     */
    validatePhotoGrid(photoUploads: IMultiPhotoUploadSpec[]): Promise<IPhotoActivityGridStats> {
        let promise: Promise<IPhotoActivityGridStats> = new Promise((resolve) => {
            let uploadCount: number = 0;
            this.gridStats.gridFilled = true;
            // check for non-filled containers
            for (let photo of photoUploads) {
                if (!(photo.data || photo.url)) {
                    this.gridStats.gridFilled = false;
                } else {
                    uploadCount += 1;
                    this.gridStats.gridPhotoValidated = true;
                }
            }
            this.gridStats.gridPhotoUploadCount = uploadCount;
            console.log("validate photo grid filled: ", this.gridStats.gridFilled);
            if (this.gridStats.gridFilled) {
                this.photoResultResponse = null;
                this.photoValidated = true;
            }
            resolve(this.gridStats);
        });
        return promise;
    }


    /**
     * handle timeout activity
     * for photo centric activities (detectObject)
     * will close after photo validation is complete
     * for photo aux activities (comparePhoto)
     * will keep (the timer) running until complete
     */
    initActivity(params: IPhotoActivityParams) {
        console.log(this.moduleName + "init");
        if (!this.subscription.timeoutActivityMonitor) {
            console.log(this.moduleName + "init started");

            this.params = params;
            console.log(params);

            this.statusObservable.next(null);
            this.initGridStats();

            let tmParams: ITimeoutMonitorParams = {
                timeLimit: params.timeLimit
            };

            this.timeoutMonitor.start(tmParams);
            this.activityFinished = false;


            let status: IPhotoActivityStatus = {
                tmData: null,
                status: ECheckActivityResult.inProgress
            };

            // allow re-upload until the time is up
            let finishOnPhotoValidate: boolean = false;

            // let mode: number = this.getPhotoValidatorMode(params.activity.code);
            // switch (mode) {
            //     case EPhotoValidatorModes.detectObject:
            //     case EPhotoValidatorModes.compareSingleWithActivityRequired:
            //         finishOnPhotoValidate = true;
            //         break;
            // }

            this.subscription.timeoutActivityMonitor = this.timeoutMonitor.getWatch().subscribe((tmData: ITimeoutMonitorData) => {
                if (tmData) {
                    // console.log(tmData);

                    if (!tmData.isFallbackTimer) {
                        status.tmData = tmData;
                    }

                    if (this.photoValidated && finishOnPhotoValidate) {
                        this.activityFinished = true;
                        status.status = ECheckActivityResult.done;
                        this.statusObservable.next(status);
                    }

                    switch (tmData.status) {
                        case ETimeoutStatus.expired:
                            if (this.photoValidated) {
                                status.status = ECheckActivityResult.done;
                            } else {
                                status.status = ECheckActivityResult.failed;
                            }
                            this.statusObservable.next(status);
                            this.activityFinished = true;
                            break;
                    }

                    if (this.activityFinished) {
                        this.subscription.timeoutActivityMonitor = ResourceManager.clearSub(this.subscription.timeoutActivityMonitor);
                        console.log("stopping timer");
                        this.timeoutMonitor.stop();
                    } else {
                        this.statusObservable.next(status);
                    }
                    console.log("photo activity status: ", status);
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    exitActivity() {
        console.log(this.moduleName + "exit");
        this.photoValidator.cancelPhotoValidate();
        this.subscription = ResourceManager.clearSubObj(this.subscription);
        console.log("stopping timer");
        this.timeoutMonitor.stop();
        this.photoValidated = false;
        this.activityFinished = false;
    }

}




