<modal-header-container [vs]="vs">
  <leplace-toolbar leftButton="close" [leftIcon]="true" rightButton="information-circle" [rightIcon]="true"
    [title]="title" (leftClick)="dismiss()" (rightClick)="showDescription()"
    [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div class="scrollbar-y places-list">
    <ion-list *ngIf="hasItems" class="list-padding">
      <ion-item *ngFor="let place of locationItems; let i = index;" class="list-item transparent-bg" no-lines>
        <place-items-card class="full-w" *ngIf="place" [locationItem]="place" tappable [crop]="true"
          (click)="getDetails(i)"></place-items-card>
      </ion-item>
    </ion-list>
    <div *ngIf="!hasItems" class="padding-s center-text">
      <span class="center-text info-text-m bold text-color-primary">No items found</span>
    </div>
  </div>
</modal-container>