import { IPlatformFlags } from "./platform";
import { EHudContext } from "./hud";

export interface ISettings {
  platform: number;
  platformFlags: IPlatformFlags;
  app: IAppSettings;
}

export enum ESettingsType {
  checkbox = 0,
  input = 1,
  select = 2,
  extFlag = 11
}

export enum ESettingsCode {
  theme = 1,
  showUserMarker = 2,
  showUserMarkerMp = 3,
  preset = 4,
  serviceUrls = 5,
  basePath = 6,
  savePath = 7,
  useCustomEventLogger = 101,
  useRequestLogger = 102,
  useRemoteConsoleLogger = 103
}

export enum EStatCache {
  droneBatteryLevel = 1001
}

export enum EServerInitStatus {
  none = 0,
  appServer = 1,
  serverApp = 2,
  sync = 3,
  syncDone = 4
}

/**
 * the generic group definitions
 */
export interface ISettingsGroupsContainer {
  appExtras: ISettingsGroupsElement;
  app: ISettingsGroupsElement;
  options: ISettingsGroupsElement;
  optionsExtra: ISettingsGroupsElement;
}

/**
 * the generic group element definitions
 */
export interface ISettingsGroupsElement {
  name: string;
  position: number;
}

/**
 * settings groups definitions
 * name, position
 */
export class SettingsGroupsDef {
  public static groups: ISettingsGroupsContainer = {
    appExtras: {
      name: "App Extra",
      position: 2
    },
    app: {
      name: "App",
      position: 1
    },
    options: {
      name: "Options",
      position: 3
    },
    optionsExtra: {
      name: "Extra Options",
      position: 4
    }
  };

  /**
   * get the group definition by group name
   * @param name 
   */
  public static getGroupByName(name: string) {
    let groups: ISettingsGroupsContainer = SettingsGroupsDef.groups;
    let keys: string[] = Object.keys(groups);
    for (let i = 0; i < keys.length; i++) {
      let key: string = keys[i];
      if (groups[key].name === name) {
        return groups[key];
      }
    }
    return null;
  }

  /**
   * get the position of the group by name
   * @param name 
   */
  public static getGroupPosition(name: string) {
    let g: ISettingsGroupsElement = SettingsGroupsDef.getGroupByName(name);
    // console.log(name, g);
    if (g) {
      return g.position;
    }
    return 0;
  }
}

/**
 * boolean
 */
export interface IBooleanSettings extends IAnySettings {
  name: string;
  value: boolean;
  iosValue?: boolean;
  type: number;
  codeOptions: ISettingsCodeOptions;
}

/**
 * numeric
 */
export interface INumericSettings extends IAnySettings {
  name: string;
  value: number;
  iosValue?: number;
  type: number;
  codeOptions: ISettingsCodeOptions;
}

/**
 * select
 */
export interface ISelectSettings extends IAnySettings {
  name: string;
  value: any;
  iosValue?: any;
  type: number;
  codeOptions: ISettingsCodeOptions;
  options: ISelectOptions;
  aux?: ISettingsAux;
}

/**
 * any
 */
export interface IAnySettings {
  name: string;
  value: any;
  iosValue?: any;
  oldValue?: any;
  type: any;
  /**
   * internal options that may be updated on sync
   */
  codeOptions: ISettingsCodeOptions;
  /**
   * select options
   */
  options?: ISelectOptions;
  optionsList?: ISelectOptionsElement[];
  aux?: ISettingsAux;
}

/**
 * aux params for settings
 */
export interface ISettingsAux {
  dispName: string;
  oldValue: any;
  initValue: any;
}

/**
 * options element under select settings
 */
export interface ISelectOptionsElement {
  name: string;
  value: any;
}

/**
 * options container under select settings
 */
export interface ISelectOptions {
  [key: string]: ISelectOptionsElement;
}

/**
 * options that are defined in the code (and may be changed easily at next update)
 */
export interface ISettingsCodeOptions {
  group: string;
  devOnly: boolean;
  androidOnly?: boolean;
  iosOnly?: boolean;
  /**
   * the uid in the app
   */
  code: number;
  /**
   * specify whether the option from local storage is reset to default at build update
   */
  reset: boolean;
  /**
   * specify if the setting was synced with the server e.g. showing map marker in mp
   */
  serverSync: number;
  /**
   * the display position in the list
   */
  position: number;
  /**
   * requires app restart e.g. audio guide
   */
  requiresAppRestart?: boolean;
  icon?: string;
  customIcon?: boolean;
}

export interface IAppLocalSettings {
  mapDebugMode: boolean;
  ARDemoMode: boolean;
  disableARTicker: boolean;
  useWebkitCompass: boolean;
}

/**
 * container for app settings
 */
export interface IAppSettings {
  /**
   * the build number is used to update and sync new settings
   */
  build: number;
  settings: IAppSettingsContent;
}

/**
 * grouped settings that are shown in the settings view
 */
export interface IGroupedSettings {
  group: string;
  position: number;
  settings: IAnySettings[];
}

/**
 * the interface that contains all the settings definitions
 */
export interface IAppSettingsContent {
  markerLocatingMode: IBooleanSettings;
  treasuresInStoryline: IBooleanSettings;
  ARExploreAutoCollect: IBooleanSettings;
  sensorStreamingAR: IBooleanSettings;
  useRequestLogger: IBooleanSettings;
  useEventLogger: IBooleanSettings;
  useRemoteConsoleLogger: IBooleanSettings;
  noActivityValidation: IBooleanSettings;
  keepScreenOn: IBooleanSettings;
  moveActivityTest: IBooleanSettings;
  locationInterpolate: IBooleanSettings;
  autoStart: IBooleanSettings;
  speedMode: ISelectSettings;
  theme: ISelectSettings;
  locationMode: ISelectSettings;
  units: ISelectSettings;
  useProfilePictureOnTheMap: IBooleanSettings;
  useProfilePictureOnTheMapMP: IBooleanSettings;
  mapDensityFiltering: IBooleanSettings;
  vibrateAlways: IBooleanSettings;
  hudMode: ISelectSettings;
  fadeLockedStoryLocations: IBooleanSettings;
  testObjectAddMode: ISelectSettings;
  ARCalibrationMode: ISelectSettings;
  smartMapNav: IBooleanSettings;
  useLocalServiceUrl: IBooleanSettings;
  enableTutorial: IBooleanSettings;
  ARVisibleObjects: ISelectSettings;
  ARFieldOfView: ISelectSettings;
  ARCalibrationFiltering: ISelectSettings;
  useMarkerClustering: IBooleanSettings;
  smartMapSelect: IBooleanSettings;
  backgroundMode: IBooleanSettings;
  onScreenHomeButtonFix: IBooleanSettings;
  settingsPreset: ISelectSettings;
  useDebugServiceUrls: IBooleanSettings;
  ARFixedScene: IBooleanSettings;
  ARHighQuality: IBooleanSettings;
  ARGimbalLockLevel: ISelectSettings;
  ARObjectHeightAdjust: INumericSettings;
  ARRecordDual: IBooleanSettings;
  AREnableScaling: IBooleanSettings;
  ARPauseDisplayLoopEnable: IBooleanSettings;
  ARScale: ISelectSettings;
  ARShortcut: IBooleanSettings;
  showScanCooldown: IBooleanSettings;
  autoBgMode: IBooleanSettings;
  useDefaultPlacePhotos: IBooleanSettings;
  showQuestPreview: IBooleanSettings;
  fileSavePath: ISelectSettings;
  fileBasePath: ISelectSettings;
  worldEditorShowLockedOnly: IBooleanSettings;
  includeNearbyScan: IBooleanSettings;
  expandTreasuresMode: IBooleanSettings;
  showMarkerIds: IBooleanSettings;
  tts: IBooleanSettings;
  ttsMute: IBooleanSettings;
  enableWalkthroughs: IBooleanSettings;
  HDPIMode: IBooleanSettings;
  useExternalBgModeNotification: IBooleanSettings;
  useHiddenBgModeNotification: IBooleanSettings;
  disableTestAds: IBooleanSettings;
  enableStripeCheckoutAlways: IBooleanSettings;
  droneSimulationRate: ISelectSettings;
  uploadFromGallery: IBooleanSettings;
  hideDevStories: IBooleanSettings;
  AREngine: ISelectSettings;
}



export enum ESettingsPreset {
  /**
   * the custom preset is selected automatically
   * when a setting is changed that regards the group of settings included in any preset
   * it allows for not overriding custom settings
   */
  custom = 2,
  /**
   * initial/normal settings that regards the group of settings included in the preset definition
   */
  normal = 0,
  /**
   * used for screencast (video making)
   */
  demo = 1
}

export class SettingsPreset {
  /**
   * set individual settings based on a selected preset
   * @param preset 
   * @param storedSettings 
   * @param codeSettings 
   */
  static syncSettingsWithPreset(preset: number, storedSettings: IAppSettingsContent, codeSettings: IAppSettingsContent, localSettings: IAppLocalSettings) {
    switch (preset) {
      case ESettingsPreset.normal:
        storedSettings.hudMode.value = codeSettings.hudMode.value;
        storedSettings.useProfilePictureOnTheMap.value = codeSettings.useProfilePictureOnTheMap.value;
        storedSettings.enableTutorial.value = codeSettings.enableTutorial.value;
        storedSettings.showScanCooldown.value = codeSettings.showScanCooldown.value;
        storedSettings.useDefaultPlacePhotos.value = codeSettings.useDefaultPlacePhotos.value;
        localSettings.ARDemoMode = false;
        localSettings.disableARTicker = false;
        localSettings.mapDebugMode = false;
        // localSettings.useWebkitCompass = true;
        break;
      case ESettingsPreset.demo:
        storedSettings.hudMode.value = EHudContext.auto;
        storedSettings.useProfilePictureOnTheMap.value = false;
        storedSettings.enableTutorial.value = false;
        storedSettings.showScanCooldown.value = false;
        storedSettings.useDefaultPlacePhotos.value = false;
        localSettings.ARDemoMode = true;
        localSettings.disableARTicker = true;
        localSettings.mapDebugMode = true;
        // localSettings.useWebkitCompass = false;
        break;
    }
  }
}



export interface IUserFlagUpdate {
  flag: number;
  value: number;
}

export interface IUserFlagDB {
  id: number;
  code: number;
  typeCode: number;
  name: string;
  value: number;
}

export enum EUserFlagDB {
  removeAds = 10
}
