import { IGetPhotoOptions, IPlacePhotoExt, ILocationUtilsOptions, ILocationPhotoResult, LocationUtilsDef } from "./location-utils-def";
import { LocationUtilsHelpers } from "./location-utils-helpers";
import { EMarkerIcons } from "../../classes/def/app/icons";
import { IPlaceExtContainer as IPlaceUnifiedContainer } from "../../classes/def/places/container";
import { EPlaceUnifiedSource } from "../../classes/def/places/provider";
import { ELocationFlag } from "../../classes/def/places/app-location";


export class LocationUtilsGoogle {

    static options: ILocationUtilsOptions = {
        useTempUrlGoogle: true
    };


    /**
     * convert to unified place data
     */
    static load(place: google.maps.places.PlaceResult) {
        let puc: IPlaceUnifiedContainer = {
            providerCode: EPlaceUnifiedSource.google,
            type: place.types[0],
            flag: ELocationFlag.RANDOM,
            name: place.name,
            id: null,
            googleId: place.place_id,
            photoUrl: null,
            isGenericPhotoLoaded: false,
            rating: place.rating,
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            reviewCount: place.reviews ? place.reviews.length : 0,
            aux: {
                photoUrlFn: place.photos ? place.photos[0] : null,
                photos: place.photos
            }
        };
        return puc;
    }


    static getPlacePhoto(location: google.maps.places.PlaceResult, options: IGetPhotoOptions) {
        return LocationUtilsGoogle.getPlacePhotoCore(location.photos, options);
    }


    /**
     * get google place photo url
     * @param photos 
     * @param noPlaceholder 
     */
    static getPlacePhotoCore(photos: google.maps.places.PlacePhoto[], options: IGetPhotoOptions): ILocationPhotoResult {
        let res: ILocationPhotoResult = LocationUtilsHelpers.getDefaultPhotoResult();
        console.log("get google place photo core request");
        if (photos && photos.length > 0) {
            for (let i = 0; i < photos.length; i++) {
                if (LocationUtilsGoogle.options.useTempUrlGoogle) {
                    res = LocationUtilsGoogle.getPlacePhotoCoreTemp(photos[i], options);
                } else {
                    res = LocationUtilsGoogle.getPlacePhotoCoreDirect(photos[i], options);
                }
                if (res.photoFound) {
                    break;
                }
            }
            console.log("get google place photo core: ", res);
        }
        return res;
    }


    /**
     * get the real photo url from google maps redirect
     * because the getUrl function returns a temp photo that is requested with a token instead of the api key
     * and the photo expires in 24 h
     * this way, the real url can be saved instead
     * ! not working anymore due to CORS issues
     * @param url 
     */
    static getGoogleRedirectPhotoUrlResolve(url: string): Promise<ILocationPhotoResult> {
        let promise: Promise<ILocationPhotoResult> = new Promise((resolve) => {

            console.log("get google redirect from: ", url);
            let options: RequestInit = {
                credentials: 'include', // Useful for including session ID (and, IIRC, authorization headers)
                // mode: "cors",
                // headers: {
                //     'Access-Control-Allow-Origin': '*'
                // }
            };

            let res: ILocationPhotoResult = LocationUtilsDef.getLocationPhotoResultContainer();
            res.photoUrl = url;
            if (!url) {
                resolve(res);
                return;
            }

            fetch(url, options)
                .then(response => {
                    res.photoUrl = response.url;
                    res.photoLoadedFinal = true;
                    res.photoFound = true;
                    resolve(res);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(res);
                });
        });
        return promise;
    }


    /**
    * get the url of photo with specified props
    * temp url
    * @param photo 
    * @param options 
    */
    static getPlacePhotoCoreTemp(photo: google.maps.places.PlacePhoto, options: IGetPhotoOptions): ILocationPhotoResult {
        let icon: string = options.noPlaceholder ? null : EMarkerIcons.location;

        let opts: google.maps.places.PhotoOptions = {
            maxHeight: 800,
            maxWidth: 800
        };

        let res: ILocationPhotoResult = LocationUtilsHelpers.getDefaultPhotoResult();
        res.photoUrl = icon;

        if (photo && photo.getUrl) {
            res.photoUrl = photo.getUrl(opts);
            res.photoFound = true;
        }

        return res;
    }


    /**
     * get the raw url
     * this does not work (even if the props exist in the response)
     * @param photo 
     * @param noPlaceholder 
     */
    static getPlacePhotoCoreDirect(photo: IPlacePhotoExt, options: IGetPhotoOptions): ILocationPhotoResult {
        let icon: string = options.noPlaceholder ? null : EMarkerIcons.location;
        let res: ILocationPhotoResult = LocationUtilsHelpers.getDefaultPhotoResult();
        res.photoUrl = icon;

        if (photo.raw_reference && photo.raw_reference.fife_url) {
            res.photoUrl = photo.raw_reference.fife_url;
            res.photoFound = true;
        }

        return res;
    }

}
