import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MPGroupMembersPage } from './mp-group-members.page';
import { PagesModule } from '../../pages.module';

const routes: Routes = [
  {
    path: '',
    component: MPGroupMembersPage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [MPGroupMembersPage]
})
export class MPGroupMembersPageModule { }
