<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="!enableBackButton" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()"
    rightButton="settings" [rightIcon]="true" (rightClick)="options()" [title]="title" [loading]="!loaded">
  </leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <div class="view-frame theme-background" fxLayout="column">

    <nav-bar class="margin-top-s theme-border-bottom" [slidesPerView]="3" [(selectedTabId)]="selectedCategoryCode"
      [loadData]="initLoaded" [navBarItems]="navBarItems" (select)="selectCategoryFromNavItem($event)">
    </nav-bar>

    <!-- (swipe)="swipeEvent($event)" -->
    <div class="scrollbar-y inventory-height margin-top-xs">
      <ion-list *ngIf="news" class="list-padding padding-top-none">
        <ion-item class="list-item transparent-bg theme-border-bottom">
          <ion-row class="full-w">
            <ion-col size="10">
              <span class="text-color-primary info-text-m bold">
                LP News & Timeline
              </span>
            </ion-col>
            <ion-col size="2" class="text-right">
              <icon-wrapper *ngIf="!expanded" tappable icon="caret-down" iconIos="chevron-down-circle-outline"
                customClass="leplace-icon icon-color-primary" class="icon-button-round button-color-alternate"
                (click)="expandAll()"></icon-wrapper>
              <icon-wrapper *ngIf="expanded" tappable icon="caret-up" iconIos="chevron-up-circle-outline"
                customClass="leplace-icon icon-color-primary" class="icon-button-round button-color-alternate"
                (click)="expandAll()"></icon-wrapper>
            </ion-col>
          </ion-row>
        </ion-item>

        <ion-item *ngFor="let entry of news" class="list-item transparent-bg theme-border-bottom">
          <newsfeed-entry class="full-w" [entry]="entry" [opened]="entry.open" (select)="onSelectItem($event)">
          </newsfeed-entry>
        </ion-item>
      </ion-list>
    </div>
    <div fxFlex></div>
    <div class="padding-horizontal-s theme-border-top margin-bottom-xs">
      <leplace-paginator [pagination]="pagination" (onSwitch)="switchPage($event)"></leplace-paginator>
    </div>
  </div>
</ion-content>