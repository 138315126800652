import { ILeplaceObjectContainer } from "../../../classes/def/core/objects";
import { AppConstants } from "../../../classes/app/constants";

export interface ICheckCollectItem {
    client: boolean;
    map: boolean;
    ar: boolean;
    server: boolean;
    type: number;
}

export class ItemCollectorUtils {

    static checkCollectItemGenericCode(object: ILeplaceObjectContainer, typeOverride: number, ontap: boolean): ICheckCollectItem {
        // check object type
        let type: number = null;

        // if (object.treasure) {
        //     type = object.treasure.type;
        // } else if (object.object) {
        //     type = object.object.genericCode;
        // }

        if (object.object) {
            type = object.object.genericCode;
        }

        if (typeOverride != null) {
            type = typeOverride;
        }

        return ItemCollectorUtils.checkCollectItemGenericCodeCore(type, ontap);
    }

    static checkCollectItemGenericCodeCore(type: number, ontap: boolean) {
        let c: ICheckCollectItem = {
            client: false,
            map: false,
            ar: false,
            server: false,
            type: null
        };

        if (type != null) {
            c.type = type;
            let cc: boolean = AppConstants.collectibleItemsClient.indexOf(type) !== -1;
            let cs: boolean = AppConstants.collectibleItemsServer.indexOf(type) !== -1;
            let ct: boolean = AppConstants.collectibleItemsOnTap.indexOf(type) !== -1;
            if (ontap) {
                cs = ct;
                cc = ct;
            }
            c.server = cs;
            c.map = cc;
            c.ar = cc;
            c.client = cc;
        }
        console.log("check collect item core: ", c);
        return c;
    }

    static checkCollectEventItemGenericCodeCore(type: number) {
        let c: ICheckCollectItem = {
            client: false,
            map: false,
            ar: false,
            server: false,
            type: null
        };

        if (type != null) {
            c.type = type;
            let cc: boolean = AppConstants.collectibleEventItemsClient.indexOf(type) !== -1;
            let cs: boolean = AppConstants.collectibleItemsServer.indexOf(type) !== -1;
            c.server = cs;
            c.map = cc;
            c.ar = cc;
            c.client = cc;
        }
        console.log("check collect item core: ", c);
        return c;
    }
}
