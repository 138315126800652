<modal-header-container [vs]="vs">
  <!-- <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [rounded]="vs.fullScreen"></leplace-toolbar> -->

  <leplace-toolbar [menu]="false" [title]="title" [leftButton]="appIcons.clear" [leftIcon]="true"
    [customLeftIcon]="true" (leftClick)="clear()" [rightButton]="appIconsStandard.send" [rightIcon]="true"
    [customRightIcon]="false" (rightClick)="submit()" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<!-- <ion-content class="outer-content custom-modal-style">

  <div class="padding-s scroll-height-btn-1" fxLayout="column" [ngClass]="vs.transparent?'view-frame-minimal':'view-frame-minimal theme-background'"> -->
<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div fxLayout="column" *ngIf="!inputMode" [@showState]="showState">
    <div class="scrollbar-y scroll-content-modal-1">
      <div class="padding-s text-center">
        <div [innerHTML]="description" class="info-text-m bold description-frame text-color-primary"></div>
      </div>

      <div class="padding-s text-center">
        <div [innerHTML]="text" class="info-text-m description-frame text-color-primary"></div>
      </div>
    </div>

    <div fxFlex></div>

    <div class="stick-bottom">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col size="4">
            <ion-button class="action-button-fill button-color-alternate" (click)="checkFocus()">
              <icon-wrapper slot="icon-only" icon="create"></icon-wrapper>
            </ion-button>
          </ion-col>

          <ion-col size="4">

          </ion-col>

          <ion-col size="4">
            <ion-button class="action-button-fill button-color-accent" (click)="submit()" [disabled]="!text">
              <icon-wrapper slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>

  <div fxLayout="column" *ngIf="inputMode" [@showState]="showState">
    <div class="scrollbar-y scroll-content-modal-1">
      <ion-textarea #input class="info-text-xl text-color-primary leplace-textarea theme-border-bottom" [rows]="10"
        [maxlength]="maxLength" [(ngModel)]="text" ngDefaultControl autocomplete="off" autocorrect="on"
        placeholder="Type a description" (ionFocus)="setFocused()" (ionBlur)="setBlurred()"></ion-textarea>
    </div>
    <div fxFlex></div>
    <div class="stick-bottom">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col class="text-right">
            <span *ngIf="text" class="info-text-m text-color-primary">{{ maxLength - text.length }}
              characters left</span>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="!fixedMode">
          <ion-col size="6">
            <ion-button class="action-button-fill button-color-alternate" (click)="back()">
              <icon-wrapper slot="icon-only" [icon]="appIcons.cancel" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col>

          <ion-col size="6">
            <ion-button class="action-button-fill button-color-accent" (click)="submit()">
              <icon-wrapper slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="fixedMode">
          <ion-col size="12">
            <ion-button class="action-button-fill button-color-accent" (click)="submit()">
              <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIconsStandard.send" [custom]="false">
              </icon-wrapper>
              <span class="button-font-size-s">Finish</span>
            </ion-button>
          </ion-col>
        </ion-row>

      </ion-grid>
    </div>
  </div>
</modal-container>