<modal-header-container [vs]="vs">
  <leplace-toolbar leftButton="close" [leftIcon]="true" rightButton="" [rightIcon]="false"
    [title]="title" (leftClick)="dismiss()" (rightClick)="nop()" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>


<!-- <ion-content no-padding [ngClass]="theme">

  <div class="view-frame theme-background" fxLayout="column"> -->

<modal-container [vs]="vs" [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div class="scrollbar-y places-list theme-border-bottom">
    <ion-list *ngIf="places && places.length > 0" class="list-padding">
      <ion-item *ngFor="let place of places; let i = index;" class="list-item list-item-card-padding transparent-bg" no-lines> 
        <place-card class="full-w" *ngIf="place" [place]="place" tappable></place-card>
      </ion-item>
    </ion-list>
  </div>
</modal-container>