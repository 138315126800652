import { JoystickOutputData } from "nipplejs";

export interface IJoystickDrivingSteeringData {
    driving: number;
    steering: number;
}

export class JoystickUtils {
    static extractDrivingSteering(jdata: JoystickOutputData): IJoystickDrivingSteeringData {
        // joystick moved
        let joystickPower: number = jdata.force;
        let joystickAngle: number = jdata.angle.degree;
        // let zone: number = 40;
        // force [0, ~2]
        // let joystickPosition = jdata.position;
        // console.log("joystick position: ", joystickPosition);

        let driving: number = 0;
        let steering: number = 0;
        let reverseControls: boolean = false;

        if (joystickAngle > 0 && joystickAngle <= 90) {
            driving = joystickPower;
            steering = (90 - joystickAngle) * 100 / 90;
        } else if (joystickAngle > 90 && joystickAngle <= 180) {
            driving = joystickPower;
            steering = -(joystickAngle - 90) * 100 / 90;
        } else if (joystickAngle > 180 && joystickAngle <= 270) {
            driving = -joystickPower;
            // steering = -(-joystickAngle - 180) * 100 / 90;
            steering = -(270 - joystickAngle) * 100 / 90;
            if (reverseControls) {
                steering = -steering;
            }
        } else if (joystickAngle > 270 && joystickAngle <= 360) {
            driving = -joystickPower;
            // steering = -(-joystickAngle - 180) * 100 / 90;
            // steering = -(270 - joystickAngle) * 100 / 90;
            steering = (joystickAngle - 270) * 100 / 90;
            if (reverseControls) {
                steering = -steering;
            }
        }

        // scale by steering factor
        // full power only when going straight 
        driving = driving * (1 - Math.abs(steering) / 100);

        let res: IJoystickDrivingSteeringData = {
            driving: driving,
            steering: steering
        };
        return res;
    }
}