export interface IVideoRef {
    id: number;
    code: number;
    name: string;
    /**
     * the youtube video id
     */
    videoId: string;
    portrait: number;
}

export enum EVideoCodes {
    explore = 1,
    worldMap = 4,
    ar = 5,
    storyline = 11,
    storylineTeams = 12
}
