import { ICustomParamForActivity } from 'src/app/classes/def/core/custom-param';
import { Component, OnInit, OnDestroy, Input, Output, AfterViewInit } from '@angular/core';
import { EItemStatus } from 'src/app/classes/def/items/game-item';
import { EventEmitter } from '@angular/core';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AppConstants } from 'src/app/classes/app/constants';
import { ActivityParamDetailsViewComponent } from 'src/app/modals/app/modals/activity-param-details-vc/activity-param-details-vc.component';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { StringUtils } from 'src/app/services/app/utils/string-utils';


@Component({
  selector: 'activity-param',
  templateUrl: './activity-param.component.html',
  styleUrls: ['./activity-param.component.scss'],
})
export class ActivityParamComponent implements OnInit, OnDestroy, AfterViewInit {
  itemModes = EItemStatus;
  photoLoaded: boolean = false;
  loaded: boolean = false;
  itemName: string = "";

  @Input()
  data: ICustomParamForActivity;

  @Input()
  mode: number;

  @Input()
  interact: boolean;

  @Input()
  showLabel: boolean;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  constructor(
    public tutorials: TutorialsService,
    public uiext: UiExtensionService
  ) {

  }

  showDescription() {
    // this.interact = true; //test
    if (this.data && (this.interact === true || this.interact == null)) {
      let cpar: ICustomParamForActivity = this.data;
      this.uiext.showCustomModal(null, ActivityParamDetailsViewComponent, {
        view: {
          fullScreen: false,
          transparent: AppConstants.transparentMenus,
          large: true,
          addToStack: true,
          frame: false
        },
        params: cpar
      }).then(() => {

      }).catch((err: Error) => {
        console.error(err);
      });
    }
    this.action.emit(this.data);
  }

  getTreasureIcon(cpar: ICustomParamForActivity) {
    if (cpar && cpar.treasureSpec) {
      return cpar.treasureSpec.photoUrl;
    }
    return null;
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    if (this.data) {
      console.log(this.data);

      let itemName: string = this.data.name;
      if (this.data.amount != null) {
        itemName += " (" + this.data.amount + ")";
      }
      this.itemName = StringUtils.trimNameMultilineHTML(itemName, EMessageTrim.itemNameCard);

      this.loaded = true;
    }
  }

  ngAfterViewInit() {

  }
}

