import { UiExtensionService } from './../ui/ui-extension';
import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { ZoomSetupViewComponent, IZoomNavParams, IZoomSetupDetails } from 'src/app/modals/app/modals/zoom-setup/zoom-setup.component';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { WebviewUtilsService } from '../../app/utils/webview-utils';


@Injectable({
    providedIn: 'root'
})
export class ZoomService {

    constructor(
        public plt: Platform,
        public webView: WebviewUtilsService,
        public uiext: UiExtensionService
    ) {
        console.log("zoom service created");
    }


    init() {
        // Initialize Zoom SDK, need to be called when app fired up.
        this.initCore().then((success: any) => {
            console.log(success);
        }).catch((error: any) => {
            console.log(error);
        });
    }

    initCore() {
        return new Promise((resolve, reject) => {
            try {
                resolve(true);
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     * connect to the zoom meeting
     * require auth (use own zoom account)
     * @param meetingNumber 
     * @param meetingPassword 
     */
    connectWizard(meetingNumber: string, meetingPassword: string): Promise<boolean> {
        return new Promise(async (resolve, reject) => {
            try {

                // Log user in with Zoom username and password.
                // open setup modal
                let modalParams: IZoomNavParams = {
                    meetingNumber: meetingNumber,
                    meetingPassword: meetingPassword,
                    hidden: (meetingNumber != null) && (meetingPassword != null)
                };

                let navParams: INavParams = {
                    view: {
                        fullScreen: false,
                        transparent: false,
                        large: true,
                        addToStack: true,
                        frame: false
                    },
                    params: modalParams
                };

                let details: IZoomSetupDetails = {
                    meetingNumber: "",
                    meetingPassword: "",
                    displayName: "",
                    userId: "",
                    userPassword: ""
                };

                details = await this.uiext.showCustomModal(null, ZoomSetupViewComponent, navParams);

                resolve(true);
            } catch (err) {
                console.error(err);
                this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
                reject(err);
            }
        });
    }
}
