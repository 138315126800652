<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title" (rightClick)="dismiss()"
    [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <!-- class="padding-s" -->
  <div class="margin-s">
    <div *ngFor="let a of actionsList">
      <ion-button class="action-button-fill button-color-transparent options-menu-button" [disabled]="!a.enabled"
        (click)="sendAction(a)">
        <span [ngClass]="a.icon ? 'span-icon button-font-size-s' : 'button-font-size-m'">{{a.name}}</span>
        <icon-wrapper class="icon-wrapper slot-icon-padding" *ngIf="a.icon" slot="start" [icon]="a.icon" [custom]="a.customIcon">
        </icon-wrapper>
      </ion-button>
    </div>
  </div>

</modal-container>