import { IAppServiceDef, IAppService } from "../../classes/def/app/app";
import { IPopoverActions } from "../def/app/modal-interaction";
import { IAppResources } from '../def/app/resources';

export class ApiUtils {
    /**
     * init load service urls
     * @param serviceUrlResponse
     * @param isTester
     * @param useDebugServiceUrls
     */
    static setupServiceUrl(container: IAppServiceDef, serviceUrlResponse: IAppServiceDef, isTester: boolean, useDebugServiceUrls: boolean) {
        console.log("setup service url debug mode: " + useDebugServiceUrls + ", isTester: " + isTester);
        let keys = Object.keys(serviceUrlResponse);
        for (let i = 0; i < keys.length; i++) {

            container[keys[i]] = serviceUrlResponse[keys[i]];

            let service: IAppService = container[keys[i]];

            service.urlProd = service.url;
            if (isTester && useDebugServiceUrls) {
                // use url test instead of url, for the entire app
                // the app still refers to url (but it contains the test url instead)
                service.url = service.urlTest;

                service.isTestUrl = true;
            } else {
                service.isTestUrl = false;
            }
        }
        console.log("service url updated: ", container);
    }

    /**
     * init load service urls after resources loaded
     * @param container 
     * @param isTester 
     * @param useDebugServiceUrls 
     */
    static setupServiceUrlCore(container: IAppServiceDef, isTester: boolean, useDebugServiceUrls: boolean) {
        console.log("setup service url debug mode core: " + useDebugServiceUrls + ", isTester: " + isTester);
        let keys: string[] = Object.keys(container);
        for (let i = 0; i < keys.length; i++) {
            let service: IAppService = container[keys[i]];
            service.urlProd = service.url;
            if (isTester && useDebugServiceUrls) {
                // use url test instead of url, for the entire app
                // the app still refers to url (but it contains the test url instead)
                service.url = service.urlTest;
                service.isTestUrl = true;
            } else {
                service.isTestUrl = false;
            }
        }
    }

    /**
     * master switch all service urls
     * @param useDebugServiceUrls
     */
    static useDebugServiceUrlGlobal(useDebugServiceUrls: boolean, rc: IAppResources) {
        let keys = Object.keys(rc.general.appServices.content.object);
        for (let i = 0; i < keys.length; i++) {
            if (useDebugServiceUrls) {
                rc.general.appServices.content.object[keys[i]].url =
                    rc.general.appServices.content.object[keys[i]].urlTest;
            } else {
                rc.general.appServices.content.object[keys[i]].url =
                    rc.general.appServices.content.object[keys[i]].urlProd;
            }
        }
        console.log("service url updated: ", rc.general.appServices.content.object);
    }
    /**
     * update individual service urls
     * @param cbVals
     */
    static useDebugServiceUrlFromCb(cbVals: IPopoverActions, rc: IAppResources) {
        let keys = Object.keys(cbVals);
        for (let i = 0; i < keys.length; i++) {
            if (cbVals[keys[i]].enabled) {
                rc.general.appServices.content.object[keys[i]].url =
                    rc.general.appServices.content.object[keys[i]].urlTest;
            } else {
                rc.general.appServices.content.object[keys[i]].url =
                    rc.general.appServices.content.object[keys[i]].urlProd;
            }
        }
        console.log("service url updated: ", rc.general.appServices.content.object);
    }
}
