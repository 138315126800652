import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'myTime'
})
export class MyTimePipe implements PipeTransform {
  /**
   * Takes a date value and returns a pretty string from current time, 
   * for instance: "four hours ago" or "in eleven minutes".
   */
  transform(value: string, ..._args) {
    return moment.utc(value).format('LT');
  }
}
