
import { Injectable } from "@angular/core";
import { ResourceManager } from "../../../../classes/general/resource-manager";
import { TimeoutService } from "../timeout";
import { LocalNotificationsService } from "../../../general/apis/local-notifications";
import { BehaviorSubject } from "rxjs";
import { ECheckActivityResult } from "../../../../classes/def/core/activity";
import { ITimeoutMonitorParams, ITimeoutMonitorData, ETimeoutStatus } from 'src/app/classes/general/timeout';
import { IMediaActivityParams, IMediaActivityStatus } from "src/app/classes/def/activity/media";


@Injectable({
    providedIn: 'root'
})
export class MediaActivityService {

    subscription = {
        timeoutActivityMonitor: null
    };

    mediaValidated: boolean = false;
    params: IMediaActivityParams;
    moduleName: string = "MEDIA ACTIVITY > ";
    statusObservable: BehaviorSubject<IMediaActivityStatus>;
    activityFinished: boolean = false;

    constructor(
        public timeoutMonitor: TimeoutService,
        public localNotifications: LocalNotificationsService
    ) {
        console.log("media activity service created");
        this.statusObservable = new BehaviorSubject(null);
    }

    watchStatus() {
        return this.statusObservable;
    }

    /**
     * handle timeout activity
     * for photo centric activities (detectObject)
     * will close after photo validation is complete
     * for photo aux activities (comparePhoto)
     * will keep (the timer) running until complete
     */
    initActivity(params: IMediaActivityParams) {
        console.log(this.moduleName + "init");
        if (!this.subscription.timeoutActivityMonitor) {
            console.log(this.moduleName + "init started");

            this.params = params;
            console.log(params);

            this.statusObservable.next(null);

            let tmParams: ITimeoutMonitorParams = {
                timeLimit: params.timeLimit
            };

            this.timeoutMonitor.start(tmParams);
            this.activityFinished = false;

            let status: IMediaActivityStatus = {
                tmData: null,
                status: ECheckActivityResult.inProgress
            };

            // allow re-upload until the time is up
            let finishOnPhotoValidate: boolean = false;

            this.subscription.timeoutActivityMonitor = this.timeoutMonitor.getWatch().subscribe((tmData: ITimeoutMonitorData) => {
                if (tmData) {
                    // console.log(tmData.timerValue);

                    if (!tmData.isFallbackTimer) {
                        status.tmData = tmData;
                    }

                    if (this.mediaValidated && finishOnPhotoValidate) {
                        this.activityFinished = true;
                        status.status = ECheckActivityResult.done;
                        this.statusObservable.next(status);
                    }

                    switch (tmData.status) {
                        case ETimeoutStatus.expired:
                            if (this.mediaValidated) {
                                status.status = ECheckActivityResult.done;
                            } else {
                                status.status = ECheckActivityResult.failed;
                            }
                            this.statusObservable.next(status);
                            this.activityFinished = true;
                            break;
                    }

                    if (this.activityFinished) {
                        this.subscription.timeoutActivityMonitor = ResourceManager.clearSub(this.subscription.timeoutActivityMonitor);
                        console.log("stopping timer");
                        this.timeoutMonitor.stop();
                    } else {
                        this.statusObservable.next(status);
                    }
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    validate() {
        this.mediaValidated = true;
    }

    exitActivity() {
        console.log(this.moduleName + "exit");
        this.subscription = ResourceManager.clearSubObj(this.subscription);
        console.log("stopping timer");
        this.timeoutMonitor.stop();
        this.mediaValidated = false;
    }

}




