<div *ngIf="data" class="flex-card bg-container bg-container-fill item-border-radius bg-hidden"
  [ngClass]="{'story-card-large': large, 'story-card': !large, 'bg-no-filter-animate': isBlink && state, 'bg-filter-2-animate': isBlink && !state}"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data.story.photoUrl}">
  <!-- bg-filter-2 -->

  <div class="content">

    <div *ngIf="isLoading">
      <div class="progress-spinner-center">
        <mat-progress-spinner [diameter]="40" color="primary" mode="indeterminate">
        </mat-progress-spinner>
      </div>
    </div>

    <ion-grid *ngIf="!isLoading" class="full-w grid-center-absolute">

      <ion-row>
        <ion-col size="12" class="text-div-container">
          <div class="text-color-overlay-s">
            <span class="info-text-m bold text-color-element" *ngIf="!showContinue()">{{data.story.name}}</span>
            <span class="info-text-m bold text-color-element" *ngIf="showContinue()">CONTINUE</span>
          </div>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="checkLabel()">
        <ion-col size="12">
          <div class="text-center">
            <span class="info-text-xs text-color-element text-color-overlay-s">{{checkLabel()}}</span>
          </div>
        </ion-col>
      </ion-row>

      <story-info-spec [data]="data"></story-info-spec>

      <ion-row *ngIf="data.modeText" class="padding-s">
        <ion-col size="12">
          <span class="info-text-s text-color-element text-color-overlay-s">{{data.modeText}}</span>
        </ion-col>
      </ion-row>

      <ion-row class="padding-s" [ngClass]="data.modeIcon?'padding-top-xs':''">
        <ion-col size="12" class="progress-bar-alternate">
          <mat-progress-bar mode="determinate" class="progress-rounded" color="primary" [value]="data.storyProgress">
          </mat-progress-bar>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="4">
          <div class="ion-float-left text-color-overlay-s" *ngIf="paidStory && price">
            <span *ngIf="!pricePromo" class="info-text-xs text-color-element">{{price}}</span>
            <div class="flex-col">
              <span *ngIf="pricePromo" class="info-text-xxs text-color-element text-strikethrough">{{price}}</span>
              <span *ngIf="pricePromo"
                class="info-text-xs text-color-element padding-horizontal-s">{{pricePromo}}</span>
            </div>
          </div>
          <div class="ion-float-left" *ngIf="!paidStory">
            <span class="info-text-xs text-color-element text-color-overlay-s">FREE</span>
          </div>
        </ion-col>
        <ion-col size="4" class="text-div-container">
          <div class="ion-float-left text-color-overlay-s">
            <span class="info-text-xs text-color-element">{{levelDisp}}</span>
          </div>
        </ion-col>
        <ion-col size="4">
          <div *ngIf="locked">
            <div class="ion-float-right leplace-icon icon-color-primary">
              <icon-wrapper [icon]="appIconsStandard.locked"></icon-wrapper>
            </div>
          </div>
          <div *ngIf="(paidStory || qrEnabled) && !locked">
            <div class="ion-float-right leplace-icon icon-color-accent">
              <icon-wrapper [icon]="appIconsStandard.unlocked"></icon-wrapper>
            </div>
          </div>
        </ion-col>
      </ion-row>

      <!-- <ion-row>
        <ion-col size="12" center-text>
          <span class="info-text-xs text-color-element text-color-overlay-s">{{rewardDisp}}</span>
        </ion-col>
      </ion-row> -->

      <ion-row class="padding-top-s">
        <ion-col size="12" class="text-div-container">
          <div *ngIf="data.story.regionType" class="text-color-overlay-s">
            <span class="info-text-xs margin-top-s bold text-color-element">{{data.story.regionType}}</span>
          </div>
        </ion-col>
      </ion-row>

      <!-- <ion-row *ngIf="data.story.regionType">
        <ion-col size="12">
          <div>
            <span
              class="info-text-s margin-top-s bold text-color-element text-color-overlay-s">{{data.story.regionType}}</span>
          </div>
        </ion-col>
      </ion-row> -->

    </ion-grid>
  </div>
</div>