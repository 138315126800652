
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { EAppIconsStandard, EAppIcons } from 'src/app/classes/def/app/icons';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ParamHandler } from 'src/app/classes/general/params';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AudioService } from 'src/app/services/media/audio';
import { IMediaUploadContext } from 'src/app/services/media/media-template';
import { MediaUtilsService } from 'src/app/services/media/media-utils';

export interface IAudioFrameNavParams {
  title: string;
  description?: string;
  context: IMediaUploadContext;
  audioUrl?: string;
}

@Component({
  selector: 'modal-audio-recording-frame',
  templateUrl: './audio-recording-frame.component.html',
  styleUrls: ['./audio-recording-frame.component.scss'],
})
export class AudioRecordingFrameComponent implements OnInit, OnDestroy {

  description: string = "";
  title: string = "";

  audioUrl: string = null;

  appIconsStandard = EAppIconsStandard;
  appIcons = EAppIcons;
  
  vs: IViewSpecs = ViewSpecs.getDefault();

  params: IAudioFrameNavParams = null;

  np: INavParams = null;

  context: IMediaUploadContext;

  constructor(
    public modalCtrl: ModalController,
    public shareProvider: ShareService,
    public mediaUtils: MediaUtilsService,
    public audio: AudioService,
    public uiext: UiExtensionService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IAudioFrameNavParams = np.params;
      this.params = params;

      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);

      this.description = params.description;

      this.title = params.title;
      this.audioUrl = params.audioUrl;
      this.context = params.context;

      // if (this.videoUrl != null) {
      //   this.video.setVideoElementPlayable(this.avStream);
      // }
    }
  }

  ngOnDestroy() {

  }

  dismiss() {
    setTimeout(() => {
      this.modalCtrl.dismiss(null).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }
}

