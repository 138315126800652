
export interface IGenericActionContainer<T> {
    action: number;
    item: T;
}

export enum EGenericAction {
    create = 1,
    update = 2,
    remove = 3
}
