import { Subscription } from 'rxjs';
import { IOverlayParams, IOverlayUpdateParams, EOverlayUpdateMode, EModalScope } from './../../../../classes/def/views/overlay';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { ParamHandler } from 'src/app/classes/general/params';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';


@Component({
  selector: 'modal-reward-popup-toast',
  templateUrl: './reward-popup-toast.component.html',
  styleUrls: ['./reward-popup-toast.component.scss'],
})
export class RewardPopupToastViewComponent implements OnInit, OnDestroy {
  wrapperClass: string = "";
  innerClass: string = "";
  vs: IViewSpecs = ViewSpecs.getDefault();
  hasVideo: boolean = false;
  params: IOverlayParams = null;
  loaded: boolean = false;

  timeoutDispose;

  np: INavParams = null;
  extHandleSub: Subscription;

  mode = {
    continuous: false,
    timeout: false
  };

  descriptionText: string = "";

  constructor(
    public modalCtrl: ModalController
  ) {

  }

  setTimeoutDispose(timeout: number) {
    this.timeoutDispose = setTimeout(() => {
      this.ok();
    }, timeout);
  }

  ngOnDestroy() {
    ResourceManager.clearTimeout(this.timeoutDispose);
    ResourceManager.clearSub(this.extHandleSub);
  }

  ngOnInit() {
    console.log("opening reward popup modal");
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IOverlayParams = np.params;
      this.params = params;
      if (np.view) {
        this.vs = np.view;
      }
      console.log(this.params);
      this.wrapperClass = "padding-s full-w";
      this.innerClass = "center-items full-w";
      this.updateContent();
      this.loaded = true;
      if (this.params.extHandleObservable) {
        this.extHandleSub = this.params.extHandleObservable.subscribe((data: IOverlayUpdateParams) => {
          console.log("ext handle received: ", data);
          if (data != null) {
            switch (data.mode) {
              case EOverlayUpdateMode.updateContent:
                if (this.checkUpdateContentAvailable(data.params)) {
                  this.params = data.params;
                  this.updateContent();
                }
                break;
            }
          }
        }, (err: Error) => {
          console.error(err);
        });
      }
    }
  }

  updateContentDisp() {
    this.descriptionText = this.params.description;
  }

  checkUpdateContentAvailable(newParams: IOverlayParams) {
    if (!newParams) {
      return false;
    }
    // if (this.params.continuous !== newParams.continuous) {
    //   return false;
    // }
    return true;
  }

  updateContent() {
    if (this.params.continuous) {
      this.mode.continuous = true;
      this.updateContentDisp();
    } else {
      this.updateContentDisp();
      if (this.params.timeout) {
        this.mode.timeout = true;
        this.setTimeoutDispose(this.params.timeout + 500);
      }
    }
  }


  dismiss(value: number) {
    if (value == null) {
      value = EAlertButtonCodes.cancel;
    }
    setTimeout(() => {
      this.modalCtrl.dismiss(value).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  ok() {
    this.dismiss(EAlertButtonCodes.ok);
  }

  cancel() {
    this.dismiss(EAlertButtonCodes.cancel);
  }
}
