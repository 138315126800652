
import { NgModule } from '@angular/core';
import { AchievementUnlockedViewComponent } from './modals/achievement-unlocked/achievement-unlocked.component';
import { ActivityFailedViewComponent } from './modals/activity-failed/activity-failed.component';
import { ActivityFinishedViewComponent } from './modals/activity-finished/activity-finished.component';
import { ActivityParamDetailsViewComponent } from './modals/activity-param-details-vc/activity-param-details-vc.component';
import { ActivityParamsViewComponent } from './modals/activity-params-vc/activity-params-vc.component';
import { ChatViewComponent } from './modals/chat/chat.component';
import { GameItemOptionsViewComponent } from './modals/game-item-options/game-item-options.component';
import { MpUserCardOptionsViewComponent } from './modals/mp-user-card-options/mp-user-card-options.component';
import { PhotoValidatedViewComponent } from './modals/photo-validated/photo-validated.component';
import { PhotoValidationRequirementsViewComponent } from './modals/photo-validation-requirements/photo-validation-requirements.component';
import { PlaceSearchViewComponent } from './modals/place-search/place-search.component';
import { PlacesItemsViewComponent } from './modals/places-items/places-items.component';
import { PlacesPopupViewComponent } from './modals/places-popup/places-popup.component';
import { ProgressViewComponent } from './modals/progress-view/progress-view.component';
import { StoryBuyOptionsViewComponent } from './modals/story-buy-options/story-buy-options.component';
import { StoryFinishedViewComponent } from './modals/story-finished/story-finished.component';
import { StoryPrologueViewComponent } from './modals/story-prologue/story-prologue.component';
import { TreasureSpecSelectorViewComponent } from './modals/treasure-spec-selector/treasure-spec-selector.component';
import { UpdatingAppViewComponent } from './modals/updating-app/updating-app.component';
import { GenericModalsModule } from '../generic/generic-modals.module';
import { AppComponentsModule } from 'src/app/components/app/app-components.module';
import { AppComponentsGenericModule } from 'src/app/components/generic/generic-components.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LocationSelectorViewComponent } from './modals/location-selector/location-selector.component';
import { ZoomSetupViewComponent } from './modals/zoom-setup/zoom-setup.component';
import { NgxStarsModule } from 'ngx-stars';
import { BeaconScannerViewComponent } from './modals/beacon-scanner/beacon-scanner.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { UnlockContentViewComponent } from './modals/unlock-content/unlock-content.component';
import { CheckLocationViewComponent } from './modals/check-location/check-location.component';
import { ItemsCollectedViewComponent } from './modals/items-collected/items-collected.component';
import { PromoStoriesViewComponent } from './modals/promo-stories/promo-stories.component';
import { NgbCarouselModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

const components = [
    AchievementUnlockedViewComponent,
    ActivityFailedViewComponent,
    ActivityFinishedViewComponent,
    ActivityParamDetailsViewComponent,
    ActivityParamsViewComponent,
    BeaconScannerViewComponent,
    ChatViewComponent,
    CheckLocationViewComponent,
    GameItemOptionsViewComponent,
    ItemsCollectedViewComponent,
    LocationSelectorViewComponent,
    MpUserCardOptionsViewComponent,
    PhotoValidatedViewComponent,
    PhotoValidationRequirementsViewComponent,
    PlaceSearchViewComponent,
    PlacesItemsViewComponent,
    PlacesPopupViewComponent,
    ProgressViewComponent,
    PromoStoriesViewComponent,
    StoryBuyOptionsViewComponent,
    StoryFinishedViewComponent,
    StoryPrologueViewComponent,
    TreasureSpecSelectorViewComponent,
    UpdatingAppViewComponent,
    UnlockContentViewComponent,
    ZoomSetupViewComponent
];

@NgModule({
    declarations: [...components],
    entryComponents: [...components],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        PipesModule,
        DirectivesModule,
        AppComponentsModule,
        AppComponentsGenericModule,
        MatTableModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        FlexLayoutModule,
        GenericModalsModule,
        NgxStarsModule,
        NgbModule,
        NgbCarouselModule
    ],
    exports: [...components]
})
export class AppModalsModule { }
