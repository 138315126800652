import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IArenaNavParams } from 'src/app/classes/def/nav-params/activity';
import { IGroup, EGroupRole, IGroupMember, EGroupModalResult } from 'src/app/classes/def/mp/groups';
import { ModalController, Platform } from '@ionic/angular';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { MPUtils } from 'src/app/services/app/mp/mp-utils';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IArenaReturnData } from 'src/app/classes/def/nav-params/arena';
import { IUserPublicData } from 'src/app/classes/def/user/general';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AdsService } from 'src/app/services/general/apis/ads';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { MPManagerService } from 'src/app/services/app/mp/mp-manager';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { IMPGenericGroupStat } from 'src/app/classes/def/mp/game';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-mp-group-members',
  templateUrl: './mp-group-members.page.html',
  styleUrls: ['./mp-group-members.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MPGroupMembersPage implements OnInit, OnDestroy {
  theme: string = "theme-light theme-light-bg";
  title: string = "My Team";
  infoHTML: string = "";
  shareMessage: string = "";

  photoUrlLoaded: string = null;
  params: IArenaNavParams = null;

  joined: boolean = false;

  loaded: boolean = true;

  group: IGroup = null;

  description: string = `
  <p>View and manage your team status</p>`;

  hasGroups: boolean = false;


  subscription = {
    status: null
  };

  timeout = {
    other: null
  };


  // the role of the current user (that opens the page)
  isAdmin: boolean = false;

  np: INavParams = null;
  vs: IViewSpecs;

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public adService: AdsService,
    public mp: MPDataService,
    public analytics: AnalyticsService,
    public mpManager: MPManagerService,
    public nps: NavParamsService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public backButton: BackButtonService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  ngOnInit() {

    this.analytics.trackView("mp-group-members");

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.mpGroupMembers, null, this.np);

      console.log("nav params: ", npInfo.params);

      let hasParams: boolean = npInfo.hasParams;
      // console.log(navParams.data);
      if (hasParams) {
        let np: INavParams = npInfo.params;
        this.params = np.params;
        this.vs = np.view;
        this.group = this.params.group;
        this.title = this.group.name;

        if (this.group.role === EGroupRole.leader) {
          this.isAdmin = true;
        }

        console.log("arena view group init: ", this.group);
      }

      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back();
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });

      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      this.watchGroupStatus();
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  removeMember(member: IGroupMember) {
    console.log("removed member: ", member);
    console.log("now reloading");
    this.loadGroup();
  }

  loadGroup() {
    this.loaded = false;
    this.mp.viewGroup(this.group.id, false).then((group: IGroup) => {
      this.group = group;
      MPUtils.formatGroupMembers(this.group, GeneralCache.userId);
      console.log("arena view group reload: ", this.group);
      this.loaded = true;
    }).catch((err: Error) => {
      console.error(err);
      this.loaded = true;
    });
  }

  back() {
    this.dismiss(null);
  }

  ok() {
    let returnData: IArenaReturnData = {
      status: null,
      group: this.group,
      role: this.group.role
    };
    returnData.status = EGroupModalResult.ok;
    this.dismiss(returnData);
  }

  cancel() {
    let returnData: IArenaReturnData = {
      status: null,
      group: this.group,
      role: this.group.role
    };
    returnData.status = EGroupModalResult.cancel;
    this.dismiss(returnData);
  }


  ngOnDestroy() {
    console.log("close modal");
    this.stopWatchGroupStatus();
    this.backButton.checkPop(this.vs);

  }

  clearWatch() {

  }

  viewMember(user: IUserPublicData) {
    console.log(user);
  }


  options() {
    let actions: IPopoverActions = {};
    actions = {
      // search: {
      //     name: "Search",
      //     code: 1,
      //     enabled: true
      // }, 
      reload: {
        name: "Reload",
        code: 0,
        icon: EAppIconsStandard.refresh,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 0:
          this.loadGroup();
          break;
        case 1:

          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }



  watchGroupStatus() {
    if (!this.subscription.status) {
      let obs = this.mpManager.getGroupStatusObservable();
      if (!obs) {
        return;
      }
      let discard: number = 1;
      this.subscription.status = obs.subscribe((_data: IMPGenericGroupStat) => {
        if (discard > 0) {
          discard -= 1;
        } else {
          // this.mpManager.syncGroupMembersStatus(this.group, data);
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }


  stopWatchGroupStatus() {
    this.subscription.status = ResourceManager.clearSub(this.subscription.status);
  }
}
