<!-- <ion-toolbar class="inner-toolbar"> -->
<ion-row class="filters">
  <ion-col class="col-with-arrow" (click)="slidePrev()" no-padding size="1">
    <icon-wrapper *ngIf="showLeftButton" icon="caret-back" iconIos="chevron-back-circle-outline"></icon-wrapper>
  </ion-col>
  <ion-col size="10">
    <ion-slides (ionSlideDidChange)="slideChanged()" [options]="sliderOptions" [ngClass]="'slide-area'"
      *ngIf="trackByFn != null">
      <ion-slide (click)="filterData(item)" *ngFor="let item of slideContent; trackBy: trackByFn"
        [ngClass]="slideClass">
        <dynamic-content [type]="type" [context]="item" class="full-w"></dynamic-content>
      </ion-slide>
    </ion-slides>
    <ion-slides (ionSlideDidChange)="slideChanged()" [options]="sliderOptions" [ngClass]="'slide-area'"
      *ngIf="trackByFn == null">
      <ion-slide (click)="filterData(item)" *ngFor="let item of slideContent" [ngClass]="slideClass">
        <dynamic-content [type]="type" [context]="item" class="full-w"></dynamic-content>
      </ion-slide>
    </ion-slides>
  </ion-col>
  <ion-col class="col-with-arrow" (click)="slideNext()" no-padding size="1">
    <icon-wrapper *ngIf="showRightButton" icon="caret-forward" iconIos="chevron-forward-circle-outline"></icon-wrapper>
  </ion-col>
</ion-row>
<!-- </ion-toolbar> -->