<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()" title="Test">
  </leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <!-- (swipe)="swipeEvent($event)" -->

  <div class="view-frame padding-s theme-background" fxLayout="column">

    <div class="scrollbar-y">

      <!-- <leplace-vertical-gauge [data]="null" [options]="chartOptions" (action)="onSelect($event)" class="account-chart">
      </leplace-vertical-gauge> -->

      <!-- <leplace-joystick></leplace-joystick> -->
      <!-- <leplace-joystick-ngx></leplace-joystick-ngx> -->



      <!-- <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="openGmapTestMode()">
        Gmap Test Mode
      </ion-button> -->

      <cooldown-button [active]="true" [triggerCooldown]="true" [cooldown]="5" icon="map" [customIcon]="true"
        name="Gmap Test Mode" theme="primary" (select)="openGmapTestMode()">
      </cooldown-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="showProminentDisclosure()">
        prominent disclosure
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s"
        (click)="diagnosticPermissionsSelfTest()">
        permissions self test
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="openAVFrame(false)">
        AV Streaming
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="openAVFrame(true)">
        AV Recording
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="openAudioFrame(false)">
        Audio Streaming
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="openAudioFrame(true)">
        Audio Recording
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="takePhoto(false)">
        Take photo
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="takePhoto(true)">
        Pick photo
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="scanQR()">
        Scan QR
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testCrash()">
        Test Crash
      </ion-button>


      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="showToast()">
        show toast
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testTransparentPopup()">
        show reward
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testTransparentPopupLongImg()">
        show reward long
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testAudio()">
        test audio
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="viewPhoto()">
        test share photo
      </ion-button>
      

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testZoomMeeting()">
        test zoom meeting
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testLoadGoogleImageUrl()">
        load google image url
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="showLoading()">
        show loading
      </ion-button>


      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testAds(2)">
        test ads (reward)
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testAds(1)">
        test ads (inter)
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testNotification()">
        test notification
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s"
        (click)="testNotificationWithVibration()">
        test notification (vib)
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testNotificationDelay()">
        test notification 10s
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testBeaconScanner()">
        test beacon scanner
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testAsyncAwait()">
        test async await
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="selectVoiceApp()">
        select voice (app)
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="selectVoiceContent()">
        select voice (content)
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testTTS()">
        test tts
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testTTSQueue()">
        test tts queue
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testTTSQueueCloud()">
        test tts queue cloud
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testVideo()">
        test video
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="editContent()">
        text input
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testUpdateModal()">
        update modal
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testAlert()">
        test alert
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testViewTreasure()">
        test view treasure
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testDismissWidgets()">
        test dismiss widgets
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testTransparentPopup()">
        transparent popup
      </ion-button>


      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="openChatWithOperator()">
        Operator Chat
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="checkUserStatsChanged()">
        check stats changed
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testActivityFinishedPopup()">
        Activity finished
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testActivityFailedPopup()">
        Activity failed
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testSliderTutorial()">
        test slider tutorial
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="getImageB64()">
        test get image
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testPhoto()">
        test photo
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testEditImage()">
        test edit image
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testPhoto2()">
        test photo 2
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testScreenshot()">
        test screenshot
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testActivityParams()">
        test activity params
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testStoryFinishedPopup(true)">
        Story finished
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testStoryFinishedPopup(false)">
        Story finished 25%
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="showMPLeaderboard()">
        MP Leaderboard
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="showArenaModal(true)">
        Arena modal mplace
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="showArenaModal(false)">
        Arena modal group
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="checkNewAchievements()">
        check new achievements test
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="showAR()">
        test AR
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testShare()">
        test share
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testMessageQ(false)">
        test msg queue
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testMessageQ(true)">
        test msg priority
      </ion-button>

      <ion-button color="primary" class="action-button-fill button-font-size-s" (click)="testAddCustomTreasure()">
        test add treasure
      </ion-button>
    </div>
  </div>
</ion-content>