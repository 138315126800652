import { ILatLng } from "../def/map/coords";
import { IWaypoint } from "../def/map/map-data";

export class MapGeometry {
    static getCoordsFromWaypointArray(wpts: IWaypoint[]): ILatLng[] {
        let coords: ILatLng[] = [];
        for (let i = 0; i < wpts.length; i++) {
            coords.push(wpts[i].coords);
        }
        return coords;
    }
}
