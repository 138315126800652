import { Injectable } from '@angular/core';
import { UiExtensionService } from '../general/ui/ui-extension';
import { IGenericResponse } from '../../classes/def/requests/general';
import { IPhotoResultResponse } from '../../classes/def/media/processing';
import { GenericDataService } from '../general/data/generic';
import { MediaUtilsService } from './media-utils';
import { ResourceManager } from '../../classes/general/resource-manager';
import { Messages } from '../../classes/def/app/messages';
import { ICustomParamForActivity } from '../../classes/def/core/custom-param';
import { StorageOpsService } from '../general/data/storage-ops';
import { ELocalAppDataKeys } from '../../classes/def/app/storage-flags';
import { LocationUtils } from '../location/location-utils';
import { IPhotoResult } from 'src/app/classes/def/media/photo';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { AnalyticsService } from '../general/apis/analytics';
import { PermissionsService } from '../general/permissions/permissions';
import { EOS } from 'src/app/classes/def/app/app';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { SleepUtils } from '../utils/sleep-utils';
import { SettingsManagerService } from '../general/settings-manager';
import { FileManagerService } from './file';

export enum EPhotoGalleryConfig {
    fileTypes = ".png, .jpg, .jpeg"
}

/**
 * the high level photo provider
 * manages camera and api interaction
 */
@Injectable({
    providedIn: 'root'
})
export class PhotosService {

    timeout = {
        loading: null
    };

    withShowLoading: boolean = true;

    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public generic: GenericDataService,
        public uiext: UiExtensionService,
        public permissionsService: PermissionsService,
        public mediaUtils: MediaUtilsService,
        public storageOps: StorageOpsService,
        public settingsProvider: SettingsManagerService,
        public analytics: AnalyticsService,
        public file: FileManagerService
    ) {
        console.log("photos service created");

        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    checkShowLoading(message: string, always: boolean): Promise<boolean> {
        return new Promise<boolean>(async (resolve) => {
            if (!message) {
                // default message
                message = "<p>Initializing camera..</p><p>Please wait.. the first time it might take a while</p>";
            }
            let val: boolean = true;
            if (!always) {
                // check flag if loading required once
                await this.storageOps.getLocalDataKeyResolve(ELocalAppDataKeys.cameraInitialized);
            }
            if (!val) {
                // set flag if loading required once
                this.storageOps.setStorageFlagNoAction({
                    flag: ELocalAppDataKeys.cameraInitialized,
                    value: true
                });
            }
            if (!val || always) {
                // show loading if loading required/always
                if (GeneralCache.os === EOS.ios) {
                    await this.showLoading(message, false);
                }
            }
            if (always) {
                // return as loading required
                val = false;
            }
            resolve(!val);
        });
    }

    /**
     * handle all steps for uploading a photo,
     * callback is the actual upload function (may be different types e.g. profile picture)
     */
    uploadPhotoFromCameraWizard(callback: (data: string) => Promise<any>, selfie: boolean, crop: boolean): Promise<IPhotoResult> {
        let promise: Promise<IPhotoResult> = new Promise(async (resolve, reject) => {
            let withLoading: boolean = await this.checkShowLoading(null, false);
            this.getPhotoFromCameraWizard(selfie, crop).then(async (res: IPhotoResult) => {
                // console.log(res);
                console.log("photo retrieved from camera");
                callback(res.data).then(async (_resp: IGenericResponse) => {
                    if (withLoading) {
                        await this.dismissLoading();
                    }
                    // this.uiext.showAlertNoAction("Media Service", "Image uploaded");
                    // resolve(res.fileURI);
                    resolve(res);
                }).catch(async (err: Error) => {
                    if (withLoading) {
                        await this.dismissLoading();
                    }
                    reject(err);
                });
            }).catch(async (err: Error) => {
                if (withLoading) {
                    await this.dismissLoading();
                }
                reject(err);
            });
        });
        return promise;
    }

    /**
     * take photo
     * handle camera permissions
     */
    getPhotoFromCameraWizard(selfie: boolean, crop: boolean): Promise<IPhotoResult> {
        let promise: Promise<IPhotoResult> = new Promise((resolve, reject) => {
            this.permissionsService.checkFilePermission().then(() => {
                this.mediaUtils.takePhoto(selfie, crop, true).then((res: IPhotoResult) => {
                    resolve(res);
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    uploadPhotoViaBrowser() {
        return new Promise<string>((resolve, reject) => {
            this.file.uploadFileViaBrowser(EPhotoGalleryConfig.fileTypes, true, null).then((res: string) => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }

    /**
     * handle all steps for uploading a photo,
     * callback is the actual upload function (may be different types e.g. profile picture)
     */
    uploadPhotoFromGalleryWizard(callback: (data: string) => Promise<any>, crop: boolean): Promise<IPhotoResult> {
        let promise: Promise<IPhotoResult> = new Promise(async (resolve, reject) => {
            let isLoading: boolean = false;
            try {
                let res: IPhotoResult;
                await this.showLoading("Loading..", false);
                isLoading = true;
                if (this.platform.WEB) {
                    res = {
                        data: null,
                        fileURI: null,
                        warn: false,
                        message: null
                    };
                    let orig: string = await this.uploadPhotoViaBrowser();
                    let resImage: string = await this.mediaUtils.resizeImage(orig, null, true);
                    let resizedB64: string = resImage as string;
                    await this.mediaUtils.checkDataSize(resizedB64);
                    res.data = resizedB64;
                } else {
                    await this.permissionsService.checkFilePermission();
                    res = await this.mediaUtils.pickPhoto(crop);
                }
                console.log("loaded");
                await this.dismissLoading();
                await this.mediaUtils.checkDataSize(res.data);
                isLoading = false;
                await SleepUtils.sleep(500);
                await this.showLoading("Processing..", false);
                isLoading = true;
                let remoteURI: string = await callback(res.data);
                if (this.platform.WEB) {
                    res.fileURI = remoteURI;
                }
                // this.uiext.showAlertNoAction(Messages.msg.info.after.msg, "Image uploaded");
                await this.dismissLoading();
                if (res && res.warn) {
                    // await this.uiext.showRewardPopupQueue("Warning", res.message, null, false, null);
                    this.uiext.showToastNoAction("Warning: " + res.message, true);
                }
                resolve(res);
            } catch (err) {
                if (isLoading) {
                    await this.dismissLoading();
                }
                reject(err);
            }
        });
        return promise;
    }

    uploadProfilePictureNoAction(imageURI: string) {
        this.uploadProfilePicture(imageURI).then(() => {

        }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "photos");
        })
    }

    /**
     * upload profile image
     * @param imageURI 
     */
    uploadProfilePicture(imageURI: string) {
        GeneralCache.resourceCache.user.general.content.photoUrl = imageURI;
        // let flag: IAppFlagsElement = {
        //     flag: ELocalAppDataKeys.photoUrl,
        //     value: imageURI
        // };
        // this.storageOps.setStorageFlagNoAction(flag);
        return this.generic.genericPostStandard("/photos/upload-profile-photo", {
            photo: imageURI
        });
    }


    /**
     * upload group photo
     * @param imageURI 
     */
    uploadGroupPhoto(groupId: number, imageURI: string) {
        return this.generic.genericPostStandard("/photos/upload-group-photo", {
            groupId: groupId,
            photo: imageURI
        });
    }

    /**
     * remove profile image
     */
    removeGroupPhoto(groupId: number) {
        return this.generic.genericGetStandard("/photos/remove-group-photo", {
            groupId: groupId
        });
    }

    /**
    * download profile image
    */
    downloadProfilePicture() {
        return this.generic.genericGetStandard("/photos/download-profile-photo", null);
    }

    /**
     * download profile image
     */
    downloadItemPhoto(name: string) {
        return this.generic.genericGetStandard("/photos/download-item-photo", { name: name });
    }


    /**
    * remove profile picture from the device
    * remove reference from local storage
    */
    removeProfilePicture() {
        let promise = new Promise((resolve, reject) => {
            // let flag: IAppFlagsElement = {
            //     flag: ELocalAppDataKeys.photoUrl,
            //     value: null
            // };
            // this.storageOps.setStorageFlagNoAction(flag);
            this.generic.genericGetStandard("/photos/remove-profile-photo", null).then(() => {
                GeneralCache.resourceCache.user.general.content.photoUrl = null;
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    setLoadingTimeout() {
        if (!this.timeout.loading) {
            this.timeout.loading = setTimeout(() => {
                console.log("loading timeout fired");
                this.uiext.dismissLoadingV2();
                this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
            }, 20000);
        }
    }

    resetLoadingTimeout() {
        this.timeout.loading = ResourceManager.clearTimeout(this.timeout.loading);
    }

    /**
     * loading disabled on web because there is no way to detect if upload was cancelled by the user, so the loading would hang
     * @returns 
     */
    isLoadingEnabled() {
        return GeneralCache.os === EOS.ios || this.withShowLoading;
    }

    showLoading(message: string, timeout: boolean): Promise<boolean> {
        return new Promise(async (resolve) => {
            if (this.isLoadingEnabled()) {
                await this.uiext.showLoadingV2Queue(message ? message : "Loading..");
                if (timeout) {
                    this.setLoadingTimeout();
                }
            } else {
                console.warn("show loading disabled in photo service: ", message, timeout);
            }
            resolve(true);
        });
    }

    dismissLoading(): Promise<boolean> {
        return new Promise(async (resolve) => {
            if (this.isLoadingEnabled()) {
                await this.uiext.dismissLoadingV2();
                this.resetLoadingTimeout();
                resolve(true);
            } else {
                console.warn("show loading disabled in photo service");
                resolve(true);
            }
        });
    }


    /**
     * upload photo
     * with all included
     * return result from server (true/false)
     * @param referencePhotoUrl the url of the photo (not the actual content)
     */
    comparePhotos(storyId: number, storyLocationId: number, referencePhotoUrl: string, isGooglePhoto: boolean, objects: ICustomParamForActivity[], uploadFromGallery: boolean): Promise<IPhotoResultResponse> {
        let promise: Promise<IPhotoResultResponse> = new Promise((resolve, reject) => {
            this.permissionsService.checkFilePermission().then(() => {
                console.log("take photo, compare with reference: ", referencePhotoUrl);
                this.takePhotoWrapper(uploadFromGallery).then(async (res: IPhotoResult) => {
                    try {
                        await this.showLoading("Uploading..", true);
                        console.log("get photo data");
                        if (!referencePhotoUrl) {
                            // compare with self (test)
                            let resp: IGenericResponse = await this.mediaUploadPhotoCompare(storyId, storyLocationId, res.data, res.data, objects, uploadFromGallery);
                            await this.dismissLoading();
                            let p: IPhotoResultResponse = resp.data;
                            resolve(p);
                        } else {
                            // check load photo provided as URL (can be from remote locations)
                            let referencePhotoData: string = null;
                            // if (isGooglePhoto) {
                            referencePhotoData = await LocationUtils.downloadPhoto(referencePhotoUrl);
                            // }
                            // resize, basic processing (compression, etc)
                            console.log("format base64 save");
                            let formatData: string = null;
                            if (referencePhotoData != null) {
                                formatData = await this.mediaUtils.resizeImage(referencePhotoData, null, false);
                            }
                            // upload the pair of photos
                            console.log("upload photo compare");
                            let resp: IGenericResponse = await this.mediaUploadPhotoCompare(storyId, storyLocationId, res.data, formatData, objects, uploadFromGallery);
                            await this.dismissLoading();
                            let p: IPhotoResultResponse = resp.data;
                            resolve(p);
                        }
                    } catch (err) {
                        await this.dismissLoading();
                        reject(err);
                    }
                }).catch(async (err: Error) => {
                    await this.dismissLoading();
                    reject(err);
                });
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    /**
     * handle take photo/upload from gallery option
     * @param uploadFromGallery 
     */
    takePhotoWrapper(uploadFromGallery: boolean) {
        let promise: Promise<IPhotoResult>;
        if (!uploadFromGallery) {
            promise = this.mediaUtils.takePhoto(false, false, true);
        } else {
            promise = this.mediaUtils.pickPhoto(false);
        }
        return promise;
    }

    /**
     * check if the specified objects are found in the image (detect)
     * @param objects 
     */
    checkPhotoObject(storyId: number, storyLocationId: number, objects: ICustomParamForActivity[], uploadFromGallery: boolean): Promise<IPhotoResultResponse> {
        let promise: Promise<IPhotoResultResponse> = new Promise((resolve, reject) => {
            this.permissionsService.checkFilePermission().then(() => {
                console.log("take photo");
                this.takePhotoWrapper(uploadFromGallery).then(async (res: IPhotoResult) => {
                    console.log("upload photo detect");
                    await this.showLoading("Uploading..", true);
                    this.mediaUploadPhotoDetect(storyId, storyLocationId, res.data, objects, uploadFromGallery).then(async (resp: IGenericResponse) => {
                        await this.dismissLoading();
                        let p: IPhotoResultResponse = resp.data;
                        resolve(p);
                    }).catch(async (err: Error) => {
                        await this.dismissLoading();
                        reject(err);
                    });
                }).catch(async (err: Error) => {
                    await this.dismissLoading();
                    reject(err);
                });
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    /**
     * check taken photo for matching activity
     * e.g. photo with a bike for cycling activities
     * @param activityCode 
     */
    checkPhotoActivity(storyId: number, storyLocationId: number, activityCode: number, objects: ICustomParamForActivity[], uploadFromGallery: boolean): Promise<IPhotoResultResponse> {
        let promise: Promise<IPhotoResultResponse> = new Promise((resolve, reject) => {
            this.permissionsService.checkFilePermission().then(() => {
                console.log("take photo");
                this.takePhotoWrapper(uploadFromGallery).then(async (res: IPhotoResult) => {
                    console.log("upload photo detect activity");
                    await this.showLoading("Uploading..", true);
                    this.mediaUploadPhotoCheckActivity(storyId, storyLocationId, res.data, activityCode, objects, uploadFromGallery).then(async (resp: IGenericResponse) => {
                        await this.dismissLoading();
                        let p: IPhotoResultResponse = resp.data;
                        resolve(p);
                    }).catch(async (err: Error) => {
                        await this.dismissLoading();
                        reject(err);
                    });
                }).catch(async (err: Error) => {
                    await this.dismissLoading();
                    reject(err);
                });
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    checkAIValidate(objects: ICustomParamForActivity[]) {
        return this.generic.genericPostStandard("/photos/validate/eval", {
            objects: objects
        });
    }

    /**
     * compare 2 photos (match)
     * direct url: GeneralCache.resourceCache.general.appServices.content.object.media.url + "/api/image/compare"
     */
    mediaUploadPhotoCompare(storyId: number, storyLocationId: number, crt: string, ref: string, objects: ICustomParamForActivity[], uploadFromGallery: boolean): Promise<IGenericResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await this.checkAIValidate(objects);
                let withAI: boolean = res.data;
                let url: string = withAI ? "/photos/validate-ai/compare" : "/photos/validate/compare";
                res = await this.generic.genericPostStandard(url, {
                    storyId: storyId,
                    storyLocationId: storyLocationId,
                    crt: crt,
                    ref: ref,
                    uploadFromGallery: uploadFromGallery
                });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     * check object for photo report (the tag is specified directly as retrieved from the custom params table)
     * direct url: GeneralCache.resourceCache.general.appServices.content.object.media.url + "/api/image/detect"
     */
    mediaUploadPhotoDetect(storyId: number, storyLocationId: number, photo: string, objects: ICustomParamForActivity[], uploadFromGallery: boolean): Promise<IGenericResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await this.checkAIValidate(objects);
                let withAI: boolean = res.data;
                let url: string = withAI ? "/photos/validate-ai/detect" : "/photos/validate/detect";
                res = await this.generic.genericPostStandard(url, {
                    storyId: storyId,
                    storyLocationId: storyLocationId,
                    photo: photo,
                    objects: objects,
                    uploadFromGallery: uploadFromGallery
                });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }

    /**
     * check activity (object is not specified here, it is deduced from the activity code)
     * direct url: GeneralCache.resourceCache.general.appServices.content.object.media.url + "/api/image/check-activity"
     */
    mediaUploadPhotoCheckActivity(storyId: number, storyLocationId: number, photo: string, activityCode: number, objects: ICustomParamForActivity[], uploadFromGallery: boolean): Promise<IGenericResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                let res = await this.checkAIValidate(objects);
                let withAI: boolean = res.data;
                let url: string = withAI ? "/photos/validate-ai/check-activity" : "/photos/validate/check-activity";
                res = await this.generic.genericPostStandard(url, {
                    storyId: storyId,
                    storyLocationId: storyLocationId,
                    photo: photo,
                    activityCode: activityCode,
                    objects: objects,
                    uploadFromGallery: uploadFromGallery
                });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }


    /**
    * upload photo grid item
    */
    mediaUploadPhotoGridItemActivity(storyId: number, storyLocationId: number, index: number, photo: string): Promise<IPhotoResultResponse> {
        return new Promise(async (resolve, reject) => {
            try {
                let url: string = "/photos/validate/check-activity-grid-photo";
                let res = await this.generic.genericPostStandardWData(url, {
                    storyId: storyId,
                    storyLocationId: storyLocationId,
                    index: index,
                    photo: photo
                });
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
    }


    // /**
    // * add watermark to screenshot (request from media api)
    // * WARNING: only works with default base path
    // */
    // addWatermarkFromFile(originalFilePath: string) {
    //     let promise = new Promise((resolve, reject) => {
    //         console.log("original file path: ", originalFilePath);
    //         // let trimmedFilePath: string = this.fileManager.removeBaseFilePath(originalFilePath);
    //         // console.log("trimmed file path: ", trimmedFilePath);
    //         this.fileManager.readFileContent(this.fileManager.getBasePath(), originalFilePath).then((dataURI: string) => {
    //             // remove original file
    //             this.fileManager.removeFile(this.fileManager.getBasePath(), originalFilePath).then(() => {
    //                 console.log("original file removed");
    //             }).catch((err: Error) => {
    //                 console.error(err);
    //             });
    //             // add watermark (request media server)
    //             this.addWatermark(dataURI).then((res: IGenericResponse) => {
    //                 let p: IPhotoResultResponse = res.data;
    //                 let timestamp: number = new Date().getTime();
    //                 this.mediaUtils.saveBase64ImageToFile("leplace_armax_" + timestamp + ".jpg", this.mediaUtils.removeBase64Header(p.processedImageData)).then((filename: string) => {
    //                     console.log("processed file path: ", filename);
    //                     resolve(filename);
    //                 }).catch((err: Error) => {
    //                     reject(err);
    //                 });
    //             }).catch((err: Error) => {
    //                 reject(err);
    //             });
    //         }).catch((err: Error) => {
    //             reject(err);
    //         });
    //     });
    //     return promise;
    // }
}







