import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { IEventGroupList, IEventDetails } from 'src/app/classes/def/events/events';
import { IPaginationContainer } from 'src/app/classes/def/views/pagination';
import { IEventListNavParams } from 'src/app/classes/def/events/nav-params';
import { Platform, ModalController } from '@ionic/angular';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { IModalEvent, EModalEmitter } from 'src/app/classes/def/app/modal-events';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { IPageResponse } from 'src/app/classes/def/requests/general';
import { TimeUtils } from 'src/app/classes/general/time';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EventsHomePage } from '../events-home/events-home.page';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { PaginationHandlerService } from 'src/app/services/general/ui/pagination-handler';
import { EventsDataService } from 'src/app/services/data/events';
import { ERouteDef } from 'src/app/app-utils';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { Router } from '@angular/router';
import { EModalEvents, Events } from 'src/app/services/utils/events';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { ILatLng } from 'src/app/classes/def/map/coords';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.page.html',
  styleUrls: ['./events-list.page.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0
      })),
      state('active', style({
        // transform: 'translateY(0)',
        opacity: 1
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class EventsListPage implements OnInit, OnDestroy {
  public title: string = "Events";
  eventList: IEventGroupList[] = [];
  info: string = "";
  coords: ILatLng = null;
  loaded: boolean = false;

  pagination: IPaginationContainer = {
    page: 0,
    pages: 1,
    pageDisp: 1,
    hideLeft: true,
    hideRight: false
  };

  showState: string = "inactive";
  timeout = null;
  params: IEventListNavParams;
  reload: boolean = true;
  theme: string = "theme-light theme-light-bg";
  animate: boolean = true;

  hasEvents: boolean = true;

  np: INavParams = null;
  vs: IViewSpecs;
  headerClass: string = "";

  isModal: boolean = false;

  constructor(
    public eventDataProvider: EventsDataService,
    public settingsProvider: SettingsManagerService,
    public router: Router,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public locationManager: LocationManagerService,
    public backButton: BackButtonService,
    public analytics: AnalyticsService,
    public modalCtrl: ModalController,
    public events: Events,
    public tutorials: TutorialsService,
    public paginationHandler: PaginationHandlerService,
    public nps: NavParamsService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }


  init() {
    this.refresh(0).then(() => {

    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "story-list");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }

  ngOnInit() {

    this.uiext.disableSidemenu();

    this.paginationHandler.init(this.pagination);
    this.analytics.trackView("event-list");

    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        // this.theme = Constants.theme["aubergine"].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    this.eventList = [];

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.events, null, this.np);

      console.log("nav params: ", npInfo.params);

      if (npInfo.hasParams) {
        let np: INavParams = npInfo.params;
        this.params = np.params;
        this.vs = np.view;
        this.isModal = this.vs ? this.vs.isModal : false;

        this.reload = this.params.reload;
        if (!this.reload || this.isModal) {
          this.animate = false;
          this.showState = 'active';
        }
      }

      this.locationManager.getCurrentLocationWrapper(true, true, true).then((coords: ILatLng) => {
        this.coords = coords;
        this.init();
      }).catch((err: Error) => {
        console.error(err);
        this.init();
      });

      this.webView.ready().then(() => {
        this.backButton.replace(() => {
          this.back(true);
        });
      }).catch((err: Error) => {
        console.error(err);
      });

      this.events.subscribe(EModalEvents.dismiss, (data: IModalEvent) => {
        // // user and time are the same arguments passed in `events.publish(user, time)`
        // console.log('Welcome', user, 'at', time);
        console.log("dismiss event");
        if (data.emitter === EModalEmitter.eventDetailGoToMap) {
          this.modalDismiss(false);
        }
      });
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  ngOnDestroy() {
    console.log("destroy");
    this.clearWatch();
    this.events.unsubscribe(EModalEvents.dismiss);
    this.uiext.enableSidemenu();
  }

  back(animate: boolean) {
    if (this.params) {
      this.params.reload = false;
      if (this.isModal) {
        let modalEvent: IModalEvent = {
          emitter: EModalEmitter.eventList
        };
        this.events.publish(EModalEvents.dismiss, modalEvent);
        this.modalDismiss(animate);
      } else {
        this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
      }
    } else {
      this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  modalDismiss(_animate: boolean) {
    setTimeout(() => {
      this.modalCtrl.dismiss().then(() => {

      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  doRefresh(dir: number) {
    this.refresh(dir).then(() => {

    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "story-list");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }


  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      tutorial: {
        name: "tutorial",
        code: 1,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      },
      reload: {
        name: "Reload",
        code: 0,
        icon: EAppIconsStandard.refresh,
        enabled: true
      },
      search: {
        name: "Search",
        code: 2,
        icon: EAppIconsStandard.search,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((code: number) => {
      switch (code) {
        case 0:
          this.doRefresh(0);
          break;
        case 1:
          this.onHelpClick();
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  onHelpClick() {
    this.tutorials.showTutorialNoAction("Events Tutorial", ETutorialEntries.events, null, null, true);
  }


  /**
   * reload events from the server
   */
  refresh(dir: number) {
    this.paginationHandler.setPaginationPage(dir);
    return this.selectNewPage(this.pagination.page);
  }

  selectNewPage(page: number) {
    let promise = new Promise((resolve, reject) => {
      this.loaded = false;
      this.eventDataProvider.nearbyScan(this.coords, page).then((response: IPageResponse) => {
        this.pagination.page = response.page;
        this.pagination.pages = response.pages;
        console.log(response.data);
        this.showEvents(response.data);
        this.loaded = true;
        this.paginationHandler.setPaginationControls();
        if (this.animate) {
          this.showState = "active";
        }
        resolve(true);
      }).catch((err: Error) => {
        this.loaded = true;
        reject(err);
      });
    });
    return promise;
  }

  selectNewPageNoAction(page: number) {
    this.selectNewPage(page).then(() => {

    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "event-list");
    });
  }


  public showEvents(events: IEventDetails[]): void {
    // console.log("load stories: ", stories);
    let eventGroups: IEventGroupList[] = [];
    for (let i = 0; i < events.length; i++) {
      let eventDate = new Date(events[i].startDate);
      let eventGroup: string = TimeUtils.getShortMonthName(eventDate.getUTCMonth()) + " " + eventDate.getUTCFullYear();
      let foundGroup: boolean = false;
      for (let j = 0; j < eventGroups.length; j++) {
        let groupMonth: string = eventGroups[j].timeCategory;
        if (eventGroup === groupMonth) {
          eventGroups[j].events.push(events[i]);
          foundGroup = true;
          break;
        }
      }
      if (!foundGroup) {
        eventGroups.push({
          name: eventGroup,
          timeCategory: eventGroup,
          events: [events[i]]
        });
      }
    }
    this.eventList = eventGroups;

    if (this.eventList.length > 0) {
      this.hasEvents = true;
    } else {
      this.hasEvents = false;
    }
  }


  clearWatch() {
    ResourceManager.clearTimeout(this.timeout);
    this.timeout = null;
  }


  /**
   * go to event detail on event select (tapped)
   * @param event 
   */
  goToEventDetail(event: IEventDetails): void {
    if (!event) {
      return;
    }
    this.enterEvent(event);
  }


  swipeEvent(e) {
    if (!this.backButton.handleSwipeEvent(e)) {
      if (e) {
        switch (e.direction) {
          case 2:
            // right to left
            if (!this.pagination.hideRight) {
              this.doRefresh(1);
            }
            break;
          case 4:
            // left to right
            if (!this.pagination.hideLeft) {
              this.doRefresh(-1);
            }
            break;
        }
      }
    }
  }

  /**
   * finally enter selected event
   * @param event 
   */
  enterEvent(event: IEventDetails) {
    let params: IEventListNavParams = {
      eventId: event.id,
      loadEvent: true,
      reload: true,
      event: event,
      links: null,
      useMp: false
    };

    console.log(params);

    this.timeout = setTimeout(() => {
      let promiseView;
      if (this.animate) {
        this.showState = "inactive";
      }
      if (this.isModal) {
        promiseView = this.uiext.showCustomModal(null, EventsHomePage, {
          view: {
            fullScreen: true,
            transparent: false,
            large: true,
            addToStack: true,
            frame: true
          },
          storyId: event.id,
          params: params
        });
      } else {
        let navParams: INavParams = {
          view: null,
          eventId: event.id,
          params: params
        };

        this.nps.set(ENavParamsResources.events, navParams);

        promiseView = this.router.navigate([ERouteDef.eventsHome], { replaceUrl: true });
      }
      promiseView.then(() => {

      }).catch((err: Error) => {
        this.uiext.showAlertNoAction(Messages.msg.eventNotAvailable.after.msg, Messages.msg.eventNotAvailable.after.sub);
        console.error(err);
      });
    }, this.animate ? AppConstants.animationTime : 0);
  }

  openPaginateSelector() {
    this.paginationHandler.openPaginateSelector((page: number) => {
      return this.selectNewPageNoAction(page);
    });
  }
}

