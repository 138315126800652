<modal-header-container [vs]="vs">
  <leplace-toolbar [menu]="false" [noBackButton]="true" [title]="title" [rightButton]="appIconsStandard.close"
    [rightIcon]="true" (rightClick)="close()" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div fxLayout="column" *ngIf="!inputMode" [@showState]="showState">
    <div class="padding-s scrollbar-y scroll-into-view-container"
      [ngClass]="vs.fullScreen ? 'scroll-content-modal-1-border-flush-fs' : 'scroll-content-modal-1-border-flush'">
      <ion-grid class="full-w padding-top-s text-center" [ngClass]="inputFocused ? 'scroll-padding' : ''">
        <ion-row>
          <ion-col size="12">
            <span class="info-text-s bold text-color-primary">Your rating</span>
          </ion-col>
        </ion-row>
        <ion-row class="padding-xs">
          <ion-col size="12">
            <!-- <rating class="rating-input" [(ngModel)]="rating" ngDefaultControl [max]="5" [float]="false"
              [readonly]="false">
            </rating> -->
            <div class="center-flex padding-s">
              <ngx-stars [readonly]="false" [size]="2" [color]="'#fcd182'" [initialStars]="rating" [maxStars]="5"
                [wholeStars]="true">
              </ngx-stars>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="4">
            <span class="info-text-s bold text-color-primary">Average rating</span>
          </ion-col>
          <ion-col size="8">
            <span class="info-text-m bold text-color-primary">{{info.averageRating}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="4">
            <span class="info-text-s bold text-color-primary">Rated by</span>
          </ion-col>
          <ion-col size="8">
            <span class="info-text-m bold text-color-primary">{{info.ratedBy}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col>
            <edit-textarea [mainContainer]="review" [editContainer]="reviewCopy" key="description"
              [maxLength]="maxLength" placeholder="Write a review" [popupInput]="true" (update)="reviewChange($event)"
              (focus)="textAreaFocused($event)">
              <inner-content>Edit review</inner-content>
            </edit-textarea>
          </ion-col>
        </ion-row>

        <!-- <div *ngIf="inputFocused" class="padding-keyboard"></div> -->

      </ion-grid>
    </div>
    <div fxFlex></div>
    <div class="theme-border-top" [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col size="12">
            <ion-button class="action-button-fill button-color-accent" (click)="submit()">
              <icon-wrapper class="slot-icon-padding" [custom]="false" slot="start" [icon]="appIconsStandard.send">
              </icon-wrapper>
              <span class="button-font-size-s">Send</span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>

  <div fxLayout="column" *ngIf="inputMode" [@showState]="showState">
    <div class="scrollbar-y scroll-content-modal-1">
      <ion-textarea #myInput class="info-text-xl text-color-primary leplace-textarea theme-border-bottom" [rows]="10"
        [(ngModel)]="text" ngDefaultControl autocomplete="off" autocorrect="on" placeholder="Type a description"
        (ionFocus)="setFocused()" (ionBlur)="setBlurred()"></ion-textarea>
    </div>
    <div fxFlex></div>
    <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col class="text-right">
            <span *ngIf="text" class="info-text-m text-color-primary">{{ maxLength - text.length }}
              characters left</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="6">
            <ion-button class="action-button-fill button-color-alternate" (click)="back()">
              <icon-wrapper slot="icon-only" icon="star"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button class="action-button-fill button-color-accent" (click)="submit()">
              <icon-wrapper slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</modal-container>