import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ILeplaceReg } from 'src/app/classes/def/places/google';
import { IArenaNavParams } from 'src/app/classes/def/nav-params/activity';
import { IGroup, EGroupRole, EGroupModalResult, EGroupType, EGroupContext } from 'src/app/classes/def/mp/groups';
import { ModalController, Platform } from '@ionic/angular';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { EFeatureCode, IFeatureDef } from 'src/app/classes/def/app/app';
import { Messages } from 'src/app/classes/def/app/messages';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { IArenaReturnData } from 'src/app/classes/def/nav-params/arena';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IGenericResponse } from 'src/app/classes/def/requests/general';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { Util } from 'src/app/classes/general/util';
import { IGetPhotoOptions } from 'src/app/services/location/location-utils-def';
import { IPlacePhotoContainer } from 'src/app/classes/def/places/container';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { MPManagerService } from 'src/app/services/app/mp/mp-manager';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { PhotosService } from 'src/app/services/media/photos';
import { PlacesDataService } from 'src/app/services/data/places';
import { PremiumDataService } from 'src/app/services/data/premium';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { MPGroupMembersPage } from '../mp-group-members/mp-group-members.page';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { LocationUtilsWizard } from 'src/app/services/location/location-utils-wizard';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { OtherUtils } from 'src/app/services/utils/other-utils';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { ISelectOptionsElement } from 'src/app/classes/def/app/settings';
import { IPhotoResult } from 'src/app/classes/def/media/photo';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-mp-create',
  templateUrl: './mp-create.page.html',
  styleUrls: ['./mp-create.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MPCreatePage implements OnInit, OnDestroy {
  theme: string = "theme-light theme-light-bg";
  title: string = "Create Team";
  infoHTML: string = "";
  shareMessage: string = "";
  place: ILeplaceReg = null;
  photoUrlLoaded: string = null;
  loaded: boolean = true;

  params: IArenaNavParams = null;

  created: boolean = false;
  fromTeamLobby: boolean = false;

  defaultGroup: IGroup = {
    name: "",
    id: null,
    timestamp: null,
    photoUrl: null,
    alias: null,
    role: EGroupRole.leader,
    type: EGroupType.mp
  };
  group: IGroup;
  photoUrl: string = null;
  photoLoaded: boolean = false;

  groupCopy: IGroup;

  createMessage: string = "Create";

  withPhoto: boolean = false;
  existingGroup: boolean = false;

  isAdmin: boolean = true;

  groups: IGroup[] = [];

  selectedGroupFromList: boolean = false;

  chatOnly: boolean = false;

  description: string = "";
  descriptionHeader: string = "<scroll down to connect>";

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  optionsList: ISelectOptionsElement[] = [
    {
      name: "Public",
      value: EGroupType.lobby
    },
    {
      name: "Private",
      value: EGroupType.mp
    }
  ];

  groupUpdated: boolean = false;
  groupCreated: boolean = false;
  zoomState: boolean = false;

  unlockCodeShortLength: number = 5;

  np: INavParams = null;
  vs: IViewSpecs;
  innerClass: string = "";

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public mp: MPDataService,
    public analytics: AnalyticsService,
    public mpManager: MPManagerService,
    public resources: ResourcesCoreDataService,
    public photoProvider: PhotosService,
    public placeProvider: PlacesDataService,
    public premiumProvider: PremiumDataService,
    public tutorials: TutorialsService,
    public nps: NavParamsService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public backButton: BackButtonService,
    public locationUtilsWizard: LocationUtilsWizard,
    public popupFeatures: PopupFeaturesService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, true);
  }


  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  isGroupUpdated() {
    if (!(this.group && this.groupCopy)) {
      return false;
    }
    if (this.group.name !== this.groupCopy.name) {
      return true;
    }
    if (this.group.type !== this.groupCopy.type) {
      return true;
    }
    if (this.group.quickJoinCode !== this.groupCopy.quickJoinCode) {
      return true;
    }
    return false;
  }

  back() {
    this.dismiss(null);
  }

  async openChat() {
    await this.mpManager.initSequence(this.group, EGroupRole.member, false);
    // will disconnect when closing the chat window
    this.mpManager.openChatWindow(true, true).then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * return to the main group view
   */
  ok() {
    let returnData: IArenaReturnData = {
      status: null,
      group: this.group,
      groupUpdated: this.groupUpdated,
      groupCreated: this.groupCreated,
      role: EGroupRole.leader
    };
    returnData.status = EGroupModalResult.ok;
    this.dismiss(returnData);
  }

  /**
   * go back without creating a group
   */
  cancel() {
    let returnData: IArenaReturnData = {
      status: null,
      group: null,
      groupUpdated: this.groupUpdated,
      groupCreated: this.groupCreated,
      role: EGroupRole.leader
    };
    returnData.status = EGroupModalResult.cancel;
    this.dismiss(returnData);
  }

  /**
   * remove the selected group
   */
  remove() {
    let returnData: IArenaReturnData = {
      status: null,
      group: null,
      role: EGroupRole.leader
    };
    returnData.status = EGroupModalResult.remove;
    this.dismiss(returnData);
  }


  getCostInfo() {
    this.premiumProvider.checkFeaturePrice(this.params.isStoryline ? EFeatureCode.createGroupStoryline : EFeatureCode.createGroup, null, false).then((price: IFeatureDef) => {
      if (price) {
        this.createMessage = "Create (" + price.price + " LP)";
      }
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "mp");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
    });
  }

  /**
   * request group create from the main server
   */
  createGroup() {
    this.loaded = false;

    switch (this.params.context) {
      case EGroupContext.story:
        this.group.storyId = this.params.contextId;
        break;
      case EGroupContext.meetingPlace:
        this.group.meetingPlaceId = this.params.contextId;
        break;
    }

    this.mp.createGroup(this.group.name, this.group, this.withPhoto).then((response: IGenericResponse) => {
      if (response.data) {
        this.created = true;
        this.group = response.data;
        this.groupUpdated = true;
        this.groupCreated = true;
        this.setOriginalGroupSpecs();
      }
      this.loaded = true;
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "mp");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
      this.loaded = true;
    });
  }

  updateGroup() {
    this.loaded = false;
    this.mp.updateGroup(this.group.id, this.group.name, this.group, this.withPhoto).then((response: IGenericResponse) => {
      if (response.data) {
        this.created = true;
        this.group = response.data;
        this.groupUpdated = true;
        this.setOriginalGroupSpecs();
        if (this.fromTeamLobby) {
          this.ok();
        }
      }
      this.loaded = true;
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "mp");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
      this.loaded = true;
    });
  }

  setOriginalGroupSpecs() {
    this.groupCopy = Object.assign({}, this.group);
    this.photoLoaded = false;
    this.photoUrl = this.group.photoUrl;
    setTimeout(() => {
      this.photoLoaded = true;
    });
  }

  deleteGroup() {
    this.uiext.showAlert(Messages.msg.deleteGroup.before.msg, Messages.msg.deleteGroup.before.sub, 2, null).then((res: number) => {
      switch (res) {
        case EAlertButtonCodes.ok:
          this.loaded = false;
          this.mp.deleteGroup(this.group.id).then((_response: IGenericResponse) => {
            this.loaded = true;
            this.groupUpdated = true;
            this.remove();
          }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "mp");
            this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
            this.loaded = true;
          });
          break;
        default:
          break;
      }
    });
  }

  /**
   * Share progress then dismiss
   */
  share() {
    this.loaded = false;
    this.shareProvider.share(this.shareMessage).then(() => {
      this.loaded = true;

    }).catch(() => {

    });
  }


  viewMembers() {
    this.uiext.showCustomModal(null, MPGroupMembersPage, {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: true
      },
      params: this.params
    }).then((res: IArenaReturnData) => {
      if (res) {
        switch (res.status) {
          default:
            break;
        }
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }



  ngOnInit() {

    this.analytics.trackView("mp-create");

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.mpCreate, null, this.np);

      console.log("nav params: ", npInfo.params);

      let hasParams: boolean = npInfo.hasParams;
      this.group = Object.assign({}, this.defaultGroup);
      // console.log(navParams.data);

      this.unlockCodeShortLength = AppConstants.gameConfig.unlockCodeLength;

      if (hasParams) {
        let np: INavParams = npInfo.params;
        this.params = np.params;
        this.place = this.params.place;
        this.vs = np.view;

        if (this.vs.frame) {
          this.innerClass = 'view-frame';
        } else {
          this.innerClass = 'view-frame-minimal';
        }

        if (this.params.isLobbyContext) {
          this.group.type = EGroupType.lobby;
        }

        if (this.params.group) {
          // view group mode
          this.group = this.params.group;
          // console.log(this.group);
          // opened the view from group list, so it's already created
          this.created = true;
          this.existingGroup = true;
          this.title = "Manage Team";
          this.setOriginalGroupSpecs();
        }

        this.fromTeamLobby = this.params.fromTeamLobby;

        this.evalGroupContext();
        this.formatPlace();
        // this.shareMessage = GetDefaults.getItemFoundShareMessage(this.collectedCoins, 0, this.place ? this.place.place.name : null);
        // this.infoHTML = GetDefaults.getItemFoundMessageHTML(this.collectedCoins, 0);
      }

      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back();
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });

      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });
      // this.loadGroups();
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  evalGroupContext() {
    let isPublicContext: boolean = this.params.isLobbyContext;

    switch (this.group.type) {
      case EGroupType.mp:
        isPublicContext = false;
        break;
      case EGroupType.lobby:
        isPublicContext = true;
        break;
    }

    if (this.params.chatOnly) {
      this.chatOnly = true;
      this.description = Messages.mp.createGroupChat;
    } else {
      if (isPublicContext) {
        if (this.created) {
          // isMember
          this.description = Messages.mp.createGroupPublicHost;
        } else {
          this.description = Messages.mp.createGroupPublic;
        }
      } else {
        if (this.created) {
          // isMember
          this.description = Messages.mp.createGroupPrivateHost;
        } else {
          this.description = Messages.mp.createGroupPrivate;
        }
      }
    }

    if (!this.created) {
      // create group mode
      this.getCostInfo();
    }
  }

  ngOnDestroy() {
    console.log("close modal");
    this.backButton.checkPop(this.vs);
  }

  clearWatch() {

  }

  canCreate() {
    return this.group != null && this.group.name !== "";
  }

  /**
   * format place display
   */
  formatPlace() {
    if (this.place) {
      let mapsUrl: string = Util.generateMapsLink(this.place.place.googleId, this.place.place.name);
      this.place.aux = {
        mapsURL: mapsUrl,
        photoUrl: null,
        photoUrlCached: null,
        canvasLoaded: false,
        canvasURL: null
      };

      let options: IGetPhotoOptions = {
        noPlaceholder: true,
        redirect: true,
        cacheDisk: true,
        useGeneric: SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value
      };

      if (options.useGeneric) {
        this.placeProvider.getPlaceTemplatePhoto(this.place.place.type).then((p: IPlacePhotoContainer) => {
          this.place.aux.photoUrl = p.photoUrl;
        });
      } else {
        this.locationUtilsWizard.loadPlacePhotoWizard1(this.place, options);
      }
    }
  }

  options() {
    let actions: IPopoverActions = {};
    actions = {
      // tutorial: {
      //   name: "Tutorial",
      //   code: 0,
      //   icon: EAppIconsStandard.tutorial,
      //   enabled: true
      // },
      viewMembers: {
        name: "View members",
        code: 5,
        icon: EAppIcons.personCircleOutline,
        customIcon: true,
        enabled: true
      },
      updatePhotoCamera: {
        name: "Update photo (camera)",
        code: 1,
        icon: EAppIconsStandard.takePhoto,
        enabled: this.existingGroup
      },
      updatePhotoGallery: {
        name: "Update photo (gallery)",
        code: 2,
        icon: EAppIconsStandard.pickPhoto,
        enabled: this.existingGroup
      },
      removePhoto: {
        name: "Remove photo",
        code: 3,
        icon: EAppIconsStandard.removePhoto,
        enabled: this.existingGroup
      },
      deleteGroup: {
        name: "Delete team",
        code: 4,
        icon: EAppIcons.cancel,
        customIcon: true,
        enabled: this.existingGroup
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 0:
          this.tutorials.showTutorialNoAction("Group Admin Tutorial", ETutorialEntries.groupAdminTutorial, null, null, true);
          break;
        case 1:
          this.uploadPhotoFromCamera();
          break;
        case 2:
          this.uploadPhotoFromGallery();
          break;
        case 3:
          this.removePhoto();
          break;
        case 4:
          this.deleteGroup();
          break;
        case 5:
          this.viewMembers();
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  uploadPhotoFromCamera() {
    this.photoProvider.uploadPhotoFromCameraWizard((data) => {
      return this.photoProvider.uploadGroupPhoto(this.group.id, data);
    }, true, false).then((photoRes: IPhotoResult) => {
      if (photoRes) {
        this.photoLoaded = false;
        this.photoUrl = photoRes.fileURI;
        setTimeout(() => {
          this.photoLoaded = true;
        });
        this.groupUpdated = true;
      }
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "arena-create");
      this.uiext.showAlertNoAction(Messages.msg.returned.after.msg, ErrorMessage.parse(err, Messages.msg.returned.after.sub));
    });
  }

  uploadPhotoFromGallery() {
    this.photoProvider.uploadPhotoFromGalleryWizard((data) => {
      return this.photoProvider.uploadGroupPhoto(this.group.id, data);
    }, false).then((photoRes: IPhotoResult) => {
      this.photoLoaded = false;
      if (photoRes && photoRes.fileURI) {
        this.photoUrl = photoRes.fileURI;
      }
      setTimeout(() => {
        this.photoLoaded = true;
      });
      this.groupUpdated = true;
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "arena-create");
      this.uiext.showAlertNoAction(Messages.msg.returned.after.msg, ErrorMessage.parse(err, Messages.msg.returned.after.sub));
    });
  }

  onZoomImage(zoom: boolean) {
    this.zoomState = zoom;
  }

  removePhoto() {
    this.photoProvider.removeGroupPhoto(this.group.id).then(() => {
      this.uiext.showAlertNoAction(Messages.msg.removePhoto.after.msg, Messages.msg.removePhoto.after.sub);
      this.groupUpdated = true;
      this.photoUrl = null;
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "account");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }

  copyToClipboard(text: string) {
    OtherUtils.copyToClipboard(text);
    this.uiext.showToastNoAction("copied to clipboard", false);
  }

  onGroupTypeChanged() {
    console.log("group type changed: ", this.group.type);
    this.evalGroupContext();
    console.log("group updated: ", this.isGroupUpdated());
  }

}
