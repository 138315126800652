<!-- <modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" (rightClick)="dismiss(null)"
    [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container> -->

<ion-row>
  <ion-col size="8">

  </ion-col>
  <ion-col size="4" class="text-right">
    <ion-button (click)="cancel()" class="as-text menu-button-size menu-button-size-right">
      <div class="leplace-menu-icon icon-color-primary">
        <icon-wrapper icon="close" [custom]="false"></icon-wrapper>
      </div>
    </ion-button>
  </ion-col>
</ion-row>

<modal-container [vs]="vs" wrapperClass="center-all" class="reward-popup-container"
  [ngClass]="vs.large?'half-height':'modal-small-height'">
  <div [class]="wrapperClass">
    <div [class]="innerClass" *ngIf="loaded">

      <ion-grid class="full-w center-items ion-justify-content-center" height-100>
        <ion-row>
          <ion-col size="12" class="padding-s">
            <div [innerHTML]="headingText" class="bold description-frame text-color-primary"
              [ngClass]="longHeading ? 'info-text-lg' : 'heading'"></div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12">
            <div *ngIf="params.photoUrl" class="text-center">
              <responsive-img [photoUrl]="params.photoUrl" class="border-radius" class="padding-top-s"></responsive-img>
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" class="padding-s">
            <div [innerHTML]="descriptionText" class="info-text-mlg bold description-frame text-color-primary ">
            </div>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" class="padding-s">
            <div *ngIf="mode.timeout">
              <hourglass class="progress-spinner-center" [timeout]="params.timeout"></hourglass>
            </div>
            <div *ngIf="mode.continuous">
              <div class="progress-spinner-center">
                <mat-progress-spinner [diameter]="40" color="primary" mode="indeterminate">
                </mat-progress-spinner>
              </div>
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</modal-container>