
import { Injectable } from '@angular/core';
// import { File, FileEntry } from '@ionic-native/file/ngx';
import { Platform } from '@ionic/angular';
import { ImageLoaderTSConfigService } from './image-loader-config';
import { CapacitorHttp, HttpOptions, HttpResponse } from '@capacitor/core';
import { Filesystem, Directory, Encoding, DeleteFileOptions, RmdirOptions, WriteFileOptions, WriteFileResult, GetUriResult, GetUriOptions, StatResult, StatOptions } from '@capacitor/filesystem';
import { FileManagerService } from './file';

interface IndexItem {
    name: string;
    modificationTime: Date;
    size: number;
}

interface QueueItem {
    imageUrl: string;
    // tslint:disable-next-line:ban-types
    resolve: Function;
    // tslint:disable-next-line:ban-types
    reject: Function;
}

declare var cordova: any;


@Injectable({
    providedIn: 'root'
})
export class ImageLoaderTSService {
    /**
     * Indicates if the cache service is ready.
     * When the cache service isn't ready, images are loaded via browser instead.
     * @type {boolean}
     */
    private isCacheReady: boolean = false;
    /**
     * Indicates if this service is initialized.
     * This service is initialized once all the setup is done.
     * @type {boolean}
     */
    private isInit: boolean = false;
    /**
     * Number of concurrent requests allowed
     * @type {number}
     */
    private concurrency: number = 5;
    /**
     * Queue items
     * @type {Array}
     */
    private queue: QueueItem[] = [];
    private processing = 0;
    /**
     * Fast accessible Object for currently processing items
     */
    private currentlyProcessing: { [index: string]: Promise<any> } = {};
    private cacheIndex: IndexItem[] = [];
    private currentCacheSize: number = 0;
    private indexed: boolean = false;

    constructor(
        private config: ImageLoaderTSConfigService,
        private fileManager: FileManagerService,
        private platform: Platform

    ) {
        console.log("image loader ts service created");
    }

    init() {
        console.log("image loader ts service init");
        if (!(this.platform.is('cordova') || this.platform.is('capacitor'))) {
            console.log("image loader init browser");
            // we are running on a browser, or using livereload
            // plugin will not function in this case
            this.isInit = true;
            this.throwWarning(
                'You are running on a browser or using livereload, IonicImageLoader will not function, falling back to browser loading.',
            );
        } else {
            console.log("image loader init native");
            if (this.nativeAvailable) {
                this.initCache();
            } else {
                // we are running on a browser, or using livereload
                // plugin will not function in this case
                this.isInit = true;
                this.throwWarning(
                    'You are running on a browser or using livereload, IonicImageLoader will not function, falling back to browser loading.',
                );
            }
        }
    }

    get nativeAvailable(): boolean {
        // return File.installed();
        // return Filesystem.
        return true;
    }

    private get isCacheSpaceExceeded(): boolean {
        return (
            this.config.maxCacheSize > -1 &&
            this.currentCacheSize > this.config.maxCacheSize
        );
    }

    private get isWKWebView(): boolean {
        return (
            this.platform.is('ios') &&
            (window as any).webkit &&
            (window as any).webkit.messageHandlers
        );
    }

    private get isIonicWKWebView(): boolean {
        return (
            (this.isWKWebView || this.platform.is('android')) &&
            (location.host === 'localhost:8080' || (window as any).LiveReload)
        );
    }

    private get isDevServer(): boolean {
        return window['IonicDevServer'] !== undefined;
    }

    /**
     * Check if we can process more items in the queue
     * @returns {boolean}
     */
    private get canProcess(): boolean {
        return this.queue.length > 0 && this.processing < this.concurrency;
    }

    /**
     * Preload an image
     * @param {string} imageUrl Image URL
     * @returns {Promise<string>} returns a promise that resolves with the cached image URL
     */
    preload(imageUrl: string): Promise<string> {
        return this.getImagePath(imageUrl);
    }

    getFileCacheDirectory() {
        if (this.config.cacheDirectoryType === 'data') {
            // return this.file.dataDirectory;
            return Directory.Data;
        }
        // return this.file.cacheDirectory;
        return Directory.Cache;
    }

    /**
     * Clears cache of a single image
     * @param {string} imageUrl Image URL
     */
    clearImageCache(imageUrl: string): void {
        if (!(this.platform.is('cordova') || this.platform.is('capacitor'))) {
            return;
        }
        const clear = () => {
            if (!this.isInit) {
                // do not run this method until our service is initialized
                setTimeout(clear.bind(this), 500);
                return;
            }
            const fileName = this.createFileName(imageUrl);
            const route = this.getFileCacheDirectory() + this.config.cacheDirectoryName;
            // pause any operations
            this.isInit = false;

            let options: DeleteFileOptions = {
                path: this.config.cacheDirectoryName + "/" + fileName,
                directory: this.getFileCacheDirectory()
            }
            // this.file.removeFile(route, fileName)
            Filesystem.deleteFile(options)
                .then(() => {
                    if (this.isWKWebView && !this.isIonicWKWebView) {
                        options.directory = Directory.Cache;
                        Filesystem.deleteFile(options).then(() => {
                            this.initCache(true);
                        }).catch(() => {
                            // Handle error?
                            this.initCache(true);
                        });
                    } else {
                        this.initCache(true);
                    }
                }).catch(this.throwError.bind(this));
        };
        clear();
    }


    /**
     * Clears the cache
     */
    clearCache(): void {
        if (!(this.platform.is('cordova') || this.platform.is('capacitor'))) {
            return;
        }

        const clear = () => {
            if (!this.isInit) {
                // do not run this method until our service is initialized
                setTimeout(clear.bind(this), 500);
                return;
            }

            // pause any operations
            this.isInit = false;

            let options: RmdirOptions = {
                path: this.config.cacheDirectoryName,
                directory: this.getFileCacheDirectory(),
                recursive: true
            };
            Filesystem.rmdir(options)
                // this.file.removeRecursively(this.getFileCacheDirectory(), this.config.cacheDirectoryName)
                .then(() => {
                    if (this.isWKWebView && !this.isIonicWKWebView) {
                        // also clear the temp files
                        options.directory = Directory.Cache;
                        Filesystem.rmdir(options).then(() => {
                            this.initCache(true);
                        }).catch(() => {
                            // Noop catch. Removing the tempDirectory might fail,
                            // as it is not persistent.
                            this.initCache(true);
                        });
                    } else {
                        this.initCache(true);
                    }
                })
                .catch(this.throwError.bind(this));
        };

        clear();
    }


    /**
     * download image as blob via cordova native http
     * @param url 
     */
    getImageBlob(url: string): Promise<Blob> {
        let promise: Promise<Blob> = new Promise((resolve, reject) => {

            let options: HttpOptions = {
                url: url,
                method: 'get',
                responseType: 'blob',
                data: {},
                headers: {}

                // data: { id: 12, message: 'test' },
                // headers: { Authorization: 'OAuth2: token' }
            };

            // cordova.plugin.http.sendRequest(url, options, (data: any) => {
            //     let blob = data.data;
            //     resolve(blob);
            // }, (error: any) => {
            //     console.log(error.status);
            //     console.log(error.error);
            //     reject(error.error);
            // });

            CapacitorHttp.request(options).then((res: HttpResponse) => {
                let blob = res.data;
                resolve(blob);
            }).catch((err) => {
                console.error(err);
                reject(err);
            });

        });
        return promise;
    }

    getImageRedirectUrl(url: string): Promise<string> {
        let promise: Promise<string> = new Promise((resolve, reject) => {
            let options: HttpOptions = {
                url: url,
                method: 'get',
                responseType: 'blob',
                data: {},
                headers: {}
                // credentials: 'include'
                // data: { id: 12, message: 'test' },
                // headers: { Authorization: 'OAuth2: token' }
            };

            // cordova.plugin.http.sendRequest(url, options, (data: any) => {
            //     let url: string = data.url;
            //     console.log("get redirect url native http: " + url);
            //     resolve(url);
            // }, (error: any) => {
            //     console.log(error.status);
            //     console.log(error.error);
            //     reject(error.error);
            // });           

            CapacitorHttp.request(options).then((res: HttpResponse) => {
                let url: string = res.url;
                console.log("get redirect url native http: " + url);
                resolve(url);
            }).catch((err) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    /**
     * download image as base64 string via cordova native http
     * @param url 
     */
    getImageB64(url: string): Promise<string> {
        let promise: Promise<string> = new Promise((resolve, reject) => {
            this.getImageBlob(url).then((blob: Blob) => {
                const reader = this.fileManager.getFileReader();
                reader.onload = function () {
                    // console.log("fetch result data url: ", this.result);
                    // this.result contains a base64 data URI
                    let b64Raw: string = this.result.toString();
                    b64Raw = b64Raw.replace('data:application/octet-stream;base64,', '');
                    let b64string: string = "";
                    if (b64Raw.indexOf("base64") !== -1) {
                        // the header is already included in the string, skip adding header
                        b64string = b64Raw;
                    } else {
                        // add header according to the requested resource url extension
                        let extList = [{
                            ext: ".jpg",
                            mtype: "jpeg"
                        }, {
                            ext: ".png",
                            mtype: "png"
                        }];
                        let itype: string = "png";
                        for (let i = 0; i < extList.length; i++) {
                            let e = extList[i];
                            if (url.indexOf(e.ext) !== -1) {
                                itype = e.mtype;
                                break;
                            }
                        }
                        let mtype: string = "data:image/" + itype + ";base64,";
                        b64string = mtype + b64Raw;
                    }
                    // console.log("fetch result b64: " + b64string);
                    resolve(b64string);
                };
                reader.onerror = () => {
                    reject(new Error("could not load image content"));
                };
                reader.readAsDataURL(blob);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * Gets the filesystem path of an image.
     * This will return the remote path if anything goes wrong or if the cache service isn't ready yet.
     * @param {string} imageUrl The remote URL of the image
     * @returns {Promise<string>} Returns a promise that will always resolve with an image URL
     */
    getImagePath(imageUrl: string): Promise<string> {
        if (typeof imageUrl !== 'string' || imageUrl.length <= 0) {
            return Promise.reject('The image url provided was empty or invalid.');
        }

        console.log("preload > get image path");

        return new Promise<string>((resolve, reject) => {
            const getImage = () => {
                if (this.isImageUrlRelative(imageUrl)) {
                    resolve(imageUrl);
                } else {
                    this.getCachedImagePath(imageUrl).then((url) => {
                        console.log("preload > image loaded from cache");
                        resolve(url);
                    }).catch((err) => {
                        console.log("preload > image not existing in cache. preloading..");
                        console.error(err);
                        // image doesn't exist in cache, lets fetch it and save it
                        this.addItemToQueue(imageUrl, resolve, reject);
                    });
                }
            };

            const check = () => {
                if (this.isInit) {
                    if (this.isCacheReady) {
                        getImage();
                    } else {
                        this.throwWarning(
                            'The cache system is not running. Images will be loaded by your browser instead.',
                        );

                        console.warn("preload > cache system not running");
                        resolve(imageUrl);
                    }
                } else {
                    console.warn("preload > cache system not initialized. retrying..");
                    setTimeout(() => check(), 500);
                }
            };

            check();
        });
    }

    /**
     * Returns if an imageUrl is an relative path
     * @param {string} imageUrl
     */
    private isImageUrlRelative(imageUrl: string) {
        return !/^(https?|file):\/\/\/?/i.test(imageUrl);
    }

    /**
     * Add an item to the queue
     * @param {string} imageUrl
     * @param resolve
     * @param reject
     */
    private addItemToQueue(imageUrl: string, resolve, reject): void {
        this.queue.push({
            imageUrl,
            resolve,
            reject,
        });

        this.processQueue();
    }

    /**
     * Processes one item from the queue
     */
    private processQueue() {
        // make sure we can process items first
        if (!this.canProcess) {
            return;
        }

        // increase the processing number
        this.processing++;

        // take the first item from queue
        const currentItem: QueueItem = this.queue.splice(0, 1)[0];

        // function to call when done processing this item
        // this will reduce the processing number
        // then will execute this function again to process any remaining items
        const done = () => {
            this.processing--;
            this.processQueue();

            // only delete if it's the last/unique occurrence in the queue
            if (this.currentlyProcessing[currentItem.imageUrl] !== undefined && !this.currentlyInQueue(currentItem.imageUrl)) {
                delete this.currentlyProcessing[currentItem.imageUrl];
            }
        };

        const error = (e) => {
            currentItem.reject();
            this.throwError(e);
            done();
        };

        if (this.currentlyProcessing[currentItem.imageUrl] === undefined) {
            this.currentlyProcessing[currentItem.imageUrl] = new Promise((resolve, reject) => {
                // process more items concurrently if we can
                if (this.canProcess) { this.processQueue(); }

                const localDir = this.getFileCacheDirectory() + this.config.cacheDirectoryName + '/';
                const fileName = this.createFileName(currentItem.imageUrl);

                this.getImageBlob(currentItem.imageUrl).then((data: Blob) => {
                    let options: WriteFileOptions = {
                        path: this.config.cacheDirectoryName + "/" + fileName,
                        directory: this.getFileCacheDirectory(),
                        data: data
                    };

                    // this.file.writeFile(localDir, fileName, data, { replace: true }).then((file: FileEntry) => {
                    Filesystem.writeFile(options).then((file: WriteFileResult) => {
                        if (this.isCacheSpaceExceeded) {
                            this.maintainCacheSize();
                        }
                        this.addFileToIndex(fileName, this.config.cacheDirectoryName).then(() => {
                            this.getCachedImagePath(currentItem.imageUrl).then((localUrl) => {
                                currentItem.resolve(localUrl);
                                resolve(true);
                                done();
                                this.maintainCacheSize();
                            });
                        });
                    }).catch((err: Error) => {
                        // could not write image
                        error(err);
                        reject(err);
                    });
                }).catch((err: Error) => {
                    // could not get image via http
                    error(err);
                    reject(err);
                });
            });
        } else {
            // Prevented same Image from loading at the same time
            this.currentlyProcessing[currentItem.imageUrl].then(() => {
                this.getCachedImagePath(currentItem.imageUrl).then(localUrl => {
                    currentItem.resolve(localUrl);
                });
                done();
            },
                (e) => {
                    error(e);
                });
        }
    }

    /**
     * Search if the url is currently in the queue
     * @param imageUrl {string} Image url to search
     * @returns {boolean}
     */
    private currentlyInQueue(imageUrl: string) {
        return this.queue.some(item => item.imageUrl === imageUrl);
    }

    /**
     * Initialize the cache service
     * @param [boolean] replace Whether to replace the cache directory if it already exists
     */
    private initCache(replace?: boolean): void {
        this.concurrency = this.config.concurrency;
        console.log("image loader > init cache");

        // create cache directories if they do not exist
        this.createCacheDirectory(replace)
            .catch(e => {
                this.throwError(e);
                this.isInit = true;
            })
            .then(() => this.indexCache())
            .then(() => {
                this.isCacheReady = true;
                this.isInit = true;
            });
    }

    /**
     * Adds a file to index.
     * Also deletes any files if they are older than the set maximum cache age.
     * @param {FileEntry} file File to index
     * @returns {Promise<any>}
     */
    private async addFileToIndex(fileName: string, dirPath: string): Promise<any> {
        try {
            let options: StatOptions = {
                path: dirPath + "/" + fileName,
                directory: this.getFileCacheDirectory()
            };
            const stat: StatResult = await Filesystem.stat(options);
            const size = stat.size;
            if ((this.config.maxCacheAge > -1) && (Date.now() - new Date(stat.mtime).getTime() > this.config.maxCacheAge)) {
                console.log("image loader > clearing cached file due to max age: " + stat.mtime);
                await this.removeFile(fileName);
            } else {
                this.currentCacheSize += size;
                this.cacheIndex.push({
                    name: fileName,
                    modificationTime: new Date(stat.mtime),
                    size
                });
            }
        } catch (e) {
            this.throwError(e);
        }
    }

    /**
     * Indexes the cache if necessary
     * @returns {Promise<void>}
     */
    private async indexCache(): Promise<void> {
        const directory = this.getFileCacheDirectory();
        const dirPath = this.config.cacheDirectoryName;
        console.log("image loader > indexing cache");
        try {
            const { files } = await Filesystem.readdir({ path: dirPath, directory });
            await Promise.all(files.map(async file => this.addFileToIndex(file.name, dirPath)));
            this.cacheIndex.sort((a, b) => b.modificationTime.getTime() - a.modificationTime.getTime());
            this.indexed = true;
        } catch (e) {
            this.throwError(e);
        }
    }

    /**
     * This method runs every time a new file is added.
     * It checks the cache size and ensures that it doesn't exceed the maximum cache size set in the config.
     * If the limit is reached, it will delete old images to create free space.
     */
    private maintainCacheSize(): void {
        if (this.config.maxCacheSize > -1 && this.indexed) {
            const maintain = () => {
                if (this.currentCacheSize > this.config.maxCacheSize) {
                    // called when item is done processing
                    // tslint:disable-next-line:ban-types
                    const next: Function = () => {
                        this.currentCacheSize -= file.size;
                        maintain();
                    };

                    // grab the first item in index since it's the oldest one
                    const file: IndexItem = this.cacheIndex.splice(0, 1)[0];

                    if (typeof file === 'undefined') {
                        return maintain();
                    }

                    // delete the file then process next file if necessary
                    this.removeFile(file.name)
                        .then(() => next())
                        .catch(() => next()); // ignore errors, nothing we can do about it
                }
            };

            maintain();
        }
    }

    /**
     * Remove a file
     * @param {string} file The name of the file to remove
     * @returns {Promise<any>}
     */
    private async removeFile(file: string): Promise<void> {
        const directory = this.getFileCacheDirectory();

        try {
            await Filesystem.deleteFile({ path: `${this.config.cacheDirectoryName}/${file}`, directory });
            if (this.isWKWebView && !this.isIonicWKWebView) {
                await Filesystem.deleteFile({ path: `${this.config.cacheDirectoryName}/${file}`, directory: Directory.Cache });
            }
        } catch (error) {
            this.throwError(error);
        }
    }

    /**
     * Get the local path of a previously cached image if exists
     * @param {string} url The remote URL of the image
     * @returns {Promise<string>} Returns a promise that resolves with the local path if exists, or rejects if doesn't exist
     */
    private async getCachedImagePath(url: string): Promise<string> {
        if (!this.isCacheReady) {
            throw new Error('Cache is not ready');
        }

        if (this.isDevServer) {
            return url;
        }

        const fileName = this.createFileName(url);
        const directory = this.getFileCacheDirectory();

        try {
            const { data } = await Filesystem.readFile({ path: `${this.config.cacheDirectoryName}/${fileName}`, directory });
            return `data:*/*;base64,${data}`;
        } catch {
            throw new Error('File not found');
        }
    }

    /**
     * Throws a console error if debug mode is enabled
     * @param {any[]} args Error message
     */
    private throwError(...args: any[]): void {
        if (this.config.debugMode) {
            args.unshift('ImageLoader Error: ');
            console.error.apply(console, args);
        }
    }

    /**
     * Throws a console warning if debug mode is enabled
     * @param {any[]} args Error message
     */
    private throwWarning(...args: any[]): void {
        if (this.config.debugMode) {
            args.unshift('ImageLoader Warning: ');
            console.warn.apply(console, args);
        }
    }

    /**
     * Create the cache directories
     * @param replace {boolean} override directory if exists
     * @returns {Promise<DirectoryEntry|FileError>} Returns a promise that resolves if the directories were created, and rejects on error
     */
    private async createCacheDirectory(replace: boolean = false): Promise<void> {
        const directory = this.getFileCacheDirectory();
        const cacheDirName = this.config.cacheDirectoryName;

        if (replace) {
            await Filesystem.mkdir({ path: cacheDirName, directory, recursive: true });
        } else {
            try {
                await Filesystem.stat({ path: cacheDirName, directory });
            } catch {
                await Filesystem.mkdir({ path: cacheDirName, directory, recursive: true });
            }
        }

        if (this.isWKWebView && !this.isIonicWKWebView) {
            if (replace) {
                await Filesystem.mkdir({ path: cacheDirName, directory: Directory.Cache, recursive: true });
            } else {
                try {
                    await Filesystem.stat({ path: cacheDirName, directory: Directory.Cache });
                } catch {
                    await Filesystem.mkdir({ path: cacheDirName, directory: Directory.Cache, recursive: true });
                }
            }
        }
    }

    /**
     * Creates a unique file name out of the URL
     * @param {string} url URL of the file
     * @returns {string} Unique file name
     */
    private createFileName(url: string): string {
        // hash the url to get a unique file name
        return (
            this.hashString(url).toString() +
            (this.config.fileNameCachedWithExtension
                ? this.getExtensionFromUrl(url)
                : '')
        );
    }

    /**
     * Converts a string to a unique 32-bit int
     * @param {string} str string to hash
     * @returns {number} 32-bit int
     */
    private hashString(str: string): number {
        let hash = 0,
            char;
        if (str.length === 0) {
            return hash;
        }
        for (let i = 0; i < str.length; i++) {
            char = str.charCodeAt(i);
            // tslint:disable-next-line
            hash = (hash << 5) - hash + char;
            // tslint:disable-next-line
            hash = hash & hash;
        }
        return hash;
    }

    /**
     * Extract extension from filename or url
     *
     * @param {string} url
     * @returns {string}
     */
    private getExtensionFromUrl(url: string): string {
        const urlWitoutParams = url.split(/\#|\?/)[0];
        return (
            // tslint:disable-next-line:no-bitwise
            urlWitoutParams.substr((~-urlWitoutParams.lastIndexOf('.') >>> 0) + 1) ||
            this.config.fallbackFileNameCachedExtension
        );
    }
}
