
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdsTemplateService, IAdConfig } from './ads-template';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { AdsDef, ERewardExitCodes } from '../../apis/ads-def';
import { EOS } from 'src/app/classes/def/app/app';
import { SleepUtils } from '../../utils/sleep-utils';
import { WaitUtils } from '../../utils/wait-utils';

declare var admob;
declare var consent;

interface IAdRequestOptions {
    id?: string;
    testDevices?: string[];
    childDirected?: boolean;
    underAgeOfConsent?: boolean;
    npa?: '1';
}

/**
 * https://admob-plus.github.io/docs/installation.html
 * In order to fulfill GDPR, it is important to ask user's consent before displaying personalized ads.
 * Install cordova-plugin-consent and following the example code.
 * cordova plugin add cordova-plugin-consent
 */
@Injectable({
    providedIn: 'root'
})
export class AdmobplusAdsService implements AdsTemplateService {

    reward: boolean = false;

    watchRewardCallback: BehaviorSubject<number>;

    initialized: boolean = false;

    adId = AdsDef.adId;
    adIdIos = AdsDef.adIdIOS;

    rewardAdConfig: IAdConfig = {
        id: null,
        isTesting: false,
        autoShow: true
    };

    interAdConfig: IAdConfig = {
        id: null,
        isTesting: false,
        autoShow: true
    };

    bannerAdConfig: IAdConfig = {
        id: null,
        isTesting: false,
        autoShow: true
    };

    isTesting: boolean = false;
    /** non-personalized ads, default: true */
    npa: boolean = true;
    /** not working yet */
    enableConsent: boolean = false;

    constructor(

    ) {
        console.log("admobplus ads service created");
        this.watchRewardCallback = new BehaviorSubject(null);
    }

    setup() {
        document.addEventListener('admob.reward_video.reward', () => {
            console.log("admob > reward issued");
            this.reward = true;
            this.watchRewardCallback.next(ERewardExitCodes.reward);
        });

        document.addEventListener('admob.reward_video.close', () => {
            console.log("admob > closed");
            this.watchRewardCallback.next(ERewardExitCodes.close);
        });

        document.addEventListener('admob.reward_video.load_fail', () => {
            console.log("admob > load fail");
            this.watchRewardCallback.next(ERewardExitCodes.fail);
        });

        document.addEventListener('admob.reward_video.exit_app', () => {
            console.log("admob > exit app");
            this.watchRewardCallback.next(ERewardExitCodes.exit);
        });

        this.initialized = true;
        console.log("ads/admobplus setup complete");
    }

    /**
     * load ad ids, os based
     */
    setAdIds(testing: boolean) {
        this.isTesting = testing;
        if (testing) {
            // testing
            this.bannerAdConfig.isTesting = true;
            this.interAdConfig.isTesting = true;
            this.rewardAdConfig.isTesting = true;

            this.bannerAdConfig.id = AdsDef.adIdTesting.banner;
            this.interAdConfig.id = AdsDef.adIdTesting.interstitial;
            this.rewardAdConfig.id = AdsDef.adIdTesting.reward;

            admob.setDevMode(true);
        } else {
            // real ads
            this.bannerAdConfig.isTesting = false;
            this.interAdConfig.isTesting = false;
            this.rewardAdConfig.isTesting = false;

            switch (GeneralCache.os) {
                case EOS.ios:
                    this.bannerAdConfig.id = this.adIdIos.banner;
                    this.interAdConfig.id = this.adIdIos.interstitial;
                    this.rewardAdConfig.id = this.adIdIos.reward;
                    break;
                case EOS.android:
                    this.bannerAdConfig.id = this.adId.banner;
                    this.interAdConfig.id = this.adId.interstitial;
                    this.rewardAdConfig.id = this.adId.reward;
                    break;
            }

            admob.setDevMode(false);
        }

        console.log("set ad ids (reward) ", this.rewardAdConfig);
    }


    handleRewardVideo(onPrepared: () => Promise<any>): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.reward = false;
            this.watchRewardCallback.next(null);

            let options: IAdRequestOptions = {
                id: this.rewardAdConfig.id
            };

            if (this.npa) {
                options.npa = '1';
            }

            admob.rewardVideo.load(options).then(async () => {
                console.log("ad prepared");
                if (onPrepared) {
                    await SleepUtils.sleep(100);
                    await onPrepared();
                }
                admob.rewardVideo.show().then(async () => {
                    console.log("reward video shown");
                    let resCode: number = await WaitUtils.waitObsAnyTimeout(this.watchRewardCallback, [ERewardExitCodes.close, ERewardExitCodes.fail, ERewardExitCodes.exit], null);
                    if (resCode === ERewardExitCodes.fail) {
                        resolve(null);
                    } else {
                        resolve(this.reward);
                    }
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
    }

    showBanner(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {

            let options: IAdRequestOptions = {
                id: this.bannerAdConfig.id
            };

            if (this.npa) {
                options.npa = '1';
            }

            admob.banner.show(options).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }

    showInterstitial(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            let options: IAdRequestOptions = {
                id: this.interAdConfig.id
            };

            if (this.npa) {
                options.npa = '1';
            }

            admob.interstitial.load(options).then(() => {
                console.log("ad prepared");
                admob.interstitial.show().then((res) => {
                    console.log("inter video result: ", res);
                    resolve(true);
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * check consent, update ad config to include the npa flag
     * @param consentStatus 
     */
    updateConsent(consentStatus: boolean) {
        if (consentStatus === true) {
            this.npa = false;
        } else {
            this.npa = true;
        }
        console.log("update consent (reward) ", this.rewardAdConfig);
    }

    /**
     * https://github.com/admob-plus/admob-plus/blob/master/examples/consent/www/js/index.js#L15
     * TODO: not working
     * use this: https://github.com/ratson/cordova-plugin-admob-free/issues/223#issuecomment-392913554
     * @param testDeviceId 
     */
    async showConsent() {
        const publisherIds = ['pub-4039552009844785'];
        if (this.isTesting) {
            let testDeviceId: string = "33BE2250B43518CCDA7DE426D04EE231";
            await consent.addTestDevice(testDeviceId);
            await consent.setDebugGeography('EEA');
        }
        let check = await consent.checkConsent(publisherIds);
        console.log("check consent: ", check);

        const ok = await consent.isRequestLocationInEeaOrUnknown();

        if (!ok && this.isTesting) {
            alert('please update testDeviceId from logcat');
        }

        const form = new consent.Form({
            privacyUrl: 'https://policies.google.com/privacy',
            adFree: true,
            nonPersonalizedAds: true,
            personalizedAds: true,
        });

        await form.load();
        const result = await form.show();
        return result;
    }
}
