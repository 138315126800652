import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ParamHandler } from 'src/app/classes/general/params';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { ICameraScanFrameNavParams, ICameraScanFrameReturnParams } from './utils';
import { ImageUtilsService } from 'src/app/services/media/image-utils';
import { AppConstants } from 'src/app/classes/app/constants';
import { Html5QrcodeResult, Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode";

@Component({
  selector: 'modal-camera-scan-frame',
  templateUrl: './camera-scan-frame.component.html',
  styleUrls: ['./camera-scan-frame.component.scss'],
})
export class CameraScanFrameViewComponent implements OnInit, OnDestroy, AfterViewInit {
  // @ViewChild('inputcamera', { read: ElementRef, static: false }) cameraInput: ElementRef;

  description: string = "";
  title: string = "";
  caption: string = "";

  vs: IViewSpecs = ViewSpecs.getDefault();

  appIconsStandard = EAppIconsStandard;
  params: ICameraScanFrameNavParams = null;
  np: INavParams = null;

  loading: boolean = true;

  html5QrcodeScanner: Html5QrcodeScanner;


  constructor(
    public modalCtrl: ModalController,
    public shareProvider: ShareService,
    public imgUtils: ImageUtilsService,
    public uiext: UiExtensionService
  ) {

  }



  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: ICameraScanFrameNavParams = np.params;
      this.params = params;
      if (np.view) {
        this.vs = np.view;
      }
      console.log(np);
      this.description = params.description;
      this.title = params.title;
      this.caption = params.caption;

      this.initScanner();

      this.loading = false;
    }
  }

  initScanner() {
    this.html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      {
        fps: 10,
        qrbox: {
          width: 250, height: 250
        },
        supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA],
        disableFlip: true,
        videoConstraints: {
          facingMode: "environment"
        }
      },
      /* verbose= */ false);

    this.html5QrcodeScanner.render((decodedText: string, decodedResult: Html5QrcodeResult) => {
      // handle the scanned code as you like, for example:
      console.log(`Code matched = ${decodedText}`, decodedResult);
      let data: ICameraScanFrameReturnParams = {
        content: decodedText
      };
      this.dismiss(data);
    }, (_error: string) => {
      // handle scan failure, usually better to ignore and keep scanning.
      // console.warn(`Code scan error = ${error}`);
    });
  }


  ngAfterViewInit(): void {
    setTimeout(() => {
      // this.initScannerRaw();
      // this.initScanner();
    }, 1000);
  }

  ngOnDestroy() {
    if (this.html5QrcodeScanner) {
      this.html5QrcodeScanner.clear();
    }
  }

  dismiss(data: ICameraScanFrameReturnParams) {
    setTimeout(() => {
      this.modalCtrl.dismiss(data).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }
}
