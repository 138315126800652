import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MPHomePage } from './mp-home.page';
import { PagesModule } from '../../pages.module';

const routes: Routes = [
  {
    path: '',
    component: MPHomePage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [MPHomePage]
})
export class MPHomePageModule {}
