import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiDef, IApiKey } from '../../classes/app/api';
import { ApiUtils } from "../../classes/app/ApiUtils";
import { GenericDataService } from '../general/data/generic';
import { IGenericResponse } from '../../classes/def/requests/general';
import { IMapStyle } from '../../classes/def/core/story';
import { IStatDef } from '../../classes/def/user/stats';
import { IServiceUrlResponse, IAppServiceDef, IStandardPhoto } from '../../classes/def/app/app';
import { SettingsManagerService } from '../general/settings-manager';
import { ITreasureSpec } from '../../classes/def/places/leplace';
import { IGameConfig } from '../../classes/app/config';
import { IMessageTimelineEntry, IMessageTimelineCategory } from '../../classes/def/newsfeed/message-timeline';
import { StorageOpsService } from '../general/data/storage-ops';
import { ELocalAppDataKeys } from '../../classes/def/app/storage-flags';
import { IPlaceProvider } from '../../classes/def/places/provider';
import { AppSettings } from '../utils/app-settings';
import { ITutorial, ITutorialCollection } from '../../classes/def/app/tutorials';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { AppConstants } from 'src/app/classes/app/constants';
import { IAppVersionDB } from 'src/app/classes/def/app/app-version';
import { BehaviorSubject } from 'rxjs';
import { IGenericSlideData } from 'src/app/classes/def/views/slides';

/**
 * general app resource provider
 */
@Injectable({
    providedIn: 'root'
})
export class ResourcesCoreDataService {

    infoData: ITutorialCollection = {};
    infoText: { [key: string]: string } = {};
    watchTriggerApiKeyLoading: BehaviorSubject<boolean>;

    constructor(
        public http: HttpClient,
        public generic: GenericDataService,
        public storageOps: StorageOpsService,
        public settingsManager: SettingsManagerService
    ) {
        console.log("resources core data service created");
        this.serverUrl = ApiDef.mainServerURL;

        this.watchTriggerApiKeyLoading = new BehaviorSubject(null);
    }
    serverUrl: string;

    init() {

    }

    triggerApiKeyLoadingRequired() {
        this.watchTriggerApiKeyLoading.next(true);
        this.watchTriggerApiKeyLoading.next(null);
    }

    getWatchTriggerApiKeyLoadingRequired() {
        return this.watchTriggerApiKeyLoading;
    }


    getVideoTutorial(videoCode: number) {
        return this.generic.genericGetStandard("/resources/get-video-tutorial", {
            videoCode: videoCode
        });
    }


    /**
     * get map style list from server
     */
    getMapStyleList() {
        let promise = new Promise((resolve, reject) => {
            if (GeneralCache.resourceCache.general.mapStyles.loaded) {
                resolve(GeneralCache.resourceCache.general.mapStyles.content);
                return;
            }

            this.generic.genericGet("/resources/get-map-style-defs", null).then((response: IGenericResponse) => {
                let mapStyles: IMapStyle[] = response.data;
                GeneralCache.resourceCache.general.mapStyles.content = mapStyles;
                GeneralCache.resourceCache.general.mapStyles.loaded = true;
                resolve(mapStyles);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get object specs list for e.g. AR display/map display
     * e.g. coin, target
     */
    getTreasureSpecs(include: number[]) {
        let promise = new Promise((resolve, reject) => {

            // disable cache (may use different includes)

            // if (GeneralCache.resourceCache.general.treasureSpecs.loaded) {
            //     resolve(GeneralCache.resourceCache.general.treasureSpecs.content);
            //     return;
            // }
            this.generic.genericPostStandard("/dex/treasures/get-treasure-specs", {
                include: include,
                all: true
            }).then((response: IGenericResponse) => {
                let objects: ITreasureSpec[] = response.data;
                GeneralCache.resourceCache.general.treasureSpecs.content = objects;
                GeneralCache.resourceCache.general.treasureSpecs.loaded = true;
                resolve(objects);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get object specs list for e.g. AR display/map display,
     * e.g. use for show layers filtering
     */
    getTreasureSpecsGeneric() {
        let promise = new Promise((resolve, reject) => {
            if (GeneralCache.resourceCache.general.treasureSpecsGeneric.loaded) {
                resolve(GeneralCache.resourceCache.general.treasureSpecsGeneric.content);
                return;
            }
            this.generic.genericGetStandard("/dex/treasures/get-treasure-specs-generic", null).then((response: IGenericResponse) => {
                let objects: ITreasureSpec[] = response.data;
                GeneralCache.resourceCache.general.treasureSpecsGeneric.content = objects;
                GeneralCache.resourceCache.general.treasureSpecsGeneric.loaded = true;
                resolve(objects);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * message timeline
     * used as subtitle tutorial in the gmap
     * @param categoryCode 
     */
    getMessageTimeline(categoryCode: number): Promise<IMessageTimelineEntry[]> {
        let promise: Promise<IMessageTimelineEntry[]> = new Promise((resolve, reject) => {
            if (GeneralCache.resourceCache.general.messageTimeline.loaded) {
                // check category loaded
                let category: IMessageTimelineCategory = GeneralCache.resourceCache.general.messageTimeline.content.find(e => e.code === categoryCode);
                if (category) {
                    resolve(category.entries);
                    return;
                }
            }
            this.generic.genericGetStandard("/resources/get-ticker-message-timeline", {
                categoryCode: categoryCode
            }).then((response: IGenericResponse) => {
                let entries: IMessageTimelineEntry[] = response.data;
                let content: IMessageTimelineCategory[] = GeneralCache.resourceCache.general.messageTimeline.content;
                let category: IMessageTimelineCategory = GeneralCache.resourceCache.general.messageTimeline.content.find(e => e.code === categoryCode);
                if (!category) {
                    content.push({
                        code: categoryCode,
                        entries: entries
                    });
                } else {
                    category.entries = entries;
                }
                GeneralCache.resourceCache.general.messageTimeline.loaded = true;
                resolve(entries);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get standard config
     * that is used by the app (front end to back end)
     * e.g. coin value
     */
    getStandardConfig(): Promise<IGameConfig> {
        let promise: Promise<IGameConfig> = new Promise((resolve, reject) => {
            if (GeneralCache.resourceCache.general.gameConfig.loaded) {
                resolve(GeneralCache.resourceCache.general.gameConfig.content);
                return;
            }

            this.generic.genericGetStandard("/resources/get-app-config", {

            }).then((response: IGenericResponse) => {
                let config: IGameConfig = response.data;
                let ok: boolean = false;
                if (config) {
                    GeneralCache.resourceCache.general.gameConfig.content = config;
                    GeneralCache.resourceCache.general.gameConfig.loaded = true;
                    ok = true;
                }

                console.log("standard config loaded (" + ok + "): ", GeneralCache.resourceCache.general.gameConfig.content);
                resolve(config);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get location providers e.g. google, here maps
     */
    getLocationProviders() {
        let promise = new Promise((resolve, reject) => {
            if (GeneralCache.resourceCache.general.placeProviders.loaded) {
                resolve(GeneralCache.resourceCache.general.placeProviders.content);
                return;
            }
            this.generic.genericGetStandard("/service/get-location-providers", {
            }).then((response: IGenericResponse) => {
                let config: IPlaceProvider[] = response.data;
                if (config) {
                    GeneralCache.resourceCache.general.placeProviders.content = config;
                    GeneralCache.resourceCache.general.placeProviders.loaded = true;
                }
                resolve(config);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * check map style server version
     * if same as local storage:
     * get map style from local storage
     * if not available, or greater than local storage:
     * get map style from server
     * then save to local storage
     * @param name 
     */
    getMapStyle(name: string): Promise<IMapStyle> {
        let promise: Promise<IMapStyle> = new Promise((resolve, reject) => {
            // check in local storage

            console.log("get map style: " + name);
            let mapStyleList: IMapStyle[] = GeneralCache.resourceCache.general.mapStyles.content;
            console.log(mapStyleList);
            let mapStyleInfo: IMapStyle = mapStyleList.find(elem => elem.name === name);
            console.log(mapStyleInfo);

            if (!mapStyleInfo) {
                // no data is available for the requested style
                reject(new Error("metadata not available"));
                return;
            }

            this.storageOps.getLocalDataKey(ELocalAppDataKeys.mapStyles).then((data: IMapStyle[]) => {
                let localDataAll: IMapStyle[] = [];
                let localData: IMapStyle = null;
                if (data) {
                    localDataAll = data;
                    localData = data.find(elem => elem.name === name);
                }

                // check version
                if (localData && mapStyleInfo && localData.version === mapStyleInfo.version) {
                    // same version, keep local (no further request)
                    resolve(localData);
                    return;
                } else {
                    // local version is not up to date
                    // or local data not initialized
                    // download latest data from the server
                    this.generic.genericGet("/resources/download-map-style", { name: name }).then((response: IGenericResponse) => {
                        let serverDataConfig: IMapStyle = Object.assign(mapStyleInfo, response.data);
                        // overwrite local storage
                        // resolve in the mean time
                        localDataAll = this.updateLocalMapStyle(localDataAll, serverDataConfig);
                        this.storageOps.setStorageFlagNoAction({
                            flag: ELocalAppDataKeys.mapStyles,
                            value: localDataAll
                        });
                        resolve(serverDataConfig);
                    }).catch((err: Error) => {
                        console.error(err);
                        // no data is available for the requested style
                        reject(new Error("resource not available"));
                    });
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }



    /**
     * update local map style with data from the server
     * @param localDataAll 
     * @param serverDataConfig 
     */
    updateLocalMapStyle(localDataAll: IMapStyle[], serverData: IMapStyle) {
        let exists: boolean = false;
        if (localDataAll) {
            for (let i = 0; i < localDataAll.length; i++) {
                if (localDataAll[i].code === serverData.code) {
                    localDataAll[i] = serverData;
                    console.log("update map style replace existing");
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                console.log("update map style create new");
                localDataAll.push(serverData);
            }
        } else {
            console.log("update map style create array");
            localDataAll = [serverData];
        }
        return localDataAll;
    }


    getPopupMessage() {
        return this.generic.genericGetStandard("/service/startup-check", null);
    }


    /**
     * get photos for standard features
     * e.g. activity failed because time expired => clock
     */
    getStandardPhotos() {
        let promise = new Promise((resolve, reject) => {

            if (GeneralCache.resourceCache.general.standardPhotos.loaded) {
                resolve(GeneralCache.resourceCache.general.standardPhotos.content);
                return;
            }

            this.generic.genericGetStandard("/resources/get-default-photos", {

            }).then((response: IGenericResponse) => {
                let sp: IStandardPhoto[] = response.data;
                GeneralCache.resourceCache.general.standardPhotos.content = sp;
                GeneralCache.resourceCache.general.standardPhotos.loaded = true;
                resolve(sp);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * load api keys from db
     * override local keys
     * used for quick updates to ext services without updating the app
     */
    loadApiKeys(): Promise<IApiKey[]> {
        let promise: Promise<IApiKey[]> = new Promise((resolve, reject) => {
            this.generic.genericGetStandard("/resources/get-app-keys", {

            }).then((response: IGenericResponse) => {
                let apiKeys: IApiKey[] = response.data;
                ApiDef.loadRemoteConfig(apiKeys);
                resolve(apiKeys);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * load api keys from db
     * override local keys
     * used for quick updates to ext services without updating the app
     */
    loadGoogleWebClientId(): Promise<IApiKey> {
        let promise: Promise<IApiKey> = new Promise((resolve, reject) => {
            this.generic.genericGetNoAuth("/register/get-google-web-key", {

            }).then((response: IGenericResponse) => {
                let apiKey: IApiKey = response.data;
                if (apiKey) {
                    ApiDef.googleWebClientId = apiKey.value;
                }
                resolve(apiKey);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * get the requested photo
     * for the standard feature code
     * @param code 
     */
    getStandardPhotoByCode(code: number) {
        let promise = new Promise((resolve, reject) => {
            this.getStandardPhotos().then((sp: IStandardPhoto[]) => {
                let photoUrl: string = null;
                let sp1: IStandardPhoto = sp.find(s => s.code === code);
                if (sp1) {
                    photoUrl = sp1.photoUrl;
                }
                resolve(photoUrl);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * get app details (incl. crt. version from the db to check for updates)
     * w/ cache
     */
    getAppDetailsFromServer(): Promise<IAppVersionDB> {
        let promise: Promise<IAppVersionDB> = new Promise((resolve, reject) => {
            if (GeneralCache.appDetails) {
                resolve(GeneralCache.appDetails);
                return;
            }
            this.generic.genericGetStandardWData("/service/get-app-version-db", {
                appId: AppConstants.appId
            }).then((data: IAppVersionDB) => {
                GeneralCache.appDetails = data;
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get url of remote services (that do not pass through the gateway)
     * these services should be used AFTER the app is already initialized (fire and forget)
     */
    getServiceUrl(isTester: boolean, reload: boolean): Promise<IServiceUrlResponse> {
        let promise: Promise<IServiceUrlResponse> = new Promise((resolve, reject) => {
            let serviceUrlResponse: IServiceUrlResponse;

            // for debug purpose it's possible that another url will be used e.g. local, defined in the CODE
            if (SettingsManagerService.settings.app.settings.useLocalServiceUrl.value) {
                resolve(GeneralCache.resourceCache.general.appServices.content);
                return;
            }

            if (!reload && GeneralCache.resourceCache.general.appServices.loaded) {
                resolve(GeneralCache.resourceCache.general.appServices.content);
                return;
            }

            this.generic.genericGetStandard("/service/get-service-url", null).then((response: IGenericResponse) => {
                serviceUrlResponse = response.data;
                if (serviceUrlResponse.object) {
                    this.settingsManager.getSettingsLoaded(true).then((res: boolean) => {
                        if (res) {
                            ApiUtils.setupServiceUrl(GeneralCache.resourceCache.general.appServices.content.object,
                                serviceUrlResponse.object, isTester, SettingsManagerService.settings.app.settings.useDebugServiceUrls.value);
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                    });
                    GeneralCache.resourceCache.general.appServices.loaded = true;
                }
                resolve(serviceUrlResponse);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * after loading resources
     */
    setupServiceUrl(): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.settingsManager.getSettingsLoaded(true).then((res: boolean) => {
                if (res) {
                    ApiUtils.setupServiceUrlCore(GeneralCache.resourceCache.general.appServices.content.object, AppSettings.testerMode, SettingsManagerService.settings.app.settings.useDebugServiceUrls.value);
                    resolve(true);
                }
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
    }

    /**
     * check if the service is available
     * if not (maybe not initialized first time), then request available services again from the server
     */
    checkAvailableService(code: number) {
        let promise = new Promise((resolve, reject) => {
            let available: boolean = false;
            // for debug purpose it's possible that another url will be used e.g. local
            if (SettingsManagerService.settings.app.settings.useLocalServiceUrl.value) {
                available = true;
            } else {
                available = this.checkAvailableServiceByCode(GeneralCache.resourceCache.general.appServices.content.object, code);
            }
            if (available) {
                resolve(true);
            } else {
                this.getServiceUrl(AppSettings.testerMode, true).then((svc: IServiceUrlResponse) => {
                    available = this.checkAvailableServiceByCode(svc.object, code);
                    resolve(available);
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }

    private checkAvailableServiceByCode(source: IAppServiceDef, code: number) {
        let keys = Object.keys(source);
        let available: boolean = false;
        for (let i = 0; i < keys.length; i++) {
            if (source[keys[i]].code === code) {
                available = source[keys[i]].status === 1;
                break;
            }
        }
        console.log("available: ", source, code, available);
        return available;
    }

    /**
     * get tutorial slider gen
     */
    getTutorialSliderGen(mode: number, storyId: number): Promise<IGenericSlideData[]> {
        let req = {
            mode: mode,
            storyId: storyId
        };
        return this.generic.genericGetNoAuthWData("/info/get-slider-tutorial", req);
    }


    /**
    * get about content
    */
    getAboutContent() {
        return this.generic.genericGetNoAuth("/info/about", null);
    }


    /**
    * get generic info text by code
    */
    getInfoText(code: number): Promise<string> {
        let promise: Promise<string> = new Promise((resolve, reject) => {
            if (this.infoText[code.toString()] != null) {
                resolve(this.infoText[code.toString()]);
                return;
            }
            this.generic.genericGetNoAuthWData("/info/get-text", {
                code: code
            }).then((res: string) => {
                this.infoText[code.toString()] = res;
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get generic info data by code
     */
    getInfoData(code: number, fromCache: boolean): Promise<ITutorial> {
        let promise: Promise<ITutorial> = new Promise((resolve, reject) => {
            if (this.infoData[code.toString()] != null && fromCache) {
                resolve(this.infoData[code.toString()]);
                return;
            }
            this.generic.genericGetNoAuthWData("/info/get-data", {
                code: code
            }).then((res: ITutorial) => {
                this.infoData[code.toString()] = res;
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get signup terms
     * BEFORE signup
     * so no auth!
     */
    getSignupTerms() {
        return this.generic.genericGetNoAuth("/info/signup-terms", null);
    }

    getSignupTutorial() {
        return this.generic.genericGetNoAuth("/info/signup-tutorial", null);
    }

    /**
     * get tutorial seen, if it's not saved locally (maybe the user changed it's phone)
     */
    getTutorialSeen(): Promise<number> {
        let promise: Promise<number> = new Promise((resolve, reject) => {
            if (GeneralCache.resourceCache.user.general.loaded) {
                resolve(GeneralCache.resourceCache.user.general.content.tutorialSeen);
                return;
            }
            this.generic.genericGetStandard("/user/check-tutorial-seen", null).then((res: IGenericResponse) => {
                resolve(res.data ? 1 : 0);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * set tutorial seen, after the user was shown the signup tutorial
     */
    setTutorialSeen() {
        return this.generic.genericPostStandard("/user/set-tutorial-seen", null);
    }

    /**
    * get about content
    */
    getContactEmail() {
        return this.generic.genericGetStandard("/service/get-contact-email", null);
    }

    /**
   * get weights for stats
   * definitions from the database
   * @param categoryCode 
   */
    getStatScores() {
        let promise = new Promise((resolve, reject) => {
            if (GeneralCache.resourceCache.general.statScores.loaded) {
                resolve(GeneralCache.resourceCache.general.statScores.content);
                return;
            }

            this.generic.genericGetStandard("/dex/stats/get-stat-scores", {
                newApi: true
            }).then((res: IGenericResponse) => {
                let stats: IStatDef[] = res.data;
                GeneralCache.resourceCache.general.statScores.content = stats;
                GeneralCache.resourceCache.general.statScores.loaded = true;
                resolve(stats);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }
}
