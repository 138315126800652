<div class="flex-card category-card bg-container item-border-radius" tappable (click)="onSelect()"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data.photoUrl}">

  <div class="content center-items">
    <div class="width-m text-color-overlay">
      <span class="heading-item text-color-element bold">{{data.name}}</span>
    </div>
  </div>
</div>

<!-- <div class="flex-card item-border-radius padding-top-s padding-bottom-s" tappable (click)="onSelect()">
  <ion-row>
    <ion-col size="12">
      <span class="text-color-primary bold info-text-lg uppercase">
        {{data.name}}
      </span>
    </ion-col>
  </ion-row>
  <div class="padding-s">
    <responsive-img [photoUrl]="data.photoUrl" [fixedZoom]="false" class="border-radius"></responsive-img>
  </div>
  <div>
    <ion-row>
      <ion-col size="12">
        <div [innerHTML]="data.description"
          class="text-color-primary info-text-s italic bold description-frame description-frame-p">
        </div>
      </ion-col>
    </ion-row>
  </div>
</div> -->