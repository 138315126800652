import { Observable } from "rxjs";

import { DownloadProgress, IRemotePackage } from "@ionic-native/code-push/ngx";

export interface IUpdatingAppNavParms {
    title: string;
    infoHTML: string;
    /**
     * behavior subject
     */
    progressObservable?: Observable<DownloadProgress>;
    /**
     * behavior subject
     */
    statusObservable?: Observable<number>;
    package1?: IRemotePackage;
}

export enum ELiveUpdateStatus {
    started = 1,
    finished = 2,
    error = 3
}
