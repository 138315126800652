import { Injectable } from "@angular/core";
import { GenericDataService } from "../general/data/generic";
import { AnalyticsService } from "../general/apis/analytics";
import { IEventStoryGroupLinkData } from 'src/app/classes/def/core/links';


@Injectable({
    providedIn: 'root'
})
export class LinksDataService {

    private eventGroupLinkData: IEventStoryGroupLinkData;
    private storyGroupLinkData: IEventStoryGroupLinkData;

    constructor(
        public generic: GenericDataService,
        public analytics: AnalyticsService
    ) {
        console.log("events data service created");
    }

    setEventGroupLinkData(e: IEventStoryGroupLinkData) {
        this.eventGroupLinkData = e;
    }

    setStoryGroupLinkData(e: IEventStoryGroupLinkData) {
        this.storyGroupLinkData = e;
    }

    getEventGroupLinkData() {
        return this.eventGroupLinkData;
    }

    getStoryGroupLinkData() {
        return this.eventGroupLinkData;
    }

    getLinkData() {
        if (this.eventGroupLinkData) {
            return this.eventGroupLinkData;
        }
        if (this.storyGroupLinkData) {
            return this.storyGroupLinkData;
        }
    }

    clearLinkData() {
        this.eventGroupLinkData = null;
        this.storyGroupLinkData = null;
    }

    setStoryGroupLinkDataWrapper(groupId: number, storyId: number, overrideProgress: boolean) {
        if (!storyId) {
            this.storyGroupLinkData = null;
            this.setStoryGroupLinkData(null);
            return;
        }
        this.storyGroupLinkData = {
            storyId: storyId,
            groupId: groupId,
            overrideProgress: overrideProgress
        };
        this.setStoryGroupLinkData(this.storyGroupLinkData);
    }

    setGroupLinkDataWrapper(groupId: number) {
        this.storyGroupLinkData = {
            storyId: null,
            groupId: groupId
        };
        this.setStoryGroupLinkData(this.storyGroupLinkData);
    }
}
