
import { Injectable } from '@angular/core';
import { SettingsManagerService } from '../../../general/settings-manager';
import { IPlatformFlags } from '../../../../classes/def/app/platform';
import { ITextInputParams } from 'src/app/modals/generic/modals/text-input/text-input.component';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { TextInputCoreViewComponent } from 'src/app/modals/generic/modals/text-input-core/text-input-core.component';
import { Messages } from 'src/app/classes/def/app/messages';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { SupportDataService } from 'src/app/services/data/support';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { ICheckLocationFrameParams, CheckLocationViewComponent, ICheckLocationFrameReturnData } from 'src/app/modals/app/modals/check-location/check-location.component';


@Injectable({
    providedIn: 'root'
})
export class SupportModalsService {

    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        private settingsProvider: SettingsManagerService,
        private support: SupportDataService,
        private analytics: AnalyticsService,
        private uiext: UiExtensionService
    ) {
        console.log("support modals service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    /**
     * Report application error and attach feedback
     * @param errorString 
     */
    reportErrorWizard(errorString: string) {
        this.uiext.showAlert(Messages.msg.applicationError.after.msg, Messages.msg.applicationError.after.sub, 2, ["Dismiss", "Report"]).then((res: number) => {
            switch (res) {
                case EAlertButtonCodes.ok:
                    this.writeFeedback(errorString);
                    break;
                case EAlertButtonCodes.cancel:
                    break;
            }
        }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err);
        });
    }

    /**
     * submit feedback to the developers
     */
    writeFeedback(errorString: string) {
        let params: ITextInputParams = {
            description: "Please send us any suggestions and questions that you might have. We will consider anything that may improve the app functionality and user experience for the next updates.",
            title: "Feedback",
            maxLength: EMessageTrim.userInput,
            okLabel: "Send",
            text: null,
            focused: false,
            allowEmpty: false
        };

        this.uiext.showCustomModal(null, TextInputCoreViewComponent, {
            view: {
                fullScreen: false,
                transparent: false,
                large: true,
                addToStack: true,
                frame: false
            },
            params: params
        }).then((description: string) => {

            if (description) {
                description = "<p>" + description + "</p>";
                if (errorString) {
                    description += "<p>ErrorString: " + errorString + "</p>";
                }
            } else {
                if (errorString) {
                    description = "<p>ErrorString: " + errorString + "</p>";
                }
            }

            if (description) {
                this.support.sendFeedback(description).then(() => {
                    this.uiext.showAlertNoAction(Messages.msg.feedbackReceived.after.msg, Messages.msg.feedbackReceived.after.sub);
                }).catch((err) => {
                    console.error(err);
                    this.analytics.dispatchError(err, "support");
                });
            }
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    showLocationErrorModal(err: Error) {
        return new Promise<boolean>((resolve) => {
            let params: ICheckLocationFrameParams = {
                title: Messages.msg.locationError.after.msg,
                description: ErrorMessage.parse(err, Messages.msg.locationError.after.sub),
                footnote: "<p>Notes</p><p>> Location accuracy may be affected by cloudy weather conditions or being indoors</p><p>> Still not getting location updates? You may have to restart your device</p>"
            };

            let navParams: INavParams = {
                view: {
                    fullScreen: false,
                    transparent: false,
                    large: true,
                    addToStack: true,
                    frame: false
                },
                params: params
            };

            this.uiext.showCustomModal(null, CheckLocationViewComponent, navParams).then((res: ICheckLocationFrameReturnData) => {
                if (res != null) {
                    resolve(res.resolved);
                } else {
                    resolve(false);
                }
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });

    }

}
