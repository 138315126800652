import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { IBackendLocation, ILocationContainer } from 'src/app/classes/def/places/backend-location';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { AppConstants } from 'src/app/classes/app/constants';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { Util } from 'src/app/classes/general/util';
import { ELocationFlag } from 'src/app/classes/def/places/app-location';
import { EAppIcons, EAppIconsStandard, IIconSpec } from 'src/app/classes/def/app/icons';
import { LocationUtils } from 'src/app/services/location/location-utils';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { IPhotoFrameNavParams, PhotoFrameViewComponent } from 'src/app/modals/generic/modals/photo-frame/photo-frame.component';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { ISelectPhotoOptions } from 'src/app/services/location/location-utils-def';
import { LocationUtilsWizard } from 'src/app/services/location/location-utils-wizard';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivityUtils } from 'src/app/classes/utils/activity-utils';
import { ILocationInfoText, LocationDispUtils } from 'src/app/services/location/location-disp-utils';
import { StringUtils } from 'src/app/services/app/utils/string-utils';
import { EStoryLocationDoneFlag } from 'src/app/classes/def/nav-params/story';
import { EAudioGuide, ECheckpointNavMode } from 'src/app/classes/def/core/interactive-features';
import { IStory } from 'src/app/classes/def/core/story';

@Component({
  selector: 'location-info',
  templateUrl: './location-info.component.html',
  styleUrls: ['./location-info.component.scss'],
})
export class LocationInfoComponent implements OnInit, OnDestroy {
  hasItems: boolean = false;
  iconClass: string = "";

  footerText: string = "";

  icons = {
    progress: {
      icon: "",
      custom: false
    },
    place: {
      icon: "pin",
      custom: false
    },
    flag: {
      icon: "",
      custom: false
    },
    activity: {
      icon: "",
      custom: false
    }
  };


  show = {
    placeName: false,
    activityIcon: false,
    placeIcon: true,
    placePhoto: false,
    flagIcon: false,
    progressIcon: false
  };


  progressClass: string = "";
  photoUrl: string = null;
  headerText: string = "";
  locationType: string = "";
  photoUrlLoaded: string = null;
  bloc: IBackendLocation = null;
  audioGuide: boolean = false;
  bikeMode: boolean = false;
  appIcons = EAppIcons;

  locationNameClass: string = "text-color-element";
  large: boolean = false;

  @Input()
  data: ILocationContainer;

  @Input()
  story: IStory;

  @Input()
  check: boolean;
  @Input()
  icon: boolean;
  @Input()
  tooltip: boolean;

  @Input()
  highlight: boolean;
  @Input()
  view: boolean;

  @Input()
  description: string;

  @Input()
  useDefaultPhoto: boolean;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  constructor(
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public tutorials: TutorialsService,
    public locationUtilsWizard: LocationUtilsWizard,
    public sanitizer: DomSanitizer
  ) {

  }

  open() {
    this.action.emit();
  }


  placeTypePopup() {
    if (this.tooltip) {
      let description: string = `
          <p>The mode of the selected place (dynamic, saved, verified)</p>
         <p>` + this.locationType.toUpperCase() +
        `</p>`;

      this.uiextStandard.showStandardModal(null, EModalTypes.description, "Place type", {
        view: {
          fullScreen: false,
          transparent: AppConstants.transparentMenus,
          large: false,
          addToStack: true,
          frame: false
        },
        params: { description: description }
      }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  viewPhoto() {
    // console.log("view photo");
    let headerText: string = StringUtils.trimName(this.headerText, EMessageTrim.locationInfoCard);
    if (this.tooltip && this.photoUrl) {
      let params: IPhotoFrameNavParams = {
        photoUrl: this.photoUrl,
        title: headerText,
        sharePhoto: false,
        uploadPhoto: false,
        isDataUrl: false
      };

      let navParams: INavParams = {
        view: {
          fullScreen: false,
          transparent: false,
          large: false,
          addToStack: true,
          frame: false
        },
        params: params
      };

      this.uiext.showCustomModal(null, PhotoFrameViewComponent, navParams).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  onImageLoaded(event) {
    let loadOk: boolean = Util.checkImageLoadedEvent(event);
    if (loadOk) {
      // if (this.data && this.bloc.photoUrlSmall) {
      //     this.photoUrlLoaded = this.bloc.photoUrlSmall;
      // }
      this.photoUrlLoaded = this.photoUrl;
    }
  }

  activityPopup() {
    if (this.tooltip) {
      let description: string = `
          <p>Challenge: ` + this.bloc.activity.name.toUpperCase() + `</p>
         <p>` + this.bloc.activity.description + `</p`;

      this.uiextStandard.showStandardModal(null, EModalTypes.description, "Challenge", {
        view: {
          fullScreen: false,
          transparent: AppConstants.transparentMenus,
          large: false,
          addToStack: true,
          frame: false
        },
        params: { description: description }
      }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  progressPopup() {
    if (this.tooltip) {
      let description: string = `
          <p>Progress: ` + ((this.bloc.done === EStoryLocationDoneFlag.done) ? "Finished" : "Pending") +
        `</p>`;
      this.uiextStandard.showStandardModal(null, EModalTypes.description, "Progress", {
        view: {
          fullScreen: false,
          transparent: AppConstants.transparentMenus,
          large: false,
          addToStack: true,
          frame: false
        },
        params: { description: description }
      }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }


  showDescription() {
    // let description: string = GetDefaults.getPlaceDescription(this.locationData.backendLocation);
    if (!this.data) {
      return;
    }

    if (!this.tooltip) {
      return;
    }

    let description: string = this.bloc.description;
    this.tutorials.showTutorialNoAction("Place Tutorial", null, description, null, true);
  }


  ngOnInit() {
    if (this.data) {
      console.log("format location info: ", this.data);
      this.bloc = this.data.merged;

      switch (this.bloc.flag) {
        case ELocationFlag.FIXED:
          this.locationType = "verified";
          break;
        case ELocationFlag.RANDOM:
          this.locationType = "dynamic";
          break;
        case ELocationFlag.SAVED:
          this.locationType = "saved";
          break;
      }

      if (this.bloc.items && this.bloc.items.length > 0) {
        this.hasItems = true;
      }

      if (this.bloc.registered) {
        // the place may be registered even if it is originally a random place
        // then the place type doesn't matter, the place is set as verified
        this.locationType = "verified";
      }

      if (this.bloc.activity) {
        let icon: IIconSpec = {
          icon: "compass",
          custom: false
        };

        icon = ActivityUtils.getIcon(this.bloc.activity.name);
        if (!icon.icon) {
          if (this.bloc.activity.icon) {
            icon.icon = this.bloc.activity.icon;
          }
        }

        if (icon) {
          this.icons.activity = icon;
          this.show.activityIcon = true;
        }
      }

      this.iconClass = "leplace-icon icon-color-primary";
      this.progressClass = this.iconClass;
      this.icons.flag = {
        icon: "",
        custom: false
      };

      switch (this.bloc.flag) {
        case ELocationFlag.FIXED:
          // this.statusIcon = "leplace-icon fa fa-2x fa-check-square-o"
          this.icons.flag.icon = "star";
          break;
        case ELocationFlag.RANDOM:
          // this.statusIcon = "leplace-icon fa fa-2x fa-random"
          this.icons.flag.icon = "shuffle";
          break;
        case ELocationFlag.SAVED:
          // this.statusIcon = "leplace-icon fa fa-2x fa-floppy-o"
          this.icons.flag.icon = "bookmark";
          break;
      }

      this.show.flagIcon = true;

      if (this.bloc.locationType) {
        if (!this.bloc.locationType.photo) {
          if (this.bloc.locationType.icon) {
            this.show.placeIcon = true;
            this.icons.place.icon = this.bloc.locationType.icon;
          }
        } else {
          this.show.placePhoto = true;
          this.icons.place.icon = this.bloc.locationType.photoUrl;
        }
      }

      if (this.bloc.done === EStoryLocationDoneFlag.done) {
        // this.progressIcon = "margin-right-s leplace-icon cursor-pointer fa fa-2x fa-check-square-o";
        this.icons.progress.icon = EAppIconsStandard.check;
        this.progressClass = "leplace-icon icon-color-primary";
      } else {
        // this.progressIcon = "margin-right-s leplace-icon cursor-pointer fa fa-2x fa-square-o";
        this.icons.progress.icon = EAppIconsStandard.close;
      }

      this.show.progressIcon = true;

      let hidden: boolean = LocationDispUtils.checkHiddenPlace(this.data, false);
      let locInfo: ILocationInfoText = LocationDispUtils.getLocationInfoText(this.bloc, hidden);
      this.headerText = locInfo.headerText;
      this.footerText = locInfo.footerText;
      // this.locationNameClass = locInfo.nameClass;
      let headerText = StringUtils.trimName(this.headerText, EMessageTrim.locationInfoCard);
      let footerText = StringUtils.trimName(this.footerText, EMessageTrim.locationInfoCard);
      if (headerText.length < this.headerText.length) {
        this.large = true;
      }
      if (footerText.length < this.footerText.length) {
        this.large = true;
      }
      this.large = true; //override
      let opts: ISelectPhotoOptions = {
        hidden: SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value ? true : null
      };

      if (this.useDefaultPhoto) {
        opts.hidden = true;
      }

      LocationUtils.selectPlaceDispPhoto(this.data, null, opts);

      if (!opts.hidden) {
        this.locationUtilsWizard.runThroughCache(this.data.merged.photoUrl).then((extracted: string) => {
          this.photoUrl = extracted;
        });
      } else {
        this.photoUrl = this.data.dispPhoto.photoUrl;
      }

      if (this.bloc.audioGuide !== EAudioGuide.disabled && this.bloc.audioGuide != null) {
        this.audioGuide = true;
      }
      if (this.bloc.navMode === ECheckpointNavMode.auto) {
        this.bikeMode = true;
      }

      if (this.story && this.story.audioGuide !== EAudioGuide.disabled && this.story.audioGuide != null && this.bloc.audioGuide == null) {
        this.audioGuide = true;
      }
      if (this.story && this.story.navMode === ECheckpointNavMode.auto && this.bloc.navMode == null) {
        this.bikeMode = true;
      }
    }
  }

  ngOnDestroy() {

  }
}
