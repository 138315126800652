
export class AdsDef {
    public static adId = {
        banner: "ca-app-pub-4039552009844785/5330837591",
        interstitial: "ca-app-pub-4039552009844785/5381495408",
        reward: "ca-app-pub-4039552009844785/7983608010"
    };

    public static adIdIOS = {
        banner: "ca-app-pub-4039552009844785/5708618562",
        interstitial: "ca-app-pub-4039552009844785/8005617029",
        reward: "ca-app-pub-4039552009844785/3402317178"
    };

    public static adIdTesting = {
        banner: "ca-app-pub-3940256099942544/6300978111",
        interstitial: "ca-app-pub-3940256099942544/1033173712",
        reward: "ca-app-pub-3940256099942544/5224354917"
    }

    public static adColony = {
        android: {
            appId: "app09f6ce016c4b45838d",
            zoneId: "vz602b5ba3ffa84740b6"
        },
        ios: {
            appId: "app74849d4eba354eb29a",
            zoneId: "vzefdc706d4e9a40e48a"
        }
    }

    constructor(
    ) {
    }
}

export enum ERewardExitCodes {
    open = 1,
    load = 2,
    start = 3,
    reward = 4,
    close = 5,
    fail = 6,
    exit = 7
}
