import { IStory, EStoryMode, EStoryUnlock } from "../def/core/story";
import { EStartCodes, EStoryLocationDoneFlag } from "../def/nav-params/story";
import { EAppIcons } from '../def/app/icons';
import { IBackendLocation } from '../def/places/backend-location';
import { IAppLocation } from '../def/places/app-location';
import { GeometryUtils } from 'src/app/services/utils/geometry-utils';

import { ActivityUtils } from "./activity-utils";
import { IActivity, IFindActivityDef } from "../def/core/activity";
import { IFixedCoin } from "../def/core/custom-param";
import { ILatLng } from "../def/map/coords";


export interface IStoryProgress {
    storyLength: number,
    progressRaw: number,
    inProgress: boolean,
    finished: boolean,
    progress: number
};

export interface ICheckStoryProviders {
    hasProviders: boolean,
    providers: IBackendLocation[]
};


export class StoryUtils {

    static lockInDistanceFactor: number = 2;

    /**
     * check if story is complete
     * @param story 
     */
    static checkFinishedStoryComplete(story: IStory) {
        let finished: boolean = true;
        for (let i = 0; i < story.locations.length; i++) {
            if (story.locs[i].merged.done !== EStoryLocationDoneFlag.done) {
                finished = false;
                break;
            }
        }
        return finished;
    }

    static checkResumeStory(story: IStory) {
        let startCode: number = EStartCodes.start;
        // for (let i = 0; i < story.locations.length; i++) {
        //     if (story.locations[i].googleId) {
        //         startCode = StartCodes.continue;
        //         break;
        //     }
        // }

        let finished: boolean = true;

        for (let i = 0; i < story.locations.length; i++) {
            if (story.locs[i].merged.done === EStoryLocationDoneFlag.done) {
                startCode = EStartCodes.continue;
                break;
            }
        }

        for (let i = 0; i < story.locations.length; i++) {
            if (story.locs[i].merged.done !== EStoryLocationDoneFlag.done) {
                finished = false;
                break;
            }
        }

        if (finished) {
            startCode = EStartCodes.restart;
        }

        return startCode;
    }

    static getStoryProgress(story: IStory): IStoryProgress {
        let res = {
            storyLength: 0,
            progressRaw: 0,
            inProgress: false,
            finished: false,
            progress: 0
        }

        if (!story.locations) {
            return res;
        }

        res.storyLength = story.locations.length;
        res.progressRaw = story.locs.filter(location => location.merged.done === EStoryLocationDoneFlag.done).length;
        res.inProgress = res.progressRaw > 0;
        res.finished = res.progressRaw === res.storyLength;
        let storyProgress: number = (res.progressRaw * 100) / res.storyLength;
        res.progress = storyProgress;
        return res;
    }

    static mergeTranslation(story: IStory, translation: IStory) {
        console.log("merge translation");
        story.description = translation.description;
        story.finishedDescription = translation.finishedDescription;
        story.name = translation.name;
        story.language = translation.language;

        let syncActivityParams = (slocActivity: IActivity, tlocActivity: IActivity) => {
            slocActivity.customParams = tlocActivity.customParams;
            slocActivity.fixedCoins = tlocActivity.fixedCoins;
        };

        for (let i = 0; i < story.locations.length; i++) {
            let sloc = story.locations[i];
            let tloc = ((translation.locations != null) && (i < translation.locations.length)) ? translation.locations[i] : null;
            if (tloc != null) {              
                sloc.name = tloc.name;
                sloc.heading = tloc.heading;
                sloc.description = tloc.description;
                sloc.finishedDescription = tloc.finishedDescription;
                sloc.navDescription = tloc.navDescription;
                sloc.shortDescription = tloc.shortDescription;
                if (sloc.activity != null && tloc.activity != null) {
                    syncActivityParams(sloc.activity, tloc.activity);
                }
                if (sloc.activityFinish != null && tloc.activityFinish != null) {
                    syncActivityParams(sloc.activityFinish, tloc.activityFinish);
                }
                if (sloc.activityNav != null && tloc.activityNav != null) {
                    syncActivityParams(sloc.activityNav, tloc.activityNav);
                }
            }
        }
        // console.log("merge translation done: ", story);
    }

    static checkAppLocationStoryInProgress(storyLocations: IAppLocation[]): boolean {
        for (let i = 0; i < storyLocations.length; i++) {
            if (storyLocations[i].loc.merged.done === EStoryLocationDoneFlag.done) {
                return true;
            }
        }
        return false;
    }

    static checkAppLocationStoryFinished(storyLocations: IAppLocation[]): boolean {
        for (let i = 0; i < storyLocations.length; i++) {
            if (storyLocations[i].loc.merged.done !== EStoryLocationDoneFlag.done) {
                return false;
            }
        }
        return true;
    }

    static getModeIcon(story: IStory) {
        let modeIcon: string = null;
        switch (story.mode) {
            case EStoryMode.linear:
                modeIcon = EAppIcons.linear;
                break;
            case EStoryMode.preload:
            case EStoryMode.mp:
                modeIcon = EAppIcons.network;
                break;
        }
        return modeIcon;
    }

    static getModeText(story: IStory) {
        let modeText: string = null;
        switch (story.mode) {
            case EStoryMode.linear:
                modeText = "linear"
                break;
            case EStoryMode.preload:
            case EStoryMode.mp:
                modeText = "nonlinear";
                break;
        }
        return modeText;
    }


    static checkProviders(story: IStory): ICheckStoryProviders {
        let hasProviders: boolean = false;
        let hasPrimaryProvider: boolean = false;
        let storyProviders: IBackendLocation[] = [];

        if (story.primaryProvider != null) {
            storyProviders = [story.primaryProvider];
            hasPrimaryProvider = true;
        }

        hasProviders = story.providers && story.providers.length > 0;
        if (hasProviders) {
            let primaryProviderId: number = null;
            if (hasPrimaryProvider) {
                primaryProviderId = story.providerId;
            }
            let providers: IBackendLocation[] = story.providers.filter(sp => sp.locationId !== primaryProviderId).map(sp => sp.location);
            storyProviders = storyProviders.concat(providers);
        }

        let res: ICheckStoryProviders = {
            hasProviders: hasPrimaryProvider || hasProviders,
            providers: storyProviders
        };

        return res;
    }

    /**
     * handle server optimizations (e.g. not loading providers with the stories request)
     */
    static checkProvidersLoadingRequired(story: IStory): boolean {
        if (story.providerId != null && story.primaryProvider == null) {
            return true;
        }
        if (story.providers && story.providers.length > 0) {
            for (let provider of story.providers) {
                if (provider.location == null) {
                    return true;
                }
            }
        }
        return false;
    }

    static checkLoadProvidersWizard(story: IStory, onLoadProviders: () => any): ICheckStoryProviders {
        let res: ICheckStoryProviders = {
            hasProviders: false,
            providers: []
        };

        let check = StoryUtils.checkProviders(story);
        if (check.hasProviders) {
            res.providers = check.providers;
            if (StoryUtils.checkProvidersLoadingRequired(story)) {
                setTimeout(() => {
                    onLoadProviders();
                }, 1);
            } else {
                res.hasProviders = true;
            }
        } else {
            if (StoryUtils.checkProvidersLoadingRequired(story)) {
                setTimeout(() => {
                    onLoadProviders();
                }, 1);
            }
        }
        return res;
    }

    static checkQRUnlock(story: IStory) {
        return story.qrUnlock != null && story.qrUnlock !== EStoryUnlock.none;
    }

    static checkQRUnlockRequired(story: IStory) {
        return story.qrUnlock === EStoryUnlock.required;
    }

    static checkQRUnlockRedeemOnly(story: IStory) {
        return story.qrUnlock === EStoryUnlock.redeemOnly;
    }

    static getCentralLocation(storyLocs: IAppLocation[]) {
        let points: ILatLng[] = storyLocs.map(loc => loc.location).filter(loc => loc != null);
        console.log("points central: ", points);
        if (points.length === 0) {
            return null;
        }
        let central: ILatLng = GeometryUtils.getCenterPoint(points);
        if (!central) {
            central = new ILatLng(storyLocs[0].location.lat, storyLocs[0].location.lng);
        }
        return central;
    }


    static getDistanceToStoryLocationProximity(currentLocation: ILatLng, sloc: IAppLocation) {
        let proximityRadius: number = 0;
        if (sloc.placeMarker.extRadius != null) {
            proximityRadius = sloc.placeMarker.extRadius;
        }
        let proximityCenter: ILatLng = sloc.location;
        if (sloc.placeMarker.fakeLocation != null) {
            // console.log("proximity check > offset center: ", sloc);
            proximityCenter = sloc.placeMarker.fakeLocation;
        } else {
            // console.log("proximity check > center: ", sloc);
        }
        // get distance to selected location w/o the proximity radius
        let dist: number = GeometryUtils.getDistanceBetweenEarthCoordinates(currentLocation, proximityCenter, Number.MAX_VALUE);
        dist -= proximityRadius;
        if (dist < 0) {
            dist = 0;
        }
        return dist;
    }

    /**
     * format story location specs preload
     * e.g., find activity custom circle radius
     * @param slocs 
     */
    static formatStoryLocationSpecs(slocs: IAppLocation[]) {
        for (let appLocation of slocs) {
            let customStartRadius: number = appLocation.loc.merged.startRadius
            let activity: IActivity = appLocation.loc.merged.activity;
            if (ActivityUtils.isFindTypeActivity(appLocation.loc)) {
                console.log("find activity format custom start radius: ", customStartRadius);
                if (customStartRadius != null) {
                    let ap: IFindActivityDef = activity.params;
                    // use custom circle radius if defined in story location
                    ap.circleRadius = customStartRadius;
                }
            }
        }
    }

    static getFixedCoins(loc: IAppLocation, nav: boolean) {
        let fc: IFixedCoin[] = null;
        if (loc == null) {
            return null;
        }
        if (loc && loc.loc && loc.loc.merged && loc.loc.merged.activity) {
            if (nav) {
                if (loc.loc.merged.activityNav) {
                    fc = loc.loc.merged.activityNav.fixedCoins;
                }
            } else {
                if (loc.loc.merged.activity) {
                    fc = loc.loc.merged.activity.fixedCoins;
                }
            }
        }
        return fc;
    }
}
