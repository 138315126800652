<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div class="leplace-slider-modal" fxLayout="column">
    <div class="padding-s" fxLayout="column">
      <div #sliderContainer>
        <ion-slides *ngIf="loaded" [pager]="true" [options]="sliderOptions"
          class="scroll-content-modal-1-border-flush slide-width-modal" (ionSlideDidChange)="slideChanged()">
          <ion-slide *ngFor="let slide of slidesContent" class="slide-height-button" fxLayout="column">
            <div class="scrollbar-y padding-s" fxLayout="column">
              <div>
                <h2 class="slide-title text-color-primary">
                  <b>{{slide.title}}</b>
                </h2>
              </div>
              <div class="padding-top-s">
                <responsive-img [photoUrl]="slide.img" customClass="slide-image"></responsive-img>
                <!-- <img [src]="slide.img" class="slide-image" /> -->
              </div>
              <div [innerHTML]="slide.desc"
                class="info-text-m description-frame text-color-primary padding-top-s text-left">
              </div>
              <div>
                <ion-button *ngIf="slide.action" large fill="clear" (click)="submitSelection()">
                  Continue
                  <icon-wrapper class="slot-icon-padding" slot="start" icon="arrow-forward"></icon-wrapper>
                </ion-button>
              </div>
              <div *ngIf="slide.footer" [innerHTML]="slide.footer"
                class="info-text-m description-frame bold text-color-primary text-left"></div>
            </div>
          </ion-slide>
        </ion-slides>
      </div>
    </div>

    <div class="theme-border-top" [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col size="12">
            <ion-button class="action-button-fill button-color-alternate" *ngIf="!isLastSlide" (click)="slideNext()">
              <span class="button-font-size-s">Next</span>
            </ion-button>
            <ion-button class="action-button-fill button-color-accent" *ngIf="isLastSlide" (click)="submitSelection()">
              <span class="button-font-size-s">Continue</span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>

</modal-container>