
import { Injectable } from '@angular/core';

import { MQTTService } from './mqtt.service';
import { MQTTChatService } from './chat.service';

@Injectable({
    providedIn: 'root'
})
export class MQTTManagerService {

    storyId: number = null;

    constructor(
        private mqttService: MQTTService,
        private mqttChatService: MQTTChatService
    ) {
        console.log("MQTT manager service created");

    }

    changeStoryContext(storyId: number) {
        if (storyId !== this.storyId) {
            this.clearChatHistory();
        }
        this.storyId = storyId;
    }

    clearChatHistory() {
        this.mqttChatService.clearChatHistory();
    }

}
