import { Component, OnInit, Input, OnDestroy } from '@angular/core';

@Component({
  selector: 'icon-wrapper-extra',
  templateUrl: './icon-wrapper-extra.component.html',
  styleUrls: ['./icon-wrapper-extra.component.scss'],
})
export class IconWrapperExtraComponent implements OnInit, OnDestroy {

  slot: string = "icon-only";

  @Input()
  icon: string;

  @Input()
  custom: boolean;

  @Input()
  text: string;

  @Input()
  disabled: boolean;


  constructor() { }

  ngOnInit() {
    if (this.text != null) {
      this.slot = "start";
    }
  }

  ngOnDestroy() {

  }

}
