<div *ngIf="init">
  <!-- class="mp-user-card" -->
  <ion-grid class="full-w" [ngClass]="{'card-selected': self}">

    <ion-row class="center-items center-text">
      <!-- <ion-col size="12">
        <div>
          <span class="info-text-m bold text-color-primary">{{userName}}</span>
        </div>
      </ion-col> -->
      <ion-col size="12" class="text-div-container">
        <div class="info-text-m bold text-color-primary" [innerHtml]="userName"></div>
      </ion-col>

    </ion-row>

    <ion-row>
      <ion-col size="4">
        <img [src]="bgPhoto" alt="Avatar" class="img-avatar-small-crop">
      </ion-col>
      <ion-col size="4">
        <!-- details -->
        <ion-row>
          <ion-col size="12">
            <span class="info-text-s bold text-color-primary">{{levelDisp}}</span>
          </ion-col>
        </ion-row>
        <ion-row>
          <ion-col size="12" class="padding-xs">
            <span class="info-text-s bold text-color-primary">{{scoreDisp}}</span>
          </ion-col>
        </ion-row>
        <!-- status icons -->
      </ion-col>
      <ion-col size="4">
        <ion-checkbox [ngModel]="selected" ngDefaultControl class="custom-checkbox checkbox-right"
          (click)="onCheck($event)">
        </ion-checkbox>
      </ion-col>
    </ion-row>

    <ion-row>
      <!-- <ion-col size="12" class="padding-xs">
        <span class="info-text-s bold text-color-primary" *ngIf="connected">ONLINE</span>
        <span class="info-text-s bold text-color-primary" *ngIf="!connected">OFFLINE</span>
      </ion-col> -->

      <ion-col size="4" class="text-left">
        <ion-button [disabled]="!enabledInSession" class="button-color-transparent text-color-primary">
          <icon-wrapper slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
        </ion-button>
      </ion-col>
      <ion-col size="4" class="text-center">
        <ion-button [disabled]="!isAdminCard" class="button-color-transparent text-color-primary">
          <icon-wrapper slot="icon-only" icon="settings" [custom]="false"></icon-wrapper>
        </ion-button>
      </ion-col>
      <ion-col size="4" class="text-right">
        <ion-button [disabled]="!connected" class="button-color-transparent text-color-primary">
          <icon-wrapper slot="icon-only" icon="wifi"></icon-wrapper>
        </ion-button>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="connecting">
      <ion-col size="12">
        <div class="padding-bottom-s progress-bar-alternate">
          <mat-progress-bar mode="indeterminate" class="progress-rounded" color="primary">
          </mat-progress-bar>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>