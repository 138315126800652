import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/**
 * Hammerjs must be imported for gestures
 */
// import 'hammerjs';


if (environment.production) {
  enableProdMode();
}

function addHashIfNeeded() {
  // If no hash fragment exists, add a default one
  const currentURL = window.location.href;
  const origin = window.location.origin;
  console.log("current URL: ", currentURL);
  if (!currentURL.includes('#')) {
    // If there is no hash in the URL, add it before the route
    // const updatedURL = currentURL.replace(/\/?$/, '#/');
    const updatedURL = window.location.origin + "/#" + currentURL.replace(origin, "");
    console.log("updated URL: ", updatedURL);
    window.history.replaceState({}, document.title, updatedURL);
  }
}

addHashIfNeeded();

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
