

import { Injectable } from '@angular/core';
import { SettingsManagerService } from '../general/settings-manager';
import { BehaviorSubject } from 'rxjs';
import { WebviewUtilsService } from '../app/utils/webview-utils';


@Injectable({
    providedIn: 'root'
})
export class GooglePlaceServiceProvider {
    place: google.maps.places.PlaceResult;
    placeService: google.maps.places.PlacesService = null;
    autocompleteService: google.maps.places.AutocompleteService = null;
    serviceReady;

    constructor(
        public settingsProvider: SettingsManagerService,
        public webView: WebviewUtilsService
    ) {
        console.log("place service created");
        this.serviceReady = new BehaviorSubject(null);
        this.initService().then().catch((err: Error) => { console.error(err); });
    }

    initService() {
        let promise = new Promise((resolve, reject) => {
            this.webView.ready().then(() => {
                this.settingsProvider.checkMapReady().then(() => {
                    this.placeService = new google.maps.places.PlacesService(document.createElement('div'));
                    this.autocompleteService = new google.maps.places.AutocompleteService();
                    console.log("init service done");
                    this.serviceReady.next(true);
                    resolve(true);
                });
            }).catch((err: Error) => {
                console.error(err);
                this.serviceReady.next(false);
                reject(err);
            });
        });
        return promise;
    }

    getAutocompleteService() {
        return this.autocompleteService;
    }

    getServiceReady() {
        return this.serviceReady;
    }

    getPlaceDetails(googleId: string): Promise<google.maps.places.PlaceResult> {
        let promise: Promise<google.maps.places.PlaceResult> = new Promise((resolve, reject) => {
            console.log("google place search: ", googleId);
            if (!this.placeService) {
                reject(new Error("google places service not available"));
                return;
            }
            // this.initService().then(()=>{
            let req: google.maps.places.PlaceDetailsRequest = {
                placeId: googleId
            };
            this.placeService.getDetails(req, (result: google.maps.places.PlaceResult, status: google.maps.places.PlacesServiceStatus) => {
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    resolve(result);
                } else {
                    reject(new Error("google places service error"));
                }
            });
            // }).catch((err)=>{
            //     reject(err);
            // });

        });
        return promise;
    }
}


