
import { Injectable } from "@angular/core";
import { DeviceOrientation, DeviceOrientationCompassOptions, DeviceOrientationCompassHeading } from '@ionic-native/device-orientation/ngx';
import { SettingsManagerService } from '../general/settings-manager';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { Messages } from 'src/app/classes/def/app/messages';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { UiExtensionService } from '../general/ui/ui-extension';
import { AnalyticsService } from '../general/apis/analytics';
import { GeometryUtils } from '../utils/geometry-utils';
import { BehaviorSubject, timer } from 'rxjs';
import { Gyroscope, GyroscopeOptions, GyroscopeOrientation } from "@ionic-native/gyroscope/ngx";
import { IJoystickStatusUpdate } from "src/app/components/generic/components/joystick-ngx/joystick-ngx.component";
import { IJoystickDrivingSteeringData, JoystickUtils } from "../utils/joystick-utils";
import { AmountInputViewComponent } from "src/app/modals/generic/modals/amount-input/amount-input.component";
import { INavParams } from "src/app/classes/def/nav-params/general";
import { IAmountNavParams } from "src/app/classes/def/nav-params/inventory";
import { ArrayFilters } from "../utils/array-filters";
import { GeneralCache } from "src/app/classes/app/general-cache";
import { PermissionsService } from "../general/permissions/permissions";
import { SleepUtils } from "../utils/sleep-utils";
import { BackgroundModeWatchService } from "../general/apis/background-mode-watch";

// import { PluginListenerHandle } from '@capacitor/core';
// import { Motion, RotationRate } from '@capacitor/motion';

export interface IHeadingStatus {
    gyroscopeNotAvailable: boolean;
    compassNotAvailable: boolean;
    compassHeading: number;
    gpsSpeed: number;
    gpsHeading: number;
    gpsReadHeading: boolean;
    droneHeading: number;
    filteredHeading: number;
    filteredHeadingContinuous: number;
    compositeHeading: number;
    alpha: number;
    beta: number;
    gamma: number;
    compassNeedsCalibration: boolean;
    compassNeedsCalibrationNotify: boolean;
    compassTimestamp: number;
}

export interface IHeadingFilterComp {
    gpsHeadingCorrectionRateDefault: number;
    gpsHeadingCorrectionRate: number;
    gpsHeadingCorrectionTrack: number;
    compositeFilterN: number;
    v1: number; // m/s
    v2: number; // m/s
    vmax: number;
    r1: number; // scale
    r2: number; // scale
}


@Injectable({
    providedIn: 'root'
})
export class HeadingService {

    status: IHeadingStatus = {} as IHeadingStatus;

    // accelHandler: PluginListenerHandle;

    statusInit: IHeadingStatus = {
        gyroscopeNotAvailable: false,
        compassNotAvailable: false,
        compassHeading: null,
        gpsSpeed: null,
        gpsHeading: null,
        gpsReadHeading: false,
        droneHeading: null,
        filteredHeading: null,
        filteredHeadingContinuous: null,
        compositeHeading: null,
        alpha: null,
        beta: null,
        gamma: null,
        compassNeedsCalibration: false,
        compassNeedsCalibrationNotify: false,
        compassTimestamp: null
    };

    // filterCompInit: IHeadingFilterComp = {
    //     gpsHeadingCorrectionRateDefault: 5,
    //     gpsHeadingCorrectionRate: 5,
    //     gpsHeadingCorrectionTrack: 0,
    //     compositeFilterN: 5,
    //     v1: 1,
    //     v2: 3,
    //     r1: 1,  //   1*5=1 (1:5)
    //     r2: 0.2 // 0.2*5=1 (1:1)
    // }

    filterCompInit: IHeadingFilterComp = {
        gpsHeadingCorrectionRateDefault: 5,
        gpsHeadingCorrectionRate: 5,
        gpsHeadingCorrectionTrack: 0,
        compositeFilterN: 3,
        v1: 1,
        v2: 3,
        vmax: 5,
        r1: 1,  //   1*5=1   (1:5)
        r2: 0.1 // 0.1*5=0.5 (2:1)
    }

    filterCompInvInit: IHeadingFilterComp = {
        gpsHeadingCorrectionRateDefault: 5,
        gpsHeadingCorrectionRate: 5,
        gpsHeadingCorrectionTrack: 0,
        compositeFilterN: 5,
        v1: 1,
        v2: 5,
        vmax: 5,
        r1: 0.2, // 0.2*5=1 (1:1)
        r2: 1    //   1*5=1 (1:5)
    }

    filterComp: IHeadingFilterComp = {} as IHeadingFilterComp;
    filterCompInv: IHeadingFilterComp = {} as IHeadingFilterComp;

    flags = {
        useGpsHeading: true,
        useDroneHeading: false,
        useCompositeHeading: true
    };

    watches = {
        heading: null,
        orientation: null
    };

    subscription = {
        heading: null,
        headingGPS: null,
        orientation: null,
        foregroundWeb: null
    };

    observables = {
        heading: null,
        orientation: null,
        warning: null
    };

    timeout = {
        noCompassHeading: null,
        noCompassHeadingOnce: null,
        popupDelay: null
    };

    manualCompDelta: number = 0;
    manualCompDeltaChanged: boolean = false;
    dsData: IJoystickDrivingSteeringData;

    compassFilterN: number = 10;
    popupShown: boolean = false;

    platform: IPlatformFlags = {} as IPlatformFlags;

    simulateCompassDev: boolean = false;
    watchRequested: boolean = false;
    enableErrorPopup: boolean = false;

    constructor(
        public deviceOrientation: DeviceOrientation,
        public gyroscope: Gyroscope,
        public settingsProvider: SettingsManagerService,
        public uiext: UiExtensionService,
        public analytics: AnalyticsService,
        public arrayFilters: ArrayFilters,
        public permissionsService: PermissionsService,
        public bgmWatch: BackgroundModeWatchService
    ) {
        console.log("heading service created");
        this.status = Object.assign({}, this.statusInit);
        this.filterComp = Object.assign({}, this.filterCompInit);
        this.filterCompInv = Object.assign({}, this.filterCompInvInit);
        this.observables = ResourceManager.initBsubObj(this.observables);
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    /**
     * normally used for navigation
     * only disabled when using 2D navigation mode with compass only
     * @param enable 
     */
    switchGPSHeading(enable: boolean) {
        console.log("switch GPS heading: ", enable);
        if (!this.flags.useDroneHeading) {
            this.flags.useGpsHeading = enable;
        } else {
            console.warn("drone lock");
        }
    }

    /**
     * not implemented
     * @param enable 
     */
    switchDroneHeading(enable: boolean) {
        console.log("switch drone heading: ", enable);
        this.flags.useDroneHeading = enable;
        this.flags.useGpsHeading = false;
    }

    /**
     * set GPS heading from current reading
     * @param heading 
     */
    setGPSHeading(heading: number) {
        console.log("set GPS heading: ", heading);
        this.status.gpsHeading = heading;
    }

    clearCalibrationNotification() {
        this.status.compassNeedsCalibrationNotify = false;
    }

    /**
     * set filtered heading from compass and GPS
     * - use compass heading mostly when standing still
     * - use GPS heading mostly when walking, with compass correction inversely proportional with GPS speed
     */
    applyCompositeHeadingCorrection() {

        let compositeHeadingCrt: number = GeometryUtils.degreesToRadians(this.status.compositeHeading); // defaults to current composite heading
        let compassHeadingCrt: number = GeometryUtils.degreesToRadians(this.status.compassHeading);

        if ((this.status.gpsHeading != null) && (this.status.gpsHeading >= 0) && this.status.gpsReadHeading) {
            compositeHeadingCrt = GeometryUtils.degreesToRadians(this.status.gpsHeading); // init GPS heading
        }

        if (this.status.gpsSpeed == null) {
            this.status.gpsSpeed = 0;
        }

        if (this.status.gpsSpeed == 0) {
            if (this.isCompassAvailable()) {
                this.status.compositeHeading = this.arrayFilters.lowPassFilterAngle("compositeHeading", this.status.compassHeading, this.filterComp.compositeFilterN);
            }
        } else {
            if ((this.isCompassAvailable()) && (this.status.gpsSpeed <= this.filterComp.vmax)) {
                // apply compass heading correction / responsive heading
                let scale: number = this.filterComp.r1 + (this.status.gpsSpeed - this.filterComp.v1) * (this.filterComp.r2 - this.filterComp.r1) / (this.filterComp.v2 - this.filterComp.v1);
                // scale: 1..0.1
                if (scale > 1) {
                    scale = 1; // max comp with actual compass angle
                }
                if (scale < this.filterComp.r2) {
                    scale = this.filterComp.r2; // min comp regardless of GPS speed
                }
                let err: number = compassHeadingCrt - compositeHeadingCrt;
                let errFiltered: number = this.arrayFilters.lowPassFilterN("compassError", err, this.compassFilterN);
                if (errFiltered > (Math.PI / 2)) {
                    if (!this.status.compassNeedsCalibration) {
                        // notification fired once
                        this.status.compassNeedsCalibration = true;
                        this.status.compassNeedsCalibrationNotify = true;
                    }
                }
                compositeHeadingCrt += err * scale;
            }
            this.status.compositeHeading = this.arrayFilters.lowPassFilterAngle("compositeHeading", GeometryUtils.radiansToDegrees(compositeHeadingCrt), this.filterComp.compositeFilterN);
        }

        // // speed-based GPS heading correction
        // if ((this.status.gpsHeading != null) && (this.status.gpsHeading >= 0) && this.status.gpsReadHeading) {
        //     let scale: number = this.filterComp.r1 + (this.status.gpsSpeed - this.filterComp.v1) * (this.filterComp.r2 - this.filterComp.r1) / (this.filterComp.v2 - this.filterComp.v1);
        //     this.filterComp.gpsHeadingCorrectionRate = Math.floor(this.filterComp.gpsHeadingCorrectionRateDefault * scale);
        //     // the higher the GPS speed, the more frequent the GPS heading correction, up to 1:1
        //     if (this.filterComp.gpsHeadingCorrectionRate < 1) {
        //         this.filterComp.gpsHeadingCorrectionRate = 1;
        //     }
        //     if (this.filterComp.gpsHeadingCorrectionTrack >= this.filterComp.gpsHeadingCorrectionRate) {
        //         this.filterComp.gpsHeadingCorrectionTrack = 0;
        //         this.status.compositeHeading = this.arrayFilters.lowPassFilterAngle("compositeHeading", this.status.gpsHeading, this.filterComp.compositeFilterN);
        //     }
        //     this.filterComp.gpsHeadingCorrectionTrack += 1;
        // }

        // // compass-based orientation
        // let scaleInv: number = this.filterCompInv.r1 + (this.status.gpsSpeed - this.filterCompInv.v1) * (this.filterCompInv.r2 - this.filterCompInv.r1) / (this.filterCompInv.v2 - this.filterCompInv.v1);
        // this.filterCompInv.gpsHeadingCorrectionRate = Math.floor(this.filterCompInv.gpsHeadingCorrectionRateDefault * scaleInv);
        // if (!this.status.gpsReadHeading) {
        //     this.filterCompInv.gpsHeadingCorrectionRate = 1; // only compass heading
        // }
        // // the higher the GPS speed, the less frequent the compass heading correction, up to 1:5
        // if (this.filterCompInv.gpsHeadingCorrectionRate < 1) {
        //     this.filterCompInv.gpsHeadingCorrectionRate = 1;
        // }
        // if (this.filterCompInv.gpsHeadingCorrectionTrack >= this.filterCompInv.gpsHeadingCorrectionRate) {
        //     this.filterCompInv.gpsHeadingCorrectionTrack = 0;
        //     this.status.compositeHeading = this.arrayFilters.lowPassFilterAngle("compositeHeading", this.status.compassHeading, this.filterCompInv.compositeFilterN);
        // }
        // this.filterCompInv.gpsHeadingCorrectionTrack += 1;
    }

    /**
     * update filtered heading based on GPS heading and speed
     * @param heading
     * @param speed 
     */
    updateHeadingViaGPS(heading: number, speed: number) {
        let test: boolean = false;
        // console.log("update heading via GPS: ", heading);
        if (speed == null) {
            speed = 0;
        }
        this.status.gpsSpeed = speed;
        this.status.gpsReadHeading = false;
        if (heading != null) {
            if (speed > 0.5 || test) {
                this.status.gpsHeading = heading;
                this.status.gpsReadHeading = true;
                // if (this.flags.useGpsHeading) {
                //     // speed > 1.8 km/h        
                //     this.status.filteredHeading = this.status.compassHeading;
                //     // this.showHudMessage(EMapHudCodes.compassHeading, "" + Math.floor(this.filteredHeading), null);
                //     this.observables.heading.next(this.status);
                // }
            }
            this.applyCompositeHeadingCorrection();
            this.status.filteredHeadingContinuous = this.status.compositeHeading;
            this.applyFilterHeadingDiscrete();
        }
    }

    /**
     * update filtered heading based on drone heading
     * not implemented
     * @param heading 
     */
    updateHeadingViaDrone(heading: number) {
        if (heading != null) {
            this.status.droneHeading = heading;
        }
        if (this.status.droneHeading) {
            if (this.flags.useDroneHeading) {
                this.status.filteredHeadingContinuous = this.status.droneHeading;
                this.status.filteredHeading = this.status.filteredHeadingContinuous;
            }
        } else {
            // keep previous heading
        }
    }

    /**
     * compare heading
     * return 0 for same heading, 1 for opposite heading
     * @param targetHeading 
     */
    compareHeading(targetHeading: number) {
        // targetHeading -= 180;
        targetHeading = 90 - targetHeading;
        if (this.status.filteredHeadingContinuous != null) {
            let diff: number = targetHeading - this.status.filteredHeadingContinuous;
            if (diff > 180) {
                diff -= 360;
            }
            if (diff < -180) {
                diff += 360;
            }
            diff = GeometryUtils.translateTo180(diff);
            // console.log("compare heading: ", this.status.filteredHeading, " target: ", targetHeading, " diff: ", diff);
            return Math.abs(diff) / 180;
        } else {
            return 1;
        }
    }

    isCompassAvailable() {
        return !this.status.compassNotAvailable;
    }

    isGyroscopeAvailable() {
        return !this.status.gyroscopeNotAvailable;
    }

    /**
     * check/test if compass is available
     */
    checkCompassOrientationAvailable(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            console.log("check compass available");
            if (!this.platform.WEB) {
                // let granted: boolean = await this.permissionsService.requestOrientationSensorsPermissions();
                // if (!granted) {
                //     console.warn("compass not available");
                //     this.setCompassNotAvailableFlags();
                // }
                // resolve(granted);
                this.deviceOrientation.getCurrentHeading().then((_data: DeviceOrientationCompassHeading) => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    this.setCompassNotAvailableFlags();
                    resolve(false);
                });
            } else {
                console.log("using browser compass");
                if (GeneralCache.isIOSWebClient) {
                    let granted: boolean = await this.permissionsService.requestOrientationSensorsPermissions();
                    if (!granted) {
                        console.warn("compass not allowed");
                        this.setCompassNotAvailableFlags();
                        resolve(false);
                    } else {
                        console.log("compass allowed");
                    }
                    await SleepUtils.sleep(500);

                    // check working
                    console.log("check compass available once");

                    let handler = () => {
                        window.removeEventListener("deviceorientation", handler, true);
                        this.timeout.noCompassHeadingOnce = ResourceManager.clearTimeout(this.timeout.noCompassHeadingOnce);
                        resolve(true);
                    };

                    window.addEventListener("deviceorientation", handler, true);

                    // timeout fallback for unresponsive compass (happens on iOS / Chrome since some unknown time 20-28 sept 23)
                    if (this.timeout.noCompassHeadingOnce == null) {
                        this.timeout.noCompassHeadingOnce = setTimeout(() => {
                            window.removeEventListener("deviceorientation", handler, true);
                            this.timeout.noCompassHeadingOnce = null;
                            resolve(false);
                        }, 5000);
                    }
                } else {
                    resolve(true);
                }
            }
        });
        return promise;
    }

    setCompassNotAvailableFlags() {
        this.status.compassNotAvailable = true;
        this.flags.useCompositeHeading = false;
    }

    /**
     * check/test if gyroscope is available
     */
    checkGyroscopeAvailable(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let options: GyroscopeOptions = {
                frequency: 1000
            };
            if (this.platform.WEB) {
                resolve(true);
                return;
            }
            this.gyroscope.getCurrent(options).then((_orientation: GyroscopeOrientation) => {
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                this.status.gyroscopeNotAvailable = true;
                resolve(false);
            });
        });
        return promise;
    }

    watchGyroscopeOrientation(ts: number = 250) {
        // console.log("watchHeading (1)");
        console.log("watch orientation @" + ts + " ms");
        if (!this.platform.WEB && !this.status.compassNotAvailable) {
            // console.log("watchHeading (2)");
            // this.internalFlags.mapView3D = true;
            // Watch the device compass heading change
            if (this.watches.orientation === null) {
                let deviceOrientationOptions: GyroscopeOptions = {
                    frequency: ts
                };
                // this.checkGyroOrientationAvailable().then(async (available: boolean) => {
                //     if (available) {
                //         this.watches.orientation = await Motion.addListener('orientation', (event: RotationRate) => {
                //             // console.log('Device motion event:', event);
                //             this.status.alpha = event.alpha;
                //             this.status.beta = event.beta;
                //             this.status.gamma = event.gamma;
                //         });
                //     } else {
                //         console.warn("orientation event not avialable");
                //         this.status.gyroscopeNotAvailable = true;
                //         this.stopWatchOrientation();
                //     }
                // }).catch((err: Error) => {
                //     console.error(err);
                //     this.status.gyroscopeNotAvailable = true;
                //     this.stopWatchOrientation();
                // });

                this.checkGyroscopeAvailable().then(() => {
                    this.watches.orientation = this.gyroscope.watch(deviceOrientationOptions);
                    this.subscription.orientation = this.gyroscope.watch(deviceOrientationOptions).subscribe((_data: GyroscopeOrientation) => {
                        // this.status.alpha = data.x;
                        // this.status.beta = data.y;
                        // this.status.gamma = data.z;
                        this.observables.orientation.next(this.status);
                    }, (err: Error) => {
                        console.error(err);
                        this.stopWatchOrientation();
                    });
                }).catch((err: Error) => {
                    console.error(err);
                    this.status.gyroscopeNotAvailable = true;
                    this.stopWatchOrientation();
                });
            } else {
                console.warn("device orientation watch already initialized");
            }
        } else {
            console.warn("device orientation not available but simulated updates");
            if (this.watches.orientation === null) {
                this.watches.orientation = timer(0, ts);
                this.subscription.orientation = this.watches.orientation.subscribe(() => {
                    this.observables.heading.next(this.status);
                }, (err: Error) => {
                    console.error(err);
                });
            }
        }
    }

    onCompassAdjustJoystickAction(event: IJoystickStatusUpdate) {
        // console.log("joystick event: ", event);
        if (!event.status) {
            if (this.dsData != null) {
                this.dsData.driving = 0;
                this.dsData.steering = 0;
            }
        } else {
            this.dsData = JoystickUtils.extractDrivingSteering(event.outputData);
        }
    }

    openCompSelector() {
        let params: IAmountNavParams = {
            title: "Heading Compensation",
            description: null,
            min: -180,
            max: 180,
            current: Math.floor(this.manualCompDelta)
        };

        let navParams: INavParams = {
            view: {
                fullScreen: false,
                transparent: false,
                large: true,
                addToStack: false,
                frame: false
            },
            params: params
        };
        this.uiext.showCustomModal(null, AmountInputViewComponent, navParams).then((val: number) => {
            if (val != null) {
                this.setManualCompensation(val);
            }
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    applyCurrentJoystickAction(ts: number) {
        if (!this.dsData) {
            return;
        }
        let driving: number = this.dsData.driving;
        let steering: number = this.dsData.steering;
        if (driving > 1) {
            this.setManualCompensation(0);
        } else {
            let adj: number = steering / 300 * (ts / 250);
            this.setManualCompensationAdj(adj);
        }
    }

    resetJoystickControl() {
        this.dsData = null;
    }

    setManualCompensationAdj(deltaInc: number) {
        this.setManualCompensation(this.manualCompDelta + deltaInc);
    }

    setManualCompensation(delta: number) {
        if (delta > 180) {
            delta = 180;
        }
        if (delta < -180) {
            delta = -180;
        }
        this.manualCompDelta = delta;
        this.manualCompDeltaChanged = true;
        console.log("set manual hdg comp: ", this.manualCompDelta);
    }

    checkResetManualCompensationChanged() {
        let changed: boolean = this.manualCompDeltaChanged;
        this.manualCompDeltaChanged = false;
        return changed;
    }

    getManualCompensation() {
        return this.manualCompDelta;
    }

    resetFilters() {
        this.arrayFilters.resetFilterAngle("compass");
        this.arrayFilters.resetFilterAngle("compositeHeading");
        this.filterComp = Object.assign(this.filterComp, this.filterCompInit);
        this.filterCompInv = Object.assign(this.filterCompInv, this.filterCompInvInit);
    }

    /**
     * watch device heading using compass
     * it can be used to rotate the map accordingly
     * @param enable
     */
    watchHeading(ts: number, useFilters: boolean) {
        // console.log("watchHeading (1)");
        console.log("watch heading @" + ts + " ms");
        if (this.watchRequested) {
            console.warn("watch heading already requested");
            return;
        }
        this.watchRequested = true;
        this.resetFilters();
        if (!this.platform.WEB) {
            if (!this.status.compassNotAvailable) {
                // console.log("watchHeading (2)");
                // this.internalFlags.mapView3D = true;
                // Watch the device compass heading change    

                if (this.watches.heading === null) {
                    let deviceOrientationOptions: DeviceOrientationCompassOptions = {
                        frequency: ts
                        // filter: 1
                    };
                    this.checkCompassOrientationAvailable().then(() => {
                        // console.log("get heading: ", data.trueHeading);
                        this.watches.heading = this.deviceOrientation.watchHeading(deviceOrientationOptions);
                        this.subscription.heading = this.watches.heading.subscribe((data: DeviceOrientationCompassHeading) => {
                            // console.log("watch heading: ", data.trueHeading);
                            this.applyCurrentJoystickAction(ts);
                            let filteredHeading: number = data.trueHeading;
                            if (useFilters) {
                                filteredHeading = this.arrayFilters.lowPassFilterAngle("compass", data.trueHeading, 5);
                            }
                            this.setCompassHeading(filteredHeading);
                            // console.log("heading update: ", filteredHeading);
                            this.observables.heading.next(this.status);
                        }, (err: Error) => {
                            console.error(err);
                            // fallback to gps control over the map
                            this.onCompassError();
                        });
                    }).catch((err: Error) => {
                        // reset follow mode if compass is not available
                        this.analytics.dispatchError(err, "heading");
                        this.onCompassError();
                    });
                }
            } else {
                console.warn("device heading not available");
                // fallback to gps control over the map
                this.flags.useGpsHeading = true;
            }
        } else {
            // WEB distribution
            console.log("watch compass web");
            if (!this.subscription.heading) {
                if (this.simulateCompassDev) {
                    // simulate/mock compass
                    console.warn("device heading not available but simulated");
                    // simulate zero heading  
                    ts = 200;
                    let timer1 = timer(0, ts);
                    let headingSim: number = 0;
                    let circleSim: boolean = true;
                    this.subscription.heading = timer1.subscribe(() => {
                        this.applyCurrentJoystickAction(ts);
                        this.setCompassHeading(headingSim);
                        if (circleSim) {
                            headingSim += 36;
                            if (headingSim >= 360) {
                                headingSim -= 360;
                            }
                        }
                        this.observables.heading.next(this.status);
                    }, (err: Error) => {
                        console.error(err);
                    });
                } else {
                    // use web compass (deviceorientation events)
                    this.checkCompassOrientationAvailable().then(() => {
                        console.log("check compass available ok");
                        if (this.watches.heading != null) {
                            // reset event listeners
                            console.log("reset compass event listeners");
                            this.setupHeadingEventListeners(false, null);
                            this.setupHeadingEventListeners(true, ts);
                        } else {
                            console.log("setup compass event listeners");
                            this.watches.heading = new BehaviorSubject<DeviceOrientationCompassHeading>(null);
                            this.setupHeadingEventListeners(true, ts);
                        }
                        this.startHeadingWatchdog();
                        this.subscription.heading = this.watches.heading.subscribe((data: number) => {
                            // console.log("heading update received");
                            this.resetHeadingWatchdog();
                            this.applyCurrentJoystickAction(ts);
                            let filteredHeading: number = data;
                            if (useFilters) {
                                filteredHeading = this.arrayFilters.lowPassFilterAngle("compass", data, 5);
                            }
                            this.setCompassHeading(filteredHeading);
                            // console.log("heading update: ", filteredHeading);
                            this.observables.heading.next(this.status);
                        }, (err: Error) => {
                            console.log("compass event subscription error");
                            console.error(err);
                            // fallback to gps control over the map
                            this.onCompassError();
                        });
                    }).catch((err: Error) => {
                        // reset follow mode if compass is not available
                        console.log("check compass available err");
                        this.analytics.dispatchError(err, "heading");
                        this.onCompassError();
                    });
                }
            } else {
                console.log("heading subscription already running");
            }
        }
    }

    simulateCompassHeading(filteredHeading: number) {
        this.setCompassHeading(filteredHeading);
        console.log("heading update: ", filteredHeading);
        this.observables.heading.next(this.status);
    }

    getCompassHeading() {
        return this.status.compassHeading;
    }

    watchForegroundWeb() {
        this.subscription.foregroundWeb = this.bgmWatch.getForegroundWebWatch().subscribe((state: boolean) => {
            if (state === false) {
                // background mode
                console.log("stop heading watchdog (background mode)")
                this.stopHeadingWatchdog();
            }
        }, (err: Error) => {
            console.error(err);
        });
    }


    /**
     * timeout fallback for unresponsive compass (happens on iOS / Chrome since some unknown time 20-28 sept 23)
     */
    startHeadingWatchdog() {
        if (this.timeout.noCompassHeading == null) {
            this.timeout.noCompassHeading = setTimeout(() => {
                console.warn("compass event timeout fallback");
                this.analytics.dispatchError(new Error("compass timeout fallback"), "heading");
                this.timeout.noCompassHeading = null;
                this.onCompassError();
            }, 5000);
        }
    }

    stopHeadingWatchdog() {
        if (this.timeout.noCompassHeading != null) {
            this.timeout.noCompassHeading = ResourceManager.clearTimeout(this.timeout.noCompassHeading);
        }
    }

    resetHeadingWatchdog() {
        this.stopHeadingWatchdog();
        this.startHeadingWatchdog();
    }

    onCompassError() {
        if (!this.popupShown) {
            this.timeout.popupDelay = setTimeout(() => {
                if (this.enableErrorPopup) {
                    this.uiext.showAlertNoAction(Messages.msg.compassError.after.msg, Messages.msg.compassError.after.sub);
                }
                this.observables.warning.next(true);
                this.observables.warning.next(null);
            }, 10000);
            this.popupShown = true;
        }
        this.setCompassNotAvailableFlags();
        this.stopWatchHeading();
        this.flags.useGpsHeading = true;
        // this.setFollow(0);
    }

    /**
     * setup heading event listeners for web distribution
     * @param enable 
     */
    setupHeadingEventListeners(enable: boolean, ts: number) {
        let handler = (e: DeviceOrientationEvent) => {
            this.deviceorientationHandlerOnEvent(e, ts);
        }
        console.log("setup heading event listeners " + enable + " ios: " + GeneralCache.isIOSWebClient);
        if (enable) {
            if (GeneralCache.isIOSWebClient) {
                window.addEventListener("deviceorientation", handler, true);
                // window.addEventListener("deviceorientationabsolute", handler, true);
            } else {
                window.addEventListener("deviceorientationabsolute", handler, true);
            }
        } else {
            if (GeneralCache.isIOSWebClient) {
                window.removeEventListener("deviceorientation", handler, true);
                // window.removeEventListener("deviceorientationabsolute", handler, true);
            } else {
                window.removeEventListener("deviceorientationabsolute", handler, true);
            }
        }
    }

    /**
     * handle deviceorientation event callbacks for web distribution
     * @param e 
     * @returns 
     */
    deviceorientationHandlerOnEvent(e: DeviceOrientationEvent, ts: number) {
        if (this.watches.heading == null) {
            console.log("heading watch not initialized");
            return;
        }
        console.log("heading update event");
        let heading: number = this.deviceorientationHandler(e);
        if (heading != null) {
            let t1: number = new Date().getTime();
            if ((this.status.compassTimestamp == null) || ((t1 - this.status.compassTimestamp) >= ts)) {
                // rate limiter
                console.log("heading update: ", heading);
                this.status.compassTimestamp = t1;
                this.watches.heading.next(heading);
            }
        }
    }

    /**
     * handle deviceorientation events for web distribution
     * @param e 
     * @returns 
     */
    deviceorientationHandler(e: DeviceOrientationEvent) {
        let heading: number = 0;
        let webkitCompass = (e as any).webkitCompassHeading;
        // console.log("deviceorientation handler event, webkit: " + webkitCompass != null + ", absolute: " + e.absolute);
        if (webkitCompass != null) {
            // webkit
            let compass = (e as any).webkitCompassHeading;
            if (compass != null && !isNaN(compass)) {
                heading = compass;
            }
        } else {
            // deviceorientation
            if (!e.absolute || e.alpha == null || e.beta == null || e.gamma == null) {
                return null;
            }
            // heading = Math.abs(e.alpha - 360);
            heading = -(e.alpha + e.beta * e.gamma / 90);
            heading -= Math.floor(heading / 360) * 360; // Wrap to range [0,360]
        }
        return heading;
    }


    /**
     * set compass heading w/ comp adjust
     * check flags
     * @param trueHeading 
     */
    setCompassHeading(trueHeading: number) {
        if (this.manualCompDelta === 0) {
            this.status.compassHeading = trueHeading;
        } else {
            this.status.compassHeading = GeometryUtils.translateTo360(trueHeading + Math.floor(this.manualCompDelta));
        }
        if (!this.flags.useGpsHeading) {
            this.status.filteredHeadingContinuous = this.status.compassHeading;
            this.applyFilterHeadingDiscrete();
        } else {
            this.applyCompositeHeadingCorrection();
            this.status.filteredHeadingContinuous = this.status.compositeHeading;
            this.applyFilterHeadingDiscrete();
        }
    }

    applyFilterHeadingDiscrete() {
        if (GeometryUtils.getAngularDiff(this.status.filteredHeadingContinuous, this.status.filteredHeading) >= 3) {
            this.status.filteredHeading = this.status.filteredHeadingContinuous;
        }
    }

    getHeadingObservable(): BehaviorSubject<IHeadingStatus> {
        return this.observables.heading;
    }

    getOrientationObservable(): BehaviorSubject<IHeadingStatus> {
        return this.observables.orientation;
    }

    getWarningObservable(): BehaviorSubject<boolean> {
        return this.observables.warning;
    }

    stopWatchHeading() {
        console.log("stop watch heading");
        this.subscription.heading = ResourceManager.clearSub(this.subscription.heading);
        this.timeout.noCompassHeading = ResourceManager.clearTimeout(this.timeout.noCompassHeading);
        this.watches.heading = null;
        this.status.filteredHeading = null;
        this.status.filteredHeadingContinuous = null;
        this.status.compositeHeading = null;
        this.status.gpsHeading = null;
        this.status.compassHeading = null;
        this.observables.heading.next(null);
        this.watchRequested = false;
        this.resetFilters();
        if (this.platform.WEB) {
            this.setupHeadingEventListeners(false, null);
        }
    }

    stopWatchOrientation() {
        if (!this.platform.WEB) {
            this.subscription.orientation = ResourceManager.clearSub(this.subscription.heading);
            this.watches.orientation = null;
            this.status.alpha = null;
            this.status.beta = null;
            this.status.gamma = null;
            this.observables.heading.next(null);
        } else {
            console.warn("device orientation not available");
        }
    }

    cleanup() {
        this.stopWatchHeading();
        this.stopWatchOrientation();
        this.status = Object.assign(this.status, this.statusInit);
    }

}