import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IFeatureDef } from 'src/app/classes/def/app/app';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';

@Component({
  selector: 'feature-info',
  templateUrl: './feature-info.component.html',
  styleUrls: ['./feature-info.component.scss'],
})
export class FeatureInfoComponent implements OnInit, OnDestroy {

  @Input()
  data: IFeatureDef;

  priceInfo: string;

  appIconsStandard = EAppIconsStandard;

  constructor() {

  }

  ngOnInit() {
    if (this.data) {
      if (!this.data.available) {
        if ((this.data.price != null) && (this.data.price > 0)) {
          this.priceInfo = this.data.price + " LP";
        }
      }
    }
  }

  ngOnDestroy() {

  }
}
