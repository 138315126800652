<div *ngIf="data" class="flex-card story-card bg-container bg-container-fill bg-hidden item-border-radius"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data.photoUrl}">

  <div class="content">
     <ion-grid class="full-w grid-center-absolute">
      <ion-row class="padding-xs margin-s">
        <ion-col size="12">
          <span class="info-text-s bold text-color-element text-color-overlay-s">{{data.name}}</span>
        </ion-col>
      </ion-row>

      <ion-row class="margin-s">
        <ion-col class="text-left" size="6">
          <span class="info-text-s bold text-color-element text-color-overlay-s">{{data.startDate | myDate2}}</span>
        </ion-col>

        <ion-col class="text-right" size="6">
          <span class="info-text-s bold text-color-element text-color-overlay-s">{{data.startDate | myTime}}</span>
        </ion-col>
      </ion-row>

      <ion-row class="padding-xs margin-s">
        <ion-col size="12">
          <div *ngIf="data.regionType">
            <span
              class="info-text-s margin-top-s bold text-color-element text-color-overlay-s">{{data.regionType}}</span>
          </div>
        </ion-col>
      </ion-row>

    </ion-grid>
  </div>
</div>