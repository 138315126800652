import { Component, OnInit, OnChanges, OnDestroy, Input, Output, SimpleChanges, SimpleChange } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { INewsfeedEntry } from 'src/app/classes/def/newsfeed/newsfeed';
import { EventEmitter } from '@angular/core';
import { FeatureShortcutService } from 'src/app/services/app/utils/feature-shortcut';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { StringUtils } from 'src/app/services/app/utils/string-utils';


export interface IOnSelectEntry {
  mode: number;
  data: any;
}

export enum EOnSelectEntry {
  expand = 0,
  launch = 1
}

@Component({

  selector: 'newsfeed-entry',
  templateUrl: './newsfeed-entry.component.html',
  styleUrls: ['./newsfeed-entry.component.scss'],

  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0,
        transform: 'translateY(-100%) scale(1, 0)'
      })),
      // state('active', style({
      //     // transform: 'translateY(0)',
      //     opacity: 1,
      //     transform: 'translateY(0) scale(1, 1)'
      // })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ]
})
export class NewsfeedEntryComponent implements OnInit, OnChanges, OnDestroy {

  showState: string = "inactive";

  @Input()
  entry: INewsfeedEntry;

  @Input()
  opened: boolean;

  @Output()
  select: EventEmitter<IOnSelectEntry> = new EventEmitter();

  hasFeature: boolean = false;

  showOpen: boolean = false;
  bgPhoto: string = "";
  trimDescription: string = "";

  appIconsStandard = EAppIconsStandard;

  timeout;

  constructor(
    public featureShortcut: FeatureShortcutService
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      // console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'opened':
            this.setOpen(this.entry, chg.currentValue);
            break;
        }
      }
    }
  }

  ngOnInit() {
    // console.log(this.data);
    if (this.entry) {
      if (this.opened) {
        this.setOpen(this.entry, true);
      }
      if (this.entry.featureCode != null) {
        this.hasFeature = true;
      }
      if (this.entry.photoUrl) {
        this.bgPhoto = this.entry.photoUrl;
      }

      this.trimDescription = StringUtils.trimHtmlPar(this.entry.description, EMessageTrim.newsPreview, 1);
    }
  }

  ngOnDestroy() {
    this.timeout = ResourceManager.clearTimeout(this.timeout);
  }

  setOpen(entry: INewsfeedEntry, open: boolean) {
    entry.open = open;
    this.showState = open ? "active" : "inactive";
    this.timeout = ResourceManager.clearTimeout(this.timeout);
    this.showOpen = open;
    // if (open) {
    //     this.showOpen = true;
    // } else {
    //     this.timeout = setTimeout(() => {
    //         this.showOpen = false;
    //     }, AppConstants.animationTime);
    // }
  }

  openFeature() {
    let code: number = this.entry.featureCode;
    console.log("open feature code: ", code);
    let e: IOnSelectEntry = {
      mode: EOnSelectEntry.launch,
      data: this.entry
    };
    this.select.emit(e);
  }

  onSelectEntry() {
    let e: IOnSelectEntry = {
      mode: EOnSelectEntry.expand,
      data: this.entry
    };
    this.select.emit(e);
  }
}
