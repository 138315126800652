import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IArenaNavParams } from 'src/app/classes/def/nav-params/activity';
import { IGroup, EGroupRole, EGroupModalResult, EGroupMode } from 'src/app/classes/def/mp/groups';
import { Platform, ModalController } from '@ionic/angular';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { EServiceUrlCodes } from 'src/app/classes/def/app/app';
import { IGenericResponse } from 'src/app/classes/def/requests/general';
import { IArenaReturnData } from 'src/app/classes/def/nav-params/arena';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AdsService } from 'src/app/services/general/apis/ads';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { MPManagerService } from 'src/app/services/app/mp/mp-manager';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { ERouteDef } from 'src/app/app-utils';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { ResourceMonitorDataService } from 'src/app/services/data/resource-monitor';
import { Router } from '@angular/router';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-mp-groups-chat',
  templateUrl: './mp-groups-chat.page.html',
  styleUrls: ['./mp-groups-chat.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MPGroupsChatPage implements OnInit, OnDestroy {
  theme: string = "theme-light theme-light-bg";
  title: string = "Teams";
  infoHTML: string = "";
  shareMessage: string = "";
  photoUrlLoaded: string = null;
  params: IArenaNavParams = null;
  loaded: boolean = true;
  groups: IGroup[] = [];
  selectedGroup: IGroup = null;
  description: string = `
  <p>View and manage your groups</p>`;
  hasGroups: boolean = false;
  isRoot: boolean = false;

  np: INavParams = null;
  vs: IViewSpecs;

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public adService: AdsService,
    public mp: MPDataService,
    public analytics: AnalyticsService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public backButton: BackButtonService,
    public router: Router,
    public mpManager: MPManagerService,
    public resources: ResourcesCoreDataService,
    public resourceMonitor: ResourceMonitorDataService,
    public popupFeatures: PopupFeaturesService,
    public nps: NavParamsService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  ngOnInit() {

    this.analytics.trackView("mp-groups-chat");

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.mpChat, null, this.np);

      console.log("nav params: ", npInfo.params);

      this.description += `
            <p>You can create groups @Meeting Places<p>
            <p>You can find them in World Map</p>`;

      if (npInfo.hasParams) {
        let np: INavParams = npInfo.params;
        this.params = np.params;
        this.vs = np.view;
        this.isRoot = false;
        // this.shareMessage = GetDefaults.getItemFoundShareMessage(this.collectedCoins, 0, this.place ? this.place.place.name : null);
        // this.infoHTML = GetDefaults.getItemFoundMessageHTML(this.collectedCoins, 0);
      } else {
        this.isRoot = true;
      }

      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back();
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });

      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      this.resourceMonitor.checkWatchLoader().then(() => {
        this.popupFeatures.checkServiceAvailable(EServiceUrlCodes.mp).then((available: boolean) => {
          if (available) {
            this.mpManager.changeServerUrl(GeneralCache.resourceCache.general.appServices.content.object.mp.url);
          } else {
            this.dismiss(null);
          }
        });
      });

      this.loadGroups();
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  swipeEvent(e) {
    this.backButton.handleSwipeEvent(e);
  }

  dismiss(params: any) {
    setTimeout(() => {
      if (this.vs && this.vs.isModal) {
        this.modalCtrl.dismiss(params).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
      } else {
        this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
      }
    }, 1);
  }

  loadGroups() {
    this.loaded = false;
    this.mp.viewAllGroups(0, EGroupMode.private, null, null).then((response: IGenericResponse) => {
      this.groups = response.data;
      if (response.data) {
        this.hasGroups = true;
      } else {
        this.hasGroups = false;
      }
      this.loaded = true;
    }).catch((err: Error) => {
      console.error(err);
      this.loaded = true;
    });
  }

  back() {
    this.dismiss(null);
  }

  // open create/join group with filled in group details from existing group
  async goToGroup(group: IGroup) {
    console.log("connecting to group: ", group);
    await this.uiext.showLoadingV2Queue("Connecting..");
    await this.mpManager.loadAuthDataResolve();
    await this.mpManager.initSequence(group, group.role, false);
    this.mpManager.setGroupScope(group);
    await this.uiext.dismissLoadingV2();
    this.mpManager.openChatWindow(true, true).then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ok() {
    let returnData: IArenaReturnData = {
      status: null,
      group: this.selectedGroup,
      role: EGroupRole.leader
    };
    returnData.status = EGroupModalResult.ok;
    this.dismiss(returnData);
  }

  cancel() {
    let returnData: IArenaReturnData = {
      status: null,
      group: null,
      role: EGroupRole.leader
    };
    returnData.status = EGroupModalResult.cancel;
    this.dismiss(returnData);
  }

  /**
   * Share progress then dismiss
   */
  share() {
    this.loaded = false;
    this.shareProvider.share(this.shareMessage).then(() => {
      this.loaded = true;

    }).catch(() => {
      this.loaded = true;
    });
  }



  ngOnDestroy() {
    console.log("close modal");
    this.backButton.checkPop(this.vs);
  }


  options() {
    let actions: IPopoverActions = {};
    actions = {
      // search: {
      //     name: "Search",
      //     code: 1,
      //     enabled: true
      // },
      reload: {
        name: "Reload",
        code: 0,
        icon: EAppIconsStandard.refresh,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 0:
          this.loadGroups();
          break;
        case 1:

          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

}
