
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HTTPErrorHandlerService } from '../data/error-handler';
import { IUserAuthDetails } from '../../../classes/def/user/general';
import { ELocalAppDataKeys } from '../../../classes/def/app/storage-flags';
import { StorageOpsService } from '../data/storage-ops';
import { GeneralCache } from '../../../classes/app/general-cache';


@Injectable({
    providedIn: 'root'
})
export class AuthCoreService {

    authenticated: boolean = false;
    authObservable: BehaviorSubject<boolean>;

    serverUrl: string;
    userInfo: IUserAuthDetails = null;

    constructor(
        public errorHandler: HTTPErrorHandlerService,
        public storageOps: StorageOpsService
    ) {
        console.log("auth core service created");
        this.authObservable = new BehaviorSubject(null);
    }

    /**
     * set user login status in local storage
     * @param loggedin 
     */
    setLoggedInFlag(loggedin: boolean) {
        this.authenticated = loggedin;
        this.authObservable.next(this.authenticated);
    }

    clearLoginDetailsCache() {
        this.userInfo = null;
    }

    /**
     * check in local storage if user is already logged in
     */
    checkLoggedInStorage() {
        let promise = new Promise((resolve, reject) => {
            this.storageOps.getLocalDataKey(ELocalAppDataKeys.userId).then((data) => {
                console.log("get logged in storage: ", data);
                if (data !== null) {
                    resolve(true);
                } else {
                    reject(new Error("You are not logged in"));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
    * get user token from local storage
    * and saves it into variable for later calls
    */
    getUserToken() {
        let promise = new Promise((resolve, reject) => {
            this.storageOps.getLocalDataKey(ELocalAppDataKeys.token).then((value) => {
                if (value !== null) {
                    resolve(value);
                } else {
                    reject(new Error(""));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * return current login status
     */
    isLoggedIn() {
        return this.authenticated;
    }

    /**
     * observe login status
     */
    checkAuthentication() {
        return this.authObservable;
    }

    setAuthentication(val: boolean) {
        this.authObservable.next(val);
    }


    saveLoginDataResolve(token: string, userId: number, username: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            GeneralCache.resourceCache.user.general.content.name = username;
            GeneralCache.userId = userId;
            this.storageOps.saveLocalDataResolve([
                {
                    flag: ELocalAppDataKeys.userId,
                    value: userId
                },
                {
                    flag: ELocalAppDataKeys.userName,
                    value: username
                },
                {
                    flag: ELocalAppDataKeys.token,
                    value: token
                }
            ]).then(() => {
                this.setLoggedInFlag(true);
                resolve(true);
            });
        });
        return promise;
    }

    removeLoginData() {
        GeneralCache.userId = 0;
        this.userInfo = null;
        this.setLoggedInFlag(false);
        this.storageOps.clearStorage().then(() => {
            console.log("storage cleared");
        }).catch((err: Error) => {
            console.error(err);
        });
       
    }

    /**
     * get username from local storage
     */
    getUsername() {
        let promise = new Promise((resolve, reject) => {
            this.storageOps.getLocalDataKey(ELocalAppDataKeys.userName).then((value) => {
                if (value) {
                    GeneralCache.resourceCache.user.general.content.name = value;
                }
                resolve(value);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
    * get user id and token from local storage
    * and saves it into variable for later calls
    */
    getUserIdAndToken() {
        let promise = new Promise((resolve, reject) => {
            if (this.userInfo) {
                resolve(this.userInfo);
                return;
            }
            this.storageOps.getLocalDataKey(ELocalAppDataKeys.token).then((token: string) => {
                if (token !== null) {
                    this.storageOps.getLocalDataKey(ELocalAppDataKeys.userId).then((id: number) => {
                        this.userInfo = {
                            id: id,
                            token: token
                        };

                        GeneralCache.userId = id;

                        resolve(this.userInfo);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    reject(new Error("Local user data not found"));
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

}
