import { Component, OnDestroy, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { ILeaderboardNavParams } from 'src/app/classes/def/nav-params/leaderboard';
import { INewsfeedEntry, INewsfeedTopic } from 'src/app/classes/def/newsfeed/newsfeed';
import { IPaginationContainer } from 'src/app/classes/def/views/pagination';
import { INavBarItem } from 'src/app/classes/def/views/nav';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { Platform, ModalController } from '@ionic/angular';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { IGenericResponse, IPageResponse } from 'src/app/classes/def/requests/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { EFeatureCode } from 'src/app/classes/def/app/app';
import { IGmapEntryNavParams, EGmapMode } from 'src/app/classes/def/nav-params/gmap';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { IPopoverActions, ICheckboxFrameStatus } from 'src/app/classes/def/app/modal-interaction';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { PaginationHandlerService } from 'src/app/services/general/ui/pagination-handler';
import { FeatureShortcutService } from 'src/app/services/app/utils/feature-shortcut';
import { NewsfeedDataService } from 'src/app/services/data/newsfeed';
import { ERouteDef } from 'src/app/app-utils';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { IOnSelectEntry, EOnSelectEntry } from 'src/app/components/app/components/newsfeed-entry/newsfeed-entry.component';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { Router } from '@angular/router';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-newsfeed',
  templateUrl: './newsfeed.page.html',
  styleUrls: ['./newsfeed.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class NewsfeedPage implements OnInit, OnDestroy {
  public title: string;
  loaded: boolean = false;
  enableDemo: boolean = false;
  theme: string = "theme-light theme-light-bg";
  params: ILeaderboardNavParams;
  isModal: boolean = false;

  news: INewsfeedEntry[] = [];

  pagination: IPaginationContainer = {
    page: 0,
    pages: 1,
    pageDisp: 1,
    hideLeft: true,
    hideRight: false
  };

  topics: INewsfeedTopic[] = [];

  expanded: boolean = false;

  selectedCategoryCode: number = 0;
  initLoaded: boolean = false;
  navBarItems: INavBarItem[] = [];

  platform: IPlatformFlags = {} as IPlatformFlags;

  np: INavParams = null;
  vs: IViewSpecs;
  enableBackButton: boolean = true;

  constructor(
    public router: Router,
    public settingsProvider: SettingsManagerService,
    public zone: NgZone,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public backButton: BackButtonService,
    public analytics: AnalyticsService,
    public modalCtrl: ModalController,
    public newsfeed: NewsfeedDataService,
    public featureShortcut: FeatureShortcutService,
    public paginationHandler: PaginationHandlerService,
    public nps: NavParamsService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  /**
   * load available timeline categories
   */
  loadFilters() {
    let promise = new Promise((resolve, reject) => {
      this.newsfeed.getNewsfeedTopicHeaders().then((response: IGenericResponse) => {
        this.topics = response.data;
        if (this.topics) {
          for (let i = 0; i < this.topics.length; i++) {
            this.topics[i].show = true;
          }
        }

        this.navBarItems = this.topics.map(topic => {
          let item: INavBarItem = {
            name: topic.name,
            value: topic.code
          };
          return item;
        });

        this.initLoaded = true;
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "timeline");
        reject(err);
      });
    });
    return promise;
  }


  /**
   * switch tab
   * @param navItem 
   */
  selectCategoryFromNavItem(navItem: INavBarItem) {
    this.pagination.page = 0;
    this.pagination.pageDisp = 1;
    this.selectedCategoryCode = navItem.value;
    // console.log(navItem);
    // console.log(this.selectedCategoryCode);
    if (navItem) {
      this.loadDataByPage(this.pagination.page);
    }
  }

  onSelectItem(event: IOnSelectEntry) {
    console.log(event);

    if (event.mode === EOnSelectEntry.launch) {
      let entry: INewsfeedEntry = event.data;
      if (!entry) {
        return;
      }

      let promiseAsk: Promise<boolean>;
      if (entry.featurePrice && entry.featurePrice.major) {
        promiseAsk = new Promise((resolve) => {
          let featureName: string = entry.featurePrice ? entry.featurePrice.name : entry.name;
          this.uiext.showAlert(Messages.msg.goToFeature.before.msg, "Go to " + featureName, 2, ["dismiss", "ok"], true).then((res: number) => {
            switch (res) {
              case EAlertButtonCodes.ok:
                resolve(true);
                break;
              default:
                resolve(false);
                break;
            }
          }).catch(() => {
            resolve(true);
          });
        });
      } else {
        promiseAsk = Promise.resolve(true);
      }

      promiseAsk.then((open: boolean) => {
        if (open) {
          this.openFeatureNav(entry.featureCode);
        }
      });
    }
  }

  openFeatureNav(code: number) {
    switch (code) {
      case EFeatureCode.getReferralCode:
        this.featureShortcut.openFeature(code);
        break;
      case EFeatureCode.worldMap:
        let params: IGmapEntryNavParams = {
          mode: EGmapMode.worldMap
        };

        let navParams: INavParams = {
          view: null,
          params: params
        };

        this.nps.set(ENavParamsResources.gmap, navParams);

        this.router.navigate([ERouteDef.gmap], { replaceUrl: true }).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
        break;
      case EFeatureCode.storyline:
        this.router.navigate([ERouteDef.storyList], { replaceUrl: true }).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
        break;
      case EFeatureCode.inventory:
        this.router.navigate([ERouteDef.inventory], { replaceUrl: true }).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
        break;
      case EFeatureCode.myAccount:
        this.router.navigate([ERouteDef.account], { replaceUrl: true }).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
        break;
      case EFeatureCode.leaderboard:
        this.router.navigate([ERouteDef.leaderboard], { replaceUrl: true }).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
        break;
      case EFeatureCode.support:
        this.router.navigate([ERouteDef.supportHome], { replaceUrl: true }).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
        break;
      case EFeatureCode.newsfeed:

        break;
    }
  }



  /**
   * select/expand all entries
   */
  expandAll(expand: boolean = null) {
    if (!this.news) {
      return;
    }

    if (expand != null) {
      this.expanded = expand;
    } else {
      this.expanded = !this.expanded;
    }

    if (this.expanded) {
      for (let i = 0; i < this.news.length; i++) {
        this.news[i].open = true;
      }
    } else {
      for (let i = 0; i < this.news.length; i++) {
        this.news[i].open = false;
      }
    }
  }

  swipeEvent(e) {
    // if (!this.backButton.handleSwipeEvent(e)) {
    if (!this.platform.IOS) {
      if (e) {
        switch (e.direction) {
          case 2:
            // right to left
            if (!this.pagination.hideRight) {
              this.switchPage(1);
            }
            break;
          case 4:
            // left to right
            if (!this.pagination.hideLeft) {
              this.switchPage(-1);
            }
            break;
        }
      }
    }
  }


  switchPage(dir: number) {
    this.paginationHandler.setPaginationPage(dir);
    this.loadDataByPage(this.pagination.page);
  }


  ngOnDestroy() {
    GeneralCache.newsfeedSelectedCategory = this.selectedCategoryCode;
    console.log("destroy");
    this.backButton.checkPop(this.vs);
  }

  back() {
    if (this.isModal) {
      this.modalDismiss();
    } else {
      this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  modalDismiss() {
    setTimeout(() => {
      this.modalCtrl.dismiss().then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }


  ngOnInit(): void {
    this.title = "Newsfeed";

    this.analytics.trackView("newsfeed");

    this.paginationHandler.init(this.pagination);
    this.selectedCategoryCode = GeneralCache.newsfeedSelectedCategory;

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.newsfeed, null, this.np);

      console.log("nav params: ", npInfo.params);

      let npar = npInfo.params;

      let hasParams: boolean = npInfo.hasParams;

      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
        if (loaded) {
          this.platform = SettingsManagerService.settings.platformFlags;
        }
      }, (err: Error) => {
        console.error(err);
      });

      if (hasParams) {
        let np: INavParams = npar;
        this.params = np.params;
        this.vs = np.view;
        console.log(this.params);
        this.isModal = this.vs ? this.vs.isModal : false;
      }

      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back();
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });

      this.loadFilters().then(() => {
        this.loadDataByPage(this.pagination.page);
      }).catch(() => {
        this.loadDataByPage(this.pagination.page);
      });
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  loadDataByPage(page: number) {
    this.loaded = false;
    let topic = this.selectedCategoryCode;
    this.newsfeed.getNewsfeedTopic(topic, page).then((response: IGenericResponse) => {
      this.loadDataCore(response.data);
      this.loaded = true;
    }).catch((err: Error) => {
      this.loaded = true;
      this.analytics.dispatchError(err, "timeline");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }


  loadDataCore(resp: IPageResponse) {
    if (!resp) {
      return;
    }
    this.expanded = false;
    this.news = resp.data;
    this.formatEntries(this.news);
    this.pagination.pages = resp.pages;
    this.pagination.page = resp.page;
    this.paginationHandler.setPaginationControls();
    this.loaded = true;
  }

  formatEntries(news: INewsfeedEntry[]) {
    for (let i = 0; i < news.length; i++) {
      news[i].open = false;
      if (news[i].isGeneral == null) {
        news[i].isGeneral = true;
      }
      // if (news[i].newsfeedCategoryCode != null) {
      //     news[i].isGeneral = false;
      // } else {
      //     news[i].isGeneral = true;
      // }
    }
  }

  viewEntry(entry: INewsfeedEntry): void {
    let description: string = "";

    if (entry.timestampDetail) {
      description += "<p>" +
        entry.timestampDetail +
        "</p>";
    }

    description +=
      "<p>" +
      entry.name +
      "</p><p>" +
      entry.description +
      "</p>";

    this.uiextStandard.showStandardModal(null, EModalTypes.description, "News entry", {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { description: description }
    }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  options() {
    let actions: IPopoverActions = {};
    actions = {
      refresh: {
        name: "Reload",
        code: 0,
        icon: EAppIconsStandard.refresh,
        enabled: true
      },
      // filter: {
      //     name: "Filter",
      //     code: 1,
      //     enabled: true
      // }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 0:
          this.pagination.page = 0;
          this.loadDataByPage(this.pagination.page);
          break;
        case 1:
          this.applyFilters();
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  applyFilters() {
    if (!this.topics) {
      return;
    }
    let actions: IPopoverActions = {};
    for (let i = 0; i < this.topics.length; i++) {
      let topic: INewsfeedTopic = this.topics[i];
      actions[i.toString()] = {
        name: topic.name,
        code: topic.code,
        enabled: topic.show
      };
    }

    // this.topicFilter = false;

    this.uiextStandard.showStandardModal(null, EModalTypes.checkbox, "News filter", {
      view: {
        fullScreen: false,
        transparent: false,
        large: true,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((data: ICheckboxFrameStatus) => {
      if (data) {
        if (data.update) {
          // refresh object layers
          actions = data.status;
          let keys = Object.keys(actions);

          for (let i = 0; i < this.topics.length; i++) {
            this.topics[i].show = false;
            for (let j = 0; j < keys.length; j++) {
              if (actions[keys[j]].code === this.topics[i].code) {
                if (actions[keys[j]].enabled) {
                  // this.topicFilter = true;
                  this.topics[i].show = true;
                }
                break;
              }
            }
          }

          this.pagination.page = 0;
          this.loadDataByPage(this.pagination.page);
        }
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  openPaginateSelector() {
    this.paginationHandler.openPaginateSelector((page: number) => {
      return this.loadDataByPage(page);
    });
  }
}


