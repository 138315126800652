import { Injectable } from '@angular/core';
import { StoryDataHelpers } from './story-helpers';

import { IStory, IStoriesPageResponse, IStoryResponse, IMasterLockResponse, IStoriesPageRequest, EStoryUnlockType, IStoryUnlockCode, EStoryUnlockScope, IDBModelLanguage } from '../../classes/def/core/story';
import { IGenericResponse } from '../../classes/def/requests/general';
import { GenericDataService } from '../general/data/generic';
import { ILocationItemsDef, ILocationContainer, IUserStoryLocation } from '../../classes/def/places/backend-location';
import { UserStatsDataService } from './user-stats';
import { InventoryDataService } from './inventory';
import { IStoryCategory } from '../../classes/def/core/category';
import { IPlaceExtContainer } from '../../classes/def/places/container';
import { EInventorySync } from 'src/app/classes/def/items/inventory';
import { IEventStoryGroupLinkData } from 'src/app/classes/def/core/links';
import { LocationUtilsWizard } from '../location/location-utils-wizard';
import { IGetPhotoOptions } from '../location/location-utils-def';
import { SettingsManagerService } from '../general/settings-manager';
import { ILeplaceTreasure } from 'src/app/classes/def/places/leplace';
import { LinksDataService } from './links';
import { IGenericSlideData } from 'src/app/classes/def/views/slides';
import { EStoryLocationDoneFlag } from 'src/app/classes/def/nav-params/story';
import { StorageOpsService } from '../general/data/storage-ops';
import { ELocalAppDataKeys } from 'src/app/classes/def/app/storage-flags';
import { IPurchaseInfo } from '../apis/iap-def';
import { IMultiPhotoUploadSpec, IProgressCache, ISetProgressData } from 'src/app/classes/def/story/progress';
import { ILatLng } from 'src/app/classes/def/map/coords';
import { ActivityStatsTrackerService, IActivityStatsCache } from '../app/modules/activity-stats-tracker';

export interface IOpenStoryList {
  resp: IStoriesPageResponse,
  cityId: number
}

export interface IOpenStoryCache {
  storyId: number,
  connectedGroupId: number,
  timestamp: number
}

@Injectable({
  providedIn: 'root'
})
export class StoryDataService {

  public openStory: IStory;
  public openStoryList: IOpenStoryList;
  public openCategoryList: IStoryCategory[];

  progressCacheInit: IProgressCache = {
    photoMainUpload: null,
    photoFinishUpload: null,
    questMainInput: null,
    questFinishInput: null,
    multiPhotoMainUpload: []
  };
  progressCache: IProgressCache = {} as IProgressCache;
  statsCache: IActivityStatsCache = {} as IActivityStatsCache;
  doneAuxCache: number = null;

  constructor(
    public generic: GenericDataService,
    public userStatsProvider: UserStatsDataService,
    public inventory: InventoryDataService,
    public locationUtilsWizard: LocationUtilsWizard,
    public links: LinksDataService,
    public storageOps: StorageOpsService,
    public activityStatsTracker: ActivityStatsTrackerService
  ) {
    console.log("story data service created");
    this.progressCache = Object.assign({}, this.progressCacheInit);
    this.syncCachedStats();
  }

  syncCachedStats() {
    console.log("story data service sync cached stats");
    this.statsCache = Object.assign({}, this.activityStatsTracker.getCachedStats());
    console.log(this.statsCache);
  }

  resetCachedStats() {
    console.log("story data service reset cached stats");
    this.statsCache = Object.assign({}, this.activityStatsTracker.getCachedStatsInit());
    console.log(this.statsCache);
  }

  /**
   * used for photo finish
   * @param photoData 
   */
  updatePhotoFinishUploadCache(photoData: string) {
    console.log("update cache / photo upload");
    this.progressCache.photoFinishUpload = photoData;
  }

  /**
   * used for main quest activity
   * @param questInput 
   */
  updateQuestInputCache(questInput: string) {
    console.log("update cache / main quest input: ", questInput);
    this.progressCache.questMainInput = questInput;
  }

  /**
   * used for 2nd quest activity
   * @param questInput 
   */
  updateQuestFinishInputCache(questInput: string) {
    console.log("update cache / 2nd quest input: ", questInput);
    this.progressCache.questFinishInput = questInput;
  }

  /**
  * used for multi photo upload (main)
  * @param photoData 
  */
  updateMultiPhotoUploadCache(photoData: IMultiPhotoUploadSpec[]) {
    console.log("update cache / multi photo upload");
    this.progressCache.multiPhotoMainUpload = photoData;
  }

  updateDoneAuxFlag(doneAux: number) {
    console.log("update done aux flag");
    this.doneAuxCache = doneAux;
  }

  getPhotoUploadCache() {
    return this.progressCache.photoFinishUpload;
  }

  clearStatusCache() {
    console.log("clear status cache");
    this.progressCache = Object.assign({}, this.progressCacheInit);
    this.doneAuxCache = null;
  }

  cacheResumeStory(connectedGroupId: number) {
    if (this.openStory != null) {
      let id: number = null;
      id = this.openStory.id;
      let set: IOpenStoryCache = {
        storyId: id,
        connectedGroupId: connectedGroupId,
        timestamp: new Date().getTime()
      };
      this.storageOps.setStorageFlagNoAction({
        flag: ELocalAppDataKeys.openStory,
        value: set
      });
    }
  }

  clearResumeStoryCache() {
    return this.storageOps.setStorageFlag({
      flag: ELocalAppDataKeys.openStory,
      value: null
    });
  }

  checkResumeStoryFromCache(coords: ILatLng): Promise<IStoryResponse> {
    return new Promise((resolve, reject) => {
      this.storageOps.getLocalDataKeyResolve(ELocalAppDataKeys.openStory).then((set: IOpenStoryCache) => {
        if (set != null) {
          let ts: number = new Date().getTime();
          // check timestamp, apply only if it's less than 24 hours since started
          if ((ts - set.timestamp) < 86400 * 1000) {
            this.getStoryFromServer(set.storyId, coords).then((data: IStoryResponse) => {
              data.story.localCache = set;
              resolve(data);
            }).catch((err: Error) => {
              reject(err);
            });
          } else {
            resolve(null);
          }
        } else {
          resolve(null);
        }
      }).catch((err: Error) => {
        reject(err);
      });
    });
  }

  updateStatus(story: IStory, locationIndex: number, status: number) {
    let promise = new Promise((resolve, reject) => {
      if (story !== null) {
        if (locationIndex < story.locations.length) {
          let locationId: number = story.locs[locationIndex].merged.id;
          if (locationId !== null) {
            let links: IEventStoryGroupLinkData = this.links.getLinkData();
            this.generic.genericPostStandard("/stories/set-checkpoint-status", {
              storyId: story.id,
              locationId: locationId,
              status: status,
              links: links
            }).then((res) => {
              resolve(res);
            }).catch((err) => {
              reject(err);
            });
          } else {
            reject(new Error("checkpoint not found"));
          }
        } else {
          reject(new Error("index exceeds story length"));
        }
      } else {
        reject(new Error("story not found"));
      }
    });
    return promise;
  }

  /**
   * update progress for a story, set done for location
   * using either local or backend stories
   * @param storyId 
   * @param locationIndex 
   * @param done 
   */
  updateProgress(story: IStory, locationIndex: number, done: number) {
    let promise = new Promise((resolve, reject) => {
      if (story !== null) {
        if (locationIndex < story.locations.length) {
          story.locs[locationIndex].merged.done = done;
          // send request to the backend to update the progress
          let locationId: number = story.locs[locationIndex].merged.id;
          if (locationId !== null) {
            if (done === EStoryLocationDoneFlag.done) {
              // achivements might be unlocked
              this.inventory.resetSyncFlag(EInventorySync.items);
            }
            this.updateProgressServer(story.id, story.locs[locationIndex].merged.id, locationIndex, done).then(() => {
              // also update local cache
              if (this.openStory) {
                this.openStory.locs[locationIndex].merged.done = done;
              }
              this.syncOpenStoryList(story);
              resolve(true);
            }).catch((err: Error) => {
              reject(err);
            });
          } else {
            reject(new Error("checkpoint not found"));
          }
        } else {
          reject(new Error("index exceeds story length"));
        }
      } else {
        reject(new Error("story not found"));
      }
    });
    return promise;
  }

  /**
   * send request to the backend to update the story progress
   * @param storyId 
   * @param locationId the story location id that is fixed in the story
   * @param done 
   */
  updateProgressServer(storyId: number, locationId: number, checkpointIndex: number, done: number) {
    let links: IEventStoryGroupLinkData = this.links.getLinkData();
    let progressData: ISetProgressData = {
      photoFinishUpload: (done === EStoryLocationDoneFlag.done) ? this.progressCache.photoFinishUpload : null,
      questMainInput: (done === EStoryLocationDoneFlag.done) ? this.progressCache.questMainInput : null,
      questFinishInput: (done === EStoryLocationDoneFlag.done) ? this.progressCache.questFinishInput : null,
      multiPhotoMainUpload: (done === EStoryLocationDoneFlag.done) ? this.progressCache.multiPhotoMainUpload : null,
      droneUsed: this.statsCache.droneUsed,
      locationSkipped: this.statsCache.locationSkipped
    };
    this.resetCachedStats();
    return this.generic.genericPostStandard("/stories/set-progress", {
      storyId: storyId,
      locationId: locationId,
      checkIndex: checkpointIndex,
      done: done,
      doneAux: this.doneAuxCache,
      links: links,
      data: progressData
    });
  }

  /**
   * update progress for a story (set false), set done for location
   * using either local or backend stories
   * @param storyId 
   * @param locationIndex 
   */
  clearProgress(story: IStory, locationIndex: number) {
    let promise = new Promise((resolve, reject) => {
      if (story !== null) {
        this.clearStatusCache();
        if (locationIndex < story.locations.length) {
          story.locs[locationIndex].merged.done = EStoryLocationDoneFlag.pending;
          // send request to the backend to (un)save the location
          let locationId: number = story.locs[locationIndex].merged.id;
          if (locationId !== null) {
            this.updateProgressServer(story.id, story.locs[locationIndex].merged.id, locationIndex, 0).then(() => {
              resolve(true);
            }).catch((err: Error) => {
              reject(err);
            });
          }
        } else {
          reject(new Error("index exceeds story length"));
        }
      } else {
        reject(new Error("story not found"));
      }
    });
    return promise;
  }


  /**
   * clear progress for the entire story (all locations)
   * @param storyId 
   */
  clearAllProgress(story: IStory) {
    console.log("clear all progress");
    let promise = new Promise((resolve, reject) => {
      if (story !== null) {
        this.clearAllProgressServer(story.id).then(() => {
          resolve(true);
        }).catch((err: Error) => {
          reject(err);
        });
      } else {
        reject(new Error("story not found"));
      }
    });
    return promise;
  }

  syncOpenStoryList(story: IStory) {
    if (this.openStoryList) {
      for (let i = 0; i < this.openStoryList.resp.stories.length; i++) {
        if (this.openStoryList.resp.stories[i].id === story.id) {
          this.openStoryList.resp.stories[i] = story;
        }
      }
    }
  }

  /**
   * clear all progress for backend story (clears all done flags for locations)
   * @param storyId 
   */
  clearAllProgressServer(storyId: number) {
    let promise = new Promise((resolve, reject) => {
      let links: IEventStoryGroupLinkData = this.links.getLinkData();
      this.generic.genericPostStandard("/stories/clear-all-progress", {
        storyId: storyId,
        links: links
      }).then((response: IGenericResponse) => {
        // also update local cache
        if (this.openStory) {
          for (let i = 0; i < this.openStory.locations.length; i++) {
            this.openStory.locs[i].merged.done = EStoryLocationDoneFlag.pending;
          }
          this.syncOpenStoryList(this.openStory);
        }
        resolve(response);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * save random location to local storage or to the backend
   * @param storyId 
   * @param locationIndex 
   * @param details 
   */
  saveLocation(story: IStory, locationIndex: number, details: IPlaceExtContainer, savePhoto: boolean) {
    let promise = new Promise((resolve, reject) => {
      if (!story) {
        reject(new Error("story not linked"));
        return;
      }

      if (locationIndex >= story.locations.length) {
        reject(new Error("index exceeds story length"));
        return;
      }

      // get real photo url from google
      let options: IGetPhotoOptions = {
        noPlaceholder: true,
        redirect: true,
        // we don't want to save the base64 data, but the actual google photos url
        cacheDisk: false,
        useGeneric: false
      };

      let getPhotoUrl: Promise<string> = new Promise((resolve) => {
        if (SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value || !savePhoto) {
          resolve(null);
        } else {
          this.locationUtilsWizard.loadPlacePhotoWizard2(details, options).then((photoUrl: string) => {
            resolve(photoUrl);
          }).catch((err: Error) => {
            console.error(err);
            resolve(null);
          });
        }
      });

      getPhotoUrl.then((photoUrl: string) => {
        details.photoUrl = photoUrl;
        details.photoUrlSmall = photoUrl;
        // story.locations[locationIndex] = details;
        console.log("save location: ", story.locations[locationIndex]);
        console.log("save location details: ", details);
        // format story, add data from details (not really required as the data is formatted at read time)
        // story.locations[locationIndex] = LocationUtils.formatStoryLoc(story.locations[locationIndex]);
        // console.log("save location details: ", details);
        // console.log("save location: ", story.locations[locationIndex]);
        // send request to the backend to save the location
        let locationId: number = story.locs[locationIndex].merged.id;
        if (locationId !== null) {
          this.saveLocationServer(story.id, story.locs[locationIndex].merged.id, details).then(() => {
            // also update local cache
            if (this.openStory) {
              // this.openStory.locations[locationIndex].details = details;
            }
            this.syncOpenStoryList(story);
            resolve(true);
          }).catch((err: Error) => {
            reject(err);
          });
        } else {
          reject(new Error("location index is null"));
        }
      });

    });
    return promise;
  }


  /**
   * get additional details for the story location
   * e.g. items 
   * @param storyId 
   * @param locationId the story location id that is fixed in the story 
   */
  getStoryLocationDetailsWithCache(storyId: number, locationId: number) {
    let promise = new Promise((resolve, reject) => {
      let load: boolean = false;
      let details: ILocationItemsDef = {
        items: []
      };
      let location: ILocationContainer = null;
      if (this.openStory.id === storyId) {
        location = this.openStory.locs.find(location => location.merged.id === locationId);
      } else {
        if (this.openStoryList) {
          for (let i = 0; i < this.openStoryList.resp.stories.length; i++) {
            if (this.openStoryList.resp.stories[i].id === storyId) {
              location = this.openStoryList[i].locations.find(location => location.id === locationId);
              break;
            }
          }
        }
      }

      if (!(location && location.merged.items)) {
        load = true;
      } else {
        details.items = location.merged.items;
      }

      if (load) {
        console.log("load details");
        this.generic.genericPostStandard("/stories/get-story-location-details", {
          storyId: storyId,
          locationId: locationId
        }).then((response: IGenericResponse) => {
          let details: ILocationItemsDef = response.data;
          location.merged.items = details.items;
          resolve(details);
        }).catch((err: Error) => {
          location.merged.items = [];
          reject(err);
        });
      } else {
        console.log("get cached details");
        resolve(details);
      }
    });
    return promise;

  }

  /**
   * save location to backend
   * 
   * @param storyId 
   * @param storyLocationId the story location id that is fixed in the story
   * @param details 
   */
  saveLocationServer(storyId: number, storyLocationId: number, details: IPlaceExtContainer) {
    let links: IEventStoryGroupLinkData = this.links.getLinkData();
    return this.generic.genericPostStandard("/stories/save-place", {
      storyId: storyId,
      locationId: storyLocationId,
      details: details,
      links: links
    });
  }

  clearAllSavedLocations(story: IStory) {
    let promise = new Promise((resolve, reject) => {
      if (story !== null) {
        this.clearAllSavedLocationsServer(story.id).then(() => {
          resolve(true);
        }).catch((err: Error) => {
          reject(err);
        });
      } else {
        reject(new Error("story not found"));
      }
    });
    return promise;
  }


  /**
   * clear all saved locations for backend story (only remove link from user story locations to fixed location table)
   * @param storyId 
   */
  clearAllSavedLocationsServer(storyId: number) {
    let links: IEventStoryGroupLinkData = this.links.getLinkData();
    return this.generic.genericPostStandard("/stories/clear-saved-places", {
      storyId: storyId,
      links: links
    });
  }

  /**
   * get all stories from the backend that are on the same page with the story having the specified name
   * the stories don't contain details
   * they are added to the local object to be shown in the story list
   */
  getAllStoriesFromServerSearchByName(coords: ILatLng, categoryCode: number, providerId: number, cityId: number, name: string, includeBeta: boolean, hideDevStories: boolean): Promise<IStoriesPageResponse> {
    let promise: Promise<IStoriesPageResponse> = new Promise((resolve, reject) => {
      let req: IStoriesPageRequest = {
        lat: coords ? coords.lat : null,
        lng: coords ? coords.lng : null,
        categoryCode: categoryCode,
        providerId: providerId,
        cityId: cityId,
        includeBeta: includeBeta,
        name: name,
        hideDevStories: hideDevStories
      };
      this.getAllStoriesFromServerCore(req).then((data: IStoriesPageResponse) => {
        resolve(data);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * get all stories from the backend that are on the same page with the story having the specified name
   * the stories don't contain details
   * they are added to the local object to be shown in the story list
   */
  getAllStoriesFromServerSearchByToken(coords: ILatLng, token: string, tokenType: number, includeBeta: boolean, hideDevStories: boolean): Promise<IStoriesPageResponse> {
    let promise: Promise<IStoriesPageResponse> = new Promise((resolve, reject) => {
      let req: IStoriesPageRequest = {
        lat: coords ? coords.lat : null,
        lng: coords ? coords.lng : null,
        token: token,
        tokenType: tokenType,
        includeBeta: includeBeta,
        hideDevStories: hideDevStories
      };
      this.getAllStoriesFromServerCore(req).then((data: IStoriesPageResponse) => {
        resolve(data);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * get all stories from the backend
   * the stories don't contain details
   * they are added to the local object to be shown in the story list
   */
  getAllStoriesFromServer(coords: ILatLng, categoryCode: number, providerId: number, cityId: number, includeBeta: boolean, page: number, hideDevStories: boolean): Promise<IStoriesPageResponse> {
    let promise: Promise<IStoriesPageResponse> = new Promise((resolve, reject) => {
      let req: IStoriesPageRequest = {
        lat: coords ? coords.lat : null,
        lng: coords ? coords.lng : null,
        categoryCode: categoryCode,
        providerId: providerId,
        cityId: cityId,
        includeBeta: includeBeta,
        page: page
      };
      if (hideDevStories) {
        req.testerMode = false;
      }
      this.getAllStoriesFromServerCore(req).then((data: IStoriesPageResponse) => {
        resolve(data);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  getAllStoriesFromServerCore(req: IStoriesPageRequest) {
    let promise = new Promise((resolve, reject) => {
      let route: string = "/stories/get-all-stories";
      console.log(req);
      if (req.page == null && req.name != null) {
        route = "/stories/search-by-name";
      }
      if (req.page == null && req.token != null) {
        route = "/stories/search-by-token";
      }
      this.generic.genericPostStandard(route, req).then((response: IGenericResponse) => {
        if (response.status) {
          let stories: IStory[] = [];
          let responseData: IStoriesPageResponse = response.data;
          for (let i = 0; i < responseData.stories.length; i++) {
            let story: IStory = responseData.stories[i];
            story = StoryDataHelpers._formatStoryFromBackend(story);
            stories.push(story);
          }
          let responseDataFormat: IStoriesPageResponse = {
            stories: stories,
            page: responseData.page,
            pages: responseData.pages
          };

          this.openStoryList = {
            resp: responseDataFormat,
            cityId: req.cityId
          };

          resolve(responseDataFormat);
        } else {
          reject(new Error(response.message));
        }
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }


  loadCategory(selectedCategoryCode: number): Promise<IStoryCategory> {
    let promise: Promise<IStoryCategory> = new Promise((resolve, reject) => {
      this.getStoryCategoriesFromServer(false).then((response: IStoryCategory[]) => {
        let categories = response;
        let category = categories.find(c => c.baseCode === selectedCategoryCode);
        resolve(category);
      }).catch((err) => {
        reject(err);
      });
    });
    return promise;
  }


  getStoryCategoriesFromServer(cache: boolean): Promise<IStoryCategory[]> {
    let promise: Promise<IStoryCategory[]> = new Promise((resolve, reject) => {
      this.generic.genericGetStandard("/stories/get-categories", {}).then((response: IGenericResponse) => {
        if (cache) {
          this.openCategoryList = response.data;
        }
        resolve(response.data);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  getAvailableTranslations(storyId: number): Promise<IDBModelLanguage[]> {
    let req = {
      storyId: storyId
    };
    return this.generic.genericPostStandardWData("/stories/get-story-translations-list", req);
  }

  loadStoryTranslation(storyId: number, languageId: number): Promise<IStory> {
    let req = {
      storyId: storyId,
      languageId: languageId
    };
    return this.generic.genericPostStandardWData("/stories/get-story-translation", req);
  }


  /**
   * get a story from server
   * save it into the local object (buffer)
   * update if existing story with id
   * @param storyId 
   */
  getStoryFromServer(storyId: number, coords: ILatLng): Promise<IStoryResponse> {
    let promise: Promise<IStoryResponse> = new Promise((resolve, reject) => {
      let links: IEventStoryGroupLinkData = this.links.getLinkData();
      let req = {
        storyId: storyId,
        lat: coords ? coords.lat : null,
        lng: coords ? coords.lng : null,
        links: links
      };

      this.generic.genericPostStandard("/stories/get-story", req).then((response: IGenericResponse) => {
        let data: IStoryResponse = response.data;
        if (!data.locked) {
          data.story = StoryDataHelpers._formatStoryFromBackend(data.story);
          this.openStory = data.story; // cache the open story
        }
        resolve(data);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * load story progress for nonlinear sync
   * @param storyId 
   */
  loadStoryProgressOnly(storyId: number, userId: number, groupId: number): Promise<IStoryResponse> {
    let promise: Promise<IStoryResponse> = new Promise((resolve, reject) => {
      let links: IEventStoryGroupLinkData = this.links.getLinkData();

      if (groupId != null) {
        links = {
          storyId: storyId,
          groupId: groupId
        };
      }

      let req: any = {
        storyId: storyId,
        links: links
      };

      if (userId != null) {
        req.userId = userId;
      }

      this.generic.genericPostStandard("/stories/check-all-progress", req).then((response: IGenericResponse) => {
        let data: IStoryResponse = response.data;
        if (!data.locked) {
          data.story = StoryDataHelpers._formatStoryFromBackend(data.story);
        }
        resolve(data);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }


  /**
   * check if the story is unlocked
   * returns codes:
   * 0 - unlocked
   * 1 - locked/level
   * 2 - locked/coins
   * @param storyId 
   */
  checkStoryUnlocked(storyId: number, coords: ILatLng) {
    let promise = new Promise((resolve, reject) => {
      let req = {
        storyId: storyId,
        lat: coords ? coords.lat : null,
        lng: coords ? coords.lng : null
      };
      this.generic.genericPostStandard("/stories/check-unlocked", req).then((response: IGenericResponse) => {
        let d: IMasterLockResponse = response.data;
        resolve(d);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }


  /**
  * unlock story (qr and/or coins)
  * @param storyId 
  */
  checkUnlockStory(storyId: number, token: string, tokenType: number, coords: ILatLng): any {
    let promise = new Promise((resolve, reject) => {
      let links: IEventStoryGroupLinkData = this.links.getLinkData();
      let req = {
        storyId: storyId,
        token: token,
        tokenType: tokenType,
        links: links,
        lat: coords ? coords.lat : null,
        lng: coords ? coords.lng : null
      };

      this.generic.genericPostStandard("/stories/check-unlock-story-context", req).then((response: IGenericResponse) => {
        resolve(response.data);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  /**
  * get story unlock codes
  * redeem short
  * team-based
  * @param storyId 
  */
  getStoryUnlockCodes(storyId: number): any {
    let promise = new Promise((resolve, reject) => {
      let req = {
        storyId: storyId,
        type: EStoryUnlockType.short,
        // or redeemShort?
        scope: EStoryUnlockScope.partner,
        teamBased: true
      };
      this.generic.genericPostStandardWData("/stories/get-story-unlock-codes", req).then((response: IStoryUnlockCode) => {
        resolve(response);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  /**
  * admin method for unlock testing
  * @param storyId 
  */
  removeUserStoryLink(storyId: number): any {
    let promise = new Promise((resolve, reject) => {
      let req = {
        storyId: storyId,
        ownedType: true
      };
      this.generic.genericPostStandard("/stories/remove-user-story-link", req).then((response: IGenericResponse) => {
        resolve(response.data);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }


  /**
   * unlock story (qr and/or coins)
   * @param storyId 
   */
  unlockStory(storyId: number, token: string, tokenType: number, purchaseData: IPurchaseInfo, coords: ILatLng, testerOverride: boolean): Promise<boolean> {
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      let links: IEventStoryGroupLinkData = this.links.getLinkData();
      let req: any = {
        storyId: storyId,
        token: token,
        tokenType: tokenType,
        transactionData: null,
        purchaseData: purchaseData,
        links: links,
        lat: coords ? coords.lat : null,
        lng: coords ? coords.lng : null
      };
      if (testerOverride) {
        req.testerAccount = testerOverride;
      }
      this.generic.genericPostStandard("/stories/unlock-story", req).then((response: IGenericResponse) => {
        resolve(response.data);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }


  getCurrentOpenStory(storyId: number, coords: ILatLng, reload: boolean): Promise<IStoryResponse> {
    let promise: Promise<IStoryResponse> = new Promise((resolve, reject) => {
      if (this.openStory && !reload) {
        let storyResp: IStoryResponse = {
          story: this.openStory,
          locked: false,
          masterLock: null,
          message: "ok"
        };
        resolve(storyResp);
      } else {
        this.getStoryFromServer(storyId, coords).then((data: IStoryResponse) => {
          resolve(data);
        }).catch((err: Error) => {
          reject(err);
        });
      }
    });
    return promise;
  }

  getCurrentStoryList(coords: ILatLng, categoryCode: number, providerId: number, cityId: number, includeBeta: boolean, page: number, hideDevStories: boolean): Promise<IStoriesPageResponse> {
    let promise: Promise<IStoriesPageResponse> = new Promise((resolve, reject) => {
      if (this.openStoryList && (this.openStoryList.cityId === cityId)) {
        resolve(this.openStoryList.resp);
      } else {
        this.getAllStoriesFromServer(coords, categoryCode, providerId, cityId, includeBeta, page, hideDevStories).then((data: IStoriesPageResponse) => {
          resolve(data);
        }).catch((err: Error) => {
          reject(err);
        });
      }
    });
    return promise;
  }

  getCurrentCategoryList(): Promise<IStoryCategory[]> {
    let promise: Promise<IStoryCategory[]> = new Promise((resolve, reject) => {
      if (this.openCategoryList) {
        resolve(this.openCategoryList);
      } else {
        this.getStoryCategoriesFromServer(true).then((data) => {
          resolve(data);
        }).catch((err: Error) => {
          reject(err);
        });
      }
    });
    return promise;
  }

  getCustomStoryTreasures(storyId: number, location: ILatLng, scanRadius: number): Promise<ILeplaceTreasure[]> {
    let promise: Promise<ILeplaceTreasure[]> = new Promise((resolve, reject) => {
      let req = {
        storyId: storyId,
        lat: location.lat,
        lng: location.lng,
        scanRadius: scanRadius
      };
      this.generic.genericPostStandardWData("/stories/custom-map/get-treasures", req).then((response: ILeplaceTreasure[]) => {
        resolve(response);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  getSliderTutorial(storyId: number): Promise<IGenericSlideData[]> {
    let req = {
      storyId: storyId
    };
    return this.generic.genericPostStandardWData("/stories/get-slider-tutorial", req);
  }

  checkValidationStatus(storyLocationId: number): Promise<IUserStoryLocation> {
    let req = {
      storyLocationId: storyLocationId
    };
    return this.generic.genericPostStandardWData("/stories/validation/check-status", req);
  }
}
