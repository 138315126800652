import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IActivity } from 'src/app/classes/def/core/activity';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { ParamHandler } from 'src/app/classes/general/params';
import { ActivityUtils } from 'src/app/classes/utils/activity-utils';
import { ECustomParamCategoryCode, ECustomParamScope, ICustomParamForActivity } from 'src/app/classes/def/core/custom-param';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { IActivityPhotoSpecs, IDynamicParamActivityParam } from 'src/app/classes/def/nav-params/activity-details';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { INavBarItem } from 'src/app/classes/def/views/nav';
import { EPhotoCardMode } from 'src/app/classes/def/story/progress';
import { OtherUtils } from 'src/app/services/utils/other-utils';

export interface IPhotoValidationRequirementsViewParams {
  descriptionExtra: string;
  description: string;
  descriptionTitle?: string;
  descriptionContext?: string;
  activityPhotoSpecs?: IActivityPhotoSpecs;
  title: string;
  photoUrl: string;
  buttonText?: string;
  uploadedPhotoUrl: string;
  activity: IActivity;
  uploadFromGallery: boolean;
  enable: boolean;
}

@Component({
  selector: 'modal-photo-validation-requirements',
  templateUrl: './photo-validation-requirements.component.html',
  styleUrls: ['./photo-validation-requirements.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PhotoValidationRequirementsViewComponent implements OnInit, OnDestroy {
  description: string = "";
  descriptionContext: string = "";
  descriptionTitle: string = "";
  title: string = "";
  selectAll: boolean = false;
  loading: boolean = false;

  photoUrl: string = null;
  photoUploadUrl: string = null;

  params: IPhotoValidationRequirementsViewParams;
  vs: IViewSpecs = ViewSpecs.getDefault();
  appIconsStandard = EAppIconsStandard;
  paramSlides: IDynamicParamActivityParam[] = [];
  hasParamSlides: boolean = false;
  enableParamSlides: boolean = false;
  hasSinglePhoto: boolean = false;
  uploadFromGallery: boolean = false;


  showPhotoCard: boolean = false;

  photoCardSelected: number = EPhotoCardMode.reference;
  photoCardUrl: string = null;

  navBarSlidesPerView: number = 2;
  navBarPhotoCardItems: INavBarItem[] = [{
    name: "reference",
    value: EPhotoCardMode.reference
  }, {
    name: "upload",
    value: EPhotoCardMode.uploaded
  }];

  continueText: string = "Continue";
  enable: boolean = true;

  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      this.params = np.params;

      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);

      if (this.params.description) {
        this.description = this.params.description;
      }
      if (this.params.descriptionExtra) {
        this.descriptionContext = this.params.descriptionExtra;
      }
      if (this.params.descriptionContext != null) {
        this.descriptionContext = this.params.descriptionContext;
      }
      if (this.params.descriptionTitle != null) {
        this.descriptionTitle = this.params.descriptionTitle;
      }

      if (this.params.buttonText) {
        this.continueText = this.params.buttonText;
      }

      if (this.params.photoUrl) {
        this.photoUrl = this.params.photoUrl;
      }

      this.checkCustomParams(this.params.activity);

      let aps: IActivityPhotoSpecs = this.params.activityPhotoSpecs;
      if (aps != null) {
        console.log("custom photo specs: ", aps);
        if (aps.d != null) {
          this.descriptionContext = aps.d;
        }
        if (aps.title != null) {
          this.descriptionTitle = aps.title;
        }
        if (aps.ref) {
          this.photoUrl = aps.ref;
        }
      }

      if (this.params.uploadedPhotoUrl) {
        this.photoUploadUrl = this.params.uploadedPhotoUrl;
        this.setPhotoCard();
      }

      this.enable = this.params.enable;
      this.uploadFromGallery = this.params.uploadFromGallery;
      // this.descriptionLines = this.formatText(this.description);
      this.title = this.params.title;
    }
  }

  ngOnDestroy() {

  }

  checkCustomParams(activity: IActivity) {
    this.paramSlides = ActivityUtils.getCustomParamSlides(activity, ECustomParamScope.challenge);
    if (this.paramSlides.length > 0) {
      if ((this.paramSlides.length === 1) || !this.enableParamSlides) {
        let pslide = this.paramSlides.find(ps => ps.data.customParamCategoryCode === ECustomParamCategoryCode.photoChallenge);
        if (pslide != null) {
          console.log("custom photo param: ", pslide.data);
          let cparam: ICustomParamForActivity = pslide.data;
          if (cparam.description != null) {
            this.descriptionContext = cparam.description;
          }
          if (cparam.title != null) {
            this.descriptionTitle = cparam.title;
          }
          let dataString: string = cparam.dataString;
          if (dataString != null) {
            try {
              let dataObj: IActivityPhotoSpecs = JSON.parse(dataString);
              this.photoUrl = dataObj.ref;
            } catch (err) {
              console.error(err);
            }
          }
        }
      } else {
        this.hasParamSlides = true;
      }
    }
  }

  dismiss(value: number) {
    if (value == null) {
      value = EAlertButtonCodes.cancel;
    }
    setTimeout(() => {
      this.modalCtrl.dismiss(value).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  ok() {
    this.dismiss(EAlertButtonCodes.ok);
  }

  cancel() {
    this.dismiss(EAlertButtonCodes.cancel);
  }


  setPhotoCard() {
    let showPhotoCard: boolean = OtherUtils.checkDefinedPhotoUrl(this.photoUploadUrl);
    switch (this.photoCardSelected) {
      case EPhotoCardMode.uploaded:
        this.photoCardUrl = this.photoUploadUrl;
        break;
      case EPhotoCardMode.reference:
        this.photoCardUrl = this.photoUrl;
        break;
    }
    this.showPhotoCard = showPhotoCard;
  }


  selectPhotoCardFromTab(navItem: INavBarItem) {
    if (!navItem) {
      return;
    }
    this.photoCardSelected = navItem.value;
    this.setPhotoCard();
  }
}
