<modal-header-container [vs]="vs">
  <leplace-toolbar leftButton="close" [leftIcon]="true" rightButton="" [rightIcon]="false" [title]="title"
    (leftClick)="dismiss(null)" (rightClick)="nop()" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>


<!-- <ion-content no-padding [ngClass]="theme">

  <div class="view-frame theme-background" fxLayout="column"> -->

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div class="scrollbar-y places-list">

    <ion-list *ngIf="loaded && hasItems" class="full-w">
      <ion-item-group *ngFor="let group of storiesGrouped">
        <!-- theme-background -->
        <ion-item-divider sticky class="list-item-divider padding-top-s">
          <ion-label class="info-text-s bold text-color-primary">
            {{group.name | uppercase}}
          </ion-label>
        </ion-item-divider>
        <ion-item *ngFor="let story of group.stories; let i = index;"
          class="list-item list-item-card-padding transparent-bg" (click)="goToStoryDetail(story)" no-lines>
          <story-info-promo class="full-w" *ngIf="story" [story]="story" [reload]="reloadItems"
            tappable></story-info-promo>
        </ion-item>
      </ion-item-group>
    </ion-list>

    <div *ngIf="loaded && !hasItems" class="full-w padding-s center-text">
      <span class="center-text info-text-m bold text-color-primary">No featured storylines found in your
        location</span>
    </div>

    <div fxFlex></div>
    <div class="stick-bottom theme-border-top">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col>
            <ion-button class="action-button-fill button-color-alternate" (click)="searchByToken()">
              <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIconsStandard.locked"></icon-wrapper>
              <span class="button-font-size-s">Unlock</span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</modal-container>