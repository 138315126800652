
import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { EAppIcons } from 'src/app/classes/def/app/icons';
import { IPhotoCardItem } from 'src/app/classes/def/items/photos';


@Component({
  selector: 'photo-upload-disp',
  templateUrl: './photo-upload-disp.component.html',
  styleUrls: ['./photo-upload-disp.component.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        transition: "background-color 250ms linear"
      })),
      state('active', style({
        transition: "background-color 250ms linear"
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ]
})
export class PhotoUploadDispComponent implements OnInit, OnDestroy, OnChanges {

  photoLoaded: boolean = false;
  showState: string = "inactive";
  state: boolean = false;

  itemName: string = null;
  isLocked: boolean = false;
  appIcons = EAppIcons;

  isReference: boolean = true;
  photoUrl: string = null;

  @Input()
  data: IPhotoCardItem;

  @Input()
  update: boolean;


  constructor(

  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'update':
            this.format();
            break;
          default:
            break;
        }
      }
    }
  }

  ngOnDestroy() {

  }

  ngOnInit() {
    this.format();
  }

  format() {
    if (this.data) {
      if (this.data.upload != null) {
        this.isReference = false;
        this.photoUrl = this.data.upload;
      } else {
        this.photoUrl = this.data.ref;
      }
      this.itemName = this.data.title;
    }
  }
}
