<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss()" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div class="padding-s" fxLayout="column">
    <safe-html-renderer [unsafeHtmlContent]="description"
      innerClass="info-text-m description-frame text-color-primary padding-horizontal-s margin-bottom-s"></safe-html-renderer>
    <div *ngFor="let a of actionsList">
      <!-- action-button-fill button-color-transparent options-menu-button -->

      <div tappable class="margin-top-s padding-horizontal-s flex-row-x full-w button-props"
        [ngClass]="(!a.enabled || !enabled) ? 'button-disabled' : ''" (click)="a.handler()">
        <icon-wrapper class="icon-wrapper slot-icon-padding" *ngIf="a.icon" slot="start" [icon]="a.icon"
          [custom]="a.customIcon">
        </icon-wrapper>
        <span class="as-text element-selector button-font-size-s uppercase text-color-primary height-auto">
          {{a.name}}
        </span>
      </div>

      <!-- <ion-button class="as-text element-selector info-text-s text-color-primary" [disabled]="!a.enabled || !enabled"
        (click)="a.handler()">
        <span [ngClass]="a.icon ? 'span-icon button-font-size-s' : 'button-font-size-m'">{{a.name}}</span>
        <icon-wrapper class="icon-wrapper slot-icon-padding" *ngIf="a.icon" slot="start" [icon]="a.icon"
          [custom]="a.customIcon">
        </icon-wrapper>
      </ion-button> -->

    </div>
    <safe-html-renderer [unsafeHtmlContent]="footnote"
      innerClass="info-text-s description-frame text-color-primary padding-top-s padding-horizontal-s"></safe-html-renderer>
  </div>
</modal-container>