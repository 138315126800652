import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EImgIcons } from 'src/app/classes/def/app/icons';
import { ITreasureSpec } from 'src/app/classes/def/places/leplace';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AppConstants } from 'src/app/classes/app/constants';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { IDescriptionFrameParams } from 'src/app/modals/generic/modals/description-frame/description-frame.component';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';

@Component({
  selector: 'treasure-spec-card',
  templateUrl: './treasure-spec-card.component.html',
  styleUrls: ['./treasure-spec-card.component.scss'],
})
export class TreasureSpecCardComponent implements OnInit, OnDestroy {
  icons = EImgIcons;

  photoUrl: string;

  @Input()
  data: ITreasureSpec;

  show: boolean = false;

  constructor(
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService
  ) {

  }

  ngOnInit() {
    console.log(this.data);
    if (this.data) {
      this.show = true;
    }
  }

  ngOnDestroy() {

  }

  showDescription() {
    let params: IDescriptionFrameParams = {
      description: this.data.description,
      title: this.data.dispName,
      photoUrl: this.data.photoUrl,
      loaderCode: null,
      mode: null
    };
    this.uiextStandard.showStandardModal(null, EModalTypes.description, "Treasure Card", {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: params
    }).then(() => {

    }).catch(() => {

    });
  }
}
