import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { ApiDef } from 'src/app/classes/app/api';
import { EAppIconsStandard, EAppIcons } from 'src/app/classes/def/app/icons';
import { Messages } from 'src/app/classes/def/app/messages';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ImageUtilsService } from 'src/app/services/media/image-utils';
import { IMediaUploadContext } from 'src/app/services/media/media-template';
import { MediaUtilsService } from 'src/app/services/media/media-utils';
import { VideoService, IVideoUploadResponse } from 'src/app/services/media/video';

@Component({
  selector: 'media-recording-video',
  templateUrl: './media-recording-video.component.html',
  styleUrls: ['./media-recording-video.component.scss'],
})
export class MediaRecordingVideoComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild('avStream', { read: ElementRef, static: false }) avStream: ElementRef<HTMLVideoElement>;

  @Input()
  description: string;

  @Input()
  title: string;

  @Input()
  videoUrl: string;

  @Input()
  toggleRecording: boolean;

  @Input()
  innerClass: string;

  @Input()
  context: IMediaUploadContext;

  @Output()
  mediaUpload: EventEmitter<boolean> = new EventEmitter();

  @Output()
  mediaRecord: EventEmitter<boolean> = new EventEmitter();

  hasVideo: boolean = false;

  withButtons: boolean = false;

  appIconsStandard = EAppIconsStandard;
  appIcons = EAppIcons;

  np: INavParams = null;

  started: boolean = false;
  recording: boolean = false;
  locked: boolean = false;

  show: boolean = false;

  loading: boolean = false;

  innerClassActive: string = "full-w border-radius";

  constructor(
    public shareProvider: ShareService,
    public mediaUtils: MediaUtilsService,
    public imgUtils: ImageUtilsService,
    public video: VideoService,
    public uiext: UiExtensionService
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'toggleRecording':
            this.toggleRecord();
            break;
        }
      }
    }
  }

  ngOnInit() {
    if (this.videoUrl != null) {
      // this.video.setVideoElementPlayable(this.avStream);
      this.show = true;
    }
    if (this.innerClass) {
      this.innerClassActive += " " + this.innerClass;
    }
  }

  isVideoContent() {
    return this.videoUrl != null;
  }

  ngOnDestroy() {
    this.stop();
  }

  toggleRecord() {
    if (this.locked) {
      console.warn("recording state locked");
      return;
    }
    if (!this.recording) {
      this.start(true);
    }
    if (this.recording) {
      this.stop();
    }
  }

  start(record: boolean) {
    if (this.videoUrl != null) {
      this.video.setMediaElementPlayable(this.avStream);
      this.video.playMediaElement(this.avStream, (paused: boolean) => {
        this.started = !paused;
      });
      this.started = true;
      return;
    }
    this.video.setMediaElement(this.avStream);
    this.locked = true;
    this.loading = true;
    this.mediaRecord.emit(null);
    this.video.startMediaCapture(null, false, (data) => {
      this.uploadRecordedBlob(data);
      // console.log("recording completed: ", data);
    }).then(() => {
      console.log("started");
      if (record) {
        this.startRecording();
      } else {
        this.mediaRecord.emit(false);
      }
      this.started = true;
      this.locked = false;
      this.loading = false;
      this.show = true;
    }).catch((err: Error) => {
      console.error(err);
      this.mediaRecord.emit(false);
      this.locked = false;
      this.loading = false;
    });
  }


  startPlay() {
    if (this.videoUrl != null) {
      this.video.setMediaElementPlayable(this.avStream);
      this.video.playMediaElement(this.avStream, (paused: boolean) => {
        this.started = !paused;
      });
      this.started = true;
      return;
    } else {
      return;
    }
  }

  async record() {
    try {
      if (this.started) {
        return;
      }
      if (this.videoUrl != null) {
        let res: number = await this.uiext.showAlert(Messages.msg.recordingOverwrite.after.msg, Messages.msg.recordingOverwrite.after.sub, 2, null);
        if (res === EAlertButtonCodes.cancel) {
          return;
        }
        this.videoUrl = null;
      }
      if (!this.started) {
        this.started = true;
        this.video.startMediaCapture(null, false, (data) => {
          this.video.uploadVideo(data, this.context.storyId, this.context.storyLocationId, this.context.file).then((res: IVideoUploadResponse) => {
            if (res != null) {
              // this.videoUrl = res.uploadedUrl;
              let toks: string[] = res.uploadedUrl.split("/");
              let filename: string = toks[toks.length - 1];
              console.log("filename: ", filename);
              this.videoUrl = ApiDef.mainServerURL + "/media/user/video-stream?file=" + filename;
            }
          }).catch((err: Error) => {
            console.error(err);
            this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
          });
        }).then(() => {
          console.log("started");
          this.started = false;
          this.locked = false;
          this.loading = false;
          this.show = true;
        }).catch((err: Error) => {
          console.error(err);
          this.locked = false;
          this.loading = false;
          this.started = false;
        });
      }
    } catch (err) {
      console.error(err);
    }
  }


  upload(data: any) {
    if (!this.context) {
      return;
    }
    this.loading = true;
    this.video.uploadMedia(this.context.storyId, this.context.storyLocationId, ".webm", data).then((res: IVideoUploadResponse) => {
      if (res && res.status) {
        console.log("uploaded url: ", res.uploadedUrl);
        this.videoUrl = res.uploadedUrl;
        this.show = true;
        this.loading = false;
        this.mediaUpload.emit(true);
      } else {
        this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
        this.loading = false;
      }
    }).catch((err) => {
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
      this.loading = false;
    });
  }

  pause() {
    this.started = false;
    if (this.videoUrl != null) {
      this.video.pauseMediaElement(this.avStream);
      return;
    }
    this.video.pauseMediaCapture();
  }

  stop() {
    this.started = false;
    this.video.stopMediaCapture();
    this.recording = false;
    this.mediaRecord.emit(false);
  }

  // async record() {
  //   try {
  //     if (this.recording) {
  //       this.stop();
  //       return;
  //     }
  //     if (this.videoUrl != null) {
  //       let res: number = await this.uiext.showAlert(Messages.msg.recordingOverwrite.after.msg, Messages.msg.recordingOverwrite.after.sub, 2, null);
  //       if (res === EAlertButtonCodes.cancel) {
  //         return;
  //       }
  //       this.videoUrl = null;
  //     }
  //     if (!this.started) {
  //       this.start(true);
  //     } else {
  //       this.stop();
  //       await SleepUtils.sleep(100);
  //       this.start(true);
  //       // this.startRecording();
  //     }
  //   } catch (err) {
  //     console.error(err);
  //   }
  // }

  startRecording() {
    this.mediaRecord.emit(true);
    this.recording = true;
    console.log("start recording");
    this.video.startRecording((data) => {
      this.uploadRecordedBlob(data);
    });
  }

  uploadRecordedBlob(data: any) {
    console.log("recorded blob: ", data);
    if (data != null) {
      this.imgUtils.blobToB64(data).then((b64: any) => {
        this.upload(b64);
      });
    } else {
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
    }
  }

}

