import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ParamHandler } from 'src/app/classes/general/params';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { Messages } from 'src/app/classes/def/app/messages';
import { NgForm } from '@angular/forms';

export interface IZoomNavParams {
  hidden: boolean,
  meetingNumber: string,
  meetingPassword: string
}

export interface IZoomSetupDetails {
  userId: string,
  userPassword: string,
  meetingNumber: string,
  meetingPassword: string,
  displayName: string
}
@Component({
  selector: 'modal-zoom-setup',
  templateUrl: './zoom-setup.component.html',
  styleUrls: ['./zoom-setup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ZoomSetupViewComponent implements OnInit, OnDestroy {
  title: string = "Zoom Connect";
  vs: IViewSpecs = ViewSpecs.getDefault();
  loaded: boolean = false;

  np: INavParams = null;

  descriptionHtml: string = "";
  loading: boolean = false;
  submitted: boolean = false;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  bd: IZoomSetupDetails = {
    userId: "",
    userPassword: "",
    meetingNumber: "",
    meetingPassword: "",
    displayName: ""
  };

  meetingNumber;
  meetingPassword;
  userPassword;

  showMeetingDetails: boolean = false;
  update: boolean = false;
  params: IZoomNavParams;

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      if (np.view) {
        this.vs = np.view;
      }
      this.params = np.params;
      this.bd.meetingNumber = this.params.meetingNumber;
      this.bd.meetingPassword = this.params.meetingPassword;
      this.showMeetingDetails = !this.params.hidden;
      if (!this.params.meetingNumber && !this.params.meetingPassword) {
        this.showMeetingDetails = true;
      }
    }

    this.init();
  }

  init() {
    let description: string = "<p>Please log in to your Zoom account to connect to this meeting.</p>";
    this.descriptionHtml = description;
    this.loaded = true;
  }

  ngOnDestroy() {

  }

  dismiss(value: IZoomSetupDetails) {
    setTimeout(() => {
      this.modalCtrl.dismiss(value).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }


  ok(form: NgForm) {
    this.submitted = true;
    if (!form.valid) {
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, "Please enter the required fields.");
      return;
    }
    this.dismiss(this.bd);
  }

  later() {
    this.dismiss(null);
  }
}
