<ion-row class="center-items">
  <ion-col size="2">
    <img *ngIf="e.photoUrl" [src]="e.photoUrl" class="img-xxs" />

    <ion-button *ngIf="e.icon" class="action-button-icon-only icon-color-primary margin-right-s"
      (click)="onTapSelect(e.code)">
      <icon-wrapper slot="icon-only" [icon]="e.icon" [custom]="e.customIcon"></icon-wrapper>
    </ion-button>

  </ion-col>
  <ion-col [size]="!fixed ? 8 : 10">
    <safe-html-renderer [unsafeHtmlContent]="descriptionHtml"
      innerClass="info-text-m description-frame text-color-primary"></safe-html-renderer>
  </ion-col>
  <ion-col size="2" *ngIf="!fixed">
    <expander [expanded]="expanded" (expand)="toggleExpanded()" [flush]="true"></expander>
  </ion-col>
</ion-row>
<!-- <ion-row *ngIf="!fixed">
  <ion-col size="12">
    <expander [expanded]="expanded" (expand)="toggleExpanded()"></expander>
  </ion-col>
</ion-row> -->