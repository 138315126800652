import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IShareMsgParams, MessageUtils, IShareMsg, EShareMsgFeatures, EMessageTrim } from 'src/app/classes/utils/message-utils';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ModalController } from '@ionic/angular';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { MiscDataService } from 'src/app/services/data/misc';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { ParamHandler } from 'src/app/classes/general/params';
import { IStory } from 'src/app/classes/def/core/story';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { IStoryFinishedModalOutput } from 'src/app/classes/def/nav-params/modal-finished-return';
import { EFinishedActionParams } from 'src/app/classes/def/core/activity';
import { IRatingView } from 'src/app/classes/def/views/rating';
import { IGenericResponse, IPageResponse } from 'src/app/classes/def/requests/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { IAdHandlerContext, AdHandlerService } from 'src/app/services/general/apis/ads-handler';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { PromiseUtils } from 'src/app/services/utils/promise-utils';
import { StringUtils } from 'src/app/services/app/utils/string-utils';
import { ITextInputParams, ITextInputResult, TextInputViewComponent } from 'src/app/modals/generic/modals/text-input/text-input.component';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { UserDataService } from 'src/app/services/data/user';
import { AppConstants } from 'src/app/classes/app/constants';
import { ScrollUtils } from 'src/app/services/general/ui/scroll-utils';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { LinksDataService } from 'src/app/services/data/links';
import { IEventStoryGroupLinkData } from 'src/app/classes/def/core/links';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IGenericLeaderboardEntry } from 'src/app/classes/def/user/leaderboard';
import { IStatDef } from 'src/app/classes/def/user/stats';
import { EAudioGuide } from 'src/app/classes/def/core/interactive-features';


@Component({
  selector: 'modal-story-finished',
  templateUrl: './story-finished.component.html',
  styleUrls: ['./story-finished.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoryFinishedViewComponent implements OnInit, OnDestroy {
  theme: string = "theme-aubergine theme-aubergine-bg";
  title: string = "Story finished";
  shareEnabled: boolean = false;
  rating: number = 0;
  params: IShareMsgParams;
  watchRewardSub;
  collectedLP: number = 0;
  platform: IPlatformFlags = {} as IPlatformFlags;
  vs: IViewSpecs = ViewSpecs.getDefault();
  photoUrl: string = null;
  photoUrlLoaded: string = null;
  finished: boolean = false;
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;
  np: INavParams = null;
  ratingEnabled: boolean = false;
  adContext: IAdHandlerContext = null;
  customDescription: string = null;
  descriptionPlain: string = null;
  inputFocused: boolean = false;

  activityStats: IStatDef[] = null;

  shareEnabledGlobal = AppConstants.enableSocialSharing;

  review = {
    description: ""
  };

  reviewCopy = {
    description: ""
  };

  maxLength: number = EMessageTrim.userInput;
  withAudioGuide: boolean = false;
  audioGuideAutoplay: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public miscProvider: MiscDataService,
    public analytics: AnalyticsService,
    public adHandler: AdHandlerService,
    public popupFeatures: PopupFeaturesService,
    public multiplayerDataProvider: MPDataService,
    public userData: UserDataService,
    public linksData: LinksDataService
  ) {
    this.adHandler.initContext();
    this.adContext = this.adHandler.getContext();
    this.adContext.shareContext = EShareMsgFeatures.storyFinished;
  }


  onPlatformLoaded() {
    this.platform = SettingsManagerService.settings.platformFlags;
  }

  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  isReviewEnabled() {
    return this.rating > 0;
  }

  /**
   * dismiss modal
   */
  continue() {
    let returnData: IStoryFinishedModalOutput = {
      status: null,
      rewardLP: this.collectedLP + this.adContext.rewardLP
    };
    let promiseRating: Promise<any>;

    if (this.rating > 0) {
      promiseRating = this.rateStory();
    } else {
      promiseRating = Promise.resolve(true);
    }

    promiseRating.then((proceed: boolean) => {
      if (proceed) {
        if (this.adContext.rewardIssued) {
          returnData.status = EFinishedActionParams.reward;
          this.dismiss(returnData);
        } else {
          returnData.status = EFinishedActionParams.default;
          this.dismiss(returnData);
        }
      }
    });
  }

  reviewChange(update: boolean) {
    if (update) {
      this.textToHTML();
    } else {
      // popup window
      let params: ITextInputParams = {
        description: "<p></p>",
        title: "Edit review",
        text: this.reviewCopy.description,
        focused: true,
        allowEmpty: true
      };

      this.uiext.showCustomModal(null, TextInputViewComponent, {
        view: {
          fullScreen: false,
          transparent: false,
          large: false,
          addToStack: true,
          frame: false
        },
        params: params
      }).then((description: ITextInputResult) => {
        if (description) {
          console.log(description);
          this.reviewCopy.description = description.text;
          this.textToHTML();
        }
      });
    }
  }

  textToHTML() {
    this.review.description = StringUtils.textToHTML(this.reviewCopy.description);
  }

  textFromHTML() {
    this.reviewCopy.description = StringUtils.textFromHTML(this.review.description);
  }

  textAreaFocused(focused: boolean) {
    console.log("focus: ", focused);
    this.inputFocused = focused;
    if (focused) {
      ScrollUtils.scrollActiveElementIntoVIew(false, null, null, 30);
    }
  }

  /**
   * resolve: user rating, null if postponed (edit some more)
   * @returns 
   */
  checkRating() {
    return new Promise((resolve) => {
      let data: IRatingView = {
        rating: this.rating,
        review: this.review.description
      };

      if (!data.review) {
        this.uiext.showAlert(Messages.msg.submitWithoutReview.after.msg, Messages.msg.submitWithoutReview.after.sub, 2, null).then((res: number) => {
          switch (res) {
            case EAlertButtonCodes.ok:
              resolve(data);
              break;
            case EAlertButtonCodes.cancel:
            default:
              resolve(null);
              break;
          }
        }).catch((err: Error) => {
          console.error(err);
          resolve(null);
        });
      } else {
        resolve(data);
      }
    });
  }

  onRatingSet(rating: number): void {
    this.rating = rating;
  }

  /**
   * submit story rating
   * resolve: proceed
   */
  rateStory() {
    let promise = new Promise((resolve) => {
      console.log("rate story: ", this.params);
      if (!(this.params && this.params.story)) {
        resolve(true); // internal error, proceed
        return;
      }
      this.checkRating().then((data: IRatingView) => {
        if (data != null) {
          this.miscProvider.rateStory(this.params.story.id, null, data.rating, data.review, this.finished).then(async (_response: IGenericResponse) => {
            if (this.rating === 5) {
              let storeAvailable: boolean = await this.popupFeatures.userPromptRateAppCheckAvailableResolve();
              if (storeAvailable) {
                let rateAppInput: number = await PromiseUtils.wrapResolve(this.uiext.showAlert(Messages.msg.thanksForRatingRequestAppRating.after.msg, Messages.msg.thanksForRatingRequestAppRating.after.sub, 2, null, true), true);
                if (rateAppInput === EAlertButtonCodes.ok) {
                  await PromiseUtils.wrapResolve(this.popupFeatures.userPromptRateApp(), true);
                }
              } else {
                await PromiseUtils.wrapResolve(this.uiext.showRewardPopupQueue(Messages.msg.thanksForRating.after.msg, Messages.msg.thanksForRating.after.sub, null, false, 2000), true);
              }
            } else {
              await PromiseUtils.wrapResolve(this.uiext.showRewardPopupQueue(Messages.msg.thanksForRating.after.msg, Messages.msg.thanksForRating.after.sub, null, false, 2000), true);
            }
            resolve(true);
          }).catch((err: Error) => {
            this.analytics.dispatchError(err, "story-home");
            resolve(true); // internal error, proceed
          });
        } else {
          resolve(false); // user postponed rating
        }
      });
    });
    return promise;
  }

  /**
   * Share progress then dismiss
   */
  shareAndContinue() {
    this.adContext.loading = true;
    this.shareEnabled = false;
    this.shareProvider.share(this.adContext.shareMessage).then(() => {
      this.adContext.loading = false;
      this.shareEnabled = true;
    }).catch(() => {
      this.adContext.loading = false;
      this.shareEnabled = true;
    });
  }

  /**
   * play reward video then dismiss
   */
  playAndContinue() {
    this.adHandler.playAndContinue(null);
  }


  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    console.log(this.np);
    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      this.params = Object.assign({}, MessageUtils.getDefaultShareMsgParams(), np.params);
      console.log(this.params);

      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);

      let story: IStory = this.params.story.story;
      this.finished = this.params.story.progress.finished;
      this.ratingEnabled = this.params.story.progress.percentFinished > 0;

      this.adContext.watchEnabled = this.finished && this.params.actionButtons.watchAd;
      this.shareEnabled = this.finished && this.params.actionButtons.share;

      this.collectedLP = this.params.items.collected * this.params.items.value;
      if (this.params.items.collectedValue != null) {
        this.collectedLP = this.params.items.collectedValue;
      }

      let shareMsg: IShareMsg = MessageUtils.buildMessage(EShareMsgFeatures.storyFinished, this.params);
      this.adContext.shareMessage = shareMsg.share;
      let infoHTML: string = shareMsg.auxHTML;

      if (story) {
        if (story.finishedDescription) {
          this.customDescription = story.finishedDescription;
          this.descriptionPlain = StringUtils.textFromHTMLPlain(this.customDescription);
        }
        this.withAudioGuide = story.audioGuide != null && story.audioGuide !== 0;
        this.audioGuideAutoplay = story.audioGuide === EAudioGuide.ttsAuto;
      }

      if (this.params.photo) {
        this.photoUrl = this.params.photo.display;
      }

      this.adHandler.updateContext(this.params, shareMsg.share, infoHTML, this.collectedLP, story != null ? story.rewardCoins : 0);

      this.getStoryLeaderboardScore();
    }

    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
      if (loaded) {
        this.onPlatformLoaded();
      }
    }, (err: Error) => {
      console.error(err);
    });
  }

  getStoryLeaderboardScore() {
    let links: IEventStoryGroupLinkData = this.linksData.getStoryGroupLinkData();
    let leaderId: number = GeneralCache.userId;
    let teams: boolean = false;
    if (links != null) {
      leaderId = links.groupId != null ? links.groupId : leaderId;
      teams = links.groupId != null;
    }
    if (!(this.params && this.params.story)) {
      return;
    }
    this.multiplayerDataProvider.getLeaderboardUserScore(leaderId, teams, this.params.story.id).then((response: IGenericResponse) => {
      let data: IPageResponse = response.data;
      let score: number = null;
      if (data != null) {
        let board: IGenericLeaderboardEntry[] = data.data;
        if (board && board.length > 0) {
          let leader: IGenericLeaderboardEntry = board[0];
          score = leader.score;
          if (this.params.story && this.params.story.xpScaleFactor != null) {
            score = Math.floor(leader.score * this.params.story.xpScaleFactor);
          }
        }
      }
      console.log("leaderboard user score: ", score);
      if (score != null) {
        this.activityStats = [{
          id: null,
          code: null,
          typeCode: null,
          delegateCode: null,
          singleName: null,
          name: "registered score",
          value: score,
          valueString: score + " XP",
          statsLevelActivities: null,
          weight: null,
          level: null,
          key: null,
          scoreEntry: 0,
          show: 1,
          genericCode: null,
          gameContext: null,
          gameContextCode: null
        }];
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngOnDestroy() {
    console.log("close modal");
    this.clearWatch();
  }

  clearWatch() {
    if (this.watchRewardSub) {
      this.watchRewardSub.unsubscribe();
      this.watchRewardSub = null;
    }
  }
}
