import { Component, OnInit, OnDestroy, Input, EventEmitter, Output, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { IGroupMember, IGroup, EGroupRole, EGroupType } from 'src/app/classes/def/mp/groups';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EAppIcons, EAppIconsStandard, EPhotos } from 'src/app/classes/def/app/icons';

@Component({
  selector: 'group-card',
  templateUrl: './group-card.component.html',
  styleUrls: ['./group-card.component.scss'],
})
export class GroupCardComponent implements OnInit, OnDestroy, OnChanges {
  photoLoaded: boolean = false;
  bgPhoto: string = null;
  groupUsers: IGroupMember[] = [];
  countUsers: string = "0";
  countActiveUsers: string = "0";
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  connecting: boolean = false;

  groupClass: string = "info-text-xs bold text-color-overlay-s uppercase";
  groupTypeName: string = "";

  timeout = null;

  expires: boolean = false;

  isAdmin: boolean = false;
  isMember: boolean = false;

  @Input()
  group: IGroup;

  @Input()
  disableTracker: boolean;

  @Input()
  selected: boolean;

  @Input()
  locked: boolean;

  @Input()
  noBorder: boolean;

  @Input()
  withReload: boolean;

  @Input()
  reloadTimeout: number;

  @Input()
  resetLoadingTimeout: boolean;

  @Input()
  update: boolean;

  border: boolean = true;

  @Output()
  select: EventEmitter<any> = new EventEmitter();

  constructor(
  ) {

  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'update':
            this.formatGroup();
            break;
        }
      }
    }
  }

  /**
   * check connected users periodically to display the connect icon
   */
  checkConnected() {
    this.timeout = setTimeout(() => {
      // console.log("check connected");
      this.formatGroup();
      this.checkConnected();
    }, 1000);
  }

  ngOnInit() {
    if (this.group) {
      this.formatGroup();
      this.checkConnected();
    }
    if (this.noBorder) {
      this.border = false;
    }
  }

  clicked() {
    console.log("clicked");
    this.select.emit(this.group);
  }

  ngOnDestroy() {
    this.timeout = ResourceManager.clearTimeout(this.timeout);
  }


  onCheck(ev: Event) {
    ev.preventDefault();
    ev.stopImmediatePropagation();
    ev.cancelBubble = true;
    ev.stopPropagation();
    // Doing other stuff here to control if checkbox should be checked or not!, or just let it be empty!
    this.select.emit(this.group);
    return (false);   // you not have to return false , depends of what you doing above like using alertController etc
  }

  formatGroup() {
    if (this.group) {
      if (!this.group.photoUrl) {
        this.bgPhoto = EPhotos.userPlaceholder;
      } else {
        this.bgPhoto = this.group.photoUrl;
      }

      if (this.group.members) {
        this.groupUsers = this.group.members;

        let connectedCount: number = 0;
        let connectingFlag: boolean = false;

        if (!this.disableTracker) {
          for (let i = 0; i < this.groupUsers.length; i++) {
            if (this.groupUsers[i].dynamic) {
              // users started to connect, switch to dynamic status display mode
              connectingFlag = true;
              if (this.groupUsers[i].dynamic.connected) {
                connectedCount += 1;
              }
            }
          }
        }

        let enabledUsersCount: number = this.groupUsers.filter(gu => {
          if (gu.staticFlags) {
            return gu.staticFlags.enabled;
          }
          return true;
        }).length;


        if (connectingFlag && (connectedCount < enabledUsersCount)) {
          this.connecting = true;
        } else {
          this.connecting = false;
        }

        if (connectingFlag) {
          // all users connected
          this.countActiveUsers = connectedCount + "/" + enabledUsersCount + "";
        } else {
          if (enabledUsersCount === 1) {
            this.countActiveUsers = enabledUsersCount + "";
          } else {
            this.countActiveUsers = enabledUsersCount + "";
          }
        }

        if (this.groupUsers.length === 1) {
          this.countUsers = this.groupUsers.length + "";
        } else {
          this.countUsers = this.groupUsers.length + "";
        }
      }

      switch (this.group.role) {
        case EGroupRole.member:
          this.groupClass = "info-text-xs bold text-color-overlay-s uppercase";
          this.isMember = true;
          break;
        case EGroupRole.leader:
          this.groupClass = "info-text-xs bold text-color-overlay-s text-color-overlay-warn uppercase";
          this.isMember = true;
          this.isAdmin = true;
          break;
      }

      switch (this.group.type) {
        case EGroupType.mp:
          this.groupTypeName = "private team";
          break;
        case EGroupType.lobby:
          this.groupTypeName = "public team";
          break;
      }

      if (this.group.expirationTimeLeftPercent != null) {
        this.expires = true;
      }
    }
  }

}
