import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { EImgIcons, EAppIcons, EPhotos, EAppIconsStandard } from 'src/app/classes/def/app/icons';

@Component({
  selector: 'stripe-card',
  templateUrl: './stripe-card.component.html',
  styleUrls: ['./stripe-card.component.scss']
})
export class StripeCardComponent implements OnInit, OnDestroy {
  imgIcons = EImgIcons;
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  stripeBadge: string = EPhotos.stripeBadge;

  descriptionDisp: string = "";
  titleDisp: string = "Alternative checkout";
  footerDisp: string = "<p>Also available as a standalone product featured in Leplace World</p><p>Checkout with Stripe</p>";

  loaded: boolean = false;

  @Input()
  price: string;

  @Input()
  promo: string

  @Output() checkout = new EventEmitter<boolean>();

  constructor(

  ) {

  }

  ngOnInit() {

  }

  ngOnDestroy() {

  }

  getPromoDisp() {
    if (this.promo != null) {
      return "Promo: " + this.price + " -> " + this.promo;
    }
    return this.price;
  }

  tapCheckout() {
    this.checkout.emit(true);
  }
}
