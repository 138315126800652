<div *ngIf="groupRole" class="group-role-card">
  <ion-grid class="full-w">
    <ion-row class="center-items center-text" [ngClass]="{'card-selected': selected || self}">
      <ion-col size="4">
        <img [src]="bgPhoto" alt="Avatar" class="img-avatar-crop">
      </ion-col>
      <ion-col size="6">
        <ion-row>
          <ion-col size="12" class="text-left">
            <span class="info-text-lg bold text-color-primary">{{caption}}</span>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="info">
            <ion-col size="12" class="text-left">
              <span class="info-text-m bold text-color-primary">{{info}}</span>
            </ion-col>
          </ion-row>
      </ion-col>

      <ion-col size="2">
        <ion-checkbox [ngModel]="selected" ngDefaultControl [disabled]="!groupRole.available" class="custom-checkbox checkbox-right" (click)="onCheck($event)">
        </ion-checkbox>
      </ion-col>

    </ion-row>
  </ion-grid>
</div>