<!-- <div *ngIf="loaded">
  <ion-grid class="full-w" [ngClass]="{'card-selected': self}">

    <ion-row class="center-items center-text">
      <ion-col size="12">
        <span class="info-text-m bold text-color-primary">{{playerName}}</span>
      </ion-col>
    </ion-row>

    <ion-row class="center-items center-text">
      <ion-col size="4">
        <img [src]="bgPhoto" alt="Avatar" class="img-avatar-crop">
      </ion-col>
      <ion-col size="8">
        <div>
          <kv-table [dict]="kvStats" [format]="true"></kv-table>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div> -->


<div *ngIf="loaded">
  <ion-grid class="full-w" [ngClass]="{'card-selected': self}">
    <ion-row class="center-items center-text">
      <ion-col size="4">
        <img [src]="bgPhoto" alt="Avatar" [ngClass]="mini ? 'img-avatar-xsmall-crop' : 'img-avatar-small-crop'">
      </ion-col>
      <ion-col size="8">
        <div>
          <span class="bold text-color-primary" [ngClass]="mini ? 'info-text-s' : 'info-text-m'">{{playerName}}</span>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>