export const environment = {
  production: true,
  firebase: {
    apiKey: 'AIzaSyCPotlxRsgOdpLAVi5mXky4O4YxG0VoJQA',
    authDomain: 'leplace-global.firebaseapp.com',
    databaseURL: 'https://leplace-global.firebaseio.com',
    projectId: 'leplace-global',
    storageBucket: 'leplace-global.appspot.com',
    messagingSenderId: '806950131803'
  }
};
