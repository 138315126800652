

export interface IArrayRemoveDuplicates<T> {
    newArray: T[],
    duplicates: T[]
}

export class ArrayUtils {


    /**
     * get unique values from array
     * @param a 
     */
    static getUniqueValues<T>(a: T[]): T[] {
        function onlyUnique(value: T, index: number, array: T[]) {
            return array.indexOf(value) === index;
        }
        let unique = a.filter(onlyUnique);
        return unique;
    }

    /**
   * shuffle gen index
   */
    static shuffleArrayGetIndex<T>(a: T[]): number[] {
        let idx: number[] = a.map((_value, index, _array) => index);
        return idx.sort((_a, _b) => 0.5 - Math.random());
    }

    /**
     * shuffle by index
     */
    static shuffleArrayByIndex<T>(a: T[], idx: number[]): T[] {
        let b: T[] = [];
        for (let i = 0; i < a.length; i++) {
            b.push(a[idx[i]]);
        }
        return b;
    }

    /**
     * fisher yates shuffle algorithm
     */
    static shuffleArray<T>(a: T[]): T[] {
        for (let i = a.length - 1; i >= 0; i--) {
            let j = Math.floor(Math.random() * i);
            let aux = a[i];
            a[i] = a[j];
            a[j] = aux;
        }
        return a;
    }

    static removeDuplicatesSimpleArray<T>(originalArray: T[]): T[] {
        let newArray: T[] = [];
        let lookupObject: any = {};

        for (let i in originalArray) {
            lookupObject[originalArray[i]] = originalArray[i];
        }

        for (let i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }

    /**
     * remove duplicates from object array by prop
     * @param originalArray 
     * @param prop 
     */
    static removeDuplicates<T>(originalArray: T[], prop: string): T[] {
        let newArray: T[] = [];
        let lookupObject: { [key: string]: T } = {};

        for (let i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        for (let i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }

    static getDuplicatesCountGetFn<T>(originalArray: T[], getProp: (obj: T) => string): number {
        let newArray: IArrayRemoveDuplicates<T> = ArrayUtils.removeDuplicatesGetFn(originalArray, getProp);
        let newCount: number = newArray.newArray.length;
        let oldCount: number = originalArray.length;
        return oldCount - newCount;
    }


    /**
     * remove duplicates from object array by getProp
     * @param originalArray 
     * @param getProp 
     */
    static removeDuplicatesGetFn<T>(originalArray: T[], getProp: (obj: T) => string): IArrayRemoveDuplicates<T> {
        let newArray: T[] = [];
        let lookupObject: { [key: string]: T } = {};

        let duplicates: T[] = [];

        // console.log("\n\n", originalArray);

        for (let e of originalArray) {
            if (!lookupObject[getProp(e)]) {
                lookupObject[getProp(e)] = e;
            } else {
                duplicates.push(e);
            }
        }

        // console.log("\n\n", lookupObject);

        for (let key in lookupObject) {
            newArray.push(lookupObject[key]);
        }

        let res: IArrayRemoveDuplicates<T> = {
            newArray: newArray,
            duplicates: duplicates
        }
        return res;
    }

    /**
     * remove duplicates from object array by prop
     * @param originalArray 
     * @param prop 
     */
    static removeDuplicatesFilter<T>(originalArray: T[], prop: string): T[] {
        return originalArray.filter((item: T, pos: number, array: T[]) => {
            return array.map((mapItem: T) => { return mapItem[prop]; }).indexOf(item[prop]) === pos;
        });
    }

    /**
     * remove duplicates from object array by prop
     * @param {} originalArray 
     * @param prop 
     */
    static removeDuplicatesReduce<T>(originalArray: T[], prop: string) {
        return (Object.values(originalArray.reduce((acc, item) => Object.assign(acc, { [item ? item[prop] : null]: item }), {})));
    }

    /**
     * sort, general purpose
     * @param array 
     */
    static sortArrayByObjectKey<T>(array: T[], key: string, asc: boolean): T[] {
        return array.sort((a, b) => {
            if (a[key] < b[key]) {
                return asc ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return asc ? 1 : -1;
            }
            return 0;
        });
    }

    /**
     * sort a dict, return an array
     * @param dict 
     * @param key 
     * @param asc 
     */
    static sortDictByObjectKey<T>(dict: T, key: string, asc: boolean): T[][] {
        // Create items array
        let items: T[][] = Object.keys(dict).map((key) => {
            return [key, dict[key]];
        });

        // Sort the array based on the second element
        items.sort((a, b) => {
            if (a[key] < b[key]) {
                return asc ? -1 : 1;
            }
            if (a[key] > b[key]) {
                return asc ? 1 : -1;
            }
            return 0;
        });
        return items;
    }

    /**
     * creates a new dict with sorted keys
     * @param dict 
     */
    static sortDictKeys<T>(dict: T): T {
        // Getting the keys of JavaScript Object. 
        let dictSorted: T = Object.keys(dict)
            // Sort and calling a method on 
            // keys on sorted fashion. 
            .sort().reduce((obj, key) => {
                // Adding the key-value pair to the 
                // new object in sorted keys manner 
                obj[key] = dict[key];
                return obj;
            }, {} as T);
        return dictSorted;
    }


    /**
     * sync arrays without creating a new array instance
     * by key
     * optional sorting
     * @param dest 
     * @param src 
     * @param key 
     * @param sort 
     */
    static syncArrayInPlace(dest: any[], src: any[], key: string, sort: boolean): any[] {

        let removeList: number[] = [];

        // check removed items
        for (let i = 0; i < dest.length; i++) {
            let d: any = dest[i];
            let exists: boolean = false;
            for (let j = 0; j < src.length; j++) {
                let s: any = src[j];
                if (s[key] === d[key]) {
                    // update existing
                    dest[i] = s;
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                removeList.push(i);
            }
        }

        // remove objects
        for (let i = 0; i < removeList.length; i++) {
            let index: number = removeList[i];
            if (index !== -1) {
                dest.splice(index, 1);
            }
        }

        // check new items
        for (let i = 0; i < src.length; i++) {
            let s: any = src[i];
            let exists: boolean = false;
            for (let j = 0; j < dest.length; j++) {
                let d: any = dest[j];
                if (d[key] === s[key]) {
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                dest.push(s);
            }
        }

        // sort by key
        if (sort) {
            dest = dest.sort((a, b) => {
                if (a[key] > b[key]) {
                    return 1;
                }
                else if (a[key] < b[key]) {
                    return -1;
                }
                else {
                    return 0;
                }

            });
        }

        return dest;
    }


    /**
     * sync objects by keys
     * only keep common keys and values from dest (if existing key)
     * don't update existing keys values
     * @param oldObj 
     * @param newObj 
     * @param inplace
     */
    static syncObjectKeys(oldObj: any, newObj: any, inplace: boolean): any {

        let oldKeys: string[] = Object.keys(oldObj);
        let newKeys: string[] = Object.keys(newObj);

        let removeKeys: string[] = [];

        // remove old keys
        for (let dk of oldKeys) {
            if (newKeys.indexOf(dk) === -1) {
                // remove key if not found in new object
                removeKeys.push(dk);
            }
        }

        let syncObj: any = {};
        if (inplace) {
            syncObj = oldObj;
        } else {
            syncObj = Object.assign({}, oldObj);
        }

        // do add new keys
        for (let sk of newKeys) {
            if (oldKeys.indexOf(sk) === -1) {
                // add new key
                syncObj[sk] = newObj[sk];
            }
        }

        // do remove old keys
        for (let rk of removeKeys) {
            delete syncObj[rk];
        }

        return syncObj;
    }

    static splitGrid(items: any[], rowSize: number, blanks: boolean) {
        let rowNum: number = 0;
        let itemGrid: any[] = Array(Math.ceil(items.length / rowSize));
        // add rows
        for (let i = 0; i < items.length; i += rowSize) {
            itemGrid[rowNum] = Array(rowSize); // declare two elements per row
            // add items
            for (let j = 0; j < rowSize; j++) {
                if (items[i + j]) {
                    itemGrid[rowNum][j] = items[i + j];
                } else {
                    if (blanks) {
                        itemGrid[rowNum][j] = null;
                    } else {
                        itemGrid[rowNum].splice(j);
                    }
                }
            }
            rowNum++; // go on to the next row
        }
        return itemGrid;
    }

    /**
     * check arrays equal
     * not considering order / sorting, only the values of the elements
     * @param a 
     * @param b 
     * @returns 
     */
    static arraysEqualAnyOrder(a: any[], b: any[]) {
        if (a === b) return true;
        if (a == null || b == null) return false;
        if (a.length !== b.length) return false;

        let sa = [...a].sort();
        let sb = [...b].sort();

        for (let i = 0; i < sa.length; ++i) {
            if (sa[i] !== sb[i]) return false;
        }
        return true;
    }

    static arrayMove(arr: any[], fromIndex: number, toIndex: number) {
        if (toIndex >= arr.length) {
            var k = toIndex - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
        return arr;
    };

}



export class CircularBuffer<T> {
    private buffer: Array<T | undefined>;
    private head: number;
    private tail: number;
    private size: number;

    constructor(capacity: number) {
        this.buffer = new Array<T | undefined>(capacity);
        this.head = 0;
        this.tail = 0;
        this.size = 0;
    }

    isEmpty(): boolean {
        return this.size === 0;
    }

    isFull(): boolean {
        return this.size === this.buffer.length;
    }

    enqueue(item: T): void {
        if (this.isFull()) {
            this.head = (this.head + 1) % this.buffer.length;
        } else {
            this.size++;
        }

        this.buffer[this.tail] = item;
        this.tail = (this.tail + 1) % this.buffer.length;
    }

    dequeue(): T | undefined {
        if (this.isEmpty()) {
            return undefined;
        }

        const item = this.buffer[this.head];
        this.buffer[this.head] = undefined;
        this.head = (this.head + 1) % this.buffer.length;
        this.size--;

        return item;
    }

    getBufferContent(): T[] {
        const content: T[] = [];

        for (let i = 0; i < this.size; i++) {
            const index = (this.head + i) % this.buffer.length;
            const item = this.buffer[index];
            if (item !== undefined) {
                content.push(item);
            }
        }

        return content;
    }
}