
import { Injectable } from '@angular/core';

import { EMQTTStatusKeys, EMQTTTopics, IMQTTChatMessage, TelemetryDef } from './def';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { BehaviorSubject } from 'rxjs';
import { IChatModalNavParams } from 'src/app/classes/def/nav-params/arena';
import { ChatViewComponent } from 'src/app/modals/app/modals/chat/chat.component';
import { UiExtensionService } from '../general/ui/ui-extension';
import { IChatElement } from 'src/app/classes/def/mp/chat';
import { EMPMessageCodes } from 'src/app/classes/def/mp/protocol';
import { MQTTService } from './mqtt.service';
import { CircularBuffer } from '../utils/array-utils';

@Injectable({
    providedIn: 'root'
})
export class MQTTChatService {

    watchInboundChat: BehaviorSubject<IMQTTChatMessage> = null;
    watchOutboundChat: BehaviorSubject<IChatElement> = null;

    watchOutboundChatSub = null;
    watchInboundChatSub = null;

    chatHistory: CircularBuffer<IChatElement> = null;
    bufferCapacity: number = 100;

    enableChatNotifications: boolean = true;
    chatNotification: boolean = false;

    constructor(
        private mqttService: MQTTService,
        private uiext: UiExtensionService
    ) {
        console.log("MQTT chat service created");
        this.watchOutboundChat = new BehaviorSubject<IChatElement>(null);
        this.watchInboundChat = this.mqttService.getWatchInboundChat();
        this.watchSendFromModal();
        this.monitorChatHistory();
    }

    /**
     * watch send from chat modal
     * send data to websocket on received
     */
    watchSendFromModal() {
        if (!this.watchOutboundChatSub) {
            this.watchOutboundChatSub = this.watchOutboundChat.subscribe(async (data: IChatElement) => {
                console.log("received input message: ", data);
                if (data) {
                    switch (data.type) {
                        case EMPMessageCodes.chat:
                            data.userId = GeneralCache.userId;
                            let message: IMQTTChatMessage = TelemetryDef.getMqttMessageFromChatMessage(data);
                            let payload = JSON.stringify(message);
                            await this.mqttService.doPublish(EMQTTTopics.resp + "/user/" + GeneralCache.userId, payload);
                            message.ext = false;
                            message.username = "you";
                            this.watchInboundChat.next(message);
                            break;
                    }
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    monitorChatHistory() {
        if (!this.watchInboundChatSub) {
            this.watchInboundChatSub = this.watchInboundChat.subscribe((data: IMQTTChatMessage) => {
                if (data) {
                    try {
                        console.log("inbound message: ", data);
                        let chatElement: IChatElement = TelemetryDef.getChatMessageFromMqttMessage(data);
                        if (!this.chatHistory) {
                            this.chatHistory = new CircularBuffer<IChatElement>(this.bufferCapacity);
                        }
                        this.chatHistory.enqueue(chatElement);
                        if (!this.chatNotification && this.enableChatNotifications) {
                            this.chatNotification = true;
                            console.log("sending general notification");
                            this.uiext.showToastNoAction("<p>You have a new message from " + data.username + ":</p><p>" + chatElement.message + "</p>", true, 3000);
                            setTimeout(() => {
                                this.chatNotification = false;
                            }, 30000);
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    clearChatHistory() {
        this.chatHistory = null;
    }

    openChatWithOperator() {
        this.watchInboundChat.next(null); // don't add previous message as it's already added in the chat history
        let params: IChatModalNavParams = {
            title: "LP Chat (Operator)",
            chatRx: this.chatHistory != null ? this.chatHistory.getBufferContent().map(e => Object.assign({}, e)) : [],
            chatRxObservable: null,
            chatRxMqttObservable: this.watchInboundChat,
            chatRxSyncObservable: null,
            sendObservable: this.watchOutboundChat,
            groupStatusObservable: null,
            groupId: null,
            groupName: "operator",  // should be operator name
            meetingPlaceId: null,
            meetingPlaceName: null,
            showChatHeads: false,
            context: null
        };

        this.enableChatNotifications = false;
        this.mqttService.updateStatus(EMQTTStatusKeys.chatOpen, { value: true });
        console.log("mqtt service chat window open");

        this.uiext.showCustomModal(null, ChatViewComponent, {
            view: {
                fullScreen: true,
                transparent: false,
                large: true,
                addToStack: true,
                headerOpacity: false,
                frame: false
            },
            params: params
        }).then((_data) => {
            console.log("mqtt service chat window closed");
            this.enableChatNotifications = true;
            this.mqttService.updateStatus(EMQTTStatusKeys.chatOpen, { value: false });
        }).catch((err: Error) => {
            console.error(err);
            this.enableChatNotifications = true;
            this.mqttService.updateStatus(EMQTTStatusKeys.chatOpen, { value: false });
        });
    }
}
