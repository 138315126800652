
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IUserStatCategory } from 'src/app/classes/def/user/stats';
import { IKVStat } from 'src/app/classes/def/app/kval';
import { INavBarItem } from 'src/app/classes/def/views/nav';
import { AlertController, PopoverController, Platform, MenuController, ModalController } from '@ionic/angular';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { AuthRequestService } from 'src/app/services/general/auth-request/auth-request';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { UserStatsDataService } from 'src/app/services/data/user-stats';
import { ERouteDef } from 'src/app/app-utils';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { EventsDataService } from 'src/app/services/data/events';
import { IEventGroupStatsResponse } from 'src/app/classes/def/events/events';
import { IEventStoryGroupLinkData } from 'src/app/classes/def/core/links';
import { IEventGroupMember, IGroup } from 'src/app/classes/def/mp/groups';
import { ITeamStatsNavParams } from 'src/app/classes/def/nav-params/teams';
import { PhotosService } from 'src/app/services/media/photos';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { Router } from '@angular/router';
import { IPhotoResult } from 'src/app/classes/def/media/photo';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-team-stats',
  templateUrl: './team-stats.page.html',
  styleUrls: ['./team-stats.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TeamStatsPage implements OnInit, OnDestroy {
  heading: string = "";
  password: string = "";
  confirmPassword: string = "";
  isOnline: boolean = false;
  userStatus: IUserStatCategory = null;
  groupStatsTable: IKVStat[] = null;
  levelPercent: number = 0;
  details: string = "";
  categoryCode: number = null;
  levelTypeLabel: string = "";
  loaded: boolean = false;
  categoryTabs: INavBarItem[] = [];
  theme: string = "theme-light theme-light-bg";

  groupMembers: IEventGroupMember[] = null;
  groupName: string = null;
  groupId: number = null;
  group: IGroup = null;

  eventDataParams: IEventStoryGroupLinkData = null;

  np: INavParams = null;
  vs: IViewSpecs;
  isModal: boolean = false;

  show = {
    levelBar: true,
    categoryTabs: true
  };

  constructor(
    public router: Router,
    public authService: AuthRequestService,
    public alertCtrl: AlertController,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public popoverCtrl: PopoverController,
    public userStatsProvider: UserStatsDataService,
    public eventData: EventsDataService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public backButton: BackButtonService,
    public menuCtrl: MenuController,
    public nps: NavParamsService,
    public analytics: AnalyticsService,
    public settingsProvider: SettingsManagerService,
    public modalCtrl: ModalController,
    public photoProvider: PhotosService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  swipeEvent(e) {
    this.backButton.handleSwipeEvent(e);
  }

  ngOnInit() {
    this.uiext.disableSidemenu();
    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.teamStats, null, this.np);
      console.log("nav params: ", npInfo.params);
      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      if (npInfo.hasParams) {
        // the view was opened with parameters
        // either for business registration or for payment (bill)
        let np: INavParams = npInfo.params;
        let params: ITeamStatsNavParams = np.params;
        this.vs = np.view;
        this.isModal = this.vs ? this.vs.isModal : false;
        this.categoryCode = params.categoryCode;
        this.levelTypeLabel = params.label ? params.label : "";
        this.categoryTabs = params.categoryTabs;
        this.eventDataParams = params.links;
        this.getStatsContext();
      }

      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back();
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  selectCategoryFromNavItem(item) {
    console.log(item);
  }

  /**
   * get stats from requested context
   * e.g. user, group stats
   */
  getStatsContext() {
    this.show.levelBar = false;
    this.show.categoryTabs = false;
    if (this.eventDataParams) {
      if (this.eventDataParams.registeredGroupIds.length > 0) {
        this.getTeamStats(this.eventDataParams.eventId, this.eventDataParams.registeredGroupIds[0]);
      }
    }
  }

  ngOnDestroy() {
    this.uiext.enableSidemenu();
    this.backButton.checkPop(this.vs);
  }


  getTeamStats(eventId: number, groupId: number) {
    this.loaded = false;
    this.eventData.viewGroupStats(eventId, groupId).then((resp: IEventGroupStatsResponse) => {
      this.groupStatsTable = resp.stats;
      this.details = "" + resp.score + " XP";
      this.groupMembers = resp.members;
      this.groupName = resp.group ? resp.group.name + " (" + resp.group.id + ")" : null;
      this.groupId = resp.group.id;
      this.heading = this.groupName;
      this.group = resp.group;
      this.loaded = true;
    }).catch((err: Error) => {
      this.loaded = true;
      this.analytics.dispatchError(err, "team-stats");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }


  back() {
    if (this.isModal) {
      this.dismissModal();
    } else {
      this.router.navigate([ERouteDef.eventsHome], { replaceUrl: true }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  dismissModal() {
    setTimeout(() => {
      this.modalCtrl.dismiss().then(() => {

      }).catch((err: Error) => {
        console.error(err);
      });
    });
  }

  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      reload: {
        name: "Refresh",
        code: 7,
        icon: EAppIconsStandard.refresh,
        enabled: true
      },
      addPhoto: {
        name: "Upload photo",
        code: 1,
        icon: EAppIconsStandard.takePhoto,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 1:
          this.uploadPhotoFromCamera();
          break;
        case 7:
          this.getStatsContext();
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  uploadPhotoFromCamera() {
    this.photoProvider.uploadPhotoFromCameraWizard((data) => {
      return this.photoProvider.uploadGroupPhoto(this.groupId, data);
    }, true, true).then((photoRes: IPhotoResult) => {
      if (photoRes) {

      }
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "team-stats");
      this.uiext.showAlertNoAction(Messages.msg.returned.after.msg, ErrorMessage.parse(err, Messages.msg.returned.after.sub));
    });
  }
}
