import { Injectable } from "@angular/core";
import { IObjectChartData, IObjectPosition, IMinimapOptions } from "../../../classes/def/ar/minimap";
import { GeometryUtils, IQuaternions } from "../../utils/geometry-utils";

import { Chart, ChartData, ChartConfiguration, ChartOptions, LineController, ScatterController, LineElement, PointElement, LinearScale, Title, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
Chart.register(LineController, ScatterController, LineElement, PointElement, LinearScale, TimeScale, Title);


interface IRateTrackerElement {
    value: number;
}

interface IRateTracker {
    [key: string]: IRateTrackerElement;
}

@Injectable({
    providedIn: 'root'
})
export class MinimapService {

    // the absolute coords of objects 
    objectChartDataFixed: IObjectChartData[] = [];
    // the translated coords of objects
    objectChartDataTranslated: IObjectPosition[] = [];
    // the displayed data on the chart (with rotation, translation, etc)
    objectChartDataDisplay: IObjectPosition[] = [];

    minimapOptions: IMinimapOptions;

    minimapChart: Chart = null;

    prevHeading: number = 0;
    prevQuaternion: IQuaternions = null;

    reversed: boolean = false;

    chartOptions: ChartConfiguration = null;

    rateLimiter: number = 20; // ms, or 0 if disabled

    rateTracker: IRateTracker = {
        translate: {
            value: null
        },
        rotate: {
            value: null
        }
    };

    constructor() {
        console.log("minimap service created");
    }

    /**
     * get a snapshot of the current objects in view
     */
    getCurrentView(): IObjectPosition[] {
        let snapshot: IObjectPosition[] = [];
        // for (let i = 0; i < this.objectChartDataDisplay.length; i++) {
        //     snapshot.push(Object.assign({}, this.objectChartDataDisplay[i]));
        // }
        for (let i = 0; i < this.objectChartDataDisplay.length; i++) {
            snapshot.push(Object.assign({}, this.objectChartDataDisplay[i]));
        }
        return snapshot;
    }


    /**
     * get the chart object or create it if not existing
     * @param ctx 
     * @param minimapOptions 
     */
    getChart(ctx, minimapOptions: IMinimapOptions) {
        // if (!this.minimapChart) {
        //     this.chartOptions = this.getSetup(minimapOptions);
        //     this.minimapChart = new Chart(ctx, this.chartOptions);
        // } else {
        //     this.minimapChart.ctx = ctx;
        // }

        this.chartOptions = this.getSetup(minimapOptions);
        this.minimapChart = new Chart(ctx, this.chartOptions);

        console.log("initial data: ", this.objectChartDataFixed, this.objectChartDataTranslated, this.objectChartDataDisplay);
        // console.log(this.minimapChart.data);
        // console.log(this.minimapChart);
        return this.minimapChart;
    }

    changeCtx(_ctx) {

    }


    /**
    * create chart for minimap
    */
    getSetup(minimapOptions: IMinimapOptions, dark: boolean = false) {
        let fontColor = "#fcd182";
        if (dark) {
            fontColor = "black";
        }

        // this.objectChartDataFixed = objectChartData;
        this.minimapOptions = minimapOptions;

        let scatterChartData: ChartData = {
            datasets: [{
                label: 'Objects',
                pointRadius: minimapOptions.pointRadius,
                // borderColor: window.chartColors.red,
                // backgroundColor: color(window.chartColors.red).alpha(0.2).rgbString(),
                backgroundColor: [fontColor],
                data: this.objectChartDataDisplay,
                // yAxisID: 'y-1'
            }],
            labels: ['Objects']
        };

        let options: ChartOptions = {
            responsive: true,
            animation: {
                duration: minimapOptions.animation
            },
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false
                },
                title: {
                    display: false
                },
                tooltip: {
                    enabled: false
                }
            },
            hover: {
                mode: null
            },
            events: [],
            elements: {
                point: {
                    backgroundColor: '#fcd182',
                    borderColor: '#fcd182'
                }
            },
            scales: {
                x: {
                    display: false,
                    type: 'linear',
                    position: 'bottom',
                    min: -minimapOptions.far,
                    max: minimapOptions.far,
                    ticks: {
                        stepSize: minimapOptions.tick,
                        textStrokeColor: fontColor,
                        color: fontColor,
                    }
                },
                y: {
                    display: false,
                    type: 'linear',
                    position: 'left',
                    min: -minimapOptions.far,
                    max: minimapOptions.far,
                    ticks: {
                        stepSize: minimapOptions.tick,
                        textStrokeColor: fontColor,
                        color: fontColor,
                    }
                }
            }
        };

        let chartOptions: ChartConfiguration = {
            type: 'scatter',
            data: scatterChartData,
            options: options
        };

        return chartOptions;

        // chartOptions.options.scales.xAxes[0].display = true;
        // chartOptions.options.scales.yAxes[0].display = true;
    }

    /**
     * check that enough time has passed since last refresh
     * @param scope 
     */
    checkTimeout(scope: IRateTrackerElement) {
        let enable: boolean = false;
        if (this.rateLimiter > 0) {
            let timeCrt: number = new Date().getTime();
            if (scope != null) {
                if (timeCrt - scope.value > 100) {
                    enable = true;
                    scope.value = timeCrt;
                }
            } else {
                scope.value = timeCrt;
            }
        } else {
            enable = true;
        }
        return enable;
    }

    /**
     * translate chart by x, y, updates the intermediary data from the original data 
     * @param dx 
     * @param dy 
     * @param useDisplayData 
     */
    translateChart(dx: number, dy: number) {
        let enable: boolean = this.checkTimeout(this.rateTracker.translate);
        if (!enable) {
            return;
        }
        let n: number = this.objectChartDataFixed.length;
        for (let i = 0; i < n; i++) {
            this.objectChartDataTranslated[i] = this.translateVector(this.objectChartDataFixed[i].position, dx, dy);
        }
        // this.minimapChart.update();
    }

    setReversed(reversed: boolean) {
        this.reversed = reversed;
    }

    /**
     * rotate minimap by degrees
     * @param deg 
     * @param reversed 
     */
    rotateChartDegrees(deg: number) {
        try {
            if (this.reversed) {
                this.prevHeading = -GeometryUtils.degreesToRadians(deg);
                this.rotateChart(this.prevHeading, true, null);
            } else {
                this.prevHeading = GeometryUtils.degreesToRadians(deg);
                this.rotateChart(this.prevHeading, true, null);
            }
        } catch {
            console.log("chart rotate exception");
        }
    }

    rotateChartQuaternion(quaternions: IQuaternions) {
        try {
            this.prevQuaternion = quaternions;
            this.rotateChart(this.prevHeading, true, this.prevQuaternion);
        } catch {
            console.log("chart rotate exception");
        }
    }


    /**
    * rotate chart by alpha (radians), this updates the actual display data from the intermediary data
    * if quaternions are provided, rotate with quaternions instead
    * @param alpha 
    */
    rotateChart(alpha: number, fit: boolean, quaternions: IQuaternions) {
        if (!this.minimapChart) {
            console.warn("chart not initialized");
            return;
        }
        let enable: boolean = this.checkTimeout(this.rateTracker.rotate);
        if (!enable) {
            return;
        }
        let n: number = this.objectChartDataTranslated.length;
        // console.log("rotate chart objects: ", n);
        for (let i = 0; i < n; i++) {
            this.objectChartDataDisplay[i] = this.rotateVector(this.objectChartDataTranslated[i], alpha, quaternions);
            if (fit) {
                // this.boundVector(this.objectChartDataDisplay[i], this.minimap.fov, this.minimap.fov);
                this.boundVectorToCircle(this.objectChartDataDisplay[i], this.minimapOptions.far - this.minimapOptions.pointRadius * 2);
            }
        }
        this.minimapChart.update();
    }


    /**
     * rotate vector by alpha (radians)
     * @param x 
     * @param y 
     * @param alpha 
     */
    rotateVector(vector: IObjectPosition, alpha: number, quaternions: IQuaternions) {
        let vector2: IObjectPosition = Object.assign({}, vector);
        if (!quaternions) {
            // rotate via euler angles
            let cosa = Math.cos(alpha);
            let sina = Math.sin(alpha);
            vector2.x = vector.x * cosa - vector.y * sina;
            vector2.y = vector.x * sina + vector.y * cosa;
        } else {
            // rotate via quaternions
            let qv: number[] = GeometryUtils.getQuatVector(quaternions);
            // let vect: number[] = [vector.x, vector.y, 0];
            let vect: number[] = [0, vector.x, vector.y];
            let vect2: number[] = GeometryUtils.rotateVectorByQuaternion(vect, qv);
            vector2.x = vect2[1];
            vector2.y = vect2[2];
            console.log("rotate quat: ", vect, qv, vect2);
        }
        return vector2;
    }

    translateVector(vector: IObjectPosition, dx: number, dy: number) {
        let vector2: IObjectPosition = Object.assign({}, vector);
        vector2.x = vector.x + dx;
        vector2.y = vector.y + dy;
        return vector2;
    }

    /**
    * x, y limits (min, max)
    * @param vector 
    * @param xlim 
    * @param ylim 
    */
    boundVector(vector: IObjectPosition, xlim: number, ylim: number) {
        if (vector.x > xlim) {
            vector.x = xlim;
        }
        if (vector.x < -xlim) {
            vector.x = -xlim;
        }
        if (vector.y > ylim) {
            vector.y = ylim;
        }
        if (vector.y < -ylim) {
            vector.y = -ylim;
        }
    }

    /**
     * x, y limit (radius)
     * @param vector 
     * @param radius 
     */
    boundVectorToCircle(vector: IObjectPosition, radius: number) {
        let dist = Math.sqrt(vector.x * vector.x + vector.y * vector.y);
        if (dist > radius) {
            vector.x = vector.x * radius / dist;
            vector.y = vector.y * radius / dist;
        }
    }


    checkExisting(id: string) {
        let existing: boolean = this.objectChartDataFixed.find(e => e.uid === id) != null;
        return existing;
    }

    /**
     * add POI to minimap at coordinates relative to the ORIGIN
     * @param dx 
     * @param dz 
     */
    addPOIToMinimap(id: string, code: number, dx: number, dz: number) {
        console.log("MINIMAP/add poi: " + id + " @" + dx + ", " + dz);
        if (this.checkExisting(id)) {
            console.warn("already exists on minimap");
            return;
        }
        let object: IObjectChartData = {
            uid: id,
            // position: {
            //     x: dx,
            //     y: -dz
            // }
            position: {
                x: dx,
                y: dz,
                code: code,
                uid: id
            }
        };
        this.objectChartDataFixed.push(object);
        this.objectChartDataTranslated.push(object.position);
        this.objectChartDataDisplay.push(object.position);

        // apply previous rotation to initialize the position instantly
        this.rotateChart(this.prevHeading, true, this.prevQuaternion);
    }


    // /**
    //  * add poi to minimap with absolute coords
    //  * transpose to XY relative to the INITIAL coords (initial GPS position)
    //  * @param id 
    //  * @param position 
    //  */
    // addGeoToMinimap(id: string, position: ILatLng){

    // }

    /**
     * remove POI from minimap
     * remove from display, translated and data buffers
     * @param index 
     */
    removePOIFromMinimapByUid(uid: string) {
        console.log("MINIMAP/remove: ", uid);
        if (!this.minimapChart) {
            console.warn("chart not initialized");
            return;
        }
        console.log("initial POI: ", this.objectChartDataDisplay);

        for (let i = 0; i < this.objectChartDataDisplay.length; i++) {
            let ddata: IObjectPosition = this.objectChartDataDisplay[i];
            if (ddata.uid === uid) {
                this.objectChartDataDisplay.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < this.objectChartDataTranslated.length; i++) {
            let fUid: string = this.objectChartDataTranslated[i].uid;
            if (fUid === uid) {
                this.objectChartDataTranslated.splice(i, 1);
                break;
            }
        }

        for (let i = 0; i < this.objectChartDataFixed.length; i++) {
            let fUid: string = this.objectChartDataFixed[i].uid;
            if (fUid === uid) {
                this.objectChartDataFixed.splice(i, 1);
                break;
            }
        }

        this.minimapChart.update();
        console.log("MINIMAP/removed: ", uid);
        console.log("POI after remove: ", this.objectChartDataDisplay);
    }

    /**
     * check if display buffer contains objects that are no longer in data buffer
     */
    removeMissingObjects() {
        let removeIndexList: number[] = [];
        for (let i = 0; i < this.objectChartDataDisplay.length; i++) {
            let ddata: IObjectPosition = this.objectChartDataDisplay[i];
            let dUid: string = ddata.uid;
            let found: boolean = false;
            for (let j = 0; j < this.objectChartDataFixed.length; j++) {
                let fUid: string = this.objectChartDataFixed[j].uid;
                if (dUid === fUid) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                removeIndexList.push(i);
                console.warn("missing object to be removed: ", dUid);
            }
        }

        for (let i = 0; i < removeIndexList.length; i++) {
            this.objectChartDataFixed.splice(i, 1);
        }
    }

    /**
     * clear all points on the minimap
     * do not replace the buffer as it breaks sync
     */
    clearMinimap() {
        console.log("MINIMAP/clear");
        if (!this.minimapChart) {
            console.warn("chart not initialized");
            return;
        }
        this.objectChartDataFixed = [];
        this.objectChartDataTranslated = [];
        this.minimapChart.clear();
        this.minimapChart.update();
        let n: number = this.objectChartDataDisplay.length;
        for (let i = 0; i < n; i++) {
            this.objectChartDataDisplay.pop();
        }
    }

    removeMinimap() {
        console.log("MINIMAP/destroy");
        if (!this.minimapChart) {
            console.warn("chart not initialized");
            return;
        }
        this.minimapChart.destroy();
        this.minimapChart = null;
    }
}
