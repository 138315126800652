<div *ngIf="fab">
  <div *ngIf="!iconOnly">
    <ion-button ion-fab [ngClass]="!state ? fabBtnDefaultClass : fabBtnHighClass" [disabled]="disabled"
      (click)="clicked(0)">
      <span [ngClass]="getTextClass()">{{name}}</span>
      <icon-wrapper class="slot-icon-padding" slot="start" [icon]="icon" [custom]="customIcon"
        [huge]="false"></icon-wrapper>
    </ion-button>
  </div>
  <div *ngIf="iconOnly">
    <ion-button ion-fab [ngClass]="!state ? fabBtnDefaultClass : fabBtnHighClass" [disabled]="disabled"
      (click)="clicked(0)">
      <icon-wrapper slot="icon-only" [icon]="icon" [custom]="customIcon" [huge]="huge"></icon-wrapper>
    </ion-button>
  </div>
</div>
<div *ngIf="!fab">
  <div *ngIf="!iconOnly">
    <ion-button [ngClass]="!state ? btnDefaultClass : btnHighClass" [disabled]="disabled" (click)="clicked(0)">
      <span [ngClass]="getTextClass()">{{name}}</span>
      <icon-wrapper class="slot-icon-padding" slot="start" [icon]="icon" [custom]="customIcon" [huge]="false">
      </icon-wrapper>
    </ion-button>
  </div>
  <div *ngIf="iconOnly">
    <ion-button [ngClass]="!state ? btnDefaultClass : btnHighClass" [disabled]="disabled" (click)="clicked(0)">
      <icon-wrapper slot="icon-only" [icon]="icon" [custom]="customIcon" [huge]="huge"></icon-wrapper>
    </ion-button>
  </div>
</div>