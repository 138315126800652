import { Injectable } from "@angular/core";
import { ResourceManager } from 'src/app/classes/general/resource-manager';

import { BehaviorSubject } from 'rxjs';
import { MQTTService } from "../../telemetry/mqtt.service";
import { EMQTTStatusKeys } from "../../telemetry/def";
import { ILatLng } from "src/app/classes/def/map/coords";

export interface IVirtualLocation {
    coords: ILatLng;
    speed: number;
    heading: number;
    source: number;
    // enable marker update on GMAP
    updateMarker: boolean;
    scanRequired?: boolean;
}

export enum EVirtualLocationSource {
    gps = 1,
    drone = 2,
    override = 3,
    droneDropoff = 4
}

@Injectable({
    providedIn: 'root'
})
export class VirtualPositionService {

    observable = {
        virtualUserPosition: null
    };

    virtualPositionCrt: IVirtualLocation;
    lastDronePosition: IVirtualLocation;
    lastGpsPosition: IVirtualLocation;

    currentPrimaryLocationSource: number = EVirtualLocationSource.gps;

    constructor(
        public mqttService: MQTTService
    ) {
        console.log("virtual position service created");
        ResourceManager.initBsubObj(this.observable);
    }

    setPrimaryLocationSource(source: number) {
        this.currentPrimaryLocationSource = source;
    }

    getPrimaryLocationSource() {
        return this.currentPrimaryLocationSource;
    }

    /**
    * check context: GPS/drone nav
    * @param data 
    * @returns 
    */
    checkNavContext(data: IVirtualLocation, strict: boolean) {
        let valid: boolean = false;
        if (data != null && data.coords != null) {
            // check context: GPS/drone nav
            if (strict) {
                let primaryLocationSource: number = this.getPrimaryLocationSource();
                valid = primaryLocationSource === data.source;
                // check overrides (special sources)
                if ([EVirtualLocationSource.override, EVirtualLocationSource.droneDropoff].indexOf(primaryLocationSource) !== -1) {
                    valid = true;
                }
            } else {
                valid = true;
            }
        }
        return valid;
    }

    placeUserAtLocation(coordinates: ILatLng, scanRequired: boolean) {
        let loc: IVirtualLocation = {
            coords: coordinates,
            speed: 0,
            heading: 0,
            source: EVirtualLocationSource.droneDropoff,
            updateMarker: true,
            scanRequired: scanRequired
        };
        this.updateVirtualPosition(loc);
    }

    /**
     * update virtual position (position of interest for explore like activities)
     * now also used in move activity (switch between running and drone mode)
     * @param loc 
     */
    updateVirtualPosition(loc: IVirtualLocation) {
        console.log("update virtual position: ", loc.coords);
        this.virtualPositionCrt = loc;
        switch (loc.source) {
            case EVirtualLocationSource.drone:
                this.lastDronePosition = loc;
                this.mqttService.updateStatus(EMQTTStatusKeys.droneMarker, loc.coords);
                break;
            case EVirtualLocationSource.gps:
                this.lastGpsPosition = loc;
                this.mqttService.updateStatus(EMQTTStatusKeys.location, loc.coords);
                break;
            default:
                break;
        }
        this.observable.virtualUserPosition.next(loc);
    }

    triggerVirtualPositionUpdateCrt() {
        this.observable.virtualUserPosition.next(this.virtualPositionCrt);
    }

    waitForDroneTakeOffCapture() {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve(true);
            }, 3000);
        })
    }

    getCurrentPosition(): ILatLng {
        if (this.virtualPositionCrt) {
            return this.virtualPositionCrt.coords;
        }
        return null;
    }

    getLastDronePosition(): ILatLng {
        if (this.lastDronePosition) {
            return this.lastDronePosition.coords;
        }
        return null;
    }

    resetVirtualPosition() {
        this.observable.virtualUserPosition.next(null);
    }

    /**
     * watch virtual position (position of interest for explore like activities)
     */
    watchVirtualPosition(): BehaviorSubject<IVirtualLocation> {
        return this.observable.virtualUserPosition;
    }
}
