<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="cancel()" [loading]="loading" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<!-- <ion-content class="custom-modal-style">

  <div [class]="wrapperClass" fxLayout="column" [ngClass]="vs.transparent?'view-frame-minimal':'view-frame-minimal theme-background'"> -->
<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <div class="padding-s" fxLayout="column">

    <!-- scroll-height-frame-btn-1 -->
    <!-- <div class="scroll-height-frame-btn-1"> -->
    <div class="scrollbar-y scroll-content-modal-1 padding-s" fxLayout="column">
      <safe-html-renderer [unsafeHtmlContent]="description"
        innerClass="info-text-m description-frame text-color-primary text-left"></safe-html-renderer>
      <div *ngIf="photoUrl" class="padding-s center-text">
        <!-- <img [src]="photoUrl" class="border-radius"> -->
        <responsive-img [photoUrl]="photoUrl" class="border-radius"></responsive-img>
      </div>
    </div>
    <!-- </div> -->

    <div fxFlex></div>
    <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-row *ngIf="buttonCount === 1">
          <ion-col size="12">
            <ion-button class="action-button-fill button-color-accent" [disabled]="buttons[0].disabled"
              (click)="onButtonClick(buttons[0].code)">
              <span class="button-font-size-m">
                {{buttons[0].name}}
              </span>
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="buttonCount === 2">
          <ion-col size="6">
            <ion-button class="action-button-fill button-color-alternate" [disabled]="buttons[0].disabled"
              (click)="onButtonClick(buttons[0].code)">
              <span class="button-font-size-m">
                {{buttons[0].name}}
              </span>
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button class="action-button-fill button-color-accent" [disabled]="buttons[1].disabled"
              (click)="onButtonClick(buttons[1].code)">
              <span class="button-font-size-m">
                {{buttons[1].name}}
              </span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</modal-container>