<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="!enableBackButton" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()"
    rightButton="settings" [rightIcon]="true" (rightClick)="options()" [title]="title" [loading]="!loaded">
  </leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <div class="view-frame theme-background" fxLayout="column">
    <div *ngIf="withDetails">
      <ion-grid class="full-w padding-top-s theme-border-bottom">
        <ion-row *ngIf="leaderboardName">
          <ion-col size="12" class="text-center">
            <span class="details fab-display-top bold text-color-primary">{{leaderboardName |
              uppercase}}</span>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="info">
          <ion-col size="12" class="text-center">
            <span class="info-text-s bold text-color-primary">{{info.timestamp | myDate2}}
              {{info.timestamp | myTime}}</span>
          </ion-col>
        </ion-row>
        <ion-row *ngIf="infoHTML">
          <ion-col size="12" class="text-center">
            <div [innerHTML]="infoHTML" class="info-text-m bold description-frame text-color-primary center-text">
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <nav-bar class="margin-top-s theme-border-bottom" *ngIf="!withDetails" [slidesPerView]="categoryTabsGroupCount"
      [(selectedTabId)]="mode" [loadData]="initLoaded" [navBarItems]="navBarItems"
      (select)="selectCategoryFromNavItem($event)">
    </nav-bar>

    <div *ngIf="emptyResult" class="padding-s">
      <span class="info-text-m bold text-color-primary">No registered users</span>
    </div>

    <!-- scroll-type-frame-list -->
    <!-- (swipe)="swipeEvent($event)" -->
    <div class="scrollbar-y">
      <ion-list *ngIf="board && loaded" class="list-padding">
        <ion-item *ngFor="let leader of board" class="list-item list-item-padding transparent-bg">
          <leader-card *ngIf="!mpCard" [user]="leader" [selected]="leader.selected" tappable
            (click)="goToLeader(leader)" class="full-w theme-border-bottom-thin" [mode]="mode"></leader-card>
          <mp-leader-card *ngIf="mpCard" [user]="leader" tappable (click)="goToLeader(leader)"
            class="full-w theme-border-bottom-thin" [mode]="mode">
          </mp-leader-card>
        </ion-item>
      </ion-list>
    </div>
    <div fxFlex></div>
    <div class="padding-horizontal-s theme-border-top margin-bottom-xs">
      <leplace-paginator [pagination]="pagination" (onSwitch)="switchPage($event)"></leplace-paginator>
    </div>
  </div>
</ion-content>