
/// <reference types="@types/google.maps" />
import { IMarkerTheme, ThemeColors, EFeatureColor } from '../../classes/def/app/theme';
import { IPlaceMarkerContent, IMarkerOptionsExtended, IShowMarkerOptions, IMarkerInternal, MarkerCapacitorExt, CircleCapacitorExt, PolylineCapacitorExt } from '../../classes/def/map/map-data';
import { MarkerUtils, ICanvasMarkerContainer, IMarkerUpdateResult, EMarkerUpdateCode } from './marker-utils';
import { EMapShapes, EMarkerTypes, ISetThisMarkerOptions } from '../../classes/def/map/markers';
import { EMarkerIcons } from '../../classes/def/app/icons';
import { GeometryUtils } from '../utils/geometry-utils';
import { ResourceManager } from '../../classes/general/resource-manager';
import { ImageLoaderTSService } from '../media/image-loader-ts';
import { IMoveMapOptions } from 'src/app/classes/def/map/interaction';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { SleepUtils } from '../utils/sleep-utils';
import { SyncService } from '../app/utils/sync';
import { BenchUtils, IBenchObject, IBenchReportContainer } from '../utils/bench-utils';
import { SettingsManagerService } from '../general/settings-manager';
// import { setDPI, drawMarkerText } from '../../classes/map/canvas-marker';

import { GoogleMap, Circle, Marker, Polyline } from 'capacitor-plugin-google-maps';
import { ILatLng } from 'src/app/classes/def/map/coords';
import { timer } from 'rxjs';

export interface IMarkerContainerMobile {
    marker: any;
    mox: IMarkerOptionsExtended<MarkerCapacitorExt>;
}

export interface ISingleMarkerMobile {
    internal: IMarkerInternal;
    container: IMarkerContainerMobile;
}

export interface IArrayMarkerMobile {
    internal: IMarkerInternal;
    container: IMarkerContainerMobile[];
}

export interface ISingleMarkerCollectionMobile {
    [name: string]: ISingleMarkerMobile;
}
export interface IArrayMarkerCollectionMobile {
    [name: string]: IArrayMarkerMobile;
}

const MARKER_UPDATE_TIMEOUT_MOBILE = 50;
const MAP_INIT_TIMEOUT_MOBILE = 3000;
const MARKER_INIT_COUNTER_MOBILE = 2;


class Mutex {
    private queue: (() => void)[] = [];
    private locked = false;

    async lock() {
        return new Promise<void>(resolve => {
            if (this.locked) {
                this.queue.push(resolve);
            } else {
                this.locked = true;
                resolve();
            }
        });
    }

    unlock() {
        if (this.queue.length > 0) {
            const nextResolve = this.queue.shift();
            if (nextResolve) {
                nextResolve();
            }
        } else {
            this.locked = false;
        }
    }
}



export class MarkersMobileCapacitor {
    singleMarkers: ISingleMarkerCollectionMobile = {};
    multiMarkers: IArrayMarkerCollectionMobile = {};

    // singleMarkersTimestamps = {};
    // multiMarkersTimestamps = {};
    globalInitTimestamp = null;

    markerTypesNames: string[];

    markerIcons;

    map: GoogleMap;

    theme: IMarkerTheme;
    themeLoaded: boolean = false;

    testAccuracy: boolean = false;

    loopDelay: number = 10;

    /**
     * master lock
     */
    enable: boolean = false;

    markerMutex: Mutex;

    timers = {
        markerRefreshLoop: null
    };

    constructor(
        public imageLoaderTS: ImageLoaderTSService,
        public syncService: SyncService
    ) {
        this.theme = {
            name: "default",
            lineColor: ThemeColors.theme.standard.lineColor,
            markerFrameColor: ThemeColors.theme.standard.lineColor
        };

        this.markerMutex = new Mutex();
    }

    /**
     * master lock
     * @param enable 
     */
    setEnabled(enable: boolean) {
        this.enable = enable;
        if (GeneralCache.os === EOS.ios) {
            // fix partial rendering and rendering errors in native plugin
            if (enable) {
                this.runMarkerRefreshLoop();
            } else {
                this.stopMarkerRefreshLoop();
            }
        }
    }

    setMap(map: GoogleMap) {
        console.log("mobile setMap");
        this.map = map;
    }

    setTheme(theme: IMarkerTheme) {
        this.theme = theme;
        if (!this.theme.markerFrameColor) {
            this.theme.markerFrameColor = ThemeColors.theme.standard.lineColor;
        }
        this.themeLoaded = true;
    }

    getMap() {
        return this.map;
    }

    setOptions(opts) {
        this.markerIcons = opts.markerIcons;
    }

    runMarkerRefreshLoop() {
        if (this.timers.markerRefreshLoop != null) {
            console.warn("marker refresh loop already running");
            return;
        }
        let timer1 = timer(0, 1000);
        this.timers.markerRefreshLoop = timer1.subscribe(async () => {
            try {
                console.log("marker refresh loop tick");
                for (let marker of Object.values(this.singleMarkers)) {
                    if (marker && marker.container) {
                        await this.refreshMarker(marker.container);
                    }
                }
                for (let markers of Object.values(this.multiMarkers)) {
                    if (markers) {
                        for (let mc of markers.container) {
                            if (mc) {
                                await this.refreshMarker(mc);
                            }
                        }
                    }
                }
            } catch (err) {
                console.error(err);
            }
        });
    }

    async refreshMarker(mc: IMarkerContainerMobile) {
        try {
            let coordsUpdated: ILatLng = null;
            if (mc && mc.mox) {
                switch (mc.mox.shape) {
                    case EMapShapes.marker:
                        coordsUpdated = GeometryUtils.getRandomPointInRadius(mc.mox.markerOptions.coordinate, 0.01, 0.02);
                        await this.map.updateMarkerPosition(mc.mox.id, coordsUpdated)
                        break;
                    case EMapShapes.circle:
                        coordsUpdated = GeometryUtils.getRandomPointInRadius(mc.mox.markerOptions.coordinate, 0.01, 0.02);
                        await this.map.updateMarkerPosition(mc.mox.id, coordsUpdated)
                        break;
                    case EMapShapes.polyline:

                        break;
                    case EMapShapes.polygon:

                        break;
                }
            }
        } catch (err) {
            console.error(err);
        }
    }

    stopMarkerRefreshLoop() {
        this.timers.markerRefreshLoop = ResourceManager.clearSub(this.timers.markerRefreshLoop);
    }

    /**
     * check if the marker container exists
     * prevent errors
     * @param layer 
     * @param multi 
     */
    private checkMarkerLayerContainer(layer: string, multi: boolean) {
        if (multi) {
            if (!(this.multiMarkers[layer] && this.multiMarkers[layer].container)) {
                return false;
            }
        } else {
            if (!(this.singleMarkers[layer] && this.singleMarkers[layer].container)) {
                return false;
            }
        }
        return true;
    }

    private getArrayMarkerIndexByUid(layer: string, uid: string) {
        if (!this.checkMarkerLayerContainer(layer, true)) {
            return -1;
        }
        for (let i = 0; i < this.multiMarkers[layer].container.length; i++) {
            if (this.multiMarkers[layer].container[i].mox.markerContent.uid === uid) {
                return i;
            }
        }
        return -1;
    }

    /**
     * updates a marker from array (multi marker), set gps position
     * does not handle the update checks (init, timeout), should be handled by the caller
     * @param layer 
     * @param data 
     */
    updateArrayMarkerCore(layer: string, data: IPlaceMarkerContent) {
        let promise = new Promise(async (resolve, reject) => {
            try {
                let index: number = this.getArrayMarkerIndexByUid(layer, data.uid);
                let mk: MarkerCapacitorExt = null;
                let container = this.multiMarkers[layer].container[index];

                if (index !== -1) {
                    mk = container.marker;
                }

                if (container.mox && container.mox.markerOptions) {
                    container.mox.markerOptions.coordinate = new ILatLng(data.location.lat, data.location.lng);
                } else {
                    console.warn("coordinates undefined in marker options");
                }

                if (mk != null) {
                    let refreshRequired = container.mox != null ? this.isRefreshRequired(container.mox.markerContent, data) : false;
                    data.requiresRefresh = false;
                    // refreshRequired = true;                    
                    if (refreshRequired) {
                        console.log("refresh required for marker: ", mk?.id);
                        await this.removeArrayMarkerCore(layer, data);
                        await this.insertArrayMarkerCore(layer, data);
                        container.mox.markerContent = Object.assign({}, data);
                        container.mox.markerContent.location = Object.assign({}, data.location);
                        resolve(mk);
                    } else {
                        let formatLabel = MarkerUtils.formatAddLabels(null, data.label, data.addLabel, data.addLabel2);
                        let label: string = formatLabel.label;
                        // check update needed
                        let dataPrev: IPlaceMarkerContent = container.mox.markerContent;
                        let formatLabelCrt = MarkerUtils.formatAddLabels(null, dataPrev.label, dataPrev.addLabel, dataPrev.addLabel2);
                        console.log("marker update: " + mk.id + " location: ", dataPrev.location, Object.assign({}, dataPrev.location), data.location, Object.assign({}, data.location));

                        if (data.location && dataPrev.location &&
                            (data.updateTrigger || data.location.lat !== dataPrev.location.lat || data.location.lng !== dataPrev.location.lng || label !== formatLabelCrt.label)
                        ) {
                            switch (data.shape) {
                                case EMapShapes.marker:
                                    // await this.map.updateMarker(mk.id, {
                                    //     coordinate: data.location,
                                    //     title: label
                                    // });
                                    await this.map.updateMarkerPosition(mk.id, data.location);
                                    break;
                                case EMapShapes.circle:
                                    await this.map.setCircleCenter(mk.id, data.location);
                                    break;
                                default:
                                    break;
                            }
                        } else {
                            console.log("nothing to update for marker: ", mk.id);
                        }
                        data.updateTrigger = false;

                        dataPrev.location = new ILatLng(data.location.lat, data.location.lng);
                        dataPrev.label = label;
                        dataPrev.updateTrigger = false;
                        resolve(mk);
                    }
                } else {
                    reject(new Error("could not update marker position"));
                    return;
                }
            } catch (err) {
                console.error(err);
                reject(new Error("could not update marker"));
                return;
            }
        });
        return promise;
    }

    private isRefreshRequired(currentContent: IPlaceMarkerContent, newContent: IPlaceMarkerContent): boolean {
        return (
            currentContent.label !== newContent.label ||
            currentContent.lockedForUser !== newContent.lockedForUser ||
            currentContent.locked !== newContent.locked ||
            newContent.requiresRefresh // use this if marker is passed by reference (cannot detect changes by attributes)
        );
    }

    /**
     * removes the marker from the map and from the array
     * @param layer 
     * @param data 
     */
    private removeArrayMarkerCore(layer: string, data: IPlaceMarkerContent) {
        let index: number = this.getArrayMarkerIndexByUid(layer, data.uid);
        return this.clearArrayMarkerByIndex(layer, index);
    }


    /**
     * get extended marker options 
     * mobile specific
     * @param data 
     */
    private getMox(data: IPlaceMarkerContent) {
        let mox: IMarkerOptionsExtended<MarkerCapacitorExt> = {
            markerOptions: {
                id: null,
                coordinate: data.location,
                iconUrl: data.icon,
                iconSize: {
                    width: 120,
                    height: 120
                },
                zIndex: data.zindex,
                priority: data.priority,
                renderFix: false,
                title: data.label,
                // snippet: data.title
                // markerClick: ()=>{
                //     console.log("marker click");
                // }
                draggable: data.drag
            },
            id: null,
            shape: data?.shape,
            markerContent: data,
            customIcon: null,
            callback: data.callback,
            dragCallback: data.dragCallback
        };
        return mox;
    }


    /**
     * init multi marker
     * @param markers 
     * @param layer 
     */
    private initMultiMarker(markers: IArrayMarkerCollectionMobile, layer: string) {
        console.log("init multi marker: " + layer);
        if (markers[layer] && markers[layer].internal) {
            return true;
        } else {
            markers[layer] = {
                container: [],
                internal: {
                    timestamp: new Date().getTime(),
                    initCounter: MARKER_INIT_COUNTER_MOBILE,
                    layerInitialized: false,
                    syncInProgress: false,
                    visibleLayer: true,
                    animateTimeout: null
                }
            };
            return true;
        }
    }

    /**
     * check single marker initialized
     * @param markers 
     * @param layer 
     */
    private initSingleMarker(markers: ISingleMarkerCollectionMobile, layer: string) {
        markers[layer] = this.getDefaultSingleMarkerContainer();
    }

    /**
     * get default single marker container
     */
    private getDefaultSingleMarkerContainer() {
        let m: ISingleMarkerMobile = {
            container: {
                marker: null,
                mox: null
            },
            internal: {
                timestamp: new Date().getTime(),
                initCounter: 3,
                layerInitialized: false,
                syncInProgress: false,
                visibleLayer: true,
                animateTimeout: null
            }
        };
        return m;
    }


    /**
     * check single marker initialized
     * @param markers 
     * @param layer 
     */
    private checkSingleMarker(markers: ISingleMarkerCollectionMobile, layer: string) {
        if (markers[layer] && markers[layer].container && markers[layer].internal) {
            return true;
        }
        return false;
    }

    /**
     * check multi marker initialized
     * @param markers 
     * @param layer 
     */
    private checkMultiMarker(markers: IArrayMarkerCollectionMobile, layer: string) {
        // console.log("check multi marker: ", markers[layer]);
        if (this.checkMarkerLayerContainer(layer, true) && markers[layer].internal) {
            return true;
        }
        return false;
    }


    /**
     * insert a marker into the array
     * does not handle the update checks (init, timeout), should be handled by the caller
     * @param layer 
     * @param markerContent 
     */
    private insertArrayMarkerCore(layer: string, markerContent: IPlaceMarkerContent): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {

            // basic error check
            if (!(this.multiMarkers[layer])) {
                reject(new Error("marker array not initialized"));
                return;
            }

            this.showMarkerMobile(layer, markerContent, true).then((res: boolean) => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * check if the layer exists
     */
    checkLayer(layer: string, multi: boolean) {
        let defined: boolean = true;
        if (!multi) {
            if (!this.singleMarkers[layer]) {
                defined = false;
            }
        } else {
            if (!this.multiMarkers[layer]) {
                defined = false;
            }
        }
        return defined;
    }

    /**
     * show a single marker
     * marker/circle
     * plain/canvas
     * @param layer 
     * @param data 
     * @param multi 
     */
    private showMarkerMobile(layer: string, data: IPlaceMarkerContent, multi: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (!data) {
                reject(new Error("sync marker no data"));
                return;
            }

            let mox: IMarkerOptionsExtended<MarkerCapacitorExt> = this.getMox(data);

            if (!this.checkLayer(layer, multi)) {
                reject(new Error("undefined layer: " + layer));
                return;
            }

            switch (data.shape) {
                case EMapShapes.marker:
                    switch (data.mode) {
                        case EMarkerTypes.plain:
                            // size used here e.g. for coins
                            let icon = null;
                            if (data.radius !== null) {
                                icon = {
                                    url: data.icon,
                                    size: {
                                        width: data.radius,
                                        height: data.radius
                                    }
                                };
                            } else {
                                icon = data.icon;
                            }
                            mox.markerOptions.iconUrl = icon;

                            this.showPlainMarkerMobile(mox).then((marker: MarkerCapacitorExt) => {
                                // console.log("marker set");
                                try {
                                    if (!multi) {
                                        this.singleMarkers[layer].container.marker = marker;
                                        this.singleMarkers[layer].container.mox = mox;
                                        this.singleMarkers[layer].internal.layerInitialized = true;
                                    } else {
                                        this.multiMarkers[layer].container.push({
                                            marker: marker,
                                            mox: mox
                                        });
                                    }
                                } catch (err) {
                                    console.error(layer, err);
                                    // the layer was cleared while the marker was being placed
                                    // remove the marker
                                    this.clearMarker(marker, mox.markerContent?.shape);
                                }
                                resolve(true);
                            }).catch((err: Error) => {
                                reject(err);
                            });
                            break;
                        case EMarkerTypes.canvasFrame:
                        case EMarkerTypes.canvasPlain:
                        case EMarkerTypes.canvasPlainCenter:
                            // set default arrow size for canvas circular marker
                            let mh = 20;
                            let mw = 20;
                            if (!data.radius) {
                                data.radius = 120;
                            } else {
                                mh = Math.floor(mh * data.radius / 120);
                                mw = Math.floor(mw * data.radius / 120);
                            }
                            let opts: IShowMarkerOptions = {
                                mh,
                                mw,
                                width: data.radius,
                                type: data.layer,
                                circularFrame: data.mode === EMarkerTypes.canvasFrame,
                                color: data.color,
                                labelFrameColor: data.labelFrameColor,
                                labelTextColor: data.labelTextColor,
                                faded: data.locked
                            };
                            opts.height = opts.width + opts.mh;

                            // this.multiMarkersData[data.type].push(mox);
                            this.showCanvasMarkerMobile(mox, opts).then((marker: MarkerCapacitorExt) => {
                                // console.log(this.multiMarkers);
                                try {
                                    if (!multi) {
                                        this.singleMarkers[layer].container.marker = marker;
                                        this.singleMarkers[layer].container.mox = mox;
                                        this.singleMarkers[layer].internal.layerInitialized = true;
                                    } else {
                                        this.multiMarkers[layer].container.push({
                                            marker: marker,
                                            mox: mox
                                        });
                                    }
                                    // // TEST ACCURACY
                                    if (this.testAccuracy) {
                                        mox.markerOptions.iconUrl = EMarkerIcons.testA;
                                        this.showPlainMarkerMobile(mox).then(() => {

                                        }).catch(() => {

                                        });
                                    }
                                } catch (err) {
                                    console.error(layer, err);
                                    // the layer was cleared while the marker was being placed
                                    // remove the marker
                                    this.clearMarker(marker, mox.markerContent?.shape);
                                }
                                resolve(true);
                            }).catch((err: Error) => {
                                console.error(err);
                                reject(err);
                            });
                            break;
                        default:
                            resolve(false);
                            break;
                    }
                    break;
                case EMapShapes.circle:
                    this.showCircleMobile(mox).then((circle: CircleCapacitorExt) => {
                        if (!multi) {
                            this.singleMarkers[layer].container.marker = circle;
                            this.singleMarkers[layer].container.mox = mox;
                            this.singleMarkers[layer].internal.layerInitialized = true;
                        } else {
                            this.multiMarkers[layer].container.push({
                                marker: circle,
                                mox: mox
                            });
                        }
                        resolve(true);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                    break;
            }
        });
        return promise;
    }

    /**
     * sync external data buffer with marker buffer
     * should be all the same type/layer e.g. places
     * @param data 
     * @param sync add markers in sync (ios) vs all at once (android)
     */
    syncMarkerArray(layer: string, data: IPlaceMarkerContent[], sync: boolean) {
        let promise = new Promise((resolve, reject) => {

            let debug: boolean = false;

            if (!data) {
                reject(new Error("data undefined"));
                return;
            }

            if (!this.checkMultiMarker(this.multiMarkers, layer)) {
                this.initMultiMarker(this.multiMarkers, layer);
            }

            // USE GLOBAL LOCK ON THE ENTIRE MARKER ARRAY SO THAT MARKERS ARE NOT DOUBLED

            if (this.multiMarkers[layer].internal.syncInProgress) {
                reject(new Error("layer sync already in progress: " + layer));
                return;
            }

            // the layer is initialized but the update is too fast
            if (this.multiMarkers[layer].internal.layerInitialized && !this.checkInitTimeout(this.multiMarkers[layer].internal.timestamp)) {
                reject(new Error("marker update too fast"));
                return;
            }

            this.multiMarkers[layer].internal.syncInProgress = true;

            let multiMarkers: IPlaceMarkerContent[] = this.multiMarkers[layer].container.map(c => c.mox.markerContent);

            let promisesU: Promise<boolean>[] = [];
            let promisesUSync: (() => Promise<boolean>)[] = [];
            let promisesI: Promise<boolean>[] = [];
            let promisesISync: (() => Promise<boolean>)[] = [];

            let added: number = 0;
            let updated: number = 0;
            let removed: number = 0;

            let removeArray: IPlaceMarkerContent[] = [];
            let updateArray: IPlaceMarkerContent[] = [];
            let insertArray: IPlaceMarkerContent[] = [];
            let update: boolean = false;
            // check update or insert
            for (let i = 0; i < data.length; i++) {
                if (!data[i]) {
                    continue;
                }
                update = false;
                for (let j = 0; j < multiMarkers.length; j++) {
                    if (multiMarkers[j].uid === data[i].uid) {
                        update = true;
                        break;
                    }
                }
                if (update) {
                    updateArray.push(data[i]);
                } else {
                    insertArray.push(data[i]);
                }
            }
            // check remove
            let remove: boolean = true;
            for (let i = 0; i < multiMarkers.length; i++) {
                remove = true;
                for (let j = 0; j < data.length; j++) {
                    if (!data[j]) {
                        continue;
                    }
                    if (multiMarkers[i].uid === data[j].uid) {
                        remove = false;
                        break;
                    }
                }
                if (remove) {
                    // console.log("remove, locksync: " + multiMarkers[i].lockSync);
                    if (!multiMarkers[i].lockSync) {
                        removeArray.push(multiMarkers[i]);
                    } else {
                        // multiMarkers[i].visible = true;
                        // updateArray.push(multiMarkers[i]);
                    }
                }
            }

            for (let i = 0; i < updateArray.length; i++) {
                updated += 1;

                if (sync) {
                    promisesUSync.push(() => {
                        return new Promise((resolve) => {
                            this.updateArrayMarkerCore(layer, updateArray[i]).then(async () => {
                                await SleepUtils.sleep(this.loopDelay);
                                resolve(true);
                            }).catch(() => {
                                resolve(false);
                            });
                        });
                    });
                } else {
                    promisesU.push(new Promise<boolean>((resolve) => {
                        this.updateArrayMarkerCore(layer, updateArray[i]).then(() => {
                            resolve(true);
                        }).catch(() => {
                            resolve(false);
                        });
                    }));
                }
            }

            let promiseUpdate: Promise<any>;

            if (updateArray.length > 0) {
                if (sync) {
                    promiseUpdate = this.syncService.runSync(promisesUSync);
                } else {
                    promiseUpdate = Promise.all(promisesU);
                }
            } else {
                promiseUpdate = Promise.resolve(true);
            }

            // update done
            promiseUpdate.then(() => {

                // insert
                for (let i = 0; i < insertArray.length; i++) {
                    added += 1;

                    if (sync) {
                        promisesISync.push(() => {
                            return new Promise((resolve) => {
                                this.insertArrayMarkerCore(layer, insertArray[i]).then(async () => {
                                    await SleepUtils.sleep(this.loopDelay);
                                    resolve(true);
                                }).catch(() => {
                                    resolve(false);
                                });
                            })
                        });
                    } else {
                        promisesI.push(new Promise((resolve) => {
                            this.insertArrayMarkerCore(layer, insertArray[i]).then(() => {
                                resolve(true);
                            }).catch(() => {
                                resolve(false);
                            });
                        }));
                    }
                }

                let promiseInsert: Promise<any>;

                if (insertArray.length > 0) {

                    if (sync) {
                        promiseInsert = this.syncService.runSync(promisesISync);
                    } else {
                        promiseInsert = Promise.all(promisesI);
                    }

                } else {
                    promiseInsert = Promise.resolve(true);
                }

                promiseInsert.then(async () => {

                    // remove is ok to be sync
                    for (let e of removeArray) {
                        removed += 1;
                        await this.removeArrayMarkerCore(layer, e);
                        if (sync) {
                            await SleepUtils.sleep(this.loopDelay);
                        }
                    }

                    if (debug) {
                        console.log("updated: " + updated);
                        console.log("added: " + added);
                        console.log("removed: " + removed);
                    }

                    if (this.checkMarkerLayerContainer(layer, true)) {
                        this.multiMarkers[layer].internal.layerInitialized = true;
                        this.multiMarkers[layer].internal.syncInProgress = false;
                    }

                    await SleepUtils.sleep(this.loopDelay);
                    resolve(true);
                }).catch(async (err: Error) => {
                    console.error(err);
                    if (this.checkMarkerLayerContainer(layer, true)) {
                        this.multiMarkers[layer].internal.syncInProgress = false;
                    }
                    await SleepUtils.sleep(this.loopDelay);
                    reject(err);
                });
            }).catch(async (err: Error) => {
                console.error(err);
                if (this.checkMarkerLayerContainer(layer, true)) {
                    this.multiMarkers[layer].internal.syncInProgress = false;
                }
                await SleepUtils.sleep(this.loopDelay);
                reject(err);
            });
        });
        return promise;
    }



    /**
     * add marker for google map into the marker array
     * different for native and browser
     * @param data
     * @param type
     * @param icon
     */
    insertArrayMarker(data: IPlaceMarkerContent, show: boolean) {
        let promise = new Promise(async (resolve, reject) => {
            if (!data.icon) {
                resolve(true);
                return;
            }

            if (!show) {
                resolve(false);
                return;
            }

            let layer: string = data.layer;
            if (!this.checkMultiMarker(this.multiMarkers, layer)) {
                // console.log("insert array marker check multi marker: false");
                this.initMultiMarker(this.multiMarkers, layer);
            }

            if (!(this.checkGlobalInitTimeout())) {
                reject(new Error("marker insert before map init"));
                return;
            }

            try {
                let res = await this.insertArrayMarkerCore(data.layer, data);
                resolve(res);
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }

    handleMarkerCallbackExt(markerId: string) {
        let marker: IMarkerContainerMobile = this.getMarkerById(markerId);
        console.log("get marker callback by id: ", markerId);
        if (marker != null) {
            // marker.hideInfoWindow();
            // if (md.data && md.data.data) {
            //     md.callback(md.data.data);
            // } else {
            //     md.callback(md.data);
            // }
            if (marker.mox.callback) {
                marker.mox.callback(marker.mox.markerContent);
            } else {
                console.warn("marker callback not set for marker id: ", markerId);
            }
        } else {
            console.warn("marker id not found: ", markerId);
        }
    }

    /**
     * handle marker callback and info window management
     * @param md
     * @param marker 
     */
    private handleMarkerCallback(md: IMarkerOptionsExtended<MarkerCapacitorExt>, marker: Marker) {
        console.log(md.markerOptions);

        // marker.on(GoogleMapsEvent.INFO_CLICK).subscribe(() => {
        //     marker.hideInfoWindow();
        // }, (err: Error) => {
        //     console.error(err);
        // });

        // if (md.callback) {          
        //     marker.on(GoogleMapsEvent.MARKER_CLICK).subscribe(async () => {
        //         await SleepUtils.sleep(this.loopDelay);
        //         marker.hideInfoWindow();
        //         // if (md.data && md.data.data) {
        //         //     md.callback(md.data.data);
        //         // } else {
        //         //     md.callback(md.data);
        //         // }
        //         md.callback(md.markerContent);
        //     }, (err: Error) => {
        //         console.error(err);
        //     });
        // }

        // if (md.markerOptions.draggable) {
        //     marker.on(GoogleMapsEvent.MARKER_DRAG).subscribe(() => {
        //         // console.log("drag marker");
        //     }, (err: Error) => {
        //         console.error(err);
        //     });

        //     marker.on(GoogleMapsEvent.MARKER_DRAG_END).subscribe(() => {
        //         try {
        //             let newpos: ILatLng = marker.getPosition();
        //             console.log("drag marker end");
        //             md.markerContent.location.lat = newpos.lat;
        //             md.markerContent.location.lng = newpos.lng;
        //             // should assign to nearby place e.g. geocode
        //             if (md.dragCallback) {
        //                 md.dragCallback(newpos.lat, newpos.lng);
        //             }
        //         } catch (e) {
        //             console.error(e);
        //             console.log(md.dragCallback);
        //         }
        //     }, (err: Error) => {
        //         console.error(err);
        //     });
        // }
    }


    /**
     * show plain marker i.e. that is not drawn via canvas
     * @param md 
     */
    showPlainMarkerMobile(md: IMarkerOptionsExtended<MarkerCapacitorExt>): Promise<MarkerCapacitorExt> {
        let promise: Promise<MarkerCapacitorExt> = new Promise((resolve, reject) => {
            if (!this.enable) {
                reject(new Error("master lock"));
                return;
            }

            this.map.addMarker(md.markerOptions).then((markerId: string) => {
                md.markerOptions.id = markerId;
                md.id = markerId;
                this.handleMarkerCallback(md, md.markerOptions);
                resolve(md.markerOptions);
            }).catch((err: Error) => {
                reject(err);
            });

        });
        return promise;
    }

    /**
     * show canvas marker
     * with circular frame, label, etc
     * @param md 
     * @param opts 
     */
    showCanvasMarkerMobile(md: IMarkerOptionsExtended<MarkerCapacitorExt>, opts: IShowMarkerOptions): Promise<MarkerCapacitorExt> {
        let promise: Promise<MarkerCapacitorExt> = new Promise((resolve, reject) => {

            if (!this.enable) {
                reject(new Error("master lock"));
                return;
            }

            let markerOpts: MarkerCapacitorExt = Object.assign({}, md.markerOptions);

            let icon1 = md.markerOptions.iconUrl;
            // console.log("icon url: ", icon1);
            if (typeof icon1 !== "string") {
                icon1 = this.markerIcons.test;
            }
            let canvas = document.createElement('canvas');

            let f: ICanvasMarkerContainer = MarkerUtils.formatCanvasMarkerContainer(md.markerContent, opts.width);

            canvas.width = f.fullW;
            canvas.height = f.fullH;

            // setDPI(canvas, 300);
            MarkerUtils.setDPI(canvas, null, SettingsManagerService.settings.app.settings.HDPIMode.value);
            // MarkerUtils.setDPI(canvas, 96, SettingsManagerService.settings.app.settings.HDPIMode.value);

            let ctx = canvas.getContext('2d');
            let img = new Image();

            img.crossOrigin = "";
            let iconUrl = md.markerOptions.iconUrl;

            let isIos: boolean = GeneralCache.os === EOS.ios;

            // looks cooler
            isIos = true;

            let cb: IBenchObject = BenchUtils.start();
            let bench: IBenchReportContainer = {
                main: {

                }
            }

            let label: string = null;
            let heading: string[] = [null];

            if (md.markerContent) {
                label = md.markerContent.label;
                if (md.markerContent.heading) {
                    heading = [md.markerContent.heading];
                }

                let formatLabel = MarkerUtils.formatAddLabels(heading[0], label, md.markerContent.addLabel, md.markerContent.addLabel2);
                heading = formatLabel.heading2d;
                // label = formatLabel.label;
                label = formatLabel.label2d;

                if (md.markerContent.minimal) {
                    label = "Checkpoint";
                    heading = [md.markerContent.addLabel];
                }
            }

            this.imageLoaderTS.preload(iconUrl).then((content: string) => {
                img.src = content;

                bench.main.preload = BenchUtils.lap(cb, true);

                let errorOnce: boolean = false;

                let imgPromise: Promise<boolean> = new Promise((resolve) => {
                    img.onload = () => {
                        if (opts.circularFrame) {
                            ctx = MarkerUtils.drawMarkerText(ctx, f, img, opts.color, opts.labelFrameColor, opts.labelTextColor, opts.faded, label, heading, isIos, md.markerContent.compassRotate);
                        } else {
                            ctx = MarkerUtils.drawMarkerTextPlain(ctx, f, img, opts.color, opts.labelFrameColor, opts.labelTextColor, opts.faded, label, heading, isIos, md.markerContent.compassRotate);
                        }

                        let icon: string = canvas.toDataURL();
                        console.log("marker content size: " + icon.length);

                        markerOpts.iconUrl = icon;
                        markerOpts.iconSize = {
                            width: f.fullW,
                            height: f.fullH
                        };

                        bench.main.load = BenchUtils.lap(cb, true);
                        resolve(true);
                    };
                    img.onerror = (err) => {
                        console.warn("canvas image error: ", err);
                        // fallback to default location icon
                        img.src = EMarkerIcons.location;
                        bench.main.loadFallback = BenchUtils.lap(cb, true);
                        if (!errorOnce) {
                            errorOnce = true;
                        } else {
                            // error loading fallback                           
                            resolve(false);
                        }
                    };
                });

                imgPromise.then(() => {
                    markerOpts.isVisible = md.markerContent ? md.markerContent.visible : true;

                    // markerOpts.anchor = [f.fullW / 2, opts.circularFrame ? f.fullH : f.fullH / 2];
                    markerOpts.iconAnchor = {
                        x: f.fullW / 2,
                        y: opts.circularFrame ? f.fullH : f.fullH / 2
                    };

                    // console.log(markerOpts.zIndex);
                    if (this.enable) {
                        this.map.addMarker(markerOpts).then((markerId: string) => {
                            bench.main.draw = BenchUtils.lap(cb, true);
                            BenchUtils.sumBench(bench.main);
                            console.log("draw marker status bench: ", bench);
                            console.log("marker added: ", markerId);
                            md.markerOptions.id = markerId;
                            md.id = markerId;
                            this.handleMarkerCallback(md, markerOpts);
                            // marker.setVisible(md.data ? md.data.visible : true);
                            resolve(md.markerOptions);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                    } else {
                        reject(new Error("master lock"));
                    }
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });

        return promise;
    }


    /**
     * show markers from marker data array
     * mobile
     * @param opts 
     */
    showMarkerArrayMobile(opts: IShowMarkerOptions) {
        opts.height = opts.width + opts.mh;
        let layer: string = opts.type;

        if (!this.checkMultiMarker(this.multiMarkers, layer)) {
            this.initMultiMarker(this.multiMarkers, layer);
        }

        let promises = [];
        let promise = new Promise((resolve, reject) => {
            if (!opts.circularFrame) {
                let mc = this.multiMarkers[opts.type].container;
                // assign created markers
                for (let i = 0; i < mc.length; i++) {
                    let md: IMarkerOptionsExtended<MarkerCapacitorExt> = mc[i].mox;
                    if (md.markerOptions.iconUrl === null) {
                        md.markerOptions.iconUrl = this.markerIcons.location;
                    }
                    let promise = this.showPlainMarkerMobile(md);
                    promises.push(promise);
                    promise.then((marker) => {
                        this.multiMarkers[opts.type].container[i].marker = marker;
                    }).catch((err: Error) => {
                        console.error(err);
                        reject(err);
                    });
                }
            } else {
                // console.log('show canvas markers');
                let moxArray: IMarkerOptionsExtended<MarkerCapacitorExt>[] = this.multiMarkers[opts.type].container.map(c => c.mox);
                for (let i = 0; i < moxArray.length; i++) {
                    let md: IMarkerOptionsExtended<MarkerCapacitorExt> = moxArray[i];
                    let promise = this.showCanvasMarkerMobile(md, opts);
                    promises.push(promise);
                    promise.then((marker) => {
                        console.log("marker set");
                        this.multiMarkers[opts.type].container[i].marker = marker;
                    }).catch((err: Error) => {
                        console.error(err);
                        reject(err);
                    });
                }
            }

            Promise.all(promises).then(() => {
                console.log("all markers set");
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }


    /**
     * show circle marker
     * @param mox 
     */
    showCircleMobile(mox: IMarkerOptionsExtended<MarkerCapacitorExt>): Promise<Circle> {
        // Add circle
        let promise: Promise<Circle> = new Promise((resolve, reject) => {
            let options: CircleCapacitorExt = {
                id: null,
                center: mox.markerContent.location,
                radius: mox.markerContent.radius,
                strokeColor: mox.markerContent.color != null ? mox.markerContent.color : this.theme.markerFrameColor,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: mox.markerContent.color != null ? mox.markerContent.color : this.theme.markerFrameColor,
                fillOpacity: 0.8,
                zIndex: mox.markerContent.zindex,
                visible: mox.markerContent ? mox.markerContent.visible : true
            };
            this.map.addCircles([options]).then((circleIds: string[]) => {
                let circleId: string = circleIds[0];
                console.log("circle added: ", circleId);
                options.id = circleId;
                mox.id = circleId;
                mox.shape = EMapShapes.circle;
                resolve(options);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * set entire layer visible/invisible
     * it can be any kind of marker, also circle
     * single/multi
     * @param layer 
     * @param visible 
     */
    async setVisibleLayer(layer: string, visible: boolean) {
        if (this.multiMarkers[layer] != null) {
            this.multiMarkers[layer].internal.visibleLayer = visible;
            for (let marker of this.multiMarkers[layer].container.map(c => c.marker)) {
                if (marker != null) {
                    try {
                        if (visible) {
                            await this.map.showMarker(marker.id);
                        } else {
                            await this.map.hideMarker(marker.id);
                        }
                    } catch (err) {
                        console.error(err);
                    }
                }
            }
        } else {
            if (this.singleMarkers[layer] != null) {
                this.singleMarkers[layer].internal.visibleLayer = visible;
                let marker = this.singleMarkers[layer].container.marker;
                try {
                    if (visible) {
                        await this.map.showMarker(marker.id);
                    } else {
                        await this.map.hideMarker(marker.id);
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        }
    }

    /**
     * get visible state of marker layer
     * single/multi
     * @param layer 
     */
    getVisibleLayer(layer: string) {
        if (this.multiMarkers[layer] != null) {
            return this.multiMarkers[layer].internal.visibleLayer;
        } else {
            if (this.singleMarkers[layer] != null) {
                return this.singleMarkers[layer].internal.visibleLayer;
            }
        }
        return false;
    }

    /**
     * this method is not currently used
     */
    addMarkerClusterer() {
        let promise = new Promise((resolve) => {
            resolve(true);
        });
        return promise;
    }


    /**
     * set this circle marker, change position if existing
     * resize marker if size is changed
     */
    syncCircleMarker(layer: string, position: ILatLng, options: ISetThisMarkerOptions) {
        return new Promise(async (resolve) => {
            try {
                let data: IPlaceMarkerContent = MarkerUtils.getBaseMarker();
                data.location = position as any;
                let res = await this.syncMarkerCore(layer, data, true, options, null);
                resolve(res);
            } catch (err) {
                console.error(err);
                resolve(false);
            }
        });
    }


    /**
     * sync a single marker (update or create)
     * @param layer 
     * @param data 
     */
    syncMarker(layer: string, data: IPlaceMarkerContent, opts: IMoveMapOptions): Promise<boolean> {
        return new Promise(async (resolve) => {
            try {
                let res = await this.syncMarkerCore(layer, data, false, null, opts);
                resolve(res);
            } catch (err) {
                console.error(err);
                resolve(false);
            }
        });
    }

    /**
     * sync a single marker (update or create)
     * @param layer 
     * @param data 
     * @param circle
     */
    private syncMarkerCore(layer: string, data: IPlaceMarkerContent, circle: boolean, options: ISetThisMarkerOptions, opts: IMoveMapOptions): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (!data) {
                reject(new Error("sync marker no data"));
                return;
            }

            let mox: IMarkerOptionsExtended<MarkerCapacitorExt> = this.getMox(data);

            if (!options) {
                options = {
                    zindex: 0,
                    size: 0
                };
            } else {
                mox.markerContent.zindex = options.zindex;
                mox.markerOptions.zIndex = options.zindex;
            }

            this.singleMarkerUpdate(layer, data.location, circle, options, opts).then((state: IMarkerUpdateResult) => {
                // console.log(state);
                switch (state.code) {
                    case EMarkerUpdateCode.ok:
                        // console.log("ok");
                        resolve(true);
                        break;
                    case EMarkerUpdateCode.shouldWait:
                        // console.log("should wait");
                        reject(new Error(state.message));
                        break;
                    case EMarkerUpdateCode.shouldCreate:
                        // console.log("should create");
                        if (!(this.checkGlobalInitTimeout())) {
                            reject(new Error("marker create before map init"));
                            return;
                        }
                        let promise: any;
                        if (circle) {
                            promise = this.singleCircleCreate(layer, mox.markerContent.location, mox, options);
                        } else {
                            promise = this.singleMarkerCreate(layer, mox.markerContent.location, mox, true);
                        }

                        promise.then(() => {
                            resolve(true);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                        break;
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * add path from waypoints
     * using single markers layer
     * @param waypoints 
     * @param layer 
     * @param show 
     */
    addPathMobile(waypoints: ILatLng[], layer: string, show: boolean): Promise<PolylineCapacitorExt> {
        let promise: Promise<PolylineCapacitorExt> = new Promise((resolve, reject) => {
            if (!this.checkSingleMarker(this.singleMarkers, layer)) {
                this.initSingleMarker(this.singleMarkers, layer);
            }

            if (waypoints.length > 0) {
                let pathOptions: PolylineCapacitorExt = {
                    id: null,
                    path: waypoints,
                    visible: show,
                    geodesic: true,
                    strokeColor: this.theme.lineColor,
                    strokeOpacity: 0.8,
                    strokeWeight: 10,
                    zIndex: 100,
                    clickable: false
                };

                this.map.addPolylines([pathOptions]).then((pid: string[]) => {
                    pathOptions.id = pid[0];
                    this.singleMarkers[layer].container.marker = pathOptions;
                    this.singleMarkers[layer].container.mox = {
                        id: pid[0],
                        shape: EMapShapes.polyline
                    } as IMarkerOptionsExtended<any>;
                    resolve(pathOptions);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                resolve(null);
            }
        });
        return promise;
    }


    /**
     * clear markers from marker array from google map
     * clear the marker array or single marker
     * does not clear all data
     * @param layer
     */
    clearMarkersResolve(layer: string): Promise<boolean> {
        // https://github.com/mapsplugin/cordova-plugin-googlemaps-doc/blob/master/v2.0.0/class/Marker/README.md
        // https://developers.google.com/android/reference/com/google/android/gms/maps/model/Marker
        let promise: Promise<boolean> = new Promise(async (resolve) => {

            if (this.checkMarkerLayerContainer(layer, true)) {
                for (let c of this.multiMarkers[layer].container) {
                    let marker: MarkerCapacitorExt = c.marker;
                    try {
                        if (marker) {
                            await this.clearMarker(marker, c.mox?.shape);
                        }
                    } catch (e) {
                        console.error(e);
                    }
                    c.marker = null;
                    console.log("removing next marker");
                    await SleepUtils.sleep(this.loopDelay);
                }
                // this.multiMarkers[layer].container[layer] = [];
            }

            if (this.checkMarkerLayerContainer(layer, false)) {
                let c: IMarkerContainerMobile = this.singleMarkers[layer].container;
                let marker: MarkerCapacitorExt = c.marker;
                try {
                    if (marker) {
                        await this.clearMarker(marker, c.mox?.shape);
                        c.marker = null;
                    }
                } catch (e) {
                    console.error(e);
                }
            }

            await SleepUtils.sleep(this.loopDelay);
            console.log("resolve clear markers");
            resolve(true);
        });

        return promise;
    }


    /**
     * clear the last marker from the array
     * @param layer 
     * @param remove completely remove the marker from the array
     */
    async clearLastArrayMarker(layer: string, remove: boolean = false) {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            let lastIndex: number = this.multiMarkers[layer].container.length - 1;
            let container: IMarkerContainerMobile = this.multiMarkers[layer].container[lastIndex];
            if (container) {
                let marker: MarkerCapacitorExt = container.marker;
                if (marker) {
                    await this.clearMarker(marker, container.mox?.shape);
                }
            }

            if (remove) {
                // clear the marker with all associated data
                this.multiMarkers[layer].container.splice(-1, 1);
            } else {
                // only clear the marker from the map
                this.multiMarkers[layer].container.forEach(c => {
                    if (c) {
                        c.marker = null;
                    }
                });
            }
        }
    }


    /**
     * completely remove the marker from the array
     * @param layer 
     * @param index 
     */
    async clearArrayMarkerByIndex(layer: string, index: number) {
        // Sets the map on all markers in the array.
        console.log("clear marker by index: " + index);
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            let markerContainer: IMarkerContainerMobile = this.multiMarkers[layer].container[index];
            if (!markerContainer) {
                return;
            }
            let marker: MarkerCapacitorExt = markerContainer.marker;
            await this.clearMarker(marker, markerContainer.mox?.shape);
            if (index !== -1 && index < this.multiMarkers[layer].container.length) {
                this.multiMarkers[layer].container.splice(index, 1);
            }
        }
    }

    /**
     * clear the specified marker from the map
     * @param marker 
     */
    private clearMarker(marker: MarkerCapacitorExt, shape: number) {
        return new Promise(async (resolve) => {
            try {
                if (marker != null) {
                    console.log("clear marker: " + marker.id + " shape: " + shape);
                    switch (shape) {
                        case EMapShapes.marker:
                            await this.map.removeMarker(marker.id);
                            break;
                        case EMapShapes.circle:
                            await this.map.removeCircles([marker.id]);
                            break;
                        case EMapShapes.polyline:
                            await this.map.removePolylines([marker.id]);
                            break;
                        case EMapShapes.polygon:
                            await this.map.removePolygons([marker.id]);
                            break;
                        default:
                            await this.map.removeMarkersAny([marker.id]);
                            break;
                    }
                    resolve(true);
                } else {
                    console.warn("clear marker: undefined");
                    resolve(false);
                }
            } catch (err) {
                console.error(err);
                resolve(false);
            }
        });
    }

    /**
     * show/hide last marker from array
     * @param layer 
     * @param show 
     */
    async toggleLastArrayMarkerShow(layer: string, show: boolean) {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            let lastIndex: number = this.multiMarkers[layer].container.length - 1;
            let marker: MarkerCapacitorExt = this.multiMarkers[layer].container[lastIndex].marker;
            if (marker) {
                show ? await this.map.showMarker(marker.id) : await this.map.hideMarker(marker.id);
            }
        }
    }

    async toggleArrayMarkerShow(layer: string, index: number, show: boolean) {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            let marker: MarkerCapacitorExt = this.multiMarkers[layer].container[index].marker;
            if (marker) {
                show ? await this.map.showMarker(marker.id) : await this.map.hideMarker(marker.id);
            }
        }
    }

    /**
     * completely remove the marker from the array
     * @param layer 
     * @param uid 
     */
    async clearArrayMarkerByUid(layer: string, uid: string) {
        // Sets the map on all markers in the array.
        console.log("clear marker by uid: " + uid);
        let index: number = this.getArrayMarkerIndexByUid(layer, uid);
        await this.clearArrayMarkerByIndex(layer, index);
    }

    getArrayMarkerDataByUid(layer: string, uid: string) {
        console.log("get marker by uid: " + uid);
        let index: number = this.getArrayMarkerIndexByUid(layer, uid);
        return this.getArrayMarkerDataByIndex(index, layer);
    }

    /**
     * clear the marker layer completely
     * @param layer 
     */
    clearMarkerLayer(layer: string) {
        if (this.multiMarkers[layer]) {
            this.multiMarkers[layer] = null;
        }
        if (this.singleMarkers[layer] != null) {
            this.singleMarkers[layer] = null;
        }
    }

    getMarkerById(id: string) {
        let layers: string[] = Object.keys(this.multiMarkers);
        for (let layer of layers) {
            if (this.multiMarkers[layer]) {
                for (let marker of this.multiMarkers[layer].container) {
                    if (marker.mox && (marker.mox.id === id)) {
                        return marker;
                    }
                }
            }
        }
        layers = Object.keys(this.singleMarkers);
        for (let layer of layers) {
            if (this.singleMarkers[layer]) {
                let marker = this.singleMarkers[layer].container;
                if (marker.mox && (marker.mox.id === id)) {
                    return marker;
                }
            }
        }
    }


    /**
     * 
     * @param index 
     * @param type 
     */
    getMarkerLocationByIndex(index: number, type: string) {
        let marker: IMarkerContainerMobile = this.getArrayMarkerByIndex(index, type);
        if (marker) {
            let pos1: ILatLng = marker.mox.markerOptions.coordinate;
            let pos: ILatLng = { lat: pos1.lat, lng: pos1.lng };
            return pos;
        } else {
            return null;
        }
    }


    /**
     * get array marker by index
     * @param index 
     * @param layer 
     */
    getArrayMarkerByIndex(index: number, layer: string) {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            if (index === null) {
                index = this.multiMarkers[layer].container.length - 1;
            }
            if (index >= this.multiMarkers[layer].container.length) {
                return null;
            }
            if (index < 0) {
                return null;
            }

            let marker: IMarkerContainerMobile;
            marker = this.multiMarkers[layer].container[index];
            return marker;
        }
        return null;
    }


    /**
     * get array marker data by index
     * @param index 
     * @param layer 
     */
    getArrayMarkerDataByIndex(index: number, layer: string) {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            if (index === null) {
                index = this.multiMarkers[layer].container.length - 1;
            }
            if (index >= this.multiMarkers[layer].container.length) {
                return null;
            }
            if (index < 0) {
                return null;
            }
            let marker: IPlaceMarkerContent;
            marker = this.multiMarkers[layer].container[index].mox.markerContent;
            return marker;
        }
        return null;
    }


    /**
     * get data layer content
     * @param layer 
     */
    getSingleMarkerDataByLayer(layer: string): IPlaceMarkerContent {
        if (this.checkSingleMarker(this.singleMarkers, layer)) {
            return this.singleMarkers[layer].container.mox.markerContent;
        }
        return null;
    }

    /**
     * get data layer content
     * @param layer 
     */
    getArrayMarkerDataByLayer(layer: string): IPlaceMarkerContent[] {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            return this.multiMarkers[layer].container.map(c => c.mox.markerContent);
        }
        return null;
    }

    /**
     * should be called just after init map
     */
    setGlobalMarkerInitTimestamp() {
        this.globalInitTimestamp = new Date().getTime();
    }


    /**
     * check marker update timeout
     * @param timestamp 
     * @param timeCrt 
     */
    private checkInitTimeout(timestamp: number) {
        let timeCrt: number = new Date().getTime();
        if (timestamp != null && ((timeCrt - timestamp) > MARKER_UPDATE_TIMEOUT_MOBILE)) {
            return this.checkGlobalInitTimeout();
        } else {
            return false;
        }
    }

    /**
     * check marker update global timeout
     */
    private checkGlobalInitTimeout() {
        let timeCrt: number = new Date().getTime();
        if (!this.globalInitTimestamp || ((timeCrt - this.globalInitTimestamp) > MAP_INIT_TIMEOUT_MOBILE)) {
            return true;
        }
        return false;
    }

    /**
     * completely remove the marker layer
     * this method may be used in a loop and contains a small delay for this matter
     * @param layer 
     */
    disposeLayerResolve(layer: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            console.log("dispose layer: " + layer);
            this.clearMarkersResolve(layer).then(async () => {
                this.clearMarkerLayer(layer);
                await SleepUtils.sleep(this.loopDelay);
                resolve(true);
            });
        });
        return promise;
    }

    /**
     * completely remove the marker layer
     * no wait
     * @param key 
     */
    disposeLayerNoAction(key: string) {
        this.disposeLayerResolve(key).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        })
    }

    /**
     * clear all markers
     * dispose all layers
     */
    async clearAll(sync: boolean) {
        let promise = new Promise(async (resolve) => {
            let keys = Object.keys(this.multiMarkers);
            for (let key of keys) {
                if (sync) {
                    await this.disposeLayerResolve(key);
                } else {
                    this.disposeLayerNoAction(key);
                }
            }

            keys = Object.keys(this.singleMarkers);
            for (let key of keys) {
                if (sync) {
                    await this.disposeLayerResolve(key);
                } else {
                    this.disposeLayerNoAction(key);
                }
            }

            resolve(true);
        });
        return promise;
    }

    /**
     * clear all without removing from the map
     * used for cleanup before map unload
     * map.clearAll should be used for clearing the map
     */
    clearAllNoRemove() {
        let promise = new Promise(async (resolve) => {
            let keys = Object.keys(this.multiMarkers);
            for (let key of keys) {
                this.clearMarkerLayer(key);
            }

            keys = Object.keys(this.singleMarkers);
            for (let key of keys) {
                this.clearMarkerLayer(key);
            }

            resolve(true);
        });
        return promise;
    }



    /**
     * get all markers data (from all layers)
     * single and multi markers
     * returns only the actual content, not the marker itself
     */
    getAllMarkersData(excludeLayers: string[]) {
        let keys: string[] = Object.keys(this.multiMarkers);
        if (excludeLayers) {
            keys = keys.filter(key => excludeLayers.indexOf(key) === -1);
        }
        let allMarkersData: IPlaceMarkerContent[] = [];
        for (let i = 0; i < keys.length; i++) {
            let m: IArrayMarkerMobile = this.multiMarkers[keys[i]];
            if (m && m.container) {
                allMarkersData = allMarkersData.concat(this.multiMarkers[keys[i]].container.map(c => c.mox.markerContent));
            }
        }
        keys = Object.keys(this.singleMarkers);
        if (excludeLayers) {
            keys = keys.filter(key => excludeLayers.indexOf(key) === -1);
        }
        for (let i = 0; i < keys.length; i++) {
            let m: ISingleMarkerMobile = this.singleMarkers[keys[i]];
            if (m && m.container && m.container.mox) {
                allMarkersData.push(m.container.mox.markerContent);
            }
        }
        // console.log("all markers data: ", allMarkersData);
        return allMarkersData;
    }


    /**
     * create a new marker on a single markers layer
     * only supports canvas markers at the moment
     * @param layer 
     * @param iconName 
     * @param position 
     * @param callback 
     * @param resize 
     * @param options 
     */
    singleMarkerCreate(layer: string, _location: ILatLng, mox: IMarkerOptionsExtended<MarkerCapacitorExt>, resize: boolean) {
        let promise = new Promise((resolve, reject) => {
            if (resize) {
                mox.markerOptions.iconUrl = mox.markerOptions.iconUrl;
                mox.markerOptions.iconSize = { width: 50, height: 50 };
            }

            mox.markerOptions.iconUrl = mox.markerOptions.iconUrl;

            let mh = 20;
            let mw = 20;

            let data: IPlaceMarkerContent = mox.markerContent;

            if (!data.radius) {
                data.radius = 120;
            } else {
                mh = Math.floor(mh * data.radius / 120);
                mw = Math.floor(mw * data.radius / 120);
            }

            let opts: IShowMarkerOptions = {
                mh,
                mw,
                width: data.radius,
                type: layer,
                circularFrame: data.mode === EMarkerTypes.canvasFrame,
                // color: data.color,
                // color: "#dc4a38",     
                color: data.color ? data.color : this.theme.markerFrameColor,
                faded: data.locked
            };

            opts.height = opts.width + opts.mh;

            if (!this.singleMarkers[layer]) {
                this.singleMarkers[layer] = this.getDefaultSingleMarkerContainer();
                this.singleMarkers[layer].container.mox = mox;
            } else {
                if (!this.singleMarkers[layer].internal.layerInitialized) {
                    reject(new Error("marker init in progress"));
                    return;
                }
            }

            this.showCanvasMarkerMobile(mox, opts).then((marker) => {
                this.singleMarkers[layer].container.marker = marker;
                this.singleMarkers[layer].container.mox = mox;
                this.singleMarkers[layer].internal.layerInitialized = true;
                console.log("marker was created ", layer);
                resolve(this.singleMarkers[layer]);
            }).catch((err: Error) => {
                console.error(err);
                this.singleMarkers[layer].internal.layerInitialized = true;
                reject(err);
            });
        });
        return promise;
    }


    /**
     * create a new circle marker on a single markers layer
     * @param layer 
     * @param position 
     * @param options 
     */
    singleCircleCreate(layer: string, position: ILatLng, mox: IMarkerOptionsExtended<MarkerCapacitorExt>, options: ISetThisMarkerOptions) {
        let promise = new Promise((resolve, reject) => {
            let circleOptions: CircleCapacitorExt = {
                id: null,
                center: position,
                radius: options.size,
                strokeColor: mox.markerContent.color != null ? mox.markerContent.color : this.theme.markerFrameColor,
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: mox.markerContent.color != null ? mox.markerContent.color : this.theme.markerFrameColor,
                fillOpacity: 0.8,
                zIndex: options.zindex,
                visible: true
            };

            if (!this.singleMarkers[layer]) {
                this.singleMarkers[layer] = this.getDefaultSingleMarkerContainer();
                this.singleMarkers[layer].container.mox = mox;
            } else {
                if (!this.singleMarkers[layer].internal.layerInitialized) {
                    reject(new Error("marker init in progress"));
                    return;
                }
            }

            this.map.addCircles([circleOptions]).then((circleIds: string[]) => {
                let circleId: string = circleIds[0];
                console.log("circle added: ", circleId);
                circleOptions.id = circleId;
                this.singleMarkers[layer].container.marker = circleOptions;
                let mox = this.singleMarkers[layer].container.mox;
                mox.id = circleId;
                mox.shape = EMapShapes.circle;
                this.singleMarkers[layer].internal.layerInitialized = true;
                resolve(circleOptions);
            }).catch((err: Error) => {
                this.singleMarkers[layer].internal.layerInitialized = true;
                reject(err);
            });
        });
        return promise;
    }


    /**
     * update marker
     * resolve to false if the marker should be created instead
     * @param layer 
     * @param position 
     */
    singleMarkerUpdate(layer: string, position: ILatLng, isCircle: boolean, options: ISetThisMarkerOptions, opts: IMoveMapOptions) {
        let promise = new Promise(async (resolve) => {
            let result: IMarkerUpdateResult = {
                code: EMarkerUpdateCode.ok,
                message: "ok"
            };
            if (!position) {
                result.message = "position not set";
                resolve(result);
                return;
            }
            try {
                if (this.checkSingleMarker(this.singleMarkers, layer)) {
                    // prevent double marker if set marker too fast
                    let mc: ISingleMarkerMobile = this.singleMarkers[layer];
                    if (!mc.internal.layerInitialized) {
                        // should wait
                        result.code = EMarkerUpdateCode.shouldWait;
                        result.message = "marker create in progress";
                        resolve(result);
                        return;
                    } else {
                        let timeCrt: number = new Date().getTime();
                        let rateLimiter: boolean = true;
                        if (opts && opts.unlockLimiter) {
                            rateLimiter = false;
                        }
                        if (options && options.unlockLimiter) {
                            rateLimiter = false;
                        }
                        if ((rateLimiter && this.checkInitTimeout(mc.internal.timestamp)) || (!rateLimiter && this.checkGlobalInitTimeout())) {
                            if (this.checkMarkerLayerContainer(layer, false)) {
                                if (mc.container.mox && mc.container.mox.markerOptions) {
                                    mc.container.mox.markerOptions.coordinate = new ILatLng(position.lat, position.lng);
                                } else {
                                    console.warn("marker options undefined");
                                }
                                if (isCircle) {
                                    let c: IMarkerOptionsExtended<MarkerCapacitorExt> = mc.container.mox;
                                    console.log("update circle: ", c.id, mc.container.marker);
                                    await this.map.setCircleCenter(c.id, position);
                                    await this.map.setCircleRadius(c.id, options.size);
                                    resolve(result);
                                } else {
                                    let enableAnimate: boolean = false;
                                    if (opts && opts.animateMarker && enableAnimate) {
                                        await this.animatePositionUpdate(mc, mc.container.mox.markerContent.location, position, opts.duration);
                                        let mk: MarkerCapacitorExt = mc.container.marker;
                                        if (mk != null) {
                                            await this.map.updateMarkerPosition(mk.id, position);
                                        }
                                        mc.internal.timestamp = timeCrt;
                                        resolve(result);
                                    } else {
                                        let mk: MarkerCapacitorExt = mc.container.marker;
                                        if (mk != null) {
                                            await this.map.updateMarkerPosition(mk.id, position);
                                        }
                                        mc.internal.timestamp = timeCrt;
                                        resolve(result);
                                    }
                                }
                            } else {
                                result.code = EMarkerUpdateCode.shouldCreate;
                                result.message = "marker container empty";
                                resolve(result);
                            }
                        } else {
                            // should wait
                            result.code = EMarkerUpdateCode.shouldWait;
                            result.message = "marker update too fast";
                            resolve(result);
                            return;
                        }
                    }
                } else {
                    // should create
                    result.code = EMarkerUpdateCode.shouldCreate;
                    result.message = "marker should be created";
                    resolve(result);
                    return;
                }
            } catch (err) {
                console.error(err);
                result.message = "error";
                resolve(result);
            }
        });
        return promise;
    }


    /**
     * todo
     * @param mc 
     * @param a 
     * @param b 
     * @param duration 
     * @returns 
     */
    animatePositionUpdate(mc: ISingleMarkerMobile, a: ILatLng, b: ILatLng, duration: number) {
        let promise = new Promise((resolve) => {
            let fraction: number = 0;
            let dt: number = 20;
            let targetTime: number = duration != null ? duration : 200;
            let npoints: number = targetTime / dt;
            let dfraction: number = 1 / npoints;

            mc.internal.animateTimeout = ResourceManager.clearTimeout(mc.internal.animateTimeout);

            let animateMk = () => {
                fraction += dfraction;
                if (fraction > 1) {
                    fraction = 1;
                    mc.internal.animateTimeout = ResourceManager.clearTimeout(mc.internal.animateTimeout);
                    resolve(true);
                }
                // console.log("animate mk pos: ", fraction);
                mc.container.marker.setPosition(GeometryUtils.getInterpolate(a as any, b as any, fraction));
                mc.internal.animateTimeout = setTimeout(() => {
                    animateMk();
                }, dt);
            };

            animateMk();
        });
        return promise;
    }
}
