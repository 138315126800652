import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, ViewChild, ElementRef, OnChanges, SimpleChanges, SimpleChange, AfterViewInit } from '@angular/core';
import { IAppLocation } from 'src/app/classes/def/places/app-location';
import { IonSlides } from '@ionic/angular';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { ISliderOptions } from 'src/app/classes/def/dynamic/slides';
import { EStoryLocationDoneFlag } from 'src/app/classes/def/nav-params/story';

@Component({
  selector: 'location-dots-ext',
  templateUrl: './location-dots-ext.component.html',
  styleUrls: ['./location-dots-ext.component.scss'],
})
export class LocationDotsExtComponent implements OnInit, OnChanges, OnDestroy, AfterViewInit {

  @ViewChild('sliderContainer', { read: ElementRef, static: false }) sliderContainer: ElementRef;
  slides: IonSlides;

  @Input()
  data: Array<IAppLocation[]>;

  @Input()
  sliderIndex: number;

  @Input()
  itemsPerSlide: number;

  @Input()
  dark: boolean;

  @Output()
  select: EventEmitter<any> = new EventEmitter();

  color: string = "light";

  sliderOptions: ISliderOptions = {
    slidesPerView: 1,
    initialSlide: 0
  };

  showLeftButton: boolean = true;
  showRightButton: boolean = true;
  slidesPerView: number = 1;
  timeout = {
    slider: null
  };
  loaded: boolean = false;
  paginate: boolean = false;

  constructor() {

  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'sliderIndex':
            this.slideToIndex(true);
            break;
        }
      }
    }
  }

  isLast(index: number) {
    if (this.data) {
      if (index < this.data.length - 1) {
        return false;
      }
      return true;
    }
    return false;
  }

  getClass(item: IAppLocation) {
    // return this.isDone(item) ? 'list active' : '';
    let css = "";
    if (item && item.loc && item.loc.merged) {
      if (item.loc.merged.googleId) {
        css = "opened";
      }
      if (item.dispDone) {
        css = "active";
      }
      if (item.loc.merged.done === EStoryLocationDoneFlag.done) {
        css = "done";
      }
    }
    return css;
  }


  getClassV2(item: IAppLocation) {
    // return this.isDone(item) ? 'list active' : '';
    let css = "";
    if (item && item.loc && item.loc.merged) {
      if (item.loc.merged.googleId) {
        css = "opened";
      }
      if (item.dispDone) {
        css = "active";
      }
      if (item.loc.merged.done === EStoryLocationDoneFlag.done) {
        css = "done";
      }
    }
    return css;
  }

  isDone(item: IAppLocation) {
    if (item && item.loc && item.loc.merged) {
      return item.loc.merged.googleId && ((item.loc.merged.done === EStoryLocationDoneFlag.done) || item.dispDone);
    }
    return false;
  }

  clicked(index: number) {
    this.select.emit([index]);
  }

  ngOnInit() {
    if (this.dark) {
      this.color = "primary";
    }
    this.sliderOptions.slidesPerView = this.slidesPerView;
    if (this.sliderIndex != null) {
      this.sliderOptions.initialSlide = this.sliderIndex;
    }
    if (this.data && this.data.length > 1) {
      this.paginate = true;
    }
    this.loaded = true;
  }

  ngAfterViewInit() {
    this.slides = this.sliderContainer.nativeElement.children[0];
  }

  slideToIndex(withRetry: boolean) {
    if (!this.slides) {
      console.warn("slides not initialized");
      if (withRetry) {
        this.timeout.slider = setTimeout(() => {
          this.slideToIndex(false);
        }, 500);
      }
      return;
    }
    this.slides.slideTo(this.sliderIndex, 500, false).then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngOnDestroy() {
    this.clearAutoSlide();
  }

  public slideChanged(): void {

  }

  /**
  * Method that shows the next slide
  * update: next GROUP of slides
  */
  public slideNext(): void {
    if (!this.slides) {
      return;
    }
    console.log("slide next");
    this.clearAutoSlide();
    this.slides.getActiveIndex().then((currentIndex: number) => {
      this.slides.length().then((slidesLength: number) => {
        let slideToIndex: number = currentIndex + this.slidesPerView;
        if (slideToIndex > slidesLength - 1) {
          slideToIndex = slidesLength - 1;
        }
        this.sliderIndex = slideToIndex;
        this.slideToIndex(true);
      }).catch((err: Error) => {
        console.error(err);
      });

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * Method that shows the previous slide
   * update: previous GROUP of slides
   */
  public slidePrev(): void {
    if (!this.slides) {
      return;
    }
    console.log("slide prev");
    this.clearAutoSlide();
    this.slides.getActiveIndex().then((currentIndex: number) => {
      let slideToIndex: number = currentIndex - this.slidesPerView;
      if (slideToIndex < this.slidesPerView - 1) {
        slideToIndex = 0;
      }
      this.sliderIndex = slideToIndex;
      this.slideToIndex(true);
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  clearAutoSlide() {
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
  }

}
