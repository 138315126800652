import { NgModule } from '@angular/core';
import { InventoryHomePageModule } from './inventory/inventory-home/inventory-home.module';
import { GmapDetailPageModule } from './gmap-detail/gmap-detail.module';
import { MPCreatePageModule } from './mp/mp-create/mp-create.module';
import { MPGroupMembersPageModule } from './mp/mp-group-members/mp-group-members.module';
import { MPGroupsChatPageModule } from './mp/mp-groups-chat/mp-groups-chat.module';
import { MPGroupsListPageModule } from './mp/mp-groups-list/mp-groups-list.module';
import { MPHomePageModule } from './mp/mp-home/mp-home.module';
import { MPJoinPageModule } from './mp/mp-join/mp-join.module';
import { HomePageModule } from './home/home.module';
import { AboutPageModule } from './about/about.module';
import { AccountHomePageModule } from './account/account-home/account-home.module';
import { AccountDetailsPageModule } from './account/account-details/account-details.module';
import { AccountCharacterPageModule } from './account/account-character/account-character.module';
import { ARViewEntryPageModule } from './ar-view-entry/ar-view-entry.module';
import { EventsHomePageModule } from './events/events-home/events-home.module';
import { EventsListPageModule } from './events/events-list/events-list.module';
import { ExtrasPageModule } from './extras/extras.module';
import { GmapPageModule } from './gmap/gmap.module';
import { InventoryDetailPageModule } from './inventory/inventory-detail/inventory-detail.module';
import { LeaderboardPageModule } from './leaderboard/leaderboard.module';
import { LoginPageModule } from './logins/login/login.module';
import { SignupPageModule } from './logins/signup/signup.module';
import { SignupTermsPageModule } from './logins/signup-terms/signup-terms.module';
import { SignupTutorialPageModule } from './logins/signup-tutorial/signup-tutorial.module';
import { NewsfeedPageModule } from './newsfeed/newsfeed.module';
import { StoryCategoriesPageModule } from './storyline/story-categories/story-categories.module';
import { StoryHomePageModule } from './storyline/story-home/story-home.module';
import { StoryListPageModule } from './storyline/story-list/story-list.module';
import { TestPageModule } from './test/test.module';
import { TreasureHomePageModule } from './treasure-home/treasure-home.module';
import { TutorialPageModule } from './tutorial/tutorial.module';
import { LandingPageModule } from './logins/landing/landing.module';
import { EventsJoinPageModule } from './events/events-join/events-join.module';
import { TeamStatsPageModule } from './groups/team-stats/team-stats.module';
import { MPGroupsHomePageModule } from './mp/mp-groups-home/mp-groups-home.module';
import { MpOptionsPageModule } from './mp/mp-options/mp-options.module';
import { LeaderboardExtPageModule } from './leaderboard-ext/leaderboard-ext.module';

@NgModule({
  declarations: [

  ],
  imports: [
    HomePageModule,

    AboutPageModule,
    AccountHomePageModule,
    AccountDetailsPageModule,
    AccountCharacterPageModule,
    ARViewEntryPageModule,
    EventsHomePageModule,
    EventsListPageModule,
    EventsJoinPageModule,

    ExtrasPageModule,
    GmapPageModule,
    GmapDetailPageModule,

    InventoryDetailPageModule,
    LeaderboardPageModule,
    LeaderboardExtPageModule,

    LandingPageModule,

    LoginPageModule,
    SignupPageModule,
    SignupTermsPageModule,
    SignupTutorialPageModule,

    MPCreatePageModule,
    MPGroupMembersPageModule,
    MPGroupsChatPageModule,
    MPGroupsListPageModule,
    MPHomePageModule,
    MPGroupsHomePageModule,
    MPJoinPageModule,
    MpOptionsPageModule,

    NewsfeedPageModule,
    StoryCategoriesPageModule,
    StoryHomePageModule,
    StoryListPageModule,
    TestPageModule,
    TreasureHomePageModule,
    TutorialPageModule,

    InventoryHomePageModule,
    TeamStatsPageModule
  ],
  exports: [

  ]
})
export class PagesLinksModule { }
