
export interface IKVStat {
    name: string;
    value: number;
    valueString?: string;
}

export interface IKVStatDict {
    [key: string]: IKVStat;
}

export class KVUtils {
    static updateKV(dict: IKVStatDict, key: string, kv: IKVStat) {
        if (dict[key] != null) {
            dict[key].value = kv.value;
            dict[key].valueString = kv.valueString;
        } else {
            dict[key] = kv;
        }
    }
}
