import { Injectable } from "@angular/core";
import { ForegroundService, StartForegroundServiceOptions, PermissionStatus } from '@capawesome-team/capacitor-android-foreground-service';
import { Messages } from "src/app/classes/def/app/messages";

@Injectable({
    providedIn: 'root'
})
export class BackgroundModeAndroidService {

    isEnabled: boolean = false;
    isActivated: boolean = false;
    initialized: boolean = false;

    constructor(

    ) {
        console.log("background mode service created");
    }

    /**
     * init config
     */
    async init() {
        console.log("init background mode");
        this.initialized = true;
    }


    isBgModeRunning() {
        return this.isEnabled && this.isActivated;
    }

    isBgModeEnabled() {
        return this.isEnabled;
    }

    async requestPermissions(): Promise<void> {
        try {
            let checkp: PermissionStatus = await ForegroundService.checkPermissions();
            console.log("check permissions: ", checkp);
            if (checkp && (checkp.display !== "granted")) {
                checkp = await ForegroundService.requestPermissions();
                console.log("request permissions: ", checkp);
            }

            // const { enabled } = await BatteryOptimization.isBatteryOptimizationEnabled();
            // console.log("is battery optimization: ", enabled);
            // if (!enabled) {
            //     await BatteryOptimization.openBatteryOptimizationSettings();
            //     await BatteryOptimization.requestIgnoreBatteryOptimization();
            // }    
        } catch (err) {
            console.error(err);
        }
    }

    /**
     * enable 
     * e.g. while on the map
     */
    async enable() {
        await this.requestPermissions();
        await this.resume();
    }

    /**
     * disable
     * e.g. while not on the map
     */
    async disable() {
        if (!this.initialized) {
            console.warn("bg mode not initialized");
            return;
        }
        console.log("disable background mode");
        await this.pause();
    }

    async pause() {
        try {
            if (!this.initialized) {
                console.warn("bg mode not initialized");
                return;
            }
            console.log("pause (disable) background mode");
            await ForegroundService.stopForegroundService();
            this.isEnabled = false;
        } catch (err) {
            console.error(err);
        }
    }

    async resume() {
        try {
            if (!this.initialized) {
                console.warn("bg mode not initialized");
                return;
            }
            console.log("resume (enable) background mode");
            let opts: StartForegroundServiceOptions = {
                buttons: [
                    {
                        id: 1,
                        title: 'Stop',
                    },
                ],
                title: Messages.notification.backgroundMode.after.msg,
                body: Messages.notification.backgroundMode.after.sub,
                id: 12398473,
                smallIcon: 'splash',
            }
            await ForegroundService.startForegroundService(opts);
            this.isEnabled = true;
        } catch (err) {
            console.error(err);
        }
    }
}