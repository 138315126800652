import { Injectable } from "@angular/core";
import { IActivity } from "../../../classes/def/core/activity";
import { IPhotoResultResponse } from "../../../classes/def/media/processing";
import { SettingsManagerService } from "../../general/settings-manager";
import { IPlatformFlags } from "../../../classes/def/app/platform";
import { ResourceManager } from "../../../classes/general/resource-manager";
import { PhotosService } from "../../media/photos";
import { UiExtensionService } from "../../general/ui/ui-extension";
import { EAlertButtonCodes } from "../../../classes/def/app/ui";
import {
    IPhotoValidationRequirementsViewParams,
    PhotoValidationRequirementsViewComponent
} from 'src/app/modals/app/modals/photo-validation-requirements/photo-validation-requirements.component';
import { IPhotoValidatedViewParams, PhotoValidatedViewComponent } from 'src/app/modals/app/modals/photo-validated/photo-validated.component';
import { IPhotoValidatorParams, EPhotoValidatorModes } from 'src/app/classes/def/media/photo-validator';
import { IActivityPhotoSpecs } from "src/app/classes/def/nav-params/activity-details";


@Injectable({
    providedIn: 'root'
})
export class PhotoValidatorService {
    platform: IPlatformFlags = {} as IPlatformFlags;
    timeouts = {
        ui: null
    };

    testCounter: number = 2;
    bypassValidationOnWeb: boolean = false;

    constructor(
        public settings: SettingsManagerService,
        public photos: PhotosService,
        public uiext: UiExtensionService
    ) {
        console.log("photo validator service created");
        this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });

    }

    cancelPhotoValidate() {
        this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
    }

    /**
     * check photo validate for e.g. bike activity
     * @param activity 
     * @param intro show intro/tutorial popup
     */
    checkPhotoActivity(storyId: number, storyLocationId: number, activity: IActivity, storyLocationDescription: string, params: IPhotoValidatorParams): Promise<IPhotoResultResponse> {
        let promise: Promise<IPhotoResultResponse> = new Promise((resolve, reject) => {
            console.log("check photo activity: ", params);
            let defaultRes: IPhotoResultResponse = {
                valid: true,
                alreadyUploaded: false,
                heading: null,
                message: "ok",
                processedImageData: null,
                uploadedPhotoUrl: null
            };

            let uploadFromGallery: boolean = params.uploadFromGallery;

            if (!activity || this.checkPhotoValidateFlag(activity)) {
                let timeoutValue: number;
                timeoutValue = params.intro ? 1000 : 100;
                this.timeouts.ui = setTimeout(async () => {
                    try {
                        // show intro popup
                        let input: boolean = true;
                        input = await this.showPhotoValidateRequired(activity, null, storyLocationDescription, params.referenceUrl, params.isGooglePhotoExt, uploadFromGallery);

                        if (!this.platform.WEB || !this.bypassValidationOnWeb) {
                            if (input) {
                                // validation is enabled, proceed to validate
                                console.log("mode: ", params.mode);
                                // check validation mode
                                let res: IPhotoResultResponse;
                                switch (params.mode) {
                                    case EPhotoValidatorModes.compareSingleWithActivityRequired:
                                        // validate photos before activity (unlock current activity)
                                        res = await this.photos.checkPhotoActivity(storyId, storyLocationId, activity.code, params.objects, uploadFromGallery);
                                        console.log("photo activity complete");
                                        await this.showPhotoValidateResult(activity, res);
                                        break;
                                    case EPhotoValidatorModes.detectObject:
                                        // validate photos before activity (unlock current activity)
                                        res = await this.photos.checkPhotoObject(storyId, storyLocationId, params.objects, uploadFromGallery);
                                        console.log("photo detect complete");
                                        await this.showPhotoValidateResult(activity, res);
                                        break;
                                    case EPhotoValidatorModes.compareDual:
                                        // validate photos after/during activity (finish/validate current activity)
                                        if (params.compareSelfTest) {
                                            // test compare photo with self
                                            res = await this.photos.comparePhotos(storyId, storyLocationId, null, params.isGooglePhotoExt, params.objects, uploadFromGallery);
                                            console.log("photos compared");
                                            // show validation popup (image processing result)
                                            await this.showPhotoValidateResult(activity, res);
                                        } else {
                                            // compare with provided photo reference, load photo reference from url
                                            res = await this.photos.comparePhotos(storyId, storyLocationId, params.referenceUrl, params.isGooglePhotoExt, params.objects, uploadFromGallery);
                                            console.log("photos compared");
                                            // show validation popup (image processing result)
                                            await this.showPhotoValidateResult(activity, res);
                                        }
                                        break;
                                }
                                console.log("photo validation complete");
                                // after all popups and server processing, return to caller with the validation result
                                resolve(res);
                            } else {
                                // skip validation, return invalid by default
                                defaultRes.valid = false;
                                resolve(defaultRes);
                            }
                        } else {
                            // browser mock test
                            if (input) {
                                if (this.testCounter > 0) {
                                    this.testCounter -= 1;
                                    defaultRes.valid = false;
                                    resolve(defaultRes);
                                } else {
                                    this.testCounter = 2;
                                    resolve(defaultRes);
                                }
                            } else {
                                resolve(defaultRes);
                            }
                        }
                    } catch (err) {
                        reject(err);
                    }
                }, timeoutValue);
            } else {
                resolve(defaultRes);
            }
        });
        return promise;
    }


    /**
     * show photo validate required modal (before validation)
     * description of the activity
     * location photo (context)
     * @param activity 
     * @param storyLocationDescription 
     * @param photoReferenceUrl 
     */
    showPhotoValidateRequired(activity: IActivity, activityPhotoSpecs: IActivityPhotoSpecs, storyLocationDescription: string, photoReferenceUrl: string, isGooglePhotoExt: boolean, uploadFromGallery: boolean): Promise<boolean> {
        // let description: string = "<p>Select a category to view your progress in the app</p> <p>Select the middle circle to view your general progress</p>";
        let promise: Promise<boolean> = new Promise((resolve) => {

            console.log("show photo validate required");
            let description = activity ? activity.description : "";

            // description += "<p>Nearby location photo: </p>";
            if (isGooglePhotoExt) {
                photoReferenceUrl = null;
            }

            let modalParams: IPhotoValidationRequirementsViewParams = {
                descriptionExtra: storyLocationDescription,
                description: description,
                title: "Photo validation",
                photoUrl: photoReferenceUrl,
                uploadedPhotoUrl: null,
                activity: activity,
                activityPhotoSpecs: activityPhotoSpecs,
                uploadFromGallery: uploadFromGallery,
                enable: true
            };

            this.uiext.showCustomModal(null, PhotoValidationRequirementsViewComponent, {
                view: {
                    fullScreen: false,
                    transparent: false,
                    large: true,
                    addToStack: true,
                    frame: false
                },
                params: modalParams
            }).then((data: number) => {
                // this.uiext.showModal(null, EModalTypes.description, modalParams, "Photo validation", true).then((data: number) => {
                if (data === EAlertButtonCodes.ok) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((err: Error) => {
                // continue if some error occured because of the app
                console.error(err);
                resolve(true);
            });
        });
        return promise;
    }


    /**
     * show the result of photo validation
     * after validation
     * @param activity 
     * @param result 
     */
    showPhotoValidateResult(_activity: IActivity, result: IPhotoResultResponse) {
        // let description: string = "<p>Select a category to view your progress in the app</p> <p>Select the middle circle to view your general progress</p>";
        let promise = new Promise((resolve) => {
            let description: string = result.message;

            let title: string = "Photo Validation";
            if (result.valid) {
                title = "Photo Validated";
            } else {
                title = "Invalid Photo";
            }

            if (result.heading) {
                title = result.heading;
            }

            let modalParams: IPhotoValidatedViewParams = {
                description: description,
                title: title,
                photoUrl: result.processedImageData,
                isDataUrl: true
            };

            this.uiext.showCustomModal(null, PhotoValidatedViewComponent, {
                view: {
                    fullScreen: false,
                    transparent: false,
                    large: true,
                    addToStack: true,
                    frame: false
                },
                params: modalParams
            }).then((data: number) => {
                resolve(data === EAlertButtonCodes.ok);
            }).catch((err: Error) => {
                // continue if some error occured because of the app
                console.warn("error on photo validation modal");
                console.error(err);
                resolve(true);
            });
        });
        return promise;

    }

    checkPhotoValidateFlag(activity: IActivity) {
        return activity.photoValidate === 1;
    }
}




