import { Injectable } from "@angular/core";
import { ApiDef } from "../../classes/app/api";
import { IScriptStoreObj, ScriptStore, IScriptStore } from 'src/app/classes/utils/script-loader';


@Injectable({
    providedIn: 'root'
})
export class ScriptLoaderService {
    private googleMapsLoaderPromise: Promise<boolean>;
    private scripts: IScriptStoreObj = {};

    constructor(

    ) {
        console.log("script loader service created");
        ScriptStore.forEach((script: IScriptStore) => {
            this.scripts[script.name] = script;
            this.scripts[script.name].loaded = false;
        });
    }

    /**
     * load scripts
     * @param scripts 
     */
    load(scripts: string[]) {
        let promises: any[] = [];
        scripts.forEach((script) => promises.push(this.loadScript(script)));
        return Promise.all(promises);
    }

    /**
     * load a single script
     * check if already loaded
     * @param name 
     */
    loadScript(name: string) {
        let scriptDef: IScriptStore = this.scripts[name];
        console.log("loading script: " + name);
        if (!scriptDef) {
            console.log("failed");
            return Promise.reject(new Error("requested script not registered"));
        }
        return new Promise((resolve) => {
            // resolve if already loaded
            if (scriptDef.loaded) {
                resolve({ script: name, loaded: true, status: 'Already Loaded' });
            } else {
                if (scriptDef.type === 'js') {
                    // load script
                    let script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.src = scriptDef.src;
                    script.onload = () => {
                        // set loaded flag
                        scriptDef.loaded = true;
                        console.log(scriptDef.name + " loaded");
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                    script.onerror = (_error: any) => {
                        console.log(scriptDef.name + " failed");
                        resolve({ script: name, loaded: false, status: 'Failed' });
                    };
                    document.getElementsByTagName('head')[0].appendChild(script);
                } else if (scriptDef.type === 'css') {
                    // load stylesheet
                    let link = document.createElement("link");
                    link.setAttribute("rel", "stylesheet");
                    link.setAttribute("type", "text/css");
                    link.setAttribute("href", scriptDef.src);
                    let callbackReturned: boolean = false;

                    link.onload = () => {
                        // set loaded flag
                        scriptDef.loaded = true;
                        console.log(scriptDef.name + " loaded");
                        callbackReturned = true;
                        resolve({ script: name, loaded: true, status: 'Loaded' });
                    };
                    link.onerror = (_error: any) => {
                        console.log(scriptDef.name + " failed");
                        callbackReturned = true;
                        resolve({ script: name, loaded: false, status: 'Failed' });
                    };

                    // fallback for callback (browser support)
                    setTimeout(() => {
                        if (!callbackReturned) {
                            resolve({ script: name, loaded: true, status: 'Will Load' });
                        }
                    }, 1000);

                    document.getElementsByTagName('head')[0].appendChild(link);
                }
            }
        });
    }

    /**
     * load google maps api
     * particular script with special loaded event
     */
    loadGoogleMaps(useWebGL: boolean): Promise<boolean> {
        // First time 'load' is called?
        if (!this.googleMapsLoaderPromise) {

            // Make promise to load
            this.googleMapsLoaderPromise = new Promise(resolve => {

                // Set callback for when google maps is loaded.
                // window['__onGoogleLoaded'] = (ev) => {
                //     resolve('google maps api loaded');
                // };
                window['__onGoogleLoaded'] = () => {
                    resolve(true);
                };

                let node = document.createElement('script');               
                if (!useWebGL) {
                    node.src = "https://maps.googleapis.com/maps/api/js?key=" + ApiDef.googleMapsApiKeyJS + "&libraries=geometry&callback=__onGoogleLoaded&libraries=places";
                } else {
                    node.src = "https://maps.googleapis.com/maps/api/js?key=" + ApiDef.googleMapsApiKeyJS + "&v=beta&libraries=geometry&callback=__onGoogleLoaded&libraries=places";
                }
                node.type = 'text/javascript';
                document.getElementsByTagName('head')[0].appendChild(node);
            });
        }
        // Always return promise. When 'load' is called many times, the promise is already resolved.
        return this.googleMapsLoaderPromise;
    }
}
