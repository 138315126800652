import { Injectable } from '@angular/core';
import { GenericDataService } from '../general/data/generic';
import { IGetCountriesWithDetailsAndNearby } from 'src/app/classes/def/world/world';
import { GeometryUtils } from '../utils/geometry-utils';
import { ILatLng } from 'src/app/classes/def/map/coords';


@Injectable({
    providedIn: 'root'
})
export class WorldDataService {

    cache: IGetCountriesWithDetailsAndNearby;
    // check distance from last scan location to determine if a new scan is required
    prevLat: number = null;
    prevLng: number = null;

    constructor(
        public generic: GenericDataService
    ) {
        console.log("world data service created");
    }

    /**
     * get countries list for local storyline
     * @param lat 
     * @param lng 
     * @param reload 
     */
    getCountriesWithDetailsAndNearby(lat: number, lng: number, categoryCode: number, reload: boolean): Promise<IGetCountriesWithDetailsAndNearby> {
        let promise: Promise<IGetCountriesWithDetailsAndNearby> = new Promise((resolve, reject) => {
            if (this.cache != null) {
                let resolveCache: boolean = false;
                if (!reload) {
                    if (this.prevLat != null && this.prevLng != null) {
                        // check distance from last request
                        // allow cache if distance is not too large (same city boundary)
                        if (GeometryUtils.getDistanceBetweenEarthCoordinates(new ILatLng(lat, lng), new ILatLng(this.prevLat, this.prevLng), Number.MAX_VALUE) < 10000) {
                            resolveCache = true;
                        }
                    } else {
                        resolveCache = true;
                    }
                }
                if (resolveCache) {
                    resolve(this.cache);
                    return;
                }
            }

            this.generic.genericPostStandardWData("/world/main/get-countries-details-nearby", {
                lat: lat,
                lng: lng,
                includeStoryCount: true,
                storyCategoryCode: categoryCode
            }).then((resp: IGetCountriesWithDetailsAndNearby) => {
                this.cache = resp;
                this.prevLat = lat;
                this.prevLng = lng;
                resolve(resp);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get weather data from provider w/ cache
     * @param lat 
     * @param lng 
     * @returns 
     */
    getWeatherData(lat: number, lng: number, celsius: boolean) {
        return this.generic.genericPostStandardWData("/world/weather/get-forecast", {
            lat: lat,
            lng: lng,
            useCache: true,
            celsius: celsius
        });
    }
}
