<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" [title]="title" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back(true)"
    rightButton="settings" [rightIcon]="true" (rightClick)="presentPopover()" [loading]="!loaded">
  </leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <!-- (swipe)="swipeEvent($event)" -->
  <div class="view-frame">

    <div class="bg-container bg-container-fill" [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: photoUrl}">

      <div class="content">
        <div fxLayout="column" class="content-height no-scroll" [@showState]="showState">

          <ion-grid *ngIf="event" class="padding-horizontal-s padding-top-s full-w">
            <div class="text-color-overlay">

              <ion-row class="padding-xs">
                <ion-col size="12" class="text-center">
                  <span class="heading text-color-primary">{{event.name}}</span>
                </ion-col>
              </ion-row>

              <ion-row class="padding-xs">
                <ion-col class="text-left" size="4">
                  <span class="info-text-s bold">{{event.startDate | myDate2}}</span>
                </ion-col>
                <ion-col size="4">

                </ion-col>
                <ion-col class="text-right" size="4">
                  <span class="info-text-s bold">{{event.endDate | myDate2}}</span>
                </ion-col>
              </ion-row>


              <ion-row class="padding-xs">
                <ion-col size="12" class="progress-bar-alternate">
                  <mat-progress-bar mode="determinate" class="progress-rounded" color="primary" [value]="eventProgress">
                  </mat-progress-bar>
                </ion-col>
              </ion-row>

              <ion-row class="padding-xs">
                <ion-col class="text-left" size="4">
                  <span class="info-text-s bold">{{event.startDate | myTime}}</span>
                </ion-col>
                <ion-col size="4" class="text-center">
                  <span class="info-text-s bold text-color-primary">{{event.eventType.name | uppercase}}</span>
                </ion-col>
                <ion-col class="text-right" size="4">
                  <span class="info-text-s bold">{{event.endDate | myTime}}</span>
                </ion-col>
              </ion-row>


              <div class="scrollbar-y event-data-height">

                <div *ngIf="entryPlace && !lockedState">
                  <ion-list class="padding-top-s">
                    <ion-item class="list-item transparent-bg">
                      <place-card class="full-w" [place]="entryPlace" tappable></place-card>
                    </ion-item>
                  </ion-list>
                </div>

                <ion-row class="padding-xs">
                  <ion-col size="12">
                    <div class="padding-horizontal-s">
                      <div [innerHTML]="event.description" class="info-text-m description-frame text-color-primary">
                      </div>
                    </div>
                  </ion-col>
                </ion-row>
              </div>
            </div>
          </ion-grid>
        </div>
        <div fxFlex></div>
        <div>
          <ion-grid class="full-w padding-bottom-xs">
            <ion-row *ngIf="lockedState">
              <ion-col size="12">
                <ion-button class="action-button-fill button-color-alternate" (click)="loadEventWrapper()" [disabled]="!loaded">
                  <span class="button-font-size-s">unlock</span>
                  <icon-wrapper slot="icon-only" [icon]="appIconsStandard.locked"></icon-wrapper>
                </ion-button>
              </ion-col>
            </ion-row>
            <ion-row *ngIf="!lockedState">

              <ion-col size="3">
                <ion-button [class]="viewContainer.main.buttons.stats.class"
                  [disabled]="!loaded || viewContainer.main.buttons.stats.disabled"
                  (click)="viewContainer.main.buttons.stats.callback()">
                  <icon-wrapper [custom]="viewContainer.main.buttons.stats.customIcon" slot="icon-only"
                    [icon]="viewContainer.main.buttons.stats.icon"></icon-wrapper>
                </ion-button>
              </ion-col>
              <ion-col size="3">
                <ion-button [class]="viewContainer.main.buttons.inventory.class"
                  [disabled]="!loaded || viewContainer.main.buttons.inventory.disabled"
                  (click)="viewContainer.main.buttons.inventory.callback()">
                  <icon-wrapper [custom]="viewContainer.main.buttons.inventory.customIcon" slot="icon-only"
                    [icon]="viewContainer.main.buttons.inventory.icon"></icon-wrapper>
                </ion-button>
              </ion-col>
              <ion-col size="3">
                <ion-button [class]="viewContainer.main.buttons.join.class"
                  [disabled]="!loaded || viewContainer.main.buttons.join.disabled"
                  (click)="viewContainer.main.buttons.join.callback()">
                  <icon-wrapper [custom]="viewContainer.main.buttons.join.customIcon" slot="icon-only"
                    [icon]="viewContainer.main.buttons.join.icon"></icon-wrapper>
                </ion-button>
              </ion-col>
              <ion-col size="3">
                <ion-button [class]="viewContainer.main.buttons.start.class"
                  [disabled]="!loaded || viewContainer.main.buttons.start.disabled"
                  (click)="viewContainer.main.buttons.start.callback()">
                  <icon-wrapper [custom]="viewContainer.main.buttons.start.customIcon" slot="icon-only"
                    [icon]="viewContainer.main.buttons.start.icon"></icon-wrapper>
                </ion-button>
              </ion-col>

            </ion-row>
          </ion-grid>
        </div>
      </div>
    </div>
  </div>
</ion-content>