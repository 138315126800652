import { AppConstants } from "../app/constants";
import { IGameItemIAP } from "../def/items/game-item";

export class PricingUtils {
    static defaultCurrency: string = "usd";

    /**
     * only for client (server uses default price)
     * @param itemIAP 
     * @returns 
     */
    static getItemPricingValueFromIAP(itemIAP: IGameItemIAP) {
        let price: string = null;
        if (itemIAP != null && itemIAP.defaultPrice != null) {
            price = itemIAP.defaultPrice;
            price = price.split(" ")[0];
        }
        return price;
    }

    /**
     * 
     * @param defaultPrice 12.99
     * @param storeDeduction 
     * @returns 
     */
    static getStripeCheckoutPriceInteger(defaultPrice: string, storeDeduction: boolean) {
        if (defaultPrice == null) {
            return null;
        }
        let priceInteger: number = Math.floor(Number.parseFloat(defaultPrice) * (storeDeduction ? AppConstants.gameConfig.externalPaymentDeduction : 1) * 100); // account for app store fee deduction  
        return priceInteger;
    }

    /**
     * 
     * @param defaultPrice 12.99
     * @param storeDeduction 
     * @returns 
     */
    static getStripeCheckoutPrice(defaultPrice: string, defaultCurrency: string, storeDeduction: boolean) {
        if (defaultPrice == null) {
            return null;
        }
        let priceDecimal: number = PricingUtils.getStripeCheckoutPriceInteger(defaultPrice, storeDeduction) / 100;
        let defaultPriceStripe: string = "" + priceDecimal + " " + (defaultCurrency != null ? defaultCurrency : PricingUtils.defaultCurrency);
        return defaultPriceStripe;
    }
}

