<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="true" title="Signup" [loading]="loading"></leplace-toolbar>
</ion-header>

<!-- <ion-content class="theme-bg"> -->
<ion-content [ngClass]="theme">
  <!-- <div ></div> -->
  <!-- <div class="logo">
        <img src="assets/img/appicon.svg" alt="Ionic logo">
      </div> -->

  <div class="view-frame-small theme-background">
    <!-- dynamic-kb-frame -->
    <div class="padding-s" fxLayout="column">

      <span class="logo no-padding text-color-alternate-high">
        Leplace World
      </span>
      <!-- <span class="sub-logo sub-logo-padding-left no-padding center-text text-color-alternate-high">
        by Leplace
      </span> -->

      <form #signupForm="ngForm" novalidate class="scrollbar-y" (submit)="onSignup(signupForm)">
        <ion-list no-lines>
          <ion-item class="transparent">
            <ion-label position="stacked" class="text-color-primary info-text-s">Email</ion-label>
            <ion-input [(ngModel)]="signup.email" ngDefaultControl name="email" type="email" #email="ngModel"
              spellcheck="false" autocapitalize="off" required
              pattern="[A-Za-z0-9._%+-]{2,}@[a-zA-Z-_.]{2,}[.]{1}[a-zA-Z]{2,}" placeholder="email"
              class="info-text-m text-color-primary">
              <!-- pattern="[^@\s]+@[^@\s]+\.[^@\s]+" -->
              <!-- https://stackoverflow.com/questions/5601647/html5-email-input-pattern-attribute -->
              <!-- pattern=”^[a-zA-Z0–9_.+-]+@[a-zA-Z0–9-]+.[a-zA-Z0–9-.]+$” -->
              <!-- abc@example.com # Minimum three characters
                ABC.xyz@example.com # Accepts Caps as well.
                abce.xyz@example.co.in # Accepts . before @ -->
            </ion-input>
          </ion-item>

          <span class="info-text-xs text-color-warn-contrast" [hidden]="email.valid || (email.pristine && !submitted)"
            color="danger" padding-left>
            Email is required
          </span>


          <ion-item class="transparent">
            <ion-label position="stacked" class="text-color-primary info-text-s">Username</ion-label>
            <ion-input [(ngModel)]="signup.username" ngDefaultControl name="username1" #username1="ngModel" type="text"
              required placeholder="username" class="text-color-primary info-text-m">
            </ion-input>
          </ion-item>

          <span class="info-text-xs text-color-warn-contrast"
            [hidden]="username1.valid || (username1.pristine && !submitted)" color="danger" padding-left>
            Username is required
          </span>

          <ion-item class="transparent">
            <ion-label position="stacked" class="text-color-primary info-text-s">Password</ion-label>
            <ion-input [(ngModel)]="signup.password" ngDefaultControl name="password1" #password1="ngModel"
              type="password" required placeholder="password" class="text-color-primary info-text-m">
            </ion-input>
          </ion-item>


          <span class="info-text-xs text-color-warn-contrast"
            [hidden]="password1.valid || (password1.pristine && !submitted)" color="danger" padding-left>
            Password is required
          </span>

          <ion-item class="transparent">
            <ion-label position="stacked" class="text-color-primary info-text-s">Confirm password</ion-label>
            <ion-input [(ngModel)]="checkPassword" ngDefaultControl name="password2" #password2="ngModel"
              type="password" required pattern="{{signup.password}}" placeholder="password"
              class="text-color-primary info-text-m">
            </ion-input>
          </ion-item>

          <span class="info-text-xs text-color-warn-contrast"
            [hidden]="password2.valid || (password2.pristine && !submitted)" color="danger" padding-left>
            Passwords do not match
          </span>

        </ion-list>

        <ion-button class="action-button-fill button-color-primary-70" type="submit" block>
          <span class="button-font-size-s">
            Sign up
          </span>
        </ion-button>
        <ion-button class="action-button-fill button-color-transparent margin-top-s" (click)="onLogin(null, null)"
          type="submit" block outline>
          <span class="text-color-primary button-font-size-s">
            Log in
          </span>
        </ion-button>

        <div tappable class="margin-top-s">
          <span class="as-text element-selector button-font-size-s text-color-primary"
            (click)="requestNewValidationEmail(signupForm)">
            Resend validation email
          </span>
        </div>

      </form>
    </div>
  </div>
</ion-content>