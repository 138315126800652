
import { Injectable } from '@angular/core';
import { AdMobFree, AdMobFreeBannerConfig } from '@ionic-native/admob-free/ngx';
import { BehaviorSubject } from 'rxjs';
import { AdsDef, ERewardExitCodes } from '../../apis/ads-def';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { AdsTemplateService } from './ads-template';
import { ApiDef } from 'src/app/classes/app/api';

interface ExtendedAdMobFreeBannerConfig extends AdMobFreeBannerConfig {
    adExtras: {
        npa?: number
    },
    testDevices: string[]
};

/**
 * https://github.com/ratson/cordova-plugin-admob-free/issues/210
 * 
 * isTesting adds the (test) device id as emulator
 * testDevices does the same thing but with custom ids if given
 * 
 * enable test devices: https://developers.google.com/admob/android/test-ads#enable_test_devices
 * testing with mediation: https://developers.google.com/admob/android/test-ads#testing_with_mediation
 */
@Injectable({
    providedIn: 'root'
})
export class AdmobFreeAdsService implements AdsTemplateService {

    adId = AdsDef.adId;
    adIdIos = AdsDef.adIdIOS;

    rewardAdConfig: ExtendedAdMobFreeBannerConfig = {
        // id: "ca-app-pub-3940256099942544/8691691433", //test
        isTesting: false,
        autoShow: true,
        adExtras: { npa: 1 },
        testDevices: []
    };

    interAdConfig: ExtendedAdMobFreeBannerConfig = {
        // id: "ca-app-pub-3940256099942544/8691691433", //test
        isTesting: false,
        autoShow: true,
        adExtras: { npa: 1 },
        testDevices: []
    };

    bannerAdConfig: ExtendedAdMobFreeBannerConfig = {
        // id: "ca-app-pub-3940256099942544/8691691433", //test
        isTesting: false,
        autoShow: true,
        adExtras: { npa: 1 },
        testDevices: []
    };

    reward: boolean = false;

    watchRewardCallback: BehaviorSubject<number>;
    watchRewardSub;

    tryAgainCounter: number = 0;
    maxRetry: number = 5;
    withRetry: boolean = false;

    /** non-personalized ads, default: true */
    npa: boolean = true;


    // https://www.techiediaries.com/ionic-cordova-admob-ads/
    constructor(
        private admobFree: AdMobFree
    ) {
        console.log("ads service created");
        this.watchRewardCallback = new BehaviorSubject(null);
    }

    setup() {
        this.loadCallbacks();
        this.setTestDevices();
        console.log("ads/admobfree setup complete");
    }

    /**
     * check consent, update ad config to include the npa flag
     * @param consentStatus 
     */
    updateConsent(consentStatus: boolean) {
        if (consentStatus === true) {
            this.npa = false;
            this.bannerAdConfig.adExtras = {};
            this.interAdConfig.adExtras = {};
            this.rewardAdConfig.adExtras = {};
        } else {
            this.npa = true;
            this.bannerAdConfig.adExtras = {
                npa: 1
            };
            this.interAdConfig.adExtras = {
                npa: 1
            };
            this.rewardAdConfig.adExtras = {
                npa: 1
            };
        }
        console.log("update consent (reward) ", this.rewardAdConfig);
    }


    /**
     * set test devices to allow requesting an ad from specified devices without getting banned by Google
     * while still using real ads from Admob account
     */
    setTestDevices() {
        // let testDevices: string[] = ["F6D7EA44868CA298D125449CC7BC13C6"];
        let testDevices: string[] = [];

        if (GeneralCache.os === EOS.ios) {
            // [iOS] not working anyways, using test device id automatically if isTesting is set true
            // [iOS] still not sure about reward ads
            if (ApiDef.admobTestDevicesIOS.length > 0) {
                console.log("using admob test devices (iOS) from db: ", ApiDef.admobTestDevicesIOS);
                testDevices = ApiDef.admobTestDevicesIOS;
            }
        } else {
            if (ApiDef.admobTestDevices.length > 0) {
                console.log("using admob test devices from db: ", ApiDef.admobTestDevices);
                testDevices = ApiDef.admobTestDevices;
            }
        }

        if (testDevices && testDevices.length > 0) {
            this.bannerAdConfig.testDevices = testDevices;
            this.interAdConfig.testDevices = testDevices;
            this.rewardAdConfig.testDevices = testDevices;
        }
    }

    /**
    * load ad ids, os based
    */
    setAdIds(testing: boolean) {
        if (testing) {
            // testing
            this.bannerAdConfig.isTesting = true;
            this.interAdConfig.isTesting = true;
            this.rewardAdConfig.isTesting = true;

            this.bannerAdConfig.id = AdsDef.adIdTesting.banner;
            this.interAdConfig.id = AdsDef.adIdTesting.interstitial;
            this.rewardAdConfig.id = AdsDef.adIdTesting.reward;
        } else {
            // real ads
            this.bannerAdConfig.isTesting = false;
            this.interAdConfig.isTesting = false;
            this.rewardAdConfig.isTesting = false;

            switch (GeneralCache.os) {
                case EOS.ios:
                    this.bannerAdConfig.id = this.adIdIos.banner;
                    this.interAdConfig.id = this.adIdIos.interstitial;
                    this.rewardAdConfig.id = this.adIdIos.reward;
                    break;
                case EOS.android:
                    this.bannerAdConfig.id = this.adId.banner;
                    this.interAdConfig.id = this.adId.interstitial;
                    this.rewardAdConfig.id = this.adId.reward;
                    break;
            }
        }

        console.log("set ad ids (reward) ", this.rewardAdConfig);
    }


    showBanner(): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this.admobFree.banner.config(this.bannerAdConfig);
            this.admobFree.banner.prepare().then(() => {
                console.log("ad prepared");
                this.admobFree.banner.show().then(() => {
                    console.log("ad shown");
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
                // banner Ad is ready
                // if we set autoShow to false, then we will need to call the show method here
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
    }

    loadCallbacks() {
        this.admobFree.on("admob.rewardvideo.events.OPEN").subscribe((res) => {
            console.log("open event fired");
            console.log(res);
            this.watchRewardCallback.next(ERewardExitCodes.open);
        });
        this.admobFree.on("admob.rewardvideo.events.LOAD").subscribe((res) => {
            console.log("load event fired");
            console.log(res);
            this.watchRewardCallback.next(ERewardExitCodes.load);
        });
        this.admobFree.on("admob.rewardvideo.events.START").subscribe((res) => {
            console.log("start event fired");
            console.log(res);
            this.watchRewardCallback.next(ERewardExitCodes.start);
        });
        this.admobFree.on("admob.rewardvideo.events.REWARD").subscribe((res) => {
            console.log("reward event fired");
            // type: "admob.rewardvideo.events.REWARD"
            // rewardType: "coins"
            // rewardAmount: 10
            console.log(res);
            this.watchRewardCallback.next(ERewardExitCodes.reward);

        });
        this.admobFree.on("admob.rewardvideo.events.CLOSE").subscribe((res) => {
            console.log("close event fired");
            console.log(res);
            this.watchRewardCallback.next(ERewardExitCodes.close);
        });
        this.admobFree.on("admob.rewardvideo.events.LOAD_FAIL").subscribe((res) => {
            console.log("load fail event fired");
            console.log(res);
            this.watchRewardCallback.next(ERewardExitCodes.fail);
        });
        this.admobFree.on("admob.rewardvideo.events.EXIT_APP").subscribe((res) => {
            console.log("exit app event fired");
            console.log(res);
            this.watchRewardCallback.next(ERewardExitCodes.exit);
        });
    }

    /**
     * prepare the reward video
     * watch reward callbacks (events)
     * try again loading if load failed the first time
     * resolve when reward issued (true) or when closed before reward (false)
     * resolve (null) when failed to load
     */
    handleRewardVideo(onPrepared: () => Promise<any>): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {

            console.log("on handleRewardVideo for ad: ", this.rewardAdConfig);

            this.tryAgainCounter = 0;
            this.reward = false;
            this.watchRewardCallback.next(null);

            let onLoadingFailed = () => {
                if (this.withRetry) {
                    // try again loading the reward video
                    this.tryAgainCounter += 1;
                    if (this.tryAgainCounter <= this.maxRetry) {
                        setTimeout(() => {
                            console.log("load retry");
                            loadAdsRetry();
                        }, 1000);
                    } else {
                        resolve(null);
                    }
                } else {
                    resolve(null);
                }
            };

            let loadAdsRetry = async () => {
                try {
                    this.admobFree.rewardVideo.config(this.rewardAdConfig);
                    console.log("preparing ad");
                    await this.admobFree.rewardVideo.prepare();
                    console.log("ad prepared");
                    let res = await this.admobFree.rewardVideo.show();
                    GeneralCache.adShown = true;
                    console.log("ad shown");
                    console.log(res);

                    if (this.watchRewardSub) {
                        this.watchRewardSub.unsubscribe();
                    }

                    // if (onPrepared) {
                    //     await onPrepared();
                    // }

                    this.watchRewardSub = this.watchRewardCallback.subscribe((res: number) => {
                        console.log("reward exit code: ", res);
                        if (res != null) {
                            switch (res) {
                                case ERewardExitCodes.open:

                                    break;
                                case ERewardExitCodes.load:
                                    if (onPrepared) {
                                        onPrepared();
                                    }
                                    break;
                                case ERewardExitCodes.start:

                                    break;
                                case ERewardExitCodes.reward:
                                    this.reward = true;
                                    break;
                                case ERewardExitCodes.close:
                                    resolve(this.reward);
                                    break;
                                case ERewardExitCodes.fail:
                                    onLoadingFailed();
                                    break;
                                case ERewardExitCodes.exit:
                                    // still has to close ad when returning to app
                                    // resolve(false);
                                    break;
                                default:
                                    reject(new Error("Unknown exit code"));
                                    break;
                            }
                        }
                    }, (err: Error) => {
                        console.error(err);
                        reject(err);
                    });
                } catch (err) {
                    console.error(err);
                    onLoadingFailed();
                }
            };

            loadAdsRetry();
        });
        return promise;
    }


    showInterstitial(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            this.admobFree.interstitial.config(this.interAdConfig);
            this.admobFree.interstitial.prepare().then(() => {
                console.log("ad prepared");
                this.admobFree.interstitial.show().then((res) => {
                    console.log("ad shown");
                    // console.log(res);
                    resolve(res);
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


}
