<span class="info-text-s bold text-color-primary padding-left-s" *ngIf="!data.icon">
  {{data.name}}</span>
<ion-row class="text-color-primary" *ngIf="data.icon">
  <ion-col *ngIf="!data.icon2" size="12">
    <icon-wrapper [icon]="data.icon" [custom]="data.customIcon" class="hud-icon" customClass="leplace-icon-s">
    </icon-wrapper>
  </ion-col>

  <div *ngIf="data.icon2">
    <ion-col size="6">
      <icon-wrapper [icon]="data.icon" [custom]="data.customIcon" class="hud-icon" customClass="leplace-icon-s">
      </icon-wrapper>
    </ion-col>
    <ion-col size="6">
      <icon-wrapper [icon]="data.icon2" [custom]="data.customIcon" class="hud-icon" customClass="leplace-icon-s">
      </icon-wrapper>
    </ion-col>
  </div>
</ion-row>