import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { EStoryReleaseFlag, IStoryExtended } from 'src/app/classes/def/core/story';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { IGameItemIAP } from 'src/app/classes/def/items/game-item';
import { StoryUtils } from 'src/app/classes/utils/story-utils';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { GameStatsUtils } from 'src/app/services/app/utils/game-stats-utils';
import { timer } from 'rxjs';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { IAPCoreService } from 'src/app/services/apis/iap-rc';

@Component({
  selector: 'story-info',
  templateUrl: './story-info.component.html',
  styleUrls: ['./story-info.component.scss'],
})
export class StoryInfoComponent implements OnInit, OnChanges, OnDestroy {

  locationType: string = "";

  @Input()
  data: IStoryExtended;

  @Input()
  large: boolean;

  @Input()
  showCategory: boolean;

  @Input()
  reload: boolean;

  timerSub = null;
  state: boolean = false;
  stateLabel: boolean = false;
  css: string;
  isBlink: boolean = false;

  level: number = 0;
  hasLevel: boolean = false;

  paidStory: boolean = false;
  qrEnabled: boolean = false;
  locked: boolean = false;
  // photoUrlLoaded: string;

  price: string = "";
  pricePromo: string = "";
  levelDisp: string = "";
  rewardDisp: string = "";
  xpDisp: string = "";
  unlisted: boolean = false;
  private: boolean = false;

  isLoading: boolean = false;
  validIap: boolean = false;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  constructor(
    public iapService: IAPCoreService
  ) {

  }

  checkLabel() {
    if (this.unlisted) {
      return "UNLISTED";
    }
    if (this.private) {
      return "DEV";
    }
    return null;
  }

  ngOnInit() {
    if (this.data) {
      if (this.data.story) {
        if (this.data.story.premium) {
          this.paidStory = true;
          if (this.data.story.itemIapCode) {
            this.checkIap();
            let price: string = this.data.story.itemIap ? this.data.story.itemIap.price : "";
            this.price = price;
          } else {
            if (this.data.story.priceCoins) {
              let price: string = this.data.story.priceCoins ? this.data.story.priceCoins + "" : "";
              this.price = price + " LP";
            } else {
              this.price = "FREE";
            }
          }
        }

        if (this.data.story.partnerUnlock) {
          this.price = "UNLOCK";
          this.pricePromo = null;
        }

        this.unlisted = this.data.story.devOnly === EStoryReleaseFlag.unlisted;
        this.private = this.data.story.devOnly === EStoryReleaseFlag.devOnly;

        if (StoryUtils.checkQRUnlock(this.data.story)) {
          this.qrEnabled = true;
        }

        this.locked = this.data.story.locked;

        if (this.data.story.level != null) {
          this.level = this.data.story.level;
          this.hasLevel = true;
        }

        this.levelDisp = "Level " + this.level;

        if (this.showCategory && this.data.story.categoryName) {
          // this.levelDisp += " " + this.data.story.categoryName.toUpperCase();
          this.levelDisp = this.data.story.categoryName.toUpperCase() + " (" + this.level + ")";
        }

        this.rewardDisp = GameStatsUtils.getStoryRewardDisp(this.data.story);

        if (this.data.story.resume) {
          this.isBlink = true;
          this.enableBlink();
        }

      }
    }
  }

  checkIap() {
    this.iapService.syncPreloadedIAPData(this.data.story);
    let itemIap: IGameItemIAP = this.data.story.itemIap;
    if (itemIap) {
      if (GeneralCache.checkPlatformOS() === EOS.ios) {
        this.price = itemIap.priceIos;
        if (this.data.story.itemIapPromo != null) {
          this.pricePromo = this.data.story.itemIapPromo.priceIos;
        }
      } else {
        this.price = itemIap.price;
        if (this.data.story.itemIapPromo != null) {
          this.pricePromo = this.data.story.itemIapPromo.price;
        }
      }
      if (itemIap.hiddenPrice) {
        this.price = "IAP";
      }
      this.validIap = itemIap.valid;
      if (!itemIap.iapLoaded) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'reload':
            this.checkIap();
            break;
        }
      }
    }
  }

  clearTimer() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
  }

  ngOnDestroy() {
    this.clearTimer();
  }

  /**
   * start blinking core
   * @param count 
   */
  enableBlink() {
    if (!this.timerSub) {
      let timer1 = timer(0, 500);
      let k: number = 0;
      this.timerSub = timer1.subscribe(() => {
        if (k == 0) {
          if (this.state) {
            this.stateLabel = true;
          } else {
            this.stateLabel = false;
          }
        }
        k++;
        if (k == 2) {
          k = 0;
          this.state = !this.state;
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  /**
   * stop blinking
   */
  disableBlink() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.state = false;
    this.stateLabel = false;
  }

  showContinue() {
    return this.isBlink && !this.stateLabel;
  }
}
