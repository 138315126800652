<div *ngIf="availableItems">
   <ion-grid class="full-w">
    <ion-row *ngFor="let itemRow of itemGrid; let i = index">
      <ion-col *ngFor="let item of itemRow" class="padding-s">
        <activity-param class="icon-contrast" [data]="item" [interact]="interact" [showLabel]="showLabel"
          (action)="itemAction($event, item)"></activity-param>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<div *ngIf="!availableItems" class="padding-s center-text">
  <span class="center-text info-text-m bold text-color-primary">{{emptyMessage}}</span>
</div>