import { IPlatformFlags } from "../../classes/def/app/platform";
import { Injectable } from "@angular/core";
import { LocationMonitorService } from "./location-monitor";
import { IGeolocationResult } from "../../classes/def/map/map-data";
import { Geolocation, Geoposition, PositionError } from "@ionic-native/geolocation/ngx";
import { BackgroundGeolocation } from "@ionic-native/background-geolocation/ngx";
import { SettingsManagerService } from "../general/settings-manager";
import { EGeolocationModes, IGeolocationOptionsSetup, EGPSTimeout, ELocationTimeoutEvent } from "../../classes/def/map/geolocation";
import { ResourceManager } from "../../classes/general/resource-manager";
import { AnalyticsService } from "../general/apis/analytics";
import { Platform } from "@ionic/angular";
import { SleepUtils } from '../utils/sleep-utils';
import { UiExtensionService } from '../general/ui/ui-extension';
import { BackgroundModeWatchService } from "../general/apis/background-mode-watch";
import { GeometryUtils } from "../utils/geometry-utils";
import { AppConstants } from "src/app/classes/app/constants";
import { EKalmanContext, KalmanFilterService } from "../utils/kalman-filter";
import { timer } from "rxjs";
import { BufferUtilsService } from "../app/utils/buffer-utils";
import { EBufferContext } from "src/app/classes/utils/buffer";
import { GeolocationUtils } from "./geolocation-utils";
import { ArrayFilters } from "../utils/array-filters";
import { ETimeoutQueue, TimeoutQueueService } from "../general/timeout-queue";
import { GeneralCache } from "src/app/classes/app/general-cache";
import { ILatLng } from "src/app/classes/def/map/coords";
import { WebviewUtilsService } from "../app/utils/webview-utils";

interface ILocationInterpolateSpec {
    distanceDelta: number;
    timeDelta: number;
    speed: number;
    heading: number;
    currentTime: number;
    interpolateTime: number;
    init: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class LocationManagerService {
    // private static _instance: LocationManagerService;

    locationObs = null;
    mode: number;
    platform: IPlatformFlags = {} as IPlatformFlags;
    // LocationMonitorService: LocationMonitorService;
    currentLoc: IGeolocationResult = null;
    prevLoc: IGeolocationResult = null;
    filteredLoc: IGeolocationResult = null;
    coordsBuffer: ILatLng[] = [];
    coordsBufferSize: number = 5;

    interpolateSpec: ILocationInterpolateSpec = {
        distanceDelta: 0,
        timeDelta: 0,
        speed: 0,
        heading: 0,
        currentTime: 0,
        interpolateTime: 0,
        init: false
    };

    interpolateTimedelta: number = 500;
    interpolateLocationSize: number = 5;

    // check when first starting up the gps location watch
    locationFixed: boolean = false;

    locationResetWatchRetryActive: boolean = false;

    locationNotFixedTimeoutLimit: number = 5;
    locationNotFixedTimeoutCounter: number = 0;
    locationTimeoutLimit: number = 3;
    locationTimeoutCounter: number = 0;
    locationSlowCounterLimit: number = 10;
    locationSlowCounter: number = 0;
    locationSlowWarningCounter: number = 0;

    singleRequestOptions: IGeolocationOptionsSetup = {
        navigator: {
            enableHighAccuracy: false, // default: false
            timeout: EGPSTimeout.gps, // default: Infinity
            maximumAge: 60000 // default: 0
            // maximumAge: 10000
        },
        cordova: {
            maximumAge: 60000,
            timeout: EGPSTimeout.gps,
            enableHighAccuracy: false
        },
        background: {
            locationProvider: 1,
            desiredAccuracy: 10, // high 0, medium 10, low 100, passive 1000
            stationaryRadius: 5, // distance moved from stationary to enable full tracking
            distanceFilter: 1, // min distance for update event
            debug: false, //  enable this hear sounds for background-geolocation life-cycle.
            stopOnTerminate: true, // enable this to clear background location settings when the app terminates
            // added
            stopOnStillActivity: false,
            interval: 200,
            saveBatteryOnBackground: true,
            notificationTitle: "Background location",
            notificationText: "enabled"
        },
        web: {
            enableHighAccuracy: false, // default: false
            timeout: EGPSTimeout.gps, // default: Infinity
            // maximumAge: 0 // default: 0
            maximumAge: 60000
        }
    };

    watchOptions: IGeolocationOptionsSetup = {
        navigator: {
            enableHighAccuracy: true,
            timeout: EGPSTimeout.gps,
            maximumAge: 60000 // was 0
        },
        cordova: {
            maximumAge: 60000, // was 0
            timeout: EGPSTimeout.gps,
            enableHighAccuracy: true
            // enableHighAccuracy: false
        },
        background: {
            locationProvider: 1,
            desiredAccuracy: 10, // high 0, medium 10, low 100, passive 1000
            stationaryRadius: 5, // distance moved from stationary to enable full tracking
            distanceFilter: 1, // min distance for update event
            debug: false, //  enable this hear sounds for background-geolocation life-cycle.
            stopOnTerminate: true, // enable this to clear background location settings when the app terminates
            // added
            stopOnStillActivity: false,
            interval: 200,
            saveBatteryOnBackground: true,
            notificationTitle: "Background location",
            notificationText: "enabled"
        },
        web: {
            enableHighAccuracy: true,
            timeout: EGPSTimeout.gps,
            maximumAge: 60000
        }
    };

    rateLimiterWeb: number = 1000;

    errorFlag = {
        watchPosition: false
    };

    observables = {
        backgroundGeolocation: null,
        myUnifiedGeolocation: null
    };

    watches = {
        nativeGeolocation: null,
        cordovaGeolocation: null
    };

    subscription = {
        cordovaGeolocation: null,
        watchInterpolate: null
    };

    flags = {
        /* pause/resume watch location for bg mode */
        watchLocationMasterEnabled: false, // prevent starting watch on resume before enabled via gmap
        watchLocationEnabled: false,
        promiseEnabled: true,
        backgroundGeolocationInit: false,
        backgroundGeolocationEnabled: false,
        masterLock: true, // true: unlocked
        interpolate: false,
        simulateWatchLocationFailTest: false
    };

    timeouts = {
        location: null,
        watchTimeout: null,
        interpolate: null
    };

    constructor(
        public locationMonitor: LocationMonitorService,
        public backgroundGeolocation: BackgroundGeolocation,
        public geolocation: Geolocation,
        public settings: SettingsManagerService,
        public analytics: AnalyticsService,
        public uiext: UiExtensionService,
        public bgmWatch: BackgroundModeWatchService,
        public kalmanFilter: KalmanFilterService,
        public bufferUtils: BufferUtilsService,
        public arrayFilters: ArrayFilters,
        public timeoutQueueService: TimeoutQueueService,
        public webView: WebviewUtilsService,
        public plt: Platform
    ) {
        console.log("location manager service created");
        // this.LocationMonitorService = LocationMonitorService.getInstance();       
        this.mode = EGeolocationModes.native;
        this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.onPlatformLoaded(SettingsManagerService.settings.platformFlags);
            }
        }, (err: Error) => {
            console.error(err);
        });

        this.webView.ready().then(() => {
            // this.setBackgroundGeolocation(true);
        });
    }

    setBackgroundGeolocation(enabled: boolean) {
        if (enabled) {
            this.backgroundGeolocation.start().then(() => {

            }).catch((err: Error) => {
                console.error(err);
            });
        } else {
            this.backgroundGeolocation.stop().then(() => {

            }).catch((err: Error) => {
                console.error(err);
            });
        }
    }

    setGeolocationMode(mode: number) {
        this.mode = mode;
        if (this.platform.MOBILE_LIVERELOAD) {
            switch (mode) {
                case EGeolocationModes.native:
                case EGeolocationModes.cordova:
                case EGeolocationModes.nativePolling:
                case EGeolocationModes.cordovaPolling:
                    // this.mode = EGeolocationModes.gmap;
                    break;
            }
        }
        this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.clearWatchdog);
        console.log("set geolocation mode: " + this.mode);
    }

    onPlatformLoaded(platform: IPlatformFlags) {
        console.log("location manager set platform: ", platform);
        this.platform = platform;
        this.setGeolocationMode(SettingsManagerService.settings.app.settings.locationMode.value);
    }

    /**
     * set timeout to reset location
     */
    setLocationWatchdog(force: boolean) {
        // console.log("location watchdog set request");

        // only used while watch location is enabled
        if (!this.flags.watchLocationEnabled && !force) {
            console.warn("location watchdog not available (set)");
            return;
        }

        if (!this.timeouts.watchTimeout) {
            // console.log("location watchdog set");
            this.timeouts.watchTimeout = setTimeout(() => {
                if (this.flags.watchLocationEnabled) {
                    this.fireLocationWatchdog();
                }
            }, EGPSTimeout.watchdog);
        }
    }

    sendLocationTimeoutValue() {
        // update speed: 0
        if (this.prevLoc) {
            this.prevLoc.speed = 0;
        }
        if (this.currentLoc) {
            this.currentLoc.speed = 0;
            this.locationMonitor.setLocationFromUnified(this.currentLoc);
        } else {
            this.locationMonitor.setLocationFromUnified(null);
        }
    }

    fireLocationWatchdog() {
        if (!this.flags.watchLocationEnabled) {
            console.warn("location watchdog not available (fire)");
            return;
        }
        console.log("location watchdog fired");
        GeneralCache.appFlags.locationFallbackActive = true;
        // disable watchdog for background geolocation
        // because it returns only when location changed
        if (this.mode !== EGeolocationModes.background) {
            // wake up location timeout
            this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeoutWatchdog);
            this.sendLocationTimeoutValue();
            // stop watch location first          

            if (!this.locationFixed) {
                this.locationNotFixedTimeoutCounter += 1;
                if (this.locationNotFixedTimeoutCounter >= this.locationNotFixedTimeoutLimit) {
                    this.locationFixed = true; // no more warnings
                    this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeoutNotFound);
                }
            }
            this.resetWatchLocationCore(false);
        }
        // slow update is not relevant on location timeout
        this.locationSlowCounter = 0;
        this.locationSlowWarningCounter = 0;
    }

    /**
     * reset watch location on command
     * @param retryLock 
     * @returns 
     */
    resetWatchLocationCore(retryLock: boolean) {
        console.log("reset watch location triggered with retry option: " + retryLock);
        if (this.locationResetWatchRetryActive) {
            console.warn("reset watch location already in progress");
            return;
        }
        this.locationResetWatchRetryActive = true;
        let resetFn = async (retry: boolean) => {
            console.log("reset watch location resetFn triggered");
            try {
                await this.getCurrentLocationCore();
                this.locationResetWatchRetryActive = false;
                // resume watch location
                this.startWatchPosition();
            } catch (err) {
                console.error(err);
                if (!retry) {
                    this.locationResetWatchRetryActive = false;
                    // resume watch location
                    this.startWatchPosition();
                } else {
                    await SleepUtils.sleep(3000);
                    resetFn(true);
                }
            }
        };
        this.stopWatchPositionResolve().then(async () => {
            // trigger get current location (single request) to kickstart location services
            if (!retryLock) {
                resetFn(false);
            } else {
                // with retry lock
                resetFn(true);
            }
        });
    }


    /**
    * reset if master enabled
    */
    resetWatchLocationCheck(retryLock: boolean) {
        if (!this.flags.masterLock) {
            console.warn("master locked");
            return;
        }

        if (this.flags.watchLocationMasterEnabled) {
            this.resetWatchLocationCore(retryLock);
        }
    }

    /**
     * clear location watchdog
     */
    clearLocationWatchdog() {
        this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.clearWatchdog);
        // console.log("location watchdog clear request");
        this.timeouts.watchTimeout = ResourceManager.clearTimeout(this.timeouts.watchTimeout);
    }

    resetLocationConnectionReaquired() {
        this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.resetConnectionReacquired);
    }

    /**
     * clear previous watchdog and reset location watchdog
     */
    resetLocationWatchdog() {
        this.clearLocationWatchdog();
        this.setLocationWatchdog(false);
    }

    resetLocationWatchdogState() {
        GeneralCache.appFlags.locationFallbackActive = false;
    }

    /**
     * this should be used only for initial geolocation
     * or instant location (not for location updates)
     * handles cache, loading modal
     */
    getCurrentLocationWrapper(enable: boolean, fromCache: boolean, withLoading: boolean): Promise<ILatLng> {
        return new Promise<ILatLng>(async (resolve, reject) => {

            if (!enable) {
                resolve(null);
                return;
            }

            if (fromCache) {
                let location: ILatLng = this.locationMonitor.getCachedLocation();
                console.log("location from cache: ", location);
                if (!location) {
                    // continue with location request
                } else {
                    resolve(location);
                    return;
                }
            }

            if (withLoading) {
                await this.uiext.showLoadingV2Queue("Waiting for location..");
            }
            this.getCurrentLocationCore().then(async (res: ILatLng) => {
                if (withLoading) {
                    await this.uiext.dismissLoadingV2();
                }
                resolve(res);
            }).catch(async (err: Error) => {
                if (withLoading) {
                    await this.uiext.dismissLoadingV2();
                }
                reject(err);
            });
        })
    }

    buildLocationErrorGen(err: PositionError | GeolocationPositionError) {
        switch (err.code) {
            case GeolocationPositionError.PERMISSION_DENIED:
                // Returned when users do not allow the app to retrieve position information. This is dependent on the platform.
                break;
            case GeolocationPositionError.POSITION_UNAVAILABLE:
                // Returned when the device is unable to retrieve a position. In general, this means the device is not connected to a network or can't get a satellite fix
                break;
            case GeolocationPositionError.TIMEOUT:
                break;
            default:
                break;
        }
        return "<p>Location error:</p><p style='font-weight: bold'>" + err.message + "</p><p>Some features might not work as expected.</p><p>Please check location services and external conditions.</p>";
    }

    /**
     * this should be used only for initial geolocation
     * or instant location (not for location updates)
     */
    getCurrentLocationCore(): Promise<ILatLng> {
        console.log("get current location");
        let promise: Promise<ILatLng> = new Promise((resolve, reject) => {
            setTimeout(() => {
                if (!this.platform.WEB) {
                    let mode: number = this.mode;
                    // if (this.platform.MOBILE_LIVERELOAD) {
                    //     mode = GeolocationModes.none;
                    // }
                    // this.flags.mobileGeolocationMode = this.app.geolocationModes.GEO_GMAP;
                    switch (mode) {
                        case EGeolocationModes.native:
                        case EGeolocationModes.background:
                        case EGeolocationModes.nativePolling:
                            if (navigator.geolocation) {
                                // using default navigator on user phone browser
                                navigator.geolocation.getCurrentPosition((location: GeolocationPosition) => {
                                    if (location) {
                                        console.log('location found using navigator');
                                        let coordinates: ILatLng = new ILatLng(location.coords.latitude, location.coords.longitude);

                                        let t1 = new Date(location.timestamp).getTime();
                                        let coords: ILatLng = new ILatLng(location.coords.latitude, location.coords.longitude);
                                        let loc: IGeolocationResult = {
                                            coords: coords,
                                            timestamp: t1,
                                            elapsed: 0,
                                            accuracy: location.coords.accuracy,
                                            speed: location.coords.speed,
                                            altitude: location.coords.altitude,
                                            heading: GeolocationUtils.formatGPSHeading(location.coords.heading)
                                        };

                                        this.setLocationCore(loc);
                                        this.locationMonitor.setRealLocationCache(coordinates);
                                        resolve(coordinates);
                                    }
                                }, (err: GeolocationPositionError) => {
                                    console.error(err);
                                    reject(new Error(this.buildLocationErrorGen(err)));
                                }, this.singleRequestOptions.navigator);
                            }
                            break;
                        case EGeolocationModes.cordova:
                        case EGeolocationModes.cordovaPolling:
                            this.geolocation.getCurrentPosition(this.singleRequestOptions.cordova).then((data: GeolocationPosition) => {
                                // console.log(data);
                                if (data && data.coords) {
                                    console.log('location found using cordova: ', data.coords);
                                    this.setLocationCordova(data);
                                    this.locationMonitor.setRealLocationCache(new ILatLng(data.coords.latitude, data.coords.longitude));
                                    let coords: ILatLng = new ILatLng(data.coords.latitude, data.coords.longitude);
                                    resolve(coords);
                                    // resolve(data.coords);
                                } else {
                                    reject(new Error("Location error: coords not found"));
                                    return;
                                }
                            }).catch((err: PositionError) => {
                                console.error(err);
                                reject(new Error(this.buildLocationErrorGen(err)));
                            });
                            break;
                        default:
                            reject(new Error("unknown geolocation mode: " + mode));
                            break;
                    }
                } else {
                    // web
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((data: GeolocationPosition) => {
                            if (data) {
                                // console.log(data);
                                let coordinates: ILatLng = new ILatLng(data.coords.latitude, data.coords.longitude);
                                let t1 = new Date().getTime();
                                let loc: IGeolocationResult = {
                                    coords: coordinates,
                                    timestamp: t1,
                                    altitude: data.coords.altitude,
                                    elapsed: 0
                                };

                                this.setLocationCore(loc);
                                this.locationMonitor.setRealLocationCache(coordinates);
                                resolve(coordinates);
                            } else {
                                reject(new Error("Location error: coords not found"));
                                return;
                            }
                        }, (err: GeolocationPositionError) => {
                            // console.error(err);
                            // setTimeout(()=>{
                            //     this.getCurrentLocation();
                            // }, 500);
                            console.error(err);
                            reject(new Error(this.buildLocationErrorGen(err)));
                        }, this.singleRequestOptions.web);
                    } else {
                        reject(new Error("Geolocation is not supported by this browser."));
                    }
                }
            }, 1);
        });
        return promise;
    }

    checkPolling() {
        if (this.flags.watchLocationEnabled) {
            this.timeouts.location = setTimeout(() => {
                this.watchPositionCore();
            }, 500);
        }
    }

    /**
     * triggered when location found and coords have been retrieved
     */
    onLocationFoundWatch() {
        // initial fix done
        // now it should work properly at least until next app restart
        this.locationFixed = true;
        this.locationTimeoutCounter = 0;
    }

    /**
     * triggered when location not found e.g. simple timeout
     * w/ throttle (prevent fast triggering loops)
     */
    onLocationNotFoundWatchWThrottle(fireWatchdogNow: boolean) {
        this.timeoutQueueService.debounceTakeLastItemWithTimeout(ETimeoutQueue.locationWatchdogThrottle, async () => {
            this.onLocationNotFoundWatch(fireWatchdogNow);
        }, 2000);
    }

    /**
     * triggered when location not found e.g. simple timeout
     */
    onLocationNotFoundWatch(fireWatchdogNow: boolean) {
        this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeout);
        this.sendLocationTimeoutValue();
        this.locationTimeoutCounter += 1;
        if (fireWatchdogNow || (this.locationTimeoutCounter >= this.locationTimeoutLimit)) {
            this.locationTimeoutCounter = 0;
            this.resetLocationWatchdog();
            this.fireLocationWatchdog();
        }
    }

    watchPositionCore() {
        console.log("watch position core");
        this.resetLocationWatchdog();
        this.resetLocationWatchdogState();
        // start watchdog now as watch position might not return
        if (!this.platform.WEB) {
            switch (this.mode) {
                case EGeolocationModes.native:
                case EGeolocationModes.nativePolling:
                    if (navigator.geolocation) {
                        // use the navigator from the user phone browser
                        console.log("watch location using navigator");
                        if (this.watches.nativeGeolocation === null) {
                            this.watches.nativeGeolocation = navigator.geolocation.watchPosition((location: GeolocationPosition) => {
                                if (this.flags.simulateWatchLocationFailTest) {
                                    this.locationMonitor.setLocationFromUnified(null);
                                    this.onLocationNotFoundWatchWThrottle(true);
                                } else {
                                    if (location) {
                                        this.onLocationFoundWatch();
                                        if (this.flags.watchLocationEnabled) {
                                            console.log("location updated using native watch");
                                            let t1 = new Date(location.timestamp).getTime();
                                            let coords: ILatLng = new ILatLng(location.coords.latitude, location.coords.longitude);
                                            let loc: IGeolocationResult = {
                                                coords,
                                                timestamp: t1,
                                                elapsed: 0,
                                                accuracy: location.coords.accuracy,
                                                speed: location.coords.speed,
                                                altitude: location.coords.altitude,
                                                heading: GeolocationUtils.formatGPSHeading(location.coords.heading)
                                            };
                                            this.resetLocationWatchdog();            
                                            this.resetLocationWatchdogState();
                                            this.setLocationCore(loc);
                                            this.locationMonitor.setRealLocationCache(coords);
                                        }
                                    }
                                }
                            }, (err: GeolocationPositionError) => {
                                console.error("watch position error ", err);
                                this.locationMonitor.setLocationFromUnified(null);
                                this.onLocationNotFoundWatchWThrottle(true);
                            }, this.watchOptions.navigator);
                            // do not unsubscribe as it can cause errors (we put it into this.observables_other which do not get unsubscribed when exit page)
                        }
                    }
                    break;
                case EGeolocationModes.background:
                    console.log("watch location using background geolocation");

                    console.warn("not available");
                    // if (!this.observables.backgroundGeolocation) {
                    //     this.observables.backgroundGeolocation = this.backgroundGeolocation.configure(this.watchOptions.background)
                    //         .then((location: BackgroundGeolocationResponse) => {
                    //             // console.log(location);
                    //             if (location) {
                    //                 let t1 = new Date(location.time).getTime();
                    //                 let coords: ILatLng = new ILatLng(location.latitude, location.longitude);
                    //                 let loc: IGeolocationResult = {
                    //                     coords,
                    //                     timestamp: t1,
                    //                     elapsed: 0,
                    //                     accuracy: location.accuracy,
                    //                     speed: location.speed,
                    //                     heading: location.bearing
                    //                 };

                    //                 this.setLocationCore(loc);
                    //                 this.resetLocationWatchdog();
                    //             }
                    //             // IMPORTANT:  You must execute the finish method here to inform the native plugin that you're finished,
                    //             // and the background-task may be completed.  You must do this regardless if your HTTP request is successful or not.
                    //             // IF YOU DON'T, ios will CRASH YOUR APP for spending too much time in the background.
                    //             // this.backgroundGeolocation.finish().then(() => { }).catch((err: Error) => {
                    //             //     console.error(err);
                    //             // }); // FOR IOS ONLY
                    //         }, (err: Error) => {
                    //             console.error(err);
                    //         });
                    //     this.setBackgroundGeolocation(true);
                    // }
                    break;
                case EGeolocationModes.cordova:
                    console.log("watch location using cordova");
                    if (this.subscription.cordovaGeolocation === null) {
                        this.watches.cordovaGeolocation = this.geolocation.watchPosition(this.watchOptions.cordova);
                        this.subscription.cordovaGeolocation = this.watches.cordovaGeolocation.subscribe((data: Geoposition) => {
                            if (this.flags.simulateWatchLocationFailTest) {
                                this.locationMonitor.setLocationFromUnified(null);
                                this.onLocationNotFoundWatchWThrottle(true);
                            } else {
                                console.log("location updated using cordova watch");
                                if (data && data.coords) {
                                    this.onLocationFoundWatch();
                                    this.setLocationCordova(data);
                                    this.locationMonitor.setRealLocationCache(new ILatLng(data.coords.latitude, data.coords.longitude));
                                    this.resetLocationWatchdog();
                                    this.resetLocationWatchdogState();
                                }
                            }
                        }, (err: Error) => {
                            console.error(err);
                            // retry subscribing to location
                            if (!this.errorFlag.watchPosition) {
                                this.errorFlag.watchPosition = true;
                                this.analytics.dispatchError(err, "location-manager");
                            }
                            this.locationMonitor.setLocationFromUnified(null);
                            this.onLocationNotFoundWatchWThrottle(true);
                        });
                    }
                    break;
                case EGeolocationModes.cordovaPolling:
                    this.geolocation.getCurrentPosition(this.watchOptions.cordova).then((data: Geoposition) => {
                        // console.log(data);
                        if (data && data.coords) {
                            console.log('location updated using cordova polling: ', data.coords);
                            this.onLocationFoundWatch();
                            this.setLocationCordova(data);
                            this.locationMonitor.setRealLocationCache(new ILatLng(data.coords.latitude, data.coords.longitude));
                            this.resetLocationWatchdog();
                            this.resetLocationWatchdogState();
                        } else {
                            console.log("location not found using cordova");
                        }
                        this.checkPolling();
                    }).catch((err: Error) => {
                        console.error(err);
                        this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeout);
                        this.checkPolling();
                    });
                    break;
                default:
                    break;
            }
        } else if (this.platform.WEB) {
            // use browser geolocation
            let useWatch: boolean = GeneralCache.isPublicDistribution;
            useWatch = true;
            if (useWatch) {
                if (navigator.geolocation) {
                    // use the navigator from the user phone browser
                    console.log("watch location using navigator");
                    if (this.watches.nativeGeolocation === null) {
                        this.watches.nativeGeolocation = navigator.geolocation.watchPosition((location: GeolocationPosition) => {
                            if (this.flags.simulateWatchLocationFailTest) {
                                this.locationMonitor.setLocationFromUnified(null);
                                this.onLocationNotFoundWatchWThrottle(true);
                            } else {
                                if (location) {
                                    this.onLocationFoundWatch();
                                    if (this.flags.watchLocationEnabled) {
                                        console.log("location updated using native watch");
                                        let t1 = new Date(location.timestamp).getTime();
                                        if (!this.currentLoc || ((t1 - this.currentLoc.timestamp) >= this.rateLimiterWeb)) {
                                            let coords: ILatLng = new ILatLng(location.coords.latitude, location.coords.longitude);
                                            let loc: IGeolocationResult = {
                                                coords,
                                                timestamp: t1,
                                                elapsed: 0,
                                                accuracy: location.coords.accuracy,
                                                speed: location.coords.speed,
                                                altitude: location.coords.altitude,
                                                heading: GeolocationUtils.formatGPSHeading(location.coords.heading)
                                            };
                                            this.resetLocationWatchdog();
                                            this.resetLocationWatchdogState();
                                            this.setLocationCore(loc);
                                            this.locationMonitor.setRealLocationCache(coords);
                                        }
                                    }
                                }
                            }
                        }, (err: GeolocationPositionError) => {
                            console.error("watch position error ", err);
                            this.locationMonitor.setLocationFromUnified(null);
                            this.onLocationNotFoundWatchWThrottle(true);
                        }, this.watchOptions.navigator);
                        // do not unsubscribe as it can cause errors (we put it into this.observables_other which do not get unsubscribed when exit page)
                    }
                }
            } else {
                this.timeouts.location = setTimeout(() => {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition((data: GeolocationPosition) => {
                            if (data) {
                                this.onLocationFoundWatch();
                                // console.log(data);
                                let location: ILatLng = new ILatLng(data.coords.latitude, data.coords.longitude);
                                let t1 = new Date().getTime();
                                let loc: IGeolocationResult = {
                                    coords: location,
                                    timestamp: t1,
                                    altitude: data.coords.altitude,
                                    elapsed: 0
                                };
                                if (this.flags.watchLocationEnabled) {
                                    this.resetLocationWatchdog();
                                    this.resetLocationWatchdogState();
                                    this.setLocationCore(loc);
                                    this.locationMonitor.setRealLocationCache(location);
                                    this.watchPositionCore();
                                }
                            }
                        }, (err: PositionError) => {
                            console.error("watch position error ", err);
                            this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeout);
                            this.locationMonitor.setLocationFromUnified(null);
                            this.timeouts.location = setTimeout(() => {
                                if (this.flags.watchLocationEnabled) {
                                    this.watchPositionCore();
                                }
                            }, 3000);
                        }, this.watchOptions.web);
                    } else {
                        this.locationMonitor.setLocationFromUnified(null);
                    }
                }, 2000);
            }
        } else {
            this.locationMonitor.setLocationFromUnified(null);
        }
    }


    /**
     * process raw data from geolocation api
     * @param loc 
     */
    setLocationCore(loc: IGeolocationResult) {
        loc.new = true;

        if (this.currentLoc != null) {
            let dist: number = GeometryUtils.getDistanceBetweenEarthCoordinates(this.currentLoc.coords, loc.coords, 0);
            if (dist > 0) {
                let heading: number = GeometryUtils.toDeg(GeometryUtils.computeHeading(this.currentLoc.coords, loc.coords));
                if (heading != null) {
                    loc.averageHeading = this.arrayFilters.lowPassFilterAngle("locationHeading", heading, this.interpolateLocationSize);
                }
            }
        }

        if (loc.averageHeading == null) {
            loc.averageHeading = loc.heading;
        }

        console.log("compute average heading: " + loc.averageHeading);
        this.currentLoc = loc;
        this.resetLocationConnectionReaquired();

        if ((this.prevLoc == null) || !this.flags.interpolate) {
            // direct GPS update
            this.locationSender(loc, true);
        }
    }

    /**
     * process raw data from cordova geolocation api
     * @param data 
     */
    setLocationCordova(data: Geoposition) {
        let t1: number = new Date(data.timestamp).getTime();
        let coords: ILatLng = new ILatLng(data.coords.latitude, data.coords.longitude);
        let loc: IGeolocationResult = {
            coords,
            timestamp: t1,
            elapsed: 0,
            accuracy: data.coords.accuracy,
            speed: data.coords.speed,
            heading: GeolocationUtils.formatGPSHeading(data.coords.heading),
            new: true
        };
        this.setLocationCore(loc);
    }

    setPrevLoc(loc: IGeolocationResult) {
        this.prevLoc = this.copyLoc(loc);
    }

    copyLoc(loc: IGeolocationResult) {
        let newLoc: IGeolocationResult = Object.assign({}, loc);
        newLoc.coords = new ILatLng(loc.coords.lat, loc.coords.lng);
        return newLoc;
    }

    watchInterpolate() {
        let t = timer(0, this.interpolateTimedelta);
        this.subscription.watchInterpolate = t.subscribe(() => {
            let loc: IGeolocationResult = this.currentLoc;
            let newLoc: boolean = false;
            let ts: number = new Date().getTime();
            // console.log("check interpolate: ", loc, this.prevLoc);
            if (loc != null) {
                if ((this.prevLoc == null) || !this.flags.interpolate) {
                    // pass
                } else {
                    // console.log("check interpolate, new coords ", loc.new);
                    if (loc.new) {
                        // GPS feed, set interpolate specs on new update receive
                        loc.new = false;
                        newLoc = true;

                        // // test
                        // let delta: number = 0.0001;
                        // let deltaNoise: number = 0.00001;
                        // if (this.prevLoc != null) {
                        //     loc.coords = new ILatLng(this.prevLoc.coords.lat, this.prevLoc.coords.lng);
                        //     loc.coords.lat += delta + Math.random() * deltaNoise;
                        //     loc.coords.lng += delta + Math.random() * deltaNoise;
                        // }
                        // // end test                       

                        if (this.prevLoc != null) {
                            // set interpolate target (crt, prev location)
                            let distanceDelta: number = GeometryUtils.getDistanceBetweenEarthCoordinates(loc.coords, this.prevLoc.coords, 0);
                            let timeDelta: number = loc.timestamp - this.prevLoc.timestamp;
                            if (timeDelta === 0) {
                                timeDelta = 1000;
                            }
                            let speed: number = loc.speed;
                            if (speed == null) {
                                speed = distanceDelta / (timeDelta / 1000); //m/s
                                loc.speed = speed;
                            }
                            let heading: number = GeolocationUtils.formatGPSHeading(loc.averageHeading);
                            if (heading == null) {
                                // heading = GeometryUtils.translateTo360(GeometryUtils.computeHeadingSimple(this.prevLoc.coords, loc.coords) + 90 * GeometryUtils.deg2rad);
                                heading = GeometryUtils.toDeg(GeometryUtils.computeHeading(this.prevLoc.coords, loc.coords));
                                loc.averageHeading = heading;
                            }
                            let tsLoc: number = loc.timestamp;
                            this.interpolateSpec = {
                                distanceDelta: distanceDelta,
                                timeDelta: timeDelta,
                                speed: speed,
                                heading: heading,
                                currentTime: tsLoc,
                                interpolateTime: ts,
                                init: true
                            };
                            // maybe apply filtering on heading and speed
                            console.log("interpolate spec: ", this.interpolateSpec);
                        }
                    } else {
                        // interpolate feed
                        let doInterpolate: boolean = true;
                        // check min/max time delta between GPS updates                  
                        if ((this.interpolateSpec.timeDelta < (this.interpolateTimedelta * 1.5)) || (this.interpolateSpec.timeDelta > 10000)) {
                            doInterpolate = false;
                        }
                        // check max speed (don't interpolate if higher)
                        if ((this.interpolateSpec.speed * 3.6) > AppConstants.gameConfig.scanPlacesMaxSpeed) {
                            doInterpolate = false;
                        }
                        // check stationary
                        if (this.interpolateSpec.speed < 0.1) {
                            doInterpolate = false;
                        }
                        if (doInterpolate) {
                            // apply interpolation rule
                            // speed on distance
                            let dist: number = this.interpolateSpec.speed * ((ts - this.interpolateSpec.interpolateTime) / 1000);
                            loc.coords = GeometryUtils.moveDeltaDistanceHeading(loc.coords, dist, this.interpolateSpec.heading);
                            this.interpolateSpec.interpolateTime = ts;
                            //console.log("interpolate: ", dist, this.interpolateSpec.heading, loc.coords);
                        }
                    }

                    // filter GPS location
                    let filteredCoords: number[] = this.kalmanFilter.filter2D(EKalmanContext.location, [loc.coords.lat, loc.coords.lng]);
                    let filteredLoc: IGeolocationResult = Object.assign({}, loc);
                    if (filteredCoords != null) {
                        let fc: ILatLng = new ILatLng(filteredCoords[0][0], filteredCoords[1][0]);
                        console.log("kalman filtered coords: ", fc);
                        // filteredLoc.coords = new ILatLng(filteredCoords[0][0], filteredCoords[1][0]);
                    }
                    // feed Kalman estimation instead of the actual GPS/interpolate location (filtered coords)
                    this.locationSender(filteredLoc, newLoc);
                }
            }
        }, (err) => {
            console.error(err);
        });
    }

    stopWatchInterpolate() {
        this.subscription.watchInterpolate = ResourceManager.clearSub(this.subscription.watchInterpolate);
    }


    /**
     * dispatch geolocation result to the location monitor
     * after some basic processing e.g. elapsed time
     */
    locationSender(loc: IGeolocationResult, locationFeed: boolean) {
        if (this.prevLoc == null) {
            this.setPrevLoc(loc);
        }

        if (locationFeed) {
            loc.elapsed = loc.timestamp - this.prevLoc.timestamp;
            this.setPrevLoc(loc);
            if (loc.elapsed >= EGPSTimeout.slowUpdate) {
                this.locationSlowCounter += 1;
                if (this.locationSlowCounter >= this.locationSlowCounterLimit) {
                    this.locationSlowCounter = 0;
                    this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeoutSlowUpdate);
                    this.locationSlowWarningCounter += 1;

                    if (this.locationSlowWarningCounter >= 3) {
                        this.locationSlowWarningCounter = 0;
                        this.locationMonitor.setLocationTimeoutObservable(ELocationTimeoutEvent.timeoutSlowUpdateWarning);
                    }
                }
            } else {
                this.locationSlowCounter = 0;
                this.locationSlowWarningCounter = 0;
            }
        }

        console.log("location sender ", loc.coords);
        this.locationMonitor.setLocationFromUnified(loc);
    }

    loadConfigSettings() {
        this.flags.interpolate = SettingsManagerService.settings.app.settings.locationInterpolate.value;
        // if (this.platform.WEB && !GeneralCache.isDev) {
        if (this.platform.WEB) {
            this.flags.interpolate = false; // interpolation jumps too much in web context
        }
    }

    /**
     * start position watch
     * returns true if not already enabled
     * returns false if previously enabled
     * set master enabled flag
     */
    startWatchPosition(): boolean {
        if (!this.flags.masterLock) {
            console.warn("master locked");
            return;
        }

        console.log("watchPosition");
        this.flags.watchLocationMasterEnabled = true;
        this.kalmanFilter.init2D(EKalmanContext.location, 0.1);
        return this.startWatchPositionCore();
    }

    /**
    * start position watch
    * returns true if not already enabled
    * returns false if previously enabled
    */
    startWatchPositionCore(): boolean {
        if (!this.flags.masterLock) {
            console.warn("master locked");
            return;
        }

        console.log("start watch position core");

        if (!this.flags.watchLocationEnabled) {
            this.flags.watchLocationEnabled = true;
            console.log("watch position enabled");
            if (this.flags.interpolate) {
                console.log("watch position interpolate enabled");
                this.watchInterpolate();
            }
            this.watchPositionCore();
            return true;
        }
        return false;
    }

    /**
     * fully enable/disable location manager
     * when disabled, watch position cannot be enabled (e.g. after gmap deinit request)
     * @param lock 
     */
    setMasterLock(lock: boolean) {
        this.flags.masterLock = lock;
    }


    /**
     * resume if master enabled
     */
    resumeWatchPosition() {
        if (!this.flags.masterLock) {
            console.warn("master locked");
            return;
        }

        if (this.flags.watchLocationMasterEnabled) {
            this.startWatchPositionCore();
        }
    }

    /**
     * pause if master enabled
     */
    pauseWatchPosition(): Promise<boolean> {
        if (this.flags.watchLocationMasterEnabled) {
            return this.stopWatchPositionResolveCore();
        } else {
            return Promise.resolve(true);
        }
    }


    /**
     * stop watch
     * reset master enabled flag
     */
    stopWatchPositionResolve(): Promise<boolean> {
        console.log("stop watch position");
        let promise: Promise<boolean> = new Promise((resolve) => {
            this.stopWatchPositionResolveCore().then(() => {
                this.flags.watchLocationMasterEnabled = false;
                resolve(true);
            });
        });
        return promise;
    }

    setSimulateWatchLocationFailTest(activeTest: boolean) {
        if (activeTest == null) {
            // toggle
            activeTest = !this.flags.simulateWatchLocationFailTest;
        }
        this.flags.simulateWatchLocationFailTest = activeTest;
    }

    getSimulateLocationFailTest() {
        return this.flags.simulateWatchLocationFailTest;
    }

    /**
     * stop watch
     */
    stopWatchPositionResolveCore(): Promise<boolean> {
        console.log("stop watch position core");
        let promise: Promise<boolean> = new Promise(async (resolve) => {

            this.flags.watchLocationEnabled = false;
            this.interpolateSpec.init = false;

            // there is a problem here!
            if (this.mode === EGeolocationModes.native && navigator && navigator.geolocation && this.watches.nativeGeolocation !== null) {
                navigator.geolocation.clearWatch(this.watches.nativeGeolocation);
                this.watches.nativeGeolocation = null;
            }

            this.subscription.cordovaGeolocation = ResourceManager.clearSub(this.subscription.cordovaGeolocation);

            if (this.observables.backgroundGeolocation !== null) {
                this.setBackgroundGeolocation(false);
                ResourceManager.clearSub(this.observables.backgroundGeolocation);
            }

            this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);

            this.prevLoc = null;
            this.currentLoc = null;
            this.bufferUtils.disposeBuffer(EBufferContext.location);
            this.arrayFilters.resetFilterAngle("locationHeading");
            this.stopWatchInterpolate();

            this.clearLocationWatchdog();
            console.log("stop watch position core complete");

            await SleepUtils.sleep(500);
            resolve(true);
        });
        return promise;
    }

}


