import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiDef } from '../../classes/app/api';
import { GenericDataService } from '../general/data/generic';
import { IARStreamingData } from '../../classes/def/ar/core';
import { GeneralCache } from 'src/app/classes/app/general-cache';


@Injectable({
    providedIn: 'root'
})
export class BenchmarkDataService {
    serverUrl: string;

    streamingBuffer: IARStreamingData[] = [];
    streamingBufferSize: number = 1000;

    logStreamingBuffer: string[] = [];
    logStreamingBufferSize: number = 10;

    constructor(
        public http: HttpClient,
        public generic: GenericDataService
    ) {
        console.log("benchmark data service created");
        this.serverUrl = ApiDef.mainServerURL;
    }

    init() {
      
    }

    initStreamingBuffer(size: number) {
        this.streamingBuffer = [];
        this.streamingBufferSize = size;
    }

    pushLogData(message: string) {
        let timestamp: string = new Date().toUTCString();
        this.logStreamingBuffer.push(timestamp + ": " + message);
        if (this.logStreamingBuffer.length >= this.logStreamingBufferSize) {
            this.sendLogDataArray(this.logStreamingBuffer);
            this.logStreamingBuffer = [];
        }
    }

    /**
     * this method automatically saves the data into the buffer
     * then when the buffer is full it sends the data to the server
     * @param data 
     */
    pushARData(data: IARStreamingData) {
        this.streamingBuffer.push(data);
        if (this.streamingBuffer.length >= this.streamingBufferSize) {
            this.sendARCalibrationDataArray(this.streamingBuffer);
            this.streamingBuffer = [];
        }
    }


    sendLogDataArray(data: string[]) {
        return this.generic.genericPostStandard("/debug/log-data-array", data, GeneralCache.resourceCache.general.appServices.content.object.bench.url);
    }

    sendARCalibrationDataArray(data: IARStreamingData[]) {
        return this.generic.genericPostStandard("/ar/calibration-data-array", data, GeneralCache.resourceCache.general.appServices.content.object.bench.url);
    }


    initARCalibrationDataFiles() {
        return this.generic.genericPostStandard("/ar/init-files", {}, GeneralCache.resourceCache.general.appServices.content.object.bench.url);
    }

    sendARCalibrationData(data: IARStreamingData) {
        return this.generic.genericPostStandard("/ar/calibration-data", data, GeneralCache.resourceCache.general.appServices.content.object.bench.url);
    }

    sendGPSPosition(lat: number, lng: number) {
        return this.generic.genericPostStandard("/ar/geolocation", {
            lat: lat,
            lng: lng
        }, GeneralCache.resourceCache.general.appServices.content.object.bench.url);
    }
}
