<div *ngIf="availableItems">
  <ion-grid class="full-w">
    <ion-row *ngFor="let itemRow of itemGrid; let i = index">
      <ion-col *ngFor="let item of itemRow" class="padding-s">
        <div class="text-center">
          <photo-upload-disp [data]="item" [update]="update"></photo-upload-disp>
          <ion-button class="action-button-fill button-color-alternate margin-top-s" (click)="viewPhotoCard(item)">
            <icon-wrapper class="slot-icon-padding" slot="icon-only" [icon]="appIconsStandard.takePhoto">
            </icon-wrapper>
            <!-- <span class="button-font-size-s">View</span> -->
          </ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<div *ngIf="!availableItems" class="padding-s center-text">
  <span class="center-text info-text-m bold text-color-primary">{{emptyMessage}}</span>
</div>