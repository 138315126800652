import { Injectable } from "@angular/core";
import { AnalyticsService } from './analytics';
import { StorageOpsService } from '../data/storage-ops';
import { ELocalAppDataKeys, IAppFlagsGenContent } from 'src/app/classes/def/app/storage-flags';
import { StorageFlagsService } from '../data/storage-flags';
import { SettingsManagerService } from '../settings-manager';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';

@Injectable({
    providedIn: 'root'
})
export class AnalyticsExtrasService {

    storageCache: IAppFlagsGenContent = {};
    loaded: boolean = false;
    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public analytics: AnalyticsService,
        public storageOps: StorageOpsService,
        public storageFlags: StorageFlagsService,
        public settingsProvider: SettingsManagerService
    ) {
        console.log("analytics extras service created");
        this.init();
    }


    init() {
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    /**
     * init load flags from local storage
     * dynamic loading (flags are not defined in advance, no CRUD support, if flag gets removed it will not be removed from local storage on sync)
     * resolve only
     */
    syncFlags() {
        return new Promise<boolean>((resolve) => {
            this.storageFlags.loadFlagsGroup(ELocalAppDataKeys.trackEventFlags, {}, false).then((flags: IAppFlagsGenContent) => {
                if (flags) {
                    this.storageCache = flags;
                }
                this.loaded = true;
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
    }

    /**
     * track one-time event
     * add to cache and local storage (for one-time tracking behavior)
     * @param category 
     * @param action 
     * @param label 
     * @param value 
     */
    sendTrackOneTimeEvent(category: string, action: string, label: string, value: number, customOnly: boolean) {
        // check all flags loaded local storage
        let checkLoaded: Promise<boolean> = new Promise((resolve) => {
            if (this.loaded) {
                resolve(true);
            } else {
                this.syncFlags().then((loaded: boolean) => {
                    resolve(loaded);
                });
            }
        });

        let trackEvent: Promise<boolean> = new Promise((resolve) => {
            if (this.platform.WEB) {
                // test
                resolve(true);
            } else {
                this.analytics.sendCustomEvent(category, action, label, value, customOnly).then((res: boolean) => {
                    resolve(res);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                });
            }
        });

        checkLoaded.then(() => {
            // check required flag
            let tracked: boolean = this.storageCache[category] != null ? this.storageCache[category] : false;
            if (tracked) {
                // already tracked, skip
                console.log("event already tracked: " + category);
            } else {
                console.log("track event: " + category);
                // track event
                trackEvent.then((res: boolean) => {
                    // check send event completed
                    if (res) {
                        // set tracked event
                        this.storageCache[category] = true;
                        this.storageFlags.updateFlagsGroup(ELocalAppDataKeys.trackEventFlags, this.storageCache);
                        this.storageFlags.saveFlagsGroupNoAction(ELocalAppDataKeys.trackEventFlags);
                    }
                }).catch((err: Error) => {
                    console.error(err);
                });
            }
        });
    }
}
