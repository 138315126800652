<div *ngIf="!withButton">

  <ion-row *ngIf="withGraphic && !isZoom">
    <ion-col size="2">
      <responsive-img *ngIf="photoUrl" [photoUrl]="photoUrl" class="border-radius img-small" customClass="img-xs"
        (zoom)="onTapZoom($event)" [noAnimation]="true"></responsive-img>
      <icon-wrapper *ngIf="icon" [icon]="icon" [custom]="customIcon"></icon-wrapper>
    </ion-col>
    <ion-col size="10" [ngClass]="center ? 'text-center' : ''">
      <span class="info-text-m bold text-color-primary">{{name}}</span>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="withGraphic && isZoom" class="text-center">
    <ion-col size="12">
      <span class="info-text-lg bold text-color-primary">{{name}}</span>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="withGraphic && isZoom" class="text-center">
    <ion-col size="12">
      <responsive-img *ngIf="photoUrl" [photoUrl]="photoUrl" class="border-radius img-small" customClass="img-xs"
        (zoom)="onTapZoom($event)" [noAnimation]="true"></responsive-img>
      <icon-wrapper *ngIf="icon" [icon]="icon" [custom]="customIcon"></icon-wrapper>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="!withGraphic">
    <ion-col size="12">
      <span class="info-text-m bold text-color-primary">{{name}}</span>
    </ion-col>
  </ion-row>
</div>

<div *ngIf="withButton">
  <ion-row *ngIf="withGraphic && !isZoom">
    <ion-col size="2">
      <responsive-img *ngIf="photoUrl" [photoUrl]="photoUrl" class="border-radius img-small" customClass="img-xs"
        (zoom)="onTapZoom($event)" [noAnimation]="true"></responsive-img>
      <icon-wrapper *ngIf="icon" [icon]="icon" [custom]="customIcon"></icon-wrapper>
    </ion-col>
    <ion-col size="8" [ngClass]="center ? 'text-center' : ''">
      <span class="info-text-m bold text-color-primary">{{name}}</span>
    </ion-col>
    <ion-col size="2" class="text-right">
      <ion-button class="button-color-primary" (click)="onTapSelect()">
        <icon-wrapper slot="icon-only" icon="open"></icon-wrapper>
      </ion-button>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="withGraphic && isZoom" class="text-center">
    <ion-col size="12">
      <span class="info-text-lg bold text-color-primary">{{name}}</span>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="withGraphic && isZoom" class="text-center">
    <ion-col size="12">
      <responsive-img *ngIf="photoUrl" [photoUrl]="photoUrl" class="border-radius img-small" customClass="img-xs"
        (zoom)="onTapZoom($event)" [noAnimation]="true"></responsive-img>
      <icon-wrapper *ngIf="icon" [icon]="icon" [custom]="customIcon"></icon-wrapper>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="withGraphic && isZoom">
    <ion-col size="12">
      <ion-button class="action-button-fill button-color-primary" (click)="onTapSelect()">
        <span class="info-text-m bold text-color-primary">Select</span>
        <icon-wrapper slot="start" icon="open" class="slot-icon-padding text-color-primary"></icon-wrapper>
      </ion-button>
    </ion-col>
  </ion-row>

  <ion-row *ngIf="!withGraphic">
    <ion-col size="10" [ngClass]="center ? 'text-center' : ''">
      <span class="info-text-m bold text-color-primary">{{name}}</span>
    </ion-col>
    <ion-col size="2">
      <ion-button class="button-color-primary" (click)="onTapSelect()">
        <icon-wrapper slot="icon-only" icon="open"></icon-wrapper>
      </ion-button>
    </ion-col>
  </ion-row>
</div>