<div *ngIf="small" class="flex-card item-card-small bg-container bg-container-fill item-border-radius"
  tappable [ngClass]="{'bg-no-filter-animate':!isLocked, 'bg-filter-2-animate':isLocked}"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data ? data.photoUrl: null}" (click)="showDescription()">
</div>

<div *ngIf="!small" class="flex-card item-card bg-container bg-container-fill item-border-radius"
  tappable [ngClass]="{'bg-no-filter-animate':!isLocked, 'bg-filter-2-animate':isLocked}"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data ? data.photoUrl: null}" (click)="showDescription()">
  <div class="content" fxLayout="column">
    <ion-grid class="full-w grid-center-absolute">
      <ion-row>
        <ion-col size="12" class="text-div-container">
          <div class="info-text-xs bold text-color-overlay-s" [innerHtml]="itemName"></div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>