import { Injectable } from "@angular/core";
import { GenericDataService } from "../general/data/generic";
import { IGenericResponse, IPageResponse } from "../../classes/def/requests/general";
import { IGroup } from 'src/app/classes/def/mp/groups';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IUserStatCategory } from "src/app/classes/def/user/stats";
import { ITimespanString } from "src/app/classes/def/nav-params/leaderboard";
import { IUserPublicData } from "src/app/classes/def/user/general";

@Injectable({
    providedIn: 'root'
})
export class MPDataService {
    photoUrl: string = null;
    userDataCache: { [key: string]: IUserPublicData } = {};
    selectedGroup: IGroup;

    constructor(
        public generic: GenericDataService
    ) {
        console.log("multiplayer data service created");
    }



    /**
     * get game results from the mp service (via node gw)
     * @param groupId 
     * @param type 
     */
    getGameResultsViaUservice(groupId: number, type: number) {
        // "/uservice/mp/get-results"
        return this.generic.genericPostStandardWData("/uservice/mp/get-results", {
            groupId: groupId,
            type: type,
            testUrl: GeneralCache.resourceCache.general.appServices.content.object.mpHttp.isTestUrl
        });
    }

    peekGroupMembersViaUservice(groupId: number) {
        return this.generic.genericPostStandardWData("/uservice/mp/peek-group-members", {
            groupId: groupId,
            testUrl: GeneralCache.resourceCache.general.appServices.content.object.mpHttp.isTestUrl
        });
    }

    getLeaderboardByPage(page: number, mode: number, lat: number, lng: number) {
        return this.generic.genericPostStandard("/leaderboard/get-leaderboard", {
            page: page,
            mode: mode,
            lat: lat,
            lng: lng
        });
    }

    getLeaderboardUserScore(leaderId: number, isGroup: boolean, storyId: number) {
        return this.generic.genericPostStandard("/leaderboard/get-leaderboard-spec-user", {
            leaderId: leaderId,
            isGroup: isGroup,
            storyId: storyId
        });
    }

    getLeaderboardExtByPage(page: number, storyId: number, mode: number) {
        return this.generic.genericPostStandard("/leaderboard/get-leaderboard-spec", {
            page: page,
            storyId: storyId,
            mode: mode
        });
    }

    getLeaderboardExtTimeframeByPage(page: number, storyId: number, mode: number, startDate: string, endDate: string) {
        return this.generic.genericPostStandard("/leaderboard/get-leaderboard-spec-timeframe", {
            page: page,
            storyId: storyId,
            mode: mode,
            startDate: startDate,
            endDate: endDate
        });
    }

    getLeaderboardByName(searchName: string) {
        return this.generic.genericPostStandard("/leaderboard/search", {
            name: searchName
        });
    }

    getLeaderboardByUserId() {
        return this.generic.genericPostStandard("/leaderboard/search", {
            name: null
        });
    }

    /**
     * ping offline members via push notifications
     * @param userIds 
     * @param groupId 
     */
    pingOfflineMembers(userIds: number[], groupId: number, groupName: string) {
        return this.generic.genericPostStandard("/mp/groups/ping", {
            users: userIds,
            groupId: groupId,
            groupName: groupName
        });
    }

    createGroup(groupName: string, groupData: IGroup, photo: boolean) {
        return this.generic.genericPostStandard("/mp/groups/create-group", {
            groupName: groupName,
            groupData: groupData,
            photo: photo
        });
    }

    updateGroup(id: number, groupName: string, groupData: IGroup, photo: boolean) {
        return this.generic.genericPostStandard("/mp/groups/update-group", {
            groupId: id,
            details: {
                groupName: groupName,
                groupData: groupData,
                photo: photo
            }
        });
    }

    joinGroup(groupAlias: string, groupId: number) {
        return this.generic.genericPostStandard("/mp/groups/join-group", {
            groupAlias: groupAlias,
            groupId: groupId
        });
    }

    leaveGroup(groupId: number) {
        return this.generic.genericPostStandard("/mp/groups/leave-group", {
            groupId: groupId
        });
    }

    /**
     * remove group member (by the admin)
     * @param groupId 
     * @param otherMemberId 
     */
    removeMember(groupId: number, otherMemberId: number) {
        return this.generic.genericPostStandard("/mp/groups/remove-member", {
            groupId: groupId,
            otherMemberId: otherMemberId
        });
    }

    deleteGroup(groupId: number) {
        return this.generic.genericPostStandard("/mp/groups/delete-group", {
            groupId: groupId
        });
    }

    viewAllGroups(page: number, mode: number, contextType: number, contextId: number) {
        return this.generic.genericPostStandardWData("/mp/groups/view-all-groups", {
            page: page,
            mode: mode,
            contextType: contextType,
            contextId: contextId
        });
    }

    viewAllGroupsSearchByAlias(groupAlias: string, mode: number, contextType: number, contextId: number) {
        return this.generic.genericPostStandardWData("/mp/groups/search-by-alias", {
            groupAlias: groupAlias,
            mode: mode,
            contextType: contextType,
            contextId: contextId
        });
    }

    clearGroupCache() {
        this.selectedGroup = null;
    }

    /**
     * view group w/ cache
     * @param groupId 
     */
    viewGroup(groupId: number, reload: boolean): Promise<IGroup> {
        return new Promise<IGroup>((resolve, reject) => {
            if (!reload && (this.selectedGroup != null)) {
                if (this.selectedGroup.id === groupId) {
                    resolve(this.selectedGroup);
                    return;
                }
            }
            this.generic.genericPostStandardWData("/mp/groups/view-group", {
                groupId: groupId
            }).then((res: IGroup) => {
                this.selectedGroup = res;
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }

    viewPublicGroup(groupId: number): Promise<IGroup> {
        return this.generic.genericPostStandardWData("/mp/groups/view-public-group", {
            groupId: groupId
        });
    }

    searchGroup(groupAlias: string, quickJoinCode: string): Promise<IGroup> {
        return new Promise((resolve, reject) => {
            this.generic.genericPostStandardWData("/mp/groups/search-group", {
                groupAlias: groupAlias,
                quickJoinCode: quickJoinCode
            }).then((res: IGroup) => {
                if (res != null) {
                    if (res["userGroups"] != null) {
                        res.members = res["userGroups"]; //compat
                    }
                }
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }

    /**
     * load user data w/ cache
     * @param userId 
     * @returns 
     */
    loadUserDataCache(userId: number) {
        let promise: Promise<IUserPublicData> = new Promise((resolve, reject) => {
            if (this.userDataCache[userId.toString()] != null) {
                resolve(this.userDataCache[userId.toString()]);
                return;
            }
            this.generic.genericPostStandardWData("/user/get-public-profile", { userId: userId }).then((data: IUserPublicData) => {
                this.userDataCache[userId.toString()] = data;
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    getLeaderboardUserInventory(leaderId: number, isGroup: boolean, storyId: number, categoryCode: number, page: number): Promise<IPageResponse> {
        let promise: Promise<IPageResponse> = new Promise((resolve, reject) => {
            this.generic.genericPostStandard("/leaderboard/get-user-inventory", {
                page: page,
                categoryCode: categoryCode,
                leaderId: leaderId,
                isGroup: isGroup,
                storyId: storyId
            }).then((response: IGenericResponse) => {
                resolve(response.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    getLeaderboardUserStats(leaderId: number, isGroup: boolean, storyId: number, includeScoreStats: boolean, startDate: string, endDate: string): Promise<IUserStatCategory> {
        let promise: Promise<IUserStatCategory> = new Promise((resolve, reject) => {
            let timespan: ITimespanString = {
                start: startDate,
                end: endDate
            };
            if (startDate == null && endDate == null) {
                timespan = null;
            }
            this.generic.genericPostStandard("/leaderboard/get-user-stats", {
                leaderId: isGroup ? null : leaderId,
                isGroup: isGroup ? leaderId : null,
                storyId: storyId,
                includeScoreStats: includeScoreStats,
                timespan: timespan
            }).then((response: IGenericResponse) => {
                resolve(response.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

}
