
/// <reference types="@types/google.maps" />
// import { setDPI, drawMarkerText } from "../../classes/map/canvas-marker";
import { ILatLng } from 'src/app/classes/def/map/coords';
import { CustomMarker, initCustomMarker } from "../../classes/utils/custom-marker";
import { IMarkerTheme, ThemeColors } from '../../classes/def/app/theme';
import { IPlaceMarkerContent, IShowMarkerOptions, IMarkerInternal, IPathContent } from '../../classes/def/map/map-data';
import { MarkerUtils, ICanvasMarkerContainer, EMarkerUpdateCode, IMarkerUpdateResult } from './marker-utils';
// import { Util } from '../../classes/general/util';

declare var google: any;
// declare var MarkerClusterer: any;
// import * as mc from "@google/markerclusterer/src/markerclusterer.js";
import * as MarkerClusterer from 'node-js-marker-clusterer';
import { EMapShapes, EMarkerTypes, ISetThisMarkerOptions } from '../../classes/def/map/markers';
import { EMarkerIcons } from '../../classes/def/app/icons';
import { GeometryUtils } from '../utils/geometry-utils';
import { ResourceManager } from '../../classes/general/resource-manager';
import { NgZone } from '@angular/core';
import { IMoveMapOptions } from 'src/app/classes/def/map/interaction';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { PlacesDataService } from '../data/places';
import { IBackendLocation } from "src/app/classes/def/places/backend-location";
import { PromiseUtils } from "../utils/promise-utils";

export interface IMarkerContainerWeb {
    marker: google.maps.Marker;
    markerContent: IPlaceMarkerContent;
}

export interface ISingleMarkerWeb {
    internal: IMarkerInternal;
    container: IMarkerContainerWeb;
}

export interface IArrayMarkerWeb {
    internal: IMarkerInternal;
    container: IMarkerContainerWeb[];
}

export interface ISingleMarkerCollectionWeb {
    [name: string]: ISingleMarkerWeb;
}
export interface IArrayMarkerCollectionWeb {
    [name: string]: IArrayMarkerWeb;
}


const MARKER_UPDATE_TIMEOUT_WEB = 50;
const MAP_INIT_TIMEOUT_WEB = 2000;

export class MarkersWeb {

    markerTypesNames: string[];

    singleMarkers: ISingleMarkerCollectionWeb = {};
    multiMarkers: IArrayMarkerCollectionWeb = {};
    globalInitTimestamp: number = null;
    map: google.maps.Map;
    test: boolean = false;

    theme: IMarkerTheme;

    markerClusters = [];

    testAccuracy: boolean = false;

    /**
     * master lock
     */
    enable: boolean = false;

    debugConsole: boolean = false;

    constructor(
        public ngZone: NgZone,
        public placeData: PlacesDataService
    ) {
        this.theme = {
            name: "default",
            lineColor: ThemeColors.theme.standard.lineColor,
            markerFrameColor: ThemeColors.theme.standard.lineColor,
        };
    }

    /**
     * master lock
     * @param enable 
     */
    setEnabled(enable: boolean) {
        this.enable = enable;
    }

    setMap(map: google.maps.Map) {
        if (this.debugConsole)
            console.log("web setMap");
        this.map = map;
        initCustomMarker();
    }

    setTheme(theme: IMarkerTheme) {
        this.theme = theme;
        if (!this.theme.markerFrameColor) {
            this.theme.markerFrameColor = ThemeColors.theme.standard.lineColor;
        }
    }

    getMap() {
        return this.map;
    }


    private getArrayMarkerIndexByUid(layer: string, uid: string) {
        for (let i = 0; i < this.multiMarkers[layer].container.length; i++) {
            if (this.multiMarkers[layer].container[i].markerContent.uid === uid) {
                return i;
            }
        }
        return -1;
    }

    /**
     * updates a marker from array (multi marker), set gps position
     * does not handle the update checks (init, timeout), should be handled by the caller
     * @param layer 
     * @param data 
     */
    updateArrayMarkerCore(layer: string, data: IPlaceMarkerContent) {
        let promise = new Promise((resolve, reject) => {
            let index: number = this.getArrayMarkerIndexByUid(layer, data.uid);
            if (this.debugConsole)
                console.log("markers > update array marker index: ", index);

            let mk: google.maps.Marker = null;
            let container = this.multiMarkers[layer].container[index];
            // console.log("container: ", container);
            let refreshRequired: boolean = false;

            if (index !== -1) {
                mk = container.marker;
            }

            // console.log(index, mk);

            if (mk != null) {
                // let oldPosition: google.maps.ILatLng = mk.getPosition();
                // let oldPosition1: ILatLng = new ILatLng(oldPosition.lat(), oldPosition.lng());
                // if (!(oldPosition1.lat === data.location.lat && oldPosition1.lng === data.location.lng)) {
                //     console.log("position changed");
                // }

                if (container) {
                    if (container.markerContent) {
                        if (data.label !== container.markerContent.label) {
                            refreshRequired = true;
                        }
                        if (data.lockedForUser !== container.markerContent.lockedForUser) {
                            refreshRequired = true;
                        }
                        if (data.locked !== container.markerContent.locked) {
                            refreshRequired = true;
                        }
                        // use this if marker is passed by reference (cannot detect changes by attributes)
                        if (data.requiresRefresh) {
                            data.requiresRefresh = false;
                            refreshRequired = true;
                        }
                    }
                    container.markerContent = data;
                }

                if (refreshRequired) {
                    if (this.debugConsole)
                        console.log("refresh update");
                    this.removeArrayMarkerCore(layer, data);
                    // SleepUtils.sleep(1000);
                    this.insertArrayMarkerCore(layer, data).then(() => {
                        resolve(mk);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    // let formatLabel = MarkerUtils.formatAddLabels(null, data.label, data.addLabel, data.addLabel2);
                    // let label: string = formatLabel.label;
                    switch (data.shape) {
                        case EMapShapes.marker:
                            // mk.setLabel(label);
                            // mk.setTitle(label);
                            mk.setPosition(data.location);
                            break;
                        case EMapShapes.circle:
                            let circlemk: google.maps.Circle = mk as any;
                            circlemk.setCenter(data.location);
                            break;
                        default:
                            break;
                    }
                    resolve(mk);
                }
            } else {
                reject(new Error("could not update marker position"));
                return;
            }
        });
        return promise;
    }

    /**
     * removes the marker from the map and from the array
     * @param layer 
     * @param data 
     */
    private removeArrayMarkerCore(layer: string, data: IPlaceMarkerContent) {
        let index: number = this.getArrayMarkerIndexByUid(layer, data.uid);
        this.clearArrayMarkerByIndex(layer, index);
    }

    /**
     * insert a marker into the array
     * does not handle the update checks (init, timeout), should be handled by the caller
     * @param layer 
     * @param markerContent 
     */
    private insertArrayMarkerCore(layer: string, markerContent: IPlaceMarkerContent): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (this.debugConsole)
                console.log("markers > insert array marker: ", layer);
            // basic error check
            if (!(this.multiMarkers[layer])) {
                reject(new Error("marker array not initialized"));
                return;
            }
            this.showMarkerWeb(layer, markerContent, true).then((res: boolean) => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * sync a single marker (update or create)
     * @param layer 
     * @param data 
     */
    syncMarker(layer: string, data: IPlaceMarkerContent, opts: IMoveMapOptions): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            this.singleMarkerUpdate(layer, data, opts).then((state: IMarkerUpdateResult) => {
                // console.log(state);
                switch (state.code) {
                    case EMarkerUpdateCode.ok:
                        // console.log("ok");
                        resolve(true);
                        break;
                    case EMarkerUpdateCode.shouldWait:
                        // console.log("should wait");
                        reject(new Error(state.message));
                        break;
                    case EMarkerUpdateCode.shouldCreate:
                        // console.log("should create");
                        if (!(this.checkGlobalInitTimeout())) {
                            reject(new Error("marker create before map init"));
                            return;
                        }
                        this.singleMarkerCreate(layer, data).then(() => {
                            resolve(true);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                        break;
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * sync a single circle marker (update or create)
     * resize marker if size is changed
     * @param layer 
     * @param position 
     * @param options 
     */
    syncCircleMarker(layer: string, position: ILatLng, options: ISetThisMarkerOptions) {
        let promise = new Promise((resolve, reject) => {
            if (this.checkSingleMarker(this.singleMarkers, layer)) {
                // update
                let marker: google.maps.Circle = this.singleMarkers[layer].container.marker as any;
                marker.setCenter(position);
                if (marker.getRadius() !== options.size) {
                    marker.setRadius(options.size);
                }
                resolve(this.singleMarkers[layer]);
            } else {
                // create
                if (!(this.checkGlobalInitTimeout())) {
                    reject(new Error("marker create before map init"));
                    return;
                }
                let marker: google.maps.Circle = new google.maps.Circle({
                    // strokeColor: Constants.defaultColor,
                    strokeColor: options.color ? options.color : this.theme.markerFrameColor,
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    // fillColor: Constants.defaultColor,
                    fillColor: options.color ? options.color : this.theme.markerFrameColor,
                    fillOpacity: 0.35,
                    map: this.map,
                    zindex: options.zindex,
                    center: new ILatLng(position.lat, position.lng),
                    radius: options.size
                });

                this.singleMarkers[layer] = this.getDefaultSingleMarkerContainer();
                this.singleMarkers[layer].container.marker = marker as any;
                this.singleMarkers[layer].container.marker.setVisible(true);

                resolve(this.singleMarkers[layer]);
            }
        });
        return promise;
    }

    /**
     * init multi marker
     * @param markers 
     * @param layer 
     */
    private initMultiMarker(markers: IArrayMarkerCollectionWeb, layer: string) {
        if (markers[layer] && markers[layer].internal) {
            return true;
        } else {
            markers[layer] = {
                container: [],
                internal: {
                    timestamp: new Date().getTime(),
                    initCounter: 3,
                    layerInitialized: false,
                    syncInProgress: false,
                    visibleLayer: true,
                    animateTimeout: null
                }
            };
            return true;
        }
    }

    /**
     * check single marker initialized
     * @param markers 
     * @param layer 
     */
    private initSingleMarker(markers: ISingleMarkerCollectionWeb, layer: string) {
        markers[layer] = this.getDefaultSingleMarkerContainer();
    }

    /**
     * get default single marker container
     */
    private getDefaultSingleMarkerContainer() {
        let m: ISingleMarkerWeb = {
            container: {
                marker: null,
                markerContent: null
            },
            internal: {
                timestamp: new Date().getTime(),
                initCounter: 3,
                layerInitialized: false,
                syncInProgress: false,
                visibleLayer: true,
                animateTimeout: null
            }
        };
        return m;
    }


    /**
     * check single marker initialized
     * @param markers 
     * @param layer 
     */
    private checkSingleMarker(markers: ISingleMarkerCollectionWeb, layer: string) {
        if (markers[layer] && markers[layer].container && markers[layer].internal) {
            return true;
        }
        return false;
    }

    /**
     * check multi marker initialized
     * @param markers 
     * @param layer 
     */
    private checkMultiMarker(markers: IArrayMarkerCollectionWeb, layer: string) {
        if (markers[layer] && markers[layer].container && markers[layer].internal) {
            return true;
        }
        return false;
    }

    /**
     * sync external data buffer with marker buffer
     * should be all the same type/layer e.g. places
     * @param data 
     */
    syncMarkerArray(layer: string, data: IPlaceMarkerContent[]) {
        let promise = new Promise((resolve, reject) => {
            if (!data) {
                reject(new Error("data undefined"));
                return;
            }

            if (!this.checkMultiMarker(this.multiMarkers, layer)) {
                this.initMultiMarker(this.multiMarkers, layer);
            }

            // USE GLOBAL LOCK ON THE ENTIRE MARKER ARRAY SO THAT MARKERS ARE NOT DOUBLED
            if (this.debugConsole)
                console.log("markers > sync marker layer: " + layer);

            if (this.multiMarkers[layer].internal.syncInProgress) {
                reject(new Error("layer sync already in progress: " + layer));
                return;
            }

            // the layer is initialized but the update is too fast
            if (this.multiMarkers[layer].internal.layerInitialized && !this.checkInitTimeout(this.multiMarkers[layer].internal.timestamp)) {
                reject(new Error("marker update too fast"));
                return;
            }

            let onComplete = () => {
                this.multiMarkers[layer].internal.syncInProgress = false;
                if (this.debugConsole)
                    console.log("markers > sync marker layer complete: " + layer);
            };

            this.multiMarkers[layer].internal.syncInProgress = true;

            let multiMarkers: IPlaceMarkerContent[] = this.multiMarkers[layer].container.map(c => c.markerContent);
            let promisesU: Promise<boolean>[] = [];
            // console.log("old buffer: " + multiMarkers.length);
            // console.log("new buffer: " + data.length);
            let added: number = 0;
            let updated: number = 0;
            let removed: number = 0;
            let removeArray: IPlaceMarkerContent[] = [];
            let updateArray: IPlaceMarkerContent[] = [];
            let insertArray: IPlaceMarkerContent[] = [];
            let update: boolean = false;


            let debug: boolean = false;

            // check update or insert
            for (let i = 0; i < data.length; i++) {
                if (!data[i]) {
                    continue;
                }
                update = false;
                for (let j = 0; j < multiMarkers.length; j++) {
                    if (multiMarkers[j].uid === data[i].uid) {
                        update = true;
                        break;
                    }
                }
                if (update) {
                    updateArray.push(data[i]);
                } else {
                    insertArray.push(data[i]);
                }
            }
            // check remove
            let remove: boolean = true;
            for (let i = 0; i < multiMarkers.length; i++) {
                // console.log("mm: ", multiMarkers[i].uid);
                remove = true;
                for (let j = 0; j < data.length; j++) {

                    if (!data[j]) {
                        // console.log("d: missing data");
                        continue;
                    }

                    // console.log("d: ", data[j].uid);

                    if (multiMarkers[i].uid === data[j].uid) {
                        remove = false;
                        break;
                    }
                }
                if (remove) {
                    if (debug) {
                        console.log("remove, locksync: " + multiMarkers[i].lockSync);
                    }
                    if (!multiMarkers[i].lockSync) {
                        removeArray.push(multiMarkers[i]);
                    } else {
                        // multiMarkers[i].visible = true;
                        // updateArray.push(multiMarkers[i]);
                    }
                }
            }

            if (debug) {
                console.log("to create: " + insertArray.length + ", to update: " + updateArray.length + ", to remove: " + removeArray.length);
            }
            // console.log(updateArray);

            // update
            for (let i = 0; i < updateArray.length; i++) {
                updated += 1;
                promisesU.push(new Promise((resolve) => {
                    this.updateArrayMarkerCore(layer, updateArray[i]).then(() => {
                        resolve(true);
                    }).catch((err: Error) => {
                        console.error(err);
                        resolve(false);
                    });
                }));
            }

            let promiseUpdate: Promise<boolean[]>;
            if (updateArray.length > 0) {
                promiseUpdate = Promise.all(promisesU);
            } else {
                promiseUpdate = Promise.resolve([true]);
            }

            // update done
            promiseUpdate.then(() => {
                // console.log("updated buffer/update: " + multiMarkers.length);

                if (debug) {
                    console.log("markers > updated: " + updated);
                }

                let promisesI: Promise<boolean>[] = [];
                // insert
                for (let i = 0; i < insertArray.length; i++) {
                    added += 1;
                    promisesI.push(new Promise((resolve) => {
                        this.insertArrayMarkerCore(layer, insertArray[i]).then(() => {
                            resolve(true);
                        }).catch(() => {
                            resolve(false);
                        });
                    }));
                }

                let promiseInsert: Promise<boolean[]>;
                if (insertArray.length > 0) {
                    promiseInsert = Promise.all(promisesI);
                } else {
                    promiseInsert = Promise.resolve([true]);
                }

                promiseInsert.then(() => {
                    // console.log("updated buffer/insert: " + multiMarkers.length);
                    // check remove
                    for (let i = 0; i < removeArray.length; i++) {
                        removed += 1;
                        this.removeArrayMarkerCore(layer, removeArray[i]);
                    }
                    // console.log("updated buffer/remove: " + multiMarkers.length);
                    // console.log("reference: " + data.length);

                    if (debug) {
                        console.log("markers > added: " + added);
                        console.log("markers > removed: " + removed);
                    }
                    // let uids: string[] = multiMarkers.map(m => m.uid);
                    // console.log("output: ", uids);
                    // console.log(Util.checkDuplicateUidArray(uids));
                    this.multiMarkers[layer].internal.layerInitialized = true;
                    onComplete();
                    resolve(true);
                }).catch((err: Error) => {
                    onComplete();
                    reject(err);
                });
            }).catch((err: Error) => {
                onComplete();
                reject(err);
            });
        });
        return promise;
    }

    /**
     * add marker for google map into the marker array
     * different for native and browser
     * @param data
     * @param type
     * @param icon
     */
    insertArrayMarker(data: IPlaceMarkerContent, show: boolean) {
        let promise = new Promise((resolve, reject) => {
            if (!data.icon) {
                resolve(true);
                return;
            }

            if (!show) {
                resolve(false);
                return;
            }

            let layer: string = data.layer;
            if (!this.checkMultiMarker(this.multiMarkers, layer)) {
                this.initMultiMarker(this.multiMarkers, layer);
            }

            if (!(this.checkGlobalInitTimeout())) {
                reject(new Error("marker insert before map init"));
                return;
            }

            this.insertArrayMarkerCore(data.layer, data).then((res) => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * show/hide last marker from array
     * @param layer 
     * @param show 
     */
    toggleLastArrayMarkerShow(layer: string, show: boolean) {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            let lastIndex: number = this.multiMarkers[layer].container.length - 1;
            if (lastIndex < 0) {
                return;
            }
            let marker: google.maps.Marker = this.multiMarkers[layer].container[lastIndex].marker;
            if (marker) {
                marker.setVisible(show);
            }
        }
    }

    toggleArrayMarkerShow(layer: string, index: number, show: boolean) {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            let marker: google.maps.Marker = this.multiMarkers[layer].container[index].marker;
            if (marker) {
                marker.setVisible(show);
            }
        }
    }

    /**
     * show circle marker
     * @param md 
     * @param zindex 
     * @param size 
     */
    private showCircleWeb(md: IPlaceMarkerContent, zindex, size) {
        let marker: google.maps.Circle;
        marker = new google.maps.Circle({
            // strokeColor: Constants.defaultColor,
            strokeColor: this.theme.markerFrameColor,
            strokeOpacity: 0.8,
            strokeWeight: 2,
            // fillColor: Constants.defaultColor,
            fillColor: md.color != null ? md.color : this.theme.markerFrameColor,
            fillOpacity: 0.35,
            map: this.map,
            zindex,
            center: new ILatLng(md.location.lat, md.location.lng),
            radius: size
        });
        // marker.setOpacity(1);
        marker.setVisible(true);
        return marker;
    }

    /**
     * show plain marker i.e. that is not drawn via canvas
     * @param md 
     * @param zindex 
     * @param size 
     */
    showPlainMarkerWeb(md: IPlaceMarkerContent, zindex: number, size: number) {
        let promise: Promise<google.maps.Marker> = new Promise((resolve) => {
            let marker: google.maps.Marker;

            console.log("show plain marker");

            let formatLabel = MarkerUtils.formatAddLabels(null, md.label, md.addLabel, md.addLabel2);
            let label: string = formatLabel.label;

            this.loadImgSrc(md).then((icon1: string) => {

                let icon: any;
                if (size != null) {
                    icon = {
                        url: icon1,
                        scaledSize: new google.maps.Size(size, size)
                    };
                } else {
                    icon = {
                        url: icon1
                    };
                }

                marker = new google.maps.Marker({
                    map: this.map,
                    optimized: false,
                    // icon: md.icon,
                    // icon: {
                    //     url: md.icon, // url
                    //     scaledSize: new google.maps.Size(50, 50)
                    // },
                    icon: icon,
                    zIndex: zindex,
                    clickable: true,
                    draggable: md.drag,
                    animation: md.animate ? google.maps.Animation.DROP : null,
                    // animation:google.maps.Animation.BOUNCE,
                    position: new ILatLng(md.location.lat, md.location.lng),
                    // label: label
                    label: ""
                });

                this.handleMarkerCallback(md, marker);

                // if (md.label) {
                //     let infowindow = new google.maps.InfoWindow({
                //         content: md.label
                //     });
                //     infowindow.open(this.map, marker);
                // }

                marker.setOpacity(1);
                marker.setVisible(true);
                resolve(marker);
            });
        });
        return promise;

    }

    /**
     * show custom marker
     * deprecated
     * @param md 
     */
    showCustomMarkerWeb(md: IPlaceMarkerContent) {
        let marker: any;
        marker = new CustomMarker(
            new ILatLng(md.location.lat, md.location.lng),
            this.map,
            md.data,
        );

        this.handleMarkerCallback(md, marker);
        // let infowindow = new google.maps.InfoWindow({
        //   content: "Hello World!"
        // });
        // infowindow.open(this.auxMap, marker);
        return marker;
    }

    loadImgSrc(md: IPlaceMarkerContent) {
        let promiseLoadSrc: Promise<string> = new Promise((resolve) => {
            let icon1: string = md.icon;
            let bloc: IBackendLocation = null;
            if (md.data && md.data.loc && md.data.loc.merged) {
                bloc = md.data.loc.merged;
            }
            if (md.ext && (bloc && bloc.googleId)) {
                if (!bloc.noLink && !md.disableGooglePhotoLoading) {
                    this.placeData.getGooglePhotoUrl(bloc.googleId).then((url: string) => {
                        if (url != null) {
                            icon1 = url;
                        }
                        resolve(icon1);
                    }).catch((err) => {
                        console.error(err);
                        resolve(icon1);
                    });
                } else {
                    resolve(icon1);
                }
            } else {
                resolve(icon1);
            }
        });
        return promiseLoadSrc;
    }

    /**
     * show canvas marker
     * with circular frame, label, etc
     * @param md 
     * @param zindex 
     * @param opts 
     */
    showCanvasMarkerWeb(md: IPlaceMarkerContent, zindex: number, opts: IShowMarkerOptions): Promise<google.maps.Marker> {
        // console.log(md, zindex, opts);
        let promise: Promise<google.maps.Marker> = new Promise((resolve, reject) => {
            let marker: google.maps.Marker;
            let md1: IPlaceMarkerContent = md;

            let canvas = document.createElement('canvas');
            let f: ICanvasMarkerContainer = MarkerUtils.formatCanvasMarkerContainer(md, opts.width);
            canvas.width = f.fullW;
            canvas.height = f.fullH;
            MarkerUtils.setDPI(canvas, 300, false);
            let ctx = canvas.getContext('2d');
            let img = new Image();

            let isIos: boolean = GeneralCache.checkPlatformOS() === EOS.ios;
            isIos = true;

            let label: string = md.label;
            let heading: string[] = [md.heading];

            let formatLabel = MarkerUtils.formatAddLabels(heading[0], label, md.addLabel, md.addLabel2);
            heading = formatLabel.heading2d;
            if (md.minimal) {
                label = "Checkpoint";
                heading = [md.addLabel];
            }
            // label = formatLabel.label;


            this.loadImgSrc(md).then((icon1: string) => {

                img.crossOrigin = "anonymous";
                // img.crossOrigin = "Anonymous";
                // img.crossOrigin = "Use-Credentials";
                // img.crossOrigin = "";
                // img.src = "assets/img/icon-flag.png";
                // opts.color = "#dc4a38";
                let iconUrl = icon1;
                // iconUrl = iconUrl.substring(0, iconUrl.indexOf("="));
                // iconUrl += "=k";
                img.src = iconUrl;
                // console.log("icon url 1: " + markerOpts.icon + "icon url 2 " + img.src);
                // md.label = "Text Here";

                let errorOnce: boolean = false;

                let imgPromise = new Promise((resolve) => {
                    img.onload = () => {
                        // console.log("draw marker faded: ", opts.faded);
                        if (opts.circularFrame) {
                            ctx = MarkerUtils.drawMarkerText(ctx, f, img, opts.color, opts.labelFrameColor, opts.labelTextColor, opts.faded, label, heading, isIos, md.compassRotate);
                        } else {
                            ctx = MarkerUtils.drawMarkerTextPlain(ctx, f, img, opts.color, opts.labelFrameColor, opts.labelTextColor, opts.faded, label, heading, isIos, md.compassRotate);
                        }

                        let icon = canvas.toDataURL();
                        // console.log("icon set");
                        md1.icon = icon;
                        resolve(true);
                    };
                    img.onerror = (err) => {
                        // fallback to default location icon
                        console.warn("canvas image error: ", err);
                        img.src = EMarkerIcons.location;
                        if (!errorOnce) {
                            errorOnce = true;
                        } else {
                            // error loading fallback
                            resolve(false);
                        }
                    };
                });
                // test draggable mk
                // markerOpts.drag = true;
                imgPromise.then(() => {
                    let iconM = {
                        url: md1.icon,
                        // f.fullH/2
                        /**
                         * for circular frame, the marker is centered on the pin (bottom)
                         * for non frame, the marker is centered on the center of the image
                         */
                        anchor: new google.maps.Point(f.fullW / 2, opts.circularFrame ? f.fullH : f.fullH / 2),
                        scaledSize: new google.maps.Size(f.fullW, f.fullH)
                    };
                    marker = new google.maps.Marker({
                        map: this.map,
                        optimized: false,
                        icon: iconM,
                        // icon: {
                        //   url: data.icon, // url
                        //   scaledSize: new google.maps.Size(50, 50)
                        // },
                        // color: md.color,
                        zIndex: zindex,
                        clickable: true,
                        animation: md.animate ? google.maps.Animation.DROP : null,
                        // animation:google.maps.Animation.BOUNCE,
                        position: new ILatLng(md1.location.lat, md1.location.lng),
                        // label: markerOpts.title
                        draggable: md1.drag,
                        // label: label
                        label: ""
                    });
                    // use original photo for detail view
                    md1.icon = icon1;
                    // marker.setOpacity(0.5);
                    // console.log("w/callback: ", markerOpts.callback);
                    this.handleMarkerCallback(md1, marker);
                    marker.setVisible(md.visible);
                    resolve(marker);
                }).catch((err: Error) => {
                    reject(err);
                });

            }).catch((err: Error) => {
                reject(err);
            });

        });
        return promise;
    }

    /**
     * handle marker callback and info window management
     * @param pd
     * @param marker 
     */
    private handleMarkerLineCallback(pd: IPathContent, marker: google.maps.Polyline) {
        if (pd.callback) {
            marker.addListener("click", () => {
                console.log("click");
                // console.log(markerOpts);
                // check overlapping markers
                pd.callback(pd);
            });
        }
    }

    /**
     * handle marker callback and info window management
     * @param md
     * @param marker 
     */
    private handleMarkerCallback(md: IPlaceMarkerContent, marker: google.maps.Marker) {
        if (md.callback) {
            marker.addListener("click", () => {
                console.log("click");
                // console.log(markerOpts);
                // check overlapping markers
                md.callback(md);
            });
        }

        if (md.drag) {
            marker.addListener('drag', () => {
                console.log("drag marker");
            });
            marker.addListener("dragend", () => {
                let newpos: google.maps.LatLng = marker.getPosition();
                console.log("drag marker end lat: " + newpos.lat() + ", lng: " + newpos.lng());
                md.location.lat = newpos.lat();
                md.location.lng = newpos.lng();
                if (md.dragCallback) {
                    md.dragCallback(md.location.lat, md.location.lng);
                }
                // should assign to nearby place e.g. geocode
            });
        }
    }


    /**
     * get all markers data (from all layers)
     * single and multi markers
     * returns only the actual content, not the marker itself
     */
    getAllMarkersData(excludeLayers: string[]) {
        let keys: string[] = Object.keys(this.multiMarkers);
        if (excludeLayers) {
            keys = keys.filter(key => excludeLayers.indexOf(key) === -1);
        }
        let allMarkersData: IPlaceMarkerContent[] = [];
        for (let i = 0; i < keys.length; i++) {
            let m: IArrayMarkerWeb = this.multiMarkers[keys[i]];
            if (m && m.container) {
                allMarkersData = allMarkersData.concat(this.multiMarkers[keys[i]].container.map(c => c.markerContent));
            }
        }
        keys = Object.keys(this.singleMarkers);
        if (excludeLayers) {
            keys = keys.filter(key => excludeLayers.indexOf(key) === -1);
        }
        for (let i = 0; i < keys.length; i++) {
            let m: ISingleMarkerWeb = this.singleMarkers[keys[i]];
            if (m && m.container) {
                allMarkersData.push(m.container.markerContent);
            }
        }
        return allMarkersData;
    }


    /**
     * show markers that were already created but not yet added on the map
     * deprecated
     * plain/canvas
     * @param opts 
     */
    showMarkerArrayWeb(opts: IShowMarkerOptions): Promise<boolean> {
        opts.height = opts.width + opts.mh;
        // clear the existing markers first
        this.multiMarkers[opts.type].container = [];
        let zindex = 10;
        let layer: string = opts.type;
        if (!this.checkMultiMarker(this.multiMarkers, layer)) {
            this.initMultiMarker(this.multiMarkers, layer);
        }
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (!this.enable) {
                reject(new Error("master lock"));
                return;
            }
            if (!opts.circularFrame) {
                let mc = this.multiMarkers[opts.type].container;
                // assign created markers
                let promises = [];
                for (let i = 0; i < mc.length; i++) {
                    // let marker = this.showPlainMarkerWeb(mc[i].markerContent, zindex, null);
                    let promise: Promise<google.maps.Marker> = this.showPlainMarkerWeb(mc[i].markerContent, zindex, null);
                    promises.push(promise);
                    promise.then((marker) => {
                        if (this.debugConsole) {
                            console.log("markers > marker set");
                        }
                        this.multiMarkers[opts.type].container[i].marker = marker;
                    }).catch((err: Error) => {
                        console.error(err);
                        reject(err);
                    });
                }
                Promise.all(promises).then(() => {
                    console.log("markers > all markers set");
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            } else {
                if (this.debugConsole) {
                    console.log('markers > show canvas markers');
                }
                let items: IPlaceMarkerContent[] = this.multiMarkers[opts.type].container.map(c => c.markerContent);
                // assign created markers
                let promises = [];
                for (let i = 0; i < items.length; i++) {
                    let md: IPlaceMarkerContent = items[i];
                    let promise = this.showCanvasMarkerWeb(md, zindex, opts);
                    promises.push(promise);
                    promise.then((marker) => {
                        if (this.debugConsole) {
                            console.log("markers > marker set");
                        }
                        this.multiMarkers[opts.type].container[i].marker = marker;
                    }).catch((err: Error) => {
                        console.error(err);
                        reject(err);
                    });
                }
                Promise.all(promises).then(() => {
                    console.log("markers > all markers set");
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            }
        });
        return promise;
    }

    /**
     * this method is not currently used
     */
    addMarkerClusterer() {
        if (this.debugConsole) {
            console.log("remove existing marker clusters");
        }

        for (let i = 0; i < this.markerClusters.length; i++) {
            this.markerClusters[i].clearMarkers();
        }
        this.markerClusters = [];
        if (this.debugConsole) {
            console.log("add marker clusterer");
        }
        let options = {
            imagePath: EMarkerIcons.cluster
        };
        let keys: string[] = Object.keys(this.multiMarkers);
        let allMultiMarkers = [];
        for (let i = 0; i < keys.length; i++) {
            if (this.multiMarkers[keys[i]].container.length > 0) {
                allMultiMarkers = allMultiMarkers.concat(this.multiMarkers[keys[i]]);
            }
        }

        let markerCluster = new MarkerClusterer(this.map, allMultiMarkers, options);
        this.markerClusters.push(markerCluster);
        if (this.debugConsole) {
            console.log(markerCluster);
        }
    }



    /**
     * add path from waypoints
     * using single markers layer
     * @param waypoints 
     * @param layer 
     * @param show 
     */
    addPathWeb(waypoints: ILatLng[], layer: string, pathData: IPathContent, show: boolean) {

        if (!this.enable) {
            return;
        }

        let lineSymbol: google.maps.Symbol = {
            path: 'M 0,-1 0,1',
            strokeOpacity: 0,
            scale: 10
        };

        let sw: number = 10;

        let polylineSpec: google.maps.PolylineOptions = {
            path: waypoints,
            geodesic: true,
            strokeColor: this.theme.lineColor,
            strokeOpacity: 0.5,
            strokeWeight: sw,
            icons: [{
                icon: lineSymbol,
                offset: '0',
                repeat: '100px'
            }]
        };

        if (pathData.arrows) {
            polylineSpec.icons = [{
                icon: {
                    path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    strokeColor: this.theme.lineColor,
                    fillColor: this.theme.lineColor,
                    fillOpacity: 0.5,
                    scale: sw / 2
                },
                repeat: '100px'
            }]
        }

        let path: google.maps.Polyline = new google.maps.Polyline(polylineSpec);


        path.setMap(this.map);
        path.setVisible(show);

        if (!this.checkSingleMarker(this.singleMarkers, layer)) {
            this.initSingleMarker(this.singleMarkers, layer);
        }

        this.handleMarkerLineCallback(pathData, path);
        this.singleMarkers[layer].container.marker = path as any;
        return path;
    }


    /**
     * set entire layer visible/invisible
     * it can be any kind of marker, also circle
     * single/multi
     * @param layer 
     * @param visible 
     */
    setVisibleLayer(layer: string, visible: boolean) {
        if (this.multiMarkers[layer] != null) {
            this.multiMarkers[layer].internal.visibleLayer = visible;
            this.multiMarkers[layer].container.map(c => c.marker).forEach((marker: google.maps.Marker) => {
                if (marker != null) {
                    marker.setVisible(visible);
                }
            });
        } else {
            if (this.singleMarkers[layer] != null) {
                this.singleMarkers[layer].internal.visibleLayer = visible;
                this.singleMarkers[layer].container.marker.setVisible(visible);
            }
        }
    }

    /**
     * get visible state of marker layer
     * single/multi
     * @param layer 
     */
    getVisibleLayer(layer: string) {
        if (this.multiMarkers[layer] != null) {
            return this.multiMarkers[layer].internal.visibleLayer;
        } else {
            if (this.singleMarkers[layer] != null) {
                return this.singleMarkers[layer].internal.visibleLayer;
            }
        }
        return false;
    }

    /**
     * clear markers from marker array from google map
     * clear the marker array or single marker
     * @param layer
     */
    clearMarkers(layer: string) {
        // Sets the map on all markers in the array.
        if (this.multiMarkers[layer] != null) {
            this.multiMarkers[layer].container.forEach((c: IMarkerContainerWeb) => {
                if (c.marker != null && c.marker.setMap != null) {
                    c.marker.setMap(null);
                    c.marker = null;
                }
            });
            // this.multiMarkers[type].container.map(c => c.marker = null);
        }
        if (this.singleMarkers[layer] != null) {
            if (this.singleMarkers[layer].container && this.singleMarkers[layer].container.marker) {
                this.singleMarkers[layer].container.marker.setMap(null);
                this.singleMarkers[layer].container.marker = null;
            }
        }
    }

    /**
     * clear the last marker from the array
     * @param layer 
     * @param remove completely remove the marker from the array
     */
    clearLastArrayMarker(layer: string, remove: boolean = false) {
        // Sets the map on all markers in the array.
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            let lastIndex: number = this.multiMarkers[layer].container.length - 1;
            let marker = this.multiMarkers[layer].container[lastIndex].marker;
            if (marker != null && marker.setMap != null) {
                marker.setMap(null);
            }

            if (remove) {
                // clear the marker with all associated data
                this.multiMarkers[layer].container.splice(-1, 1);
            } else {
                // only clear the marker from the container
                this.multiMarkers[layer].container.forEach(c => {
                    c.marker = null;
                });
            }
        }
    }

    /**
     * completely remove the marker from the array
     * @param layer 
     * @param index 
     */
    clearArrayMarkerByIndex(layer: string, index: number) {
        // Sets the map on all markers in the array.
        if (this.debugConsole) {
            console.log("markers > clear marker by index: " + layer + ", " + index);
        }
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            let markerContainer: IMarkerContainerWeb = this.multiMarkers[layer].container[index];
            if (!markerContainer) {
                return;
            }
            let marker: any = markerContainer.marker;
            this.clearMarker(marker);
            if (index !== -1 && index < this.multiMarkers[layer].container.length) {
                this.multiMarkers[layer].container.splice(index, 1);
            }
        }
    }

    /**
     * clear the specified marker from the map
     * @param marker 
     */
    private clearMarker(marker: google.maps.Marker) {
        if (marker != null && marker.setMap != null) {
            marker.setMap(null);
        }
    }

    /**
     * completely remove the marker from the array
     * @param layer 
     * @param uid 
     */
    clearArrayMarkerByUid(layer: string, uid: string) {
        // Sets the map on all markers in the array.
        if (this.debugConsole) {
            console.log("markers > clear marker by uid: " + uid);
        }
        let index: number = this.getArrayMarkerIndexByUid(layer, uid);
        this.clearArrayMarkerByIndex(layer, index);
    }

    getArrayMarkerDataByUid(layer: string, uid: string) {
        if (this.debugConsole) {
            console.log("get marker by uid: " + uid);
        }
        let index: number = this.getArrayMarkerIndexByUid(layer, uid);
        return this.getArrayMarkerDataByIndex(index, layer);
    }

    /**
     * clear the marker layer completely
     * @param layer 
     */
    clearMarkerLayer(layer: string) {
        if (this.debugConsole) {
            console.log("markers > clear marker layer: ", layer);
        }
        if (this.multiMarkers[layer]) {
            this.multiMarkers[layer] = null;
        }
        if (this.singleMarkers[layer] != null) {
            this.singleMarkers[layer] = null;
        }
    }

    /**
     * 
     * @param index 
     * @param type 
     */
    getMarkerLocationByIndex(index: number, type: string) {
        let marker: google.maps.Marker = this.getArrayMarkerByIndex(index, type);
        let pos;
        if (marker != null) {
            pos = marker.getPosition();
            pos = new ILatLng(pos.lat(), pos.lng());
            return pos;
        } else {
            return null;
        }
    }

    /**
     * get array marker by index
     * @param index 
     * @param layer 
     */
    getArrayMarkerByIndex(index: number, layer: string) {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            if (index === null) {
                index = this.multiMarkers[layer].container.length - 1;
            }
            if (index >= this.multiMarkers[layer].container.length) {
                return null;
            }
            if (index < 0) {
                return null;
            }
            let marker: google.maps.Marker;
            marker = this.multiMarkers[layer].container[index].marker;
            return marker;
        }
        return null;
    }

    /**
     * get array marker data by index
     * @param index 
     * @param layer 
     */
    getArrayMarkerDataByIndex(index: number, layer: string) {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            if (index === null) {
                index = this.multiMarkers[layer].container.length - 1;
            }
            if (index >= this.multiMarkers[layer].container.length) {
                return null;
            }
            if (index < 0) {
                return null;
            }
            let marker: IPlaceMarkerContent;
            marker = this.multiMarkers[layer].container[index].markerContent;
            return marker;
        }
        if (this.debugConsole) {
            console.log("not found");
        }
        return null;
    }


    /**
     * get data layer content
     * @param layer 
     */
    getSingleMarkerDataByLayer(layer: string): IPlaceMarkerContent {
        if (this.checkSingleMarker(this.singleMarkers, layer)) {
            return this.singleMarkers[layer].container.markerContent;
        }
        return null;
    }

    /**
     * get data layer content
     * @param layer 
     */
    getArrayMarkerDataByLayer(layer: string): IPlaceMarkerContent[] {
        if (this.checkMultiMarker(this.multiMarkers, layer)) {
            return this.multiMarkers[layer].container.map(c => c.markerContent);
        }
        return null;
    }

    /**
     * completely remove the marker layer
     * @param layer 
     */
    disposeLayer(layer: string) {
        this.clearMarkers(layer);
        this.clearMarkerLayer(layer);
    }

    /**
     * clear all markers
     * dispose all layers
     */
    clearAll() {
        let keys = Object.keys(this.multiMarkers);
        keys.forEach(key => {
            this.disposeLayer(key);
        });
        keys = Object.keys(this.singleMarkers);
        keys.forEach(key => {
            this.disposeLayer(key);
        });
    }

    /**
     * clear all without removing from the map
     * used for cleanup before map unload
     * map.clearAll should be used for clearing the map
     */
    clearAllNoRemove() {
        let keys = Object.keys(this.multiMarkers);
        for (let key of keys) {
            this.clearMarkerLayer(key);
        }

        keys = Object.keys(this.singleMarkers);
        for (let key of keys) {
            this.clearMarkerLayer(key);
        }
    }

    /**
     * should be called just after init map
     */
    setGlobalMarkerInitTimestamp() {
        this.globalInitTimestamp = new Date().getTime();
    }

    /**
     * check marker update timeout
     * @param timestamp 
     * @param timeCrt 
     */
    private checkInitTimeout(timestamp: number) {
        let timeCrt: number = new Date().getTime();
        if (timestamp != null && ((timeCrt - timestamp) > MARKER_UPDATE_TIMEOUT_WEB)) {
            return this.checkGlobalInitTimeout();
        } else {
            return false;
        }
    }

    /**
     * check marker update global timeout
     */
    private checkGlobalInitTimeout() {
        let timeCrt: number = new Date().getTime();
        if (!this.globalInitTimestamp || ((timeCrt - this.globalInitTimestamp) > MAP_INIT_TIMEOUT_WEB)) {
            return true;
        }
        return false;
    }


    /**
     * create a new marker on a single markers layer
     * @param layer 
     * @param data 
     */
    singleMarkerCreate(layer: string, data: IPlaceMarkerContent) {
        let promise = new Promise((resolve, reject) => {
            if (!this.singleMarkers[layer]) {
                this.singleMarkers[layer] = this.getDefaultSingleMarkerContainer();
            } else {
                if (!this.singleMarkers[layer].internal.layerInitialized) {
                    reject(new Error("marker init in progress"));
                    return;
                }
            }
            this.showMarkerWeb(layer, data, false).then((res) => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * check if the layer exists
     */
    checkLayer(layer: string, multi: boolean) {
        let defined: boolean = true;
        if (!multi) {
            if (!this.singleMarkers[layer]) {
                defined = false;
            }
        } else {
            if (!this.multiMarkers[layer]) {
                defined = false;
            }
        }
        return defined;
    }


    /**
     * show a single marker
     * marker/circle
     * plain/canvas
     * @param layer 
     * @param data 
     * @param multi 
     */
    private showMarkerWeb(layer: string, data: IPlaceMarkerContent, multi: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {

            if (!this.enable) {
                reject(new Error("master lock"));
                return;
            }


            if (!this.checkLayer(layer, multi)) {
                reject(new Error("undefined layer: " + layer));
                return;
            }

            // if (!multi) {
            //     if (!this.checkSingleMarker(this.singleMarkers, layer)) {
            //         this.initSingleMarker(this.singleMarkers, layer);
            //     }
            // } else {
            //     if (!this.checkMultiMarker(this.multiMarkers, layer)) {
            //         this.initMultiMarker(this.multiMarkers, layer);
            //     }
            // }

            let marker: google.maps.Marker;
            let circle: google.maps.Circle;

            switch (data.shape) {
                case EMapShapes.marker:
                    switch (data.mode) {
                        case EMarkerTypes.plain:
                            // insert/add marker
                            this.showPlainMarkerWeb(data, data.zindex, data.radius).then((marker: google.maps.Marker) => {
                                if (!multi) {
                                    this.singleMarkers[layer].container.marker = marker;
                                    this.singleMarkers[layer].container.markerContent = data;
                                    this.singleMarkers[layer].internal.layerInitialized = true;
                                } else {
                                    data.currentLocationCopy = new ILatLng(data.location.lat, data.location.lng);
                                    this.multiMarkers[layer].container.push({
                                        marker,
                                        markerContent: data
                                    });
                                }
                                resolve(true);
                            });
                            break;
                        case EMarkerTypes.canvasFrame:
                        case EMarkerTypes.canvasPlain:
                        case EMarkerTypes.canvasPlainCenter:
                            let mh = 20;
                            let mw = 20;

                            if (!data.radius) {
                                data.radius = 120;
                            } else {
                                mh = Math.floor(mh * data.radius / 120);
                                mw = Math.floor(mw * data.radius / 120);
                            }

                            let opts: IShowMarkerOptions = {
                                mh,
                                mw,
                                width: data.radius,
                                type: layer,
                                circularFrame: data.mode === EMarkerTypes.canvasFrame,
                                // color: data.color,
                                // color: "#dc4a38",
                                color: data.color ? data.color : this.theme.markerFrameColor,
                                faded: data.locked
                            };

                            opts.height = opts.width + opts.mh;

                            // insert/add marker
                            this.showCanvasMarkerWeb(data, data.zindex, opts).then((marker: google.maps.Marker) => {
                                if (!multi) {
                                    this.singleMarkers[layer].container.marker = marker;
                                    this.singleMarkers[layer].container.markerContent = data;
                                    this.singleMarkers[layer].internal.layerInitialized = true;
                                } else {
                                    this.multiMarkers[layer].container.push({
                                        marker,
                                        markerContent: data
                                    });
                                }

                                // TEST ACCURACY
                                if (this.testAccuracy) {
                                    data.icon = EMarkerIcons.testA;
                                    PromiseUtils.wrapNoAction(this.showPlainMarkerWeb(data, data.zindex, null), true);
                                }

                                resolve(true);
                            }).catch((err: Error) => {
                                console.error(err);
                                if (!multi) {
                                    this.singleMarkers[layer].internal.layerInitialized = true;
                                }
                                reject(err);
                            });
                            break;
                        default:
                            resolve(false);
                            break;
                    }
                    break;
                case EMapShapes.circle:
                    circle = this.showCircleWeb(data, data.zindex, data.radius);
                    if (!multi) {
                        this.singleMarkers[layer].container.marker = circle as any;
                        this.singleMarkers[layer].container.markerContent = data;
                        this.singleMarkers[layer].internal.layerInitialized = true;
                    } else {
                        this.multiMarkers[layer].container.push({
                            marker: circle as any,
                            markerContent: data
                        });
                    }
                    resolve(true);
                    break;
                default:
                    resolve(false);
                    break;
            }
        });
        return promise;
    }



    /**
     * update marker
     * resolve to false if the marker should be created instead
     * @param thisMarkerName 
     * @param position 
     */
    singleMarkerUpdate(layer: string, data: IPlaceMarkerContent, opts: IMoveMapOptions) {
        let promise = new Promise((resolve) => {
            let result: IMarkerUpdateResult = {
                code: EMarkerUpdateCode.ok,
                message: "ok"
            };
            // console.log("update marker: " + layer);
            if (this.checkSingleMarker(this.singleMarkers, layer)) {
                if (!this.singleMarkers[layer].internal.layerInitialized) {
                    // should wait
                    result.code = EMarkerUpdateCode.shouldWait;
                    result.message = "marker create in progress";
                    resolve(result);
                    return;
                } else {
                    let timeCrt: number = new Date().getTime();
                    let rateLimiter: boolean = true;
                    if (opts.unlockLimiter) {
                        rateLimiter = false;
                    }
                    // prevent double marker if set marker too fast
                    if ((rateLimiter && this.checkInitTimeout(this.singleMarkers[layer].internal.timestamp)) || (!rateLimiter && this.checkGlobalInitTimeout())) {
                        let enableAnimate: boolean = false;
                        if (opts && opts.animateCamera && enableAnimate) {
                            this.animatePositionUpdate(this.singleMarkers[layer], this.singleMarkers[layer].container.markerContent.currentLocationCopy, data.location, opts.duration).then(() => {
                                this.singleMarkers[layer].internal.timestamp = timeCrt;
                                this.singleMarkers[layer].container.markerContent.currentLocationCopy = new ILatLng(data.location.lat, data.location.lng);
                                // console.log("marker update done");
                                resolve(result);
                            });
                        } else {
                            this.singleMarkers[layer].container.marker.setPosition(data.location);
                            this.singleMarkers[layer].internal.timestamp = timeCrt;
                            resolve(result);
                        }
                        return;
                    } else {
                        // should wait
                        result.code = EMarkerUpdateCode.shouldWait;
                        result.message = "marker update too fast";
                        resolve(result);
                        return;
                    }
                }
            } else {
                // should create
                result.code = EMarkerUpdateCode.shouldCreate;
                result.message = "";
                resolve(result);
                return;
            }
        });
        return promise;
    }

    animatePositionUpdate(mc: ISingleMarkerWeb, a: ILatLng, b: ILatLng, duration: number) {
        let promise = new Promise((resolve) => {
            let fraction: number = 0;
            let dt: number = 500;
            let targetTime: number = duration != null ? duration : 200;
            let npoints: number = targetTime / dt;
            let dfraction: number = 1 / npoints;

            mc.internal.animateTimeout = ResourceManager.clearTimeout(mc.internal.animateTimeout);

            let from: ILatLng = new ILatLng(a.lat, a.lng);
            let to: ILatLng = new ILatLng(b.lat, b.lng);

            let distance: number = GeometryUtils.getDistanceBetweenEarthCoordinates(from, to, 0);
            let heading: number = GeometryUtils.computeHeading(from, to);

            // console.log("move a: ", from, ", b: ", to, ", npoints: ", npoints, ", dfraction: ", dfraction);

            // if (distance > 0) {
            //     console.log(distance + ", " + fraction + ", " + heading);
            // }

            let pointArray: ILatLng[] = [];
            for (let i = 0; i < npoints; i++) {
                fraction += dfraction;
                pointArray.push(GeometryUtils.moveDeltaDistanceHeading(from, distance * fraction, heading));
            }

            // console.log(pointArray);

            let pointIndex: number = 0;

            let animateMk = () => {

                // fraction += dfraction;

                if (pointIndex >= npoints) {
                    mc.internal.animateTimeout = ResourceManager.clearTimeout(mc.internal.animateTimeout);
                    resolve(true);
                    return;
                }

                // mc.container.marker.setPosition(GeometryUtils.moveDeltaDistanceHeading(from, distance * fraction, heading));

                mc.container.marker.setPosition(pointArray[pointIndex]);

                pointIndex += 1;

                // console.log("animate mk pos: ", fraction);
                // mc.container.marker.setPosition(GeometryUtils.getInterpolate(from, to, fraction));
                // mc.container.marker.setPosition(new ILatLng(from.lat + fraction * 0.01, from.lng + fraction * 0.01));
                mc.internal.animateTimeout = setTimeout(() => {
                    animateMk();
                }, dt);
            };

            animateMk();
            // mc.container.marker.setPosition(to);
            // resolve(true);
        });
        return promise;
    }
}
