import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ContentChild, OnChanges, SimpleChange, SimpleChanges, ViewChild } from '@angular/core';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { InnerContentDirective } from 'src/app/directives/directives/inner-content';
import { StringUtils } from 'src/app/services/app/utils/string-utils';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { MediaUtilsService } from 'src/app/services/media/media-utils';
import { PhotosService } from 'src/app/services/media/photos';

@Component({
  selector: 'edit-textarea',
  templateUrl: './edit-textarea.component.html',
  styleUrls: ['./edit-textarea.component.scss'],
})
export class EditTextareaComponent implements OnInit, OnChanges {
  @ContentChild(InnerContentDirective, { read: ElementRef })
  content: ElementRef;

  appIconsStandard = EAppIconsStandard;
  editSelected: boolean = false;
  loaded: boolean = false;

  lock: boolean = false;

  @Input()
  mainContainer: any;

  @Input()
  editContainer: any;

  @Input()
  label: string;

  @Input()
  key: string;

  @Input()
  photoKey: string;

  @Input()
  maxLength: number;

  @Input()
  alwaysEdit: boolean;

  @Input()
  withPhotoUpload: boolean;

  @Input()
  checkOut: boolean;

  @Input()
  placeholder: string;

  @Input()
  popupInput: boolean;

  @Input()
  popupInputAlways: boolean;

  @Output()
  update: EventEmitter<any> = new EventEmitter();

  @Output()
  focus: EventEmitter<boolean> = new EventEmitter();

  labelSelected: string = "Done";
  labelAddPhoto: string = "Upload";

  photoUrl: string = null;

  showCharCount: boolean = false;

  divPlaceholder: string = "Type a description";
  textareaPlaceholder: string = "Type a description";

  constructor(
    public photoProvider: PhotosService,
    public mediaUtils: MediaUtilsService,
    public analytics: AnalyticsService,
    public uiext: UiExtensionService
  ) { }


  /**
   * function to adjust the height of the message textarea
   */
  adjustTextarea(event: any): void {
    console.log("adjust textarea");
    let textarea: any = event.target.querySelector('textarea');
    textarea.style.overflow = 'hidden';
    textarea.style.height = 'auto';
    textarea.style.height = textarea.scrollHeight + 'px';
    this.setFocusState(true);
    return;
  }

  setFocusState(state: boolean){
    this.focus.emit(state);
  }

  ngOnInit(): void {
    if (!this.maxLength) {
      this.maxLength = 10000;
    } else {
      this.showCharCount = true;
    }
    if (this.alwaysEdit) {
      this.editSelected = true;
    }
    this.loaded = true;
    if (this.placeholder) {
      this.divPlaceholder = this.placeholder;
      this.textareaPlaceholder = StringUtils.textFromHTML(this.placeholder);
    }
    console.log("main container: ", this.mainContainer[this.key]);
    console.log("edit container: ", this.editContainer[this.key]);
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'checkOut':
            // this.reviewChange();
            break;
        }
      }
    }
  }

  ngAfterContentInit() {
    if (!this.label) {
      if (this.content) {
        this.label = this.content.nativeElement.innerText;
      }
    }
  }

  onSwitchEditMode() {
    if (GeneralCache.enablePopupInputMode && (this.popupInput && (GeneralCache.checkPlatformOS() !== EOS.ios)) || this.popupInputAlways) {
      this.update.emit(false);
    } else {
      this.editSelected = !this.editSelected;
      // if (GeneralCache.checkOSReal() !== EOS.ios) {
      //   this.focus.emit(this.editSelected);
      // }
      if (!this.editSelected) {
        this.reviewChange();
        this.setFocusState(false);
      }
    }
  }

  reviewChange() {
    console.log("review change");
    this.mainContainer[this.key] = StringUtils.textToHTML(this.editContainer[this.key]);
    this.update.emit(true);
  }
}
