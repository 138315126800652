<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" [title]="title" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()"
    rightButton="settings" [rightIcon]="true" (rightClick)="options()" [loading]="!loaded"></leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <div class="view-frame theme-background" fxLayout="column">

    <!-- <div *ngIf="!hasGroups && loaded">
            <div [innerHTML]="description" class="padding-s info-text-m description-frame text-color-primary"></div>
        </div> -->

    <div class="scrollbar-y">
      <ion-list *ngIf="group" class="list-padding list-padding-top-inner">
        <ion-item *ngFor="let member of group.members" class="list-item list-item-padding transparent-bg">
          <div tappable (click)="viewMember(member)" class="full-w theme-border-bottom-thin">
            <mp-user-card class="full-w" [member]="member" [admin]="isAdmin" (removeMember)="removeMember(member)">
            </mp-user-card>
          </div>
        </ion-item>
      </ion-list>
    </div>
  </div>
</ion-content>