import { Injectable, NgZone } from "@angular/core";
import { CodePush, SyncStatus, DownloadProgress, SyncOptions, InstallMode, ILocalPackage, IRemotePackage } from "@ionic-native/code-push/ngx";
import { UiExtensionService } from "../ui/ui-extension";
import { BehaviorSubject } from "rxjs";
import { Platform } from "@ionic/angular";
import { IsDebug } from "@ionic-native/is-debug/ngx";
import { GeneralCache } from "../../../classes/app/general-cache";
import { ELiveUpdateStatus } from "../../../classes/def/app/liveupdate";
import { SettingsManagerService } from '../settings-manager';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { ApiDef } from 'src/app/classes/app/api';
import { StorageOpsService } from '../data/storage-ops';
import { ELocalAppDataKeys } from 'src/app/classes/def/app/storage-flags';
import { AppConstants } from "src/app/classes/app/constants";
import { ECodePushSource } from "src/app/classes/def/app/app";
import { WebviewUtilsService } from "../../app/utils/webview-utils";


@Injectable({
    providedIn: 'root'
})
export class LiveUpdateCoreService {
    statusObs: BehaviorSubject<number>;
    progressObs: BehaviorSubject<DownloadProgress>;

    debugKey: string = ApiDef.codePushDebugKey;
    releaseKey: string = ApiDef.codePushReleaseKey;
    debugKeyIOS: string = ApiDef.codePushDebugKeyIOS;
    releaseKeyIOS: string = ApiDef.codePushReleaseKeyIOS;

    key: string = "";
    updateTs: number = 0;
    package: IRemotePackage;

    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public codePush: CodePush,
        public uiext: UiExtensionService,
        public plt: Platform,
        public webView: WebviewUtilsService,
        public ngZone: NgZone,
        public isDebug: IsDebug,
        public settingsProvider: SettingsManagerService,
        public storageOps: StorageOpsService
    ) {
        console.log("live update core service created");
        this.statusObs = new BehaviorSubject(null);
        this.progressObs = new BehaviorSubject(null);

        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
                if (this.platform.IOS) {
                    // load ios keys
                    this.debugKey = this.debugKeyIOS;
                    this.releaseKey = this.releaseKeyIOS;
                }
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    /**
     * get status observable for the update process
     */
    getStatusObservable() {
        return this.statusObs;
    }

    /**
     * get progress observable for the update process
     */
    getProgressObservable() {
        return this.progressObs;
    }

    /**
     * restart the app to apply the updates
     */
    async restartApp() {
        if (AppConstants.gameConfig.codePushSource === ECodePushSource.none) {
            return;
        }
        await this.uiext.showLoadingV2Queue("Please wait..");
        // stop asking for permission to leave page
        GeneralCache.codePushReload = true;
        // set updated flag
        await this.storageOps.setStorageFlagResolve({
            flag: ELocalAppDataKeys.codePushUpdated,
            value: true
        });
        this.codePush.restartApplication().then(() => {
            console.log("will now restart the app");
            this.uiext.dismissLoadingV2();
        }).catch((err: Error) => {
            console.error(err);
            this.uiext.dismissLoadingV2();
        });
    }

    /**
     * get current package info
     * e.g. code push release label
     */
    getCurrentPackageInfo() {
        let promise = new Promise((resolve, reject) => {
            if (AppConstants.gameConfig.codePushSource === ECodePushSource.none) {
                resolve(null);
                return;
            }
            this.codePush.getCurrentPackage().then((package1: ILocalPackage) => {
                console.log(package1);
                if (package1) {
                    GeneralCache.codePushLabel = package1.label;
                    resolve(package1.label);
                } else {
                    resolve(null);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    confirmUpdateCompleted() {
        if (AppConstants.gameConfig.codePushSource === ECodePushSource.none) {
            return;
        }
        this.codePush.notifyApplicationReady().then(() => {
            console.log("code push notify ready");
        }).catch((err: Error) => {
            console.error(err);
        });
    }


    /**
     * check for updates
     * if the app version is the same (e.g. 1.0.0), then it looks for the latest update package
     * if the app version is greater than any available packages, then it returns null (up to date)
     * if there are no more updates it also returns null (up to date)
     */
    checkUpdate() {
        let promise = new Promise((resolve, reject) => {
            if (AppConstants.gameConfig.codePushSource === ECodePushSource.none) {
                resolve(null);
                return;
            }
            this.progressObs.next(null);
            this.statusObs.next(null);
            console.log("code push check for updates");
            this.isDebug.getIsDebug().then(isDebug => {
                console.log('is debug:', isDebug);
                this.key = isDebug ? this.debugKey : this.releaseKey;
                this.codePush.checkForUpdate(this.key).then((package1: IRemotePackage) => {
                    console.log("check for update: ");
                    console.log(package1);
                    resolve(package1);
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * check for available code push update and add to cache
     */
    checkUpdateAddToCache(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            this.checkUpdate().then((package1: IRemotePackage) => {
                if (package1) {
                    this.package = package1;
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch(() => {
                resolve(false);
            });
        });
        return promise;
    }


    updateDownloadProgress(progress: DownloadProgress) {
        let tstamp: number = new Date().getTime();
        let percent: number = 0;
        if (progress) {
            percent = progress.receivedBytes / progress.totalBytes * 100;
        }
        if ((tstamp - this.updateTs) > 100 || (percent >= 100)) {
            this.updateTs = tstamp;
            console.log(progress);
            // let progressString: string = `Downloaded ${progress.receivedBytes} of ${progress.totalBytes}`;
            // this.progressObs.next(progressString);
            this.progressObs.next(progress);
        }
    }

    /**
     * download and install updates
     * @param package1 
     */
    update(package1: IRemotePackage) {
        let promise = new Promise((resolve, reject) => {
            package1.download((downloadedPackage: ILocalPackage) => {
                downloadedPackage.install(() => {
                    resolve(true);
                }, (err: Error) => {
                    reject(err);
                });
            }, (err: Error) => {
                reject(err);
            }, (progress: DownloadProgress) => {
                this.ngZone.run(() => {
                    this.updateDownloadProgress(progress);
                });
            });
        });
        return promise;
    }

    /**
     * check for updates via code push
     */
    checkUpdateAutoInstall() {
        this.progressObs.next(null);
        this.statusObs.next(null);

        this.isDebug.getIsDebug().then(isDebug => {
            console.log('Is debug:', isDebug);
            this.key = isDebug ? this.debugKey : this.releaseKey;

            /**
             * Convenience method for installing updates in one method call
             * https://github.com/Microsoft/cordova-plugin-code-push#api-reference
             */

            let syncOptions: SyncOptions = {
                updateDialog: {
                    appendReleaseDescription: true,
                    descriptionPrefix: "\n\nChange log:\n"
                },
                // installMode: InstallMode.IMMEDIATE
                deploymentKey: this.key,
                installMode: InstallMode.ON_NEXT_RESTART
            };

            /**
             * Convenience method for installing updates in one method call. 
             * This method is provided for simplicity, and its behavior can be replicated by using 
             * window.codePush.checkForUpdate(), RemotePackages download() and LocalPackages install() methods.
             */
            let statusObservable = this.codePush.sync(syncOptions, (progress: DownloadProgress) => {
                this.ngZone.run(() => {
                    this.updateDownloadProgress(progress);
                });
            });

            statusObservable.subscribe((syncStatus) => {
                // console.log(syncStatus);
                this.ngZone.run(() => {
                    switch (syncStatus) {
                        case SyncStatus.CHECKING_FOR_UPDATE:
                            console.log("checking for update (1)");
                            break;
                        case SyncStatus.DOWNLOADING_PACKAGE:
                            console.log("downloading package (2)");

                            // this.showUpdateModal(this.statusObs, this.progressObs, true);
                            this.statusObs.next(ELiveUpdateStatus.started);

                            break;
                        case SyncStatus.IN_PROGRESS:
                            console.log("in progress");
                            break;
                        case SyncStatus.INSTALLING_UPDATE:
                            console.log("installing update (3)");
                            break;
                        case SyncStatus.UP_TO_DATE:
                            console.log("up to update");
                            this.statusObs.next(ELiveUpdateStatus.finished);
                            // after restart
                            break;
                        case SyncStatus.UPDATE_INSTALLED:
                            console.log("update installed (4)");
                            this.statusObs.next(ELiveUpdateStatus.finished);
                            // app will restart
                            break;
                        case SyncStatus.UPDATE_IGNORED:
                            console.log("update ignored");
                            // the user cancelled the update
                            break;
                        case SyncStatus.ERROR:
                            console.log("error");
                            this.statusObs.next(ELiveUpdateStatus.error);
                            break;
                        case SyncStatus.AWAITING_USER_ACTION:
                            console.log("awaiting user action");
                            // this happens so that the user is notified about the update and may continue or ignore the update
                            break;
                    }
                });
            });
        }).catch(err => {
            console.error(err);
        });
    }
}




