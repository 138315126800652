import { Injectable } from "@angular/core";
import { IStatDef, IGameStats, EStatCodes, ISpecificStat } from "../../../classes/def/user/stats";
import { ResourcesCoreDataService } from "../../data/resources-core";
import { IStory } from "../../../classes/def/core/story";
import { IActivity, IActivityStatsContainer } from "../../../classes/def/core/activity";
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IBackendLocation } from "src/app/classes/def/places/backend-location";

@Injectable({
    providedIn: 'root'
})
export class GameStatsService {

    constructor(
        public resourcesProvider: ResourcesCoreDataService
    ) {
        console.log("game stats service created");
    }


    init() {

    }

    /** 
     * load stat def so that xp reward is shown after each completed activity
     */
    loadStatDef(reload: boolean): Promise<IStatDef[]> {
        let promise: Promise<IStatDef[]> = new Promise((resolve, reject) => {
            if (!reload && GeneralCache.resourceCache.general.statScores.loaded) {
                resolve(GeneralCache.resourceCache.general.statScores.content);
                return;
            }
            this.resourcesProvider.getStatScores().then((stats: IStatDef[]) => {
                GeneralCache.resourceCache.general.statScores.content = stats;
                GeneralCache.resourceCache.general.statScores.loaded = true;
                resolve(stats);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }
}
