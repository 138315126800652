<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" [title]="title" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back(true)"
    rightButton="settings" [rightIcon]="true" (rightClick)="presentPopover()" [loading]="!loaded">
  </leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <div class="view-frame theme-background" fxLayout="column">

    <div *ngIf="!hasEvents" class="padding-s center-text">
      <span class="center-text info-text-m bold text-color-primary">No events found</span>
    </div>

    <!-- (swipe)="swipeEvent($event)" -->
    <div class="margin-top-s scroll-type-frame-list">
      <ion-list [@showState]="showState">
        <ion-item-group *ngFor="let group of eventList">
          <ion-item-divider class="list-item-divider">
            <ion-label class="info-text-s bold text-color-primary">
              {{group.name}}
            </ion-label>
          </ion-item-divider>
          <ion-item class="list-item list-item-padding transparent-bg" *ngFor="let event of group.events" #slidingItem
            [hidden]="false">
            <event-info [data]="event" class="icon-color-primary full-w" tappable (click)="goToEventDetail(event)">
            </event-info>
          </ion-item>
        </ion-item-group>
      </ion-list>
    </div>

    <div fxFlex></div>

    <div class="padding-horizontal-s theme-border-top padding-bottom-xs">
      <leplace-paginator [pagination]="pagination" (onSwitch)="doRefresh($event)"></leplace-paginator>
    </div>
  </div>

</ion-content>