import { Component, OnInit, OnDestroy, ViewEncapsulation, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IStory, IMasterLockResponse, IStoryUnlockContext, IStoryPhoto, EStoryUnlockTokenType } from 'src/app/classes/def/core/story';
import { IStoryBuyNavParams } from 'src/app/classes/def/nav-params/story';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { QRService } from 'src/app/services/general/apis/qr';
import { PlacesDataService } from 'src/app/services/data/places';
import { StoryDataService } from 'src/app/services/data/story';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { ParamHandler } from 'src/app/classes/general/params';
import { IItemAction } from 'src/app/classes/def/items/action';
import { EItemActions, IGameItemIAP, ILocationGameItem } from 'src/app/classes/def/items/game-item';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { ILocationContainer, IBackendLocation } from 'src/app/classes/def/places/backend-location';
import { IPlacesItemsNavParams } from 'src/app/classes/def/nav-params/place-popup';
import { PlacesItemsViewComponent } from '../places-items/places-items.component';
import { IEventStoryGroupLinkData } from 'src/app/classes/def/core/links';
import { IAPUtils } from 'src/app/services/apis/iap-utils';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { StoryUtils, ICheckStoryProviders } from 'src/app/classes/utils/story-utils';
import { IPopoverInputs, IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { PromiseUtils } from 'src/app/services/utils/promise-utils';
import { SleepUtils } from 'src/app/services/utils/sleep-utils';
import { AppConstants } from 'src/app/classes/app/constants';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { PaymentStripeDataService } from 'src/app/services/data/payment-stripe';
import { Util } from 'src/app/classes/general/util';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { PricingUtils } from 'src/app/classes/utils/pricing';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { ScrollUtils } from 'src/app/services/general/ui/scroll-utils';
import { EConnectLinks } from 'src/app/classes/app/api';
import { IAPCoreService } from 'src/app/services/apis/iap-rc';
import { IPurchaseInfo } from 'src/app/services/apis/iap-def';
import { ILatLng } from 'src/app/classes/def/map/coords';

@Component({
  selector: 'modal-story-buy-options',
  templateUrl: './story-buy-options.component.html',
  styleUrls: ['./story-buy-options.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StoryBuyOptionsViewComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('unlockEntry', { read: ElementRef, static: false }) unlockEntry: ElementRef<Element>;
  @ViewChild('unlockButtonTop', { read: ElementRef, static: false }) unlockButtonTop: ElementRef<Element>;
  @ViewChild('scrollContent', { read: ElementRef, static: false }) scrollContent: ElementRef<Element>;

  description: string = "";
  descriptionLines: string[];
  title: string = "Unlock Story";
  story: IStory;
  links: IEventStoryGroupLinkData;
  masterLockData: IMasterLockResponse;
  actionDescription: string = "";
  statusDescription: string = "";
  hasProviders: boolean = false;

  priceDisp: string = null;
  pricePromoDisp: string = null;
  priceDispStat: string = null;
  pricePromoDispStat: string = null;
  isIAP: boolean = false;

  storyProviders: IBackendLocation[] = [];
  unlockCodeInputLabel: string = "Enter unlock code";

  params: IStoryBuyNavParams = null;

  enable = {
    buy: false,
    unlock: false
  };

  disabled = {
    buy: false,
    unlock: false
  };

  vs: IViewSpecs = ViewSpecs.getDefault();

  np: INavParams = null;
  unlockInProgress: boolean = false;
  providersLoaded: boolean = false;
  appIconsStandard = EAppIconsStandard;
  appIcons = EAppIcons;

  coords: ILatLng;

  unlockCode: string = "";
  isUnlockCode: boolean = false;
  isUnlockCodeShort: boolean = true;
  unlockCodeShortLength: number = 5;
  proceedHighlight: boolean = false;

  descriptionFooter: string = "<scroll down to unlock>";
  viewMoreLabel: string = "<view more>";
  inputFocused: boolean = false;

  unlockCodeEnabled: boolean = false;
  unlockCodeRequired: boolean = false;
  unlockCodeRedeemOnly: boolean = false;
  unlockCodeRedeemOnlyEnabled: boolean = false;

  descriptionRedeem: string = "<use redeem code>";
  descriptionRedeemBooking: string = "<use booking reference>";
  storyTitle: string = "";

  externalCheckoutIndications: string = "<p>Please refer to the Connect page or the original listing to unlock this storyline</p>";

  useStripeCheckout: boolean = false;
  alternativePrice: string = "";
  alternativePricePromo: string = "";

  storyPhotos: IStoryPhoto[] = null;
  allowIAP: boolean = GeneralCache.isPublicDistribution;
  iapInProgress: boolean = false;

  timeout = {
    debounce: null
  };

  purchaseActionHeader: string = "In-App ";

  platform: IPlatformFlags = {} as IPlatformFlags;
  buttonActive: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService,
    public qrSecurity: QRService,
    public placesData: PlacesDataService,
    public dataProvider: StoryDataService,
    public analytics: AnalyticsService,
    public iapService: IAPCoreService,
    public locationManager: LocationManagerService,
    public settingsProvider: SettingsManagerService,
    public paymentStripe: PaymentStripeDataService,
  ) {

  }

  use() {

  }

  textAreaFocused(focused: boolean) {
    console.log("focus: ", focused);
    this.inputFocused = focused;
    if (focused) {
      ScrollUtils.scrollActiveElementIntoVIew(false, null, null, 30);
    }
  }

  /**
   * check buy options
   * only check for providers (scan qr)
   */
  buy(event: Event) {
    console.log("buy");
    if (event != null) {
      console.log("event prevent default");
      event.preventDefault();
    }
    this.buyExt(this.unlockCode, EStoryUnlockTokenType.leplace);
  }

  buyExt(token: string, tokenType: number) {
    let itemAction: IItemAction = {
      code: EItemActions.buy,
      data: {
        qrCode: token,
        amount: 1,
        type: tokenType
      }
    };

    console.log("buy story entry point");

    // check unlock code
    if (StoryUtils.checkQRUnlock(this.story)) {
      console.log("story unlock QR code required / redeem enabled");
      this.unlockStoryQR(itemAction);
    } else {
      // premium story than should be purchased via IAP only
      console.log("story unlock IAP required");
      this.buyCoreNoAction(itemAction);
    }
  }

  /**
   * unlock story entry point
   */
  unlockStory(event: Event, scan: boolean) {
    if (event != null) {
      console.log("event prevent default");
      event.preventDefault();
    }
    let itemAction: IItemAction = {
      code: EItemActions.buy,
      data: {
        qrCode: !scan ? this.unlockCode : null,
        amount: 1,
        type: EStoryUnlockTokenType.leplace
      }
    };

    console.log("unlock story entry point");

    // check unlock code
    if (StoryUtils.checkQRUnlock(this.story)) {
      // unlock story via QR code
      this.unlockStoryQR(itemAction);
    } else {
      // try unlock without params
      this.dataProvider.unlockStory(this.story.id, null, null, null, this.coords, false).then(() => {

      }).catch((err: Error) => {
        console.error(err);
        itemAction.code = EItemActions.cancel;
        this.errorHandler(err);
        this.dismiss(itemAction);
      });
    }
  }

  updateUnlockCode(event: any) {
    console.log("update unlock code: ", event);
    console.log("prop: ", this.unlockCode);
    // this.unlockCode = event;
    this.proceedHighlight = event != null;
    this.checkCode();
  }

  checkCode() {
    setTimeout(() => {
      if ((this.unlockCode != null) && (this.unlockCode.length > 0)) {
        this.priceDisp = this.unlockCodeRedeemOnlyEnabled ? "Redeem" : "Unlock";
        this.pricePromoDisp = null;
        this.isUnlockCode = true;
      } else {
        this.priceDisp = this.priceDispStat;
        this.pricePromoDisp = this.pricePromoDispStat;
        this.isUnlockCode = false;
      }
    }, 1);
  }

  /**
   * scan QR code and call buy core function
   * @param itemAction 
   */
  async unlockStoryQR(itemAction: IItemAction) {
    try {
      console.log("unlock story QR: ", itemAction);
      let code: string = "";
      if (StoryUtils.checkQRUnlockRequired(this.story) || this.unlockCodeRedeemOnlyEnabled) {
        if (!itemAction.data.qrCode) {
          code = await this.qrSecurity.scanQRResultString(false, "Unlock story");
        } else {
          code = itemAction.data.qrCode;
        }
      } else {
        code = itemAction.data.qrCode;
      }
      console.log("unlock story scan QR: " + code);
      itemAction.data.qrCode = code;
      await this.uiext.showLoadingV2Queue("Registering..");
      let status: boolean = await this.buyCore(itemAction);
      await this.uiext.dismissLoadingV2();
      await SleepUtils.sleep(500); // this is important (otherwise modal does not return)
      console.log("buy core status: ", status);
      if (status) {
        this.dismiss(itemAction);
      }
    } catch (err) {
      this.analytics.dispatchError(err, "story-unlock");
      await this.uiext.dismissLoadingV2();
      PromiseUtils.wrapNoAction(this.uiext.showRewardPopupQueue(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub), null, false, 3000), true);
    }
  }

  unlockStoryInputCode(itemAction: IItemAction) {
    let popoverInputs: IPopoverInputs = {
      code: {
        // the name is the actual tag here
        name: "code",
        value: "",
        placeholder: "unlock code",
        enabled: true
      }
    };
    let popoverActions: IPopoverActions = {
      cancel: {
        name: "Cancel",
        code: 0,
        enabled: true
      },
      ok: {
        name: "Ok",
        code: 1,
        enabled: true
      }
    };

    this.uiext.showCustomAlertInput("Unlock code", null, popoverInputs, popoverActions).then((res: any) => {
      let code = res.code;
      let data = res.data;
      switch (code) {
        case 0:
          break;
        case 1:
          console.log("unlock story scan QR: " + data.code);
          itemAction.data.qrCode = data.code;
          this.buyCoreNoAction(itemAction);
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * call buy core function
   * handle response and errors
   * @param itemAction 
   */
  private buyCoreNoAction(itemAction: IItemAction) {
    this.iapInProgress = true;
    this.timeout.debounce = ResourceManager.clearTimeout(this.timeout.debounce);
    this.timeout.debounce = setTimeout(() => {
      this.iapInProgress = false;
    }, 3000);
    this.buyCore(itemAction).then((status: boolean) => {
      this.timeout.debounce = ResourceManager.clearTimeout(this.timeout.debounce);
      this.iapInProgress = false;
      if (status) {
        this.dismiss(itemAction);
      }
    }).catch((err: Error) => {
      this.timeout.debounce = ResourceManager.clearTimeout(this.timeout.debounce);
      this.iapInProgress = false;
      itemAction.code = EItemActions.cancel;
      this.errorHandler(err);
      this.dismiss(itemAction);
    });
  }

  /**
   * handle error
   * @param err 
   */
  private errorHandler(err) {
    this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
    this.analytics.dispatchError(err, "story-unlock");
  }


  /**
   * buy with coins/cash
   * resolve buy status: true/false
   * @param itemAction  
   */
  buyCore(itemAction: IItemAction): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
      console.log("init buy sequence");
      let transactionData: IPurchaseInfo = null;
      try {
        // check qr code, level, etc (preconditions to proceed with unlock)
        let unlockStoryContext: IStoryUnlockContext = await this.dataProvider.checkUnlockStory(this.story.id, itemAction.data.qrCode, itemAction.data.type, this.coords);

        if (!unlockStoryContext.valid) {
          this.uiext.showAlertNoAction(Messages.msg.storyUnlockDenied.after.msg, Messages.msg.storyUnlockDenied.after.sub);
          resolve(false);
          return;
        }

        let requireIAP: boolean = !unlockStoryContext.resolveUnlocked && (this.story.itemIapCode != null) && (this.story.itemIap != null);

        if (this.useStripeIAPAlways()) {
          if (requireIAP) {
            // unlock with Connect
            console.log("init Stripe sequence");
            this.checkoutStripe();
            resolve(false);
            return;
          }
        } else {
          await this.uiext.showLoadingV2Queue("Waiting for store");
          // unlock with IAP
          if (requireIAP) {
            console.log("init IAP sequence");
            let productIAP: IGameItemIAP = this.story.itemIapPromo != null ? this.story.itemIapPromo : this.story.itemIap;
            let product: string = IAPUtils.getProductId(productIAP);
            if (GeneralCache.isPublicDistribution || (AppConstants.gameConfig.unlockIAPAlways === 1) || (this.story.partnerUnlock === -1)) {
              transactionData = await this.iapService.buyItemConsumeCore(product);
              if (!transactionData) {
                await this.uiext.dismissLoadingV2();
                this.uiext.showAlertNoAction(Messages.msg.orderCancelled.after.msg, Messages.msg.orderCancelled.after.sub);
                resolve(false);
                return;
              }
            } else {
              await this.uiext.dismissLoadingV2();
              this.uiext.showAlertNoAction(Messages.msg.itemUnlockDeniedTester.after.msg, Messages.msg.itemUnlockDeniedTester.after.sub);
              resolve(false);
              return;
            }
          }
        }
        // proceed to unlock story
        let unlocked: boolean = await this.dataProvider.unlockStory(this.story.id, itemAction.data.qrCode, itemAction.data.type, transactionData, this.coords, GeneralCache.isWeb);
        if (!unlocked) {
          await this.uiext.dismissLoadingV2();
          this.uiext.showAlertNoAction(Messages.msg.storyUnlockDenied.after.msg, Messages.msg.storyUnlockDenied.after.sub);
          resolve(false);
          return;
        }
        await this.uiext.dismissLoadingV2();
        if ((this.story.priceCoins != null) && (this.story.priceCoins > 0) && !unlockStoryContext.resolveUnlocked) {
          await this.uiext.showToast("-" + this.story.priceCoins * itemAction.data.amount + " LP", false);
        }
        resolve(true);
      } catch (err) {
        await this.uiext.dismissLoadingV2();
        reject(err);
      }
    });
    return promise;
  }

  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }


  checkPricingIAP() {
    this.iapService.syncPreloadedIAPData(this.story);
    if (this.story.itemIapCode) {
      if (GeneralCache.checkPlatformOS() === EOS.ios) {
        if (this.story.itemIapPromo != null) {
          this.priceDisp = this.story.itemIap.priceIos;
          this.pricePromoDisp = this.story.itemIapPromo.priceIos;
        } else {
          this.priceDisp = this.story.itemIap.priceIos;
        }
      } else {
        if (this.story.itemIapPromo != null) {
          this.priceDisp = this.story.itemIap.price;
          this.pricePromoDisp = this.story.itemIapPromo.price;
        } else {
          this.priceDisp = this.story.itemIap.price;
        }
      }
      this.isIAP = true;
    }
  }

  initFn() {
    console.log("init fn");
    if (this.checkData()) {
      let res: ICheckStoryProviders = StoryUtils.checkLoadProvidersWizard(this.story, () => { return this.loadProviders(); });
      this.hasProviders = res.hasProviders;
      this.storyProviders = res.providers;

      this.checkPricingIAP();
      this.priceDispStat = this.priceDisp;
      this.pricePromoDispStat = this.pricePromoDisp;

      this.unlockCodeEnabled = StoryUtils.checkQRUnlock(this.story);
      this.unlockCodeRequired = StoryUtils.checkQRUnlockRequired(this.story); // default input code showing always
      this.unlockCodeRedeemOnly = StoryUtils.checkQRUnlockRedeemOnly(this.story); // special case, show code input toggle
      this.setStatusDescription();
      this.checkStripePaymentOption();

      this.actionDescription = "<p>Available options: </p>";

      if (StoryUtils.checkQRUnlockRedeemOnly(this.story)) {
        this.unlockCodeInputLabel = "Enter unlock code (redeem)";
      }

      if (this.story.priceCoins || this.story.itemIapCode) {
        // story requires IAP / LP
        this.enable.buy = true;
        this.disabled.buy = false;

        let withCoins: boolean = false;
        let withIAP: boolean = false;
        if (this.story.priceCoins) {
          this.actionDescription += "<p>>Checkout with LP coins</p>";
          this.priceDisp = this.story.priceCoins + " LP";
          this.pricePromoDisp = null; // promo not supported
          withCoins = true;
        } else if (this.story.itemIapCode) {
          this.checkPricingIAP();
          let messageCheckout: string = "";
          if (this.useStripeCheckout) {
            if (this.platform.WEB) {
              messageCheckout += "<p>>Checkout with Stripe or LP Connect</p>";
            } else {
              messageCheckout += "<p>>Checkout with in-app purchase (unavailable in TestFlight)</p>";
              // messageCheckout += "<p>>Checkout with Stripe</p>";
            }
          } else {
            messageCheckout += "<p>>Checkout with in-app purchase</p>";
          }
          if (this.unlockCodeRedeemOnly) {
            messageCheckout += "<p>>Unlock with redeem code</p>";
          }
          this.actionDescription += messageCheckout;
          withIAP = true;
        }

        // may also require QR code
        if (StoryUtils.checkQRUnlockRequired(this.story) || this.unlockCodeRedeemOnlyEnabled) {
          this.actionDescription += "<p>>Scan QR / enter unlock code</p>";
          let priceDispOrig: string = this.priceDisp;
          this.priceDisp = "Scan QR";
          this.pricePromoDisp = null; // promo not supported
          if (withCoins) {
            this.priceDisp += " + " + priceDispOrig;
          } else if (withIAP) {
            this.priceDisp += " + " + priceDispOrig;
          }
        }
      } else {
        // story does not require IAP / LP
        this.enable.unlock = true;
        this.disabled.unlock = false;
        if (StoryUtils.checkQRUnlockRequired(this.story) || this.unlockCodeRedeemOnlyEnabled) {
          this.actionDescription += "<p>>Scan QR / enter unlock code</p>";
          this.priceDisp = "Scan QR";
          this.pricePromoDisp = null; // promo not supported
        } else {
          this.actionDescription += "<p>>Unlock</p>";
          this.priceDisp = "Unlock";
          this.pricePromoDisp = null; // promo not supported
        }
      }
      this.priceDispStat = this.priceDisp;
      this.pricePromoDispStat = this.pricePromoDisp;
    }
  }


  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    this.platform = SettingsManagerService.settings.platformFlags;

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      this.params = np.params;
      if (np.view) {
        this.vs = np.view;
      }

      this.unlockCodeShortLength = AppConstants.gameConfig.unlockCodeLength;

      console.log(np);
      this.story = this.params.storyExt;

      if (this.story.storyPhotos && this.story.storyPhotos.length > 0) {
        this.storyPhotos = this.story.storyPhotos;
      }
      this.masterLockData = this.params.masterLockData;
      this.description = this.story.description;
      this.storyTitle = this.story.name;
      this.links = this.params.links;
      if (this.params.prefillToken != null && this.params.prefillToken.length > 0) {
        this.unlockCode = this.params.prefillToken;
        if (this.unlockCode.length > this.unlockCodeShortLength) {
          this.isUnlockCodeShort = false;
        } else {
          this.proceedHighlight = true;
          this.checkCode();
        }
      }

      PromiseUtils.wrapResolve(this.locationManager.getCurrentLocationWrapper(true, true, true), true).then((coords: ILatLng) => {
        this.coords = coords;
      });
    }
    this.initFn();
    // this.toggleRedeemCodeInput(false);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onUserScoll(null);
    }, 100);
  }

  checkDisabledBuy() {
    if (this.unlockCodeRedeemOnlyEnabled) {
      return false;
    }
    return this.disabled.buy || this.iapInProgress || (!this.allowIAP && !this.platform.WEB);
  }

  toggleRedeemCodeInput(init: boolean) {
    console.log("toggle redeem code input.. ", this.unlockCodeRedeemOnly, init);
    if (this.unlockCodeRedeemOnly) {
      this.unlockCodeRedeemOnlyEnabled = !this.unlockCodeRedeemOnlyEnabled;
      if (init) {
        this.initFn();
      }
      if (this.unlockCodeRedeemOnlyEnabled) {
        this.priceDispStat = "Redeem";
        this.pricePromoDispStat = null;
        this.priceDisp = this.priceDispStat;
        this.pricePromoDisp = null;
        this.descriptionRedeem = "<use standard unlock>";
        if (init) {
          setTimeout(() => {
            this.inputFocused = true;
            // UIUtils.scrollActiveElementIntoVIew(false, unlockEntry, null, 30);
            ScrollUtils.scrollIntoView(null, this.unlockEntry.nativeElement, null, 30);
          }, 100);
        }
      } else {
        this.unlockCode = "";
        this.proceedHighlight = false;
        this.checkCode();
        this.descriptionRedeem = "<use redeem code>";
      }
    }
  }

  openBookingReferenceInput() {
    let searchName: string = "";
    let popoverInputs: IPopoverInputs = {
      token: {
        // the name is the actual tag here
        name: "token",
        value: searchName,
        placeholder: "booking reference number",
        enabled: true
      }
    };
    let popoverActions: IPopoverActions = {
      cancel: {
        name: "Cancel",
        code: 0,
        enabled: true
      },
      ok: {
        name: "Ok",
        code: 1,
        enabled: true
      }
    };

    let title: string = "Redeem your booking ticket";

    this.uiext.showCustomAlertInput(title, null, popoverInputs, popoverActions).then((res: any) => {
      let code = res.code;
      let data = res.data;
      // console.log(data);
      switch (code) {
        case 0:
          break;
        case 1:
          searchName = data.token;
          this.buyExt(searchName, EStoryUnlockTokenType.booking);
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  getPromoDisp() {
    if (this.pricePromoDisp != null) {
      return "Promo: " + this.priceDisp + " -> " + this.pricePromoDisp;
    }
  }

  setStatusDescription() {
    this.statusDescription = "";
    if (this.story.priceCoins || this.story.itemIapCode || this.hasProviders) {
      this.statusDescription += "<p>LOCKED</p>";

      if (this.story.priceCoins) {
        this.statusDescription += "<p>You need " + this.story.priceCoins + " LP to unlock this story</p>";
      }

      if (this.story.itemIapCode && this.story.itemIap) {
        if (this.story.itemIapCodePromo && this.story.itemIapPromo) {
          this.statusDescription += "<p>Discounted paid options available</p>";
        } else {
          this.statusDescription += "<p>Paid options available</p>";
        }
      }

      if (this.hasProviders && StoryUtils.checkQRUnlockRequired(this.story)) {
        this.statusDescription += "<p>You have to use the unlock code indicated by the provider to access this story</p>";
      }

      if (this.hasProviders && this.unlockCodeRedeemOnlyEnabled) {
        this.statusDescription += "<p>You may use the redeem code indicated by the provider to unlock this story</p>";
      }
    }
  }

  checkData() {
    return this.story != null;
  }

  loadProviders() {
    this.storyProviders = [];
    this.placesData.viewStoryProviders(this.story.id).then((places: ILocationContainer[]) => {
      if (places && places.length) {
        for (let i = 0; i < places.length; i++) {
          this.storyProviders.push(places[i].db);
        }
        this.hasProviders = true;
      }
    }).catch((err) => {
      console.error(err);
    });
  }


  viewPlaces() {
    if (this.checkData()) {
      this.placesData.viewStoryProviders(this.story.id).then((places: ILocationContainer[]) => {
        let locationItems: ILocationGameItem[] = [];
        for (let i = 0; i < places.length; i++) {
          locationItems.push({
            id: null,
            itemCode: null,
            description: null,
            locationId: places[i].merged.id,
            location: places[i]
          });
        }

        let params: IPlacesItemsNavParams = {
          locationItems: locationItems,
          title: "Providers"
        };

        this.uiext.showCustomModal(null, PlacesItemsViewComponent, {
          view: {
            fullScreen: false,
            transparent: false,
            large: true,
            addToStack: true,
            frame: false
          },
          params: params
        }).then((res: any) => {
          console.log(res);
        }).catch((err: Error) => {
          // console.log("returned from ar view");
          console.error(err);
          // console.log(this.map);
          // this.enableMap();
        });
      }).catch((err: Error) => {
        this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
      });

    }
  }

  getPlaceDetails(index) {
    console.log(index);
  }

  ngOnDestroy() {
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
    // this.clearTimer();
  }

  useStripeIAPAlways() {
    return this.useStripeCheckout && this.platform.WEB;
  }

  /**
   * enabled for testflight distribution (or override)
   * and default price is defined
   * and it's not partner unlocked (via unlock codes only) or alternativeCheckout is set (force alternative checkout)
   */
  checkStripePaymentOption() {
    if (
      (!GeneralCache.isPublicDistribution || SettingsManagerService.settings.app.settings.enableStripeCheckoutAlways.value) &&
      (this.story.defaultPrice != null) &&
      (this.story.partnerUnlock == null || this.story.partnerUnlock === 0 || this.story.alternativeCheckout) ||
      this.platform.WEB
    ) {
      // enable stripe payment option for web (unavailable) or testflight distribution
      if (this.story.defaultPrice != null && this.story.currency != null) {
        this.alternativePrice = PricingUtils.getStripeCheckoutPrice(this.story.defaultPrice, this.story.currency.name, true);
        this.alternativePricePromo = PricingUtils.getStripeCheckoutPrice(this.story.promoPrice, this.story.currency.name, true);
        this.useStripeCheckout = true;
      } else {
        if (this.story.itemIap != null) {
          this.alternativePrice = PricingUtils.getStripeCheckoutPrice(PricingUtils.getItemPricingValueFromIAP(this.story.itemIap), null, true);
          this.useStripeCheckout = true;
        }
        if (this.story.itemIapPromo != null) {
          this.alternativePricePromo = PricingUtils.getStripeCheckoutPrice(PricingUtils.getItemPricingValueFromIAP(this.story.itemIapPromo), null, true);
        }
      }
      if (this.useStripeIAPAlways()) {
        this.purchaseActionHeader = "Checkout ";
        if (this.alternativePrice != null) {
          this.priceDispStat = this.alternativePrice;
          this.priceDisp = this.priceDispStat;
        }
        if (this.alternativePricePromo != null) {
          this.pricePromoDispStat = this.alternativePricePromo;
          this.pricePromoDisp = this.pricePromoDispStat;
        }
      }
    } else {
      console.log("Stripe payment option not available");
    }
  }

  async checkoutStripe() {
    try {
      await this.uiext.showLoadingV2Queue("Redirecting to checkout..");
      let checkoutUrl: string = await this.paymentStripe.createPaymentCheckoutWizard(null, this.story.id, null, EConnectLinks.support);
      await this.uiext.dismissLoadingV2();
      Util.openURLExt(checkoutUrl);
      await SleepUtils.sleep(2000);
      let res: number = await this.uiext.showAlert(Messages.msg.stripeCheckoutUnlockStoryline.after.msg, Messages.msg.stripeCheckoutUnlockStoryline.after.sub, 2, ["dismiss", "refresh"], false);
      if (res === EAlertButtonCodes.ok) {
        let itemAction: IItemAction = {
          code: EItemActions.buy,
          data: {
            qrCode: null,
            amount: 1
          }
        };
        await this.uiext.showLoadingV2Queue("Waiting for refresh..");
        await SleepUtils.sleep(2500);
        await this.uiext.dismissLoadingV2();
        await SleepUtils.sleep(500);
        this.dismiss(itemAction);
      }
    } catch (err) {
      await this.uiext.dismissLoadingV2();
      this.analytics.dispatchError(err, "story-buy-options");
      this.uiext.showAlertNoAction(Messages.msg.preCheckoutError.after.msg, ErrorMessage.parse(err, Messages.msg.preCheckoutError.after.sub));
    }
  }

  async openConnectPagePotentialCheckout() {
    if (!this.story.connectLink) {
      console.warn("no connect link");
      return;
    }
    try {
      await this.uiext.showLoadingV2Queue("Opening Connect..");
      Util.openURLExt(this.story.connectLink);
      await SleepUtils.sleep(2000);
      let res: number = await this.uiext.showAlert(Messages.msg.stripeCheckoutUnlockStoryline.after.msg, Messages.msg.stripeCheckoutUnlockStoryline.after.sub, 2, ["dismiss", "refresh"], false);
      if (res === EAlertButtonCodes.ok) {
        let itemAction: IItemAction = {
          code: EItemActions.buy,
          data: {
            qrCode: null,
            amount: 1
          }
        };
        await this.uiext.showLoadingV2Queue("Waiting for refresh..");
        await SleepUtils.sleep(2500);
        await this.uiext.dismissLoadingV2();
        await SleepUtils.sleep(500);
        this.dismiss(itemAction);
      }
    } catch (err) {
      await this.uiext.dismissLoadingV2();
      this.analytics.dispatchError(err, "story-buy-options");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
    }
  }

  scrollToUnlock() {
    console.log("scroll to start");
    ScrollUtils.scrollIntoView(null, this.unlockButtonTop.nativeElement, null, 30);
  }

  onUserScoll(_ev) {
    if (ScrollUtils.checkScrollEnd(this.scrollContent)) {
      // console.log("scroll end");
      this.buttonActive = true;
    } else {
      // console.log("scroll");
      this.buttonActive = false;
    }
  }
}
