<ion-app [ngClass]="{'shift-view': global.shiftView}">
  <button id="speechButtonHidden" style="display:none"></button>
  <!-- <ion-split-pane [when]="splitPane.getSplitPane()" #menuContainer id="menuContainer"
    [ngClass]="!transparentBg ? 'ion-split-pane-bg' : ''"> -->
  <div [ngClass]="{'ion-split-pane-bg': !transparentBg}" class="height-100" #menuContainer id="menuContainer">
    <ion-menu (ionDidOpen)="menuOpened()" (ionDidClose)="menuClosed()" class="main-menu" #menuContent id="menuContent"
      contentId="my-content">

      <ion-header class="menu-header menu-title-ios-adapter">
        <ion-toolbar class="menu-toolbar" [ngClass]="global.shiftView ? 'shift-toolbar' : ''">
          <ion-title class="title-m">Menu</ion-title>
        </ion-toolbar>
      </ion-header>

      <ion-content class="main-menu-bg menu-content">
        <ion-list *ngFor="let menuGroup of appMenu; let i = index" class="transparent-bg menu-scroll">
          <ion-list-header *ngIf="menuGroup.name">
            {{menuGroup.name}}
          </ion-list-header>
          <div *ngFor="let p of menuGroup.pages">
            <ion-menu-toggle menu="first" autoHide="false" class="transparent-bg"
              [ngClass]="isActive(p) ? 'icon-color-alternate':'icon-color-primary-low'"
              *ngIf="(p.enabled===undefined || p.enabled.value) && ((p.loginRequired && authenticated) || (!p.loginRequired && !authenticated) || (p.loginRequired === undefined))">
              <!-- <ion-item tappable (click)="openPage(p)" class="transparent-bg"> -->
              <!-- [routerDirection]="'root'" [routerLink]="p.url" (click)="openView(p)" -->
              <ion-item tappable class="transparent-bg padding-left-s" (click)="openPage(p)">
                <icon-wrapper class="slot-icon-padding" customClass="normal" slot="start" [icon]="p.icon"
                  [custom]="p.customIcon"></icon-wrapper>
                <ion-label>
                  <span class="text-color-primary button-font-size-m">{{p.title}}</span>
                </ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
        </ion-list>
      </ion-content>

    </ion-menu>

    <ion-router-outlet id="my-content" [ngClass]="{'web-content': isWeb && webFrameEnabled}"></ion-router-outlet>

    <!-- </ion-split-pane> -->
  </div>
</ion-app>