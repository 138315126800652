
import { Injectable } from '@angular/core';
import { GenericDataService } from '../general/data/generic';
import { UiExtensionService } from '../general/ui/ui-extension';
import { ISaveTourContent, ILoadTourContent } from '../../classes/def/tours/tours';


@Injectable({
    providedIn: 'root'
})
export class TourContentDataService {
    constructor(
        public generic: GenericDataService,
        public uiext: UiExtensionService
    ) {
        console.log("tour content data service created");
    }

    init() {
        // console.log("tour content provider init");
    }

    saveTour(tourData: ISaveTourContent) {
        return this.generic.genericPostStandard("/tours/save-tour", {
            tourData: tourData
        });
    }

    loadTour(tourId: number): Promise<ILoadTourContent> {
        return this.generic.genericPostStandardWData("/tours/load-tour", {
            tourId: tourId
        });
    }

    listUserTours(): Promise<ILoadTourContent[]> {
        return this.generic.genericPostStandardWData("/tours/list-user-tours", {
            
        });
    }
}
