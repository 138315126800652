<div *ngIf="data">
  <ion-grid class="full-w">
    <ion-row>
      <ion-col size="2">
        <img *ngIf="data.photoUrl" [src]="data.photoUrl" alt="icon" class="img-icon">
        <icon-wrapper *ngIf="data.icon" [icon]="data.icon" [custom]="data.customIcon"></icon-wrapper>
      </ion-col>
      <ion-col size="10">
        <span class="info-text-lg bold" [ngClass]="locked ? 'text-color-primary-low' : 'text-color-primary'">{{name}}</span>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>