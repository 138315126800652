import { ElementRef, Input, OnInit, OnDestroy, Directive, Renderer2 } from '@angular/core';
import { timer } from 'rxjs';
import { ResourceManager } from 'src/app/classes/general/resource-manager';


export interface IBlinkOptions {
    enable: boolean;
    timeout: number;
    class: string;
    hclass: string;
}

@Directive({
    selector: '[blink]'
})
export class BlinkDirective implements OnInit, OnDestroy {
    @Input('blink') options: IBlinkOptions;

    initClass: string = "";
    timeout;
    timerSub;
    state: boolean = false;

    constructor(private el: ElementRef, private _renderer: Renderer2) { }

    ngOnInit() {
        this.initClass = this.el.nativeElement.className;
        console.log(this.initClass);
        console.log(this.options);

        // this._renderer.setElementAttribute(this.el.nativeElement, 'col-' + this.value, '');
        // this._renderer.setElementClass(this.el.nativeElement, this.initClass, true);

        if (!this.options) {
            return;
        }
        if (this.options.enable) {
            this.enableBlink(this.options.timeout);
        }
        this.setClass();
    }

    ngOnDestroy() {
        this.disableBlink();
    }


    enableBlink(count: number) {
        if (!this.timerSub) {
            let timer1 = timer(0, 500);
            let flagCount: boolean = ((count != null) && (count > 0));
            let countk: number = count * 2;
            this.timerSub = timer1.subscribe(() => {
                this.state = !this.state;
                this.setClass();
                if (flagCount) {
                    countk -= 1;
                    if (countk <= 0) {
                        this.disableBlink();
                    }
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    disableBlink() {
        this.timerSub = ResourceManager.clearSub(this.timerSub);
        this.state = false;
        this.setClass();
    }

    setClass() {
        if (this.state) {
            this._renderer.removeClass(this.el.nativeElement, this.options.class);
            this._renderer.addClass(this.el.nativeElement, this.options.hclass);
        } else {
            this._renderer.removeClass(this.el.nativeElement, this.options.hclass);
            this._renderer.addClass(this.el.nativeElement, this.options.class);            
        }
    }
}
