import { ITreasureSpecInput } from "src/app/modals/app/modals/treasure-spec-selector/treasure-spec-selector.component";
import { IExploreCoinGen } from "../activity/explore";
import { IActivityStatsContainer } from "../core/activity";
import { ILeplaceObjectContainer } from "../core/objects";
import { IAppLocation } from "../places/app-location";
import { ILeplaceTreasure } from "../places/leplace";
import { EAppIconsStandard } from "../app/icons";

export interface IGmapButtonsFlag {
    group: IGmapButtonFlags;

    operatorChat: IGmapButtonFlags;

    places: IGmapButtonFlags;
    skip: IGmapButtonFlags;
    start: IGmapButtonFlags;
    startLoc: IGmapButtonFlags;
    resumeLoc: IGmapButtonFlags;
    startChallenge: IGmapButtonFlags;
    pause: IGmapButtonFlags;
    exit: IGmapButtonFlags;
    exitFab: IGmapButtonFlags;
    completeActivity: IGmapButtonFlags;
    ar: IGmapButtonFlags;
    drone: IGmapButtonFlags;
    inventory: IGmapButtonFlags;
    inventoryFlag: IGmapButtonFlags;

    scan: IGmapButtonFlags;
    magnet: IGmapButtonFlags;
    zoom: IGmapButtonFlags;
    compass: IGmapButtonFlags;
    playVideo: IGmapButtonFlags;
    recalculateDirections: IGmapButtonFlags;
    toggleSpeaker: IGmapButtonFlags;

    location: IGmapButtonFlags;
    menu: IGmapButtonFlags;

    addPlace: IGmapButtonFlags;
    quickAddPlace: IGmapButtonFlags;
    editPlace: IGmapButtonFlags;
    undoEditPlace: IGmapButtonFlags;

    progress: IGmapButtonFlags;
    progressLoading: IGmapButtonFlags;
}

export interface IGmapButtonFlags {
    enabled: boolean;
    disabled: boolean;
    blink: boolean;
    blinkOnChange: boolean;
    icon?: string;
    customIcon?: boolean;
    /**
   * query function, add complex logic functions here
   */
    isEnabled: () => any;
    /**
     * query function, add complex logic functions here
     */
    isDisabled: () => any;
    // /**
    //  * query function, add complex logic functions here
    //  */
    // isEnabled: () => {

    // },
    // /**
    //  * query function, add complex logic functions here
    //  */
    // isDisabled: () => {

    // }
}

export class GmapUtils {

    static flags(value: boolean) {
        let flags: IGmapButtonFlags = {
            enabled: value,
            disabled: false,
            blink: false,
            blinkOnChange: false,
            isEnabled: () => {
                return value;
            },
            isDisabled: () => {
                return false;
            }
        };
        return flags;
    }

    static getButtonsDefault(value: boolean) {
        let btn: IGmapButtonsFlag = {
            menu: GmapUtils.flags(value),
            group: GmapUtils.flags(value),
            operatorChat: GmapUtils.flags(value),
            places: GmapUtils.flags(value),
            skip: GmapUtils.flags(value),
            start: GmapUtils.flags(value),           
            startLoc: GmapUtils.flags(value),
            resumeLoc: GmapUtils.flags(value),
            startChallenge: GmapUtils.flags(value),
            pause: GmapUtils.flags(value),
            exit: GmapUtils.flags(value),
            exitFab: GmapUtils.flags(value),
            completeActivity: GmapUtils.flags(value),
            ar: GmapUtils.flags(value),
            drone: GmapUtils.flags(value),
            scan: GmapUtils.flags(value),
            compass: GmapUtils.flags(value),
            magnet: GmapUtils.flags(value),
            zoom: GmapUtils.flags(value),
            inventory: GmapUtils.flags(value),
            inventoryFlag: GmapUtils.flags(value),
            addPlace: GmapUtils.flags(value),
            quickAddPlace: GmapUtils.flags(value),
            editPlace: GmapUtils.flags(value),
            undoEditPlace: GmapUtils.flags(value),
            progress: GmapUtils.flags(value),
            progressLoading: GmapUtils.flags(value),
            location: GmapUtils.flags(value),
            playVideo: GmapUtils.flags(value),
            recalculateDirections: GmapUtils.flags(value),
            toggleSpeaker: GmapUtils.flags(value)
        };
        btn.magnet.icon = EAppIconsStandard.magnet;
        btn.magnet.customIcon = false;
        return btn;
    }
}

export enum EGmapShowOptions {
    exitMap = -1,
    tutorial = 1,
    mapFilter = 2,
    compassMode = 3,
    directions = 4,
    toggleHud = 5,
    arview = 6,
    placeTreasure = 7,
    placeScan = 8,
    teleport = 9,
    placeUser = 10,
    locationFab = 11,
    expire = 12,
    expireLater = 13,
    treasureScan = 14,
    treasureScanCore = 15,
    testScan = 16,
    lockTreasures = 17,
    unlockTreasures = 18,
    toggleRecording = 19,
    clearMap = 20,
    loadTour = 21,
    showLayers = 22,
    hideLayers = 23,
    refreshStoryLocationMarkers = 24,
    hideStoryLocationMarkers = 25,
    reloadStoryLocations = 26,
    switchDroneMode = 27,
    shareOptions = 28,
    preloadStory = 29,
    preloadStoryFull = 30,
    placeSearch = 31,
    jumpToMeetingPlace = 32,
    setAppendTreasuresMode = 33,
    showCompassAdjustControl = 34,
    showCompassAdjustInput = 35,
    toggleScannerEnabled = 36,
    debugMode = 37,
    demoModeAR = 38,
    webkitCompassAR = 39,
    simulateNoCompass = 40,
    reloadLastStoryMarker = 41,
    skipToLocationIndex = 42,
    locationFailTest = 43,
    refreshLayout = 44,
    decreaseTimer = 45
};


export interface IGmapCountdown {
    value: number;
}

export interface IGapModeSelector {
    blank: boolean;
    worldMap: boolean;
    storyline: boolean;
    editor: boolean;
    eventChallenge: boolean;
    // event with private world map
    eventWm: boolean;
}

export interface IFindActivityResult {
    status: number,
    appLocation: IAppLocation,
    activityStats: IActivityStatsContainer;
}

export enum ENearbyContentType {
    treasure = 1,
    challenge = 2,
    coin = 3
}

export interface INearbyContentMagnetElem {
    elem: ILeplaceTreasure | ILeplaceObjectContainer | IExploreCoinGen,
    type: number
}

export interface INearbyContentMagnet {
    objectsNearby: boolean,
    selectedSpec: ITreasureSpecInput,
    list: INearbyContentMagnetElem[]
}

export interface INearbyContentMagnetContext {
    treasures: INearbyContentMagnet,
    challenges: INearbyContentMagnet,
    coins: INearbyContentMagnet
}
