import { Component, ViewChild, ElementRef, HostListener, NgZone, OnInit, OnDestroy, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Messages } from 'src/app/classes/def/app/messages';
import { IHudConfig, IHudElement, HudUtils, EHudContext, EMapHudCodes, EARHudCodes } from 'src/app/classes/def/app/hud';
import { EClass, EAlertButtonCodes, EHeaderClass, EFooterClass } from 'src/app/classes/def/app/ui';
import { EPhotos, EAppIconsStandard, EAppIcons } from 'src/app/classes/def/app/icons';
import { IPopoverActions, ICheckboxFrameStatus } from 'src/app/classes/def/app/modal-interaction';
import {
  IARStreamingData, IARUpdatePOV, EHeadingCalibrationSources, IARSpecialActivity, EViewLinkCodes,
  EARSpecialActivity, IAREnableParams, IARHeadingData, IARMotionData, EARGenModes, IGyroAlign
} from 'src/app/classes/def/ar/core';

import {
  ILeplaceObject, IObjectStoreElement, IActionLayers, ILeplaceObjectContainer, ILeplaceObjectGenerator,
  ECRUD, IARObject, IARObjectRemoveSpecs, IARObjectCreateRelativeSpecs, IARObjectCreateAbsoluteSpecs
} from 'src/app/classes/def/core/objects';
import { IMinimapOptions } from 'src/app/classes/def/ar/minimap';
import { IActivity } from 'src/app/classes/def/core/activity';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { IARViewNavParams } from 'src/app/classes/def/nav-params/ar-view';
import { Platform, ModalController } from '@ionic/angular';
import { ItemScannerUtils } from 'src/app/services/app/modules/item-scanner-utils';
import { ETreasureType } from 'src/app/classes/def/items/treasures';
import { timer } from 'rxjs';
import { INavParams, IViewSpecs } from 'src/app/classes/def/nav-params/general';
import { ICustomParamForActivity, ECustomParamScope } from 'src/app/classes/def/core/custom-param';
import { IActivityParamsView, EActivityParamsViewModes } from 'src/app/classes/def/nav-params/activity-details';
import { ActivityParamsViewComponent } from 'src/app/modals/app/modals/activity-params-vc/activity-params-vc.component';
import { IMessageQueueEvent, IQueueMessage, EQueueMessageCode } from 'src/app/classes/utils/queue';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { AppSettings } from 'src/app/services/utils/app-settings';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { ETreasureLockedMode } from 'src/app/classes/def/places/leplace';
import { IGeolocationResult } from 'src/app/classes/def/map/map-data';
import { GeometryUtils } from 'src/app/services/utils/geometry-utils';
import { MathUtils } from 'src/app/classes/general/math';
import { IAppSettingsContent } from 'src/app/classes/def/app/settings';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS, EQueues } from 'src/app/classes/def/app/app';
import { EGeoObjectsProviderCode } from 'src/app/classes/def/places/geo-objects';
import { IProgressDisplay, IProgressNavParams, ProgressViewComponent } from 'src/app/modals/app/modals/progress-view/progress-view.component';
import { EMessageTimelineCodes, IMessageTimelineEntry } from 'src/app/classes/def/newsfeed/message-timeline';
import { ICheckCollectItem } from 'src/app/services/app/modules/item-collector-utils';
import { IPhotoFrameNavParams } from 'src/app/modals/generic/modals/photo-frame/photo-frame.component';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { GameUtils } from 'src/app/classes/utils/game-utils';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { GeoObjectsService } from 'src/app/services/app/modules/geo-objects';
import { ScriptLoaderService } from 'src/app/services/general/script-loader';
import { LocationMonitorService } from 'src/app/services/map/location-monitor';
import { MinimapService } from 'src/app/services/app/utils/minimap';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { MessageQueueHandlerService } from 'src/app/services/general/message-queue-handler';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { ItemScannerService } from 'src/app/services/app/modules/item-scanner';
import { ExploreActivityService } from 'src/app/services/app/modules/activities/explore';
import { GenericQueueService } from 'src/app/services/general/generic-queue';
import { PhotoViewService } from 'src/app/services/general/apis/photo-view';
import { PhotosService } from 'src/app/services/media/photos';
import { ARExploreActivityService } from 'src/app/services/app/modules/activities/arexplore';
import { ActivityService } from 'src/app/services/app/modules/activity';
import { FindActivityService } from 'src/app/services/app/modules/activities/find';
import { BenchmarkDataService } from 'src/app/services/data/benchmark';
import { MediaUtilsService } from 'src/app/services/media/media-utils';
import { ScreenshotService } from 'src/app/services/media/screenshot';
import { ItemCollectorService } from 'src/app/services/app/modules/item-collector';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { ERouteDef } from 'src/app/app-utils';
import { INavParamsInfo, NavParamsService } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { SleepUtils } from 'src/app/services/utils/sleep-utils';
import { VirtualPositionService } from 'src/app/services/app/modules/virtual-position';
import { MoveActivityService } from 'src/app/services/app/modules/activities/move';
import { TimeoutService } from 'src/app/services/app/modules/timeout';
import { NavGaugeService } from 'src/app/services/app/utils/nav-gauge';
import { INgxChartOptions } from 'src/app/classes/def/app/charts';
import { NgxChartParams } from 'src/app/classes/general/params';
import { WalkthroughService } from 'src/app/services/app/modules/minor/walkthrough';
import { StorageFlagsService } from 'src/app/services/general/data/storage-flags';
import { ELocalAppDataKeys, ILocalShowFlags } from 'src/app/classes/def/app/storage-flags';
import { AnalyticsExtrasService } from 'src/app/services/general/apis/analytics-extras';
import { ETrackedEvents } from 'src/app/classes/app/analytics';
import { BackgroundModeWatchService } from 'src/app/services/general/apis/background-mode-watch';
import { Router } from '@angular/router';
import { PermissionsService } from 'src/app/services/general/permissions/permissions';
import { HeadingService, IHeadingStatus } from 'src/app/services/map/heading';
import { Chart } from 'chart.js';
import { IGenericSlideData } from 'src/app/classes/def/views/slides';
import { ESliderTutorialMode, ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { ModularViewsService } from 'src/app/services/utils/modular-views';
import { EARShowOptions } from 'src/app/classes/def/ar/utils';
import { PromiseUtils } from 'src/app/services/utils/promise-utils';
import { StringUtils } from 'src/app/services/app/utils/string-utils';
import { ILatLng } from 'src/app/classes/def/map/coords';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

interface IARTxQueueElem {
  name: string;
  data: any;
}

@Component({
  selector: 'app-ar-view-entry',
  templateUrl: './ar-view-entry.page.html',
  styleUrls: ['./ar-view-entry.page.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0
      })),
      state('active', style({
        // transform: 'translateY(100%)',
        opacity: 1
      })),
      transition('inactive => active', [animate("0.7s ease-in")]),
      transition('active => inactive', [animate("0.7s ease-out")]),
      // transition('void => *', [
      //   style({ transform: 'translateY(-100%)' }),
      //   animate('500ms ease-out')
      // ]),
      // transition('* => void', [
      //   animate('500ms ease-in', style({ transform: 'translateY(100%)' }))
      // ])
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class ARViewEntryPage implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('myChart', { read: ElementRef, static: false }) myChart: ElementRef;

  appIconsStandard = EAppIconsStandard;
  appIcons = EAppIcons;

  debugLog: boolean = false;
  testRetryQueue: number = 0;
  txQueue: IARTxQueueElem[] = [];
  debugMode: boolean = false;

  enableHud = {
    debug: false,
    main: false,
    xp: true
  };

  contextHud: boolean = false;
  showHud: boolean = false;
  navGaugeReady: boolean = false;

  footerClass: string = EFooterClass.FOOTER_SMALL;
  headerClass: string = EHeaderClass.HEADER_SMALL;
  footerContentClass: string = EFooterClass.FOOTER_CONTENT;

  hudConfig: IHudConfig = {
    outerClass: EClass.HUD_BAN_NORMAL,
    tintClass: EClass.HUD_INFO,
    innerClass: EClass.HUD_INNER_INFO
  };

  hudMsgXP: string = "";
  hudShow: boolean = false;
  loading: boolean = true;
  show: boolean = false;
  plateSub: string = "Loading";
  logoSrc: string = EPhotos.logo;
  showState: string = "active";
  ARInitComplete: boolean = false;
  ARInitSignalReceived: boolean = false;

  iframe: HTMLIFrameElement = null;

  initCheck = {
    engineLoaded: false,
    headingCalibrated: false,
    sceneLoaded: false
  };

  layers: IPopoverActions = {};
  infoText: string = "ARMAX Engine";

  blink = {
    camera: false
  };

  streamingData: IARStreamingData = null;

  currentPosition: IARUpdatePOV = null;

  filtered = {
    alpha: 0,
    beta: 0,
    gamma: 0
  };

  hudMsgMap: IHudElement[] = [];
  screenshotEnabled: boolean = true;

  /**
   * update gps coords using keys, from AR view to GPS coords
   * used for debug
   */
  // testUpdateLocationFromARPov: boolean = false;

  hudMsgAR: IHudElement[] = HudUtils.getARHudInitValues();

  /**
   * stop loading process (stray messages from the iframe) if the view has been disposed
   */
  exitFlag: boolean = false;

  private hasParams: boolean = false;
  private initialLocation: ILatLng;
  private currentLocation: ILatLng;
  currentTrueHeading: number = 0;
  private currentHeadingCompassDegrees: number = 0;
  // private currentHeadingCompassDegreesFilteredAux: number = 0;

  objectId: number = 0;

  nextObjectSpecs: ILeplaceObject = null;
  addedTestObjects: ILeplaceObject[] = [];
  objectSpecsList: ILeplaceObject[] = [];
  objectStore: IObjectStoreElement[] = [];

  private compassAlign = {
    initialDelta: 0, // initial delta (systematic error, experimental)
    delta: 0,
    deltaAux: 0,
    calibrated: true,
    gyroInit: false,
    filterArray: [],
    nfilter: 3,
    counter: 0,
    counterMax: 10, // max number of measurements that are above the deviation threshold
    ki: 0.5,
    gpsHeadingAdjust: 0,
    maxThreshold: 360
    // maxThreshold: 60 // the maximum deviation of the compass from the real direction of movement that will be integrated
  };

  private currentHeadingGPSDegrees: number = 0;
  private currentHeadingTest: number = 0;

  currentSpeedGPS: number = 0;
  minimapChart: Chart = null;

  // replaced via app settings
  far: number = 50; // meters, AR far setting

  minimapOptions: IMinimapOptions = {
    // replaced via app settings
    far: 75,
    tick: 25,
    pointRadius: 4,
    animation: 0 // general animation time, no animation for performance
  };

  title: string = "AR View";

  private observables = {
    deviceOrientationData: null,
    objectGenerator: null,
    ARInitializationComplete: null,
    downloadProgress: null
  };

  private subscription = {
    compassHeading: null,
    deviceOrientationData: null,
    location: null,
    objectGenerator: null,
    objectCollector: null,
    messageQueue: null,
    ARInitializationComplete: null,
    linkAR: null,
    itemScanEventWatch: null,
    internalTimer1: null
  };

  private timeouts = {
    generateObject: null,
    loadAR: null,
    compassAlignRequired: null,
    showWarning: null,
    hidePlate: null,
    retryTx: null,
    messageTx: null,
    addObjects: null,
    watchObjectsInit: null,
    placeMultipleObjects: null,
    showWalkthrough: null
  };

  activity: IActivity = null;

  private platform: IPlatformFlags;
  private largeChart: boolean = false;

  /**
   * TODO: update
   */
  public flags = {
    alignGyro: true,
    calibrationSpeedGPS: 0.85,
    showCalibratePopup: true,
    calibratePopupIsActive: false,
    gpsSignalReceived: false,
    useCompassHeading: false,
    headingCalibrationSource: EHeadingCalibrationSources.compass,
    objectsNearby: false,
    compassNeedsCalibrationShown: false
  };

  objectsNearbyList: ILeplaceObject[] = [];
  // scale: number = 0.5;
  scale: number = 1;
  locationWatchAlreadyStarted: boolean = false;

  private isModal: boolean = true;

  params: IARViewNavParams;


  enableSpecialActivity: boolean = false;
  specialActivityParams: IARSpecialActivity;
  selectedSpecialActivityCode: number = 0;
  arexploreParams = {
    viewAngle: 60
  };
  enable = {
    arexplore: true
  };

  benchmarkEnabled: boolean = false;

  scaleFactor = {
    options: [1, 1.2, 1.4, 2],
    index: 0
  };

  notifyRecordComplete: boolean = false;
  showLoadingView: boolean = false;
  showProgressView: boolean = false;

  np: INavParams = null;
  vs: IViewSpecs;

  vgOptions: INgxChartOptions = NgxChartParams.getVGaugeOptions();

  useARjs: boolean = true;
  // useARjs: boolean = false;
  // useNativeDeviceOrientation: boolean = true;
  useNativeDeviceOrientation: boolean = false;
  isGimbalLockZone: boolean = false;

  showStatButtons: boolean = false
  useQueueExt: boolean = false;
  showARStats: boolean = false;
  demoMode: boolean = false;
  useWebkitCompass: boolean = true;

  onChartClick(event) {
    console.log(event);
  }

  @HostListener('window: outputAR', ['$event'])
  onAROutput(e) {
    // console.log("PARENT>output received: ", e.detail);
    let data = e.detail;
    if (data) {
      if (this) {
        this.ngZone.run(() => {
          this.leplaceMessageRx(data.name, data.data);
        });
      }
    }
  }

  @HostListener('window: compassneedscalibration', [])
  oncompassneedscalibration() {
    if (!this.flags.compassNeedsCalibrationShown) {
      this.flags.compassNeedsCalibrationShown = true;
      this.uiext.showAlertNoAction(Messages.msg.compassNeedsCalibration.after.msg, Messages.msg.compassNeedsCalibration.after.sub);
    }
  }


  constructor(
    public router: Router,
    public permissionsService: PermissionsService,
    public settingsProvider: SettingsManagerService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public geoObjects: GeoObjectsService,
    public jsLoader: ScriptLoaderService,
    public ngZone: NgZone,
    public locationManager: LocationManagerService,
    public locationMonitor: LocationMonitorService,
    public minimapProvider: MinimapService,
    public resourcesProvider: ResourcesCoreDataService,
    public messageQueueHandler: MessageQueueHandlerService,
    public analytics: AnalyticsService,
    public analyticsExtras: AnalyticsExtrasService,
    public backButton: BackButtonService,
    public itemScanner: ItemScannerService,
    public exploreProvider: ExploreActivityService,
    public q: GenericQueueService,
    public photoViewer: PhotoViewService,
    public photos: PhotosService,
    public arexploreProvider: ARExploreActivityService,
    public findActivityProvider: FindActivityService,
    public bench: BenchmarkDataService,
    public activityProvider: ActivityService,
    public mediaUtils: MediaUtilsService,
    public screenshot: ScreenshotService,
    public itemCollector: ItemCollectorService,
    public tutorials: TutorialsService,
    public modalCtrl: ModalController,
    public virtualPositionService: VirtualPositionService,
    public nps: NavParamsService,
    public moveActivityProvider: MoveActivityService,
    public timeoutMonitor: TimeoutService,
    public navGauge: NavGaugeService,
    public walkthrough: WalkthroughService,
    public storageFlags: StorageFlagsService,
    public bgmWatch: BackgroundModeWatchService,
    public headingService: HeadingService,
    public modularViews: ModularViewsService
  ) {
    this.observables = ResourceManager.initBsubObj(this.observables);
  }

  swipeEvent(e) {
    this.backButton.handleSwipeEvent(e);
  }

  /**
   * prevent AR running in bg mode (slows down phone/unresponsive)
   */
  watchExitOnBgModeActivated() {
    this.bgmWatch.waitDefaultBgmWatch(true).then(() => {
      console.log("exit AR on bg mode activated");
      this.returnToMap();
    });
  }

  /**
   * handle AR based activities 
   */
  handleSpecialActivity(code: number, customParams: ICustomParamForActivity[]) {
    switch (code) {
      case EARSpecialActivity.screenshotAR:
        this.watchObjectsInView();
        this.blink.camera = true;
        this.enable.arexplore = true;
        console.log(customParams);
        this.enableSpecialActivity = true;
        this.arexploreProvider.setActivityParams(this.specialActivityParams);
        this.viewSpecialActivityTutorial(code, customParams);
        break;
      default:
        break;
    }
  }

  viewSpecialActivityTutorial(code: number, customParams: ICustomParamForActivity[]) {
    switch (code) {
      case EARSpecialActivity.screenshotAR:
        let params: IActivityParamsView = {
          customParams: customParams,
          customParamsNav: [],
          mode: EActivityParamsViewModes.before,
          description: this.activity.description,
          questData: null,
          scope: ECustomParamScope.challenge,
          videoGuideSpecs: null
        };

        this.uiext.showCustomModal(null, ActivityParamsViewComponent, {
          view: {
            fullScreen: false,
            transparent: false,
            large: true,
            addToStack: true,
            frame: false
          },
          params: params
        }).then(() => {

        }).catch((err: Error) => {
          console.error(err);
        });
        break;
      default:
        break;
    }
  }

  /**
   * check if the required objects are visible
   * flash the camera button if true
   */
  watchObjectsInView() {
    if (!this.subscription.internalTimer1) {
      let timer1 = timer(0, 500);
      this.subscription.internalTimer1 = timer1.subscribe(() => {
        this.blink.camera = this.arexploreProvider.checkRequiredObjects(this.minimapProvider.getCurrentView(), false);
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  returnToMap() {
    this.exit(null);
  }

  /**
  * exit AR view
  * @param data 
  */
  exit(data: any) {
    console.log("exit AR view: ", data);
    this.show = false;

    let promise = null;
    if (!this.platform.WEB) {
      // if (data) {
      //     promise = Promise.resolve(EAlertButtonCodes.ok);
      // } else {
      //     promise = this.uiext.showAlert(Messages.msg.exitAR.before.msg, Messages.msg.exitAR.before.sub, 2, null, false);
      // }
      promise = Promise.resolve(EAlertButtonCodes.ok);
    } else {
      promise = Promise.resolve(EAlertButtonCodes.ok);
    }

    this.bgmWatch.clearDefaultBgmWatch();

    promise.then(async (value: number) => {
      if (value === EAlertButtonCodes.ok) {
        this.exitFlag = true;
        if (this.isModal) {
          console.log("dismiss AR modal");
          await SleepUtils.sleep(100);
          console.log("dismiss AR modal (2)");
          this.modalCtrl.dismiss(data).then(() => {
            console.log("dismiss AR modal complete");
          }).catch((err: Error) => {
            console.error(err);
          });
        } else {
          this.router.navigate([ERouteDef.settings], { replaceUrl: true }).then(() => {

          }).catch((err: Error) => {
            console.error(err);
          });
        }
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngOnInit() {
    this.uiext.disableSidemenu();
    this.minimapProvider.clearMinimap();
    this.nextObjectSpecs = ItemScannerUtils.getLocalObjectSpecs(ETreasureType.treasure);
    this.analytics.trackView("ar-view-entry");
    this.analyticsExtras.sendTrackOneTimeEvent(ETrackedEvents.firstAROpen, "open", "open", 1, false);
    this.geoObjects.getShowLayersResolve(false).then((layers: IActionLayers) => {
      this.layers = layers;
    }).catch((err: Error) => {
      console.error(err);
    });
    this.watchExitOnBgModeActivated();
    HudUtils.setHudHighlightAll(this.hudMsgAR, HudUtils.getHudDisplayModes().default);
  }

  loadSettings() {
    let set: IAppSettingsContent = SettingsManagerService.settings.app.settings;
    this.far = set.ARFieldOfView.value;
    this.minimapOptions.far = 1.5 * this.far;
    if (set.AREngine.value === 1) {
      // awe.js engine
      this.useARjs = false;
    } else {
      // ar.js engine
      this.useARjs = true;
    }
  }

  toggleHudMode(debug: boolean) {
    this.debugMode = debug;
    let hudMode: number = SettingsManagerService.settings.app.settings.hudMode.value;
    if (this.debugMode) {
      this.enableHud.debug = true;
      this.enableHud.main = false;
      this.showHud = true;
    } else {
      this.enableHud.debug = false;
      // check fixed enable hud modes 
      this.enableHud.main = [EHudContext.ar, EHudContext.mapAndAr].indexOf(hudMode) !== -1;
      // check dynamic enable hud mode (context hud)
      this.contextHud = hudMode === EHudContext.auto;
    }
  }

  loadPreset() {
    if (AppSettings.localSettings.disableARTicker) {
      this.enableHud.xp = false;
    }
    this.demoMode = AppSettings.localSettings.ARDemoMode;
    this.useWebkitCompass = AppSettings.localSettings.useWebkitCompass;
  }

  ngAfterViewInit() {
    this.settingsProvider.getSettingsLoaded(false).then((res: boolean) => {
      // wait to load global settings
      if (res) {
        this.loadSettings();
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
          if (loaded) {
            this.platform = SettingsManagerService.settings.platformFlags;
            this.benchmarkEnabled = SettingsManagerService.settings.app.settings.sensorStreamingAR.value;
            this.toggleHudMode(this.debugMode);
            this.loadPreset();
            if (this.benchmarkEnabled) {
              this.bench.initARCalibrationDataFiles().then(() => {

              }).catch((err: Error) => {
                console.error(err);
              });
            }
            this.bench.initStreamingBuffer(1000);
            this.geoObjects.setPlatform(this.platform);
            // console.log(this.aweJsProvider.getGeometryByShape("sphere"));
            this.nps.checkParamsLoaded().then(() => {
              let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.ar, null, this.np);
              console.log("nav params: ", npInfo.params);
              this.hasParams = npInfo.hasParams;
              let initLoc: ILatLng = null;
              this.setOverlayStyle();
              if (this.hasParams) {
                let np: INavParams = npInfo.params;
                this.params = np.params;
                this.vs = np.view;
                if (this.params) {
                  // sync with map hud (activity info, etc)
                  this.hudMsgMap = this.params.hudMsg;
                  let activityName: string = "WORLD MAP AR";
                  this.activity = this.params.activity;
                  if (this.activity) {
                    activityName = this.activity.name;
                    // activity started, show dynamic hud (if not already enabled as fixed hud)
                    if (this.contextHud) {
                      // show the hud when activity is in progress                     
                      this.enableHud.main = true;
                      this.showHud = true;
                    }
                  }
                  this.infoText = "<p>" + activityName + "</p>";
                  console.log(this.params);
                  if (this.params.linkMapSendToAR) {
                    this.subscription.linkAR = this.params.linkMapSendToAR.subscribe((code: number) => {
                      console.log("AR link message: " + code);
                      switch (code) {
                        case EViewLinkCodes.exit:
                          this.exit(true);
                          break;
                        default:
                          break;
                      }
                    }, (err: Error) => {
                      console.error(err);
                    });
                  }
                }
                this.isModal = true;
              } else {
                this.isModal = false;
              }

              // will be opened as a modal
              // with add to stack false
              this.webView.ready().then(() => {
                this.backButton.pushOrReplace(() => {
                  this.exit(null);
                }, this.vs);
              });

              let init: boolean = this.locationManager.startWatchPosition();
              this.locationWatchAlreadyStarted = !init;

              if (!initLoc) {
                initLoc = this.locationMonitor.getCachedLocation();
              }
              if (!initLoc) {
                initLoc = new ILatLng(0, 0);
              }

              this.currentLocation = Object.assign({}, initLoc);
              this.initialLocation = Object.assign({}, initLoc);
              console.log("current location init: ", this.currentLocation);

              this.createChart();
              this.initMain();
            }).catch((err: Error) => {
              console.error(err);
            });
          }
        }, (err: Error) => {
          console.error(err);
        });
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngOnDestroy() {
    console.log("AR view entry page dismiss");
    console.log("AR cleanup");
    this.messageQueueHandler.cleanup();
    this.minimapProvider.clearMinimap();
    this.minimapProvider.removeMinimap();
    this.clearTimers();
    this.clearObservables();
    this.headingService.resetJoystickControl();
    if (!this.locationWatchAlreadyStarted) {
      this.locationManager.stopWatchPositionResolve().then(() => {

      }).catch((err: Error) => {
        console.error(err);
      });
    }
    this.show = false;
    this.removeAllTestObjects();
    HudUtils.clearHudMessage(this.hudMsgMap, EMapHudCodes.compassHeading);
    HudUtils.clearHudMessage(this.hudMsgMap, EMapHudCodes.fps);
    this.uiext.enableSidemenu();
    this.backButton.checkPop(this.vs);
  }

  showWalkthrough() {
    this.walkthrough.showARViewIntro().then(() => {
      console.log("walkthrough returned to AR");
    });
  }

  showWalkthroughAutoInit() {
    if (SettingsManagerService.settings.app.settings.enableWalkthroughs.value) {
      this.storageFlags.loadFlagsGroup(ELocalAppDataKeys.localShowFlags, null, true).then((flags: ILocalShowFlags) => {
        if (!flags.arTutorial) {
          this.timeouts.showWalkthrough = setTimeout(() => {
            flags.arTutorial = true;
            this.storageFlags.updateFlagsGroup(ELocalAppDataKeys.localShowFlags, flags);
            this.storageFlags.saveFlagsGroup(ELocalAppDataKeys.localShowFlags).then(() => {
              this.showWalkthrough();
            });
          }, 3000);
        }
      });
    }
  }

  /**
   * subscribe to message queue
   * it handles reward popups with timeouts and queue for minimum show duration of messages
   */
  subscribeToMessageQueue() {
    if (!this.subscription.messageQueue) {
      this.subscription.messageQueue = this.messageQueueHandler.getQueueWatch().subscribe((qEvent: IMessageQueueEvent) => {
        if (qEvent) {
          // console.log("qEvent: ", qEvent);
          let msg: IQueueMessage = qEvent.data;
          if (msg) {
            let msgString: string = msg.message;
            msgString = StringUtils.trimName(msgString, EMessageTrim.xpMessageHud);
            this.hudMsgXP = msgString;
            this.hudShow = qEvent.state;
          } else {
            this.hudMsgXP = "";
            this.hudShow = qEvent.state;
          }

          this.hudConfig = HudUtils.getXpHudClass(msg, this.hudConfig);
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  /**
   * show hud message main/debug
   * @param code 
   * @param value 
   * @param unit 
   * @param mapCode 
   */
  showHudMessage(code: number, value: string, unit: string, mapCode?: number) {
    this.showHud = true;
    if (this.enableHud.main) {
      if (mapCode) {
        HudUtils.showHudMessage(this.hudMsgMap, mapCode, value, unit, false);
      }
    }
    if (this.enableHud.debug) {
      HudUtils.showHudMessage(this.hudMsgAR, code, value, unit, true);
    }
  }

  /**
   * check hud enabled
   * main/debug
   */
  checkHudEnabled() {
    if (this.debugMode) {
      return this.enableHud.debug;
    } else {
      return this.enableHud.main;
    }
  }

  /**
   * set/toggle enable hud
   * @param enable 
   */
  setHudEnabled(enable: boolean) {
    if (this.debugMode) {
      if (enable != null) {
        this.enableHud.debug = enable;
      } else {
        this.enableHud.debug = !this.enableHud.debug;
      }
    } else {
      if (enable != null) {
        this.enableHud.main = enable;
      } else {
        this.enableHud.main = !this.enableHud.main;
      }
    }
  }

  /**
   * show options menu
   */
  showOptions() {
    let actions: IPopoverActions = {
      // reload: {
      //     name: "Reload",
      //     code: 1,
      //     enabled: true
      // },
      // align: {
      //     name: "Realign",
      //     code: 2,
      //     enabled: true
      // },
      exit: {
        name: "Exit AR",
        code: EARShowOptions.exitAR,
        icon: EAppIconsStandard.exit,
        enabled: true
      },
      tutorial: {
        name: "Tutorial",
        code: EARShowOptions.showTutorial,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      },
      toggleLayers: {
        name: "AR Layers",
        code: EARShowOptions.filterLayers,
        icon: EAppIconsStandard.mapFilter,
        enabled: true
      },
      toggleHud: {
        name: this.checkHudEnabled() ? "Disable HUD" : "Enable HUD",
        code: EARShowOptions.toggleHud,
        icon: EAppIconsStandard.hud,
        enabled: true
      },
      sync: {
        name: "Geo Sync",
        code: EARShowOptions.geoSync,
        icon: EAppIconsStandard.sync,
        enabled: true
      }
    };

    if (!this.isModal) {
      actions.obj = {
        name: "Select object",
        code: EARShowOptions.selectObject,
        enabled: true
      };
    }

    if (AppSettings.testerMode) {
      let actions3: IPopoverActions = {
        toggleHudDebug: {
          name: this.debugMode ? "Disable debug HUD" : "Enable debug HUD",
          code: EARShowOptions.toggleDebugHud,
          icon: EAppIconsStandard.hud,
          enabled: true
        },
        align: {
          name: "Align*",
          code: EARShowOptions.gyroAlign,
          enabled: true
        },
        itemScan: {
          name: "Treasure Scan*",
          code: EARShowOptions.treasureScan,
          enabled: true
        },
        expire: {
          name: "Expire now*",
          code: EARShowOptions.triggerExpireActivity,
          enabled: true
        },
        testScaleFactor: {
          name: "Scale factor*",
          code: EARShowOptions.resizeCanvas,
          enabled: true
        },
        record: {
          name: "Start Recording*",
          code: EARShowOptions.startRecording,
          enabled: true
        },
        stopRecord: {
          name: "Stop Recording*",
          code: EARShowOptions.stopRecording,
          enabled: true
        },
        refreshViewObjects: {
          name: "Refresh view*",
          code: EARShowOptions.refreshViewObjects,
          enabled: true
        },
        showARStats: {
          name: "Show AR Stats*",
          code: EARShowOptions.showARStats,
          enabled: true
        },
        toggleHudXP: {
          name: this.enableHud.xp ? "Disable Ticker*" : "Enable Ticker*",
          code: EARShowOptions.toggleHudXp,
          enabled: true
        },
        showCompassAdjust: {
          name: "Compass adjust",
          code: EARShowOptions.showCompassAdjustInput,
          enabled: true
        }
      };
      Object.assign(actions, actions3);
    }

    this.uiextStandard.showStandardModal(null, EModalTypes.options, "Options", {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: true,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((code: number) => {
      switch (code) {
        case EARShowOptions.exitAR:
          this.exit(null);
          break;
        case EARShowOptions.gyroAlignReset:
          this.leplaceMessageTx("gyro_align_reset", null);
          this.initCheck.headingCalibrated = false;
          break;
        case EARShowOptions.showTutorial:
          if (this.enableSpecialActivity) {
            this.viewSpecialActivityTutorial(this.params.specialActivity.code, this.params.specialActivity.customParams);
          } else {
            this.tutorials.showTutorialNoAction("AR Tutorial", ETutorialEntries.AR, null, null, true);
          }
          break;
        case EARShowOptions.filterLayers:
          this.selectLayers();
          break;
        case EARShowOptions.selectObject:
          if (!this.objectSpecsList || this.objectSpecsList.length === 0) {
            this.resourcesProvider.getTreasureSpecsGeneric().then((objects: ILeplaceObject[]) => {
              this.objectSpecsList = objects;
              this.openObjectPicker(this.objectSpecsList).then((objectSpec: ILeplaceObject) => {
                this.nextObjectSpecs = objectSpec;
                if (!this.nextObjectSpecs.animate) {
                  this.nextObjectSpecs.animate = 1;
                }
              }).catch((err: Error) => {
                console.error(err);
              });
            }).catch((err: Error) => {
              console.error(err);
            });
          } else {
            this.openObjectPicker(this.objectSpecsList).then((objectSpec: ILeplaceObject) => {
              this.nextObjectSpecs = objectSpec;
            }).catch((err: Error) => {
              console.error(err);
            });
          }
          break;
        case EARShowOptions.geoSync:
          this.initObjectsSync();
          break;
        case EARShowOptions.gyroAlign:
          this.leplaceMessageTx("gyro_align", {
            ref: this.currentHeadingCompassDegrees,
            align: true
          });
          break;
        case EARShowOptions.triggerExpireActivity:
          this.triggerExpireActivity();
          break;
        case EARShowOptions.treasureScan:
          PromiseUtils.wrapNoAction(this.itemScanner.treasureScan(), true);
          break;
        case EARShowOptions.toggleHud:
          this.setHudEnabled(null);
          break;
        case EARShowOptions.toggleDebugHud:
          this.toggleHudMode(!this.debugMode);
          break;
        case EARShowOptions.resizeCanvas:
          console.log("will resize canvas");
          this.leplaceMessageTx("resize_canvas", { scaleFactor: this.scaleFactor.options[this.scaleFactor.index] });
          this.scaleFactor.index += 1;
          if (this.scaleFactor.index >= this.scaleFactor.options.length) {
            this.scaleFactor.index = 0;
          }
          break;
        case EARShowOptions.startRecording:
          this.leplaceMessageTx("start_recording", {
            dualMode: SettingsManagerService.settings.app.settings.ARRecordDual.value
          });
          break;
        case EARShowOptions.stopRecording:
          this.notifyRecordComplete = true;
          this.leplaceMessageTx("stop_recording", null);
          break;
        case EARShowOptions.refreshViewObjects:
          this.refreshViewObjects();
          break;
        case EARShowOptions.showARStats:
          this.showARStats = !this.showARStats;
          this.leplaceMessageTx("update_setup", this.getARSetupParams());
          break;
        case EARShowOptions.toggleHudXp:
          this.enableHud.xp = !this.enableHud.xp;
          break;
        case EARShowOptions.showCompassAdjustInput:
          this.headingService.openCompSelector();
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  async handleShowTutorial() {
    let slideData: IGenericSlideData[] = [];
    try {
      // get AR slider tutorial
      slideData = await this.resourcesProvider.getTutorialSliderGen(ESliderTutorialMode.ar, null);
      await this.modularViews.showGameplayOverviewSlider(slideData, "AR View");
    } catch (err) {
      console.error(err);
    }
  }

  /**
   * debug test trigger expire activity timeout
   */
  triggerExpireActivity() {
    console.log("trigger expire");
    this.exploreProvider.triggerExpired();
    this.moveActivityProvider.triggerExpired();
    this.timeoutMonitor.triggerExpired();
  }

  selectLayers() {
    this.uiextStandard.showStandardModal(null, EModalTypes.checkboxGrid, "AR layers", {
      view: {
        fullScreen: false,
        transparent: false,
        large: true,
        addToStack: true,
        frame: false
      },
      params: { actions: this.layers }
    }).then((data: ICheckboxFrameStatus) => {
      if (data) {
        if (data.update) {
          // refresh object layers
          this.layers = data.status;
          this.geoObjects.setShowLayers(data.status as IActionLayers);
          this.refreshLayers();
        }
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  refreshLayers() {
    this.initObjectsSync();
  }

  /**
   * pick the next object from the list
   * @param objectSpecsList 
   */
  openObjectPicker(objectSpecsList: ILeplaceObject[]) {
    let promise = new Promise((resolve, reject) => {
      let actions: IPopoverActions = {};
      actions = {

      };

      for (let i = 0; i < objectSpecsList.length; i++) {
        let object: ILeplaceObject = objectSpecsList[i];
        actions[object.code.toString()] = {
          name: object.name,
          code: object.code,
          enabled: true
        };
      }

      this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
        view: {
          fullScreen: false,
          transparent: false,
          large: false,
          addToStack: true,
          frame: false
        },
        params: { actions: actions }
      }).then((code: number) => {
        resolve(objectSpecsList.find(o => o.code === code));
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }


  toggleChartSize() {
    this.largeChart = !this.largeChart;
  }

  onGaugeSelect(event: any) {
    console.log("select gauge: ", event);
  }

  /**
   * begin initialization sequence
   */
  initMain() {
    this.subscribeToMessageQueue();
    this.permissionsService.requestCameraPermission().then(async () => {
      // watch external generated objects
      // this.watchObjects();
      this.messageQueueHandler.prepare("ARMAX Engine is loading", false, EQueueMessageCode.info);
      // let gyro: boolean = await this.headingService.checkGyroscopeAvailable();
      let gyro: boolean = await this.headingService.checkCompassOrientationAvailable();
      let supported: boolean = true;
      if (!gyro) {
        this.messageQueueHandler.prepare("Gyroscope is not available", false, EQueueMessageCode.error);
        this.messageQueueHandler.prepare("AR is not fully supported on your device", false, EQueueMessageCode.error);
        if (!this.platform.WEB) {
          supported = false;
          await this.uiext.showAlert(Messages.msg.ARNotSupportedMissingSensors.after.msg, Messages.msg.ARNotSupportedMissingSensors.after.sub, 1, null);
        }
      }
      if (supported) {
        await SleepUtils.sleep(1000);
        this.show = true;
      } else {
        this.exit(null);
      }
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "AR");
      this.uiext.showAlertNoAction(Messages.msg.error.after.msg, ErrorMessage.parse(err));
    });
  }

  showUpdateInProgress() {
    this.messageQueueHandler.prepare("AR update in progress", false, EQueueMessageCode.info);
  }

  /**
   * check if the object should be included in the scene
   * e.g. fixed scene contains only locked objects
   * @param object 
   */
  checkARScene(object: ILeplaceObjectContainer) {
    if (SettingsManagerService.settings.app.settings.ARFixedScene.value) {
      if (object.object.locked === ETreasureLockedMode.fixedSceneDev) {
        return true;
      }
      return false;
    }
    return true;
  }

  /**
   * watch externally generated objects
   * or generate objects in front of the user at specified timeouts
   */
  watchObjects() {
    let MObjectsObservable = this.geoObjects.getMapFirstObjectWatch();
    console.log("watch objects");
    this.initObjectsSync();
    if (MObjectsObservable) {
      console.log("started");
      this.subscription.objectGenerator = MObjectsObservable.subscribe((object: ILeplaceObjectGenerator) => {
        console.log("new object event: ", object);
        if (object && object.container && this.checkARScene(object.container)) {
          switch (object.operation) {
            case ECRUD.add:
              // add NEW object on minimap/AR
              this.placeObjectExtGen(object.container.location, object.container.object);
              // this.q.enqueue(this.placeObjectExtGen(object.container.location, object.container.object), null, null);
              break;
            case ECRUD.remove:
              // remove from minimap/AR
              this.removeARObject(object.container.object.uid, true);
              // this.q.enqueue(this.removeARObject(object.container.object.uid), null, null);
              break;
            case ECRUD.update:
              // update, show visible on AR, keep unchanged on minimap
              // show/hide managed from AR module
              let objectsG: ILeplaceObjectContainer[] = this.geoObjects.getGlobalObjects();
              let objectsM: ILeplaceObjectContainer[] = this.geoObjects.getMapFirstObjects();
              let objects: ILeplaceObjectContainer[] = objectsM.concat(objectsG);
              console.log("update visible: ", objects.filter(e => this.checkVisibleAR(e.object)).length + "/" + objects.length);
              this.updateObject(object.container.location, object.container.object, object.container.minimapLocked);
              break;
            default:
              break;
          }
        }
      });
    }

    // check for scan event, show loading
    if (!this.subscription.itemScanEventWatch) {
      this.subscription.itemScanEventWatch = this.itemScanner.getScanEventObservable().subscribe((scan: boolean) => {
        if (scan !== null) {
          if (scan) {
            this.loading = true;
            this.showUpdateInProgress();
          } else {
            this.loading = false;
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  isMobileSensing() {
    return !this.platform.WEB || (this.platform.WEB && GeneralCache.isPublicDistribution);
  }

  /**
   * watch true compass heading (compass)
   */
  watchCompassHeading() {
    console.log("watch compass heading");
    if (!this.subscription.compassHeading && this.isMobileSensing()) {
      console.log("started");
      let calibrationInterval: number = 100; // x50 ms = 5 s
      let calibrationIntervalCounter: number = 10;

      let ga: IGyroAlign = {
        ref: 0,
        align: false
      };

      this.headingService.checkCompassOrientationAvailable().then(async () => {
        // watch heading custom ts (fast update)
        this.headingService.stopWatchHeading();
        await SleepUtils.sleep(500);
        this.headingService.watchHeading(this.platform.WEB ? 100 : 50, false);
        this.subscription.compassHeading = this.headingService.getHeadingObservable().subscribe((status: IHeadingStatus) => {
          if (status != null) {
            ga.align = false;
            if (status.compassHeading != null) {
              this.currentHeadingCompassDegrees = status.compassHeading;
              this.showHudMessage(EARHudCodes.headingCompass, Math.floor(this.currentHeadingCompassDegrees).toString(), null);
              // calibrate at regular intervals using compass heading, get alignment delta
              // if heading calibration source is compass
              ga.ref = this.currentHeadingCompassDegrees;
              // check calibration interval
              if (this.flags.headingCalibrationSource === EHeadingCalibrationSources.compass) {
                if (calibrationIntervalCounter > 0) {
                  calibrationIntervalCounter--;
                } else {
                  calibrationIntervalCounter = !this.initCheck.engineLoaded ? 10 : calibrationInterval;
                  // set align flag
                  ga.align = true;
                }
              }
              // check manual comp
              if (this.headingService.checkResetManualCompensationChanged()) {
                this.leplaceMessageTx("compass_adj", {
                  adj: this.headingService.getManualCompensation()
                });
                // set align flag
                ga.align = true;
              }
              // check align flag
              if (ga.align) {
                this.leplaceMessageTx("gyro_align", ga);
                if (this.debugMode) {
                  // this.messageQueueHandler.prepare("compass align", true);
                }
              }
            }
          }
        });
      }).catch((err: Error) => {
        this.analytics.dispatchError(err, "AR");
        this.messageQueueHandler.prepare("Compass is not available", false, EQueueMessageCode.warn);
        this.messageQueueHandler.prepare("AR will not work on your device", false, EQueueMessageCode.warn);
      });
    }
  }

  watchDeviceOrientation() {
    console.log("watch device orientation native");
    if (!this.subscription.deviceOrientationData && this.isMobileSensing()) {
      console.log("started");
      this.headingService.checkCompassOrientationAvailable().then(() => {
        // watch heading custom ts (fast update)
        this.headingService.stopWatchOrientation();
        this.headingService.watchGyroscopeOrientation(50);
        this.subscription.deviceOrientationData = this.headingService.getOrientationObservable().subscribe((status: IHeadingStatus) => {
          if (status != null) {
            if (status.alpha != null && status.beta != null && status.gamma != null) {
              this.leplaceMessageTx("deviceorientation_ext", {
                alpha: status.alpha,
                beta: status.beta,
                gamma: status.gamma
              });
            }
          }
        });
      }).catch((err: Error) => {
        this.analytics.dispatchError(err, "AR");
        this.messageQueueHandler.prepare("Compass is not available", false, EQueueMessageCode.warn);
        this.messageQueueHandler.prepare("AR will not work on your device", false, EQueueMessageCode.warn);
      });
    }
  }

  /**
   * watch position using the location monitor
   */
  watchPosition() {
    let locationObs = this.locationMonitor.getUnifiedLocationObservable();
    console.log("watch position");
    if (locationObs) {
      console.log("started");
      this.subscription.location = locationObs.subscribe((data: IGeolocationResult) => {
        // console.log(data);
        if (data && data.coords && (data.coords.lat != null && data.coords.lng != null)) {
          this.flags.gpsSignalReceived = true;
          this.currentLocation.lat = data.coords.lat;
          this.currentLocation.lng = data.coords.lng;

          if (!this.ARInitComplete) {
            if (data.accuracy <= AppConstants.gameConfig.locationAccuracyThreshold) {
              this.ARInitComplete = true;
              this.initialLocation.lat = data.coords.lat;
              this.initialLocation.lng = data.coords.lng;
              this.leplaceMessageTx("set_initial_position", { location: this.initialLocation });
            }
          }

          let multiplier: number = 10000;
          let dispLat: number = Math.floor((this.currentLocation.lat * multiplier) % multiplier);
          let dispLng: number = Math.floor((this.currentLocation.lng * multiplier) % multiplier);

          this.showHudMessage(EARHudCodes.lat, dispLat.toString(), null);
          this.showHudMessage(EARHudCodes.lng, dispLng.toString(), null);

          if (this.ARInitComplete) {
            // get location info
            let heading: number = data.heading + this.compassAlign.gpsHeadingAdjust;
            heading = GeometryUtils.translateTo360(heading);
            let speed: number = data.speed;
            this.currentHeadingGPSDegrees = Math.floor(heading);

            if (this.isMobileSensing()) {
              // for web, that whould trigger an infinite loop, because the gps position is updated with the keys
              this.leplaceMessageTx("update_gps_position", { location: this.currentLocation });
            }

            if (heading != null) {
              this.showHudMessage(EARHudCodes.headingGPS, this.currentHeadingGPSDegrees.toString(), null);
            } else {
              HudUtils.clearHudMessage(this.hudMsgAR, EARHudCodes.headingGPS);
            }
            this.currentSpeedGPS = speed;

            if (speed != null) {
              let formatSpeed = MathUtils.formatSpeedDisp(speed * 3.6);
              this.showHudMessage(EARHudCodes.speed, formatSpeed.value, formatSpeed.unit);
            } else {
              HudUtils.clearHudMessage(this.hudMsgAR, EARHudCodes.speed);
            }

            // calculate the new position (point) relative to the initial position
            // update the point of view (POV) accordingly so that the objects are 
            // shown at real scale

            // get angle from gps delta to align compass
            // only when moving faster that 1 m/s (3.6 km/h) 
            if (heading != null && speed >= this.flags.calibrationSpeedGPS && this.flags.headingCalibrationSource === EHeadingCalibrationSources.GPS) {
              this.leplaceMessageTx("gyro_align", {
                ref: this.currentHeadingGPSDegrees,
                align: true
              });
            }
            // this.distanceAxis = GeometryUtils.getDistanceBetweenEarthCoordinatesOnAxis(this.initialLocation, this.currentLocation);
            // set absolute position when received data from GPS
            // between readings it is possible to interpolate
            // this.requestPovUpdateMetric(this.distanceAxis.dx, this.distanceAxis.dy);

            // check distance to objects
            // this.checkDistanceToObjects();
          }


          this.objectsNearbyList = this.geoObjects.checkNearbyMapFirstObjects(this.currentLocation);
          if (this.objectsNearbyList.length > 0) {
            if (!this.flags.objectsNearby) {
              this.flags.objectsNearby = true;
              this.messageQueueHandler.prepare("There is an item nearby", false, EQueueMessageCode.info);
            }
          } else {
            this.flags.objectsNearby = false;
          }

        }
      });
    }
  }


  /**
   * simulate gps direction for testing
   */
  rotateDirection() {
    this.currentHeadingTest += 10;
    if (this.currentHeadingTest > 359) {
      this.currentHeadingTest = 0;
    }
    this.leplaceMessageTx("gyro_align", {
      ref: this.currentHeadingTest,
      align: true
    });
  }

  /**
   * simulate align event
   */
  testAlign() {
    this.leplaceMessageTx("gyro_align", {
      ref: this.currentHeadingTest,
      align: true
    });
  }

  getCodeFromUid(uid: string) {
    let object: ILeplaceObjectContainer = this.geoObjects.getMapFirstObjectByUid(uid);
    if (!object) {
      return null;
    }
    // console.log("uid: ", uid, ", code: ", object.object.code);
    return object.object.genericCode;
  }

  setOverlayStyle() {
    let ios: boolean = GeneralCache.checkPlatformOS() === EOS.ios;
    if (!SettingsManagerService.settings.app.settings.onScreenHomeButtonFix.value) {
      ios = false;
    }
    this.footerClass = ios ? "footer-size-large" : "footer-size";
    this.footerContentClass = ios ? "footer-size-large-inner" : "footer-size-inner";
    this.hudConfig.outerClass = ios ? EClass.HUD_BAN_AR_IOSX : EClass.HUD_BAN_AR_NORMAL;
  }

  getARSetupParams() {
    let filterConstant: number = 0.95;
    filterConstant = SettingsManagerService.settings.app.settings.ARCalibrationFiltering.value;
    let set: IAppSettingsContent = SettingsManagerService.settings.app.settings;
    let params: IAREnableParams = {
      enableScale: set.AREnableScaling.value,
      calibrationMode: set.ARCalibrationMode.value,
      calibrationInterval: 10, // not used in AR.js implementation
      filterConstant: filterConstant,
      hq: set.ARHighQuality.value,
      calibrationZone: set.ARGimbalLockLevel.value,
      heightAdjust: set.ARObjectHeightAdjust.value,
      pauseDisplayLoopEnabled: set.ARPauseDisplayLoopEnable.value,
      far: this.useARjs ? this.far * 5 : this.far,
      farLevel: this.far,
      scale: this.scale,
      // webMode: this.platform.WEB && GeneralCache.isDev,
      webMode: this.platform.WEB,
      ios: GeneralCache.checkPlatformOS() === EOS.ios,
      checkSensors: GeneralCache.checkPlatformOS() === EOS.ios,
      // checkSensors: true,
      customSensor: GeneralCache.checkPlatformOS() !== EOS.ios,
      // customSensor: true,
      stats: this.showARStats,
      // extCalibration: GeneralCache.checkOSReal() !== EOS.ios,
      useWebkitCompass: this.useWebkitCompass,
      extCalibration: true,
      altitude: this.useARjs ? 2 : 2,
      blinkCursor: !this.demoMode
    };
    return params;
  }

  /**
   * receive message from IFRAME
   * @param name 
   * @param data 
   */
  leplaceMessageRx(name: string, data: any) {
    // the message arrives from IFRAME tx method
    if (this.debugLog) {
      console.log("AR_IFRAME/OUTPUT_RECEIVED: ", name, data);
    }

    // prevent restarting after the AR view is disposed
    if (this.exitFlag) {
      return;
    }

    try {
      switch (name) {
        case "output/main_loaded":
          // [INIT] signals that the iframe has loaded
          this.showState = 'inactive';
          this.timeouts.hidePlate = setTimeout(() => {
            this.ARInitSignalReceived = true;
            this.navGaugeReady = true;
          }, 1000);
          this.timeouts.loadAR = setTimeout(() => {
            this.leplaceMessageTx("enable", this.getARSetupParams());
            // will trigger "output/initialized" on init
          }, 1000);
          break;
        case "output/initialized":
          // [INIT] signals that AWE.js was initialized
          this.timeouts.loadAR = setTimeout(() => {
            this.watchCompassHeading();
            if (this.useNativeDeviceOrientation) {
              this.watchDeviceOrientation();
            }
            this.watchPosition();
            if (this.platform.WEB) {
              this.leplaceMessageTx("set_initial_position", { location: this.initialLocation });
              // this.leplaceMessageTx("update_gps_position", { location: this.currentLocation });
              this.dispAlignData(data);
              this.proceedWithLoadingScene();
            }
            if (this.params && this.params.specialActivity) {
              this.specialActivityParams = this.params.specialActivity;
              this.selectedSpecialActivityCode = this.params.specialActivity.code;
              this.handleSpecialActivity(this.params.specialActivity.code, this.params.specialActivity.customParams);
            }
            this.initCheck.engineLoaded = true;
            this.messageQueueHandler.prepare("Waiting for compass", true, EQueueMessageCode.info);
            this.timeouts.compassAlignRequired = setTimeout(() => {
              this.messageQueueHandler.prepare("Calibrating. Point camera downward", true, EQueueMessageCode.info);
            }, 3000);
            if (!this.timeouts.showWarning) {
              if (!this.demoMode) {
                this.timeouts.showWarning = setTimeout(() => {
                  this.handleShowTutorial();
                }, 10000);
              }
            }
          }, 1000);
          break;
        case "output/gyro_aligned":
          // wait for gyro alignment before loading objects
          this.timeouts.compassAlignRequired = ResourceManager.clearTimeout(this.timeouts.compassAlignRequired);
          this.dispAlignData(data);
          if (data.apply) {
            if (this.debugMode) {
              this.messageQueueHandler.prepare("compass align", true, EQueueMessageCode.info);
            }
          }
          if (!this.initCheck.headingCalibrated) {
            this.initCheck.headingCalibrated = true;
            this.messageQueueHandler.prepare("Compass alignment ready", true, EQueueMessageCode.info);
            this.proceedWithLoadingScene();
          }
          break;
        case "output/calibration_status":
          if (data.calibrateRequired) {
            this.messageQueueHandler.prepare("Calibration required", false, EQueueMessageCode.warn);
            this.messageQueueHandler.prepare("Point camera downward", false, EQueueMessageCode.warn);
          } else {
            if (data.calibrateComplete) {
              this.messageQueueHandler.prepare("Calibration complete", false, EQueueMessageCode.info);
            }
          }
          break;
        case "output/current_heading":
          // 20 ms fixed ts
          let headingData: IARHeadingData = data;
          // console.log("heading data: ", headingData);
          if (headingData.updateChart) {
            // variable ts, max 100 ms ts
            this.minimapProvider.setReversed(headingData.reversed);
            this.minimapProvider.rotateChartDegrees(headingData.headingGimbalFix);
            // if (this.platform.WEB) {
            //   this.minimapProvider.rotateChartDegrees(headingData.headingGimbalFix);
            // } else {
            //   this.minimapProvider.rotateChartQuaternion(headingData.raw.quaternions);
            // }
          }
          this.isGimbalLockZone = headingData.isGimbalLockZone;
          this.showHudMessage(EARHudCodes.headingGyroAligned, Math.floor(headingData.heading).toString(), null, EMapHudCodes.compassHeading);
          this.showHudMessage(EARHudCodes.fps, Math.floor(headingData.fps).toString(), null, EMapHudCodes.fps);
          this.showHudMessage(EARHudCodes.headingGyroRaw, Math.floor(headingData.rawHeading).toString(), null);
          this.showHudMessage(EARHudCodes.tilt, Math.floor(headingData.tilt).toString(), null);

          // let diff: number = GeometryUtils.getAngularDiffSigned(headingData.heading, this.currentHeadingCompassDegrees);
          // let diff: number = GeometryUtils.getCompassHeadingError(headingData.heading, GeometryUtils.translateTo360(360 - this.currentHeadingCompassDegrees));
          // this.showHudMessage(EARHudCodes.headingDelta, Math.floor(diff).toString(), null);
          this.showHudMessage(EARHudCodes.headingDelta, Math.floor(headingData.compassHeadingErrorFiltered).toString(), null);


          this.currentTrueHeading = headingData.heading;
          if (this.benchmarkEnabled) {
            let streamingData: IARStreamingData = {
              // timestamp: new Date().getTime(),
              timestamp: headingData.timestamp,
              calibratedHeading: headingData.heading,
              compassHeading: headingData.compassHeading,
              gyroHeading: headingData.rawHeading,
              lat: this.currentLocation.lat,
              lng: this.currentLocation.lng,
              dx: this.currentPosition.x,
              dy: this.currentPosition.y
            };
            this.streamingData = streamingData;
            // AR SRA testing, send raw data to server, text file or db
            // warning: the data is not uniform sampled (only on gyro update)
            this.bench.pushARData(streamingData);
          }
          // this.filtered.alpha = this.filtered.alpha * 0.9 + data.raw.alpha * 0.1;
          // this.filtered.beta = this.filtered.beta * 0.9 + data.raw.beta * 0.1;
          // this.filtered.gamma = this.filtered.gamma * 0.9 + data.raw.gamma * 0.1;
          // console.log("current heading: " + this.filtered.alpha + "\t\t" + this.filtered.beta + "\t\t" + this.filtered.gamma);
          // console.log("minimap data: ", this.minimapProvider.objectChartDataDisplay);
          break;
        case "output/motion":
          let motionData: IARMotionData = data;
          // console.log(motionData.altitude.relativeAltitude);
          this.showHudMessage(EARHudCodes.altitude, motionData.altitude.relativeAltitude.toFixed(2).toString(), null);
          this.showHudMessage(EARHudCodes.altitudeAccel, motionData.altitude.acceleration.toFixed(2).toString(), null);
          break;
        case "output/key_event":
          switch (data.key) {
            case "t":
              this.locationMonitor.keyOverrideLocation(119);
              break;
            case "g":
              this.locationMonitor.keyOverrideLocation(115);
              break;
            case "f":
              this.locationMonitor.keyOverrideLocation(97);
              break;
            case "h":
              this.locationMonitor.keyOverrideLocation(100);
              break;
            // case "x":
            //     this.testUpdateLocationFromARPov = true;
            // break;
          }
          break;
        case "output/add_object_absolute":
          let obj: IARObject = data;
          console.log("output/add_object_absolute", obj);
          if (this.checkVisibleMinimap(obj.data)) {
            this.minimapProvider.addPOIToMinimap(obj.uid, this.getCodeFromUid(obj.uid), obj.x, obj.y);
          }
          // this.minimapProvider.addPOIToMinimap(data.uid, data.xRel, data.yRel);
          // console.log(data);
          // this.minimapProvider.addPOIToMinimap(data.uid, (data.lat - this.currentLocation.lat)*100000, (data.lng - this.currentLocation.lng)*100000);
          break;
        case "output/add_object_relative":
          let objRel: IARObject = data;
          this.minimapProvider.addPOIToMinimap(objRel.uid, this.getCodeFromUid(objRel.uid), objRel.x, objRel.y);
          if (objRel.lat && objRel.lng) {
            let object: ILeplaceObjectContainer = {
              location: new ILatLng(objRel.lat, objRel.lng),
              object: objRel.data,
              treasure: null,
              providerCode: EGeoObjectsProviderCode.AR,
              dynamic: {
                distance: null
              }
            };
            this.geoObjects.addGlobalObject(object);
          }
          break;
        case "output/set_initial_position":
          // initial location sync with AR module
          this.initialLocation = new ILatLng(data.lat, data.lng);
          // console.log("initial position from AR: ", this.initialLocation);
          this.observables.ARInitializationComplete.next(true);
          break;
        case "output/update_pov":
          let posData: IARUpdatePOV = data;
          this.minimapProvider.translateChart(-posData.x, -posData.y); // translate by changing the origin
          let x1 = MathUtils.formatDistanceDisp(posData.x);
          let y1 = MathUtils.formatDistanceDisp(posData.y);
          this.currentPosition = Object.assign({}, posData);
          this.showHudMessage(EARHudCodes.x, x1.value, x1.unit);
          this.showHudMessage(EARHudCodes.y, y1.value, y1.unit);
          if (!this.isMobileSensing()) {
            this.locationMonitor.manualLocationOverrideLock(new ILatLng(posData.lat, posData.lng));
            this.currentLocation.lat = posData.lat;
            this.currentLocation.lng = posData.lng;
          }
          // console.log("pov changed: ", posData.lat, ", ", posData.lng, ", ", x1.value, ", ", y1.value);
          break;
        case "output/remove_object_id":
          console.log("output remove object id");
          // redundant
          // this.minimapProvider.removePOIFromMinimapById(data.uid);
          let spec: IARObjectRemoveSpecs = data.spec;
          if (spec.collected) {
            // object was collected
            if (this.activity) {
              PromiseUtils.wrapNoAction(this.uiext.showRewardPopupQueue("", "Collected " + (spec.name ? spec.name : "item"), null, false, 1000), true);
            }
          }
          break;
        case "output/object_clicked":
          // object clicked before tap is invoked 
          this.tapARObject(data.uid, false);
          break;
        case "output/start_recording":
          console.log("recording started");
          this.messageQueueHandler.prepare("recording started", false, EQueueMessageCode.info);
          break;
        case "output/recording_progress":
          this.showProgress();
          let dp: IProgressDisplay = {
            progress: data.progress,
            caption: "Saving video (" + Math.floor(data.progress) + "%)",
            determinate: true,
            id: data.id
          };
          this.observables.downloadProgress.next(dp);
          break;
        case "output/recording_error":
          this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, data.text);
          break;
        case "output/stop_recording":
          console.log("stop recording data: ", data.blob);
          let ab: ArrayBuffer = data.blob;
          let b: Blob = new Blob([new Uint8Array(ab)]);
          console.log("blob: ", b);
          // if (this.notifyRecordComplete) {
          if (!this.showLoadingView) {
            this.showLoadingView = true;
            this.messageQueueHandler.prepare("recording stopped", false, EQueueMessageCode.info);
            this.uiext.showLoadingV2Queue("Saving video..");
          }
          // }
          this.mediaUtils.saveBlobVideo("ar_video_" + data.id + "_" + new Date().getTime() + ".webm", b).then((uri: string) => {
            // if (this.notifyRecordComplete) {
            this.notifyRecordComplete = false;
            this.uiext.dismissLoadingV2();
            this.uiext.showAlertNoAction(Messages.msg.success.after.msg, "Video #" + data.id + " saved as: " + uri);
            // }
            console.log("video saved as: ", uri);
          }).catch((err: Error) => {
            console.error(err);
            this.uiext.dismissLoadingV2();
            this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
          });
          break;
        case "output/warning":
          data.message += "<p>AR might not work as expected</p>";
          PromiseUtils.wrapNoAction(this.uiext.showRewardPopupQueue(Messages.msg.warning.after.msg, data.message, null, false, 5000), true);
          // this.uiext.showAlertNoAction(Messages.msg.warning.after.msg, data.message);
          break;
        case "output/capture_screen":
          if (!data.image) {
            console.log(data.message);
          }
          this.prepareScreenshot(data.image);
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  }

  showProgress() {
    if (!this.showProgressView) {
      this.showProgressView = true;
      let modalParams: IProgressNavParams = {
        title: "Progress",
        info: "Saving video",
        progressObservable: this.observables.downloadProgress,
        caption: "",
        progress: 0
      };

      // not added to stack = cannot dismiss
      this.uiext.showCustomModal(null, ProgressViewComponent, {
        view: {
          fullScreen: false,
          transparent: false,
          large: true,
          addToStack: true,
          frame: false
        },
        params: modalParams
      }).then(() => {
        this.showProgressView = false;
      }).catch((err: Error) => {
        console.error(err);
        this.showProgressView = false;
      });
    }
  }

  dispAlignData(data: any) {
    if (data) {
      if (data.delta && data.deltaAux) {
        this.showHudMessage(EARHudCodes.alignDelta, Math.floor(data.delta).toString(), null);
        this.showHudMessage(EARHudCodes.alignDeltaAux, Math.floor(data.deltaAux).toString(), null);
      }
    }
  }

  /**
   * proceed with loading the scene
   * after heading calibrated, wait for initialization complete (initial position set)
   * then load objects
   */
  proceedWithLoadingScene() {
    if (this.initCheck.sceneLoaded) {
      console.warn("scene already loaded or loading in progress");
      return;
    }
    this.initCheck.sceneLoaded = true;
    // [INIT] signals that the gyro is calibrated, objects can now be added
    this.timeouts.watchObjectsInit = setTimeout(() => {
      // make sure that the AR Engine GPS position is initialized
      // before loading objects and other stuff
      this.scheduleTutorials(EMessageTimelineCodes.AR);
      this.subscription.ARInitializationComplete = this.observables.ARInitializationComplete.subscribe((res: boolean) => {
        if (res) {
          this.loading = false;
          // this.showWalkthroughAutoInit();
          this.watchObjects();
        }
      }, (err: Error) => {
        console.error(err);
      });
    }, 2000);
  }

  /**
   * schedule timeline tutorials
   */
  scheduleTutorials(mode: number) {
    if (SettingsManagerService.settings.app.settings.enableTutorial.value) {
      this.resourcesProvider.getMessageTimeline(mode).then((entries: IMessageTimelineEntry[]) => {
        if (entries) {
          for (let i = 0; i < entries.length; i++) {
            this.messageQueueHandler.schedule(entries[i].message, entries[i].timedelta, EQueueMessageCode.info);
          }
        }
      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "gmap");
      });
    }
  }

  /**
   * send message to IFRAME
   * @param name 
   * @param params 
   */
  leplaceMessageTx(name: string, params: any) {
    /**
     * ngzone does not require angular to interact with the UI
     * this can improve performance
     */
    let paramsSnapshot = params != null ? DeepCopy.deepcopy(params) : null;
    this.ngZone.run(() => {
      if (!this.iframe) {
        this.iframe = document.getElementById("myiframe") as HTMLIFrameElement;
      }
      if (!name) {
        name = "default";
      }
      this.timeouts.messageTx = setTimeout(() => {
        if (this.show) {
          this.pushLeplaceMessageTxQueue(name, paramsSnapshot);
          if (this.timeouts.retryTx == null) {
            // restart sending queue if not already running
            this.sendLeplaceMessageTxQueueRetry(10);
          }
        }
      }, 1);
    });
  }

  leplaceMessageTxCore(name: string, params: any) {
    let sent: boolean = false;
    let msg: string = "";
    // the message arrives into IFRAME rx method
    if (this.iframe && this.iframe.contentWindow) {
      let iframeContent: any = this.iframe.contentWindow;
      if (this.testRetryQueue > 0) {
        console.error("missed tx, test retry queue: " + this.testRetryQueue);
        this.testRetryQueue -= 1;
      } else {
        if (iframeContent.main) {
          try {
            iframeContent.main.leplaceMessageRx(name, params);
            if (this.debugLog) {
              console.log("AR_IFRAME/INPUT_SENT: " + name + ", " + JSON.stringify(params));
            }
            sent = true;
          } catch (err) {
            // internal error but message was sent
            sent = true;
            console.error(err);
          }
        } else {
          msg = "missed tx, main not initialized";
        }
      }
    } else {
      msg = "missed tx, iframe not available";
    }
    if (!sent) {
      console.error(msg);
      console.log("on AR_IFRAME/INPUT_SENT: " + name + ", " + JSON.stringify(params));
    }
    return sent;
  }

  pushLeplaceMessageTxQueue(name: string, params: any) {
    if (this.txQueue.length >= 100) {
      console.warn("tx queue size exceeded");
    } else {
      // push to queue
      this.txQueue.push({ name: name, data: params });
    }
  }

  sendLeplaceMessageTxQueueRetry(retryCounter: number) {
    if (this.txQueue.length === 0) {
      this.timeouts.retryTx = ResourceManager.clearTimeout(this.timeouts.retryTx);
      return;
    }
    if (retryCounter <= 0) {
      console.warn("retry timeout, discard queue");
      this.txQueue = [];
      return;
    }
    // try send first, if passed, send the others left in the queue
    let lastSentIndex: number = -1;
    for (let i = 0; i < this.txQueue.length; i++) {
      let el = this.txQueue[i];
      if (!this.leplaceMessageTxCore(el.name, el.data)) {
        // don't keep sending elements if the current element failed to send (preserve message order)
        console.warn("failed to send element from queue");
        break;
      } else {
        lastSentIndex = i;
      }
    }
    // update queue removing sent elements
    if (lastSentIndex !== -1) {
      this.txQueue.splice(0, lastSentIndex + 1);
    }
    // if all elements were sent, return, else keep looping
    if (this.txQueue.length > 0) {
      this.timeouts.retryTx = setTimeout(() => {
        this.sendLeplaceMessageTxQueueRetry(retryCounter - 1);
      }, 500);
    } else {
      this.timeouts.retryTx = ResourceManager.clearTimeout(this.timeouts.retryTx);
      return;
    }
  }

  /**
   * add currently selected object type (for testing purpose)
   */
  addObject() {
    // this.testGenerate(EARGenModes.compassRelative, 0, this.nextObjectSpecs);
    let genMode: number = SettingsManagerService.settings.app.settings.testObjectAddMode.value;
    this.testGenerate(genMode, 0, this.nextObjectSpecs);
  }

  removeLastObject() {
    if (this.addedTestObjects.length > 0) {
      let popObject: ILeplaceObject = this.addedTestObjects.pop();
      this.tapARObject(popObject.uid, false);
    }
  }

  /**
   * collect ONE nearby object per click
   */
  collectNearbyObject() {
    if (this.objectsNearbyList && this.objectsNearbyList.length > 0) {
      this.tapARObject(this.objectsNearbyList[0].uid, true);
    }
  }

  removeAllTestObjects() {
    for (let i = 0; i < this.addedTestObjects.length; i++) {
      let popObject: ILeplaceObject = this.addedTestObjects[i];
      this.minimapProvider.removePOIFromMinimapByUid(popObject.uid);
    }
    this.addedTestObjects = [];
  }

  /**
   * remove all objects from the AR view
   * remove from minimap and test object store as well
   */
  removeAllObjects() {
    this.minimapProvider.clearMinimap();
    this.removeAllTestObjects();
    this.leplaceMessageTx("remove_all_objects", null);
  }


  removeARObject(uid: string, minimap: boolean) {
    let removeSpecs: IARObjectRemoveSpecs = {
      uid: uid,
      animate: false,
      collected: false,
      name: null
    };

    // do this when the remove signal comes from the geoObjects provider observable itself
    if (minimap) {
      this.minimapProvider.removePOIFromMinimapByUid(uid);
    }
    this.leplaceMessageTx("remove_object_id", removeSpecs);
  }

  /**
   * run action defined when the object is tapped
   * e.g.
   * remove AR object from the AR view, minimap and global object provider
   * also remove from M objects now because the external collect handler is disabled while AR is open
   */
  tapARObject(uid: string, animate: boolean) {
    let removeSpecs: IARObjectRemoveSpecs = {
      uid: uid,
      animate: animate,
      collected: true,
      name: null
    };

    let obj: ILeplaceObjectContainer = this.itemCollector.getARObjectByUid(uid);
    console.log("tap AR object: ", obj);
    removeSpecs.name = (obj && obj.object && obj.object.name) ? obj.object.name : null;

    let activityBaseType: number = this.activityProvider.getActivityNavCollectType();
    if (activityBaseType == null) {
      activityBaseType = this.activityProvider.getActivityCollectType();
    }
    let check: ICheckCollectItem = this.itemCollector.collectFromAR(uid, activityBaseType);
    console.log("check collect: ", check);

    // check remove from AR
    if (check && check.client) {
      let removedObject: ILeplaceObjectContainer = this.geoObjects.removeGlobalObjectByUid(uid, false);
      if (!removedObject) {
        removedObject = this.geoObjects.removeMapFirstObjectByUid(uid, false);
        console.log("removed M object: ", removedObject);
      } else {
        console.log("removed G object: ", removedObject);
      }
      this.minimapProvider.removePOIFromMinimapByUid(uid);
      this.leplaceMessageTx("remove_object_id", removeSpecs);
    } else {
      //
    }
  }

  captureScreen() {
    console.log("screenshot");
    // Take a screenshot and save to file
    this.screenshotEnabled = false;
    this.leplaceMessageTx("capture_screen", null);
  }

  refreshViewObjects() {
    console.log("refresh view objects");
    this.leplaceMessageTx("refresh_view_objects", null);
  }

  prepareScreenshot(screenshotDataURI: string) {
    if (!screenshotDataURI) {
      this.screenshotEnabled = true;
      this.analytics.dispatchError(new Error("no screenshot data"), "ar-view-entry");
      return;
    }

    this.uiext.showLoadingV2Queue("Loading preview...").then(() => {
      this.screenshot.prepareScreenshot("AR View", screenshotDataURI).then((params: IPhotoFrameNavParams) => {
        this.uiext.dismissLoadingV2().then(() => {
          this.screenshot.previewScreenshot(params).then(() => {
            this.screenshotEnabled = true;
            this.checkARExplorePhotoActivity();
            this.exitARExplorePhotoActivity();
          }).catch((err: Error) => {
            this.screenshotEnabled = true;
            this.analytics.dispatchError(err, "ar-view-entry");
            this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
          });
        });
      }).catch((err: Error) => {
        this.screenshotEnabled = true;
        this.analytics.dispatchError(err, "ar-view-entry");
        this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
      });
    });
  }

  /**
   * extract the AR content to check for required objects
   * send the objects and coords to the arexplore provider
   */
  checkARExplorePhotoActivity() {
    if (this.selectedSpecialActivityCode === EARSpecialActivity.screenshotAR) {
      let found: boolean = this.arexploreProvider.checkRequiredObjects(this.minimapProvider.getCurrentView(), true);
      if (!found) {
        this.uiext.showAlertNoAction(Messages.msg.ARObjectNotFound.after.msg, Messages.msg.ARObjectNotFound.after.sub);
      }
    }
  }

  exitARExplorePhotoActivity() {
    if (this.selectedSpecialActivityCode === EARSpecialActivity.screenshotAR) {
      this.arexploreProvider.unlock();
    }
  }

  /**
   * show already existing objects
   * e.g. crates that are discovered before opening the AR view
   */
  initObjectsSync() {
    this.geoObjects.refreshAll(null);
    this.timeouts.addObjects = setTimeout(() => {
      let objectsG: ILeplaceObjectContainer[] = this.geoObjects.getGlobalObjects();
      let objectsM: ILeplaceObjectContainer[] = this.geoObjects.getMapFirstObjects();
      let objects: ILeplaceObjectContainer[] = objectsM.concat(objectsG);
      console.log("available M objects: ", objectsM);
      console.log("available G objects: ", objectsG);
      console.log("visible: ", objects.filter(e => this.checkVisibleAR(e.object)).length + "/" + objects.length);
      this.removeAllObjects();
      this.timeouts.addObjects = ResourceManager.clearTimeout(this.timeouts.addObjects);
      this.timeouts.addObjects = setTimeout(() => {
        this.placeObjectExtGenMultiple(objects);
      }, 2000);
    }, 1000);
  }

  /**
   * place multiple objects
   * @param objects 
   */
  placeObjectExtGenMultiple(objects: ILeplaceObjectContainer[]) {
    objects.forEach((object) => {
      if (object && object.object && this.checkARScene(object)) {
        this.placeObjectExtGen(object.location, object.object);
      }
    });
  }

  /**
   * recursive function to place objects from array with a delay in between
   */
  placeSingleObjectFromArray(objects: ILeplaceObjectContainer[], index: number) {
    this.timeouts.placeMultipleObjects = setTimeout(() => {
      if (index >= objects.length - 1) {
        this.timeouts.placeMultipleObjects = ResourceManager.clearTimeout(this.timeouts.placeMultipleObjects);
        this.loading = false;
        return;
      }
      let object: ILeplaceObjectContainer = objects[index];
      if (object && object.object) {
        this.placeObjectExtGen(object.location, object.object);
      }
      this.placeSingleObjectFromArray(objects, index + 1);
    }, 100);
  }

  /**
   * create a new object in front of the camera
   * uses the device orientation to calculate the POI coordinates of the object in front of the POV
   */
  testGenerate(mode: number, angle: number, specs: ILeplaceObject) {
    let createSpecs: IARObjectCreateRelativeSpecs = {
      uid: null,
      mode: mode,
      angle: angle,
      distance: 20,
      specs: specs
    };

    let objectSpecs: ILeplaceObject = DeepCopy.deepcopy(specs);
    objectSpecs.id = this.objectId;
    objectSpecs.uid = GameUtils.getDefaultObjectUid(this.objectId, specs.code);
    createSpecs.uid = objectSpecs.uid;

    switch (mode) {
      case EARGenModes.compassAbsolute:
        // let forwardLocation: ILatLng = GeometryUtils.getPointOnHeading(this.currentLocation, 20, GeometryUtils.flipAngle(this.currentHeadingGyroDegrees));
        let forwardLocation: ILatLng = GeometryUtils.getPointOnHeading(this.currentLocation, 20, this.currentTrueHeading);
        // console.log("place object: ", this.currentLocation, ", ", forwardLocation, ", ", this.currentTrueHeading);
        // console.log("delta: ", this.currentLocation.lat - forwardLocation.lat, ", ", this.currentLocation.lng - forwardLocation.lng);

        let objectContainer: ILeplaceObjectContainer = {
          location: forwardLocation,
          object: objectSpecs,
          providerCode: EGeoObjectsProviderCode.AR,
          treasure: null,
          dynamic: {
            distance: null
          }
        };
        this.geoObjects.addMapFirstObject(objectContainer);
        this.placeObjectExtGen(forwardLocation, objectSpecs);
        break;
      case EARGenModes.compassRelative:
        this.leplaceMessageTx("add_object_relative", createSpecs);
        break;
      default:
        break;
    }

    this.addedTestObjects.push(objectSpecs);
    this.objectId += 1;
  }

  /**
   * update object
   * add or remove from AR
   * keep on minimap
   * @param objectLocation 
   * @param specs 
   * @param showAR add/remove (show/hide) object from AR
   */
  updateObject(objectLocation: ILatLng, specs: ILeplaceObject, minimapLocked: boolean) {
    if (this.checkVisibleAR(specs)) {
      // already existing is handled in the minimap provider
      this.placeObjectExtGen(objectLocation, specs);
    } else {
      this.removeARObject(specs.uid, !minimapLocked);
    }
  }

  checkVisibleAR(lpo: ILeplaceObject) {
    return (lpo.show && !this.useARjs) || (lpo.available && this.useARjs);
    // this doesn't work well with new AR engine, handled inside AR iframe for managing object view distance
    // return lpo.show;
  }

  checkVisibleMinimap(lpo: ILeplaceObject) {
    return (lpo.showMinimap && !this.useARjs) || (lpo.available && this.useARjs);
  }

  /**
   * place externally generated object (absolute coordinates)
   * w/ timer queue
   * @param objectLocation
   * @param specs
   */
  placeObjectExtGen(objectLocation: ILatLng, specs: ILeplaceObject) {
    if (this.useQueueExt) {
      this.q.enqueue(() => {
        this.placeObjectExtGenCore(objectLocation, specs);
        return SleepUtils.sleep(50);
      }, () => {
        console.log("queue place ext object");
      }, null, EQueues.placeObjectAR, null, null);
    } else {
      this.placeObjectExtGenCore(objectLocation, specs);
    }
  }

  /**
   * place externally generated object (absolute coordinates)
   * @param objectLocation
   * @param specs
   */
  placeObjectExtGenCore(objectLocation: ILatLng, specs: ILeplaceObject) {
    let createSpecs: IARObjectCreateAbsoluteSpecs = {
      uid: specs.uid,
      location: objectLocation,
      specs: specs
    };
    this.leplaceMessageTx("add_object_absolute", createSpecs);
  }

  clearObservables() {
    console.log("clear observables");
    this.subscription = ResourceManager.clearSubObj(this.subscription);
    this.observables = ResourceManager.clearSubObj(this.observables);
  }

  clearTimers() {
    console.log("clear timers");
    this.timeouts = ResourceManager.clearTimeoutObj(this.timeouts);
  }

  /**
   * update point of view (not the object position but the camera position)
   * this is used to correlate with the gps position
   * so that the object is scaled accordingly
   */
  requestPovUpdateMetric(x: number, y: number) {
    this.leplaceMessageTx("update_pov", {
      x: x,
      y: y
    });
    this.minimapProvider.translateChart(-x, -y); // translate by changing the origin
  }

  /**
   * create chart for minimap
   */
  createChart() {
    let canvas = this.myChart.nativeElement;
    let ctx = canvas.getContext('2d');
    this.minimapChart = this.minimapProvider.getChart(ctx, this.minimapOptions);
  }
}
