

import { Component, Input, OnInit, ViewChild, ElementRef, AfterViewInit, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { ScriptLoaderService } from 'src/app/services/general/script-loader';


enum ESegmentCount {
  // Segment display types
  SevenSegment = 7,
  FourteenSegment = 14,
  SixteenSegment = 16
}

enum ESegmentStyle {
  // Segment corner types
  SymmetricCorner = 0,
  SquaredCorner = 1,
  RoundedCorner = 2
}


/** 
 * adapted library using
 * 
 * segment-display.js
 * Copyright 2012, Rüdiger Appel
 * http://www.3quarks.com
 * Published under Creative Commons 3.0 License.
 *
 * Date: 2012-02-14
 * Version: 1.0.0
 * 
 * Dokumentation: http://www.3quarks.com/de/Segmentanzeige
 * Documentation: http://www.3quarks.com/en/SegmentDisplay
 */
@Component({
  selector: 'segment-display',
  templateUrl: './segment-display.component.html',
  styleUrls: ['./segment-display.component.scss'],
})
export class SegmentDisplayComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('container', { read: ElementRef, static: false }) container: ElementRef;

  @Input()
  value: string;

  @Input()
  // 1-10
  scale: number;

  displayId: string;
  pattern: string;
  dispValue: string;
  digitHeight: number;
  digitWidth: number;
  digitDistance: number;
  displayAngle: number;
  segmentWidth: number;
  segmentDistance: number;
  segmentCount: number;
  cornerType: number;
  colorOn: string;
  colorOff: string;


  initSize = {
    width: 0,
    height: 0
  };

  constructor(
    public scriptLoader: ScriptLoaderService
  ) {

  }

  ngOnInit() {
    // this.showNumericPreview();
  }

  ngAfterViewInit() {
    this.initContainer(1);
    this.setValue(this.value);
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      // console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'value':
            this.setValue(this.value);
            break;
        }
      }
    }
  }


  initContainer(displayId) {
    this.displayId = displayId;
    this.pattern = '##';
    this.dispValue = '25';
    this.digitHeight = 20;
    this.digitWidth = 12;
    this.digitDistance = 2.5;
    this.displayAngle = 10;
    this.segmentWidth = 2.5;
    this.segmentDistance = 0.5;
    this.segmentCount = ESegmentCount.SevenSegment;
    this.cornerType = ESegmentStyle.RoundedCorner;
    this.colorOn = "#fcd182";
    // this.colorOff = "#f2f2f2";
    this.colorOff = "rgba(0,0,0,0)";




    let canvas: HTMLCanvasElement = this.container.nativeElement;
    this.initSize.height = canvas.height;
    this.initSize.width = canvas.width;


    if (this.scale) {
      console.log("scale: " + this.scale);
      this.digitHeight = Math.floor(this.digitHeight * this.scale / 10);
      this.digitWidth = Math.floor(this.digitWidth * this.scale / 10);
      this.digitDistance = Math.floor(this.digitDistance * this.scale / 10);
      this.segmentWidth = Math.floor(this.segmentWidth * this.scale / 10);
      this.segmentDistance = Math.floor(this.segmentDistance * this.scale / 10);

      this.initSize.height = Math.floor(canvas.height * this.scale / 10);
      this.initSize.width = Math.floor(canvas.width * this.scale / 10);
    }


    function setupCanvas(canvas: HTMLCanvasElement) {
      /**
       * Canvases have two different 'sizes': their DOM width/height and their CSS width/height. 
       * You can increase a canvas' resolution by increasing the DOM size while keeping the CSS size fixed,
       * and then using the .scale() method to scale all of your future draws to the new bigger size. Here's an example:
       */

      let scaleFactor = window.devicePixelRatio || 1;
      scaleFactor *= 2;
      console.log("scale factor: ", scaleFactor);

      // Set up CSS size.
      canvas.style.width = canvas.style.width || canvas.width + 'px';
      canvas.style.height = canvas.style.height || canvas.height + 'px';

      // Resize canvas and scale future draws.
      canvas.width = Math.ceil(canvas.width * scaleFactor);
      canvas.height = Math.ceil(canvas.height * scaleFactor);
      let ctx = canvas.getContext('2d');
      ctx.scale(scaleFactor, scaleFactor);
    }

    setupCanvas(canvas);

  }

  setValue(value: any) {
    // console.log("set value: " + value);
    this.dispValue = "" + value;
    let nchar: number = this.dispValue.length;
    this.pattern = "#".repeat(nchar);
    this.draw();
  }

  draw() {
    let display: HTMLCanvasElement = this.container.nativeElement;
    // console.log(display);
    if (display) {
      let context = display.getContext('2d');
      if (context) {
        // clear canvas
        context.clearRect(0, 0, this.initSize.width, this.initSize.height);
        // compute and check display width
        let width = 0;
        let first = true;
        if (this.pattern) {
          for (let i = 0; i < this.pattern.length; i++) {
            let c = this.pattern.charAt(i).toLowerCase();
            if (c === '#') {
              width += this.digitWidth;
            } else if (c === '.' || c === ':') {
              width += this.segmentWidth;
            } else if (c !== ' ') {
              return;
            }
            width += first ? 0 : this.digitDistance;
            first = false;
          }
        }
        if (width <= 0) {
          return;
        }

        // compute skew factor
        let angle = -1.0 * Math.max(-45.0, Math.min(45.0, this.displayAngle));
        let skew = Math.tan((angle * Math.PI) / 180.0);

        // compute scale factor
        let scale = Math.min(this.initSize.width / (width + Math.abs(skew * this.digitHeight)), this.initSize.height / this.digitHeight);

        // compute display offset
        let offsetX = (this.initSize.width - (width + skew * this.digitHeight) * scale) / 2.0;
        let offsetY = (this.initSize.height - this.digitHeight * scale) / 2.0;

        // context transformation
        context.save();
        context.translate(offsetX, offsetY);
        context.scale(scale, scale);
        context.transform(1, 0, skew, 1, 0, 0);

        // draw segments
        let xPos = 0;
        let size = (this.dispValue) ? this.dispValue.length : 0;
        for (let i = 0; i < this.pattern.length; i++) {
          let mask = this.pattern.charAt(i);
          let value = (i < size) ? this.dispValue.charAt(i).toLowerCase() : ' ';
          xPos += this.drawDigit(context, xPos, mask, value);
        }

        // finish drawing
        context.restore();
      }
    }
  }

  drawDigit(context, xPos, mask, c) {
    switch (mask) {
      case '#':
        let r = Math.sqrt(this.segmentWidth * this.segmentWidth / 2.0);
        let d = Math.sqrt(this.segmentDistance * this.segmentDistance / 2.0);
        let e = d / 2.0;
        let f = (this.segmentWidth - d) * Math.sin((45.0 * Math.PI) / 180.0);
        let g = f / 2.0;
        let h = (this.digitHeight - 3.0 * this.segmentWidth) / 2.0;
        let w = (this.digitWidth - 3.0 * this.segmentWidth) / 2.0;
        let s = this.segmentWidth / 2.0;
        let t = this.digitWidth / 2.0;

        let x = 0;
        let y = 0;

        // draw segment a (a1 and a2 for 16 segments)
        if (this.segmentCount === 16) {
          x = xPos;
          y = 0;
          context.fillStyle = this.getSegmentColor(c, null, '02356789abcdefgiopqrstz@%', null);
          context.beginPath();
          switch (this.cornerType) {
            case ESegmentStyle.SymmetricCorner:
              context.moveTo(x + s + d, y + s);
              context.lineTo(x + this.segmentWidth + d, y);
              break;
            case ESegmentStyle.SquaredCorner:
              context.moveTo(x + s + e, y + s - e);
              context.lineTo(x + this.segmentWidth, y);
              break;
            default:
              context.moveTo(x + this.segmentWidth - f, y + this.segmentWidth - f - d);
              context.quadraticCurveTo(x + this.segmentWidth - g, y, x + this.segmentWidth, y);
          }
          context.lineTo(x + t - d - s, y);
          context.lineTo(x + t - d, y + s);
          context.lineTo(x + t - d - s, y + this.segmentWidth);
          context.lineTo(x + this.segmentWidth + d, y + this.segmentWidth);
          context.fill();

          x = xPos;
          y = 0;

          context.fillStyle = this.getSegmentColor(c, null, '02356789abcdefgiopqrstz@', null);
          context.beginPath();
          context.moveTo(x + this.digitWidth - this.segmentWidth - d, y + this.segmentWidth);
          context.lineTo(x + t + d + s, y + this.segmentWidth);
          context.lineTo(x + t + d, y + s);
          context.lineTo(x + t + d + s, y);
          switch (this.cornerType) {
            case ESegmentStyle.SymmetricCorner:
              context.lineTo(x + this.digitWidth - this.segmentWidth - d, y);
              context.lineTo(x + this.digitWidth - s - d, y + s);
              break;
            case ESegmentStyle.SquaredCorner:
              context.lineTo(x + this.digitWidth - this.segmentWidth, y);
              context.lineTo(x + this.digitWidth - s - e, y + s - e);
              break;
            default:
              context.lineTo(x + this.digitWidth - this.segmentWidth, y);
              context.quadraticCurveTo(x + this.digitWidth - this.segmentWidth + g, y, x + this.digitWidth - this.segmentWidth + f, y + this.segmentWidth - f - d);
          }
          context.fill();

        } else {
          x = xPos;
          y = 0;
          context.fillStyle = this.getSegmentColor(c, '02356789acefp', '02356789abcdefgiopqrstz@', null);
          context.beginPath();
          switch (this.cornerType) {
            case ESegmentStyle.SymmetricCorner:
              context.moveTo(x + s + d, y + s);
              context.lineTo(x + this.segmentWidth + d, y);
              context.lineTo(x + this.digitWidth - this.segmentWidth - d, y);
              context.lineTo(x + this.digitWidth - s - d, y + s);
              break;
            case ESegmentStyle.SquaredCorner:
              context.moveTo(x + s + e, y + s - e);
              context.lineTo(x + this.segmentWidth, y);
              context.lineTo(x + this.digitWidth - this.segmentWidth, y);
              context.lineTo(x + this.digitWidth - s - e, y + s - e);
              break;
            default:
              context.moveTo(x + this.segmentWidth - f, y + this.segmentWidth - f - d);
              context.quadraticCurveTo(x + this.segmentWidth - g, y, x + this.segmentWidth, y);
              context.lineTo(x + this.digitWidth - this.segmentWidth, y);
              context.quadraticCurveTo(x + this.digitWidth - this.segmentWidth + g, y, x + this.digitWidth - this.segmentWidth + f, y + this.segmentWidth - f - d);
          }
          context.lineTo(x + this.digitWidth - this.segmentWidth - d, y + this.segmentWidth);
          context.lineTo(x + this.segmentWidth + d, y + this.segmentWidth);
          context.fill();
        }

        // draw segment b
        x = xPos + this.digitWidth - this.segmentWidth;
        y = 0;
        context.fillStyle = this.getSegmentColor(c, '01234789adhpy', '01234789abdhjmnopqruwy', null);
        context.beginPath();
        switch (this.cornerType) {
          case ESegmentStyle.SymmetricCorner:
            context.moveTo(x + s, y + s + d);
            context.lineTo(x + this.segmentWidth, y + this.segmentWidth + d);
            break;
          case ESegmentStyle.SquaredCorner:
            context.moveTo(x + s + e, y + s + e);
            context.lineTo(x + this.segmentWidth, y + this.segmentWidth);
            break;
          default:
            context.moveTo(x + f + d, y + this.segmentWidth - f);
            context.quadraticCurveTo(x + this.segmentWidth, y + this.segmentWidth - g, x + this.segmentWidth, y + this.segmentWidth);
        }
        context.lineTo(x + this.segmentWidth, y + h + this.segmentWidth - d);
        context.lineTo(x + s, y + h + this.segmentWidth + s - d);
        context.lineTo(x, y + h + this.segmentWidth - d);
        context.lineTo(x, y + this.segmentWidth + d);
        context.fill();

        // draw segment c
        x = xPos + this.digitWidth - this.segmentWidth;
        y = h + this.segmentWidth;
        context.fillStyle = this.getSegmentColor(c, '013456789abdhnouy', '01346789abdghjmnoqsuw@', '%');
        context.beginPath();
        context.moveTo(x, y + this.segmentWidth + d);
        context.lineTo(x + s, y + s + d);
        context.lineTo(x + this.segmentWidth, y + this.segmentWidth + d);
        context.lineTo(x + this.segmentWidth, y + h + this.segmentWidth - d);
        switch (this.cornerType) {
          case ESegmentStyle.SymmetricCorner:
            context.lineTo(x + s, y + h + this.segmentWidth + s - d);
            context.lineTo(x, y + h + this.segmentWidth - d);
            break;
          case ESegmentStyle.SquaredCorner:
            context.lineTo(x + s + e, y + h + this.segmentWidth + s - e);
            context.lineTo(x, y + h + this.segmentWidth - d);
            break;
          default:
            context.quadraticCurveTo(x + this.segmentWidth, y + h + this.segmentWidth + g, x + f + d, y + h + this.segmentWidth + f); //
            context.lineTo(x, y + h + this.segmentWidth - d);
        }
        context.fill();

        // draw segment d (d1 and d2 for 16 segments)
        if (this.segmentCount === 16) {
          x = xPos;
          y = this.digitHeight - this.segmentWidth;
          context.fillStyle = this.getSegmentColor(c, null, '0235689bcdegijloqsuz_=@', null);
          context.beginPath();
          context.moveTo(x + this.segmentWidth + d, y);
          context.lineTo(x + t - d - s, y);
          context.lineTo(x + t - d, y + s);
          context.lineTo(x + t - d - s, y + this.segmentWidth);
          switch (this.cornerType) {
            case ESegmentStyle.SymmetricCorner:
              context.lineTo(x + this.segmentWidth + d, y + this.segmentWidth);
              context.lineTo(x + s + d, y + s);
              break;
            case ESegmentStyle.SquaredCorner:
              context.lineTo(x + this.segmentWidth, y + this.segmentWidth);
              context.lineTo(x + s + e, y + s + e);
              break;
            default:
              context.lineTo(x + this.segmentWidth, y + this.segmentWidth);
              context.quadraticCurveTo(x + this.segmentWidth - g, y + this.segmentWidth, x + this.segmentWidth - f, y + f + d);
              context.lineTo(x + this.segmentWidth - f, y + f + d);
          }
          context.fill();

          x = xPos;
          y = this.digitHeight - this.segmentWidth;
          context.fillStyle = this.getSegmentColor(c, null, '0235689bcdegijloqsuz_=@', '%');
          context.beginPath();
          context.moveTo(x + t + d + s, y + this.segmentWidth);
          context.lineTo(x + t + d, y + s);
          context.lineTo(x + t + d + s, y);
          context.lineTo(x + this.digitWidth - this.segmentWidth - d, y);
          switch (this.cornerType) {
            case ESegmentStyle.SymmetricCorner:
              context.lineTo(x + this.digitWidth - s - d, y + s);
              context.lineTo(x + this.digitWidth - this.segmentWidth - d, y + this.segmentWidth);
              break;
            case ESegmentStyle.SquaredCorner:
              context.lineTo(x + this.digitWidth - s - e, y + s + e);
              context.lineTo(x + this.digitWidth - this.segmentWidth, y + this.segmentWidth);
              break;
            default:
              context.lineTo(x + this.digitWidth - this.segmentWidth + f, y + f + d);
              context.quadraticCurveTo(x + this.digitWidth - this.segmentWidth + g, y + this.segmentWidth, x + this.digitWidth - this.segmentWidth, y + this.segmentWidth);
          }
          context.fill();
        } else {
          x = xPos;
          y = this.digitHeight - this.segmentWidth;
          context.fillStyle = this.getSegmentColor(c, '0235689bcdelotuy_', '0235689bcdegijloqsuz_=@', null);
          context.beginPath();
          context.moveTo(x + this.segmentWidth + d, y);
          context.lineTo(x + this.digitWidth - this.segmentWidth - d, y);
          switch (this.cornerType) {
            case ESegmentStyle.SymmetricCorner:
              context.lineTo(x + this.digitWidth - s - d, y + s);
              context.lineTo(x + this.digitWidth - this.segmentWidth - d, y + this.segmentWidth);
              context.lineTo(x + this.segmentWidth + d, y + this.segmentWidth);
              context.lineTo(x + s + d, y + s);
              break;
            case ESegmentStyle.SquaredCorner:
              context.lineTo(x + this.digitWidth - s - e, y + s + e);
              context.lineTo(x + this.digitWidth - this.segmentWidth, y + this.segmentWidth);
              context.lineTo(x + this.segmentWidth, y + this.segmentWidth);
              context.lineTo(x + s + e, y + s + e);
              break;
            default:
              context.lineTo(x + this.digitWidth - this.segmentWidth + f, y + f + d);
              context.quadraticCurveTo(x + this.digitWidth - this.segmentWidth + g, y + this.segmentWidth, x + this.digitWidth - this.segmentWidth, y + this.segmentWidth);
              context.lineTo(x + this.segmentWidth, y + this.segmentWidth);
              context.quadraticCurveTo(x + this.segmentWidth - g, y + this.segmentWidth, x + this.segmentWidth - f, y + f + d);
              context.lineTo(x + this.segmentWidth - f, y + f + d);
          }
          context.fill();
        }

        // draw segment e
        x = xPos;
        y = h + this.segmentWidth;
        context.fillStyle = this.getSegmentColor(c, '0268abcdefhlnoprtu', '0268acefghjklmnopqruvw@', null);
        context.beginPath();
        context.moveTo(x, y + this.segmentWidth + d);
        context.lineTo(x + s, y + s + d);
        context.lineTo(x + this.segmentWidth, y + this.segmentWidth + d);
        context.lineTo(x + this.segmentWidth, y + h + this.segmentWidth - d);
        switch (this.cornerType) {
          case ESegmentStyle.SymmetricCorner:
            context.lineTo(x + s, y + h + this.segmentWidth + s - d);
            context.lineTo(x, y + h + this.segmentWidth - d);
            break;
          case ESegmentStyle.SquaredCorner:
            context.lineTo(x + s - e, y + h + this.segmentWidth + s - d + e);
            context.lineTo(x, y + h + this.segmentWidth);
            break;
          default:
            context.lineTo(x + this.segmentWidth - f - d, y + h + this.segmentWidth + f);
            context.quadraticCurveTo(x, y + h + this.segmentWidth + g, x, y + h + this.segmentWidth);
        }
        context.fill();

        // draw segment f
        x = xPos;
        y = 0;
        context.fillStyle = this.getSegmentColor(c, '045689abcefhlpty', '045689acefghklmnopqrsuvwy@', '%');
        context.beginPath();
        context.moveTo(x + this.segmentWidth, y + this.segmentWidth + d);
        context.lineTo(x + this.segmentWidth, y + h + this.segmentWidth - d);
        context.lineTo(x + s, y + h + this.segmentWidth + s - d);
        context.lineTo(x, y + h + this.segmentWidth - d);
        switch (this.cornerType) {
          case ESegmentStyle.SymmetricCorner:
            context.lineTo(x, y + this.segmentWidth + d);
            context.lineTo(x + s, y + s + d);
            break;
          case ESegmentStyle.SquaredCorner:
            context.lineTo(x, y + this.segmentWidth);
            context.lineTo(x + s - e, y + s + e);
            break;
          default:
            context.lineTo(x, y + this.segmentWidth);
            context.quadraticCurveTo(x, y + this.segmentWidth - g, x + this.segmentWidth - f - d, y + this.segmentWidth - f);
            context.lineTo(x + this.segmentWidth - f - d, y + this.segmentWidth - f);
        }
        context.fill();

        // draw segment g for 7 segments
        if (this.segmentCount === 7) {
          x = xPos;
          y = (this.digitHeight - this.segmentWidth) / 2.0;
          context.fillStyle = this.getSegmentColor(c, '2345689abdefhnoprty-=', null, null);
          context.beginPath();
          context.moveTo(x + s + d, y + s);
          context.lineTo(x + this.segmentWidth + d, y);
          context.lineTo(x + this.digitWidth - this.segmentWidth - d, y);
          context.lineTo(x + this.digitWidth - s - d, y + s);
          context.lineTo(x + this.digitWidth - this.segmentWidth - d, y + this.segmentWidth);
          context.lineTo(x + this.segmentWidth + d, y + this.segmentWidth);
          context.fill();
        }

        // draw inner segments for the fourteen- and sixteen-segment-display
        if (this.segmentCount !== 7) {
          // draw segment g1
          x = xPos;
          y = (this.digitHeight - this.segmentWidth) / 2.0;
          context.fillStyle = this.getSegmentColor(c, null, '2345689aefhkprsy-+*=', '%');
          context.beginPath();
          context.moveTo(x + s + d, y + s);
          context.lineTo(x + this.segmentWidth + d, y);
          context.lineTo(x + t - d - s, y);
          context.lineTo(x + t - d, y + s);
          context.lineTo(x + t - d - s, y + this.segmentWidth);
          context.lineTo(x + this.segmentWidth + d, y + this.segmentWidth);
          context.fill();

          // draw segment g2
          x = xPos;
          y = (this.digitHeight - this.segmentWidth) / 2.0;
          context.fillStyle = this.getSegmentColor(c, null, '234689abefghprsy-+*=@', '%');
          context.beginPath();
          context.moveTo(x + t + d, y + s);
          context.lineTo(x + t + d + s, y);
          context.lineTo(x + this.digitWidth - this.segmentWidth - d, y);
          context.lineTo(x + this.digitWidth - s - d, y + s);
          context.lineTo(x + this.digitWidth - this.segmentWidth - d, y + this.segmentWidth);
          context.lineTo(x + t + d + s, y + this.segmentWidth);
          context.fill();

          // draw segment j 
          x = xPos + t - s;
          y = 0;
          context.fillStyle = this.getSegmentColor(c, null, 'bdit+*', '%');
          context.beginPath();
          if (this.segmentCount === 14) {
            context.moveTo(x, y + this.segmentWidth + this.segmentDistance);
            context.lineTo(x + this.segmentWidth, y + this.segmentWidth + this.segmentDistance);
          } else {
            context.moveTo(x, y + this.segmentWidth + d);
            context.lineTo(x + s, y + s + d);
            context.lineTo(x + this.segmentWidth, y + this.segmentWidth + d);
          }
          context.lineTo(x + this.segmentWidth, y + h + this.segmentWidth - d);
          context.lineTo(x + s, y + h + this.segmentWidth + s - d);
          context.lineTo(x, y + h + this.segmentWidth - d);
          context.fill();

          // draw segment m
          x = xPos + t - s;
          y = this.digitHeight;
          context.fillStyle = this.getSegmentColor(c, null, 'bdity+*@', '%');
          context.beginPath();
          if (this.segmentCount === 14) {
            context.moveTo(x, y - this.segmentWidth - this.segmentDistance);
            context.lineTo(x + this.segmentWidth, y - this.segmentWidth - this.segmentDistance);
          } else {
            context.moveTo(x, y - this.segmentWidth - d);
            context.lineTo(x + s, y - s - d);
            context.lineTo(x + this.segmentWidth, y - this.segmentWidth - d);
          }
          context.lineTo(x + this.segmentWidth, y - h - this.segmentWidth + d);
          context.lineTo(x + s, y - h - this.segmentWidth - s + d);
          context.lineTo(x, y - h - this.segmentWidth + d);
          context.fill();

          // draw segment h
          x = xPos + this.segmentWidth;
          y = this.segmentWidth;
          context.fillStyle = this.getSegmentColor(c, null, 'mnx\\*', null);
          context.beginPath();
          context.moveTo(x + this.segmentDistance, y + this.segmentDistance);
          context.lineTo(x + this.segmentDistance + r, y + this.segmentDistance);
          context.lineTo(x + w - this.segmentDistance, y + h - this.segmentDistance - r);
          context.lineTo(x + w - this.segmentDistance, y + h - this.segmentDistance);
          context.lineTo(x + w - this.segmentDistance - r, y + h - this.segmentDistance);
          context.lineTo(x + this.segmentDistance, y + this.segmentDistance + r);
          context.fill();

          // draw segment k
          x = xPos + w + 2.0 * this.segmentWidth;
          y = this.segmentWidth;
          context.fillStyle = this.getSegmentColor(c, null, '0kmvxz/*', '%');
          context.beginPath();
          context.moveTo(x + w - this.segmentDistance, y + this.segmentDistance);
          context.lineTo(x + w - this.segmentDistance, y + this.segmentDistance + r);
          context.lineTo(x + this.segmentDistance + r, y + h - this.segmentDistance);
          context.lineTo(x + this.segmentDistance, y + h - this.segmentDistance);
          context.lineTo(x + this.segmentDistance, y + h - this.segmentDistance - r);
          context.lineTo(x + w - this.segmentDistance - r, y + this.segmentDistance);
          context.fill();

          // draw segment l
          x = xPos + w + 2.0 * this.segmentWidth;
          y = h + 2.0 * this.segmentWidth;
          context.fillStyle = this.getSegmentColor(c, null, '5knqrwx\\*', null);
          context.beginPath();
          context.moveTo(x + this.segmentDistance, y + this.segmentDistance);
          context.lineTo(x + this.segmentDistance + r, y + this.segmentDistance);
          context.lineTo(x + w - this.segmentDistance, y + h - this.segmentDistance - r);
          context.lineTo(x + w - this.segmentDistance, y + h - this.segmentDistance);
          context.lineTo(x + w - this.segmentDistance - r, y + h - this.segmentDistance);
          context.lineTo(x + this.segmentDistance, y + this.segmentDistance + r);
          context.fill();

          // draw segment n
          x = xPos + this.segmentWidth;
          y = h + 2.0 * this.segmentWidth;
          context.fillStyle = this.getSegmentColor(c, null, '0vwxz/*', '%');
          context.beginPath();
          context.moveTo(x + w - this.segmentDistance, y + this.segmentDistance);
          context.lineTo(x + w - this.segmentDistance, y + this.segmentDistance + r);
          context.lineTo(x + this.segmentDistance + r, y + h - this.segmentDistance);
          context.lineTo(x + this.segmentDistance, y + h - this.segmentDistance);
          context.lineTo(x + this.segmentDistance, y + h - this.segmentDistance - r);
          context.lineTo(x + w - this.segmentDistance - r, y + this.segmentDistance);
          context.fill();
        }

        return this.digitDistance + this.digitWidth;

      case '.':
        context.fillStyle = (c === '#') || (c === '.') ? this.colorOn : this.colorOff;
        this.drawPoint(context, xPos, this.digitHeight - this.segmentWidth, this.segmentWidth);
        return this.digitDistance + this.segmentWidth;

      case ':':
        context.fillStyle = (c === '#') || (c === ':') ? this.colorOn : this.colorOff;
        y = (this.digitHeight - this.segmentWidth) / 2.0 - this.segmentWidth;
        this.drawPoint(context, xPos, y, this.segmentWidth);
        this.drawPoint(context, xPos, y + 2.0 * this.segmentWidth, this.segmentWidth);
        return this.digitDistance + this.segmentWidth;

      default:
        return this.digitDistance;
    }
  }

  drawPoint(context, x1, y1, size) {
    let x2 = x1 + size;
    let y2 = y1 + size;
    let d = size / 4.0;

    context.beginPath();
    context.moveTo(x2 - d, y1);
    context.quadraticCurveTo(x2, y1, x2, y1 + d);
    context.lineTo(x2, y2 - d);
    context.quadraticCurveTo(x2, y2, x2 - d, y2);
    context.lineTo(x1 + d, y2);
    context.quadraticCurveTo(x1, y2, x1, y2 - d);
    context.lineTo(x1, y1 + d);
    context.quadraticCurveTo(x1, y1, x1 + d, y1);
    context.fill();
  }

  getSegmentColor(c, charSet7, charSet14, charSet16) {
    if (c === '#') {
      return this.colorOn;
    } else {
      switch (this.segmentCount) {
        case 7: return (charSet7.indexOf(c) === -1) ? this.colorOff : this.colorOn;
        case 14: return (charSet14.indexOf(c) === -1) ? this.colorOff : this.colorOn;
        case 16: let pattern = charSet14 + (charSet16 === undefined ? '' : charSet16);
                 return (pattern.indexOf(c) === -1) ? this.colorOff : this.colorOn;
        default: return this.colorOff;
      }
    }
  }

}
