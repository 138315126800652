<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" [title]="title" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back(false)"
    rightButton="settings" [rightIcon]="true" (rightClick)="presentPopover()" [loading]="!loaded">
  </leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <div class="view-frame theme-background" fxLayout="column">

    <div *ngIf="!hasGroups" class="padding-s center-text">
      <span class="center-text info-text-m bold text-color-primary">No registered groups</span>
    </div>

    <ion-row class="padding-s theme-border-bottom">
      <ion-col size="12" class="text-center">
        <span class="info-text-m bold text-color-primary">{{eventName}}</span>
      </ion-col>
    </ion-row>

    <!-- <ion-row class="padding-horizontal-s">
      <ion-col size="12">
        <span class="info-text-m bold text-color-primary">{{selectedGroupName}}</span>
      </ion-col>
    </ion-row> -->

    <!-- (swipe)="swipeEvent($event)" -->
    <div class="margin-top-events-join scroll-type-frame-list bottom-events-join">
      <ion-list [@showState]="showState">
        <ion-item-group>
          <!-- <ion-item-divider class="list-item-divider">
            <ion-label class="info-text-s bold text-color-primary">
              {{selectedGroupName}}
            </ion-label>
          </ion-item-divider> -->
          <ion-item class="list-item list-item-padding transparent-bg" *ngFor="let g of groups" #slidingItem
            [hidden]="false">
            <!-- [locked]="alreadyRegistered" -->
            <group-card class="full-w" [group]="g" (select)="selectGroup(g)" [selected]="checkSelected(g)"
              [locked]="alreadyRegistered" [noBorder]="true">
            </group-card>
          </ion-item>
        </ion-item-group>
      </ion-list>
    </div>

    <div fxFlex></div>

    <div class="theme-border-top">
      <ion-grid class="full-w padding-bottom-xs">
        <ion-row>
          <ion-col size="4">
            <ion-button [class]="viewContainer.main.buttons.quit.class"
              [disabled]="!loaded || viewContainer.main.buttons.quit.disabled"
              (click)="viewContainer.main.buttons.quit.callback()">
              <icon-wrapper [custom]="viewContainer.main.buttons.quit.customIcon" slot="icon-only"
                [icon]="viewContainer.main.buttons.quit.icon"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="4">
            <ion-button [class]="viewContainer.main.buttons.join.class"
              [disabled]="!loaded || viewContainer.main.buttons.join.disabled"
              (click)="viewContainer.main.buttons.join.callback()">
              <icon-wrapper [custom]="viewContainer.main.buttons.join.customIcon" slot="icon-only"
                [icon]="viewContainer.main.buttons.join.icon"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="4">
            <ion-button [class]="viewContainer.main.buttons.proceed.class"
              [disabled]="!loaded || viewContainer.main.buttons.proceed.disabled"
              (click)="viewContainer.main.buttons.proceed.callback()">
              <icon-wrapper [custom]="viewContainer.main.buttons.proceed.customIcon" slot="icon-only"
                [icon]="viewContainer.main.buttons.proceed.icon"></icon-wrapper>
            </ion-button>
          </ion-col>

        </ion-row>
      </ion-grid>
    </div>
  </div>
</ion-content>