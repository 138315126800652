export enum ENavParamsResources {
    storyCategories = 1,
    storyList = 2,
    home = 3,
    gmap = 4,
    account = 5,

    inventory = 6,
    newsfeed = 7,
    settings = 8,
    extras = 9,
    events = 10,


    login = 11,
    leaderboard = 12,
    leaderboardExt = 13,
    storyHome = 14,

    ar = 15,
    gmapDetail = 16,

    // mp home + mp groups home (dual views)
    mpHome = 17,
    mpJoin = 18,
    mpCreate = 19,
    mpGroupMembers = 20,
    mpChat = 21,
    mpGroupList = 22,
    treasureHome = 23,
    accountDetails = 24,
    teamStats = 25,


    support = 26,
    supportFaq = 27,
    supportChat = 28,


    mpOptions = 29
}
