import { Messages } from "../def/app/messages";

export class ErrorMessage {
    static parse(err: any, defaultMessage: string = null): string {
        if (err) {
            if (err.message) {
                // let finalMsg: string = "(" + defaultMessage + ") ";
                // if (defaultMessage) {
                //     finalMsg += err.message;
                // } else {
                //     finalMsg = err.message;
                // }
                // return finalMsg;
                // return err.message + " / " + defaultMessage;
                return err.message;
            } else {
                // return "";
                // return err;
                if (typeof err === 'string') {
                    return err;
                } else {
                    // return JSON.stringify(err);
                    // unknown error
                    if (defaultMessage != null) {
                        return defaultMessage;
                    } else {
                        return Messages.msg.error.after.sub;
                    }
                }
            }
        } else {
            return "";
        }
    }

    static parseAdd(err: any, appendMessage: string = null): string {
        let parsed: string = ErrorMessage.parse(err, "");
        if (parsed != "") {
            // has parsed message
            if (appendMessage) {
                parsed += "/" + appendMessage;
            }
        } else {
            // no message parsed, just append
            parsed = appendMessage ? appendMessage : "";
        }
        return parsed;
    }

    /**
     * using HTML formatting
     * @param err error type/string
     * @param appendMessage HTML
     */
    static parseAddBefore(err: any, appendMessage: string = null): string {
        let parsed: string = ErrorMessage.parse(err, "");
        if (parsed != "") {
            // has parsed message
            if (appendMessage) {
                parsed = appendMessage + "<p>Error reference: " + parsed + "</p>";
            }
        } else {
            // no message parsed, just append
            parsed = appendMessage ? appendMessage : "";
        }
        return parsed;
    }
}
