import { Injectable } from "@angular/core";
import { ResourceManager } from 'src/app/classes/general/resource-manager';

export enum ETimeoutQueue {
    mpMessageSync = "mpMessageSync",
    locationWatchdogThrottle = "locationWatchdogThrottle",
    mpMessageScrollThrottle = "mpMessageScrollThrottle"
}

@Injectable({
    providedIn: 'root'
})
export class TimeoutQueueService {
    qTimeouts: { [key: string]: any } = {};

    constructor(

    ) {
        console.log("timeout queue service created");
    }

    /**
     * only take LAST item as callback
     * using timeout, prevent flooding events where only the last item of a kind is required to sync
     * delays first callback too
     * @param qid 
     * @param callback 
     * @param timeout 
     */
    debounceTakeLastItemWithTimeout(qid: string, callback: () => any, timeout: number): Promise<boolean> {
        return new Promise((resolve) => {
            this.qTimeouts[qid] = ResourceManager.clearTimeout(this.qTimeouts[qid]);
            this.qTimeouts[qid] = setTimeout(() => {
                console.log("debounce take last item with timeout: " + qid);
                callback();
                resolve(true);
            }, timeout);
        });
    }

    /**
    * only take FIRST item as callback
    * using timeout, prevent flooding events where only the last item of a kind is required to sync
    * no delay for first callback
    * @param qid 
    * @param callback 
    * @param timeout 
    */
    debounceTakeFirstItemWithTimeout(qid: string, callback: () => any, timeout: number): Promise<boolean> {
        return new Promise((resolve) => {
            if (this.qTimeouts[qid] != null) {
                resolve(false);
            } else {
                console.log("debounce take first item with timeout: " + qid);
                callback();
                this.qTimeouts[qid] = setTimeout(() => {
                    this.qTimeouts[qid] = ResourceManager.clearTimeout(this.qTimeouts[qid]);
                }, timeout);
                resolve(true);
            }
        });
    }
}




