
import { EActivityCodes, IActivity } from "../def/core/activity";

import { MathUtils } from "../general/math";

import { IGameItem } from "../def/items/game-item";
import { ILocationContainer, ILocationItemsDef } from "../def/places/backend-location";
import { EStartCodes } from "../def/nav-params/story";
import { ETreasureType } from "../def/items/treasures";
import { GeneralUtils } from "./general";
import { ICustomParamForActivity, ECustomParamScope, ECustomParamScope2, ECustomParamMode } from "../def/core/custom-param";
import { IStory } from "../def/core/story";
import { IStatDef } from "../def/user/stats";
import { TimeUtils } from '../general/time';
import { IGenericActivityStats } from "../def/core/activity-stats";


/**
 * the max length of the message for different cases
 */
export enum EMessageTrim {
    storyNameTitle = 20,
    titleModalLarge = 20,
    itemNameCard = 15,
    itemNameCardSmall = 10,
    leaderboardUsernameCard = 20,
    mpLeaderboardUsernameCard = 10,
    mpUserStatusCard = 40,
    placeNameCard = 25,
    locationInfoCard = 30,
    xpMessageHud = 41,
    markerAuxCaptionCanvas = 25,
    markerCaptionCanvas = 40,
    descriptionPreview = 500,
    newsPreview = 350,
    userInput = 200
}

export enum EMessageTrimLines {
    descriptionPreview = 3
}

export enum EShareMsgFeatures {
    activityFinished = 1,
    storyFinished = 2,
    itemFound = 3,
    achievementUnlocked = 5
}

export interface IShareMsgParams {
    photo?: {
        display: string,
        share: string
    };
    place?: {
        name: string,
        place?: ILocationContainer
    };
    story?: IShareMsgParamsStory;
    xp: number;
    scaled?: boolean;
    items?: IShareMsgParamsItems;
    activityStats?: IShareMsgParamsActivity;
    activityFinish?: IActivity;

    customParams?: ICustomParamForActivity[];
    newAchievements?: {
        items: IGameItem[],
        categoryName: string
    };
    placeItems?: {
        availableItems?: ILocationItemsDef
    };
    treasureType?: number;
    actionButtons: {
        watchAd: boolean,
        scanQR: boolean,
        share: boolean
    }
}

export interface IShareMsgParamsActivity {
    photoUrl: string,
    videoUrl: string,
    code: number,
    name: string,
    finishedDescription: string,
    baseCode: number,
    standardRewardCap: number,
    progress?: IShareActivityProgress,
    statsList?: IStatDef[],
    stats?: IGenericActivityStats,
    qrCodeTarget?: string,
    qrMode?: number
};

export interface IShareMsgParamsItems {
    /**
     * these can be any items
     */
    collected: number,
    /**
    * this can be from any items
    */
    collectedValue: number,
    /**
     * reward is in LP
     */
    reward: number,
    /**
     * the value of an item (LP)
     */
    value: number
};

export interface IShareMsgParamsStory {
    worldMap: boolean,
    id: number,
    name: string,
    rewardCoins: number,
    progress?: {
        finished: boolean,
        percentFinished: number,
        alreadyFinished: boolean
    },
    xpScaleFactor?: number,
    story?: IStory
}


export interface IShareActivityProgress {
    target: number[];
    current: number[];
}

export interface IShareMsg {
    share: string;
    aux: string;
    auxHTML: string;
}

export interface IShareStoryParams {
    coinsCollected: number;
    story: string;
    finished: boolean;
    percentFinished: number;
    rewardCoins?: number;
}


export class MessageUtils {

    static getActivityItemsDescription(context: number, customParamScope: number, isNav: boolean) {
        let descriptionItems: string = "";
        switch (context) {
            case ECustomParamScope.story:
                descriptionItems = "Game ";
                break;
            case ECustomParamScope.challenge:
            default:
                descriptionItems = "Game ";
                break;
        }
        switch (customParamScope) {
            case ECustomParamScope2.items:
                descriptionItems += "items";
                break;
            case ECustomParamScope2.treasures:
            default:
                descriptionItems += "items";
                break;
        }
        if (isNav) {
            descriptionItems += " along the way";
        }
        return descriptionItems;
    }

    static getStartButtonText(code: number) {
        let text: string = "Start";
        switch (code) {
            case EStartCodes.start:
                text = "Start";
                break;
            case EStartCodes.continue:
                text = "Continue";
                break;
            case EStartCodes.restart:
                text = "Restart";
                break;
        }
        return text;
    }

    static buildMessage(featureCode: number, params: IShareMsgParams) {
        let shareMessage: string = "";
        let auxHTML: string = "";
        let auxMessage: string = "";
        let collectedItems: number = 0;
        let rewardLP: number = 0;
        let collectedLP: number = 0;

        let getItemsMessage = (collectedItems: number, collectedLP: number, rewardLP: number) => {
            let msg: string = "";
            let includeItemsCount: boolean = false;
            if (collectedLP !== collectedItems) {
                includeItemsCount = true;
                msg += collectedItems + ((collectedItems > 1 || collectedItems === 0) ? " items (" : " item (");
            }
            if (rewardLP) {
                msg += collectedLP + " + " + rewardLP + " LP";
            } else {
                msg += collectedLP + " LP";
            }
            if (includeItemsCount) {
                msg += ")";
            }
            return msg;
        };

        let getXpMessage = (xp: number) => {
            let msg: string = "";
            if (xp && xp > 0) {
                msg = "+" + xp + " XP";
            }
            return msg;
        };

        if (params.items) {
            if (!params.items.collected) {
                params.items.collected = 0;
            }
            if (!params.items.reward) {
                params.items.reward = 0;
            }

            collectedItems = params.items.collected;
            rewardLP = params.items.reward;
            collectedLP = collectedItems * params.items.value;

            if (collectedItems || rewardLP) {
                auxHTML = "<p>You have collected " + getItemsMessage(collectedItems, collectedLP, rewardLP) + "</p>";
            }
        }

        // auxHTML += getXpMessage(params.xp);

        if (params.actionButtons.watchAd) {
            auxHTML += "<p>Watch a video ad to increase your reward</p>";
        }


        let contextName: string = "";
        if (params.story) {
            contextName = params.story.name;
            if (!params.story.worldMap) {
                contextName += " story";
            }
        }

        switch (featureCode) {
            case EShareMsgFeatures.activityFinished:
                // add description at the beginning
                auxHTML = "<p>Challenge completed (" + params.activityStats.name + ")</p>" + params.activityStats.finishedDescription + auxHTML;
                shareMessage = "Completed a challenge in " + contextName;
                console.log("message utils activity base code: " + params.activityStats.baseCode);

                // create share message
                switch (params.activityStats.baseCode) {
                    case EActivityCodes.explore:
                        shareMessage = "Explored the surroundings in " + contextName;
                        if (collectedItems > 0) {
                            if (collectedItems > 1) {
                                shareMessage = "Collected " + collectedItems + " items in " + contextName;
                            } else {
                                shareMessage = "Collected " + collectedItems + " item in " + contextName;
                            }
                        }
                        break;
                    case EActivityCodes.escape:
                        shareMessage = "Escaped the followers in " + contextName;
                        break;
                    case EActivityCodes.pursue:
                        shareMessage = "Caught the suspects in " + contextName;
                        break;
                    case EActivityCodes.find:
                        shareMessage = "Found " + params.place.name + " in " + contextName;
                        auxMessage = "Place found";
                        break;
                    case EActivityCodes.walk:
                        shareMessage = "Finished a " + MathUtils.formatDistanceDisp(params.activityStats.progress.target[0]).disp + " long walk in " + contextName;
                        break;
                    case EActivityCodes.run:
                        shareMessage = "Finished a " + MathUtils.formatDistanceDisp(params.activityStats.progress.target[0]).disp + " run ";
                        if (params.activityStats.progress.current[1]) {
                            shareMessage += "@" + MathUtils.formatSpeedDisp(params.activityStats.progress.current[1]).disp;
                        }
                        shareMessage += " in " + contextName;
                        break;
                    case EActivityCodes.enduranceRun:
                        shareMessage = "Finished a " + TimeUtils.formatTimeFromSeconds2(params.activityStats.progress.target[2], true) + " endurance run ";
                        if (params.activityStats.progress.current[1]) {
                            shareMessage += " of " + MathUtils.formatDistanceDisp(params.activityStats.progress.current[0]).disp;
                        }
                        shareMessage += " in " + contextName;
                        break;
                    case EActivityCodes.dance:
                        shareMessage = "Unlocked " + params.activityStats.progress.current[0] + " beats ";
                        shareMessage += " in " + contextName;
                        break;
                    case EActivityCodes.decibel:
                        shareMessage = "Listened to live music ";
                        shareMessage += " in " + contextName;
                        break;
                    case EActivityCodes.photo:
                        if (params.customParams) {
                            shareMessage = "Photographed (";
                            let validCparams: ICustomParamForActivity[] = params.customParams.filter(cparam => cparam.mode !== ECustomParamMode.hidden);
                            if (validCparams.length === 0) {
                                shareMessage += "Quest Item";
                            } else {
                                for (let i = 0; i < validCparams.length; i++) {
                                    shareMessage += validCparams[i].name;
                                    if (i < validCparams.length - 1) {
                                        shareMessage += ", ";
                                    }
                                }
                            }
                            shareMessage += ") in " + contextName;
                        }
                        break;
                    case EActivityCodes.screenshotAR:
                        if (params.customParams) {
                            shareMessage = "Found AR objects: ";
                            for (let i = 0; i < params.customParams.length; i++) {
                                shareMessage += params.customParams[i].name;
                                if (i < params.customParams.length - 1) {
                                    shareMessage += ", ";
                                }
                            }
                            shareMessage += " in " + contextName;
                        }
                        break;
                    default:
                        // keep default message
                        break;
                }

                break;
            case EShareMsgFeatures.storyFinished:
                if (params.story.progress.finished) {
                    // add description at the beginning
                    let startHTML = "<p>Story finished</p>";
                    // if (params.story.progress.alreadyFinished) {
                    //     startHTML += "<p>Progress already registered</p>";
                    // }
                    auxHTML = startHTML + auxHTML;
                    shareMessage = "Finished " + params.story.name + " story";
                } else {
                    auxHTML = "<p>Story " + params.story.progress.percentFinished + "% complete</p><p>You may play again later to complete the remaining challenges</p>" + auxHTML;
                }
                break;
            case EShareMsgFeatures.itemFound:
                // add description at the beginning
                auxHTML = "<p>Treasure opened</p>" + auxHTML;
                if (params.treasureType === ETreasureType.treasure) {
                    if (params.place.name) {
                        shareMessage = "Found a hidden stash of " + collectedLP + " LP @" + params.place.name;
                    } else {
                        shareMessage = "Found a hidden stash of " + collectedLP + " LP";
                    }
                }
                break;
            case EShareMsgFeatures.achievementUnlocked:
                if (params.newAchievements.items && (params.newAchievements.items.length > 0)) {
                    let items: IGameItem[] = params.newAchievements.items;

                    let categories: string[] = [];
                    for (let i = 0; i < items.length; i++) {
                        let item: IGameItem = items[i];
                        if (item.itemCategory) {
                            categories.push(item.itemCategory.name);
                        }
                    }
                    categories = GeneralUtils.getUniqueValues(categories);

                    let categoryName: string = "";

                    if (categories.length > 0) {
                        auxHTML = "<p>Unlocked: " + categories.join(", ") + "</p>";
                        categoryName = " " + categories[0];
                    }

                    if (categories.length > 1) {
                        categoryName = "";
                    }

                    shareMessage = "Unlocked" + categoryName + ": ";
                    for (let i = 0; i < items.length; i++) {
                        shareMessage += items[i].name;
                        if ((items[i].validCount != null) && (items[i].validCount > 1)) {
                            shareMessage += " (" + items[i].validCount + ")";
                        }
                        if (i < items.length - 1) {
                            shareMessage += ", ";
                        }
                    }
                }
                break;
            default:
                break;
        }

        let res: IShareMsg = {
            share: shareMessage,
            aux: auxMessage,
            auxHTML: auxHTML
        };
        return res;
    }

    static getDefaultShareMsgParams() {
        let params: IShareMsgParams = {
            place: {
                name: null,
                place: null
            },
            story: {
                name: null,
                id: null,
                worldMap: false,
                rewardCoins: 0,
                progress: {
                    finished: false,
                    percentFinished: 0,
                    alreadyFinished: false
                }
            },
            items: {
                collected: 0,
                collectedValue: 0,
                reward: 0,
                value: 0
            },
            xp: 0,
            activityStats: {
                photoUrl: null,
                videoUrl: null,
                code: 0,
                baseCode: 0,
                name: "",
                finishedDescription: "",
                standardRewardCap: 0,
                progress: {
                    target: [],
                    current: []
                }
            },
            newAchievements: {
                items: [],
                categoryName: "new achievements"
            },
            placeItems: {
                availableItems: null
            },
            treasureType: ETreasureType.treasure,
            actionButtons: {
                watchAd: false,
                scanQR: false,
                share: false
            }
        };
        return params;
    }
}
