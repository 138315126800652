
import { Injectable } from '@angular/core';
import { GenericDataService } from '../general/data/generic';
import { ISupportCategory, IFaqCategory } from 'src/app/classes/def/support/support';

export enum EFAQCategories {
    stories = 15,
    storiesVirtual = 16
}

@Injectable({
    providedIn: 'root'
})
export class SupportDataService {

    categories: ISupportCategory[] = null;
    faqs: IFaqCategory[] = null;

    constructor(
        public generic: GenericDataService
    ) {
        console.log("support data service created");
    }

    init() {

    }

    getCategories(reload: boolean): Promise<ISupportCategory[]> {
        let promise: Promise<ISupportCategory[]> = new Promise((resolve, reject) => {
            if (this.categories && !reload) {
                resolve(this.categories);
                return;
            }

            this.generic.genericGetStandardWData("/support/get-categories", null).then((data: ISupportCategory[]) => {
                this.categories = data;
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    getFaq(reload: boolean): Promise<IFaqCategory[]> {
        let promise: Promise<IFaqCategory[]> = new Promise((resolve, reject) => {
            if (this.faqs && !reload) {
                resolve(this.faqs);
                return;
            }
            this.generic.genericGetStandardWData("/support/get-faq", null).then((data: IFaqCategory[]) => {
                this.faqs = data;
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    getFaqV2(categories: number[]): Promise<IFaqCategory[]> {
        let promise: Promise<IFaqCategory[]> = new Promise((resolve, reject) => {
            this.generic.genericPostStandardWData("/support/get-faq", {
                categories: categories
            }).then((data: IFaqCategory[]) => {
                this.faqs = data;
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    sendFeedback(description: string): Promise<boolean> {
        return this.generic.genericPostStandardWData("/support/send-feedback", {
            description: description
        });
    }

}
