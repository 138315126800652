import { ISoundBin } from '../media/sound';

export interface ISignalProcessingOptions {
    filter: number;
    bpm: boolean;
    level: boolean;
    beatZone: boolean;
    fft?: {
        fftSamplingTime: number,
        soundBins: ISoundBin[],
        alphaScale: number
    };
}

export enum ESignalProcessingFilter {
    nofilter = 0,
    lowPass = 1,
    lowPassDual = 2,
    envelope = 3
}

export interface ISignalProcessingDataContainer {
    [key: string]: ISignalData;
}

export interface ISignalProcessingParamsContainer {
    [key: string]: ISignalProcessingParams;
}

export interface ISignalData {
    timeDomain: {
        /**
         * raw sample (external)
         */
        sample: number;
        /**
         * short
         */
        shortFilter: number;
        /**
         * long
         */
        longFilter: number;
        /**
         * short - long
         */
        filterDiff: number;
        filterDiffPrev: number;
        /**
         * the average diff
         */
        filterDiffFilter: number;
        timestamp: number;
        timestampPrev: number;
    };
    frequencyDomain: IFrequencyDomainData;
    beatDetection: {
        beatFlag: boolean;
        /**
         * detect beat transition
         */
        beatFlagPrev: boolean;
        beatCounter: number;
        rawBpm: number;
        /**
         * filtered bpm
         */
        bpm: number;
        /**
        * filtered beat
        * goes to 100 when beat is detected
        * then returns slowly to 0
        */
        beatZone: number;
        /**
        * filtered beat
        * goes to 100 when beat is detected
        * then returns slowly to 0
        * reset to 0 on tap
        */
        beatZoneTap: number;
        lastTimestamp: number;
        /**
         * detect if the music has stopped for a min amount of time
         */
        cutoffTimestamp: number;
        cutoffDetected: boolean;
    };
    validation: {

        /**
         * the amount of time that the activity is validated (target value)
         */
        timeCounter: number;
        /**
        * the target bpm may be dynamic (changed from dbmeter)
        */
        targetBpm: number;
        /**
        * the target value for e.g. decibels
        */
        targetValue: number;
        maxBpm: number;
    };
}

export interface IFrequencyDomainData {
    fftData: IFFTBin[];
    soundBins: ISoundBin[];
    /**
     * the scale of the chart (dynamic)
     */
    scaleFilter: number;
}

export interface IFFTBin {
    frequency: number;
    power: number;
}

export interface ISignalProcessingParams {
    /**
     * the factor by which the current acceleration has to be greater than the previous acceleration to detect a beat
     */
    compareFactor: number;
    /**
     * the min acceleration that is considered into the beat detector
     */
    diffThreshold: number;

    alphaDecay: number;
    alphaDecayInit: number;
    alphaDecayAccel: number;
    alphaShort: number;
    alphaLong: number;
    cutoffLevel: number;
    maxBpm: number;
    minBpm: number;
    cutoffCounterTimeout: number;
}

export interface ISoundIntervalCounts {
    interval: number;
    count: number;
}

export interface ITempoCounts {
    tempo: number;
    count: number;
}
