import { Injectable } from '@angular/core';
import { ApiDef } from '../../classes/app/api';

import { GenericDataService } from '../general/data/generic';
import { ILeplaceTreasure, ILeplaceWrapper, ILeplaceTreasureMin } from '../../classes/def/places/leplace';
import { IGenericResponse, IGenericResponseDataWrapper } from '../../classes/def/requests/general';
import { IPlaceExtContainer, IPlacePhotoContainer, EPlacePhotoContainerFlag, ILocationPhotoUrlContainer } from '../../classes/def/places/container';
import { ILocationType } from '../../classes/def/places/backend-location';
import { LocationUtils } from '../location/location-utils';
import { IPlaceScanParamsResponse, IProcessScanTreasuresDMSRequest, IProcessScanTreasuresAdsRequest, IPlaceScanParams } from 'src/app/classes/def/items/scanner';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IEventStoryGroupLinkData } from 'src/app/classes/def/core/links';
import { ILeplaceReg, ELocationTypeScan } from 'src/app/classes/def/places/google';
import { AppConstants } from 'src/app/classes/app/constants';
import { IGameItem } from 'src/app/classes/def/items/game-item';
import { LinksDataService } from './links';
import { IPlaceErrorStat } from '../location/def';
import { ILatLng } from 'src/app/classes/def/map/coords';

@Injectable({
    providedIn: 'root'
})
export class PlacesDataService {
    serverUrl: string;

    cachedLocationPhotos: ILocationPhotoUrlContainer[] = [];
    lastCacheDump: number = null;

    promise = {
        placeTypes: null
    };

    constructor(
        public generic: GenericDataService,
        public links: LinksDataService
    ) {
        console.log("places data service created");
        this.serverUrl = ApiDef.mainServerURL;
    }

    init() {

    }

    getDefaultPlaceStandard(placeType: string): number[] {
        let standard: number[] = [null, null];
        if (placeType === ELocationTypeScan.restaurant) {
            standard[0] = 40;
        }
        return standard;
    }

    getRandomPlaceType(): Promise<string> {
        let promise: Promise<string> = new Promise((resolve) => {
            this.getPlaceScanParams().then((params: IPlaceScanParamsResponse) => {
                let typesObj: IPlaceScanParams[] = params.treasures;
                let types: string[] = typesObj.map(type => type.type);
                let index: number = Math.floor(Math.random() * types.length);
                resolve(types[index]);
            });
        });
        return promise;
    }

    /**
     * get params for google places scan
     * only do actual server request once per session
     * or if the previous request failed
     * if the request fails fallback to the definitions in the app
     */
    getPlaceScanParams(): Promise<IPlaceScanParamsResponse> {
        let promise: Promise<IPlaceScanParamsResponse> = new Promise((resolve) => {
            if (GeneralCache.resourceCache.general.placeScanParams.loaded) {
                resolve(GeneralCache.resourceCache.general.placeScanParams.content);
                return;
            }
            this.generic.genericGetStandard("/treasures/get-scan-params", null).then((resp: IGenericResponse) => {
                let params: IPlaceScanParamsResponse = resp.data;
                GeneralCache.resourceCache.general.placeScanParams.content = params;
                GeneralCache.resourceCache.general.placeScanParams.loaded = true;
                resolve(params);
            }).catch(() => {
                // fallback to hardcoded defaults
                resolve(GeneralCache.resourceCache.general.placeScanParams.content);
            });
        });
        return promise;
    }

    getPlaceTemplates(): Promise<ILocationType[]> {
        let promise: Promise<ILocationType[]> = new Promise((resolve, reject) => {
            if (GeneralCache.resourceCache.general.placeTemplates.loaded) {
                resolve(GeneralCache.resourceCache.general.placeTemplates.content);
                return;
            }
            // prevent multiple requests at the same time
            if (!this.promise.placeTypes) {
                this.promise.placeTypes = this.generic.genericGetStandard("/treasures/get-place-templates", null);
            }

            this.promise.placeTypes.then((resp: IGenericResponse) => {
                let placeTypes: ILocationType[] = resp.data;
                GeneralCache.resourceCache.general.placeTemplates.content = placeTypes;
                GeneralCache.resourceCache.general.placeTemplates.loaded = true;
                resolve(placeTypes);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get place template photo from cache
     * @param type 
     */
    getPlaceTemplatePhoto(type: string) {
        let promise = new Promise((resolve) => {
            resolve(this.getPlaceTemplatePhotoCore(type));
        });
        return promise;
    }

    getPlaceTemplateByType(type: string) {
        let pt: ILocationType = GeneralCache.resourceCache.general.placeTemplates.content.find(e => e.type === type);
        return pt;
    }

    /**
     * get place template photo from cache
     * @param type 
     */
    getPlaceTemplatePhotoCore(type: string) {
        let photo: IPlacePhotoContainer = {
            photoUrl: null,
            flag: EPlacePhotoContainerFlag.loaded
        };
        let pt: ILocationType = this.getPlaceTemplateByType(type);

        if (pt != null) {
            photo.photoUrl = pt.photoUrl;
        }
        return photo;
    }

    // /**
    //  * process search results on the server
    //  * e.g. sort by rating and priority of registered places
    //  * @param currentLocation 
    //  * @param standard 
    //  * @param searchResults 
    //  */
    // processSearchResultsWrapper(currentLocation: ILatLng, standard: number[], searchResults: IPlaceExtContainer[], sortRating: boolean, mode: number, radius: number) {
    //     switch (mode) {
    //         case EProcessSearchResultsModes.business:
    //             return this.processScanAds(currentLocation, standard, searchResults, sortRating);
    //         case EProcessSearchResultsModes.treasures:
    //             return this.processScanTreasuresDMS(currentLocation, standard, searchResults, sortRating, radius, false, false);
    //     }
    // }

    reportPlaceScanError(report: IPlaceErrorStat) {
        let req: any = {
            data: report
        };
        return this.generic.genericPostStandardWData("/locations/report-place-scan-error", req);
    }

    /**
     * process search results on the server
     * e.g. sort by rating and priority of registered places
     * for business/place ads
     * @param currentLocation 
     * @param standard 
     * @param searchResults 
     */
    processScanAds(currentLocation: ILatLng, standard: number[], searchResults: IPlaceExtContainer[], sortRating: boolean): Promise<IGenericResponseDataWrapper<ILeplaceReg[]>> {
        let req: IProcessScanTreasuresAdsRequest = {
            lat: currentLocation.lat,
            lng: currentLocation.lng,
            standard: standard,
            sortRating: sortRating,
            data: searchResults
        };
        return this.generic.genericPostStandardWData("/treasures/business-scan", req);
    }


    /**
     * process search results on the server
     * e.g. sort by rating and priority of registered places
     * for treasures/dms scan
     * @param currentLocation 
     * @param standard 
     * @param searchResults 
     */
    processScanTreasuresDMS(
        currentLocation: ILatLng,
        standard: number[],
        searchResults: IPlaceExtContainer[],
        sortRating: boolean,
        radius: number,
        nearbyScan: boolean): Promise<IGenericResponseDataWrapper<ILeplaceWrapper[]>> {
        let req: IProcessScanTreasuresDMSRequest = {
            lat: currentLocation.lat,
            lng: currentLocation.lng,
            radius: radius,
            standard: standard,
            sortRating: sortRating,
            data: searchResults,
            nearbyScan: nearbyScan
        };
        // console.log(req);
        return this.generic.genericPostStandardWData("/treasures/treasure-scan", req);
    }


    /**
     * post processing on crates locations retrieved by process search results
     * return available crates (i.e. crates that have not been collected during the specified time frame)
     * @param currentLocation 
     * @param standard 
     * @param crates 
     */
    processUserTreasures(crates: ILeplaceTreasure[], worldEditor: boolean, lockedOnly: boolean) {
        return this.generic.genericPostStandard("/treasures/process-user-treasures", {
            data: crates,
            worldEditor: worldEditor,
            lockedOnly: lockedOnly
        });
    }

    /**
     * post processing on crates locations retrieved by process search results
     * return available crates (i.e. crates that have not been collected during the specified time frame)
     * reduce data
     * @param currentLocation 
     * @param standard 
     * @param crates 
     */
    processUserTreasuresMin(crates: ILeplaceTreasureMin[], worldEditor: boolean, lockedOnly: boolean) {
        return this.generic.genericPostStandard("/treasures/process-user-treasures-min", {
            data: crates,
            worldEditor: worldEditor,
            lockedOnly: lockedOnly
        });
    }


    /**
     * save collected crate in db
     * so that it's registered and not shown again until some time
     * also send full place details just in case
     * @param treasureId 
     */
    saveCollectedTreasureV3(treasureId: string, googleId: string, type: number, item: ILeplaceTreasure, collected: boolean, startTs: number) {
        let links: IEventStoryGroupLinkData = this.links.getLinkData();
        return this.generic.genericPostStandard("/treasures/register-user-treasure", {
            uid: treasureId,
            googleId: googleId,
            type: type,
            item: item,
            links: links,
            collected: collected,
            startTs: startTs
        });
    }

    /**
     * store photo url as retrieved from google place photos
     * next time, the url will be readliy available in the db, reducing calls to google api (and cost)
     * @param googleId 
     * @param photoUrl 
     * @param photoUrlSmall 
     */
    savePlacePhotoUrl(googleId: string, photoUrl: string, photoUrlSmall: string) {
        return this.generic.genericPostStandard("/locations/update-photo-url", {
            googleId: googleId,
            photoUrl: photoUrl,
            photoUrlSmall: photoUrlSmall
        });
    }

    /**
     * add to cache
     * @param googleId 
     * @param photoUrl 
     * @param photoUrlSmall 
     */
    cachePlacePhotoUrl(googleId: string, photoUrl: string, photoUrlSmall: string): boolean {
        // check existing place in cache, update details
        console.log("cache place photo url: ", googleId, photoUrl, photoUrlSmall);
        let existing: boolean = false;
        for (let i = 0; i < this.cachedLocationPhotos.length; i++) {
            let p: ILocationPhotoUrlContainer = this.cachedLocationPhotos[i];
            if (p.googleId === googleId) {
                existing = true;
                if (photoUrl != null) {
                    p.photoUrl = photoUrl;
                }
                break;
            }
        }

        if (!existing) {
            let newLocationPhotoContainer: ILocationPhotoUrlContainer = {
                googleId: googleId,
                photoUrl: photoUrl,
                photoUrlSmall: photoUrlSmall
            };
            this.cachedLocationPhotos.push(newLocationPhotoContainer);
        }

        return existing;
    }

    /**
     * check places in cache (useful for periodic dumps)
     * dump cache to server
     * clear cache on complete
     */
    saveCachedPlacePhotoUrlMultiCheckNoAction() {
        let updateCache: boolean = false;
        let timeCrt: number = new Date().getTime();
        let timedelta: number = 0;

        if (this.lastCacheDump == null) {
            this.lastCacheDump = new Date().getTime();
        }

        timedelta = timeCrt - this.lastCacheDump;

        // check min cache size required
        if (this.cachedLocationPhotos.length >= AppConstants.gameConfig.placePhotoCacheSize) {
            // check min timedelta (let's say 10 sec)
            if (timedelta >= 10000) {
                updateCache = true;
            }
        }

        // check max timedelta
        if (timedelta >= (AppConstants.gameConfig.placePhotoCacheTime * 1000)) {
            updateCache = true;
        }

        // update only if the cache is not empty
        if (this.cachedLocationPhotos.length === 0) {
            updateCache = false;
        }

        // check cache conditions
        // cache size, time since last dump
        if (updateCache) {
            this.saveCachedPlacePhotoUrlMulti().then(() => {
                console.log("location photos cached to server");
            }).catch((err: Error) => {
                console.error(err);
            });
        }
    }


    /**
     * dump cache to server
     * clear cache on complete
     */
    saveCachedPlacePhotoUrlMultiNoAction() {
        this.saveCachedPlacePhotoUrlMulti().then(() => {
            console.log("location photos cached to server");
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * dump cache to server
     * clear cache on complete
     */
    saveCachedPlacePhotoUrlMulti(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (this.cachedLocationPhotos.length === 0) {
                reject(new Error("cache empty"));
                return;
            }
            this.generic.genericPostStandard("/locations/update-photo-url-multi", {
                cache: this.cachedLocationPhotos
            }).then(() => {
                this.cachedLocationPhotos = [];
                this.lastCacheDump = new Date().getTime();
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * check available sales for the specified place
     * the place should be a registered business so that it makes sense to request this information
     * @param googleId 
     */
    checkAvailableSales(googleId: string, page: number) {
        let promise = new Promise((resolve, reject) => {
            this.generic.genericPostStandard("/treasures/get-business-items", {
                googleId: googleId,
                page: page
            }).then((response: IGenericResponse) => {
                resolve(response.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    viewStoryProviders(storyId: number) {
        let promise = new Promise((resolve, reject) => {
            this.generic.genericPostStandard("/stories/get-story-providers", {
                storyId: storyId
            }).then((response: IGenericResponse) => {
                resolve(response.data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    viewItemProviders(itemCode: number): Promise<IGameItem> {
        let promise: Promise<IGameItem> = new Promise((resolve, reject) => {
            this.generic.genericPostStandardWData("/inventory/get-item-providers", {
                itemCode: itemCode
            }).then((response: IGameItem) => {
                resolve(response);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * load existing treasure from the db
     * transform into universal place container
     */
    loadTreasure(treasureId: number): Promise<ILeplaceTreasure> {
        let promise: Promise<ILeplaceTreasure> = new Promise((resolve, reject) => {
            this.generic.genericPostStandard("/treasures/get-treasure-by-id", {
                treasureId: treasureId
            }).then((response: IGenericResponse) => {
                // convert to standard representation
                let rloc: ILeplaceTreasure = response.data;
                LocationUtils.formatTreasureLocationDB(rloc);
                resolve(rloc);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * resolve only
     * return null if error
     * @param placeId 
     * @returns 
     */
    getGooglePhotoUrl(placeId: string): Promise<string> {
        return new Promise((resolve) => {
            this.generic.genericPostStandardWData("/locations/get-google-photo-url", {
                placeId: placeId
            }).then((res: string) => {
                resolve(res);
            }).catch((err: Error) => {
                console.error(err);
                resolve(null);
            });
        });
    }

    getGooglePhotoUrlMulti(placeIds: string[]): Promise<string> {
        return this.generic.genericPostStandardWData("/locations/get-google-photo-url-multi", {
            placeIds: placeIds
        });
    }
}
