import { ModalOptions } from '@ionic/core';
import { UiExtensionService } from './ui-extension';
import { Injectable } from '@angular/core';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { IModalParams, IPopoverAction } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { IDescriptionFrameParams, DescriptionFrameViewComponent } from 'src/app/modals/generic/modals/description-frame/description-frame.component';
import { OptionsListViewComponent } from 'src/app/modals/generic/modals/options-list/options-list.component';
import { CheckboxFrameViewComponent } from 'src/app/modals/generic/modals/checkbox-frame/checkbox-frame.component';
import { CheckboxGridFrameViewComponent } from 'src/app/modals/generic/modals/checkbox-grid-frame/checkbox-grid-frame.component';
import { EModalScope } from 'src/app/classes/def/views/overlay';
import { GeneralCache } from 'src/app/classes/app/general-cache';


@Injectable({
    providedIn: 'root'
})
export class UiExtensionStandardService {

    constructor(
        public uiext: UiExtensionService
    ) {
        console.log("ui extension standard service created");
    }

    /**
    * show modal of custom type
    * either description type
    * or options type
    * @param mode 
    * @param description 
    * @param navParams 
    */
    showStandardModal(_event: Event, mode: number, title: string, navParams: INavParams, onCreated?: () => any) {

        let params: IModalParams = navParams.params;

        let navParams2: INavParams = {
            view: navParams.view,
            params: params
        };

        navParams.view.webIOS = GeneralCache.isWeb;

        let opts: ModalOptions = {
            component: null,
            componentProps: {
                np: navParams2
            },
            showBackdrop: true,
            backdropDismiss: true,
            // showBackdrop: false,
            // enableBackdropDismiss: true,
            cssClass: navParams.view.large ? 'modal-center-large' : 'modal-center',
            // enterAnimation: "leplace-modal-transition-enter",
            // leaveAnimation: "leplace-modal-transition-leave",
            // cssClass: "modal-center"
        };

        let promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                switch (mode) {
                    case EModalTypes.description:

                        let params2: IDescriptionFrameParams = {
                            description: params.description,
                            title: title ? title : "Description",
                            mode: mode,
                            loaderCode: params.loaderCode,
                            photoUrl: params.photoUrl
                        };
                        navParams2.params = params2;

                        opts.component = DescriptionFrameViewComponent;
                        opts.componentProps = {
                            np: navParams2
                        };

                        this.uiext.createModalBB(opts, navParams ? navParams.view : null, onCreated, null, false, EModalScope.modal).then((data) => {
                            resolve(data);
                        }).catch((err: Error) => {
                            reject(err);
                        });

                        break;
                    case EModalTypes.options:
                        navParams2.params = {
                            actions: params.actions,
                            title: title ? title : "Options"
                        };

                        opts.component = OptionsListViewComponent;
                        opts.componentProps = {
                            np: navParams2
                        };

                        this.uiext.createModalBB(opts, navParams ? navParams.view : null, onCreated, null, false, EModalScope.modal).then((data: IPopoverAction) => {
                            resolve(data ? data.code : null);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                        break;
                    case EModalTypes.checkbox:
                        navParams2.params = {
                            actions: params.actions,
                            title: title ? title : "Options"
                        };

                        opts.component = CheckboxFrameViewComponent;
                        opts.componentProps = {
                            np: navParams2
                        };

                        this.uiext.createModalBB(opts, navParams ? navParams.view : null, onCreated, null, false, EModalScope.modal).then((data) => {
                            resolve(data);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                        break;
                    case EModalTypes.checkboxGrid:
                        navParams2.params = {
                            actions: params.actions,
                            title: title ? title : "Options"
                        };

                        opts.component = CheckboxGridFrameViewComponent;
                        opts.componentProps = {
                            np: navParams2
                        };

                        this.uiext.createModalBB(opts, navParams ? navParams.view : null, onCreated, null, false, EModalScope.modal).then((data) => {
                            resolve(data);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                        break;
                    default:
                        reject(new Error("unknown modal type"));
                        return;
                }
            }, 1);
        });
        return promise;
    }

}
