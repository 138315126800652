
export enum EMarkerIcons {
    user = "assets/img/markers/user_v2.png",
    drone = "assets/img/markers/balloon_drone.png",
    mpUser = "assets/img/markers/mp_user.png",
    null = "assets/img/test_markers/blue_MarkerA.png",
    clue = "assets/img/markers/lamp.png",
    location = "assets/img/markers/place.png",
    cluster = "assets/img/markers/m",
    waypoint = "assets/img/test_markers/green_MarkerW.png",
    treasure = "assets/img/markers/crate.png",
    story = "assets/img/markers/story.png",
    gathering = "assets/img/markers/gathering.png",
    coin = "assets/img/markers/coin.png",
    testA = "assets/img/test_markers/blue_MarkerA.png",
    testB = "assets/img/test_markers/green_MarkerB.png",
    testC = "assets/img/test_markers/orange_MarkerC.png",
    testD = "assets/img/test_markers/red_MarkerD.png",
    findCircle = "assets/img/markers/search.png",
    arrow = "assets/img/markers/arrow.png",
    navPointer = "assets/img/markers/nav_pointer.png",
    sign = "assets/img/markers/sign.png"
}


// https://ionicons.com/v4/
// ionicons v5
export enum EAppIconsStandard {
    close = "close",
    exit = "exit",
    lt = "chevron-back",
    gt = "chevron-forward",
    map = "map",
    stories = "bookmarks",
    checkpoint = "bookmark",
    tutorial = "information-circle",
    mapFilter = "list",
    compass = "compass",
    hud = "speedometer",
    ar = "eye",
    view = "eye",
    placeTreasure = "flag",
    checkin = "checkmark-circle",
    check = "checkmark-circle",
    circle = "radio-button-off",
    sales = "pricetag",
    share = "share",
    ping = "wifi",
    terms = "newspaper",
    briefing = "briefcase",
    refresh = "refresh",
    image = "image",
    viewPhoto = "image",
    takePhoto = "camera",
    pickPhoto = "images",
    removePhoto = "close",
    remove = "close",
    dash = "remove",
    sync = "sync",
    search = "search",
    download = "cloud-download",
    upload = "cloud-upload",
    reset = "close",
    accept = "checkmark",
    validate = "checkmark",
    reject = "close",
    repeat = "repeat",
    retry = "repeat",
    clearSavedPlaces = "remove-circle",
    rate = "star",
    edit = "create",
    account = "briefcase",
    profile = "person",
    groups = "people",
    userMarker = "person-circle",
    densityFiltering = "funnel",
    key = "key",
    hourglass = "hourglass",
    frame = "scan",
    sound = "volume-high",
    locked = "lock-closed",
    unlocked = "lock-open",
    settings = "settings",
    addGroup = "person-add",
    leaderboard = "ribbon",
    calendar = "calendar",
    filter = "funnel",
    skipForward = "play-skip-forward",
    pause = "pause",
    play = "play",
    stop = "stop",
    record = "radio-button-on",
    clipboard = "clipboard-outline",
    alert = "alert",
    place = "pin",
    info = "information-circle-outline",
    idea = "bulb",
    send = "send",
    location = "locate",
    chat = "chatbox",
    magnet = "magnet",
    speakerOn = "volume-high",
    speakerOff = "volume-off"
}

export enum EAppIcons {
    backpack = "assets/icons/backpack.svg",
    alarmClock = "assets/icons/alarm-clock.svg",
    hourglass = "assets/icons/hourglass.svg",
    distance = "assets/icons/distance-road.svg",
    distanceTarget = "assets/icons/distance-target.svg",
    stopwatch = "assets/icons/stopwatch.svg",
    check = "assets/icons/checked.svg",
    play = "assets/icons/press-play-button.svg",

    cancel = "assets/icons/cancel.svg",
    film = "assets/icons/film.svg",
    handshake = "assets/icons/hand-shake.svg",
    www = "assets/icons/globe.svg",
    qr = "assets/icons/qr-code.svg",
    arView = "assets/icons/ar-view.svg",
    googleMaps = "assets/icons/google-maps-bw.svg",
    navigation = "assets/icons/navigation.svg",
    gpsFixed = "assets/icons/gps-fixed-indicator.svg",
    compass = "assets/icons/compass.svg",
    world = "assets/icons/worldwide.svg",
    // drone = "assets/icons/drone.svg",
    drone = "assets/icons/balloon-drone.svg",
    battery = "assets/icons/battery.svg",

    payment = "assets/icons/credit-card.svg",
    faq = "assets/icons/faq.svg",
    hotAirBalloon = "assets/icons/hot-air-balloon.svg",
    feedback = "assets/icons/letter.svg",
    search = "assets/icons/search.svg",
    issues = "assets/icons/technical-support.svg",
    chat = "assets/icons/chat.svg",

    personCircleOutline = "assets/icons/person-circle-outline.svg",
    exitOutline = "assets/icons/exit-outline.svg",
    // exit = "assets/icons/exit.svg",
    exit = "assets/icons/sign-out.svg",

    logout = "assets/icons/logout.svg",
    leaveGroup = "assets/icons/leave-group.svg",
    disconnect = "assets/icons/disconnect.svg",

    disconnectNetwork = "assets/icons/no-wifi.svg",

    personRemove = "assets/icons/person-remove.svg",
    starred = "assets/icons/starred.svg",

    rotate = "assets/icons/rotate.svg",
    flipX = "assets/icons/flip-x.svg",
    flipY = "assets/icons/flip-y.svg",

    clear = "assets/icons/eraser.svg",
    clue = "assets/icons/light-bulb.svg",

    linear = "assets/icons/route.svg",
    network = "assets/icons/neural.svg",
    route = "assets/icons/route.svg",

    swipe = "assets/icons/swipe.svg",
    backStep = "assets/icons/return.svg",
    groups = "assets/icons/group.svg",
    turbo = "assets/icons/turbo.svg",

    videoAd = "assets/icons/video-player.svg",
    city = "assets/icons/smart-city.svg",

    personPlace = "assets/icons/person-place.svg",

    questText = "assets/icons/text-editor.svg",
    questSelect = "assets/icons/multiple.svg",
    questNumeric = "assets/icons/numeric.svg",

    forward = "assets/icons/fast-forward.svg",
    // continue = "assets/icons/forward-button.svg",
    continue = "assets/icons/fast-forward.svg",
    powerOff = "assets/icons/on-off-button.svg",

    audioGuide = "assets/icons/audio-guide.svg",
    bike = "assets/icons/bike.svg"
}

export enum EImgIcons {
    maps = "assets/img/icons8-google-maps-50.png"
}

export interface IIconSpec {
    icon: string,
    custom: boolean
}

export interface IIconSpecs {
    [key: string]: IIconSpec;
}

export enum EActivityIcons {
    run = "walk",
    eat = "restaurant",
    walk = "walk",
    visit = "pin",
    explore = "compass",
    drink = "beer",
    find = "search",
    bike = "bicycle",
    snapshot = "images",
    photo = "camera",
    screenshotAR = "eye",
    dance = "disc",
    decibel = "headset",
    enduranceRun = "walk",
    escape = "walk",
    pursue = "walk",
    walkTarget = "walk",
    runTarget = "walk",
    exploreFind = "compass",
    exploreTarget = "compass",
    quest = "compass",
    questHidden = "compass"
}

export enum EActivityIconsCustom {
    run = "assets/icons/activities/run.svg",
    eat = "",
    walk = "assets/icons/activities/walk.svg",
    visit = "assets/icons/activities/visit.svg",
    explore = "assets/icons/activities/explore.svg",
    drink = "",
    find = "assets/icons/activities/find.svg",
    bike = "",
    snapshot = "",
    photo = "",
    screenshotAR = "",
    dance = "",
    decibel = "",
    enduranceRun = "assets/icons/activities/endurance-run.svg",
    escape = "assets/icons/activities/escape.svg",
    pursue = "assets/icons/activities/escape.svg",
    walkTarget = "assets/icons/activities/target.svg",
    runTarget = "assets/icons/activities/target.svg",
    exploreFind = "assets/icons/activities/explore.svg",
    exploreTarget = "assets/icons/activities/target.svg",
    quest = "assets/icons/activities/quest.svg",
    questHidden = "assets/icons/activities/quest.svg"
}

/**
 * obsolete because now we use cloud storage
 */
export enum EMapStylePhotos {
    aubergine = "assets/img/maps/aubergine.jpg"
}

/**
 * obsolete because now we use cloud storage
 */
export enum ECategoryPhotos {
    explore = "assets/img/photos/explore.jpg",
    sport = "assets/img/photos/run.jpg",
    casual = "assets/img/photos/casual.jpg",
    private = "assets/img/photos/bench.jpg",
    default = "assets/img/Logo/logo_icon.png"
}

/**
 * obsolete because now we use cloud storage
 */
export enum EPhotos {
    default = "assets/img/photos/bench.jpg",
    drink = "assets/img/photos/casual.jpg",
    eat = "assets/img/photos/eat.jpg",
    run = "assets/img/photos/run.jpg",
    walk = "assets/img/photos/run.jpg",
    bike = "assets/img/photos/bike.jpg",
    visit = "assets/img/photos/visit.jpg",
    explore = "assets/img/photos/explore.jpg",
    exploreMap = "assets/img/photos/explore_map.jpg",
    home = "assets/img/photos/home2.jpg",
    find = "assets/img/photos/find.jpg",
    logo = "assets/img/Logo/logo_transparent.png",
    userPlaceholder = "assets/img/photos/user_placeholder.png",
    tutorialStory = "assets/img/photos/tutorial_stories.png",
    tutorialWm = "assets/img/photos/tutorial_wm.png",
    tutorialAR = "assets/img/photos/tutorial_ar.png",
    QRInfo = "assets/img/photos/qr_info.png",
    imageNotAvailable = "assets/img/photos/image_not_available.png",
    stripeBadge = "assets/img/Logo/stripe_powered_white.png",
}

