
export enum ETimeoutStatus {
    started = 0,
    expired = 1
}
export interface ITimeoutMonitorParams {
    timeLimit: number;
}

export interface ITimeoutMonitorData {
    timerValue: number;
    elapsedValue: number;
    // counting may be enabled/disabled
    elapsedValueAux: number;
    status: number;
    timerDisp: string;
    isFallbackTimer: boolean;
}
