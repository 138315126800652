<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" [title]="title" leftButton="arrow-back" [leftIcon]="true" (leftClick)="cancel()"
    rightButton="settings" [rightIcon]="true" (rightClick)="options()" [loading]="!loaded"></leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <div class="theme-background" [ngClass]="innerClass" fxLayout="column">

    <ion-list no-lines class="padding-s">

      <ion-item class="transparent" *ngIf="existingGroup">
        <span class="text-color info-text-m input-span-replacer">Team name: {{group.name}}</span>
      </ion-item>

      <ion-item class="transparent">
        <ion-row class="full-w center-items">
          <ion-col size="10" class="no-padding">
            <ion-label *ngIf="!existingGroup" position="stacked" class="text-color info-text-m">Team ID</ion-label>
            <ion-input *ngIf="!existingGroup" [(ngModel)]="group.alias" ngDefaultControl icon="name" type="text"
              class="text-color info-text-m" placeholder="Enter team ID"> </ion-input>
            <span *ngIf="existingGroup" class="text-color info-text-m input-span-replacer">Team ID:
              {{group.alias}}</span>
          </ion-col>
          <ion-col size="2" class="text-right">
            <ion-button class="action-button-icon-only margin-right-s" [disabled]="!group.alias"
              (click)="copyToClipboard(group.alias)">
              <icon-wrapper slot="icon-only" icon="copy"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-item>

      <ion-item class="transparent" *ngIf="existingGroup">
        <ion-row class="full-w center-items">
          <ion-col>
            <span class="text-color info-text-m input-span-replacer">Team photo</span>
          </ion-col>
        </ion-row>
        <!-- <ion-label position="stacked" class="text-color info-text-m">Team photo</ion-label> -->
      </ion-item>

      <ion-item class="transparent" *ngIf="existingGroup">
        <ion-row class="full-w center-items">
          <ion-col>
            <responsive-img [withUpload]="false" [photoUrl]="photoUrl" class="border-radius"
              customClass="img-place-header" (zoom)="onZoomImage($event)">
            </responsive-img>
          </ion-col>
        </ion-row>
      </ion-item>

      <ion-item class="transparent" *ngIf="existingGroup">
        <span class="text-color info-text-m input-span-replacer">Role: {{group.roleName}}</span>
      </ion-item>
    </ion-list>

    <!-- <div tappable class="padding-xs text-center" *ngIf="descriptionHeader">
      <span class="as-text element-selector button-font-size-m text-color-primary">{{descriptionHeader}}</span>
    </div> -->

    <div class="padding-s">
      <div [innerHTML]="description" class="info-text-m description-frame text-color-primary text-center">
      </div>
    </div>

    <div fxFlex></div>

    <div>
      <ion-grid *ngIf="newJoin()">
        <!-- join a new group -->
        <ion-row>
          <ion-col size="6">
            <ion-button class="action-button-fill button-color-alternate" [disabled]="!group.alias"
              (click)="joinGroup()">
              <span class="button-font-size-s">Join</span>
              <icon-wrapper class="slot-icon-padding" slot="start" icon="person-add"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button class="action-button-fill button-color-primary" (click)="joinGroup()">
              <span class="button-font-size-s">Scan</span>
              <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIcons.qr" [custom]="true">
              </icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>

      <ion-grid *ngIf="!newJoin()">
        <!-- join an existing group for a game -->
        <ion-row>
          <!-- <ion-col size="4">
            <ion-button *ngIf="joined" class="action-button-fill button-color-warn" (click)="leaveGroup()">
              <icon-wrapper slot="icon-only" [icon]="appIcons.exitOutline" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col> -->

          <!-- <ion-col size="4">
            <ion-button class="action-button-fill button-color-alternate" [disabled]="!isFilled(group)" (click)="viewMembers()">
              <icon-wrapper slot="icon-only" [icon]="appIcons.personCircleOutline" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col> -->

          <ion-col size="12">
            <ion-button *ngIf="joined && !chatOnly" class="action-button-fill button-color-accent" (click)="ok()"
              [disabled]="fromTeamLobby">
              <span class="button-font-size-s">Connect</span>
              <icon-wrapper slot="start" class="slot-icon-padding" [icon]="appIconsStandard.play"
                [custom]="false"></icon-wrapper>
            </ion-button>
            <ion-button *ngIf="joined && chatOnly" class="action-button-fill button-color-accent" (click)="openChat()">
              <span class="button-font-size-s">Chat</span>
              <icon-wrapper slot="start" class="slot-icon-padding" icon="send"></icon-wrapper>
            </ion-button>
          </ion-col>

        </ion-row>
      </ion-grid>

    </div>
  </div>

</ion-content>