<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" [title]="title" leftButton="arrow-back" [leftIcon]="true" (leftClick)="cancel(null)"
    rightButton="settings" [rightIcon]="true" (rightClick)="options()" [rightButtonDisabled]="true"
    [loading]="!loaded"></leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <div class="theme-background" [ngClass]="innerClass" fxLayout="column">

    <nav-bar *ngIf="!created" class="theme-border-bottom" [slidesPerView]="categoryTabsGroupCount"
      [(selectedTabId)]="selectedIndex" [loadData]="loaded" [navBarItems]="navBarItems"
      (select)="selectIndexFromNavItem($event)">
    </nav-bar>

    <ion-slides #slides [pager]="true" [options]="sliderOptions" class="slider-primary-bullets full-height full-w"
      (ionSlideDidChange)="slideChanged()">
      <ion-slide fxLayout="column" class="padding-s" [ngClass]="!created ? 'slide-height' : 'slide-height-min'">

        <div class="scrollbar-y full-height full-w" fxLayout="column">

          <ion-list no-lines class="padding-s" *ngIf="group && loaded">
            <ion-item class="transparent">
              <ion-row class="full-w center-items">
                <ion-col size="10">
                  <ion-label *ngIf="!existingGroup" position="stacked" class="text-color info-text-m">Team
                    ID</ion-label>
                  <ion-input *ngIf="!existingGroup" [(ngModel)]="group.alias" ngDefaultControl icon="name" type="text"
                    class="text-color info-text-m" placeholder="Enter team ID"> </ion-input>
                  <span *ngIf="existingGroup" class="text-color info-text-m input-span-replacer">Team ID:
                    {{group.alias}}</span>
                </ion-col>
                <ion-col size="2" class="text-right">
                  <ion-button class="action-button-icon-only margin-right-s" [disabled]="!group.alias"
                    (click)="copyToClipboard(group.alias)">
                    <icon-wrapper slot="icon-only" icon="copy"></icon-wrapper>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-item>
          </ion-list>

          <div>
            <ion-grid *ngIf="!existingGroup">
              <!-- join a new group -->
              <ion-row>
                <ion-col size="6">
                  <ion-button class="action-button-fill button-color-alternate" [disabled]="!group.alias"
                    (click)="joinGroup()">
                    <span class="button-font-size-s">Join</span>
                    <icon-wrapper class="slot-icon-padding" slot="start" icon="person-add"></icon-wrapper>
                  </ion-button>
                </ion-col>
                <ion-col size="6">
                  <ion-button class="action-button-fill button-color-primary" (click)="joinGroup()">
                    <span class="button-font-size-s">Scan</span>
                    <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIcons.qr" [custom]="true">
                    </icon-wrapper>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>

          <div class="padding-s">
            <div [innerHTML]="descriptionJoin" class="info-text-m description-frame text-color-primary text-center">
            </div>
          </div>

          <!-- <div fxFlex></div> -->
        </div>
      </ion-slide>

      <ion-slide fxLayout="column" class="padding-s" [ngClass]="!created ? 'slide-height' : 'slide-height-min'">
        <div class="scrollbar-y full-height full-w" fxLayout="column">

          <ion-list no-lines class="padding-s" *ngIf="group && loaded">
            <ion-item class="transparent">
              <ion-label position="stacked" class="text-color info-text-m">Team name</ion-label>
              <ion-input [(ngModel)]="group.name" ngDefaultControl icon="name" type="text"
                class="text-color info-text-m" [disabled]="created" placeholder="Enter team name">
              </ion-input>
            </ion-item>
            <ion-item class="transparent">
              <ion-row class="full-w center-items">
                <ion-col size="10" class="no-padding">
                  <span *ngIf="existingGroup" class="text-color info-text-m input-span-replacer">Team ID:
                    {{group.alias}}</span>
                  <span *ngIf="!existingGroup" class="text-color info-text-m input-span-replacer">Team ID:
                    undefined</span>
                </ion-col>
                <ion-col size="2" class="text-right">
                  <ion-button class="action-button-icon-only margin-right-s" [disabled]="!group.alias"
                    (click)="copyToClipboard(group.alias)">
                    <icon-wrapper slot="icon-only" icon="copy"></icon-wrapper>
                  </ion-button>
                </ion-col>
              </ion-row>
            </ion-item>
            <ion-item class="transparent">
              <span class="text-color info-text-m input-span-replacer">Role: {{group.roleName}}</span>
            </ion-item>
            <ion-item class="transparent">
              <ion-label class="text-color-primary info-text-m-imp">Visibility</ion-label>
              <ion-select class="custom-select info-text-m" ngDefaultControl [(ngModel)]="group.type"
                (ionChange)="onGroupTypeChanged()">
                <ion-select-option *ngFor="let o of optionsList; let i = index" [value]="o.value">{{o.name}}
                </ion-select-option>
              </ion-select>
            </ion-item>
          </ion-list>

          <!-- QR code for join -->
          <div *ngIf="created" class="padding-s">
            <qr-code [code]="group.alias" [version]="4"></qr-code>
          </div>

          <div>
            <ion-grid>
              <!-- create group, show before and after create context -->
              <ion-row *ngIf="!existingGroup && !created">
                <ion-col size="12">
                  <ion-button class="action-button-fill button-color-alternate" [disabled]="!group.name"
                    (click)="createGroup()">
                    <span class="button-font-size-s">{{createMessage}}</span>
                    <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIcons.personCircleOutline"
                      [custom]="true">
                    </icon-wrapper>
                  </ion-button>
                </ion-col>
              </ion-row>

              <ion-row *ngIf="existingGroup || created">
                <ion-col size="12">
                  <ion-button class="action-button-fill button-color-accent" (click)="cancel(null)">
                    <span class="button-font-size-s">Return</span>
                    <icon-wrapper slot="start" class="slot-icon-padding" icon="play"></icon-wrapper>
                  </ion-button>
                </ion-col>
              </ion-row>

            </ion-grid>
          </div>

          <div class="padding-s">
            <div [innerHTML]="descriptionCreate" class="info-text-m description-frame text-color-primary text-center">
            </div>
          </div>

          <!-- <div class="leplace-icon-div-m leplace-icon icon-color-primary margin-top-s">
            <ion-icon [icon]="appIcons.swipe"></ion-icon>
          </div> -->

        </div>
      </ion-slide>
    </ion-slides>
  </div>
</ion-content>