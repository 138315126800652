
import { NgModule } from '@angular/core';
import { BlinkButtonComponent } from './components/blink-button/blink-button.component';
import { ContentDrawerComponent } from './components/content-drawer/content-drawer.component';
import { ExpanderComponent } from './components/expander/expander.component';
import { HourglassComponent } from './components/hourglass/hourglass.component';
import { LeplaceHudCaptionComponent } from './components/leplace-hud-caption/leplace-hud-caption.component';
import { LeplaceHudSlideComponent } from './components/leplace-hud-slide/leplace-hud-slide.component';
import { LeplaceHudComponent } from './components/leplace-hud/leplace-hud.component';
import { LeplacePlateComponent } from './components/leplace-plate/leplace-plate.component';
import { StickerbombComponent } from './components/leplace-stickerbomb/leplace-stickerbomb.component';
import { LeplaceToolbarComponent } from './components/leplace-toolbar/leplace-toolbar.component';
import { LevelComponent } from './components/level/level.component';
import { ModalContainerComponent } from './components/modal-container/modal-container.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { ProgressLoadingComponent } from './components/progress-loading/progress-loading.component';
import { ProgressTimeoutComponent } from './components/progress-timeout/progress-timeout.component';
import { ResponsiveImgComponent } from './components/responsive-img/responsive-img.component';
import { SegmentDisplayComponent } from './components/segment-display/segment-display.component';
import { ModalHeaderContainerComponent } from './components/modal-header-container/modal-header-container.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { ComponentsModule } from '../components.module';
import { IconWrapperComponent } from './components/icon-wrapper/icon-wrapper.component';
import { YouTubeViewComponent } from './components/youtube-view/youtube-view.component';
import { PlainEntryComponent } from './components/plain-entry/plain-entry.component';
import { PlainEntryHeaderComponent } from './components/plain-entry-header/plain-entry-header.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';

// angular modules
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { IconWrapperExtraComponent } from './components/icon-wrapper-extra/icon-wrapper-extra.component';
import { JoystickComponent } from './components/joystick/joystick.component';
import { NgxJoystickModule } from 'ngx-joystick';
import { JoystickNgxComponent } from './components/joystick-ngx/joystick-ngx.component';
import { BlinkSpanComponent } from './components/blink-span/blink-span.component';
import { CooldownButtonComponent } from './components/cooldown-button/cooldown-button.component';
import { LeplacePaginatorComponent } from './components/leplace-paginator/leplace-paginator.component';
import { LeplaceSliderComponent } from './components/leplace-slider/leplace-slider.component';
import { CardItemComponent } from './components/card-item/card-item.component';
import { StateFlagsComponent } from './components/state-flags/state-flags.component';
import { ProgressDualComponent } from './components/progress-dual/progress-dual.component';
import { CheckboxCardComponent } from './components/checkbox-card/checkbox-card.component';
import { EditCodeComponent } from './components/edit-code/edit-code.component';
import { EditTextareaComponent } from './components/edit-textarea/edit-textarea.component';
import { CodeInputComponent } from './components/code-input/code-input.component';
import { BlinkProgressBarComponent } from './components/blink-progress-bar/blink-progress-bar.component';
import { PlainEntryExpandComponent } from './components/plain-entry-expand/plain-entry-expand.component';
import { MediaRecordingAudioComponent } from './components/media-recording-audio/media-recording-audio.component';
import { MediaRecordingVideoComponent } from './components/media-recording-video/media-recording-video.component';
import { SafeHtmlRendererComponent } from './components/safe-html-renderer/safe-html-renderer.component';
import { DirectionArrowComponent } from './components/direction-arrow/direction-arrow.component';

const components = [
  BlinkButtonComponent,
  BlinkProgressBarComponent,
  BlinkSpanComponent,
  CodeInputComponent,
  ContentDrawerComponent,
  CooldownButtonComponent,
  DirectionArrowComponent,
  EditCodeComponent,
  EditTextareaComponent,
  ExpanderComponent,
  HourglassComponent,
  LeplaceHudCaptionComponent,
  LeplaceHudSlideComponent,
  LeplacePaginatorComponent,
  LeplaceHudComponent,
  LeplacePlateComponent,
  LeplaceSliderComponent,
  StickerbombComponent,
  LeplaceToolbarComponent,
  LevelComponent,
  MediaRecordingAudioComponent,
  MediaRecordingVideoComponent,
  ModalContainerComponent,
  NavBarComponent,
  ProgressDualComponent,
  ProgressLoadingComponent,
  ProgressTimeoutComponent,
  ResponsiveImgComponent,
  SafeHtmlRendererComponent,
  SegmentDisplayComponent,
  ModalHeaderContainerComponent,
  SidenavComponent,
  StateFlagsComponent,
  IconWrapperComponent,
  YouTubeViewComponent,
  PlainEntryComponent,
  PlainEntryExpandComponent,
  PlainEntryHeaderComponent,
  CardItemComponent,
  CheckboxCardComponent,
  QrCodeComponent,
  IconWrapperExtraComponent,
  JoystickComponent,
  JoystickNgxComponent
];

@NgModule({
  declarations: [...components],
  entryComponents: [...components],
  imports: [
    ComponentsModule,
    NgxQRCodeModule,
    NgxJoystickModule
  ],
  exports: [...components]
})
export class AppComponentsGenericModule { }
