import { Component, OnDestroy, ViewChild, ElementRef, OnInit } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ModalController } from '@ionic/angular';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { ScriptLoaderService } from 'src/app/services/general/script-loader';
import { DomSanitizer } from '@angular/platform-browser';
import { ParamHandler } from 'src/app/classes/general/params';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { Messages } from 'src/app/classes/def/app/messages';
import { EScripts } from 'src/app/classes/utils/script-loader';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';

export interface IImageCropFrameNavParams {
  description?: string;
  title: string;
  photoUrl: string;
  quality: number;
}
@Component({
  selector: 'modal-image-crop-frame',
  templateUrl: './image-crop-frame.component.html',
  styleUrls: ['./image-crop-frame.component.scss'],
})
export class ImageCropFrameViewComponent implements OnInit, OnDestroy {
  @ViewChild('myCrop', { read: ElementRef, static: false }) myCrop: ElementRef;

  description: string = "";
  title: string = "";
  croppedImage: string = null;

  cropper: Cropper = null;

  cropperLoaded: boolean = false;

  myImage = null;
  scaleValX = 1;
  scaleValY = 1;

  vs: IViewSpecs = ViewSpecs.getDefault();
  withButtons: boolean = true;
  params: IImageCropFrameNavParams = null;

  timeout;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  cropperOptions = {
    dragMode: 'crop',
    aspectRatio: 1,
    autoCrop: true,
    movable: true,
    zoomable: true,
    scalable: true,
    autoCropArea: 0.8
  };

  np: INavParams = null;

  rotateIndex: number = 0;

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService,
    public scriptLoader: ScriptLoaderService,
    private sanitizer: DomSanitizer
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IImageCropFrameNavParams = np.params;
      this.params = params;
      if (np.view) {
        this.vs = np.view;
      }
      // console.log(np);
      this.description = params.description;
      this.title = params.title;
      this.myImage = this.sanitizer.bypassSecurityTrustUrl(params.photoUrl);
    }

    this.timeout = setTimeout(() => {
      this.initDeps();
    }, 1);
  }

  ngOnDestroy() {
    console.log("cropper deinit");
    this.cropper.destroy();
    // this.cropper = null;   

    ResourceManager.clearTimeout(this.timeout);
  }



  dismiss(data: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(data).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  captureImage() {
    this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
  }

  cancel() {
    this.dismiss(null);
  }

  ok() {
    this.save();
    this.dismiss(this.croppedImage);
  }


  reset() {
    this.cropper.reset();
  }

  clear() {
    this.cropper.clear();
  }

  rotate() {
    this.cropper.rotate(-90);
    this.rotateIndex += 1;
    if (this.rotateIndex >= 4) {
      this.rotateIndex = 0;
    }
  }

  zoom(zoomIn: boolean) {
    let factor = zoomIn ? 0.1 : -0.1;
    this.cropper.zoom(factor);
  }

  flipX() {
    if (this.rotateIndex % 2 == 0) {
      this.scaleY();
    } else {
      this.scaleX();
    }
  }

  flipY() {
    if (this.rotateIndex % 2 == 0) {
      this.scaleX();
    } else {
      this.scaleY();
    }
  }

  scaleX() {
    this.scaleValX = this.scaleValX * -1;
    this.cropper.scaleX(this.scaleValX);
  }

  scaleY() {
    this.scaleValY = this.scaleValY * -1;
    this.cropper.scaleY(this.scaleValY);
  }

  move(x, y) {
    this.cropper.move(x, y);
  }

  save() {
    let croppedImgB64String: string = this.cropper.getCroppedCanvas().toDataURL('image/jpeg', (this.params.quality / 100));
    this.croppedImage = croppedImgB64String;
    console.log("save cropped image");
    // console.log(this.croppedImage);
  }

  /**
  * load and init stickerbomb with specified params
  */
  initDeps() {
    this.scriptLoader.load([EScripts.cropperJs, EScripts.cropperJsCss]).then(() => {
      console.log("cropperjs loaded");
      let image = this.myCrop.nativeElement;
      // console.log(image);
      this.cropperLoaded = true;
      this.cropper = new Cropper(image, {
        aspectRatio: 1,
        crop: (e) => {
          console.log("cropped");
          console.log(e.detail.x);
          console.log(e.detail.y);
          console.log(e.detail.width);
          console.log(e.detail.height);
          console.log(e.detail.rotate);
          console.log(e.detail.scaleX);
          console.log(e.detail.scaleY);
        }
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }
}
