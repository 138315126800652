import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { timer } from 'rxjs';
import { ResourceManager } from 'src/app/classes/general/resource-manager';

@Component({
  selector: 'hourglass',
  templateUrl: './hourglass.component.html',
  styleUrls: ['./hourglass.component.scss'],
})
export class HourglassComponent implements OnInit, OnDestroy {
  @Input()
  timeout: number;


  generateTimeout = null;
  generateCheckTimerSub = null;

  timeoutData = {
    presetValue: 20000, // milliseconds
    displayValue: 0,
    displayValueInverse: 0,
    displayValueInversePercent: 0,
    startTime: null,
    endTime: null
  };

  constructor() {

  }

  ngOnInit() {
    this.timeoutData.presetValue = this.timeout;
    this.initTimer();
  }

  initTimer() {
    let timer1 = timer(0, 100);
    let t0: number = new Date().getTime();

    this.timeoutData.startTime = t0;
    this.timeoutData.endTime = t0 + this.timeout;

    this.generateCheckTimerSub = timer1.subscribe(() => {
      let currentTime: number = new Date().getTime();
      this.timeoutData.displayValue = this.timeoutData.endTime - currentTime;

      this.timeoutData.displayValueInverse = currentTime - this.timeoutData.startTime;
      this.timeoutData.displayValueInversePercent = this.timeoutData.displayValueInverse * 100 / this.timeoutData.presetValue;

      // console.log(this.timeoutData.displayValueInversePercent);
      if (this.timeoutData.displayValueInversePercent > 100) {
        this.timeoutData.displayValueInversePercent = 100;
      }
      if (this.timeoutData.displayValue < 0) {
        this.timeoutData.displayValue = 0;
      }
    });
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.
    ResourceManager.clearSub(this.generateCheckTimerSub);
  }
}
