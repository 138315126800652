export enum EMenuCommand {
  logout = 1,
  exit = 2
}

export interface IPageInterface {
  // title as shown in the menu
  title: string;
  // unique identifier
  name: string;
  url: string;
  icon: string;
  cmd?: number;
  index?: number;
  tabName?: string;
  tabComponent?: any;
  loginRequired?: boolean;
  enableDrawer: boolean;
  enabled?: any;
  customIcon?: boolean;
  transparent?: boolean;
  fullView?: boolean;
}

export interface IMenuInterface {
  name: string;
  pages: IPageInterface[];
}



export enum ERouteDef {
  landing = "landing",
  home = "home",
  about = "about",
  account = "account",
  accountDetails = "account-details",
  character = "character",
  arViewEntry = "ar-view-entry",
  eventsList = "events-list",
  eventsHome = "events-home",
  // modal only
  eventsJoin = "events-join",

  supportHome = "support",
  supportFaq = "support-faq",
  supportChat = "support-chat",

  extras = "extras",
  gmap = "gmap",
  gmapDetail = "gmap-detail",
  inventory = "inventory",
  inventoryDetail = "inventory-detail",
  leaderboard = "leaderboard",
  leaderboardExt = "leaderboard-ext",
  login = "login",
  mpHome = "mp-home",
  mpCreate = "mp-create",
  mpJoin = "mp-join",
  mpOptions = "mp-options",
  mpGroupsHome = "mp-groups",
  mpGroupsList = "mp-groups-list",
  mpGroupMembers = "mp-group-members",
  mpGroupChat = "mp-group-chat",
  newsfeed = "newsfeed",
  settings = "settings",
  signup = "signup",
  signupTerms = "signup-terms",
  signupTutorial = "signup-tutorial",
  storyCategories = "story-categories",
  storyList = "story-list",
  // storyList = "story-list/:id/:categoryCode",
  storyHome = "story-home",
  // storyHome = "story-home/:id/:storyId/:categoryCode/:dynamic",
  test = "test",
  treasureHome = "treasure-home",
  tutorial = "tutorial",
  teamStats = "team-stats"
}

