
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthCoreService } from '../general/auth-request/auth-core';
import { IUserAuthDetails } from '../../classes/def/user/general';
import { IObservableMultiplex, IMPRouteManager, IMPRouteHandle } from '../../classes/def/mp/subs';
import { ResourceManager } from '../../classes/general/resource-manager';
import { GenericQueueService } from '../general/generic-queue';
import { EQueues } from '../../classes/def/app/app';
import { IMessageQueueEvent } from 'src/app/classes/utils/queue';
import { IMPRequestContainer } from 'src/app/classes/def/mp/generic';
import { SettingsManagerService } from '../general/settings-manager';
import { ApiDef } from 'src/app/classes/app/api';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { AppSettings } from '../utils/app-settings';


export enum EWSStatus {
    connected = 1,
    error = 2,
    aborted = 3,
    disconnected = 4
}

@Injectable({
    providedIn: 'root'
})
/**
 * provides an interface to websockets
 * handles low level functionality and wraps the messaging functionality into bahavior subjects
 */
export class WebsocketDataService {
    serverUrl: string;
    wsDataObservable: IObservableMultiplex = {};
    wsStatusObservable: IObservableMultiplex = {};
    wsErrorObservable: IObservableMultiplex = {};

    /**
     * any number of routes can be created dynamically
     */
    ws: IMPRouteManager = {

    };

    simulateConnectionLostGlobal: boolean = false;


    constructor(
        public authCore: AuthCoreService,
        public q: GenericQueueService
    ) {
        console.log("websocket data service created");
        this.serverUrl = null;
    }

    /**
     * add the data as object to the sending queue
     * the queue will return the result as a callback 
     * the return data encapsulates the state (ok/error) and the associated data
     * insert auth token or remove it as required
     * @param data 
     * @param withAuthToken 
     * @param highPriority retry count
     */
    sendQ(data: IMPRequestContainer<any>, route: string, withAuthToken: boolean, highPriority: boolean, errorCallback: (err: any) => any, testId: string) {
        let promise = new Promise((resolve, reject) => {
            if (!this.ws[route]) {
                reject(new Error("ws channel not initialized"));
                return;
            }
            this.ws[route].sendRetryEnable = true;
            // console.log("sendQ: ", data);
            // console.log("request send: ", this.ws[route].txCounter + 1);
            let retryCount: number = 60;
            if (!highPriority) {
                retryCount = 0;
            }
            // retryCount = 0;
            this.q.enqueueWithDataSnapshot((data) => {
                return this.sendRetryCore(data, this.ws[route], withAuthToken, retryCount, errorCallback, testId);
            }, (result: IMessageQueueEvent) => {
                // console.log(result);
                if (result.state) {
                    // console.log(result.data);
                    // if (result.data && result.data.message) {
                    //     // console.log("sent: ", this.ws[route].txCounter);
                    //     resolve(result.data.message);
                    // } else {
                    //     reject(new Error("connection closed by the user"));
                    // }
                    resolve(result.data);
                } else {
                    if (result.data && result.data.message) {
                        reject(result.data.message);
                    } else {
                        reject(new Error("connection state error"));
                    }
                }
            }, testId, data, EQueues.messaging, {
                size: 50,
                delay: null,
                timeout: 3000
            }, 10000);
        });
        return promise;
    }

    /**
     * send the data as object
     * insert auth token or remove it as required
     * should be called only after the previous request has finished
     * so there needs to be a queue that calls this method !!!
     * @param data 
     * @param withAuthToken 
     */
    sendRetryCore(data: IMPRequestContainer<any>, routeHandle: IMPRouteHandle, withAuthToken: boolean, retryCount: number, errorCallback: (err: any) => any, _testId: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (!routeHandle) {
                reject(new Error("Socket not defined"));
                return;
            }

            if (!(routeHandle.socket)) {
                reject(new Error("Socket not initialized"));
                return;
            }

            // let dataSample: IMPGenericRequest = JSON.parse(JSON.stringify(data));
            let dataSample: IMPRequestContainer<any> = data;

            // console.log(new Date().toISOString() + " send retry core started " + testId);
            const fn = (limit: number) => {
                try {
                    if (!(routeHandle.socket && routeHandle.socket.OPEN)) {
                        throw new Error("Socket closed");
                    }
                    if (routeHandle.socket.CONNECTING) {
                        throw new Error("socket not connected");
                    }
                    if (routeHandle.simulateConnectionLost || this.simulateConnectionLostGlobal) {
                        throw new Error("connection loss");
                    }
                    routeHandle.socket.send(JSON.stringify(dataSample));
                    routeHandle.sendCount += 1;
                    // if no exception then the request is authenticated successfully
                    routeHandle.authenticated = true;
                    routeHandle.sendRetryTimeout = ResourceManager.clearTimeout(routeHandle.sendRetryTimeout);
                    // console.log(new Date().toISOString() + " sent " + _testId);
                    resolve(true);
                    // console.log("resolved");
                    return;
                } catch (e) {
                    errorCallback(e);
                    console.log("socket error " + _testId + ", retry:" + limit);
                    routeHandle.authenticated = false;
                    if (limit <= 0) {
                        reject(e);
                        return;
                    }
                    if (routeHandle.sendRetryEnable) {
                        if (routeHandle.sendRetryTimeout) {
                            reject(
                                new Error(`cannot set multiple requests in retry mode on the same route. 
                                           this is normally avoided by calling this function using a synchronized message queue`));
                            return;
                        }
                        routeHandle.sendRetryTimeout = setTimeout(() => {
                            routeHandle.sendRetryTimeout = null;
                            fn(limit - 1);
                        }, 500);
                    } else {
                        reject(e);
                        return;
                    }
                }
            };

            if (withAuthToken && !routeHandle.authenticated) {
                this.authCore.getUserIdAndToken().then((user: IUserAuthDetails) => {
                    dataSample.token = "Bearer " + user.token;
                    // setTimeout(() => {
                    //     fn(retryCount);
                    // }, 5000);
                    fn(retryCount);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                if (dataSample.token != null) {
                    dataSample.token = "";
                }
                // setTimeout(() => {
                //     fn(retryCount);
                // }, 5000);
                fn(retryCount);
            }
        });
        return promise;
    }

    /**
     * observe websocket receive data
     */
    observeRx(route: string) {
        return this.wsDataObservable[route];
    }

    observeStatus(route: string) {
        return this.wsStatusObservable[route];
    }

    /**
     * change server url
     * check direct connection
     * if direct, use provided url
     * else connect through gateway using secure connection wss
     * @param url 
     */
    changeServerUrl(url: string) {
        let useRedirect: boolean = false;
        if (useRedirect) {
            console.log("ws > change server url direct: ", url);
            this.serverUrl = url;
        } else {
            // http => ws
            // https => wss
            let mainServerURL: string = ApiDef.mainServerURL;
            let wsUrl: string = mainServerURL.replace("https://", "wss://").replace("http://", "ws://");
            this.serverUrl = wsUrl + "/ws";
            console.log("ws > change server url redirect: ", this.serverUrl);
        }
    }

    private waitSocketConnected(bsubStatus: BehaviorSubject<any>): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            let messageReceived = bsubStatus.subscribe((status: number) => {
                if (status != null) {
                    console.log("wait socket connected received: ", status);
                    switch (status) {
                        case EWSStatus.connected:
                            messageReceived = ResourceManager.clearSub(messageReceived);
                            resolve(true);
                            break;
                        case EWSStatus.aborted:
                            messageReceived = ResourceManager.clearSub(messageReceived);
                            reject(new Error("connection aborted"));
                            break;
                        default:
                            break;
                    }
                }
            }, (err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    /**
     * using behavior subject
     * .error will also close the behavior subject
     * the easy way is to use 2 bsubs one for data and one for errors and only use .next
     * returns promise to check when connected
     * @param route 
     * @param bsub 
     */
    private socketInit(routeHandle: IMPRouteHandle, bsub: BehaviorSubject<any>, bsubStatus: BehaviorSubject<any>, bsubError: BehaviorSubject<any>) {
        console.log("socket init: " + routeHandle.route);
        if (routeHandle.simulateConnectionLost || this.simulateConnectionLostGlobal) {
            // retry loop until connection resumed
            routeHandle.connectRetryTimeout = setTimeout(() => {
                console.log("socket init retry timeout");
                this.socketInit(routeHandle, bsub, bsubStatus, bsubError);
            }, 3000);
            return;
        }

        if (!(routeHandle.socket && routeHandle.retryConnectEnable)) {
            // console.log("socket will be created");
            bsub.next(null);
            bsubStatus.next(null);
            bsubError.next(null);
            routeHandle.socket = new WebSocket(this.serverUrl + routeHandle.route);
            routeHandle.socket.onopen = (e) => {
                console.log("socket onopen: " + routeHandle.route, e);
                bsub.next(null);
                bsubStatus.next(EWSStatus.connected);
            };

            routeHandle.socket.onclose = (e) => {
                console.log("socket onclose: " + routeHandle.route, e);
                bsubStatus.next(EWSStatus.disconnected);
                // attempt reconnect
                if (routeHandle.retryConnectEnable) {
                    this.resetSocketData(routeHandle);
                    routeHandle.socket = null;
                    routeHandle.connectRetryTimeout = setTimeout(() => {
                        console.log("socket init retry");
                        this.socketInit(routeHandle, bsub, bsubStatus, bsubError);
                    }, 3000);
                } else {
                    bsubStatus.next(EWSStatus.aborted);
                }
                // bsub.error(e);
                bsubError.next(e);
            };

            routeHandle.socket.onerror = (e) => {
                console.log("socket onerror: " + routeHandle.route, e);
                // bsub.error(e);
                bsubError.next(e);
                bsubStatus.next(EWSStatus.error);
            };

            routeHandle.socket.onmessage = (e) => {
                let dataObj = null;
                try {
                    // console.log("onmessage: ", e.data);
                    dataObj = JSON.parse(e.data);
                    // observer.next(dataObj);
                    bsub.next(dataObj);
                } catch (err) {
                    // observer.next(null);
                    // bsub.error(e);
                    bsubError.next(err);
                    bsubStatus.next(EWSStatus.error);
                }
            };
        }
    }

    /**
     * init socket route
     * wait for connection
     */
    socketWatch(route: string): Promise<boolean> {
        console.log("ws/watch: " + route);
        if (!this.wsDataObservable[route]) {
            // first time created and never disposed while the app is open
            // only the actual ws connection may be disposed
            this.wsDataObservable[route] = new BehaviorSubject(null);
            this.wsStatusObservable[route] = new BehaviorSubject(null);
            this.wsErrorObservable[route] = new BehaviorSubject(null);
        }
        if (!this.ws[route]) {
            console.log("new socket object");
            this.ws[route] = {
                route: "/" + route,
                connected: false,
                retryConnectEnable: true,
                inputSub: null,
                outputObs: null,
                connectRetryTimeout: null,
                sendRetryTimeout: null,
                sendRetryEnable: true,
                sendCount: 0,
                receiveCount: 0,
                authenticated: false,
                simulateConnectionLost: false,
                socket: null
            };
        } else {
            this.resetSocketData(this.ws[route]);
        }

        this.socketInit(this.ws[route], this.wsDataObservable[route], this.wsStatusObservable[route], this.wsErrorObservable[route]);
        return this.waitSocketConnected(this.wsStatusObservable[route]);
    }

    /**
     * reset socket data for when the connection is reset
     * @param routeHandle 
     */
    resetSocketData(routeHandle: IMPRouteHandle) {
        routeHandle.retryConnectEnable = true;
        routeHandle.authenticated = false;
        routeHandle.simulateConnectionLost = false;
    }

    simulateConnectionLost(route: string) {
        if (!AppSettings.testerMode) {
            console.warn("method only available for testers");
            return;
        }
        console.log(new Date().toISOString() + " connection lost " + route);
        if (this.ws[route]) {
            this.ws[route].simulateConnectionLost = true;
            this.simulateConnectionLostGlobal = true;
            this.socketToggleConnect(this.ws[route], false);
        }
    }

    simulateConnectionResume(route: string) {
        if (!AppSettings.testerMode) {
            console.warn("method only available for testers");
            return;
        }
        console.log(new Date().toISOString() + " connection resume " + route);
        if (this.ws[route]) {
            this.ws[route].simulateConnectionLost = false;
            this.simulateConnectionLostGlobal = false;
            this.socketToggleConnect(this.ws[route], true);
        }
    }

    private socketToggleConnect(routeHandle: IMPRouteHandle, conn: boolean) {
        if (!(routeHandle && routeHandle.socket)) {
            return;
        }
        console.log("socket toggle connect state open: ", routeHandle.socket.OPEN);
        if (conn) {
            if (!routeHandle.socket.OPEN) {
                // will be opened on retry connection                
                // routeHandle.socket = new WebSocket(this.serverUrl + routeHandle.route);
            }
        } else {
            if (routeHandle.socket.OPEN) {
                routeHandle.socket.close();
                routeHandle.socket = null;
            }
        }
    }

    resetQueue() {
        this.q.resetQueue(EQueues.messaging);
    }

    /**
     * deinit and close socket route
     * @param route 
     */
    closeSocket(route: string) {
        console.log("ws/clear: " + route);
        let routeHandle: IMPRouteHandle = this.ws[route];
        if (routeHandle) {
            if (routeHandle.socket) {
                routeHandle.socket.close();
            }

            routeHandle.retryConnectEnable = false;
            routeHandle.connected = false;
            routeHandle.connectRetryTimeout = ResourceManager.clearTimeout(routeHandle.connectRetryTimeout);
            routeHandle.sendRetryTimeout = ResourceManager.clearTimeout(routeHandle.sendRetryTimeout);
            routeHandle.socket = null;
        }
    }
}
