import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ParamHandler } from 'src/app/classes/general/params';
import { ModalController, IonSlides } from '@ionic/angular';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { IGenericSlideData } from 'src/app/classes/def/views/slides';
import { IGenericSlidesNavParams, ISliderOptions } from 'src/app/classes/def/dynamic/slides';
import { ResourceManager } from 'src/app/classes/general/resource-manager';

@Component({
  selector: 'modal-slides-selector',
  templateUrl: './slides-selector.component.html',
  styleUrls: ['./slides-selector.component.scss'],
})
export class SlidesSelectorViewComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('sliderContainer', { read: ElementRef, static: false }) sliderContainer: ElementRef;
  slides: IonSlides;
  title: string = "";
  vs: IViewSpecs = ViewSpecs.getDefault();
  np: INavParams = null;
  loaded: boolean = false;
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  showLeftButton: boolean = true;
  showRightButton: boolean = true;

  slidesContent: IGenericSlideData[] = [];
  selectedIndex: number = 0;

  sliderOptions: ISliderOptions = {
    slidesPerView: 1,
    initialSlide: 0
  };

  isLastSlide: boolean = false;

  timeout = {
    sliderInit1: null,
    sliderInit2: null
  };

  constructor(
    public modalCtrl: ModalController
  ) { }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);
      let params: IGenericSlidesNavParams = np.params;
      this.title = params.title;

      this.slidesContent = params.slides;
    }
  }

  ngAfterViewInit() {
    this.initSlides();
  }

  ngOnDestroy() {
    ResourceManager.clearTimeoutObj(this.timeout);
  }


  initSlides() {
    this.timeout.sliderInit1 = setTimeout(() => {
      this.loaded = true;
      this.timeout.sliderInit2 = setTimeout(() => {
        this.slides = this.sliderContainer.nativeElement.children[0];
        this.slides.update().then(() => {

        }).catch((err) => {
          console.error(err);
        });
      }, 100);
    }, 100);
  }

  submitSelection() {
    this.dismiss(null);
  }

  // Method executed when the slides are changed
  slideChanged(): void {
    this.slides.getActiveIndex().then((currentIndex: number) => {
      // this.showLeftButton = currentIndex !== 0;
      this.selectedIndex = currentIndex;
      this.isLastSlide = false;
      this.slides.length().then((slidesLength: number) => {
        if (currentIndex + this.sliderOptions.slidesPerView >= slidesLength) {
          this.isLastSlide = true;
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * Method that shows the next slide
   * update: next GROUP of slides
   */
  public slideNext(): void {
    console.log("slide next");
    this.slides.getActiveIndex().then((currentIndex: number) => {
      // this.showLeftButton = currentIndex !== 0;
      this.selectedIndex = currentIndex;
      // let isLastSlide: boolean = false;
      this.slides.length().then((slidesLength: number) => {
        let slideToIndex: number = currentIndex + this.sliderOptions.slidesPerView;
        if (slideToIndex > slidesLength - 1) {
          slideToIndex = slidesLength - 1;
        }
        this.slides.slideTo(slideToIndex).then(() => {

        }).catch((err: Error) => {
          console.error(err);
        });
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * Method that shows the previous slide
   * update: previous GROUP of slides
   */
  public slidePrev(): void {
    if (!this.slides) {
      return;
    }
    console.log("slide prev");
    this.slides.getActiveIndex().then((currentIndex: number) => {
      let slideToIndex: number = currentIndex - this.sliderOptions.slidesPerView;
      if (slideToIndex < this.sliderOptions.slidesPerView - 1) {
        slideToIndex = 0;
      }
      this.slides.slideTo(slideToIndex).then(() => {

      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  dismiss(data: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(data).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }
}
