<div *ngIf="show" class="flex-card place-ads-card bg-container bg-container-fill item-border-radius"
  [ngClass]="{'bg-filter-2': !nofilter}"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: (placeAux.photoUrlCached ? placeAux.photoUrlCached : placeAux.photoUrl)}">
  <!-- [@showState]="showState" -->
  <div class="content" fxLayout="column">
    <ion-grid class="full-w grid-center-absolute">

      <ion-row>
        <!-- <ion-col size="12">
          <span class="info-text-m bold text-color-element" [ngClass]="{'text-color-overlay': nofilter}">
            {{placeName}}
          </span>
        </ion-col> -->
        <ion-col size="12" [ngClass]="{'text-div-container': nofilter}">
          <div class="info-text-m bold" [ngClass]="{'text-color-overlay': nofilter}" [innerHtml]="placeName"></div>
        </ion-col>

      </ion-row>
      <ion-row>

        <ion-col size="12">
          <span class="info-text-xs bold uppercase text-color-element" [ngClass]="{'text-color-overlay': nofilter}">
            {{placeType}}
          </span>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="4">
          <ion-button class="action-button-xs button-color-primary-70" [disabled]="!placeAux.mapsURL"
            (click)="openURL(placeAux.mapsURL)">
            <!-- <img [src]="appIcons.maps" height="30"> -->
            <icon-wrapper [icon]="appIcons.googleMaps" [custom]="true"></icon-wrapper>
          </ion-button>
        </ion-col>
        <ion-col size="4">

        </ion-col>
        <ion-col size="4">
          <ion-button class="action-button-xs button-color-alternate" [disabled]="!enableBusinessFeaturesAction"
            (click)="viewSales()">
            <icon-wrapper slot="icon-only" icon="basket"></icon-wrapper>
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="placeRating > 0">
        <ion-col size="12">
          <div class="center-text" [ngClass]="{'text-color-overlay': nofilter}">
            <!-- <rating class="rating-view" [(ngModel)]="placeRating" [max]="5" [float]="true" [readonly]="true">
            </rating> -->
            <div class="center-flex padding-s">
              <ngx-stars [readonly]="true" [size]="1" [color]="'#fcd182'" [initialStars]="placeRating" [maxStars]="5">
              </ngx-stars>
            </div>
          </div>
        </ion-col>
      </ion-row>

    </ion-grid>
  </div>
</div>