import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IEventDetails } from 'src/app/classes/def/events/events';

@Component({
  selector: 'event-info',
  templateUrl: './event-info.component.html',
  styleUrls: ['./event-info.component.scss'],
})
export class EventInfoComponent implements OnInit, OnDestroy {

  @Input()
  data: IEventDetails;

  constructor() {

  }

  ngOnInit() {
    if (this.data) {
      console.log(this.data);
    }
  }

  ngOnDestroy() {

  }
}
