import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { PhotoViewService } from 'src/app/services/general/apis/photo-view';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { IPhotoValidationRequirementsViewParams, PhotoValidationRequirementsViewComponent } from 'src/app/modals/app/modals/photo-validation-requirements/photo-validation-requirements.component';
import { PhotosService } from 'src/app/services/media/photos';
import { IPhotoResult } from 'src/app/classes/def/media/photo';
import { IPhotoCardItem } from 'src/app/classes/def/items/photos';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';


@Component({
  selector: 'grid-photo-uploads',
  templateUrl: './grid-photo-uploads.component.html',
  styleUrls: ['./grid-photo-uploads.component.scss'],
})
export class GridPhotoUploadsComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  data: IPhotoCardItem[];

  @Input()
  reload: boolean;

  @Input()
  blanks: boolean;

  @Input()
  enable: boolean;

  @Input()
  emptyMessage: string;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  availableItems: boolean = false;
  itemGrid: Array<Array<IPhotoCardItem>>;
  rowSpec: number = 2;

  appIconsStandard = EAppIconsStandard;
  appIcons = EAppIcons;

  /**
   * sync blink
   * between all items on the grid
   */
  blinkTimerSub: BehaviorSubject<boolean>;

  timerSub: any;

  update: boolean = false;

  constructor(
    public uiext: UiExtensionService,
    public photoViewer: PhotoViewService,
    public photoProvider: PhotosService
  ) {
    this.blinkTimerSub = new BehaviorSubject(null);
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'data':
          case 'reload':
            this.updateItemGrid(this.data);
            break;
        }
      }
    }
  }

  ngOnDestroy() {

  }

  uploadNewPhoto(item: IPhotoCardItem) {
    console.log("upload new photo: ", item);
  }

  viewPhotoCard(item: IPhotoCardItem) {
    let modalParams: IPhotoValidationRequirementsViewParams = {
      descriptionExtra: item.description,
      description: "Upload a photo to match the description or photo reference",
      title: "Photo Quest",
      photoUrl: item.ref,
      uploadedPhotoUrl: item.upload,
      buttonText: "Take photo",
      activity: null,
      uploadFromGallery: true,
      enable: this.enable
    };

    this.uiext.showCustomModal(null, PhotoValidationRequirementsViewComponent, {
      view: {
        fullScreen: false,
        transparent: false,
        large: true,
        addToStack: true,
        frame: false
      },
      params: modalParams
    }).then((data: number) => {
      if (data === EAlertButtonCodes.ok) {
        this.photoProvider.getPhotoFromCameraWizard(false, false).then((photo: IPhotoResult) => {
          item.updated = true;
          item.upload = photo.data;
          item.ts = new Date();
          this.update = !this.update;
          this.action.emit(item);
        }).catch((err: Error) => {
          console.error(err);
        });
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngOnInit() {
    // console.log(this.data);
    if (this.data) {
      this.updateItemGrid(this.data);
      console.log(this.itemGrid);
    }
  }

  /**
   * update item grid for display
   * @param category 
   */
  updateItemGrid(items: IPhotoCardItem[]) {
    // console.log("update item grid");
    let rowNum: number = 0; // counter to iterate over the rows in the grid

    this.availableItems = items && (items.length > 0);
    if (!this.availableItems) {
      return;
    }

    this.itemGrid = Array(Math.ceil(items.length / this.rowSpec));

    // add rows
    for (let i = 0; i < items.length; i += this.rowSpec) {
      this.itemGrid[rowNum] = Array(this.rowSpec); // declare two elements per row
      // add items
      for (let j = 0; j < this.rowSpec; j++) {
        if (items[i + j]) {
          this.itemGrid[rowNum][j] = items[i + j];
        } else {
          if (this.blanks) {
            this.itemGrid[rowNum][j] = null;
          } else {
            this.itemGrid[rowNum].splice(j);
          }
        }
      }

      rowNum++; // go on to the next row
    }
  }

}
