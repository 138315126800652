
import { Injectable } from '@angular/core';
import { SettingsManagerService } from '../settings-manager';
import { BehaviorSubject } from 'rxjs';
import { AdsDef, ERewardExitCodes } from '../../apis/ads-def';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { WaitUtils } from '../../utils/wait-utils';
import { AdsTemplateService } from './ads-template';

declare var cordova: any;

// this is somehow exported and available to use directly
declare var AdColony: any;

@Injectable({
    providedIn: 'root'
})
export class AdColonyAdsService implements AdsTemplateService {

    reward: boolean = false;

    watchRewardCallback: BehaviorSubject<number>;

    tryAgainCounter: number = 0;
    maxRetry: number = 5;

    initialized: boolean = false;

    constructor(
        private settingsProvider: SettingsManagerService
    ) {
        console.log("adcolony ads service created");
        this.watchRewardCallback = new BehaviorSubject(null);
    }

    /**
     * load ad ids, os based
     */
    setAdIds(_testing: boolean) {

    }

    setup() {
        if (!AdColony) {
            console.warn("AdColony not installed");
            return;
        }
        this.loadCallbacks();
        this.setupAdc();
        this.initialized = true;
        console.log("ads/adcolony setup complete");
    }


    setupAdc() {
        cordova.plugins.AdColony = AdColony;
        if (window["plugins"]) {
            window["plugins"]["AdColony"] = AdColony;
        }
        // AdColony Constants
        // User Meta-data values
        const USER_MALE = "male";
        const USER_FEMALE = "female";
        const USER_SINGLE = "single";
        const USER_MARRIED = "married";
        const USER_EDUCATION_GRADE_SCHOOL = "grade_school";
        const USER_EDUCATION_SOME_HIGH_SCHOOL = "some_high_school";
        const USER_EDUCATION_HIGH_SCHOOL_DIPLOMA = "high_school_diploma";
        const USER_EDUCATION_SOME_COLLEGE = "some_college";
        const USER_EDUCATION_ASSOCIATES_DEGREE = "associates_degree";
        const USER_EDUCATION_BACHELORS_DEGREE = "bachelors_degree";
        const USER_EDUCATION_GRADUATE_DEGREE = "graduate_degree";

        // Example of receiving a reward notification
        AdColony.onRewardReceived = (reward) => {
            console.log('Reward received :' + JSON.stringify(reward));
            this.reward = true;
            this.watchRewardCallback.next(ERewardExitCodes.reward);
        };

        // Example of setting
        // AdColony.setAdOptions({
        //     confirmation_enabled: false,
        //     results_enabled: true
        // });

        // AdColony.setUserMetaData({
        //     adc_age: 28,
        //     adc_gender: USER_MALE,
        //     adc_marital_status: USER_MARRIED, // Or "married"
        //     adc_education: USER_EDUCATION_BACHELORS_DEGREE,
        //     adc_household_income: 0.0,
        //     adc_zip: "WD245GJ",
        //     adc_interests: "golf"
        // });

        // // Optionally check which platform you have and pass the APP_ID and ZONE_ID here
        // // Example of setting App options
        // var appOptions = [{ // See AdColonyAppOptions
        //     origin_store: "google",
        //     app_orientation: "0",
        //     orientation: "1"
        // }];


        let appOptions = null;

        switch (GeneralCache.os) {
            case EOS.ios:
                // AdColony.configureWithAppID('app185a7e71e1714831a49ec7', 'vz1fd5a8b2bf6841a0a4b826', appOptions);
                AdColony.configureWithAppID(AdsDef.adColony.ios.appId, [AdsDef.adColony.ios.zoneId], appOptions);
                break;
            case EOS.android:
                // AdColony.configureWithAppID('appbdee68ae27024084bb334a', 'vzf8e4e97704c4445c87504e', appOptions);
                AdColony.configureWithAppID(AdsDef.adColony.android.appId, [AdsDef.adColony.android.zoneId], appOptions);
                break;
        }

        console.log("configured adcolony ads");
    }

    loadCallbacks() {
        // These are examples of the events that occur. Use them to tune your UI (e.g. enable/disable buttons etc.)
        document.addEventListener('ConfigureSuccess', () => {
            console.log('AdColonyPlugin: Configuration successfully completed');
            document.getElementById('info').innerHTML = "Config Done";
            // Register the above method as the reward receiver
            AdColony.registerRewardReceiver('AdColony.onRewardReceived');
            AdColony.requestInterstitialInZone();
        });
        document.addEventListener('AdColonyRequestSubmitted', () => {
            console.log('AdColonyPlugin: Interstitial ad request submitted');
            document.getElementById('info').innerHTML = "Ad Requested";
            this.watchRewardCallback.next(ERewardExitCodes.start);
        });
        document.addEventListener('AdColonyRequestFilled', () => {
            console.log('AdColonyPlugin: Interstitial ad loaded :');
            document.getElementById('info').innerHTML = "Ad Ready";
            this.watchRewardCallback.next(ERewardExitCodes.load);
        });
        document.addEventListener('AdColonyRequestNotFilled', (error) => {
            console.log('AdColonyPlugin: Request Failed :');
            this.tryAgainCounter += 1;
            if (this.tryAgainCounter <= this.maxRetry) {
                // Failed so retry after 3 seconds
                setTimeout(() => {
                    AdColony.requestInterstitialInZone();
                }, 3000);
            } else {
                this.watchRewardCallback.next(ERewardExitCodes.fail);
            }
        });
        document.addEventListener('AdColonyRequestOpened', () => {
            console.log('AdColonyPlugin: Interstitial ad opened :');
            this.tryAgainCounter = 0;
            this.watchRewardCallback.next(ERewardExitCodes.open);
        });
        document.addEventListener('AdColonyRequestClosed', () => {
            console.log('AdColonyPlugin: Interstitial ad closed :');
            document.getElementById('info').innerHTML = "Ad Done";
            // Closed, so get another one in the meantime
            AdColony.requestInterstitialInZone();
            this.watchRewardCallback.next(ERewardExitCodes.close);
        });
        document.addEventListener('AdColonyRequestExpiring', () => {
            console.log('AdColonyPlugin: Interstitial ad expired :');
            document.getElementById('info').innerHTML = "Ad Expired";
            AdColony.requestInterstitialInZone();
        });

        console.log("registered adcolony ads callbacks");
    }

     /**
     * resolve reward state
     * null if failed to load
     */
    handleRewardVideo(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            if (!this.initialized) {
                console.error("AdColony not initialized");
                resolve(null);
                return;
            }
            this.reward = false;
            AdColony.showWithPresentingViewController();
            let returnCode: number = await WaitUtils.waitObsAnyTimeout(this.watchRewardCallback, [ERewardExitCodes.close, ERewardExitCodes.fail], null);
            if (returnCode === ERewardExitCodes.fail) {
                resolve(null);
                return;
            }
            resolve(this.reward);
        });
        return promise;
    }

    showBanner(): Promise<boolean> {
        console.warn("not implemented");
        return Promise.resolve(false);
    }

    showInterstitial(): Promise<boolean> {
        console.warn("not implemented");
        return Promise.resolve(false);
    }


    /**
     * check consent, update ad config to include the npa flag
     * @param consentStatus 
     */
    updateConsent(_consentStatus: boolean) {
        console.warn("not implemented");
    }
}
