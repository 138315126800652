import { Injectable } from '@angular/core';
import { IMPRouteDef, IMPRouteHandleDef, ISubMultiplex } from '../../../classes/def/mp/subs';
import { WebsocketDataService, EWSStatus } from '../../data/websocket';
import { IMPStatusTxContainer, IMPStatusRxContainer, IMPStatusRxData } from '../../../classes/def/mp/status';
import { IMPMessageTxContainer, IMPMessageRxContainer, IMPMessageRxSyncContainer } from '../../../classes/def/mp/message';
import { LocationMonitorService } from '../../map/location-monitor';
import { EGroupRole, IGroup, IGroupMember, EArenaEvent, GroupDynamic, IGroupDynamic, EArenaParams } from '../../../classes/def/mp/groups';
import { IChatElement } from "../../../classes/def/mp/chat";
import { ResourceManager } from '../../../classes/general/resource-manager';

import { IMPStatusDB } from '../../../classes/def/mp/status';
import { IMPMessageDB } from '../../../classes/def/mp/message';
import { AuthCoreService } from '../../general/auth-request/auth-core';
import { IUserAuthDetails, IUserPublicData } from '../../../classes/def/user/general';
import { BehaviorSubject } from 'rxjs';
import { GeneralUtils } from '../../../classes/utils/general';
import { EMPUserInputCodes, EMPMessageCodes, EMPVirtualGroupCodes, EMPEventSource, MPEncoding, EMemberStates, ELeaderStates, EMPVirtualMemberCodes } from '../../../classes/def/mp/protocol';
import { IChatModalNavParams } from '../../../classes/def/nav-params/arena';
import { GeneralCache } from '../../../classes/app/general-cache';
import { UiExtensionService } from '../../general/ui/ui-extension';
import { LocalNotificationsService } from '../../general/apis/local-notifications';
import { MPUtils } from './mp-utils';
import { IMPEventContainer } from '../../../classes/def/mp/events';
import { IMPMessageDataChat } from '../../../classes/def/mp/message-data';
import { MPDataService } from '../../data/multiplayer';
import { ChatViewComponent } from 'src/app/modals/app/modals/chat/chat.component';
import { IMPRequestContainer, EMPScope, IMPResponseContainer, EResponseMessageTypeContainer, EMPContext, EMPStatusContext } from 'src/app/classes/def/mp/generic';
import { SleepUtils } from '../../utils/sleep-utils';
import { IMPMeetingPlace } from 'src/app/classes/def/mp/meeting-place';
import { SoundManagerService } from '../../general/apis/sound-manager';
import { DroneSimulatorService } from '../../map/drone-simulator';
import { IMPGenericGroupStat } from 'src/app/classes/def/mp/game';
import { ILatLng } from 'src/app/classes/def/map/coords';


@Injectable({
    providedIn: 'root'
})
export class MPManagerService {
    routes: IMPRouteDef = {
        play: {
            route: "play",
            connected: false
        }
    };

    updateDelay: number = 1000;
    pingDelay: number = 5000;
    lastPingTs: number = null;

    timeout = {
        retryConnect: null,
        statusFeed: null,
        messageFeedConnect: null,
        selfStateTransition: null,
        ping: null
    };


    playerId: number = 0;
    playerIdLoaded: boolean = false;

    statusRequest: IMPStatusTxContainer = {
        data: {
            playerId: 3,
            context: EMPStatusContext.GROUP,
            contextId: 2,
            // meetingPlaceId: null,
            // groupId: null,
            lat: null,
            lng: null,
            droneLat: null,
            droneLng: null,
            isOnline: true,
            type: EGroupRole.member
        },
        aux: {
            messageOffset: 0,
            lastTimestamp: 0
        }
    };

    messageRequest: IMPMessageTxContainer = {
        data: {
            playerId: 3,
            context: EMPStatusContext.GROUP,
            contextId: 2,
            // groupId: null,
            // meetingPlaceId: null,
            type: -1,
            data: {

            }
        },
        aux: {
            messageOffset: 0,
            lastTimestamp: 0
        }
    };

    observables = {
        state: null as BehaviorSubject<number>,
        externalInput: null,
        arenaEvent: null,
        chat: null,
        chatSync: null,
        playerIdLoaded: null,
        chatInput: null,
        status: null,
        groupStatus: null as BehaviorSubject<IMPGenericGroupStat>,
        message: null
    };

    subscription: ISubMultiplex = {
        state: null,
        externalInput: null,
        stateTransitionWatch: null,
        playRx: null,
        playerIdLoaded: null,
        chatInput: null,
        status: null,
        reloadRequired: null
    };

    groupScope: IGroup = null;
    isOnline: boolean = false;
    meetingPlaceScope: IMPMeetingPlace = null;
    initialized: boolean = false;
    sessionStarted: boolean = false;

    // not working yet
    withExtraSync: boolean = false;

    testCounterStatus: number = 0;
    testCounterMessage: number = 0;
    chatHistory: IMPMessageDB[] = [];
    state: number = 0;
    stateParams: any = null;
    stateBeforeDisconnectHandle: number = 0;
    entry: boolean = false;
    role: number = EGroupRole.member;
    chatOpen: boolean = false;

    connected: boolean = false;
    canExit: boolean = false;
    context: number = EMPContext.GROUP;
    messageHistory: IMPMessageDB[] = [];
    synchronized: boolean = true;
    useTimeoutAutoStateSwitch: boolean = true;

    constructor(
        public wsProvider: WebsocketDataService,
        public locationMonitor: LocationMonitorService,
        public authCore: AuthCoreService,
        public uiext: UiExtensionService,
        public localNotifications: LocalNotificationsService,
        public mpDataService: MPDataService,
        public droneSimulator: DroneSimulatorService,
        public soundManager: SoundManagerService
    ) {
        console.log("mp manager service created");
        this.observables = ResourceManager.initBsubObj(this.observables);
    }

    connectGroupModeWizard(group: IGroup, role: number, synchronized: boolean) {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            await this.loadAuthDataResolve();
            this.setGroupScope(group);
            this.switchGroupMode(false);
            await this.start(synchronized);
            this.initStateMachine(role);
            resolve(true);
        });
        return promise;
    }

    /** 
     * set player id for real time data transfer
     * the same as user id from the local storage
     * @param playerId 
     */
    loadAuthDataResolve(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            console.log("mp-manager/load auth data");
            this.authCore.getUserIdAndToken().then((response: IUserAuthDetails) => {
                if (response) {
                    this.statusRequest.data.playerId = response.id;
                    this.messageRequest.data.playerId = response.id;
                    this.playerId = response.id;
                    this.playerIdLoaded = true;
                    this.observables.playerIdLoaded.next(true);
                }
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
        return promise;
    }

    /**
     * open sockets
     * start automated tasks e.g. status updates
     */
    start(synchronized: boolean): Promise<boolean> {
        this.synchronized = synchronized;
        return this.startMode(false);
    }

    /**
     * start only map sync service
     */
    startSyncOnly(): Promise<boolean> {
        return this.startMode(true);
    }


    startMode(syncOnly: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            this.sessionStarted = true;
            this.canExit = false;
            console.log("mp-manager/start");
            console.log("sychronized: ", this.synchronized);
            let keys: string[] = Object.keys(this.observables);
            // reset observables
            for (let key of keys) {
                this.observables[key].next(null);
            }
            this.messageRequest.aux.messageOffset = 0;
            this.connected = false;
            await this.openSocketsResolve();
            this.watchService(syncOnly);
            await SleepUtils.sleep(100);
            this.runStatusTxCore();
            this.runStatusTxLoop();
            this.canExit = true;
            this.setOnlineStatus(true);
            this.watchGroupReloadRequired();
            resolve(true);
        });
        return promise;
    }

    switchMeetingPlaceMode() {
        this.context = EMPContext.MEETING_PLACE;
    }

    switchGroupMode(tempGroup: boolean) {
        if (!tempGroup) {
            this.context = EMPContext.GROUP;
        } else {
            this.context = EMPContext.MEETING_PLACE;
        }
    }

    changeServerUrl(url: string) {
        this.wsProvider.changeServerUrl(url);
    }

    /**
     * init all websocket routes
     */
    private openSocketsResolve(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let proms: Promise<boolean>[] = [];

            let keys: string[] = Object.keys(this.routes);
            for (let key of keys) {
                proms.push(new Promise((resolve, reject) => {
                    this.wsProvider.socketWatch(this.routes[key].route).then((res) => {
                        resolve(res);
                    }).catch((err) => {
                        reject(err);
                    });
                }));
            }

            Promise.all(proms).then(() => {
                resolve(true);
            }).catch((err) => {
                console.error(err);
                resolve(false);
            });
        });
        return promise;
    }

    /**
     * disconnect from all sockets (routes)
     */
    private closeSockets() {
        let keys: string[] = Object.keys(this.routes);
        for (let key of keys) {
            this.disconnectCore(this.routes[key]);
        }
    }

    /**
     * dispose all resources and exit MP mode
     */
    quitSession() {
        console.log("mp-manager/quit session");
        this.wsProvider.resetQueue();
        this.closeSockets();
        this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
        this.subscription = ResourceManager.clearSubObj(this.subscription);
        this.chatHistory = [];
        this.messageHistory = [];
        this.connected = false;
        this.sessionStarted = false;
        this.setOnlineStatus(false);
        this.observables.groupStatus.next(null);
    }

    /**
     * disconnect from the specified route handle
     * @param routeHandle 
     */
    private disconnectCore(routeHandle: IMPRouteHandleDef) {
        console.log("disconnect: ", routeHandle.route);
        routeHandle.connected = false;
        this.wsProvider.closeSocket(routeHandle.route);
    }

    /**
     * get message observable
     * listening for messages on the /play route
     */
    watchPlayWS() {
        return this.wsProvider.observeRx(this.routes.play.route);
    }

    watchPlayStatus() {
        return this.wsProvider.observeStatus(this.routes.play.route);
    }

    watchRawStatusUpdates(): BehaviorSubject<IMPStatusRxData> {
        return this.observables.status;
    }

    watchGroupReloadRequired() {
        this.subscription.reloadRequired = ResourceManager.clearSub(this.subscription.reloadRequired);
        this.subscription.reloadRequired = this.watchEventMux().subscribe((gs: IMPEventContainer) => {
            if (gs != null) {
                if (gs.code === MPEncoding.encodeEvent(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.newMemberDetected, null).code) {
                    console.log("watch virtual group state reload required");
                    this.reloadGroupOnUpdate();
                }
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    reloadGroupOnUpdate() {
        let group: IGroup = this.groupScope;
        this.mpDataService.viewGroup(group.id, true).then((group: IGroup) => {
            MPUtils.formatGroupMembers(group, GeneralCache.userId);
            this.setGroupScope(group);
            console.log("reload group spec: ", group);
        }).catch((err) => {
            console.error(err);
        });
    }

    /**
     * get message observable
     * returns single messages (sync messages processed internally)
     */
    watchChatWS() {
        return this.observables.chat;
    }

    watchChatSyncWS() {
        return this.observables.chatSync;
    }

    watchMessageRx() {
        return this.observables.message;
    }

    /**
     * this should be called on chat open
     * so that there is no duplicate of the last received message
     */
    resetChatRx() {
        this.observables.chat.next(null);
        this.observables.chatSync.next(null);
    }


    /**
     * update user status
     * this will trigger the status response that will be received via observable
     * @param status 
     */
    updateStatusWS(status: IMPStatusTxContainer): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {

            let requestContainer: IMPRequestContainer<IMPStatusTxContainer> = {
                data: status,
                scope: EMPScope.STATUS,
                context: this.context,
                token: null
            };

            this.wsProvider.sendQ(requestContainer, this.routes.play.route, true, false, (_err) => {
                console.error(_err);
                this.sendArenaEvent(EArenaEvent.connectionProblem);
            }, "status " + this.testCounterStatus).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
    * send message to the group
    * @param message 
    */
    sendMessageWS(message: IMPMessageTxContainer) {
        let promise = new Promise((resolve, reject) => {

            let requestContainer: IMPRequestContainer<IMPMessageTxContainer> = {
                data: message,
                scope: EMPScope.MESSAGE,
                context: this.context,
                token: null
            };

            this.wsProvider.sendQ(requestContainer, this.routes.play.route, true, true, (_err) => {
                this.sendArenaEvent(EArenaEvent.connectionProblem);
            }, "message " + this.testCounterStatus).then(() => {
                // increment offset
                // the offset will be used when reconnecting, to get the latest data that was sent by the others while offline
                this.messageRequest.aux.messageOffset += 1;
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    sendSyncMessageRequestWS() {
        let promise = new Promise((resolve, reject) => {

            let requestContainer: IMPRequestContainer<IMPMessageTxContainer> = {
                data: null,
                scope: EMPScope.MESSAGE_SYNC,
                context: this.context,
                token: null
            };

            this.wsProvider.sendQ(requestContainer, this.routes.play.route, true, true, (_err) => {
                this.sendArenaEvent(EArenaEvent.connectionProblem);
            }, "message " + this.testCounterStatus).then(() => {
                // increment offset
                // the offset will be used when reconnecting, to get the latest data that was sent by the others while offline
                this.messageRequest.aux.messageOffset += 1;
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    getChatHistory() {
        return this.chatHistory;
    }

    /**
     * watch group status rx only
     */
    getGroupStatusObservable(): BehaviorSubject<IMPGenericGroupStat> {
        return this.observables.groupStatus;
    }

    /**
     * watch send from chat modal
     * send data to websocket on received
     */
    watchSendFromModal() {
        if (!this.subscription.chatInput) {
            this.subscription.chatInput = this.observables.chatInput.subscribe((data: IChatElement) => {
                // console.log("received input message: ", data);
                if (data) {
                    this.sendChatMessageWSNoAction(data);
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    /**
     * this can be called from external
     * used for modular interaction with the chat window
     */
    openChatWindow(initDispose: boolean, withChatHistory: boolean) {
        let promise = new Promise((resolve, reject) => {

            let chatHistory: IMPMessageDB[] = withChatHistory ? this.getChatHistory() : [];
            let chatHistory1: IChatElement[] = withChatHistory ? chatHistory.map(msg => {
                return MPUtils.getChatMessageFromMessageDB(msg);
            }) : [];

            this.resetChatRx();

            if (!this.groupScope && !this.meetingPlaceScope) {
                reject(new Error("chat scope undefined"));
                return;
            }

            let params: IChatModalNavParams = {
                title: this.groupScope != null ? "LP Chat (Team)" : "LP Chat (Lobby)",
                chatRx: chatHistory1,
                chatRxObservable: this.watchChatWS(),
                chatRxSyncObservable: withChatHistory ? this.watchChatSyncWS() : null,
                sendObservable: this.observables.chatInput,
                groupStatusObservable: this.observables.groupStatus,
                groupId: this.groupScope ? this.groupScope.id : null,
                groupName: this.groupScope ? this.groupScope.name : null,
                meetingPlaceId: this.meetingPlaceScope ? this.meetingPlaceScope.id : null,
                meetingPlaceName: this.meetingPlaceScope ? this.meetingPlaceScope.name : null,
                showChatHeads: true,
                context: this.context
            };

            this.watchSendFromModal();
            this.chatOpen = true;

            this.uiext.showCustomModal(null, ChatViewComponent, {
                view: {
                    fullScreen: true,
                    transparent: false,
                    large: true,
                    addToStack: true,
                    headerOpacity: false,
                    frame: false
                },
                params: params
            }).then((_data) => {
                console.log("mp-manager/chat window closed");
                this.sendArenaEvent(EArenaEvent.chatWindowClosed);
                this.stopWatchGroupChatInput();
                this.onCloseChatWindow(initDispose);
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                this.onCloseChatWindow(initDispose);
                reject(err);
            });
            // }).catch((err: Error) => {
            //     // console.log("SENDQ/err " + err.message);
            //     console.error(err);
            //     this.onCloseChatWindow(initDispose);
            //     this.uiext.showAlertNoAction(Messages.msg.mpConnectChatError.after.msg, Messages.msg.mpConnectChatError.after.sub);
            // });
        });
        return promise;
    }

    private onCloseChatWindow(initDispose: boolean) {
        if (initDispose) {
            this.quitSession();
        }
        this.chatOpen = false;
    }


    stopWatchGroupChatInput() {
        this.subscription.chatInput = ResourceManager.clearSub(this.subscription.chatInput);
        this.observables.chatInput.next(null);
    }


    /**
     * send group chat message
     * low priority
     * @param message 
     */
    sendChatMessageWS(message: IChatElement) {
        let promise = new Promise((resolve, reject) => {
            if (!message) {
                reject(new Error("Empty message"));
                return;
            }

            let chatMessage: IMPMessageTxContainer = {
                data: Object.assign(this.messageRequest.data),
                aux: Object.assign(this.messageRequest.aux)
            };

            chatMessage.data.type = message.type;

            let data: IMPMessageDataChat = {
                text: message.message
            };

            chatMessage.data.data = data;

            let requestContainer: IMPRequestContainer<IMPMessageTxContainer> = {
                data: chatMessage,
                scope: EMPScope.MESSAGE,
                context: this.context,
                token: null
            };

            this.wsProvider.sendQ(requestContainer, this.routes.play.route, true, false, (_err) => {
                console.log(_err);
            }, "message " + this.testCounterStatus).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * send group chat message
     * low priority
     * @param message 
     */
    sendChatMessageWSNoAction(message: IChatElement) {
        this.sendChatMessageWS(message).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * send message to the connected group by message code
     * @param code 
     * @param data additional params
     */
    dispatchMessage(code: number, data: any) {
        this.messageRequest.data.type = code;
        console.log("dispatch message: ", code, data);
        if (!data) {
            // special case for messages without real data
            this.messageRequest.data.data = {

            };
        } else {
            this.messageRequest.data.data = data;
        }
        return this.sendMessageWS(this.messageRequest);
    }

    dispatchMessageNoAction(code: number, data: any) {
        this.dispatchMessage(code, data).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }


    simulateConnectionLost() {
        this.wsProvider.simulateConnectionLost(this.routes.play.route);
    }

    simulateConnectionResume() {
        this.wsProvider.simulateConnectionResume(this.routes.play.route);
    }

    isSessionStarted() {
        return this.sessionStarted;
    }

    isGroupOnline() {
        return this.isOnline;
    }

    /**
     * handle all required initialization 
     * connect to mp service, ready
     * e.g. when only a chat window is required
     */
    async initSequence(groupScope: IGroup, groupRole: number, synchronized: boolean): Promise<boolean> {
        this.setGroupScope(groupScope);
        this.initStateMachine(groupRole);
        await this.start(synchronized);
        await SleepUtils.sleep(this.updateDelay);
        this.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.tapReady, null);
        return await SleepUtils.sleep(this.updateDelay);
    }

    setOnlineStatus(online: boolean) {
        if (this.groupScope != null) {
            this.isOnline = online;
        } else {
            console.warn("group scope undefined");
        }
    }

    /**
     * set current working group
     * @param group 
     */
    setGroupScope(group: IGroup) {
        if (!group) {
            return;
        }
        console.log("set group scope: ", group);
        this.groupScope = group;
        this.initialized = false;
        this.updateGroupMemberData(group.id, group.role, false);
    }

    setMeetingPlaceScope(meetingPlace: IMPMeetingPlace) {
        if (!meetingPlace) {
            return;
        }
        console.log("set meeting place scope: ", meetingPlace);
        this.meetingPlaceScope = meetingPlace;
        this.initialized = false;
        this.updateMeetingPlaceMemberData(meetingPlace.id);
    }


    getGroupScope() {
        return this.groupScope;
    }

    clearScope() {
        this.groupScope = null;
        this.meetingPlaceScope = null;
    }

    /**
     * get state watch to be in sync with the internal state
     * should be handled by a front-end state machine that will enable the required UI interactions
     */
    watchStateMachine(): BehaviorSubject<number> {
        return this.observables.state;
    }

    watchArenaEvent(): BehaviorSubject<number> {
        return this.observables.arenaEvent;
    }

    getCurrentState() {
        return this.state;
    }

    getCurrentStateParams() {
        return this.stateParams;
    }

    /**
     * update the current status data
     * that is sent periodically to the server
     * not sending the data immediately
     * @param groupId 
     * @param memberType 
     */
    updateGroupMemberData(groupId: number, memberType: number, tempGroup: boolean) {
        this.clearMemberContextData();

        if (tempGroup) {
            this.statusRequest.data.context = EMPStatusContext.TEMP_GROUP;
            this.messageRequest.data.context = EMPStatusContext.TEMP_GROUP;
        } else {
            this.statusRequest.data.context = EMPStatusContext.GROUP;
            this.messageRequest.data.context = EMPStatusContext.GROUP;
        }

        this.statusRequest.data.contextId = groupId;
        this.messageRequest.data.contextId = groupId;

        this.statusRequest.data.type = memberType;

        let upd: IUserPublicData = GeneralCache.resourceCache.user.general.content;
        if (upd != null) {
            this.statusRequest.data.playerName = upd.name;
            this.statusRequest.data.photoUrl = upd.photoUrl;
        }
    }

    updateMeetingPlaceMemberData(meetingPlaceId: number) {
        this.clearMemberContextData();

        this.statusRequest.data.context = EMPStatusContext.MEETING_PLACE;
        this.messageRequest.data.context = EMPStatusContext.MEETING_PLACE;

        this.statusRequest.data.contextId = meetingPlaceId;
        this.messageRequest.data.contextId = meetingPlaceId;

        let upd: IUserPublicData = GeneralCache.resourceCache.user.general.content;
        if (upd != null) {
            this.statusRequest.data.playerName = upd.name;
            this.statusRequest.data.photoUrl = upd.photoUrl;
        }
    }

    clearMemberContextData() {
        // clear context
        this.statusRequest.data.contextId = null;
        this.messageRequest.data.contextId = null;
        this.statusRequest.data.context = null;
        this.messageRequest.data.context = null;

        this.statusRequest.data.type = null;
        this.statusRequest.data.playerName = null;
        this.statusRequest.data.photoUrl = null;
    }

    /**
     * init status update thread
     */
    private runStatusTxLoop() {
        this.timeout.statusFeed = setTimeout(() => {
            this.runStatusTxCore();
            let ts: number = new Date().getTime();
            if ((this.lastPingTs == null) || ((ts - this.lastPingTs) >= this.pingDelay)) {
                this.lastPingTs = ts;
                this.dispatchMessageNoAction(EMPMessageCodes.ping, null);
            }
            this.runStatusTxLoop();
        }, this.updateDelay);
    }

    private runStatusTxCore() {
        let locationCrt: ILatLng = this.locationMonitor.getCachedLocationUnified();

        if (this.droneSimulator.simulationRunning) {
            this.statusRequest.data.droneLat = this.droneSimulator.droneStatus.location.lat;
            this.statusRequest.data.droneLng = this.droneSimulator.droneStatus.location.lng;
        } else {
            this.statusRequest.data.droneLat = null;
            this.statusRequest.data.droneLng = null;
        }

        if (locationCrt) {
            this.statusRequest.data.lat = locationCrt.lat;
            this.statusRequest.data.lng = locationCrt.lng;
        } else {
            this.statusRequest.data.lat = null;
            this.statusRequest.data.lng = null;
        }

        this.testCounterStatus += 1;
        // console.log("SENDQ/in");
        this.updateStatusWS(this.statusRequest).then(() => {
            // console.log("SENDQ/out");
            // notify the user about connection problems
            // in the background the message will be sent with a max retry count that covers 60 seconds
            this.checkConnectToMessageFeed();
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    private checkConnectToMessageFeed() {
        if (!this.connected) {
            this.connected = true;
            this.connectToMessageFeed();
        }
    }

    /**
     * connect to message feed
     * try until connected
     * the retry functionality is handled internally by the websocket provider
     */
    private connectToMessageFeed() {
        console.log("connecting to message feed");
        this.dispatchMessageNoAction(EMPMessageCodes.ping, null);

        // let message: IChatElement = {
        //     message: "",
        //     user: "",
        //     self: true,
        //     type: EMPMessageCodes.chat
        // };

        // switch (this.role) {
        //     case EGroupRole.leader:
        //         message.message = "#host";
        //         break;
        //     case EGroupRole.member:
        //         message.message = "#member";
        //         break;
        // }

        // this.timeout.ping = setTimeout(() => {
        //     this.sendChatMessageWSNoAction(message);
        // }, 2000);
    }


    dispachContextStatus() {
        switch (this.context) {
            case EMPContext.GROUP:
                this.dispatchGroupStatus();
                break;
            case EMPContext.MEETING_PLACE:
                this.dispatchMeetingPlaceStatus();
                break;
            default:
                break;
        }
    }

    dispatchGroupStatus() {
        MPUtils.formatGroupMembers(this.groupScope, GeneralCache.userId);
        console.log("dispatch group status: ", this.groupScope);
        let gstat: IMPGenericGroupStat = {
            group: this.groupScope,
            meetingPlace: null
        };
        this.observables.groupStatus.next(gstat);
    }

    dispatchMeetingPlaceStatus() {
        // MPUtils.formatGroupMembers(this.groupScope, GeneralCache.userId);
        console.log("dispatch meeting place status: ", this.meetingPlaceScope);
        let gstat: IMPGenericGroupStat = {
            group: null,
            meetingPlace: this.meetingPlaceScope
        };
        this.observables.groupStatus.next(gstat);
    }

    watchService(statusOnly: boolean) {
        console.log("mp watch service started");
        switch (this.context) {
            case EMPContext.GROUP:
                this.watchServiceGroupMode(statusOnly);
                break;
            case EMPContext.MEETING_PLACE:
                this.watchServiceMeetingPlaceMode();
                break;
            default:
                break;
        }

        this.watchServiceStatus();
    }

    watchServiceStatus() {
        if (this.subscription.playStatus !== null || this.subscription.playStatus !== null) {
            return;
        }
        this.subscription.playStatus = this.watchPlayStatus().subscribe((status: number) => {
            switch (status) {
                case EWSStatus.connected:
                    // initialize message feed on first (re)connect
                    this.checkConnectToMessageFeed();
                    break;
                case EWSStatus.disconnected:
                    this.connected = false;
                    break;
                case EWSStatus.error:
                    this.connected = false;
                    break;
                default:
                    break;
            }
        });
    }

    /**
     * listen for websocket messages from the other clients
     */
    watchServiceGroupMode(statusOnly: boolean) {
        if (this.subscription.playRx !== null || this.subscription.playRx !== null) {
            return;
        }
        this.subscription.playRx = this.watchPlayWS().subscribe((data: IMPResponseContainer<any>) => {
            if (data) {
                let messageScopeString: string = "N/A";
                if (this.initialized || !this.withExtraSync) {
                    switch (data.scope) {
                        case EMPScope.STATUS:
                            messageScopeString = "STATUS";
                            let statusData: IMPStatusRxContainer = data.data;
                            this.observables.status.next(statusData.data);
                            this.updateMemberDynamicFromStatus(this.groupScope, statusData);
                            if (!statusOnly) {
                                switch (this.role) {
                                    case EGroupRole.member:
                                        this.memberCheckLeaderConnectedAndReady(this.groupScope, true);
                                        break;
                                    case EGroupRole.leader:
                                        this.leaderCheckAllReady(this.groupScope, true);
                                        break;
                                }

                                // this.externalInput(EMPExternalCodes.statusChanged);
                                // console.log("sync group status: ", this.groupScope);
                                // this.handleMembersDisconnected(this.groupScope);
                            }
                            this.dispachContextStatus();
                            break;
                        case EMPScope.MESSAGE:
                            messageScopeString = "MESSAGE";
                            switch (data.type) {
                                case EResponseMessageTypeContainer.MESSAGE:
                                    let dc1: IMPMessageRxContainer = data.data;
                                    let message: IMPMessageDB = dc1.data;
                                    this.updateMemberDynamicFromMessage(this.groupScope, message);
                                    this.processMessageRx(message);
                                    if (this.isChatTypeMessage(message)) {
                                        this.updateMessageSyncGroup(this.groupScope, message);
                                        this.handleChatMessage(message, true);
                                    }
                                    break;
                                case EResponseMessageTypeContainer.MESSAGE_LIST:
                                    let dc2: IMPMessageRxSyncContainer = data.data;
                                    let messages: IMPMessageDB[] = dc2.data;
                                    messages = this.filterCachedMessages(messages);
                                    for (let message of messages) {
                                        this.updateMemberDynamicFromMessage(this.groupScope, message);
                                        this.processMessageRx(message);
                                        if (this.isChatTypeMessage(message)) {
                                            this.updateMessageSyncGroup(this.groupScope, message);
                                            this.handleChatMessage(message, false);
                                        }
                                    }
                                    let chatMessages: IMPMessageDB[] = messages.filter(msg => msg.type === EMPMessageCodes.chat);
                                    this.observables.chatSync.next(chatMessages);
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                } else {
                    switch (data.scope) {
                        // will get a message by loopback/ping request
                        case EMPScope.MESSAGE:
                            messageScopeString = "MESSAGE";
                            switch (data.type) {
                                case EResponseMessageTypeContainer.MESSAGE:
                                    this.sendSyncMessageRequestWS().then(() => {
                                        this.initialized = true;
                                    }).catch((err: Error) => {
                                        console.error(err);
                                    });
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                }
                console.log("received message scope: " + messageScopeString + " code: " + (data.data != null ? data.data.type : "N/A"));
            }
        }, (err: Error) => {
            console.warn("watch service stopped");
            console.error(err);
        });
    }

    private processMessageRx(message: IMPMessageDB) {
        if (!message) {
            console.warn("mp message received: undefined");
            return;
        }
        console.log("mp message received: " + message.type + " | " + EMPMessageCodes[message.type]);
        this.messageHistory.push(message);
        this.observables.message.next(message);
    }


    /**
    * listen for websocket messages from the other clients
    */
    watchServiceMeetingPlaceMode() {
        if (this.subscription.playRx !== null || this.subscription.playRx !== null) {
            return;
        }
        this.subscription.playRx = this.watchPlayWS().subscribe((data: IMPResponseContainer<any>) => {
            if (data) {
                let messageScopeString: string = "N/A";
                switch (data.scope) {
                    case EMPScope.STATUS:
                        messageScopeString = "STATUS";
                        let sdata: IMPStatusRxContainer = data.data;
                        // update scope
                        this.meetingPlaceScope.players = sdata.data.coords;
                        this.dispachContextStatus();
                        break;
                    case EMPScope.MESSAGE:
                        messageScopeString = "MESSAGE";
                        if (this.initialized || !this.withExtraSync) {
                            switch (data.type) {
                                case EResponseMessageTypeContainer.MESSAGE:
                                    let dc1: IMPMessageRxContainer = data.data;
                                    let message: IMPMessageDB = dc1.data;
                                    this.processMessageRx(message);
                                    if (this.isChatTypeMessage(message)) {
                                        this.updateMessageSyncMeetingPlace(this.meetingPlaceScope, message);
                                        this.handleChatMessage(message, true);
                                    }
                                    break;
                                case EResponseMessageTypeContainer.MESSAGE_LIST:
                                    let dc2: IMPMessageRxSyncContainer = data.data;
                                    let messages: IMPMessageDB[] = dc2.data;
                                    messages = this.filterCachedMessages(messages);
                                    for (let message of messages) {
                                        this.processMessageRx(message);
                                        if (this.isChatTypeMessage(message)) {
                                            this.updateMessageSyncMeetingPlace(this.meetingPlaceScope, message);
                                            this.handleChatMessage(message, false);
                                        }
                                    }
                                    let chatMessages: IMPMessageDB[] = messages.filter(msg => msg.type === EMPMessageCodes.chat);
                                    this.observables.chatSync.next(chatMessages);
                                    break;
                                default:
                                    break;
                            }
                            break;
                        } else {
                            switch (data.scope) {
                                // will get a message by loopback/ping request
                                case EMPScope.MESSAGE:
                                    messageScopeString = "MESSAGE";
                                    switch (data.type) {
                                        case EResponseMessageTypeContainer.MESSAGE:
                                            this.sendSyncMessageRequestWS().then(() => {
                                                this.initialized = true;
                                            }).catch((err: Error) => {
                                                console.error(err);
                                            });
                                            break;
                                        default:
                                            break;
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }
                        break;
                    default:
                        break;
                }
                console.log("received message scope: " + messageScopeString + " code: " + (data.data != null ? data.data.type : "N/A"));
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    private isChatTypeMessage(message: IMPMessageDB) {
        if (!message) {
            return false;
        }
        return [EMPMessageCodes.chat, EMPMessageCodes.typing].indexOf(message.type) !== -1;
    }

    private filterCachedMessages(messages: IMPMessageDB[]) {
        let filteredMessages: IMPMessageDB[] = messages.filter(msg => this.messageHistory.find(mh => {
            if ((mh != null) && (mh.id != null) && (mh.id === msg.id)) {
                return false;
            }
            return true;
        }) == null);
        return filteredMessages;
    }


    public watchEventMux() {
        return this.observables.externalInput;
    }

    /**
     * listen for input, one time
     * returns when an input is received
     * @param expectedCodes the message codes that will trigger resolve
     */
    private watchEvent(expectedCodes: number[]): Promise<IMPEventContainer> {
        let promise: Promise<IMPEventContainer> = new Promise((resolve, reject) => {
            if (this.subscription.externalInput !== null) {
                reject(new Error("input listener already enabled"));
                return;
            }
            console.log("watch event: ", expectedCodes);
            this.subscription.externalInput = this.observables.externalInput.subscribe((event: IMPEventContainer) => {
                if (event) {
                    console.log("external input: ", event.code);
                    if (expectedCodes.indexOf(event.code) !== -1) {
                        console.log("external input matched: ", event);
                        this.stateParams = event.data;
                        resolve(event);
                    }
                }
            }, (err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }


    /**
    * listen for input, one time
    * returns when an input is received
    * @param expectedState the message codes that will trigger resolve
    */
    watchStateTransitionResolve(expectedState: number[]): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            this.watchStateTransition(expectedState).then((res) => {
                resolve(res);
            }).catch(() => {
                resolve(false);
            });
        });
        return promise;
    }

    /**
     * listen for input, one time
     * returns when an input is received
     * @param expectedState the message codes that will trigger resolve
     */
    watchStateTransition(expectedState: number[]): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (this.subscription.stateTransitionWatch !== null) {
                reject(new Error("state listener already enabled"));
                return;
            }
            this.subscription.stateTransitionWatch = this.observables.state.subscribe((state: number) => {
                // console.log("external input: ", code);
                if (state != null) {
                    if (expectedState.indexOf(state) !== -1) {
                        console.log("state transition detected: ", state);
                        resolve(true);
                    }
                }
            }, (err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    private clearInputListener() {
        this.subscription.externalInput = ResourceManager.clearSub(this.subscription.externalInput);
    }


    /**
     * init state machine
     * handles the group status management
     * starting the game
     * handling disconnected members/leader
     * @param role 
     */
    initStateMachine(role: number) {
        this.role = role;

        console.log("init state machine: ", role);

        if (this.subscription.state !== null) {
            console.warn("subscription state already subscribed");
            return;
        }

        this.stateParams = null;

        switch (role) {
            case EGroupRole.member:
                this.state = EMemberStates.INIT;
                console.log("init state machine as MEMBER");
                break;
            case EGroupRole.leader:
                this.state = ELeaderStates.INIT;
                console.log("init state machine as LEADER");
                break;
        }
        this.setState(this.state);
        this.subscription.state = this.observables.state.subscribe(() => {
            this.stateMachine(role);
        }, (err: Error) => {
            console.warn("state observable error");
            console.error(err);
        });
    }


    switchToLeader() {
        this.role = EGroupRole.leader;
    }

    switchToMember() {
        this.role = EGroupRole.member;
    }


    /**
     * change state machine state
     * reset state params
     */
    private setState(state: number) {
        this.timeout.selfStateTransition = ResourceManager.clearTimeout(this.timeout.selfStateTransition);
        this.timeout.selfStateTransition = setTimeout(() => {
            this.state = state;
            this.entry = true;
            let stateName: string = "";
            let timestamp: string = new Date().toUTCString();
            switch (this.role) {
                case EGroupRole.member:
                    stateName = "MP_STATE/MEMBER/" + this.getStateName(EMemberStates);
                    break;
                case EGroupRole.leader:
                    stateName = "MP_STATE/LEADER/" + this.getStateName(ELeaderStates);
                    break;
            }
            console.log("MP set state: " + state + " (" + stateName + ") at " + timestamp);
            this.clearInputListener();
            if (this.observables.state !== null) {
                this.observables.state.next(state);
                // reset watches
                this.observables.state.next(null);
            }
        }, 100);
    }

    private setStateWTimeout(state: number) {
        if (this.useTimeoutAutoStateSwitch) {
            this.timeout.selfStateTransition = setTimeout(() => {
                this.setState(state);
            }, 1000);
        } else {
            this.setState(state);
        }
    }

    getState() {
        return this.state;
    }

    private afterEntryAction() {
        // this.stateParams = null;
    }

    /**
     * run state machine entry action i.e. called once when entering the state
     * @param func 
     */
    private runEntryAction(func) {
        if (this.entry) {
            this.entry = false;
            func();
        }
    }

    /**
     * event multiplexer
     * @param source 
     * @param action 
     * @param data additional params
     */
    dispatchEventMux(source: number, action: number, data: any) {
        console.log("external input dispatch: " + source + "/" + action, data);
        this.observables.externalInput.next(MPEncoding.encodeEvent(source, action, data));
    }

    private getStateName(states: any) {
        return GeneralUtils.getPropName(states, this.state);
    }

    private stateMachine(role: number) {
        switch (role) {
            case EGroupRole.member:
                this.stateMachineMember();
                break;
            case EGroupRole.leader:
                this.stateMachineLeader();
                break;
        }
    }


    /**
     * should be handled by MEMBERS only
     */
    handleLeaderDisconnected() {
        if (!this.synchronized) {
            return;
        }
        switch (this.role) {
            case EGroupRole.member:
                // go to disconnect handle state
                if (!(this.state === EMemberStates.HANDLE_DISCONNECTED_LEADER)) {
                    this.stateBeforeDisconnectHandle = this.state;
                    this.setState(EMemberStates.HANDLE_DISCONNECTED_LEADER);
                }
                break;
        }
    }

    /**
     * should be handled by MEMBERS only
     */
    handleLeaderReconnected() {
        if (!this.synchronized) {
            return;
        }
        switch (this.role) {
            case EGroupRole.member:
                // go to initial state if the member has connected in the mean time
                if (this.state === EMemberStates.HANDLE_DISCONNECTED_LEADER) {
                    this.setState(this.stateBeforeDisconnectHandle);
                }
                break;
        }
    }

    /**
     * should be handled by LEADER only
     */
    handleMemberDisconnected() {
        if (!this.synchronized) {
            return;
        }
        // go to disconnect handle state
        switch (this.role) {
            case EGroupRole.leader:
                if (!(this.state === ELeaderStates.HANDLE_DISCONNECTED_MEMBER)) {
                    this.stateBeforeDisconnectHandle = this.state;
                    this.setState(ELeaderStates.HANDLE_DISCONNECTED_MEMBER);
                }
                break;
        }
    }

    /**
     * should be handled by LEADER only
     */
    handleMemberReconnected() {
        if (!this.synchronized) {
            return;
        }
        switch (this.role) {
            case EGroupRole.leader:
                // go to initial state if the member has connected in the mean time
                if (this.state === ELeaderStates.HANDLE_DISCONNECTED_MEMBER) {
                    this.setState(this.stateBeforeDisconnectHandle);
                }
                break;
        }
    }

    /**
     * the state machine for the LEADER app
     * it handles the mp framework functions
     * init, connection handling 
     */
    private stateMachineLeader() {
        switch (this.state) {
            case ELeaderStates.INIT:
                this.runEntryAction(() => {
                    let tapReady = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.tapReady, null).code;
                    this.watchEvent([tapReady]).then((event: IMPEventContainer) => {
                        console.log("tap ready detected");
                        switch (event.code) {
                            case tapReady:
                                this.setReadyState(this.groupScope, this.playerId, true);
                                // the leader does not actually send a ready event to members because
                                // it connects AFTER it's already tapped on ready
                                this.setState(ELeaderStates.READY);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(ELeaderStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;
            case ELeaderStates.READY:
                this.runEntryAction(() => {
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    let membersReady = MPEncoding.encodeEvent(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.allMembersReady, null).code;
                    if (!this.synchronized) {
                        this.setStateWTimeout(ELeaderStates.SET);
                    } else {
                        this.watchEvent([back, membersReady]).then((event: IMPEventContainer) => {
                            switch (event.code) {
                                case back:
                                    this.setState(ELeaderStates.INIT);
                                    break;
                                case membersReady:
                                    this.setState(ELeaderStates.SET);
                                    break;
                            }
                        }).catch((err: Error) => {
                            console.error(err);
                            this.setState(ELeaderStates.ERROR);
                        });
                    }
                    this.afterEntryAction();
                });
                break;

            case ELeaderStates.SET:
                this.runEntryAction(() => {
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    let tapStart = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.tapStart, null).code;
                    this.watchEvent([back, tapStart]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                this.setState(ELeaderStates.INIT);
                                break;
                            case tapStart:
                                if (this.leaderCheckAllReady(this.groupScope, false)) {
                                    this.setState(ELeaderStates.GO);
                                } else {
                                    this.setState(ELeaderStates.SET_AND_TAPPED_START);
                                }
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(ELeaderStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case ELeaderStates.SET_AND_TAPPED_START:
                this.runEntryAction(() => {
                    if (this.leaderCheckAllReady(this.groupScope, false)) {
                        this.setState(ELeaderStates.GO);
                    }
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    let membersReady = MPEncoding.encodeEvent(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.allMembersReady, null).code;
                    if (!this.synchronized) {
                        this.setStateWTimeout(ELeaderStates.GO);
                    } else {
                        this.watchEvent([back, membersReady]).then((event: IMPEventContainer) => {
                            switch (event.code) {
                                case back:
                                    this.setState(ELeaderStates.INIT);
                                    break;
                                case membersReady:
                                    this.setState(ELeaderStates.GO);
                                    break;
                            }
                        }).catch((err: Error) => {
                            console.error(err);
                            this.setState(ELeaderStates.ERROR);
                        });
                    }
                    this.afterEntryAction();
                });
                break;

            case ELeaderStates.GO:
                this.runEntryAction(() => {
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    let selectChallenge = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.selectChallenge, null).code;
                    this.watchEvent([back, selectChallenge]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                this.setState(ELeaderStates.INIT);
                                break;
                            case selectChallenge:
                                // here we can load the event data
                                console.log("leader selectChallenge state params: ", event.data);
                                this.setState(ELeaderStates.CHALLENGE_LOADED);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(ELeaderStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            ////////////////////////////////////////////  CHALLENGE //////////////////////////////////////////// 
            case ELeaderStates.CHALLENGE_LOADED:
                this.runEntryAction(() => {

                    // dispatch challenge data to other members
                    this.dispatchMessageNoAction(EMPMessageCodes.leaderSelectChallenge, this.getCurrentStateParams());

                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    let memberRejectedChallenge = MPEncoding.encodeEvent(EMPEventSource.message, EMPMessageCodes.memberRejectedChallenge, null).code;
                    let allMembersLoadedChallenge = MPEncoding.encodeEvent(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.allMembersLoadedChallenge, null).code;

                    this.watchEvent([back, allMembersLoadedChallenge, memberRejectedChallenge]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                this.setState(ELeaderStates.INIT);
                                break;
                            case allMembersLoadedChallenge:
                                this.setState(ELeaderStates.CHALLENGE_READY);
                                break;
                            case memberRejectedChallenge:
                                this.setState(ELeaderStates.CHALLENGE_STOPPED);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(ELeaderStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case ELeaderStates.CHALLENGE_READY:
                this.runEntryAction(() => {
                    this.dispatchMessageNoAction(EMPMessageCodes.leaderStartChallenge, this.getCurrentStateParams());
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    let allMembersStartedChallenge = MPEncoding.encodeEvent(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.allMembersStartedChallenge, null).code;
                    this.watchEvent([back, allMembersStartedChallenge]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                this.setState(ELeaderStates.INIT);
                                break;
                            case allMembersStartedChallenge:
                                this.setState(ELeaderStates.CHALLENGE_IN_PROGRESS);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(ELeaderStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case ELeaderStates.CHALLENGE_IN_PROGRESS:
                this.runEntryAction(() => {
                    this.dispatchMessageNoAction(EMPMessageCodes.leaderStartChallenge, this.getCurrentStateParams());
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    let finishChallenge = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.finishChallenge, null).code;
                    let failChallenge = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.failChallenge, null).code;
                    let stopChallenge = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.stopChallenge, null).code;
                    this.watchEvent([back, finishChallenge, failChallenge, stopChallenge]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                this.setState(ELeaderStates.INIT);
                                break;
                            case finishChallenge:
                                this.setState(ELeaderStates.CHALLENGE_COMPLETE);
                                break;
                            case failChallenge:
                                this.setState(ELeaderStates.CHALLENGE_FAILED);
                                break;
                            case stopChallenge:
                                this.setState(ELeaderStates.CHALLENGE_STOPPED);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(ELeaderStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;


            case ELeaderStates.CHALLENGE_COMPLETE:
                this.runEntryAction(() => {
                    // send finished challenge WITH activity stats (score is loaded via ext request at this point and included in the message)
                    this.dispatchMessageNoAction(EMPMessageCodes.leaderFinishedChallenge, this.getCurrentStateParams());
                    this.setState(ELeaderStates.WAIT_FOR_ENDGAME);
                    this.afterEntryAction();
                });
                break;

            case ELeaderStates.CHALLENGE_FAILED:
                this.runEntryAction(() => {
                    this.dispatchMessageNoAction(EMPMessageCodes.leaderFailedChallenge, this.getCurrentStateParams());
                    this.setState(ELeaderStates.WAIT_FOR_ENDGAME);
                    this.afterEntryAction();
                });
                break;

            case ELeaderStates.WAIT_FOR_ENDGAME:
                this.runEntryAction(() => {
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    let waitMembersExitChallenge = MPEncoding.encodeEvent(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.allMembersExitChallenge, null).code;
                    this.watchEvent([back, waitMembersExitChallenge]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                this.setState(ELeaderStates.INIT);
                                break;
                            case waitMembersExitChallenge:
                                // all others completed the challenge in some way (finished, failed, stopped)
                                this.setState(ELeaderStates.WAIT_STATS_DISPATCH);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(ELeaderStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case ELeaderStates.CHALLENGE_STOPPED:
                this.runEntryAction(() => {
                    this.dispatchMessageNoAction(EMPMessageCodes.leaderStopChallenge, this.getCurrentStateParams());
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    // check all members stopped
                    let waitMembersExitChallenge = MPEncoding.encodeEvent(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.allMembersExitChallenge, null).code;
                    this.watchEvent([back, waitMembersExitChallenge]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                this.setState(ELeaderStates.INIT);
                                break;
                            case waitMembersExitChallenge:
                                this.setState(ELeaderStates.ENDGAME);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(ELeaderStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case ELeaderStates.WAIT_STATS_DISPATCH:
                // request stats via http, show popup, then dispatch to others
                // the controller will request the stats (to show them in modal)
                this.runEntryAction(() => {
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    // wait for stats loading and showing leaderboard in the UI
                    let waitRequestStats = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.statsLoaded, null).code;
                    this.watchEvent([back, waitRequestStats]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                this.setState(ELeaderStates.INIT);
                                break;
                            case waitRequestStats:
                                this.setState(ELeaderStates.REQUEST_STATS_DISPATCH);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(ELeaderStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case ELeaderStates.REQUEST_STATS_DISPATCH:
                this.runEntryAction(() => {
                    this.dispatchMessageNoAction(EMPMessageCodes.leaderStatsReady, null);
                    this.setState(ELeaderStates.ENDGAME);
                    this.afterEntryAction();
                });
                break;

            case ELeaderStates.ENDGAME:
                this.runEntryAction(() => {
                    this.dispatchMessageNoAction(EMPMessageCodes.leaderBroadcastEndgame, null);
                    this.resetGame(this.groupScope);
                    this.setState(ELeaderStates.GO);
                    this.afterEntryAction();
                });
                break;

            ////////////////////////////////////////////  ERROR HANDLER //////////////////////////////////////////// 
            case ELeaderStates.HANDLE_DISCONNECTED_MEMBER:
                this.sendArenaEvent(EArenaEvent.requireUserAction);
                this.runEntryAction(() => {
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    this.watchEvent([back]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                // close session
                                this.setState(ELeaderStates.INIT);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(ELeaderStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;
            case ELeaderStates.ERROR:
                this.runEntryAction(() => {
                    setTimeout(() => {
                        this.setState(ELeaderStates.INIT);
                    }, this.updateDelay);
                });
                break;
        }
    }

    private sendArenaEvent(event: number) {
        this.observables.arenaEvent.next(event);
        // reset watches
        this.observables.arenaEvent.next(null);
    }

    /**
     * the state machine for the MEMBER app
     * it handles the mp framework functions
     * init, connection handling 
     */
    private stateMachineMember() {
        switch (this.state) {
            case EMemberStates.INIT:
                this.runEntryAction(() => {
                    let tapReady = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.tapReady, null).code;
                    this.watchEvent([tapReady]).then((event: IMPEventContainer) => {
                        // console.log(this.subscription.input);
                        console.log("tap ready detected");
                        switch (event.code) {
                            case tapReady:
                                this.setState(EMemberStates.READY);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(EMemberStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case EMemberStates.READY:
                // wait for leader to connect (status)
                this.runEntryAction(() => {
                    if (this.memberCheckLeaderConnectedAndReady(this.groupScope, false)) {
                        this.setState(EMemberStates.SET);
                    } else {
                        let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                        let leaderConnected = MPEncoding.encodeEvent(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.leaderConnectedViaStatus, null).code;
                        if (!this.synchronized) {
                            this.setStateWTimeout(EMemberStates.SET);
                        } else {
                            this.watchEvent([back, leaderConnected]).then((event: IMPEventContainer) => {
                                // console.log(this.subscription.input);
                                switch (event.code) {
                                    case back:
                                        this.setState(EMemberStates.INIT);
                                        break;
                                    case leaderConnected:
                                        // dispatch message, member ready
                                        this.setState(EMemberStates.SET);
                                        break;
                                }
                            }).catch((err: Error) => {
                                console.error(err);
                                this.setState(EMemberStates.ERROR);
                            });
                        }
                    }
                    this.afterEntryAction();
                });
                break;

            case EMemberStates.SET:
                this.runEntryAction(() => {
                    // console.log(this.subscription.input);
                    if (!this.synchronized) {
                        this.setStateWTimeout(EMemberStates.GO);
                    } else {
                        this.timeout.selfStateTransition = setTimeout(() => {
                            if (this.memberCheckLeaderConnectedAndReady(this.groupScope, false)) {
                                console.log("set leader is ok");
                                this.setState(EMemberStates.WAIT_GO);
                            } else {
                                console.log("set leader is not ok");
                                this.setState(EMemberStates.SET);
                            }
                        }, 3000);
                    }

                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    this.watchEvent([back]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                this.setState(EMemberStates.INIT);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(EMemberStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case EMemberStates.WAIT_GO:
                this.runEntryAction(() => {
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    let tapStart = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.tapStart, null).code;
                    this.watchEvent([back, tapStart]).then((event: IMPEventContainer) => {
                        // console.log(this.subscription.input);
                        switch (event.code) {
                            case back:
                                this.setState(EMemberStates.INIT);
                                break;
                            case tapStart:
                                if (this.memberCheckLeaderConnectedAndReady(this.groupScope, false)) {
                                    console.log("set leader is ok");
                                    this.setState(EMemberStates.GO);
                                } else {
                                    console.log("set leader is not ok");
                                    this.setState(EMemberStates.SET);
                                }
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(EMemberStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case EMemberStates.GO:
                this.runEntryAction(() => {
                    this.dispatchMessageNoAction(EMPMessageCodes.memberReady, null);
                    this.memberUpdateGroupDynamicComplete(this.groupScope);

                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    let leaderSelectChallenge = MPEncoding.encodeEvent(EMPEventSource.message, EMPMessageCodes.leaderSelectChallenge, null).code;
                    this.watchEvent([back, leaderSelectChallenge]).then((event: IMPEventContainer) => {
                        // console.log(this.subscription.input);
                        switch (event.code) {
                            case back:
                                this.setState(EMemberStates.INIT);
                                break;
                            case leaderSelectChallenge:
                                this.setState(EMemberStates.CHALLENGE_LOADED);
                                break;

                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(EMemberStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            ////////////////////////////////////////////  CHALLENGE //////////////////////////////////////////// 
            case EMemberStates.CHALLENGE_LOADED:
                this.runEntryAction(() => {
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    let confirmChallenge = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.confirmChallenge, null).code;
                    let leaderStopChallenge = MPEncoding.encodeEvent(EMPEventSource.message, EMPMessageCodes.leaderStopChallenge, null).code;
                    this.watchEvent([back, confirmChallenge, leaderStopChallenge]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                this.setState(EMemberStates.INIT);
                                break;
                            case confirmChallenge:
                                console.log("member CHALLENGE_LOADED: ", event.data);
                                this.setState(EMemberStates.CHALLENGE_READY);
                                break;
                            case leaderStopChallenge:
                                // a member may have rejected the challenge and then the leader stopped the challenge
                                this.setState(EMemberStates.CHALLENGE_STOPPED_EXT);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(EMemberStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case EMemberStates.CHALLENGE_READY:
                this.runEntryAction(() => {
                    this.dispatchMessageNoAction(EMPMessageCodes.memberLoadChallenge, this.getCurrentStateParams());
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    let leaderStartChallenge = MPEncoding.encodeEvent(EMPEventSource.message, EMPMessageCodes.leaderStartChallenge, null).code;
                    let leaderStopChallenge = MPEncoding.encodeEvent(EMPEventSource.message, EMPMessageCodes.leaderStopChallenge, null).code;
                    this.watchEvent([back, leaderStartChallenge, leaderStopChallenge]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                this.setState(EMemberStates.INIT);
                                break;
                            case leaderStartChallenge:
                                console.log("member CHALLENGE_READY: ", event.data);
                                this.setState(EMemberStates.CHALLENGE_IN_PROGRESS);
                                break;
                            case leaderStopChallenge:
                                this.setState(EMemberStates.CHALLENGE_STOPPED_EXT);
                                break;

                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(EMemberStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case EMemberStates.CHALLENGE_IN_PROGRESS:
                this.runEntryAction(() => {
                    this.dispatchMessageNoAction(EMPMessageCodes.memberStartChallenge, this.getCurrentStateParams());
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    let finishChallenge = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.finishChallenge, null).code;
                    let failChallenge = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.failChallenge, null).code;
                    let stopChallenge = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.stopChallenge, null).code;
                    let leaderStopChallenge = MPEncoding.encodeEvent(EMPEventSource.message, EMPMessageCodes.leaderStopChallenge, null).code;
                    this.watchEvent([back, finishChallenge, failChallenge, stopChallenge, leaderStopChallenge]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                this.setState(EMemberStates.INIT);
                                break;
                            case finishChallenge:
                                this.setState(EMemberStates.CHALLENGE_COMPLETE);
                                break;
                            case failChallenge:
                                this.setState(EMemberStates.CHALLENGE_FAILED);
                                break;
                            case stopChallenge:
                                this.setState(EMemberStates.CHALLENGE_STOPPED);
                                break;
                            case leaderStopChallenge:
                                this.setState(EMemberStates.CHALLENGE_STOPPED_EXT);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(EMemberStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case EMemberStates.CHALLENGE_COMPLETE:
                this.runEntryAction(() => {
                    // send finished challenge WITH activity stats (score is loaded via ext request at this point and included in the message)
                    this.dispatchMessageNoAction(EMPMessageCodes.memberFinishedChallenge, this.getCurrentStateParams());
                    this.setState(EMemberStates.WAIT_FOR_ENDGAME);
                    this.afterEntryAction();
                });
                break;

            case EMemberStates.CHALLENGE_FAILED:
                this.runEntryAction(() => {
                    this.dispatchMessageNoAction(EMPMessageCodes.memberFailedChallenge, this.getCurrentStateParams());
                    this.setState(EMemberStates.WAIT_FOR_ENDGAME);
                    this.afterEntryAction();
                });
                break;

            case EMemberStates.CHALLENGE_STOPPED:
                this.runEntryAction(() => {
                    this.dispatchMessageNoAction(EMPMessageCodes.memberStopChallenge, this.getCurrentStateParams());
                    this.setState(EMemberStates.WAIT_FOR_ENDGAME);
                    this.afterEntryAction();
                });
                break;

            case EMemberStates.CHALLENGE_STOPPED_EXT:
                this.runEntryAction(() => {
                    this.dispatchMessageNoAction(EMPMessageCodes.memberStopChallenge, this.getCurrentStateParams());
                    // the leader has stopped the challenge
                    // don't wait for stats, go to endgame directly
                    this.setState(EMemberStates.ENDGAME);
                    this.afterEntryAction();
                });
                break;

            case EMemberStates.WAIT_FOR_ENDGAME:
                this.runEntryAction(() => {
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    let waitForStats = MPEncoding.encodeEvent(EMPEventSource.message, EMPMessageCodes.leaderStatsReady, null).code;
                    // this happens when the members stopped the challenge and then the leader stopped the challenge too
                    // the leaderStatsReady message will never get fired in this case
                    let waitForEndgame = MPEncoding.encodeEvent(EMPEventSource.message, EMPMessageCodes.leaderBroadcastEndgame, null).code;
                    this.watchEvent([back, waitForStats, waitForEndgame]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                this.setState(EMemberStates.INIT);
                                break;
                            case waitForStats:
                                // stats are ready at this point
                                this.setState(EMemberStates.WAIT_REQUEST_STATS);
                                break;
                            case waitForEndgame:
                                // the game has ended without stats
                                this.setState(EMemberStates.ENDGAME);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(EMemberStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case EMemberStates.WAIT_REQUEST_STATS:
                // request stats via http, show popup, then dispatch to others
                // the controller will request the stats (to show them in modal)
                this.runEntryAction(() => {
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    // wait for stats loading and showing leaderboard in the UI
                    let waitRequestStats = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.statsLoaded, null).code;
                    this.watchEvent([back, waitRequestStats]).then((event: IMPEventContainer) => {
                        switch (event.code) {
                            case back:
                                this.setState(EMemberStates.INIT);
                                break;
                            case waitRequestStats:
                                this.setState(EMemberStates.ENDGAME);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(EMemberStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case EMemberStates.ENDGAME:
                this.runEntryAction(() => {
                    this.resetGame(this.groupScope);
                    this.setState(EMemberStates.GO);
                    this.afterEntryAction();
                });
                break;

            ////////////////////////////////////////////  ERROR HANDLER //////////////////////////////////////////// 
            case EMemberStates.HANDLE_DISCONNECTED_LEADER:
                this.sendArenaEvent(EArenaEvent.requireUserAction);
                this.runEntryAction(() => {
                    let back = MPEncoding.encodeEvent(EMPEventSource.userInput, EMPUserInputCodes.back, null).code;
                    this.watchEvent([back]).then((event: IMPEventContainer) => {
                        // console.log(this.subscription.input);
                        switch (event.code) {
                            case back:
                                // close session
                                this.setState(EMemberStates.INIT);
                                break;
                        }
                    }).catch((err: Error) => {
                        console.error(err);
                        this.setState(EMemberStates.ERROR);
                    });
                    this.afterEntryAction();
                });
                break;

            case EMemberStates.ERROR:
                this.runEntryAction(() => {
                    setTimeout(() => {
                        this.setState(EMemberStates.INIT);
                    }, this.updateDelay);
                });
                break;
        }
    }



    clearGroupMemberStatus(group: IGroup) {
        if (!(group && group.members)) {
            return;
        }

        for (let gm of group.members) {
            gm.dynamic = null;
        }
    }



    /**
     * check if all group members are connected and ready
     * group members will emit the ready signal after map is initialized
     * @param group 
     */
    leaderCheckAllReady(group: IGroup, emitEvent: boolean) {
        if (!(group && group.members)) {
            return false;
        }
        group = this.initGroupDynamic(group);
        let allMembersReady: boolean = true;
        for (let gm of group.members) {
            let memberEnabled: boolean = true;
            if (gm.staticFlags) {
                memberEnabled = gm.staticFlags.enabled;
            }
            if (!(gm.dynamic && gm.dynamic.connected && gm.dynamic.flags && gm.dynamic.flags.ready) && memberEnabled) {
                allMembersReady = false;
                break;
            }
        }
        // aggregate fn
        group.dynamic.allMembersReady = allMembersReady;
        console.log("leader check all members ready: ", allMembersReady);
        if (emitEvent) {
            this.groupStatusEventEmitter(group);
        }
        return allMembersReady;
    }

    /**
     * check leader connected and ready
     * @param group 
     */
    memberCheckLeaderConnectedAndReady(group: IGroup, emitEvent: boolean) {
        if (!(group && group.members)) {
            return false;
        }
        group = this.initGroupDynamic(group);
        let leaderReady: boolean = false;
        let leaderConnected: boolean = false;
        let isLeader: boolean = false;

        for (let gm of group.members) {
            if (gm.type === EGroupRole.leader) {
                isLeader = true;
                if (gm.dynamic && gm.dynamic.connected) {
                    leaderConnected = true;
                    if (gm.dynamic.flags && gm.dynamic.flags.ready) {
                        leaderReady = true;
                    }
                }
                break;
            }
        }

        if (!isLeader) {
            leaderConnected = true;
            leaderReady = true;
        }

        // aggregate fn
        group.dynamic.leaderOk = leaderConnected;
        group.dynamic.leaderReady = leaderReady;

        if (emitEvent) {
            this.groupStatusEventEmitter(group);
        }
        // return leaderReady;

        // with ready check, the event is not captured for some reason
        return leaderConnected;
    }


    private checkInitGroupMemberDynamic(gm: IGroupMember) {
        if (!gm.dynamic) {
            return false;
        }
        return true;
    }

    private initGroupMemberDynamicDefault(gm: IGroupMember) {
        gm.dynamic = GroupDynamic.getMemberDefault();
        gm.dynamicPrev = Object.assign({}, gm.dynamic);
        gm.dynamicDiff = Object.assign({}, gm.dynamic);
        let keys: string[] = Object.keys(gm.dynamicDiff);
        for (let key of keys) {
            gm.dynamicDiff[key] = false;
        }
        console.log("init/reset group member dynamic: ", gm.userId);
        gm.isDynamicDefault = true;
        return gm;
    }

    private cacheGroupMemberDynamic(gm: IGroupMember) {
        console.log("cache group member dynamic: ", gm.userId);
        gm.cache = {
            dynamic: Object.assign({}, gm.dynamic),
            dynamicDiff: Object.assign({}, gm.dynamicDiff),
            dynamicPrev: Object.assign({}, gm.dynamicPrev)
        };
        return gm;
    }

    private restoreGroupMemberDynamic(gm: IGroupMember) {
        console.log("restore group member dynamic: ", gm.userId);
        gm.isDynamicDefault = false;
        if (!gm.cache) {
            return gm;
        }
        gm.dynamic = Object.assign({}, gm.cache.dynamic);
        return gm;
    }

    private initGroupDynamic(group: IGroup) {
        if (!group.dynamic) {
            group.dynamic = GroupDynamic.getGroupDefault();
            group.dynamicPrev = Object.assign({}, group.dynamic);
            group.dynamicDiff = Object.assign({}, group.dynamic);

            group.dynamicPrevDebounced = Object.assign({}, group.dynamic);
            group.dynamicDiffDebounced = Object.assign({}, group.dynamic);
            group.dynamicDiffCounter = GroupDynamic.getGroupCounterDefault();

            let keys: string[] = Object.keys(group.dynamicDiff);
            for (let key of keys) {
                group.dynamicDiff[key] = false;
                group.dynamicDiffDebounced[key] = false;
            }
        }
        return group;
    }


    /**
     * set initial values
     * v1: set all true and then disable them if there are exceptions from the rule
     * @param d 
     * @param value 
     */
    setGroupDynamic(d: IGroupDynamic<boolean>, value: boolean) {
        d.complete = value;
        d.allMembersReady = value;
        d.allMembersLoadedChallenge = value;
        d.allMembersStartedChallenge = value;
        d.allMembersExitChallenge = value;
        d.allMembersSyncStart = value;
        return d;
    }


    /**
     * add dynamic data to static group data
     * aggregate fn
     * @param group 
     * @param statusResponse 
     */
    private updateMemberDynamicFromStatus(group: IGroup, statusResponse: IMPStatusRxContainer) {

        if (!(group && group.members && statusResponse && statusResponse.data && statusResponse.data.coords)) {
            return;
        }

        group = this.initGroupDynamic(group);
        // by default set all flags true, will be set false if a member does not meet the condition
        this.setGroupDynamic(group.dynamic, true);
        group.dynamic.refreshToggle = false;

        for (let mstatus of statusResponse.data.coords) {
            // check group contains status member
            let staticMember = group.members.find(gm => gm.userId === mstatus.playerId);
            if (staticMember == null) {
                // group refresh required
                group.dynamic.refreshToggle = true;
                break;
            }
        }

        for (let gm of group.members) {
            // create group dynamic if not existing

            if (!this.checkInitGroupMemberDynamic(gm)) {
                this.initGroupMemberDynamicDefault(gm);
            }
            if (!gm.isDynamicDefault) {
                this.cacheGroupMemberDynamic(gm);
            }

            let memberOnline: boolean = false;
            let memberEnabled: boolean = true;

            if (gm.staticFlags) {
                memberEnabled = gm.staticFlags.enabled;
            }

            for (let mstatus of statusResponse.data.coords) {
                // check online members from status coords
                if (gm.userId === mstatus.playerId) {
                    memberOnline = mstatus.isOnline;
                    gm.dynamic.connected = true;
                    gm.dynamic.status = mstatus;
                    break;
                }
            }

            // ignore members that are disabled during the current session
            if (memberEnabled) {
                if (!memberOnline) {
                    // the user is not connected
                    // maybe the user has disconnected
                    // remove the associated dynamic data (set default)
                    // ignore members that are disabled during the current session
                    console.log("member not online: ", gm.userId);
                    if (gm) {
                        this.checkMemberDynamicDiff(gm);
                        if (gm.dynamicDiff.connected && !gm.dynamic.connected) {
                            let message: string = "player has disconnected";
                            if (gm && gm.user) {
                                message = gm.user.name + " has disconnected";
                            }
                            this.dispatchEventMux(EMPEventSource.virtualMemberState, EMPVirtualMemberCodes.disconnected, message);
                        }
                    }
                    this.initGroupMemberDynamicDefault(gm);

                    if (this.role === EGroupRole.leader) {
                        // group.dynamic.complete = false;
                        // group.dynamic.membersReady = false;
                        this.setGroupDynamic(group.dynamic, false);
                    }
                    // console.log("member disconnected: ", groupMember);
                } else {
                    // the user is connected
                    // check reconnect
                    // update dynamic flags
                    console.log("member online: ", gm.userId);
                    if (gm.isDynamicDefault) {
                        this.restoreGroupMemberDynamic(gm);
                        gm.isDynamicDefault = false;
                    }

                    // set group flags from enabled members status
                    if (!gm.dynamic.flags.ready) {
                        group.dynamic.allMembersReady = false;
                    }
                    if (!gm.dynamic.flags.challengeLoaded) {
                        group.dynamic.allMembersLoadedChallenge = false;
                    }
                    if (!gm.dynamic.flags.challengeStarted) {
                        group.dynamic.allMembersStartedChallenge = false;
                    }
                    if (!gm.dynamic.flags.exitChallenge) {
                        group.dynamic.allMembersExitChallenge = false;
                    }
                    if (!gm.dynamic.flags.syncStart) {
                        group.dynamic.allMembersSyncStart = false;
                    }
                }
            }
        }

        if (group.dynamic.complete) {
            // the game is on
            // now the disconnect handle will kick in when a user disconnects
            group.dynamic.initialized = true;
        }

        // console.log("status update check all members ready: ", group.dynamic.membersReady);
        // check state transitions
        this.groupStatusEventEmitter(group);
    }

    memberUpdateGroupDynamicComplete(group: IGroup) {
        if (!(group && group.dynamic)) {
            return;
        }

        group.dynamic.complete = true;
    }


    /**
     * reset game so that the diff events can be registered
     * e.g. challenge loaded event
     * @param group 
     */
    resetGame(group: IGroup) {
        for (let gm of group.members) {
            if (!this.checkInitGroupMemberDynamic(gm)) {
                this.initGroupMemberDynamicDefault(gm);
            }
            let keys: string[] = Object.keys(gm.dynamic.flags);
            let readyState: boolean = gm.dynamic.flags.ready;
            for (let key of keys) {
                gm.dynamic.flags[key] = false;
            }
            // keep old ready flag
            gm.dynamic.flags.ready = readyState;
        }
    }

    /**
     * fill user data from status
     * add user data e.g. username
     * @param meetingPlace 
     * @param message 
     */
    private updateMessageSyncMeetingPlace(meetingPlace: IMPMeetingPlace, message: IMPMessageDB) {
        if (!(meetingPlace && meetingPlace.players && message)) {
            return;
        }

        let gm: IMPStatusDB = meetingPlace.players.find(gm => gm.playerId === message.playerId);
        if (gm != null) {
            message.userStatus = gm;
        }
    }

    /**
     * fill user data from status
     * add user data e.g. username
     * @param group 
     * @param message 
     */
    private updateMessageSyncGroup(group: IGroup, message: IMPMessageDB) {
        if (!(group && group.members && message)) {
            return;
        }

        let gm: IGroupMember = group.members.find(gm => gm.userId === message.playerId);
        if (gm != null) {
            message.userData = gm;
        }
    }

    /**
     * update group status based on the messages that are being exchanged
     * @param group 
     * @param message 
     */
    private updateMemberDynamicFromMessage(group: IGroup, message: IMPMessageDB) {
        if (!(group && group.members && message)) {
            return;
        }
        // send the message to the state machines
        this.dispatchEventMux(EMPEventSource.message, message.type, message.data);
        // console.log("message received: ", messageResponse);
        // find the member whose message was received
        // then update the status accordingly
        for (let gm of group.members) {
            if (!this.checkInitGroupMemberDynamic(gm)) {
                this.initGroupMemberDynamicDefault(gm);
            }
            // check for the user id that has sent the message within the group
            if (gm.dynamic.status && (gm.dynamic.status.playerId === message.playerId)) {
                message.userData = gm;
                // handle incoming messages
                switch (message.type) {
                    case EMPMessageCodes.memberReady:
                    case EMPMessageCodes.leaderReady:
                        // update ready flag for leader/member
                        gm.dynamic.flags.ready = true;
                        break;
                    case EMPMessageCodes.memberLoadChallenge:
                    case EMPMessageCodes.leaderSelectChallenge:
                        gm.dynamic.flags.challengeLoaded = true;
                        break;
                    case EMPMessageCodes.memberStartChallenge:
                    case EMPMessageCodes.leaderStartChallenge:
                        gm.dynamic.flags.challengeStarted = true;
                        break;
                    case EMPMessageCodes.memberFinishedChallenge:
                    case EMPMessageCodes.memberStopChallenge:
                    case EMPMessageCodes.memberFailedChallenge:
                    case EMPMessageCodes.leaderFinishedChallenge:
                    case EMPMessageCodes.leaderStopChallenge:
                    case EMPMessageCodes.leaderFailedChallenge:
                        gm.dynamic.flags.exitChallenge = true;
                        break;
                    case EMPMessageCodes.memberSyncStart:
                    case EMPMessageCodes.leaderSyncStart:
                        gm.dynamic.flags.syncStart = true;
                        break;
                }
                break;
            }
        }
    }

    private handleChatMessage(message: IMPMessageDB, notifyEnabled: boolean) {
        if (!message) {
            return;
        }
        let messageWatchCodes: number[] = [EMPMessageCodes.chat, EMPMessageCodes.typing];
        if (messageWatchCodes.indexOf(message.type) !== -1) {
            this.chatHistory.push(message);
            this.observables.chat.next(message);

            if (notifyEnabled && (!this.chatOpen || GeneralCache.paused)) {
                switch (message.type) {
                    case EMPMessageCodes.chat:
                        let info: string = MPUtils.getStringMessageFromMessageDB(message, null);
                        this.localNotifications.notify("Ongoing chat", info, false, null);
                        this.soundManager.vibrateContext(false);
                        break;
                    case EMPMessageCodes.typing:
                        break;
                }
            }
        }
    }


    /**
     * emit events based on aggregated status data
     * only check for state transitions on single flags
     * @param group 
     */
    private groupStatusEventEmitter(group: IGroup) {
        if (!(group && group.dynamic)) {
            return;
        }

        // handle exceptional conditions for members
        // check transition of leader ok
        // active only after the group has been completed first

        this.checkGroupDynamicDiff(group);

        // console.log("current group: ", group.dynamic);
        // console.log("dynamic diff: ", group.dynamicDiff);

        // general events
        if (group.dynamicDiffDebounced.leaderOk) {
            if (group.dynamic.initialized) {
                // the game should be started by now
                if (!group.dynamic.leaderOk) {
                    this.dispatchEventMux(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.leaderDisconnectedViaStatus, null);
                    this.handleLeaderDisconnected();
                } else {
                    // dispatch connected event
                    this.dispatchEventMux(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.leaderConnectedViaStatus, null);
                    this.handleLeaderReconnected();
                }
            } else {
                // the game is not yet started, it's still in connecting phase
                if (!group.dynamic.leaderOk) {
                    this.dispatchEventMux(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.leaderDisconnectedViaStatus, null);
                } else {
                    // dispatch connected event
                    this.dispatchEventMux(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.leaderConnectedViaStatus, null);
                }
            }
        }

        if (group.dynamicDiffDebounced.allMembersReady) {
            if (group.dynamic.initialized) {
                // the game should be started by now
                if (!group.dynamic.allMembersReady) {
                    this.dispatchEventMux(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.notAllMembersReady, null);
                    this.handleMemberDisconnected();
                } else {
                    this.dispatchEventMux(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.allMembersReady, null);
                    this.handleMemberReconnected();
                }
            } else {
                // the game is not yet started, it's still in connecting phase
                if (!group.dynamic.allMembersReady) {
                    this.dispatchEventMux(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.notAllMembersReady, null);
                } else {
                    this.dispatchEventMux(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.allMembersReady, null);
                }
            }
        }


        // game spec events
        if (group.dynamicDiff.allMembersLoadedChallenge) {
            if (group.dynamic.allMembersLoadedChallenge) {
                this.dispatchEventMux(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.allMembersLoadedChallenge, null);
            }
        }

        if (group.dynamicDiff.allMembersStartedChallenge) {
            if (group.dynamic.allMembersStartedChallenge) {
                this.dispatchEventMux(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.allMembersStartedChallenge, null);
            }
        }

        if (group.dynamicDiff.allMembersExitChallenge) {
            if (group.dynamic.allMembersExitChallenge) {
                this.dispatchEventMux(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.allMembersExitChallenge, null);
            }
        }

        if (group.dynamicDiff.allMembersSyncStart) {
            if (group.dynamic.allMembersSyncStart) {
                this.dispatchEventMux(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.allMembersSyncStart, null);
            }
        }

        if (group.dynamicDiff.refreshToggle) {
            if (group.dynamic.refreshToggle) {
                this.dispatchEventMux(EMPEventSource.virtualGroupState, EMPVirtualGroupCodes.newMemberDetected, null);
            }
        }
    }



    /**
     * check for flags that changed at the current status update
     * @param group 
     */
    private checkGroupDynamicDiff(group: IGroup) {
        if (!(group && group.dynamic)) {
            return null;
        }
        let keys = Object.keys(group.dynamic);

        for (let key of keys) {
            group.dynamicDiff[key] = (group.dynamic[key] !== group.dynamicPrev[key]);

            if (group.dynamic[key] !== group.dynamicPrevDebounced[key]) {
                // debounce on diff
                if (group.dynamicDiffCounter[key] > 0) {
                    group.dynamicDiffCounter[key] -= 1;
                } else {
                    group.dynamicDiffCounter[key] = EArenaParams.maxCounterTimeoutDebounce;
                    group.dynamicDiffDebounced[key] = true;
                    group.dynamicPrevDebounced[key] = group.dynamic[key];
                }
            } else {
                // no debounce on equals
                group.dynamicDiffDebounced[key] = false;
            }
        }

        group.dynamicPrev = Object.assign({}, group.dynamic);
    }

    /**
    * check for flags that changed at the current status update
    * @param gm 
    */
    private checkMemberDynamicDiff(gm: IGroupMember) {
        if (!(gm && gm.dynamic)) {
            return null;
        }
        let keys: string[] = Object.keys(gm.dynamic);
        for (let key of keys) {
            gm.dynamicDiff[key] = (gm.dynamic[key] !== gm.dynamicPrev[key]);
        }
        gm.dynamicPrev = Object.assign({}, gm.dynamic);
    }


    /**
     * use this for implicit ready state
     * e.g. the leader is ready anyways if it's connected
     * @param group 
     * @param playerId 
     * @param state 
     */
    setReadyState(group: IGroup, playerId: number, state: boolean) {
        console.log("set leader ready state");
        if (!(group && group.members)) {
            return;
        }
        for (let gm of group.members) {
            if (gm.userId === playerId) {
                if (!this.checkInitGroupMemberDynamic(gm)) {
                    this.initGroupMemberDynamicDefault(gm);
                }
                gm.dynamic.flags.ready = state;
                break;
            }
        }
        console.log("set leader ready state complete");
    }
}




