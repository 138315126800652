<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" [title]="title" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back(false)"
    rightButton="settings" [rightIcon]="true" (rightClick)="presentPopover()" [loading]="!loaded">
  </leplace-toolbar>
</ion-header>

<!-- overflow-scroll="true" -->
<ion-content [ngClass]="theme">
  <!-- (swipe)="swipeEvent($event)" -->
  <div class="view-frame">
    <!-- bg-filter-1 -->
    <div class="bg-container bg-container-fill" [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: photoUrl}">

      <div class="content">
        <div fxLayout="column" class="content-height no-scroll">
          <div class="scrollbar-y content-height scroll-into-view-container" fxLayout="column">
            <div *ngIf="story" [@showState]="showState">
              <div class="text-color-overlay margin-horizontal-s margin-top-s">
                <ion-row class="padding-xs">
                  <ion-col size="12" class="text-center">
                    <span class="heading text-color-primary">{{story.name}}</span>
                  </ion-col>
                </ion-row>

                <story-info-spec *ngIf="storyExt" [data]="storyExt"></story-info-spec>

                <ion-row class="padding-xs" *ngIf="storyExt && storyExt.modeText">
                  <ion-col size="12" class="text-center">
                    <span class="info-text-s text-color-primary">{{storyExt.modeText}}</span>
                  </ion-col>
                </ion-row>

                <ion-row class="padding-top-xs">
                  <ion-col size="12" class="text-center">
                    <span class="info-text-m text-color-primary">{{levelDisp}}</span>
                  </ion-col>
                </ion-row>

                <ion-row class="padding-bottom-xs">
                  <ion-col size="12" class="text-center">
                    <span class="info-text-xs text-color-primary">{{rewardDisp}}</span>
                  </ion-col>
                </ion-row>

                <ion-row class="padding-xs" *ngIf="storyExt">
                  <ion-col size="12" class="progress-bar-alternate">
                    <mat-progress-bar mode="determinate" class="progress-rounded" color="primary"
                      [value]="storyExt.storyProgress"></mat-progress-bar>
                  </ion-col>
                </ion-row>

                <div *ngIf="entryPlace && !lockedState">
                  <ion-list class="padding-top-s">
                    <ion-item class="list-item transparent-bg">
                      <place-card class="full-w" [place]="entryPlace" tappable></place-card>
                    </ion-item>
                  </ion-list>
                </div>

                <div tappable class="padding-xs text-center" *ngIf="isBeta">
                  <span class="as-text element-selector info-text-xs text-color-primary">UNLISTED</span>
                </div>

                <div tappable class="padding-xs text-center" *ngIf="isDev">
                  <span class="as-text element-selector info-text-xs text-color-primary">DEV</span>
                </div>

                <!-- <ion-row *ngIf="languageDisp" class="padding-xs">
                  <ion-col size="12" class="text-center">
                    <span class="info-text-m text-color-primary">{{languageDisp}}</span>
                  </ion-col>
                </ion-row> -->

                <ion-row *ngIf="languageDisp">
                  <ion-col size="12">
                    <ion-button class="action-button-fill-xs button-color-transparent" (click)="switchLanguage()">
                      <span class="button-font-size-s">{{languageDisp.nameLocale}}</span>
                      <icon-wrapper class="slot-icon-padding" slot="start" [icon]="languageDisp.icon" [custom]="true">
                      </icon-wrapper>
                    </ion-button>
                  </ion-col>
                </ion-row>

                <div tappable class="padding-xs text-center" *ngIf="languageDisp && isMultiLanguage"
                  (click)="switchLanguage()">
                  <span
                    class="as-text element-selector button-font-size-mlg text-color-primary">{{descriptionSwitchLanguage}}</span>
                </div>

                <audio-guide *ngIf="withAudioGuide && descriptionPlain" label="" [contentHTML]="descriptionPlain"
                  [autoplay]="false"></audio-guide>

                <ion-row class="padding-xs">
                  <ion-col size="12">
                    <div class="text-left">
                      <safe-html-renderer [imageWrapper]="true" [unsafeHtmlContent]="story.description"
                        innerClass="info-text-m description-frame text-color-primary"></safe-html-renderer>
                    </div>
                  </ion-col>
                </ion-row>

                <div *ngIf="videoUrl" class="margin-top-s margin-bottom-xs">
                  <youtube-view [videoUrl]="videoUrl" customClass="height-40"></youtube-view>
                </div>

                <div tappable class="padding-xs text-center" *ngIf="descriptionFooter" (click)="scrollToStart()">
                  <span
                    class="as-text element-selector button-font-size-mlg text-color-primary">{{descriptionFooter}}</span>
                </div>

              </div>

              <ion-row *ngIf="storyLoaded">
                <ion-col size="12" class="text-center">
                  <!-- padding-right-m -->
                  <!-- class="padding-horizontal-s" -->
                  <div class="margin-right-s">
                    <location-dots-v [useDefaultPhotos]="useDefaultLocationPhotos" class="icon-color-primary-low"
                      [data]="appLocations" [story]="story"
                      (select)="getLocationDetailsForIndexOnClick($event[0])"></location-dots-v>
                  </div>
                </ion-col>
              </ion-row>

              <div class="text-color-overlay margin-horizontal-s margin-top-s" *ngIf="hasProviders">
                <ion-list class="list-padding">
                  <span class="info-text-mlg bold text-color-primary">Provided by</span>
                  <ion-item *ngFor="let bloc of storyProviders; let i = index;" class="list-item transparent-bg"
                    no-lines>
                    <place-items-card class="full-w" *ngIf="bloc" [location]="bloc" tappable [crop]="false"
                      (click)="getPlaceDetails(i)">
                    </place-items-card>
                  </ion-item>
                </ion-list>
              </div>

              <div class="text-color-overlay margin-horizontal-s text-left margin-top-s" *ngIf="extDataLoaded">

                <div class="center-text margin-top-s">
                  <span class="info-text-mlg bold text-color-primary">Notes</span>
                </div>

                <div *ngFor="let fc of faqCategories" class="list-item list-item-padding transparent-bg">
                  <div *ngFor="let e of fc.faqEntries" class="list-item list-item-padding transparent-bg">
                    <plain-entry-expand [e]="e"></plain-entry-expand>
                  </div>
                </div>

                <div class="center-text margin-top-s" *ngIf="hasWeather">
                  <span class="info-text-mlg bold text-color-primary">Weather</span>
                </div>

                <div class="padding-s" *ngIf="hasWeather">
                  <weather-info *ngIf="wd" class="full-w" [wd]="wd" [refresh]="refreshWeather"
                    [celsius]="weatherUnitsCelsius" (switchUnits)="switchWeatherUnits($event)">
                  </weather-info>
                </div>

                <div class="center-text margin-top-s" *ngIf="withTeams">
                  <span class="info-text-mlg bold text-color-primary">Gameplay</span>
                </div>

                <div class="margin-top-s" *ngIf="withTeams">
                  <nav-bar [slidesPerView]="categoryTabsGroupCount" [(selectedTabId)]="selectedIndex"
                    [loadData]="loaded" [navBarItems]="navBarItems" (select)="selectIndexFromNavItem($event)">
                  </nav-bar>
                </div>

                <div *ngIf="withTeams" [innerHTML]="gameModeDescription"
                  class="info-text-m description-frame text-color-primary margin-top-s margin-bottom-s padding-horizontal-s">
                </div>

                <div *ngIf="withTeams && connectedGroup" class="card-selected">
                  <group-card class="full-w" tappable (select)="goToGroup(connectedGroup)" [group]="connectedGroup"
                    [noBorder]="true" [withReload]="showProgressReload && autoReload" [reloadTimeout]="loadingTimeout"
                    [resetLoadingTimeout]="resetLoadingTimeout"></group-card>
                </div>

                <div *ngIf="withTeams && withTeamsSelected && !connectedGroup" class="border-frame"
                  [ngClass]="inputFocused ? 'scroll-padding' : ''">
                  <edit-code [(prop)]="quickJoinCode" placeholder="" [numeric]="false" [dim]="unlockCodeShortLength"
                    [autocaps]="true" [disabled]="false" (focusChange)="textAreaFocused($event)"
                    (inputComplete)="updateQuickJoin()" enterKeyPress (enterKeyPress)="quickJoin()">
                    <inner-content>Quick join code</inner-content>
                  </edit-code>
                </div>

                <div *ngIf="withTeams && withTeamsSelected">
                  <ion-row class="padding-s">
                    <ion-col size="12">
                      <ion-button *ngIf="!connectedGroup && !withQuickJoin"
                        class="action-button-fill button-color-alternate" [disabled]="false" (click)="openLobby()">
                        <span class="button-font-size-s">Game Lobby</span>
                        <icon-wrapper class="slot-icon-padding" slot="start"
                          [icon]="appIconsStandard.groups"></icon-wrapper>
                      </ion-button>

                      <ion-button *ngIf="!connectedGroup && withQuickJoin"
                        class="action-button-fill button-color-accent" [disabled]="false" (click)="quickJoin()">
                        <span class="button-font-size-s">Quick Join</span>
                        <icon-wrapper class="slot-icon-padding" slot="start"
                          [icon]="appIconsStandard.groups"></icon-wrapper>
                      </ion-button>

                      <ion-button *ngIf="connectedGroup" class="action-button-fill button-color-alternate"
                        (click)="openGroupLobby()">
                        <span class="button-font-size-s">Team Lobby</span>
                        <icon-wrapper class="slot-icon-padding" slot="start"
                          [icon]="appIconsStandard.groups"></icon-wrapper>
                      </ion-button>
                    </ion-col>
                  </ion-row>
                </div>

                <div class="center-text margin-top-s" *ngIf="withTeams && teamUnlockCodeLoaded && teamUnlockCode">
                  <span class="info-text-mlg bold text-color-primary">Tickets</span>
                </div>

                <div *ngIf="withTeams && teamUnlockCodeLoaded && teamUnlockCode" class="border-frame">
                  <edit-code [(prop)]=" teamUnlockCode.unlockCode" placeholder="" [numeric]="false"
                    [dim]="unlockCodeShortLength" [autocaps]="true" [disabled]="true">
                    <inner-content>Team Unlock Code</inner-content>
                  </edit-code>
                </div>

                <div *ngIf="withTeams && teamUnlockCodeLoaded && teamUnlockCode" class="padding-top-s padding-bottom-s">
                  <kv-table [data]="unlockCodeStatsTable" [format]="false"></kv-table>
                </div>


                <div class="center-text margin-top-s" *ngIf="withConnect">
                  <span class="info-text-mlg bold text-color-primary">Connect</span>
                </div>

                <div *ngIf="withConnect"
                  class="text-left description-frame info-text-s padding-top-s padding-horizontal-s"
                  [innerHTML]="withConnectLabel">
                </div>

                <div *ngIf="withConnect" tappable class="text-center padding-top-s padding-bottom-s"
                  (click)="openConnectPage()">
                  <span
                    class="as-text element-selector button-font-size-mlg text-color-primary">{{withConnectButtonLabel}}</span>
                </div>

                <div [innerHTML]="loggedInMessage"
                  class="info-text-s description-frame text-color-primary margin-top-s margin-bottom-s padding-horizontal-s text-center">
                </div>

              </div>

              <!-- <div class="text-color-overlay margin-horizontal-s text-left margin-top-s" *ngIf="withTeams">               

              </div> -->

            </div>

            <div fxFlex></div>

            <div #startButtonBar>
              <ion-grid class="full-w padding-bottom-xs">
                <ion-row *ngIf="lockedState">
                  <ion-col size="12">
                    <ion-button class="action-button-fill button-color-alternate" (click)="loadStoryWrapper(true)"
                      [disabled]="!loaded || unlockInProgress">
                      <span class="button-font-size-s">unlock</span>
                      <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIconsStandard.locked">
                      </icon-wrapper>
                    </ion-button>
                  </ion-col>
                </ion-row>
                <ion-row *ngIf="!lockedState">
                  <ion-col size="6">
                    <ion-button class="action-button-fill button-color-alternate"
                      [disabled]="!storyLoaded || lockPreview" (click)="viewLeaderboard()">
                      <span class="button-font-size-s">LP Board</span>
                      <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIconsStandard.leaderboard">
                      </icon-wrapper>
                    </ion-button>
                  </ion-col>
                  <ion-col size="6">
                    <ion-button class="action-button-fill button-color-accent" [disabled]="!storyLoaded || startLocked"
                      (click)="startButton()">
                      <span class="button-font-size-s">{{startButtonText}}</span>
                      <icon-wrapper class="slot-icon-padding" slot="start" icon="map"></icon-wrapper>
                    </ion-button>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</ion-content>