import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EventsHomePage } from './events-home.page';
import { PagesModule } from '../../pages.module';

const routes: Routes = [
  {
    path: '',
    component: EventsHomePage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [EventsHomePage]
})
export class EventsHomePageModule { }
