import { Media, MediaObject, MEDIA_STATUS } from '@ionic-native/media/ngx';
import { Injectable } from '@angular/core';
import { BehaviorSubject, timer } from 'rxjs';
import { ResourceManager } from '../../../classes/general/resource-manager';
import { IChallengeParams, IChallengeData, EChallengeStatus } from '../../../classes/def/core/challenge';
import { ChallengeCoreService } from '../modules/challenge-core';
import { ISoundResultResponse } from '../../../classes/def/media/processing';
import { SoundService } from '../../media/sound';
import { PermissionsService } from '../../general/permissions/permissions';

@Injectable({
    providedIn: 'root'
})
export class SoundValidatorService {
    timeouts = {
        ui: null
    };

    mediaObject: MediaObject = null;

    standardDuration: number = 5000;
    maxDuration: number = 10000;

    statusObservable: BehaviorSubject<number>;
    amplitudeObservable: BehaviorSubject<number>;

    triggerableTimeouts = {
        timedRecording: null
    };

    subscription = {
        statusUpdate: null,
        success: null,
        error: null,
        challengeTimer: null,
        watchAmplitude: null
    };

    timers = {

    };

    isRecording: boolean = false;
    recordingComplete: boolean = false;

    recordingFilename: string = "";

    constructor(
        private media: Media,
        private challengeCore: ChallengeCoreService,
        public permissions: PermissionsService,
        public sound: SoundService
    ) {
        console.log("sound validator service created");
        this.statusObservable = new BehaviorSubject(null);
        this.amplitudeObservable = new BehaviorSubject(null);
    }

    /**
     * begin challenge, start timeout, start recording
     * @param item 
     */
    beginSoundChallenge(test: boolean) {
        let promise = new Promise((resolve, reject) => {
            this.statusObservable.next(null);
            this.amplitudeObservable.next(null);

            let promiseInitRecording;

            if (test) {
                promiseInitRecording = Promise.resolve(true);
            } else {
                promiseInitRecording = new Promise((resolve, reject) => {
                    this.permissions.requestMicrophoneRecordingPermissions().then(() => {
                        this.startRecording().then(() => {
                            resolve(true);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                    }).catch((err: Error) => {
                        reject(err);
                    });
                });
            }
            promiseInitRecording.then(() => {
                let params: IChallengeParams = {
                    timeLimit: this.standardDuration / 1000
                };
                this.challengeCore.startChallenge(params);
                this.subscription.challengeTimer = this.challengeCore.getWatch().subscribe(() => {
                    let data: IChallengeData = this.challengeCore.getTimeoutData();
                    if (data) {
                        if (data.status === EChallengeStatus.expired) {
                            this.stopRecording();
                        }
                    }
                });
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    watchAmplitude() {
        return this.amplitudeObservable;
    }


    private startWatchAmplitude() {
        if (!this.subscription.watchAmplitude) {
            let timer1 = timer(0, 100);
            this.subscription.watchAmplitude = timer1.subscribe(() => {
                this.mediaObject.getCurrentAmplitude().then((value: number) => {
                    this.amplitudeObservable.next(value);
                }).catch(() => {
                    this.amplitudeObservable.next(null);
                });
            });
        }
    }

    /**
     * watch timeout from controller
     */
    watchTimerProgress() {
        return this.challengeCore.getWatch();
    }

    /**
     * finalize sound challenge on demand
     */
    stopSoundChallenge() {
        this.challengeCore.stopChallenge();
        this.stopRecording();
    }


    /**
     * record audio file
     * record for a max amount of time specified in the controller
     * the recording can be stopped at any time by using a triggerable timeout
     */
    private startRecording() {
        // Recording to a file
        console.log("start recording");
        let promise = new Promise((resolve, reject) => {
            if (this.isRecording) {
                reject(new Error("recording in progress"));
                return;
            }

            // this.recordingFilename = this.file.tempDirectory.replace(/^file:\/\//, '') + this.getAudioRecordingName();
            this.recordingFilename = this.sound.getAudioRecordingPath();
            this.recordingComplete = false;
            // this.file.createFile(this.file.tempDirectory, this.getAudioRecordingName(), true).then(() => {
            //     console.log("start recording: ", this.recordingFilename);
            //     // this.file.createFile()
            this.mediaObject = this.media.create(this.recordingFilename);
            console.log(this.mediaObject);
            this.mediaObject.startRecord();
            // to listen to plugin events:
            this.subscription.statusUpdate = this.mediaObject.onStatusUpdate.subscribe(status => {
                console.log("file status changed: ", status);
                switch (status) {
                    case MEDIA_STATUS.RUNNING:
                        // the recording process is up and running
                        this.startWatchAmplitude();
                        resolve(true);
                        break;
                }
            }); // fires when file status changes

            this.subscription.success = this.mediaObject.onSuccess.subscribe(() => {
                console.log('Action is successful');
            });

            this.subscription.error = this.mediaObject.onError.subscribe(error => {
                console.error("Media error");
                reject(error);
            });

            // fallback timeout
            this.triggerableTimeouts.timedRecording = ResourceManager.createTriggerableTimeout(() => {
                this.stopRecordingCore();
            }, this.maxDuration);

            // }).catch((err: Error) => {
            //     reject(err);
            // });
        });
        return promise;

    }


    /**
     * deinit resources after recording stopped
     * this will be called always after the recording is done (either the safety timeout expired or it was triggered earlier by the challenge controller)
     */
    private stopRecordingCore() {
        console.log("stop recording");
        if (this.mediaObject) {
            this.mediaObject.stopRecord();
            this.mediaObject.release();
            this.mediaObject = null;

            // /** Do something with the record file and then delete */
            // this.file.removeFile(this.file.tempDirectory, this.getAudioRecordingName()).then(() => {

            // }).catch((err: Error) => {
            //     console.error(err);
            // });
        }
        this.isRecording = false;
        this.recordingComplete = true;
        this.subscription = ResourceManager.clearSubObj(this.subscription);
    }


    /**
     * stop the ongoing recording as triggerable timeout
     */
    stopRecording() {
        // will trigger stop recording core
        this.triggerableTimeouts.timedRecording = ResourceManager.clearTriggerableTimeout(this.triggerableTimeouts.timedRecording);
        // call the method anyway as fallback
        this.stopRecordingCore();
        this.subscription.watchAmplitude = ResourceManager.clearSub(this.subscription.watchAmplitude);
    }


    /**
     * process audio with associated activity code that is used to identify the sound in the recording
     * @param activityCode 
     */
    processAudioOnTheServer(activityCode: number) {
        let promise = new Promise((resolve, reject) => {
            if (!this.recordingComplete) {
                reject(new Error("No recording found"));
                return;
            }
            this.sound.mediaUploadSoundCheckActivity(this.recordingFilename, activityCode).then((resp: ISoundResultResponse) => {
                resolve(resp);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

}
