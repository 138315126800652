
import { Injectable } from '@angular/core';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { Messages } from 'src/app/classes/def/app/messages';
import { ETutorialEntries, ITutorial } from 'src/app/classes/def/app/tutorials';
import { UiExtensionService } from '../ui/ui-extension';
import { StorageOpsService } from '../data/storage-ops';
import { ResourcesCoreDataService } from '../../data/resources-core';
import { ELocalAppDataKeys } from 'src/app/classes/def/app/storage-flags';


@Injectable({
    providedIn: 'root'
})
export class AdSupportService {

    constructor(
        private uiext: UiExtensionService,
        private storageOps: StorageOpsService,
        private resources: ResourcesCoreDataService
    ) {
        console.log("ads support service created");
    }

    /**
     * check if consent previously given in local storage
     */
    checkSavedConsent(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            this.storageOps.getLocalDataKey(ELocalAppDataKeys.adConsent).then((value: boolean) => {
                resolve(value);
            }).catch((err: Error) => {
                console.error(err);
                resolve(null);
            });
        });
        return promise;
    }

    /**
    * https://github.com/ratson/cordova-plugin-admob-free/issues/223#issuecomment-392913554
    * ask for consent
    * resolve true/false (proceed with personalized ads or not)
    */
    showConsent(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            console.log("show consent");

            let actions: IPopoverActions = {
                ok: {
                    name: "Yes, continue to see relevant ads",
                    code: 0,
                    enabled: true
                },
                later: {
                    name: "No, see ads that are less relevant",
                    code: 1,
                    enabled: true
                },
                no: {
                    name: "Pay for the ad-free version",
                    code: 2,
                    enabled: true
                }
            };

            this.resources.getInfoData(ETutorialEntries.adConsent, true).then((resp: ITutorial) => {
                this.uiext.showCustomAlert(resp.title, resp.description, actions).then((resp: number) => {
                    let promiseConsent: Promise<boolean>;
                    switch (resp) {
                        case 0:
                            promiseConsent = Promise.resolve(true);
                            break;
                        case 2:
                            promiseConsent = new Promise((resolve) => {
                                this.uiext.showAlert(Messages.msg.removeAdsInfo.after.msg, Messages.msg.removeAdsInfo.after.sub, 1, ["ok"], true).then(() => {
                                    resolve(false);
                                }).catch((err: Error) => {
                                    console.error(err);
                                    resolve(false);
                                });
                            });
                            break;
                        case 1:
                        default:
                            promiseConsent = Promise.resolve(false);
                            break;
                    }

                    promiseConsent.then((res: boolean) => {
                        this.storageOps.setStorageFlagNoAction({
                            flag: ELocalAppDataKeys.adConsent,
                            value: res
                        });
                        resolve(res);
                    });

                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

}

