import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IGenericEntry } from 'src/app/classes/def/app/entry';
import { StringUtils } from 'src/app/services/app/utils/string-utils';

@Component({
  selector: 'plain-entry-expand',
  templateUrl: './plain-entry-expand.component.html',
  styleUrls: ['./plain-entry-expand.component.scss'],
})
export class PlainEntryExpandComponent implements OnInit {

  @Input()
  e: IGenericEntry;

  @Input()
  fixed: boolean;
 
  @Output()
  action: EventEmitter<number> = new EventEmitter();

  descriptionHtml: string;

  expanded: boolean = false;

  constructor(

  ) { }

  ngOnInit() {
    if (this.e) {
      if (this.fixed) {
        this.expanded = true;
      }
      this.setExpanded(this.expanded);
    }
  }

  ngOnDestroy() {

  }

  onTapSelect(code: number) {
    this.action.emit(code);
  }

  toggleExpanded() {
    this.expanded = !this.expanded;
    this.setExpanded(this.expanded);
  }

  setExpanded(expanded: boolean) {
    if (!expanded) {
      this.descriptionHtml = StringUtils.trimHtmlPar(this.e.description, null, 1);
    } else {
      this.descriptionHtml = this.e.description;
    }
  }
}

