<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="!enableBackButton" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()"
    title="About" rightButton="settings" [rightIcon]="true" (rightClick)="presentPopover()"
    [loading]="!loaded"></leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <!-- (swipe)="swipeEvent($event)" -->
  <div class="view-frame theme-background" fxLayout="column">

    <div class="scrollbar-y margin-top-s padding-s" fxLayout="column">
      <span class="info-text-m text-color-primary text-center">
        Version {{version}}
      </span>
      <safe-html-renderer [@showState]="showState" [unsafeHtmlContent]="description"
        innerClass="padding-s info-text-m description-frame text-color-primary"></safe-html-renderer>
    </div>
    <div fxFlex></div>

    <div>
      <ion-grid class="full-w">
        <ion-row>
          <ion-col size="4">
            <ion-button class="action-button-fill button-color-primary-70" (click)="openWebpage()">
              <icon-wrapper slot="icon-only" icon="globe"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="4">
            <ion-button class="action-button-fill button-color-primary-70" (click)="openFBPage()">
              <icon-wrapper slot="icon-only" icon="logo-facebook"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="4">
            <ion-button class="action-button-fill button-color-primary-70" (click)="contact()">
              <icon-wrapper slot="icon-only" icon="mail"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

    <!-- <div class="padding-s" fxLayout="column">
        <div center >
          <a class="details text-color-primary" tappable (click)="openWebpage()">About LEPLACE</a>
        </div>
      </div> -->
  </div>
</ion-content>