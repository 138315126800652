import { BehaviorSubject } from 'rxjs';
export interface IOverlayParams {
    id?: string;
    heading: string;
    description: string;
    photoUrl: string;
    continuous: boolean;
    timeout: number;
    extHandleObservable?: BehaviorSubject<any>;
    scope: number;
}

export interface IOverlayUpdateParams {
    mode: number,
    type: number,
    params: IOverlayParams
}

export enum EOverlayUpdateMode {
    updateContent = 1,
    replaceModal = 2,
    dismissModal = 3
}

export enum EModalScope {
    modal = 1,
    loading = 2,
    toast = 3
}