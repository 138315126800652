<div *ngIf="loaded && hasData">
  <ion-grid class="full-w padding-xs">
    <ion-row class="center-items">
      <ion-col size="4">
        <img *ngIf="photoUrl" [src]="photoUrl | safeUrl" alt="Avatar"
          [ngClass]="crop ? 'img-avatar-25-crop' : 'img-avatar-25'" height="80">

        <!-- <img *ngIf="photoUrl" [src]="photoUrl | safeUrl" alt="Avatar" class="img-avatar-header-large"> -->

        <!-- <img-loader useImg [src]="photoUrl" alt="Avatar" [imgAttributes]="[{
                  element: 'class',
                  value: 'img-avatar-25'
                }, {
                    element: 'height',
                    value: 80
                }]">  </img-loader> -->
      </ion-col>
      <ion-col size="8">
        <ion-row>
          <!-- <ion-col size="6" class="text-left">
                          <span class="info-text-lg text-color-primary">{{user.rank}}</span>
                      </ion-col> -->
          <ion-col size="12">
            <span class="info-text-m bold text-color-primary">{{placeName}}</span>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <span class="info-text-xs bold bold uppercase text-color-element">
              {{placeType}}
            </span>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="6">
            <ion-button class="action-button-xs button-color-transparent" [disabled]="!mapsUrl"
              (click)="openURL(mapsUrl)">
              <!-- <img [src]="appIcons.maps" height="30"> -->
              <icon-wrapper [icon]="appIcons.googleMaps" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button class="action-button-xs button-color-transparent" [disabled]="!placeUrl"
              (click)="goToReference()">
              <icon-wrapper [icon]="appIcons.www" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-col>
    </ion-row>

    <ion-row *ngIf="descriptionHtml">
      <safe-html-renderer [unsafeHtmlContent]="descriptionHtml"
        innerClass="info-text-m description-frame text-color-primary"></safe-html-renderer>
    </ion-row>
  </ion-grid>
</div>

<div class="content" fxLayout="column" *ngIf="loaded && !hasData">
  <span class="info-text-m margin-top-s text-color-element">
    Place unavailable
  </span>
</div>