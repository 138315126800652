import { Component, OnInit, OnDestroy, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { IUserLoginRequest, IUserLoginCommons } from 'src/app/classes/def/user/general';
import { Subscription } from 'rxjs';
import { Platform } from '@ionic/angular';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { Messages } from 'src/app/classes/def/app/messages';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { NgForm } from '@angular/forms';
import { IPopoverInputs, IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { IGenericResponse } from 'src/app/classes/def/requests/general';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { AuthRequestService } from 'src/app/services/general/auth-request/auth-request';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { LoginExtService } from 'src/app/services/general/auth-request/login';
import { ERouteDef } from 'src/app/app-utils';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { SignupTermsPage } from '../signup-terms/signup-terms.page';
import { ISignupTermsNavParams } from 'src/app/classes/def/nav-params/modals';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { ApiDef } from 'src/app/classes/app/api';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { Router } from '@angular/router';
import { KeyboardProvider } from 'src/app/services/apis/keyboard';
import { ScriptLoaderService } from 'src/app/services/general/script-loader';
import { IGoogleProfileData } from 'src/app/classes/def/social/profile';
import { EScripts } from 'src/app/classes/utils/script-loader';
import { AppVersionService } from 'src/app/services/general/app-version';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

declare var google: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.page.html',
  styleUrls: ['./login.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LoginPage implements OnInit, AfterViewInit, OnDestroy {
  login: IUserLoginRequest = {
    // email: 'test@gmail',
    // password: '12345678'
    email: '',
    password: ''
  };
  submitted = false;
  authWatch: Subscription = null;
  message: string = "";

  theme: string = "theme-light theme-light-bg";

  justSignedUp: boolean = false;

  loading: boolean = false;

  np: INavParams = null;

  appleLogin: boolean = false;
  apiKeysLoaded: boolean = false;

  useWebSignInGoogleAPI: boolean = false;
  enableGoogleLogin: boolean = true;

  platform: IPlatformFlags = {} as IPlatformFlags;

  watchApiKeyLoadingRequiredSub: Subscription = null;
  isWeb: boolean = false;
  vs: IViewSpecs;

  constructor(
    public router: Router,
    public authentication: AuthRequestService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public uiext: UiExtensionService,
    public settingsProvider: SettingsManagerService,
    public backButton: BackButtonService,
    public analytics: AnalyticsService,
    public loginProvider: LoginExtService,
    public nps: NavParamsService,
    public resourcesCore: ResourcesCoreDataService,
    public keyboardProvider: KeyboardProvider,
    public appVersionService: AppVersionService,
    public scriptLoader: ScriptLoaderService
  ) {

    this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
      if (loaded) {
        this.platform = SettingsManagerService.settings.platformFlags;
        if (this.platform.IOS) {
          this.appleLogin = true;
        }
        if (this.platform.WEB) {
          this.isWeb = true;
          this.useWebSignInGoogleAPI = true;
          if (this.appVersionService.checkWebProd()) {
            // no apple login on web prod
            this.appleLogin = false;
          } else {
            // dev mode
            this.appleLogin = true;
          }
        }
        if (this.platform.IOS) {
          // this.useWebSignInGoogleAPI = true;
          // this.enableGoogleLogin = false;
        }
      }
    }, (err: Error) => {
      console.error(err);
    });
  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  checkAuth() {
    // this.authenticated = this.authService.isLoggedIn();
    // this.authWatch = this.authService.checkAuthentication().subscribe(isloggedin => {
    //   console.log("login page, isloggedin: ", isloggedin);
    //   this.authenticated = isloggedin;
    // });
  }

  clearWatch() {
    ResourceManager.clearSub(this.authWatch);
    ResourceManager.clearSub(this.watchApiKeyLoadingRequiredSub);
  }


  ngOnInit() {

    this.keyboardProvider.setEnableScroll(false);

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.login, null, this.np);

      console.log("nav params: ", npInfo.params);

      let npar = npInfo.params;

      let hasParams: boolean = npInfo.hasParams;

      if (hasParams) {
        // the view was opened with parameters
        // either for business registration or for payment (bill)
        let np: INavParams = npar;
        let params: IUserLoginCommons = np.params;

        this.login.email = params.email;
        this.login.password = params.password;
        this.justSignedUp = true;
      }

      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      this.webView.ready().then(() => {
        this.backButton.replace(() => {

          if (!this.uiext.isAlert()) {
            this.uiext.showAlert(Messages.msg.exit.before.msg, Messages.msg.exit.before.sub, 2, null, false).then((res: number) => {
              if (res === EAlertButtonCodes.ok) {
                this.backButton.exitApp();
              }
            }).catch(() => {
            });
          }

        });
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  ngAfterViewInit() {
    this.loadApiKeys().then(() => {
      if (this.useWebSignInGoogleAPI) {
        this.scriptLoader.load([EScripts.googleSignin]).then(() => {
          if (google.accounts) {
            google.accounts.id.initialize({
              client_id: ApiDef.googleWebClientId,
              callback: (response: any) => this.handleGoogleSignIn(response)
            });
            google.accounts.id.renderButton(document.getElementById("buttonDiv"), {
              // type: 'standard',
              // size: 'medium',
              // text: 'signin_with',
              // theme: 'filled_blue'
              // theme: 'outline',
              scope: 'profile email',
              size: 'medium',
              // size: 'large',
              type: "icon",
              // type: 'standard',
              shape: "pill",
              // logo_alignment: 'left'
            });
            // google.accounts.id.prompt(); // also display the One Tap dialog
          } else {
            console.warn("google login not available");
          }
        }).catch((err: Error) => {
          console.error(err);
        });
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  handleGoogleSignIn(response: any) {
    // console.log(response.credential);

    // This next is for decoding the idToken to an object if you want to see the details.
    let base64Url = response.credential.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    let signinData = JSON.parse(jsonPayload);
    // console.log(signinData);

    let googleData: IGoogleProfileData = {
      name: signinData.name,
      email: signinData.email,
      // picture: user.picture,
      picture: null,
      token: response.credential,
      id: signinData.sub
    };

    // console.log(googleData);

    this.loginProvider.loginWithGoogle(googleData).then(() => {
      this.loading = false;
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "login");
      this.loading = false;
    });

  }

  ngOnDestroy() {
    this.keyboardProvider.setEnableScroll(true);
    this.clearWatch();
  }

  /**
   * trigger (re)loading api key (if not already loaded)
   * on connection reset/reload via app.component
   */
  watchLoadApiKeysRequired() {
    this.watchApiKeyLoadingRequiredSub = this.resourcesCore.getWatchTriggerApiKeyLoadingRequired().subscribe((trigger: boolean) => {
      if (trigger) {
        if (!this.apiKeysLoaded) {
          // try reload api keys
          console.log("try reloading api keys via login page");
          this.loadApiKeys();
        }
      }
    }, (err: Error) => {
      console.error(err);
    });
  }

  loadApiKeys() {
    return new Promise<boolean>((resolve) => {
      if (!ApiDef.apiKeysLoaded) {
        this.resourcesCore.loadGoogleWebClientId().then(() => {
          console.log("api key (google web key) loaded via login page: ", ApiDef.googleWebClientId);
          this.apiKeysLoaded = true;
          resolve(true);
        }).catch((err) => {
          console.error(err);
          resolve(false);
        });
      } else {
        this.apiKeysLoaded = true;
        resolve(true);
      }
    });
  }


  onLogin(form: NgForm) {
    this.submitted = true;
    console.log("logging in");
    if (form.valid) {
      this.loginWithEmail(this.login.email, this.login.password);
    } else {
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, "Please enter the required fields.");
    }
  }


  /**
   * login with email on the server
   * @param email 
   * @param password 
   */
  loginWithEmail(email: string, password: string) {
    if (this.loading) {
      // previous loading in progress
      return;
    }
    this.loading = true;
    this.loginProvider.loginWithEmail(email, password).then(() => {
      this.loading = false;
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "login");
      this.loading = false;
    });
  }

  /**
   * login with google
   * the backend validates the access token and retrieves the username and email from google api
   */
  loginWithGoogle() {
    if (this.loading) {
      // previous loading in progress
      return;
    }
    this.loading = true;
    this.loginProvider.loginWithGoogle(null).then(() => {
      this.loading = false;
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "login");
      this.loading = false;
    });
  }

  showSignupTerms() {

    let params: ISignupTermsNavParams = {
      withButtons: false
    };

    this.uiext.showCustomModal(null, SignupTermsPage, {
      view: {
        fullScreen: false,
        transparent: false,
        large: true,
        addToStack: true,
        frame: false
      },
      params: params
    }).then((accept: boolean) => {
      console.log("signup terms resolved: ", accept);
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  /**
   * sign in with facebook
   */
  loginWithFacebook() {
    if (this.loading) {
      // previous loading in progress
      return;
    }
    this.loading = true;
    this.loginProvider.loginWithFacebook().then(() => {
      this.loading = false;
    }).catch((err: Error) => {
      console.error(err);
      this.loading = false;
      this.analytics.dispatchError(err, "login");
    });
  }

  /**
   * sign in with apple (iOS only)
   */
  signInWithApple() {
    if (this.loading) {
      // previous loading in progress
      return;
    }
    this.loading = true;
    this.loginProvider.signInWithApple().then(() => {
      this.loading = false;
    }).catch((err: Error) => {
      console.error(err);
      this.analytics.dispatchError(err, "login");
      this.loading = false;
    });
  }

  // /**
  //  * submit login from any social provider (google, facebook)
  //  * @param socialData 
  //  */
  // submitSocialLogin_deprecated(socialData: ISocialProfileData) {
  //   // ask for a password    
  //   this.createPassword().then((password: string) => {
  //     this.authentication.signUpWithEmail(socialData.email, socialData.name, password, true).then((_result: IGenericResponse) => {
  //       this.loginWithEmail(socialData.email, password);
  //     }).catch((_err) => {
  //       this.uiext.showAlert(Messages.msg.info.after.msg, 
  // "You might be already registered. Please enter the password that you used for registering on LEPLACE or contact support", 2, null).then((res: number) => {
  //         if (res === EAlertButtonCodes.ok) {
  //           this.enterPassword().then((password: string) => {
  //             this.loginWithEmail(socialData.email, password);
  //           }).catch((err: Error) => {
  //             this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
  //           });
  //         } else {

  //         }
  //       }).catch((err: Error) => {
  //         console.error(err);
  //       });
  //     });
  //   }).catch((err: Error) => {
  //     this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
  //   });
  // }

  setFocus(event, input) {
    console.log(event, input);
    // input.nativeElement.setFocus();
    // input.nativeElement.focus();
    input.setFocus();
    // this.renderer.invokeElementMethod(input.nativeElement, 'focus');
  }

  /**
   * set password after facebook login
   * the password is used to save user into db
   */
  createPassword() {
    let popoverInputs: IPopoverInputs = {
      password: {
        // the name is the actual tag here
        name: "password",
        value: "",
        placeholder: "new password",
        type: "password",
        enabled: true
      },
      confirmPassword: {
        name: "confirmPassword",
        value: "",
        placeholder: "confirm password",
        type: "password",
        enabled: true
      }
    };
    let popoverActions: IPopoverActions = {
      ok: {
        name: "Ok",
        code: 1,
        enabled: true
      }
    };
    let promise = new Promise((resolve, reject) => {
      this.uiext.showCustomAlertInput("Create Password", null, popoverInputs, popoverActions).then((res: any) => {
        let code = res.code;
        let data = res.data;
        switch (code) {
          case 1:
            if (data.password === data.confirmPassword) {
              resolve(data.password);
            } else {
              this.uiext.showAlert(Messages.msg.passwordsDoNotMatch.after.msg, Messages.msg.passwordsDoNotMatch.after.sub, 1, null).then(() => {
                reject(new Error("passwords do not match"));
              }).catch((err: Error) => {
                console.error(err);
                reject(err);
              });
            }
            break;
          default:
            reject(new Error("no action"));
            break;
        }
      }).catch((err: Error) => {
        console.error(err);
        reject(err);
      });
    });
    return promise;
  }


  enterPassword() {
    let popoverInputs: IPopoverInputs = {
      password: {
        // the name is the actual tag here
        name: "password",
        value: "",
        placeholder: "password",
        type: "password",
        enabled: true
      }
    };
    let popoverActions: IPopoverActions = {
      ok: {
        name: "Ok",
        code: 1,
        enabled: true
      }
    };
    let promise = new Promise((resolve, reject) => {
      this.uiext.showCustomAlertInput("Enter Password", null, popoverInputs, popoverActions).then((res: any) => {
        let code = res.code;
        let data = res.data;
        switch (code) {
          case 1:
            resolve(data.password);
            break;
          default:
            reject(new Error("no action"));
            break;
        }
      }).catch((err: Error) => {
        console.error(err);
        reject(err);
      });
    });
    return promise;
  }


  forgotMyPassword(form: NgForm) {
    console.log(form.controls.email.valid);
    if (form.controls.email.valid) {
      this.authentication.forgotMyPassword(form.value.email).then((_response: IGenericResponse) => {
        this.uiext.showAlertNoAction(Messages.msg.requestNewPasswordEmail.after.msg, Messages.msg.requestNewPasswordEmail.after.sub);
      }).catch((err: Error) => {
        this.analytics.dispatchError(err, "login");
        this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
      });
    } else {
      this.uiext.showAlertNoAction(Messages.msg.error.after.msg, "Please enter your valid email");
    }
  }

  logout() {
    this.authentication.logout().then(() => { }).catch((err: Error) => {
      console.error(err);
    });
  }

  isLoggedIn() {
    return this.authentication.isLoggedIn();
  }

  onSignup() {
    this.router.navigate([ERouteDef.signup], { replaceUrl: true }).then(() => {
    }).catch((err: Error) => {
      console.error(err);
    });
  }

}
