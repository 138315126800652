import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IUserStatCategory } from 'src/app/classes/def/user/stats';
import { IKVStat } from 'src/app/classes/def/app/kval';
import { INavBarItem } from 'src/app/classes/def/views/nav';
import { AlertController, PopoverController, Platform, MenuController, ModalController } from '@ionic/angular';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { IAccountDetailsNavParams } from 'src/app/classes/def/nav-params/account';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { ESkillCategory } from 'src/app/classes/def/user/skills';
import { IGenericResponse } from 'src/app/classes/def/requests/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { AuthRequestService } from 'src/app/services/general/auth-request/auth-request';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { UserStatsDataService } from 'src/app/services/data/user-stats';
import { ERouteDef } from 'src/app/app-utils';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { EventsDataService } from 'src/app/services/data/events';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { Router } from '@angular/router';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';


// declare var vis: any; 

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.page.html',
  styleUrls: ['./account-details.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AccountDetailsPage implements OnInit, OnDestroy {
  heading: string = "";
  password: string = "";
  confirmPassword: string = "";
  isOnline: boolean = false;
  userStats: IUserStatCategory = null;
  userStatsTable: IKVStat[] = null;
  levelPercent: number = 0;
  details: string = "";
  categoryCode: number = null;
  levelTypeLabel: string = "";
  loaded: boolean = false;
  categoryTabs: INavBarItem[] = [];
  theme: string = "theme-light theme-light-bg";

  np: INavParams = null;
  vs: IViewSpecs;
  headerClass: string = "";
  isModal: boolean = false;

  show = {
    levelBar: true,
    categoryTabs: true
  };

  constructor(

    public authService: AuthRequestService,
    public alertCtrl: AlertController,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public popoverCtrl: PopoverController,
    public userStatsProvider: UserStatsDataService,
    public eventData: EventsDataService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public backButton: BackButtonService,
    public menuCtrl: MenuController,
    public nps: NavParamsService,
    public analytics: AnalyticsService,
    public settingsProvider: SettingsManagerService,
    public modalCtrl: ModalController,
    public router: Router
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  selectCategoryFromNavItem(item: INavBarItem) {
    console.log(item);
    this.categoryCode = item.value;
    this.levelTypeLabel = item.name;
    GeneralCache.metricsSelectedCategory = this.categoryCode;
    this.getAccountStats();
  }

  swipeEvent(e) {
    this.backButton.handleSwipeEvent(e);
  }

  ngOnInit() {
    this.uiext.disableSidemenu();
    this.analytics.trackView("account-details");
    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.accountDetails, null, this.np);
      console.log("nav params: ", npInfo.params);
          
      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      if (npInfo.hasParams) {
        // the view was opened with parameters
        // either for business registration or for payment (bill)
        let np: INavParams = npInfo.params;
        let params: IAccountDetailsNavParams = np.params;
        this.vs = np.view;
        this.isModal = this.vs ? this.vs.isModal : false;
        this.categoryCode = params.categoryCode;
        this.levelTypeLabel = params.label ? params.label : "";
        this.categoryTabs = params.categoryTabs;

        this.getStatsContext();
      }

      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back();
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * get stats from requested context
   * e.g. user, group stats
   */
  getStatsContext() {
    this.heading = GeneralCache.resourceCache.user.general.content.name;
    this.getUsername();
    this.getAccountStats();
  }

  ngOnDestroy() {
    this.uiext.enableSidemenu();
    this.backButton.checkPop(this.vs);
  }

  getAccountStats() {
    // console.log("get account stats: ", this.categoryCode);
    this.loaded = false;
    let categoryCode: number = this.categoryCode;
    if (categoryCode === ESkillCategory.general) {
      categoryCode = null;
    }
    this.userStatsProvider.getUserStats(categoryCode).then((resp: IGenericResponse) => {
      this.userStats = resp.data;
      if (this.userStats.refLevelUp - this.userStats.refStart > 0) {
        this.levelPercent = (this.userStats.score - this.userStats.refStart) / (this.userStats.refLevelUp - this.userStats.refStart) * 100;
      } else {
        this.levelPercent = 0;
      }

      if (this.userStats.score > this.userStats.refLevelUp) {
        this.details = "Level " + this.userStats.level + " (" + this.userStats.score + " XP)";
      } else {
        this.details = "Level " + this.userStats.level + " (" + this.userStats.score + "/" + this.userStats.refLevelUp + " XP)";
      }

      this.userStatsTable = this.userStats.disp;
      this.loaded = true;
    }).catch((err: Error) => {
      this.loaded = true;
      this.analytics.dispatchError(err, "account-details");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }


  back() {
    if (this.isModal) {
      this.dismissModal();
    } else {
      this.router.navigate([ERouteDef.account], { replaceUrl: true }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }


  dismissModal() {
    setTimeout(() => {
      this.modalCtrl.dismiss().then(() => {

      }).catch((err: Error) => {
        console.error(err);
      });
    });
  }



  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      reload: {
        name: "Refresh",
        code: 7,
        icon: EAppIconsStandard.refresh,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 7:
          this.getStatsContext();
          break;
        default:
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  getUsername() {
    this.authService.checkLoggedInStorage().then(() => {
      this.isOnline = true;
      this.authService.getUsername().then((username: string) => {
        if (username) {
          this.heading = username;
        }
      }).catch((err: Error) => {
        console.error(err);
        this.isOnline = false;
      });
    }).catch((err: Error) => {
      console.error(err);
      this.isOnline = false;
    });
  }
}
