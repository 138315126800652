<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="false" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()" title="Metrics"
    rightButton="settings" [rightIcon]="true" (rightClick)="presentPopover()" [loading]="!loaded">
  </leplace-toolbar>
</ion-header>

<!-- <ion-content padding-s class="theme-bg"> -->
<ion-content [ngClass]="theme">
  <!-- (swipe)="swipeEvent($event)" -->
  <div class="view-frame theme-background" fxLayout="column" style="text-align: center">

    <div class="padding-s" [ngClass]="show.categoryTabs ? 'metrics-height' : 'full-height'" fxLayout="column">
      <span class="info-text-mlg bold text-color-primary">{{heading}}</span>
      <div class="margin-vertical-s progress-bar-alternate" *ngIf="show.levelBar">
        <mat-progress-bar class="progress-rounded" mode="determinate" [value]="levelPercent"></mat-progress-bar>
      </div>

      <span class="info-text-m text-color-primary" *ngIf="details">{{details}}</span>

      <div fxFlex class="scrollbar-y margin-top-s">
        <div *ngIf="userStatsTable">
          <kv-table [data]="userStatsTable"></kv-table>
        </div>
      </div>
    </div>

    <nav-bar *ngIf="show.categoryTabs" class="padding-bottom-s padding-top-s stick-bottom theme-border-top"
      [slidesPerView]="3" [(selectedTabId)]="categoryCode" [navBarItems]="categoryTabs"
      (select)="selectCategoryFromNavItem($event)">
    </nav-bar>
  </div>
</ion-content>