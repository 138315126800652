import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { ICustomParamForActivity } from 'src/app/classes/def/core/custom-param';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ModalController } from '@ionic/angular';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { AdsService } from 'src/app/services/general/apis/ads';
import { InventoryDataService } from 'src/app/services/data/inventory';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { BusinessFeaturesService } from 'src/app/services/app/utils/business';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { ParamHandler } from 'src/app/classes/general/params';
import { EMessageTrim, MessageUtils } from 'src/app/classes/utils/message-utils';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { StringUtils } from 'src/app/services/app/utils/string-utils';

@Component({
  selector: 'modal-activity-param-details-vc',
  templateUrl: './activity-param-details-vc.component.html',
  styleUrls: ['./activity-param-details-vc.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActivityParamDetailsViewComponent implements OnInit, OnDestroy {
  title: string = "Game Item";
  vs: IViewSpecs = ViewSpecs.getDefault();
  param: ICustomParamForActivity = null;
  loaded: boolean = false;
  appIconsStandard = EAppIconsStandard;
  np: INavParams = null;

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public adService: AdsService,
    public inventory: InventoryDataService,
    public analytics: AnalyticsService,
    public businessFeaturesProvider: BusinessFeaturesService,
    public resources: ResourcesCoreDataService
  ) {

  }

  ngOnInit() {
    let hasParams: boolean = ParamHandler.checkParams(this.np);

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      this.param = Object.assign({}, MessageUtils.getDefaultShareMsgParams(), np.params);

      if (np.view) {
        this.vs = np.view;
      }

      if (this.param.name) {
        this.title = StringUtils.trimName(this.param.name, EMessageTrim.titleModalLarge);
      }

      this.loaded = true;

      console.log(np);
    }
  }

  ngOnDestroy() {

  }

  dismiss(value: number) {
    if (value == null) {
      value = EAlertButtonCodes.cancel;
    }
    setTimeout(() => {
      this.modalCtrl.dismiss(value).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  ok() {
    this.dismiss(EAlertButtonCodes.ok);
  }

  cancel() {
    this.dismiss(EAlertButtonCodes.cancel);
  }
}
