import { Vibration } from '@ionic-native/vibration/ngx';
import { Injectable } from '@angular/core';
import { TextToSpeechService } from './tts';
import { SoundEffectsService } from './sound-effects';
import { AppConstants } from 'src/app/classes/app/constants';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { SettingsManagerService } from '../settings-manager';
import { SoundUtils } from './sound-utils';

@Injectable({
    providedIn: 'root'
})
export class SoundManagerService {

    enabled: boolean = AppConstants.enableSoundApi;

    constructor(
        private tts: TextToSpeechService,
        private soundEffects: SoundEffectsService,
        private vibration: Vibration

    ) {
        console.log("sound mamager service created");
    }

    /**
     * vibrate
     * check conditions
     * @alwaysIfEnabled vibrate even in foreground (if settings flag enabled)
     */
    vibrateContext(alwaysIfEnabled: boolean) {
        let vibrate: boolean = false;
        if (GeneralCache.paused) {
            vibrate = true;
        }

        if (alwaysIfEnabled && SettingsManagerService.settings.app.settings.vibrateAlways.value) {
            vibrate = true;
        }

        if (vibrate) {
            this.vibration.vibrate(500);
        }
    }

    ttsWrapper(text: string, withSoundEffect: boolean, customSound?: string, onComplete?: () => any) {
        let promisePlaySound: Promise<boolean> = new Promise((resolve) => {
            if (withSoundEffect && this.enabled) {
                let soundId: string = customSound != null ? customSound : SoundUtils.soundBank.beginTransmission.id;
                console.log("soundId: " + soundId);
                this.soundEffects.playQueueResolve(soundId).then(() => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });

        promisePlaySound.then(() => {
            console.log("tts request: " + text);
            this.tts.textToSpeechQueue(text, false, null, onComplete, null, false);
        });
    }

    ttsCodeWrapper(loaderCode: number, withSoundEffect: boolean, customSound?: string, onComplete?: () => any) {
        let promisePlaySound: Promise<boolean> = new Promise((resolve) => {
            if (withSoundEffect && this.enabled) {
                let soundId: string = customSound != null ? customSound : SoundUtils.soundBank.beginTransmission.id;
                console.log("soundId: " + soundId);
                this.soundEffects.playQueueResolve(soundId).then(() => {
                    resolve(true);
                });
            } else {
                resolve(true);
            }
        });

        promisePlaySound.then(() => {
            this.tts.speakUsingLoaderCode(loaderCode, null, true, true, null, onComplete, null);
        });
    }

}