
import { ILatLng } from 'src/app/classes/def/map/coords';
import { IPlatformFlags } from "../../classes/def/app/platform";
import { MarkerHandlerService } from "./markers";
import { IWaypoint, IRecordWaypoint, IPathContent, IPlaceMarkerContent } from "../../classes/def/map/map-data";
import { Injectable } from "@angular/core";
import { SettingsManagerService } from "../general/settings-manager";
import { LocationMonitorService } from "./location-monitor";
import { ResourceManager } from "../../classes/general/resource-manager";
import { EMarkerLayers } from "../../classes/def/map/marker-layers";
import { IGameConfig } from "../../classes/app/config";
import { AppConstants } from "../../classes/app/constants";
import { IIndexedSegment, GeometryUtils, IPointOnSegment } from '../utils/geometry-utils';
import { MapGeometry } from 'src/app/classes/utils/map-geometry';
import { VirtualPositionService, IVirtualLocation } from '../app/modules/virtual-position';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { IActivityProviderContainer, EActivityProgressDisplayCategory, EActivityProgressDisplay, IActivityProgressDisplayGroup } from "src/app/classes/def/core/activity-manager";
import { MathUtils } from "src/app/classes/general/math";
import { WaitUtils } from "../utils/wait-utils";
import { UiExtensionService } from "../general/ui/ui-extension";
import { LocalNotificationsService } from "../general/apis/local-notifications";
import { SoundManagerService } from "../general/apis/sound-manager";
import { Messages } from "src/app/classes/def/app/messages";
import { SoundUtils } from "../general/apis/sound-utils";
import { MarkerUtils } from "./marker-utils";


export interface INavContainer {
  wpB: ILatLng;
  wpCoords: ILatLng[];
  segments: IIndexedSegment[];
  segmentIndex: number;
  destination: ILatLng;
  currentLocation: ILatLng;
  ppFinal: ILatLng;
  // find activity masked location
  realDestinationOverride: ILatLng;
  revealDistance: number;
  placeRevealed: boolean;
  // search radius reached
  inSearchRadius: boolean;
  // search radius reached
  startInSearchRadius: boolean;
  // find activity offset center location
  offsetCenter: ILatLng;
  // distance enter find location
  startTimerDistance: number;
  // entered find location, timer started
  timerStarted: boolean;
  // navigation state
  navState: number;
  // reached distance threshold
  reachedDistance: number;
  // use offset location as reference for calculating distance
  useOffsetRef: boolean;
  // requires user action to complete navigation
  requiresAckComplete: boolean;
  // user action issued to complete navigation
  ackComplete: boolean;
  // is find activity
  isFind: boolean;
  // include directions recalculate check
  directionsCheckRecalculate: boolean;
  lastMilestoneUpdateTimestamp: number;
}

export interface INavCompute {
  segment2: IIndexedSegment;
}

export enum ENavState {
  running = 0,
  inSearchRadius = 1,
  destinationRevealed = 2,
  destinationFound = 3,

  recalculateRoute = 4,
  navigationError = 5
}

export enum ENavUpdateResult {
  navigationInProgress = 0,
  destinationFound = 1,
  recalculateRoute = 2,
  destinationRevealed = 3,
  searchRadiusReached = 4,
  destinationFoundAckRequired = 5,
  recalculateRouteAckRequired = 6,
  navigationError = -1
}

export interface INavUpdateResult {
  distanceToRealDestination: number;
  headingToRealDestination?: number;
  result: number,
  resFlags: {
    inSearchRadius: boolean,
    destinationRevealed: boolean,
    destinationFound: boolean,
    recalculateRoute: boolean,
    navigationError: boolean
  }
}

@Injectable({
  providedIn: 'root'
})
export class NavigationHandlerService {
  platform: IPlatformFlags = {} as IPlatformFlags;

  test: boolean = false;
  waypointCoords: ILatLng[] = [];
  waypointCoordsView: ILatLng[] = [];
  waypointCoordsRecording: ILatLng[] = [];
  waypointRecordings: IRecordWaypoint[] = [];
  waypoints: IWaypoint[] = [];
  promiseUpdatePath = null;

  isRecording: boolean = false;

  obs = {
    navUpdate: null,
    navStatusUpdate: null
  };

  sub = {
    geolocation: null,
    navStatusUpdate: null
  };

  samplingTime: number = 5000;

  recData = {
    lastSample: 0
  };

  nc: INavContainer = {
    destination: null,
    wpB: null,
    wpCoords: null,
    segments: null,
    segmentIndex: 0,
    currentLocation: null,
    ppFinal: null,
    realDestinationOverride: null,
    revealDistance: null,
    inSearchRadius: false,
    startInSearchRadius: false,
    placeRevealed: false,
    offsetCenter: null,
    startTimerDistance: null,
    timerStarted: false,
    navState: ENavState.running,
    reachedDistance: AppConstants.gameConfig.reachedDistance,
    useOffsetRef: false,
    requiresAckComplete: false,
    ackComplete: false,
    isFind: false,
    directionsCheckRecalculate: true,
    lastMilestoneUpdateTimestamp: null
  };

  nresInit: INavUpdateResult = {
    distanceToRealDestination: null,
    result: ENavUpdateResult.navigationInProgress,
    resFlags: {
      inSearchRadius: false,
      destinationRevealed: false,
      destinationFound: false,
      recalculateRoute: false,
      navigationError: false
    }
  };

  nres: INavUpdateResult = {
    distanceToRealDestination: null,
    result: ENavUpdateResult.navigationInProgress,
    resFlags: {
      inSearchRadius: false,
      destinationRevealed: false,
      destinationFound: false,
      recalculateRoute: false,
      navigationError: false
    }
  };

  initialized: boolean = false;
  computeDistanceViaPath: boolean = false;
  lastStatus: number = null;

  constructor(
    public markerHandler: MarkerHandlerService,
    public settings: SettingsManagerService,
    public locationMonitor: LocationMonitorService,
    public virtualPositionService: VirtualPositionService,
    public uiext: UiExtensionService,
    public localNotifications: LocalNotificationsService,
    public soundManager: SoundManagerService
  ) {
    console.log("navigation service created");
    this.obs = ResourceManager.initBsubObj(this.obs);
    this.nres = DeepCopy.deepcopy(this.nresInit);

    this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
      if (loaded) {
        this.onPlatformLoaded();
      }
    }, (err: Error) => {
      console.error(err);
    });

  }

  onPlatformLoaded() {
    this.platform = SettingsManagerService.settings.platformFlags;
  }

  /**
   * init nav container
   * @param wpts 
   * @param loc 
   */
  init(
    destination: ILatLng,
    wpts: IWaypoint[],
    loc: ILatLng,
    realDestinationOverride: ILatLng,
    revealDistance: number,
    offsetCenter: ILatLng,
    startTimerDistance: number,
    alreadyReachedSearchArea: boolean,
    customReachedDistance: number,
    useOffsetRef: boolean,
    requiresAckComplete: boolean,
    isFind: boolean,
    directionsCheckRecalculate: boolean
  ) {
    let wpCoords: ILatLng[] = MapGeometry.getCoordsFromWaypointArray(wpts);
    let wpB: ILatLng = null;
    if (wpCoords.length > 0) {
      wpB = wpCoords[wpCoords.length - 1];
    }
    this.nc = {
      destination: destination,
      wpB: wpB,
      wpCoords: wpCoords,
      segments: GeometryUtils.getLineSegmentsWithIndex(wpCoords),
      segmentIndex: 0,
      currentLocation: loc,
      ppFinal: loc,
      realDestinationOverride: realDestinationOverride,
      revealDistance: revealDistance,
      inSearchRadius: false,
      startInSearchRadius: alreadyReachedSearchArea,
      placeRevealed: false,
      offsetCenter: offsetCenter,
      startTimerDistance: startTimerDistance,
      timerStarted: false,
      navState: 0,
      reachedDistance: (customReachedDistance != null) ? customReachedDistance : AppConstants.gameConfig.reachedDistance,
      useOffsetRef: useOffsetRef,
      requiresAckComplete: requiresAckComplete,
      ackComplete: false,
      isFind: isFind,
      directionsCheckRecalculate: directionsCheckRecalculate,
      lastMilestoneUpdateTimestamp: null
    };

    console.log("navigation init container: ", this.nc);

    this.setReachedDistance(this.nc.reachedDistance);
    this.nres = DeepCopy.deepcopy(this.nresInit);
    this.initialized = true;
    this.obs.navUpdate.next(null);
    this.obs.navStatusUpdate.next(null);
    this.checkNavigationStatusUpdatesNotify();
  }

  async deinit() {
    this.initialized = false;
    this.sub = ResourceManager.clearSubObj(this.sub);
    await this.markerHandler.waitForUpdatePath();
  }

  /**
   * set reached distance
   * adjust reveal distance
   * @param reachedDistance 
   */
  setReachedDistance(reachedDistance: number) {
    this.nc.reachedDistance = reachedDistance;
    let minRevealDist: number = this.nc.reachedDistance + 10;
    if (this.nc.revealDistance < minRevealDist) {
      this.nc.revealDistance = minRevealDist;
    }
  }

  getReachedDistance() {
    return this.nc.reachedDistance;
  }

  getNavUpdateObservable() {
    return this.obs.navUpdate;
  }

  getDistanceToDestinationViaPath(location: ILatLng) {
    if (!this.initialized) {
      return null;
    }
    let nc: INavContainer = DeepCopy.deepcopy(this.nc);
    let resCompute: INavCompute = this.computeNav(location, nc, nc.wpCoords);
    let dist: number = 0;
    if (resCompute.segment2 !== null) {
      let path: ILatLng[] = this.computePath(location, nc.wpCoords, nc.segments[nc.segmentIndex].indexA);
      for (let i = 1; i < path.length; i++) {
        dist += GeometryUtils.getDistanceBetweenEarthCoordinates(path[i], path[i - 1], 0);
      }
      // console.log("compute nav path dist: ", dist);
    }
    return dist;
  }

  getPathProjection(location: ILatLng) {
    if (!this.initialized) {
      return location;
    }
    let nc: INavContainer = DeepCopy.deepcopy(this.nc);
    this.computeNav(location, nc, nc.wpCoords);
    let pp: ILatLng = (nc.ppFinal != null) ? nc.ppFinal : location;
    return pp;
  }

  /**
   * confirm navigation complete
   * set ack flag
   * wait for nav update
   * timeout after 5 sec
   * @returns 
   */
  confirmNavigationComplete() {
    return new Promise(async (resolve) => {
      try {
        this.nc.ackComplete = true;
        await this.uiext.showLoadingV2Queue("Waiting for location..");
        await WaitUtils.waitObsTimeout(this.obs.navStatusUpdate, ENavUpdateResult.destinationFound, 3000);
        this.virtualPositionService.triggerVirtualPositionUpdateCrt();
        await this.uiext.dismissLoadingV2();
        resolve(true);
      } catch (err) {
        console.error(err);
        await this.uiext.dismissLoadingV2();
        resolve(false);
      }
    });
  }

  /**
   * on location update nav
   * set nav line
   * check distance
   * @param loc 
   * @param show 
   */
  updateNav(loc: ILatLng, show: boolean): INavUpdateResult {
    let distanceToProjection: number = 0;

    let resCompute: INavCompute = this.computeNav(loc, this.nc, this.nc.wpCoords);

    // let distanceToDestination = GeometryUtils.computeDistance(x, wp_b);
    let distanceToRealDestination: number = null;
    let headingToRealDestination: number = null;

    if (this.nc.realDestinationOverride != null) {
      // the case with find activity
      distanceToRealDestination = GeometryUtils.getDistanceBetweenEarthCoordinates(loc, this.nc.realDestinationOverride, Number.MAX_VALUE);
    } else {
      // default case
      distanceToRealDestination = GeometryUtils.getDistanceBetweenEarthCoordinates(loc, this.nc.destination, Number.MAX_VALUE);
    }

    headingToRealDestination = GeometryUtils.computeHeading(loc, this.nc.destination);
    this.nres.headingToRealDestination = headingToRealDestination;

    distanceToProjection = GeometryUtils.getDistanceBetweenEarthCoordinates(loc, this.nc.ppFinal, 0);

    if (resCompute.segment2 !== null) {
      this.updatePathNav(this.nc.ppFinal, this.nc.segments[this.nc.segmentIndex].indexA, EMarkerLayers.MAIN_PATH, show).then(() => {

      }).catch((err: Error) => {
        console.error(err);
        // reject(err);
        // return;
      });

      // if (this.flags.showTestMarkers) {
      //   this.markerHandler.setThisMarkerFromArray(EMarkerLayers.TESTS, 0, 0, EMarkerIcons.testA, pp_final, null, false, EMarkerPriority.waypoint);
      //   this.markerHandler.setThisMarkerFromArray(EMarkerLayers.TESTS, 1, 0, 
      //  EMarkerIcons.testB, wpts_coords[segments[segmentIndex].indexA], null, false, EMarkerPriority.waypoint);
      //   this.markerHandler.setThisMarkerFromArray(EMarkerLayers.TESTS, 2, 0, 
      // EMarkerIcons.testC, wpts_coords[segments[segmentIndex].indexB], null, false, EMarkerPriority.waypoint);
      // }
    }

    if (isNaN(distanceToRealDestination)) {
      this.nres.result = ENavUpdateResult.navigationError;
      this.obs.navUpdate.next(this.nres);
      this.obs.navStatusUpdate.next(this.nres.result);
      return this.nres;
    }

    this.nres.distanceToRealDestination = distanceToRealDestination;
    this.nres.result = ENavUpdateResult.navigationInProgress;

    let distanceToOffsetCenter: number;

    if (this.nc.offsetCenter) {
      distanceToOffsetCenter = GeometryUtils.getDistanceBetweenEarthCoordinates(loc, this.nc.offsetCenter, Number.MAX_VALUE);
    } else {
      distanceToOffsetCenter = distanceToRealDestination;
    }

    let distanceCalc: number = this.nc.useOffsetRef ? distanceToOffsetCenter : distanceToRealDestination;

    // console.log("distance to offset: ", distanceToOffsetCenter);
    // console.log("distance to target: ", distanceToRealDestination);
    // console.log("distance to projection: ", distanceToProjection);

    // recalculate suggested
    if (this.nc.directionsCheckRecalculate && (AppConstants.gameConfig.maxDistanceFromPathNotify !== null) && (distanceToProjection > AppConstants.gameConfig.maxDistanceFromPathNotify)) {
      this.nres.result = ENavUpdateResult.recalculateRouteAckRequired;
    }

    // recalculate required
    if ((AppConstants.gameConfig.maxDistanceFromPath !== null) && (distanceToProjection > AppConstants.gameConfig.maxDistanceFromPath) && (this.nc.realDestinationOverride == null)) {
      this.nres.result = ENavUpdateResult.recalculateRoute;
    }

    // entered search radius and timer not yet started
    if (((distanceToOffsetCenter < this.nc.startTimerDistance) || this.nc.startInSearchRadius) && !this.nc.timerStarted) {
      this.nc.timerStarted = true;
      this.nc.inSearchRadius = true;
      this.nc.startInSearchRadius = false;
      this.nres.result = ENavUpdateResult.searchRadiusReached;
      this.obs.navUpdate.next(this.nres);
      this.obs.navStatusUpdate.next(this.nres.result);
      return this.nres;
    }

    // place revealed
    if ((distanceCalc < this.nc.revealDistance) && this.nc.inSearchRadius && !this.nc.placeRevealed) {
      this.nc.placeRevealed = true;
      this.nres.result = ENavUpdateResult.destinationRevealed;
    }

    // destination found/reached
    if (distanceCalc < this.nc.reachedDistance) {
      if (this.nc.requiresAckComplete) {
        if (this.nc.ackComplete) {
          this.nres.result = ENavUpdateResult.destinationFound;
        } else {
          this.nres.result = ENavUpdateResult.destinationFoundAckRequired;
        }
      } else {
        this.nres.result = ENavUpdateResult.destinationFound;
      }
    }

    this.obs.navUpdate.next(this.nres);
    this.obs.navStatusUpdate.next(this.nres.result);
    return this.nres;
  }

  checkNavigationStatusUpdatesNotify() {
    if (!this.sub.navStatusUpdate) {
      this.sub.navStatusUpdate = this.obs.navStatusUpdate.subscribe((status: number) => {
        if (status !== this.lastStatus) {
          // notify on status change
          switch (status) {
            case ENavUpdateResult.destinationRevealed:
              this.localNotifications.notify(Messages.notification.destinationRevealed.after.msg, Messages.notification.destinationRevealed.after.sub, false, null);
              this.soundManager.vibrateContext(true);
              this.soundManager.ttsWrapper(Messages.tts.checkpointRevealed, true);
              break;
            case ENavUpdateResult.searchRadiusReached:
              this.localNotifications.notify(Messages.notification.searchZoneReached.after.msg, Messages.notification.searchZoneReached.after.sub, false, null);
              this.soundManager.vibrateContext(true);
              this.soundManager.ttsWrapper(Messages.tts.searchZoneReached, true);
              break;
            case ENavUpdateResult.recalculateRouteAckRequired:
              this.localNotifications.notify(Messages.notification.checkNavDirections.after.msg, Messages.notification.checkNavDirections.after.sub, false, null);
              this.soundManager.vibrateContext(true);
              this.soundManager.ttsWrapper(Messages.tts.checkNavDirections, true);
              break;
            case ENavUpdateResult.destinationFound:
              if (!this.nc.requiresAckComplete) {
                this.localNotifications.notify(Messages.notification.destinationReached.after.msg, Messages.notification.destinationReached.after.sub, false, null);
                this.soundManager.vibrateContext(false);
              }
              if (this.nc.isFind) {
                this.soundManager.ttsWrapper(Messages.tts.challengeComplete, true, SoundUtils.soundBank.complete.id);
              } else {
                this.soundManager.ttsWrapper(Messages.tts.destinationReached, true, SoundUtils.soundBank.complete.id);
              }
              break;
            case ENavUpdateResult.destinationFoundAckRequired:
              if (this.nc.requiresAckComplete) {
                this.localNotifications.notify(Messages.notification.destinationReached.after.msg, Messages.notification.destinationReached.after.sub, false, null);
                this.soundManager.vibrateContext(true);
                this.soundManager.ttsWrapper(Messages.tts.destinationReached, true, SoundUtils.soundBank.complete.id);
              }
              break;
            default:
              break;
          }
        }
        this.lastStatus = status;
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  getCurrentNav(): INavUpdateResult {
    return this.nres;
  }

  computeNav(loc: ILatLng, nc: INavContainer, waypointCoords: ILatLng[]) {
    let x: ILatLng = loc;
    let ppMin: ILatLng;
    let ppMinDist: number = 0;
    let wpMin: ILatLng;
    let wpMinDist = 0;
    let segments: IIndexedSegment[] = GeometryUtils.getLineSegmentsWithIndex(waypointCoords);

    // get nearest waypoint
    wpMin = GeometryUtils.getNearestPoint(x, waypointCoords);
    wpMinDist = GeometryUtils.getDistanceBetweenEarthCoordinates(x, wpMin, 0);
    let ppMinData: IPointOnSegment = GeometryUtils.getNearestProjectionOnPathSegments(x, segments);
    ppMin = ppMinData.point;
    if (ppMin !== null) {
      ppMinDist = GeometryUtils.getDistanceBetweenEarthCoordinates(x, ppMin, 0);
    } else {
      ppMinDist = wpMinDist;
    }
    // get connected segments
    let segment1: IIndexedSegment = GeometryUtils.getSegmentEndingWithPoint(segments, wpMin);
    let segment2: IIndexedSegment = GeometryUtils.getSegmentStartingWithPoint(segments, wpMin);
    let index1: number = segment1 !== null ? segment1.index : 0;
    let index2: number = segment2 !== null ? segment2.index : 0;
    // get projections to the connected segments
    let pr1: ILatLng = null;
    let pr2: ILatLng = null;
    if (segment1) {
      pr1 = GeometryUtils.getProjectionOnSegment(x, segment1);
    }
    if (segment2) {
      pr2 = GeometryUtils.getProjectionOnSegment(x, segment2);
    }
    if (ppMinDist < wpMinDist) {
      // choose the segment with the nearest projection if the distance is less than the distance to
      // the nearest waypoint
      nc.ppFinal = ppMin;
      nc.segmentIndex = ppMinData.segmentIndex;
    } else {
      if (pr1 === null && pr2 === null) {
        // if projections are outside the segments
        // then use the waypoint as the projection to path
        // and the next segment index
        nc.ppFinal = wpMin;
        nc.segmentIndex = index2;
      } else {
        let distp1 = 0, distp2 = 0;
        nc.ppFinal = wpMin;
        nc.segmentIndex = index2;
        if (pr1 !== null) {
          distp1 = GeometryUtils.getDistanceBetweenEarthCoordinates(x, pr1, Number.MAX_VALUE);
          if (distp1 < wpMinDist) {
            // if the distance to projection on segment 1 is less than the distance to the waypoint
            // then use the projection on segment 1
            nc.ppFinal = pr1;
            nc.segmentIndex = index1;
          }
        }
        if (pr2 !== null) {
          distp2 = GeometryUtils.getDistanceBetweenEarthCoordinates(x, pr2, Number.MAX_VALUE);
          if (distp2 < wpMinDist) {
            // if the distance to projection on segment 2 is less than the distance to the waypoint
            // then use the projection on segment 2
            nc.ppFinal = pr2;
            nc.segmentIndex = index2;
          }
        }
      }
    }

    nc.segmentIndex -= 1;
    if (nc.segmentIndex > nc.segments.length - 1) {
      nc.segmentIndex = nc.segments.length - 1;
    }
    if (nc.segmentIndex < 0) {
      nc.segmentIndex = 0;
    }

    let resCompute: INavCompute = {
      segment2: segment2
    };

    return resCompute;
  }

  computePath(location: ILatLng, waypointCoords: ILatLng[], fromWpIndex: number) {
    let wplen: number = waypointCoords.length;
    let fromWpIndexBounded: number = fromWpIndex < wplen - 1 ? fromWpIndex + 1 : wplen - 2;
    if (fromWpIndexBounded < 0) {
      fromWpIndexBounded = 0;
    }
    let newPath: ILatLng[];
    newPath = waypointCoords.slice(fromWpIndexBounded);
    newPath.splice(0, 0, location);
    let distanceToFinalWaypoint: number = GeometryUtils.getDistanceBetweenEarthCoordinates(location, waypointCoords[waypointCoords.length - 1], 0);
    if (distanceToFinalWaypoint < this.nc.reachedDistance) {
      newPath = [];
    }
    return newPath;
  }

  /**
   * replace waypoint with user location and draw line from there to the end of the line
   * @param location current location
   * @param fromWpIndex previous waypoint 
   */
  updatePathNav(location: ILatLng, fromWpIndex: number, pathName: string, show: boolean) {
    // console.log("update path nav user location: ", location);
    let newPath: ILatLng[];
    // if path is being updated do not try to update again
    if (this.promiseUpdatePath !== null) {
      return this.promiseUpdatePath;
    }

    this.promiseUpdatePath = new Promise(async (resolve, reject) => {
      if (this.waypointCoords === null) {
        this.promiseUpdatePath = null;
        reject(new Error("path not initialized"));
      }
      newPath = this.computePath(location, this.waypointCoords, fromWpIndex);
      let pd: IPathContent = {
        arrows: false,
        distanceLabels: true,
        path: EMarkerLayers.MAIN_PATH,
        callback: null
      };

      try {
        console.log("update path");
        await this.markerHandler.updatePath(newPath, pathName, pd, show, true);
        // console.log("path updated");
        // if (this.waypointCoords && (this.waypointCoords.length > 0)) {
        //   let distanceToFinalWaypoint: number = GeometryUtils.getDistanceBetweenEarthCoordinates(location, this.waypointCoords[this.waypointCoords.length - 1], 0);
        //   await this.updateMilestoneLabel(distanceToFinalWaypoint);
        // }
        this.promiseUpdatePath = null;
        resolve(true);
      } catch (err) {
        this.promiseUpdatePath = null;
        reject(err);
      };
      // this.promiseUpdatePath = null;
      // resolve(true);
    });
    return this.promiseUpdatePath;
  }

  clearPath() {
    return new Promise(async (resolve) => {
      console.log("clear path");
      await this.markerHandler.disposeLayerResolve(EMarkerLayers.MAIN_PATH);
      resolve(true);
    });
  }

  /**
  * show distance markers
  * @param pd 
  * @param layer 
  * @param wpoints 
  * @returns 
  */
  showMilestones(pd: IPathContent, layer: string, wpoints: ILatLng[]) {
    return new Promise<boolean>(async (resolve) => {
      if (pd.distanceLabels) {
        let distanceLabels: string[] = [];
        let distanceLabelCoords: ILatLng[] = [];
        for (let i = 1; i < pd.waypoints.length; i++) {
          let distance: number = GeometryUtils.getDistanceBetweenEarthCoordinates(pd.waypoints[i - 1], pd.waypoints[i], 0);
          distanceLabels.push(MathUtils.formatDistanceDisp(distance).disp);
          distanceLabelCoords.push(GeometryUtils.getCenterPoint([pd.waypoints[i - 1], pd.waypoints[i]]));
        }
        let distanceLabelMarkers: IPlaceMarkerContent[] = [];
        this.init(distanceLabelCoords[distanceLabels.length - 1], wpoints.map(wp => {
          let wpt: IWaypoint = {
            coords: wp,
            visited: false
          };
          return wpt;
        }), distanceLabelCoords[0], null, null, null, null, false, null, false, false, false, false);
        for (let i = 0; i < distanceLabels.length; i++) {
          let coordsProjection: ILatLng = this.getPathProjection(distanceLabelCoords[i]);
          let dlm: IPlaceMarkerContent = MarkerUtils.getWaypointDistMarkerData(layer, i, coordsProjection, distanceLabels[i]);
          distanceLabelMarkers.push(dlm);
        }
        console.log("distance label markers: ", distanceLabelMarkers);
        try {
          await this.markerHandler.syncMarkerArray(layer, distanceLabelMarkers);
          resolve(true);
        } catch (err) {
          console.error(err);
          resolve(false);
        }
      } else {
        resolve(false);
      }
    });
  }

  async updateMilestoneLabel(distance: number) {
    return new Promise<boolean>(async (resolve) => {
      try {
        let updateTs: number = new Date().getTime();
        let proceed: boolean = false;
        if (this.nc.lastMilestoneUpdateTimestamp != null) {
          if ((updateTs - this.nc.lastMilestoneUpdateTimestamp) >= 1000) {
            proceed = true;
            this.nc.lastMilestoneUpdateTimestamp = updateTs;
          }
        } else {
          this.nc.lastMilestoneUpdateTimestamp = updateTs;
          proceed = true;
        }
        if (!proceed) {
          resolve(false);
          return;
        }
        let layer: string = EMarkerLayers.WAYPOINTS;
        let pm: IPlaceMarkerContent[] = this.markerHandler.getMarkerDataMultiLayer(layer);
        if (!(pm && pm.length > 0)) {
          resolve(false);
          return;
        }
        pm[0] = MarkerUtils.getWaypointDistMarkerData(layer, 0, pm[0].location, MathUtils.formatDistanceDisp(distance).disp);
        await this.markerHandler.syncMarkerArray(layer, pm);
        resolve(true);
      } catch (err) {
        console.error(err);
        resolve(false);
      }
    });
  }

  setWaypoints(waypoints: IWaypoint[]) {
    this.waypointCoords = [];
    this.waypointCoordsView = [];
    this.waypoints = waypoints;
    for (let wp of waypoints) {
      this.waypointCoords.push(wp.coords);
      this.waypointCoordsView.push(wp.coords);
    }
  }

  getWaypointCoords() {
    return this.waypointCoords;
  }

  getWaypoints() {
    return this.waypoints;
  }


  /**
   * watch location updates
   * record waypoints if enabled
   */
  private subscribeToLocationUpdates() {
    if (!this.sub.geolocation) {
      this.sub.geolocation = this.virtualPositionService.watchVirtualPosition().subscribe((data: IVirtualLocation) => {
        if (this.virtualPositionService.checkNavContext(data, true)) {
          let tsample: number = new Date().getTime();
          if ((tsample - this.recData.lastSample) >= this.samplingTime) {
            this.recData.lastSample = tsample;
            let coords: ILatLng = Object.assign({}, data.coords);

            this.waypointRecordings.push({
              lat: coords.lat,
              lng: coords.lng,
              timestamp: tsample
            });

            this.waypointCoordsRecording.push(coords);
            let pd: IPathContent = {
              arrows: false,
              distanceLabels: true,
              path: EMarkerLayers.MAIN_PATH,
              callback: null
            };
            this.markerHandler.updatePathNoAction(this.waypointCoordsRecording, EMarkerLayers.RECORD_PATH, pd, true, true);
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  getRecordWaypoints() {
    return this.waypointRecordings;
  }

  private unsubscribeFromLocation() {
    if (this.sub.geolocation) {
      this.sub.geolocation = ResourceManager.clearSub(this.sub.geolocation);
    }
  }


  /**
   * record the waypoints while moving
   * start recording
   * set sampling rate
   */
  startRecording() {
    let gc: IGameConfig = AppConstants.gameConfig;
    if (gc.recordSamplingTime) {
      this.samplingTime = gc.recordSamplingTime;
    }
    this.subscribeToLocationUpdates();
  }

  /**
   * stop recording waypoints while moving
   * clear waypoints
   * dispose waypoint layer
   */
  stopRecording() {
    this.unsubscribeFromLocation();
  }

  resetTour() {
    this.waypointCoordsRecording = [];
    this.markerHandler.disposeLayerResolve(EMarkerLayers.RECORD_PATH).then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  getProgressNav(container: IActivityProviderContainer, from: ILatLng, to: ILatLng, init: boolean) {
    let targetDistance: number = null;
    let isPath: boolean = false;
    if ((from != null) && (to != null)) {
      if (!init && this.computeDistanceViaPath) {
        targetDistance = this.getDistanceToDestinationViaPath(from);
      }
      if (targetDistance != null) {
        isPath = true;
      } else {
        targetDistance = GeometryUtils.getDistanceBetweenEarthCoordinates(from, to, 0);
      }
    }
    let showDistance: boolean = (targetDistance != null) && (targetDistance > 0);

    if (init) {
      container.validateDisp = {
        navDistance: {
          name: "Nav Distance",
          value: 100,
          raw: 0,
          showText: true,
          animate: true,
          show: showDistance,
          category: EActivityProgressDisplayCategory.default,
          mode: EActivityProgressDisplay.slider,
          dispValue: showDistance ? ((isPath ? "" : "~") + MathUtils.formatDistanceDisp(targetDistance).disp) : "N/A"
        }
      };
    } else {
      if (showDistance) {
        let res: INavUpdateResult = this.getCurrentNav();
        let progress: number = Math.floor(res.distanceToRealDestination / targetDistance * 100);
        container.validateDisp.navDistance.value = progress;
        container.validateDisp.navDistance.dispValue = (isPath ? "" : "~") + MathUtils.formatDistanceTargetDisp(res.distanceToRealDestination, targetDistance);
        container.validateDisp.navDistance.show = true;
      }
    }
    return container;
  }
}
