// RxJS v6+
import { timer, Subscription } from 'rxjs';
export class SleepUtils {
  static async sleep(ms: number) {
    return new Promise<boolean>(resolve => setTimeout(resolve, ms));
  }
  static async sleepRealtime(ms: number) {
    return new Promise<boolean>(resolve => {
      let source = timer(ms);
      let sub: Subscription;
      let oncomplete = (state: boolean) => {
        sub.unsubscribe();
        resolve(state);
      }
      sub = source.subscribe(() => {
        oncomplete(true);
      }, (err) => {
        console.error(err);
        oncomplete(false);
      });
    });
  }
}