import { ILeplaceReg } from "../../classes/def/places/google";
import { ILatLng } from 'src/app/classes/def/map/coords';
import { IPlaceMarkerContent } from "../../classes/def/map/map-data";
import { IAppLocation } from "../../classes/def/places/app-location";
import { ILeplaceTreasure, TreasureUtils } from "../../classes/def/places/leplace";
import { DeepCopy } from "../../classes/general/deep-copy";
import { EMarkerLayers } from "../../classes/def/map/marker-layers";
import { ILeplaceObjectContainer } from "../../classes/def/core/objects";
import { IGroupMember } from "../../classes/def/mp/groups";
import { EColorsHEX, EColorsRGBA } from '../../classes/def/app/theme';
import { EMarkerIcons } from '../../classes/def/app/icons';
import { EMarkerPriority, EMarkerTypes, EMapShapes, EMarkerScope, EMarkerRenderPriority } from '../../classes/def/map/markers';
import { ETreasureType } from '../../classes/def/items/treasures';
import { GeometryUtils } from '../utils/geometry-utils';
import { IPlaceExtContainer } from "../../classes/def/places/container";
import { EMessageTrim } from "../../classes/utils/message-utils";
import { SettingsManagerService } from '../general/settings-manager';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { EHiddenMarkerModes } from "src/app/classes/def/core/story";
import { IBackendLocation } from "src/app/classes/def/places/backend-location";
import { LocationDispUtils } from "../location/location-disp-utils";
import { StringUtils } from "../app/utils/string-utils";
import { EStoryLocationDoneFlag } from "src/app/classes/def/nav-params/story";
import { GeneralCache } from "src/app/classes/app/general-cache";


export interface ICanvasMarkerContainer {
    paddingH: number;
    paddingW: number;
    contentW: number;
    contentH: number;
    fullW: number;
    fullH: number;
    pointerH: number;
    pointerW: number;
    lineWidth: number;
    scale: number;
}

export interface IRoundRectangleRadiusSpec {
    tl: number,
    tr: number,
    br: number,
    bl: number
}

export enum EMarkerClass {
    single = 0,
    multi = 1
}

export enum EMarkerShowType {
    plain = 0,
    circle = 1,
    canvas = 2
}

export enum EMarkerUpdateCode {
    ok = 0,
    shouldWait = 1,
    shouldCreate = 2
}

export interface IMarkerUpdateResult {
    code: number;
    message: string;
}

export enum EMarkerFrameColorScheme {
    default = "#1e72a3",
    registered = "#fcd182"
}

export enum EMarkerTextColorScheme {
    default = "#fcd182",
    registered = "#1e72a3"
}

interface IMarkerSettings {
    outerPaddingW: number,
    outerPaddingH: number,
    innerPadding: number,
    lineW: number,

    labelRadius: number,
    labelWPadding: number,
    labelHPadding: number,
    lockedFilter: string,
    maxScale: number,
    maxWidth: number,
    fontSize: number,
    allCaps: boolean
}

export class MarkerUtils {

    /**
     * show frame around the marker to debug the positioning
     */
    static debug: boolean = false;

    static settings: IMarkerSettings = {

        outerPaddingW: 140, // previously 80
        // outerPaddingH = 32,
        // outerPaddingH: 32,
        outerPaddingH: 48,
        innerPadding: 10,
        lineW: 6,

        labelRadius: 10,
        labelWPadding: 6,
        labelHPadding: 7,
        lockedFilter: "sepia(1)",
        // lockedFilter: "grayscale(100%)",
        maxWidth: 320,
        fontSize: 11,
        maxScale: 2,
        allCaps: false
    };

    /**
     * platform spec update
     * @param platform 
     */
    static setPlatform(platform: IPlatformFlags) {
        if (platform.IOS) {
            MarkerUtils.settings.fontSize = 11;
            // MarkerUtils.settings.allCaps = true;
            // MarkerUtils.settings.labelHPadding = 6;
        }
    }

    static addFilter(ctx: CanvasRenderingContext2D) {

        let width = ctx.canvas.width;
        let height = ctx.canvas.height;

        // set of sepia colors
        let r = [0, 0, 0, 1, 1, 2, 3, 3, 3, 4, 4, 4, 5, 5, 5, 6, 6, 7, 7, 7, 7, 8, 8, 8, 9, 9, 9, 9, 10, 10, 10, 10, 11, 11, 12, 12, 12, 12, 13, 13, 13, 14, 14, 15, 15, 16, 16, 17, 17, 17, 18, 19, 19, 20, 21, 22, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 39, 40, 41, 42, 44, 45, 47, 48, 49, 52, 54, 55, 57, 59, 60, 62, 65, 67, 69, 70, 72, 74, 77, 79, 81, 83, 86, 88, 90, 92, 94, 97, 99, 101, 103, 107, 109, 111, 112, 116, 118, 120, 124, 126, 127, 129, 133, 135, 136, 140, 142, 143, 145, 149, 150, 152, 155, 157, 159, 162, 163, 165, 167, 170, 171, 173, 176, 177, 178, 180, 183, 184, 185, 188, 189, 190, 192, 194, 195, 196, 198, 200, 201, 202, 203, 204, 206, 207, 208, 209, 211, 212, 213, 214, 215, 216, 218, 219, 219, 220, 221, 222, 223, 224, 225, 226, 227, 227, 228, 229, 229, 230, 231, 232, 232, 233, 234, 234, 235, 236, 236, 237, 238, 238, 239, 239, 240, 241, 241, 242, 242, 243, 244, 244, 245, 245, 245, 246, 247, 247, 248, 248, 249, 249, 249, 250, 251, 251, 252, 252, 252, 253, 254, 254, 254, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255, 255],
            g = [0, 0, 1, 2, 2, 3, 5, 5, 6, 7, 8, 8, 10, 11, 11, 12, 13, 15, 15, 16, 17, 18, 18, 19, 21, 22, 22, 23, 24, 26, 26, 27, 28, 29, 31, 31, 32, 33, 34, 35, 35, 37, 38, 39, 40, 41, 43, 44, 44, 45, 46, 47, 48, 50, 51, 52, 53, 54, 56, 57, 58, 59, 60, 61, 63, 64, 65, 66, 67, 68, 69, 71, 72, 73, 74, 75, 76, 77, 79, 80, 81, 83, 84, 85, 86, 88, 89, 90, 92, 93, 94, 95, 96, 97, 100, 101, 102, 103, 105, 106, 107, 108, 109, 111, 113, 114, 115, 117, 118, 119, 120, 122, 123, 124, 126, 127, 128, 129, 131, 132, 133, 135, 136, 137, 138, 140, 141, 142, 144, 145, 146, 148, 149, 150, 151, 153, 154, 155, 157, 158, 159, 160, 162, 163, 164, 166, 167, 168, 169, 171, 172, 173, 174, 175, 176, 177, 178, 179, 181, 182, 183, 184, 186, 186, 187, 188, 189, 190, 192, 193, 194, 195, 195, 196, 197, 199, 200, 201, 202, 202, 203, 204, 205, 206, 207, 208, 208, 209, 210, 211, 212, 213, 214, 214, 215, 216, 217, 218, 219, 219, 220, 221, 222, 223, 223, 224, 225, 226, 226, 227, 228, 228, 229, 230, 231, 232, 232, 232, 233, 234, 235, 235, 236, 236, 237, 238, 238, 239, 239, 240, 240, 241, 242, 242, 242, 243, 244, 245, 245, 246, 246, 247, 247, 248, 249, 249, 249, 250, 251, 251, 252, 252, 252, 253, 254, 255],
            b = [53, 53, 53, 54, 54, 54, 55, 55, 55, 56, 57, 57, 57, 58, 58, 58, 59, 59, 59, 60, 61, 61, 61, 62, 62, 63, 63, 63, 64, 65, 65, 65, 66, 66, 67, 67, 67, 68, 69, 69, 69, 70, 70, 71, 71, 72, 73, 73, 73, 74, 74, 75, 75, 76, 77, 77, 78, 78, 79, 79, 80, 81, 81, 82, 82, 83, 83, 84, 85, 85, 86, 86, 87, 87, 88, 89, 89, 90, 90, 91, 91, 93, 93, 94, 94, 95, 95, 96, 97, 98, 98, 99, 99, 100, 101, 102, 102, 103, 104, 105, 105, 106, 106, 107, 108, 109, 109, 110, 111, 111, 112, 113, 114, 114, 115, 116, 117, 117, 118, 119, 119, 121, 121, 122, 122, 123, 124, 125, 126, 126, 127, 128, 129, 129, 130, 131, 132, 132, 133, 134, 134, 135, 136, 137, 137, 138, 139, 140, 140, 141, 142, 142, 143, 144, 145, 145, 146, 146, 148, 148, 149, 149, 150, 151, 152, 152, 153, 153, 154, 155, 156, 156, 157, 157, 158, 159, 160, 160, 161, 161, 162, 162, 163, 164, 164, 165, 165, 166, 166, 167, 168, 168, 169, 169, 170, 170, 171, 172, 172, 173, 173, 174, 174, 175, 176, 176, 177, 177, 177, 178, 178, 179, 180, 180, 181, 181, 181, 182, 182, 183, 184, 184, 184, 185, 185, 186, 186, 186, 187, 188, 188, 188, 189, 189, 189, 190, 190, 191, 191, 192, 192, 193, 193, 193, 194, 194, 194, 195, 196, 196, 196, 197, 197, 197, 198, 199];

        // noise value
        let noise = 20;

        let imageData = ctx.getImageData(0, 0, width, height);


        // make the image grayscale
        for (let y = 0; y < imageData.height; y++) {
            for (let x = 0; x < imageData.width; x++) {
                let i = (y * 4) * imageData.width + x * 4;
                let avg = (imageData.data[i] + imageData.data[i + 1] + imageData.data[i + 2]) / 3;
                imageData.data[i] = avg;
                imageData.data[i + 1] = avg;
                imageData.data[i + 2] = avg;
            }
        }

        // apply filter mask

        for (let i = 0; i < imageData.data.length; i += 4) {

            // change image colors
            imageData.data[i] = r[imageData.data[i]];
            imageData.data[i + 1] = g[imageData.data[i + 1]];
            imageData.data[i + 2] = b[imageData.data[i + 2]];

            // apply noise
            if (noise > 0) {
                let noise1 = Math.round(noise - Math.random() * noise);

                for (let j = 0; j < 3; j++) {
                    let iPN = noise1 + imageData.data[i + j];
                    imageData.data[i + j] = (iPN > 255) ? 255 : iPN;
                }
            }
        }

        // put image data back to context
        ctx.putImageData(imageData, 0, 0);
    }


    /**
     * add border color to marker based on the location type
     */
    static getPlaceMarkerColor(element: IPlaceExtContainer, data: IAppLocation = null) {
        let color = GeneralCache.isWeb ? EColorsRGBA.blue : EColorsHEX.blue;
        // let colorKeys = Object.keys(EPlaceColors);
        // let appLocation: IAppLocation = DeepCopy.deepcopy(data);
        // if (appLocation) {
        //     // use type defined in story template
        //     colorKeys.forEach((key) => {
        //         if (appLocation.loc.merged.type === key) {
        //             color = EPlaceColors[key];
        //         }
        //     });
        // } else {
        //     // use type from google found place
        //     colorKeys.forEach((key) => {
        //         if (element.type === key) {
        //             color = EPlaceColors[key];
        //         }
        //     });
        // }
        // placeMarkerContent.color = color;
        return color;
    }

    /**
     * apply special marker display modes
     * @param aloc 
     * @param mk 
     * @param checked 
     * @returns 
     */
    static setMarkerSpecialModeChecked(aloc: IAppLocation, mk: IPlaceMarkerContent, checked: boolean) {
        console.log("set marker special mode active: ", checked);
        let hiddenMarkerMode: number = aloc.loc.merged.hiddenMarkerMode;
        if (hiddenMarkerMode == null || hiddenMarkerMode == EHiddenMarkerModes.show) {
            return;
        }
        // context styles
        if (!checked) {
            switch (hiddenMarkerMode) {
                case EHiddenMarkerModes.index:
                    mk.mode = EMarkerTypes.canvasFrame;
                    mk.shape = EMapShapes.marker;
                    mk.icon = EMarkerIcons.location;
                    mk.disableGooglePhotoLoading = true;
                    break;
                case EHiddenMarkerModes.circle:
                    mk.mode = EMarkerTypes.canvasPlain;
                    mk.shape = EMapShapes.marker;
                    mk.icon = EMarkerIcons.findCircle;
                    mk.disableGooglePhotoLoading = true;
                    break;
                default:
                    break;
            }
        } else {
            mk.mode = EMarkerTypes.canvasFrame;
            mk.shape = EMapShapes.marker;
            mk.icon = mk.tempIcon;
            mk.disableGooglePhotoLoading = false;
        }

        // console.log("snapshot: ", DeepCopy.deepcopy(mk));

        // always on styles
        if (hiddenMarkerMode === EHiddenMarkerModes.noFrame) {
            mk.mode = EMarkerTypes.canvasPlain;
        }
    }

    static setMarkerDone(mk: IPlaceMarkerContent, done: boolean) {
        if (done) {
            mk.locked = true;
            mk.visible = true;
        }
        if (mk.data && mk.data.loc && mk.data.loc.merged) {
            let bloc: IBackendLocation = mk.data.loc.merged;
            bloc.done = done ? EStoryLocationDoneFlag.done : EStoryLocationDoneFlag.pending;
        }
    }

    static setMarkerLockedMode(mk: IPlaceMarkerContent, locked: boolean) {
        mk.locked = locked;
        mk.minimal = locked;
        if (locked) {
            console.log("switch to locked radius: ", mk.lockedRadius);
            if (mk.lockedRadius != null) {
                mk.radius = mk.lockedRadius;
                //mk.label
            }
        } else {
            console.log("switch to default radius: ", mk.defaultRadius);
            if (mk.defaultRadius != null) {
                mk.radius = mk.defaultRadius;
            }
        }
    }

    static syncMarkerData(mk: IPlaceMarkerContent, sloc: IAppLocation) {
        if (mk.data && mk.data.loc && mk.data.loc.merged) {
            let bloc: IBackendLocation = mk.data.loc.merged;
            let blocCrt: IBackendLocation = sloc.loc.merged;
            bloc.uploadedPhotoUrl = blocCrt.uploadedPhotoUrl;
            bloc.uploadedPhotoFinishUrl = blocCrt.uploadedPhotoFinishUrl;
            bloc.validated = blocCrt.validated;
            bloc.photoValidated = blocCrt.photoValidated;
        }
    }

    /**
     * add external properties to marker
     */
    static getPlaceMarkerDataFromPlaceResult(data: IAppLocation, result: ILeplaceReg, checked: boolean): IPlaceMarkerContent {
        let element: IPlaceExtContainer = result.place;
        // let icon = LocationUtils.getLocationPhoto(element);
        let aloc: IAppLocation = DeepCopy.deepcopy(data);
        // let aloc: IAppLocation = data;
        let bloc: IBackendLocation = aloc.loc.merged;
        let placeMarkerContent: IPlaceMarkerContent = this.getBaseMarkerDataFromPlaceResult(result);
        placeMarkerContent.ext = true;
        placeMarkerContent.tempIcon = aloc.loc.dispPhoto.photoUrl;
        placeMarkerContent.icon = aloc.loc.dispPhoto.photoUrl;
        placeMarkerContent.data = aloc;
        placeMarkerContent.layer = EMarkerLayers.PLACES;
        placeMarkerContent.color = MarkerUtils.getPlaceMarkerColor(element, aloc);
        placeMarkerContent.zindex = EMarkerPriority.place;
        placeMarkerContent.priority = EMarkerRenderPriority.place;
        placeMarkerContent.callback = null;
        LocationDispUtils.updateLocationMarkerHeading(placeMarkerContent, bloc, LocationDispUtils.checkHiddenCheckpointName(data.loc, false), data);
        // show place name label
        placeMarkerContent.label = bloc.name;
        placeMarkerContent.uid = "PLACE_MARKER/" + bloc.googleId + "/" + aloc.storyLocationId;
        placeMarkerContent.hiddenMarkerMode = bloc.hiddenMarkerMode;
        MarkerUtils.setMarkerSpecialModeChecked(aloc, placeMarkerContent, checked);
        // MarkerUtils.setMarkerDisplayOptions(placeMarkerContent, EMarkerScope.place);
        return placeMarkerContent;
    }

    static getBaseMarkerDataFromCoords(lat: number, lng: number): IPlaceMarkerContent {
        // let icon = LocationUtils.getLocationPhoto(element);
        let placeMarkerContent: IPlaceMarkerContent = {
            location: new ILatLng(lat, lng),
            initLocation: new ILatLng(lat, lng),
            currentLocationCopy: new ILatLng(lat, lng),
            title: "Marker",
            tempTitle: "Marker",
            tempIcon: null,
            icon: null,
            data: null,
            shape: EMapShapes.marker,
            mode: EMarkerTypes.canvasFrame,
            visible: true,
            layer: null,
            radius: null,
            zindex: null,
            priority: null,
            color: null,
            callback: null,
            dragCallback: null,
            locked: false,
            label: null,
            uid: null,
            drag: false
        };
        return placeMarkerContent;
    }

    static getBaseMarkerDataFromPlaceResult(result: ILeplaceReg): IPlaceMarkerContent {
        let elem: IPlaceExtContainer = result.place;
        // let icon = LocationUtils.getLocationPhoto(element);
        let placeMarkerContent: IPlaceMarkerContent = {
            location: new ILatLng(elem.lat, elem.lng),
            initLocation: new ILatLng(elem.lat, elem.lng),
            currentLocationCopy: new ILatLng(elem.lat, elem.lng),
            title: elem.name,
            tempTitle: elem.name,
            tempIcon: null,
            icon: null,
            data: null,
            shape: EMapShapes.marker,
            mode: EMarkerTypes.canvasFrame,
            visible: true,
            layer: null,
            radius: null,
            zindex: null,
            priority: null,
            color: null,
            callback: null,
            dragCallback: null,
            locked: false,
            label: null,
            uid: null,
            drag: false
        };
        return placeMarkerContent;
    }

    static setColorDefault(pm: IPlaceMarkerContent) {
        pm.labelFrameColor = EMarkerFrameColorScheme.default;
        pm.labelTextColor = EMarkerTextColorScheme.default;
    }

    static setColorRegistered(pm: IPlaceMarkerContent) {
        pm.labelFrameColor = EMarkerFrameColorScheme.registered;
        pm.labelTextColor = EMarkerTextColorScheme.registered;
    }


    static getBaseMarkerDataFromTreasure(treasure: ILeplaceTreasure, markerType: number): IPlaceMarkerContent {
        let place: IPlaceExtContainer = (treasure.place != null) ? treasure.place.place : null;
        let placeName: string = (place != null) ? place.name : "";
        let mode: number = EMarkerTypes.canvasFrame;
        if (markerType != null) {
            mode = treasure.spec ? treasure.spec.markerType : EMarkerTypes.canvasFrame;
        }

        let label: string = null;
        let locked: boolean = false;
        let priority: number = EMarkerRenderPriority.treasure;

        switch (treasure.type) {
            case ETreasureType.treasure:
                priority = EMarkerRenderPriority.treasure;
                if (treasure.lockedForUser) {
                    locked = true;
                }
                break;
            case ETreasureType.story:
                priority = EMarkerRenderPriority.story;
                if (treasure.story) {
                    if (treasure.story.name) {
                        label = treasure.story.name;
                    }
                    if (treasure.story.shortName) {
                        label = treasure.story.shortName;
                    }
                    if (treasure.story.locked) {
                        locked = true;
                    }
                    label += " (S)";
                }
                break;
            case ETreasureType.arena:
                priority = EMarkerRenderPriority.arena;
                if (treasure.spec) {
                    label = treasure.spec.dispName;
                }
                break;
            case ETreasureType.challenge:
                priority = EMarkerRenderPriority.challenge;
                if (treasure.spec) {
                    label = TreasureUtils.getChallengeName(treasure, true);
                }
                break;
            case ETreasureType.storyLocation:
                priority = EMarkerRenderPriority.place;
                if (treasure.spec) {
                    label = TreasureUtils.getChallengeName(treasure, true);
                }
                break;
            default:
                priority = EMarkerRenderPriority.treasure;
                if (treasure.spec) {
                    label = treasure.spec.dispName;
                }
                break;
        }


        if (treasure.spec) {
            if (treasure.spec.dispNameAux) {
                let oldLabel = label;
                label = treasure.spec.dispNameAux;
                if (oldLabel) {
                    label += " " + oldLabel;
                }
            }
        }

        if (SettingsManagerService.settings.app.settings.showMarkerIds.value) {
            label = "#" + treasure.id + "/" + label;
        }

        let crateMarkerContent: IPlaceMarkerContent = {
            location: new ILatLng(treasure.lat, treasure.lng),
            initLocation: new ILatLng(treasure.lat, treasure.lng),
            currentLocationCopy: new ILatLng(treasure.lat, treasure.lng),
            title: placeName + " (" + treasure.dynamic.cacheIndex + "/" + (treasure.index + 1) + ")",
            tempTitle: null,
            tempIcon: null,
            icon: null,
            data: null,
            shape: EMapShapes.marker,
            mode: mode,
            visible: true,
            layer: null,
            radius: null,
            zindex: null,
            priority: priority,
            color: null,
            callback: null,
            dragCallback: null,
            locked: locked,
            label: label,
            uid: null,
            drag: false
        };
        crateMarkerContent.tempTitle = crateMarkerContent.title;

        return crateMarkerContent;
    }



    static getBaseMarkerDataFromARObject(item: ILeplaceObjectContainer): IPlaceMarkerContent {
        let mode: number = EMarkerTypes.canvasPlain;
        let crateMarkerContent: IPlaceMarkerContent = {
            location: new ILatLng(item.location.lat, item.location.lng),
            initLocation: new ILatLng(item.location.lat, item.location.lng),
            currentLocationCopy: new ILatLng(item.location.lat, item.location.lng),
            title: item.object.name + " (" + item.object.code + "/" + item.object.id + ")",
            tempTitle: null,
            tempIcon: null,
            icon: null,
            data: null,
            shape: EMapShapes.marker,
            mode: mode,
            visible: true,
            layer: null,
            radius: null,
            zindex: null,
            priority: null,
            color: null,
            callback: null,
            dragCallback: null,
            locked: false,
            label: "AR",
            uid: null,
            drag: false
            // label: null
        };

        crateMarkerContent.tempTitle = crateMarkerContent.title;

        return crateMarkerContent;
    }



    static getBaseMarkerDataForUser(username: string): IPlaceMarkerContent {
        let mode: number = EMarkerTypes.canvasFrame;
        let gMarkerContent: IPlaceMarkerContent = {
            location: new ILatLng(null, null),
            initLocation: new ILatLng(null, null),
            currentLocationCopy: new ILatLng(null, null),
            title: username,
            tempTitle: username,
            tempIcon: null,
            icon: null,
            data: null,
            shape: EMapShapes.marker,
            mode: mode,
            visible: true,
            layer: null,
            radius: null,
            zindex: EMarkerPriority.user,
            priority: EMarkerRenderPriority.user,
            color: null,
            callback: null,
            dragCallback: null,
            locked: false,
            label: username,
            uid: null,
            drag: false
        };
        return gMarkerContent;
    }

    static getBaseMarker(): IPlaceMarkerContent {
        let gMarkerContent: IPlaceMarkerContent = {
            location: new ILatLng(null, null),
            initLocation: new ILatLng(null, null),
            currentLocationCopy: new ILatLng(null, null),
            title: null,
            tempTitle: null,
            tempIcon: null,
            icon: null,
            data: null,
            shape: EMapShapes.marker,
            mode: EMarkerTypes.canvasFrame,
            visible: true,
            layer: null,
            radius: null,
            zindex: EMarkerPriority.user,
            priority: null,
            color: null,
            callback: null,
            dragCallback: null,
            locked: false,
            label: null,
            uid: null,
            drag: false
        };
        return gMarkerContent;
    }


    static getBaseMarkerDataFromGroupMember(member: IGroupMember): IPlaceMarkerContent {
        let mode: number = EMarkerTypes.canvasFrame;
        let lat: number = 0;
        let lng: number = 0;
        if (!member) {
            return null;
        }
        if (member.dynamic && member.dynamic.status) {
            lat = member.dynamic.status.lat;
            lng = member.dynamic.status.lng;
        }

        if (lat == null || lng == null) {
            return null;
        }

        let title: string = "Player";
        let photoUrl: string = null;
        if (member.user) {
            if (member.user.name) {
                title = member.user.name;
            }
            if (member.user.photoUrl) {
                photoUrl = member.user.photoUrl;
            }
        }

        let gMarkerContent: IPlaceMarkerContent = {
            location: new ILatLng(lat, lng),
            initLocation: new ILatLng(lat, lng),
            currentLocationCopy: new ILatLng(lat, lng),
            title: title,
            tempTitle: title,
            tempIcon: null,
            icon: null,
            data: null,
            shape: EMapShapes.marker,
            mode,
            visible: true,
            layer: null,
            radius: null,
            zindex: EMarkerPriority.otherUser,
            priority: EMarkerRenderPriority.otherUser,
            color: null,
            callback: null,
            dragCallback: null,
            locked: false,
            label: title,
            uid: null,
            drag: false,
            detailsOpenContext: {
                title: "Player",
                heading: title,
                description: null,
                photoUrl: photoUrl,
                large: false

            }
        };
        return gMarkerContent;
    }


    /**
     * create a circle marker to hide an exact place marker
     */
    static createCircleMarker(hiddenPlaceMarkerData: IPlaceMarkerContent, radius: number, layer: string): IPlaceMarkerContent {
        return MarkerUtils.createCircleMarkerCore(hiddenPlaceMarkerData.location, hiddenPlaceMarkerData.title, hiddenPlaceMarkerData.color, radius, layer);
    }

    /**
     * create a circle marker to hide an exact place marker
     */
    static createCircleMarkerCore(center: ILatLng, title: string, color: string, radius: number, layer: string): IPlaceMarkerContent {
        let loc: ILatLng = Object.assign({}, center);
        let placeMarkerContentCircle: IPlaceMarkerContent = {
            location: loc,
            initLocation: loc,
            currentLocationCopy: new ILatLng(loc.lat, loc.lng),
            title: title,
            tempTitle: title,
            tempIcon: EMarkerIcons.location,
            icon: EMarkerIcons.location,
            data: null,
            shape: EMapShapes.circle,
            mode: EMarkerTypes.plain,
            layer: layer,
            visible: true,
            radius,
            zindex: EMarkerPriority.circle,
            priority: null,
            color: GeneralCache.isWeb ? EColorsRGBA[color] : EColorsHEX[color],
            callback: null,
            dragCallback: null,
            locked: false,
            label: null,
            uid: null,
            drag: false
        };
        return placeMarkerContentCircle;
    }

    static getWaypointDistMarkerData(layer: string, index: number, coords: ILatLng, title: string) {
        let placeMarkerContentWaypoint: IPlaceMarkerContent = MarkerUtils.getWaypointMarkerData(coords, title);
        MarkerUtils.setMarkerDisplayOptions(placeMarkerContentWaypoint, EMarkerScope.item);
        placeMarkerContentWaypoint.icon = EMarkerIcons.sign;
        placeMarkerContentWaypoint.visible = true;
        placeMarkerContentWaypoint.radius = 40;
        placeMarkerContentWaypoint.layer = layer;
        placeMarkerContentWaypoint.zindex = EMarkerPriority.waypoint;
        placeMarkerContentWaypoint.priority = EMarkerRenderPriority.waypoint;
        placeMarkerContentWaypoint.uid = "/WAYPOINT/" + index;
        return placeMarkerContentWaypoint;
    }

    static getWaypointMarkerData(coords: ILatLng, title: string) {
        let markerIcon: string = EMarkerIcons.waypoint;
        let placeMarkerContentWaypoint: IPlaceMarkerContent = {
            location: coords,
            initLocation: new ILatLng(coords.lat, coords.lng),
            currentLocationCopy: new ILatLng(coords.lat, coords.lng),
            title: title,
            tempTitle: title,
            tempIcon: markerIcon,
            icon: markerIcon,
            data: null,
            layer: EMarkerLayers.WAYPOINTS,
            visible: false,
            zindex: EMarkerPriority.waypoint,
            priority: EMarkerRenderPriority.waypoint,
            mode: EMarkerTypes.canvasPlain,
            radius: null,
            shape: EMapShapes.marker,
            callback: null,
            dragCallback: null,
            locked: false,
            label: title,
            uid: null,
            drag: false
        }
        return placeMarkerContentWaypoint;
    }

    /**
     * udpate marker display params based on the mode
     */
    static setMarkerDisplayOptions(markerContent: IPlaceMarkerContent, scope: number, checked: boolean = true) {
        switch (scope) {
            case EMarkerScope.place:
                markerContent.radius = 120;
                markerContent.defaultRadius = 120;
                markerContent.lockedRadius = 60;
                markerContent.zindex = EMarkerPriority.place;
                markerContent.priority = EMarkerRenderPriority.place;
                markerContent.locked = false;
                markerContent.mode = EMarkerTypes.canvasFrame;
                break;
            case EMarkerScope.auxPlace:
                markerContent.radius = 60;
                markerContent.defaultRadius = 60;
                markerContent.lockedRadius = 60;
                markerContent.zindex = EMarkerPriority.auxPlace;
                markerContent.priority = EMarkerRenderPriority.auxPlace;
                markerContent.locked = true;
                markerContent.mode = EMarkerTypes.canvasFrame;
                break;
            case EMarkerScope.item:
            case EMarkerScope.auxItem:
                switch (markerContent.mode) {
                    case EMarkerTypes.canvasFrame:
                        markerContent.radius = 60;
                        markerContent.defaultRadius = 60;
                        markerContent.lockedRadius = 60;
                        // markerContent.mode = EMarkerTypes.plain;
                        break;
                    case EMarkerTypes.plain:
                    case EMarkerTypes.canvasPlain:
                        markerContent.radius = 40;
                        markerContent.defaultRadius = 40;
                        markerContent.lockedRadius = 40;
                        // markerContent.mode = EMarkerTypes.plain;
                        break;
                }
                if (scope === EMarkerScope.auxItem) {
                    markerContent.locked = true;
                } else {
                    markerContent.locked = false;
                }
                markerContent.zindex = EMarkerPriority.auxPlace;
                markerContent.priority = EMarkerRenderPriority.auxPlace;
                break;
        }

        if (!checked) {
            // check hidden marker modes
            switch (markerContent.hiddenMarkerMode) {
                case EHiddenMarkerModes.index:
                    markerContent.radius = 40;
                    markerContent.defaultRadius = 40;
                    markerContent.lockedRadius = 40;
                    break;
                case EHiddenMarkerModes.circle:
                    markerContent.radius = 40;
                    markerContent.defaultRadius = 40;
                    markerContent.lockedRadius = 40;
                    break;
                default:
                    break;
            }
        }
    }


    static setTreasureMarkerZindex(crateMarkerContent: IPlaceMarkerContent, tm: ILeplaceTreasure, reverse: boolean) {
        if (tm.dynamic.showMarker) {
            // item type is the generic type (i.e. any kind of story is of type story)
            // the specific type is defined as specificType
            // assign marker priority/zindex
            crateMarkerContent.zindex = EMarkerPriority.otherTreasure;
            let typeKeys: string[] = Object.keys(ETreasureType);
            let priorityKeys: string[] = Object.keys(EMarkerPriority);
            let maxp: number = 0;

            reverse = false;

            if (reverse) {
                for (let i = 0; i < priorityKeys.length; i++) {
                    let key: string = priorityKeys[i];
                    if (EMarkerPriority[key] > maxp) {
                        maxp = EMarkerPriority[key];
                    }
                }
            }

            for (let i = 0; i < typeKeys.length; i++) {
                let key: string = typeKeys[i];
                if (tm.type === ETreasureType[key]) {
                    if (EMarkerPriority[key] != null) {
                        if (reverse) {
                            crateMarkerContent.zindex = maxp - EMarkerPriority[key];
                        } else {
                            crateMarkerContent.zindex = EMarkerPriority[key];
                        }
                    }
                    break;
                }
            }

            for (let i = 0; i < typeKeys.length; i++) {
                let key: string = typeKeys[i];
                if (tm.type === ETreasureType[key]) {
                    if (EMarkerRenderPriority[key] != null) {
                        crateMarkerContent.priority = EMarkerRenderPriority[key];
                    }
                    break;
                }
            }
        }
    }

    static getCrateOpenInfo(tm: ILeplaceTreasure, isWorldMap: boolean, debug: boolean) {
        if (debug) {
            console.log("clicked: ", tm);
        }
        let placeName: string = (tm && tm.place && tm.place.place && tm.place.place.name) ? tm.place.place.name : "Unknown place";
        let title: string = (tm && tm.spec && tm.spec.dispName) ? tm.spec.dispName : "Unknown item";
        let sub: string = (tm && tm.spec && tm.spec.description) ? tm.spec.description : "Found";
        sub += " @" + placeName;
        if (!tm.dynamic.showMarker) {
            if (!isWorldMap) {
                title += " (N/A)";
            } else {
                title += " (locked)";
            }
        }

        return {
            title,
            sub
        };
    }

    /**
     * check init array
     * initialize elements 
     * @param array 
     * @param multiMarkersData 
     * @param key 
     */
    static checkInitArray(array, key: string) {
        let isInit: boolean = true;
        if (!array[key]) {
            array[key] = [];
            isInit = false;
        }
        return isInit;
    }


    /**
     * check init single object
     * initialize
     * @param object 
     * @param singleMarkersData 
     * @param key 
     */
    static checkInitSingle(object, key: string) {
        let isInit: boolean = true;
        if (!object[key]) {
            object[key] = null;
            isInit = false;
        }
        return isInit;
    }


    static setDPIForPhoto(canvas: HTMLCanvasElement, dpi: number, hdpi: boolean) {
        let ctx = canvas.getContext("2d");

        let scaleFactor: number = MarkerUtils.getScaleFactor(dpi, hdpi);
        // console.log(scaleFactor);
        // var scaleFactor = dpi / 96;
        let oldWidth = canvas.width;
        let oldHeight = canvas.height;

        canvas.width = Math.ceil(oldWidth * scaleFactor);
        canvas.height = Math.ceil(oldHeight * scaleFactor);

        canvas.style.width = oldWidth + 'px';
        canvas.style.height = oldHeight + 'px';

        // now scale the context to counter
        // the fact that we've manually scaled
        // our canvas element
        ctx.scale(scaleFactor, scaleFactor);
        return canvas;
    }

    static getScaleFactor(dpi: number, hdpi: boolean): number {

        // let backingStoreRatio;

        // if (dpi === null) {
        //     // finally query the various pixel ratios
        //     dpi = window.devicePixelRatio || 1;
        //     backingStoreRatio = ctx.webkitBackingStorePixelRatio ||
        //         ctx.mozBackingStorePixelRatio ||
        //         ctx.msBackingStorePixelRatio ||
        //         ctx.oBackingStorePixelRatio ||
        //         ctx.backingStorePixelRatio || 1;

        //     dpi *= 2;
        // } else {
        //     backingStoreRatio = 96;
        // }

        // let scaleFactor = dpi / backingStoreRatio;

        let backingStoreRatio: number;
        let scaleFactor: number = 1;

        if (dpi === null) {
            // use for flexible dpi
            scaleFactor = hdpi ? 4 : 2;
        } else {
            // use for fixed dpi
            backingStoreRatio = 96;
            scaleFactor = dpi / backingStoreRatio;
        }

        if (scaleFactor > MarkerUtils.settings.maxScale) {
            scaleFactor = MarkerUtils.settings.maxScale;
        }
        return scaleFactor;
    }

    static setDPI(canvas: HTMLCanvasElement, dpi: number, hdpi: boolean) {
        let ctx: CanvasRenderingContext2D = canvas.getContext("2d");
        let scaleFactor: number = MarkerUtils.getScaleFactor(dpi, hdpi);

        // console.log(scaleFactor);
        // var scaleFactor = dpi / 96;
        let oldWidth = canvas.width;
        let oldHeight = canvas.height;

        let scaleFactor2 = 1;
        if (oldWidth > MarkerUtils.settings.maxWidth) {
            scaleFactor2 = MarkerUtils.settings.maxWidth / oldWidth;
        }

        oldWidth *= scaleFactor2;
        oldHeight *= scaleFactor2;

        canvas.width = Math.ceil(oldWidth * scaleFactor);
        canvas.height = Math.ceil(oldHeight * scaleFactor);

        canvas.style.width = oldWidth + 'px';
        canvas.style.height = oldHeight + 'px';

        // now scale the context to counter
        // the fact that we've manually scaled
        // our canvas element
        ctx.scale(scaleFactor, scaleFactor);
        return canvas;
    }


    // https://stackoverflow.com/questions/4276048/html5-canvas-fill-circle-with-image
    static drawMarker(ctx: CanvasRenderingContext2D, width: number, height: number, mh: number, mw: number, img: CanvasImageSource, color: string, filter: boolean, ios: boolean) {
        ctx.imageSmoothingEnabled = true;
        // context.globalCompositeOperation = "lighter";

        ctx.globalAlpha = 0.9;
        if (!ios && filter) {
            ctx.filter = MarkerUtils.settings.lockedFilter;
            // ctx.filter = "grayscale(80%) sepia(0%)";
        }
        // 30,114,163
        if (!color) {
            color = "rgba(30,114,163,0.9)";
        }
        // ctx.strokeStyle = "rgba(30,114,163,0.8)";
        ctx.strokeStyle = color;

        let lw = 10;
        ctx.lineWidth = lw;

        // width -= lw;
        // height -= lw;

        let radius1 = Math.floor((width - lw) / 2);
        let radius = Math.floor(width / 2);

        let cx = radius, cy = radius;
        let pax = cx - mw / 2;
        let pay = cy + radius1 + lw;
        let pbx = cx + mw / 2;
        let pby = cx + radius1 + lw;
        let pcx = cx;
        let pcy = cy + radius1 + mh;

        // Save the state, so we can undo the clipping
        ctx.save();

        // create pointer
        ctx.beginPath();
        ctx.fillStyle = color;
        ctx.moveTo(pax, pay);
        ctx.lineTo(pbx, pby);
        ctx.lineTo(pcx, pcy);
        ctx.lineTo(pax, pay);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        // Create a circle
        ctx.beginPath();
        // ctx.fillStyle = "rgba(0,0,0,0)";
        ctx.arc(cx, cy, radius1, 0, Math.PI * 2, true);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();

        // Clip to the current path
        ctx.clip();

        ctx.drawImage(img, 0, 0, width, height - mh);

        // Undo the clipping
        ctx.restore();


        if (ios && filter) {
            MarkerUtils.addFilter(ctx);
        }

        return ctx;
    }


    /**
     * format container
     * for canvas marker display
     * @param md 
     * @param circleDiameter 
     */
    static formatCanvasMarkerContainer(md: IPlaceMarkerContent, circleDiameter: number) {
        let f: ICanvasMarkerContainer = {
            fullW: 0,
            fullH: 0,
            paddingH: 0,
            paddingW: 0,
            pointerH: 0,
            contentH: 0,
            contentW: 0,
            pointerW: 0,
            lineWidth: MarkerUtils.settings.lineW,
            scale: 0
        };

        // relative values for diameter 120
        f.pointerH = 20;
        f.pointerW = 20;

        if (!circleDiameter) {
            circleDiameter = 120;
        } else {
            f.pointerH = Math.floor(f.pointerH * circleDiameter / 120);
            f.pointerW = Math.floor(f.pointerW * circleDiameter / 120);
        }

        f.scale = circleDiameter / 120;

        f.contentH = circleDiameter;
        f.contentW = circleDiameter;

        f.fullW = circleDiameter;
        f.fullH = circleDiameter;

        if (md && md.label) {
            MarkerUtils.applyPadding(f);
        } else {
            MarkerUtils.applyPadding(f);
        }

        f.fullH += f.pointerH + f.lineWidth * 3;

        return f;
    }

    static applyPadding(f: ICanvasMarkerContainer) {
        f.fullW += MarkerUtils.settings.outerPaddingW;
        f.fullH += MarkerUtils.settings.outerPaddingH;
        f.paddingH = MarkerUtils.settings.innerPadding;
        f.paddingW = Math.floor(MarkerUtils.settings.outerPaddingH / 2);
    }

    // https://stackoverflow.com/questions/4276048/html5-canvas-fill-circle-with-image
    static drawMarkerText(ctx: CanvasRenderingContext2D, f: ICanvasMarkerContainer, img: CanvasImageSource, color: string, labelFrameColor: string, labelTextColor: string, filter: boolean, text: string, supertext: string[], ios: boolean, compassRotate: number) {

        // console.log(f);
        let debug: boolean = MarkerUtils.debug;
        // let ctx2: CanvasRenderingContext2D;
        // ctx2.fillText();
        ctx.imageSmoothingEnabled = true;
        // context.globalCompositeOperation = "lighter";
        ctx.globalAlpha = 0.9;

        if (!ios && filter) {
            ctx.filter = MarkerUtils.settings.lockedFilter;
            // ctx.filter = "grayscale(80%) sepia(0%)";
        }
        // 30,114,163
        if (!color) {
            color = "rgba(30,114,163,0.9)";
        }
        // ctx.strokeStyle = "rgba(30,114,163,0.8)";
        ctx.strokeStyle = color;

        ctx.lineWidth = f.lineWidth;

        let contentRadius = Math.floor(f.contentH / 2);
        // let resizeRatio: number = f.paddingW / radius;
        // f.pointerH = Math.floor(f.pointerH * (1 - resizeRatio));
        // f.pointerW = Math.floor(f.pointerW * (1 - resizeRatio));

        let cx = Math.floor(f.fullW / 2);
        let cy = contentRadius + f.paddingH + f.lineWidth;

        // contentRadius -= f.paddingW * 2;

        if (text) {
            let fontSize: number = MarkerUtils.settings.fontSize;
            ctx.font = "small-caps bold " + fontSize + "px Arial";
            ctx.textAlign = "center";
            let small: boolean = f.scale < 0.8;
            text = StringUtils.trimName(text, small ? EMessageTrim.markerAuxCaptionCanvas : EMessageTrim.markerCaptionCanvas);

            if (MarkerUtils.settings.allCaps) {
                text = text.toUpperCase();
            }

            // get width of text
            let rWidth = ctx.measureText(text).width;

            // color for background
            // ctx.fillStyle = '#f50';
            ctx.fillStyle = labelFrameColor ? labelFrameColor : "#1e72a3";
            /// draw background rect assuming height of font
            // ctx.fillRect(width / 2 - rWidth / 2 - rPad, 0, rWidth + rPad * 2, 12);
            let rHeight = fontSize;
            if (supertext != null) {
                rHeight *= (supertext.length + 1);
                for (let i = 0; i < supertext.length; i++) {
                    supertext[i] = StringUtils.trimName(supertext[i], small ? EMessageTrim.markerAuxCaptionCanvas : EMessageTrim.markerCaptionCanvas);
                    if (MarkerUtils.settings.allCaps) {
                        supertext[i] = supertext[i].toUpperCase();
                    }
                    let rWidth2 = ctx.measureText(supertext[i]).width;
                    if (rWidth2 > rWidth) {
                        rWidth = rWidth2;
                    }
                }
            }

            MarkerUtils.roundRect(ctx, Math.floor(f.fullW / 2 - rWidth / 2 - MarkerUtils.settings.labelWPadding), 0,
                Math.floor(rWidth + MarkerUtils.settings.labelWPadding * 2), rHeight + MarkerUtils.settings.labelHPadding, MarkerUtils.settings.labelRadius, true, false);
            // color for text
            // ctx.fillStyle = '#fff';
            ctx.fillStyle = labelTextColor ? labelTextColor : "#fcd182";

            if (supertext != null) {
                let div = supertext.length + 1;
                for (let i = 0; i < supertext.length; i++) {
                    ctx.fillText(supertext[i], Math.floor(f.fullW / 2), (fontSize + 1) * (i + 1));
                }
                ctx.fillText(text, Math.floor(f.fullW / 2), (fontSize + 1) * div);
                cy += (fontSize + 1) * (div - 1);
            } else {
                ctx.fillText(text, Math.floor(f.fullW / 2), fontSize + 1);
            }
        }

        let imageX = cx - contentRadius;
        let imageY = cy - contentRadius;

        let imageW = f.contentW;
        let imageH = f.contentH;

        if (debug) {
            // ctx.strokeStyle = "#fcd182";
            ctx.strokeRect(0, 0, f.fullW, f.fullH);
            ctx.strokeRect(imageX, imageY, imageW, imageH);
            ctx.strokeStyle = color;
        }

        ctx.translate(0, f.paddingH);

        let pax = Math.floor(cx - f.pointerW / 2);
        let pay = Math.floor(cy + contentRadius + f.lineWidth);
        let pbx = Math.floor(cx + f.pointerW / 2);
        let pby = pay;
        let pcx = cx;
        let pcy = pay + f.pointerH;
        // Save the state, so we can undo the clipping
        ctx.save();
        // create pointer
        ctx.beginPath();
        ctx.fillStyle = color;
        ctx.moveTo(pax, pay);
        ctx.lineTo(pbx, pby);
        ctx.lineTo(pcx, pcy);
        ctx.lineTo(pax, pay);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        // Create a circle
        ctx.beginPath();
        // ctx.fillStyle = "rgba(0,0,0,0)";
        ctx.arc(cx, cy, contentRadius, 0, Math.PI * 2, true);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
        // Clip to the current path
        ctx.clip();
        ctx.drawImage(img, imageX, imageY, imageW, imageH);
        // Undo the clipping
        ctx.restore();

        console.log("add marker dimension: ", imageW, imageH, contentRadius, pax, pay, pbx, pby, pcx, pcy);

        if (ios && filter) {
            MarkerUtils.addFilter(ctx);
        }

        if (compassRotate != null) {
            ctx.rotate(GeometryUtils.degreesToRadians(compassRotate));
        }

        return ctx;
    }


    // https://stackoverflow.com/questions/4276048/html5-canvas-fill-circle-with-image
    static drawMarkerTextPlain(ctx: CanvasRenderingContext2D, f: ICanvasMarkerContainer, img: CanvasImageSource, color: string, labelFrameColor: string, labelTextColor: string, filter: boolean, text: string, supertext: string[], ios: boolean, compassRotate: number) {
        // console.log(f);
        let debug: boolean = MarkerUtils.debug;
        // let ctx2: CanvasRenderingContext2D;
        // ctx2.fillText();
        ctx.imageSmoothingEnabled = true;
        // context.globalCompositeOperation = "lighter";

        if (!ios && filter) {
            ctx.filter = MarkerUtils.settings.lockedFilter;
            // ctx.filter = "grayscale(80%) sepia(0%)";
        }
        // 30,114,163
        if (!color) {
            color = "rgba(30,114,163,0.9)";
        }

        let fullH = f.fullH - f.pointerH;
        let fullW = f.fullW;
        // ctx.strokeStyle = "rgba(30,114,163,0.8)";
        ctx.strokeStyle = color;
        ctx.lineWidth = f.lineWidth;
        let contentRadius = Math.floor(f.contentH / 2);
        // let resizeRatio: number = f.paddingW / radius;
        // f.pointerH = Math.floor(f.pointerH * (1 - resizeRatio));
        // f.pointerW = Math.floor(f.pointerW * (1 - resizeRatio));
        let cx = Math.floor(fullW / 2);
        // let cy = contentRadius + 4 * f.paddingH + f.lineWidth + f.pointerH;
        let cy = contentRadius + f.paddingH + f.lineWidth + f.pointerH;
        // contentRadius -= f.paddingW * 2;

        if (text) {
            let fontSize = MarkerUtils.settings.fontSize;
            let margin = 4;
            ctx.font = "small-caps bold " + fontSize + "px Arial";
            ctx.textAlign = "center";
            let small: boolean = f.scale < 0.8;
            text = StringUtils.trimName(text, small ? EMessageTrim.markerAuxCaptionCanvas : EMessageTrim.markerCaptionCanvas);

            if (MarkerUtils.settings.allCaps) {
                text = text.toUpperCase();
            }

            // get width of text
            let rWidth = ctx.measureText(text).width;

            // color for background
            // ctx.fillStyle = '#f50';
            ctx.fillStyle = labelFrameColor ? labelFrameColor : "#1e72a3";
            let rHeight = fontSize;
            if (supertext != null) {
                rHeight *= (supertext.length + 1);
                for (let i = 0; i < supertext.length; i++) {
                    supertext[i] = StringUtils.trimName(supertext[i], small ? EMessageTrim.markerAuxCaptionCanvas : EMessageTrim.markerCaptionCanvas);
                    if (MarkerUtils.settings.allCaps) {
                        supertext[i] = supertext[i].toUpperCase();
                    }
                    let rWidth2 = ctx.measureText(supertext[i]).width;
                    if (rWidth2 > rWidth) {
                        rWidth = rWidth2;
                    }
                }
            }

            let starty: number = f.pointerH - margin;
            let endy: number = rHeight + MarkerUtils.settings.labelHPadding;

            if (supertext) {
                starty -= 2;
                endy += 2;
            }

            /// draw background rect assuming height of font
            // ctx.fillRect(width / 2 - rWidth / 2 - rPad, 0, rWidth + rPad * 2, 12);
            MarkerUtils.roundRect(ctx,
                Math.floor(f.fullW / 2 - rWidth / 2 - MarkerUtils.settings.labelWPadding),
                starty,
                Math.floor(rWidth + MarkerUtils.settings.labelWPadding * 2),
                endy,
                MarkerUtils.settings.labelRadius,
                true,
                false);

            // color for text
            // ctx.fillStyle = '#fff';
            ctx.fillStyle = labelTextColor ? labelTextColor : "#fcd182";

            if (supertext != null) {
                let div = supertext.length + 1;
                for (let i = 0; i < supertext.length; i++) {
                    ctx.fillText(supertext[i], Math.floor(f.fullW / 2), starty + (fontSize + 1) * (i + 1));
                }
                ctx.fillText(text, Math.floor(f.fullW / 2), starty + (fontSize + 1) * div);
                cy += (fontSize + 1) * (div - 1);
            } else {
                ctx.fillText(text, Math.floor(f.fullW / 2), starty + fontSize + 1);
            }
        }

        let imageX = cx - contentRadius;
        // let imageY = cy - contentRadius + f.pointerH;
        let imageY = cy - contentRadius - f.lineWidth;

        let imageW = f.contentW;
        let imageH = f.contentH;

        if (debug) {
            // ctx.strokeStyle = "#fcd182";
            ctx.strokeRect(0, 0, fullW, fullH);
            ctx.strokeRect(imageX, imageY, f.contentW, f.contentH);
            ctx.strokeStyle = color;
        }

        ctx.translate(0, f.paddingH);

        // only limit max size, allow smaller sizes, check aspect ratio
        imageW = (img as any).width as number;
        imageH = (img as any).height as number;
        let aspectRatio: number = imageW / imageH;

        if (imageW > f.contentW) {
            imageW = f.contentW;
            imageH = imageW / aspectRatio;
        } else if (imageH > f.contentH) {
            imageH = f.contentH;
            imageW = imageH * aspectRatio;
        }

        imageX += (f.contentW - imageW) / 2;
        imageY += (f.contentH - imageH) / 2;

        imageX = Math.floor(imageX);
        imageY = Math.floor(imageY);
        imageW = Math.floor(imageW);
        imageH = Math.floor(imageH);

        // imageY -= f.paddingH;
        ctx.drawImage(img, imageX, imageY, imageW, imageH);

        if (ios && filter) {
            MarkerUtils.addFilter(ctx);
        }

        if (compassRotate != null) {
            ctx.rotate(GeometryUtils.degreesToRadians(compassRotate));
        }

        return ctx;
    }


    /**
     * check for overlapping features within the viewport
     * @param lat 
     * @param lng 
     * @param zoom 
     * @param mkdata 
     */
    static checkOverlapsCore(lat: number, lng: number, zoom: number, mkdata: IPlaceMarkerContent[]) {
        let metersPerPx: number = GeometryUtils.getMetersPerPx(lat, zoom);
        let posCrt: ILatLng = new ILatLng(lat, lng);
        let overlapThreshold: number = 50;
        let mkdataOverlaps: IPlaceMarkerContent[] = [];
        let distRealVect: number[] = [];
        let distPxVect: number[] = [];
        for (let i = 0; i < mkdata.length; i++) {
            // compute distance to markers
            // convert distance to pixels distance to check for overlaps
            if (mkdata[i]) {
                let distReal: number = GeometryUtils.getDistanceBetweenEarthCoordinates(posCrt, mkdata[i].location, Number.MAX_VALUE);
                let distPx: number = distReal / metersPerPx;
                distRealVect.push(distReal);
                distPxVect.push(distPx);
                // console.log(distPx);
                if (distPx < overlapThreshold) {
                    mkdataOverlaps.push(mkdata[i]);
                }
            }
        }

        // console.log("mkdata: ", mkdata);
        // console.log("zoom: ", zoom, " meters per px: ", metersPerPx);
        // console.log(distRealVect);
        // console.log(distPxVect);
        // console.log("overlaps: ", mkdataOverlaps);

        // max zoom (even if there are still overlaps)
        if (zoom >= 20) {
            mkdataOverlaps = [];
        }
        return mkdataOverlaps;
    }



    /**
     * Draws a rounded rectangle using the current state of the canvas.
     * If you omit the last three params, it will draw a rectangle
     * outline with a 5 pixel border radius
     * @param {CanvasRenderingContext2D} ctx
     * @param {Number} x The top left x coordinate
     * @param {Number} y The top left y coordinate
     * @param {Number} width The width of the rectangle
     * @param {Number} height The height of the rectangle
     * @param {Number} [radius = 5] The corner radius; It can also be an object 
     *                 to specify different radii for corners
     * @param {Number} [radius.tl = 0] Top left
     * @param {Number} [radius.tr = 0] Top right
     * @param {Number} [radius.br = 0] Bottom right
     * @param {Number} [radius.bl = 0] Bottom left
     * @param {Boolean} [fill = false] Whether to fill the rectangle.
     * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
     */
    static roundRect(ctx: CanvasRenderingContext2D, x: number, y: number, width: number, height: number, radius1: number | IRoundRectangleRadiusSpec, fill: boolean, stroke: boolean) {
        let radius: IRoundRectangleRadiusSpec = { tl: 0, tr: 0, br: 0, bl: 0 };

        if (typeof stroke === 'undefined') {
            stroke = true;
        }
        if (typeof radius1 === 'undefined') {
            radius1 = 5;
        }

        if (typeof radius1 === 'number') {
            radius = { tl: radius1, tr: radius1, br: radius1, bl: radius1 };
        } else {
            let defaultRadius: IRoundRectangleRadiusSpec = { tl: 0, tr: 0, br: 0, bl: 0 };
            // tslint:disable-next-line:forin
            for (let side in defaultRadius) {
                radius[side] = radius[side] || defaultRadius[side];
            }
        }

        ctx.beginPath();
        ctx.moveTo(x + radius.tl, y);
        ctx.lineTo(x + width - radius.tr, y);
        ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
        ctx.lineTo(x + width, y + height - radius.br);
        ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
        ctx.lineTo(x + radius.bl, y + height);
        ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
        ctx.lineTo(x, y + radius.tl);
        ctx.quadraticCurveTo(x, y, x + radius.tl, y);
        ctx.closePath();
        if (fill) {
            ctx.fill();
        }
        if (stroke) {
            ctx.stroke();
        }

    }

    /**
     * combine add labels
     * @param heading 
     * @param label 
     * @param addLabel1 
     * @param addLabel2 
     * @returns 
     */
    static formatAddLabels(heading: string, label: string, addLabel1: string, addLabel2: string) {
        let addLabelCombinedLeft: string = "";
        let addLabelCombinedRight: string = "";
        let hasLabel: boolean = false;
        let labelInit: string = label;
        let heading2d: string[] = [];

        if (heading) {
            heading2d.push(heading);
        }

        if (addLabel1) {
            addLabelCombinedLeft += addLabel1 + " ";
            addLabelCombinedRight += " " + addLabel1;
            hasLabel = true;
            heading2d.push(addLabel1);
        }
        if (addLabel2) {
            addLabelCombinedLeft += addLabel2 + " ";
            addLabelCombinedRight += " " + addLabel2;
            hasLabel = true;
            heading2d.push(addLabel2);
        }

        if (hasLabel) {
            if (heading) {
                heading = addLabelCombinedLeft + " " + heading;
            } else {
                // label = StringUtils.trimName(label, EMessageTrim.markerCaptionCanvas - addLabelCombinedRight.length - 2);
                // label += addLabelCombinedRight;
                label = addLabelCombinedLeft + " " + label;
            }
        }

        heading2d.reverse();

        return {
            heading: heading,
            heading2d: heading2d,
            label2d: labelInit,
            label: label
        };
    }

}
