<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="later()" [loading]="!loaded" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">
  <!-- <activity-param-details *ngIf="loaded" [data]="param"></activity-param-details> -->
  <div class="padding-s" *ngIf="loaded" fxLayout="column">
    <form class="scrollbar-y scroll-content-modal-2s padding-s" #bdForm="ngForm" novalidate fxLayout="column">
      <safe-html-renderer [unsafeHtmlContent]="descriptionHtml"
        innerClass="info-text-s bold description-frame text-color-primary"></safe-html-renderer>
      <div>
        <ion-item class="list-item transparent-bg settings-item item-noborder padding-bottom-s">
          <ion-label class="text-color-primary info-text-s-imp ion-label-input">Zoom user*</ion-label>
          <ion-input type="text" class="text-left heading" placeholder="email" [(ngModel)]="bd.userId" ngDefaultControl
            required name="bdUser" #bdUser="ngModel">
          </ion-input>
        </ion-item>
        <span class="info-text-xs text-color-warn" [hidden]="bdUser.valid || (bdUser.pristine && !submitted)"
          color="danger" padding-left>
          Required field
        </span>
      </div>
      <ion-item class="list-item transparent-bg settings-item item-noborder padding-bottom-s">
        <ion-label class="text-color-primary info-text-s-imp ion-label-input">Zoom password*</ion-label>
        <ion-input type="text" class="text-left info-text-m" placeholder="password" [(ngModel)]="bd.userPassword"
          ngDefaultControl required name="userPassword" #userPassword="ngModel">
        </ion-input>
      </ion-item>
      <span class="info-text-xs text-color-warn" [hidden]="userPassword.valid || (userPassword.pristine && !submitted)"
        color="danger" padding-left>
        Required field
      </span>

      <ion-item class="list-item transparent-bg settings-item item-noborder padding-bottom-s">
        <ion-label class="text-color-primary info-text-s-imp ion-label-input">Display name*</ion-label>
        <ion-input type="text" class="text-left info-text-m" placeholder="password" [(ngModel)]="bd.displayName"
          ngDefaultControl required name="displayName" #displayName="ngModel">
        </ion-input>
      </ion-item>
      <span class="info-text-xs text-color-warn" [hidden]="displayName.valid || (displayName.pristine && !submitted)"
        color="danger" padding-left>
        Required field
      </span>

      <ion-item class="list-item transparent-bg settings-item item-noborder padding-bottom-s"
        *ngIf="showMeetingDetails">
        <ion-label class="text-color-primary info-text-s-imp ion-label-input">Meeting number*</ion-label>
        <ion-input type="text" class="text-left info-text-m" placeholder="password" [(ngModel)]="bd.meetingNumber"
          ngDefaultControl required name="meetingNumber" #meetingNumber="ngModel">
        </ion-input>
      </ion-item>
      <span class="info-text-xs text-color-warn" *ngIf="showMeetingDetails"
        [hidden]="meetingNumber.valid || (meetingNumber.pristine && !submitted)" color="danger" padding-left>
        Required field
      </span>

      <ion-item class="list-item transparent-bg settings-item item-noborder padding-bottom-s"
        *ngIf="showMeetingDetails">
        <ion-label class="text-color-primary info-text-s-imp ion-label-input">Meeting password*</ion-label>
        <ion-input type="text" class="text-left info-text-m" placeholder="password" [(ngModel)]="bd.meetingPassword"
          ngDefaultControl required name="meetingPassword" #meetingPassword="ngModel">
        </ion-input>
      </ion-item>
      <span class="info-text-xs text-color-warn" *ngIf="showMeetingDetails"
        [hidden]="meetingPassword.valid || (meetingPassword.pristine && !submitted)" color="danger" padding-left>
        Required field
      </span>

    </form>

    <div fxFlex></div>
    <div class="theme-border-top" [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col size="6">
            <ion-button class="action-button-fill button-color-primary" (click)="later()" icon-end>
              <span class="button-font-size-s">Later</span>
            </ion-button>
          </ion-col>
          <ion-col size="6">
            <ion-button class="action-button-fill button-color-primary" (click)="ok(bdForm)" icon-end>
              <span class="button-font-size-s">Continue</span>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>

</modal-container>