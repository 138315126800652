


import { Injectable } from "@angular/core";
import { AppAvailability } from "@ionic-native/app-availability/ngx";
import { Platform } from "@ionic/angular";
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { Util } from 'src/app/classes/general/util';
import { BackgroundModeWatchService, EBGMWatchId } from './background-mode-watch';


interface ICheckApp {
    code: number;
    android: ICheckAppDetails;
    ios: ICheckAppDetails;
}

interface ICheckAppDetails {
    appId: string;
}

export enum ECheckApp {
    fb = 1,
    fbLite = 2,
    twitter = 3,
    messenger = 4
}


// event tracking on google analytics
// https://analytics.google.com/analytics/web/?authuser=0#realtime/rt-event/a114689607w170689812p170459048/%3Ffilter.list%3D41%3D%3Dstart%3B/
@Injectable({
    providedIn: 'root'
})
export class CheckAppsService {
    appDefs: ICheckApp[];
    constructor(
        public platform: Platform,
        public appAvailability: AppAvailability,
        public backgroundModeWatch: BackgroundModeWatchService
    ) {
        console.log("check apps service created");
        this.loadAppDefs();
    }

    loadAppDefs() {
        this.appDefs = [
            {
                code: ECheckApp.fb,
                android: {
                    appId: "com.facebook.katana"
                },
                ios: {
                    appId: "fb://"
                }
            },
            {
                code: ECheckApp.fbLite,
                android: {
                    appId: "com.facebook.lite"
                },
                ios: {
                    // not sure
                    appId: "fb://"
                }
            },
            {
                code: ECheckApp.twitter,
                android: {
                    appId: "com.twitter.android"
                },
                ios: {
                    appId: "twitter://"
                }
            },
            {
                code: ECheckApp.messenger,
                android: {
                    appId: "com.facebook.orca"
                },
                ios: {
                    appId: "fb-messenger://"
                }
            }
        ];
    }

    checkResolve(code: number): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            let appId: string;

            for (let i = 0; i < this.appDefs.length; i++) {
                if (this.appDefs[i].code === code) {
                    if (this.platform.is('ios')) {
                        appId = this.appDefs[i].ios.appId;
                    } else if (this.platform.is('android')) {
                        appId = this.appDefs[i].android.appId;
                    }
                }
            }

            if (appId != null) {
                // this.appAvailability.check(appId).then(() => {
                //     resolve(true);
                // }).catch((err: Error) => {
                //     console.error(err);
                //     resolve(false);
                // });

                this.appAvailability.check(appId).then(
                    (_yes: boolean) => {
                        console.log(appId + ' is available');
                        resolve(true);
                    },
                    (_no: boolean) => {
                        console.log(appId + ' is NOT available');
                        resolve(false);
                    });
            } else {
                console.log('appId is NOT specified');
                resolve(false);
            }
        });
        return promise;
    }

    checkAnyResolve(codes: number[]): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (!(codes && codes.length > 0)) {
                resolve(false);
                return;
            }
            let promises = [];
            // let countReqired: number = codes.length;
            let count: number = 0;
            for (let i = 0; i < codes.length; i++) {
                promises.push(new Promise((resolve) => {
                    this.checkResolve(codes[i]).then((res: boolean) => {
                        if (res) {
                            count += 1;
                        }
                        resolve(true);
                    });
                }));
            }
            Promise.all(promises).then(() => {
                resolve(count > 0);
            });
        });
        return promise;
    }


    openNativeTimeout(validAppCodes: number[], nativeUrl: string, webUrl: string) {

        let onTimeout = () => {
            console.log("open timeout");
            Util.openURLExt(webUrl);
        };

        this.checkAnyResolve(validAppCodes).then((res: boolean) => {
            // fallback if app is not opened within time frame (e.g. app is disabled)
            this.backgroundModeWatch.initBgmWatchTriggerAndClearOnPaused(EBGMWatchId.checkApp, "Redirecting..", onTimeout, 3000);
            console.log("opening native: ", res);
            if (res) {
                if (GeneralCache.checkPlatformOS() === EOS.ios) {
                    // ios open with native app
                    Util.openURLExt(nativeUrl);
                } else {
                    // android open with native app
                    Util.openURLExt(nativeUrl);
                }
            } else {
                Util.openURLExt(webUrl);
            }
        });
    }
}
