import { Injectable } from "@angular/core";

export interface IActivityStatsCache {
    droneUsed: boolean,
    locationSkipped: boolean
}
@Injectable({
    providedIn: 'root'
})
export class ActivityStatsTrackerService {

    cachedStatsInit: IActivityStatsCache = {
        droneUsed: false,
        locationSkipped: false // jump/skip
    };

    cachedStats: IActivityStatsCache;

    constructor(

    ) {
        console.log("activity stats tracker service created");
        this.cachedStats = Object.assign({}, this.cachedStatsInit);
    }

    checkDroneUsed() {
        return this.cachedStats.droneUsed;
    }

    checkLocationSkipped() {
        return this.cachedStats.locationSkipped;
    }

    getCachedStats() {
        return this.cachedStats;
    }

    getCachedStatsInit() {
        return this.cachedStatsInit;
    }

    setCachedStats(cachedStats: IActivityStatsCache) {
        this.cachedStats = cachedStats;
    }

    registerDroneUsed() {
        console.log("stats tracker register drone used");
        this.cachedStats.droneUsed = true;
    }

    startNewSession() {
        console.log("stats tracker start new session");
        this.cachedStats = Object.assign({}, this.cachedStatsInit);
    }

    registerLocationSkipped() {
        console.log("stats tracker register location skipped");
        this.cachedStats.locationSkipped = true;
    }
}
