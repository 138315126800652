import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MpOptionsPage } from './mp-options.page';
import { PagesModule } from '../../pages.module';

const routes: Routes = [
  {
    path: '',
    component: MpOptionsPage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [MpOptionsPage]
})
export class MpOptionsPageModule { }
