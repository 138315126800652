<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="!enableBackButton" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()"
    rightButton="settings" [rightIcon]="true" (rightClick)="options()" [title]="title" [loading]="!loaded">
  </leplace-toolbar>
</ion-header>

<ion-content [ngClass]="theme">
  <div class="view-frame theme-background" fxLayout="column">

    <nav-bar class="margin-top-s theme-border-bottom" [slidesPerView]="categoryTabsGroupCount"
      [(selectedTabId)]="selectedTabId" [loadData]="initLoaded" [navBarItems]="navBarItems"
      (select)="selectCategoryFromNavItem($event)">
    </nav-bar>

    <progress-timeout *ngIf="showProgressReload" pid="groups-list" [small]="true"
      customClass="progress-bar-alternate-light" [timeoutValue]="loadingTimeout"
      [resetTimeoutToggle]="resetLoadingTimeout" [enabled]="autoReload">
    </progress-timeout>

    <div class="full-w padding-top-none theme-border-bottom padding-bottom-none">
      <ion-row class="ion-align-items-center" tappable (click)="openDateSelector()">
        <ion-col size="10">
          <span class="info-text-s fab-display-top bold text-color-primary">{{timeframeDisp}}</span>
        </ion-col>
        <ion-col size="2" class="text-right">
          <ion-button class="action-button-icon-only margin-right-s">
            <icon-wrapper slot="icon-only" [icon]="appIconsStandard.filter"></icon-wrapper>
          </ion-button>
        </ion-col>
      </ion-row>
    </div>

    <div class="scrollbar-y">
      <ion-row *ngIf="infoHTML">
        <ion-col size="12" class="text-center">
          <div [innerHTML]="infoHTML" class="info-text-m bold description-frame text-color-primary center-text">
          </div>
        </ion-col>
      </ion-row>

      <div *ngIf="emptyResult" class="padding-s text-center">
        <span class="info-text-m bold text-color-primary">Leaderboard empty</span>
      </div>

      <ion-list *ngIf="board && loaded" class="list-padding">
        <ion-item *ngFor="let entry of board" class="list-item list-item-padding transparent-bg">
          <leader-card-ext [entry]="entry" [selected]="entry.selected" tappable (click)="openEntry(entry)"
            class="full-w theme-border-bottom-thin"></leader-card-ext>
        </ion-item>
      </ion-list>
    </div>

    <div fxFlex></div>

    <div class="padding-horizontal-s theme-border-top margin-bottom-xs">
      <leplace-paginator [pagination]="pagination" (onSwitch)="switchPage($event)"></leplace-paginator>
    </div>
  </div>
</ion-content>