import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IUserInventory } from 'src/app/classes/def/user/stats';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { IGameItemCategoryWithItems, IGameItem, EInventoryCategoryCode, EItemActions } from 'src/app/classes/def/items/game-item';
import { EInventoryDetailMode, IInventoryDetailNavParams } from 'src/app/classes/def/nav-params/inventory';
import { AlertController, PopoverController, Platform, ModalController } from '@ionic/angular';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { IPageResponse, IGenericResponse } from 'src/app/classes/def/requests/general';
import { IItemActionContainer, IItemAction } from 'src/app/classes/def/items/action';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { IShareMsgParams } from 'src/app/classes/utils/message-utils';
import { AchievementUnlockedViewComponent } from 'src/app/modals/app/modals/achievement-unlocked/achievement-unlocked.component';
import { AuthRequestService } from 'src/app/services/general/auth-request/auth-request';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { UserStatsDataService } from 'src/app/services/data/user-stats';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { InventoryDataService } from 'src/app/services/data/inventory';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { LocationMonitorService } from 'src/app/services/map/location-monitor';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { PlacesDataService } from 'src/app/services/data/places';
import { AchievementsDataService } from 'src/app/services/data/achievements';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { PaginationHandlerService } from 'src/app/services/general/ui/pagination-handler';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { IAPCoreService } from 'src/app/services/apis/iap-rc';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-inventory-detail',
  templateUrl: './inventory-detail.page.html',
  styleUrls: ['./inventory-detail.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InventoryDetailPage implements OnInit, OnDestroy {

  leaderName: string = "";
  leaderId: number = -1;
  placeName: string = "";
  googleId: string = "";


  subTitle: string = "";
  emptyMessage: string = "";

  loaded: boolean = false;
  isRoot: boolean = false;

  userInventory: IUserInventory;
  heading: string;

  title: string = "Medals";
  theme: string = "theme-light theme-light-bg";

  selectedPage: number = 0;
  selectedCategory: number = 0;
  platform: IPlatformFlags = {} as IPlatformFlags;

  rowSpec: number = 2;

  category: IGameItemCategoryWithItems;
  gameItems: IGameItem[];
  externalItems: boolean = false;
  firstInit: boolean = true;


  // slides
  showLeftButton: boolean = true;
  showRightButton: boolean = true;
  enableTabButtons: boolean = false;
  activeItems: IGameItem[] = [];

  mode: number = EInventoryDetailMode.leaderboard;

  reloadItems: boolean = true;

  pagination = {
    page: 0,
    pages: 1,
    pageDisp: 1,
    hideLeft: true,
    hideRight: false
  };

  np: INavParams = null;
  vs: IViewSpecs;
  isModal: boolean = false;

  enableLockedDescription: boolean = false;

  constructor(

    public authService: AuthRequestService,
    public alertCtrl: AlertController,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public popoverCtrl: PopoverController,
    public userStatsProvider: UserStatsDataService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public analytics: AnalyticsService,
    public settingsProvider: SettingsManagerService,
    public iapService: IAPCoreService,
    public modalCtrl: ModalController,
    public inventory: InventoryDataService,
    public backButton: BackButtonService,
    public locationMonitor: LocationMonitorService,
    public locationManager: LocationManagerService,
    public multiplayer: MPDataService,
    public placesDataProvider: PlacesDataService,
    public achievements: AchievementsDataService,
    public tutorials: TutorialsService,
    public paginationHandler: PaginationHandlerService,
    public nps: NavParamsService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }
  
  ngOnInit() {
    this.selectedCategory = EInventoryCategoryCode.medals;
    this.analytics.trackView("inventory-detail");
    this.paginationHandler.init(this.pagination);

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.inventory, null, this.np);

      console.log("nav params: ", npInfo.params);

      let npar = npInfo.params;

      let hasParams: boolean = npInfo.hasParams;
      if (hasParams) {
        let np: INavParams = npar;
        let params: IInventoryDetailNavParams = np.params;

        this.vs = np.view;
        this.isModal = this.vs.isModal;
        if (this.vs.addToStack) {
          this.isModal = false;
        }

        this.enableLockedDescription = params.enableLockedDescriptions;

        this.mode = params.mode;

        if (params.items != null) {
          this.gameItems = params.items;
          this.title = "Items";
          this.heading = params.heading;
        } else {
          switch (params.mode) {
            case EInventoryDetailMode.leaderboard:
              this.selectedCategory = EInventoryCategoryCode.medals;
              if (params.leaderboardDetail) {
                this.leaderName = params.leaderboardDetail.leaderName;
                this.leaderId = params.leaderboardDetail.leaderId;
                this.subTitle = this.leaderName;
                this.title = "Medals";
                this.emptyMessage = "No medals yet";
              }
              break;
            case EInventoryDetailMode.placeSales:
              this.selectedCategory = EInventoryCategoryCode.sales;
              if (params.placeSalesDetail) {
                this.googleId = params.placeSalesDetail.googleId;
                this.placeName = params.placeSalesDetail.placeName;
                this.subTitle = this.placeName;
                this.title = "Sales";
                this.emptyMessage = "No sales available at the moment";
              }
              break;
          }
        }
      }

      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
          // this.theme = Constants.theme["aubergine"].css;
          this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
              this.platform = SettingsManagerService.settings.platformFlags;
            }
          }, (err: Error) => {
            console.error(err);
          });
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      this.init();
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  /**
   * get available coins
   * for current/leaderboard user
   */
  getUserCoins(reload: boolean) {
    this.userStatsProvider.getAllCoins(reload).then((coins: number) => {
      GeneralCache.resourceCache.user.general.content.cachedCoins = coins;
      this.heading = coins + " LP";
    }).catch((err: Error) => {
      this.analytics.dispatchError(err, "inventory");
    });
  }


  init() {
    // items already provided via nav params
    if (this.gameItems != null) {
      if (this.firstInit) {
        this.firstInit = false;
        this.externalItems = true;
      }
      this.showSelectedItems();
      return;
    }
    this.getUserCoins(false);
    this.selectCategoryOnInit().then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngOnDestroy() {
    console.log("leaving inventory");
    this.backButton.checkPop(this.vs);
  }

  trackByFn(_index, item) {
    return item.id;
  }


  trackItem(_index, item: IGameItem) {
    // console.log(item);
    return item ? item.id : undefined;
  }



  selectCategoryOnInit() {
    let promise = new Promise((resolve, reject) => {
      this.loadItems().then(() => {
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  /**
   * load prev/next page for the selected category
   * @param delta 
   */
  switchPage(delta: number) {
    this.paginationHandler.setPaginationPage(delta);
    this.selectCategoryOnInit().then(() => { }).catch(() => { });
  }

  /**
   * items already loaded before opening the page
   */
  showSelectedItems() {
    this.pagination.page = 0;
    this.pagination.pages = 1;
    this.paginationHandler.setPaginationControls();
    this.loaded = true;
  }

  /**
   * load category items
   */
  loadItems() {
    let promise = new Promise((resolve, reject) => {
      this.loaded = false;
      let promise = null;
      switch (this.mode) {
        case EInventoryDetailMode.leaderboard:
          promise = this.multiplayer.getLeaderboardUserInventory(this.leaderId, false, null, null, this.pagination.page);
          break;
        case EInventoryDetailMode.placeSales:
          promise = this.placesDataProvider.checkAvailableSales(this.googleId, this.pagination.page);
          break;
        default:
          promise = this.multiplayer.getLeaderboardUserInventory(this.leaderId, false, null, null, this.pagination.page);
          break;
      }
      promise.then((pageResp: IPageResponse) => {
        // console.log(pageResp);
        if (pageResp) {
          this.pagination.page = pageResp.page;
          this.pagination.pages = pageResp.pages;
          if (pageResp.data) {
            this.category = pageResp.data;
          }
          this.paginationHandler.setPaginationControls();
        }
        this.formatItems();
        this.loaded = true;
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        this.loaded = true;
        this.analytics.dispatchError(err, "inventory-detail");
        reject(err);
      });
    });
    return promise;
  }


  formatItems() {
    if (this.category) {
      // this.gameItems = this.category.items.map(item => {
      //     let gis: IGameItemWithStats = {
      //         item: item,
      //         stats: []
      //     };
      //     return gis;
      // });
      this.gameItems = this.category.items;
    }
  }


  back() {
    setTimeout(() => {
      this.modalCtrl.dismiss().then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }


  itemActionContainer(event: IItemActionContainer) {
    this.itemAction(event.action, event.item);
  }

  /**
   * minimal action set
   * not full inventory functionality
   * just buy with coins and use
   * to be used only for e.g. sales
   * @param itemAction 
   * @param item 
   */
  itemAction(itemAction: IItemAction, item: IGameItem) {
    console.log("item action: ", itemAction, " on: ", item);

    if (!itemAction) {
      return;
    }

    switch (itemAction.code) {
      case EItemActions.use:
        this.inventory.activateInventoryItem(item.code, itemAction.data.qrCode).then((_resp: IGenericResponse) => {
          this.init();
        }).catch((err: Error) => {
          console.error(err);
          this.analytics.dispatchError(err, "inventory");
          this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
        });
        break;
      case EItemActions.buy:
        this.buyItem(item, itemAction.data.amount).then(() => {
          this.init();
        }).catch((err: Error) => {
          console.error(err);
          this.analytics.dispatchError(err, "inventory");
          this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
        });
        break;
      default:
        return;
    }
  }



  /**
  * buy item sequence
  * @param code 
  */
  buyItem(item: IGameItem, amount: number) {
    console.log("buy with coins");
    let promise = new Promise((resolve, reject) => {
      // send request to the server to save the pending item
      this.inventory.buyInventoryItemWithCoins(item.code, amount).then(() => {
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }




  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      tutorial: {
        name: "Tutorial",
        code: 1,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      },
      refresh: {
        name: "Reload",
        code: 0,
        icon: EAppIconsStandard.refresh,
        enabled: !this.externalItems
      }
    };

    // if (this.mode === EInventoryDetailMode.leaderboard) {
    //     actions.recalculateMedals = {
    //         name: "Recalculate medals",
    //         code: 2,
    //         enabled: true
    //     }
    // };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      let promise;
      switch (result) {
        case 0:
          this.init();
          break;
        case 1:
          this.tutorials.showTutorialNoAction("Inventory Tutorial", ETutorialEntries.inventoryTutorial, null, null, true);
          break;
        case 2:
          if (this.mode === EInventoryDetailMode.leaderboard) {
            promise = this.checkNewAchievements();
          } else {
            promise = Promise.resolve(true);
          }
          promise.then(() => {
            this.init();
          }).catch((err: Error) => {
            console.error(err);
          });
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }


  /**
   * check new achievements
   * that have not been seen by the user yet
   */
  checkNewAchievements() {
    let promise = new Promise((resolve) => {
      this.loaded = false;
      // check for pending achievements to be unlocked
      this.achievements.recalculateAchievements().then((unlocked: number) => {
        if (!unlocked) {
          this.loaded = true;
          resolve(false);
          return;
        }
        // actually display newly unlocked achievements
        this.achievements.checkNewAchievements(false).then((medals: IGameItem[]) => {
          this.loaded = true;

          if (medals && medals.length > 0) {
            let achievementUnlockedParams: IShareMsgParams = {
              xp: 0,
              newAchievements: {
                items: medals,
                categoryName: "new achievements"
              },
              actionButtons: {
                watchAd: false,
                scanQR: false,
                share: false
              }
            };
            this.uiext.showCustomModal(null, AchievementUnlockedViewComponent, {
              view: {
                fullScreen: true,
                transparent: false,
                large: true,
                addToStack: true,
                frame: true
              },
              params: achievementUnlockedParams
            }).then((_res: number) => {
              resolve(true);
            }).catch((err: Error) => {
              console.error(err);
              this.analytics.dispatchError(err, "inventory-detail");
              resolve(false);
            });
          } else {
            resolve(false);
          }
        }).catch((err: Error) => {
          console.error(err);
          this.analytics.dispatchError(err, "inventory-detail");
          this.loaded = true;
          resolve(false);
        });
      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "inventory-detail");
        this.loaded = true;
        resolve(false);
      });
    });
    return promise;
  }

  openPaginateSelector() {
    this.paginationHandler.openPaginateSelector(() => {
      return this.loadItems();
    });
  }

  swipeEvent(e) {
    if (!this.backButton.handleSwipeEvent(e)) {
      if (e) {
        switch (e.direction) {
          case 2:
            // right to left
            if (!this.pagination.hideRight) {
              this.switchPage(1);
            }
            break;
          case 4:
            // left to right
            if (!this.pagination.hideLeft) {
              this.switchPage(-1);
            }
            break;
        }
      }
    }
  }

}

