import { Injectable } from "@angular/core";
import { AnalyticsTemplateService } from './analytics-template';
import { FirebaseAnalytics } from '@capacitor-firebase/analytics';
// declare var FirebaseAnalytics;


// event tracking on firebase analytics
@Injectable({
    providedIn: 'root'
})
export class AnalyticsFirebaseService implements AnalyticsTemplateService {
    public appInstanceId = '';
    enable: boolean = true;

    constructor(

    ) {

    }

    init() {
        let promise = new Promise((resolve) => {
            if (!this.enable) {
                resolve(false);
                return;
            }
            FirebaseAnalytics.getAppInstanceId().then((result) => {
                this.appInstanceId = result.appInstanceId || '';
                console.log("firebase analytics app instance id: ", this.appInstanceId);
            });
            resolve(true);
        });
        return promise;
    }

    /**
     * set user id from the db
     * @param userId 
     */
    setUserId(userId: number) {
        try {
            if (!this.enable) {               
                return;
            }
            FirebaseAnalytics.setUserId({ userId: userId.toString() }).then(() => {

            }).catch((err: Error) => {
                console.error(err);
            });
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * track view (changes)
     * @param title 
     */
    trackView(title: string) {
        console.log("track view: " + title);
        try {
            if (!this.enable) {               
                return;
            }
            FirebaseAnalytics.setCurrentScreen({ screenName: title }).then((res: any) => {
                console.log(res);
            }).catch((error: any) => {
                console.error(error);
            });
        } catch (e) {
            console.error(e);
        }
    }

    /**
     * send event to analytics service
     * @param category 
     * @param action 
     * @param label 
     * @param value 
     */
    sendEvent(category: string, action: string, label: string, value: number): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            try {
                if (!this.enable) {               
                    return;
                }
                FirebaseAnalytics.logEvent({
                    name: category,
                    params: {
                        action: action,
                        description: action,
                        label: label,
                        value: value
                    }
                }).then((res: any) => {
                    console.log(res);
                    resolve(true);
                }).catch((error: any) => {
                    console.error(error);
                    resolve(false);
                });
            } catch (e) {
                console.error(e);
                resolve(false);
            }
        });
    }
}
