<div *ngIf="loaded && hasData">
  <ion-grid class="full-w padding-xs">
    <ion-row class="center-items">
      <ion-col size="4">
        <img *ngIf="photoUrl" [src]="photoUrl | safeUrl" alt="Avatar" class="img-avatar-header-large">
      </ion-col>
      <ion-col size="8">
        <ion-row>

          <ion-col size="12">
            <span class="info-text-m bold text-color-primary">{{placeName}}</span>
          </ion-col>
        </ion-row>

        <!-- <ion-row>
          <ion-col size="12">
            <span class="info-text-xs bold bold uppercase text-color-element">
              {{placeType}}
            </span>
          </ion-col>
        </ion-row> -->

        <ion-row>
          <ion-col size="12">
            <ion-button [disabled]="!placeUrl"
              class="as-text element-selector info-text-s text-color-primary text-center" (click)="goToReference()">
              <span class="button-font-size-xs">Website</span>
              <icon-wrapper slot="start" [icon]="appIcons.www" [custom]="true" class="slot-icon-padding">
              </icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row>
          <ion-col size="12">
            <ion-button [disabled]="!mapsUrl"
              class="as-text element-selector info-text-s text-color-primary text-center" (click)="openURL(mapsUrl)">
              <span class="button-font-size-xs">Google Maps</span>
              <icon-wrapper slot="start" [icon]="appIcons.googleMaps" [custom]="true" class="slot-icon-padding">
              </icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>

      </ion-col>
    </ion-row>

    <ion-row *ngIf="descriptionHtml" class="margin-top-s">
      <div [innerHTML]="descriptionHtml" class="info-text-m description-frame text-color-primary"></div>
    </ion-row>
  </ion-grid>
</div>

<div class="content" fxLayout="column" *ngIf="loaded && !hasData">
  <span class="info-text-m margin-top-s text-color-element">
    Place unavailable
  </span>
</div>