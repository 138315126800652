import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { IUserInventory, IUserStatCategory } from 'src/app/classes/def/user/stats';
import { EItemStatus, IGameItemCategoryWithItems, IGameItem, EItemActions, EItemCodes, EItemIapCodes } from 'src/app/classes/def/items/game-item';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { INavBarItem } from 'src/app/classes/def/views/nav';
import { IInventoryReturnItem, IInventoryNavParams } from 'src/app/classes/def/nav-params/inventory';
import { IUserPublicData } from 'src/app/classes/def/user/general';
import { IPaginationContainer } from 'src/app/classes/def/views/pagination';
import { IItemActionButton, IItemActionContainer, IItemAction } from 'src/app/classes/def/items/action';
import { AlertController, PopoverController, Platform, ModalController } from '@ionic/angular';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { IPageResponse } from 'src/app/classes/def/requests/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { AuthRequestService } from 'src/app/services/general/auth-request/auth-request';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { UserStatsDataService } from 'src/app/services/data/user-stats';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { InventoryDataService } from 'src/app/services/data/inventory';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { LocationMonitorService } from 'src/app/services/map/location-monitor';
import { LocationManagerService } from 'src/app/services/map/location-manager';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { PaginationHandlerService } from 'src/app/services/general/ui/pagination-handler';
import { ERouteDef } from 'src/app/app-utils';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { TextToSpeechService } from 'src/app/services/general/apis/tts';
import { IAPUtils } from 'src/app/services/apis/iap-utils';
import { SoundManagerService } from 'src/app/services/general/apis/sound-manager';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { UserDataService } from 'src/app/services/data/user';
import { Router } from '@angular/router';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { PromiseUtils } from 'src/app/services/utils/promise-utils';
import { SupportModalsService } from 'src/app/services/app/modules/minor/support-modals';
import { ECategoryCodes } from 'src/app/classes/def/core/category';
import { IKVStat } from 'src/app/classes/def/app/kval';
import { IStoryResponse } from 'src/app/classes/def/core/story';
import { StoryDataService } from 'src/app/services/data/story';
import { EStoryLocationStatusFlag } from 'src/app/classes/def/nav-params/story';
import { IAPCoreService } from 'src/app/services/apis/iap-rc';
import { ILatLng } from 'src/app/classes/def/map/coords';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-inventory-home',
  templateUrl: './inventory-home.page.html',
  styleUrls: ['./inventory-home.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InventoryHomePage implements OnInit, OnDestroy {
  username: string = "";
  loaded: boolean = false;
  initLoaded: boolean = false;
  userInventory: IUserInventory;
  itemModes = EItemStatus;
  prevCoins: number = null;
  coins: number = 0;
  title: string = "Inventory";
  theme: string = "theme-light theme-light-bg";
  selectedCategoryCode: number = 0;
  platform: IPlatformFlags = {} as IPlatformFlags;
  photoFlagObs;
  photoCounterObs;
  downloadingResources: boolean = false;
  downloadResourcesMessage: string = "";
  downloadResourcesProgress: number = 0;
  rowSpec: number = 2;
  page: number = 0;
  pages: number = 0;
  pageDisp: number = 0;
  category: IGameItemCategoryWithItems;
  categoryList: IGameItemCategoryWithItems[];
  navBarItems: INavBarItem[] = [];
  gameItems: IGameItem[];
  categoryTabsGroupCount: number = 3;
  isRoot: boolean = false;
  currentLocation: ILatLng = null;
  loadSeparateCategoryInit: boolean = true;
  availableItems: boolean = false;
  returnItems: IInventoryReturnItem[] = [];
  leaderId: number = -1;
  leaderboardMode: boolean = false;
  isGroup: boolean = false;
  storyId: number = null;
  xpScoreFactor: number = null;
  leaderDetails: IUserPublicData;
  leaderName: string = "";
  heading: string = "";
  reloadItems: boolean = true;

  pagination: IPaginationContainer = {
    page: 0,
    pages: 1,
    pageDisp: 1,
    hideLeft: true,
    hideRight: false,
    blinkLeft: false,
    blinkRight: false
  };

  fixedCategory: boolean = false;
  wantedItemGenericCode: number = null;
  wantedItemCategoryCode: number = null;
  wantedOnActivate: boolean = false;

  actionButtons: IItemActionButton[] = [
    {
      name: "Add",
      code: EItemActions.buy,
      icon: "add"
    },
    {
      name: "Use",
      code: EItemActions.use,
      icon: "play"
    },
    {
      name: "Drop",
      code: EItemActions.drop,
      icon: "close"
    },
    // {
    //     name: "Cancel",
    //     code: EItemActions.cancel,
    //     icon: "close"
    // },
    // { 
    //     name: "Retry",
    //     code: EItemActions.retry,
    //     icon: ""
    // }
  ];

  np: INavParams = null;
  vs: IViewSpecs;
  isModal: boolean = false;

  prevHeading: string = null;
  updateCheck: boolean = false;
  iapCompleted: boolean = false;
  enableBackButton: boolean = true;

  selectedCategoryInit: boolean = false;


  // stats
  includeScoreStats: boolean = false;
  showIncludeScoreStatsOption: boolean = false;
  userStats: IUserStatCategory = null;
  userStatsTable: IKVStat[] = null;
  userStoryTable: IKVStat[] = null;

  startDate: string = null;
  endDate: string = null;

  constructor(
    public router: Router,
    public authService: AuthRequestService,
    public alertCtrl: AlertController,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public popoverCtrl: PopoverController,
    public userStatsProvider: UserStatsDataService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public analytics: AnalyticsService,
    public settingsProvider: SettingsManagerService,
    public iapService: IAPCoreService,
    public modalCtrl: ModalController,
    public inventory: InventoryDataService,
    public backButton: BackButtonService,
    public locationMonitor: LocationMonitorService,
    public locationManager: LocationManagerService,
    public multiplayer: MPDataService,
    public tutorials: TutorialsService,
    public nps: NavParamsService,
    public paginationHandler: PaginationHandlerService,
    public tts: TextToSpeechService,
    public soundManager: SoundManagerService,
    public userDataService: UserDataService,
    public supportModals: SupportModalsService,
    public storyData: StoryDataService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  ngOnInit() {
    this.selectedCategoryCode = GeneralCache.inventorySelectedCategory;
    this.analytics.trackView("inventory");
    this.paginationHandler.init(this.pagination);
    this.paginationHandler.setBlinkEnabled(true);

    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.inventory, null, this.np);
      console.log("nav params: ", npInfo);
      this.username = GeneralCache.resourceCache.user.general.content.name;
      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
          // this.theme = Constants.theme["aubergine"].css;
          this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
              this.platform = SettingsManagerService.settings.platformFlags;
            }
          }, (err: Error) => {
            console.error(err);
          });
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      if (npInfo.hasParams) {
        let np: INavParams = npInfo.params;
        let params: IInventoryNavParams = np.params;
        console.log(params);

        if (params.leaderboardDetail) {
          this.leaderboardMode = true;
          this.leaderId = params.leaderboardDetail.leaderId;
          this.leaderName = params.leaderboardDetail.leaderName;
          this.isGroup = params.leaderboardDetail.isGroup;
          this.storyId = params.leaderboardDetail.storyId;
          this.xpScoreFactor = params.leaderboardDetail.xpScaleFactor;
          this.leaderDetails = params.leaderboardDetail.details;
          this.startDate = params.leaderboardDetail.startDate;
          this.endDate = params.leaderboardDetail.endDate;
          // if not matching, first category is selected
          this.selectedCategoryCode = 0;
        }

        this.vs = np.view;
        this.isModal = this.vs.isModal;

        this.fixedCategory = params.fixedCategory;

        this.wantedItemGenericCode = params.wantedItemGenericCode;
        this.wantedItemCategoryCode = params.wantedItemCategoryCode;
        this.wantedOnActivate = params.wantedOnActivate;

        this.isRoot = false;

        if (params.fromMapOpened) {
          this.soundManager.ttsCodeWrapper(ETutorialEntries.ttsInventory, true);
        }

      } else {
        this.isRoot = true;
      }

      this.locationManager.getCurrentLocationWrapper(true, true, true).then((location: ILatLng) => {
        console.log("init location: ", location);
        this.currentLocation = location;
        this.init(false, true, false);
      }).catch((err: Error) => {
        console.error(err);
        PromiseUtils.wrapNoAction(this.supportModals.showLocationErrorModal(err), true);
        this.init(false, true, false);
      });

      // should be opened without add to stack param
      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back();
        }, this.vs);
      });

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  init(reload: boolean, showLoading: boolean, forceReloadIAP: boolean) {
    console.log("init");
    this.getUsername();
    this.getUserCoins(reload);
    this.getInventory(reload, showLoading, forceReloadIAP);
  }

  ngOnDestroy() {
    console.log("leaving inventory: ", this.selectedCategoryCode);
    this.photoCounterObs = ResourceManager.clearSub(this.photoCounterObs);
    this.photoFlagObs = ResourceManager.clearSub(this.photoFlagObs);
    // GeneralCache.inventorySelectedPage = this.selectedPage;
    if (!this.leaderboardMode) {
      GeneralCache.inventorySelectedCategory = this.selectedCategoryCode;
    }
    this.paginationHandler.setBlinkEnabled(false);
    this.backButton.checkPop(this.vs);
  }

  /**
   * check heading updated
   * set blink text
   */
  checkHeading() {
    if (this.prevHeading) {
      if (this.heading !== this.prevHeading) {
        this.updateCheck = !this.updateCheck;
      }
    }
    this.prevHeading = this.heading;
  }

  /**
   * get available coins
   * for current/leaderboard user
   */
  getUserCoins(reload: boolean) {
    let checkCoinsFn = () => {
      this.checkIapCoinsUpdated();
      this.checkHeading();
    };
    if (!this.leaderboardMode) {
      this.userStatsProvider.getAllCoins(reload).then((coins: number) => {
        this.coins = coins;
        GeneralCache.resourceCache.user.general.content.cachedCoins = coins;
        this.heading = coins + " LP";
        checkCoinsFn();
      }).catch((err: Error) => {
        this.analytics.dispatchError(err, "inventory");
      });
    } else {
      if (this.leaderDetails != null) {
        this.coins = this.leaderDetails.cachedCoins;
        // this.heading = this.coins + " LP";
        this.heading = this.leaderDetails.name;
        checkCoinsFn();
        // this.userStatsProvider.getAllCoinsForAnotherUser(this.leaderId).then((coins: number) => {
        //     this.coins = coins;
        // }).catch((err: Error) => {
        //     this.analytics.dispatchError(err, "inventory");
        // });
      } else {
        if (this.leaderName) {
          this.heading = this.leaderName;
        }
      }
    }
  }

  selectCategoryFromNavItem(navItem: INavBarItem) {
    this.pagination.page = 0;
    this.pagination.pageDisp = 1;
    if (navItem) {
      this.selectCategoryCore(navItem.value, false, true, false);
    }
  }

  selectCategoryCore(code: number, reload: boolean, showLoading: boolean, forceReloadIAP: boolean) {
    switch (code) {
      case ECategoryCodes.stats:
        this.selectedCategoryCode = code;
        this.getAccountStats();
        break;
      default:
        let categoryIndex: number = this.categoryList.map(c => c.code).indexOf(code);
        this.userStats = null;
        this.userStatsTable = null;
        this.showIncludeScoreStatsOption = false;
        if (categoryIndex !== -1) {
          this.selectCategoryOnInit(reload, this.categoryList[categoryIndex], showLoading, forceReloadIAP).then(() => { }).catch(() => { });
        }
        break;
    }
    this.selectedCategoryInit = true;
  }

  checkPaginationEnabled() {
    return this.userStats == null;
  }

  async getAccountStats() {
    this.loaded = false;
    this.gameItems = null;
    try {
      let respUserStats: IUserStatCategory = await this.multiplayer.getLeaderboardUserStats(this.leaderId, this.isGroup, this.storyId, this.includeScoreStats, this.startDate, this.endDate);
      let userId: number = this.isGroup ? null : this.leaderId;
      let groupId: number = this.isGroup ? this.leaderId : null;
      this.userStats = respUserStats;
      this.userStatsTable = respUserStats != null ? respUserStats.disp : null;
      if (this.includeScoreStats && (this.xpScoreFactor != null)) {
        this.userStatsTable.push({
          name: "score factor",
          value: this.xpScoreFactor,
          valueString: "" + this.xpScoreFactor
        });
      }
      if (this.storyId != null) {
        let respStoryStats: IStoryResponse = await this.storyData.loadStoryProgressOnly(this.storyId, userId, groupId);
        this.userStoryTable = respStoryStats.story.locations.map((loc, index, _array) => {
          let label: string = loc.heading != null ? loc.heading + " (" + (loc.name != null ? ("@" + loc.name) : "UNCHARTED") + ")" : ("@" + loc.name);
          let status: string;
          if (loc.done === 1) {
            status = "done";
          } else {
            if (!loc.started) {
              status = "pending";
            } else {
              if (loc.status != null) {
                switch (loc.status) {
                  case EStoryLocationStatusFlag.started:
                    status = "started";
                    break;
                  case EStoryLocationStatusFlag.failed:
                    status = "failed";
                    break;
                  case EStoryLocationStatusFlag.retry:
                    status = "retry";
                    break;
                  case EStoryLocationStatusFlag.skipped:
                    status = "skipped";
                    break;
                  default:
                    status = "walking";
                    break;
                }
              } else {
                status = "walking";
              }
            }
          }
          return {
            name: (index + 1) + ". " + label,
            value: 0,
            valueString: status
          };
        });
      }
      this.showIncludeScoreStatsOption = true;
      this.loaded = true;
    } catch (err) {
      this.loaded = true;
      this.analytics.dispatchError(err, "account-details");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    }
  }

  selectCategoryOnInit(reload: boolean, category: IGameItemCategoryWithItems, showLoading: boolean, forceReloadIAP: boolean) {
    let promise = new Promise((resolve, reject) => {
      this.selectCategory(category);
      this.loadItems(reload, category, showLoading, forceReloadIAP).then(() => {
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  selectCategory(category: IGameItemCategoryWithItems) {
    let page: number = this.categoryList.map(c => c.code).indexOf(category.code);
    this.selectedCategoryCode = category.code;
    this.selectedCategoryInit = true;
    if (this.pagination.hasBlinked) {
      this.paginationHandler.setBlinkEnabled(false);
    }
    if (page !== -1) {
      this.selectPage(page, false);
    }
  }


  /**
   * load prev/next page for the selected category
   * reload flag is true as there is a single page cached at a time
   * @param delta 
   */
  switchPage(delta: number) {
    this.paginationHandler.setPaginationPage(delta);
    this.selectCategoryOnInit(true, this.category, true, false).then(() => { }).catch(() => { });
  }

  /**
   * load category items
   */
  loadItems(reload: boolean, categoryDef: IGameItemCategoryWithItems, showLoading: boolean, forceReloadIAP: boolean) {
    let promise = new Promise((resolve, reject) => {
      console.log("get inventory category items @", this.currentLocation);
      this.loaded = false;

      let promiseItems: Promise<IPageResponse>;

      if (!this.leaderboardMode) {
        promiseItems = this.inventory.getItemsCategory(reload, categoryDef.code, this.currentLocation, this.pagination.page);
      } else {
        promiseItems = this.multiplayer.getLeaderboardUserInventory(this.leaderId, this.isGroup, this.storyId, categoryDef.code, this.pagination.page);
      }

      promiseItems.then((pageResp: IPageResponse) => {
        // let prevCategory: IGameItemCategory = Object.assign({}, this.category);
        // console.log(pageResp);
        if (pageResp) {
          this.pagination.page = pageResp.page;
          this.pagination.pages = pageResp.pages;
          this.pagination.pageDisp = pageResp.page + 1;

          this.category = DeepCopy.deepcopy(categoryDef);
          this.category.items = pageResp.data.items;

          // console.log(this.category);
          // this.updateItemGrid(this.category);
          this.paginationHandler.setPaginationControls();
          this.formatItems();
          this.loadIapDetails(reload, showLoading, forceReloadIAP);
        } else {
          this.loaded = true;
        }
        resolve(true);
      }).catch((err: Error) => {
        console.error(err);
        this.loaded = true;
        this.analytics.dispatchError(err, "inventory");
        reject(err);
      });
    });
    return promise;
  }

  /**
   * set game items
   * load iap product data
   */
  formatItems(): IGameItem[] {
    if (this.category) {
      this.gameItems = this.category.items;

      for (let i = 0; i < this.gameItems.length; i++) {
        this.gameItems[i].peek = this.category.peek;
      }

      if (this.wantedItemGenericCode != null) {
        this.gameItems = this.gameItems.filter(item => this.checkWantedItem(item));
      }
    }
    return this.gameItems;
  }

  /**
   * load iap details for current products
   * @param reload 
   */
  loadIapDetails(reload: boolean, showLoading: boolean, forceReloadIAP: boolean) {
    // this.loaded = false;
    this.loaded = true;
    showLoading = false; // override, show loading for each item instead
    // check products loading required
    let promiseCheckLoading: Promise<boolean> = new Promise(async (resolve) => {
      let check = IAPUtils.checkItemDataMulti(this.gameItems, reload, false);
      if (!showLoading) {
        resolve(false);
      } else {
        if (check.checkItems.length > 0) {
          await this.uiext.showLoadingV2Queue("Loading products..");
          resolve(true);
        } else {
          resolve(false);
        }
      }
    });

    promiseCheckLoading.then((loading: boolean) => {
      this.iapService.retrieveProductList(this.gameItems, reload, false, forceReloadIAP).then(async () => {
        console.log("iap products loaded: ", this.gameItems);
        this.reloadItems = !this.reloadItems;
        if (loading) {
          await this.uiext.dismissLoadingV2();
        }
        this.loaded = true;
      }).catch(async (err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "inventory");
        if (loading) {
          await this.uiext.dismissLoadingV2();
        }
        this.loaded = true;
      });
    });
  }

  /**
   * check if wanted/required item was selected/bought
   * @param item 
   */
  checkWantedItem(item: IGameItem) {
    if (this.wantedItemGenericCode != null) {
      let gcode: number = item.code;
      if (item.delegateCode != null) {
        gcode = item.delegateCode;
      }
      if (gcode === this.wantedItemGenericCode) {
        return true;
      }
    }
    if (this.wantedItemCategoryCode != null) {
      return this.wantedItemCategoryCode === item.itemCategoryCode;
    }
    return false;
  }

  checkWantedItemCategory(item: IGameItem) {
    if (this.wantedItemCategoryCode != null) {
      return this.wantedItemCategoryCode === item.itemCategoryCode;
    }
    return false;
  }

  /**
   * check if special item was purchased
   * e.g. remove ads
   * @param item 
   */
  checkSpecialItem(item: IGameItem): Promise<boolean> {
    let promise: Promise<boolean> = new Promise(async (resolve, reject) => {
      try {
        switch (item.code) {
          case EItemCodes.removeAds:
            // reload user flags
            await this.uiext.showLoadingV2Queue("Verifying..");
            await this.userDataService.getAllFlagsServer(true);
            await this.uiext.dismissLoadingV2();
            if (this.userDataService.checkAdsDisabledFlag()) {
              this.uiext.showAlertNoAction(Messages.msg.adsRemoved.after.msg, Messages.msg.adsRemoved.after.sub);
            }
            resolve(true);
            break;
          default:
            resolve(false);
            break;
        }
      } catch (err) {
        reject(err);
      }
    });
    return promise;
  }

  selectPage(page: number, loadIap: boolean) {
    if (page != null) {
      this.page = page;
    }
    if (this.categoryList && this.categoryList.length) {
      this.category = this.userInventory ? this.userInventory.categories[page] : null;
    }

    if (!(this.category && this.category.items)) {
      return;
    }

    this.formatItems();
    if (loadIap) {
      this.loadIapDetails(false, true, false);
    }
    // this.updateItemGrid(this.category);
    this.setDisp(page, this.pages);
  }


  setDisp(page: number, _pages: number) {
    this.pageDisp = page;
    this.pageDisp += 1;
  }


  getInventory(reload: boolean, showLoading: boolean, forceReloadIAP: boolean) {
    this.loaded = false;
    console.log("get inventory @", this.currentLocation);
    // this.inventoryHandler.loadSession(reload, false, this.currentLocation).then((resp: IUserInventory) => {

    let promiseHeaders: Promise<IGameItemCategoryWithItems[]>;

    if (!this.leaderboardMode) {
      promiseHeaders = this.inventory.getUserInventoryHeaders(reload, this.currentLocation);
    } else {
      promiseHeaders = this.inventory.getLeaderboardInventoryHeaders(this.leaderId, this.storyId != null);
    }

    promiseHeaders.then((resp: IGameItemCategoryWithItems[]) => {
      // this.userInventory = resp;
      // console.log(this.userInventory);
      this.categoryList = resp;
      let tabs: number = resp.length;

      this.navBarItems = resp.map(cat => {
        let item: INavBarItem = {
          name: cat.name,
          value: cat.code
        };
        return item;
      });

      if (this.leaderboardMode) {
        this.navBarItems.push({
          name: "Stats",
          value: ECategoryCodes.stats
        });
        tabs += 1;
      }

      // max tabs defined initially
      if (tabs > this.categoryTabsGroupCount) {
        tabs = this.categoryTabsGroupCount;
      }

      this.categoryTabsGroupCount = tabs;

      // toggle refresh nav bar
      this.initLoaded = !this.initLoaded;

      // console.log(this.categoryList);

      this.pages = this.categoryList.length;
      this.page = 0;
      this.pageDisp = this.page + 1;
      // this.switchPage(0);
      // console.log("select page: ", this.selectedPage);

      let selectedCategory: IGameItemCategoryWithItems = null;

      let selectedCategoryCode: number = this.selectedCategoryCode;
      let selectedCategoryName: string = "";

      let useFallbackCategory: boolean = false;
      if (!this.leaderboardMode) {
        for (let i = 0; i < this.categoryList.length; i++) {
          if (this.categoryList[i].code === this.selectedCategoryCode) {
            selectedCategory = this.categoryList[i];
            selectedCategoryCode = selectedCategory.code;
            break;
          }
        }
        if (selectedCategoryCode == null) {
          useFallbackCategory = true;
        }
      } else {
        selectedCategoryCode = this.selectedCategoryCode;
        if (selectedCategoryCode == null || !this.selectedCategoryInit) {
          useFallbackCategory = true;
        }
        if (selectedCategoryCode === ECategoryCodes.stats) {
          selectedCategoryName = "Stats";
        }
      }

      if (useFallbackCategory) {
        if (this.categoryList.length) {
          // use first found category as a fallback
          selectedCategory = this.categoryList[0];
          selectedCategoryCode = selectedCategory.code;
          selectedCategoryName = selectedCategory.name;
        }
      }

      console.log("get inventory selected category: ", selectedCategoryCode, selectedCategoryName, selectedCategory);

      if (this.fixedCategory) {
        this.title = selectedCategoryName;
      }

      this.selectCategoryCore(selectedCategoryCode, reload, showLoading, forceReloadIAP);

    }).catch((err: Error) => {
      this.loaded = true;
      console.error(err);
      this.analytics.dispatchError(err, "inventory");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }

  back() {
    if (this.vs && this.vs.isModal) {
      setTimeout(() => {
        this.modalCtrl.dismiss(this.returnItems).then(() => {
        }).catch((err: Error) => {
          console.error(err);
        });
      }, 1);
    } else {
      this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }


  presentPopover() {
    let actions: IPopoverActions = {};
    actions = {
      tutorial: {
        name: "Tutorial",
        code: 1,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      },
      refresh: {
        name: "Reload",
        code: 2,
        icon: EAppIconsStandard.refresh,
        enabled: true
      },
      refreshIAP: {
        name: "Reload Store",
        code: 3,
        icon: EAppIconsStandard.refresh,
        enabled: true
      }
    };

    if (this.showIncludeScoreStatsOption) {
      let actionsExtra: IPopoverActions = {
        includeScoreStats: {
          name: this.includeScoreStats ? "Hide Score" : "Show Score",
          code: 4,
          icon: EAppIconsStandard.clipboard,
          enabled: true
        }
      };
      actions = Object.assign(actions, actionsExtra);
    }

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 1:
          this.tutorials.showTutorialNoAction("Inventory Tutorial", ETutorialEntries.inventoryTutorial, null, null, true);
          break;
        case 2:
          this.init(true, true, false);
          break;
        case 3:
          this.init(true, true, true);
          break;
        case 4:
          this.includeScoreStats = !this.includeScoreStats;
          this.init(true, true, false);
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * keep only the latest action (commit) for a given item
   * remove previous actions so they are not processed in the gmap
   */
  private clearItemHistory(ret: IInventoryReturnItem) {
    let removeItemActionsIdx: number[] = [];
    for (let i = 0; i < this.returnItems.length; i++) {
      if (ret.item.code === this.returnItems[i].item.code) {
        removeItemActionsIdx.push(i);
        break;
      }
    }
    let newReturnItems: IInventoryReturnItem[] = [];
    for (let i = 0; i < this.returnItems.length; i++) {
      if (removeItemActionsIdx.indexOf(i) !== -1) {

      } else {
        newReturnItems.push(this.returnItems[i]);
      }
    }
    // console.log(newReturnItems);
    this.returnItems = newReturnItems;
  }

  itemActionContainer(event: IItemActionContainer) {
    this.itemAction(event.action, event.item);
  }

  itemAction(itemAction: IItemAction, item: IGameItem) {
    // console.log("item action: ", itemAction, " on: ", item);
    if (!itemAction) {
      return;
    }

    let ret: IInventoryReturnItem = {
      item: item,
      action: itemAction.code
    };

    switch (itemAction.code) {
      case EItemActions.use:
        this.inventory.activateInventoryItem(item.code, itemAction.data.qrCode).then(() => {
          this.clearItemHistory(ret);
          this.returnItems.push(ret);
          if (this.wantedOnActivate && this.checkWantedItem(item)) {
            // show message for wanted item
            this.uiext.showAlert(Messages.msg.returnToMapAfterRequiredItemActivated.after.msg,
              Messages.msg.returnToMapAfterRequiredItemActivated.after.sub, 2, null).then((res: number) => {
                switch (res) {
                  case EAlertButtonCodes.ok:
                    this.back();
                    break;
                  default:
                    this.init(true, false, false);
                    break;
                }
              }).catch((err: Error) => {
                console.error(err);
                this.init(true, false, false);
              });
          } else {
            // proceed to update the status
            this.init(true, false, false);
          }
        }).catch((err: Error) => {
          console.error(err);
          this.analytics.dispatchError(err, "inventory");
          this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
        });
        break;
      case EItemActions.buy:
        this.buyItem(item, itemAction.data.amount, itemAction.data.override).then(() => {
          if (this.checkWantedItem(item)) {
            // show message for wanted item
            this.uiext.showAlert(Messages.msg.returnToMapAfterRequiredItemBought.after.msg,
              Messages.msg.returnToMapAfterRequiredItemBought.after.sub, 2, null).then((res: number) => {
                switch (res) {
                  case EAlertButtonCodes.ok:
                    this.returnItems.push(ret);
                    this.back();
                    break;
                  default:
                    this.init(true, false, false);
                    break;
                }
              }).catch((err: Error) => {
                this.init(true, false, false);
                console.error(err);
              });
          } else {
            // proceed to update the status
            this.checkSpecialItem(item).then(() => {
              console.log("check special item returned");
            }).catch((err: Error) => {
              this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, Messages.msg.requestFailed.after.sub);
              console.error(err);
            });

            this.returnItems.push(ret);
            this.init(true, false, false);
          }
        }).catch((err: Error) => {
          console.error(err);
          this.analytics.dispatchError(err, "inventory");
          this.init(true, false, false);
          this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
        });
        break;
      case EItemActions.retry:
        this.retryBuyItem(item).then(() => {
          this.returnItems.push(ret);
          this.init(true, false, false);
        }).catch((err: Error) => {
          console.error(err);
          this.analytics.dispatchError(err, "inventory");
          this.init(true, false, false);
          this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
        });
        break;
      case EItemActions.cancel:
        this.inventory.removePendingInventoryItem(item.code, itemAction.data.amount).then(() => {
          this.returnItems.push(ret);
          this.init(true, false, false);
        }).catch((err: Error) => {
          console.error(err);
          this.analytics.dispatchError(err, "inventory");
          this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
        });
        break;
      case EItemActions.drop:
        // this.inventoryHandler.removeActiveItemFromSession(item).then(() => {
        this.inventory.removeInventoryItem(item.code, itemAction.data.amount).then(() => {
          this.clearItemHistory(ret);
          this.returnItems.push(ret);
          this.init(true, false, false);
        }).catch((err: Error) => {
          console.error(err);
          this.analytics.dispatchError(err, "inventory");
          this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
        });
        break;
      default:
        return;
    }
  }

  openPaginateSelector() {
    this.paginationHandler.openPaginateSelector(() => {
      return this.selectCategoryOnInit(true, this.category, true, false).then(() => { }).catch(() => { });
    });
  }

  swipeEvent(e) {
    if (!this.backButton.handleSwipeEvent(e)) {
      if (e) {
        switch (e.direction) {
          case 2:
            // right to left
            if (!this.pagination.hideRight) {
              this.switchPage(1);
            }
            break;
          case 4:
            // left to right
            if (!this.pagination.hideLeft) {
              this.switchPage(-1);
            }
            break;
        }
      }
    }
  }

  /**
   * check/confirm coins purchased when reloading the inventory
   */
  checkIapCoinsUpdated() {
    if (this.iapCompleted) {
      this.iapCompleted = false;
      if (this.prevCoins != null) {
        if (this.coins > this.prevCoins) {
          let sub: string = Messages.msg.coinsPurchased.after.sub;
          let added: number = this.coins - this.prevCoins;
          // <p>You now have " + this.coins + " LP to buy stuff in the game (previous amount: " + this.prevCoins + " LP)</p>
          sub = "<p>" + added + " LP were added to your account</p>";
          this.uiext.showAlertNoAction(Messages.msg.coinsPurchased.after.msg, sub);
        }
      }
    }
    this.prevCoins = this.coins;
  }


  /**
  * buy item sequence
  * first request save item
  * then request payment with IAP
  * then request confirm item
  * after that, the item is available for use
  * @param code 
  */
  buyItem(item: IGameItem, amount: number, override: boolean): Promise<boolean> {
    return new Promise<boolean>(async (resolve, reject) => {
      try {
        if (override) {
          this.iapCompleted = true;
          resolve(true);
          return;
        }
        if (item.itemIap) {
          console.log("buy with IAP");
          // item.itemIap.playStoreCode = "android.test.purchased";
          if (item.itemIap.code === EItemIapCodes.adminRequest) {
            // not really iap
            // request e.g. coin pass for beta testers
            await this.inventory.buyInventoryItemWithCoins(item.code, amount);
            resolve(true);
          } else {
            await this.uiext.showLoadingV2Queue("Waiting for store");
            // send request to the server to save the pending item
            let res: boolean = await this.iapService.buyItemConsume(item);
            this.iapCompleted = true;
            await this.uiext.dismissLoadingV2();
            if (!res) {
              await this.uiext.showRewardPopupQueue(Messages.msg.orderCancelled.after.msg, Messages.msg.orderCancelled.after.sub, null, false);
            }
            resolve(true);
          }
        } else {
          console.log("buy with coins");
          // send request to the server to save the pending item
          await this.inventory.buyInventoryItemWithCoins(item.code, amount);
          if (item.priceCoins) {
            await this.uiext.showToast("-" + item.priceCoins * amount + " LP", false);
          }
          resolve(true);
        }
      } catch (err) {
        await this.uiext.dismissLoadingV2();
        reject(err);
      }
    });
  }


  retryBuyItem(item: IGameItem): Promise<boolean> {
    // item.itemIap.playStoreCode = "android.test.purchased";
    console.log("retry buy item");
    let promise: Promise<boolean> = new Promise((resolve, reject) => {
      reject(new Error("method not available"));
      // this.iapService.retryBuyItemConsume(item).then(() => {
      //   resolve(true);
      // }).catch((err: Error) => {
      //   console.error(err);
      //   reject(err);
      // });
    });
    return promise;
  }


  getUsername() {
    this.authService.checkLoggedInStorage().then(() => {
      this.authService.getUsername().then((username: string) => {
        if (username) {
          this.username = username;
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

}
