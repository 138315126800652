import { Injectable } from "@angular/core";
import { timer, Observable } from "rxjs";
import { ILeplaceObjectContainer, IPrepareCoinSpecs } from "../../../../classes/def/core/objects";
import { AnalyticsService } from "../../../general/apis/analytics";
import { DirectionsService } from "../../../utils/directions";
import { ResourceManager } from "../../../../classes/general/resource-manager";
import { IWaypointCoordsMulti, IPlaceMarkerContent, IMarkerDetailsOpenContext } from "../../../../classes/def/map/map-data";
import { EMarkerLayers } from "../../../../classes/def/map/marker-layers";
import { MarkerHandlerService } from "../../../map/markers";
import { GeometryUtils } from "../../../utils/geometry-utils";
import { IActivity, IExploreActivityDef } from "../../../../classes/def/core/activity";
import { GameUtils } from "../../../../classes/utils/game-utils";
import { LocationMonitorService } from "../../../map/location-monitor";
import { MapManagerService } from "../../../map/map-manager";
import { IExploreCoinGen, IExploreActivityInit, EExploreObjectDynamics, EExploreModes, EExploreCoinAction, ICoinSpecsMpSyncData, IExploreActivityStatus, IExploreCollectibleParams } from 'src/app/classes/def/activity/explore';
import { EActivityDirectionsMode } from 'src/app/classes/def/map/navigation';
import { SleepUtils } from 'src/app/services/utils/sleep-utils';
import { MPGameInterfaceService } from '../../mp/mp-game-interface';
import { ExploreActivityUtilsService, IExploreCheckCollectReturn } from './explore-utils';
import { VirtualPositionService, IVirtualLocation } from '../virtual-position';
import { PromiseUtils } from "src/app/services/utils/promise-utils";
import { ILatLng } from "src/app/classes/def/map/coords";
import { AppConstants, EAppConstants } from "src/app/classes/app/constants";

@Injectable({
    providedIn: 'root'
})
export class ExploreActivityService {

    // aux
    subscription = {
        navigateToCollectible: null,
        timer: null,
        coinObs: null,
        canClearMap: null
    };

    observable = {
        collectStatus: null
    };

    timeout = {
        initObjectDynamics: null
    };

    currentLocationPos: ILatLng;

    triggerableTimeouts = {
        collectCoinsExpired: null,
        escapeMinTimeLimit: null
    };

    estatusInit: IExploreActivityStatus = {
        distanceTravelled: 0,
        lastPosition: null,
        generateComplete: false,
        chartInitLocation: null,
        minDistanceToAnyObjectInit: null,
        minDistanceToAnyObject: null,
        maxDistanceToAnyObject: null,
        evadeRadiusComputed: null
    };

    estatus: IExploreActivityStatus;

    moduleName: string = "EXPLORE ACTIVITY > ";

    // draw waypoint line (google) 
    headingLine: boolean = true;

    constructor(
        private analytics: AnalyticsService,
        private directions: DirectionsService,
        private markerHandler: MarkerHandlerService,
        private locationMonitor: LocationMonitorService,
        private mapManager: MapManagerService,
        private mpGameInterface: MPGameInterfaceService,
        private exploreUtils: ExploreActivityUtilsService,
        private virtualPositionService: VirtualPositionService
    ) {
        console.log("explore activity service created");
        this.observable = ResourceManager.initBsubObj(this.observable);
        this.resetStatus();
    }

    resetStatus() {
        this.estatus = Object.assign({}, this.estatusInit);
    }

    getEStatus() {
        return this.estatus;
    }

    /**
     * watch explore status update
     * check player position relative to the objects (may be moving objects as well)
     */
    watchCollectStatus() {
        return this.observable.collectStatus;
    }

    init(currentLocation: ILatLng) {
        this.currentLocationPos = currentLocation;
    }

    /**
     * set main entry point for marker callback (linked to map)
     * @param mc 
     */
    setMarkerCallback(mc: (data: IPlaceMarkerContent, context: IMarkerDetailsOpenContext) => any) {
        this.exploreUtils.setMarkerCallback(mc);
    }

    /**
     * enable internal object collect watch
     * @param enabled 
     */
    setAutoCollectInt(enabled: boolean) {
        console.log("set explore auto collect: ", enabled);
        this.exploreUtils.autoCollect = enabled;
    }

    /**
     * start coin collector part of the explore activity
     * watch distance to objects &
     * watch AR collected objects
     */
    startCoinCollector(params: IExploreActivityInit) {
        this.exploreUtils.initSession(params.collectDistance);
        console.log("start coin collector, collect distance: " + this.exploreUtils.getExploreStats().collectDistance);
        if (!this.subscription.navigateToCollectible) {
            this.subscription.navigateToCollectible = this.virtualPositionService.watchVirtualPosition().subscribe((data: IVirtualLocation) => {
                if (this.virtualPositionService.checkNavContext(data, true)) {
                    this.currentLocationPos = data.coords;
                    PromiseUtils.wrapNoAction(this.checkCollect(params), true);
                    if (!this.estatus.lastPosition) {
                        this.estatus.lastPosition = new ILatLng(data.coords.lat, data.coords.lng);
                    }
                    this.estatus.distanceTravelled += GeometryUtils.getDistanceBetweenEarthCoordinates(this.estatus.lastPosition, data.coords, 0);
                    this.estatus.lastPosition = new ILatLng(data.coords.lat, data.coords.lng);
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
        return true;
    }


    /**
     * object dynamics (update objects)
     * @param params 
     */
    startObjectDynamicsGenerator(params: IExploreActivityInit) {
        if (this.subscription.timer != null) {
            console.error("object dynamics already initialized");
            return;
        }

        console.log("start object dynamics generator");
        let mode: number = params.objectDynamics;

        if (!params.targetSpeed) {
            params.targetSpeed = 3.6;
        }

        let speed: number = params.targetSpeed / 3.6; // m/s
        let timer1: Observable<number> = timer(0, 1000);

        this.subscription.timer = timer1.subscribe(() => {
            // update positions of all generated objects
            let userLocation: ILatLng = this.currentLocationPos;
            let distanceDelta: number = speed;
            if (userLocation) {
                let coins: ILeplaceObjectContainer[] = this.exploreUtils.getCoinObjects();
                for (let i = 0; i < coins.length; i++) {
                    let c: ILeplaceObjectContainer = coins[i];
                    let objLocation: ILatLng = c.location;
                    let newObjLocation: ILatLng = null;
                    let cmode: number = this.exploreUtils.getCoinMoveType(c, mode);
                    switch (cmode) {
                        case EExploreObjectDynamics.moveTowardsUser:
                            let move: boolean = true;
                            let moveTarget: ILatLng = userLocation;
                            if (params.evadeRadius != null) {
                                // check distance within the escape zone
                                let distanceDZ: number = GeometryUtils.getDistanceBetweenEarthCoordinates(objLocation, params.placeLocation, 0);
                                if (distanceDZ > params.evadeRadius) {
                                    move = false; // start moving randomly (user not inside circle)
                                    moveTarget = GeometryUtils.getRandomPointInRadius(params.placeLocation, 0, params.evadeRadius * 1.2);
                                }
                            }
                            newObjLocation = GeometryUtils.moveDelta(objLocation, moveTarget, distanceDelta, false);
                            // console.log("new obj location [" + c.object.uid + "]: " + newObjLocation.lat + ", " + newObjLocation.lng);
                            this.exploreUtils.updateCoinPosition(c, newObjLocation);
                            break;
                        case EExploreObjectDynamics.moveAwayFromUser:
                            // check if already collected
                            if (!c.collected) {
                                // check static target
                                let staticTarget: ILatLng = this.exploreUtils.checkStaticTarget();
                                if (!staticTarget) {
                                    // move away from user
                                    newObjLocation = GeometryUtils.moveDelta(objLocation, userLocation, distanceDelta, true);
                                } else {
                                    // move towards static target
                                    newObjLocation = GeometryUtils.moveDelta(objLocation, staticTarget, distanceDelta, false);
                                }
                                this.exploreUtils.updateCoinPosition(c, newObjLocation);
                            }
                            break;
                        case EExploreObjectDynamics.moveOnDirections:
                            break;
                        default:
                            break;
                    }
                }
            }

            let co: ILeplaceObjectContainer[] = this.exploreUtils.getCoinObjects();
            this.markerHandler.syncMarkerArrayNoAction(EMarkerLayers.COINS, co.map(c => c.placeMarker));
            this.markerHandler.syncMarkerArrayNoAction(EMarkerLayers.COINS_CIRCLES, co.map(c => c.placeMarkerCircle));

            // run checker again, as gps connection might be disabled
            PromiseUtils.wrapNoAction(this.checkCollect(params), true);
        }, (err: Error) => {
            console.error(err);
        });
    }

    async checkCollect(params: IExploreActivityInit) {
        let evadeDistance: number = null;
        if (params.evadeRadius != null) {
            if (this.estatus.minDistanceToAnyObjectInit != null) {
                // evadeDistance = params.evadeRadius + this.estatus.minDistanceToAnyObjectInit; // relative to starting point (prevent escape completed when started from farther away)
                // this.estatus.evadeRadiusComputed = evadeDistance;
                evadeDistance = params.evadeRadius; // relative to starting point (prevent escape completed when started from farther away)
                this.estatus.evadeRadiusComputed = evadeDistance;
            }
        }
        let res: IExploreCheckCollectReturn = await this.exploreUtils.checkCollect(params.collectDistance, params.coinCap, evadeDistance, this.currentLocationPos, this.estatus.generateComplete);
        this.estatus.minDistanceToAnyObject = res.minDistanceToAnyObject;
        this.estatus.maxDistanceToAnyObject = res.maxDistanceToAnyObject;

        if (this.estatus.minDistanceToAnyObjectInit == null) {
            if (res.minDistanceToAnyObject != null) {
                this.estatus.minDistanceToAnyObjectInit = res.minDistanceToAnyObject;
                console.log("min distance init computed: ", res.minDistanceToAnyObject);
            }
        }

        // send to gmap for gauge display (evade distance) update
        this.observable.collectStatus.next(true);
    }

    /**
     * collect item from AR view
     * @param object 
     */
    collectFromAR(object: ILeplaceObjectContainer) {
        PromiseUtils.wrapNoAction(this.exploreUtils.collectFromAR(object), true);
    }


    triggerExpired() {
        for (let key of Object.keys(this.triggerableTimeouts)) {
            if (this.triggerableTimeouts[key]) {
                this.triggerableTimeouts[key].trigger();
                this.triggerableTimeouts[key] = null;
            }
        }
    }

    /**
     * main api for movement activity with coins
     */
    generateCoinsMove(params: IExploreActivityInit) {
        console.log("generate coins move");
        // this observes how many coins were generated
        // this.coinCollectObservable.next(null);
        this.exploreUtils.register(null);
        if (params.timeLimit) {
            this.triggerableTimeouts.collectCoinsExpired = ResourceManager.createTriggerableTimeout(() => {
                // no more coins
                console.log(this.moduleName + "time expired");
                this.exploreUtils.register({
                    index: null,
                    amount: null,
                    action: EExploreCoinAction.exception
                });
            }, params.timeLimit * 1000);
        }

        this.estatus.chartInitLocation = Object.assign({}, this.currentLocationPos);
        let initialLocation: ILatLng = this.estatus.chartInitLocation;

        if (params.exploreMode == null) {
            params.exploreMode = EExploreModes.guided;
        }

        let promiseGetWaypoints: Promise<IWaypointCoordsMulti> = new Promise((resolve, reject) => {
            if (params.syncData && params.syncData.waypoints) {
                console.log("using sync data");
                resolve(params.syncData.waypoints);
            } else {
                if (params.fixedCoins != null && (params.fixedCoins.length > 0)) {
                    // get directions to fixed coin
                    console.log("using target fixed coins");
                    let targetCoords: ILatLng = new ILatLng(params.fixedCoins[0].lat, params.fixedCoins[0].lng);
                    this.directions.getDirectionsToTarget(initialLocation, targetCoords, true).then((waypointsMultipleDirections: IWaypointCoordsMulti) => {
                        resolve(waypointsMultipleDirections);
                    }).catch((err) => {
                        reject(err);
                    });
                } else {
                    console.log("using get directions");
                    this.directions.getDirectionsForActivity(
                        params.directionsMode,
                        initialLocation, params.coinRadius,
                        this.headingLine ? this.locationMonitor.getAverageHeading().crt : null).then((waypointsMultipleDirections: IWaypointCoordsMulti) => {
                            resolve(waypointsMultipleDirections);
                        }).catch((err) => {
                            reject(err);
                        });
                }
            }
        });

        promiseGetWaypoints.then((waypointsMultipleDirections: IWaypointCoordsMulti) => {
            // generate coins with directions
            if (!params.randomCoins) {
                params.coinCap = null;
            }
            let preparedCoinSpecs: IPrepareCoinSpecs[] = this.exploreUtils.getPreparedSyncCoins(params.syncData);
            if (!(preparedCoinSpecs && preparedCoinSpecs.length > 0)) {
                preparedCoinSpecs = this.exploreUtils.prepareCoinsWithDirectionsMultiple(initialLocation, params.coinCap, params.minRadius, params.coinRadius, waypointsMultipleDirections, params.coinScope);
            }
            PromiseUtils.wrapNoAction(this.mintCoinMultiplePrepared(preparedCoinSpecs, waypointsMultipleDirections, params.publishSyncData, params.extras), true);
            // this.mintCoinMultipleAsCheckpointNow(initialLocation, waypointsMultipleDirections, params);
        }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "gmap-explore");
            // cannot generate coins if there are no directions for move activity
            // for safety reasons
        });
    }

    waitForGeneratorComplete() {
        let promise = new Promise((resolve, reject) => {
            this.subscription.coinObs = this.exploreUtils.getWatchCoins().subscribe((item: IExploreCoinGen) => {
                if (item && item.action === EExploreCoinAction.generateComplete) {
                    console.log("generator complete");
                    this.subscription.coinObs = ResourceManager.clearSub(this.subscription.coinObs);
                    resolve(true);
                }
            }, (err: Error) => {
                this.subscription.coinObs = ResourceManager.clearSub(this.subscription.coinObs);
                reject(err);
            });
        });
        return promise;
    }

    /**
     * main api for explore activity
     */
    generateCoinsExplore(params: IExploreActivityInit) {
        console.log("generate coins explore");
        // this observes how many coins were generated
        this.exploreUtils.register(null);
        let timeLimitMs: number = params.timeLimit * 1000;
        if (params.timeLimit) {
            console.log("configuring escape time limit: " + params.timeLimit);
            this.triggerableTimeouts.collectCoinsExpired = ResourceManager.createTriggerableTimeout(() => {
                console.log(this.moduleName + "time expired");
                this.exploreUtils.register({
                    index: null,
                    amount: null,
                    action: EExploreCoinAction.exception
                });
            }, timeLimitMs);
            console.log("configuring escape min time limit: " + EAppConstants.escapeMinTimeLimit);
            this.triggerableTimeouts.escapeMinTimeLimit = ResourceManager.createTriggerableTimeout(() => {
                console.log(this.moduleName + "escape min time limit reached");
                this.exploreUtils.register({
                    index: null,
                    amount: null,
                    action: EExploreCoinAction.escapeMinTimeLimit
                });
            }, EAppConstants.escapeMinTimeLimit * 1000);
        }

        this.estatus.chartInitLocation = Object.assign({}, this.currentLocationPos);
        let initialLocation: ILatLng = this.estatus.chartInitLocation;

        this.exploreUtils.resetCoinSpecs();

        if (params.exploreMode == null) {
            params.exploreMode = EExploreModes.guided;
        }
        if (params.exploreMode !== EExploreObjectDynamics.static) {
            this.waitForGeneratorComplete().then(() => {
                this.startObjectDynamicsGenerator(params);
            }).catch((err: Error) => {
                console.error(err);
            });
        }

        if (!params.randomCoins) {
            params.coinCap = null;
        }

        let preparedCoinSpecs: IPrepareCoinSpecs[];
        if (params.fixedCoins && (params.fixedCoins.length > 0)) {
            params.exploreMode = EExploreModes.fixed;
        }

        console.log("generating coins explore params: ", params);

        switch (params.exploreMode) {
            case EExploreModes.free:
                // generate coins the old fashion way
                // params.minRadius, params.radius, params2.coinGenerateTime, params.coinCap, params2.delayArray, params2.delayAdd
                preparedCoinSpecs = this.exploreUtils.getPreparedSyncCoins(params.syncData);
                if (!(preparedCoinSpecs && preparedCoinSpecs.length > 0)) {
                    preparedCoinSpecs = this.exploreUtils.prepareCoinsRandom(initialLocation, params.coinCap, params.minRadius, params.coinRadius, params.coinScope);
                }
                this.exploreUtils.checkUpdateParams(params, preparedCoinSpecs.length);
                // this.mintCoinMultiple(initialLocation, params, params2);
                PromiseUtils.wrapNoAction(this.mintCoinMultiplePrepared(preparedCoinSpecs, null, params.publishSyncData, params.extras), true);
                break;
            case EExploreModes.guided:
                let promiseGetWaypoints: Promise<IWaypointCoordsMulti> = new Promise((resolve, reject) => {
                    if (params.syncData && params.syncData.waypoints) {
                        resolve(params.syncData.waypoints);
                    } else {
                        this.directions.getDirectionsForActivity(
                            params.directionsMode,
                            initialLocation, params.coinRadius,
                            this.headingLine ? this.locationMonitor.getAverageHeading().crt : null).then((waypointsMultipleDirections: IWaypointCoordsMulti) => {
                                resolve(waypointsMultipleDirections);
                            }).catch((err) => {
                                reject(err);
                            });
                    }
                });
                promiseGetWaypoints.then((waypointsMultipleDirections: IWaypointCoordsMulti) => {
                    // generate coins with directions
                    // this.mintCoinWithDirectionsMultiple(initialLocation, params, params2, waypointsMultipleDirections);
                    preparedCoinSpecs = this.exploreUtils.getPreparedSyncCoins(params.syncData);
                    if (!(preparedCoinSpecs && preparedCoinSpecs.length > 0)) {
                        preparedCoinSpecs = this.exploreUtils.prepareCoinsWithDirectionsMultiple(initialLocation, params.coinCap, params.minRadius, params.coinRadius, waypointsMultipleDirections, params.coinScope);
                    }
                    this.exploreUtils.checkUpdateParams(params, preparedCoinSpecs.length);
                    // this.mintCoinMultiple(initialLocation, params, params2);
                    PromiseUtils.wrapNoAction(this.mintCoinMultiplePrepared(preparedCoinSpecs, waypointsMultipleDirections, params.publishSyncData, params.extras), true);
                }).catch((err: Error) => {
                    console.error(err);
                    this.analytics.dispatchError(err, "gmap-explore");
                    // generate coins the old fashion way

                    preparedCoinSpecs = this.exploreUtils.getPreparedSyncCoins(params.syncData);
                    if (!(preparedCoinSpecs && preparedCoinSpecs.length > 0)) {
                        preparedCoinSpecs = this.exploreUtils.prepareCoinsRandom(initialLocation, params.coinCap, params.minRadius, params.coinRadius, params.coinScope);
                    }

                    this.exploreUtils.checkUpdateParams(params, preparedCoinSpecs.length);
                    // this.mintCoinMultiple(initialLocation, params, params2);
                    PromiseUtils.wrapNoAction(this.mintCoinMultiplePrepared(preparedCoinSpecs, null, params.publishSyncData, params.extras), true);
                });
                break;
            case EExploreModes.fixed:
                preparedCoinSpecs = this.exploreUtils.prepareCoinsFixed(params.fixedCoins, params.coinScope);
                this.exploreUtils.checkUpdateParams(params, preparedCoinSpecs.length);
                PromiseUtils.wrapNoAction(this.mintCoinMultiplePrepared(preparedCoinSpecs, null, params.publishSyncData, params.extras), true);
                break;
            default:
                break;
        }
    }

    /**
     * check coins generated
     * generate notifications
     * @param index 
     * @param coinCap 
     */
    checkGenComplete(index: number, coinCap: number) {
        this.exploreUtils.register({
            index: index + 1,
            amount: 1,
            target: coinCap,
            action: EExploreCoinAction.create
        });
        if (index === coinCap - 1) {
            this.exploreUtils.register({
                index: null,
                amount: null,
                target: coinCap,
                action: EExploreCoinAction.generateComplete
            });
            this.estatus.generateComplete = true;
        }
    }

    /**
     * mint/place prepared coins
     * broadcast to MP if enabled
     * @param preparedCoinSpecs 
     * @param waypoints
     */
    async mintCoinMultiplePrepared(preparedCoinSpecs: IPrepareCoinSpecs[], waypoints: IWaypointCoordsMulti, broadcast: boolean, collectibleParams: IExploreCollectibleParams) {
        console.log("mint prepared coins: ", preparedCoinSpecs);

        if (broadcast) {
            console.log("publishing to MP first");
            let syncData: ICoinSpecsMpSyncData = {
                coins: preparedCoinSpecs,
                waypoints: waypoints
            };
            this.mpGameInterface.broadcastCoinSpecs(syncData);
            console.log("now minting coins");
        }

        for (let i = 0; i < preparedCoinSpecs.length; i++) {
            try {
                let pc: IPrepareCoinSpecs = preparedCoinSpecs[i];
                await this.exploreUtils.mintCoinCore(pc.position, pc.specIndex, pc.customParamCode, pc.fixedCoinIndex, false, false, pc, collectibleParams);
                this.checkGenComplete(i, preparedCoinSpecs.length);
            } catch (err) {
                console.error(err);
            }
        }
        await SleepUtils.sleep(500); // seems important to prevent error: layer sync already in progress
        this.exploreUtils.setCollectLock(true);
        await PromiseUtils.wrapResolve(this.exploreUtils.mintPreparedCoins(), true);
        this.exploreUtils.setCollectLock(false);
    }


    stopCoinGenerator() {
        this.triggerableTimeouts.collectCoinsExpired = ResourceManager.clearTriggerableTimeout(this.triggerableTimeouts.collectCoinsExpired);
        this.triggerableTimeouts.escapeMinTimeLimit = ResourceManager.clearTriggerableTimeout(this.triggerableTimeouts.escapeMinTimeLimit);
    }

    stopCoinCollector() {
        this.subscription.navigateToCollectible = ResourceManager.clearSub(this.subscription.navigateToCollectible);
    }

    clearSub() {
        this.subscription = ResourceManager.clearSubObj(this.subscription);
    }


    /**
     * reset collected coins so that there is no possibility of collecting infinite amounts of coins
     * when skipping location or scanning places
     * this should be always called on view unload to clear the subs!
     */
    exitExploreActivity(_clearCoins: boolean = false): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            console.log(this.moduleName + "exit");
            this.stopCoinGenerator();
            this.stopCoinCollector();
            this.clearSub();
            this.mapManager.setExploreRadiusCircleSize(0);
            this.exploreUtils.setCollectRadiusOverride(null);
            await this.exploreUtils.checkClearMap();
            await this.exitExploreActivityCleanup();
            resolve(true);
        });
        return promise;
    }


    async exitExploreActivityCleanup(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            await this.directions.clearDirectionsForActivity();
            this.estatus.lastPosition = null;
            this.estatus.generateComplete = false;
            await this.exploreUtils.clearCoins();
            this.subscription.canClearMap = ResourceManager.clearSub(this.subscription.canClearMap);
            resolve(true);
        });
        return promise;
    }

    /**
     * start coin generator and collector
     * @param params 
     */
    initStage2(params: IExploreActivityInit): IExploreActivityInit {
        if (params.useCheckpoints) {
            params.exploreMode = EExploreModes.guided;
            params.directionsMode = EActivityDirectionsMode.single;
            this.generateCoinsMove(params);
        } else {
            params.exploreMode = EExploreModes.guided;
            params.directionsMode = EActivityDirectionsMode.multi;
            this.generateCoinsExplore(params);
        }

        this.resetStatus();
        this.startCoinCollector(params);
        return params;
    }


    /**
     * initialize the explore provider and the timeout generic activity
     */
    initStage1(params: IExploreActivityInit): IExploreActivityInit {
        params = GameUtils.checkExploreGameItems(params, params.activeInventoryItems) as IExploreActivityInit;
        this.exploreUtils.initSession(params.collectDistance);
        this.mapManager.setExploreRadiusCircleSize(params.collectDistance);
        this.exploreUtils.register(null);
        ResourceManager.broadcastBsubObj(this.observable, null);
        this.init(params.currentLocation);
        return params;
    }

    checkCoinsCollected(activity: IActivity, collected: number): boolean {
        let minRequiredCoins: number = this.getMinRequiredCoins(activity);
        console.log("explore check coins collected: " + collected + "/" + minRequiredCoins);
        if (minRequiredCoins) {
            return collected >= minRequiredCoins;
        } else {
            return false;
        }
    }

    getMinRequiredCoins(activity: IActivity): number {
        let ap: IExploreActivityDef = activity.params;
        // check fixed coins too
        let minRequiredCoins: number = (ap.coinCap != null) ? Math.floor(ap.coinCap / 2) + 1 : null;
        if ((activity.fixedCoins != null) && (activity.fixedCoins.length > 0)) {
            minRequiredCoins = activity.fixedCoins.length;
        }
        return minRequiredCoins;
    }
}


