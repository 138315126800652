import { NgModule } from '@angular/core';
import { MyKeysPipe } from './pipes/keys.pipe';
import { MyValuesPipe } from './pipes/values.pipe';
import { MyDatePipe } from './pipes/date.pipe';
import { MyDate2Pipe } from './pipes/date2.pipe';
import { MyRelativePipe } from './pipes/relative.pipe';
import { MyTimePipe } from './pipes/time.pipe';
import { CommonModule } from '@angular/common';
import { MySafeUrlPipe } from './pipes/safeUrl.pipe';
import { MySafeHtmlPipe } from './pipes/safeHtml.pipe';
import { InteractiveWrapperPipe } from './pipes/interactiveWrapper.pipe';

const pipes = [
  MyKeysPipe,
  MyValuesPipe,
  MyDatePipe,
  MyDate2Pipe,
  MyRelativePipe,
  MySafeUrlPipe,
  MySafeHtmlPipe,
  MyTimePipe,
  InteractiveWrapperPipe
]
@NgModule({
  declarations: pipes,
  imports: [
    CommonModule
  ],
  exports: pipes
})
export class PipesModule { }
