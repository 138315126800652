import { Component, OnInit, OnDestroy, Input, OnChanges, SimpleChanges, SimpleChange, Output, EventEmitter } from '@angular/core';
import { IGroupRole } from 'src/app/classes/def/mp/groups';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { EPhotos } from 'src/app/classes/def/app/icons';

@Component({
  selector: 'group-role-card',
  templateUrl: './group-role-card.component.html',
  styleUrls: ['./group-role-card.component.scss']
})
export class GroupRoleCardComponent implements OnInit, OnDestroy, OnChanges {
  photoLoaded: boolean = false;

  caption: string = "";
  info: string = "";
  self: boolean = false;

  @Input()
  groupRole: IGroupRole;

  @Input()
  selected: boolean;

  @Output()
  onClick: EventEmitter<any> = new EventEmitter();


  bgPhoto: string = null;

  constructor(
    public uiext: UiExtensionService
  ) {

  }

  ngOnInit() {
    // console.log(this.groupRole);
    if (this.groupRole) {
      this.formatGroup();
    }

    console.log("group role card selected: " + this.selected);
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'selected':
            console.log("selected changed");
            break;
        }
      }
    }
  }

  ngOnDestroy() {

  }

  onCheck(ev: Event) {
    console.log("select role");
    ev.preventDefault();
    ev.stopImmediatePropagation();
    ev.cancelBubble = true;
    ev.stopPropagation();
    // Doing other stuff here to control if checkbox should be checked or not!, or just let it be empty!
    this.onClick.emit(this.groupRole);
    return (false);   // you not have to return false , depends of what you doing above like using alertController etc
  }

  formatGroup() {
    if (this.groupRole) {
      this.caption = this.groupRole.name;
      this.self = this.groupRole.self;

      if (!this.groupRole.available) {
        // this.caption += " (locked)";
        this.info = "locked";
      }

      if (!this.groupRole.photoUrl) {
        this.bgPhoto = EPhotos.userPlaceholder;
      } else {
        this.bgPhoto = this.groupRole.photoUrl;
      }
    }
  }
}
