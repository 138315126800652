import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { IGameItem } from 'src/app/classes/def/items/game-item';
import { BehaviorSubject, timer } from 'rxjs';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { IItemAction, IItemActionContainer } from 'src/app/classes/def/items/action';

@Component({
  selector: 'grid-item',
  templateUrl: './grid-item.component.html',
  styleUrls: ['./grid-item.component.scss'],
})
export class GridItemComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  data: IGameItem[];

  @Input()
  emptyMessage: string;

  @Input()
  interact: boolean;

  @Input()
  basicView: boolean;

  @Input()
  blanks: boolean;

  @Input()
  reload: boolean;

  @Input()
  enableLockedDescription: boolean;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  availableItems: boolean = false;
  itemGrid: Array<Array<IGameItem>>;
  rowSpec: number = 2;

  /**
   * sync blink
   * between all items on the grid
   */
  blinkTimerSub: BehaviorSubject<boolean>;

  timerSub: any;

  timeout = {
    blink: null
  };

  constructor(

  ) {
    this.blinkTimerSub = new BehaviorSubject(null);
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'data':
            this.updateItemGrid(this.data);
            break;
        }
      }
    }
  }

  ngOnDestroy() {
    this.disableBlink();
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
  }

  itemAction(itemAction: IItemAction, item: IGameItem) {
    let actionContainer: IItemActionContainer = {
      action: itemAction,
      item: item
    };
    this.action.emit(actionContainer);
  }

  ngOnInit() {
    // console.log(this.data);
    if (this.data) {
      this.updateItemGrid(this.data);
      console.log(this.itemGrid);
      this.setBlink(false);
    }
  }

  /**
   * update item grid for display
   * @param category 
   */
  updateItemGrid(items: IGameItem[]) {
    // console.log("update item grid");
    let rowNum: number = 0; // counter to iterate over the rows in the grid

    this.availableItems = items && (items.length > 0);
    if (!this.availableItems) {
      return;
    }

    this.itemGrid = Array(Math.ceil(items.length / this.rowSpec));

    // add rows
    for (let i = 0; i < items.length; i += this.rowSpec) {
      this.itemGrid[rowNum] = Array(this.rowSpec); // declare two elements per row
      // add items
      for (let j = 0; j < this.rowSpec; j++) {
        if (items[i + j]) {
          this.itemGrid[rowNum][j] = items[i + j];
        } else {
          if (this.blanks) {
            this.itemGrid[rowNum][j] = null;
          } else {
            this.itemGrid[rowNum].splice(j);
          }
        }
      }

      rowNum++; // go on to the next row
    }
  }


  /**
  * start blinking
  * @param timeout 
  */
  setBlink(timeout: boolean) {
    this.enableBlink(timeout ? 5 : 0);
    if (timeout) {
      this.timeout.blink = setTimeout(() => {
        this.disableBlink();
      }, 5500);
    }
  }

  /**
   * start blinking core
   * @param count 
   */
  enableBlink(count: number) {
    if (!this.timerSub) {
      let timer1 = timer(0, 500);
      let flagCount: boolean = ((count != null) && (count > 0));
      let countk: number = count * 2;
      this.timerSub = timer1.subscribe(() => {
        this.blinkTimerSub.next(true);
        if (flagCount) {
          countk -= 1;
          if (countk <= 0) {
            this.disableBlink();
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  /**
   * stop blinking
   */
  disableBlink() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.timeout.blink = ResourceManager.clearTimeout(this.timeout.blink);
  }
}
