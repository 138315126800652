<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div class="padding-s" fxLayout="column">
    <div class="scrollbar-y scroll-content-modal-1" fxLayout="column">
      <div class="padding-top-xs padding-bottom-xs">
        <span class="info-text-m">{{playerName}}</span>
      </div>
      <div [innerHTML]="description"
        class="info-text-m description-frame text-color-primary theme-border-bottom-negative">
      </div>
      <div [innerHTML]="statusDescription"
        class="info-text-m description-frame text-color-primary theme-border-bottom-negative">
      </div>
      <div [innerHTML]="actionDescription"
        class="info-text-m description-frame text-color-primary theme-border-bottom-negative">
      </div>
    </div>

    <div fxFlex></div>

    <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col>
            <ion-button class="action-button-fill button-color-accent" [disabled]="disabled.add || !enable.add"
              (click)="add()">
              <icon-wrapper slot="icon-only" icon="person-add"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button class="action-button-fill button-color-alternate"
              [disabled]="disabled.disable || !enable.disable" (click)="setInactive()">
              <icon-wrapper slot="icon-only" icon="pause"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col>
            <ion-button class="action-button-fill button-color-warn" [disabled]="disabled.remove || !enable.remove"
              (click)="exclude()">
              <icon-wrapper slot="icon-only" [icon]="appIcons.personRemove" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>

</modal-container>