<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss(null)" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div fxLayout="column">
    <!-- <div *ngIf="hasProviders">
          <ion-button  class="action-button-fill button-color-alternate" (click)="viewPlaces()">
              <span>providers</span>
              <icon-wrapper icon="pin"></icon-wrapper>
          </ion-button>
      </div> -->
    <div class="scrollbar-y scroll-content-modal-1-border-flush padding-s scroll-into-view-container"
      (scroll)="onUserScoll($event)" #scrollContent fxLayout="column">

      <div [innerHTML]="storyTitle"
        class="info-text-mlg bold description-frame text-color-primary text-center padding-bottom-s">
      </div>

      <responsive-img *ngIf="story && story.photoUrl" [photoUrl]="story.photoUrl" [withZoom]="false"
        class="border-radius img-small"></responsive-img>

      <div tappable class="text-center padding-top-s padding-bottom-s" (click)="scrollToUnlock()">
        <span class="as-text element-selector button-font-size-mlg text-color-primary">{{descriptionFooter}}</span>
      </div>

      <safe-html-renderer [imageWrapper]="true" [unsafeHtmlContent]="description"
        innerClass="info-text-m description-frame text-color-primary"></safe-html-renderer>

      <div *ngIf="story && story.connectLink" tappable class="text-center padding-top-s padding-bottom-s"
        (click)="openConnectPagePotentialCheckout()">
        <span class="as-text element-selector button-font-size-mlg text-color-primary">{{viewMoreLabel}}</span>
      </div>

      <ion-row class="center-items padding-s" *ngIf="statusDescription">
        <ion-col size="2">
          <icon-wrapper [icon]="appIconsStandard.locked" [custom]="false"></icon-wrapper>
        </ion-col>
        <ion-col [size]="10">
          <div [innerHTML]="statusDescription" class="info-text-m bold description-frame text-color-primary">
          </div>
        </ion-col>
      </ion-row>

      <ion-list *ngIf="hasProviders"
        class="margin-top-s margin-bottom-s list-padding text-color-overlay-flush text-center">
        <span class="info-text-mlg bold text-color-primary">Provided by</span>
        <ion-item *ngFor="let bloc of storyProviders; let i = index;" class="list-item transparent-bg" no-lines>
          <place-story-provider-card class="full-w" *ngIf="bloc" [location]="bloc" tappable [crop]="false"
            (click)="getPlaceDetails(i)">
          </place-story-provider-card>
        </ion-item>
      </ion-list>

      <!-- <ion-row class="center-items">
        <ion-col size="2">
          <icon-wrapper [icon]="appIconsStandard.unlocked" [custom]="false"></icon-wrapper>
        </ion-col>
        <ion-col [size]="10">
          <div [innerHTML]="actionDescription" class="info-text-m bold description-frame text-color-primary">
          </div>
        </ion-col>
      </ion-row> -->

      <div [innerHTML]="actionDescription" class="info-text-m bold description-frame text-color-primary padding-s">
      </div>

      <ion-row class="center-text" *ngIf="storyPhotos">
        <ion-col>
          <span class="info-text-m bold description-frame text-color-primary center-text">In-Game Preview
          </span>
        </ion-col>
      </ion-row>

      <responsive-img *ngIf="storyPhotos" [photoUrl]="storyPhotos[0].photoUrl" [withZoom]="true"
        class="border-radius img-small"></responsive-img>

      <!-- <div class="bootstrap-carousel margin-top-s" *ngIf="storyPhotos">
        <ngb-carousel>
          <ng-template *ngFor="let sp of storyPhotos; let i = index" ngbSlide>
            <div class="picsum-img-wrapper center-text ngb-slider-img-padding">
              <img [src]="sp.photoUrl" class="slide-image-inner-modal">
            </div>
            <div class="carousel-caption">
              <span class="center-text info-text-m bold text-color-primary">{{sp.name}}</span>
            </div>
          </ng-template>
        </ngb-carousel>
      </div> -->

      <!-- <stripe-card *ngIf="useStripeIAPAlways()" [price]="alternativePrice" [promo]="alternativePricePromo"
        (checkout)="checkoutStripe()"></stripe-card> -->

      <div *ngIf="useStripeCheckout && !useStripeIAPAlways()" [innerHTML]="externalCheckoutIndications" tappable
        (click)="openConnectPagePotentialCheckout()"
        class="info-text-m bold description-frame text-color-primary padding-s">
      </div>

      <div *ngIf="unlockCodeRedeemOnly" tappable class="text-center padding-s" (click)="toggleRedeemCodeInput(true)">
        <span class="as-text element-selector button-font-size-mlg text-color-primary">{{descriptionRedeem}}</span>
      </div>

      <ion-row #unlockEntry [ngClass]="inputFocused ? 'scroll-padding' : ''"
        *ngIf="(unlockCodeRequired || unlockCodeRedeemOnlyEnabled) && isUnlockCodeShort">
        <ion-col size="12">
          <div class="border-frame">
            <edit-code [(prop)]="unlockCode" placeholder="" [numeric]="false" [dim]="unlockCodeShortLength"
              [autocaps]="true" (inputComplete)="updateUnlockCode($event)" (focusChange)="textAreaFocused($event)"
              enterKeyPress (enterKeyPress)="enable.buy ? buy($event) : unlockStory($event, false)">
              <inner-content>{{unlockCodeInputLabel}}</inner-content>
            </edit-code>
          </div>
        </ion-col>
      </ion-row>

      <div *ngIf="unlockCodeRedeemOnly" tappable class="text-center padding-s" (click)="openBookingReferenceInput()">
        <span
          class="as-text element-selector button-font-size-mlg text-color-primary">{{descriptionRedeemBooking}}</span>
      </div>

      <ion-row *ngIf="pricePromoDisp" class="center-text">
        <ion-col>
          <span class="info-text-s center-text">{{getPromoDisp()}}</span>
        </ion-col>
      </ion-row>

      <div #unlockButtonTop></div>

    </div>

    <!-- <div *ngIf="inputFocused" class="padding-keyboard"></div> -->

    <div fxFlex></div>
    <div class="stick-bottom theme-border-top">
      <ion-grid class="full-w">
        <ion-row *ngIf="enable.buy">
          <!-- <ion-col *ngIf="hasProviders">
              <ion-button class="action-button-fill button-color-primary-70" (click)="viewPlaces()">
                <icon-wrapper slot="icon-only" icon="pin"></icon-wrapper>
              </ion-button>
            </ion-col> -->
          <ion-col size="12">
            <ion-button class="action-button-fill"
              [ngClass]="{'button-color-accent': proceedHighlight, 'button-color-alternate': !proceedHighlight, 'button-grey': !buttonActive}"
              [disabled]="checkDisabledBuy()" (click)="buy($event)">
              <icon-wrapper *ngIf="!priceDisp" slot="icon-only" icon="basket"></icon-wrapper>
              <icon-wrapper *ngIf="priceDisp" class="slot-icon-padding" slot="start" icon="basket"></icon-wrapper>
              <span *ngIf="unlockCodeRedeemOnlyEnabled" class="button-font-size-s">Redeem </span>
              <span *ngIf="isIAP && !unlockCodeRedeemOnlyEnabled" class="button-font-size-s"
                [innerHTML]="purchaseActionHeader"></span>
              <span *ngIf="priceDisp && !pricePromoDisp && !unlockCodeRedeemOnlyEnabled"
                class="button-font-size-s">({{priceDisp}})</span>
              <span *ngIf="pricePromoDisp && !unlockCodeRedeemOnlyEnabled"
                class="button-font-size-s">({{pricePromoDisp}})</span>
            </ion-button>
          </ion-col>
        </ion-row>

        <ion-row *ngIf="enable.unlock">
          <!-- <ion-col size="12">
            <ion-button class="action-button-fill"
              [ngClass]="{'button-color-accent': proceedHighlight, 'button-color-alternate': !proceedHighlight, 'button-grey': !buttonActive}"
              [disabled]="disabled.unlock" (click)="unlockStory($event)">
              <icon-wrapper *ngIf="!priceDisp" slot="icon-only" [icon]="appIconsStandard.locked"></icon-wrapper>
              <icon-wrapper *ngIf="priceDisp" class="slot-icon-padding" slot="start" [icon]="appIconsStandard.locked">
              </icon-wrapper>
              <span *ngIf="priceDisp" class="button-font-size-s">{{priceDisp}}</span>
            </ion-button>
          </ion-col> -->


          <ion-col size="6">
            <ion-button class="action-button-fill button-color-alternate" [ngClass]="{'button-grey': !buttonActive}"
              [disabled]="disabled.unlock" (click)="unlockStory($event, true)">
              <span class="button-font-size-m">
                Scan
              </span>
              <icon-wrapper slot="start" [icon]="appIcons.qr" [custom]="true" class="slot-icon-padding">
              </icon-wrapper>
            </ion-button>
          </ion-col>

          <ion-col size="6">
            <ion-button class="action-button-fill" [disabled]="disabled.unlock || !isUnlockCode"
              [ngClass]="proceedHighlight ? 'button-color-accent' : 'button-color-alternate'"
              (click)="unlockStory($event, false)">
              <span class="button-font-size-m">
                Enter
              </span>
              <icon-wrapper slot="start" [icon]="appIconsStandard.unlocked" [custom]="false" class="slot-icon-padding">
              </icon-wrapper>
            </ion-button>

            <!-- <ion-button class="action-button-fill"
              [ngClass]="{'button-color-accent': proceedHighlight, 'button-color-alternate': !proceedHighlight, 'button-grey': !buttonActive}"
              [disabled]="disabled.unlock" (click)="unlockStory($event)">
              <icon-wrapper *ngIf="!priceDisp" slot="icon-only" [icon]="appIconsStandard.locked"></icon-wrapper>
              <icon-wrapper *ngIf="priceDisp" class="slot-icon-padding" slot="start" [icon]="appIconsStandard.locked">
              </icon-wrapper>
              <span *ngIf="priceDisp" class="button-font-size-s">{{priceDisp}}</span>
            </ion-button> -->

          </ion-col>


        </ion-row>
      </ion-grid>
    </div>
  </div>
</modal-container>