<div *ngIf="fab">
  <ion-button ion-fab [ngClass]="!state ? fabBtnDefaultClass : fabBtnHighClass"
    [disabled]="disabled || (inCooldown && !autoTriggerMode)" (click)="clicked(0)">
    <icon-wrapper slot="icon-only" [icon]="icon" [custom]="customIcon"></icon-wrapper>
  </ion-button>
</div>
<div *ngIf="!fab">
  <div *ngIf="!iconOnly">
    <ion-button [ngClass]="!state ? btnDefaultClass : btnHighClass"
      [disabled]="disabled || (inCooldown && !autoTriggerMode)" (click)="clicked(0)">
      <span class="button-font-size-s">{{actualName}}</span>
      <icon-wrapper class="slot-icon-padding" slot="start" [icon]="icon" [custom]="customIcon"></icon-wrapper>
    </ion-button>
  </div>
  <div *ngIf="iconOnly">
    <ion-button [ngClass]="!state ? btnDefaultClass : btnHighClass"
      [disabled]="disabled || (inCooldown && !autoTriggerMode)" (click)="clicked(0)">
      <icon-wrapper slot="icon-only" [icon]="icon" [custom]="customIcon"></icon-wrapper>
    </ion-button>
  </div>
</div>