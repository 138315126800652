import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignupTermsPage } from './signup-terms.page';
import { PagesModule } from '../../pages.module';

const routes: Routes = [
  {
    path: '',
    component: SignupTermsPage
  }
];

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forChild(routes)
  ],
  declarations: [SignupTermsPage]
})
export class SignupTermsPageModule {}
