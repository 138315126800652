import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { IGenericEntry } from 'src/app/classes/def/app/entry';

@Component({
  selector: 'plain-entry',
  templateUrl: './plain-entry.component.html',
  styleUrls: ['./plain-entry.component.scss'],
})
export class PlainEntryComponent implements OnInit, OnDestroy {
  descriptionHtml: string;

  @Input()
  data: IGenericEntry;

  @Output()
  action: EventEmitter<boolean> = new EventEmitter();

  constructor(

  ) { }

  ngOnInit() {
    if (this.data) {
      this.descriptionHtml = this.data.description;
    }
  }

  ngOnDestroy() {

  }

  onTapSelect() {
    this.action.emit(true);
  }
}

