import { Injectable } from '@angular/core';
import { ApiDef } from '../../classes/app/api';
// import { File, FileEntry, IWriteOptions, Entry, FileError, RemoveResult } from '@ionic-native/file/ngx';
import { Filesystem, Directory, Encoding, StatResult, WriteFileResult } from '@capacitor/filesystem';
import { Platform } from '@ionic/angular';
import { SettingsManagerService } from '../general/settings-manager';
import { IPlatformFlags } from '../../classes/def/app/platform';
import { EFilePathOption } from 'src/app/classes/def/media/file';
import { WebviewUtilsService } from '../app/utils/webview-utils';

declare var window;


/**
 * handles the file related operations
 * save to file
 * read from file
 * file system path management
 */
@Injectable({
    providedIn: 'root'
})
export class FileManagerService {
    serverUrl: string;
    basePath: string = "";
    savePath: string = "";
    prevInputElement: HTMLInputElement;
    prevOutputElement: HTMLAnchorElement;
    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public plt: Platform,
        public webView: WebviewUtilsService,
        public settingsProvider: SettingsManagerService
    ) {
        console.log("file service created");
        this.serverUrl = ApiDef.mainServerURL;
        this.webView.ready().then(() => {
            console.log("directory: ", Directory);
            this.setBasePath(EFilePathOption.EXT_DATA_DIR);
            this.setSavePath(EFilePathOption.EXT_ROOT_DIR);
            this.settingsProvider.checkSettingsLoaded().subscribe((loaded: boolean) => {
                if (loaded) {
                    this.setBasePath(SettingsManagerService.settings.app.settings.fileBasePath.value);
                    this.setSavePath(SettingsManagerService.settings.app.settings.fileSavePath.value);
                }
            }, (err: Error) => {
                console.error(err);
            });
        }).catch((err: Error) => {
            console.error(err);
        });

        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    getBasePath() {
        return this.basePath;
    }

    setBasePath(option: number) {
        this.basePath = this.getPathFromOption(option);
        console.log("set base path: " + this.basePath);
    }

    setSavePath(option: number) {
        this.savePath = this.getPathFromOption(option);
        console.log("set save path: " + this.savePath);
    }

    private getPathFromOption(option: number) {
        let path: string = "";
        switch (option) {
            case EFilePathOption.APP_DIR:
                path = Directory.Documents;
                break;
            case EFilePathOption.APP_STORAGE_DIR:
                path = Directory.Documents;
                break;
            case EFilePathOption.CACHE_DIR:
                path = Directory.Cache;
                break;
            case EFilePathOption.DATA_DIR:
                path = Directory.Data;
                break;
            case EFilePathOption.DOC_DIR:
                path = Directory.Documents;
                break;
            case EFilePathOption.EXT_APP_STORAGE_DIR:
                path = Directory.ExternalStorage;
                break;
            case EFilePathOption.EXT_DATA_DIR:
                path = Directory.ExternalStorage;
                break;
            case EFilePathOption.EXT_ROOT_DIR:
                path = Directory.External;
                break;
            case EFilePathOption.SHARED_DIR:
                path = Directory.Documents;
                break;
            case EFilePathOption.TEMP_DIR:
                path = Directory.Cache;
                break;
            default:
                path = Directory.Documents;
                break;
        }

        return path;
    }

    /**
     * resolve local filesystem url
     * @param fileURI 
     */
    resolveLfsUrl(fileURI: string) {
        let promise = new Promise((resolve, reject) => {
            if (!this.platform.WEB) {
                // native plugin
                Filesystem.stat({ path: fileURI }).then((res: StatResult) => {
                    resolve(res.uri);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                window.resolveLocalFilesystemUrl(fileURI, (entry: any) => {
                    resolve(entry.nativeURL);
                }, (err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }


    /**
     * write file to default path
     * /leplace
     * @param filename 
     * @param blob 
     */
    writeFileDefaultPath(filename: string, blob: string | Blob | ArrayBuffer): Promise<string> {
        return this.writeFileCore(filename, blob, this.basePath);
    }

    /**
     * write file to save path
     * /leplace
     * @param filename 
     * @param blob 
     */
    writeFileSavePath(filename: string, blob: string | Blob | ArrayBuffer): Promise<string> {
        return this.writeFileCore(filename, blob, this.savePath);
    }


    /**
    * write file to default path
    * /leplace
    * @param filename 
    * @param blob 
    */
    writeFileCore(filename: string, blob: string | Blob | ArrayBuffer, basePath: string): Promise<string> {
        let promise: Promise<string> = new Promise(async (resolve, reject) => {
            try {
                console.log("starting to write the file");
                // The path where the file will be saved
                // var folderpath = "file:///storage/emulated/0/";
                // let filenameFinal: string = Constants.APP_STORAGE_FOLDER + "/" + filename;
                console.log("will write to: ", basePath + filename);
                let data: WriteFileResult = await Filesystem.writeFile({
                    path: filename,
                    data: typeof blob === 'string' ? blob : await this.blobToBase64(blob as any),
                    directory: basePath as any,
                    recursive: true
                });
                // console.log(file);
                console.log("write file done: ", data.uri);
                // resolve(this.basePath + "/" + filenameFinal);
                resolve(data.uri);
            } catch (err) {
                console.warn("write file error");
                reject(err);
            }
        });
        return promise;
    }

    getFileReader(): FileReader {
        const fileReader = new FileReader();
        const zoneOriginalInstance = (fileReader as any)["__zone_symbol__originalInstance"];
        return zoneOriginalInstance || fileReader;
    }    

    private async blobToBase64(blob: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = this.getFileReader();
            reader.onloadend = () => {
                const base64data = reader.result as string;
                resolve(base64data.split(',')[1]);
            };
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    }

    private removeBaseFilePath(basePath: string, filename: string) {
        // "file:///storage/emulated/0/"
        let bpath: string = basePath.replace("file://", "");
        let filename2: string = filename.replace(bpath, "");
        // if(filename2[0] == "/"){
        //     filename2 = filename2.replace()
        // }
        return filename2;
    }

    async readFileContent(filename: string) {
        try {
            console.log("reading file content: ", filename);
            const result = await Filesystem.readFile({
                path: filename,
                directory: this.basePath as any
            });
            return result.data;
        } catch (err) {
            throw err;
        }
    }

    /**
     * remove file from specified base path
     * @param basePath 
     * @param filename 
     */
    removeFile(basePath: string, filename: string) {
        let promise = new Promise((resolve, reject) => {
            let filename2: string = this.removeBaseFilePath(basePath, filename);
            console.log("removing file: ", filename, ", base path: ", basePath, " removed base path: (" + filename2 + ")");
            Filesystem.deleteFile({
                path: filename2,
                directory: this.basePath as any
            }).then(() => {
                console.log("file removed");
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    openURLAsAnchor(url: string) {
        let element: HTMLAnchorElement = document.createElement('a');
        element.setAttribute('href', url);
        if (this.prevOutputElement) {
            document.body.removeChild(this.prevOutputElement);
            this.prevOutputElement = null;
        }
        document.body.appendChild(element);
        this.prevOutputElement = element;
        element.click();
    }

    downloadAsFileViaBrowser(filename: string, type: string, textInput: string) {
        if (!type) {
            type = 'data:text/plain;charset=utf-8,';
        }
        this.downloadAsFileViaBrowserCore(filename, type + encodeURIComponent(textInput));
    }

    downloadAsFileViaBrowserCore(filename: string, encodedInput: string) {
        let element: HTMLAnchorElement = document.createElement('a');
        element.setAttribute('href', encodedInput);
        element.setAttribute('download', filename);
        if (this.prevOutputElement) {
            document.body.removeChild(this.prevOutputElement);
            this.prevOutputElement = null;
        }
        document.body.appendChild(element);
        this.prevOutputElement = element;
        element.click();
        // let cleanup = () => {
        //     this.prevOutputElement = null;
        //     document.body.removeChild(element);
        // };
        // cleanup();
    }

    uploadFileViaBrowser(accept: string, returnDataUrl: boolean, readCallback: (file: Blob) => any) {
        return new Promise<string>((resolve, reject) => {
            let input: HTMLInputElement = document.createElement('input');
            input.id = "uploadFileElementTempInput";
            input.type = "file";
            input.hidden = true;
            input.accept = accept ? accept : ".png, .jpg";
            // <input *ngIf="withUpload" id="fileSelect" type='file' (change)="onSelectFile($event)" style="display: none;">

            if (this.prevInputElement) {
                document.body.removeChild(this.prevInputElement);
                this.prevInputElement = null;
            }

            document.body.appendChild(input);
            this.prevInputElement = input;

            let cleanup = () => {
                this.prevInputElement = null;
                document.body.removeChild(input);
            };

            input.onchange = (event: any) => { // called each time file input changes
                if (event.target.files && event.target.files[0]) {
                    if (returnDataUrl) {                       
                        const reader = this.getFileReader();
                        reader.readAsDataURL(event.target.files[0]); // read file as data url
                        reader.onload = (event: any) => { // called once readAsDataURL is completed
                            let url = event.target.result;
                            cleanup();
                            resolve(url as string);
                        };
                        reader.onerror = (err) => {
                            console.error(err);
                            cleanup();
                            reject(err);
                        };
                    } else {
                        if (readCallback) {
                            readCallback(event.target.files[0]);
                        }
                        cleanup();
                        resolve(null);
                    }
                } else {
                    cleanup();
                    resolve(null);
                }
            };

            input.onerror = (err) => {
                console.error(err);
                cleanup();
                reject(err);
            };

            // input.onfocus = () => {
            //     console.log("onfocus");
            // };
            // input.onblur = () => {
            //     console.log("onblur");
            // };
            // input.oncancel = () => {
            //     console.log("oncancel");
            // };

            input.click();
        });
    }

}




