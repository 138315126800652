import { ILatLng } from 'src/app/classes/def/map/coords';
import { Injectable } from "@angular/core";
import { ILeplaceReg, ILeplaceRegMulti } from "../../classes/def/places/google";
import { BusinessDataService } from "../data/business";
import { PlacesDataService } from "../data/places";
import { IAppLocation, ELocationFlag } from "../../classes/def/places/app-location";
import { LocationApiHelpersService } from "./location-api-helpers";
import { GeometryUtils } from "../utils/geometry-utils";
import { IRegisteredPlaceCheck } from "../../classes/def/places/leplace";
import { BehaviorSubject } from "rxjs";
import { UiExtensionService } from "../general/ui/ui-extension";
import { Messages } from "../../classes/def/app/messages";
import { InventoryWizardService } from "../app/modules/inventory-wizard";
import { IPlaceExtContainer } from "../../classes/def/places/container";
import { EPlaceUnifiedSource, PlaceProvider } from "../../classes/def/places/provider";
import { GenericDataService } from "../general/data/generic";
import { ApiDef } from "../../classes/app/api";
import { IHereMapsRequestNearby, IHereMapsResponseNearby, IHereMapsItem } from "../../classes/def/places/here";
import { LocationUtilsHere } from "./location-utils-here";
import { LocationUtilsGoogle } from "./location-utils-google";
import { AppConstants } from "../../classes/app/constants";
import { EGoogleMapsRequestStatus } from './location-utils-def';
import { EProcessSearchResultsModes } from 'src/app/classes/def/places/search';
import { IBackendLocation } from 'src/app/classes/def/places/backend-location';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { EStoryLocationDoneFlag } from "src/app/classes/def/nav-params/story";
import { IPlaceErrorStat, ILocationScanSpecs } from "./def";
import { PromiseUtils } from "../utils/promise-utils";

@Injectable({
    providedIn: 'root'
})
export class LocationApiService {
    placesService: google.maps.places.PlacesService;
    geocodeService: google.maps.Geocoder;

    resultBuffer: ILeplaceReg[] = [];
    resultBufferOutIndex: number = 0;

    fixedLocationCache: { [key: string]: ILeplaceReg } = {};
    placeErrorStat: IPlaceErrorStat;
    reportedPlaceErrorCache: string[] = [];

    googleStatusObs: BehaviorSubject<number>;

    notifyEnabled: boolean = true;

    lastTimestamp: number = null;

    constructor(
        public businessDataProvider: BusinessDataService,
        public placesDataProvider: PlacesDataService,
        public locationApiHelpers: LocationApiHelpersService,
        public inventoryWizard: InventoryWizardService,
        public uiext: UiExtensionService,
        public gdp: GenericDataService
    ) {
        console.log("location api service created");
        this.googleStatusObs = new BehaviorSubject(null);
    }


    /**
     * subscribe to google status
     * e.g. quota exceeded
     */
    getGoogleStatusObs() {
        return this.googleStatusObs;
    }

    setPlacesService(ps: google.maps.places.PlacesService) {
        this.placesService = ps;
    }

    setGeocodeService(gc: google.maps.Geocoder) {
        this.geocodeService = gc;
    }

    clearResultBuffer() {
        this.resultBuffer = [];
        this.resultBufferOutIndex = 0;
    }

    clearCache() {
        this.fixedLocationCache = {};
    }

    getScanSpecs(placeSpecs: IAppLocation): ILocationScanSpecs {
        let bloc: IBackendLocation = placeSpecs.loc.merged;
        let isFixed: boolean = [ELocationFlag.FIXED].indexOf(bloc.flag) !== -1;
        if (bloc.keepOriginalName) {
            isFixed = true;
        }
        let specs: ILocationScanSpecs = {
            fixedName: isFixed ? bloc.name : null,
            type: bloc.type,
            standardLow: bloc.standardLow,
            standardHigh: bloc.standardHigh,
            googleId: bloc.googleId != null ? bloc.googleId : bloc.googleId,
            providerCode: bloc.providerCode,
            radius: null,
            minDistance: null,
            locationId: bloc.locationId
        };
        return specs;
    }

    /**
     * check fixed location override with story location details
     * @param place 
     * @param placeSpecs 
     */
    checkFixedLocationOverrides(place: ILeplaceReg, placeSpecs: IAppLocation) {
        if (placeSpecs.loc && placeSpecs.loc.merged) {
            let bloc: IBackendLocation = placeSpecs.loc.merged;
            if (bloc.noLink) {
                place.place.photoUrl = bloc.photoUrl;
                place.place.photoUrlSmall = bloc.photoUrlSmall;
                // place.place.googleId = null;
                // googleId is used in multiple places so it breaks things to set it to null
                place.place.name = bloc.name;
                console.log("fixed location override > selected: ", place.place);
            }
            if (bloc.overrideCoords) {
                console.log("fixed location override coords");
                place.place.lat = bloc.lat;
                place.place.lng = bloc.lng;
            }
            if ((bloc.hiddenLocationName === 1) && (bloc.done !== EStoryLocationDoneFlag.done)) {
                if (bloc.tempName == null) {
                    bloc.tempName = place.place.name;
                }
                // place.place.name = "UNCHARTED";
                place.place.name = "HIDDEN";
            } else {
                if (bloc.tempName != null) {
                    // handle locations that were just completed (replace uncharted label with place name)
                    place.place.name = bloc.tempName;
                }
            }
        }
    }

    searchFixedLocation(currentLocation: ILatLng, scanSpecs: ILocationScanSpecs, excludeLocationIdList: string[], fromBuffer: boolean, fromBufferDirection: number, fixed: boolean): Promise<ILeplaceRegMulti> {
        let promise: Promise<ILeplaceRegMulti> = new Promise((resolve, reject) => {
            let promisePlaceScan: Promise<any>;

            let providerCode: number = scanSpecs.providerCode;
            if (providerCode == null) {
                providerCode = EPlaceUnifiedSource.google;
            }

            console.log("search fixed location: " + scanSpecs.googleId);
            this.clearPlaceErrorStat();

            if (this.fixedLocationCache[scanSpecs.googleId]) {
                console.log("from cache");
                let placeResultWrapper: ILeplaceRegMulti = {
                    selected: DeepCopy.deepcopy(this.fixedLocationCache[scanSpecs.googleId]),
                    array: []
                };
                resolve(placeResultWrapper);
                return;
            }

            switch (providerCode) {
                case EPlaceUnifiedSource.google:
                    promisePlaceScan = this.searchFixedLocationCoreGoogle(scanSpecs.googleId);
                    break;
                case EPlaceUnifiedSource.here:
                    promisePlaceScan = this.searchFixedLocationCoreHere(scanSpecs.googleId);
                    break;
                default:
                    promisePlaceScan = Promise.reject(new Error("unknown place provider"));
                    break;
            }

            // this one is free (no scan energy is billed)
            promisePlaceScan.then((puc: IPlaceExtContainer) => {

                if (scanSpecs.fixedName) {
                    puc.name = scanSpecs.fixedName;
                }

                let placeResult: ILeplaceReg = {
                    place: puc,
                    registeredBusiness: false,
                    registeredId: null
                };


                let placeResultWrapper: ILeplaceRegMulti = {
                    selected: placeResult,
                    array: []
                };

                let placeLocation: ILatLng = new ILatLng(puc.lat, puc.lng);
                let overrideSavedLocation: boolean = false;
                if (!fixed) {
                    overrideSavedLocation = (GeometryUtils.getDistanceBetweenEarthCoordinates(currentLocation, placeLocation, 0) > AppConstants.gameConfig.maxDistanceLocationMeters);
                }
                if (overrideSavedLocation) {
                    // location out of range e.g. was in another city when it was saved
                    console.log("location out of range, scanning...");
                    this.searchRandomLocation(currentLocation, scanSpecs, excludeLocationIdList, fromBuffer, fromBufferDirection).then((psx: ILeplaceRegMulti) => {
                        console.log("scan complete");
                        resolve(psx);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    // continue and return fixed location result
                    this.locationApiHelpers.checkRegisteredFixedLocation(puc).then((status: IRegisteredPlaceCheck) => {
                        if (status) {
                            placeResult.registeredBusiness = true;
                            placeResult.registeredId = status.id;
                            placeResultWrapper.selected.registeredBusiness = true; // just to be safe
                        } else {
                            placeResult.registeredBusiness = false;
                            placeResult.registeredId = null;
                            placeResultWrapper.selected.registeredBusiness = false; // just to be safe
                        }
                        this.fixedLocationCache[scanSpecs.googleId] = placeResult;
                        resolve(placeResultWrapper);
                    }).catch((err) => {
                        console.error(err);
                        reject(new Error("Google maps place service error"));
                    });
                }
            }).catch((err: Error) => {
                console.error(err);
                if (this.placeErrorStat != null) {
                    this.placeErrorStat.locationId = scanSpecs.locationId;
                }
                reject(err);
            });
        });
        return promise;
    }

    handlePlaceErrorStat() {
        if (this.placeErrorStat) {
            // send report to developer
            console.log("sending report to developer: ", this.placeErrorStat);
            if (this.reportedPlaceErrorCache.indexOf(this.placeErrorStat.googleId) !== -1) {
                console.warn("already reported");
            } else {
                this.reportedPlaceErrorCache.push(this.placeErrorStat.googleId);
                PromiseUtils.wrapNoAction(this.placesDataProvider.reportPlaceScanError(this.placeErrorStat), true);
            }
        }
        return this.placeErrorStat;
    }

    clearPlaceErrorStat() {
        this.placeErrorStat = null;
    }

    /**
     * search for random locations nearby
     * 
     * @param currentLocation current user location
     * @param scanSpecs app location data
     * @param excludeLocationIdList exclude locations from results (previously visited locations in a story)
     * @param fromBuffer use the places from the previous search buffer
     * @param islocal defines if the story is local or from the server, additional functionality if server story
     */
    searchRandomLocation(currentLocation: ILatLng, scanSpecs: ILocationScanSpecs, excludeLocationIdList: string[], fromBuffer: boolean, fromBufferDirection: number): Promise<ILeplaceRegMulti> {
        let promise: Promise<ILeplaceRegMulti> = new Promise((resolve, reject) => {
            scanSpecs.radius = null;
            console.log("search random location");
            // console.log("search location: ", currentLocation);
            if (fromBuffer && this.resultBuffer.length > 0) {
                console.log("search random location from buffer");
                if (!fromBufferDirection) {
                    fromBufferDirection = 1;
                }
                if (fromBufferDirection < 0) {
                    this.resultBufferOutIndex--;
                    if (this.resultBufferOutIndex < 0) {
                        this.resultBufferOutIndex = this.resultBuffer.length - 1;
                    }
                }
                if (fromBufferDirection > 0) {
                    this.resultBufferOutIndex++;
                    if (this.resultBufferOutIndex > this.resultBuffer.length - 1) {
                        this.resultBufferOutIndex = 0;
                    }
                }

                let result: ILeplaceRegMulti = {
                    selected: this.resultBuffer[this.resultBufferOutIndex],
                    array: this.resultBuffer
                };

                resolve(result);
            } else {
                this.collectRandomLocations(currentLocation, scanSpecs, 2, 1.5, 5, excludeLocationIdList).then((result: ILeplaceRegMulti) => {
                    // console.log(result);
                    if (result !== null) {
                        if (scanSpecs.minDistance != null) {
                            for (let i = 0; i < result.array.length; i++) {
                                let a: ILeplaceReg = result.array[i];
                                if (GeometryUtils.getDistanceBetweenEarthCoordinates(currentLocation, new ILatLng(a.place.lat, a.place.lng), Number.MAX_VALUE) >= scanSpecs.minDistance) {
                                    result.selected = a;
                                    break;
                                }
                            }
                        }
                        resolve(result);
                    } else {
                        reject(new Error("Google maps place service error"));
                    }
                });
            }
        });
        return promise;
    }

    /**
     * search random location recursively on increasing radius until a match is found (location that fits the search criteria)
     * @param currentLocation 
     * @param scanSpecs 
     * @param radius 
     * @param alpha = 2
     * @param beta  = 2
     * @param maxIter = 3 
     */
    collectRandomLocations(currentLocation: ILatLng, scanSpecs: ILocationScanSpecs, alpha: number, beta: number, maxIter: number, excludeLocationIdList: string[]) {
        // call the async function return a promise

        // do not use radius and prominence ranking
        // use distance ranking instead
        if (!scanSpecs.radius) {
            maxIter = 1;
        }

        return this.randomLocation(currentLocation, scanSpecs, excludeLocationIdList).then((result: ILeplaceRegMulti) => {
            scanSpecs.radius = scanSpecs.radius * alpha;
            alpha = alpha * beta;
            maxIter--;
            if ((result !== null) || (maxIter <= 0)) {
                // when satisfied just return the final message
                return result;
            } else {
                // return the promise from the next recursive call with new params
                return this.collectRandomLocations(currentLocation, scanSpecs, alpha, beta, maxIter, excludeLocationIdList);
            }
        }).catch((err: Error) => {
            console.error(err);
            scanSpecs.radius = scanSpecs.radius * alpha;
            alpha = alpha * beta;
            maxIter--;
            if (maxIter <= 0) {
                // when satisfied just return the final message
                return null;
            } else {
                // return the promise from the next recursive call with new params
                return this.collectRandomLocations(currentLocation, scanSpecs, alpha, beta, maxIter, excludeLocationIdList);
            }
        });
    }

    /**
     * consume scan energy before running a place query via ext provider
     */
    consumeScanEnergy() {
        let promise = new Promise((resolve, reject) => {

            this.inventoryWizard.consumeScanEnergyCore(AppConstants.gameConfig.defaultScanEnergy).then((res: boolean) => {
                let promiseRecharge;
                if (!res) {
                    // prevent additional requests while the user is recharging
                    this.googleStatusObs.next(EGoogleMapsRequestStatus.scanEnergyDepleted);
                    promiseRecharge = this.inventoryWizard.tryRechargeWizard();
                } else {
                    promiseRecharge = Promise.resolve(true);
                }

                promiseRecharge.then((res: boolean) => {
                    if (!res) {
                        reject(new Error("Place scan access denied"));
                        return;
                    }
                    resolve(true);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    randomLocation(currentLocation: ILatLng, scanSpecs: ILocationScanSpecs, excludeLocationIdList: string[]): Promise<ILeplaceRegMulti> {
        console.log("random location search radius: " + scanSpecs.radius + ", type: " + scanSpecs.type);
        // implement logic for open now
        // let openNow = true;
        let promise: Promise<ILeplaceRegMulti> = new Promise((resolve, reject) => {
            this.consumeScanEnergy().then(() => {
                let promisePlaceScan: Promise<IPlaceExtContainer[]>;

                let providerCode: number = PlaceProvider.getOneAvailableLocationProviderDefault();

                switch (providerCode) {
                    case EPlaceUnifiedSource.google:
                        promisePlaceScan = this.searchRandomLocationOfTypeGoogle(currentLocation, scanSpecs.type, scanSpecs.radius);
                        break;
                    case EPlaceUnifiedSource.here:
                        promisePlaceScan = this.searchRandomLocationOfTypeHere(currentLocation);
                        break;
                    default:
                        promisePlaceScan = Promise.reject(new Error("unknown place provider"));
                        break;
                }

                promisePlaceScan.then((resultsUnified: IPlaceExtContainer[]) => {
                    let standard = [null, null];
                    if (scanSpecs.standardLow != null) {
                        standard[0] = scanSpecs.standardLow / 100;
                    }
                    if (scanSpecs.standardHigh != null) {
                        standard[1] = scanSpecs.standardHigh / 100;
                    }

                    this.locationApiHelpers.chooseRandomLocation(currentLocation,
                        resultsUnified, standard, excludeLocationIdList, false, EProcessSearchResultsModes.business).then((r: ILeplaceRegMulti) => {
                            this.resultBuffer = r.array;
                            resolve(r);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }



    /**
     * find places nearby suggestion
     * for multiple types
     * with separate request for each type 
     * and results are combined after processing on the server
     * @param currentLocation 
     * @param types 
     */
    findPlacesNearbySuggestionMultiPass(currentLocation: ILatLng, types: string[], limitPerType: number[], mode: number) {
        let promise = new Promise((resolve, reject) => {
            this.findPlacesNearbySuggestionMultiPassCore(currentLocation, types, limitPerType).then((placeResults: IPlaceExtContainer[]) => {
                this.showPlacesDebug(placeResults);
                this.locationApiHelpers.chooseRandomLocation(currentLocation, placeResults, [null, null], [], true, mode).then((r: ILeplaceRegMulti) => {
                    this.resultBuffer = r.array;
                    this.showPlaceResultsDebug(r.array);
                    resolve(r);
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    showPlaceResultsDebug(placeResults: ILeplaceReg[]) {
        let placeNames: string[] = [];
        for (let i = 0; i < placeResults.length; i++) {
            let p: ILeplaceReg = placeResults[i];
            if (p && p.place) {
                placeNames.push(i + ". " + p.place.name + "/" + p.place.googleId + "/" + p.registeredBusiness);
            }
        }
        console.log("place results processed: ", placeNames);
    }

    showPlacesDebug(placeResults: IPlaceExtContainer[]) {
        let placeNames: string[] = [];
        for (let i = 0; i < placeResults.length; i++) {
            let p: IPlaceExtContainer = placeResults[i];
            if (p) {
                placeNames.push(i + ". " + p.name + "/" + p.googleId);
            }
        }
        console.log("place results: ", placeNames);
    }




    /**
     * find places nearby suggestion
     * for multiple types
     * with separate request for each type 
     * and results are combined after processing on the server
     * @param currentLocation 
     * @param types 
     */
    findPlacesNearbySuggestionMultiPassCore(currentLocation: ILatLng, types: string[], limitPerType: number[]): Promise<IPlaceExtContainer[]> {
        let promise: Promise<IPlaceExtContainer[]> = new Promise((resolve, reject) => {
            this.resultBuffer = [];
            this.resultBufferOutIndex = 0;
            if (!types || types.length === 0) {
                reject(new Error("no types specified"));
                return;
            }
            this.consumeScanEnergy().then(() => {
                let providerCode: number = PlaceProvider.getOneAvailableLocationProviderDefault();
                switch (providerCode) {
                    case EPlaceUnifiedSource.google:
                        this.findRandomLocationsGoogle(currentLocation, types, limitPerType).then((data: IPlaceExtContainer[]) => {
                            resolve(data);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                        break;
                    case EPlaceUnifiedSource.here:
                        this.findRandomLocationsHere(currentLocation, types, limitPerType).then((data: IPlaceExtContainer[]) => {
                            resolve(data);
                        }).catch((err: Error) => {
                            reject(err);
                        });
                        break;
                    default:
                        reject(new Error("unknown place provider"));
                        break;
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * notify the user about the possible errors related to google maps services
     * e.g. quota exceeded
     * @param status 
     */
    sendGoogleStatusEvent(status: any) {
        if (this.notifyEnabled) {
            this.notifyEnabled = false;
            let ts: number = new Date().getTime();
            let skip: boolean = false;

            if (this.lastTimestamp === null) {

            } else {
                // do not spam the user with popups if they is less than one second between them
                if ((ts - this.lastTimestamp) < 1000) {
                    skip = true;
                }
                this.lastTimestamp = ts;
            }

            if (skip) {
                return;
            }

            console.log("send google status event: " + status);

            let alert: boolean = false;
            let msg: string;
            let sub: string;
            switch (status) {
                case google.maps.places.PlacesServiceStatus.OK:
                    this.googleStatusObs.next(EGoogleMapsRequestStatus.ok);
                    break;
                case google.maps.places.PlacesServiceStatus.OVER_QUERY_LIMIT:
                    this.googleStatusObs.next(EGoogleMapsRequestStatus.quotaExceeded);
                    msg = Messages.msg.googleQuotaExceeded.after.msg;
                    sub = Messages.msg.googleQuotaExceeded.after.sub;
                    alert = true;
                    break;
                default:
                    this.googleStatusObs.next(EGoogleMapsRequestStatus.error);
                    msg = Messages.msg.googleError.after.msg;
                    sub = Messages.msg.googleError.after.sub;
                    alert = true;
                    break;
            }

            if (alert) {
                this.uiext.showAlert(msg, sub, 1, null).then(() => {
                    this.notifyEnabled = true;
                }).catch(() => {
                    this.notifyEnabled = true;
                });
            }
        }
    }


    /**
     * search by single type
     * @param currentLocation 
     * @param placeSpecs 
     * @param radius 
     */
    private searchRandomLocationOfTypeGoogle(currentLocation: ILatLng, type: string, radius: number): Promise<IPlaceExtContainer[]> {
        let promise: Promise<IPlaceExtContainer[]> = new Promise((resolve, reject) => {
            let resultsUnified: IPlaceExtContainer[] = [];

            // if (placeSpecs.backendLocation.type === Constants.locationType.park) {
            //     openNow = false;
            //     // minPriceLevel = 2; //Valid values range between 0 (most affordable) to 4 (most expensive)
            //     // maxPriceLevel = 4;
            // }

            let minPriceLevel: number = null;
            let maxPriceLevel: number = null;

            let searchOptions: google.maps.places.PlaceSearchRequest = {
                location: currentLocation,
                radius: radius,
                type: type,
                // types: [placeSpecs.backendLocation.type],
                openNow: false,
                rankBy: !radius ? google.maps.places.RankBy.DISTANCE : null, // DISTANCE, PROMINENCE
                minPriceLevel: minPriceLevel, // Valid values range between 0 (most affordable) to 4 (most expensive)
                maxPriceLevel,
                // keyword: null, 
                // A term to be matched against all available fields, including but not limited to name, type, and address, 
                // as well as customer reviews and other third-party content.
            };

            this.placesService.nearbySearch(searchOptions, (results: google.maps.places.PlaceResult[], status) => {
                this.sendGoogleStatusEvent(status);
                if (status === google.maps.places.PlacesServiceStatus.OK) {
                    this.resultBufferOutIndex = 0;
                    this.resultBuffer = [];
                    for (let i = 0; i < results.length; i++) {
                        resultsUnified.push(LocationUtilsGoogle.load(results[i]));
                    }
                    resolve(resultsUnified);
                } else {
                    reject(new Error("Google maps place service error: " + status));
                }
            });
        });
        return promise;
    }

    private searchRandomLocationOfTypeHere(currentLocation: ILatLng): Promise<IPlaceExtContainer[]> {
        let promise: Promise<IPlaceExtContainer[]> = new Promise((resolve, reject) => {
            let options: IHereMapsRequestNearby = {
                app_id: ApiDef.hereMapsAppId,
                app_code: ApiDef.hereMapsAppCode,
                at: currentLocation.lat + "," + currentLocation.lng,
                pretty: true
            };

            let resultsUnified: IPlaceExtContainer[] = [];

            this.gdp.genericGetExt("https://places.cit.api.here.com/places/v1/discover/here", options).then((data: IHereMapsResponseNearby) => {
                for (let i = 0; i < data.results.items.length; i++) {
                    let item: IHereMapsItem = data.results.items[i];
                    resultsUnified.push(LocationUtilsHere.load(item));
                }
                resolve(resultsUnified);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * search by types
     * @param currentLocation 
     * @param types 
     * @param limitPerType 
     */
    private findRandomLocationsHere(currentLocation: ILatLng, types: string[], limitPerType: number[]) {
        let promise = new Promise((resolve, reject) => {
            let options: IHereMapsRequestNearby = {
                app_id: ApiDef.hereMapsAppId,
                app_code: ApiDef.hereMapsAppCode,
                at: currentLocation.lat + "," + currentLocation.lng,
                pretty: true
            };

            console.log(types);
            console.log(limitPerType);

            let resultsUnified: IPlaceExtContainer[] = [];

            this.gdp.genericGetExt("https://places.cit.api.here.com/places/v1/discover/here", options).then((data: IHereMapsResponseNearby) => {
                for (let i = 0; i < data.results.items.length; i++) {
                    let item: IHereMapsItem = data.results.items[i];
                    resultsUnified.push(LocationUtilsHere.load(item));
                }
                resolve(resultsUnified);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * search by types
     * @param currentLocation 
     * @param types 
     * @param limitPerType 
     */
    private findRandomLocationsGoogle(currentLocation: ILatLng, types: string[], limitPerType: number[]) {
        let promise = new Promise((resolve, reject) => {
            let promises = [];
            let googleMapsStatus;
            let resultsUnified: IPlaceExtContainer[] = [];
            for (let i = 0; i < types.length; i++) {
                promises.push((new Promise((resolve) => {
                    let searchOptions: google.maps.places.PlaceSearchRequest = {
                        location: currentLocation,
                        type: types[i],
                        // types: [types[t]],
                        openNow: false,
                        rankBy: google.maps.places.RankBy.DISTANCE
                    };

                    this.placesService.nearbySearch(searchOptions, (results: google.maps.places.PlaceResult[], status) => {
                        this.sendGoogleStatusEvent(status);
                        if (status === google.maps.places.PlacesServiceStatus.OK) {
                            for (let j = 0; (j < results.length && j < limitPerType[i]); j++) {
                                resultsUnified.push(LocationUtilsGoogle.load(results[j]));
                            }
                        } else {
                            googleMapsStatus = status;
                        }
                        resolve(true);
                    });
                })));
            }

            Promise.all(promises).then(() => {
                if (resultsUnified.length === 0) {
                    reject(new Error("Google maps place service error: " + googleMapsStatus));
                    return;
                }
                resolve(resultsUnified);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    private searchFixedLocationCoreGoogle(googleId: string) {
        let promise = new Promise((resolve, reject) => {
            this.placesService.getDetails({
                placeId: googleId
            }, (result: google.maps.places.PlaceResult, status) => {
                this.sendGoogleStatusEvent(status);
                switch (status) {
                    case google.maps.places.PlacesServiceStatus.OK:
                        resolve(LocationUtilsGoogle.load(result));
                        break;
                    case google.maps.places.PlacesServiceStatus.NOT_FOUND:
                    case google.maps.places.PlacesServiceStatus.ZERO_RESULTS:
                    case google.maps.places.PlacesServiceStatus.INVALID_REQUEST:
                        // report missing place to developer
                        this.placeErrorStat = {
                            googleId: googleId,
                            locationId: null,
                            message: status,
                            fallbackAllowed: status !== google.maps.places.PlacesServiceStatus.INVALID_REQUEST
                        };
                        reject(new Error("Google maps place service error: " + status));
                        break;
                    default:
                        reject(new Error("Google maps place service error: " + status));
                        break;
                }
            });
        });
        return promise;
    }

    private searchFixedLocationCoreHere(_hereId: string) {
        let promise = new Promise((_resolve, reject) => {
            reject(new Error("not implemented"));
            return;
        });
        return promise;
    }


    /**
     * get the name of nearby places based on coords
     * @param coords 
     */
    reverseGeocode(coords: ILatLng): Promise<google.maps.GeocoderResult[]> {
        let promise: Promise<google.maps.GeocoderResult[]> = new Promise((resolve, reject) => {
            let req: google.maps.GeocoderRequest = {
                location: {
                    lat: coords.lat,
                    lng: coords.lng
                }
            };
            this.geocodeService.geocode(req, (result: google.maps.GeocoderResult[], status: google.maps.GeocoderStatus) => {
                if (status === google.maps.GeocoderStatus.OK) {
                    resolve(result);
                } else {
                    reject(new Error("Google maps geocode service error: " + status));
                }
            });
        });
        return promise;
    }

}



