import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChange, SimpleChanges } from '@angular/core';
import { timer } from 'rxjs';
import { IProgressBarDisplay } from 'src/app/classes/def/core/activity-manager';
import { ResourceManager } from 'src/app/classes/general/resource-manager';

@Component({
  selector: 'blink-progress-bar',
  templateUrl: './blink-progress-bar.component.html',
  styleUrls: ['./blink-progress-bar.component.scss'],
})
export class BlinkProgressBarComponent implements OnInit, OnChanges, OnDestroy {

  /**
   * blink (absolute)
   */
  @Input()
  set: boolean;
  /**
   * blink (toggle on state change) for a finite amount of time
   */
  @Input()
  toggleTimeout: boolean;
  /**
   * blink (absolute) for a finite amount of time
   */
  @Input()
  setTimeout: boolean;
  /**
   * blink (absolute) for a finite amount of time, on state transition
   */
  @Input()
  setTimeoutOnChange: boolean;

  @Input()
  duration: number;

  @Input()
  config: IProgressBarDisplay;

  @Input()
  enableBlink: boolean;

  blinkEnable: boolean = false;
  state: boolean = false;
  timerSub = null;
  blinkTimeout;
  init: boolean = false;


  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      // console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'set':
            this.blinkEnable = chg.currentValue;
            this.setBlink(false);
            break;
          case 'toggleTimeout':
            // only start blinking
            // the timeout will take care of the rest
            if (!this.blinkEnable) {
              this.blinkEnable = true;
              this.setBlink(true);
            }
            break;
          case 'setTimeout':
            this.blinkEnable = chg.currentValue;
            if (this.blinkEnable) {
              // start blinking and timeout
              this.setBlink(true);
            } else {
              // stop blinking on demand
              this.disableBlink();
            }
            break;
          case 'setTimeoutOnChange':
            this.blinkEnable = true;
            this.disableBlink();
            this.setBlink(true);
            break;
        }
      }
      // const chg: SimpleChange = changes[keys[i]];
      // console.log('prev value: ', chg.previousValue);
      // console.log('got name: ', chg.currentValue);
    }
  }

  ngOnInit() {
    if (this.config != null) {
      // console.log("blink progress bar config: ", this.config);
      if (this.config.blink && this.enableBlink) {
        this.blinkEnable = true;
        this.setBlink(false);
      }
      this.init = true;
    }
  }

  getCustomCss() {
    let css: string = "full-w progress-rounded ";
    if (this.config != null) {
      if (!this.config.animate) {
        css += "progress-no-animation ";
      }
    }
    return css;
  }

  getCustomCssWrapper() {
    let css: string = "";
    if (this.config.blink && this.enableBlink) {
      if (this.config.highlight) {
        css = this.config.highClass != null ? this.config.highClass : "progress-bar-accent";
      } else {
        css = "progress-bar-alternate";
      }
      if (this.state) {
        // highlight
        css = this.config.highlight ? "progress-bar-alternate" : (this.config.highClass != null ? this.config.highClass : "progress-bar-accent");
      }
    } else {
      if (this.config.highlight) {
        css = this.config.highClass != null ? this.config.highClass : "progress-bar-accent";
      } else {
        css = "progress-bar-alternate";
      }
    }
    return css;
  }

  setBlink(timeout: boolean) {
    if (this.blinkEnable) {
      let duration: number = 5000;

      if (this.duration) {
        duration = this.duration;
      }
      let count: number = Math.floor(duration / 1000);

      this.enableBlinkCore(timeout ? count : 0);
      if (timeout) {
        this.blinkTimeout = setTimeout(() => {
          this.blinkEnable = false;
          this.disableBlink();
        }, duration + 500);
      }
    } else {
      this.disableBlink();
    }
  }

  enableBlinkCore(count: number) {
    if (!this.timerSub) {
      let timer1 = timer(0, 500);
      let flagCount: boolean = ((count != null) && (count > 0));
      let countk: number = count * 2;
      this.timerSub = timer1.subscribe(() => {
        this.state = !this.state;
        if (flagCount) {
          countk -= 1;
          if (countk <= 0) {
            this.disableBlink();
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  disableBlink() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.blinkTimeout = ResourceManager.clearTimeout(this.blinkTimeout);
    this.state = false;
  }

  ngOnDestroy() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.blinkTimeout = ResourceManager.clearTimeout(this.blinkTimeout);
  }
}
