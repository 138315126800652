import { Component, OnInit, AfterViewInit, Input, Output, Self, ElementRef, ChangeDetectorRef, HostListener, OnDestroy, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { NgxChartParams } from 'src/app/classes/general/params';
import { EventEmitter } from '@angular/core';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ThemeColors, EChartTheme } from 'src/app/classes/def/app/theme';
import { INgxChartOptions, IChartSeriesContainer } from 'src/app/classes/def/app/charts';
import { DeepCopy } from 'src/app/classes/general/deep-copy';

@Component({
  selector: 'leplace-vertical-gauge',
  templateUrl: './leplace-vertical-gauge.component.html',
  styleUrls: ['./leplace-vertical-gauge.component.scss'],
})
export class LeplaceVerticalGaugeComponent implements OnInit, AfterViewInit, OnDestroy, OnChanges {

  chartOptions: INgxChartOptions = NgxChartParams.getVGaugeOptions();

  hmapData: IChartSeriesContainer[] = [
    {
      name: "Level",
      series: []
    }
  ];

  @Input()
  value: number;

  @Input()
  // start from top/bottom
  inverted: boolean;

  @Input()
  theme: number;

  @Input()
  levels: number;

  @Input()
  chartClass: string;

  @Input()
  options: INgxChartOptions;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  init: boolean = false;

  modalHeight: number;
  modalWidth: number;
  positionLeft: string;
  positionTop: string;

  constructor(
    public uiext: UiExtensionService,
    public settingsProvider: SettingsManagerService,
    @Self() public element: ElementRef,
    public cdr: ChangeDetectorRef
  ) {
    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        let theme: string = SettingsManagerService.settings.app.settings.theme.value;
        this.chartOptions.colorScheme.domain = ThemeColors.theme[theme].vgTheme.default;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'value':
            this.setGauge(this.value);
            break;
          case 'theme':
            this.setTheme(this.theme);
            break;
        }
      }
    }
  }

  initGauge() {
    for (let i = 0; i < this.levels; i++) {
      this.hmapData[0].series.push({
        name: "" + i,
        value: this.levels - i - 1
      });
    }
  }

  setTheme(code: number) {
    console.log("set gauge theme: ", code);
    switch (code) {
      case EChartTheme.default:
        this.chartOptions.colorScheme.domain = ThemeColors.theme.aubergine.vgTheme.default;
        break;
      case EChartTheme.accent1:
        this.chartOptions.colorScheme.domain = ThemeColors.theme.aubergine.vgTheme.accent1;
        break;
      case EChartTheme.accent2:
        this.chartOptions.colorScheme.domain = ThemeColors.theme.aubergine.vgTheme.accent2;
        break;
    }
  }

  setGauge(level: number) {
    console.log("set gauge level: ", level);
    if (level < 0) {
      level = 0;
    }
    if (level > this.levels) {
      level = this.levels;
    }

    if (this.inverted) {
      level = this.levels - level;
    }

    for (let i = 0; i < level; i++) {
      this.hmapData[0].series[i].value = this.inverted ? 0 : 1;
    }
    for (let i = level; i < this.levels; i++) {
      this.hmapData[0].series[i].value = this.inverted ? 1 : 0;
    }

    // charts need an immutable object for change detection
    this.hmapData = [...this.hmapData];
  }

  onSelect(event: any) {
    console.log("hmap chart tap: ", event);
    this.action.emit(event);
  }

  ngAfterViewInit() {
    this.getDimensions();
    this.cdr.detectChanges();
  }

  /**
   * get component dimensions (from parent reference element) and update the chart view frame
   */
  getDimensions() {
    let container = this.element.nativeElement.parentElement;
    this.modalHeight = container.clientHeight;
    this.modalWidth = container.clientWidth;
    if (this.chartOptions.view) {
      this.chartOptions.view[0] = this.modalWidth;
      this.chartOptions.view[1] = this.modalHeight;
      console.log(this.chartOptions.view);
    }
  }

  ngOnInit() {

    if (this.options) {
      // prevent unwanted links when options are provided from the same source, e.g. theme changes
      this.chartOptions = DeepCopy.deepcopy(this.options);
    }

    if (!this.chartClass) {
      this.chartClass = "";
    }

    if (!this.levels) {
      this.levels = 10;
    }

    this.initGauge();

    if (this.value != null) {
      this.setGauge(this.value);
      this.init = true;
    } else {
      this.init = true;
    }

    this.getDimensions();
  }

  ngOnDestroy() {

  }

  @HostListener('window:resize', ['$event'])
  onResize(_event) {
    console.log("resize event");
    this.getDimensions();
    // this.chartOptions.view = [event.target.innerWidth / 2, event.target.innerHeight / 2];
    // console.log(this.chartOptions.view);
  }
}

