
import { ILocationPhotoResult } from "./location-utils-def";
import { IPlacePhotoContainer, IPlaceExtContainer, EPlacePhotoContainerFlag } from "../../classes/def/places/container";
import { ILocationContainer, IBackendLocation } from "../../classes/def/places/backend-location";
import { EPlaceUnifiedSource } from "../../classes/def/places/provider";
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { EStoryLocationDoneFlag } from "src/app/classes/def/nav-params/story";

export class LocationUtilsHelpers {
    static getDefaultPhotoResult() {
        let res: ILocationPhotoResult = {
            photoUrl: null,
            photoLoadedFinal: false,
            photoFound: false
        };
        return res;
    }

    static createNewPhotoContainer() {
        let pc: IPlacePhotoContainer = {
            photoUrl: null,
            flag: EPlacePhotoContainerFlag.empty
        };
        return pc;
    }

    /**
     * merge data from db (e.g. random location, fixed location, saved location data)
     * @param loc 
     */
    static mergeDBData(loc: ILocationContainer) {
        if (!loc.merged) {
            loc.merged = DeepCopy.deepcopy(loc.db);
        } else {
            loc.merged = DeepCopy.deepcopy(loc.db);
        }
    }

    /**
     * merge data from external provider
     * @param loc 
     */
    static mergeExtData(loc: ILocationContainer) {
        loc.merged.name = loc.ext.name;
        loc.merged.rating = loc.ext.rating;
        loc.merged.lat = loc.ext.lat;
        loc.merged.lng = loc.ext.lng;
        loc.merged.googleId = loc.ext.googleId;
        loc.merged.providerCode = loc.ext.providerCode;
        loc.merged.photoUrl = loc.ext.photoUrl;
        loc.merged.photoUrlSmall = loc.ext.photoUrlSmall;
        loc.extLoaded = true;
        return loc;
    }

    /**
     * update ext photo from photo result
     * @param loc 
     * @param p 
     */
    static updateExtPhoto(loc: ILocationContainer, p: ILocationPhotoResult) {
        loc.ext.photoUrl = p.photoUrl;
        loc.ext.photoUrlSmall = p.photoUrl;
        loc.ext.photoLoadedFinal = p.photoLoadedFinal;
        return loc;
    }

    static createNewPlaceExtContainer() {
        let pext: IPlaceExtContainer = {
            id: -1,
            providerCode: EPlaceUnifiedSource.google,
            rating: null,
            googleId: "",
            photoUrl: null,
            isGenericPhotoLoaded: false,
            name: "",
            type: "place",
            flag: 0,
            lat: null,
            lng: null,
        };
        return pext;
    }

    static createNewLocationContainer() {
        let pc: IPlacePhotoContainer = LocationUtilsHelpers.createNewPhotoContainer();
        let bloc: IBackendLocation = {
            id: -1,
            providerCode: EPlaceUnifiedSource.google,
            url: null,
            rating: null,
            locationId: - 1,
            googleId: "",
            photoUrl: null,
            name: "",
            heading: "",
            type: "place",
            flag: 0,
            activity: null,
            samePlace: null,
            done: EStoryLocationDoneFlag.pending,
            dynamic: 1,
            lat: null,
            lng: null,
            locationType: null,
            timestamp: null
        };

        let loc: ILocationContainer = {
            ext: LocationUtilsHelpers.createNewPlaceExtContainer(),
            db: Object.assign({}, bloc),
            dispPhoto: Object.assign({}, pc),
            extLoaded: false,
            index: null,
            merged: Object.assign({}, bloc)
        };
        return loc;
    }

}
