import { Injectable } from "@angular/core";
import { IRemotePackage } from "@ionic-native/code-push/ngx";
import { UiExtensionService } from "../ui/ui-extension";
import { IUpdatingAppNavParms } from "../../../classes/def/app/liveupdate";
import { LiveUpdateCoreService } from "./live-update-core";
import { UpdatingAppViewComponent } from 'src/app/modals/app/modals/updating-app/updating-app.component';


@Injectable({
    providedIn: 'root'
})
export class LiveUpdateService {
    constructor(
        public uiext: UiExtensionService,
        public liveUpdateCore: LiveUpdateCoreService
    ) {
        console.log("live update service created");
    }

    /**
     * run full update sequence no action
     */
    runFullUpdateSequenceNoAction() {
        // check for code push updates i.e. minor updates only
        this.liveUpdateCore.checkUpdateAddToCache().then((available: boolean) => {
            if (available) {
                this.initUpdate();
            }
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * init update process from cached package
     */
    initUpdate() {
        if (this.liveUpdateCore.package) {
            this.initUpdateCore().then(() => { }).catch(() => { });
        }
    }

    /**
     * init update process from cached package
     * returns promise (maybe the user cancelled the update)
     */
    initUpdateCore(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            if (this.liveUpdateCore.package) {
                this.showUpdateModalCore(null, null, this.liveUpdateCore.package).then((res: boolean) => {
                    console.log("show update modal resolved: ", res);
                    resolve(res);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                resolve(false);
            }
        });
        return promise;
    }

    /**
     * show update modal with progress, status bar
     * @param statusObservable 
     * @param progressObservable 
     */
    showUpdateModalCore(statusObservable: any, progressObservable: any, package1: IRemotePackage) {
        let promise = new Promise((resolve, reject) => {
            let modalParams: IUpdatingAppNavParms = {
                title: "LP Update",
                infoHTML: "",
                statusObservable: statusObservable,
                progressObservable: progressObservable,
                package1: package1
            };

            // not added to stack = cannot dismiss
            this.uiext.showCustomModal(null, UpdatingAppViewComponent, {
                view: {
                    fullScreen: false,
                    transparent: false,
                    large: true,
                    // prevent back button dismiss
                    addToStack: false,
                    frame: false
                },
                params: modalParams
            }).then((restart: boolean) => {
                console.log("returned from update app modal");
                if (restart) {
                    this.liveUpdateCore.restartApp();
                    // do not resolve, wait for the app to restart
                } else {
                    resolve(true);
                }
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }
}




