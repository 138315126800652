import { Component, OnInit, Output, EventEmitter, Input, OnDestroy, SimpleChange, SimpleChanges } from '@angular/core';
import { JoystickEvent } from 'ngx-joystick';
import { JoystickManagerOptions, JoystickOutputData, JoystickManager, EventData, create, Joystick, Position } from 'nipplejs';
import { EFeatureColor } from 'src/app/classes/def/app/theme';
import { ResourceManager } from 'src/app/classes/general/resource-manager';

export interface IJoystickStatusUpdate {
  outputData: JoystickOutputData;
  status: boolean;
}

export interface IJoystickPosition {
  x: number;
  y: number;
  distance?: number;
  heading?: number;
  headingDeg?: number;
  headingComp?: number;
}

@Component({
  selector: 'leplace-joystick-ngx',
  templateUrl: './joystick-ngx.component.html',
  styleUrls: ['./joystick-ngx.component.scss'],
})
export class JoystickNgxComponent implements OnInit, OnDestroy {
  newApi: boolean = false;

  // @ViewChild('staticJoystic', { static: false }) staticJoystick: NgxJoystickComponent;

  @Input()
  color: string;

  @Input()
  alternate: boolean;

  @Output()
  action: EventEmitter<IJoystickStatusUpdate> = new EventEmitter();

  @Input()
  position: IJoystickPosition;

  @Input()
  updatePosition: boolean;

  staticOptions: JoystickManagerOptions = {
    mode: 'static',
    position: { left: '50%', top: '50%' },
    color: EFeatureColor.leplaceBlue,
    size: 160
  }

  staticOutputData: JoystickOutputData;

  status: IJoystickStatusUpdate = {
    outputData: null,
    status: false
  };

  directionStatic: string;
  interactingStatic: boolean;

  init: boolean = false;

  jsManager: JoystickManager;

  samplingTs: number = 0;
  samplingTimeout: any;

  constructor() { }

  ngOnInit() {

    let color: string = this.color;

    if (this.alternate) {
      color = EFeatureColor.action3;
    }

    if (color != null) {
      this.staticOptions.color = color;
    }

    setTimeout(() => {
      this.init = true;
      setTimeout(() => {
        this.createJoystick();
      }, 100);
    }, 400);
  }

  ngOnDestroy() {
    ResourceManager.clearTimeout(this.samplingTimeout);
    if (this.jsManager) {
      this.jsManager.destroy();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'updatePosition':
            if (!this.position) {
              this.setPositionDistanceHeading(0, 0);
            } else {
              this.setPositionDistanceHeading(this.position.distance, this.position.heading);
            }
            break;
        }
      }
    }
  }

  createJoystick() {
    // create joystick
    let optionsExt: JoystickManagerOptions = {
      zone: document.getElementById('zone')
    };
    let optionsCreate = Object.assign(this.staticOptions, optionsExt);
    this.jsManager = create(optionsCreate);

    // init handlers
    this.jsManager.on("start", (evt: EventData, data: JoystickOutputData) => {
      this.interactingStatic = true;
    });

    this.jsManager.on("end", (evt: EventData, data: JoystickOutputData) => {
      this.staticOutputData = data;
      this.interactingStatic = false;
      this.wrapStatus();
    });

    this.jsManager.on("move", (evt: EventData, data: JoystickOutputData) => {
      this.staticOutputData = data;
      this.wrapStatus();
    });

    // update style
    let zone = document.getElementById('zone');
    let front: any = zone.querySelector('.front');
    front.style.border = "2px solid black";
    let back: any = zone.querySelector('.back');
    back.style.border = "2px solid black";
  }

  wrapStatus() {
    this.status.outputData = this.staticOutputData;
    this.status.status = this.interactingStatic;

    if (!this.staticOutputData) {
      return;
    }
    // rate limiter, keep last command at the end of the pipe
    let ts: number = new Date().getTime();
    let rateLimit: number = 50;
    if (!this.samplingTs || ((ts - this.samplingTs) >= rateLimit)) {
      // rate limiter
      this.samplingTs = ts;
      this.samplingTimeout = ResourceManager.clearTimeout(this.samplingTimeout);
      this.action.emit(this.status);
    } else {
      // implicit queue / emit last command
      this.samplingTimeout = setTimeout(() => {
        this.action.emit(this.status);
      }, rateLimit);
    }
  }

  onStartStatic(event: JoystickEvent) {
    this.interactingStatic = true;
  }

  onEndStatic(event: JoystickEvent) {
    this.interactingStatic = false;
    this.wrapStatus();
  }

  onMoveStatic(event: JoystickEvent) {
    // console.log("static output data: ", event.data);
    this.staticOutputData = event.data as any;
    this.wrapStatus();
  }

  onPlainUpStatic(event: JoystickEvent) {
    this.directionStatic = 'UP';
  }

  onPlainDownStatic(event: JoystickEvent) {
    this.directionStatic = 'DOWN';
  }

  onPlainLeftStatic(event: JoystickEvent) {
    this.directionStatic = 'LEFT';
  }

  onPlainRightStatic(event: JoystickEvent) {
    this.directionStatic = 'RIGHT';
  }

  setPositionDistanceHeading(distance: number, heading: number) {
    if (heading == null || distance == null) {
      return;
    }
    // Convert angle from degrees to radians
    // const angleRadians = (heading * Math.PI) / 180;
    const angleRadians = heading; // already provided in radians

    // Calculate the actual distance as a fraction of the maximum half-width/height S/2
    const actualDistance = (this.staticOptions.size / 2) * distance;

    // Calculate new x and y using the polar to Cartesian conversion
    const x = actualDistance * Math.cos(angleRadians);
    const y = actualDistance * Math.sin(angleRadians);

    this.setPosition(x, y);
  }

  setPosition(x: number, y: number) {
    if (!this.newApi) {
      return;
    }
    let joystick: Joystick = this.jsManager.get(0);
    console.log("set position for joystick: ", joystick);
    let position: Position = {
      x: x,
      y: y
    }
    joystick.setPosition((joystick: Joystick) => {
      console.log("set position done");
    }, position);
  }
}
