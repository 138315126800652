
import { NgModule } from '@angular/core';
import { ActivityDetailsComponent } from './components/activity-details/activity-details.component';
import { ActivityDetailsEntryComponent } from './components/activity-details-entry/activity-details-entry.component';
import { ActivityParamComponent } from './components/activity-param/activity-param.component';
import { ActivityParamDetailsComponent } from './components/activity-param-details/activity-param-details.component';
import { ActivityProgressDataComponent } from './components/activity-progress-data/activity-progress-data.component';
import { BusinessFeaturesComponent } from './components/business-features/business-features.component';
import { CategoryInfoComponent } from './components/category-info/category-info.component';
import { ChatDotsComponent } from './components/chat-dots/chat-dots.component';
import { FeatureInfoComponent } from './components/feature-info/feature-info.component';
import { GameItemComponent } from './components/game-item/game-item.component';
import { GroupCardComponent } from './components/group-card/group-card.component';
import { KVTableComponent } from './components/kv-table/kv-table.component';
import { LeaderCardComponent } from './components/leader-card/leader-card.component';
import { LeplacePolarChartComponent } from './components/leplace-polar-chart/leplace-polar-chart.component';
import { LocationDotsComponent } from './components/location-dots/location-dots.component';
import { LocationDotsVComponent } from './components/location-dots-v/location-dots-v.component';
import { LocationInfoComponent } from './components/location-info/location-info.component';
import { MinimapHudComponent } from './components/minimap-hud/minimap-hud.component';
import { MpLeaderCardComponent } from './components/mp-leader-card/mp-leader-card.component';
import { MpUserCardComponent } from './components/mp-user-card/mp-user-card.component';
import { NewsfeedEntryComponent } from './components/newsfeed-entry/newsfeed-entry.component';
import { PlaceCardComponent } from './components/place-card/place-card.component';
import { PlaceItemsCardComponent } from './components/place-items-card/place-items-card.component';
import { SoundButtonComponent } from './components/sound-button/sound-button.component';
import { StoryInfoComponent } from './components/story-info/story-info.component';
import { TreasureSpecCardComponent } from './components/treasure-spec-card/treasure-spec-card.component';
import { EventInfoComponent } from './components/event-info/event-info.component';
import { GridActivityParamComponent } from './components/grid-activity-param/grid-activity-param.component';
import { GridItemComponent } from './components/grid-item/grid-item.component';
import { ComponentsModule } from '../components.module';
import { AppComponentsGenericModule } from '../generic/generic-components.module';
import { DynamicSlidesComponent } from './components/dynamic-slides/dynamic-slides.component';
import { DynamicContentComponent } from './components/dynamic-content/dynamic-content.component';
import { DynamicGridComponent } from './components/dynamic-grid/dynamic-grid.component';
import { SponsorCardComponent } from './components/sponsor-card/sponsor-card.component';
import { GroupRoleCardComponent } from './components/group-role-card/group-role-card.component';
import { DynamicListComponent } from './components/dynamic-list/dynamic-list.component';
import { GameItemDispComponent } from './components/game-item-disp/game-item-disp.component';
import { GroupMemberCardComponent } from './components/group-member-card/group-member-card.component';
import { SupportCategoryComponent } from './components/support-category/support-category.component';
import { MpStatusCardComponent } from './components/mp-status-card/mp-status-card.component';
import { LeplaceVerticalGaugeComponent } from './components/leplace-vertical-gauge/leplace-vertical-gauge.component';
import { QuestItemComponent } from './components/quest-item/quest-item.component';
import { GroupMemberItemComponent } from './components/group-member-item/group-member-item.component';
import { GridGroupMemberComponent } from './components/grid-group-member/grid-group-member.component';
import { LeaderCardExtComponent } from './components/leader-card-ext/leader-card-ext.component';
import { LocationDotsExtComponent } from './components/location-dots-ext/location-dots-ext.component';
import { StoryInfoSpecComponent } from './components/story-info-spec/story-info-spec.component';
import { PhotoItemComponent } from './components/photo-item/photo-item.component';
import { LeplaceBeaconComponent } from './components/leplace-beacon/leplace-beacon.component';
import { LeplaceXYChartComponent } from './components/leplace-xy-chart/leplace-xy-chart.component';
import { LocationInfoCoreComponent } from './components/location-info-core/location-info-core.component';
import { WeatherInfoComponent } from './components/weather-info/weather-info.component';
import { ChatHeadsSliderComponent } from './components/chat-heads-slider/chat-heads-slider.component';
import { StoryInfoPromoComponent } from './components/story-info-promo/story-info-promo.component';
import { StripeCardComponent } from './components/stripe-card/stripe-card.component';
import { PlaceStoryProviderCardComponent } from './components/place-story-provider-card/place-story-provider-card.component';
import { GridPhotoUploadsComponent } from './components/grid-photo-uploads/grid-photo-uploads.component';
import { PhotoUploadDispComponent } from './components/photo-upload-disp/photo-upload-disp.component';
import { AudioGuideComponent } from './components/audio-guide/audio-guide.component';

const components = [
  ActivityDetailsComponent,
  ActivityDetailsEntryComponent,
  ActivityParamComponent,
  ActivityParamDetailsComponent,
  ActivityProgressDataComponent,
  AudioGuideComponent,
  LeplaceVerticalGaugeComponent,
  LeplaceXYChartComponent,
  BusinessFeaturesComponent,
  CategoryInfoComponent,
  ChatDotsComponent,
  ChatHeadsSliderComponent,
  FeatureInfoComponent,
  GameItemComponent,
  GameItemDispComponent,
  GroupCardComponent,
  KVTableComponent,
  LeaderCardComponent,
  LeaderCardExtComponent,
  LeplaceBeaconComponent,
  LeplacePolarChartComponent,
  LocationDotsComponent,
  LocationDotsVComponent,
  LocationDotsExtComponent,
  LocationInfoComponent,
  LocationInfoCoreComponent,
  MinimapHudComponent,
  MpLeaderCardComponent,
  MpUserCardComponent,
  NewsfeedEntryComponent,
  PhotoItemComponent,
  PhotoUploadDispComponent,
  PlaceCardComponent,
  PlaceItemsCardComponent,
  PlaceStoryProviderCardComponent,
  QuestItemComponent,
  SoundButtonComponent,
  StoryInfoComponent,
  StoryInfoPromoComponent,
  StoryInfoSpecComponent,
  StripeCardComponent,
  TreasureSpecCardComponent,
  EventInfoComponent,
  GridActivityParamComponent,
  GridItemComponent,
  GridGroupMemberComponent,
  GridPhotoUploadsComponent,
  DynamicSlidesComponent,
  DynamicContentComponent,
  DynamicListComponent,
  DynamicGridComponent,
  SponsorCardComponent,
  GroupRoleCardComponent,
  GroupMemberCardComponent,
  GroupMemberItemComponent,
  SupportCategoryComponent,
  MpStatusCardComponent,
  WeatherInfoComponent
];

@NgModule({
  declarations: [...components],
  entryComponents: [...components],
  imports: [
    AppComponentsGenericModule,
    ComponentsModule
  ],
  exports: [...components]
})
export class AppComponentsModule { }
