import { ThemeColors } from "../def/app/theme";
import { INgxChartOptions } from '../def/app/charts';


export class ParamHandler {
    /**
     * check if nav params available from 1-2 level nested nav params
     * @param obj 
     */
    static checkParams(obj: any) {
        if (obj) {
            return true;
            // if (obj.data) {
            //     let keys = Object.keys(obj.data);
            //     return keys.length > 0;
            // } else {
            //     return Object.keys(obj).length > 0;
            // }
        } else {
            return false;
        }
    }


    /**
     * get data from 1-2 level nested nav params
     * @param obj 
     */
    static getParams(obj: any) {
        let params = null;
        if (obj) {
            // if (obj.data) {
            //     params = obj.data;
            //     if (obj.data.data) {
            //         params = obj.data.data;
            //     }
            // } else {
            //     params = obj;
            // }
            params = obj;
        }
        return params;
    }
}

export class NgxChartParams {

    static getDefaultOptions() {
        let chartOptions: INgxChartOptions = {
            showXAxis: true,
            showYAxis: false,
            gradient: false,
            showLegend: false,
            showXAxisLabel: false,
            animations: true,
            view: [400, 400],
            xAxisLabel: 'Category',
            yAxisMinScale: 5,
            showYAxisLabel: false,
            yAxisLabel: 'Level',
            colorScheme: {
                domain: ThemeColors.theme.aubergine.chartTheme
            },
            tooltip: true

        };
        return chartOptions;
    }

    static getXYOptions() {
        let chartOptions: INgxChartOptions = {
            showXAxis: true,
            showYAxis: true,
            gradient: false,
            showLegend: false,
            showXAxisLabel: true,
            animations: true,
            view: [200, 200],
            xAxisLabel: 'X',
            yAxisMinScale: 1,
            showYAxisLabel: true,
            yAxisLabel: 'Y',
            colorScheme: {
                domain: ThemeColors.theme.aubergine.chartTheme
            },
            tooltip: true,
            maxRadius: 20,
            minRadius: 5,
            // yScaleMin: 0,
            // yScaleMax: 10

        };
        return chartOptions;
    }

    static getVGaugeOptions() {
        let chartOptions: INgxChartOptions = {
            showXAxis: false,
            showYAxis: false,
            gradient: false,
            showLegend: false,
            showXAxisLabel: false,
            animations: true,
            view: undefined,
            xAxisLabel: 'Category',
            yAxisMinScale: 5,
            showYAxisLabel: false,
            yAxisLabel: 'Level',
            colorScheme: {
                domain: ThemeColors.theme.aubergine.vgTheme.default
            },
            tooltip: false
        };
        return chartOptions;
    }
}




