<div *ngIf="!loaded && showLoading">
  <progress-loading [small]="true"></progress-loading>
</div>

<div class="img-container" [@showState]="showState" [ngClass]="containerClass">

  <ion-button *ngIf="withUpload" class="action-button-xs button-color-alternate info-text-s upload-btn"
    (click)="tapUpload()">
    <icon-wrapper slot="icon-only" [icon]="appIconsStandard.pickPhoto" [custom]="false"></icon-wrapper>
  </ion-button>

  <ion-button *ngIf="withZoomOption" class="as-text element-selector info-text-s text-color-primary zoom-btn" (click)="tapZoom()">
    <icon-wrapper slot="icon-only" icon="search"></icon-wrapper>
  </ion-button>

  <img *ngIf="photoUrl" class="border-radius-img" [src]="photoUrl" [ngClass]="zoomState?'img-full':defaultClass">

</div>

<img *ngIf="photoUrl && !noAnimation" [src]="photoUrl" (load)="setLoaded()" hidden>