
import { IGameItem } from 'src/app/classes/def/items/game-item';
import { IStory } from 'src/app/classes/def/core/story';
import { CustomerInfo, PurchasesStoreProduct } from '@revenuecat/purchases-capacitor/dist/esm';

export interface ICheckItemData {
    valid: boolean,
    productId: string,
    info: string
}


export enum EIAPCustomEvent {
    error = 1,
    cancelled = 2,
    storeError = 3,
    storeReady = 4,
    updated = 5,
    approved = 6,
    requested = 7,
    invalid = 8,
    valid = 9,
    owned = 10,
    finished = 11,
    registered = 12,
    initiated = 13
}


export interface IAPStoreProduct {
    /**
     * Product Id.
     */
    identifier: string;
    /**
     * Description of the product.
     */
    description: string;
    /**
     * Title of the product.
     */
    title: string;
    /**
     * Price of the product in the local currency.
     * Contains the currency code value of defaultOption for Google Play.
     */
    price: number;
    /**
     * Formatted price of the item, including its currency sign, such as €3.99.
     * Contains the currency code value of defaultOption for Google Play.
     */
    priceString: string;
    /**
     * Currency code for price and original price.
     * Contains the currency code value of defaultOption for Google Play.
     */
    currencyCode: string;

    product: PurchasesStoreProduct;
}

// export interface IAPProduct extends CdvPurchase.Product {
//     transaction?: CdvPurchase.Transaction // send for server validation
// };

// export interface IAPProductOptions extends CdvPurchase.IRegisterProduct { };
// export interface ICheckItemsList {
//     checkItems: ICheckItemData[],
//     itemsList: (IGameItem | IStory)[],
//     hasItems: boolean
// }

// export interface ICachedProductDict {
//     [key: string]: IAPProduct;
// }

// export interface IIAPEventAg {
//     productId: string,
//     event: string,
//     customEvent: number,
//     product: IAPProduct,
//     transaction: CdvPurchase.Transaction
// }


export interface IAPProduct  {
    [key: string]: any;
};

export interface IAPProductOptions { };
export interface ICheckItemsList {
    checkItems: ICheckItemData[],
    itemsList: (IGameItem | IStory)[],
    hasItems: boolean
}

export interface ICachedProductDict {
    [key: string]: IAPProduct;
}

export interface IIAPEventAg {
    productId: string,
    event: string,
    customEvent: number,
    product: IAPProduct,
    transaction: any
}

export interface IPurchaseInfo {
    customer: CustomerInfo
}


/**
 * REGISTERED +--> INVALID
           |
           +--> VALID +--> REQUESTED +--> INITIATED +-+
                                                      |
                ^      +------------------------------+
                |      |
                |      |             +--> DOWNLOADING +--> DOWNLOADED +
                |      |             |                                |
                |      +--> APPROVED +--------------------------------+--> FINISHED +--> OWNED
                |                                                             |
                +-------------------------------------------------------------+
 */




