import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { ILeplaceReg } from 'src/app/classes/def/places/google';
import { IArenaNavParams } from 'src/app/classes/def/nav-params/activity';
import { IGroup, EGroupRole, EArenaEvent, IGroupMember, EGroupRoleName, EGroupModalResult } from 'src/app/classes/def/mp/groups';
import { Messages } from 'src/app/classes/def/app/messages';
import { ModalController, Platform } from '@ionic/angular';
import { BehaviorSubject, timer } from 'rxjs';
import { IChatElement } from 'src/app/classes/def/mp/chat';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { MPUtils } from 'src/app/services/app/mp/mp-utils';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EServiceUrlCodes } from 'src/app/classes/def/app/app';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { EStandardCode, EStandardTreasureCode } from 'src/app/classes/def/core/activity';
import { IArenaReturnToMapData, IArenaReturnData, EArenaReturnCodes } from 'src/app/classes/def/nav-params/arena';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EMPEventSource, EMPUserInputCodes, ELeaderStates, EMemberStates } from 'src/app/classes/def/mp/protocol';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { Util } from 'src/app/classes/general/util';
import { IGetPhotoOptions } from 'src/app/services/location/location-utils-def';
import { IPlacePhotoContainer } from 'src/app/classes/def/places/container';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { AppSettings } from 'src/app/services/utils/app-settings';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { ETreasureType } from 'src/app/classes/def/items/treasures';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { MPManagerService } from 'src/app/services/app/mp/mp-manager';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { UserStatsDataService } from 'src/app/services/data/user-stats';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { PlacesDataService } from 'src/app/services/data/places';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { MPGroupMembersPage } from '../mp-group-members/mp-group-members.page';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { IMPStatusDB } from 'src/app/classes/def/mp/status';
import { EMPCooldownScope } from 'src/app/classes/def/mp/meeting-place';
import { MPGameInterfaceService } from 'src/app/services/app/mp/mp-game-interface';
import { SleepUtils } from 'src/app/services/utils/sleep-utils';
import { LocationUtilsWizard } from 'src/app/services/location/location-utils-wizard';
import { ResourceMonitorDataService } from 'src/app/services/data/resource-monitor';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { OtherUtils } from 'src/app/services/utils/other-utils';
import { IGenericActionContainer, EGenericAction } from 'src/app/classes/def/dynamic/action';
import { IFaqEntry } from 'src/app/classes/def/support/support';
import { MessagesExt } from 'src/app/classes/def/app/messages-ext';
import { IMPGenericGroupStat } from 'src/app/classes/def/mp/game';
import { MPCreatePage } from '../mp-create/mp-create.page';
import { MpJoinPage } from '../mp-join/mp-join.page';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';


@Component({
  selector: 'app-mp-groups-home',
  templateUrl: './mp-groups-home.page.html',
  styleUrls: ['./mp-groups-home.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MPGroupsHomePage implements OnInit, OnDestroy {
  theme: string = "theme-light theme-light-bg";
  title: string = "Team Lobby";
  infoHTML: string = "";
  shareMessage: string = "";
  place: ILeplaceReg = null;
  photoUrlLoaded: string = null;
  params: IArenaNavParams = null;
  loaded: boolean = false;
  selectedGroup: IGroup = null;
  groupRole: number = EGroupRole.member;
  allConnected: boolean = false;
  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  buttonText = {
    ready: "Connect",
    start: "Start",
    ready2: "",
    start2: "",
    continue: "Map",
    return: "Return"
  };

  buttonEnabled = {
    ready: true,
    start: false,
    return: false
  };

  buttonTextFn = {
    ready: () => { return this.buttonText.ready; },
    // start: () => { let text: string = this.buttonText.start; if (!this.buttonEnabled.start) { text += " (N/A)"; } return text; },
    start: () => { return this.buttonText.start; },
    ready2: () => { return this.buttonText.ready2; },
    start2: () => { return this.buttonText.start2; },
    continue: () => { return this.buttonText.continue },
    return: () => { let text: string = this.buttonText.return; if (!this.buttonEnabled.return) { text += " (N/A)"; } return text; },
  };

  cooldownTimer = null;

  infoText: string = Messages.mp.init;

  infoTextGraphics: IFaqEntry[] = MessagesExt.mpInitGroup;

  infoTextAux: string = null;
  infoTextCustom: string = null;
  roleText: string = "";

  photoUrlSource: string = null;
  placePhotoUrlSource: string = null;
  photoUrlGroup: string = null;

  autoDismiss: boolean = false;
  autoStart: boolean = false;
  statusUpdate: boolean = false;

  subscription = {
    groupStatus: null,
    mpStateMachine: null,
    chatRx: null,
    arenaEvent: null,
    dispTimer: null
  };

  timeout = {
    other: null,
    enterMap: null,
    tapStartAuto: null,
    auxMessage: null,
    autoReload: null
  };

  observables = {
    chatInput: null
  };

  groupOnline: boolean = false;
  exitOnly: boolean = false;
  chatMessageReceived: boolean = false;
  chatBtnBlink: boolean = false;
  isChatOnly: boolean = false;
  isChatOpened: boolean = false;

  np: INavParams = null;
  vs: IViewSpecs;

  inRange: boolean = false;
  showQR: boolean = false;
  showQRAvailable: boolean = false;
  canExit: boolean = false;

  returnToHome: boolean = false;

  synchronized: boolean = true;
  isLobby: boolean = false;

  gridItemsContainers: any[] = [];
  gridItemsRow: number = 2;
  updateGridItems: boolean = false;
  updateGridItemsContent: boolean = false;
  groupMembers: IGroupMember[] = [];
  nearbyPlayers: IMPStatusDB[] = [];
  groupMembersLoaded: boolean = false;

  reloadOnTapReady: boolean = true;
  isRunning: boolean = false;

  groupUpdated: boolean = false;
  groupResetConnection: boolean = false;
  autoReload: boolean = false;
  autoReloadPrev: boolean = false;

  loadingTimeout: number = 20;
  resetLoadingTimeout: boolean = false;
  showProgressReload: boolean = true;

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public mpDataService: MPDataService,
    public analytics: AnalyticsService,
    public mpManager: MPManagerService,
    public mpGameInterface: MPGameInterfaceService,
    public resourcesProvider: ResourcesCoreDataService,
    public resourceMonitor: ResourceMonitorDataService,
    public userStatsProvider: UserStatsDataService,
    public popupFeatures: PopupFeaturesService,
    public placeProvider: PlacesDataService,
    public tutorials: TutorialsService,
    public nps: NavParamsService,
    public backButton: BackButtonService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public locationUtilsWizard: LocationUtilsWizard
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  loadStandardPhoto() {
    this.resourcesProvider.getStandardPhotoByCode(EStandardCode.treasure + EStandardTreasureCode.foundArenaGroup).then((photoUrl: string) => {
      this.photoUrlSource = photoUrl;
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  loadGroupPhoto(group: IGroup) {
    this.photoUrlGroup = group.photoUrl;
  }

  onSend(data: IChatElement) {
    console.log("send: ", data);
  }


  toggleShowQR() {
    this.showQR = !this.showQR;
  }

  ngOnInit() {

    this.analytics.trackView("mp-groups-home");

    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    this.nps.checkParamsLoaded().then(async () => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.mpHome, null, this.np);

      console.log("nav params: ", npInfo.params);
      this.observables.chatInput = new BehaviorSubject<IChatElement>(null);

      let hasParams: boolean = npInfo.hasParams;
      // console.log(navParams.data);
      await this.resourceMonitor.checkWatchLoader();
      let available: boolean = await this.popupFeatures.checkServiceAvailable(EServiceUrlCodes.mp)

      if (available) {
        this.mpManager.changeServerUrl(GeneralCache.resourceCache.general.appServices.content.object.mp.url);
      } else {
        // this.dismiss(null);
        this.dismissReturn();
        return;
      }

      console.log("has params: ", hasParams);

      if (hasParams) {
        let np: INavParams = npInfo.params;
        this.params = np.params;
        this.vs = np.view;

        if (!this.params.testing) {
          this.place = this.params.place;
          this.selectedGroup = this.params.group;
          this.groupRole = this.params.groupRole;
          this.formatPlace();
        }

        this.inRange = this.params.inRange;
        this.canExit = this.params.canExit;
        this.synchronized = this.params.synchronized;
        this.isLobby = this.params.isLobbyContext;

        this.buttonText.continue = this.params.fromMapOpened ? "map" : "continue";

        if (!this.inRange) {
          this.infoText += "<p>You are too far away</p>";
        }

        if (this.params.customMessage) {
          this.infoTextCustom = this.params.customMessage;
        }

        this.returnToHome = true; // go back on exit button click

        if (this.selectedGroup != null && this.selectedGroup.dynamic != null && this.mpManager.isGroupOnline()) {
          this.groupOnline = true;
        }

        if (this.params.fromMapOpened && this.groupOnline) {
          // don't allow disconnect, exit session directly instead
          this.exitOnly = true;
        }

        this.loadStandardPhoto();

        if (this.selectedGroup != null) {
          console.log("loading mp group > selected group: ", this.selectedGroup);
          // the game is already on, the view is opened from the map
          // group full specs
          this.setSelectedGroupSpecs(this.selectedGroup);
          this.initServicesGroupMode();
          this.loaded = true;
        } else {
          let groupId: number = this.params.groupId;
          console.log("loading mp group > group id: " + groupId);
          // group id specs
          if (groupId != null) {
            this.startCooldownTimerNoAction(EMPCooldownScope.readyCooldown);
            this.viewSelectedGroupWrapper(groupId).then(() => {
              // the game is already on / chat opened from notification
              console.log("loaded mp group > selected group: ", this.selectedGroup);
              if (this.selectedGroup) {
                // check open with group specs from mp home
                if (!this.params.simpleUI) {
                  this.initServicesGroupMode();
                }
              }
              this.loaded = true;
            }).catch((err: Error) => {
              console.error(err);
              this.loaded = true;
            });
          } else {
            this.loaded = true;
          }
        }

        this.isChatOnly = this.params.chatOnly;

        if (this.isChatOnly) {
          this.buttonEnabled.ready = false;
        }

        console.log("ARENA init");
        console.log("game is on: ", this.groupOnline);
        console.log("chat only: ", this.isChatOnly);
      } else {
        this.loaded = true;
      }

      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back();
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });

    }).catch((err: Error) => {
      console.error(err);
    });

  }


  /**
   * exit arena view and return to map
   * @param params 
   */
  dismiss(params: IArenaReturnToMapData) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  viewGroupDetails() {
    this.params.group = this.selectedGroup;
    this.pauseAutoReload();
    this.uiext.showCustomModal(null, MPGroupMembersPage, {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: true
      },
      params: this.params
    }).then((res: IArenaReturnData) => {
      console.log("exit group details: ", this.selectedGroup);
      this.resumeAutoReload();
      if (res) {
        switch (res.status) {
          default:
            break;
        }
      }
    }).catch((err: Error) => {
      this.resumeAutoReload();
      console.error(err);
    });
  }

  selectNearbyPlayer(p) {
    console.log(p);
  }

  /**
   * view current selected group (created/joined)
   * @param groupId 
   */
  viewSelectedGroupWrapper(groupId: number) {
    return new Promise<boolean>((resolve, reject) => {
      this.loaded = false;
      this.mpDataService.viewGroup(groupId, false).then((group: IGroup) => {
        this.setSelectedGroupSpecs(group);
        this.loaded = true;
        resolve(true);
      }).catch((err) => {
        this.loaded = true;
        reject(err);
      });
    });
  }


  /**
  * request group join from the main server
  */
  async joinGroup() {
    return this.mpDataService.joinGroup(null, this.selectedGroup.id);
  }


  formatRole(role: number) {
    if (this.groupRole !== role) {
      switch (this.groupRole) {
        case EGroupRole.leader:
          this.infoText = Messages.mp.leaderInit;
          this.roleText = "Role: " + EGroupRoleName.leader;
          this.showQRAvailable = true;
          break;
        case EGroupRole.member:
          this.infoText = Messages.mp.memberInit;
          this.roleText = "Role: " + EGroupRoleName.member;
          break;
      }
    }
  }

  setSelectedGroupSpecs(group: IGroup) {
    console.log("set selected group specs: ", group);
    MPUtils.formatGroupMembers(group, GeneralCache.userId);
    this.selectedGroup = group;
    this.loadGroupPhoto(group);
    this.formatRole(group.role);
    this.groupRole = group.role;
    this.selectedGroup = this.formatSelectedGroup(this.selectedGroup, false);
    console.log("formatted group: ", this.gridItemsContainers);
    this.mpManager.setGroupScope(this.selectedGroup);
  }

  formatSelectedGroup(group: IGroup, connected: boolean) {

    if (!group) {
      this.gridItemsContainers = [];
      return group;
    }

    this.groupRole = group.role;

    if (!group.members) {
      this.gridItemsContainers = [];
      return group;
    }

    this.groupMembers = group.members;

    this.nearbyPlayers = this.groupMembers.map(gm => MPUtils.getMPStatusFromGroupMember(gm, group.id));
    console.log("connected players: ", this.nearbyPlayers);
    setTimeout(() => {
      this.groupMembersLoaded = true;
    }, 100);

    let newEntry: boolean = false;
    for (let i = 0; i < group.members.length; i++) {
      let m: IGroupMember = group.members[i];
      if (!connected) {
        m.dynamic = null;
      }
      // sync (TODO: crud sync)
      let found: any = this.gridItemsContainers.find(u => {
        let data: IGroupMember = u.data;
        return data.userId === m.userId;
      });
      if (!found) {
        this.gridItemsContainers.push({
          data: m,
          small: false,
          interact: true,
          isAdmin: group.role === EGroupRole.leader,
          update: false
        });
        newEntry = true;
      } else {
        let foundIndex: number = this.gridItemsContainers.indexOf(found);
        this.gridItemsContainers[foundIndex].data = m;
        // this.gridItemsContainers[foundIndex].update = !this.gridItemsContainers[foundIndex].update;
        // console.log("set update flag: ", this.gridItemsContainers[foundIndex].update);
      }
    }

    this.updateGridItemsContent = !this.updateGridItemsContent;

    if (newEntry) {
      // refresh grid
      this.updateGridItems = !this.updateGridItems;
    }
    return group;
  }


  leaveSession() {
    this.uiext.showAlert(Messages.msg.leaveSession.before.msg, Messages.msg.leaveSession.before.sub, 2, null, true).then((res: number) => {
      switch (res) {
        case EAlertButtonCodes.ok:
          this.leaveSessionCore();
          break;
      }
    });
  }

  leaveSessionCore() {
    this.disposeMP();
    let params: IArenaReturnToMapData = {
      closeMpView: false,
      code: EArenaReturnCodes.leave,
      group: null,
      groupOnline: false,
      groupResetConnection: false,
      groupUpdated: this.groupUpdated,
      role: null
    };
    // this.userStatsProvider.registerWorldMapStatNoAction(EStatCodes.groupEventsClosed, 1);
    this.dismiss(params);
  }

  continueToMap() {
    let params: IArenaReturnToMapData = {
      closeMpView: true,
      code: EArenaReturnCodes.resume,
      group: this.selectedGroup,
      groupOnline: this.groupOnline,
      groupResetConnection: this.groupResetConnection,
      groupUpdated: this.groupUpdated,
      role: this.groupRole
    };
    this.mpGameInterface.setGameContainerGroupConnect(this.selectedGroup, this.groupRole);
    this.dismiss(params);
  }


  dismissReturn() {
    let params: IArenaReturnToMapData = {
      closeMpView: false,
      code: EArenaReturnCodes.resume,
      group: this.selectedGroup,
      groupOnline: this.groupOnline,
      groupResetConnection: this.groupResetConnection,
      groupUpdated: this.groupUpdated,
      role: this.groupRole
    };
    this.mpGameInterface.setGameContainerGroupConnect(this.selectedGroup, this.groupRole);
    this.dismiss(params);
  }


  /**
   * begin the group story
   * this is the last step that the LEADER has to do before the initialization is complete
   * the leader can tap start only after the others have already tapped (auto/app) start
   */
  tapStart() {
    if (this.isChatOpened) {
      console.warn("tapStart blocked / chat was opened");
      return;
    }
    // this.dismiss(null);
    this.timeout.tapStartAuto = ResourceManager.clearTimeout(this.timeout.tapStartAuto);
    this.stopCooldownTimer();
    this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.tapStart, null);
    this.infoText = Messages.mp.gameOn;
    // this.userStatsProvider.registerWorldMapStatNoAction(EStatCodes.groupEventsStarted, 1);
    this.continueToMap();
  }

  checkCanExit() {
    return this.mpManager.canExit;
  }

  async tapReady() {
    try {
      console.log("tap ready");
      console.log("tap ready selected group: ", this.selectedGroup);
      if (this.selectedGroup) {
        if (!MPUtils.isGroupMember(this.selectedGroup, GeneralCache.userId)) {
          // request join group first
          let res: number = await this.uiext.showAlert(Messages.msg.joinSelectedGroup.before.msg, Messages.msg.joinSelectedGroup.before.sub, 2, null, false);
          if (res === EAlertButtonCodes.ok) {
            await this.joinGroup();
          } else {
            return;
          }
        }
        // request static data from db to see all the users that are registered
        let group = await this.mpDataService.viewGroup(this.selectedGroup.id, false);
        this.setSelectedGroupSpecs(group);
        switch (this.groupRole) {
          case EGroupRole.leader:
            this.infoText = Messages.mp.leaderReady;
            break;
          case EGroupRole.member:
            this.infoText = Messages.mp.memberReady;
            break;
        }
        if (this.reloadOnTapReady) {
          this.reloadOnTapReady = false;
          await this.reloadCoreResetConnection();
        }
        this.groupOnline = true;
        // this.uiext.showAlertNoAction(Messages.msg.waitForGroupConnect.after.msg, Messages.msg.waitForGroupConnect.after.sub);
        this.showQRAvailable = false;
        this.connectGroupModeWrapper();
      } else {
        console.warn("group not loaded");
      }
    } catch (err) {
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
    }
  }

  async connectGroupModeWrapper() {
    // console.log("try connecting");
    // await this.connectGroupMode();
    // console.log("reset connection");
    // await SleepUtils.sleep(100);
    // this.disposeMP();
    // await SleepUtils.sleep(100);
    // console.log("now connecting");
    await this.connectGroupMode();
    // all set, now can send ready message
    await SleepUtils.sleep(1000);
    this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.tapReady, null);
  }

  async connectGroupMode() {
    await this.mpManager.connectGroupModeWizard(this.selectedGroup, this.groupRole, this.synchronized);
    await this.initServicesGroupMode();
  }

  canRefresh() {
    return true;
    // return !(this.gameIsAlreadyOn || this.groupOnline);
  }

  /**
   * init core services
   * watch events, status
   * start timers
   */
  initServicesGroupMode(): Promise<boolean> {
    console.log("init services group mode");
    let promise: Promise<boolean> = new Promise((resolve) => {
      this.toggleAutoReload(true);
      this.timeout.other = setTimeout(() => {
        this.watchGroupStatus();
        this.watchMPStateMachine();
        this.watchArenaEvent();
        this.watchGroupChatRx();
        resolve(true);
      }, 100);
    });
    return promise;
  }


  /**
   * subscribe to group status events
   * for display purpose
   * the group sync with dynamic data is done internally by the mp manager
   */
  watchGroupStatus() {
    if (!this.subscription.groupStatus) {
      let obs = this.mpManager.getGroupStatusObservable();
      if (!obs) {
        return;
      }
      this.subscription.groupStatus = obs.subscribe((data: IMPGenericGroupStat) => {
        if (data && data.group) {
          // console.log("group status: ", data.group);
          this.selectedGroup = this.formatSelectedGroup(data.group, true);
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }


  watchArenaEvent() {
    // connect to arena events e.g. disconnected member
    if (!this.subscription.arenaEvent) {
      this.subscription.arenaEvent = this.mpManager.watchArenaEvent().subscribe((event: number) => {
        if (event != null) {
          this.setArenaEvent(event);
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  groupMemberAction(event: IGenericActionContainer<IGroupMember>) {
    console.log("group member action: ", event);
    if (event) {
      switch (event.action) {
        case EGenericAction.remove:
          this.reload(false);
          break;
      }
    }
  }

  private setArenaEvent(event: number) {
    switch (event) {
      case EArenaEvent.requireUserAction:
        // already returned to arena from gmap and showing state-based message
        break;
      case EArenaEvent.connectionProblem:
        if (!this.timeout.auxMessage) {
          this.infoTextAux = "<p>Connection problems</p><p>Check your data connection</p>";
          this.timeout.auxMessage = setTimeout(() => {
            this.infoTextAux = null;
            this.timeout.auxMessage = null;
          }, 5000);
        }
        break;
      case EArenaEvent.chatWindowClosed:
        // this event is received from the mp manager as it handles the chat ui
        this.chatMessageReceived = false;
        this.chatBtnBlink = false;
        break;
      default:
        break;
    }
  }

  /**
   * subscribe to the state of mp manager state machine
   * handles the UI elements/interaction that are associated to each state
   */
  watchMPStateMachine() {
    if (!this.isChatOnly) {
      this.setState(this.mpManager.getState());
    }
    if (!this.subscription.mpStateMachine) {
      this.subscription.mpStateMachine = this.mpManager.watchStateMachine().subscribe((state: number) => {
        console.log("watch state changed: ", state);
        if (state != null) {
          if (!this.isChatOnly) {
            this.setState(state);
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  setState(state: number) {
    console.log("mp groups home set state: " + state + " (role: " + this.groupRole + ")");
    switch (this.groupRole) {
      case EGroupRole.leader:
        switch (state) {
          case ELeaderStates.HANDLE_DISCONNECTED_MEMBER:
            if (!this.isLobby) {
              this.autoDismiss = true; // will enter the map auto on member reconnect
            }
            this.buttonEnabled.return = false;
            this.infoText = Messages.mp.leaderHandleMemberDisconnected;
            break;
          case ELeaderStates.INIT:
            this.isRunning = false;
            break;
          case ELeaderStates.READY:
            this.infoText = Messages.mp.leaderReady;
            break;
          case ELeaderStates.SET:
            if (!this.buttonEnabled.start) {
              this.buttonEnabled.start = true;
            }
            this.infoText = Messages.mp.leaderSet;
            break;
          case ELeaderStates.SET_AND_TAPPED_START:
            if (!this.buttonEnabled.start) {
              this.buttonEnabled.start = true;
            }
            this.infoText = Messages.mp.leaderSetAndTappedStart;
            break;
          case ELeaderStates.SET_AND_ALL_READY:
            if (!this.buttonEnabled.start) {
              this.buttonEnabled.start = true;
            }
            this.infoText = Messages.mp.leaderSetAndTappedStart;
            break;
          case ELeaderStates.GO:
            // e.g. when returned from error handler
            this.buttonEnabled.return = true;
            this.isRunning = true;
            this.infoText = Messages.mp.gameOn;
            if (this.autoDismiss) {
              this.timeout.tapStartAuto = setTimeout(() => {
                this.tapStart();
              }, 1000);
            }
            break;
        }
        break;
      case EGroupRole.member:
        switch (state) {
          case EMemberStates.HANDLE_DISCONNECTED_LEADER:
            this.buttonEnabled.return = false;
            if (!this.isLobby) {
              this.autoDismiss = true; // will enter the map auto on member reconnect
            }
            this.infoText = Messages.mp.memberHandleLeaderDisconnected;
            break;
          case EMemberStates.INIT:
            this.isRunning = false;
            break;
          case EMemberStates.READY:
            this.infoText = Messages.mp.memberReady;
            break;
          case EMemberStates.SET:
            // initialize sequence then trigger state change
            // load group static data
            // the leader tapped ready so the group should be all set
            // but the member does not know about this until it receives the message from the leader
            // so the group static data has to be loaded then
            this.infoText = Messages.mp.memberSet;
            this.buttonEnabled.start = true;

            // if (this.autoStart) {
            //   this.startCooldownTimerNoAction(EMPCooldownScope.autoStart);
            // }

            // refresh group after leader started the game
            this.mpDataService.viewGroup(this.selectedGroup.id, false).then((group: IGroup) => {
              this.setSelectedGroupSpecs(group);
              if (this.autoStart) {
                this.startCooldownTimerNoAction(EMPCooldownScope.autoStart);
              }
            }).catch((err: Error) => {
              this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.back, null);
              this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
            });
            break;
          case EMemberStates.WAIT_GO:
            this.infoText = Messages.mp.memberSetGo;
            // this should be done automatically AFTER map init
            if (this.autoStart) {
              this.timeout.tapStartAuto = setTimeout(() => {
                this.tapStart();
              }, 1000);
            }
            break;
          case EMemberStates.GO:
            // e.g. when returned from error handler
            this.buttonEnabled.return = true;
            this.isRunning = true;
            this.infoText = Messages.mp.gameOn;
            if (this.autoDismiss) {
              this.timeout.tapStartAuto = setTimeout(() => {
                this.tapStart();
              }, 1000);
            }
            break;
        }
        break;
    }
  }

  stopWatchArenaEvent() {
    this.subscription.arenaEvent = ResourceManager.clearSub(this.subscription.arenaEvent);
    this.infoTextAux = null;
  }

  /**
   * check when a message arrives so that it can make the button blinking
   */
  watchGroupChatRx() {
    if (!this.subscription.chatRx) {
      this.subscription.chatRx = this.mpManager.watchChatWS().subscribe((event: number) => {
        if (event) {
          this.chatMessageReceived = true;
          if (this.groupOnline) {
            this.chatBtnBlink = false;
            setTimeout(() => {
              this.chatBtnBlink = true;
            }, 1);
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  stopWatchGroupChatRx() {
    this.subscription.chatRx = ResourceManager.clearSub(this.subscription.chatRx);
  }

  stopWatchGroupStatus() {
    this.subscription.groupStatus = ResourceManager.clearSub(this.subscription.groupStatus);
    this.mpManager.clearGroupMemberStatus(this.selectedGroup);
  }

  stopWatchMPStateMachine() {
    this.subscription.mpStateMachine = ResourceManager.clearSub(this.subscription.mpStateMachine);
  }

  disposeMP() {
    this.mpManager.quitSession();
    let selectedGroup = this.mpManager.getGroupScope();
    console.log("dispose mp selected group: ", selectedGroup);
    if (selectedGroup != null) {
      this.selectedGroup = selectedGroup;
    }
    this.mpGameInterface.disconnect();
    this.mpGameInterface.dispose();
    this.stopWatchGroupStatus();
    this.stopWatchMPStateMachine();
    this.stopWatchGroupChatRx();
    this.stopWatchArenaEvent();
  }

  cancelStep() {
    this.mpManager.dispatchEventMux(EMPEventSource.userInput, EMPUserInputCodes.back, null);
    if (this.groupOnline) {
      this.groupOnline = false;
      this.groupResetConnection = true;
      this.startCooldownTimerNoAction(EMPCooldownScope.readyCooldown);
      this.disposeMP();

      // reset previous game container (it's set to default when disposing resources)
      this.mpGameInterface.setPrevGameContainer();
      this.selectedGroup = this.formatSelectedGroup(this.selectedGroup, false);

      switch (this.groupRole) {
        case EGroupRole.leader:
          this.infoText = Messages.mp.leaderInit;
          this.showQRAvailable = true;
          break;
        case EGroupRole.member:
          this.infoText = Messages.mp.memberInit;
          break;
      }
    } else {
      if (this.returnToHome) {
        if (this.groupOnline) {
          this.uiext.showAlert(Messages.msg.exitGroupChat.before.msg, Messages.msg.exitGroupChat.before.sub, 2, null, true).then((res: number) => {
            if (res === EAlertButtonCodes.ok) {
              // this.back();
              this.leaveSession();
            }
          });
        } else {
          // this.back();
          // this.leaveSession();
          this.leaveSessionCore();
        }
      } else {
        this.selectedGroup = this.formatSelectedGroup(null, false);
        this.photoUrlSource = this.placePhotoUrlSource;
        this.infoText = Messages.mp.init;
      }
    }
  }

  startCooldownTimerNoAction(scope: number) {
    this.startCooldownTimer(scope).then(() => { }).catch(() => { });
  }


  startCooldownTimer(scope: number) {
    let promise = new Promise((resolve, reject) => {
      if (this.cooldownTimer) {
        reject(new Error("cooldown timer already started"));
        return;
      }
      this.buttonEnabled.ready = false;
      let timer1 = timer(0, 1000);
      let cooldown: number = 1;
      this.cooldownTimer = timer1.subscribe(() => {
        switch (scope) {
          case EMPCooldownScope.readyCooldown:
            // this.buttonText.ready = "(" + cooldown + ") Ready";
            this.buttonText.ready = "~Ready~";
            this.buttonText.ready2 = "" + cooldown;
            break;
          case EMPCooldownScope.autoStart:
            // this.buttonText.start = "(" + cooldown + ") Start";
            this.buttonText.start = "~Start~";
            this.buttonText.start2 = "" + cooldown;
            break;
        }
        if (cooldown > 0) {
          cooldown -= 1;
        } else {
          this.cooldownComplete(scope);
          resolve(true);
        }
      }, (err: Error) => {
        console.error(err);
        reject(err);
      });
    });
    return promise;
  }

  cooldownComplete(scope: number) {
    switch (scope) {
      case EMPCooldownScope.autoStart:
        this.stopCooldownTimer();
        this.infoText = Messages.mp.gameOn;
        break;
      default:
        this.stopCooldownTimer();
        break;
    }
  }

  stopCooldownTimer() {
    this.cooldownTimer = ResourceManager.clearSub(this.cooldownTimer);
    this.buttonEnabled.ready = true;
    if (!this.isChatOpened) {
      this.buttonEnabled.start = false;
    }
    this.buttonText.ready = "Connect";
    this.buttonText.start = "Start";
    this.buttonText.ready2 = "";
    this.buttonText.start2 = "";
  }

  /**
   * this should check the real time status
   * to see if all the members are connected
   */
  checkConnected() {
    this.allConnected = true;
  }


  back() {
    if (this.groupOnline) {
      this.continueToMap();
    } else {
      // this.dismiss(null);
      this.dismissReturn();
    }
  }


  ngOnDestroy() {
    console.log("arena on destroy");
    this.stopCooldownTimer();
    this.clearWatch();
    this.backButton.checkPop(this.vs);
  }

  clearWatch() {
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
    this.subscription = ResourceManager.clearSubObj(this.subscription);
  }

  /**
   * format place display
   */
  formatPlace() {
    if (this.place) {
      let mapsUrl: string = Util.generateMapsLink(this.place.place.googleId, this.place.place.name);
      this.place.aux = {
        mapsURL: mapsUrl,
        photoUrl: null,
        photoUrlCached: null,
        canvasLoaded: false,
        canvasURL: null
      };

      let options: IGetPhotoOptions = {
        noPlaceholder: true,
        redirect: true,
        cacheDisk: true,
        useGeneric: SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value
      };

      if (options.useGeneric) {
        this.placeProvider.getPlaceTemplatePhoto(this.place.place.type).then((p: IPlacePhotoContainer) => {
          this.place.aux.photoUrl = p.photoUrl;
          this.placePhotoUrlSource = p.photoUrl;
        });
      } else {
        this.locationUtilsWizard.loadPlacePhotoWizard1(this.place, options).then(() => {
          this.placePhotoUrlSource = this.place.place.photoUrl;
        });
      }
    }
  }

  options() {
    let actions: IPopoverActions = {};
    actions = {
      tutorial: {
        name: "Tutorial",
        code: 10,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      },
      refresh: {
        name: "Reload",
        code: 0,
        icon: EAppIconsStandard.refresh,
        enabled: true
      },
      viewMembers: {
        name: "View members",
        code: 11,
        icon: EAppIcons.personCircleOutline,
        customIcon: true,
        enabled: this.selectedGroup != null
      },
    };

    if (AppSettings.testerMode) {
      let actions2: IPopoverActions = {
        simulateConnectionLost: {
          name: "Test connection lost*",
          code: 1,
          enabled: true
        },
        simulateConnectionResume: {
          name: "Test connection resume*",
          code: 2,
          enabled: true
        },
        chatHUD: {
          name: "Chat HUD*",
          code: 3,
          enabled: true
        }
      };
      Object.assign(actions, actions2);
    }

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 0:
          this.reload(true);
          break;
        case 1:
          this.mpManager.simulateConnectionLost();
          break;
        case 2:
          this.mpManager.simulateConnectionResume();
          break;
        case 3:
          this.showChatModal();
          break;
        case 10:
          this.tutorials.showTutorialNoAction(this.title + " Tutorial", 200 + ETreasureType.arena, null, null, true);
          break;
        case 11:
          this.viewGroupDetails();
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  async reloadOnTap() {
    try {
      this.toggleAutoReload(false);
      await this.reload(true);
      this.toggleAutoReload(true);
    } catch (err) {
      console.error(err);
      this.toggleAutoReload(true);
    }
  }

  async reload(resetViewAlways: boolean) {
    try {
      this.loaded = resetViewAlways ? false : true;
      let resetConnection: boolean = await this.reloadCoreResetConnectionOnGroupChanged(!resetViewAlways);
      this.loaded = true;
      if (this.groupOnline && resetConnection) {
        this.tapReady();
      }
    } catch (err) {
      console.error(err);
      this.loaded = true;
    }
  }

  async reloadCoreResetConnectionOnGroupChanged(resetView: boolean) {
    try {
      if (!this.selectedGroup) {
        return false;
      }
      let groupId: number = this.selectedGroup.id;
      let group: IGroup = await this.mpDataService.viewGroup(groupId, true);
      this.setSelectedGroupSpecs(group);
      let groupChanged: boolean = MPUtils.checkGroupMembersChanged(this.selectedGroup, group);
      console.log("check group changed reload: ", groupChanged);
      if (groupChanged) {
        if (this.groupOnline) {
          let res: number = await this.uiext.showAlert(Messages.msg.refreshGroupChangeDetectedOngoingSession.after.msg, Messages.msg.refreshGroupChangeDetectedOngoingSession.after.sub, 2, null);
          if (res !== EAlertButtonCodes.ok) {
            return false;
          }
        }
        if (resetView) {
          this.loaded = false;
        }
        this.disposeMP();
        this.mpDataService.clearGroupCache();
        console.log("reload selected group: ", groupId);
        this.setSelectedGroupSpecs(group);
        if (resetView) {
          await SleepUtils.sleep(100);
          this.loaded = true;
        }
      }
      return groupChanged;
    } catch (err) {
      console.error(err);
      return false;
    }
  }

  async reloadCoreResetConnection() {
    try {
      if (!this.selectedGroup) {
        return;
      }
      this.disposeMP();
      let groupId: number = this.selectedGroup.id;
      console.log("reload selected group: ", groupId);
      this.mpDataService.clearGroupCache();
      await this.viewSelectedGroupWrapper(groupId);
    } catch (err) {
      console.error(err);
      return;
    }
  }


  trackByFn(_index: number, item: IMPStatusDB) {
    // return index; // or item.id, if your image has an ID
    return item.playerId;
  }

  async showChatModal() {
    try {
      this.isChatOpened = true;
      this.pauseAutoReload();
      if (this.isChatOnly) {
        await this.uiext.showLoadingV2Queue("Connecting..");
        // is this required?
        await this.connectGroupModeWrapper();
        await this.mpManager.initSequence(this.selectedGroup, this.groupRole, this.synchronized);
        await this.uiext.dismissLoadingV2();
        await this.mpManager.openChatWindow(true, true);
        this.isChatOpened = false;
        this.resumeAutoReload();
      } else {
        // do not disconnect on chat window close
        await this.mpManager.openChatWindow(false, true);
        this.isChatOpened = false;
        this.resumeAutoReload();
      }
    } catch (err) {
      this.isChatOpened = false;
      this.resumeAutoReload();
      console.error(err);
    }
  }

  copyToClipboard(text: string) {
    OtherUtils.copyToClipboard(text);
    this.uiext.showToastNoAction("copied to clipboard", false);
  }

  viewGroups() {

  }

  onTapSelectGraphic(code: number) {
    switch (code) {
      case 1:

        break;
      case 2:

        break;
      case 3:

        break;
    }
  }

  pauseAutoReload() {
    if (!this.autoReload) {
      console.warn("auto reload not available");
      return;
    }
    this.autoReloadPrev = true;
    this.showProgressReload = false;
    this.toggleAutoReload(false);
  }

  resumeAutoReload() {
    if (!this.autoReloadPrev) {
      console.warn("auto reload not available prev");
      return;
    }
    this.autoReloadPrev = false;
    this.showProgressReload = true;
    this.toggleAutoReload(true);
  }

  /**
   * set or toggle auto reload
   * @param enable 
   */
  toggleAutoReload(enable: boolean) {
    console.log("toggle auto reload: ", enable, " ext: ", this.autoReload);
    this.resetLoadingTimeout = !this.resetLoadingTimeout;
    if (enable && !this.autoReload) {
      console.log("enable auto reload");
      this.autoReload = true;
      this.timeout.autoReload = ResourceManager.clearTimeout(this.timeout.autoReload);
      this.doAutoReload();
    }
    if (!enable && this.autoReload) {
      console.log("clear auto reload");
      this.autoReload = false;
      this.timeout.autoReload = ResourceManager.clearTimeout(this.timeout.autoReload);
    }
  }

  /**
   * auto reload every 20 sec
   */
  doAutoReload() {
    console.log("do auto reload");
    this.timeout.autoReload = setTimeout(() => {
      this.reload(false).then(() => {
        console.log("auto reload complete");
        this.resetLoadingTimeout = !this.resetLoadingTimeout;
        this.doAutoReload();
      }).catch((err: Error) => {
        console.error(err);
        this.doAutoReload();
      });
    }, this.loadingTimeout * 1000);
  }

  manageGroup() {
    let group: IGroup = this.selectedGroup;
    let params = Object.assign({}, this.params);

    if (!params) {
      params = {
        group: null,
        place: null,
        meetingPlace: null,
        groupRole: null,
        groupId: null,
        testing: false,
        chatOnly: false,
        playerId: null,
        inRange: true,
        canExit: true,
        enableGroups: true,
        isStoryline: false,
        isLobbyContext: null,
        context: null,
        contextId: null,
        synchronized: false,
        fromTeamLobby: true
      };
    }

    params.group = group;
    params.fromTeamLobby = true;

    this.pauseAutoReload();

    this.uiext.showCustomModal(null, [EGroupRole.member, EGroupRole.lobby].indexOf(group.role) !== -1 ? MpJoinPage : MPCreatePage, {
      view: {
        fullScreen: true,
        transparent: false,
        large: true,
        addToStack: false,
        frame: true
      },
      params: params
    }).then(async (res: IArenaReturnData) => {
      console.log("mp groups return data: ", res);
      this.resumeAutoReload();
      if (res) {
        switch (res.status) {
          case EGroupModalResult.ok:
            if (res.groupUpdated) {
              this.groupUpdated = true;
              await this.reloadOnTap();
            }
            this.selectedGroup = res.group;
            // return to main view with selected group    
            this.mpGameInterface.setGameContainerUpdateGroupScope(this.selectedGroup, null, false);
            break;
          case EGroupModalResult.remove:
            this.groupUpdated = true;
            this.leaveSessionCore();
            break;
          case EGroupModalResult.cancel:
            if (res.groupUpdated) {
              this.groupUpdated = true;
              await this.reloadOnTap();
            }
            break;
          default:
            break;
        }
      }
    }).catch((err: Error) => {
      console.error(err);
      this.resumeAutoReload();
    });
  }
}
