import { Directive, HostListener } from '@angular/core';
import { Util } from 'src/app/classes/general/util';

/**
 * Custom directive so that we can listen for clicks (or taps) on the event detail page.
 * When the user clicks on an embeded link it will be opened by the browser service and now what ever target
 * the link was written for.
 * Since we dont control the content of the event feed descriptions this is the only way we can control
 * what happens when they include a link and the user opens it.
 */
@Directive({
    selector: '[linkInterceptor]',
})
export class LinkIntereptorDirective {

    @HostListener('click', ['$event']) onClick(event: any) {
        this.clickJack(event);
    }

    @HostListener('tap', ['$event']) onTap(event: any) {
        this.clickJack(event);
    }

    private clickJack(event) {
        event.preventDefault();
        // console.log("clickJack fired: ", event);
        if (event.target.tagName.toLowerCase() === 'a') {
            this.open(event.target.href);
        }
    }

    private async open(href: string) {
        // here you can handle how you want to open the url.
        console.log("linkInterceptor: " + href);
        Util.openURLExt(href);
    }

    constructor() { }
}