import { Injectable } from "@angular/core";
import { IGmapNavParams, IGmapDetailInteractions, IGmapDetailReturnParams, EGmapDetailReturnCode, IGmapActivityStartOptions } from "../../classes/def/nav-params/gmap";
import { EStoryMode, IStory } from "../../classes/def/core/story";
import { IAppLocation } from "../../classes/def/places/app-location";
import { UiExtensionService } from "../general/ui/ui-extension";
import { ILeplaceTreasure } from "../../classes/def/places/leplace";
import { IItemFound } from "../../classes/def/nav-params/activity";
import { ITreasureFoundReturnData } from "../../classes/def/nav-params/modal-finished-return";
import { ModalController } from '@ionic/angular';
import { TreasureHomePage } from 'src/app/pages/treasure-home/treasure-home.page';
import { GmapDetailPage } from 'src/app/pages/gmap-detail/gmap-detail.page';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { SlidesSelectorViewComponent } from 'src/app/modals/generic/modals/slides-selector/slides-selector.component';
import { IGenericSlidesNavParams } from 'src/app/classes/def/dynamic/slides';
import { IGenericSlideData } from 'src/app/classes/def/views/slides';
import { EDroneMode } from '../data/activities';
import { OtherUtils } from './other-utils';
import { EModalScope } from "src/app/classes/def/views/overlay";


interface IOpenViews {
    [key: string]: HTMLIonModalElement;
}

/**
 * abstracts the view type
 * that may be either modal/page/etc
 */
@Injectable({
    providedIn: 'root'
})
export class ModularViewsService {
    // locationDetailsView: IOpenViews = null;
    openViews: IOpenViews = {
        location: null,
        itemFound: null
    };

    currentGmapDetailReturnParams: IGmapDetailReturnParams;

    constructor(
        public modalCtrl: ModalController,
        public uiext: UiExtensionService
    ) {
        console.log("modular views service created");
    }


    clearCurrentGmapDetailParams() {
        this.currentGmapDetailReturnParams = null;
    }

    getCurrentGmapDetailParams() {
        console.log("get current gmap detail return params: ", this.currentGmapDetailReturnParams);
        return this.currentGmapDetailReturnParams;
    }

    /**
     * dismiss modal
     * no action
     */
    dismissLocationDetailsViewNoAction() {
        this.dismissLocationDetailsViewResolve().then(() => { }).catch(() => { });
    }

    /**
     * dismiss modal
     * resolve only
     */
    async dismissLocationDetailsViewResolve(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            if (this.openViews.location) {
                this.openViews.location.dismiss().then(() => {
                    resolve(true);
                }).catch((err: Error) => {
                    console.error(err);
                    resolve(false);
                });
            } else {
                resolve(true);
            }
        });
        return promise;
    }

    getItemFoundView(itemFoundParams: IItemFound): Promise<ITreasureFoundReturnData> {
        let promise: Promise<ITreasureFoundReturnData> = new Promise((resolve, reject) => {
            this.uiext.showCustomModal(null, TreasureHomePage, {
                view: {
                    fullScreen: true,
                    transparent: false,
                    large: true,
                    addToStack: false,
                    frame: true
                },
                params: itemFoundParams
            }).then((res: ITreasureFoundReturnData) => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    showGameplayOverviewSlider(slideData: IGenericSlideData[], title: string) {
        let promise: Promise<any> = new Promise((resolve, reject) => {
            let params: IGenericSlidesNavParams = {
                title: title != null ? title : "Gameplay Tutorial",
                slides: slideData
            };
            this.uiext.showCustomModal(null, SlidesSelectorViewComponent, {
                view: {
                    fullScreen: false,
                    transparent: false,
                    large: true,
                    addToStack: true,
                    frame: false
                },
                params: params
            }).then((res: any) => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * 
     * @param story 
     * @param appLocation 
     * @param treasure 
     * @param block 
     * @param withDismiss dismiss/ok instead of return button
     */
    getLocationDetailsView(
        story: IStory,
        appLocation: IAppLocation,
        treasure: ILeplaceTreasure,
        block: boolean,
        interactions: IGmapDetailInteractions,
        fullScreen: boolean,
        startOptions: IGmapActivityStartOptions): Promise<IGmapDetailReturnParams> {
            
        let promise: Promise<IGmapDetailReturnParams> = new Promise((resolve, reject) => {
            if (appLocation != null) {
                appLocation.inProgress = block;
            }
            let customInteraction: boolean = false;
            if (interactions != null) {
                let customButtons: boolean = false;
                if (interactions.startReady) {
                    customButtons = true;
                    if (interactions.withDrone == null) {
                        interactions.withDrone = EDroneMode.withDrone;
                    }
                    interactions.buttons = OtherUtils.buildDroneModeButtons(interactions.withDrone);
                }
                if (customButtons) {
                    customButtons = true;
                    interactions.withCustomButtons = true;
                    customInteraction = true;
                }
            }

            let params: IGmapNavParams = {
                location: appLocation,
                story: story,
                photoValidated: (appLocation != null) ? appLocation.photoValidated : false,
                treasure: treasure,
                fromGmap: true,
                isPreloadStory: (story != null) ? story.mode === EStoryMode.preload : false,
                interactions: interactions,
                startOptions: startOptions
            };

            let navParams: INavParams = {
                view: {
                    fullScreen: fullScreen,
                    transparent: false,
                    large: true,
                    addToStack: false,
                    frame: true
                },
                params: params
            };

            this.uiext.showCustomModalHandler(GmapDetailPage, navParams, (modal: HTMLIonModalElement) => {
                this.openViews.location = modal;
            }, () => {
                this.openViews.location = null;
            }, false, EModalScope.modal).then((data: IGmapDetailReturnParams) => {
                if (data != null) {
                    if (customInteraction) {
                        // set details in startOptions                       
                        data.startOptionsSelected = {
                            optionsPreloaded: customInteraction,
                            launchDrone: (data.code === EGmapDetailReturnCode.drone) ? (interactions.withDrone !== EDroneMode.noDrone) : false
                        };
                        if ([EGmapDetailReturnCode.gps, EGmapDetailReturnCode.drone].indexOf(data.code) !== -1) {
                            data.code = EGmapDetailReturnCode.proceed;
                        }
                    }
                }
                this.currentGmapDetailReturnParams = data;
                console.log("set current gmap detail return params: ", data);
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }
}



