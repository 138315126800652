import { GeneralCache } from "../../app/general-cache";
import { EOS } from "../app/app";

/**
 * additional view specs
 * params that customize the view mode
 * e.g. transparent, rounded header
 */
export interface IViewSpecs {
    transparent: boolean;
    headerOpacity?: boolean;
    large: boolean;
    small?: boolean;
    addToStack: boolean;
    frame: boolean;
    fullScreen: boolean;
    backdropDismiss?: boolean;
    // used for dismiss modal by id (and not the top modal)
    id?: string;
    // added by default when using showCustomModal function
    isModal?: boolean;
    // 
    webIOS?: boolean;
}


export interface INavParams {
    params: any;
    view: IViewSpecs;
    /**
     * other params that may be used for deep linking in the browser
     */
    [key: string]: any;
}


export class ViewSpecs {
    static getDefault() {
        let vs: IViewSpecs = {
            transparent: false,
            fullScreen: false,
            frame: false,
            large: false,
            addToStack: true,
            isModal: true
        };
        return vs;
    }

    static getHeaderClass(vs: IViewSpecs, withBorderBottomDefault: boolean) {
        let headerClass: string = "";
        if (!vs || (vs && vs.fullScreen)) {
            if (GeneralCache.checkPlatformOSWithPWA() === EOS.ios) {
                headerClass += " overlay-header-ios";
            } else if (GeneralCache.isWeb) {
                // headerClass += " overlay-header-web";
            } else {
                headerClass += " overlay-header";
            }
        } else {
            if (withBorderBottomDefault && !vs.frame) {
                headerClass += " theme-border-bottom";
            }
        }
        return headerClass;
    }
}

