import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { IUserPublicData } from 'src/app/classes/def/user/general';
import { IStatDef, ActivityStatsUtils } from 'src/app/classes/def/user/stats';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { EPhotos } from 'src/app/classes/def/app/icons';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { GeneralUtils } from 'src/app/classes/utils/general';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { IPhotoFrameNavParams, PhotoFrameViewComponent } from 'src/app/modals/generic/modals/photo-frame/photo-frame.component';
import { StringUtils } from 'src/app/services/app/utils/string-utils';

@Component({
  selector: 'mp-leader-card',
  templateUrl: './mp-leader-card.component.html',
  styleUrls: ['./mp-leader-card.component.scss'],
})
export class MpLeaderCardComponent implements OnInit, OnDestroy {
  photoLoaded: boolean = false;
  @Input()
  user: IUserPublicData;

  @Input()
  mode: number;

  scoreDisp: string = "";
  headingDisp: string = "";
  levelDisp: string = "";
  bgPhoto: string = null;
  hasPhoto: boolean = false;
  userName: string = "";
  userAlias: string = "";
  self: boolean = false;

  activityStats: IStatDef[] = null;

  loaded: boolean = false;

  constructor(
    public uiext: UiExtensionService
  ) {

  }

  ngOnInit() {
    // console.log(this.user);
    if (this.user) {
      this.formatUser();
    }
  }

  ngOnDestroy() {

  }

  viewPhoto() {
    // console.log("view photo");
    if (this.user && this.hasPhoto) {
      let params: IPhotoFrameNavParams = {
        photoUrl: this.user.photoUrl,
        title: this.user.name,
        sharePhoto: false,
        uploadPhoto: false,
        isDataUrl: false
      };
      let navParams: INavParams = {
        view: {
          fullScreen: false,
          transparent: false,
          large: false,
          addToStack: true,
          frame: false
        },
        params: params
      };
      this.uiext.showCustomModal(null, PhotoFrameViewComponent, navParams).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }


  formatUser() {
    if (this.user) {
      // console.log(JSON.stringify(this.user));
      if (!this.user.photoUrl) {
        this.bgPhoto = EPhotos.userPlaceholder;
      } else {
        this.bgPhoto = this.user.photoUrl;
        this.hasPhoto = true;
      }

      if (this.user.id === GeneralCache.userId) {
        this.self = true;
      }

      let score: number = this.user.cachedScore ? this.user.cachedScore : 0;
      this.scoreDisp = score + " XP";
      this.headingDisp = "" + GeneralUtils.getOrdinalString(this.user.rank) + " (" + this.scoreDisp + ")";
      let level: number = this.user.cachedLevel ? this.user.cachedLevel : 0;
      this.levelDisp = "Level " + level;
      this.userName = StringUtils.trimNameMultilineHTML(this.user.name, EMessageTrim.mpLeaderboardUsernameCard);
      this.userAlias = "@" + this.user.alias;
      this.activityStats = ActivityStatsUtils.getStatList(this.user.activityStats);
      this.loaded = true;
    }
  }
}
