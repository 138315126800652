<!-- bg-filter-2 -->
<div class="flex-card category-card bg-container item-border-radius" tappable
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: data.photoUrl}">

  <div class="content">
    <ion-grid class="full-w grid-center-absolute">
      <ion-row>
        <ion-col size="12">
          <div class="width-m text-color-overlay">
            <span class="info-text-mlg text-color-element bold">{{data.name}}</span>
          </div>
        </ion-col>
      </ion-row>
      <ion-row class="padding-xs" *ngIf="!data.available">
        <ion-col size="2" class="ion-float-left">
          <div>
            <div class="leplace-icon icon-color-primary">
              <icon-wrapper [icon]="appIconsStandard.locked"></icon-wrapper>
            </div>
          </div>
        </ion-col>
        <ion-col size="8">
          <div>
            <span class="info-text-s bold text-color-overlay-s">{{priceInfo}}</span>
          </div>
        </ion-col>
        <ion-col size="2" class="ion-float-right">
          <div>
            <div class="leplace-icon icon-color-primary">
              <icon-wrapper [icon]="appIconsStandard.locked"></icon-wrapper>
            </div>
          </div>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>