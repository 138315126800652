<ion-row class="slider-controls control-icon">
  <ion-col class="col-with-arrow" (click)="slidePrev()" no-padding size="1">
    <icon-wrapper *ngIf="showLeftButton" icon="caret-back" iconIos="chevron-back-circle-outline"></icon-wrapper>
  </ion-col>
  <ion-col size="10" #sliderContainer>
    <ion-slides #slides [pager]="true" [options]="sliderOptions" class="slider-container-light" *ngIf="loaded">
      <ion-slide *ngFor="let p of chatheads; trackBy: trackByFn" fxLayout="column"
        class="padding-xs slide-height slide-center slide-avatar">

        <ion-grid class="full-w no-padding" [ngClass]="{'card-selected': p.self, 'card-disabled': !p.isOnline}">
          <ion-row class="center-items center-text">
            <ion-col size="12" class="no-padding">
              <img [src]="p.photoUrlDisp" alt="Avatar" class="img-avatar-small-crop">
            </ion-col>
          </ion-row>
          <ion-row class="center-items center-text">
            <ion-col size="12" class="no-padding">
              <div>
                <span class="bold text-color-primary info-text-xs">{{p.playerName}}</span>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>

      </ion-slide>
    </ion-slides>
  </ion-col>
  <ion-col class="col-with-arrow control-icon" (click)="slideNext()" no-padding size="1">
    <icon-wrapper *ngIf="showRightButton" icon="caret-forward" iconIos="chevron-forward-circle-outline">
    </icon-wrapper>
  </ion-col>
</ion-row>
<ion-row>
  <ion-col class="center-text">
    <span class="info-text-s">{{summaryDisp}}</span>
  </ion-col>
</ion-row>