<div *ngIf="availableItems">
  <ion-grid class="full-w">
    <ion-row *ngFor="let itemRow of itemGrid; let i = index">
      <ion-col *ngFor="let item of itemRow" class="padding-s">
        <dynamic-content *ngIf="item != null" class="full-w" [type]="type" [update]="update" [context]="item" (action)="itemAction($event)">
        </dynamic-content>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<div *ngIf="!availableItems && loaded" class="padding-s center-text">
  <span class="center-text info-text-m bold text-color-primary">{{emptyMessage}}</span>
</div>