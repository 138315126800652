import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';

@Component({
  selector: 'icon-wrapper',
  templateUrl: './icon-wrapper.component.html',
  styleUrls: ['./icon-wrapper.component.scss'],
})
export class IconWrapperComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  slot: string;

  @Input()
  custom: boolean;

  @Input()
  icon: string;

  @Input()
  iconIos: string;

  @Input()
  customClass: string;

  @Input()
  huge: boolean;

  activeClass: string = "leplace-icon-fixed leplace-icon";

  adapter: boolean = true;
  show: boolean = true;
  iconIosActive: string = "";
  iconMdActive: string = "";

  constructor() { }

  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      // console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'huge':
            this.initDisp();
            break;
          case 'icon':
            this.initDisp();
            this.refresh();
            break;
        }
      }

    }
  }

  ngOnInit() {
    this.initDisp();
  }

  refresh() {
    this.show = false;
    setTimeout(() => {
      this.show = true;
    }, 1);
  }

  initDisp() {
    if (this.huge) {
      this.activeClass = "leplace-icon-fixed-huge leplace-icon";
    }
    if (this.customClass) {
      this.activeClass = "leplace-icon-fixed " + this.customClass;
    }
    if (!this.custom) {
      if (this.adapter) {
        if (this.icon != null) {
          this.iconIosActive = (this.iconIos != null) ? this.iconIos : this.icon;
          this.iconMdActive = this.icon;
        }
      }
    }
  }

  ngOnDestroy() {

  }

}
