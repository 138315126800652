import { IPlatformFlags } from "../../classes/def/app/platform";
import { Injectable } from "@angular/core";
import { LocationMonitorService } from "./location-monitor";
import { IGeolocationResult } from "../../classes/def/map/map-data";
import { SettingsManagerService } from "../general/settings-manager";
import { AnalyticsService } from "../general/apis/analytics";
import { Platform } from "@ionic/angular";
import { UiExtensionService } from '../general/ui/ui-extension';
import { BackgroundModeWatchService } from "../general/apis/background-mode-watch";
import { BLE, BLEScanOptions } from '@awesome-cordova-plugins/ble/ngx';
import { ResourceManager } from "src/app/classes/general/resource-manager";
import { BehaviorSubject, Observable, timer } from "rxjs";
import { PromiseUtils } from "../utils/promise-utils";
import { BLEUtils, IBeaconLocateResult, IBLEDevice, IBeaconSpecs, IBeaconStats, IBLEDeviceDict } from "./ble-utils";
import { GeneralCache } from "src/app/classes/app/general-cache";
import { EOS } from "src/app/classes/def/app/app";
import { ArrayUtils } from "../utils/array-utils";
import { MathUtils } from 'src/app/classes/general/math';
import { ILatLng } from 'src/app/classes/def/map/coords';
import { WebviewUtilsService } from "../app/utils/webview-utils";


@Injectable({
    providedIn: 'root'
})
export class IndoorLocationManagerService {

    platform: IPlatformFlags = {} as IPlatformFlags;
    prevLoc: IGeolocationResult = null;

    devices: IBLEDeviceDict = {};
    devicesList: IBLEDevice[] = [];

    deviceEntries: IBeaconSpecs[] = [
        {
            id: "DC:0D:C6:89:71:EF",
            lat: 44.4564929,
            lng: 26.0809785,
            x: 0,
            y: 0,
            z: 0,
            sig: [
                { d: 0.5, s: -50 },
                { d: 1, s: -60 },
                { d: 2, s: -70 }
            ]
        },
        {
            id: "DC:0D:31:E3:71:00",
            lat: null,
            lng: null,
            x: 0,
            y: 3,
            z: 0,
            sig: [
                { d: 0.5, s: -50 },
                { d: 1, s: -60 },
                { d: 2, s: -70 }
            ]
        },
        {
            id: "DC:0D:C7:E8:D5:19",
            lat: null,
            lng: null,
            x: 3,
            y: 3,
            z: 0,
            sig: [
                { d: 0.5, s: -50 },
                { d: 1, s: -60 },
                { d: 2, s: -70 }
            ]
        },
        {
            id: "DC:0D:C5:A0:FA:63",
            lat: null,
            lng: null,
            x: 3,
            y: 0,
            z: 0,
            sig: [
                { d: 0.5, s: -50 },
                { d: 1, s: -60 },
                { d: 2, s: -70 }
            ]
        }
    ];
    deviceEntriesDict: { [key: string]: IBeaconSpecs } = {};

    observables = {
        refresh: null
    };

    watches = {

    };

    subscription = {
        bleScanner: null,
        locator: null
    };

    flags = {
        watchLocationEnabled: false,
    };

    timeouts = {

    };

    options = {
        expireTsDelta: 5000,
        minRSSI: -90
    };

    scannerRunning: boolean = false;

    estimate: IBeaconLocateResult;
    estimateCoords: ILatLng;

    constructor(
        public locationMonitor: LocationMonitorService,
        public settings: SettingsManagerService,
        public analytics: AnalyticsService,
        public uiext: UiExtensionService,
        public bgmWatch: BackgroundModeWatchService,
        public plt: Platform,
        public webView: WebviewUtilsService,
        public ble: BLE
    ) {
        console.log("indoor location manager service created");
        this.observables.refresh = new BehaviorSubject(null);
        this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.onPlatformLoaded(SettingsManagerService.settings.platformFlags);
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    watchUpdate(): BehaviorSubject<any> {
        return this.observables.refresh;
    }

    getDevices(): IBLEDeviceDict {
        return this.devices;
    }

    onPlatformLoaded(platform: IPlatformFlags) {
        console.log("location manager set platform: ", platform);
        this.platform = platform;
    }

    loadDeviceList() {
        let init: boolean = BLEUtils.computeBeaconFleetCoords(this.deviceEntries);
        console.log("beacon fleet initialized: ", init);
        console.log("beacons: ", this.deviceEntries);
        for (let device of this.deviceEntries) {
            this.deviceEntriesDict[device.id] = device;
        }
    }

    isScannerRunning() {
        return this.scannerRunning;
    }

    startWizard() {
        this.loadDeviceList();
        this.startBeaconScanner();
        this.startLocator();
    }

    stopWizard() {
        this.stopLocator();
        for (let key of Object.keys(this.devices)) {
            if (this.devices[key].rssiTracker) {
                ResourceManager.clearSub(this.devices[key].rssiTracker);
            }
        }
        this.devices = {};
        this.devicesList = [];
        PromiseUtils.wrapNoAction(this.stopBeaconScanner(), true);
    }

    startLocator() {
        if (this.subscription.locator != null) {
            console.warn("locator already started");
            return;
        }
        let timer1: Observable<number> = timer(0, 100);

        let k = 0;
        this.subscription.locator = timer1.subscribe(() => {
            // check for expired devices
            this.getDeviceList();
            this.checkExpireDevices();
            this.filterRSSI(0.8);
            if (k < 5) {
                k++;
            } else {
                k = 0;
                console.log("ble devices crt: ");
                let stats: IBeaconStats[] = this.computeBeaconDistances();
                BLEUtils.printDeviceStats(this.devicesList);
                this.estimate = BLEUtils.locateWizard(stats);
                console.log("locate: ", this.estimate.coords, " (M" + this.estimate.mode + ")");
                this.estimateCoords = BLEUtils.toGPSCoords(this.estimate.coords, this.deviceEntries);
                console.log("locate coords: ", this.estimateCoords);
                this.observables.refresh.next(true);
            }
        }, (err) => {
            console.error(err);
        });
    }

    getDeviceList() {
        let keys: string[] = Object.keys(this.devices);
        let devicesList: IBLEDevice[] = [];
        for (let key of keys.sort()) {
            devicesList.push(this.devices[key]);
        }
        let registeredDevices: IBLEDevice[] = devicesList.filter(dev => dev.registered);
        let otherDevices: IBLEDevice[] = devicesList.filter(dev => !dev.registered);
        this.devicesList = registeredDevices.concat(otherDevices);
    }

    filterRSSI(alpha: number) {
        for (let device of this.devicesList) {
            if (device.rssiFiltered == null) {
                device.rssiFiltered = device.rssi;
            }
            device.rssiFiltered = MathUtils.lowPassFilter(device.rssiFiltered, device.rssi, alpha);
        }
    }

    computeBeaconDistances(): IBeaconStats[] {
        let stats: IBeaconStats[] = [];
        for (let device of this.devicesList) {
            if (device.registered) {
                let deviceEntry: IBeaconSpecs = this.deviceEntriesDict[device.id];
                if (deviceEntry != null) {
                    let stat: IBeaconStats = {
                        x: deviceEntry.x,
                        y: deviceEntry.y,
                        z: deviceEntry.z,
                        sig: device.rssi,
                        r: BLEUtils.computeDistanceDevice(deviceEntry, device)
                    }
                    device.dist = stat.r;
                    stats.push(stat);
                }
            }
        }
        if (stats.length > 0) {
            // sort by RSSI in descending order (from strogest signal to weakest signal)
            stats = ArrayUtils.sortArrayByObjectKey(stats, "sig", false);
        }
        return stats;
    }


    checkExpireDevices() {
        let timeCrt: number = new Date().getTime();
        for (let device of this.devicesList) {
            let expire: boolean = false;
            if ((timeCrt - device.lastTs) > this.options.expireTsDelta) {
                expire = true;
            }
            if (device.rssi < this.options.minRSSI) {
                expire = true;
            }
            if (expire) {
                console.log("expire ble device: ", device);
                if (this.devices[device.id].rssiTracker) {
                    ResourceManager.clearSub(this.devices[device.id].rssiTracker);
                }
                delete this.devices[device.id];
            }
        }
    }

    /**
     * register device check dict
     * @param device 
     * @param deviceDict 
     * @returns 
     */
    registerDevice(device: IBLEDevice, deviceDict: { [key: string]: IBeaconSpecs }) {
        if (!device) {
            return;
        }
        let registered: boolean = deviceDict[device.id] != null;
        // only track registered beacons (defined in the app) for location estimator
        if (registered || true) {
            device.lastTs = new Date().getTime();
            // parse advertising data
            if (GeneralCache.os === EOS.android) {
                device.advertisingDict = BLEUtils.parseAdvertisingDataAndroid(device.advertising);
            } else {
                device.advertisingDict = device.advertising;
            }
            if (this.devices[device.id] == null) {
                console.log("new ble device: ", device);
                device.pingCount = 0;
                this.devices[device.id] = device;
                // // only works if connected
                // device.rssiTracker = timer(0, 250).subscribe(() => {
                //     this.ble.readRSSI(device.id).then((rssi: number) => {
                //         console.log("read rssi for " + device.id + ": " + rssi);
                //         device.rssi = rssi;
                //     }).catch((err) => {
                //         console.error('unable to read RSSI', err);
                //     });
                // }, (err) => {
                //     console.error(err);
                // });
            } else {
                this.devices[device.id] = Object.assign(this.devices[device.id], device);
                this.devices[device.id].pingCount += 1;
            }
            this.devices[device.id].registered = registered;
            if (deviceDict[device.id] != null) {
                this.devices[device.id].beaconSpecs = deviceDict[device.id];
            }
        }
    }

    stopLocator() {
        this.subscription.locator = ResourceManager.clearSub(this.subscription.locator);
    }

    startBeaconScanner() {
        if (this.scannerRunning) {
            console.warn("beacon scanner already started");
            return;
        }
        console.log("start ble scanner");
        let options: BLEScanOptions = {
            reportDuplicates: true
        };
        this.scannerRunning = true;
        if (this.platform.WEB) {
            // mock
            let t = timer(0, 500);
            let index: number = 0;
            let ndev: number = this.deviceEntries.length;
            let inc: number = Math.floor(ndev / 5);
            if (inc === 0) {
                inc = 1;
            }

            this.subscription.bleScanner = t.subscribe(() => {
                // update each <inc> devices
                for (let i = 0; i < inc; i++) {
                    if ((index + i) >= this.deviceEntries.length) {
                        break;
                    }
                    let dev = this.deviceEntries[index + i];
                    if (dev != null) {
                        let rssi: number = Math.floor(-(30 + Math.random() * 50));
                        let device: IBLEDevice = {
                            id: dev.id,
                            rssi: rssi,
                            rssiFiltered: rssi,
                            registered: false,
                            lastTs: null,
                            pingCount: 0,
                            advertising: null,
                            advertisingDict: null
                        }
                        this.registerDevice(device, this.deviceEntriesDict);
                    }
                }
                index += 1;
                if ((index + inc - 1) >= this.deviceEntries.length) {
                    index = 0;
                }
            });
        } else {
            this.subscription.bleScanner = this.ble.startScanWithOptions([], options).subscribe((device: IBLEDevice) => {
                if (device != null) {
                    this.registerDevice(device, this.deviceEntriesDict);
                }
            }, (err) => {
                console.error(err);
            });
        }
    }

    stopBeaconScanner() {
        return new Promise((resolve, reject) => {
            if (!this.scannerRunning) {
                console.warn("beacon scanner already stopped");
                resolve(false);
            }
            this.subscription.bleScanner = ResourceManager.clearSub(this.subscription.bleScanner);
            if (this.platform.WEB) {
                this.scannerRunning = false;
            } else {
                this.ble.stopScan().then(() => {
                    console.log("ble scanner stopped");
                    this.scannerRunning = false;
                    resolve(true);
                }).catch((err) => {
                    console.error(err);
                    this.scannerRunning = false;
                    reject(err);
                });
            }
        });
    }
}


