export interface IMessageTimelineEntry {
    message: string;
    timedelta: number;
    messageCategoryCode: number;
}

export interface IMessageTimelineCategory {
    code: number;
    entries: IMessageTimelineEntry[];
}

export enum EMessageTimelineCodes {
    worldMap = 1,
    storylineMap = 2,
    AR = 3
}
