


import { Injectable } from "@angular/core";
import { MathUtils } from "../../../classes/general/math";
import { BufferUtilsService } from "./buffer-utils";
import {
    ISignalData, ISignalProcessingDataContainer, ISignalProcessingParams,
    ISignalProcessingParamsContainer, ISoundIntervalCounts, ITempoCounts,
    IFFTBin, ISignalProcessingOptions, ESignalProcessingFilter
} from "../../../classes/def/processing/signal";
import { ISampleBufferElement, ICircularBuffer } from 'src/app/classes/utils/buffer';
import { ISoundBin } from 'src/app/classes/def/media/sound';
import { DeepCopy } from 'src/app/classes/general/deep-copy';


/**
 * some resources
 * 
 * http://archive.gamedev.net/archive/reference/programming/features/beatdetection/index.html
 * http://mziccard.me/2015/05/28/beats-detection-algorithms-1/
 * 
 */
@Injectable({
    providedIn: 'root'
})
export class SignalProcessingService {

    dataInit: ISignalData = {
        timeDomain: {
            sample: 0,
            shortFilter: 0,
            longFilter: 0,
            filterDiff: 0,
            filterDiffPrev: 0,
            filterDiffFilter: 0,
            timestamp: 0,
            timestampPrev: 0
        },
        frequencyDomain: {
            fftData: [],
            soundBins: [],
            scaleFilter: 0
        },
        beatDetection: {
            beatFlag: false,
            beatFlagPrev: false,
            beatCounter: 0,
            rawBpm: 0,
            bpm: 0,
            beatZone: 0,
            beatZoneTap: 0,
            lastTimestamp: 0,
            cutoffTimestamp: 0,
            cutoffDetected: false
        },
        validation: {
            timeCounter: 0,
            targetBpm: 0,
            targetValue: 0,
            maxBpm: 200
        }
    };

    data: ISignalProcessingDataContainer = {};

    paramsInit: ISignalProcessingParams = {
        /** 
         * Normally a value that exceeds the average plus its half is a good threshold to detect a beat
         * 1 + 25%
         */
        compareFactor: 1.25,
        diffThreshold: 0.2,
        alphaDecay: 0.8,
        alphaDecayInit: 0.95,
        alphaDecayAccel: 0.9,
        alphaShort: 0.8,
        alphaLong: 0.95,
        cutoffLevel: 20,
        cutoffCounterTimeout: 2,
        maxBpm: 180,
        minBpm: 60
    };

    params: ISignalProcessingParamsContainer = {};

    constructor(
        public bufferUtils: BufferUtilsService
    ) {
        console.log("signal processing service created");
        // this.initData();
    }


    /**
     * initialize the container
     * @param container 
     */
    initData(container: string) {
        this.data[container] = DeepCopy.deepcopy(this.dataInit);
        this.data[container].timeDomain.timestamp = new Date().getTime();
        this.data[container].timeDomain.timestampPrev = new Date().getTime();
        let scope: ICircularBuffer = {
            array: [],
            size: 1000,
            fillIndex: 0,
            circularIndex: 0,
            computeIndex: 0
        };
        this.bufferUtils.initBuffer(container, scope);
        this.params[container] = DeepCopy.deepcopy(this.paramsInit);
        return this.data[container];
    }

    setTargetValue(container: string, val: number) {
        this.data[container].validation.targetValue = val;
    }

    /**
     * get params
     * @param container 
     */
    getBpParams(container: string) {
        return this.params[container];
    }

    /**
     * set params
     * @param container 
     * @param params 
     */
    setBpParams(container: string, params: ISignalProcessingParams) {
        this.params[container] = params;
        console.log("bparams set: ", params);
    }



    /**
     * Function to identify peaks
     * @param data 
     * @param threshold 
     */
    getPeaksAtThreshold(data: number[], threshold: number) {
        let peaksArray: number[] = [];
        let length: number = data.length;
        for (let i = 0; i < length; i++) {
            if (data[i] > threshold) {
                peaksArray.push(i);
                // Skip forward ~ 1/4s to get past this peak.
                i += 10000;
            }
            i++;
        }

        return peaksArray;
    }

    /**
     * Function to identify peaks
     * based on timestamps, min interval, max bpm
     * @param buffer 
     * @param threshold 
     */
    getPeaksAtThreshold2(array: ISampleBufferElement[], threshold: number, params: ISignalProcessingParams) {
        let minTsBeat: number = 1000 / (params.maxBpm / 60);
        let lastTsBeat: number = 0;
        let peaksArray: number[] = [];
        let beatLock: boolean = false;
        for (let i = 0; i < array.length; i++) {
            if (array[i]) {
                if (beatLock) {
                    if ((array[i].value / threshold) < params.compareFactor) {
                        beatLock = false;
                    }
                } else {
                    if ((array[i].value / threshold) > params.compareFactor) {
                        beatLock = true;
                        // console.log(buffer.array[i].value, threshold);
                        let interval: number = array[i].timestamp - lastTsBeat;
                        if (interval > minTsBeat) {
                            // console.log("passed");
                            lastTsBeat = array[i].timestamp;
                            peaksArray.push(i);
                        }
                    }
                }
            }
        }
        return peaksArray;
    }

    /**
     * Function used to return a histogram of peak intervals
     * @param peaks 
     */
    countIntervalsBetweenNearbyPeaks(peaks: number[]) {
        let intervalCounts: ISoundIntervalCounts[] = [];
        // let searchRange: number = peaks.length / 10;
        // let searchRange: number = peaks.length;
        peaks.forEach((peak: number, index: number) => {
            // check nearby neighbours
            let searchRange: number = peaks.length - index;
            // if (searchRange > 10) {
            //     searchRange = 10;
            // }
            for (let i = 0; i < searchRange; i++) {
                // redundant
                if ((index + i) < peaks.length) {
                    // get the array distance between peak indices (translated into time interval between peaks)
                    let interval: number = peaks[index + i] - peak;
                    // add new interval or update an existing interval count
                    let foundInterval: ISoundIntervalCounts = intervalCounts.find((intervalCount: ISoundIntervalCounts) => intervalCount.interval === interval);
                    if (foundInterval) {
                        foundInterval.count++;
                    } else {
                        intervalCounts.push({
                            interval: interval,
                            count: 1
                        });
                    }
                }
            }
        });
        return intervalCounts;
    }

    /**
     * Function used to return a histogram of tempo candidates.
     * @param intervalCounts 
     */
    groupNeighborsByTempo(intervalCounts: ISoundIntervalCounts[], samplingTime: number, params: ISignalProcessingParams) {
        let tempoCounts: ITempoCounts[] = [];
        intervalCounts.forEach((intervalCount, _i) => {
            if (intervalCount.interval !== 0) {
                // Convert an interval to tempo
                let theoreticalTempo = 60 / (intervalCount.interval * samplingTime);

                let maxiter: number = 10;
                let iterCount: number = maxiter;


                /**
                 * Some of the basic tempo markings
                    Largo is 40-60 BPM
                    Larghetto is 60-66 BPM
                    Adagio is 66-76 BPM
                    Andante is 76-108 BPM
                    Moderato is 108-120 BPM
                    Allegro is 120-168 BPM
                    Presto is 168-200 BPM
                    Prestissimo is 200+ BPM
                 */

                // Adjust the tempo to fit within the desired BPM range
                while (theoreticalTempo < params.minBpm) {
                    theoreticalTempo *= 2;
                    iterCount -= 1;
                    if (iterCount <= 0) {
                        break;
                    }
                }

                iterCount = maxiter;
                while (theoreticalTempo > params.maxBpm) {
                    theoreticalTempo /= 2;
                    iterCount -= 1;
                    if (iterCount <= 0) {
                        break;
                    }
                }


                // build the tempo array
                let foundTempo: ITempoCounts = tempoCounts.find(tempoCount => tempoCount.tempo === theoreticalTempo);
                if (foundTempo) {
                    foundTempo.count += intervalCount.count;
                } else {
                    tempoCounts.push({
                        tempo: theoreticalTempo,
                        count: intervalCount.count
                    });
                }
            }
        });

        let finalTempoCounts: ITempoCounts[] = [];

        // merge neighbors

        // for (let i = 0; i < tempoCounts.length; i++) {
        //     let tc1 = tempoCounts[i];
        //     let averageNeighbors: number = tc1.tempo;
        //     let neighborCount: number = 1;
        //     let totalCount: number = tc1.count;
        //     for (let j = i; j < tempoCounts.length; j++) {
        //         let tc2 = tempoCounts[j];
        //         if (Math.abs(tc1.tempo - tc2.tempo) < 2) {
        //             averageNeighbors += tc2.tempo;
        //             neighborCount += 1;
        //             totalCount += tc2.count;
        //         }
        //     }
        //     tempoCounts[i].tempo = averageNeighbors / neighborCount;
        //     tempoCounts[i].count = totalCount;
        // }

        for (let i = 0; i < tempoCounts.length; i++) {
            let found: boolean = false;
            for (let j = 0; j < finalTempoCounts.length; j++) {
                if (finalTempoCounts[j].tempo === tempoCounts[i].tempo) {
                    found = true;
                    break;
                }
            }
            if (!found) {
                finalTempoCounts.push(tempoCounts[i]);
            }
        }

        finalTempoCounts = finalTempoCounts.sort((a, b) => {
            if (a.count > b.count) {
                return -1;
            }
            if (a.count < b.count) {
                return 1;
            }
            return 0;
        });

        return finalTempoCounts;
    }

    /**
     * detect and count beats
     * beat zone
     * @param data 
     * @params params 
     */
    beatCounter(data: ISignalData, params: ISignalProcessingParams) {
        data.beatDetection.beatFlag = false;
        let abs: number = Math.abs(data.timeDomain.filterDiff);

        data.timeDomain.filterDiffFilter = MathUtils.lowPassFilter(data.timeDomain.filterDiffFilter, abs, params.alphaLong);

        let absPrev: number = Math.abs(data.timeDomain.filterDiffFilter);

        // console.log(abs);

        // check for beat (if the current sample is higher than the previous sample by a specified factor)
        // should average out multiple samples, because at high sampling rates, it's hard to have so much difference between the samples

        let ts: number = new Date().getTime();

        // max bpm = 200

        let minTsBeat: number = 1000 / (params.maxBpm / 60);
        minTsBeat = 0;

        // check the beat threshold (dynamic)
        if ((ts - data.beatDetection.lastTimestamp) > minTsBeat) {
            if (abs > params.diffThreshold) {
                if ((abs / absPrev) > params.compareFactor) {
                    data.beatDetection.beatFlag = true;
                    data.beatDetection.lastTimestamp = ts;
                }
            }
        }

        // check the sound level
        if (data.timeDomain.sample < params.cutoffLevel) {
            if (!data.beatDetection.cutoffDetected) {
                data.beatDetection.cutoffTimestamp = ts;
                data.beatDetection.cutoffDetected = true;
            }

            if ((ts - data.beatDetection.cutoffTimestamp) >= params.cutoffCounterTimeout) {
                data.beatDetection.beatFlag = false;
            }
        } else {
            data.beatDetection.cutoffDetected = false;
        }

        // scale the beat to percent, with decay
        if (data.beatDetection.beatFlag) {
            data.beatDetection.beatZone = 100;
            data.beatDetection.beatZoneTap = 100;
        } else {
            // accelerated filtering
            if (data.beatDetection.beatZone > 0) {
                data.beatDetection.beatZone = Math.floor(data.beatDetection.beatZone * params.alphaDecay);
                data.beatDetection.beatZoneTap = Math.floor(data.beatDetection.beatZoneTap * params.alphaDecay);
                params.alphaDecay = params.alphaDecay * params.alphaDecayAccel;
            } else {
                params.alphaDecay = params.alphaDecayInit;
            }
        }

        // count the beats
        // check for beat transition, count only once for each beat
        if (data.beatDetection.beatFlag && !data.beatDetection.beatFlagPrev) {
            data.beatDetection.beatCounter += 1;
        }

        data.beatDetection.beatFlagPrev = data.beatDetection.beatFlag;
    }


    /**
     * count the time that is spent above the target level
     * @param bpd 
     * @param samplingTime in seconds
     */
    levelCounter(bpd: ISignalData, samplingTime: number) {
        // console.log(bpd);
        if (bpd.timeDomain.shortFilter >= bpd.validation.targetValue) {
            if (bpd.timeDomain.timestampPrev) {
                bpd.validation.timeCounter += samplingTime;
            }
        }
    }


    /**
     * process frequency domain data for beat detection on multiple ranges
     * @param container 
     * @param fftData 
     * @param options 
     */
    processFFTSample(container: string, fftData: IFFTBin[], options: ISignalProcessingOptions) {
        let debug: boolean = false;
        let data: ISignalData = this.data[container];
        // let params: ISignalProcessingParams = this.bpParams[container];
        data.frequencyDomain.fftData = fftData;
        data.frequencyDomain.soundBins = options.fft.soundBins;

        let fftSize: number = fftData.length;

        let values: number = 0;
        for (let i = 0; i < fftSize; i++) {
            let freq: number = fftData[i].frequency;
            let power: number = fftData[i].power;
            values += power;
            for (let j = 0; j < data.frequencyDomain.soundBins.length; j++) {
                let sb: ISoundBin = data.frequencyDomain.soundBins[j];
                if ((freq >= sb.lowerLimit) && ((freq < sb.upperLimit) || (sb.upperLimit === 0))) {
                    sb.value += power;
                    sb.count += 1;
                    break;
                }
            }
        }

        // the scale (max range) is the max power and a little bit more
        let graphScale: number = 0;

        for (let j = 0; j < data.frequencyDomain.soundBins.length; j++) {
            let sb: ISoundBin = data.frequencyDomain.soundBins[j];
            if (sb.count > 0) {
                sb.value = sb.value / sb.count;

                if (sb.value < sb.cutoffLimit) {
                    sb.value = 0;
                }
            }
            if (sb.value > graphScale) {
                graphScale = sb.value * 1.1;
            }
        }

        let average: number = values / fftSize;

        if (debug) {
            console.log(average);
        }

        let scaleFilter: number = data.frequencyDomain.scaleFilter;

        scaleFilter = MathUtils.lowPassFilter(scaleFilter, graphScale, options.fft.alphaScale);
        data.frequencyDomain.scaleFilter = scaleFilter;

        // data.timeDomain.sample = average;
        // data.timeDomain.shortFilter = average;
        // data.timeDomain.longFilter = average;

        let sb: ISoundBin[] = data.frequencyDomain.soundBins;

        // apply beat detection on the bass line, warning: runs on the same container!
        // let sample: number = data.frequencyDomain.soundBins[0].value;
        let sample: number = (sb[0].value + sb[1].value) / 2;

        // console.log(fftData.map(d=>d.frequency));
        // console.log(data.frequencyDomain.soundBins);

        // console.log(sample);
        data.beatDetection = this.processSample(container, sample, options).beatDetection;

        // return the real time data object
        return data;
    }

    resetBeatZone(container: string) {
        let data: ISignalData = this.data[container];
        if (!data) {
            return;
        }
        data.beatDetection.beatZoneTap = 0;
    }

    /**
     * run beat detector each sample
     * add to buffer, then process batch
     * https://www.parallelcube.com/2018/03/30/beat-detection-algorithm/
     * http://joesul.li/van/beat-detection-using-web-audio/
     * 
     * @param sample 
     */
    processSample(container: string, sample: number, options: ISignalProcessingOptions) {
        let data: ISignalData = this.data[container];
        let params: ISignalProcessingParams = this.params[container];

        if (!data) {
            data = this.initData(container);
        }

        // console.log(container);

        // sample timestamp
        data.timeDomain.timestamp = new Date().getTime();
        data.timeDomain.sample = sample;

        switch (options.filter) {
            case ESignalProcessingFilter.nofilter:
                data.timeDomain.shortFilter = sample;
                data.timeDomain.longFilter = sample;
                data.timeDomain.filterDiff = sample;
                break;
            case ESignalProcessingFilter.lowPass:
                data.timeDomain.shortFilter = sample;
                data.timeDomain.longFilter = MathUtils.lowPassFilter(data.timeDomain.longFilter, sample, params.alphaLong);
                data.timeDomain.filterDiff = data.timeDomain.shortFilter - data.timeDomain.longFilter;
                break;
            case ESignalProcessingFilter.lowPassDual:
                // low pass filter 1
                data.timeDomain.shortFilter = MathUtils.lowPassFilter(data.timeDomain.shortFilter, sample, params.alphaShort);
                // low pass filter 2 (higher time constant)
                data.timeDomain.longFilter = MathUtils.lowPassFilter(data.timeDomain.longFilter, sample, params.alphaLong);
                // difference between the two filters/dynamic beat filter
                data.timeDomain.filterDiff = data.timeDomain.shortFilter - data.timeDomain.longFilter;
                break;
            case ESignalProcessingFilter.envelope:
                data.timeDomain.shortFilter = sample;
                data.timeDomain.longFilter = MathUtils.lowPassFilter(data.timeDomain.longFilter, sample, params.alphaLong);
                data.timeDomain.filterDiff = MathUtils.envelopeFilter(data.timeDomain.filterDiff, data.timeDomain.sample, data.timeDomain.longFilter, params.alphaDecay);
                break;
        }


        let samplingTime: number = (data.timeDomain.timestamp - data.timeDomain.timestampPrev) / 1000;

        // beat counter should use filtered beats

        if (options.beatZone) {
            this.beatCounter(data, params);
        }

        if (options.level) {
            this.levelCounter(data, samplingTime);
        }

        if (options.bpm) {
            this.processBuffer(container, samplingTime);
        }

        // update previous values
        data.timeDomain.timestampPrev = data.timeDomain.timestamp;
        data.timeDomain.filterDiffPrev = data.timeDomain.filterDiff;

        // return the real time data object
        return data;
    }

    /**
    * run beat detector sample buffer
    * @param container
    * @param samplingTime in seconds
    */
    processBuffer(container: string, samplingTime: number) {
        let data: ISignalData = this.data[container];
        let params: ISignalProcessingParams = this.params[container];

        // check bpm
        let sb: ISampleBufferElement = {
            value: data.timeDomain.shortFilter,
            timestamp: data.timeDomain.timestamp
        };
        let buffer: ICircularBuffer = this.bufferUtils.circularAdd(container, sb);
        buffer.computeIndex += 1;
        if (buffer.computeIndex >= 5) {
            buffer.computeIndex = 0;

            // check for sound power cutoff (sound level too low)
            // this is only valid if the signal comes from FFT
            if (data.timeDomain.longFilter < 1) {
                data.beatDetection.bpm = 0;
            } else {
                // Identify peaks in the song, which we can interpret as drum hits
                let peaksArray: number[] = this.getPeaksAtThreshold2(this.bufferUtils.circularGetInOrder(container), data.timeDomain.longFilter, params);
                // console.log("peaks array: ", peaksArray);
                // Create an array composed of the most common intervals between drum hits
                let intervalCounts: ISoundIntervalCounts[] = this.countIntervalsBetweenNearbyPeaks(peaksArray);
                // console.log("interval counts: ", intervalCounts);
                // Group the count of those intervals by tempos they might represent.
                let tempoCounts: ITempoCounts[] = this.groupNeighborsByTempo(intervalCounts, samplingTime, params);
                // We assume that any interval is some power-of-two of the length of a measure
                // We assume the tempo is between 90-180BPM
                // Select the tempo that the highest number of intervals point to
                // console.log("tempo counts: ", tempoCounts);

                // tempo counts are sorted
                if (tempoCounts.length > 0) {
                    let tempo: number = tempoCounts[0].tempo;
                    // console.log(tempo);
                    data.beatDetection.bpm = MathUtils.lowPassFilter(data.beatDetection.bpm, tempo, 0.95);
                }
            }
        }
    }
}

