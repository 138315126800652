<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="dismiss()" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div class="scrollbar-y padding-s" [ngClass]="{'scroll-content-modal-1' : withButtons}">
    <responsive-img [photoUrl]="photoUrl" [showLoading]="true" [fixedZoom]="expanded" [zoomState]="expanded"
      [withZoom]="!expanded"></responsive-img>
  </div>

  <div fxFlex>
  </div>

  <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
    <ion-grid class="full-w">
      <ion-row *ngIf="enable.share">
        <ion-col size="12">
          <ion-button class="action-button-fill button-color-primary" (click)="sharePhoto()">
            <span class="button-font-size-s">Share</span>
            <icon-wrapper slot="start" class="slot-icon-padding" [icon]="appIconsStandard.image" [custom]="false">
            </icon-wrapper>
          </ion-button>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="enable.upload">
        <ion-col size="12">
          <ion-button class="action-button-fill button-color-primary" (click)="uploadPhoto()">
            <span class="button-font-size-s">Upload</span>
            <icon-wrapper slot="start" class="slot-icon-padding" [icon]="appIconsStandard.image" [custom]="false">
            </icon-wrapper>
          </ion-button>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</modal-container>