
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GenericDataService } from '../general/data/generic';
import { IGenericResponse } from '../../classes/def/requests/general';
import { ITutorial, ETutorialCategory } from 'src/app/classes/def/app/tutorials';
import { IActivityQuestSpecs, IActivityQuestResponse } from 'src/app/classes/def/nav-params/activity-details';

export interface IActivityTutorialContainer {
    spec: ITutorial,
    walkthrough: ITutorial[]
}

export interface IActivityTutorialCollection {
    [key: string]: IActivityTutorialContainer
}

export enum EDroneMode {
    noDrone = 0,
    withDrone = 1,
    onlyDrone = 2
}


@Injectable({
    providedIn: 'root'
})
export class ActivitiesDataService {
    activityTutorialCache: IActivityTutorialCollection = {};

    constructor(
        public http: HttpClient,
        public generic: GenericDataService,
    ) {
        console.log("activities data service created");
    }

    getActivityTutorials(activityCode: number): Promise<IActivityTutorialContainer> {
        let promise: Promise<IActivityTutorialContainer> = new Promise((resolve, reject) => {
            if (this.activityTutorialCache[activityCode.toString()] != null) {
                resolve(this.activityTutorialCache[activityCode.toString()]);
                return;
            }
            this.generic.genericGetStandard("/dex/activities/get-activity-tutorials", {
                activityCode: activityCode
            }).then((resp: IGenericResponse) => {
                let tutorials: ITutorial[] = resp.data;
                let spec: ITutorial = tutorials.find(t => t.tutorialCategoryCode === ETutorialCategory.activitySpec);
                let container: IActivityTutorialContainer = {
                    spec: spec ? spec : null,
                    walkthrough: tutorials.filter(t => t.tutorialCategoryCode === ETutorialCategory.walkthroughSpec)
                };
                this.activityTutorialCache[activityCode.toString()] = container;
                resolve(container);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    validateQuestData(specs: IActivityQuestSpecs, resp: IActivityQuestResponse) {
        return this.generic.genericPostStandardWData("/activity-validate/validate-quest-data", {
            specs: specs,
            resp: resp
        });
    }
}



