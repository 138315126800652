import { Injectable, NgZone } from '@angular/core';
import { IGenericResponse } from '../../classes/def/requests/general';
import { ISoundResultResponse } from '../../classes/def/media/processing';
import { GenericDataService } from '../general/data/generic';
import { FileManagerService } from './file';
import { GeneralCache } from '../../classes/app/general-cache';
import { EOS } from '../../classes/def/app/app';
import { DBMeter } from '@ionic-native/db-meter/ngx';
import { Observable, BehaviorSubject, timer } from 'rxjs';
import { IPlatformFlags } from '../../classes/def/app/platform';
import { SettingsManagerService } from '../general/settings-manager';
import { MathUtils } from '../../classes/general/math';
import { ResourceManager } from '../../classes/general/resource-manager';
import { SignalProcessingService } from '../app/utils/signal-processing';
import { ISignalData, ISignalProcessingParams, IFFTBin, ESignalProcessingFilter } from '../../classes/def/processing/signal';
import { ISoundBin } from 'src/app/classes/def/media/sound';
import { EBufferContext } from 'src/app/classes/utils/buffer';


interface ISoundSim {
    sample: number;
    filtered: number;
    stream: MediaStream;
    audioContext: AudioContext;
    analyser: AnalyserNode;
    microphone: MediaStreamAudioSourceNode;
    javascriptNode: ScriptProcessorNode;
}


@Injectable({
    providedIn: 'root'
})
export class SoundService {

    observable = {
        dbMeter: null,
        soundData: null
    };

    subscription = {
        dbMeter: null
    };

    platform: IPlatformFlags = {} as IPlatformFlags;

    sim: ISoundSim = {
        sample: 0,
        filtered: 0,
        stream: null,
        audioContext: null,
        analyser: null,
        microphone: null,
        javascriptNode: null
    };

    samplingRate: number = 44100;

    constructor(
        public generic: GenericDataService,
        public settingsProvider: SettingsManagerService,
        public fileManager: FileManagerService,
        public dbMeter: DBMeter,
        public beatDetector: SignalProcessingService,
        public ngZone: NgZone
    ) {
        console.log("sound service created");
        this.observable.soundData = new BehaviorSubject<ISignalData>(null);
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    resetBeatZone() {
        this.beatDetector.resetBeatZone(EBufferContext.sound);
        this.beatDetector.resetBeatZone(EBufferContext.soundBpm);
    }

    /**
     * watch audio db
     * simulate on web
     */
    startWatchDBMonitor(init: boolean): Observable<any> {
        let useWebAudio: boolean = true;
        // shortcut to enable web audio
        let useWebAudioMasterSwitch: boolean = true;

        if (init) {
            this.beatDetector.initData(EBufferContext.sound);
            this.beatDetector.initData(EBufferContext.soundBpm);
        }

        if (this.platform.WEB || useWebAudioMasterSwitch) {
            let timer1 = timer(0, 100);
            if (useWebAudio || useWebAudioMasterSwitch) {
                this.startMonitorWebAudio();
            } else {
                if (!this.subscription.dbMeter) {
                    this.subscription.dbMeter = timer1.subscribe(() => {
                        this.sim.sample = Math.floor(Math.random() * 100);
                        this.sim.filtered = MathUtils.lowPassFilter(this.sim.filtered, this.sim.sample, 0.5);
                        let rtData: ISignalData = this.beatDetector.processSample(EBufferContext.sound, this.sim.filtered, {
                            filter: ESignalProcessingFilter.lowPassDual,
                            beatZone: true,
                            bpm: true,
                            level: true
                        });
                        this.observable.soundData.next(rtData);
                    }, (err: Error) => {
                        console.error(err);
                    });
                }
            }
            return this.observable.soundData;
        }

        if (!this.observable.dbMeter) {
            this.observable.dbMeter = this.dbMeter.start();
            this.subscription.dbMeter = this.observable.dbMeter.subscribe((data) => {
                let rtData: ISignalData = this.beatDetector.processSample(EBufferContext.sound, data, {
                    filter: ESignalProcessingFilter.lowPassDual,
                    beatZone: true,
                    bpm: true,
                    level: true
                });
                this.observable.soundData.next(rtData);
            }, (err: Error) => {
                console.error(err);
            });
        }
        return this.observable.soundData;
    }


    getSoundBins() {
        /**
         * bass: kick drum
         * low mid: snare drum, low order harmonics of most instruments
         * mid
         */

        let defaultCutoffLimit: number = 60;
        let soundBins: ISoundBin[] = [{
            value: 0,
            count: 0,
            lowerLimit: 60,
            upperLimit: 130,
            cutoffLimit: defaultCutoffLimit
        }, {
            value: 0,
            count: 0,
            lowerLimit: 300,
            upperLimit: 750,
            cutoffLimit: defaultCutoffLimit
        }, {
            value: 0,
            count: 0,
            lowerLimit: 1000,
            upperLimit: 4000,
            cutoffLimit: defaultCutoffLimit
        }];
        return soundBins;
    }
    /**
     * use web audio api
     * implements fft internally
     */
    startMonitorWebAudio() {

        navigator.mediaDevices.getUserMedia({ audio: true, video: false }).then((stream: MediaStream) => {
            this.sim.stream = stream;
            this.sim.audioContext = new AudioContext();
            this.samplingRate = this.sim.audioContext.sampleRate;
            console.log("sampling rate: ", this.samplingRate);

            let fftSize: number = 1024;

            let fftSamplingRate: number = this.samplingRate / fftSize;
            let fftSamplingTime: number = 1 / fftSamplingRate;

            let alphaScale: number = MathUtils.computeAlphaFromTimeConstant(fftSamplingTime, 1);

            let params: ISignalProcessingParams = this.beatDetector.getBpParams(EBufferContext.sound);
            params.alphaShort = MathUtils.computeAlphaFromCutoffFrequency(fftSamplingTime, 500);

            params.alphaLong = MathUtils.computeAlphaFromTimeConstant(fftSamplingTime, 2);

            // params.alphaDecayAccel = MathUtils.computeAlphaFromTimeConstant(fftSamplingTime, 10);
            params.alphaDecayAccel = 1;
            params.alphaDecayInit = MathUtils.computeAlphaFromTimeConstant(fftSamplingTime, 5);
            params.alphaDecay = params.alphaDecayInit;

            this.beatDetector.setBpParams(EBufferContext.sound, params);
            this.beatDetector.setBpParams(EBufferContext.soundBpm, params);

            this.sim.analyser = this.sim.audioContext.createAnalyser();
            this.sim.microphone = this.sim.audioContext.createMediaStreamSource(stream);
            this.sim.javascriptNode = this.sim.audioContext.createScriptProcessor(fftSize, 1, 1);
            this.sim.analyser.smoothingTimeConstant = 0.8;
            this.sim.analyser.fftSize = fftSize;
            this.sim.microphone.connect(this.sim.analyser);

            this.sim.analyser.connect(this.sim.javascriptNode);
            this.sim.javascriptNode.connect(this.sim.audioContext.destination);
            this.sim.javascriptNode.onaudioprocess = (_e) => {
                let array = new Uint8Array(this.sim.analyser.frequencyBinCount);

                let soundBuffer: Float32Array = new Float32Array(this.sim.analyser.frequencyBinCount);
                // this.sim.analyser.getFloatFrequencyData(soundBuffer);
                try {
                    soundBuffer = _e.inputBuffer.getChannelData(0);
                } catch (err) {
                    console.error(err);
                }
                // get the fft sound spectrum

                /**
                 * getByteFrequencyData results in a normalized array of values between 0 and 255. 
                 * (it copies the data to the array it gets passed-in).
                 * the frequency bands are split equally, so each element N of your array corresponds to: N * samplerate/fftSize
                 */
                this.sim.analyser.getByteFrequencyData(array);
                let fftData: IFFTBin[] = [];
                let rms: number = 0;
                // length is fft bin count = fftSize/2
                let length: number = array.length;

                let soundBins: ISoundBin[] = this.getSoundBins();

                for (let i = 0; i < length; i++) {
                    rms += array[i] * array[i];
                    // https://hackernoon.com/creative-coding-using-the-microphone-to-make-sound-reactive-art-part1-164fd3d972f3
                    // RMS is a better indication of the total volume of a sound input
                    let freq: number = i * this.samplingRate / fftSize;
                    fftData.push({
                        frequency: freq,
                        power: array[i]
                    });
                }

                let average: number = Math.sqrt(rms / length);

                // get the raw data average (sound level)
                if (soundBuffer && soundBuffer.length) {
                    // get rms
                    // rms = 0;
                    // for (let i = 0; i < soundBuffer.length; i++) {
                    //     let val: number = soundBuffer[i];
                    //     rms += Math.abs(val);
                    // }
                    // rms = Math.sqrt(rms / soundBuffer.length);
                    // average = 20 * MathUtils.log10(rms) + 90;
                }

                this.ngZone.run(() => {
                    // average
                    let baseData: ISignalData = this.beatDetector.processSample(EBufferContext.sound, average, {
                        filter: ESignalProcessingFilter.lowPass,
                        beatZone: true,
                        bpm: false,
                        level: true
                    });

                    let multiRangeData: ISignalData = this.beatDetector.processFFTSample(EBufferContext.soundBpm, fftData, {
                        filter: ESignalProcessingFilter.envelope,
                        beatZone: true,
                        bpm: true,
                        level: true,
                        fft: {
                            soundBins,
                            fftSamplingTime,
                            alphaScale
                        }
                    });


                    // the time domain is validated on the average sound levels
                    // the frequency domain, beat detection and BPM is validated on the fft array

                    // console.log(multiRangeData.timeDomain.sample, baseData.timeDomain.sample);

                    baseData.frequencyDomain = multiRangeData.frequencyDomain;
                    // bpm, beat counter, beat focus are extracted from the bass line
                    baseData.beatDetection.bpm = multiRangeData.beatDetection.bpm;
                    baseData.beatDetection.beatCounter = multiRangeData.beatDetection.beatCounter;


                    // baseData.beatDetection.beatZone = multiRangeData.beatDetection.beatZone;

                    // baseData.validation = multiRangeData.validation;

                    // console.log(baseData);
                    this.observable.soundData.next(baseData);
                });
            };
        }).catch((err: Error) => {
            /* handle the error */
            console.error(err);
        });
    }


    /**
     * stop web audio api
     */
    stopMonitorWebAudio() {
        if (this.sim.stream) {
            this.sim.stream.getTracks().forEach((track: MediaStreamTrack) => {
                track.stop();
            });
            this.sim.stream = null;
            this.sim.analyser.disconnect();
            this.sim.microphone.disconnect();
            this.sim.javascriptNode.disconnect();
        }
    }

    /**
     * stop watch audio db
     * simulate on web
     */
    stopDBMonitor() {

        if (this.platform.WEB) {
            this.subscription.dbMeter = ResourceManager.clearSub(this.subscription.dbMeter);
            this.stopMonitorWebAudio();
            return;
        }

        if (this.observable.dbMeter) {
            this.observable.dbMeter = null;
            this.dbMeter.stop().then(() => {
                console.log("db meter stopped");

                this.dbMeter.delete().then(() => {
                    console.log('Deleted DB Meter instance');
                }).catch((err: Error) => {
                    console.error(err);
                });
            }).catch((err: Error) => {
                console.error(err);

                this.dbMeter.delete().then(() => {
                    console.log('Deleted DB Meter instance');
                }).catch((err: Error) => {
                    console.error(err);
                });
            });
        }

        this.observable.soundData.next(null);
    }

    getAudioRecordingPath() {
        // let recPath: string = this.fileManager.getBasePath() + Constants.APP_STORAGE_FOLDER + "/" + this.getAudioRecordingName();
        let recPath: string = this.fileManager.getBasePath() + this.getAudioRecordingName();
        return recPath;
    }

    getAudioRecordingName() {
        let recName: string = "audioSample";
        // recPath += ".mp3";
        // return recPath;
        switch (GeneralCache.os) {
            case EOS.android:
                recName += ".aac";
                break;
            case EOS.ios:
                recName += ".m4a";
                // recName += ".wav";
                break;
            default:
                break;
        }
        return recName;
    }

    /**
     * check activity (object is not specified here, it is deduced from the activity code)
     * @param audioFile 
     * @param activityCode 
     */
    mediaUploadSoundCheckActivity(audioFile: string, activityCode: number) {
        let promise = new Promise((resolve, reject) => {
            this.generic.uploadFile("audioSample", audioFile, "/api/sound/check-activity", {
                activityCode
            }, GeneralCache.resourceCache.general.appServices.content.object.media.url).then((response: IGenericResponse) => {
                let soundResponse: ISoundResultResponse = response.data;
                resolve(soundResponse);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

}




