import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { CanvasLoaderService } from 'src/app/services/utils/canvas-loader';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { ParamHandler } from 'src/app/classes/general/params';
import { IPlacesItemsNavParams } from 'src/app/classes/def/nav-params/place-popup';
import { ILocationGameItem } from 'src/app/classes/def/items/game-item';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';

@Component({
  selector: 'modal-places-items',
  templateUrl: './places-items.component.html',
  styleUrls: ['./places-items.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PlacesItemsViewComponent implements OnInit, OnDestroy {
  hasParams: boolean = false;
  title: string = "Places"; // providers
  theme: string = "theme-aubergine theme-aubergine-bg";
  locationItems: ILocationGameItem[];
  hasItems: boolean = false;
  vs: IViewSpecs = ViewSpecs.getDefault();

  np: INavParams = null;

  constructor(
    public uiext: UiExtensionService,
    public modalCtrl: ModalController,
    public canvasLoader: CanvasLoaderService,
    public tutorials: TutorialsService
  ) {

  }

  ngOnInit() {
    this.hasParams = ParamHandler.checkParams(this.np);

    if (!this.hasParams) {

    } else {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IPlacesItemsNavParams = np.params;

      this.title = params.title;
      this.locationItems = params.locationItems;
      this.hasItems = this.locationItems && (this.locationItems.length > 0);

      if (np.view) {
        this.vs = np.view;
      }

      console.log(np);
    }
  }

  ngOnDestroy() {

  }

  getDetails(i) {
    console.log(i);
  }

  dismiss() {
    setTimeout(() => {
      this.modalCtrl.dismiss(null).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  showDescription() {
    this.tutorials.showTutorialNoAction("Place Scan Tutorial", ETutorialEntries.placeAdsTutorial, null, null, true);
  }

}
