<div *ngIf="data" class="flex-card bg-container bg-container-fill bg-filter-2 item-border-radius"
  [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: photoUrl}"
  [ngClass]="large ? 'place-card-exp' : 'place-card'">
  <!-- tappable (click)="showDescription()" -->
  <div class="content">
    <ion-grid class="full-w height-100 grid-center-absolute place-card-align-content">

      <ion-row>
        <ion-col size="12" class="padding-xxs">
          <span class="info-text-m bold text-color-element">{{headerText}}</span>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="2" class="padding-xxs">
          <div *ngIf="show.placeIcon" class="ion-float-left" [ngClass]="iconClass">
            <icon-wrapper [icon]="icons.place.icon" [custom]="icons.place.custom" [class]="iconClass"></icon-wrapper>
          </div>
          <div *ngIf="show.placePhoto" class="ion-float-left">
            <img [src]="icons.place.icon" alt="Photo" height="30">
          </div>
        </ion-col>

        <ion-col size="8" class="padding-xxs">
          <div class="flex-row">
            <div *ngIf="show.flagIcon" [class]="iconClass">
              <icon-wrapper [icon]="icons.flag.icon" [custom]="icons.flag.custom"></icon-wrapper>
            </div>
            <div class="leplace-icon-div-m leplace-icon icon-color-primary margin-horizontal-s" *ngIf="audioGuide">
              <icon-wrapper [icon]="appIcons.audioGuide" [custom]="true" customClass="leplace-icon">
              </icon-wrapper>
            </div>
            <div class="leplace-icon-div-m leplace-icon icon-color-primary margin-horizontal-s" *ngIf="bikeMode">
              <icon-wrapper [icon]="appIcons.bike" [custom]="true" customClass="leplace-icon">
              </icon-wrapper>
            </div>
          </div>
        </ion-col>

        <ion-col size="2" class="padding-xxs">
          <div *ngIf="show.activityIcon" class="ion-float-right" [ngClass]="iconClass">
            <icon-wrapper [icon]="icons.activity.icon" [custom]="icons.activity.custom"></icon-wrapper>
          </div>
        </ion-col>
      </ion-row>

      <ion-row>
        <ion-col size="12" class="padding-xxs">
          <span class="info-text-s bold text-color-element" [ngClass]="locationNameClass">{{footerText}}
          </span>
        </ion-col>
      </ion-row>
    </ion-grid>
  </div>
</div>