
import { Injectable } from '@angular/core';
import { UiExtensionService } from '../../general/ui/ui-extension';
import { AnalyticsService } from '../../general/apis/analytics';
import { EFeatureCode } from '../../../classes/def/app/app';
import { PopupFeaturesService } from '../modules/minor/popup-features';

@Injectable({
    providedIn: 'root'
})
export class FeatureShortcutService {
    constructor(
        public uiext: UiExtensionService,
        public analytics: AnalyticsService,
        public popupFeatures: PopupFeaturesService
    ) {
        console.log("feature shortcut service provider");
    }

    /**
     * open feature by code
     */
    openFeature(code: number) {
        let opened: boolean = false;
        switch (code) {
            case EFeatureCode.getReferralCode:
                this.popupFeatures.getReferralCode();
                opened = true;
                break;
        }
        return opened;
    }
}
