import { ILeaderboardDetailNavParams } from "./leaderboard";
import { IGameItem } from "../items/game-item";


export enum EInventoryDetailMode {
    leaderboard = 1,
    placeSales = 2
}

export interface IInventoryDetailNavParams {
    mode: number;
    /**
     * the inventory was opened from leaderboard, so use the details from the selected user
     */
    leaderboardDetail?: ILeaderboardDetailNavParams;
    placeSalesDetail?: IPlaceSalesDetailNavParams;
    items?: IGameItem[];
    // override peek attribute for locked items
    enableLockedDescriptions: boolean;
    heading: string;
}

export interface IPlaceSalesDetailNavParams {
    placeName: string;
    googleId: string;
}

export interface IAmountNavParams {
    title: string;
    description: string;
    min: number;
    max: number;
    current: number;
}

export interface IInventoryNavParams {
    closeOnItemUse: boolean;
    fixedCategory: boolean;
    wantedItemGenericCode?: number;
    wantedItemCategoryCode?: number;
    wantedOnActivate?: boolean;
    /**
     * the inventory was opened from leaderboard, so use the details from the selected user
     */
    leaderboardDetail?: ILeaderboardDetailNavParams;
    fromMapOpened?: boolean;
}

export interface IInventoryReturnItem {
    item: IGameItem;
    action: number;
}

