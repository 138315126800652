import { IGameItemIAP, IGameItem, EIAPType } from 'src/app/classes/def/items/game-item';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { EIAPCustomEvent, IAPProduct, IAPStoreProduct, ICachedProductDict, ICheckItemData, ICheckItemsList, IIAPEventAg } from './iap-def';
import { IStory } from 'src/app/classes/def/core/story';

export class IAPUtils {
    static productCheckStates = [EIAPCustomEvent.invalid, EIAPCustomEvent.valid, EIAPCustomEvent.approved, EIAPCustomEvent.owned];
    /**
     * get product id by os
     * @param iapItem 
     */
    static getProductId(itemIap: IGameItemIAP): string {
        let productId: string = null;
        if (!itemIap) {
            return null;
        }
        switch (GeneralCache.checkPlatformOS()) {
            case EOS.ios:
                if (itemIap.appStoreCode) {
                    productId = itemIap.appStoreCode;
                }
                break;
            case EOS.android:
            default:
                if (itemIap.playStoreCode) {
                    productId = itemIap.playStoreCode;
                } else {
                    // test
                    productId = "android.test.purchased";
                }
                break;
        }
        return productId;
    }

    static isProductValid(product: IAPProduct) {
        if (!product) {
            return false;
        }
        return product.canPurchase;
    }

    static isProductOwned(product: IAPProduct) {
        if (!product) {
            return false;
        }
        return product.owned;
    }

    static getProductFromEvent(event: IIAPEventAg, products: ICachedProductDict): IAPProduct {
        let product: IAPProduct = null;
        if (event.product) {
            product = event.product;
        }
        if (event.transaction) {
            if (!product && event.transaction.products) {
                product = products[event.transaction.products[0].id];
            }
            product.transaction = event.transaction["nativePurchase"]; // this contains the receipt and signature to be sent to backend for validation
        }
        return product;
    }

    /**
     * update item data from iap data
     * @param item 
     * @param product 
     */
    static updateIapItemData(item: (IGameItem | IStory), product: IAPStoreProduct, updateContent: boolean) {

        // Good! Product loaded and valid.
        console.log("product details updated [" + product.identifier + "]");

        // the iap data has been received and loaded
        item.itemIap.iapLoaded = true;

        // let valid: boolean = product.valid;
        let valid: boolean = true;

        if (updateContent) {
            if (product.title) {
                item.name = product.title;
            }
            if (product.description) {
                item.description = product.description;
            }
        }

        if (product.priceString) {
            switch (GeneralCache.checkPlatformOS()) {
                case EOS.ios:
                    item.itemIap.priceIos = product.priceString;
                    break;
                case EOS.android:
                default:
                    item.itemIap.price = product.priceString;
                    break;
            }
        } else {
            valid = false;
        }

        item.itemIap.iapReady = true;
        if (!valid) {
            item.itemIap.price = "UNAVAILABLE";
            item.itemIap.priceIos = "UNAVAILABLE";
        }
        item.itemIap.valid = valid;
        item.loading = false;
        item.itemIap.hiddenPrice = false;
    }

    static setUnavailable(item: (IGameItem | IStory)) {
        // maybe retry loading on next occasion
        item.itemIap.iapReady = false;
        item.itemIap.price = "UNAVAILABLE";
        item.itemIap.priceIos = "UNAVAILABLE";
        item.itemIap.valid = false;
        item.loading = false;
        item.itemIap.hiddenPrice = false;
        item.itemIap.iapLoaded = true;
    }

    static setAvailableTest(item: (IGameItem | IStory)) {
        if (item.itemIap) {
            item.itemIap.iapLoaded = true;
            item.itemIap.iapReady = true;
            item.itemIap.valid = true;
            item.itemIap.hiddenPrice = false;
            // item.name += " (loaded)";
        }
        item.loading = false;
    }

    /**
    * check item data for iap valid
    * platform spec
    * @param item 
    */
    static checkItemData(item: IGameItem | IStory): ICheckItemData {
        let res: ICheckItemData = {
            valid: true,
            productId: null,
            info: null
        };

        if (!item) {
            res.valid = false;
            res.info = "null item";
            return res;
        }

        if (!item.itemIap) {
            res.valid = false;
            res.info = "no iap for item";
            return res;
        }

        if (item.itemIap.code === -1) {
            res.valid = false;
            res.info = "special request";
            return res;
        }

        res.productId = IAPUtils.getProductId(item.itemIap);

        if (!res.productId) {
            res.valid = false;
            res.info = "item not currently up for sale";
            return res;
        } else {
            res.valid = true;
            return res;
        }
    }

    static syncPreloadedIAPData(item: IGameItem | IStory, cachedProducts: IAPStoreProduct[]) {
        let productId = IAPUtils.getProductId(item.itemIap);
        let productIdPromo = IAPUtils.getProductId((item as IStory).itemIapPromo);
        if (productId != null) {
            let product: IAPStoreProduct = cachedProducts.find(p => p.identifier === productId);
            if (product != null) {
                item.itemIap.price = product.priceString;
                item.itemIap.priceIos = product.priceString;
            }
        }
        if (productIdPromo != null) {
            let product: IAPStoreProduct = cachedProducts.find(p => p.identifier === productIdPromo);
            if (product != null) {
                (item as IStory).itemIapPromo.price = product.priceString;
                (item as IStory).itemIapPromo.priceIos = product.priceString;
            }
        }
    }


    /**
     * check item data for iap valid/loaded (multiple items)
     * platform spec
     * @param items 
     * @param reload 
     */
    static checkItemDataMulti(items: (IGameItem | IStory)[], reload: boolean, update: boolean): ICheckItemsList {
        let checkItems: ICheckItemData[] = [];
        let itemsList: (IGameItem | IStory)[] = [];
        let hasItems: boolean = false;

        for (let i = 0; i < items.length; i++) {
            let item: IGameItem | IStory = items[i];
            let check: ICheckItemData = IAPUtils.checkItemData(item);

            if (!check.valid) {
                continue;
            }

            if (!reload) {
                if (item.itemIap.iapLoaded && item.itemIap.iapReady) {
                    // product already loaded, don't add to check list (continue)
                    if (update) {
                        item.loading = false;
                    }
                    continue;
                }
            }

            // if passed all checks
            checkItems.push(check);
            itemsList.push(item);
            hasItems = true;
        }

        let res: ICheckItemsList = {
            checkItems: checkItems,
            itemsList: itemsList,
            hasItems: hasItems
        };

        return res;
    }

    /**
     * check item iap data
     * hide prices (before loading iap data, if not already loaded)
     * @param items 
     */
    static checkItemListHidePrice(items: (IGameItem | IStory)[]): void {
        if (!items) {
            return;
        }
        for (let i = 0; i < items.length; i++) {
            let item: (IGameItem | IStory) = items[i];
            let check: ICheckItemData = IAPUtils.checkItemData(item);
            // check valid iap specs
            // and not already loaded
            if (check.valid && !item.itemIap.iapLoaded) {
                item.itemIap.hiddenPrice = true;
                // item.loading = true;
            }
        }
    }


    /**
    * get store type based on itemIap spec and device os
    * @param itemIap 
    */
    static getStoreTypeContext(itemIap: IGameItemIAP) {
        let type: number = EIAPType.consumable;;
        switch (GeneralCache.os) {
            case EOS.ios:
                type = itemIap.typeIos;
                break;
            case EOS.android:
            default:
                type = itemIap.type;
                break;
        }
        return this.getStoreType(type);
    }

    static getStorePlatformContext() {
        let platform: string = null;
        switch (GeneralCache.os) {
            case EOS.ios:
                platform = null;
                break;
            case EOS.android:
            default:
                platform = null;
                break;
        }
        return platform;
    }

    /**
     * get store type based on itemIap type spec
     * @param type 
     */
    static getStoreType(type: number) {
        let storeType: string;
        switch (type) {
            case EIAPType.consumable:
                storeType = null;
                break;
            case EIAPType.nonConsumable:
                storeType = null;
                break;
            case EIAPType.subscription:
                storeType = null;
                break;
            case EIAPType.other:
            default:
                storeType = null;
                break;
        }
        return storeType;
    }
}