import { Component, OnInit, OnChanges, OnDestroy, ViewChild, Input, SimpleChanges, SimpleChange } from '@angular/core';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { IonSlides } from '@ionic/angular';
import { ISliderOptions } from 'src/app/classes/def/dynamic/slides';

@Component({
  selector: 'dynamic-slides',
  templateUrl: './dynamic-slides.component.html',
  styleUrls: ['./dynamic-slides.component.scss'],
})
export class DynamicSlidesComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(IonSlides, { read: IonSlides, static: false }) slides: IonSlides;
  enableTabButtons: boolean = false;
  showLeftButton: boolean = true;
  showRightButton: boolean = true;


  /**
   * this is only for linear navigation through tabs
   * it is initialized once
   */
  selectedIndex: number = 0;

  @Input()
  type: string;

  @Input()
  slidesPerView: number;

  @Input()
  slideContent: any[];

  @Input()
  slideClass: string;

  @Input()
  trackByFn: (index: number, item: any) => any;

  sliderOptions: ISliderOptions = {
    slidesPerView: 3,
    initialSlide: 0
  };

  constructor(
    public uiext: UiExtensionService
  ) {

  }

  ngOnInit() {
    if (!this.slidesPerView) {
      this.slidesPerView = 3;
    }
    if (!this.selectedIndex) {
      this.selectedIndex = 0;
    }

    this.sliderOptions.slidesPerView = this.slidesPerView;
    this.sliderOptions.initialSlide = this.selectedIndex;

    if (!this.slideClass) {
      this.slideClass = "";
    }

    console.log("selected index: ", this.selectedIndex);
    console.log("slide class: ", this.slideClass);
    console.log("track by fn: ", this.trackByFn);
  }

  ngOnDestroy() {

  }


  ngOnChanges(changes: SimpleChanges) {
    // console.log(changes);
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      console.log(chg);
      if (!chg.isFirstChange() || chg.currentValue === true) {
        // skip the first change only if the current value is not already set to true
        // this may happen if the button is just shown and it is set to blink
        switch (keys[i]) {
          case 'loadData':

            break;
        }
      }
    }
  }




  // Method executed when the slides are changed
  public slideChanged(): void {
    this.slides.getActiveIndex().then((currentIndex: number) => {
      // this.showLeftButton = currentIndex !== 0;
      this.selectedIndex = currentIndex;
      let isLastSlide: boolean = false;
      this.slides.length().then((slidesLength: number) => {
        if (currentIndex + this.slidesPerView >= slidesLength) {
          isLastSlide = true;
        }
        // this.showRightButton = currentIndex !== Math.ceil(this.slides.length() / this.categoryTabsGroupCount) - 1;
        this.showRightButton = !isLastSlide;
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  public filterData(navItem: any): void {
    // Handle what to do when a category is selected
    console.log(navItem);

  }


  /**
   * Method that shows the next slide
   * update: next GROUP of slides
   */
  public slideNext(): void {
    console.log("slide next");
    this.slides.getActiveIndex().then((currentIndex: number) => {
      // this.showLeftButton = currentIndex !== 0;
      this.selectedIndex = currentIndex;
      // let isLastSlide: boolean = false;
      this.slides.length().then((slidesLength: number) => {
        let slideToIndex: number = currentIndex + this.slidesPerView;
        if (slideToIndex > slidesLength - 1) {
          slideToIndex = slidesLength - 1;
        }
        this.slides.slideTo(slideToIndex).then(() => {

        }).catch((err: Error) => {
          console.error(err);
        });
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  /**
   * Method that shows the previous slide
   * update: previous GROUP of slides
   */
  public slidePrev(): void {
    console.log("slide prev");
    this.slides.getActiveIndex().then((currentIndex: number) => {
      let slideToIndex: number = currentIndex - this.slidesPerView;
      if (slideToIndex < this.slidesPerView - 1) {
        slideToIndex = 0;
      }
      this.slides.slideTo(slideToIndex).then(() => {

      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });
  }
}
