
export interface IMPRequestContainer<T> {
    // status/message
    scope: number,
    // group/meeting place
    context: number,
    data: T,
    token: string
}

export enum EMPScope {
    STATUS = 0,
    MESSAGE = 1,
    MESSAGE_SYNC = 2
}

export enum EMPContext {
    // static group
    GROUP = 0,
    // meeting place or temp group
    MEETING_PLACE = 1
}

export enum EMPStatusContext {
    GROUP = 0,
    MEETING_PLACE = 1,
    // temp group, use with meeting place context
    TEMP_GROUP = 2
}

export enum EResponseMessageTypeContainer {
    STATUS_LIST = 0,
    MESSAGE = 1,
    MESSAGE_LIST = 2
}

export interface IMPResponseContainer<T>{
    // status/message
    scope: number,
    // status/message type e.g. message sync response
    type: number,
    data: T
}

export interface IMPGenericTxContent<T, U> {
    data: T,
    aux: U
}

export interface IMPGenericRxContent<T, U> {
    data: T,
    aux: U
}