<ion-header [ngClass]="getHeaderClass()">
    <leplace-toolbar [menu]="false" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()" title="Explorer"
        rightButton="settings" [rightIcon]="true" (rightClick)="presentPopover()" [loading]="!loaded">
    </leplace-toolbar>
</ion-header>

<!-- <ion-content padding-s class="theme-bg"> -->
<ion-content [ngClass]="theme">
    <!-- (swipe)="swipeEvent($event)" -->
    <div class="view-frame padding-s theme-background" fxLayout="column" style="text-align: center">
        <leplace-stickerbomb [config]="config"></leplace-stickerbomb>
    </div>
</ion-content>