import { Component, OnInit, OnDestroy, Input, Output, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { timer } from 'rxjs';
import { EventEmitter } from '@angular/core';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { EAppIcons } from 'src/app/classes/def/app/icons';
import { IGroupMember, EGroupRole } from 'src/app/classes/def/mp/groups';
import { IMPUserCardOptionsNavParams } from 'src/app/classes/def/nav-params/arena';
import { INavParams } from 'src/app/classes/def/nav-params/general';
import { MpUserCardOptionsViewComponent } from 'src/app/modals/app/modals/mp-user-card-options/mp-user-card-options.component';
import { IItemAction } from 'src/app/classes/def/items/action';
import { EItemActions } from 'src/app/classes/def/items/game-item';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { IGenericActionContainer, EGenericAction } from 'src/app/classes/def/dynamic/action';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { StringUtils } from 'src/app/services/app/utils/string-utils';


@Component({
  selector: 'group-member-item',
  templateUrl: './group-member-item.component.html',
  styleUrls: ['./group-member-item.component.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        transition: "background-color 250ms linear"
      })),
      state('active', style({
        transition: "background-color 250ms linear"
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ]
})
export class GroupMemberItemComponent implements OnInit, OnChanges, OnDestroy {
  highlight: boolean = false;
  photoLoaded: boolean = false;
  showState: string = "inactive";
  // valid: boolean = true; 
  css: string;
  // cssUnlocked: string = "flex-card item-card bg-container bg-container-fill bg-no-filter-animate";
  // cssLocked: string = "flex-card item-card bg-container bg-container-fill bg-filter-2-animate";

  cssUnlocked: string = "flex-card item-card bg-container bg-container-fill";
  cssLocked: string = "flex-card item-card bg-container bg-container-fill";

  cssItem: string = "info-text-xs bold text-color-overlay-s";
  cssItemDefault: string = "info-text-xs bold text-color-overlay-s";
  cssItemActive: string = "info-text-xs bold text-color-overlay-s";
  // cssItemActive: string = "info-text-xs bold text-color-overlay-s text-color-overlay-accent";
  active: boolean = false;
  isLocked: boolean = false;
  showProgress: boolean = false;

  state: boolean = false;
  timerSub = null;

  memberName: string = "";
  memberPhotoUrl: string = "";

  appIcons = EAppIcons;
  isLoading: boolean = false;

  enabledInSession: boolean = true;
  connecting: boolean = false;
  connected: boolean = false;
  isAdminCard: boolean = false;
  self: boolean = false;
  withOptions: boolean = false;

  @Input()
  data: IGroupMember;

  @Input()
  interact: boolean;

  @Input()
  update: boolean;

  @Input()
  isAdmin: boolean;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  timeout = {
    blink: null,
    itemExpire: null
  };

  subscription = {
    blinkEvent: null
  };

  constructor(
    public uiext: UiExtensionService,
    public multiplayer: MPDataService,
    public analytics: AnalyticsService
  ) {
    this.css = this.cssUnlocked;
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'data':
            this.init();
            break;
          case 'update':
            this.init();
            break;
        }
      }
    }
  }

  showDescription() {
    let item: IGroupMember = this.data;
    console.log("show group member: ", item, this.interact);
  }


  showOptions() {
    if (!this.withOptions) {
      return;
    }
    if (this.data) {
      let params: IMPUserCardOptionsNavParams = {
        member: this.data,
        isAdmin: this.isAdmin
      };
      let navParams: INavParams = {
        view: {
          fullScreen: false,
          transparent: false,
          large: false,
          addToStack: true,
          frame: false
        },
        params: params
      };

      let action: IGenericActionContainer<IGroupMember> = {
        action: EGenericAction.update,
        item: this.data
      };

      this.uiext.showCustomModal(null, MpUserCardOptionsViewComponent, navParams).then((data: IItemAction) => {
        if (data) {
          let promisePopup = new Promise((resolve) => {
            let msg: string = "Admin";
            let sub: string = "";

            switch (data.code) {
              case EItemActions.buy:
                sub = "Include in current game session";
                break;
              case EItemActions.drop:
                sub = "Exclude from current game session";
                break;
              case EItemActions.cancel:
                sub = "Remove from group";
                break;
            }

            this.uiext.showAlert(msg, sub, 2, null, true).then((res: number) => {
              resolve(res === EAlertButtonCodes.ok);
            }).catch((err: Error) => {
              console.error(err);
              resolve(false);
            });
          });

          promisePopup.then((proceed: boolean) => {
            if (proceed) {
              switch (data.code) {
                case EItemActions.buy:
                  // set active for the current session (reset inactive)
                  if (this.data.staticFlags) {
                    this.data.staticFlags.enabled = true;
                  }
                  this.init();
                  this.action.emit(action);
                  break;
                case EItemActions.drop:
                  // set inactive for the current session
                  if (this.data.staticFlags) {
                    this.data.staticFlags.enabled = false;
                  }
                  this.init();
                  this.action.emit(action);
                  break;
                case EItemActions.cancel:
                  // remove member from the group
                  this.multiplayer.removeMember(this.data.groupId, this.data.userId).then(() => {
                    // emit member removed event
                    console.log("removed member");
                    action.action = EGenericAction.remove;
                    action.item = this.data;
                    this.multiplayer.clearGroupCache();
                    this.action.emit(action);
                    this.data = null;
                  }).catch((err: Error) => {
                    console.error(err);
                    this.analytics.dispatchError(err, "mp-user-card");
                  });
                  break;
              }
            }
          });
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }



  clearTimer() {
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
    this.timerSub = ResourceManager.clearSub(this.timerSub);
  }

  ngOnDestroy() {
    this.clearTimer();
  }

  ngOnInit() {
    this.init();
  }

  init() {
    // console.log("group member item init");
    if (this.data && this.data.user) {
      let member: IGroupMember = this.data;
      let itemName: string = member.user.name;
      this.memberName = StringUtils.trimNameMultilineHTML(itemName, EMessageTrim.itemNameCard);
      this.memberPhotoUrl = member.user.photoUrl;

      if (member.staticFlags) {
        switch (member.type) {
          case EGroupRole.leader:
            break;
          case EGroupRole.member:
            break;
        }

        if (member.staticFlags.self) {
          // this.userName += " (you)";
          this.self = true;
        }

        if (member.staticFlags) {
          this.enabledInSession = member.staticFlags.enabled;
        }

        if (member.type === EGroupRole.leader) {
          this.isAdminCard = true;
        }
      } else {
        this.self = false;
        this.isAdminCard = false;
        this.enabledInSession = true;
      }

      if (member.dynamic) {
        this.connected = member.dynamic.connected;
        if (!this.connected && this.enabledInSession) {
          this.connecting = true;
        } else {
          this.connecting = false;
        }
      } else {
        this.connected = false;
        this.connecting = false;
      }

      this.setViewMode(true);
    }
  }


  setViewMode(open: boolean) {
    // this.showState = open ? "active" : "inactive";
    if (open) {
      this.css = this.cssUnlocked;
    } else {
      this.css = this.cssLocked;
    }
  }


  /**
   * start blinking
   * @param timeout 
   */
  setBlink(timeout: boolean) {
    this.enableBlink(timeout ? 5 : 0);
    if (timeout) {
      this.timeout.blink = setTimeout(() => {
        this.disableBlink();
      }, 5500);
    }
  }

  /**
   * start blinking core
   * @param count 
   */
  enableBlink(count: number) {
    if (!this.timerSub) {
      let timer1 = timer(0, 500);
      let flagCount: boolean = ((count != null) && (count > 0));
      let countk: number = count * 2;
      this.timerSub = timer1.subscribe(() => {
        this.state = !this.state;

        this.setViewMode(this.state);

        if (flagCount) {
          countk -= 1;
          if (countk <= 0) {
            this.disableBlink();
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  /**
   * stop blinking
   */
  disableBlink() {
    this.timerSub = ResourceManager.clearSub(this.timerSub);
    this.timeout.blink = ResourceManager.clearTimeout(this.timeout.blink);
    this.state = false;
    this.setViewMode(this.state);
  }

}
