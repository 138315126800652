<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="cancel()" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div class="center-text vertical-center-modal-content">
    <ion-row>
      <ion-col size="12">
        <ion-input class="ion-input-dash" [(ngModel)]="amount" ngDefaultControl type="number" [max]="max" min="min"
          class="text-color-primary ion-input-center input-number-style-huge">
        </ion-input>
      </ion-col>
    </ion-row>
  </div>

  <div fxFlex>
  </div>

  <div class="margin-s" [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
    <ion-row>
      <ion-col size="4">
        <ion-button class="action-button-fill button-color-primary" (click)="decreaseAmount()">
          <icon-wrapper slot="icon-only" [icon]="appIconsStandard.lt"></icon-wrapper>
        </ion-button>
      </ion-col>
      <ion-col size="4">
        <ion-button class="action-button-fill button-color-primary" (click)="ok()">
          <span class="button-font-size-s">Ok</span>
          <!-- <icon-wrapper slot="start" class="slot-icon-padding" [icon]="appIcons.check" [custom]="true"></icon-wrapper> -->
        </ion-button>
      </ion-col>
      <ion-col size="4">
        <ion-button class="action-button-fill button-color-primary" (click)="increaseAmount()">
          <icon-wrapper slot="icon-only" [icon]="appIconsStandard.gt"></icon-wrapper>
        </ion-button>
      </ion-col>
    </ion-row>
  </div>

</modal-container>