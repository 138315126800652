import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ILeplaceReg } from 'src/app/classes/def/places/google';
import { IArenaNavParams } from 'src/app/classes/def/nav-params/activity';
import { IGroup, EGroupRole, EGroupModalResult, IGroupMember, EGroupType } from 'src/app/classes/def/mp/groups';
import { ModalController, Platform } from '@ionic/angular';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { IGenericResponse } from 'src/app/classes/def/requests/general';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IArenaReturnData } from 'src/app/classes/def/nav-params/arena';
import { EAlertButtonCodes } from 'src/app/classes/def/app/ui';
import { Util } from 'src/app/classes/general/util';
import { IGetPhotoOptions } from 'src/app/services/location/location-utils-def';
import { IPlacePhotoContainer } from 'src/app/classes/def/places/container';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { AppConstants } from 'src/app/classes/app/constants';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { ShareService } from 'src/app/services/general/apis/share';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { MPManagerService } from 'src/app/services/app/mp/mp-manager';
import { ResourcesCoreDataService } from 'src/app/services/data/resources-core';
import { PlacesDataService } from 'src/app/services/data/places';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { MPGroupMembersPage } from '../mp-group-members/mp-group-members.page';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { QRService } from 'src/app/services/general/apis/qr';
import { LocationUtilsWizard } from 'src/app/services/location/location-utils-wizard';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { OtherUtils } from 'src/app/services/utils/other-utils';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { PromiseUtils } from 'src/app/services/utils/promise-utils';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-mp-join',
  templateUrl: './mp-join.page.html',
  styleUrls: ['./mp-join.page.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MpJoinPage implements OnInit, OnDestroy {
  theme: string = "theme-light theme-light-bg";
  title: string = "Join Team";
  infoHTML: string = "";
  shareMessage: string = "";
  place: ILeplaceReg = null;
  photoUrlLoaded: string = null;
  params: IArenaNavParams = null;

  joined: boolean = false;
  existingGroup: boolean = false;
  fromTeamLobby: boolean = false;

  loaded: boolean = true;

  group: IGroup = {
    name: "",
    id: null,
    timestamp: null,
    photoUrl: null,
    alias: null,
    role: EGroupRole.member
  };

  description: string = "";
  descriptionHeader: string = "<scroll down to connect>";

  photoUrl: string = "";

  chatOnly: boolean = false;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  groupUpdated: boolean = false;
  zoomState: boolean = false;

  np: INavParams = null;
  vs: IViewSpecs;
  innerClass: string = "";

  constructor(
    public modalCtrl: ModalController,
    public settingsProvider: SettingsManagerService,
    public shareProvider: ShareService,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public mp: MPDataService,
    public analytics: AnalyticsService,
    public mpManager: MPManagerService,
    public resources: ResourcesCoreDataService,
    public placeProvider: PlacesDataService,
    public tutorials: TutorialsService,
    public nps: NavParamsService,
    public backButton: BackButtonService,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public qrSecurity: QRService,
    public locationUtilsWizard: LocationUtilsWizard,
    public popupFeatures: PopupFeaturesService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, true);
  }

  isFilled(group: IGroup) {
    return !((group.alias == null) || (group.alias === ''));
  }

  dismiss(params: any) {
    setTimeout(() => {
      this.modalCtrl.dismiss(params).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  ngOnInit() {
    this.analytics.trackView("mp-join");
    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.mpJoin, null, this.np);
      console.log("nav params: ", npInfo.params);
      let hasParams: boolean = npInfo.hasParams;
      // console.log(navParams.data);
      if (hasParams) {
        let np: INavParams = npInfo.params;
        this.params = np.params;
        this.vs = np.view;
        if (this.vs.frame) {
          this.innerClass = 'view-frame';
        } else {
          this.innerClass = 'view-frame-minimal';
        }

        this.place = this.params.place;
        this.formatPlace();
        if (this.params.group) {
          // view group mode
          this.group = this.params.group;
          this.setOriginalGroupSpecs();
          // opened the view from group list, so it's already created
          this.joined = true;
          this.existingGroup = true;
          this.title = "View Team";
        }
        this.evalGroupContext();
        this.fromTeamLobby = this.params.fromTeamLobby;
      }
      this.webView.ready().then(() => {
        this.backButton.pushOrReplace(() => {
          this.back();
        }, this.vs);
      }).catch((err: Error) => {
        console.error(err);
      });
      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  setOriginalGroupSpecs() {
    this.photoUrl = this.group.photoUrl;
  }

  newJoin() {
    return !this.existingGroup || !this.joined;
  }

  evalGroupContext() {
    let isPublicContext: boolean = this.params.isLobbyContext;

    switch (this.group.type) {
      case EGroupType.mp:
        isPublicContext = false;
        break;
      case EGroupType.lobby:
        isPublicContext = true;
        this.joined = [EGroupRole.leader, EGroupRole.member].indexOf(this.group.role) !== -1;
        break;
    }

    if (this.params.chatOnly) {
      this.chatOnly = true;
      this.description = Messages.mp.joinGroupChat;
    } else {
      if (isPublicContext) {
        if (this.joined) {
          // isMember
          this.description = Messages.mp.joinGroupPublicMember;
        } else {
          this.description = Messages.mp.joinGroupPublic;
        }
      } else {
        if (this.joined) {
          // isMember
          this.description = Messages.mp.joinGroupPrivateMember;
        } else {
          this.description = Messages.mp.joinGroupPrivate;
        }
      }
    }
  }

  loadGroup() {
    return new Promise((resolve, reject) => {
      this.mp.viewGroup(this.group.id, true).then((group: IGroup) => {
        this.group = group;
        this.setOriginalGroupSpecs();
        resolve(group);
      }).catch((err) => {
        reject(err);
      });
    });
  }


  viewMembers() {
    let promise = new Promise((resolve, reject) => {
      // if the mode is join (not view)
      if (!this.joined) {
        // search group by name as it's not in the user list yet
        this.mp.searchGroup(this.group.alias, null).then((group: IGroup) => {
          this.group = group;
          this.params.group = this.group;
          this.setOriginalGroupSpecs();
          resolve(true);
        }).catch((err: Error) => {
          console.error(err);
          this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
          reject(err);
        });
      } else {
        resolve(true);
      }
    });

    promise.then(() => {
      this.uiext.showCustomModal(null, MPGroupMembersPage, {
        view: {
          fullScreen: true,
          transparent: false,
          large: true,
          addToStack: false,
          frame: false
        },
        params: this.params
      }).then((res: IArenaReturnData) => {
        if (res) {
          switch (res.status) {
            default:
              break;
          }
        }
      }).catch((err: Error) => {
        console.error(err);
      });
    }).catch(() => {

    });
  }


  back() {
    this.dismiss(null);
  }

  async openChat() {
    await this.mpManager.initSequence(this.group, EGroupRole.member, false);
    this.mpManager.openChatWindow(true, true).then(() => {

    }).catch((err: Error) => {
      console.error(err);
    });
  }

  onZoomImage(zoom: boolean) {
    this.zoomState = zoom;
  }


  /**
   * request group join from the main server
   */
  async joinGroup() {
    this.loaded = false;
    let groupAlias: string = null;
    let groupId: number = null;
    try {
      if (!this.isFilled(this.group)) {
        // join using QR code
        groupAlias = await this.qrSecurity.scanQRResultString(false, "Join team");
        console.log("scan QR: " + groupAlias);
      } else {
        // group is selected
        // join using alias / group id
        groupAlias = this.group.alias;
        groupId = this.group.id;
        if (groupId != null) {
          groupAlias = null;
        }
      }
      let response: IGenericResponse = await this.mp.joinGroup(groupAlias, groupId);
      if (response.data) {
        let data: IGroupMember = response.data;
        this.group.id = data.groupId;
        this.joined = true;
      }
      this.loaded = true;
      this.groupUpdated = true;
      await PromiseUtils.wrapResolve(this.loadGroup(), true);
      this.ok();
    } catch (err) {
      console.error(err);
      this.analytics.dispatchError(err, "mp");
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
      this.loaded = true;
    }
  }


  /**
  * remove the selected group
  */
  remove() {
    let returnData: IArenaReturnData = {
      status: null,
      group: null,
      role: EGroupRole.leader
    };
    returnData.status = EGroupModalResult.remove;
    this.dismiss(returnData);
  }


  leaveGroup() {
    this.uiext.showAlert(Messages.msg.leaveGroup.before.msg, Messages.msg.leaveGroup.before.sub, 2, null).then((res: number) => {
      switch (res) {
        case EAlertButtonCodes.ok:
          this.loaded = false;
          this.mp.leaveGroup(this.group.id).then((_response: IGenericResponse) => {
            this.loaded = true;
            this.remove();
          }).catch((err: Error) => {
            console.error(err);
            this.analytics.dispatchError(err, "mp");
            this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err));
            this.loaded = true;
          });
          break;
        default:
          break;
      }
    });
  }

  /**
   * return to the main group view
   */
  ok() {
    let returnData: IArenaReturnData = {
      status: null,
      group: this.group,
      groupUpdated: this.groupUpdated,
      groupJoined: this.joined,
      role: EGroupRole.member
    };
    returnData.status = EGroupModalResult.ok;
    this.dismiss(returnData);
  }


  cancel() {
    let returnData: IArenaReturnData = {
      status: null,
      group: null,
      groupUpdated: this.groupUpdated,
      groupJoined: this.joined,
      role: EGroupRole.member
    };
    returnData.status = EGroupModalResult.cancel;
    this.dismiss(returnData);
  }

  /**
   * Share progress then dismiss
   */
  share() {
    this.loaded = false;
    this.shareProvider.share(this.shareMessage).then(() => {
      this.loaded = true;

    }).catch((err: Error) => {
      console.error(err);
    });
  }


  ngOnDestroy() {
    console.log("close modal");
    this.backButton.checkPop(this.vs);
  }

  clearWatch() {

  }

  /**
   * format place display
   */
  formatPlace() {
    if (this.place) {
      let mapsUrl: string = Util.generateMapsLink(this.place.place.googleId, this.place.place.name);
      this.place.aux = {
        mapsURL: mapsUrl,
        photoUrl: null,
        photoUrlCached: null,
        canvasLoaded: false,
        canvasURL: null
      };

      let options: IGetPhotoOptions = {
        noPlaceholder: true,
        redirect: true,
        cacheDisk: true,
        useGeneric: SettingsManagerService.settings.app.settings.useDefaultPlacePhotos.value
      };

      if (options.useGeneric) {
        this.placeProvider.getPlaceTemplatePhoto(this.place.place.type).then((p: IPlacePhotoContainer) => {
          this.place.aux.photoUrl = p.photoUrl;
        });
      } else {
        this.locationUtilsWizard.loadPlacePhotoWizard1(this.place, options);
      }
    }
  }

  options() {
    let actions: IPopoverActions = {};
    actions = {
      // tutorial: {
      //   name: "Tutorial",
      //   code: 0,
      //   icon: EAppIconsStandard.tutorial,
      //   enabled: true
      // },
      viewMembers: {
        name: "View members",
        code: 2,
        icon: EAppIcons.personCircleOutline,
        customIcon: true,
        enabled: this.isFilled(this.group)
      },
      leaveGroup: {
        name: "Leave group",
        code: 1,
        icon: EAppIcons.exitOutline,
        customIcon: true,
        enabled: true
      }
    };

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 0:
          this.tutorials.showTutorialNoAction("Group Member Tutorial", ETutorialEntries.groupMemberTutorial, null, null, true);
          break;
        case 1:
          this.leaveGroup();
          break;
        case 2:
          this.viewMembers();
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  copyToClipboard(text: string) {
    OtherUtils.copyToClipboard(text);
    this.uiext.showToastNoAction("copied to clipboard", false);
  }
}
