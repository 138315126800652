<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title" (rightClick)="cancel()"
    [loading]="loading" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div fxLayout="column" *ngIf="!inputMode">

    <div class="margin-top-s scrollbar-y theme-border-bottom theme-border-top scroll-content-modal-1" *ngIf="hasParams">

      <ion-list radio-group ngDefaultControl [(ngModel)]="selectedSpec">
        <ion-item *ngFor="let item of treasureSpecs" class="list-item transparent-bg settings-item item-noborder">
          <ion-label class="padding-s">
            <treasure-spec-card class="full-w" [data]="item.data"></treasure-spec-card>
          </ion-label>
          <ion-radio [value]="item.data" class="custom-radio"></ion-radio>
        </ion-item>
      </ion-list>
    </div>


    <div fxFlex></div>

    <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col size="4">
            <ion-button class="action-button-fill button-color-alternate" (click)="addContent()">
              <icon-wrapper slot="icon-only" icon="create"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="4">

          </ion-col>
          <ion-col size="4">
            <ion-button class="action-button-fill button-color-accent" (click)="submit()">
              <icon-wrapper slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>

  </div>


  <div fxLayout="column" *ngIf="inputMode">

    <div class="scroll-content-modal-1 scrollbar-y">
      <ion-textarea #input class="info-text-xl text-color-primary leplace-textarea theme-border-top theme-border-bottom" [rows]="10" [(ngModel)]="customContent"
        autocomplete="off" autocorrect="on" placeholder="Type a description" (ionFocus)="setFocused()"
        (ionBlur)="setBlurred()"></ion-textarea>
    </div>

    <div fxFlex></div>
    <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
      <ion-grid class="full-w">
        <ion-row>
          <ion-col size="4">
            <ion-button class="action-button-fill button-color-alternate" (click)="back()">
              <icon-wrapper slot="icon-only" icon="menu"></icon-wrapper>
            </ion-button>
          </ion-col>
          <ion-col size="4">

          </ion-col>
          <ion-col size="4">
            <ion-button class="action-button-fill button-color-accent" (click)="submit()">
              <icon-wrapper slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
            </ion-button>
          </ion-col>
        </ion-row>

      </ion-grid>
    </div>
  </div>
</modal-container>