
import { Injectable } from '@angular/core';
import { QRService } from '../../general/apis/qr';
import { IBackendLocation, ILocationContainer } from '../../../classes/def/places/backend-location';
import { BusinessDataService } from '../../data/business';
import { IGenericResponse } from '../../../classes/def/requests/general';
import { UiExtensionService } from '../../general/ui/ui-extension';
import { Messages } from '../../../classes/def/app/messages';
import { AnalyticsService } from '../../general/apis/analytics';
import { ErrorMessage } from '../../../classes/general/error-message';
import { IPlaceSalesDetailNavParams, IInventoryDetailNavParams, EInventoryDetailMode } from '../../../classes/def/nav-params/inventory';
import { IPlatformFlags } from '../../../classes/def/app/platform';
import { SettingsManagerService } from '../../general/settings-manager';
import { IPopoverActions } from '../../../classes/def/app/modal-interaction';
import { AppConstants } from '../../../classes/app/constants';
import { InventoryDetailPage } from 'src/app/pages/inventory/inventory-detail/inventory-detail.page';
import { IBusinessFeaturesEnabled } from 'src/app/classes/def/business/business';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { UiExtensionStandardService } from '../../general/ui/ui-extension-standard';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { ActivityUtils } from 'src/app/classes/utils/activity-utils';


@Injectable({
    providedIn: 'root'
})
export class BusinessFeaturesService {
    platform: IPlatformFlags = {} as IPlatformFlags;
    enabled: IBusinessFeaturesEnabled = {
        qr: false,
        sales: false
    };

    constructor(
        public qrSecurity: QRService,
        public businessDataProvider: BusinessDataService,
        public uiext: UiExtensionService,
        public uiextStandard: UiExtensionStandardService,
        public analytics: AnalyticsService,
        public settings: SettingsManagerService
    ) {
        console.log("business features service created");
        this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }


    scanQrRegisterClientCoreNoAction(locationId: number) {
        this.scanQrRegisterClientCore(locationId).then(() => {

        }).catch(() => {

        });
    }

    scanQrRegisterClientNoAction(blocation: ILocationContainer) {
        this.scanQrRegisterClientBLocation(blocation).then(() => {

        }).catch(() => {

        });
    }

    /**
     * scan qr code
     * return true if success
     * then disable scan until the next init
     * reject if any error
     * @param locationId 
     */
    scanQrRegisterClientCore(locationId: number) {
        let promise = new Promise((resolve, reject) => {
            if (this.platform.WEB) {
                console.log("Scan QR resolved because running on platform web");
                this.enabled.qr = false;
                resolve(true);
                return;
            }
            this.qrSecurity.scanQRResultString(true, "Scan QR code").then((token: string) => {
                this.businessDataProvider.registerClient(locationId, token).then((_response: IGenericResponse) => {
                    this.enabled.qr = false;
                    this.uiext.showAlert(Messages.msg.registerClient.after.msg, Messages.msg.registerClient.after.sub, 1, null).then(() => {
                        resolve(true);
                    }).catch((err: Error) => {
                        console.error(err);
                        resolve(true);
                    });
                }).catch((err: Error) => {
                    this.analytics.dispatchError(err, "business-features");
                    this.uiext.showAlertNoAction(Messages.msg.registerClientError.after.msg, ErrorMessage.parse(err, Messages.msg.registerClientError.after.sub));
                    reject(err);
                });
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    /**
     * scan qr
     * then disable scan until the next init
     * @param loc 
     */
    scanQrRegisterClientBLocation(loc: ILocationContainer) {
        let promise = new Promise((resolve, reject) => {
            if (loc) {
                this.scanQrRegisterClientCore(loc.merged.locationId).then((res) => {
                    resolve(res);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                reject(new Error("location undefined"));
            }
        });
        return promise;
    }

    /**
     * view sales associated to the location
     * then disable sales until the next init
     * @param blocation 
     */
    viewSalesCoreNoAction(googleId: string, placeName: string) {
        this.viewSalesCore(googleId, placeName).then(() => { }).catch(() => { });
    }

    /**
     * view sales associated to the location
     * then disable sales until the next init
     * @param blocation 
     */
    viewSalesCore(googleId: string, placeName: string) {
        let promise = new Promise((resolve, reject) => {
            let params1: IPlaceSalesDetailNavParams = {
                googleId: googleId,
                placeName: placeName
            };

            let params: IInventoryDetailNavParams = {
                placeSalesDetail: params1,
                mode: EInventoryDetailMode.placeSales,
                enableLockedDescriptions: true,
                heading: "Sales"
            };

            this.uiext.showCustomModal(null, InventoryDetailPage, {
                view: {
                    fullScreen: true,
                    transparent: false,
                    large: true,
                    addToStack: true,
                    frame: true
                },
                params: params
            }).then(() => {
                // this.enabled.sales = false;
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    /**
     * view sales associated to the location
     * @param loc 
     */
    viewSalesBLocationNoAction(loc: ILocationContainer) {
        if (loc) {
            this.viewSalesCoreNoAction(loc.merged.googleId, loc.merged.name);
        }
    }

    /**
     * view sales associated to the location
     * @param loc 
     */
    viewSalesBLocation(loc: ILocationContainer) {
        let promise = new Promise((resolve, reject) => {
            if (loc) {
                this.viewSalesCore(loc.merged.googleId, loc.merged.name).then((res) => {
                    resolve(res);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                reject(new Error("no location specified"));
            }
        });
        return promise;
    }

    openAsModalNoAction(blocation: ILocationContainer, enabled: IBusinessFeaturesEnabled) {
        this.openAsModal(blocation, enabled).then(() => { }).catch(() => { });
    }

    /**
     * open business features
     * return the code of the business feature that was activated/used
     * when the promise resolves, the caller should request the enable flags again
     * @param blocation 
     * @param enabled 
     */
    openAsModal(blocation: ILocationContainer, enabled: IBusinessFeaturesEnabled) {
        let promise = new Promise((resolve) => {
            let actions: IPopoverActions = {};
            actions = {
                scanQR: {
                    name: "Check in",
                    code: 1,
                    icon: EAppIconsStandard.checkin,
                    enabled: enabled.qr
                },
                viewSales: {
                    name: "View sales",
                    code: 2,
                    icon: EAppIconsStandard.sales,
                    enabled: enabled.sales
                }
            };

            this.uiextStandard.showStandardModal(null, EModalTypes.options, "B-Interactive", {
                view: {
                    fullScreen: false,
                    transparent: AppConstants.transparentMenus,
                    large: true,
                    addToStack: true,
                    frame: false
                },
                params: { actions: actions }
            }).then((code: number) => {
                switch (code) {
                    case 1:
                        this.scanQrRegisterClientBLocation(blocation).then((_res: boolean) => {
                            resolve(true);
                        }).catch((err: Error) => {
                            console.error(err);
                            resolve(false);
                        });
                        break;
                    case 2:
                        this.viewSalesBLocation(blocation).then((_res: boolean) => {
                            resolve(true);
                        }).catch((err: Error) => {
                            console.error(err);
                            resolve(false);
                        });
                        break;
                    default:
                        resolve(false);
                        break;
                }
            }).catch((err: Error) => {
                console.error(err);
                resolve(false);
            });
        });
        return promise;
    }


    /**
     * check if business is registered
     * and enable qr code and sales buttons
     */
    loadBusinessFeatures(loc: ILocationContainer, storyline: boolean) {
        return this.loadBusinessFeaturesCore(loc ? loc.merged.registered : false, storyline, loc ? loc.merged : null);
    }

    /**
     * check if business is registered
     * and enable qr code and sales buttons
     */
    loadBusinessFeaturesCore(registered: boolean, storyline: boolean, bloc: IBackendLocation) {
        let enabled: IBusinessFeaturesEnabled = {
            qr: false,
            sales: false
        };
        if (storyline) {
            if (bloc) {
                if (bloc.scan) {
                    enabled.qr = true;
                }
                if (ActivityUtils.getSalesAvailable(bloc.activity)) {
                    enabled.sales = true;
                }
            }
        } else {
            if (registered) {
                enabled.qr = true;
                enabled.sales = true;
            }
        }
        this.enabled = enabled;
        return enabled;
    }

    resetBusinessFeatures() {
        let keys: string[] = Object.keys(this.enabled);
        for (let i = 0; i < keys.length; i++) {
            this.enabled[keys[i]] = false;
        }
    }

    getCurrentBusinessFeatures() {
        return this.enabled;
    }

}
