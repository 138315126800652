
import { Injectable } from '@angular/core';
import { Camera } from '@ionic-native/camera/ngx';
import { UiExtensionService } from '../general/ui/ui-extension';
import { FileManagerService } from './file';
import { GenericDataService } from '../general/data/generic';
import { IBase64Ext } from 'src/app/classes/def/media/photo';
import { WebviewUtilsService } from '../app/utils/webview-utils';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { SettingsManagerService } from '../general/settings-manager';


/**
 * the interface between the top providers (callers) and the low level providers (save to file, request apis)
 * handles the direct media apis and interfaces (camera, sound recording, media formatting etc)
 */
@Injectable({
    providedIn: 'root'
})
export class ImageUtilsService {
    contentTypes: string[] = ['image/jpg', 'image/jpeg', 'image/png'];
    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public camera: Camera,
        public generic: GenericDataService,
        public uiext: UiExtensionService,
        public fileManager: FileManagerService,
        public webviewUtils: WebviewUtilsService,
        public settingsProvider: SettingsManagerService

    ) {
        console.log("media utils service created");

        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    /**
    * Convert a Blob to base64 string
    * @param blob 
    */
    blobToB64(blob: Blob) {
        let promise = new Promise((resolve, reject) => {
            if (!blob) {
                reject(new Error("Invalid Blob provided"));
                return;
            }
            const reader = this.fileManager.getFileReader();
            reader.onload = () => {
                console.log("FileReader onload triggered");
                let dataUrl: string = reader.result as string;
                // let base64 = dataUrl.split(',')[1];
                let base64 = dataUrl;
                resolve(base64);
            };
            reader.onerror = (err) => {
                console.error("FileReader onerror triggered", err);
                reject(err);
            };
            reader.onabort = (err) => {
                console.error("FileReader onabort triggered", err);
                reject(new Error("File reading aborted"));
            };
            reader.onloadend = () => {
                console.log("FileReader onloadend triggered");
            };
            console.log("Starting to read the Blob as data URL");
            reader.readAsDataURL(blob);
        });
        return promise;
    }

    /**
    * Convert a base64 string in a Blob according to the data and contentType.
    * 
    * @param b64Data {String} Pure base64 string without contentType
    * @param contentType {String} the content type of the file i.e (image/jpeg - image/png - text/plain)
    * @param sliceSize {Int} SliceSize to process the byteCharacters
    * @see http://stackoverflow.com/questions/16245767/creating-a-blob-from-a-base64-string-in-javascript
    * @return Blob
    */
    b64toBlob(b64Data: string, contentType: string, sliceSize: number) {
        contentType = contentType || '';
        sliceSize = sliceSize || 512;

        let byteCharacters = atob(b64Data);
        let byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            let slice = byteCharacters.slice(offset, offset + sliceSize);

            let byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            let byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        let blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    /**
     * save base64 image data downloaded from the server (profile picture) to file
     * @param base64Data 
     */
    saveBase64ImageToFile(filename: string, base64Data: string, save: boolean, contentType: string) {
        let promise = new Promise((resolve, reject) => {
            if (!contentType) {
                contentType = "image/jpg";
            }
            let blob: Blob = this.b64toBlob(base64Data, contentType, null);
            // console.log(blob);
            if (this.platform.WEB) {
                let base64DataWithHeader: string = "data:" + contentType + ";base64," + base64Data;
                this.fileManager.downloadAsFileViaBrowserCore(filename, base64DataWithHeader); // this is just to have it in the gallery
                resolve(base64DataWithHeader); // returns base64 url
            } else {
                let promiseWrite: Promise<string>;
                if (save) {
                    promiseWrite = this.fileManager.writeFileSavePath(filename, blob);
                } else {
                    promiseWrite = this.fileManager.writeFileDefaultPath(filename, blob);
                }
                promiseWrite.then((filenameFull: string) => {
                    resolve(filenameFull);
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }

    /**
    * remove header (content type) and keep content only
    * @param base64Data 
    */
    formatBase64PhotoContent(base64Data: string): IBase64Ext {
        let contentType = this.contentTypes[0];
        for (let i = 0; i < this.contentTypes.length; i++) {
            if (base64Data.indexOf(this.contentTypes[i]) !== -1) {
                contentType = this.contentTypes[i];
                break;
            }
        }

        let base64DataContent: string = base64Data.replace("data:" + contentType + ";base64,", "");
        let res: IBase64Ext = {
            content: base64DataContent,
            contentType
        };
        return res;
    }

}



