
import { NgModule } from '@angular/core';
import { AmountInputViewComponent } from './modals/amount-input/amount-input.component';
import { CheckboxFrameViewComponent } from './modals/checkbox-frame/checkbox-frame.component';
import { DescriptionFrameViewComponent } from './modals/description-frame/description-frame.component';
import { ImageCropFrameViewComponent } from './modals/image-crop-frame/image-crop-frame.component';
import { OptionsListViewComponent } from './modals/options-list/options-list.component';
import { PhotoFrameViewComponent } from './modals/photo-frame/photo-frame.component';
import { RatingFrameViewComponent } from './modals/rating-frame/rating-frame.component';
import { RewardPopupViewComponent } from './modals/reward-popup/reward-popup.component';
import { TextInputViewComponent } from './modals/text-input/text-input.component';
import { TextInputCoreViewComponent } from './modals/text-input-core/text-input-core.component';
import { AppComponentsModule } from 'src/app/components/app/app-components.module';
import { AppComponentsGenericModule } from 'src/app/components/generic/generic-components.module';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule } from '@angular/forms';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ListSelectorViewComponent } from './modals/list-selector/list-selector.component';
import { YouTubeViewFrameComponent } from './modals/youtube-view-frame/youtube-view-frame.component';
import { GridSelectorViewComponent } from './modals/grid-selector/grid-selector.component';
import { DateSelectorViewComponent } from './modals/date-selector/date-selector.component';
import { SlidesSelectorViewComponent } from './modals/slides-selector/slides-selector.component';
import { NgxStarsModule } from 'ngx-stars';
import { CheckboxGridFrameViewComponent } from './modals/checkbox-grid-frame/checkbox-grid-frame.component';
import { RewardPopupToastViewComponent } from './modals/reward-popup-toast/reward-popup-toast.component';
import { AvRecordingFrameComponent } from './modals/av-recording-frame/av-recording-frame.component';
import { AudioRecordingFrameComponent } from './modals/audio-recording-frame/audio-recording-frame.component';
import { CameraFrameViewComponent } from './modals/camera-frame/camera-frame.component';
import { CameraScanFrameViewComponent } from './modals/camera-scan-frame/camera-scan-frame.component';
import { DescriptionFrameExtraViewComponent } from './modals/description-frame-extra/description-frame-extra.component';

const components = [
    AmountInputViewComponent,
    AudioRecordingFrameComponent,
    AvRecordingFrameComponent,
    CameraFrameViewComponent,
    CameraScanFrameViewComponent,
    CheckboxFrameViewComponent,
    CheckboxGridFrameViewComponent,
    DateSelectorViewComponent,
    DescriptionFrameViewComponent,
    DescriptionFrameExtraViewComponent,
    ImageCropFrameViewComponent,
    OptionsListViewComponent,
    PhotoFrameViewComponent,
    RatingFrameViewComponent,
    RewardPopupViewComponent,
    RewardPopupToastViewComponent,
    SlidesSelectorViewComponent,
    TextInputViewComponent,
    TextInputCoreViewComponent,
    ListSelectorViewComponent,
    GridSelectorViewComponent,
    YouTubeViewFrameComponent
];

@NgModule({
    declarations: [...components],
    entryComponents: [...components],
    imports: [
        CommonModule,
        IonicModule,
        FormsModule,
        PipesModule,
        DirectivesModule,
        AppComponentsModule,
        AppComponentsGenericModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        FlexLayoutModule,
        NgxStarsModule
    ],
    exports: [...components]
})
export class GenericModalsModule { }
