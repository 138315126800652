<ion-header [ngClass]="getHeaderClass()">
  <leplace-toolbar [menu]="!enableBackButton" leftButton="arrow-back" [leftIcon]="true" (leftClick)="back()"
    rightButton="settings" [rightIcon]="true" title="My Account" (rightClick)="presentPopover()"
    [loading]="!loaded"></leplace-toolbar>
</ion-header>


<!-- <ion-content padding-s class="theme-bg"> -->
<ion-content [ngClass]="theme">
  <!-- (swipe)="swipeEvent($event)" -->
  <div class="view-frame padding-s no-scroll theme-background" fxLayout="column" style="text-align: center">
    <!-- <span class="space"></span> -->

    <ion-fab vertical="top" horizontal="start" class="fab-margin-top-gmap-detail">
      <!-- button-color-primary -->

      <ion-fab-button>
        <ion-button class="round-fab round-fab-size" (click)="showOverview()"
          [blink]="{enable: true, timeout: 1, class: 'button-color-primary-70', hclass: 'button-color-alternate'}">
          <icon-wrapper slot="icon-only" [icon]="appIconsStandard.clipboard"></icon-wrapper>
        </ion-button>
      </ion-fab-button>

      <ion-fab-button>
        <ion-button class="round-fab round-fab-size button-color-primary-70" (click)="goToPhotoSelection()">
          <icon-wrapper slot="icon-only" icon="camera"></icon-wrapper>
        </ion-button>
      </ion-fab-button>

      <ion-fab-button>
        <ion-button class="round-fab round-fab-size button-color-alternate" (click)="goToInventory()">
          <icon-wrapper slot="icon-only" [icon]="appIcons.backpack" [custom]="true"></icon-wrapper>
        </ion-button>
      </ion-fab-button>

    </ion-fab>

    <div>
      <span class="info-text-mlg bold text-color-primary">{{username}}</span>
      <div class="margin-vertical-s progress-bar-alternate">
        <mat-progress-bar mode="determinate" class="progress-rounded" [value]="levelPercent"></mat-progress-bar>
      </div>

      <span class="info-text-m text-color-primary">{{levelMsg}}</span>
    </div>



    <div fxFlex class="generic-chart-container">
      <leplace-polar-chart *ngIf="init" [data]="polarData" [options]="chartOptions" (action)="onSelect($event)"
        class="account-chart"></leplace-polar-chart>
    </div>

    <!-- <div fxFlex class="chart-container">
        <ngx-charts-polar-chart *ngIf="init" [scheme]="chartOptions.colorScheme" [results]="polarData" [gradient]="chartOptions.gradient"
          [xAxis]="chartOptions.showXAxis" [yAxis]="chartOptions.showYAxis" [legend]="chartOptions.showLegend"
          [showXAxisLabel]="chartOptions.showXAxisLabel" [showYAxisLabel]="chartOptions.showYAxisLabel" [xAxisLabel]="chartOptions.xAxisLabel"
          [yAxisLabel]="chartOptions.yAxisLabel" (select)="onSelect($event)" [yAxisMinScale]="chartOptions.yAxisMinScale">
        </ngx-charts-polar-chart>
      </div> -->

  </div>
</ion-content>