
import { Injectable } from '@angular/core';
import { GenericDataService } from '../general/data/generic';

import { IGenericResponse } from '../../classes/def/requests/general';
import { ITreasureSpec, ILeplaceTreasure, ITreasureUpdateContent, ITreasureCustomContent, TreasureUtils } from '../../classes/def/places/leplace';
import { PhotosService } from '../media/photos';
import { UiExtensionService } from '../general/ui/ui-extension';
import { Messages } from '../../classes/def/app/messages';
import { EAlertButtonCodes } from '../../classes/def/app/ui';
import { SettingsManagerService } from '../general/settings-manager';
import { IPlatformFlags } from '../../classes/def/app/platform';
import { LocalDataService } from './local';
import { IPhotoResult } from 'src/app/classes/def/media/photo';
import { ILatLng } from 'src/app/classes/def/map/coords';

@Injectable({
    providedIn: 'root'
})
export class UserContentDataService {

    contentIndex: number = 0;
    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public generic: GenericDataService,
        public photoProvider: PhotosService,
        public uiext: UiExtensionService,
        public settings: SettingsManagerService
    ) {
        console.log("user content data service created");
        this.settings.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    init() {
        // console.log("user content provider init");
    }

    /**
     * fixed test object for AR testing
     * @param push public objects, add now
     */
    createFixedTreasure(location: ILatLng, type: number, specificType: number, nearbyLocationUid: string, content: ITreasureCustomContent, push: boolean) {
        let promise: Promise<ILeplaceTreasure> = new Promise((resolve, reject) => {
            let promisePhoto: Promise<string>;

            if (content.withPhoto) {
                promisePhoto = new Promise((resolve) => {
                    this.uiext.showAlert(Messages.msg.addPhotoTreasure.before.msg, Messages.msg.addPhotoTreasure.before.sub, 2, ["no", "yes"]).then((res: number) => {
                        switch (res) {
                            case EAlertButtonCodes.ok:
                                if (!this.platform.WEB) {
                                    this.photoProvider.getPhotoFromCameraWizard(false, false).then((photo: IPhotoResult) => {
                                        resolve(photo.data);
                                    }).catch((err: Error) => {
                                        console.error(err);
                                        resolve(null);
                                    });
                                } else {
                                    resolve(LocalDataService.getTestImage());
                                }
                                break;
                            case EAlertButtonCodes.cancel:
                            default:
                                resolve(null);
                                break;
                        }
                    });
                });
            } else {
                promisePhoto = Promise.resolve(null);
            }

            promisePhoto.then((photoData: string) => {
                content.photoUrl = photoData;
                if (push) {
                    this.generic.genericPostStandard("/user-content/add-fixed-treasure", {
                        lat: location.lat,
                        lng: location.lng,
                        type: type,
                        specificType: specificType,
                        content: content,
                        public: false,
                        nearbyLocationUid: nearbyLocationUid
                    }).then((response: IGenericResponse) => {
                        let treasure: ILeplaceTreasure = response.data;
                        resolve(treasure);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                } else {
                    let treasure: ILeplaceTreasure = TreasureUtils.getLocalTreasureDataFromUserContent(location, type, specificType, nearbyLocationUid, content, this.contentIndex);
                    this.contentIndex += 1;
                    resolve(treasure);
                }
            });
        });
        return promise;
    }

    /**
     * remove fixed treasure that was created by the same user
     * @param treasureId 
     */
    removeFixedTreasure(treasureId: number, editor: boolean) {
        return this.generic.genericPostStandard("/user-content/remove-fixed-treasure", {
            treasureId: treasureId,
            editor: editor
        });
    }

    /**
     * update fixed treasure that was created by the same user
     * @param treasureId 
     */
    updateFixedTreasure(treasureId: number, data: ITreasureUpdateContent, editor: boolean) {
        return this.generic.genericPostStandard("/user-content/update-fixed-treasure", {
            treasureId: treasureId,
            data: data,
            editor: editor
        });
    }

    checkWorldEdit(data: ILeplaceTreasure[]) {
        if (!data) {
            return [];
        }
        let mods: ILeplaceTreasure[] = data.filter((tm: ILeplaceTreasure) => {

            // check update position
            if (tm.placeMarker) {
                let loc1: ILatLng = tm.placeMarker.initLocation;
                let loc2: ILatLng = tm.placeMarker.location;
                if ((loc1.lat !== loc2.lat) || (loc1.lng !== loc2.lng)) {
                    return true;
                }
            } else {
                // console.log("no place marker at: ", tm);
            }

            // check other updates e.g. content
            if (tm.dynamic.updated) {
                return true;
            }
            return false;
        });
        return mods;
    }

    /**
    * update fixed treasure that was created by the same user
    * @param treasureId 
    */
    updateFixedTreasureArray(data: ILeplaceTreasure[], editor: boolean) {
        let promise = new Promise((resolve, reject) => {
            if (!(data && data.length > 0)) {
                reject(new Error("empty treasure array"));
                return;
            }

            let mods: ILeplaceTreasure[] = this.checkWorldEdit(data);

            console.log("input treasures: ", data);
            console.log("updated treasures: ", mods);

            let data1: ITreasureUpdateContent[] = mods.map(d => {
                let d1: ITreasureUpdateContent = {
                    id: d.id,
                    // set updated coords
                    lat: d.placeMarker ? d.placeMarker.location.lat : d.lat,
                    lng: d.placeMarker ? d.placeMarker.location.lng : d.lng,
                    name: d.placeMarker ? d.placeMarker.label : null,
                    description: d.treasureContent ? d.treasureContent.description : null
                };
                return d1;
            });

            this.generic.genericPostStandard("/user-content/update-fixed-treasure-array", {
                data: data1,
                editor: editor
            }).then((data) => {
                console.log(data);
                resolve(data);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get object specs list for creating user content
     */
    getFixedTreasureSpecs(): Promise<ITreasureSpec[]> {
        let promise: Promise<ITreasureSpec[]> = new Promise((resolve, reject) => {
            this.generic.genericGetStandard("/user-content/get-custom-content-specs", null).then((response: IGenericResponse) => {
                let objects: ITreasureSpec[] = response.data;
                resolve(objects);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * lock treasures in view
     * prevent them from being removed on db cleanup operations
     * @param treasureIds 
     */
    lockTreasures(treasureIds: number[]) {
        return this.generic.genericPostStandard("/user-content/lock-treasures", {
            treasureIds: treasureIds
        });
    }


    /**
     * unlock treasures in view
     * let them be removed on db cleanup operations
     * @param treasureIds 
     */
    unlockTreasures(treasureIds: number[]) {
        return this.generic.genericPostStandard("/user-content/unlock-treasures", {
            treasureIds: treasureIds
        });
    }
}
