import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { EStoryReleaseFlag, IStory } from 'src/app/classes/def/core/story';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EOS } from 'src/app/classes/def/app/app';
import { IGameItemIAP } from 'src/app/classes/def/items/game-item';
import { StoryUtils } from 'src/app/classes/utils/story-utils';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { GameStatsUtils } from 'src/app/services/app/utils/game-stats-utils';
import { IAPCoreService } from 'src/app/services/apis/iap-rc';

@Component({
  selector: 'story-info-promo',
  templateUrl: './story-info-promo.component.html',
  styleUrls: ['./story-info-promo.component.scss'],
})
export class StoryInfoPromoComponent implements OnInit, OnChanges, OnDestroy {

  locationType: string = "";

  @Input()
  story: IStory;

  @Input()
  large: boolean;

  @Input()
  showCategory: boolean;

  @Input()
  reload: boolean;

  level: number = 0;
  hasLevel: boolean = false;

  paidStory: boolean = false;
  qrEnabled: boolean = false;
  locked: boolean = false;
  // photoUrlLoaded: string;

  price: string = "";
  pricePromo: string = "";
  levelDisp: string = "";
  xpDisp: string = "";
  unlisted: boolean = false;
  private: boolean = false;

  isLoading: boolean = false;
  validIap: boolean = false;

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  providerSpec: string = null;


  constructor(
    public iapService: IAPCoreService
  ) {

  }

  ngOnInit() {
    if (!this.story) {
      return;
    }

    if (this.story.premium) {
      this.paidStory = true;
      if (this.story.itemIapCode) {
        this.checkIap();
      } else {
        if (this.story.priceCoins) {
          let price: string = this.story.priceCoins ? this.story.priceCoins + "" : "";
          this.price = price + " LP";
        } else {
          this.price = "FREE";
        }
      }
    }

    this.unlisted = this.story.devOnly === EStoryReleaseFlag.unlisted;
    this.private = this.story.devOnly === EStoryReleaseFlag.devOnly;

    if (StoryUtils.checkQRUnlock(this.story)) {
      this.qrEnabled = true;
    }

    this.locked = this.story.locked;

    if (this.story.level != null) {
      this.level = this.story.level;
      this.hasLevel = true;
    }

    if (this.story.primaryProvider != null) {
      this.providerSpec = "Provided by: " + this.story.primaryProvider.name;
    }

    // let potentialXp: number = GameStatsUtils.getStoryFinishedWeightAdjusted(this.story);
    this.levelDisp = "Level " + this.level;

    if (this.showCategory && this.story.categoryName) {
      // this.levelDisp += " " + this.story.categoryName.toUpperCase();
      this.levelDisp = this.story.categoryName.toUpperCase() + " (" + this.level + ")";
    }

    // if (potentialXp) {
    //   // this.xpDisp = potentialXp + " XP";
    //   this.levelDisp += " (" + potentialXp + " XP)";
    // }
  }

  checkIap() {
    let itemIap: IGameItemIAP = this.story.itemIap;
    this.iapService.syncPreloadedIAPData(this.story);
    if (itemIap) {
      if (GeneralCache.checkPlatformOS() === EOS.ios) {
        this.price = itemIap.priceIos;
        if (this.story.itemIapPromo != null) {
          this.pricePromo = this.story.itemIapPromo.priceIos;
        }
      } else {
        this.price = itemIap.price;
        if (this.story.itemIapPromo != null) {
          this.pricePromo = this.story.itemIapPromo.price;
        }
      }
      if (itemIap.hiddenPrice) {
        this.price = "IAP";
      }
      this.validIap = itemIap.valid;
      if (!itemIap.iapLoaded) {
        this.isLoading = true;
      } else {
        this.isLoading = false;
      }
    } else {
      this.price = "";
    }

    if (this.story.partnerUnlock) {
      this.price = "UNLOCK";
      this.pricePromo = null;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'reload':
            this.checkIap();
            break;
        }
      }
    }
  }

  ngOnDestroy(): void {

  }
}
