


import { Injectable } from '@angular/core';
// import html2canvas = require("html2canvas");
// var html2canvas = require('html2canvas')
// import { } from "html2canvas";

// import * as html2canvas from "html2canvas";
import html2canvas from 'html2canvas'; 

import { MediaUtilsService } from './media-utils';
import { UiExtensionService } from '../general/ui/ui-extension';
import { IPlatformFlags } from '../../classes/def/app/platform';
import { SettingsManagerService } from '../general/settings-manager';
import { IPhotoFrameNavParams, PhotoFrameViewComponent } from 'src/app/modals/generic/modals/photo-frame/photo-frame.component';


@Injectable({
    providedIn: 'root'
})
export class ScreenshotService {

    platform: IPlatformFlags = {} as IPlatformFlags;

    constructor(
        public mediaUtils: MediaUtilsService,
        public uiext: UiExtensionService,
        public settingsProvider: SettingsManagerService
    ) {
        console.log("screenshot service created");
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        });
    }

    screenshotJS(element: HTMLElement): Promise<string> {
        let promise: Promise<string> = new Promise((resolve, reject) => {
            // if (this.platform.IOS) {
            //     reject(new Error("method not available"));
            // } else {
            html2canvas(element != null ? element : document.body).then((canvas: HTMLCanvasElement) => {
                let imgData: string = canvas.toDataURL("image/jpg");
                // console.log(imgData);
                resolve(imgData);
            }).catch((err: Error) => {
                reject(err);
            });
            // }
        });
        return promise;
    }


    /**
     * capture screenshot and submit for watermark
     * show results in a preview modal with save/share options
     * @param name 
     */
    captureScreenshot(name: string, element: HTMLElement, _native: boolean) {
        let promise = new Promise((resolve, reject) => {
            let promiseScreenshot: Promise<string>;
            promiseScreenshot = this.screenshotJS(element);
            promiseScreenshot.then((data: string) => {
                this.prepareScreenshot(name, data).then((params: IPhotoFrameNavParams) => {
                    this.previewScreenshot(params).then((res) => {
                        resolve(res);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * request media api, add watermark
     * @param name 
     * @param screenshotDataURI 
     */
    prepareScreenshot(name: string, screenshotDataURI: string): Promise<IPhotoFrameNavParams> {
        let promise: Promise<IPhotoFrameNavParams> = new Promise((resolve, reject) => {
            // console.log("prepare screenshot: " + screenshotDataURI);
            let promiseWatermark: Promise<IPhotoFrameNavParams>;
            if (!this.platform.WEB) {
                promiseWatermark = new Promise((resolve, reject) => {
                    this.mediaUtils.addWatermark(screenshotDataURI).then((imageData: string) => {
                        let params: IPhotoFrameNavParams = {
                            photoUrl: imageData,
                            title: name,
                            sharePhoto: true,
                            isDataUrl: true,
                            uploadPhoto: false
                        };
                        resolve(params);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                });
            } else {
                // let params: IPhotoFrameNavParams = {
                //     photoUrl: screenshotDataURI,
                //     title: name,
                //     sharePhoto: true,
                //     uploadPhoto: false,
                //     isDataUrl: true
                // };
                // promiseWatermark = Promise.resolve(params);

                promiseWatermark = new Promise((resolve, reject) => {
                    this.mediaUtils.addWatermark(screenshotDataURI).then((imageData: string) => {
                        let params: IPhotoFrameNavParams = {
                            photoUrl: imageData,
                            title: name,
                            sharePhoto: true,
                            isDataUrl: true,
                            uploadPhoto: false
                        };
                        resolve(params);
                    }).catch((err: Error) => {
                        reject(err);
                    });
                });
            }

            promiseWatermark.then((params: IPhotoFrameNavParams) => {
                resolve(params);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * display photo result frame
     * @param params 
     */
    previewScreenshot(params: IPhotoFrameNavParams): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            this.uiext.showCustomModal(null, PhotoFrameViewComponent, {
                view: {
                    fullScreen: false,
                    transparent: false,
                    large: false,
                    addToStack: true,
                    frame: false
                },
                params: params
            }).then(() => {
                resolve(true);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }
}




