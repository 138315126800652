import { IPlaceExtContainer } from '../places/container';

export interface IPlaceScanParams {
    type: string;
    limit: number;
}

/**
 * from the db
 */
export interface IPlaceScanParamsResponse {
    treasures: IPlaceScanParams[];
    placeAds: IPlaceScanParams[];
}

export interface IProcessScanTreasuresDMSRequest extends IProcessScanTreasuresAdsRequest {
    radius: number,
    nearbyScan: boolean
};

export interface IProcessScanTreasuresAdsRequest {
    lat: number,
    lng: number,
    standard: number[],
    sortRating: boolean,
    data: IPlaceExtContainer[],
};


export interface IWorldMapRefreshOptions {
    hardReset: boolean;
    markerClustering: boolean;
    /**
     * e.g. in mp mode filter the treasures that are available for mp (e.g. only some activities, no stories/yet)
     */
    gameContextCode: number;

    // check if private world map
    // only use leplace api for location provider
    privateScannerMode: number;
    // used to retrieve the event/story world map, dynamic loading
    privateScannerItemId: number;
    // disable zoom layers
    fixed: boolean;
    // event group role, enable allowed challenges
    groupRole: number;
    // time based scanner only
    timeBasedScanner: boolean;
    // show/hide treasures based on actual proximity
    locationBasedFiltering: boolean;
}

export enum EPrivateScannerMode {
    disabled = 0,
    event = 1,
    story = 2
}

export enum EItemScanRequired {
    none = 0,
    scan = 1,
    cacheSync = 2
}
