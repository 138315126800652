import { Component, OnInit, Input } from '@angular/core';
import { PopupFeaturesService } from 'src/app/services/app/modules/minor/popup-features';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { EAppIcons, EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { FileManagerService } from 'src/app/services/media/file';

@Component({
  selector: 'qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss'],
})
export class QrCodeComponent implements OnInit {

  appIcons = EAppIcons;
  appIconsStandard = EAppIconsStandard;

  @Input()
  code: string;

  @Input()
  version: number;

  @Input()
  interact: number;

  @Input()
  index: number;

  show: boolean = false;
  customId: string = "qrcode";

  constructor(
    public popupFeatures: PopupFeaturesService,
    public fileManager: FileManagerService
  ) { }

  ngOnInit() {
    if (this.code != null) {
      this.show = true;
    }

    // https://www.qrcode.com/en/about/version.html
    if (!this.version) {
      this.version = 10;
    }

    if (this.index != null) {
      this.customId += "-" + this.index;
    }
  }

  copyToClipboard() {
    this.popupFeatures.copyToClipboard(this.code);
  }

  downloadAsImage() {
    let qrcode = document.getElementById(this.customId);
    let canvas = qrcode.querySelector("canvas") as HTMLCanvasElement;
    let imageData = canvas.toDataURL("image/png").toString();
    this.fileManager.downloadAsFileViaBrowserCore("qrcode.png", imageData);
  }
}