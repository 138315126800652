import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { ICustomParamForActivity } from 'src/app/classes/def/core/custom-param';
import { BehaviorSubject } from 'rxjs';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { IItemAction } from 'src/app/classes/def/items/action';

@Component({
  selector: 'grid-activity-param',
  templateUrl: './grid-activity-param.component.html',
  styleUrls: ['./grid-activity-param.component.scss'],
})
export class GridActivityParamComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  data: ICustomParamForActivity[];
  @Input()
  emptyMessage: string;
  @Input()
  showLabel: boolean;

  @Output()
  action: EventEmitter<any> = new EventEmitter();

  @Input()
  interact: boolean;

  availableItems: boolean = false;
  itemGrid: Array<Array<ICustomParamForActivity>>;
  rowSpec: number = 2;

  /**
   * sync blink
   * between all items on the grid
   */
  blinkTimerSub: BehaviorSubject<boolean>;

  timerSub: any;

  timeout = {
    blink: null
  };

  constructor(

  ) {
    this.blinkTimerSub = new BehaviorSubject(null);
  }

  ngOnChanges(changes: SimpleChanges) {
    let keys: string[] = Object.keys(changes);
    for (let i = 0; i < keys.length; i++) {
      const chg: SimpleChange = changes[keys[i]];
      if (!chg.isFirstChange()) {
        switch (keys[i]) {
          case 'data':
            this.updateItemGrid(this.data);
            break;
        }
      }
    }
  }

  ngOnDestroy() {
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
  }

  itemAction(_itemAction: IItemAction, item: ICustomParamForActivity) {
    // let actionContainer: IItemActionGenericContainer = {
    //     action: itemAction,
    //     item: item
    // };
    this.action.emit(item);
  }

  ngOnInit() {
    // console.log(this.data);
    if (this.data) {
      this.updateItemGrid(this.data);
      console.log(this.itemGrid);
    }
  }

  /**
   * update item grid for display
   * @param category 
   */
  updateItemGrid(items: ICustomParamForActivity[]) {
    // console.log("update item grid");
    let rowNum: number = 0; // counter to iterate over the rows in the grid

    this.availableItems = items && (items.length > 0);
    if (!this.availableItems) {
      return;
    }

    this.itemGrid = Array(Math.ceil(items.length / 2));
    for (let i = 0; i < items.length; i += this.rowSpec) {
      this.itemGrid[rowNum] = Array(this.rowSpec); // declare two elements per row
      if (items[i]) {
        this.itemGrid[rowNum][0] = items[i];
      }
      if (items[i + 1]) {
        this.itemGrid[rowNum][1] = items[i + 1];
      }
      rowNum++; // go on to the next row
    }
  }
}

