<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="continue()" [loading]="loading" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div class="bg-container bg-container-fill bg-filter-2"
    [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: coverItem ? coverItem.photoUrl : null}">

    <div class="content">
      <div class="content-height">
        <div fxLayout="column" class="content-height">

          <div class="scrollbar-y scroll-content-modal-1 theme-border-bottom">
            <div class="padding-s">
              <div [innerHTML]="infoHTML" class="info-text-m bold description-frame text-color-primary center-text">
              </div>
            </div>

            <ion-row *ngIf="gameItems">
              <ion-col class="center-text">
                <!-- <span class="info-text-m bold text-color-primary center-text">Medals</span> -->
                <grid-item [data]="gameItems" [interact]="true" [basicView]="true" emptyMessage="No medals available">
                </grid-item>
              </ion-col>
            </ion-row>

            <!-- <div *ngIf="shareMessage" class="padding-s" fxLayout="column">
              <span class="info-text-m bold text-color-primary center-text">Share progress</span>
              <span class="info-text-m text-color-primary center-text">{{shareMessage}}</span>
            </div> -->

            <div class="padding-s" *ngIf="showFooter">
              <div [innerHTML]="footerMessage"
                class="info-text-s bold description-frame text-color-primary center-text">
              </div>
            </div>

            <div *ngIf="closeTimerValue > 0">
              <span class="info-text-m bold text-color-primary center-text">This window will close in:
                {{closeTimerValue}}
              </span>
            </div>
          </div>

          <div fxFlex></div>
          <div class="stick-bottom">
            <ion-grid class="full-w">
              <ion-row>
                <!-- <ion-col size="6">
                  <ion-button class="action-button-fill button-color-primary-70" [disabled]="!shareEnabled || loading"
                    (click)="shareAndContinue()">
                    <icon-wrapper slot="icon-only" icon="share"></icon-wrapper>
                  </ion-button>
                </ion-col>

                <ion-col size="6">
                  <ion-button class="action-button-fill button-color-accent" (click)="continue()">
                    <icon-wrapper slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
                  </ion-button>
                </ion-col> -->

                <ion-col size="12">
                  <ion-button class="action-button-fill button-color-accent" (click)="continue()">
                    <span class="button-font-size-s">Continue</span>
                    <icon-wrapper slot="start" class="slot-icon-padding" [icon]="appIcons.continue"
                      [custom]="true"></icon-wrapper>
                  </ion-button>
                </ion-col>
              </ion-row>

            </ion-grid>
          </div>
        </div>
      </div>
    </div>
  </div>
</modal-container>