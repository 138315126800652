
import { IPhotoResultResponse } from './../../classes/def/media/processing';
import { Injectable } from '@angular/core';
// import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { UiExtensionService } from '../general/ui/ui-extension';
import { FileManagerService } from './file';
import { GenericDataService } from '../general/data/generic';
import * as watermark from 'watermarkjs';
import { EPhotos } from '../../classes/def/app/icons';
import { IGenericResponse } from '../../classes/def/requests/general';
import { IImageCropFrameNavParams, ImageCropFrameViewComponent } from 'src/app/modals/generic/modals/image-crop-frame/image-crop-frame.component';
import { IPhotoResult, IBase64Ext, IImageSize } from 'src/app/classes/def/media/photo';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { WebviewUtilsService } from '../app/utils/webview-utils';
import { AppConstants } from 'src/app/classes/app/constants';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { SettingsManagerService } from '../general/settings-manager';
import { CameraFrameViewComponent } from 'src/app/modals/generic/modals/camera-frame/camera-frame.component';
import { ICameraFrameNavParams, ICameraFrameReturnParams } from 'src/app/modals/generic/modals/camera-frame/utils';
import { ImageUtilsService } from './image-utils';

import { Camera, CameraDirection, CameraResultType, CameraSource, ImageOptions } from '@capacitor/camera';


export interface IResizeImageResult {
    content: string | Blob,
    warn: boolean,
    message: string,
    detail: string
}

/**
 * the interface between the top providers (callers) and the low level providers (save to file, request apis)
 * handles the direct media apis and interfaces (camera, sound recording, media formatting etc)
 */
@Injectable({
    providedIn: 'root'
})
export class MediaUtilsService {

    targetWidth: number = 1200;
    quality: number = 60;
    maxDataSize: number = 1024;
    enableCrop: boolean = true;

    contentTypes: string[] = ['image/jpg', 'image/jpeg', 'image/png'];
    private win: any = window;

    platform: IPlatformFlags = {} as IPlatformFlags;


    constructor(
        // public camera: Camera,
        public generic: GenericDataService,
        public uiext: UiExtensionService,
        public fileManager: FileManagerService,
        public webviewUtils: WebviewUtilsService,
        public imgUtils: ImageUtilsService,
        public settingsProvider: SettingsManagerService

    ) {
        console.log("media utils service created");

        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    initConfig() {
        if (AppConstants.gameConfig.photoTargetWidth != null) {
            this.targetWidth = AppConstants.gameConfig.photoTargetWidth;
        }
        if (AppConstants.gameConfig.photoTargetQuality != null) {
            this.quality = AppConstants.gameConfig.photoTargetQuality;
        }
        if (AppConstants.gameConfig.maxPhotoDataSize != null) {
            this.maxDataSize = AppConstants.gameConfig.maxPhotoDataSize;
        }
    }

    getImgSrc(src: string) {
        if (src) {
            return this.win.Ionic.WebView.convertFileSrc(src);
        }
        return src
    }

    uploadPhotoViaBrowser() {
        return new Promise<string>((resolve, reject) => {
            this.fileManager.uploadFileViaBrowser(".png, .jpg", true, null).then((res: string) => {
                resolve(res);
            }).catch((err: Error) => {
                reject(err);
            });
        });
    }

    /**
     * take photo and retrieve data content
     * remove the file afterwards
     * @param options 
     * @param crop 
     */
    private preparePhoto(options: ImageOptions, crop: boolean, _save: boolean): Promise<IPhotoResult> {
        let result: IPhotoResult = {
            fileURI: null,
            data: null
        };

        let promise: Promise<IPhotoResult> = new Promise(async (resolve, reject) => {
            let fileURI: string = "";
            let croppedB64: string = "";
            let resizedB64: string = "";
            let format: string = "";
            let useFileURI: boolean = false;

            try {
                if (!this.platform.WEB) {
                    let image = await Camera.getPhoto(options);
                    fileURI = "data:image/jpeg;base64," + image.base64String;
                    useFileURI = false;
                    // format = "image/jpeg";

                    // fileURI = image.webPath;
                    // console.log("captured image: " + fileURI);
                    // fileURI = this.getImgSrc(fileURI);
                    // console.log("converted URI: " + fileURI);
                } else {
                    useFileURI = true;
                    if (options.source === CameraSource.Photos) {
                        // photo upload
                        fileURI = await this.uploadPhotoViaBrowser();                       
                        console.log("uploaded image");
                    } else {
                        // take photo
                        let params: ICameraFrameNavParams = {
                            title: "Take photo",
                            description: "",
                            caption: "lpworld"
                        };
                        let res: ICameraFrameReturnParams = await this.uiext.showCustomModal(null, CameraFrameViewComponent, {
                            view: {
                                fullScreen: false,
                                transparent: false,
                                large: true,
                                addToStack: true,
                                frame: false
                            },
                            params: params
                        });
                        if (res != null) {
                            await this.checkDataSize(res.photoContent);
                            result.fileURI = res.photoFile; // base64 encoded
                            // result.data = res.photoContent;
                            result.data = res.photoFile; //base64 encoded
                            resolve(result);
                            return;
                            // fileURI = res.photoFile;
                        } else {
                            reject(new Error("photo dismissed"));
                            return;
                        }
                    }
                }

                if (crop && this.enableCrop) {
                    let params: IImageCropFrameNavParams = {
                        description: "Edit Photo",
                        title: "Edit Photo",
                        photoUrl: fileURI,
                        quality: this.quality
                    };

                    croppedB64 = await this.uiext.showCustomModal(null, ImageCropFrameViewComponent, {
                        view: {
                            fullScreen: true,
                            transparent: false,
                            large: true,
                            addToStack: true,
                            frame: true
                        },
                        params
                    });
                } else {
                    croppedB64 = fileURI;
                }

                console.log("cropped image");

                if (!croppedB64) {
                    reject(new Error("photo dismissed"));
                    return;
                }
                await this.uiext.showLoadingV2Queue("Processing..");
                resizedB64 = await this.resizeImage(croppedB64, format, useFileURI);
                console.log("resized image");
                await this.checkDataSize(resizedB64);
                result.fileURI = resizedB64;
                result.data = resizedB64;
                await this.uiext.dismissLoadingV2();
                resolve(result);
            } catch (err) {
                reject(err);
            }
        });

        return promise;
    }

    /**
     * pick image from gallery, resize and return file uri and dbase64 data
     * crop to square ratio
     * resize/compress image
     */
    pickPhoto(crop: boolean): Promise<IPhotoResult> {
        let options: ImageOptions = {
            quality: this.quality,
            resultType: CameraResultType.Base64,
            source: CameraSource.Photos,
            saveToGallery: false
        };
        return this.preparePhotoWrapper(options, crop, false);
    }

    preparePhotoWrapper(options: ImageOptions, crop: boolean, save: boolean): Promise<IPhotoResult> {
        let promise: Promise<IPhotoResult> = new Promise((resolve, reject) => {
            this.preparePhoto(options, crop, save).then(async (res) => {
                await this.webviewUtils.resetViewframe(false, false);
                resolve(res);
            }).catch(async (err) => {
                await this.webviewUtils.resetViewframe(false, false);
                reject(err);
            });
        });
        return promise;
    }



    /**
     * capture image from camera and return file uri and dbase64 data
     * crop to square ratio
     * resize/compress image
     */
    takePhoto(selfie: boolean, crop: boolean, save: boolean) {
        let options: ImageOptions = {
            quality: this.quality,
            resultType: CameraResultType.Base64,
            source: CameraSource.Camera,
            saveToGallery: save,
            direction: selfie ? CameraDirection.Front : CameraDirection.Rear,
            width: this.targetWidth
        };
        return this.preparePhotoWrapper(options, crop, save);
    }

    /**
     * resize base64 image
     * @param imageData 
     */
    resizeImage(imageData: string, format: string, useFileURI: boolean): Promise<string> {
        let promise: Promise<string> = new Promise((resolve, reject) => {
            console.log("resize image");
            this.resizeImageCore(imageData, this.targetWidth, this.targetWidth, format, useFileURI).then((res: Blob) => {
                console.log("image resized (blob)");
                this.imgUtils.blobToB64(res).then((imageB64: string) => {
                    console.log("image resized (b64)");
                    resolve(imageB64);
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    checkDataSize(imageData: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            let b64Size: number = imageData.length / 1024;
            let imgSize: number = Math.floor(b64Size / 1.37);
            if (imgSize < this.maxDataSize) {
                resolve(true);
            } else {
                reject(new Error("<p>Image too large: " + imgSize + " KB</p><p>Allowed max: " + this.maxDataSize + " KB<p><p>You may try to resize the image or use .jpg instead of .png format</p>"));
            }
        });
        return promise;
    }


    saveBlobVideo(filename: string, blob: string | Blob | ArrayBuffer): Promise<string> {
        let promise: Promise<string> = new Promise((resolve, reject) => {
            let promiseWrite = this.fileManager.writeFileSavePath(filename, blob);
            promiseWrite.then((filenameFull: string) => {
                resolve(filenameFull);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * get logo resized to the container with relative width spec
     * @param containerWidth 
     */
    getLogoResized(containerWidth: number, relativeSize: number = 0.2) {
        let promise = new Promise((resolve, reject) => {
            fetch(EPhotos.logo).then((res) => {
                res.blob().then((blob: Blob) => {
                    let width = containerWidth * relativeSize;
                    const reader = this.fileManager.getFileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = (event: any) => {
                        const img = new Image();
                        img.src = event.target.result;
                        img.onload = () => {
                            const elem = document.createElement('canvas');
                            const scaleFactor = width / img.width;
                            elem.width = width;
                            elem.height = img.height * scaleFactor;
                            const ctx = elem.getContext('2d');
                            // img.width and img.height will contain the original dimensions
                            ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
                            ctx.canvas.toBlob((blob) => {
                                // const file = new File([blob], fileName, {
                                //     type: 'image/jpeg',
                                //     lastModified: Date.now()
                                // });
                                resolve(blob);
                            }, 'image/png', this.quality);
                        };
                        reader.onerror = (err) => {
                            reject(err);
                        };
                    };
                }).catch((err: Error) => {
                    reject(err);
                });
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * resize image
     * downscale only
     * @param imageB64 
     * @param targetWidth 
     * @param format 
     */
    private resizeImageCore(imageB64: string, targetWidth: number, maxHeight: number, format: string, useFileURI: boolean) {
        let promise = new Promise(async (resolve, reject) => {
            if (!format) {
                // extract from b64
                // data:image/png,
                let b64test: string = imageB64.slice(imageB64.indexOf("data:"), imageB64.indexOf(","));
                // console.log(b64test);
                if (
                    (b64test.indexOf("jpeg") !== -1) ||
                    (b64test.indexOf("jpg") !== -1)
                ) {
                    format = "image/jpeg";
                } else if (b64test.indexOf("png") !== -1) {
                    format = "image/png";
                } else {
                    console.log("unrecognized format");
                    format = "image/jpeg";
                }
                console.log("format detected: ", format);
            }
            console.log("fetching base64 image");

            let loadImgFn = (src: string) => {
                const img = new Image();
                img.src = src;
                img.onload = () => {
                    console.log("loaded image");
                    const elem = document.createElement('canvas');
                    let scaleFactor: number = 0;

                    let newWidth: number = 0;
                    let newHeight: number = 0;

                    if (targetWidth > img.width) {
                        // keep original / don't downscale
                        scaleFactor = 1;
                        newWidth = img.width;
                        newHeight = img.height;
                    } else {
                        // downscale
                        scaleFactor = targetWidth / img.width;
                        newWidth = targetWidth;
                        newHeight = img.height * scaleFactor;
                    }

                    console.log("resize width scale factor: " + scaleFactor + " (" + newWidth + "x" + newHeight + ")");

                    if (newHeight > maxHeight) {
                        scaleFactor = maxHeight / newHeight;
                        newHeight = maxHeight;
                        newWidth = newWidth * scaleFactor;
                        console.log("resize height scale factor: " + scaleFactor + " (" + newWidth + "x" + newHeight + ")");
                    }


                    elem.width = newWidth;
                    elem.height = newHeight;

                    const ctx = elem.getContext('2d');
                    // img.width and img.height will contain the original dimensions
                    ctx.drawImage(img, 0, 0, newWidth, newHeight);
                    ctx.canvas.toBlob((blob) => {
                        resolve(blob);
                    }, format, this.quality);
                };
                img.onerror = (err) => {
                    console.error(err);
                    reject(err);
                };
            };

            try {
                if (useFileURI) {
                    let res: Response = await fetch(imageB64);
                    console.log("fetched base64 image");
                    let blob: Blob = await res.blob();
                    console.log("fetched blob from image");
                    const reader = this.fileManager.getFileReader();
                    reader.readAsDataURL(blob);
                    reader.onload = (event: any) => {
                        loadImgFn(event.target.result);
                    };
                    reader.onerror = (err) => {
                        console.error(err);
                        reject(err);
                    };
                } else {
                    loadImgFn(imageB64);
                }
            } catch (err) {
                reject(err);
            }
        });
        return promise;
    }


    /**
     * get image size from blob
     * @param blob 
     */
    getImageSizeFromBlob(blob: Blob): Promise<IImageSize> {
        let promise: Promise<IImageSize> = new Promise((resolve, reject) => {
            const reader = this.fileManager.getFileReader();
            reader.readAsDataURL(blob);
            reader.onload = (event: any) => {
                const img = new Image();
                img.src = event.target.result;
                img.onload = () => {
                    let result: IImageSize = {
                        width: img.width,
                        height: img.height
                    };
                    resolve(result);
                };
                reader.onerror = (err) => {
                    reject(err);
                };
            };
        });
        return promise;
    }




    /**
     * add leplace watermark
     * @param objects 
     */
    addWatermark(imageData: string) {
        let local: boolean = true;
        let promise = new Promise((resolve, reject) => {
            if (!local) {
                this.generic.genericPostStandard("/api/image/leplace-overlay", {
                    photo: imageData,
                    type: 1,
                    message: ""
                }, GeneralCache.resourceCache.general.appServices.content.object.media.url).then((res: IGenericResponse) => {
                    let data: IPhotoResultResponse = res.data;
                    resolve(data.processedImageData);
                }).catch((err: Error) => {
                    reject(err);
                });
            } else {
                fetch(imageData).then((res) => {
                    res.blob().then((blob: Blob) => {
                        this.getImageSizeFromBlob(blob).then((isize: IImageSize) => {
                            console.log("master image size: ", isize);
                            this.getLogoResized(isize.width, 0.2).then((logoBlob: Blob) => {
                                watermark([blob, logoBlob])
                                    // .image(watermark.text.lowerRight('MyPhoto', '28px serif', '#fff', 0.5))
                                    .image(watermark.image.lowerRight(0.5))
                                    .then((img) => {
                                        // console.log(img.src);
                                        resolve(img.src);
                                    }).catch((err: Error) => {
                                        reject(err);
                                    });
                            }).catch((err: Error) => {
                                reject(err);
                            });
                        }).catch((err: Error) => {
                            reject(err);
                        });
                    }).catch((err: Error) => {
                        reject(err);
                    });
                }).catch((err: Error) => {
                    reject(err);
                });
            }
        });
        return promise;
    }
}



