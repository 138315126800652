
import { Injectable } from "@angular/core";
import { ResourceManager } from "../../../../classes/general/resource-manager";
import { TimeoutService } from "../timeout";
import { LocalNotificationsService } from "../../../general/apis/local-notifications";
import { BehaviorSubject } from "rxjs";
import { ECheckActivityResult } from "../../../../classes/def/core/activity";
import { SignalProcessingService } from "../../utils/signal-processing";
import { SoundService } from "../../../media/sound";
import { SettingsManagerService } from "../../../general/settings-manager";
import { IPlatformFlags } from "../../../../classes/def/app/platform";
import { ISignalData } from "../../../../classes/def/processing/signal";
import { IDecibelActivityParams, IDecibelActivityStatus, IDecibelRTData } from 'src/app/classes/def/activity/decibel';
import { ITimeoutMonitorParams, ITimeoutMonitorData, ETimeoutStatus } from 'src/app/classes/general/timeout';
import { EBufferContext } from "src/app/classes/utils/buffer";
import { PermissionsService } from "src/app/services/general/permissions/permissions";


@Injectable({
    providedIn: 'root'
})
export class DecibelActivityService {

    subscription = {
        timeoutActivityMonitor: null,
        sound: null
    };

    params: IDecibelActivityParams;

    statusObservable: BehaviorSubject<IDecibelActivityStatus>;
    monitorObservable: BehaviorSubject<IDecibelRTData>;


    rtDataInit: IDecibelRTData = {
        db: 0,
        targetBpm: 100,
        targetDB: 0,
        timeCounter: 0,
        bpm: 0,
        raw: null
    };


    rtData: IDecibelRTData;

    moduleName: string = "DECIBEL ACTIVITY > ";

    /**
     * the target has been reached
     */
    unlocked: boolean = false;

    check: boolean = true;

    platform: IPlatformFlags = {} as IPlatformFlags;

    activityFinished: boolean = false;

    constructor(
        public timeoutMonitor: TimeoutService,
        public localNotifications: LocalNotificationsService,
        public sound: SoundService,
        public permissions: PermissionsService,
        public beatDetector: SignalProcessingService,
        public settingsProvider: SettingsManagerService
    ) {
        console.log("decibel activity service created");
        this.statusObservable = new BehaviorSubject(null);
        this.monitorObservable = new BehaviorSubject(null);

        this.initData();
        this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
            if (loaded) {
                this.platform = SettingsManagerService.settings.platformFlags;
                if (this.platform.WEB) {
                    this.check = false;
                }
            }
        }, (err: Error) => {
            console.error(err);
        });
    }

    watchStatus() {
        return this.statusObservable;
    }

    watchRT() {
        return this.monitorObservable;
    }

    initData() {
        this.unlocked = false;
        this.params = null;
        this.rtData = Object.assign({}, this.rtDataInit);
        this.beatDetector.initData(EBufferContext.sound);
        this.beatDetector.initData(EBufferContext.soundBpm);
    }

    /**
     * handle timeout activity
     */
    initActivity(timeLeft: number) {
        console.log(this.moduleName + "init");
        if (!this.subscription.timeoutActivityMonitor) {
            console.log(this.moduleName + "init started");
            this.statusObservable.next(null);
            this.monitorObservable.next(null);

            let tmParams: ITimeoutMonitorParams = {
                timeLimit: timeLeft
            };
            this.timeoutMonitor.start(tmParams);

            this.activityFinished = false;

            let status: IDecibelActivityStatus = {
                tmData: null,
                status: ECheckActivityResult.inProgress
            };

            this.initData();

            this.subscription.timeoutActivityMonitor = this.timeoutMonitor.getWatch().subscribe((tmData: ITimeoutMonitorData) => {

                if (tmData) {
                    // console.log(tmData.timerValue);
                    if (!tmData.isFallbackTimer) {
                        status.tmData = tmData;
                    }

                    if (this.unlocked) {
                        this.activityFinished = true;
                        status.status = ECheckActivityResult.done;
                        this.statusObservable.next(status);
                    }

                    switch (tmData.status) {
                        case ETimeoutStatus.expired:
                            status.status = ECheckActivityResult.failed;
                            this.statusObservable.next(status);
                            this.activityFinished = true;
                            break;
                    }

                    if (this.activityFinished) {
                        this.subscription.timeoutActivityMonitor = ResourceManager.clearSub(this.subscription.timeoutActivityMonitor);
                        console.log("stopping timer");
                        this.timeoutMonitor.stop();
                    } else {
                        this.statusObservable.next(status);
                    }
                }
            }, (err: Error) => {
                console.error(err);
            });
        }
    }

    /**
     * set activity params
     * required dbs and duration
     * @param params 
     */
    setActivityParams(params: IDecibelActivityParams) {
        this.params = params;
        console.log("set activity params: ", this.params);
        this.beatDetector.setTargetValue(EBufferContext.sound, params.targetDB);
    }

    /**
     * start listening
     */
    startMonitor() {
        // Start listening
        if (!this.subscription.sound) {
            let promiseCheck: any;
            if (this.check) {
                promiseCheck = this.permissions.requestMicrophonePermissions();
            } else {
                promiseCheck = Promise.resolve(true);
            }
            promiseCheck.then((status: boolean) => {
                if (status) {
                    this.subscription.sound = this.sound.startWatchDBMonitor(false).subscribe((data: ISignalData) => {
                        if (data) {
                            this.rtData.raw = data;
                            this.rtData.db = data.timeDomain.sample;
                            // convert to seconds
                            this.rtData.timeCounter = Math.floor(data.validation.timeCounter);
                            this.rtData.bpm = Math.floor(data.beatDetection.bpm);

                            if (this.params) {
                                this.rtData.targetDB = this.params.targetDB;
                                if (this.params.targetDuration && (this.rtData.timeCounter > this.params.targetDuration)) {
                                    // activity is validated
                                    // the target duration of valid conditions was reached 
                                    this.unlocked = true;
                                }
                            }

                            this.monitorObservable.next(this.rtData);
                        }
                    }, (err: Error) => {
                        console.error(err);
                    });
                }
            }).catch((err: Error) => {
                console.error(err);
            });
        }
    }


    /**
     * stop listening
     */
    stopMonitor() {
        if (this.subscription.sound) {
            this.subscription.sound = ResourceManager.clearSub(this.subscription.sound);
            this.sound.stopDBMonitor();
        }
    }

    exitActivity() {
        this.subscription = ResourceManager.clearSubObj(this.subscription);
        console.log("stopping timer");
        this.timeoutMonitor.stop();
        this.initData();
        this.activityFinished = false;
    }

}




