import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiDef } from '../../classes/app/api';
import { GenericDataService } from '../general/data/generic';
import { IUserStatsOverview, IStatChange, EStatCodes, IRegisterStoryFinished } from '../../classes/def/user/stats';
import { ISkillSet, ISkill, ISkillSetResponse } from '../../classes/def/user/skills';
import { IGenericResponse } from '../../classes/def/requests/general';
import { InventoryDataService } from './inventory';
import { EInventorySync } from 'src/app/classes/def/items/inventory';
import { EVirtualLocationSource } from '../app/modules/virtual-position';
import { IEventStoryGroupLinkData } from 'src/app/classes/def/core/links';
import { LinksDataService } from './links';

interface IReqGetUserStats {
    categoryCode?: number;
}

interface IReqGetStatsOverview {
    disp?: boolean;
    refreshCache?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class UserStatsDataService {
    serverUrl: string;

    constructor(
        public http: HttpClient,
        public generic: GenericDataService,
        public inventory: InventoryDataService,
        public links: LinksDataService
    ) {
        console.log("user stats data service created");
        this.serverUrl = ApiDef.mainServerURL;
    }

    init() {
        // console.log("user data provider init");
    }

    /**
     * get user stats for selected category
     * @param categoryCode 
     */
    getUserStats(categoryCode: number) {
        let req: IReqGetUserStats = {};
        if (categoryCode != null) {
            req.categoryCode = categoryCode;
        }
        return this.generic.genericGetStandard("/stats/get-stats", req);
    }


    /**
     * get user stats for selected category
     * @param categoryCode 
     */
    getUserStatsOverview(refreshCache: boolean) {
        let req: IReqGetStatsOverview = {
            disp: true,
            refreshCache: refreshCache
        };
        return this.generic.genericGetStandard("/stats/get-stats-overview", req);
    }


    /**
     * get user coins
     * use caching from the inventory provider
     */
    getAllCoins(reload: boolean) {
        let promise = new Promise((resolve, reject) => {
            // if (this.localCache.coins.inSync && this.localCache.coins.cache != null && !reload) {
            //     resolve(this.localCache.coins.cache);
            //     return;
            // }
            if (!reload && this.inventory.getSyncFlag(EInventorySync.coins)) {
                let cache = this.inventory.getSyncCache(EInventorySync.coins);
                resolve(cache);
                return;
            }

            this.generic.genericGetStandard("/stats/get-all-coins", null).then((resp: IGenericResponse) => {
                if (resp.data) {
                    this.inventory.setSyncCache(EInventorySync.coins, resp.data.coins);
                    resolve(resp.data.coins);
                } else {
                    resolve(0);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    /**
     * get user coins
     * use caching from the inventory provider
     */
    getAllCoinsForAnotherUser(leaderId: number) {
        let promise = new Promise((resolve, reject) => {
            this.generic.genericGetStandard("/stats/get-all-coins-leaderboard", {
                leaderId: leaderId
            }).then((resp: IGenericResponse) => {
                if (resp.data) {
                    resolve(resp.data.coins);
                } else {
                    resolve(0);
                }
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }

    registerWorldMapStatNoAction(statCode: number, amount: number) {
        this.registerWorldMapStat(statCode, amount).then(() => {
            console.log("world map stats registered");
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    /**
     * includes links
     * @param statCode 
     * @param amount 
     * @returns 
     */
    registerWorldMapStat(statCode: number, amount: number) {
        let links: IEventStoryGroupLinkData = this.links.getLinkData();
        return this.generic.genericPostStandard("/stats/register-world-map-stat", {
            statCode: statCode,
            amount: amount,
            links: links
        });
    }

    /**
     * includes links
     * @param storyId 
     * @returns 
     */
    registerStoryStarted(storyId: number) {
        // an achievement might be unlocked
        this.inventory.resetSyncFlag(EInventorySync.items);
        let links: IEventStoryGroupLinkData = this.links.getLinkData();
        return this.generic.genericPostStandard("/stats/register-story-started", {
            storyId: storyId,
            links: links
        });
    }


    checkStoryFinished(storyId: number): Promise<IRegisterStoryFinished> {
        let promise: Promise<IRegisterStoryFinished> = new Promise((resolve, reject) => {
            this.generic.genericPostStandardWData("/stats/check-story-finished", {
                storyId: storyId
            }).then((response: IRegisterStoryFinished) => {
                resolve(response);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * register story finished with coins collected
     * set sync flag to false
     * i.e. the view coins request should do a real request to get the current coin amount
     * includes links
     * @param storyId 
     * @param coins 
     */
    registerStoryFinished(storyId: number, coins: number): Promise<IRegisterStoryFinished> {
        let promise: Promise<IRegisterStoryFinished> = new Promise((resolve, reject) => {
            let links: IEventStoryGroupLinkData = this.links.getLinkData();
            this.generic.genericPostStandardWData("/stats/register-story-finished", {
                storyId: storyId,
                coins: coins,
                links: links
            }).then((response: IRegisterStoryFinished) => {
                // an achievement might be unlocked
                this.inventory.resetSyncFlag(EInventorySync.items);
                // coins might be added
                this.inventory.resetSyncFlag(EInventorySync.coins);
                resolve(response);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * register coins collected e.g. for activity finished
     * set sync flag to false
     * i.e. the view coins request should do a real request to get the current coin amount
     * if the storyId is null, the coins will be redirected to world map category!
     * includes links
     * @param storyId 
     * @param coins 
     */
    registerLPCollected(storyId: number, coins: number) {
        let promise = new Promise((resolve, reject) => {
            if (coins == null || coins === 0) {
                resolve(null);
                return;
            }
            let links: IEventStoryGroupLinkData = this.links.getLinkData();
            this.generic.genericPostStandard("/stats/register-coins-collected", {
                storyId: storyId,
                coins: coins,
                links: links
            }).then((response: IGenericResponse) => {
                // an achievement might be unlocked
                this.inventory.resetSyncFlag(EInventorySync.items);
                // coins might be added
                this.inventory.resetSyncFlag(EInventorySync.coins);
                resolve(response);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }


    /**
     * format skill tree from user status response
     * @param stats 
     */
    getSkillsFromStatsOverview(stats: IUserStatsOverview) {
        // https://www.macmillandictionary.com/thesaurus-category/british/words-for-people-according-to-what-they-enjoy
        let nodebg = "#cecece";
        // let nodebg2 = "rgba(66, 134, 244, 1)";
        let nodeborder = "#cecece";
        // let nodeborder2 = "rgba(66, 134, 244, 1)";

        let skills: ISkillSet = {
            root: {
                id: null,
                label: stats.root.label,
                categoryCode: stats.root.categoryCode,
                level: stats.root.level,
                max: stats.root.maxLevel,
                xp: stats.root.score,
                xpLevelUp: stats.root.refLevelUp,
                color: {
                    border: nodeborder,
                    background: nodebg
                },
                shape: 'circle',
                plot: true
            },
            tree: []
        };

        // let keys = Object.keys(stats.categories);
        for (let i = 0; i < stats.categories.length; i++) {
            let category = stats.categories[i];
            let skill: ISkill = {
                id: i,
                label: category.label,
                categoryCode: category.categoryCode,
                level: category.level,
                max: stats.root.maxLevel,
                xp: category.score,
                xpLevelUp: category.refLevelUp,
                color: {
                    border: nodeborder,
                    background: nodebg
                },
                shape: 'circle',
                plot: category.dispFlag === 1
            };
            if (category.categoryCode >= 0) {
                skills.tree.push(skill);
            }
        }

        let skillSetResponse: ISkillSetResponse = {
            skillSet: skills,
            max: stats.root.maxLevel
        };
        let promise = new Promise((resolve) => {
            resolve(skillSetResponse);
        });
        return promise;
    }

    //  https://www.macmillandictionary.com/thesaurus-category/british/words-for-people-according-to-what-they-enjoy

    checkUserStatsChanged(): Promise<IStatChange[]> {
        return this.generic.genericPostStandardWData("/stats/check-stats-changed", {});
    }

    /**
     * update incremental distance
     * based on distance source (gps/drone)
     * includes links
     * @param source 
     * @param incrementalDistance 
     */
    updateGlobalDistanceCounter(source: number, incrementalDistance: number): Promise<any> {
        let promise: Promise<any> = new Promise((resolve, reject) => {
            let callback = () => {
                resolve(false);
            };
            switch (source) {
                case EVirtualLocationSource.gps:
                case EVirtualLocationSource.override:
                    callback = () => {
                        let links: IEventStoryGroupLinkData = this.links.getLinkData();
                        this.generic.genericPostStandardWData("/stats/update-distance-travelled-incremental", {
                            distance: incrementalDistance,
                            links: links
                        }).then((data) => {
                            resolve(data);
                        }).catch((err) => {
                            reject(err);
                        });
                    }
                    break;
                case EVirtualLocationSource.drone:
                    callback = () => {
                        this.registerWorldMapStat(EStatCodes.distanceFlown, incrementalDistance).then((data) => {
                            resolve(data);
                        }).catch((err) => {
                            reject(err);
                        });
                    }
                    break;
                default:
                    break;
            }
            callback();
        });
        return promise;
    }
}
