

import { Injectable } from "@angular/core";
import { IActivity } from "src/app/classes/def/core/activity";
import { ILeplaceObject } from "src/app/classes/def/core/objects";
import { EMarkerLayers } from "src/app/classes/def/map/marker-layers";
import { INavigationStats } from "src/app/classes/def/map/navigation";
import { INavParams } from "src/app/classes/def/nav-params/general";
import { IStatDef, IUserStatsChangedResult } from "src/app/classes/def/user/stats";
import { IItemsCollectedParams, ItemsCollectedViewComponent } from "src/app/modals/app/modals/items-collected/items-collected.component";
import { ItemScannerService } from "../app/modules/item-scanner";
import { RewardModalsService } from "../app/modules/minor/reward-modals";
import { AnalyticsService } from "../general/apis/analytics";
import { SoundManagerService } from "../general/apis/sound-manager";
import { SoundUtils } from "../general/apis/sound-utils";
import { MessageQueueHandlerService } from "../general/message-queue-handler";
import { UiExtensionService } from "../general/ui/ui-extension";
import { PromiseUtils } from "../utils/promise-utils";
import { EQueueMessageCode } from "src/app/classes/utils/queue";


@Injectable({
    providedIn: 'root'
})
export class MapGeneralUtilsService {

    constructor(
        public rewardModals: RewardModalsService,
        public itemScanner: ItemScannerService,
        public analytics: AnalyticsService,
        public messageQueueHandler: MessageQueueHandlerService,
        public soundManager: SoundManagerService,
        public uiext: UiExtensionService
    ) {
        console.log("map general utils service created");
    }

    checkUserStatsChanged(showPopup: boolean, reloadMap: boolean): Promise<IUserStatsChangedResult> {
        let promise: Promise<IUserStatsChangedResult> = new Promise((resolve, reject) => {
            this.rewardModals.checkUserStatsChanged(true, showPopup).then((res: IUserStatsChangedResult) => {
                console.log("stats changed results: ", res);
                if (res.reload && reloadMap) {
                    this.itemScanner.refreshWorldMapMarkerLayerWrapperResolve(EMarkerLayers.CRATES, [], null).then(() => {
                        this.itemScanner.clearCache();
                        PromiseUtils.wrapNoAction(this.itemScanner.treasureScan(), true);
                    });
                }
                resolve(res);
            }).catch((err: Error) => {
                console.error(err);
                this.analytics.dispatchError(err, "gmap");
                reject(err);
            });
        });
        return promise;
    }

    /**
    * show reward
    * check transition (if required)
    * resolve only
    * @param stat 
    * @param enableCheck 
    */
    showRewardResolve(stat: IStatDef, enableCheck: boolean, showPopup: boolean, reloadMap: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            console.log("show reward: ", stat);
            if (!stat) {
                resolve(false);
                return;
            }
            let totalXP: number = stat.weight ? stat.weight : 0;
            if ((stat.singleName != null) && totalXP) {
                if (stat.notify) {
                    let description: string = stat.description != null ? stat.description : stat.singleName;
                    await this.uiext.showRewardPopupQueue("+" + totalXP + " XP", description, null, false);
                } else {
                    this.messageQueueHandler.prepare(stat.singleName + " +" + totalXP + " XP", false, EQueueMessageCode.info);
                    this.soundManager.vibrateContext(true);
                    this.soundManager.ttsWrapper("You have earned " + totalXP + " X.P.", true, SoundUtils.soundBank.gainedXP.id);
                }
                console.log("check notify complete");
                if (enableCheck && stat.checkTransition) {
                    this.checkUserStatsChanged(showPopup, reloadMap).then(() => {
                        resolve(true);
                    }).catch(() => {
                        resolve(false);
                    });
                } else {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        });
        return promise;
    }

    /**
     * handle items collected
     */
    handleItemsCollected(stats: INavigationStats, activity: IActivity, message: string, autoDismiss: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve, reject) => {
            let params: IItemsCollectedParams = {
                activityParams: stats.collectedList != null ? stats.collectedList.map(item => item != null ? item.customParamReference : null) : null,
                description: message,
                title: "Navigation Complete",
                coinsCollected: stats.collectedCoins,
                coinsCollectedValue: stats.collectedValue,
                stats: stats,
                activity: activity,
                autoClose: autoDismiss
            };
            let navParams: INavParams = {
                params: params,
                view: {
                    fullScreen: true,
                    transparent: false,
                    large: true,
                    addToStack: true,
                    frame: true
                }
            };
            this.uiext.showCustomModal(null, ItemsCollectedViewComponent, navParams).then((_res: number) => {
                resolve(true);
            }).catch((err: Error) => {
                console.error(err);
                this.analytics.dispatchError(err, "popup-features");
                reject(err);
            });
        });
        return promise;
    }

    /**
    * show collected items and LP value
    * @param collectedCoins
    * @param collectedValue 
    * @param enableCheck 
    */
    showCollectedLPValueResolve(stats: INavigationStats, activity: IActivity, checkTransition: boolean, autoDismiss: boolean, showPopup: boolean, reloadMap: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            if (!stats.collectedCoins) {
                resolve(false);
                return;
            }
            console.log("show collected LP value: " + stats.collectedCoins + " (" + stats.collectedValue + ")");
            let message: string = "<p>Collected " + stats.collectedCoins + ((stats.collectedCoins === 1) ? " item" : " items") + ((stats.collectedValue > 0) ? " (" + stats.collectedValue + " LP)" : "") + "</p>";

            // if (stats.collectedValue > 0) {
            //     message += "<p>Gained " + stats.collectedValue + " LP</p>";
            // }

            if (stats.collectedList && stats.collectedList.length > 0) {
                message = "<p>Navigation complete!</p><p>You have found some items along the way.</p>";
                await this.handleItemsCollected(stats, activity, message, autoDismiss);
            }

            if (stats.collectedValue > 0) {
                this.messageQueueHandler.prepare("+" + stats.collectedValue + " LP", false, EQueueMessageCode.info);
                this.soundManager.vibrateContext(true);
                this.soundManager.ttsWrapper("You have collected " + stats.collectedValue + " L.P.", true, SoundUtils.soundBank.gainedXP.id);
            }

            // await this.uiext.showRewardPopupQueue("Nav checkout", message, null, false);
            console.log("check notify complete");
            if (checkTransition) {
                this.checkUserStatsChanged(showPopup, reloadMap).then(() => {
                    resolve(true);
                }).catch(() => {
                    resolve(false);
                });
            } else {
                resolve(false);
            }
        });
        return promise;
    }
}
