


import { Injectable } from '@angular/core';
import { ToastController, AlertController, ModalController, MenuController } from '@ionic/angular';
import { LoadingController } from '@ionic/angular';
import { IPopoverActions, IPopoverInputs } from '../../../classes/def/app/modal-interaction';
import { BackButtonService } from './back-button';
import { EAlertButtonCodes, EModalClass } from '../../../classes/def/app/ui';
import { INavParams, IViewSpecs } from '../../../classes/def/nav-params/general';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AlertOptions, ModalOptions, ToastOptions, LoadingOptions, OverlayEventDetail } from '@ionic/core';
import { IWidgetContainer, IAlertReturnData } from 'src/app/classes/utils/uiext';
import { RewardPopupViewComponent } from 'src/app/modals/generic/modals/reward-popup/reward-popup.component';
import { IOverlayParams, IOverlayUpdateParams, EOverlayUpdateMode, EModalScope } from 'src/app/classes/def/views/overlay';
import { SleepUtils } from '../../utils/sleep-utils';
import { GenericQueueService } from '../generic-queue';
import { EQueues } from 'src/app/classes/def/app/app';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { RewardPopupToastViewComponent } from 'src/app/modals/generic/modals/reward-popup-toast/reward-popup-toast.component';
import { GeneralUtils } from 'src/app/classes/utils/general';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { IGenericMessageQueueEvent } from 'src/app/classes/utils/queue';


@Injectable({
    providedIn: 'root'
})
export class UiExtensionService {
    toastStack: IWidgetContainer<HTMLIonToastElement>[] = [];
    alertStack: IWidgetContainer<HTMLIonAlertElement>[] = [];
    loading: HTMLIonLoadingElement = null;

    index = {
        toast: 0,
        alert: 0
    };

    focusObservable: BehaviorSubject<boolean>;
    extHandleObservable: BehaviorSubject<any>;
    timeoutLoading;
    isOverlay: boolean = false;
    isRewardPopupActive: boolean = false;
    useToast: boolean = false;

    constructor(
        public toastCtrl: ToastController,
        public alertCtrl: AlertController,
        public loadingCtrl: LoadingController,
        public modalCtrl: ModalController,
        public menuCtrl: MenuController,
        public backButton: BackButtonService,
        public genericQueue: GenericQueueService
    ) {
        console.log("ui extension service created");
        this.focusObservable = new BehaviorSubject(null);
        this.extHandleObservable = new BehaviorSubject(null);
    }

    enableSidemenu() {
        this.menuCtrl.enable(true).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    disableSidemenu() {
        this.menuCtrl.enable(false).then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    showAlertNoAction(title: string, message: string, block: boolean = false) {
        if (block) {
            this.showAlert(title, message, 1, ["Ok"]).then(() => {
                console.log("alert dismissed");
            }).catch((err: Error) => {
                console.error(err);
            });
        } else {
            this.showRewardPopupNoAction(title, message, null, false, null);
        }
    }

    getWidgetById(stack: IWidgetContainer<any>[], id: number) {
        return stack.find(e => e.id === id);
    }

    removeWidgetById(stack: IWidgetContainer<any>[], id: number) {
        let index: number = -1;
        for (let i = 0; i < stack.length; i++) {
            if (stack[i].id === id) {
                index = i;
                break;
            }
        }
        if (index > -1) {
            stack.splice(index, 1);
        }
    }

    removeLastWidget(stack: IWidgetContainer<any>[]) {
        if (stack.length > 0) {
            stack.pop();
        }
    }

    getFocusObservable() {
        return this.focusObservable;
    }

    showCustomAlert(title: string, message: string, actions: IPopoverActions, allowDismiss: boolean = true): Promise<number> {
        let promise: Promise<number> = new Promise((resolve, reject) => {
            this.showCustomAlertCore(title, message, null, actions, allowDismiss).then((res: IAlertReturnData) => {
                resolve(res ? res.code : null);
            }).catch((err: Error) => {
                console.error(err);
                reject(err);
            });
        });
        return promise;
    }

    showCustomAlertInput(title: string, message: string, inputs: IPopoverInputs, actions: IPopoverActions, allowDismiss: boolean = true): Promise<IAlertReturnData> {
        return this.showCustomAlertCore(title, message, inputs, actions, allowDismiss);
    }


    /**
     * show alert with custom number of buttons
     * by convention the first button is dismiss code and the last button is ok code
     * 
     * @param title 
     * @param message 
     * @param buttonCount 
     * @param buttonNames e.g. ["dismiss", "ok"]
     * @param allowDismiss 
     */
    showAlert(title: string, message: string, buttonCount: number, buttonNames: string[], allowDismiss: boolean = true): Promise<number> {
        let promise: Promise<number> = new Promise((resolve, reject) => {

            if (!buttonNames) {
                if (buttonCount === 1) {
                    buttonNames = ["Ok"];
                }
                if (buttonCount === 2) {
                    buttonNames = ["Dismiss", "Ok"];
                }
                if (buttonCount > 2) {
                    buttonNames = ["Dismiss"];
                    for (let i = 0; i < buttonCount - 2; i++) {
                        buttonNames.push("Aux");
                    }
                    buttonNames.push("Ok");
                }
            }

            let popoverActionsObject: IPopoverActions = {};

            // add buttons
            for (let i = 0; i < buttonCount; i++) {
                let buttonCode: number = i;
                // consider left button is dismiss and right button is ok, and in between aux
                // if there is only one button then it is ok button
                if (i === 0) {
                    buttonCode = EAlertButtonCodes.cancel;
                }
                if (i === buttonCount - 1) {
                    buttonCode = EAlertButtonCodes.ok;
                }

                popoverActionsObject[i.toString()] = {
                    name: buttonNames[i],
                    code: buttonCode,
                    enabled: true
                };
            }

            this.showCustomAlertCore(title, message, null, popoverActionsObject, allowDismiss).then((res: IAlertReturnData) => {
                resolve(res ? res.code : null);
            }).catch((err: Error) => {
                reject(err);
            });
        });
        return promise;
    }



    private showCustomAlertCore(title: string, message: string, inputSpecs: IPopoverInputs, actions: IPopoverActions, allowDismiss: boolean = true): Promise<IAlertReturnData> {
        let promise: Promise<IAlertReturnData> = new Promise((resolve, reject) => {
            setTimeout(() => {
                let selectedCode: number = null;
                let inputData: any;

                let buttons = [];
                let inputs = [];

                if (inputSpecs != null) {
                    let inpk = Object.keys(inputSpecs);
                    for (let i = 0; i < inpk.length; i++) {
                        if (inputSpecs[inpk[i]].enabled) {
                            inputs.push({
                                name: inputSpecs[inpk[i]].name,
                                value: inputSpecs[inpk[i]].value,
                                placeholder: inputSpecs[inpk[i]].placeholder,
                                type: inputSpecs[inpk[i]].type
                            });
                        }
                    }
                }

                if (actions != null) {
                    let actk = Object.keys(actions);
                    for (let i = 0; i < actk.length; i++) {
                        buttons.push({
                            text: actions[actk[i]].name,
                            handler: (data: any) => {
                                selectedCode = actions[actk[i]].code;
                                inputData = data;
                                console.log("button clicked");
                                if (actions[actk[i]].handler) {
                                    return actions[actk[i]].handler(data);
                                }
                            }
                        });
                    }
                }

                /**
                 *  header?: string;
                    subHeader?: string;
                    message?: string;
                    cssClass?: string | string[];
                    inputs?: AlertInput[];
                    buttons?: (AlertButton | string)[];
                    backdropDismiss?: boolean;
                    translucent?: boolean;
                    animated?: boolean;
                    mode?: Mode;
                    keyboardClose?: boolean;
                    id?: string;
                    enterAnimation?: AnimationBuilder;
                    leaveAnimation?: AnimationBuilder;
                 */

                let opts: AlertOptions = {
                    header: title,
                    // subHeader: message,
                    message: message,
                    cssClass: 'custom-alert text-selectable',
                    buttons: buttons,
                    inputs: inputs,
                    backdropDismiss: allowDismiss // this makes the alert non dismiss on click outside the box
                };

                // if alert is already shown and allow dismiss is false
                // the do not open another alert, so that there are no multiple alerts for e.g. back button exit app
                if (!allowDismiss && this.alertStack.length > 0) {
                    resolve(null);
                    return;
                }

                console.log("show alert: " + this.index.alert);

                this.index.alert += 1;

                this.alertCtrl.create(opts).then((alert: HTMLIonAlertElement) => {
                    this.focusObservable.next(true);
                    console.log("alert created");


                    this.alertStack.push({
                        id: this.index.alert,
                        element: alert
                    });

                    alert.present().then(() => {
                        if (allowDismiss) {
                            this.backButton.push(() => {
                                alert.dismiss().then(() => {

                                }).catch((err: Error) => { console.error(err); });
                            });
                        }
                        alert.onDidDismiss().then(() => {
                            console.log("alert dismissed");
                            this.focusObservable.next(false);
                            this.removeLastWidget(this.alertStack);

                            if (allowDismiss) {
                                this.backButton.pop();
                            }

                            let res: IAlertReturnData = {
                                code: selectedCode,
                                data: inputData
                            };

                            resolve(res);
                        }).catch((err: Error) => {
                            console.error(err);
                            reject(err);
                        });
                    }).catch((err: Error) => {
                        this.focusObservable.next(false);
                        console.error(err);
                        reject(err);
                    });
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            }, 1);
        });
        return promise;
    }

    showCustomModalGen(_event: Event, component: any, navParams: INavParams, type: number = EModalScope.modal): Promise<any> {
        // console.log(event);
        let modalOptions: ModalOptions = this.prepareModalOptionsGen(component, navParams.params);
        console.log("showing custom modal gen");
        return this.createModalBB(modalOptions, navParams ? navParams.view : null, null, null, false, type);
    }

    /**
     * show custom modal, any view
     * @param event 
     * @param navParams 
     */
    showCustomModal(_event: Event, component: any, navParams: INavParams, type: number = EModalScope.modal): Promise<any> {
        // console.log(event);
        let modalOptions: ModalOptions = this.prepareModalOptions(component, navParams, type);
        console.log("showing custom modal");
        return this.createModalBB(modalOptions, navParams ? navParams.view : null, null, null, false, type);
    }



    /**
     * show custom modal
     * return handle to created modal for e.g. registering modals
     * @param component 
     * @param navParams 
     * @param onCreated 
     * @param onDismissed 
     */
    showCustomModalHandler(component: any, navParams: INavParams, onCreated: (modal: HTMLIonModalElement) => any, onDismissed: () => any, isOverlay: boolean, type: number): Promise<any> {
        // console.log(large);
        let modalOptions: ModalOptions = this.prepareModalOptions(component, navParams, type);
        console.log("showing custom modal");
        return this.createModalBB(modalOptions, navParams ? navParams.view : null, onCreated, onDismissed, isOverlay, type);
    }


    private prepareModalOptionsGen(component: any, options: any): ModalOptions {
        let modalOptions: ModalOptions = {
            component: component,
            componentProps: options
        };
        return modalOptions;
    }

    private prepareModalOptions(component: any, navParams: INavParams, type: number): ModalOptions {
        // console.log(large);
        let modalOptions: ModalOptions = {
            component: component,
            componentProps: {
                np: navParams
            },
            showBackdrop: !navParams.view.fullScreen,
            // !navParams.view.fullScreen
            backdropDismiss: (navParams.view.backdropDismiss != null) ? navParams.view.backdropDismiss : false,
            id: navParams.view.id
            // enterAnimation: "leplace-modal-transition-enter",
            // leaveAnimation: "leplace-modal-transition-leave"
            // cssClass: fullScreen ? null : 'modal-center'
        };

        if (type != null) {
            modalOptions.id = "" + type;
        }

        let cssClass: string = "";
        let withClass: boolean = false;

        if (!navParams.view.fullScreen) {
            withClass = true;
            if (navParams.view.small) {
                cssClass += 'modal-center-small';
            } else {
                cssClass += navParams.view.large ? 'modal-center-large' : 'modal-center';
            }
        } else {
            withClass = true;
            // cssClass += ' modal-fullscreen';
            cssClass += ' web-content-modal';
        }

        if (navParams.view.transparent) {
            withClass = true;
            cssClass += ' modal-transparent';
        }

        if (withClass) {
            modalOptions.cssClass = cssClass;
        }

        // allows the component to check if it's being shown as modal
        // if modal - back button handled by uiext
        // if not modal - back button handled by the components
        navParams.view.isModal = true;
        navParams.view.webIOS = GeneralCache.isWeb;

        return modalOptions;
    }

    /**
     * create modal
     * handle back button on dismiss
     * @param opts 
     */
    createModalBB(opts: ModalOptions, vs: IViewSpecs, onCreated: (modal: HTMLIonModalElement) => any, onDismissed: () => any, isOverlay: boolean, type: number) {
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                this.modalCtrl.create(opts).then((modal: HTMLIonModalElement) => {
                    if (onCreated) {
                        onCreated(modal);
                    }
                    modal.present().then(() => {
                        this.focusObservable.next(true);

                        if (vs && vs.addToStack) {
                            this.backButton.push(() => {
                                console.log("received backButton dismiss request in uiext");
                                modal.dismiss().then(() => {

                                }).catch((err: Error) => {
                                    console.error(err);
                                });
                            });
                        }

                        let sub: Subscription;

                        if (isOverlay) {
                            // watch for ext dismiss request                         
                            sub = this.extHandleObservable.subscribe((params: IOverlayUpdateParams) => {
                                if (params != null && (params.mode === EOverlayUpdateMode.dismissModal) && (params.type === type)) {
                                    console.log("received dismissModal in uiext");
                                    if (sub != null) {
                                        sub.unsubscribe();
                                    }
                                    modal.dismiss().then(() => {

                                    }).catch((err: Error) => {
                                        console.error(err);
                                    });
                                }
                            }, (err) => {
                                console.error(err);
                            });
                        }

                        modal.onDidDismiss().then((data: OverlayEventDetail<any>) => {
                            console.log("modal dismissed, data: ", data);
                            this.focusObservable.next(false);

                            if (vs && vs.addToStack) {
                                this.backButton.pop();
                            }

                            if (sub != null) {
                                sub.unsubscribe();
                            }

                            if (onDismissed) {
                                onDismissed();
                            }

                            resolve(data ? data.data : null);
                        }).catch((err: Error) => {
                            reject(err);
                        });

                    }).catch((err: Error) => {
                        this.focusObservable.next(false);
                        console.error(err);
                        reject(err);
                    });
                }).catch((err: Error) => {
                    console.error(err);
                    reject(err);
                });
            }, 1);
        });
        return promise;
    }

    showToast(message: string, _withButton: boolean, duration: number = 3000) {
        // use modal / alert
        return this.showRewardPopupQueue("", message, null, false, duration, false);
    }

    showToastNoAction(message: string, withButton: boolean, duration: number = 3000) {

        if (!this.useToast) {
            // use modal / alert
            this.showRewardPopupNoAction("", message, null, false, duration, false);
            return;
        }

        let opts: ToastOptions = {
            message: message,
            duration: duration,
            position: 'bottom',
            buttons: withButton ? ["Ok"] : [],
            translucent: true,
            animated: true,
            cssClass: "toast-custom-class"
            // dismissOnPageChange: true
        };

        if (!withButton) {
            opts.cssClass = "toast-custom-class toast-center";
        }

        this.toastCtrl.create(opts).then((toast: HTMLIonToastElement) => {

            this.toastStack.push({
                id: this.index.toast,
                element: toast
            });

            console.log("show toast: " + this.index.toast);

            this.index.toast += 1;

            toast.onDidDismiss().then(() => {
                console.log('Dismissed toast');
                this.removeLastWidget(this.toastStack);
            }).catch((err: Error) => {
                console.error(err);
            });

            toast.present().then(() => {

            }).catch((err: Error) => {
                console.error(err);
            });
        }).catch((err: Error) => {
            console.error(err);
        });
    }

    showOverlay(heading: string, description: string, photoUrl: string, continuous: boolean, test: boolean, onCreated: () => any) {
        let promise = new Promise(async (resolve, reject) => {
            console.log("show overlay, existing: ", this.isOverlay);
            this.extHandleObservable.next(null);

            let navParams: INavParams = {
                view: {
                    fullScreen: false,
                    transparent: true,
                    large: false,
                    // addToStack: true,
                    addToStack: false,
                    frame: false,
                    id: EModalClass.overlay
                },
                params: null
            };

            let scope: number = EModalScope.loading;

            let params: IOverlayParams = {
                id: "" + scope,
                heading: heading,
                description: description,
                photoUrl: photoUrl,
                continuous: continuous,
                timeout: continuous ? (test ? null : 2000) : null,
                extHandleObservable: this.extHandleObservable,
                scope: scope
                // timeout: null
            };

            navParams.params = params;

            // wait to be sure the previous modal has been disposed (has internal delay 1)
            await SleepUtils.sleep(10);

            // update overlay content
            if (this.isOverlay) {
                let updateParams: IOverlayUpdateParams = {
                    mode: EOverlayUpdateMode.updateContent,
                    type: EModalScope.loading,
                    params: params
                };
                this.extHandleObservable.next(updateParams);
                // callback for resolve
                onCreated();
                return;
            }

            this.extHandleObservable.next(null);
            this.isOverlay = true;
            this.showCustomModalHandler(RewardPopupViewComponent, navParams, onCreated, null, true, EModalScope.loading).then(() => {
                this.isOverlay = false;
                resolve(true);
            }).catch((err: Error) => {
                this.isOverlay = false;
                reject(err);
            });
        });
        return promise;
    }

    /**
     * show loading
     * resolve when shown
     * @param text 
     */
    showLoadingV2(text: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {

            console.log("show loading: " + text);

            let onCreated = () => {
                console.log("show loading complete");
                SleepUtils.sleep(100).then(() => {
                    resolve(true);
                });
            };

            this.timeoutLoading = ResourceManager.clearTimeout(this.timeoutLoading);
            // warning: closes other modals if fired after overlay was already dismissed
            this.timeoutLoading = setTimeout(async () => {
                // workaround to prevent loading not triggering after (unknown) sequence of events: auto dismiss loading if not triggered in a long time
                if (this.isOverlay) {
                    resolve(false); // fallback
                    await this.dismissLoadingV2();
                    this.isOverlay = false;
                }
            }, 30 * 1000);

            this.showOverlay(null, text, null, true, false, onCreated).then(() => {
                this.timeoutLoading = ResourceManager.clearTimeout(this.timeoutLoading);
                resolve(true); // fallback
            }).catch((err: Error) => {
                console.error(err);
                this.timeoutLoading = ResourceManager.clearTimeout(this.timeoutLoading);
                resolve(false);
            });
        });
        return promise;
    }


    /**
    * show loading
    * resolve when shown
    * use queue for robustness (prevent multiple calls while modal is not yet shown)
    * @param text 
    */
    showLoadingV2Queue(text: string): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            this.genericQueue.enqueue(() => {
                return this.showLoadingV2(text);
            }, () => {
                resolve(true);
            }, null, EQueues.loadingModal, null, 5000);
        });
        return promise;
    }

    /**
     * dismiss with delay
     * @param skip 
     */
    dismissLoadingV2WithSkip(skip: boolean) {
        let promise = new Promise(async (resolve) => {
            if (skip) {
                resolve(true);
                return;
            }
            await SleepUtils.sleep(400);
            await this.dismissLoadingV2();
            resolve(true);
        });
        return promise;
    }


    /**
     * resolve only
     */
    dismissLoadingV2(): Promise<boolean> {
        let promise: Promise<boolean> = new Promise(async (resolve) => {
            await SleepUtils.sleep(100);
            console.log("dismiss loading v2");
            this.timeoutLoading = ResourceManager.clearTimeout(this.timeoutLoading);
            let params: IOverlayUpdateParams = {
                mode: EOverlayUpdateMode.dismissModal,
                type: EModalScope.loading,
                params: null
            };
            this.extHandleObservable.next(params);
            await SleepUtils.sleep(400);
            resolve(true);
        });
        return promise;
    }

    resetOverlay() {
        this.isOverlay = false;
    }

    /**
     * show loading modal
     * @param allowDismiss 
     */
    showLoadingV1(allowDismiss: boolean, text: string) {
        setTimeout(() => {
            if (!this.loading) {
                console.log("show loading");
                let opts: LoadingOptions = {
                    message: text ? text : 'Please wait...'
                };
                this.loadingCtrl.create(opts).then((loading: HTMLIonLoadingElement) => {
                    loading.present().then(() => {
                        this.loading = loading;
                        if (allowDismiss) {
                            this.backButton.push(() => {
                                this.dismissLoading();
                            });
                        }
                        this.loading.onDidDismiss().then(() => {
                            if (allowDismiss) {
                                this.backButton.pop();
                            }
                            this.loading = null;
                        }).catch((err: Error) => {
                            console.error(err);
                        });
                    }).catch((err: Error) => {
                        console.error(err);
                    });
                }).catch((err: Error) => {
                    console.error(err);
                });
            }
        }, 1);
    }

    /**
     * dismiss loading modal no action
     */
    dismissLoading() {
        this.dismissLoadingPromise().then(() => {

        }).catch((err: Error) => {
            console.error(err);
        });
    }

    dismissLoadingPromiseResolve() {
        let promise = new Promise((resolve) => {
            setTimeout(() => {
                this.dismissLoadingPromise().then(() => {
                    setTimeout(() => {
                        resolve(true);
                    }, 100);
                }).catch((err: Error) => {
                    console.error(err);
                    setTimeout(() => {
                        resolve(false);
                    }, 100);
                });
            }, 100);
        });
        return promise;
    }

    /**
     * dismiss loading modal 
     * returns promise
     */
    dismissLoadingPromise() {
        let promise = new Promise((resolve, reject) => {
            setTimeout(() => {
                console.log("dismiss loading");
                if (this.loading !== null) {
                    this.loading.dismiss().then(() => {
                        setTimeout(() => {
                            this.loading = null;
                            resolve(true);
                        }, 1);
                    }).catch((err: Error) => {
                        setTimeout(() => {
                            this.loading = null;
                            reject(err);
                        }, 1);
                    });
                } else {
                    setTimeout(() => {
                        resolve(true);
                    }, 1);
                }
            }, 1000);
        });
        return promise;
    }


    isAlert() {
        return this.alertStack.length > 0;
    }
    isToast() {
        return this.toastStack.length > 0;
    }
    isLoading() {
        return this.loading !== null;
    }

    dismissWidgetStack(stack: IWidgetContainer<any>[]) {
        let promise = new Promise((resolve, reject) => {
            let promiseDismiss = [];
            promiseDismiss.push(Promise.resolve(true));
            for (let i = stack.length - 1; i >= 0; i--) {
                console.log("dismiss widget: " + stack[i].id);
                promiseDismiss.push(stack[i].element.dismiss());
            }
            Promise.all(promiseDismiss).then(() => {
                stack = [];
                this.focusObservable.next(false);
                resolve(true);
            }).catch((err: Error) => {
                stack = [];
                this.focusObservable.next(false);
                reject(err);
            });
        });
        return promise;
    }

    dismissAlertStack() {
        console.log("dismiss alert");
        return this.dismissWidgetStack(this.alertStack);
    }

    dismissToastStack() {
        console.log("dismiss toast");
        return this.dismissWidgetStack(this.toastStack);
    }

    /**
     * dismiss all widgets
     */
    dismissAllWidgets() {
        console.log("dismiss all");
        this.dismissToastStack().then(() => {
            console.log("dismiss toast complete");
        }).catch((err: Error) => {
            console.error(err);
        });
        this.dismissAlertStack().then(() => {
            console.log("dismiss alert complete");
        }).catch((err: Error) => {
            console.error(err);
        });
    }


    showRewardPopupQueue(heading: string, description: string, photoUrl: string, test: boolean, customTimeout?: number, toast?: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {
            this.genericQueue.enqueue(() => {
                return this.showRewardPopup(heading, description, photoUrl, test, customTimeout, false, toast);
            }, (qEvent: IGenericMessageQueueEvent) => {
                resolve(qEvent != null ? qEvent.data : null);
            }, null, EQueues.rewardModal, null, 20000);
        });
        return promise;
    }

    /**
     * resolve only
     * @param heading 
     * @param description 
     * @param photoUrl 
     * @param test 
     * @param customTimeout 
     * @param blocking 
     */
    showRewardPopup(heading: string, description: string, photoUrl: string, test: boolean, customTimeout: number, blocking: boolean, toast: boolean): Promise<boolean> {
        let promise: Promise<boolean> = new Promise((resolve) => {

            // add queue instead of rejecting new modal
            if (this.isRewardPopupActive && blocking) {
                console.error("reward popup already registered");
                resolve(false);
                return;
            }

            let navParams: INavParams = {
                view: {
                    fullScreen: false,
                    transparent: true,
                    large: false,
                    small: toast,
                    addToStack: true,
                    frame: false
                },
                params: null
            };

            let scope: number = toast ? EModalScope.toast : EModalScope.loading;

            let params: IOverlayParams = {
                id: "" + scope,
                heading: heading,
                description: description,
                photoUrl: photoUrl,
                continuous: false,
                timeout: test ? null : (customTimeout ? customTimeout : 3000),
                scope: scope
                // timeout: null
            };

            navParams.params = params;

            this.isRewardPopupActive = true;
            this.showCustomModal(null, toast ? RewardPopupToastViewComponent : RewardPopupViewComponent, navParams).then((res: number) => {
                this.isRewardPopupActive = false;
                if (res === EAlertButtonCodes.ok) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            }).catch((err: Error) => {
                this.isRewardPopupActive = false;
                console.error(err);
                resolve(false);
            });
        });
        return promise;
    }

    showRewardPopupNoAction(heading: string, description: string, photoUrl: string, test: boolean, customTimeout?: number, toast?: boolean) {
        this.showRewardPopupQueue(heading, description, photoUrl, test, customTimeout, toast).then(() => {
        });
    }

}
