export interface ITutorial {
    id: number;
    code: number;
    tutorialCategoryCode: number;
    name: string;
    title: string;
    description: string;
    photoUrl: string;
    mode: number;
}

export interface ITutorialCollection {
    [key: string]: ITutorial
}

export enum ETutorialCategory {
    /** specific tutorial for activities */
    activitySpec = 1,
    /** walkthrough tutorial for activities */
    walkthroughSpec = 5,
    /** overview tutorial for activities */
    activityOverview = 7
}

export enum ETutorialMode {
    leftGauge = 1,
    rightGauge = 2
}

export enum ESliderTutorialMode {
    story = 1,
    worldMap = 2,
    ar = 3
}

export enum ETutorialEntries {
    // pages
    about = 0,
    signupTerms = 1,
    signupTutorial = 2,
    signupTermsTown = 30,
    signupTutorialTown = 31,
    accountTutorial = 3,
    storyTutorial = 4,
    gmapTutorial = 5,
    gmapTutorialStory = 53,
    inventoryTutorial = 6,
    AR = 8,
    placesTutorial = 9,
    chatTutorial = 10,
    placeAdsTutorial = 11,
    groupMemberTutorial = 12,
    groupAdminTutorial = 13,
    storylineTutorial = 14,
    storiesTutorial = 15,
    extras = 16,
    events = 17,
    eventHome = 18,
    eventJoin = 19,
    rating = 20,
    warning = 21,
    liveChat = 22,
    adConsent = 23,

    prominentDisclosure = 28,
    prominentDisclosureWeb = 45,
    prominentDisclosureWebDenied = 46,

    storyline = 41,
    worldMap = 42,

    // entries
    gmap = 5,
    droneOnlyMode = 601,
    teamsMode = 602,
    gameplayOverview = 603,
    treasureHunting = 604,

    ttsChallenge = 301,
    ttsMeetingPlace = 302,
    ttsStory = 303,
    ttsTreasureFound = 304,
    ttsTreasureUnlocked = 305,
    ttsInventory = 306,


    storylineTutorialVideo = 52,
    worldMapTutorialVideo = 54,

    skipLinear = 55,
    skipNonlinear = 56,

    prestart = 68,
    skipStart = 69,

    localTtsBackgroundMode = -100
}