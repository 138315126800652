<div class="margin-top-s flex-row" *ngIf="loaded">
  <div class="leplace-icon-div-m leplace-icon icon-color-primary margin-horizontal-s" *ngIf="data.modeIcon != null">
    <ion-icon [icon]="data.modeIcon"></ion-icon>
  </div>
  <div class="leplace-icon-div-m leplace-icon icon-color-primary margin-horizontal-s" *ngIf="teams">
    <icon-wrapper [icon]="appIcons.groups" [custom]="true" customClass="leplace-icon">
    </icon-wrapper>
  </div>
  <div class="leplace-icon-div-m leplace-icon icon-color-primary margin-horizontal-s" *ngIf="droneOnly">
    <icon-wrapper [icon]="appIcons.drone" [custom]="true" customClass="leplace-icon">
    </icon-wrapper>
  </div>
  <div class="leplace-icon-div-m leplace-icon icon-color-primary margin-horizontal-s" *ngIf="enableAR">
    <icon-wrapper [icon]="appIcons.arView" [custom]="true" customClass="leplace-icon">
    </icon-wrapper>
  </div>
  <div class="leplace-icon-div-m leplace-icon icon-color-primary margin-horizontal-s" *ngIf="audioGuide">
    <icon-wrapper [icon]="appIcons.audioGuide" [custom]="true" customClass="leplace-icon">
    </icon-wrapper>
  </div>
  <div class="leplace-icon-div-m leplace-icon icon-color-primary margin-horizontal-s" *ngIf="bikeMode">
    <icon-wrapper [icon]="appIcons.bike" [custom]="true" customClass="leplace-icon">
    </icon-wrapper>
  </div>
  <div class="margin-horizontal-s text-color-element" *ngIf="hasMinAge">
    <span class="info-text-mlg">{{minAgeDisp}}</span>
  </div>
</div>