<div class="flex-card item-card bg-container bg-container-fill item-border-radius" tappable
  [ngClass]="{'bg-no-filter-animate':!isLocked, 'bg-filter-2-animate':isLocked}"
  [photoBg]="{fade: false, fadeClass: 'bg-container-fade', url: data ? memberPhotoUrl: null}" (click)="showOptions()">
  <div class="content" fxLayout="column">
    <ion-grid class="full-w grid-center-absolute">
      <ion-row>
        <!-- <ion-col size="12">
          <span class="info-text-xs bold text-color-overlay-s">{{memberName}}</span>
        </ion-col> -->
        <ion-col size="12" class="text-div-container">
          <div class="info-text-xs bold text-color-overlay-s" [innerHtml]="memberName"></div>
        </ion-col>
      </ion-row>

      <ion-row>
        <!-- <ion-col size="4" class="text-left">
          <ion-button [disabled]="!enabledInSession" class="button-color-transparent text-color-primary">
            <icon-wrapper slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper>
          </ion-button>
        </ion-col>
        <ion-col size="4" class="text-center">

        </ion-col> 
        <ion-col size="4" class="text-right">
          <ion-button [disabled]="!connected" class="button-color-transparent text-color-primary">
            <icon-wrapper slot="icon-only" icon="wifi"></icon-wrapper>
          </ion-button>
        </ion-col> -->

        <ion-col size="12" class="padding-xs">
          <span class="info-text-xs bold text-color-overlay-s" *ngIf="connected">ONLINE</span>
          <!-- <span class="info-text-s bold text-color-primary" *ngIf="!connected">OFFLINE</span> -->
          <div class="padding-bottom-s progress-bar-alternate" *ngIf="connecting">
            <mat-progress-bar mode="indeterminate" class="progress-rounded" color="primary">
            </mat-progress-bar>
          </div>
        </ion-col>
      </ion-row>

      <ion-row *ngIf="isAdminCard">
        <ion-col size="12" class="text-center">
          <span class="info-text-xs bold text-color-overlay-s">HOST</span>
        </ion-col>
      </ion-row>

    </ion-grid>
  </div>
</div>