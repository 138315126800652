import { Component, OnDestroy, NgZone, Renderer2, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { AppConstants } from 'src/app/classes/app/constants';
import { IStoryCategory, ECategoryCodes, ECategoryModes } from 'src/app/classes/def/core/category';
import { IStoryListNavParams } from 'src/app/classes/def/nav-params/story';
import { Platform, ModalController } from '@ionic/angular';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { INavParams, IViewSpecs, ViewSpecs } from 'src/app/classes/def/nav-params/general';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { IModalEvent, EModalEmitter } from 'src/app/classes/def/app/modal-events';
import { ECategoryPhotos } from 'src/app/classes/def/app/icons';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { StoryListPage } from '../story-list/story-list.page';
import { StoryDataService } from 'src/app/services/data/story';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { BackButtonService } from 'src/app/services/general/ui/back-button';
import { AnalyticsService } from 'src/app/services/general/apis/analytics';
import { CanvasLoaderService } from 'src/app/services/utils/canvas-loader';
import { ERouteDef } from 'src/app/app-utils';
import { NavParamsService, INavParamsInfo } from 'src/app/services/app/nav-params';
import { ENavParamsResources } from 'src/app/classes/def/nav-params/resources';
import { ActivatedRoute, Router } from '@angular/router';
import { INavBarItem } from 'src/app/classes/def/views/nav';
import { IGenericSlideData } from 'src/app/classes/def/views/slides';
import { EModalEvents, Events } from 'src/app/services/utils/events';
import { WebviewUtilsService } from 'src/app/services/app/utils/webview-utils';

@Component({
  selector: 'app-story-categories',
  templateUrl: './story-categories.page.html',
  styleUrls: ['./story-categories.page.scss'],
  animations: [
    trigger('showState', [
      state('inactive', style({
        // transform: 'translateY(-100%)',
        opacity: 0
      })),
      state('active', style({
        // transform: 'translateY(0)',
        opacity: 1
      })),
      transition('inactive => active', animate(AppConstants.animationMode)),
      transition('active => inactive', animate(AppConstants.animationMode))
    ])
  ],
  encapsulation: ViewEncapsulation.None
})
export class StoryCategoriesPage implements OnInit, OnDestroy {
  title: string;
  categories: IStoryCategory[] = [];
  categoriesDisp: IStoryCategory[] = [];
  loaded: boolean = false;
  showState: string = "inactive";
  timeout = null;
  reload: boolean = true;
  theme: string = "theme-light theme-light-bg";
  params: IStoryListNavParams;
  isModal: boolean = false;
  animate: boolean = true;
  np: INavParams = null;
  vs: IViewSpecs;
  slidesData: IGenericSlideData[];

  selectedCategoryMode: number = ECategoryModes.global;
  categoryTabsGroupCount: number = 2;
  navBarItems: INavBarItem[] = [];
  multipleCategories: boolean = false;

  constructor(
    public dataProvider: StoryDataService,
    public settingsProvider: SettingsManagerService,
    public zone: NgZone,
    public plt: Platform,
    public webView: WebviewUtilsService,
    public uiext: UiExtensionService,
    public backButton: BackButtonService,
    public analytics: AnalyticsService,
    public canvasLoader: CanvasLoaderService,
    public modalCtrl: ModalController,
    public events: Events,
    public route: ActivatedRoute,
    public renderer: Renderer2,
    public el: ElementRef,
    public router: Router,
    public nps: NavParamsService
  ) {

  }

  getHeaderClass() {
    return ViewSpecs.getHeaderClass(this.vs, false);
  }

  ngOnInit() {
    // console.log("activated route: " + this.route.snapshot.paramMap.get('id'));
    console.log("activated route: ", this.route.snapshot.queryParams);
    this.title = "Storyline";
    this.analytics.trackView("category-list");
    this.nps.checkParamsLoaded().then(() => {
      let npInfo: INavParamsInfo = this.nps.getCombined(ENavParamsResources.storyCategories, null, this.np);
      console.log("nav params: ", npInfo.params);
      let npar = npInfo.params;
      let hasParams: boolean = npInfo.hasParams;
      this.settingsProvider.getSettingsLoaded(false).then((res) => {
        if (res) {
          this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
          // this.theme = Constants.theme["aubergine"].css;
        }
      }).catch((err: Error) => {
        console.error(err);
      });

      if (hasParams) {
        console.log(npar);
        let np: INavParams = npar;
        this.params = np.params;
        this.reload = this.params.reload;
        if (this.params.category) {
          this.selectedCategoryMode = this.params.category.mode;
        }
        this.vs = np.view;
        this.isModal = this.vs ? this.vs.isModal : false;
        if (!this.reload || this.isModal) {
          this.animate = false;
          setTimeout(() => {
            this.showState = 'active';
          }, 250);
        }
      }

      this.webView.ready().then(() => {
        this.backButton.replace(() => {
          this.back(true);
        });
      }).catch((err: Error) => {
        console.error(err);
      });

      this.loadData(this.reload).then(() => {
        this.loaded = true;
        if (this.animate) {
          setTimeout(() => {
            this.showState = 'active';
          }, 250);
        }
      }).catch((err: Error) => {
        this.loaded = true;
        this.analytics.dispatchError(err, "category-list");
        this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
      });

      this.events.subscribe(EModalEvents.dismiss, (data: IModalEvent) => {
        // // user and time are the same arguments passed in `events.publish(user, time)`
        // console.log('Welcome', user, 'at', time);
        console.log("dismiss event");
        if (data.emitter === EModalEmitter.storyDetailGoToMap) {
          this.modalDismiss(false);
        }
      });
    }).catch((err: Error) => {
      console.error(err);
    });

  }

  swipeEvent(_e) {
    // this.backButton.handleSwipeEvent(e);
  }


  ngOnDestroy() {
    console.log("destroy");
    this.clearWatch();
    this.events.unsubscribe(EModalEvents.dismiss);
  }

  back(animate: boolean) {
    // this.showState = "inactive";
    // this.timeout = setTimeout(() => {
    //   this.navCtrl.setRoot(HomePage).then(() => {
    //   }).catch((err: Error) => {
    //     console.error(err);
    //   });
    // }, Constants.ANIMATION_TIME);
    if (this.isModal) {
      // this.viewCtrl.dismiss(null, null, {
      //   animate: true
      // }).then(() => {
      // }).catch(() => {
      // });
      this.modalDismiss(animate);
    } else {
      this.router.navigate([ERouteDef.home], { replaceUrl: true }).then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }
  }

  modalDismiss(_animate: boolean) {
    setTimeout(() => {
      this.modalCtrl.dismiss().then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  doRefresh() {
    this.loadData(true).then(() => {
      this.loaded = true;
    }).catch((err: Error) => {
      this.loaded = true;
      this.analytics.dispatchError(err, "category-list");
      this.uiext.showAlertNoAction(Messages.msg.serverError.after.msg, ErrorMessage.parse(err, Messages.msg.serverError.after.sub));
    });
  }


  public loadData(reload: boolean) {
    let promise = new Promise((resolve, reject) => {
      this.loaded = false;
      this.categories = [];
      let promise: Promise<IStoryCategory[]>;
      if (reload) {
        promise = this.dataProvider.getStoryCategoriesFromServer(true);
      } else {
        promise = this.dataProvider.getCurrentCategoryList();
      }

      promise.then((response: IStoryCategory[]) => {
        // console.log(response);
        this.categories = response;
        console.log("categories: ", this.categories);
        this.addCategoryPhotos(this.categories);
        this.slidesData = this.categories.map(cat => {
          let sd: IGenericSlideData = {
            title: cat.name.toUpperCase(),
            desc: cat.description,
            img: cat.photoUrl,
            data: cat,
            enabled: true,
            footer: cat.modeLabel,
            start: false
          };
          return sd;
        });

        this.navBarItems = [];
        let modes: number[] = [];
        for (let cat of this.categories) {
          if (modes.indexOf(cat.mode) === -1) {
            let item: INavBarItem = {
              name: cat.modeLabel,
              value: cat.mode
            };
            this.navBarItems.push(item);
            modes.push(cat.mode);
          }
        }

        if (modes.length < 2) {
          this.multipleCategories = false;
          this.categoriesDisp = this.categories;
        } else {
          // this.multipleCategories = true;
          this.multipleCategories = false;
          this.selectCategoryFromMode(this.selectedCategoryMode);
        }

        this.loaded = true;
        resolve(true);
      }).catch((err: Error) => {
        reject(err);
      });
    });
    return promise;
  }

  selectCategoryFromNavItem(item: INavBarItem) {
    this.selectCategoryFromMode(item.value);
  }

  selectCategoryFromMode(mode: number) {
    this.categoriesDisp = this.categories.filter(cat => cat.mode === mode);
  }

  addCategoryPhotos(categories: IStoryCategory[]) {
    let catkeys = Object.keys(ECategoryCodes);
    categories.forEach((category) => {
      if (category.photo === 1 && category.photoUrl) {

      } else {
        for (let i = 0; i < catkeys.length; i++) {
          if (category.code === ECategoryCodes[catkeys[i]]) {
            category.photoUrl = ECategoryPhotos[catkeys[i]];
            break;
          }
        }
      }
    });
  }

  clearWatch() {
    ResourceManager.clearTimeout(this.timeout);
    this.timeout = null;
  }

  goToStoryList(category: IStoryCategory): void {
    let params: IStoryListNavParams = {
      category: category,
      categoryCode: category ? category.code : null,
      storyId: null,
      dynamic: null,
      reload: true,
      loadStory: this.params ? this.params.loadStory : true,
      selectedCityId: null,
      // localStories: category ? category.mode === ECategoryModes.local : false,
      localStories: true,
      includeGlobal: true
    };

    if (this.animate) {
      this.showState = "inactive";
    }

    this.timeout = setTimeout(() => {
      let promise: Promise<any>;
      if (this.isModal) {
        promise = this.uiext.showCustomModal(null, StoryListPage, {
          view: {
            fullScreen: true,
            transparent: false,
            large: true,
            addToStack: true,
            frame: true
          },
          params: params
        });
      } else {
        let navParams: INavParams = {
          view: null,
          params: params
        };

        this.nps.set(ENavParamsResources.storyList, navParams);
        promise = this.router.navigate([ERouteDef.storyList], { replaceUrl: true });
      }
      promise.then(() => {
      }).catch((err: Error) => {
        console.error(err);
        this.analytics.dispatchError(err, "category-list");
        this.uiext.showAlertNoAction(Messages.msg.storyCategoryNotAvailable.after.msg, Messages.msg.storyCategoryNotAvailable.after.sub);
      });
    }, this.animate ? AppConstants.animationTime : 0);
  }
}
