
import { Injectable } from "@angular/core";
import { MarkerUtils } from "../map/marker-utils";
// import { setDPI } from "../../classes/map/canvas-photo";


@Injectable({
    providedIn: 'root'
})
export class CanvasLoaderService {
    constructor(
    ) { 
        console.log("canvas loader service created");
    }

    getCanvasURL(photoUrl: string) {
        let promise = new Promise((resolve, reject) => {
            let canvas = document.createElement('canvas');
            let w = 500, h = 500;
            // canvas.width = w;
            // canvas.height = h;
            MarkerUtils.setDPIForPhoto(canvas, 72, false);
            let ctx = canvas.getContext('2d');
            let img = new Image();
            img.crossOrigin = "anonymous";
            // img.crossOrigin = "Anonymous";
            // img.crossOrigin = "Use-Credentials";
            // img.crossOrigin = "";
            // img.src = "assets/img/icon-flag.png";

            // iconUrl = iconUrl.substring(0, iconUrl.indexOf("="));
            // iconUrl += "=k";
            // img.src = photoUrl;
            // console.log("icon url 1: " + markerOpts.icon + "icon url 2 " + img.src);
            img.onload = () => {
                // First draw your image to the buffer
                // w = img.width;
                // h = img.height;
                // let ratio = w / h;

                // let canvas2 = document.createElement("canvas");
                // let octx = canvas2.getContext('2d');

                // canvas2.height = 50;
                // canvas2.width = canvas2.height * ratio;
                // octx.drawImage(img, 0, 0, canvas2.width, canvas2.height);

                // octx.drawImage(canvas2, 0, 0, canvas.width, canvas.height);

                ctx.drawImage(img, 0, 0);


                // imageData = this.hueShift(imageData, 100);

                // step 2: adjust saturation (chroma, intensity)
                ctx.globalCompositeOperation = "saturation";
                ctx.fillStyle = "hsl(0," + 80 + "%, 50%)";  // hue doesn't matter here
                ctx.fillRect(0, 0, w, h);

                // step 3: adjust hue, preserve luma and chroma
                ctx.globalCompositeOperation = "hue";
                ctx.fillStyle = "hsl(" + 202 + ",1%, 50%)";  // sat must be > 0, otherwise won't matter
                ctx.fillRect(0, 0, w, h);

                // step 4: in our case, we need to clip as we filled the entire area
                ctx.globalCompositeOperation = "destination-in";


                let image = ctx.getImageData(0, 0, w, h);
                // get the image data values 
                let imageData = image.data;

                // set every fourth value to 50
                for (let i = 3; i < imageData.length; i += 4) {
                    imageData[i] = 80;
                }

                // after the manipulation, reset the data
                // image.data = imageData;
                // and put the imagedata back to the canvas

                ctx.putImageData(image, 0, 0);

                let icon = canvas.toDataURL("image/png");
                console.log(icon.length);
                resolve(icon);
            };
            img.onerror = () => {
                reject(new Error("Image load error"));
            };

            img.src = photoUrl;
        });
        return promise;
    }



    // hueShift(data, shift) {
    //     let red, green, blue, alpha;
    //     for (var i = 0; i < data.length; i += 4) {
    //         red = data[i + 0];
    //         green = data[i + 1];
    //         blue = data[i + 2];
    //         alpha = data[i + 3];

    //         // skip transparent/semiTransparent pixels
    //         if (alpha < 230) { continue; }

    //         var hsl = this.rgbToHsl(red, green, blue);
    //         var hue = hsl.h * 360;

    //         // change redish pixels to the new color
    //         if (hue < 30 || hue > 300) {
    //             var newRgb = this.hslToRgb(hsl.h + shift, hsl.s, hsl.l);
    //             data[i + 0] = newRgb.r;
    //             data[i + 1] = newRgb.g;
    //             data[i + 2] = newRgb.b;
    //             data[i + 3] = 255;
    //         }
    //     }
    //     return data;
    // }



    // ////////////////////////
    // // Helper functions
    // //

    // rgbToHsl(r, g, b) {
    //     r /= 255, g /= 255, b /= 255;
    //     var max = Math.max(r, g, b), min = Math.min(r, g, b);
    //     var h, s, l = (max + min) / 2;
    //     if (max == min) {
    //         h = s = 0; // achromatic
    //     } else {
    //         var d = max - min;
    //         s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    //         switch (max) {
    //             case r: h = (g - b) / d + (g < b ? 6 : 0); break;
    //             case g: h = (b - r) / d + 2; break;
    //             case b: h = (r - g) / d + 4; break;
    //         }
    //         h /= 6;
    //     }
    //     return ({ h: h, s: s, l: l });
    // }

    // hslToRgb(h, s, l) {
    //     var r, g, b;
    //     if (s == 0) {
    //         r = g = b = l; // achromatic
    //     } else {
    //         let hue2rgb = function (p, q, t) {
    //             if (t < 0) t += 1;
    //             if (t > 1) t -= 1;
    //             if (t < 1 / 6) return p + (q - p) * 6 * t;
    //             if (t < 1 / 2) return q;
    //             if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    //             return p;
    //         }
    //         var q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    //         var p = 2 * l - q;
    //         r = hue2rgb(p, q, h + 1 / 3);
    //         g = hue2rgb(p, q, h);
    //         b = hue2rgb(p, q, h - 1 / 3);
    //     }
    //     return ({
    //         r: Math.round(r * 255),
    //         g: Math.round(g * 255),
    //         b: Math.round(b * 255),
    //     });
    // }

}



