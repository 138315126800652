

export interface ISoundBank {
    beginTransmission: ISoundEffect,
    coin: ISoundEffect,
    complete: ISoundEffect,
    energy: ISoundEffect,
    failed: ISoundEffect,
    hangup: ISoundEffect,
    levelUp: ISoundEffect,
    milestone: ISoundEffect,
    worldMapIntro: ISoundEffect,
    gainedXP: ISoundEffect
}

export interface ISoundEffect {
    id: string,
    path: string
}

export class SoundUtils {
    // make id the same as key!
    static soundBank: ISoundBank = {
        beginTransmission: {
            id: "beginTransmission",
            path: "assets/sounds/begin_transmission.mp3"
        },
        coin: {
            id: "coin",
            path: "assets/sounds/coin.mp3"
        },
        complete: {
            id: "complete",
            path: "assets/sounds/complete.mp3"
        },
        energy: {
            id: "energy",
            path: "assets/sounds/energy.mp3"
        },
        failed: {
            id: "failed",
            path: "assets/sounds/failed.mp3"
        },
        hangup: {
            id: "hangup",
            path: "assets/sounds/hangup.mp3"
        },
        levelUp: {
            id: "levelUp",
            path: "assets/sounds/level_up.mp3"
        },
        milestone: {
            id: "milestone",
            path: "assets/sounds/milestone.mp3"
        },
        worldMapIntro: {
            id: "worldMapIntro",
            path: "assets/sounds/world_map_intro.mp3"
        },
        gainedXP: {
            id: "gainedXP",
            path: "assets/sounds/xp.mp3"
        }
    }
}