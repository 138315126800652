<div>
  <ion-row class="filters">
    <ion-col class="col-with-arrow" (click)="slidePrev()" no-padding size="1">
      <icon-wrapper *ngIf="showLeftButton" icon="caret-back" iconIos="chevron-back-circle-outline"></icon-wrapper>
    </ion-col>
    <ion-col size="10" #sliderContainer>
      <!-- (swipe)="swipeEvent($event)" -->
      <!-- #sliderRef -->
      <ion-slides *ngIf="configInit" (ionSlideDidChange)="slideChanged()" [options]="sliderOptions">
        <ion-slide (click)="filterData(item)" *ngFor="let item of navBarItems">
          <ion-button *ngIf="!isSelected(item)" class="button-color-primary scrollitem"
            [ngClass]="slim ? 'action-button-tabs-slim' : 'action-button-tabs'" (click)="selectCategoryOnClick(item)">
            <span class="button-font-size-xs uppercase bold">{{item.name}}</span>
          </ion-button>
          <ion-button *ngIf="isSelected(item)" class="button-color-alternate scrollitem"
            [ngClass]="slim ? 'action-button-tabs-slim' : 'action-button-tabs'" (click)="selectCategoryOnClick(item)">
            <span class="button-font-size-xs uppercase bold">{{item.name}}</span>
            <!-- <span class="uppercase bold">{{item.name}}</span> -->
          </ion-button>
        </ion-slide>
      </ion-slides>
    </ion-col>
    <ion-col class="col-with-arrow" (click)="slideNext()" no-padding size="1">
      <icon-wrapper *ngIf="showRightButton" icon="caret-forward"
        iconIos="chevron-forward-circle-outline"></icon-wrapper>
    </ion-col>
  </ion-row>
</div>