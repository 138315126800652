import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable } from "rxjs";
import { AuthRequestService } from "./auth-request/auth-request";
import { ERouteDef } from "src/app/app-utils";

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(private authRequest: AuthRequestService, private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return new Promise<boolean>((resolve) => {
            this.authRequest.checkAuthentication().subscribe((isLoggedIn: boolean) => {
                console.log("login event: ", isLoggedIn);
                if (isLoggedIn !== null) {
                    if (isLoggedIn) {
                        resolve(true); // User is logged in, allow access to the route
                    } else {
                        // User is not logged in, redirect to the login page
                        this.router.navigate([ERouteDef.login]);
                        resolve(false);
                    }
                }
            }, (err: Error) => {
                console.error(err);
            });
        });
    }
}