<modal-header-container [vs]="vs">
  <leplace-toolbar [noBackButton]="true" [rightButton]="appIconsStandard.close" [rightIcon]="true" [title]="title"
    (rightClick)="continue()" [loading]="adContext.loading" [rounded]="vs.fullScreen"></leplace-toolbar>
</modal-header-container>

<modal-container [vs]="vs"
  [ngClass]="!vs.fullScreen ? (!vs.large?'modal-small-height':'modal-large-height'):vs.webIOS ? 'full-height-web-ios' : 'full-height'">

  <div class="bg-container bg-container-fill bg-filter-2"
    [photoBg]="{fade: true, fadeClass: 'bg-container-fade', url: photoUrl}">

    <div class="content">
      <div class="content-height">
        <div fxLayout="column" class="content-height">

          <div class="scrollbar-y scroll-content-modal-1 padding-top-s scroll-into-view-container" fxLayout="column">

            <safe-html-renderer [unsafeHtmlContent]="adContext.infoHTML"
              innerClass="info-text-m description-frame text-color-primary text-center"></safe-html-renderer>

            <audio-guide *ngIf="descriptionPlain && withAudioGuide" label="" [contentHTML]="descriptionPlain"
              [autoplay]="audioGuideAutoplay"></audio-guide>

            <div *ngIf="customDescription" [innerHTML]="customDescription"
              class="info-text-m description-frame text-color-primary text-left padding-top-s"></div>

            <!-- <div *ngIf="photoUrl" class="text-center">
                <responsive-img [photoUrl]="photoUrl" class="border-radius"></responsive-img>
            </div> -->

            <div *ngIf="activityStats">
              <ion-row>
                <ion-col>
                  <div class="margin-top-s">
                    <span class="info-text-m bold text-color-primary center-text">Game stats</span>
                    <kv-table [data]="activityStats" [format]="true"></kv-table>
                  </div>
                </ion-col>
              </ion-row>
            </div>

            <div class="text-center" class="padding-top-s" *ngIf="ratingEnabled">
              <span class="text-center info-text-m bold text-color-primary">Rate this story</span>
            </div>

            <div class="text-center" *ngIf="ratingEnabled" [ngClass]="inputFocused ? 'scroll-padding' : ''">
              <!-- <rating class="rating-input" [(ngModel)]="rating" [max]="5" [float]="false" [readonly]="false">
              </rating> -->
              <div class="center-flex padding-s">
                <ngx-stars [readonly]="false" [size]="2" [color]="'#fcd182'" [initialStars]="rating" [maxStars]="5"
                  [wholeStars]="true" (ratingOutput)="onRatingSet($event)">
                </ngx-stars>
              </div>
              <div class="center-flex padding-s" *ngIf="isReviewEnabled()">
                <edit-textarea class="full-w" [mainContainer]="review" [editContainer]="reviewCopy" key="description"
                  [maxLength]="maxLength" placeholder="Write a review" [popupInput]="true"
                  (update)="reviewChange($event)" (focus)="textAreaFocused($event)">
                  <inner-content>Edit review</inner-content>
                </edit-textarea>
              </div>
            </div>

            <!-- <div *ngIf="inputFocused" class="padding-keyboard"></div> -->

            <div *ngIf="adContext.shareMessage && shareEnabled && shareEnabledGlobal" fxLayout="column">
              <span class="info-text-m bold text-color-primary center-text">Share progress</span>
              <span class="info-text-m text-color-primary center-text">{{adContext.shareMessage}}</span>
            </div>
          </div>

          <div fxFlex></div>
          <div [ngClass]="!vs.fullScreen ? 'stick-bottom' : 'stick-bottom-fullscreen'">
            <ion-grid class="full-w">
              <ion-row>
                <!-- <ion-col size="4">
                  <ion-button class="action-button-fill button-color-primary-70"
                    [disabled]="!shareEnabled || adContext.loading" (click)="shareAndContinue()">
                    <icon-wrapper slot="icon-only" icon="share"></icon-wrapper>
                  </ion-button>
                </ion-col>
                <ion-col size="4">
                  <cooldown-button [active]="adContext.watchEnabled && !adContext.loading"
                    [triggerCooldown]="adContext.triggerCooldown" [cooldown]="10" [icon]="appIcons.videoAd"
                    [customIcon]="true" [name]="" theme="alternate" (select)="playAndContinue()">
                  </cooldown-button>
                </ion-col> -->
                <ion-col size="12">
                  <ion-button class="action-button-fill button-color-accent" (click)="continue()">
                    <!-- <span>Return</span> -->
                    <!-- <icon-wrapper slot="icon-only" [icon]="appIcons.check" [custom]="true"></icon-wrapper> -->
                    <icon-wrapper class="slot-icon-padding" slot="start" [icon]="appIcons.check"
                      [custom]="true"></icon-wrapper>
                    <span class="button-font-size-s">Continue</span>
                  </ion-button>
                </ion-col>

              </ion-row>
            </ion-grid>
          </div>
        </div>
      </div>
    </div>
  </div>