import { Component, OnInit, Input, Output, EventEmitter, OnDestroy, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';

@Component({
    selector: 'leplace-toolbar',
    templateUrl: './leplace-toolbar.component.html',
    styleUrls: ['./leplace-toolbar.component.scss'],
})

export class LeplaceToolbarComponent implements OnInit, OnDestroy, OnChanges {

    @Input()
    leftButton: string;

    @Input()
    rightButton: string;

    @Input()
    leftButtonDisabled: boolean;

    @Input()
    rightButtonDisabled: boolean;

    @Input()
    leftIcon: boolean;

    @Input()
    rightIcon: boolean;

    @Input()
    title: string;

    @Input()
    loading: boolean;

    @Input()
    rounded: boolean;

    @Input()
    transparent: boolean;

    @Input()
    menu: boolean;

    @Input()
    noBackButton: boolean;

    @Output()
    leftClick: EventEmitter<any> = new EventEmitter();

    @Output()
    rightClick: EventEmitter<any> = new EventEmitter();

    @Input()
    customRightIcon: boolean;

    @Input()
    customLeftIcon: boolean;

    titleSmall: boolean = false;
    trimTitle: string = "";
    show: boolean = true;

    constructor() {

    }

    leftClicked() {
        this.leftClick.emit();
    }

    rightClicked() {
        this.rightClick.emit();
    }

    ngOnInit() {
        if (this.rounded == null) {
            this.rounded = true;
        }
        this.updateTitle();

    }

    updateTitle() {
        if (this.title) {
            let titleLength: number = this.title.length;
            let level1: number = 20;
            let level2: number = 32;
            if (titleLength < level1) {
                this.trimTitle = this.title;
                this.titleSmall = false;
            } else {
                // this.trimTitle = GeneralUtils.trimName(this.title, level2);
                this.trimTitle = this.title;
                this.titleSmall = true;
            }
        }

    }

    ngOnChanges(changes: SimpleChanges) {
        // console.log(changes);
        let keys: string[] = Object.keys(changes);
        for (let i = 0; i < keys.length; i++) {
            const chg: SimpleChange = changes[keys[i]];
            if (!chg.isFirstChange() || chg.currentValue === true) {
                // skip the first change only if the current value is not already set to true
                // this may happen if the button is just shown and it is set to blink
                switch (keys[i]) {
                    case 'title':
                        this.updateTitle();
                        break;
                    case 'rightButton':
                        this.refresh();
                        break;
                }
            }
        }
    }

    refresh() {
        this.show = false;
        setTimeout(() => {
            this.show = true;
        }, 1);
    }

    ngOnDestroy() {

    }
}
