import { Component, OnDestroy, ViewChild, ElementRef, OnInit, ViewEncapsulation } from '@angular/core';
import { IonContent, ModalController } from '@ionic/angular';
import { IChatElement } from 'src/app/classes/def/mp/chat';
import { IGroupChatMember, IGroup, IGroupMember } from 'src/app/classes/def/mp/groups';
import { BehaviorSubject } from 'rxjs';
import { IMPMessageDB } from 'src/app/classes/def/mp/message';
import { ISubMultiplex } from 'src/app/classes/def/mp/subs';
import { AppSettings } from 'src/app/services/utils/app-settings';
import { IViewSpecs, ViewSpecs, INavParams } from 'src/app/classes/def/nav-params/general';
import { IPlatformFlags } from 'src/app/classes/def/app/platform';
import { UiExtensionService } from 'src/app/services/general/ui/ui-extension';
import { SettingsManagerService } from 'src/app/services/general/settings-manager';
import { KeyboardProvider, IKeyboardEvent } from 'src/app/services/apis/keyboard';
import { MPDataService } from 'src/app/services/data/multiplayer';
import { TutorialsService } from 'src/app/services/app/modules/minor/tutorials';
import { ParamHandler } from 'src/app/classes/general/params';
import { ThemeColors } from 'src/app/classes/def/app/theme';
import { IChatModalNavParams } from 'src/app/classes/def/nav-params/arena';
import { EMPMessageCodes } from 'src/app/classes/def/mp/protocol';
import { ResourceManager } from 'src/app/classes/general/resource-manager';
import { MPUtils } from 'src/app/services/app/mp/mp-utils';
import { IPopoverActions } from 'src/app/classes/def/app/modal-interaction';
import { AppConstants } from 'src/app/classes/app/constants';
import { Messages } from 'src/app/classes/def/app/messages';
import { ErrorMessage } from 'src/app/classes/general/error-message';
import { EModalTypes } from 'src/app/classes/utils/uiext';
import { UiExtensionStandardService } from 'src/app/services/general/ui/ui-extension-standard';
import { ScrollUtils } from 'src/app/services/general/ui/scroll-utils';
import { IMPMeetingPlace } from 'src/app/classes/def/mp/meeting-place';
import { EMPContext } from 'src/app/classes/def/mp/generic';
import { EMessageTrim } from 'src/app/classes/utils/message-utils';
import { TextToSpeechService } from 'src/app/services/general/apis/tts';
import { EAppIconsStandard } from 'src/app/classes/def/app/icons';
import { GeneralCache } from 'src/app/classes/app/general-cache';
import { ETutorialEntries } from 'src/app/classes/def/app/tutorials';
import { IMPGenericGroupStat } from 'src/app/classes/def/mp/game';
import { IMPStatusDB } from 'src/app/classes/def/mp/status';
import { IMQTTChatMessage, TelemetryDef } from 'src/app/services/telemetry/def';
import { DeepCopy } from 'src/app/classes/general/deep-copy';
import { ETimeoutQueue, TimeoutQueueService } from 'src/app/services/general/timeout-queue';

export interface IInfoMessage {
  message: string,
  showTimeout: any
}

export interface IInfoMessageContainer {
  [key: string]: IInfoMessage,
  connected: IInfoMessage,
  typing: IInfoMessage
}

// https://pusher.com/tutorials/chat-app-ionic-sentiment
@Component({
  selector: 'modal-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChatViewComponent implements OnInit, OnDestroy {
  @ViewChild(IonContent, { read: ElementRef, static: false }) content: IonContent;
  @ViewChild('chat_content', { read: ElementRef, static: false }) chatContent: ElementRef;
  @ViewChild('chat_input', { read: ElementRef, static: false }) messageInput: ElementRef;
  @ViewChild('chat_footer', { read: ElementRef, static: false }) chatFooter: ElementRef;

  theme: string = "theme-aubergine theme-aubergine-bg";
  // theme: string = "theme-aubergine theme-aubergine-bg";
  title: string = "LP Chat";
  chatRx: IChatElement[] = [];
  chatTx: string = "";
  groupMembersStatus: IGroupChatMember[] = [];
  sendObservable: BehaviorSubject<IChatElement>;
  chatRxObservable: BehaviorSubject<IMPMessageDB>;
  chatRxMqttObservable: BehaviorSubject<IMQTTChatMessage>;
  chatRxSyncObservable: BehaviorSubject<IMPMessageDB[]>
  groupStatusObservable: BehaviorSubject<IMPGenericGroupStat>;
  writeMessage: string = "Write a message";
  timeout = {
    scroll: null,
    position: null,
    typingSend: null,
    typingRx: null,
    scrollTrigger: null
  };
  subscription: ISubMultiplex = {
    keyboard: null,
    chatRx: null,
    groupStatus: null
  };
  showEmojiPicker: boolean = false;
  // theme-aubergine-bg
  class: string = "chat-footer";
  footerHeightInit: number = 0;
  footerOffsetKb: number = 50;
  footerHeight: number = 0;
  footerHeightPx: string = "0px";
  messagePadPx: string = "5px";

  infoMessage: IInfoMessageContainer = {
    connected: {
      message: "",
      showTimeout: null
    },
    typing: {
      message: "",
      showTimeout: null
    }
  };

  typingUser: number = 0;
  otherIsTyping: boolean = false;
  infoMessageTimeout: number = 3000;
  groupId: number = 0;
  groupName: string = "";
  initScrollHeight: number = 0;
  isScrolling: boolean = false;
  enableAutoScroll: boolean = true;
  simulateKbOpen: boolean = false;
  devMode: boolean = AppSettings.testerMode;
  vs: IViewSpecs = ViewSpecs.getDefault();
  platform: IPlatformFlags = {} as IPlatformFlags;
  np: INavParams = null;
  meetingPlaceId: number = 0;
  meetingPlaceName: string = "";
  context: number = EMPContext.GROUP;
  maxLength: number = EMessageTrim.userInput;

  nearbyPlayers: IMPStatusDB[] = [];
  nearbyPlayersSlides = [];
  statusUpdate: boolean = false;

  inputClass: string = "input-area-margin";

  showChatHeads: boolean = false;

  constructor(
    public modalCtrl: ModalController,
    public uiext: UiExtensionService,
    public uiextStandard: UiExtensionStandardService,
    public settingsProvider: SettingsManagerService,
    public keyboard: KeyboardProvider,
    public mp: MPDataService,
    public tutorials: TutorialsService,
    public tts: TextToSpeechService,
    public timeoutQueueService: TimeoutQueueService
  ) {

  }


  subscribeToGroupStatus() {
    if (this.groupStatusObservable) {
      if (!this.subscription.groupStatus) {
        // let prescaler: number = 5;
        // let crt: number = prescaler;
        this.subscription.groupStatus = this.groupStatusObservable.subscribe((data: IMPGenericGroupStat) => {
          // console.log("group status update: ", data);
          if (data && data.group) {
            this.checkConnectionStatus(data.group);
            this.nearbyPlayers = data.group.members.map(m => MPUtils.getMPStatusFromGroupMember(m, data.group.id)).filter(e => e != null);
            // console.log("group data: ", data.group.members);
            // console.log("nearby players: ", this.nearbyPlayers);
            this.statusUpdate = !this.statusUpdate;
            this.getSlides();
          }
          if (data && data.meetingPlace) {
            this.checkConnectionStatus(data.meetingPlace);
            this.nearbyPlayers = data.meetingPlace.players.filter(e => e != null);
            // console.log("nearby players: ", this.nearbyPlayers);
            this.statusUpdate = !this.statusUpdate;
            this.getSlides();
          }
        }, (err: Error) => {
          console.error(err);
        });
      }
    }
  }

  getSlides() {
    let nearbyPlayersSlides = this.nearbyPlayers.map(np => {
      return {
        player: np,
        update: this.statusUpdate,
        mini: true,
        self: false,
        _self: this.isSelf(np)
      };
    });
    nearbyPlayersSlides.sort((a, b) => {
      if (a._self) {
        return 1;
      }
      if (b._self) {
        return -1;
      }
      return 0;
    });
    this.nearbyPlayersSlides = nearbyPlayersSlides;
    // console.log("get chathead slides: ", this.nearbyPlayersSlides);
  }

  trackByFn(_index: number, item: IMPStatusDB) {
    return item.playerId;
  }

  isSelf(p: IMPStatusDB) {
    return p.playerId === GeneralCache.userId;
  }

  selectNearbyPlayer(p) {
    console.log(p);
  }

  selectMember(member: IGroupChatMember) {
    // console.log(member);
    this.uiext.showAlertNoAction(member.name, member.connected ? "available" : "away");
  }

  checkConnectionStatus(container: IGroup | IMPMeetingPlace) {
    switch (this.context) {
      case EMPContext.GROUP:
        this.checkConnectionStatusGroupMode(container as IGroup);
        break;
      case EMPContext.MEETING_PLACE:

        break;
    }
  }

  /**
   * check for connect/disconnect events in the group status data
   * @param group 
   */
  checkConnectionStatusGroupMode(group: IGroup) {
    if (!(group && group.members && group.members.length > 0)) {
      return false;
    }

    let members: IGroupChatMember[] = group.members.map((member: IGroupMember) => {
      let gcm: IGroupChatMember = {
        id: member.userId,
        name: member.user ? member.user.name : "player",
        connected: member.dynamic ? member.dynamic.connected : false
      };
      return gcm;
    });

    // check for existing and new members
    // mostly existing because static members are seldom added
    for (let i = 0; i < members.length; i++) {
      let m1: IGroupChatMember = members[i];
      let existing: boolean = false;
      for (let j = 0; j < this.groupMembersStatus.length; j++) {
        let m2: IGroupChatMember = this.groupMembersStatus[j];
        if (m1.id === m2.id) {
          let s1: boolean = m1.connected;
          let s2: boolean = m2.connected;
          // check state transition
          // console.log("check member: ", m1.id, s1, s2);
          if (!s1 && s2) {
            // was not connected but now is connected via status
            this.showUserStatusUpdate(m1.name, false);
          }
          if (s1 && !s2) {
            // was connected but now has disconnected via status
            this.showUserStatusUpdate(m1.name, true);
          }
          // only now update the status flags
          m2.connected = m1.connected;
          existing = true;
          break;
        }
      }
      if (!existing) {
        // console.log("new member: ", m1);
        if (m1.connected) {
          // has just conected
          this.showUserStatusUpdate(m1.name, true);
        }
        // WARNING! Be careful with pass by reference, because without deep copy, 
        // the current members will be in sync with the new members so no state transition would be detected
        this.groupMembersStatus.push(DeepCopy.deepcopy(m1));
        // this.groupMembersStatus.push(Object.assign(m1));
      }
    }

    let removedMembersIndex: number[] = [];
    // check for removed members
    // this is uncommon
    for (let i = 0; i < this.groupMembersStatus.length; i++) {
      let m1: IGroupChatMember = this.groupMembersStatus[i];
      let removed: boolean = true;
      for (let j = 0; j < members.length; j++) {
        let m2: IGroupChatMember = members[j];
        if (m1.id === m2.id) {
          removed = false;
          break;
        }
      }
      if (removed) {
        // console.log("removed member: ", m1);
        this.showUserStatusUpdate(m1.name, false);
        removedMembersIndex.push(i);
      }
    }

    for (let i = 0; i < removedMembersIndex.length; i++) {
      this.groupMembersStatus.splice(removedMembersIndex[i], 1);
    }

    // console.log(this.groupMembersStatus);
  }

  /**
   * show when a user connects or disconnects
   * @param username 
   * @param connected 
   */
  showUserStatusUpdate(username: string, connected: boolean) {
    this.updateInfoMessage(this.infoMessage.connected, username + (connected ? " joined the room" : " left the room"));
  }


  checkPivot(element: IChatElement, pivot: IChatElement) {
    let diff: number = 0;
    if (element.timestampValue && pivot.timestampValue) {
      diff = element.timestampValue - pivot.timestampValue;
      // console.log("diff: ", diff);
    }
    return diff > 10000;
  }

  /**
  * assign next message if it's from the same user
  * @param elements 
  * @param next 
  */
  processNext(elements: IChatElement[], next: IChatElement) {
    if (!(elements && elements.length > 0)) {
      next.nextUser = true;
      return next;
    }

    if (next.user !== elements[elements.length - 1].user) {
      next.nextUser = true;
    } else {
      next.nextUser = false;
    }

    if (this.checkPivot(next, elements[elements.length - 1])) {
      next.nextUser = true;
    }
    return next;
  }


  private mergeNext(elements: IChatElement[], next: IChatElement): boolean {
    if (!(elements && elements.length > 0)) {
      return false;
    }

    elements[elements.length - 1].message += "<br/>" + next.message;

    return true;
  }

  processMulti(chatRx: IChatElement[]) {
    let mergedChatRx: IChatElement[] = [];
    for (let chatMessage of chatRx) {
      chatMessage = this.processNext(mergedChatRx, chatMessage);

      let merged: boolean = false;
      if (!chatMessage.nextUser) {
        // check merge
        merged = this.mergeNext(mergedChatRx, chatMessage);
      }

      if (!merged) {
        mergedChatRx.push(chatMessage);
      }
    }
    return mergedChatRx;
  }


  checkLineBreaksMulti(elements: IChatElement[]) {
    if (!(elements && elements.length > 0)) {
      return;
    }
    for (let i = 0; i < elements.length; i++) {
      elements[i].message = elements[i].message.replace(new RegExp('\r?\n', 'g'), "<br/>");
    }
    // console.log(elements);
  }


  checkLineBreaks(next: IChatElement) {
    if (!next) {
      return;
    }
    next.message = next.message.replace(new RegExp('\r?\n', 'g'), "<br/>");
  }


  watchKeyboard() {
    if (!this.subscription.keyboard) {
      this.subscription.keyboard = this.keyboard.watchKeyboardEvent().subscribe((data: IKeyboardEvent) => {
        console.log("watch keyboard: ", data);
        if (data) {
          if (this.platform.IOS) {
            // iOS does not require lifting up the chat window manually
            // only scroll to bottom
            // this.timeout.scroll = setTimeout(() => {
            //   // this.setTextInputHeight();
            //   this.timeout.scroll = setTimeout(() => {
            //     this.scrollToBottom(data.height, true);
            //   }, 100);
            // }, 500);
            // actually, because keyboardResize has been disabled, use the same logic
            if (data.show) {
              this.setFooterPosition(data.height);
            } else {
              this.setFooterPosition(0);
            }
          } else {
            // ANDROID / WEB
            if (data.show) {
              this.setFooterPosition(data.height);
            } else {
              this.setFooterPosition(0);
            }
          }
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  setFooterPosition(kbHeight: number) {
    this.footerHeight = this.footerHeightInit + kbHeight;
    if (kbHeight > 0) {
      // safe zone (kb height might not be accurate)
      this.footerHeight += this.footerOffsetKb;
    }
    this.footerHeightPx = this.footerHeight + "px";
    this.messagePadPx = (this.footerHeight + 5) + "px";
    this.timeout.scroll = ResourceManager.clearTimeout(this.timeout.scroll);
    if (!kbHeight) {
      // this.setTextInputHeight();
      this.timeout.scroll = setTimeout(() => {
        this.scrollToBottom(kbHeight, true);
      }, 100);
    } else {
      this.timeout.scroll = setTimeout(() => {
        // this.setTextInputHeight();
        this.timeout.scroll = setTimeout(() => {
          this.scrollToBottom(kbHeight, true);
        }, 100);
      }, 500);
    }
  }

  dismiss() {
    setTimeout(() => {
      this.modalCtrl.dismiss().then(() => {
      }).catch((err: Error) => {
        console.error(err);
      });
    }, 1);
  }

  updateInfoMessage(container: IInfoMessage, message: string) {
    // console.log("update info message: " + message);
    container.message = message;
    container.showTimeout = ResourceManager.clearTimeout(container.showTimeout);
    if (message != null) {
      container.showTimeout = setTimeout(() => {
        container.message = null;
      }, this.infoMessageTimeout);
    }
  }

  clearAllInfoMessageTimeouts() {
    let keys: string[] = Object.keys(this.infoMessage);
    for (let key of keys) {
      ResourceManager.clearTimeout(this.infoMessage[key].showTimeout);
    }
  }



  processMessageWrapper(chatMessage: IChatElement, apply: boolean) {
    // check self
    if (chatMessage.userId === GeneralCache.userId) {
      chatMessage.self = true;
      if (!chatMessage.user) {
        chatMessage.user = "You";
      }
    }

    switch (chatMessage.type) {
      case EMPMessageCodes.chat:
        this.checkLineBreaks(chatMessage);
        chatMessage = this.processNext(this.chatRx, chatMessage);

        let merged: boolean = false;
        if (!chatMessage.nextUser) {
          // check merge
          merged = this.mergeNext(this.chatRx, chatMessage);
        }

        if (!merged) {
          this.chatRx.push(chatMessage);
        }

        if (apply) {
          if (this.typingUser === chatMessage.userId) {
            this.otherIsTyping = false;
            this.updateInfoMessage(this.infoMessage.typing, null);
            this.timeout.typingRx = ResourceManager.clearTimeout(this.timeout.typingRx);
          }
          this.scrollToBottom(0, false);
        }

        break;
      case EMPMessageCodes.typing:
        if (!chatMessage.self) {
          if (apply) {
            this.typingUser = chatMessage.userId;
            this.updateInfoMessage(this.infoMessage.typing, chatMessage.user + " is typing...");
            if (!this.otherIsTyping) {
              this.scrollToBottom(0, false);
            }
            this.otherIsTyping = true;
            this.timeout.typingRx = ResourceManager.clearTimeout(this.timeout.typingRx);
            this.timeout.typingRx = setTimeout(() => {
              this.otherIsTyping = false;
            }, this.infoMessageTimeout);
          }
        }
        break;
    }
  }

  subscribeToRx() {
    if (this.chatRxSyncObservable) {
      this.subscription.chatRxSync = this.chatRxSyncObservable.subscribe((messageList: IMPMessageDB[]) => {
        if (messageList) {
          for (let i = 0; i < messageList.length; i++) {
            let message: IMPMessageDB = messageList[i];
            let chatMessage: IChatElement = MPUtils.getChatMessageFromMessageDB(message);
            this.processMessageWrapper(chatMessage, false);
          }
          this.scrollToBottom(0, false);
        }
      }, (err: Error) => {
        console.error(err);
      });
    }

    if (this.chatRxObservable) {
      this.subscription.chatRx = this.chatRxObservable.subscribe((message: IMPMessageDB) => {
        if (message) {
          // console.log(message);
          let chatMessage: IChatElement = MPUtils.getChatMessageFromMessageDB(message);
          this.processMessageWrapper(chatMessage, true);
        }
      }, (err: Error) => {
        console.error(err);
      });
    }

    if (this.chatRxMqttObservable) {
      this.subscription.chatRxMqtt = this.chatRxMqttObservable.subscribe((message: IMQTTChatMessage) => {
        if (message) {
          console.log("mqtt message received: ", message);
          let chatMessage: IChatElement = TelemetryDef.getChatMessageFromMqttMessage(message);
          this.processMessageWrapper(chatMessage, true);
        }
      }, (err: Error) => {
        console.error(err);
      });
    }
  }

  /**
   * only scroll after some time since last message
   * prevent 1000 scrolls on first message sync, as messages are coming one by one
   */
  takeLastMessageTimeout(callback: () => any) {
    this.timeout.scrollTrigger = ResourceManager.clearTimeout(this.timeout.scrollTrigger);
    this.timeout.scrollTrigger = setTimeout(() => {
      callback();
    }, 100);
  }


  ngOnDestroy() {
    console.log("on destroy chat");
    this.timeout = ResourceManager.clearTimeoutObj(this.timeout);
    this.clearAllInfoMessageTimeouts();
    this.subscription = ResourceManager.clearSubObj(this.subscription);
    // prevent duplicate last message
    if (this.chatRxObservable) {
      this.chatRxObservable.next(null);
    }
    if (this.chatRxMqttObservable) {
      this.chatRxMqttObservable.next(null);
    }
  }

  ngOnInit() {
    let np: INavParams = this.np;
    let hasParams: boolean = ParamHandler.checkParams(this.np);
    if (np.view) {
      this.vs = np.view;
    }
    console.log(np);
    this.footerHeight = this.footerHeightInit;
    this.footerHeightPx = this.footerHeight + "px";
    this.messagePadPx = (this.footerHeight + 5) + "px";
    this.setOverlayStyle(false);
    this.settingsProvider.getSettingsLoaded(false).then((res) => {
      if (res) {
        this.theme = ThemeColors.theme[SettingsManagerService.settings.app.settings.theme.value].css;
      }
    }).catch((err: Error) => {
      console.error(err);
    });

    this.settingsProvider.watchPlatformFlagsLoaded().subscribe((loaded: boolean) => {
      if (loaded) {
        console.log("platform flags loaded");
        this.platform = SettingsManagerService.settings.platformFlags;
        this.watchKeyboard();
      }
    }, (err: Error) => {
      console.error(err);
    });

    if (hasParams) {
      let np: INavParams = ParamHandler.getParams(this.np);
      let params: IChatModalNavParams = np.params;
      console.log(np);
      this.groupId = params.groupId;
      this.groupName = params.groupName;
      if (this.groupName) {
        this.writeMessage += " to " + this.groupName;
      }
      let chatRx: IChatElement[] = params.chatRx;
      if (chatRx && chatRx.length > 0) {
        // exclude typing events from previous chats
        chatRx = chatRx.filter(element => element.type === EMPMessageCodes.chat);
      }
      this.checkLineBreaksMulti(chatRx);
      this.chatRx = this.processMulti(chatRx);
      // console.log("history: ", this.chatRx);
      this.chatRxObservable = params.chatRxObservable;
      this.chatRxMqttObservable = params.chatRxMqttObservable;
      this.chatRxSyncObservable = params.chatRxSyncObservable;
      this.groupStatusObservable = params.groupStatusObservable;
      this.subscribeToRx();
      this.subscribeToGroupStatus();
      this.sendObservable = params.sendObservable;
      if (params.title != null) {
        this.title = params.title;
      }
      this.showChatHeads = params.showChatHeads;
    }
    this.timeout.scroll = setTimeout(() => {
      this.scrollToBottom(0, false);
    }, 1000);
  }


  onFocus() {
    console.log("onfocus");
    this.showEmojiPicker = false;
    this.enableAutoScroll = true;
    // this.content.resize();
    // this.chatContent.nativeElement;  
    // this.setOverlayStyle(true);
    this.timeout.scroll = setTimeout(() => {
      this.scrollToBottom(0, false);
    }, 500);
  }

  onBlur() {
    console.log("onblur");
    // this.setOverlayStyle(false);
    // this.setFooterPosition(0);
  }

  switchEmojiPicker() {
    this.showEmojiPicker = !this.showEmojiPicker;
    if (!this.showEmojiPicker) {
      this.class = "chat-footer";
      this.focus();
    } else {
      this.class = "chat-footer chat-footer-emoji";
      this.setTextInputHeight();
    }
    // this.content.resize();
    this.scrollToBottom(0, false);
  }

  /**
   * @name sendMsg
   */
  sendMsg() {
    console.log("about to send: ", this.chatTx);

    if (!this.chatTx.trim()) {
      this.chatTx = "";
      return;
    }

    // if (this.chatTx.indexOf("#ping") === 0) {
    //   this.pingOfflineMembers();
    //   this.chatTx = "";
    //   return;
    // }

    this.timeout.typingSend = ResourceManager.clearTimeout(this.timeout.typingSend);
    this.sendMsgCore(this.chatTx);

    // this.tts.textToSpeechNoAction(this.chatTx);

    this.chatTx = "";
  }

  sendMsgCore(msg: string) {
    let chatElement: IChatElement = {
      user: "",
      message: msg,
      self: null,
      type: EMPMessageCodes.chat,
      timestamp: null,
      timestampValue: 0,
      nextUser: false
    };
    // console.log("send: ", chatElement);
    if (this.sendObservable) {
      this.sendObservable.next(chatElement);
    }
  }

  typing() {
    // console.log("typing...");
    if (!this.timeout.typingSend) {
      // send typing event
      let chatElement: IChatElement = {
        user: "",
        message: "",
        self: true,
        type: EMPMessageCodes.typing,
        timestamp: "",
        timestampValue: 0,
        nextUser: false
      };
      if (this.sendObservable) {
        this.sendObservable.next(chatElement);
      }
      this.timeout.typingSend = setTimeout(() => {
        this.timeout.typingSend = null;
      }, this.infoMessageTimeout);
    }
  }

  scrollToBottom(_offset: number, force: boolean) {
    if (this.isScrolling) {
      console.warn("is already scrolling");
      return;
    }
    if (!this.enableAutoScroll) {
      if (force) {
        console.warn("autoscroll is disabled but overridden on request");
        return;
      } else {
        console.warn("autoscroll is disabled");
        return;
      }
    }

    this.timeoutQueueService.debounceTakeLastItemWithTimeout(ETimeoutQueue.mpMessageScrollThrottle, async () => {
      let shouldScroll: boolean = !ScrollUtils.checkScrollEnd(this.chatContent);
      // check if scrolled to bottom, allow automatic scroll on message received
      // console.log("should scroll: ", shouldScroll);
      if (shouldScroll || force) {
        // ScrollUtils.scrollEnd(this.chatContent, offset);
        ScrollUtils.scrollBottom(this.chatContent);
      }
    }, 500);

  }

  private focus() {
    if (this.messageInput && this.messageInput.nativeElement) {
      this.messageInput.nativeElement.focus();
    }
  }

  private setTextInputHeight() {
    if (this.messageInput && this.messageInput.nativeElement) {
      this.messageInput.nativeElement.scrollTop = this.messageInput.nativeElement.scrollHeight + this.footerHeight;
    }
  }

  onUserScoll(_ev) {
    if (ScrollUtils.checkScrollEnd(this.chatContent)) {
      this.enableAutoScroll = true;
    } else {
      this.enableAutoScroll = false;
    }
  }

  // This method adds classes to the element based on the message type
  getClasses(self: boolean) {
    return {
      incoming: self,
      outgoing: !self,
    };
  }

  showOptions() {
    let actions: IPopoverActions = {};
    actions = {
      tutorial: {
        name: "Tutorial",
        code: 1,
        icon: EAppIconsStandard.tutorial,
        enabled: true
      },
      ping: {
        name: "Ping",
        code: 2,
        icon: EAppIconsStandard.ping,
        enabled: this.showChatHeads
      },

    };

    if (this.devMode) {
      actions.simulateKb = {
        name: "Simulate kb*",
        code: 101,
        enabled: true
      };
    }

    this.uiextStandard.showStandardModal(null, EModalTypes.options, null, {
      view: {
        fullScreen: false,
        transparent: AppConstants.transparentMenus,
        large: false,
        addToStack: true,
        frame: false
      },
      params: { actions: actions }
    }).then((result) => {
      switch (result) {
        case 1:
          this.tutorials.showTutorialNoAction("Chat Tutorial", ETutorialEntries.chatTutorial, null, null, true);
          break;
        case 2:
          this.pingOfflineMembers();
          break;
        case 101:
          this.simulateKB();
          break;
      }
    }).catch((err: Error) => {
      console.error(err);
    });
  }

  simulateKB() {
    this.simulateKbOpen = !this.simulateKbOpen;
    if (this.simulateKbOpen) {
      this.setFooterPosition(100);
    } else {
      this.setFooterPosition(0);
    }
  }

  pingOfflineMembers() {
    if (!(this.groupMembersStatus && this.groupMembersStatus.length > 0)) {
      return;
    }

    let pingIds: number[] = [];
    for (let i = 0; i < this.groupMembersStatus.length; i++) {
      if (!this.groupMembersStatus[i].connected) {
        pingIds.push(this.groupMembersStatus[i].id);
      }
    }

    this.mp.pingOfflineMembers(pingIds, this.groupId, this.groupName).then(() => {
      //  this.uiext.showAlertNoAction(Messages.msg.success.after.msg, Messages.msg.success.after.sub);
      this.sendMsgCore("#ping");
    }).catch((err: Error) => {
      this.uiext.showAlertNoAction(Messages.msg.requestFailed.after.msg, ErrorMessage.parse(err, Messages.msg.requestFailed.after.sub));
    });
  }

  setOverlayStyle(kbOpened: boolean) {
    // let ios: boolean = GeneralCache.os === EOS.ios;
    // let web: boolean = GeneralCache.os === EOS.browser;
    // if (!SettingsManagerService.settings.app.settings.onScreenHomeButtonFix.value) {
    //   ios = false;
    // }
    // this.inputClass = ((ios || web) && !kbOpened) ? "input-area-margin-large" : "input-area-margin";
    this.inputClass = !kbOpened ? "input-area-margin-large" : "input-area-margin";
    console.log("input class: " + this.inputClass);
    // if(web){
    //   this.setFooterPosition(100);
    // }
  }
}
